import firebase from 'firebase/app';
import * as I from 'immutable';
import React from 'react';

import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {useFirebaseDatabase} from 'app/tools/Firebase';

// ActiveUsersContext has a default of "no active users" so that WithActiveUsers
// can be safely used in components that might render outside of
// ActiveUsersProvider.
const ActiveUsersContext = React.createContext<string[]>([]);

/**
 * Use this component in a place where there is a logged in user and an active
 * project.
 *
 * Updates Firebase to indicate that the current user is "active" on the
 * project, and also sets up a context (accessible through WithActiveUsers) that
 * provides an array of all active email addresses.
 *
 * Listens to Firebase updates in order to keep the array up-to-date.
 */
const ActiveUsersProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    profile: I.ImmutableOf<ApiOrganizationUser>;
    projectId: string;
  }>
> = ({profile, projectId, children}) => {
  const database = useFirebaseDatabase();
  const activeUserEmails = useActiveUserEmails(database, projectId);
  useSetUserAsActive(database, projectId, profile.get('email'));

  return (
    <ActiveUsersContext.Provider value={activeUserEmails}>{children}</ActiveUsersContext.Provider>
  );
};

const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

/**
 * Returns the list of currently active email addresses for the given project,
 * and updates it (via an internal useState) as that list changes.
 *
 * We show users who have an activity timestamp in the past 24h as a way to weed
 * out stale "active" users, since they don’t get reliably marked as "inactive"
 * when authentication changes unexpectedly.
 */
function useActiveUserEmails(database: firebase.database.Database, projectId: string): string[] {
  const [activeUserEmails, setActiveUserEmails] = React.useState<string[]>([]);

  React.useEffect(() => {
    const realtimeUsersPath = `realtime/projects/${projectId}/_activeUserEmails`;
    const realtimeUsersRef = database.ref(realtimeUsersPath);

    const handleActiveUserEmailsValue = (snapshot: firebase.database.DataSnapshot) => {
      // Where "key" is a comma-punctuated email address (e.g.,
      // person@upstream,tech). Value is either a boolean or timestamp of last
      // activity.
      const sanitizedActiveUserEmailsMap: Record<string, boolean | number> | null = snapshot.val();

      const activeUserEmails =
        sanitizedActiveUserEmailsMap &&
        Object.keys(sanitizedActiveUserEmailsMap)
          .filter((email) => {
            const timestamp = sanitizedActiveUserEmailsMap[email];
            // As a safety backstop we only show users who were last active on
            // the project within the last day. Keeps us from showing stale
            // users whose activity was never correctly cleaned up.
            //
            // We don’t mind that Date.now() could be skewed from the server,
            // since this calculation does not need to be particularly accurate.
            //
            // We also don’t bother to recalculate this while the current user
            // stays on the same page. So one of the active user icons could
            // have "expired" due to passing 24hrs, but we won’t catch that
            // until some server-side update causes us to re-run this handler.
            return typeof timestamp === 'number' && Date.now() - timestamp < DAY_IN_MILLIS;
          })
          // we sanitize emails in firebase '.'->',' due to database limitations
          .map((email) => email.replace(/,/g, '.'));

      setActiveUserEmails(activeUserEmails || []);
    };

    // TODO(fiona): The old code had this debounced to 3000ms. Is that really
    // necessary? If we do, we’ll want to make sure to still run on the first
    // call.
    realtimeUsersRef.on('value', handleActiveUserEmailsValue);

    return () => {
      realtimeUsersRef.off('value', handleActiveUserEmailsValue);
      setActiveUserEmails([]);
    };
  }, [database, projectId]);

  return activeUserEmails;
}

/**
 * Marks the given email address as "active" for the given project ID. Will
 * remove this marker when the ID changes or is unmounted, or if the client
 * completely disconnects.
 */
function useSetUserAsActive(
  database: firebase.database.Database,
  projectId: string,
  email: string
) {
  React.useEffect(() => {
    // we sanitize emails in firebase '.'->',' due to database limitations
    const sanitizedEmail = email.replace(/\./g, ',');

    const realtimeCurrentUserPath = `realtime/projects/${projectId}/_activeUserEmails/${sanitizedEmail}`;
    const realtimeCurrentUserRef = database.ref(realtimeCurrentUserPath);

    // We wrap this in an .info/connected listener so that we correctly restore
    // if the connection drops and comes back while the browser window is still
    // open.
    //
    // See: https://firebase.google.com/docs/firestore/solutions/presence
    const connectedRef = database.ref('.info/connected');
    const connectedListener = connectedRef.on('value', async (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      await realtimeCurrentUserRef.onDisconnect().set(false);

      // We send the timestamp so that we can filter out "active" users with
      // stale timestamps. Due to auth reasons, we can’t always rely on
      // onDisconnect because it doesn’t trigger when our database connection’s
      // auth _changes_ (due to being signed out in another window) rather than
      // simply disconnecting.
      //
      // TODO(fiona): Periodically re-set this to the current time while the
      // user remains on the page.
      realtimeCurrentUserRef.set(firebase.database.ServerValue.TIMESTAMP);
    });

    return () => {
      connectedRef.off('value', connectedListener);

      // Our cleanup for this hook is to set the value to false, since we’re no
      // longer active. Nevertheless, sometimes the reason we’ve been unmounted
      // and need to clean up is that the user signed out in another window and
      // we’re being redirected to the /signin page. Since we’re already logged
      // out, this call will fail. We swallow the exception because there’s no
      // remediation. These users will eventually not be shown as active once
      // their timestamps pass the 24h hour mark.
      realtimeCurrentUserRef.set(false).catch((e) => {
        if (e.code !== 'PERMISSION_DENIED') {
          throw e;
        }
      });
    };
  }, [database, projectId, email]);
}

/**
 * Wrapper component around React Context to provide an array of
 * currently-active email addresses for the project set by ActiveUsersProvider.
 *
 * Its single child should be a function that takes a string array and returns a
 * React/JSX something.
 *
 * If called outside of ActiveUsersProvider will provide an empty array.
 */
export const WithActiveUsers: React.FunctionComponent<{
  children: (emails: string[]) => React.ReactElement | null;
}> = ({children}) => {
  const activeUserEmails = React.useContext(ActiveUsersContext);
  return children(activeUserEmails);
};

export default ActiveUsersProvider;
