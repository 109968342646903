import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import * as cs from 'app/components/NoteCard/NoteCard.styl';
import {api} from 'app/modules/Remote';
import {ApiReport} from 'app/modules/Remote/Feature';
import colors from 'app/styles/colors.json';
import {useUserInfo} from 'app/utils/featureFlags';
import {BULLET} from 'app/utils/noteUtils';

import * as reportCardCs from './styles.styl';

export const ReportCard: React.FunctionComponent<
  React.PropsWithChildren<{
    report: ApiReport<string>;
    openReportExportModalWithState: (ev: React.MouseEvent, report: ApiReport<string>) => void;
    featureId: number;
    fetchReports: () => Promise<void>;
  }>
> = ({report, openReportExportModalWithState, featureId, fetchReports}) => {
  const [, profile] = useUserInfo();
  const canEdit = profile!.get('role') !== 'readonly';

  const archiveReport = React.useCallback(
    async (reportId: number) => {
      if (canEdit && confirm('Are you sure you want to delete this report?')) {
        await api.reports(featureId).archive(reportId);
        fetchReports();
      }
    },
    [fetchReports, featureId, canEdit]
  );

  const updateTitle = React.useCallback(
    async (reportId: number, newTitle: string) => {
      await api.reports(featureId).updateTitle(reportId, newTitle);
      fetchReports();
    },
    [fetchReports, featureId]
  );

  const handleUpdateTitle = () => {
    const editedText = prompt(`Enter a new report name`, report.title);

    // If name (1) has not been changed, or (2) has been deleted, no-op.
    if (editedText === report.title || !editedText) {
      return;
    }

    updateTitle(report.id, editedText);
  };
  const [createdByUser, updatedByUser] = React.useMemo(
    () => [report.createdByUser, report.updatedByUser].map((ru) => ru.name),
    [report]
  );

  return (
    <B.Card
      interactive
      className={classnames(cs.card, reportCardCs.reportCard)}
      onClick={(ev) => openReportExportModalWithState(ev, report)}
    >
      <div className={cs.body}>
        <div className={cs.header} style={{height: 'unset', alignItems: 'baseline'}}>
          <div className={reportCardCs.reportTitle}>{report.title}</div>
          <div className={cs.headerActions}>
            <B.Popover
              interactionKind={B.PopoverInteractionKind.HOVER}
              modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
              position="bottom"
              minimal
              content={
                <B.Menu style={{minWidth: 0}}>
                  {canEdit && (
                    <>
                      <B.MenuItem
                        icon="edit"
                        text="Edit title"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          handleUpdateTitle();
                        }}
                      />
                      <B.MenuItem
                        icon="trash"
                        text="Delete"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          archiveReport(report.id);
                        }}
                      />
                    </>
                  )}
                </B.Menu>
              }
            >
              <B.AnchorButton
                small
                minimal
                icon={
                  <B.Icon
                    icon="more"
                    size={14}
                    color={colors.gray}
                    onClick={(ev) => {
                      ev.stopPropagation();
                    }}
                  />
                }
              />
            </B.Popover>
          </div>
        </div>
        {report.createdAt !== report.updatedAt && (
          <div
            className={classnames(cs.headerText, reportCardCs.dateDescription)}
            style={{marginBottom: '0.5rem'}}
          >
            <span className={reportCardCs.dateLabel}>Updated: </span>
            {`${updatedByUser} ${BULLET} ${moment(report.updatedAt).format(`MM/DD/YY ∙ h:mma`)}`}
          </div>
        )}
        <div className={classnames(cs.headerText, reportCardCs.dateDescription)}>
          <span className={reportCardCs.dateLabel}>Created:</span>
          {`${createdByUser} ${BULLET} ${moment(report.createdAt).format(`MM/DD/YY ∙ h:mma`)}`}
        </div>
      </div>
    </B.Card>
  );
};
