import * as Sentry from '@sentry/react';
import * as I from 'immutable';
import React from 'react';

import {api} from 'app/modules/Remote';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import ImageryPricesProvider from 'app/providers/ImageryPricesProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {recordEvent} from 'app/tools/Analytics';
import * as CONSTANTS from 'app/utils/constants';
import {
  findPrimaryFeatureCollection,
  getPrimaryFeatureCollectionIdFromProjectId,
} from 'app/utils/featureCollectionUtils';
import {keyInAppProperties} from 'app/utils/featureUtils';
import * as featureUtils from 'app/utils/featureUtils';
import {useApiGet} from 'app/utils/hookUtils';
import {LAYER_PACKAGES, S2_NDVI} from 'app/utils/layers';
import {
  checkLayerIsProcessingStatus,
  createLayerKeysFromLayerObject,
  getLayer,
  getLayerResolution,
  getPackageLayerKeys,
  parseLayerKey,
} from 'app/utils/layerUtils';
import {
  createLayerObjectFromLayerKeys,
  getEnrolledLayerKeysForFeatureCollection,
  getProcessingMetadataForFeatureCollection,
  getSupportedLayerKeysForFeatureCollection,
} from 'app/utils/layerUtils';
import {orgIsAllowedTier} from 'app/utils/organizationUtils';

import LensLibraryView from './LensLibraryView';
import {PREVIEW_LAYERS_CONFIG} from './libraryConfig';
import {LayerDataset, LibraryDataset, LibraryDatasetType} from './LibraryTypes';

export type SupportedLayersMap = Record<
  string,
  {
    supportedFeatureCollectionIds: number[];
    enrolledFeatureCollectionIds: number[];
    processingFeatureCollectionIds: number[];
  }
>;

export type LayersLibraryMode = 'librarySettings' | 'singlePortfolio';
// These layers cannot be turned on or off by users and we don't want to display them in the library
const HIDDEN_LAYER_KEYS = [
  'UserData_high-res-truecolor',
  'COG-TRUECOLOR_high-res-truecolor',
  'WMS-TRUECOLOR_high-res-truecolor',
  'WMTS-TRUECOLOR_high-res-truecolor',
  // We've lost access to NICFI, so we don't want to display it in the library. It's been removed
  // from product configs, leaving this here in case any data sneaks through.
  'PLANET-NICFI_high-res-truecolor',
];
const DEFAULT_THUMBNAIL_URL = 'https://storage.googleapis.com/upstream-icons/layers/default.png';

export const LensLibraryProviderSettingsModeWrapper: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
  }>
> = ({organization, profile}) => {
  const [
    projects,
    featureCollectionsById,
    {addLayersToFeatureCollections, removeLayersFromFeatureCollections},
    projectsMeta,
  ] = useProjects();
  return (
    <LensLibraryProvider
      organization={organization}
      profile={profile}
      addLayersToFeatureCollections={addLayersToFeatureCollections}
      removeLayersFromFeatureCollections={removeLayersFromFeatureCollections}
      mode={'librarySettings'}
      projects={projects}
      featureCollectionsById={featureCollectionsById}
      areProjectsLoading={projectsMeta.loading}
    />
  );
};

export const LensLibraryProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    addLayersToFeatureCollections: (
      ids: number[],
      layers: Record<string, string[]>
    ) => Promise<void>;
    removeLayersFromFeatureCollections: (
      ids: number[],
      layers: Record<string, string[]>
    ) => Promise<void>;
    mode: LayersLibraryMode;
    selectedOverlayIds?: number[];
    setSelectedOverlayIds?: React.Dispatch<React.SetStateAction<number[]>>;
    projects: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<ApiProject>>> | null;
    featureCollectionsById: I.Map<number, I.ImmutableOf<ApiFeatureCollection>>;
    areProjectsLoading: boolean;
    onClose?: () => void;
  }>
> = ({
  organization,
  profile,
  addLayersToFeatureCollections,
  removeLayersFromFeatureCollections,
  selectedOverlayIds,
  setSelectedOverlayIds,
  mode,
  projects,
  featureCollectionsById,
  areProjectsLoading,
  onClose,
}) => {
  const layers: LibraryDataset<LayerDataset>[] = React.useMemo(() => {
    if (!projects) {
      return [];
    }

    const featureCollections = projects.map((project) =>
      findPrimaryFeatureCollection(project!, featureCollectionsById)
    );

    const layersMap: SupportedLayersMap[] = featureCollections.size
      ? featureCollections
          ?.map((fc) => {
            const supportedLayerKeys = fc ? getSupportedLayerKeysForFeatureCollection(fc) : [];
            const enrolledLayerKeys = fc ? getEnrolledLayerKeysForFeatureCollection(fc) : [];
            const processingMetadata = fc ? getProcessingMetadataForFeatureCollection(fc) : {};

            const layersInfo: SupportedLayersMap = supportedLayerKeys.reduce(
              (layersInfo, layerKey) => {
                const isEnrolled = enrolledLayerKeys.includes(layerKey);
                const isProcessing = checkLayerIsProcessingStatus(layerKey, processingMetadata);

                const currSupportedFeatureCollectionIds =
                  layersInfo[layerKey]?.supportedFeatureCollectionIds || [];
                const currEnrolledFeatureCollectionIds =
                  layersInfo[layerKey]?.enrolledFeatureCollectionIds || [];
                const currProcessingFeatureCollectionIds =
                  layersInfo[layerKey]?.processingFeatureCollectionIds || [];

                if (HIDDEN_LAYER_KEYS.includes(layerKey)) {
                  return layersInfo;
                }

                return {
                  ...layersInfo,
                  [layerKey]: {
                    supportedFeatureCollectionIds: [
                      ...currSupportedFeatureCollectionIds,
                      fc?.get('id'),
                    ],
                    enrolledFeatureCollectionIds: isEnrolled
                      ? [...currEnrolledFeatureCollectionIds, fc?.get('id')]
                      : currEnrolledFeatureCollectionIds,
                    processingFeatureCollectionIds: isProcessing
                      ? [...currProcessingFeatureCollectionIds, fc?.get('id')]
                      : currProcessingFeatureCollectionIds,
                  },
                };
              },
              {}
            );
            return layersInfo;
          })
          .toList()
          .toJS()
      : createLayerKeysFromLayerObject(organization.get('supportedLayersBySource').toJS()).map(
          (layerKey) => ({
            [layerKey]: {
              supportedFeatureCollectionIds: [],
              enrolledFeatureCollectionIds: [],
              processingFeatureCollectionIds: [],
            },
          })
        );

    const consolidatedLayersMap: SupportedLayersMap = layersMap.reduce((acc, layerInfo) => {
      Object.entries(layerInfo).forEach(([layerKey, value]) => {
        let key = layerKey;
        const source = parseLayerKey(key).sourceId;

        // If we have a Planet Forest Carbon package layer, we don't want to display that directly
        // in the layersLibrary. Instead We want to display the package that represents them all. So
        // we replace the layerKey with the layerKey that represents the package. This will happen for
        // each of the forest carbon layer keys so we deduplicate the featureCollectionId arrays to avoid
        // repeats. NOTE: it is assumed that the enrolled/supported/processing featureCollectionIds will
        // be the same for each of these layers because they can only be manipulated as a group.
        if (`${source}_package` in LAYER_PACKAGES) {
          key = `${source}_package`;
        }
        if (key in acc) {
          acc[key].enrolledFeatureCollectionIds = Array.from(
            new Set([
              ...acc[key].enrolledFeatureCollectionIds,
              ...value.enrolledFeatureCollectionIds,
            ])
          );
          acc[key].supportedFeatureCollectionIds = Array.from(
            new Set([
              ...acc[key].supportedFeatureCollectionIds,
              ...value.supportedFeatureCollectionIds,
            ])
          );
          acc[key].processingFeatureCollectionIds = Array.from(
            new Set([
              ...acc[key].processingFeatureCollectionIds,
              ...value.processingFeatureCollectionIds,
            ])
          );
        } else {
          acc[key] = value;
        }
      });
      return acc;
    }, {});

    let layers: LibraryDataset<LayerDataset>[] = Object.entries(consolidatedLayersMap).map(
      ([
        layerKey,
        {
          supportedFeatureCollectionIds,
          enrolledFeatureCollectionIds,
          processingFeatureCollectionIds,
        },
      ]) => {
        const source = parseLayerKey(layerKey).sourceId;
        // Special override for Planet forest carbon package. Everywhere else we want the source to be called Planet Forest
        // Carbon but in the layers library it results in redundant information so we simplify to Planet Labs
        const sourceDisplayName =
          source === 'PLANET-FOREST-CARBON-30' || source == 'PLANET-FOREST-CARBON-3'
            ? 'Planet Labs'
            : CONSTANTS.SOURCE_DETAILS_BY_ID[source]
              ? `${CONSTANTS.SOURCE_DETAILS_BY_ID[source].operator} ${CONSTANTS.SOURCE_DETAILS_BY_ID[source].name}`.trim()
              : '';
        const premium = CONSTANTS.SOURCE_DETAILS_BY_ID[source]
          ? CONSTANTS.SOURCE_DETAILS_BY_ID[source].premium
          : false;
        const billingIntervals = CONSTANTS.SOURCE_DETAILS_BY_ID[source]
          ? CONSTANTS.SOURCE_DETAILS_BY_ID[source].billing_intervals
          : [];
        const layer = getLayer(layerKey, true);
        const resolution = getLayerResolution(layerKey);
        const thumbnailUrl = layer.thumbnailUrl || DEFAULT_THUMBNAIL_URL;
        const tags = layer.tags || [];
        const libraryAction = layer.libraryAction;
        const restricted = layer.allowedTiers
          ? !orgIsAllowedTier(organization, layer.allowedTiers)
          : false;

        return {
          layerKey,
          libraryKey: layerKey,
          supportedFeatureCollectionIds,
          enrolledFeatureCollectionIds,
          processingFeatureCollectionIds,
          name: layer.shortName,
          source: sourceDisplayName,
          resolution: resolution,
          thumbnailUrl: thumbnailUrl,
          highResPreview: layer.highResPreview,
          premium,
          description: layer.description || '',
          license: layer.license,
          tags,
          libraryAction,
          billingIntervals,
          restricted,
          type: LibraryDatasetType.LAYER,
        };
      }
    );

    layers = layers.filter((l) => {
      // Don't show individual layers that are a part of a package
      return !Object.values(LAYER_PACKAGES).flat().includes(l.layerKey);
    });

    // append any layers that we want to preview in the library to our list of returned layers.
    // returning these as proper Layer objects means we can display descriptions and other details.
    layers = [...layers, ...Object.values(PREVIEW_LAYERS_CONFIG)];

    // sort layers alphabetically by both name and source
    return layers
      .sort((a, b) => featureUtils.alphanumericSort(a.source, b.source))
      .sort((a, b) => featureUtils.alphanumericSort(a.name, b.name));
  }, [featureCollectionsById, projects, organization]);

  const addLayers = async (projectIds: string[], layerKey: string) => {
    recordEvent('Layer added from layers library', {layerKey: layerKey});
    const layerKeys = getPackageLayerKeys(layerKey);
    const layersObject = createLayerObjectFromLayerKeys(layerKeys);
    const featureCollectionIds = projectIds
      .map((pId) =>
        getPrimaryFeatureCollectionIdFromProjectId(pId, projects!, featureCollectionsById)
      )
      .filter((fcId) => fcId) as unknown as number[];

    // This section looks slightly different than in overlays. This is because our caching
    // logic is inside the projects provider, and cache restore behavior invokes a rejection.
    addLayersToFeatureCollections(featureCollectionIds, layersObject).catch((e) => {
      // e is unknown, add a type, use optional chaining, and provide a default
      const errorBody = (e as {body: {error: string}; error: Error})?.body?.error || '';

      Sentry.captureException(e);
      // In lieu of actual error types or enums, check to see if this error is about being readonly
      const errorMessage = errorBody.includes('readonly')
        ? 'Please reach out to an admin or member on your team to add layers.'
        : 'We weren’t able to add these layers. Please try again.';
      window.alert(errorMessage);
    });
  };

  const removeLayers = async (projectIds: string[], layerKey: string) => {
    if (layerKey === S2_NDVI && mode === 'librarySettings') {
      if (
        confirm(
          `Are you sure you want to remove Vegetation (Sentinel-2)? This will automatically deactivate Vegetation Alerts for properties in these portfolios.`
        )
      ) {
        //kick off asynchronous unenrollment of project features from Vegetation alerts
        projectIds.map((pId) => unenrollSelectedProjectFeatures(pId));
      } else {
        return;
      }
    }

    const layerKeys = getPackageLayerKeys(layerKey);
    const layersObject = createLayerObjectFromLayerKeys(layerKeys);
    const featureCollectionIds: number[] = projectIds
      .map((pId) =>
        getPrimaryFeatureCollectionIdFromProjectId(pId, projects!, featureCollectionsById)
      )
      .filter((fcId) => fcId) as unknown as number[];

    // This section looks slightly different than in overlays. This is because our caching
    // logic is inside the projects provider, and cache restore behavior invokes a rejection.
    removeLayersFromFeatureCollections(featureCollectionIds, layersObject).catch((e) => {
      // e is unknown, add a type, use optional chaining, and provide a default
      const errorBody = (e as {body: {error: string}; error: Error})?.body?.error || '';

      Sentry.captureException(e);
      // In lieu of actual error types or enums, check to see if this error is about being readonly
      const errorMessage = errorBody.includes('readonly')
        ? 'Please reach out to an admin or member on your team to remove layers.'
        : 'We weren’t able to remove these overlays. Please try again.';
      window.alert(errorMessage);
    });
  };

  const unenrollSelectedProjectFeatures = async (projectId) => {
    const featureCollectionId = projectIdToFcIdMap[projectId];

    // load all features for selected project
    // TODO(eva): try and use the FeaturesProvider (maybe useGeoJsonFeaturesLoader) for this instead
    try {
      const getFeatures = async (featureCollectionId) =>
        (
          await api.featureCollections
            .features(featureCollectionId)
            .list({perPage: 200}, {getAllPages: true})
        ).get('data');

      const features = await getFeatures(featureCollectionId);

      // we only care about unenrolling features already enrolled in veg alerts
      const featuresEnrolledInVegAlerts = features.filter((f) =>
        keyInAppProperties(f!, CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY)
      );
      const featureIds = featuresEnrolledInVegAlerts.map((f) => f!.get('id'));

      //if at least one feature is enrolled in Veg Alerts, kick off the unenrollment step
      if (featureIds.size > 0) {
        let featurePatch: I.MergesInto<I.ImmutableOf<ApiFeature>> = {};
        featurePatch = I.fromJS({
          properties: {
            [CONSTANTS.APP_PROPERTIES_KEY]: {
              [CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY]: false,
            },
          },
        });

        //TODO(eva): try to use batchPatchFeatures from FeaturesProvider for this instead
        (
          await api.featureCollections
            .features(featureCollectionId)
            .batchPatch(featureIds!.toList(), featurePatch)
        ).get('data');
      }
    } catch (e) {
      Sentry.captureException(e);
      window.alert(
        `We weren't able to turn off vegetation alerts for these properties. Please try again.`
      );
      //if we weren't able to turn off alerts for every feature in the property, add the S2 Veg layer back:
      //we don't want to remove the layer if some alerts are still turned on.
      addLayers([projectId], S2_NDVI);
    }
  };

  // Creates a map of featureCollectionIds to projectIds. We need to use projectIds
  // a lot throughout the UI to display portfolio names to add layers to and work more seamlessly
  // with the shared layers library code. Creating this map allows for an efficient lookup
  // when needing to translate between the two.
  const [projectIdToFcIdMap, fcIdToProjectMap] = React.useMemo(() => {
    const fcIdToProjectTuples = projects
      ? projects
          .valueSeq()
          .toList()
          .map((project) => {
            const primaryFeatureCollection = findPrimaryFeatureCollection(
              project!,
              featureCollectionsById
            );
            return [primaryFeatureCollection!.get('id'), project?.toJS()];
          })
          .toJS()
      : [];
    const projectIdToFcIdTuples = fcIdToProjectTuples.map(([fcId, project]) => [project.id, fcId]);
    return [
      Object.fromEntries(projectIdToFcIdTuples),
      Object.fromEntries(fcIdToProjectTuples) as Record<number, ApiProject>,
    ];
  }, [projects, featureCollectionsById]);

  const organizationId = organization.get('id');
  const [{value: subscribedPremiumSourceIds}, {refresh: refreshSubscribedPremiumSourceIds}] =
    useApiGet(
      async (organizationId: string) => {
        const subscribedPremiumSourceIds: string[] = (
          await api.organizations.listPremiumSources(organizationId)
        )
          .get('data')
          .toJS();
        return subscribedPremiumSourceIds;
      },
      [organizationId]
    );

  const isLoading = areProjectsLoading || subscribedPremiumSourceIds === undefined;

  const purchasePaidLayer = async (
    sourceId: string,
    billingInterval: string,
    subscriptionProrationDate: number,
    billingCycleAnchor: number,
    couponId: string | undefined
  ) => {
    try {
      return (
        await api.organizations.addPaidLayer(
          organizationId,
          sourceId,
          billingInterval,
          subscriptionProrationDate,
          billingCycleAnchor,
          couponId
        )
      ).getIn(['data', 'clientSecret']);
    } finally {
      // Alwaya refresh in case the addPaidLayer request hits the error
      // where someone else from the organization paid for this layer while this
      // user was paying for it
      await refreshSubscribedPremiumSourceIds();
    }
  };

  return (
    <ImageryPricesProvider>
      <LensLibraryView
        addLayers={addLayers}
        removeLayers={removeLayers}
        selectedOverlayIds={selectedOverlayIds}
        setSelectedOverlayIds={setSelectedOverlayIds}
        layers={layers}
        projects={(projects || I.OrderedMap()).valueSeq().toList()}
        isLoading={isLoading}
        fcIdToProjectMap={fcIdToProjectMap}
        subscribedPremiumSourceIds={subscribedPremiumSourceIds || []}
        purchasePaidLayer={purchasePaidLayer}
        organization={organization}
        profile={profile}
        role={profile.get('role')}
        mode={mode}
        onClose={onClose}
      />
    </ImageryPricesProvider>
  );
};
