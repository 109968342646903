import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import cs from './MapOverlayDialog.styl';

/**
 * Component to show a dialog that overlays a map. Includes a title, close box,
 * and optional buttons.
 */
const MapOverlayDialog: React.ForwardRefRenderFunction<
  HTMLDivElement,
  {
    title?: string | React.ReactNode;
    titleIcon?: B.IconName | B.MaybeElement;
    titleBarStyle?: React.CSSProperties;

    additionalButtons?: React.ReactNode;
    onClose?: () => void;
    canOutsideClickClose?: boolean;
    insideRefs?: React.MutableRefObject<HTMLElement | null>[];

    style?: React.CSSProperties;
    className?: string;
    contentClassName?: string;
    contentStyles?: React.CSSProperties;
    // Hook for Blueprint’s Overlay to allow this component to have focus.
    // (Otherwise the browser gives focus to the first focusable component
    // within, usually the close box, which is not what we want.)
    tabIndex?: number;

    // Need to explicitly include this for ForwardingRef to see it.
    children?: React.ReactNode;
    footer?: React.ReactNode;
  }
> = (
  {
    children,
    className,
    contentClassName,
    contentStyles = {},
    style,
    title = '',
    titleIcon,
    titleBarStyle,
    onClose,
    canOutsideClickClose,
    insideRefs,
    additionalButtons,
    tabIndex,
    footer,
  },
  ref
) => {
  /**we want to close this "dialog" (just a div styled to look like a dialog) anytime
   **somewhere other than the dialog and the dialog control button is clicked**/
  const wrapperRef = React.useRef(null);

  useDetectOutsideClick(
    [wrapperRef, ...(insideRefs || [])],
    [document.getElementById('map-overlays-control')],
    canOutsideClickClose && !!onClose ? onClose : undefined
  );

  return (
    <div ref={wrapperRef}>
      <div
        className={classnames(cs.container, className)}
        style={style}
        ref={ref}
        tabIndex={tabIndex}
      >
        <div className={cs.titleBar} style={titleBarStyle}>
          {titleIcon && (
            <div className={cs.titleIcon}>
              <B.Icon icon={titleIcon} />
            </div>
          )}

          <div className={classnames(cs.title, {[cs.asString]: typeof title === 'string'})}>
            {title}
          </div>

          <B.ButtonGroup>
            {additionalButtons}
            {onClose && (
              <B.Tooltip content="Exit" position={B.Position.TOP}>
                <B.Button icon="cross" minimal={true} onClick={onClose} />
              </B.Tooltip>
            )}
          </B.ButtonGroup>
        </div>
        <div className={classnames(cs.content, contentClassName)} style={contentStyles}>
          {children}
        </div>
        {footer && <div className={cs.footer}>{footer}</div>}
      </div>
    </div>
  );
};

/**
 * Hook that takes a list of HTML elements (can be assembled either by ref.current
 * or document.getElementById) and detects clicks outside any of those elements.
 * Useful for closing non-Blueprint divs on outside click.
 */
function useDetectOutsideClick(
  refs: React.MutableRefObject<HTMLElement | null>[],
  els: (HTMLElement | null)[],
  onOutsideClick: (() => void) | undefined
) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      const elements = [...els, ...refs.map((ref) => ref.current)];
      if (
        !elements.some((element) => element && element.contains(event.target)) &&
        !!onOutsideClick
      ) {
        onOutsideClick();
      }
    }

    if (onOutsideClick) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);
}

export default React.forwardRef(MapOverlayDialog);
