import * as I from 'immutable';
import React, {useEffect} from 'react';

import {ApiFeature, ApiFeatureData, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {OrderableScenesLoader} from 'app/providers/OrderableScenesProvider';
import * as featureUtils from 'app/utils/featureUtils';
import * as layers from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {MapStateDispatch} from './MapStateProvider';

/**
 * Loads scene  from the url when present. After the first render where featureData
 * exists, it is removed from the url. Since we do not have the orderableScenes on
 * this first rendering the monitorProjectView, this component functions to keep track
 * of what scene needs to be loaded and wait until we have orderableScenes
 * to load it. After it is loaded the component state is set back to null.
 */
const UrlSceneHandler: React.FunctionComponent<
  React.PropsWithChildren<{
    imageRefFromUrl: mapUtils.MapImageRef | null;
    featureData: I.ImmutableOf<ApiFeatureData[]> | null;
    mapStateDispatch: MapStateDispatch;
    orderableScenesLoader: OrderableScenesLoader;
    selectedFeature: I.ImmutableOf<ApiFeature> | null;
    openOrderImageryModal: (sceneToOrder: I.ImmutableOf<ApiOrderableScene>) => void;
  }>
> = ({
  imageRefFromUrl,
  featureData,
  mapStateDispatch,
  orderableScenesLoader,
  selectedFeature,
  openOrderImageryModal,
}) => {
  const [orderableSceneImageRef, setOrderableSceneImageRef] =
    React.useState<mapUtils.MapImageRef | null>(null);

  /*
   * This should only happen once - when a scene is set in the url and featureData has loaded.
   * If it is unordered we update the state with that cursor, otherwise we set the map state to it.
   */
  useEffect(() => {
    if (!imageRefFromUrl || !featureData) {
      return;
    }

    const featureDatum = featureData.find((d) => d!.get('date') === imageRefFromUrl.cursor);

    if (featureDatum) {
      // Lack of template URL is a sign that the image hasn’t been ordered,
      // though it may be processing. (In either case we can’t show it on the
      // map, so we check the scene API rather than jumping to it with
      // setMapState.)
      if (!featureUtils.getHasTemplateUrl(featureDatum, imageRefFromUrl.layerKey)) {
        setOrderableSceneImageRef(imageRefFromUrl);
      } else {
        mapStateDispatch({
          type: 'SET_IMAGE_REFS',
          imageRefs: [imageRefFromUrl],
        });

        setOrderableSceneImageRef(null);
      }
    }
  }, [featureData, imageRefFromUrl, selectedFeature, mapStateDispatch, orderableScenesLoader]);

  const orderableScenes =
    !!selectedFeature?.get('id') &&
    !!orderableSceneImageRef &&
    orderableScenesLoader(selectedFeature.get('id')).value;

  /*
   * This also should only happen once. If the scene is unordered, after the cursor is set
   * in state we fetch the orderableScenes. Once we have the scene we set the modal to
   * open that scene and set the cursor state back to null so this will not happen again.
   */
  useEffect(() => {
    if (orderableSceneImageRef && orderableScenes) {
      if (layerUtils.isLayerKeyHighResTruecolor(orderableSceneImageRef.layerKey)) {
        // We need to support legacy URLs that don’t have a source-specific
        // layerKey.
        const sourceIdPredicate =
          orderableSceneImageRef.layerKey === layers.ANY_TRUECOLOR_HIGH_RES
            ? () => true
            : (sourceId: string) =>
                sourceId === featureUtils.sourceIdFromLayerKey(orderableSceneImageRef.layerKey);

        const sceneToOpen = orderableScenes.find(
          (scene) =>
            scene!.get('sensingTime') === orderableSceneImageRef.cursor &&
            sourceIdPredicate(scene!.get('sourceId'))
        );

        // TODO(fiona): Do we need a specific experience around scenes that are
        // pending processing?
        if (sceneToOpen && !sceneToOpen.get('isOrdered')) {
          openOrderImageryModal(sceneToOpen);
        }
      }

      setOrderableSceneImageRef(null);
    }
  }, [orderableScenes, orderableSceneImageRef, openOrderImageryModal]);

  return null;
};

export default UrlSceneHandler;
