import * as B from '@blueprintjs/core';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import LayerLegend from 'app/components/LayerLegend';
import DraggableMapOverlayDialog from 'app/components/MapOverlayDialog/DraggableMapOverlayDialog';
import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {
  OverlayMaskSource,
  PolygonDispatch,
  useOverlayMask,
} from 'app/providers/MapPolygonStateProvider';
import {OverlayMaskState} from 'app/providers/MapPolygonStateProvider';
import {TileData, getFeatureDataHasThumbnailUrls} from 'app/stores/RasterCalculationStore';
import {StatusMaybe} from 'app/utils/hookUtils';
import * as layers from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import LegendThresholdMaskSlider from './LegendThresholdMaskSlider';
import WithWidth from '../WithWidth';
import cs from './LegendWithSlider.styl';
import {fetchTileData} from '../AnalyzePolygonPopup/utils';

const X_PADDING = 10;
const Y_PADDING = 26;
const WIDTH = 280;

// This was forked from DraggableLegend (which simply renders LayerLegend), which is lightweight and
// can be used anywhere including PublicMap, compared to this which calculates threshold masks so users
// can apply overlay threshold maps to the map from the legend using a slider.
export default function DraggableLegendWithSlider({
  imageRefs,
  layer,
  index,
  offset = {},
  firebaseToken,
  featureData,
  polygonDispatch,
  polygon,
  overlayMask,
}: {
  imageRefs: mapUtils.MapImageRefs;
  layer: layers.LayerInfo;
  index: number;
  offset?: {right?: number; bottom?: number; left?: number};
  firebaseToken: string | null;
  featureData: I.ImmutableListOf<ApiFeatureData>;
  polygonDispatch: PolygonDispatch;
  polygon: geojson.Polygon | geojson.MultiPolygon | null;
  overlayMask: OverlayMaskState | null;
}) {
  const [editingOverlayMask, setEditingOverlayMask] = React.useState(false);

  // prevent editing overlay mask if there's already a mask coming from somewhere else
  const preventMaskEdits = !!overlayMask && overlayMask.source !== OverlayMaskSource.MAP;
  // don't show overlay mask filter for layers that don't support masks
  const hideFilter = layer.resolutionCategory !== 'higher-frequency';

  const tileData: StatusMaybe<TileData> = React.useMemo(() => {
    return fetchTileData(layer.key, polygon, featureData);
  }, [layer.key, polygon, featureData]);

  const hasThumbnailUrls = React.useMemo(
    () =>
      !!layer.key &&
      featureData &&
      getFeatureDataHasThumbnailUrls(featureData, layerUtils.getRawLayerKey(layer.key)),
    [featureData, layer.key]
  );

  const [updateOverlayMask, clearOverlayMask, isCalculating] = useOverlayMask(
    firebaseToken,
    polygonDispatch,
    imageRefs,
    featureData,
    'date', // cursorKey
    null, // graphMode
    layer.key,
    polygon,
    tileData, // masks won't be set if tileData.status was error or unknown
    hasThumbnailUrls,
    OverlayMaskSource.MAP
  );

  let dataLayerInfo: layers.DataLayerInfo | undefined;
  let imageLayerInfo: layers.ImageLayerInfo | undefined;

  if (layer.type === 'data') {
    dataLayerInfo = layer;
  } else if (layer.type === 'image') {
    imageLayerInfo = layer;
  }

  const [currentOverlayThreshold, setCurrentOverlayThreshold] = React.useState<
    [number, number] | null
  >(null);

  React.useEffect(() => {
    // if we're editing but the user hasn't set a threshold, use the default for that layer
    if (dataLayerInfo && editingOverlayMask && !currentOverlayThreshold) {
      setCurrentOverlayThreshold(dataLayerInfo.dataRange as [number, number]);
    }
  }, [dataLayerInfo, editingOverlayMask]);

  React.useEffect(() => {
    // whenever imageRefs update, update the masks and show them on the map
    if (currentOverlayThreshold) {
      updateOverlayMask({type: 'threshold', threshold: currentOverlayThreshold});
    }
  }, [imageRefs, updateOverlayMask]);

  React.useEffect(() => {
    // if the overlayMasks's layerKey updates to not match the slider's expected key (ie in
    // compare mode viewing two different layers), or we otherwise should not see the slider,
    // set edit mode to false.
    if (preventMaskEdits || overlayMask?.layerKey !== layer.key) {
      setEditingOverlayMask(false);
    }
  }, [preventMaskEdits, overlayMask?.layerKey]);

  if (
    (!dataLayerInfo && !imageLayerInfo) ||
    (dataLayerInfo && !dataLayerInfo.dataLabels) ||
    (dataLayerInfo && !dataLayerInfo.gradientStops) ||
    (imageLayerInfo && !imageLayerInfo.renderLayerLegend)
  ) {
    return null;
  }

  // calculate initial position for the DraggableMapOverlayDialog
  let isPosRight = true;
  if (imageRefs.length === 2) {
    if (imageRefs[0].layerKey !== imageRefs[1]!.layerKey) {
      // if we are in compare mode and the layers aren't the same
      isPosRight = index !== 0;
    } else if (index === 1) {
      // layer keys are the same and we are rendering the second one, skip it
      return null;
    }
  }

  offset = {right: 0, bottom: 0, left: 0, ...offset};

  return (
    <DraggableMapOverlayDialog
      isCollapsable={true}
      isCurrentlyDraggable={!editingOverlayMask}
      initialPosition={
        isPosRight
          ? {bottom: Y_PADDING + offset.bottom!, right: X_PADDING + offset.right!}
          : {bottom: Y_PADDING + offset.bottom!, left: X_PADDING + offset.left!}
      }
      style={{width: WIDTH, maxWidth: '45%'}} // magic width that feel good, max-width for public maps on mobile
      title={(dataLayerInfo || imageLayerInfo)!.shortName}
    >
      <WithWidth>
        {(width) => {
          //standard blueprint buttons are 30px
          const widthWithFilter = width - 30;
          if (dataLayerInfo) {
            return (
              <div className={cs.legendContainer}>
                <div className={cs.legendAndFilter}>
                  <LayerLegend
                    gradientStops={dataLayerInfo.gradientStops}
                    dataLabels={dataLayerInfo.dataLabels}
                    //allow room for our overlay mask toggle button,
                    width={hideFilter ? width : widthWithFilter}
                    hideLabels={editingOverlayMask && !preventMaskEdits && !hideFilter}
                  />
                  {!hideFilter && (
                    <B.Popover
                      content={
                        <div style={{padding: '1rem'}}>
                          {preventMaskEdits ? 'Filter already applied' : 'Range filter'}
                        </div>
                      }
                      interactionKind={B.PopoverInteractionKind.HOVER}
                      minimal
                      position={B.PopoverPosition.LEFT_BOTTOM}
                    >
                      {isCalculating ? (
                        <B.Spinner size={24} />
                      ) : (
                        <B.Button
                          minimal
                          disabled={preventMaskEdits}
                          icon={
                            // if there's an overlay mask, but not for the layer that THIS legend is
                            //displaying (eg in compare mode), don't show filter-keep or active
                            overlayMask && overlayMask.layerKey === layer.key && !preventMaskEdits
                              ? 'filter-keep'
                              : 'filter'
                          }
                          active={editingOverlayMask && overlayMask?.layerKey === layer.key}
                          onClick={() => {
                            setEditingOverlayMask((prev) => !prev);
                          }}
                        ></B.Button>
                      )}
                    </B.Popover>
                  )}
                </div>
                <div className={cs.sliderContainer}>
                  {editingOverlayMask &&
                    currentOverlayThreshold &&
                    !preventMaskEdits &&
                    !hideFilter && (
                      <LegendThresholdMaskSlider
                        layer={layer as layers.DataLayerInfo}
                        dataRange={dataLayerInfo.dataRange}
                        overlayThreshold={currentOverlayThreshold}
                        setOverlayThreshold={setCurrentOverlayThreshold}
                        width={width - 30}
                        imageRefs={imageRefs}
                        firebaseToken={firebaseToken}
                        featureData={featureData}
                        polygon={polygon}
                        polygonDispatch={polygonDispatch}
                        updateOverlayMask={updateOverlayMask}
                      />
                    )}
                  {editingOverlayMask && !preventMaskEdits && !hideFilter && (
                    <B.Popover
                      content={<div style={{padding: '1rem'}}>Clear filter</div>}
                      interactionKind={B.PopoverInteractionKind.HOVER}
                      minimal
                      position={B.PopoverPosition.LEFT_BOTTOM}
                    >
                      <B.Button
                        minimal
                        icon="cross"
                        disabled={overlayMask?.layerKey !== layer.key}
                        onClick={() => {
                          setCurrentOverlayThreshold(null);
                          clearOverlayMask();
                          setEditingOverlayMask(false);
                        }}
                      ></B.Button>
                    </B.Popover>
                  )}
                </div>
              </div>
            );
          }
        }}
      </WithWidth>
      {imageLayerInfo && imageLayerInfo.renderLayerLegend!()}
    </DraggableMapOverlayDialog>
  );
}
