import * as B from '@blueprintjs/core';
import classNames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';
import MultiLineEllipsis from 'react-dotdotdot';
import {Link} from 'react-router-dom';

import Omnisearch from 'app/components/Omnisearch';
import StaticMap from 'app/components/StaticMap';
import {api} from 'app/modules/Remote';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {useProjects} from 'app/providers/ProjectsProvider';
import * as C from 'app/utils/constants';
import {findPrimaryFeatureCollection} from 'app/utils/featureCollectionUtils';
import {alphanumericSort} from 'app/utils/featureUtils';
import {makeProjectDashboardUrl, makeUuidPrefix} from 'app/utils/routeUtils';

import cs from './styles.styl';

const PortfoliosView: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    history: History;
    profile: I.ImmutableOf<ApiOrganizationUser>;
  }>
> = ({organization, history, profile}) => {
  const [projectsMap, featureCollections, {updateProject, deleteProject}, meta] = useProjects();
  const isLoading = meta.loading;
  const projects = React.useMemo(
    () => (projectsMap || I.OrderedMap()).valueSeq().toList(),
    [projectsMap]
  );

  const sortedProjects = projects
    .sortBy((project) => project!.get('name'), alphanumericSort)
    .toList();

  return (
    <div className={cs.portfoliosSection}>
      <div className={cs.header}>
        <h2 className={cs.headerText}>Portfolios</h2>
        {projects && projects.size > 0 && (
          <Omnisearch
            isOpen={true}
            history={history}
            searchFeatures={api.searchFeatures}
            projects={projects}
            staticSearchbar={true}
            placeholderText={'Search all portfolios and properties'}
          />
        )}
      </div>

      <div className={cs.portfoliosContainer}>
        {/* NEW PORTFOLIO BUTTON: */}
        {profile.get('role') !== C.USER_ROLE_READONLY && (
          <div
            className={classNames(cs.addPortfolioCard, cs.basePortfolioCard)}
            onClick={() =>
              history.push(`/${makeUuidPrefix(organization.get('id'))}/admin/manageProperties/`)
            }
          >
            <div className={cs.addPortfolioIcon}>
              <B.Icon icon="plus" intent="primary" size={28} />
            </div>
            <div className={cs.portfolioName}>
              {profile.get('role') !== 'readonly' && 'Add a portfolio'}
            </div>
          </div>
        )}
        <>
          {isLoading
            ? skeletonTag()
            : sortedProjects.map((project) => {
                const featureCollection = findPrimaryFeatureCollection(
                  project!,
                  featureCollections
                );
                return (
                  <PortfolioCard
                    key={project!.get('id')}
                    project={project!}
                    organization={organization}
                    history={history}
                    profile={profile}
                    featureCollection={featureCollection}
                    updateProject={updateProject}
                    deleteProject={deleteProject}
                  />
                );
              })}
        </>
      </div>
    </div>
  );
};

const PortfolioCard: React.FunctionComponent<
  React.PropsWithChildren<{
    project: I.MapAsRecord<I.ImmutableFields<ApiProject>>;
    organization: I.ImmutableOf<ApiOrganization>;
    history: History;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    featureCollection: I.MapAsRecord<I.ImmutableFields<ApiFeatureCollection>> | null;
    updateProject: (id: string, p: Partial<ApiProject>) => void | Promise<void>;
    deleteProject: (id: string) => void | Promise<void>;
  }>
> = ({
  project,
  history,
  organization,
  profile,
  featureCollection: fc,
  updateProject,
  deleteProject,
}) => {
  const status = fc?.get('status');
  const isProcessing =
    status === 'waiting-for-upload' ||
    status === 'waiting-for-processing' ||
    status === 'waiting-for-update-processing';

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const renderActionsMenu = (project: I.MapAsRecord<I.ImmutableFields<ApiProject>>) => {
    const projectId = project.get('id');
    const projectIdPrefix = makeUuidPrefix(projectId);
    const orgIdPrefix = makeUuidPrefix(organization.get('id'));
    const role = profile.get('role');
    return (
      <B.Menu onClick={(e) => e.stopPropagation()}>
        <B.MenuItem
          onClick={() => {
            const newName = prompt('Enter a new name for this project', project.get('name'));
            if (newName) {
              updateProject(projectId, {name: newName});
            }
          }}
          text="Rename"
        />
        <B.MenuItem
          onClick={() => {
            history.push(`/${orgIdPrefix}/admin/manageProperties/${projectIdPrefix}`);
          }}
          text="Add Properties"
        />
        {role !== C.USER_ROLE_READONLY && (
          <B.MenuItem
            onClick={() => {
              history.push(`/${orgIdPrefix}/admin/updateProperties/${projectIdPrefix}`);
            }}
            text="Update Properties"
          />
        )}
        {role === C.USER_ROLE_OWNER && (
          <B.MenuItem
            onClick={() => {
              if (
                confirm(
                  `Are you sure you want to delete ${project.get(
                    'name'
                  )} portfolio? This will also delete any properties in your portfolio.`
                )
              ) {
                deleteProject(projectId);
              }
            }}
            text="Delete Portfolio"
          />
        )}
      </B.Menu>
    );
  };

  const renderThumbnail = () => {
    if (isProcessing) {
      return (
        <div className={classNames(cs.portfolioLoading, cs.portfolioThumbnail)}>
          <B.Tooltip content="Imagery is loading">
            <B.Icon icon="time" intent="warning" size={28} />
          </B.Tooltip>
        </div>
      );
    } else if (fc?.get('status') === 'failed') {
      return (
        <div className={classNames(cs.portfolioLoading, cs.portfolioThumbnail)}>
          <B.Tooltip
            content="There was an error processing imagery for your portfolio. Please email us at
                    lens@upstream.tech for support."
          >
            <B.Icon icon="warning-sign" intent="warning" size={28} />
          </B.Tooltip>
        </div>
      );
    } else {
      return (
        <div className={cs.portfolioThumbnail}>
          {fc ? <StaticMap featureCollection={fc} /> : <StaticMap />}
        </div>
      );
    }
  };

  return (
    <Link
      key={project!.get('id')}
      className={classNames(cs.portfolioCard, cs.basePortfolioCard)}
      to={makeProjectDashboardUrl(organization, project!)}
    >
      <div key={project!.get('id')} className={cs.portfolioCardContent}>
        {renderThumbnail()}
        <div className={cs.portfolioName}>
          <MultiLineEllipsis clamp={3}>{project!.get('name')}</MultiLineEllipsis>
        </div>
      </div>

      <div className={cs.portfolioMenu}>
        <B.Popover
          content={renderActionsMenu(project!)}
          minimal
          position={'bottom'}
          isOpen={isPopoverOpen}
          interactionKind="click"
          onInteraction={(nextOpenState) => setIsPopoverOpen(nextOpenState)}
        >
          <B.AnchorButton
            icon="more"
            intent="none"
            minimal
            small
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsPopoverOpen((isOpen) => !isOpen);
            }}
          />
        </B.Popover>
      </div>
    </Link>
  );
};

const skeletonTag = () => {
  return (
    <div
      key={'test'}
      className={classNames('bp5-skeleton', cs.portfolioCard, cs.basePortfolioCard)}
    />
  );
};
export default PortfoliosView;
