import {flatten, kinks} from '@turf/turf';
import geojson from 'geojson';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {DrawingMode} from 'app/components/DeclarativeMap/DrawLayer';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {featureM2ToArea} from 'app/utils/featureUtils';
import {UTMArea} from 'app/utils/geoJsonUtils';

import {
  NumberedFeature,
  NumberedFeatureCollection,
  useManageFeatures,
} from './ManageFeaturesProvider';
import ManageFeaturesView from './ManageFeaturesView';

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
  firebaseToken: string | null;
}

export interface DrawPropertiesState {
  featureToEdit: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties> | null;
  setFeatureToEdit: React.Dispatch<
    React.SetStateAction<geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties> | null>
  >;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  // This is used by parcel mode - throwing this in here isn't the best, but at least the other modes won't
  // have to know about it and parcel mode is really just a variation on draw
  onMapFeatureClick?: (
    f: geojson.Feature<geojson.Polygon | geojson.MultiPolygon>,
    ev: mapboxgl.MapLayerMouseEvent
  ) => void;
  drawingMode?: DrawingMode;
  setDrawingMode?: React.Dispatch<React.SetStateAction<DrawingMode>>;
}

const ManagePropertiesDraw: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
  firebaseToken,
}) => {
  const {manageFeaturesState, manageFeaturesActions, loading} = useManageFeatures();

  const [featureToEdit, setFeatureToEdit] = React.useState<null | geojson.Feature>(null);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [drawingMode, setDrawingMode] = React.useState<DrawingMode>('draw_polygon');

  const {setUploadedFeatureCollection} = manageFeaturesActions;

  // Handles adding a new feature and editing an existing feature
  React.useEffect(() => {
    if (featureToEdit) {
      setUploadedFeatureCollection((fc) => {
        const currentFeatureCollection: NumberedFeatureCollection = fc || {
          type: 'FeatureCollection',
          features: [],
        };

        const featureProblems = validateDrawnProperty(featureToEdit);

        // editing an existing feature
        if (currentFeatureCollection.features.find((f) => f.id === featureToEdit.id)) {
          const newFC: NumberedFeatureCollection = {
            ...currentFeatureCollection,
            features: fc!.features.map((f) => {
              if (f.id === featureToEdit.id) {
                const updatedFeature: NumberedFeature = {
                  ...f,
                  properties: {
                    name: f.properties?.name,
                    __UPSTREAM_PROBLEMS: featureProblems.length ? featureProblems : null,
                  },
                  geometry: featureToEdit.geometry,
                };
                return updatedFeature;
              }
              return f;
            }),
          };
          return newFC;
        }
        // adding a new feature
        else {
          // Get whatever the last feature's id was and add 1 to it. Can't rely just on using length
          // since features can be deleted
          const id = currentFeatureCollection.features.length
            ? currentFeatureCollection.features[currentFeatureCollection.features.length - 1].id + 1
            : 1;
          const newFeature: NumberedFeature = {
            ...featureToEdit,
            id: id,
            properties: {
              name: `New Property ${id}`,
              __UPSTREAM_PROBLEMS: featureProblems.length ? featureProblems : null,
            },
          };
          const newFc: NumberedFeatureCollection = {
            ...currentFeatureCollection,
            features: [...currentFeatureCollection.features, newFeature],
          };
          return newFc;
        }
      });
    }
  }, [featureToEdit, setFeatureToEdit, setUploadedFeatureCollection]);

  const drawPropertiesState: DrawPropertiesState = {
    editMode: editMode,
    setEditMode: setEditMode,
    featureToEdit: featureToEdit,
    setFeatureToEdit: setFeatureToEdit,
    drawingMode: drawingMode,
    setDrawingMode: setDrawingMode,
  };

  return (
    <ManageFeaturesView
      history={history}
      profile={profile}
      organization={organization}
      manageFeaturesState={manageFeaturesState}
      manageFeaturesActions={manageFeaturesActions}
      loggedInUserActions={loggedInUserActions}
      mode={'draw'}
      drawPropertiesState={drawPropertiesState}
      loading={loading}
      firebaseToken={firebaseToken}
    />
  );
};

export const validateDrawnProperty = (
  featureToEdit: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties>
) => {
  // validate drawn properties and surface errors if needed
  const featureProblems: string[] = [];

  const featureArea = featureM2ToArea(UTMArea(featureToEdit), {unit: 'areaAcre'});

  if (featureArea > 500000) {
    featureProblems.push('Property is too large. Max size: 500,000 Acres');
  }

  const featureGeometry: geojson.Polygon | geojson.MultiPolygon = featureToEdit.geometry as
    | geojson.Polygon
    | geojson.MultiPolygon;

  // Flatten polygon or multipolygon into a feature collection of polygons to look
  // for self intersections in the polygons. We only care about kinks within
  // an individual polygon, but turf/kinks is more strict and checks intersections
  // between any parts of the multipolygon. Flattening and calling kinks on each polygon
  // lets us be more flexible but still safe here.
  const flattenedFeatureCollection = flatten(featureGeometry);
  const selfIntersectingPoints = flattenedFeatureCollection.features.reduce(
    (points, f) => points + kinks(f).features.length,
    0
  );

  if (selfIntersectingPoints > 0) {
    featureProblems.push('Boundary lines cross each other.');
  }

  return featureProblems;
};

export default ManagePropertiesDraw;
