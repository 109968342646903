import * as B from '@blueprintjs/core';
import React from 'react';

import {ToastNotification} from 'app/modules/Notification';

export type PushNotificationFn = (n: ToastNotification) => void;

const NotificationContext = React.createContext<ToastNotification | null>(null);
const PushNotificationContext = React.createContext<PushNotificationFn | null>(null);

export const PushNotificationContextForTest = PushNotificationContext;

export function usePushNotification(): PushNotificationFn {
  const pushNotification = React.useContext(PushNotificationContext);

  if (!pushNotification) {
    throw new Error('usePushNotification called without PushNotificationProvider');
  }

  return pushNotification;
}

export const PushNotificationProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [notification, setNotification] = React.useState<ToastNotification | null>(null);

  return (
    <PushNotificationContext.Provider value={setNotification}>
      <NotificationContext.Provider value={notification}>{children}</NotificationContext.Provider>
    </PushNotificationContext.Provider>
  );
};

/**
 * Component that displays a push notification toast on mount.
 */
export const ShowPushNotification: React.FunctionComponent<
  React.PropsWithChildren<{
    message: string;
  }>
> = ({message}) => {
  const pushNotification = usePushNotification();

  React.useEffect(() => {
    pushNotification({message, autoHideDuration: 3000});
  }, []);

  return null;
};

const PushNotification: React.FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const notification = React.useContext(NotificationContext);
  const toasterRef = React.useRef<B.OverlayToaster>(null);

  React.useEffect(() => {
    if (notification) {
      const toaster = toasterRef.current;
      const {message, autoHideDuration, options = {}, shouldClearPrevToast = true} = notification;

      toaster?.show({
        message,
        timeout: autoHideDuration,
        ...options,
      });

      return () => {
        if (shouldClearPrevToast) {
          toaster?.clear();
        }
      };
    }
  }, [notification]);

  return <B.OverlayToaster position={B.Position.TOP} ref={toasterRef} />;
};

export default PushNotification;
