import * as Sentry from '@sentry/react';
import {History} from 'history';
import React from 'react';

import {useAuth} from 'app/providers/AuthProvider';
import {gitSha, isProd, nodeEnv} from 'app/utils/envUtils';
import {useHistoryState} from 'app/utils/hookUtils';
import {isUpstreamEmail} from 'app/utils/userUtils';

// https://upstream-ne.sentry.io/projects/app/?project=1309259
const APP_SENTRY_DSN =
  'https://94c2e5e27f524d928f27be7d174e2452@o198815.ingest.us.sentry.io/1309259';
// https://upstream-ne.sentry.io/projects/js-test/?project=1313922
const JS_TEST_SENTRY_DSN =
  'https://ca368b55378d4c90bcb27a7ebc187c4f@o198815.ingest.us.sentry.io/1313922';

// Set this to true if you want to capture all events of yourself.
const DEV_TEST_MODE = false;

export function initSentry(history: History) {
  if (isProd || DEV_TEST_MODE) {
    Sentry.init({
      dsn: DEV_TEST_MODE ? JS_TEST_SENTRY_DSN : APP_SENTRY_DSN,
      environment: nodeEnv,
      release: gitSha,
      tracesSampler: (samplingContext) => {
        if (samplingContext.parentSampled !== undefined) {
          return samplingContext.parentSampled;
        }
        const userEmail = Sentry.getCurrentScope().getUser()?.email;

        // Always send sessions for Upstream users to Sentry to make it easier to debug
        // issues that G&P/etc see.
        if (isUpstreamEmail(userEmail || null)) {
          return 1;
        }

        return 0.2; // default
      },
      // Sample 10% of non-error sessions and 100% of error sessions
      replaysSessionSampleRate: DEV_TEST_MODE ? 1.0 : 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history: history,
        }),
        Sentry.thirdPartyErrorFilterIntegration({
          // Specify the application keys that you specified in the Sentry bundler plugin
          filterKeys: ['upstream-lens-app'],
          // Defines how to handle errors that contain third party stack frames.
          // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
          behaviour: 'drop-error-if-contains-third-party-frames',
        }),
        // TODO (maya): uncomment this and turn it on. look into performance more closely
        // Sentry.replayCanvasIntegration(),
      ],
      beforeSend: (event, hint) => {
        // Filter out alerts handled by the 404 page.
        const originalException: any = hint?.originalException;

        if (!originalException || typeof originalException === 'string') {
          return event;
        }

        if (originalException.message === 'Not found') {
          return null;
        }

        // Filter out third-party library errors. `exceptions` are lists of
        // keywords where all of the keywords must be present in the alert stack
        // in order to be filtered out.
        const exceptions = [
          // mapbox-gl
          {
            message: "Cannot read property 'get' of undefined",
            name: 'TypeError',
            stack: ['queryIntersectsFeature'],
          },
          // Edge version of the above message
          {
            message: "Unable to get property 'get' of undefined or null reference",
            name: 'TypeError',
            stack: ['queryIntersectsFeature'],
          },
          {
            message: "Cannot read property 'get' of undefined",
            name: 'TypeError',
            stack: ['queryRadius'],
          },

          // Intentionally leaving off the parent object from `paint` since
          // this varies (`this.`, `t.`, `r.`, etc).
          {message: 'paint is undefined', name: 'TypeError', stack: ['queryRadius']},

          {
            message: "undefined is not an object (evaluating 'this.paint.get')",
            name: 'TypeError',
            stack: ['queryRenderedFeatures'],
          },
          // alternate version, which doesn't have anything useful in the stack
          {
            message: "undefined is not an object (evaluating 't.paint.get')",
            name: 'TypeError',
            stack: [],
          },
          {
            // Leaving off getActor parent object since it varies between
            // `e.getActor` and `t.getActor`
            message: 'getActor(...) is undefined',
            name: 'TypeError',
            stack: ['cacheEntryPossiblyAdded'],
          },

          // Intentionally leaving off the parent object from `.paint` since
          // this varies (`this`, `t`, `r`, etc).
          //
          // Filters out alerts like the following:
          // * https://sentry.io/organizations/upstream-ne/issues/1840808475
          // * https://sentry.io/organizations/upstream-ne/issues/1840789692
          {
            message: 'paint is undefined',
            name: 'TypeError',
            stack: ['queryIntersectsFeature'],
          },
          // Alternate version, except when `setFeatureState` appears in the
          // stack trace.
          //
          // Filters out alerts like the following:
          // * https://sentry.io/organizations/upstream-ne/issues/2068917798
          {
            message: 'paint is undefined',
            name: 'TypeError',
            stack: ['setFeatureState'],
          },

          // Filters out alerts like the following:
          // * https://sentry.io/organizations/upstream-ne/issues/2068799695
          {
            message: 'undefined has no properties',
            name: 'TypeError',
            stack: ['queryIntersectsFeature', 'loadMatchingFeature', 'queryRenderedFeatures'],
          },

          // Filters out alerts like the following:
          // * https://sentry.io/organizations/upstream-ne/issues/2252563593
          {
            message: 'this.expression.evaluate is not a function',
            name: 'TypeError',
            stack: ['setFeatureState', 'updatePaintArray'],
          },

          // Google Chrome browser extensions
          {
            message: 'chromestatus',
            name: 'ReportingObserver',
            stack: [],
          },
          // Edge legacy issues with working across windows (report generation)
          {
            message: 'The remote server machine does not exist or is unavailable',
            name: 'Error',
            stack: [],
          },
          // If the user cancelled an operation, we don’t care. This comes up in
          // Edge Legacy.
          {
            message: 'The operation was canceled by the user',
            name: 'Error',
            stack: [],
          },
          // Noisy non actionable error
          {
            message: 'Non-Error promise rejection captured with value',
            name: 'UnhandledRejection',
            stack: [],
          },
          // Noisy non actionable error
          {
            message: 'Failed to fetch',
            name: 'TypeError',
            stack: [],
          },
        ];

        if (
          exceptions.some(
            (exception) =>
              originalException.message?.includes(exception.message) &&
              originalException.name?.includes(exception.name) &&
              exception.stack.every((stackKeyword) =>
                originalException.stack?.includes(stackKeyword)
              )
          )
        ) {
          return null;
        }

        return event;
      },
    });
  }
}

/**
 * Ensures that the current user / email is sent in the Sentry scope.
 */
export const SentryUserListener: React.FunctionComponent = () => {
  const auth = useAuth();

  const profile = auth.status === 'logged in' && auth.profile;
  const organization = auth.status === 'logged in' && auth.currentOrganization;

  React.useEffect(() => {
    if (!profile) {
      return;
    }

    const email = profile.get('email');
    const id = profile.get('id');
    const organizationKey = organization && organization.get('id');
    const organizationName = organization ? organization.get('name') : 'organization not set';

    Sentry.setUser({email, id});
    Sentry.setExtra('organization id', organizationKey);
    Sentry.setExtra('organization name', organizationName);

    return () => {
      Sentry.getCurrentScope().clear();
    };
  }, [profile, organization]);

  return null;
};

export const SentrySessionReplayListener: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
  }>
> = ({history}) => {
  const auth = useAuth();
  const profile = auth.status === 'logged in' && auth.profile;

  // We need to track whether we have already initalized sentry Replay in browser history to prevent
  // us from trying to re-initalize it again. Sentry currently has no support for checking if
  // an integration is enabled, or disabling an integration. The useEffect below has no
  // dependencies, but still can run more than once becuase this component is unmounted and remounted each
  // time auth is updated. And since we need the profile's email from auth, we need access to
  // auth but can't re-add the integration when it changes. So useHistoryState is our best solution
  // here to prevent addIntegration from getting called more than once.
  const [getHistoryState, setHistoryState] = useHistoryState(history, 'sentryState', {
    sentryReplayInitalized: false,
  });

  React.useEffect(() => {
    if (!profile) {
      return;
    }

    const email = profile.get('email');

    // Don't record replay sessions of ourselves, unless we are testing
    if ((!isUpstreamEmail(email) || DEV_TEST_MODE) && !getHistoryState('sentryReplayInitalized')) {
      try {
        Sentry.addIntegration(
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            // This is the default but I'm setting just to explicitly call out here
            // that we aren't recording any user inputs
            maskAllInputs: true,
          })
        );
        setHistoryState('sentryReplayInitalized', true);
      } catch (_e) {
        // It's possible something isn't persisted in history's storage and we try
        // to add the integration again. Don't let this crash the app and don't
        // report to Sentry
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return null;
};
