import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';
import {SwatchesPicker} from 'react-color';
import tinycolor from 'tinycolor2';

import PolygonFilterIcon from 'app/components/DeclarativeMap/PolygonFilterIcon';

import * as cs from './SwatchColorPicker.styl';

const HUES = [0, 36, 64, 125, 172, 213, 271, 302];

const COLOR_GROUPS = HUES.map((hue) => [
  tinycolor({h: hue, s: 1, l: 0.35}).toHexString(),
  tinycolor({h: hue, s: 0.75, l: 0.55}).toHexString(),
  tinycolor({h: hue, s: 1, l: 0.7}).toHexString(),
]);

export const DEFAULT_COLORS = [
  ...COLOR_GROUPS.map((g) => g[1]),
  ...COLOR_GROUPS.map((g) => g[0]),
  ...COLOR_GROUPS.map((g) => g[2]),
];

/**
 * Component that renders a color swatch that, when clicked, pops up a color
 * picker of swatches.
 */
const SwatchColorPicker: React.FunctionComponent<
  React.PropsWithChildren<{
    color: string;
    setColor: (c: string) => void;
    fill?: boolean;
    setFill?: (f: boolean) => void;
    /** Pixel size of the color control. */
    size?: number;
    /** If true, popover closes when a color is picked. */
    closeOnSelect?: boolean;
    position?: B.PopoverPosition;
    /** Polygon icon used for overlay customization but not for tags */
    polygon?: boolean;
    onClosed?: () => void;
    popoverRef?: React.MutableRefObject<HTMLElement | null>;
  }>
> = ({
  color,
  setColor,
  fill,
  setFill,
  closeOnSelect,
  size = 24,
  position = 'left',
  polygon = false,
  onClosed,
  popoverRef,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // Swatch button. We grab this ref so we can focus it when the popover closes,
  // to preserve keyboard navigation.
  const buttonRef = React.useRef<HTMLElement | null>(null);

  // This component is used for tags as well where a fill parameter is not passed in. In this
  // case show button as filled, not outline.
  const displayFill = fill || fill === undefined;

  return (
    <B.Popover
      className={cs.swatchPopover}
      position={position}
      // Allows popper.js to position the popover outside of the dialog’s bounds
      modifiers={{preventOverflow: {enabled: false}}}
      // We want a controlled state so that we can close it ourselves when
      // closeOnSelect is true. Using onInteraction preserves the default
      // Popover open/close on clicking the button or clicking outside of the
      // popover.
      isOpen={isOpen}
      onInteraction={(isOpen, ev) => {
        setIsOpen(isOpen);
        // Keeps e.g. Escape key from closing the parent dialog as well.
        ev?.stopPropagation();
      }}
      onClosed={() => {
        buttonRef.current?.focus();
        onClosed && onClosed();
      }}
      content={
        <div
          ref={(r) => {
            if (popoverRef) {
              popoverRef.current = r;
            }
          }}
        >
          {/* preventDefault to prevent clicks in the swatches from double-click selecting
      the contents of the dialog. This kind of works, but there might be a better
      solution I'm not seeing */}
          <div className={cs.swatchesContainer} onClick={(ev) => ev.preventDefault()}>
            <SwatchesPicker
              // Tuned to fit 2 3-swatch rows of 4 columns.
              width={226}
              height={192}
              colors={COLOR_GROUPS}
              color={color}
              onChange={(color) => {
                if (closeOnSelect) {
                  setIsOpen(false);
                }

                setColor(color.hex);
              }}
            />
          </div>
          {setFill && fill !== undefined && (
            <div className={cs.fillSwitch}>
              <B.Switch checked={fill} label="Fill" onChange={() => setFill(!fill)} />
            </div>
          )}
        </div>
      }
    >
      {polygon ? (
        <PolygonFilterIcon color={color} fill={displayFill} size={24} />
      ) : (
        <B.AnchorButton
          ref={(el) => (buttonRef.current = el)}
          minimal
          className={classnames(cs.swatchButton, {
            [cs.outlineButton]: !displayFill,
            [cs.fillButton]: displayFill,
          })}
          style={{
            borderColor: displayFill ? 'transparent' : color,
            backgroundColor: displayFill ? color : 'transparent',
            minWidth: size,
            minHeight: size,
          }}
        />
      )}
    </B.Popover>
  );
};

export default SwatchColorPicker;
