import * as I from 'immutable';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

import {ApiOrganizationUser} from 'app/modules/Remote/Organization';

interface Props {
  roles: ApiOrganizationUser['role'][];
  profile: I.ImmutableOf<ApiOrganizationUser>;
}

export default class RequireRoleContainer extends Component<React.PropsWithChildren<Props>> {
  render() {
    const {profile, roles, children} = this.props;

    if (profile && roles.includes(profile.get('role'))) {
      return children;
    } else {
      return <Redirect to="/projects" />;
    }
  }
}
