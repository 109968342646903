import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {PortalControl} from 'app/utils/mapUtils';

import BearingIcon from './BearingIcon';
import MapContent, {SourceRecord, isMapReady} from './MapContent';
import * as cs from './TerrainControl.styl';

const ZERO_PITCH = 0;
const ZERO_BEARING = 0;

const TERRAIN_ID = 'mapbox-dem';
const DEFAULT_TERRAIN_PITCH = 60;
const TERRAIN_EXAGGERATION = 1;

const BEARING_STEP = 10;

const TERRAIN_SOURCE: SourceRecord = {
  id: TERRAIN_ID,
  source: {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 14,
  },
};

const TERRAIN_PROPERTIES: mapboxgl.TerrainSpecification = {
  source: TERRAIN_ID,
  exaggeration: TERRAIN_EXAGGERATION,
};

const TerrainControl: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;

    showTerrain: boolean;
    toggleShowTerrain: () => void;
    initialPitch?: number;
    initialBearing?: number;
  }>
> = ({
  map,
  isMapLoaded,
  showTerrain,
  toggleShowTerrain,
  initialPitch = DEFAULT_TERRAIN_PITCH,
  initialBearing = ZERO_BEARING,
}) => {
  const terrainControlEl = React.useRef(document.createElement('div'));
  const terrainControl = React.useMemo(
    () => new PortalControl(terrainControlEl.current),
    [terrainControlEl]
  );

  // Duplicate the map bearing value into state so that we can use it for
  // bearing button style and behavior.
  const [bearing, setBearing] = React.useState(map.getBearing());

  // Keep local state and map state bearing values insync.
  React.useEffect(() => {
    const updateBearing = () => {
      setBearing(map.getBearing());
    };

    map.on('moveend', updateBearing);

    return () => {
      map.off('moveend', updateBearing);
    };
  }, [map]);

  React.useEffect(() => {
    const updateTerrain = () => {
      map.setTerrain(showTerrain ? TERRAIN_PROPERTIES : null);
      if (showTerrain) {
        map.dragRotate.enable();
      } else {
        map.dragRotate.disable();
      }
    };

    // Update the map pitch and bearing.
    map.setPitch(showTerrain ? initialPitch : ZERO_PITCH);
    map.setMaxPitch(DEFAULT_TERRAIN_PITCH);
    map.setBearing(showTerrain ? initialBearing : ZERO_BEARING);

    // Update the terrain style property, if the style has already loaded.
    if (isMapReady(map)) {
      updateTerrain();
    }

    // Attach a function to update the terrain style property to the style load
    // event. This ensures that if the map style changes while the
    // TerrainControl tool is active (e.g., the basemap is changed), the terrain
    // style property is set once the style is finished loading.
    map.on('style.load', updateTerrain);

    return () => {
      map.off('style.load', updateTerrain);
    };
  }, [map, showTerrain, initialBearing, initialPitch]);

  // Unset the style‘s terrain property on unmount. This will run before the
  // terrain source is removed via the clean-up function in MapContent.
  React.useEffect(() => {
    return () => {
      if (isMapReady(map)) {
        map.setTerrain(null);
      }
    };
  }, [map]);

  return (
    <>
      {ReactDOM.createPortal(
        <B.ButtonGroup>
          {showTerrain && (
            <>
              <B.Tooltip content="Rotate counterclockwise" position="top-left">
                <B.AnchorButton
                  className={cs.btn}
                  icon={<B.Icon icon="reset" iconSize={14} />}
                  onClick={() => map.easeTo({bearing: bearing + BEARING_STEP})}
                />
              </B.Tooltip>

              <B.Tooltip content="Reset north" position="top-left">
                <B.AnchorButton
                  className={cs.btn}
                  // Using absolute value here since Mapbox will initialize the
                  // map with a bearing of -0 if a value is not provided.
                  disabled={Math.abs(bearing) === ZERO_BEARING}
                  icon={<BearingIcon style={{transform: `rotate(${bearing}deg)`}} />}
                  onClick={() => map.setBearing(ZERO_BEARING)}
                />
              </B.Tooltip>

              <B.Tooltip content="Rotate clockwise" position="top-left">
                <B.AnchorButton
                  className={cs.btn}
                  icon={<B.Icon icon="repeat" iconSize={14} />}
                  onClick={() => map.easeTo({bearing: bearing - BEARING_STEP})}
                />
              </B.Tooltip>
            </>
          )}

          <B.Tooltip
            content={`${showTerrain ? 'Hide' : 'Show'} 3D terrain`}
            position={!showTerrain ? 'left' : 'top-left'}
          >
            <B.AnchorButton
              className={cs.btn}
              icon={'mountain'}
              active={showTerrain}
              disabled={!isMapLoaded}
              onClick={() => toggleShowTerrain()}
            />
          </B.Tooltip>
        </B.ButtonGroup>,
        terrainControlEl.current
      )}

      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[terrainControl]}
        controlPosition="top-right"
        sources={[TERRAIN_SOURCE]}
      />
    </>
  );
};

export default TerrainControl;
