/**
 * IMPORTANT: This file is used by BOTH react and node scripts. Be careful when importing not to add any scripts that will
 * import frontend only packages or functions or cause circular imports. If you must, look at how the filters are imported
 * for `FeatureCollectionInBounds` and other properties. Managing imports like this allows the node scripts to run without
 * frontend dependencies.
 *
 * Example:
 * We have a node script that runs to sync these layers with our marketign site. We don't need the filters defined on the layers
 * but if we try to import them we start importing all of our other geo dependencies and other files which may also import
 * this file. Because of this, we define placeholder constants and functions, and conditionally initialize them when the script runs.
 */

import * as geojson from 'geojson';
import type {ImmutableOf} from 'immutable';
import {sortBy} from 'lodash';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import type {DataRange} from 'app/components/AnalyzePolygonChart/types';
import type {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';

import {
  CHLORIS_FOREST_VALUE_MAP,
  ESA_WORLDCOVER_VALUE_MAP,
  GLOBAL_FOREST_CHANGE_LOSSYEAR_VALUE_MAP,
  IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER_VALUE_MAP,
  IMPACT_OBSERVATORY_LANDCOVER_VALUE_MAP,
  LENS_TIERS_TYPE,
  LENS_TIER_ENTERPRISE,
  LENS_TIER_PLUS,
  MAPBIOMAS_BRAZIL_LANDCOVER_VALUE_MAP,
  MAPBIOMAS_CHACO_LANDCOVER_VALUE_MAP,
  NLCD_LANDCOVER_VALUE_MAP,
  SBTN_NATURAL_LANDS_CLASSIFICATION_VALUE_MAP,
  SPACE_INTELLIGENCE_HABITAT_10_CLASS_VALUE_MAP,
  SPACE_INTELLIGENCE_HABITAT_URBAN_VALUE_MAP,
  SPACE_INTELLIGENCE_HABITAT_VALUE_MAP,
  TMF_ANNUAL_CHANGE_VALUE_MAP,
} from './constants';
import {BRAZIL_SHAPE, CHACO_BBOX} from './geoJsonUtils';
import {
  BURN_GRADIENT_STOPS,
  CHLORIS_CARBON_STOCK_STOPS,
  COOL_GRADIENT_STOPS,
  COPPER_GRADIENT_STOPS,
  DEFAULT_GRADIENT_STOPS,
  GNBU_REVERSE_GRADIENT_STOPS,
  GREENS_GRADIENT_STOPS,
  GradientStops,
  MAGMA_GRADIENT_STOPS,
  NDVI_GRADIENT_STOPS,
  PLANET_FOREST_CARBON_CANOPY_COVER_STOPS,
  PLANET_FOREST_CARBON_CANOPY_HEIGHT_STOPS,
  S1_WATER_GRADIENT_STOPS,
  S2_NDWI_GRADIENT_STOPS,
  SALO_GREEN_VEGETATION_GRADIENT_STOPS,
  SEISMIC_R_GRADIENT_STOPS,
  SPACE_INTELLIGENCE_ABOVEGROUND_CARBON_GRADIENT_STOPS,
  VIRIDIS_GRADIENT_STOPS,
} from './gradientStops';

// Instead of direct imports, declare function types
let _featureCollectionInBounds: any = null;
let _featureCollectionInUS: any = null;

try {
  // In a browser/React context
  const utils = require('./featureCollectionUtils');

  _featureCollectionInBounds = utils.featureCollectionInBounds;
  _featureCollectionInUS = utils.featureCollectionInUS;
} catch (e) {
  // Fallback for Node.js - provide dummy implementations
  _featureCollectionInBounds = () => true;
  _featureCollectionInUS = () => true;
}

export const UPSTREAM_RASTER_PREFIX = 'FeatureRaster';

export const NONE = 'none';
// We use this new “ANY” synthetic layer key because it specifically does not
// come up in the API responses. This is a way of saying “we want high-res
// truecolor, but the source is currently undetermined.” This state is relevant
// during app startup, for example, before FeatureData has loaded and we have a
// specific high-res truecolor scene we’re showing.

export const ANY_TRUECOLOR_HIGH_RES = 'ANY_high-res-truecolor';
// TODO(emily): Delete after we’ve updated feature data fixtures (#5576) and
// consequently no longer use this layer in AnalyzePolygonTool.stories.tsx.
export const ALL_MNDWI = 'ALL_MNDWIw';

export const S2_NDVI = 'S2_NDVI';
export const S2_EVI = 'S2_EVI';
export const S2_NDCI = 'S2_NDCI';
export const S2_PHYCOCYANIN = 'S2_Phycocyanin';
export const S2_NDWI = 'S2_NDWIw';
export const S2_NDWI_MOISTURE = 'S2_NDWIw_moisture';
export const S2_NDWI_M = 'S2_NDWIm';
export const S2_NDSI = 'S2_NDSI';
export const S2_NBR = 'S2_NBR';
export const S2_NDBI = 'S2_NDBI';
export const S2_NDTI = 'S2_NDTI';
export const S2_TRUECOLOR = 'S2_truecolor';
export const S2_FALSECOLOR_SWIR_NIR_GREEN = 'S2_falsecolor_SWIR1_N_G';
export const S2_FALSECOLOR_BLUE_SWIR = 'S2_falsecolor_B_SWIR1_SWIR2';
export const S2_FALSECOLOR_NIR_RED_GREEN = 'S2_falsecolor_N_R_G';

export const L8_NDVI = 'L8_NDVI';
export const L8_NDWI = 'L8_NDWIw';
export const L8_NDSI = 'L8_NDSI';
export const L8_NBR = 'L8_NBR';
export const L8_NDBI = 'L8_NDBI';
export const L8_TRUECOLOR = 'L8_truecolor';
export const L8_FALSECOLOR_SWIR_NIR_GREEN = 'L8_falsecolor_SWIR1_N_G';
export const L8_FALSECOLOR_BLUE_SWIR = 'L8_falsecolor_B_SWIR1_SWIR2';
export const L8_FALSECOLOR_NIR_RED_GREEN = 'L8_falsecolor_N_R_G';

export const S1_WATER_CLASSIFICATION = 'S1_RADAR-WATER';

export const NLCD_LANDCOVER = 'NLCD_landcover';
export const ESA_WORLDCOVER_LANDCOVER = 'ESA-WORLDCOVER_landcover';
export const IMPACT_OBSERVATORY_LANDCOVER = 'IMPACT-OBSERVATORY-LANDCOVER_landcover';
export const IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER =
  'IMPACT-OBSERVATORY-HIGH-RES-LANDCOVER_landcover';
export const IMPACT_OBSERVATORY_BIODIVERSITY =
  'IMPACT-OBSERVATORY-BIODIVERSITY_biodiversity_intactness';
export const MAPBIOMAS_BRAZIL_LANDCOVER = 'MAPBIOMAS-BRAZIL-LANDCOVER_landcover';
export const MAPBIOMAS_CHACO_LANDCOVER = 'MAPBIOMAS-CHACO-LANDCOVER_landcover';
export const SBTN_NATURAL_LANDCOVER = 'SBTN-WRINLC_landcover';
export const TMF_ANNUAL_CHANGE_LANDCOVER = 'TMF-ANNUAL-CHANGE_landcover';

export const NAIP_HIGH_RES_TRUECOLOR = 'NAIP_high-res-truecolor';
export const NAIP_NDVI = 'NAIP_NDVI';
export const NAIP_NDWI = 'NAIP_NDWIw';
export const NAIP_NDWI_MOISTURE = 'NAIP_NDWIw_moisture';
export const NAIP_FALSECOLOR_NIR_RED_GREEN = 'NAIP_falsecolor_N_R_G';

export const METAWRI_CANOPY_HEIGHT = 'METAWRI-CANOPY-HEIGHT_CanopyHeight';

export const PLANET_FOREST_ABOVEGROUND_CARBON = 'PLANET-FOREST-CARBON-30_abovegroundcarbon';
export const PLANET_FOREST_CANOPY_HEIGHT = 'PLANET-FOREST-CARBON-30_canopyheight';
export const PLANET_FOREST_CANOPY_COVER = 'PLANET-FOREST-CARBON-30_canopycover';
export const PLANET_FOREST_ABOVEGROUND_CARBON_3M = 'PLANET-FOREST-CARBON-3_abovegroundcarbon';
export const PLANET_FOREST_CANOPY_COVER_3M = 'PLANET-FOREST-CARBON-3_canopycover';
export const PLANET_FOREST_CANOPY_HEIGHT_3M = 'PLANET-FOREST-CARBON-3_canopyheight';

export const CHLORIS_30_CARBON_STOCK = 'CHLORIS-30_stock';
export const CHLORIS_30_CARBON_STOCK_CHANGE = 'CHLORIS-30_change';
export const CHLORIS_30_FOREST_COVER = 'CHLORIS-30_forest';
export const CHLORIS_10_CARBON_STOCK = 'CHLORIS-10_stock';
export const CHLORIS_10_CARBON_STOCK_CHANGE = 'CHLORIS-10_change';
export const CHLORIS_10_FOREST_COVER = 'CHLORIS-10_forest';
// These are not real layers - but used by the layersLibrary to group together the above
// Planet forest carbon layers that can only be turned on/off as a group

export const PLANET_FOREST_DILIGENCE_PACKAGE = 'PLANET-FOREST-CARBON-30_package';
export const PLANET_FOREST_MONITORING_PACKAGE = 'PLANET-FOREST-CARBON-3_package';
export const CHLORIS_30_PACKAGE = 'CHLORIS-30_package';
export const CHLORIS_10_PACKAGE = 'CHLORIS-10_package';

export const PRISM_M_PRECIPITATION = 'PRISM-M_precipitation';
// These layers are used for Lens for Carbon

export const SALO_ABOVEGROUND_CARBON_30M = 'SALO_abovegroundcarbon';
export const SALO_CANOPY_COVER_30M = 'SALO_canopycover';
export const SALO_CANOPY_HEIGHT_30M = 'SALO_canopyheight';
export const SALO_ABOVEGROUND_CARBON_3M = 'SALO_abovegroundcarbon-03';
export const SALO_CANOPY_COVER_3M = 'SALO_canopycover-03';
export const SALO_CANOPY_HEIGHT_3M = 'SALO_canopyheight-03';
export const SPACE_INTELLIGENCE_CARBON = 'SPACE-INTELLIGENCE_Carbon';
export const SPACE_INTELLIGENCE_CARBON_TREE_CANOPY = 'SPACE-INTELLIGENCE_CarbonTreeCanopy';
export const SPACE_INTELLIGENCE_HABITAT = 'SPACE-INTELLIGENCE_Habitat';
export const SPACE_INTELLIGENCE_HABITAT_URBAN = 'SPACE-INTELLIGENCE_HabitatUrban';
export const SPACE_INTELLIGENCE_HABITAT_10_CLASS = 'SPACE-INTELLIGENCE_Habitat10Class';

export const AIRBUS_PLEIADES_HIGH_RES_TRUECOLOR = 'AIRBUS-PLEIADES_high-res-truecolor';
export const AIRBUS_PLEIADES_ARCHIVE_HIGH_RES_TRUECOLOR =
  'AIRBUS-PLEIADES-ARCHIVE_high-res-truecolor';
export const AIRBUS_SPOT_HIGH_RES_TRUECOLOR = 'AIRBUS-SPOT_high-res-truecolor';
export const AIRBUS_SPOT_ARCHIVE_HIGH_RES_TRUECOLOR = 'AIRBUS-SPOT-ARCHIVE_high-res-truecolor';
export const AIRBUS_PNEO_HIGH_RES_TRUECOLOR = 'AIRBUS-PNEO_high-res-truecolor';
export const AIRBUS_PNEO_ARCHIVE_HIGH_RES_TRUECOLOR = 'AIRBUS-PNEO-ARCHIVE_high-res-truecolor';
export const DIGITAL_GLOBE_50_HIGH_RES_TRUECOLOR = 'DIGITAL-GLOBE-50_high-res-truecolor';
export const DIGITAL_GLOBE_30_HIGH_RES_TRUECOLOR = 'DIGITAL-GLOBE-30_high-res-truecolor';
export const NEARMAP_15_HIGH_RES_TRUECOLOR = 'NEARMAP-15_high-res-truecolor';
export const NEARMAP_50_HIGH_RES_TRUECOLOR = 'NEARMAP-50_high-res-truecolor';
export const PLANET_BASEMAP_HIGH_RES_TRUECOLOR = 'PLANET-BASEMAP_high-res-truecolor';
export const PLANET_SKYSAT_COLLECT_HIGH_RES_TRUECOLOR = 'PLANET-SKYSAT-COLLECT_high-res-truecolor';
export const CHLORIS_ABOVEGROUND_WOODY_BIOMASS = 'CHLORIS-BIOMASS_aboveground-woody-biomass';
export const CHLORIS_CARBON_STOCK = 'CHLORIS_CarbonStock';
export const CHLORIS_FOREST_COVER = 'CHLORIS_ForestCover';
export const MOBI_SPECIES_RICHNESS_ALL = 'MOBI_SpeciesRichness-All';
export const GLOBAL_FOREST_CHANGE_LOSSYEAR = 'GLOBAL-FOREST-CHANGE_lossyear';

export const LAYER_PACKAGES = {
  [PLANET_FOREST_DILIGENCE_PACKAGE]: [
    PLANET_FOREST_ABOVEGROUND_CARBON,
    PLANET_FOREST_CANOPY_COVER,
    PLANET_FOREST_CANOPY_HEIGHT,
  ],
  [PLANET_FOREST_MONITORING_PACKAGE]: [
    PLANET_FOREST_ABOVEGROUND_CARBON_3M,
    PLANET_FOREST_CANOPY_COVER_3M,
    PLANET_FOREST_CANOPY_HEIGHT_3M,
  ],
  [CHLORIS_30_PACKAGE]: [
    CHLORIS_30_CARBON_STOCK,
    CHLORIS_30_CARBON_STOCK_CHANGE,
    CHLORIS_30_FOREST_COVER,
  ],
  [CHLORIS_10_PACKAGE]: [
    CHLORIS_10_CARBON_STOCK,
    CHLORIS_10_CARBON_STOCK_CHANGE,
    CHLORIS_10_FOREST_COVER,
  ],
};

export interface LayerTag {
  label: string;
  color: string;
}

export const FOREST_TAG = {label: 'Forest', color: '#C4DFC5'};
export const VEGETATION_TAG = {label: 'Vegetation', color: '#DDF3C7'};
export const FALSECOLOR_TAG = {label: 'False-color', color: '#FEDBD3'};
export const LANDCOVER_TAG = {label: 'Land cover', color: '#E3D4C7'};
export const FIRE_TAG = {label: 'Fire', color: '#FFBB8D'};
export const HYDROLOGY_TAG = {label: 'Hydrology', color: '#C1E1F3'};
export const TRUECOLOR_TAG = {label: 'Truecolor', color: '#FFF8D2'};
export const BIODIVERSITY_TAG = {label: 'Biodiversity', color: '#DCC7F2'};
export const INFRASTRUCTURE_TAG = {label: 'Infrastructure', color: 'lightGray'};
export const ENVIRONMENTAL_JUSTICE_TAG = {label: 'Environmental Justice', color: 'pink'};

export type DataLabels = readonly [string, string];
// Where "resolution" can refer to geospatial resolution (higher-resolution),
// temporal resolution (higher-frequency), etc.

export type ResolutionCategoryId = 'higher-resolution' | 'higher-frequency' | 'data';
// Where "index" refers to graphical indicator derived from satellite bands.

export type IndexCategoryId =
  | 'truecolor'
  | 'index'
  | 'falsecolor'
  | 'salo'
  | 'salo-carbon'
  | 'space-intelligence';
type MosaicType = 'monthly' | 'annual' | ((date: Date) => [Date, Date]);

export type LayerLegendMap = Record<string, {color: string; label: string; order?: number}>;
/**
 * Layer that’s primarily just a visual overlay on the graph.
 */

export interface ImageLayerInfo {
  type: 'image';

  // Optional, as these are currently only used in Lens.
  resolutionCategory?: ResolutionCategoryId;
  indexCategory?: IndexCategoryId;

  key: string;

  // Layer name that may include source or resolution information.
  // Use this as the layer name when source info isn't displayed next
  // to it.
  display: string;
  // Layer name that does not include any source information.
  // Use this when displaying the layer name along side source information.
  shortName: string;

  renderLayerLegend?: () => React.ReactNode;
  layerLegendMap?: LayerLegendMap;

  thumbnailUrl?: string;
  highResPreview?: string;
  tags?: LayerTag[];
  description?: string | React.ReactNode;
  license?: {
    name: string;
    url: string;
  };

  gradientStops?: readonly Readonly<[number, string]>[];

  // Called to conditionally filter out the layer from the U
  filter?: (featureCollection: ImmutableOf<ApiFeatureCollection>) => boolean;

  // Optionally override the source's resolution from CONSTANTS.SOURCE_DETAILS_BY_ID
  resolution?: number;

  // We'll display a static capture window for mosaics. E.g. the start & end date of
  // the month for a monthly mosaic.
  mosaic?: MosaicType;
  // We don't have accurate capture times for some sources (e.g. NAIP & Nearmap).
  onlyDisplayCaptureDate?: boolean;

  resampling?: mapboxgl.RasterPaint['raster-resampling'];

  // Allows for custom layer interactions in the library that aren't the usual add
  // or purchase
  libraryAction?: {text: string; link: string};
  allowedTiers?: LENS_TIERS_TYPE[];

  // User-facing string stating where the layer has available data
  geographicExtent?: string;
  // Polygon for where the layer has available data
  polygonExtent?: geojson.Polygon | geojson.MultiPolygon;

  // If true, the layer will be shown in the Analysis Tool, range overlay filter, and
  // other places that utilize raw layer data
  hasRawLayer?: boolean;

  // The vendor of the layer
  vendor: string;
}
/**
 * Layer that overlays on the map but its colors correspond to numeric values.
 *
 * Includes information necessary to render a graph of related data. Must
 * provide either a range and labels, or a unit to interpret the values with.
 */

export interface DataLayerInfo {
  type: 'data';

  // Optional, as these are currently only used in Lens.
  resolutionCategory?: ResolutionCategoryId;
  indexCategory?: IndexCategoryId;

  key: string;

  // Layer name that may include source or resolution information.
  // Use this as the layer name when source info isn't displayed next
  // to it.
  display: string;
  // Layer name that does not include any source information.
  // Use this when displaying the layer name along side source information.
  shortName: string;

  thumbnailUrl?: string;
  highResPreview?: string;
  tags?: LayerTag[];
  description?: string | React.ReactNode;

  // User-facing string stating where the layer has available data
  geographicExtent?: string;
  // Polygon for where the layer has available data
  polygonExtent?: geojson.Polygon;

  license?: {
    name: string;
    url: string | null;
  };

  dataRange: DataRange;
  dataLabels?: DataLabels;
  dataUnit?: string;

  cursorKey?: string;

  gradientStops: GradientStops;

  // All data layers can be viewed on the map, but for some like PRISM the resolution is
  // so low that it's not useful. Set this property to true to prevent the layer from being
  // displayed on the map and it will just be displayed in Analyze Area
  isNotDisplayedOnMap?: boolean;

  // Called to conditionally filter out the layer from the U
  filter?: (featureCollection: ImmutableOf<ApiFeatureCollection>) => boolean;

  // Optionally override the source's resolution from CONSTANTS.SOURCE_DETAILS_BY_ID
  resolution?: number;

  // We'll display a static capture window for mosaics. E.g. the start & end date of
  // the month for a monthly mosaic.
  mosaic?: MosaicType;
  // We don't have accurate capture times for some sources (e.g. NAIP & Nearmap).
  onlyDisplayCaptureDate?: boolean;

  resampling?: mapboxgl.RasterPaint['raster-resampling'];

  // Allows for custom layer interactions in the library that aren't the usual add
  // or purchase
  libraryAction?: {text: string; link: string};
  allowedTiers?: LENS_TIERS_TYPE[];
  vendor: string;

  // If true, the layer will be shown in the Analysis Tool, range overlay filter, and
  // other places that utilize raw layer data
  hasRawLayer?: boolean;
}

export type LayerInfo = ImageLayerInfo | DataLayerInfo;

export const ALL_LAYER_TAGS = [
  VEGETATION_TAG,
  LANDCOVER_TAG,
  TRUECOLOR_TAG,
  HYDROLOGY_TAG,
  FALSECOLOR_TAG,
  FOREST_TAG,
  FIRE_TAG,
  BIODIVERSITY_TAG,
  INFRASTRUCTURE_TAG,
  ENVIRONMENTAL_JUSTICE_TAG,
];

export const CUSTOM_GEOGRAPHIC_EXTENT = 'Custom';
export const GLOBAL_GEOGRAPHIC_EXTENT = 'Global';
export const CONTINENTAL_US_GEOGRAPHIC_EXTENT = 'Continental US';
export const TROPICS_GEOGRAPHIC_EXTENT = 'Tropics';
export const US_GEOGRAPHIC_EXTENT = 'US';

// NAIP data is available in the contiguous US only.
const NAIP_LAYER_FILTER = (featureCollection: ImmutableOf<ApiFeatureCollection>) =>
  _featureCollectionInUS(featureCollection);

export const LAYERS: Record<string, LayerInfo> = {};

LAYERS[AIRBUS_PLEIADES_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: AIRBUS_PLEIADES_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Airbus%20Pleiades%20(0.5m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  tags: [TRUECOLOR_TAG],
  description:
    'Airbus Pléiades captures high-resolution satellite imagery worldwide at 0.5m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Airbus End User License',
    url: 'https://www.intelligence-airbusds.com/legal/licences/',
  },
  vendor: 'Airbus',
};

LAYERS[AIRBUS_PLEIADES_ARCHIVE_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: AIRBUS_PLEIADES_ARCHIVE_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Airbus%20Pleiades%20(0.5m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  tags: [TRUECOLOR_TAG],
  description:
    'Airbus Pléiades captures high-resolution satellite imagery worldwide at 0.5m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors. The Airbus Pléiades Extended Archive does not receive the same level of verification and correction.',
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Airbus End User License',
    url: 'https://www.intelligence-airbusds.com/legal/licences/',
  },
  vendor: 'Airbus',
};

LAYERS[AIRBUS_SPOT_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: AIRBUS_SPOT_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Airbus%20SPOT%20(1.5m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Airbus SPOT captures high-resolution satellite imagery worldwide at 1.5m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Airbus End User License',
    url: 'https://www.intelligence-airbusds.com/legal/licences/',
  },
  vendor: 'Airbus',
};

LAYERS[AIRBUS_SPOT_ARCHIVE_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: AIRBUS_SPOT_ARCHIVE_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Airbus%20SPOT%20(1.5m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Airbus SPOT captures high-resolution satellite imagery worldwide at 1.5m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors. The Airbus SPOT Extended Archive does not receive the same level of verification and correction.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Airbus End User License',
    url: 'https://www.intelligence-airbusds.com/legal/licences/',
  },
  vendor: 'Airbus',
};

LAYERS[AIRBUS_PNEO_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: AIRBUS_PNEO_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Airbus%20Neo%20(0.png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Airbus Pléiades Neo captures high-resolution satellite imagery worldwide at 0.3m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Airbus End User License',
    url: 'https://www.intelligence-airbusds.com/legal/licences/',
  },
  vendor: 'Airbus',
};

LAYERS[AIRBUS_PNEO_ARCHIVE_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: AIRBUS_PNEO_ARCHIVE_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Airbus%20Neo%20(0.png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Airbus Pléiades Neo captures high-resolution satellite imagery worldwide at 0.3m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors. The Airbus Pléiades Neo Extended Archive does not receive the same level of verification and correction.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Airbus End User License',
    url: 'https://www.intelligence-airbusds.com/legal/licences/',
  },
  vendor: 'Airbus',
};

LAYERS[DIGITAL_GLOBE_50_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: DIGITAL_GLOBE_50_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Maxar%20Worldview%20(0.3m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Maxar Worldview 2 captures high-resolution satellite imagery worldwide at 0.5m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Maxar End User License',
    url: 'https://www.maxar.com/legal/internal-use-license',
  },
  vendor: 'Maxar',
};

LAYERS[DIGITAL_GLOBE_30_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: DIGITAL_GLOBE_30_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Maxar%20Worldview%20(0.3m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Maxar Worldview 3 captures high-resolution satellite imagery worldwide at 0.3m resolution. Imagery available in the archive can be ordered on a per-property basis in Lens and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Maxar End User License',
    url: 'https://www.maxar.com/legal/internal-use-license',
  },
  vendor: 'Maxar',
};

LAYERS[NEARMAP_15_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: NEARMAP_15_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Nearmap%20(0.15m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Nearmap captures high-resolution aerial imagery which we display at 0.15m resolution in Lens. Imagery available in the archive can be ordered on a per-property basis and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Nearmap End User License',
    url: 'https://www.nearmap.com/us/en/legal/products-agreement',
  },
  vendor: 'Nearmap',
};

LAYERS[NEARMAP_50_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: NEARMAP_50_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Nearmap%20(0.5m).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Nearmap captures high-resolution aerial imagery which we display at 0.5m resolution in Lens. Imagery available in the archive can be ordered on a per-property basis and costs scale by acres. Availability and timing of capture varies based on your geography and other factors.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Nearmap End User License',
    url: 'https://www.nearmap.com/us/en/legal/products-agreement',
  },
  vendor: 'Nearmap',
};

LAYERS[PLANET_BASEMAP_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: PLANET_BASEMAP_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Planet%20Basemap_lowres.png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Planet%20Basemap.png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'PlanetScope visual basemaps are time series mosaics created from all scene captures within the month, optimized for consistency and minimal cloud cover. This is a recurring subscription that can be billed monthly or annually at $1,999 per month or $19,999 per year before tax. Non-profits are eligible for a 50% discount on this monthly or annual pricing using the code BASEMAPNGO50. Enrolled customers automatically receive new imagery for all properties in their account every month. PlanetScope mosaics are ideal for organizations with large holdings who are interested in high-frequency insights to identify landscape changes.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'monthly',
  license: {
    name: 'Planet End User License',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
};

LAYERS[PLANET_SKYSAT_COLLECT_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: PLANET_SKYSAT_COLLECT_HIGH_RES_TRUECOLOR,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Planet%20Skysat.jpeg',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20Planet%20Skysat.jpeg',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'Planet SkySat is a constellation of 21 high-resolution satellites that capture imagery worldwide at 0.5m resolution. The SkySat archive can be ordered on a per-property basis and costs scale by the area ordered. The timing and availability of SkySat images varies widely and there is not a consistent pattern in past captures.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Planet License Agreement',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
};

LAYERS[NAIP_HIGH_RES_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: NAIP_HIGH_RES_TRUECOLOR,
  filter: NAIP_LAYER_FILTER,
  display: 'Truecolor',
  shortName: 'Truecolor',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20(NAIP).png',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  description:
    'USDA NAIP is high-resolution imagery captured for the continental United States roughly every three years dating back to the early 2000s. This 1-meter resolution imagery is captured during the summer months.',
  tags: [TRUECOLOR_TAG],
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Public domain',
    url: 'https://www.fsa.usda.gov/help/policies-and-links/',
  },
  vendor: 'USDA',
};

LAYERS[NONE] = {
  type: 'image',
  resolutionCategory: 'data',
  key: NONE,
  display: 'None (Basemap)',
  shortName: 'Basemap',
  vendor: 'None',
};

LAYERS[S2_NDVI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: S2_NDVI,
  hasRawLayer: true,
  dataRange: [0, 1],
  dataLabels: ['None', 'High Vigor'],
  display: 'Vegetation (S2)',
  shortName: 'Vegetation',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Vegetation%20(S2).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Vegetation%20(S2)%20-%20HighRes.png',
  gradientStops: NDVI_GRADIENT_STOPS,
  description:
    "Vegetation vigor is a measure of photosynthetic activity, or how much plant growth is occurring. It's derived from the Normalized Difference Vegetation Index (NDVI) using visible and near-infrared reflectance. The vegetation layer ranges from 0 to 1, with low values indicating no vegetation present in white or yellow and high values showing areas with vigorous vegetation in dark green. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.",
  tags: [VEGETATION_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_EVI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: S2_EVI,
  hasRawLayer: true,
  dataRange: [0, 3],
  dataLabels: ['None', 'High Vigor'],
  display: 'Enhanced Vegetation (S2)',
  shortName: 'Enhanced Vegetation',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Enhanced%20Vegetation%20(S2).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Enhanced%20Vegetation%20(S2).png',
  gradientStops: NDVI_GRADIENT_STOPS,
  description: (
    <>
      Enhanced Vegetation Index (EVI) is a vegetation index that was developed to improve upon the
      Normalized Difference Vegetation Index (NDVI), particularly in areas with dense vegetation
      cover or in situations where atmospheric conditions may affect the quality of satellite
      imagery. EVI uses blue light to correct for the atmospheric influences and the canopy
      background signal, which can lead to more accurate assessments of vegetation health and
      density. Higher values indicate healthier and more dense vegetation. See{' '}
      <a
        href="https://www.sciencedirect.com/science/article/abs/pii/S0034425702000962"
        target="_blank"
        rel="noopener noreferrer"
      >
        Huete et al. (2002)
      </a>{' '}
      for details. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent
      captures.
    </>
  ),
  tags: [VEGETATION_TAG, FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_NDCI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'index',
  key: S2_NDCI,
  hasRawLayer: true,
  dataRange: [-0.2, 0.5],
  dataLabels: ['None', 'High'],
  display: 'Chlorophyll (S2)',
  shortName: 'Chlorophyll',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/NDCI%20(S2)%20-%20LowRes.png',
  highResPreview: 'https://storage.googleapis.com/upstream-icons/layers/NDCI%20(S2).png',
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  description:
    'The Normalized Difference Chlorophyll Index (NDCI) is an index calculated using the red and red edge bands. This index is used to estimate the amount of chlorophyll in bodies of water and this layer is not meant to be used in areas of dry ground. Higher values indicate higher presence of chlorophyll. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [VEGETATION_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_NDTI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'index',
  key: S2_NDTI,
  hasRawLayer: true,
  dataRange: [-1, 0.2],
  dataLabels: ['None', 'High'],
  display: 'Turbidity (S2)',
  shortName: 'Turbidity',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/NDTI%20(S2).png',
  gradientStops: GNBU_REVERSE_GRADIENT_STOPS,
  description:
    'The Normalized Difference Turbidity Index (NDTI) is useful for assessing water quality. By comparing the reflectance of specific wavelengths of light, typically in the visible and near-infrared spectrum, NDTI quantifies the turbidity level of water bodies. Turbidity is an indicator of suspended particles in water which may be related to pollution or sedimentation, which can impact aquatic species and ecosystems. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_PHYCOCYANIN] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'index',
  key: S2_PHYCOCYANIN,
  hasRawLayer: true,
  dataRange: [-500, 200],
  dataLabels: ['Low', 'High'],
  display: 'Phycocyanin (S2)',
  shortName: 'Phycocyanin',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Phycocyanin%20(S2).png',
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  description: (
    <>
      <b>Please note that this is an experimental layer.</b> Phycocyanin is a natural pigment found
      in blue-green algae and some species of cyanobacteria. High concentrations of phycocyanin can
      be an indicator for algal bloom events which can impact people and aquatic ecosystems alike.
      This dataset utilizes a remote sensing formula tuned for phycocyanin pigment reflectance for
      inland water bodies.{' '}
      <a href="https://www.mdpcom/2072-4292/9/6/538" target="_blank" rel="noopener noreferrer">
        See this paper for more information.
      </a>
    </>
  ),
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[L8_NDVI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: L8_NDVI,
  hasRawLayer: true,
  dataRange: [0, 0.9],
  dataLabels: ['None', 'High Vigor'],
  display: 'Vegetation (L8)',
  shortName: 'Vegetation',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Vegetation%20(L8).png',
  gradientStops: NDVI_GRADIENT_STOPS,
  description:
    "Vegetation vigor is a measure of photosynthetic activity, or how much plant growth is occurring. It's derived from the Normalized Difference Vegetation Index (NDVI) using visible and near-infrared reflectance. The vegetation layer ranges from 0 to 1, with low values indicating no vegetation present in white or yellow and high values showing areas with vigorous vegetation in dark green. This layer is derived from NASA/USGS Landsat 8 which has been capturing data since 2013.",
  tags: [VEGETATION_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[S2_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'truecolor',
  key: S2_TRUECOLOR,
  display: 'Truecolor (S2)',
  shortName: 'Truecolor',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20(S2).png',
  description:
    'ESA Sentinel-2 is a high frequency layer that goes back to late 2015 at 10m resolution. Data is captured almost every week, making it an ideal source for tracking changes over time. This truecolor layer may be lower resolution compared to commercial sources, but can be useful for detecting landscape change or pinpointing the timing of a change.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_FALSECOLOR_BLUE_SWIR] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'falsecolor',
  display: 'Falsecolor Blue, SWIR (S2)',
  shortName: 'Blue, SWIR',
  key: S2_FALSECOLOR_BLUE_SWIR,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Blue%20SWIR%20(S2)%20(2).png',
  description:
    'This layer shows blue light as red, and two different short-wave infrared (SWIR) bands as green and blue. This band combination is valuable in distinguishing snow, ice, and clouds. Ice on the ground will be bright red in this layer, while snow will appear orange, and clouds will range from white to dark peach. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [FALSECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[L8_FALSECOLOR_BLUE_SWIR] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'falsecolor',
  display: 'Falsecolor Blue, SWIR (L8)',
  shortName: 'Blue, SWIR',
  key: L8_FALSECOLOR_BLUE_SWIR,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Blue%20SWIR%20(L8).png',
  tags: [FALSECOLOR_TAG],
  description:
    'This layer shows blue light as red, and two different short-wave infrared (SWIR) bands as green and blue. This band combination is valuable in distinguishing snow, ice, and clouds. Ice on the ground will be bright red in this layer, while snow will appear orange, and clouds will range from white to dark peach. This layer is derived from NASA/USGS Landsat 8 which has been capturing data since 2013.',
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[S2_FALSECOLOR_NIR_RED_GREEN] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'falsecolor',
  display: 'Falsecolor NIR, red, green (S2)',
  shortName: 'NIR, red, green',
  key: S2_FALSECOLOR_NIR_RED_GREEN,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/NIR%20red%2C%20green%20(S2).png',
  description:
    'This layer shows near-infrared (NIR) light as red, red light as green, and green light as blue. Plants reflect near-infrared and green light, while absorbing red, which makes this layer useful in assessing plant health. Since they reflect more near-infrared than green, plant-covered land appears deep red. Denser plant growth is darker red. Cities and exposed ground are gray or tan, and clear water is black. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [FALSECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[L8_FALSECOLOR_NIR_RED_GREEN] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'falsecolor',
  display: 'Falsecolor NIR, red, green (L8)',
  shortName: 'NIR, red, green',
  key: L8_FALSECOLOR_NIR_RED_GREEN,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/NIR%20red%2C%20green%20(L8).png',
  description:
    'This layer shows near-infrared (NIR) light as red, red light as green, and green light as blue. Plants reflect near-infrared and green light, while absorbing red, which makes this layer useful in assessing plant health. Since they reflect more near-infrared than green, plant-covered land appears deep red. Denser plant growth is darker red. Cities and exposed ground are gray or tan, and clear water is black. This layer is derived from NASA/USGS Landsat 8 which has been capturing data since 2013.',
  tags: [FALSECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[NAIP_FALSECOLOR_NIR_RED_GREEN] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'falsecolor',
  display: 'Falsecolor NIR, red, green (NAIP)',
  shortName: 'NIR, red, green',
  key: NAIP_FALSECOLOR_NIR_RED_GREEN,
  filter: NAIP_LAYER_FILTER,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/NIR%20red%2C%20green%20(NAIP).png',
  description:
    'This layer shows near-infrared (NIR) light as red, red light as green, and green light as blue. Plants reflect near-infrared and green light, while absorbing red, which makes this layer useful in assessing plant health. Since they reflect more near-infrared than green, plant-covered land appears deep red. Denser plant growth is darker red. Cities and exposed ground are gray or tan, and clear water is black. This layer is derived from USDA NAIP aerial data, which is captured in the continental United States on average every three years going back to the early 2000s.',
  tags: [FALSECOLOR_TAG],
  geographicExtent: CONTINENTAL_US_GEOGRAPHIC_EXTENT,
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Public domain',
    url: 'https://www.fsa.usda.gov/help/policies-and-links/',
  },
  vendor: 'USDA',
};

LAYERS[S2_FALSECOLOR_SWIR_NIR_GREEN] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'falsecolor',
  display: 'Falsecolor SWIR, NIR, green (S2)',
  shortName: 'SWIR, NIR, green',
  key: S2_FALSECOLOR_SWIR_NIR_GREEN,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/SWIR%2C%20NIR%2C%20green%20(S2).png',
  description:
    'This layer shows near short-wave infrared (SWIR) light as red, NIR as green, and green light as blue. Because water and wet soil stand out in this band combination, it is valuable for monitoring floods. Saturated soil and sediment-laden water will appear blue. Ice clouds, snow, and ice are bright blue, and vegetated areas appear bright green. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [FALSECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[L8_FALSECOLOR_SWIR_NIR_GREEN] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'falsecolor',
  display: 'Falsecolor SWIR, NIR, green (L8)',
  shortName: 'SWIR, NIR, green',
  key: L8_FALSECOLOR_SWIR_NIR_GREEN,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/SWIR%2C%20NIR%2C%20green%20(L8).png',
  description:
    'This layer shows near short-wave infrared (SWIR) light as red, NIR as green, and green light as blue. Because water and wet soil stand out in this band combination, it is valuable for monitoring floods. Saturated soil and sediment-laden water will appear blue. Ice clouds, snow, and ice are bright blue, and vegetated areas appear bright green. This layer is derived from NASA/USGS Landsat 8 which has been capturing data since 2013.',
  tags: [FALSECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[L8_TRUECOLOR] = {
  type: 'image',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'truecolor',
  key: L8_TRUECOLOR,
  display: 'Truecolor (L8)',
  shortName: 'Truecolor',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Truecolor%20(L8).png',
  description:
    'NASA/USGS Landsat 8 is a high frequency layer that goes back to 2013 at 30m resolution. Data is captured approximately every other week, making it an ideal source for tracking changes over time. This truecolor layer may be lower resolution compared to commercial sources, but can be useful for detecting landscape change or pinpointing the timing of a change.',
  tags: [TRUECOLOR_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[L8_NDWI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: L8_NDWI,
  hasRawLayer: true,
  dataRange: [-0.1, 0.4],
  dataLabels: ['Dry Land', 'Deep Water'],
  display: 'Surface Water (L8)',
  shortName: 'Surface Water',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Surface%20Water%20(L8).png',
  gradientStops: S2_NDWI_GRADIENT_STOPS,
  description:
    'The Surface Water layer is derived from the Normalized Difference Water Index (NDWI) and shows which parts of a property have standing water present. Values range from -0.1 to 0.4, where low values show dry land and higher values in blue show areas with water, such as rivers or ponds. This layer is derived from NASA/USGS Landsat 8 which has been capturing data since 2013.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[L8_NDSI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: L8_NDSI,
  display: 'Snow (L8)',
  shortName: 'Snow',
  dataRange: [0, 0.8],
  dataLabels: ['None', 'Snow'],
  gradientStops: [
    [0.0, '#FFFFFF'],
    [0.25, '#8888FF'],
    [0.75, '#0000FF'],
    [1.0, '#000088'],
  ],
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Snow%20(L8).png',
  description:
    'The Snow Index layer uses the Normalized Difference Snow Index (NDSI) to highlight areas on a property where snow is present. Lower values indicate a lack of snow and are shown in dark blue, while higher values denote areas with snow presence and are shown in white. This layer is derived from NASA/USGS Landsat 8 which has been capturing data since 2013.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[L8_NBR] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: L8_NBR,
  hasRawLayer: true,
  display: 'Burn Index (L8)',
  shortName: 'Burn Index',
  dataRange: [-0.1, 0.4],
  dataLabels: ['Low', 'High'],
  gradientStops: BURN_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Burn%20(L8).png',
  description:
    'Normalized burn ratio is an index calculated using near-infrared (NIR) and shortwave infrared (SWIR) values derived from NASA/USGS Landsat 8, launched in 2013. It highlights burnt areas in fire zones and denotes burn severity. Burnt areas display high SWIR and low NIR reflectance, which is the opposite of healthy vegetation. Values in Lens range from -0.1 to 0.4, where lower values denote healthy vegetation and higher values correspond to more intense burns. Because index values may vary based on the landscape, pre-fire vegetation, presence of smoke or clouds, it’s useful to view alongside truecolor, vegetation, and other datasets. NBR can provide insight into the timing and intensity of fires to assess post-fire conditions and monitor recovery.',
  tags: [FIRE_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[L8_NDBI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: L8_NDBI,
  hasRawLayer: true,
  display: 'Built-up Index (L8)',
  shortName: 'Built-up Index',
  dataRange: [0.1, 0.4],
  dataLabels: ['Low', 'High'],
  gradientStops: COPPER_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Built%20Up%20Index%20(L8)_lowres.png',
  description:
    'Normalized burn ratio is an index calculated using near-infrared (NIR) and shortwave infrared (SWIR) values derived from NASA/USGS Landsat 8, launched in 2013. It highlights burnt areas in fire zones and denotes burn severity. Burnt areas display high SWIR and low NIR reflectance, which is the opposite of healthy vegetation. Values in Lens range from -0.1 to 0.4, where lower values denote healthy vegetation and higher values correspond to more intense burns. Because index values may vary based on the landscape, pre-fire vegetation, presence of smoke or clouds, it’s useful to view alongside truecolor, vegetation, and other datasets. NBR can provide insight into the timing and intensity of fires to assess post-fire conditions and monitor recovery.',
  tags: [FIRE_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://www.usgs.gov/emergency-operations-portal/data-policy',
  },
  vendor: 'USGS',
};

LAYERS[S1_WATER_CLASSIFICATION] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: S1_WATER_CLASSIFICATION,
  hasRawLayer: true,
  display: 'Surface Water (S1)',
  shortName: 'Surface Water',
  dataRange: [-0.1, 0],
  dataLabels: ['Dry', 'Water'],
  gradientStops: S1_WATER_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Surface%20Water%20(S1)_lowres.png',
  description:
    'This Surface Water dataset is derived from ESA Sentinel-1, launched in 2014 and captured approximately every 12 days. Sentinel-1 uses C-band synthetic aperture radar to collect data in any conditions, regardless of weather or time of day. Surface Water is displayed on a spectrum from dry to water, or white to blue respectively, and can be viewed visually or as a time-series in Analyze Area.  This dataset is ideal for assessing water conditions in areas with significant cloud cover or storms, and applications include monitoring changes in bodies of water or post-disaster assessments.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Public domain',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[ANY_TRUECOLOR_HIGH_RES] = {
  type: 'image',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'truecolor',
  key: ANY_TRUECOLOR_HIGH_RES,
  display: 'Truecolor (High Resolution)',
  shortName: 'Truecolor',
  gradientStops: DEFAULT_GRADIENT_STOPS,
  vendor: 'None',
};

LAYERS[S2_NDWI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: S2_NDWI,
  hasRawLayer: true,
  dataRange: [-0.1, 0.4],
  dataLabels: ['Dry', 'Water'],
  display: 'Surface Water (S2)',
  shortName: 'Surface Water',
  gradientStops: S2_NDWI_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Surface%20Water%20(S2).png',
  description:
    'The Surface Water layer is derived from the Normalized Difference Water Index (NDWI) and shows which parts of a property have standing water present. Values range from -0.1 to 0.4, where low values show dry land and higher values in blue show areas with water, such as rivers or ponds. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_NDWI_MOISTURE] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: S2_NDWI_MOISTURE,
  hasRawLayer: true,
  dataRange: [-0.65, 0],
  dataLabels: ['Dry', 'Saturated'],
  display: 'Surface Moisture (S2)',
  shortName: 'Surface Moisture',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Surface%20Moisture%20(S2).png',
  gradientStops: S2_NDWI_GRADIENT_STOPS,
  description:
    'The Surface Moisture layer uses infrared and visible spectrum public satellite data to identify moist bare soil and very shallow water. Derived from the Normalized Difference Water Index (NDWI), this layer is best used in areas with bare ground or minimal vegetation to understand saturated ground, water bodies, and streams. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_NDSI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'index',
  key: S2_NDSI,
  hasRawLayer: true,
  display: 'Snow (S2)',
  shortName: 'Snow',
  dataRange: [0, 0.8],
  dataLabels: ['None', 'Snow'],
  gradientStops: [
    [0.0, '#FFFFFF'],
    [0.25, '#8888FF'],
    [0.75, '#0000FF'],
    [1.0, '#000088'],
  ],
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Snow%20(S2).png',
  description:
    'The Snow Index layer uses the Normalized Difference Snow Index (NDSI) to highlight areas on a property where snow is present. Lower values indicate a lack of snow and are shown in dark blue, while higher values denote areas with snow presence and are shown in white. This layer is derived from ESA Sentinel-2 going back to late 2015 with frequent captures.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_NBR] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'index',
  key: S2_NBR,
  hasRawLayer: true,
  display: 'Burn Index (S2)',
  shortName: 'Burn Index',
  dataRange: [-0.1, 0.4],
  dataLabels: ['Low', 'High'],
  gradientStops: BURN_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Burn%20(S2).png',
  description:
    'Normalized burn ratio is an index calculated using near-infrared (NIR) and shortwave infrared (SWIR) values derived from ESA Sentinel-2, launched in 2015. It highlights burnt areas in fire zones and denotes burn severity. Burnt areas display high SWIR and low NIR reflectance, which is the opposite of healthy vegetation. Values in Lens range from -0.1 to 0.4, where lower values denote healthy vegetation and higher values correspond to more intense burns. Because index values may vary based on the landscape, pre-fire vegetation, presence of smoke or clouds, it’s useful to view alongside truecolor, vegetation, and other datasets. NBR can provide insight into the timing and intensity of fires to assess post-fire conditions and monitor recovery.',
  tags: [FIRE_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[S2_NDBI] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  resolution: 20,
  indexCategory: 'index',
  key: S2_NDBI,
  hasRawLayer: true,
  display: 'Built-up Index (S2)',
  shortName: 'Built-up Index',
  gradientStops: COPPER_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Built%20Up%20Index%20(S2).png',
  dataRange: [-1, 1],
  dataLabels: ['Low', 'High'],
  description:
    'Normalized difference built-up index is calculated using shortwave infrared (SWIR) and near-infrared (NIR) values derived from ESA Sentinel-2, launched in 2015. It highlights areas without vegetation, often capturing bare earth and impervious surfaces. Note that due to the usage of the SWIR band there may be some false positives around water and shorelines.',
  tags: [INFRASTRUCTURE_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Copernicus Sentinel Data Terms',
    url: 'https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice',
  },
  vendor: 'ESA',
};

LAYERS[NLCD_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: NLCD_LANDCOVER,
  hasRawLayer: true,
  display: 'Landcover (NLCD)',
  shortName: 'Landcover',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Landcover%20(NLCD).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Landcover%20(NLCD)%20-%20HighRes.png',
  layerLegendMap: NLCD_LANDCOVER_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(NLCD_LANDCOVER_VALUE_MAP)),
  // NLCD data is available in the contiguous US, Alaska, Hawaii, Puerto Rico.
  filter: (featureCollection) =>
    _featureCollectionInUS(featureCollection, {include: ['AK', 'HI', 'PR']}),
  description:
    'The NLCD is developed by a partnership of multiple United States Federal agencies. The most recent available layer in Lens is from 2016 and the earliest is 2001, and this data covers the United States only. Land is classified into one of 16 classes.',
  tags: [LANDCOVER_TAG],
  geographicExtent: US_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Public domain',
    url: 'https://www.usa.gov/government-works',
  },
  resampling: 'nearest',
  vendor: 'USGS',
};

LAYERS[ESA_WORLDCOVER_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: ESA_WORLDCOVER_LANDCOVER,
  hasRawLayer: true,
  display: 'Landcover (ESA WorldCover)',
  shortName: 'Landcover',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Landcover%20(ESA%20WorldCover).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Landcover%20(ESA%20WorldCover)%20-%20HighRes.png',
  layerLegendMap: ESA_WORLDCOVER_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(ESA_WORLDCOVER_VALUE_MAP)),
  description:
    'ESA WorldCover provides land cover classifications at 10m resolution globally for 2020 and 2021, and is based on data from ESA Sentinel-1 and Sentinel-2. This data layer was developed by a consortium of major European service providers and research organizations and includes 12 categories of land cover.',
  tags: [LANDCOVER_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Creative Commons Attribution Non Commercial Share Alike 4.0 International',
    url: 'https://spdx.org/licenses/CC-BY-NC-SA-4.0.html',
  },
  mosaic: 'annual',
  resampling: 'nearest',
  vendor: 'ESA',
};

LAYERS[IMPACT_OBSERVATORY_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: IMPACT_OBSERVATORY_LANDCOVER,
  hasRawLayer: true,
  display: 'Landcover (Impact Observatory)',
  shortName: 'Landcover',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Landcover%20(Impact%20Observatory).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/Landcover%20(Impact%20Observatory)%20-%20High%20Res.png',
  layerLegendMap: IMPACT_OBSERVATORY_LANDCOVER_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(IMPACT_OBSERVATORY_LANDCOVER_VALUE_MAP)),
  description:
    'This dataset, produced by Impact Observatory, Microsoft, and Esri, displays a global map of land use/land cover (LULC) derived from ESA Sentinel-2 imagery at 10m resolution back to 2017. Each year is generated from Impact Observatory’s deep learning AI land classification model using a massive training dataset of billions of human-labeled image pixels. The global maps were produced by applying this model to every Sentinel-2 scene, processing over 400,000 Earth observations per year. The algorithm generates LULC predictions for 9 classes globally. These classifications include Built, Crops, Trees, Water, Rangeland, Flooded Vegetation, Snow/Ice, Bare Ground, and Clouds.',
  tags: [LANDCOVER_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Creative Commons Attribution 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  mosaic: 'annual',
  resampling: 'nearest',
  vendor: 'Impact Observatory',
};

LAYERS[IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER,
  hasRawLayer: true,
  display: 'Custom Landcover (Impact Observatory)',
  shortName: 'Custom Landcover',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/High%20Res%20Landcover%20(Impact%20Observatory).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/High%20Res%20Landcover%20(Impact%20Observatory)%20-%20HiRes.png',
  onlyDisplayCaptureDate: true,
  layerLegendMap: IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER_VALUE_MAP,
  renderLayerLegend: () =>
    renderColorKey(Object.values(IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER_VALUE_MAP)),
  description: (
    <>
      <div>
        This dataset, produced by Impact Observatory, displays land use/land cover (LULC) derived
        from ESA Sentinel-2 imagery at 10m resolution for a custom area and time period. Impact
        Observatory's innovative AI-powered methods categorize land cover into 14 classes: Water
        Channel Extent, Variable Water, Persistent Water, Dense Trees, Sparse Trees, Dense
        Rangeland, Sparse Rangeland, Flooded Vegetation, Crops, High Density Built, Low Density
        Built, Bare Ground, Snow/Ice, Clouds.
      </div>
      <br />
      <div>
        The cost to request this custom land cover dataset is 1 cent per acre, with a minimum spend
        of $500, and data delivery will include the time period requested and one year prior for
        comparison. Click the “Request” button to get a quote for your area.
      </div>
    </>
  ),
  tags: [LANDCOVER_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  license: {
    name: 'Impact Observatory End User License Agreement',
    url: 'https://www.impactobservatory.com/legal/eula/',
  },
  resampling: 'nearest',
  libraryAction: {
    text: 'Request',
    link: 'https://form.asana.com/?k=enNwaZ1O-_S01_P5R5caYw&d=253187955686276',
  },
  allowedTiers: [LENS_TIER_PLUS, LENS_TIER_ENTERPRISE],
  vendor: 'Impact Observatory',
};

LAYERS[MAPBIOMAS_BRAZIL_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: MAPBIOMAS_BRAZIL_LANDCOVER,
  hasRawLayer: true,
  display: 'Landcover (MapBiomas Brasil)',
  shortName: 'Landcover (Brazil)',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Mapbiomas%20Brazil%20Landcover.png',
  layerLegendMap: MAPBIOMAS_BRAZIL_LANDCOVER_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(MAPBIOMAS_BRAZIL_LANDCOVER_VALUE_MAP)),
  filter: (featureCollection) => _featureCollectionInBounds(featureCollection, BRAZIL_SHAPE),
  description: (
    <>
      <div>
        The MapBiomas Brasil dataset provides annual land use and land cover maps for Brazil from
        2000 to the latest available year, created through a collaboration between NGOs,
        universities and technology companies. This dataset includes dozens of land cover categories
        and offers a comprehensive view of how Brazil's landscapes have transformed over time,
        making it an essential resource for monitoring environmental changes. The project is
        continually updated to improve accuracy and coverage. Detailed accuracy information and
        methodological insights are available{' '}
        <a href="https://brasil.mapbiomas.org/en/" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </div>
    </>
  ),
  tags: [BIODIVERSITY_TAG, LANDCOVER_TAG],
  geographicExtent: 'Brazil',
  polygonExtent: BRAZIL_SHAPE,
  resolution: 30,
  mosaic: 'annual',
  license: {
    name: 'Creative Commons Attribution 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  resampling: 'nearest',
  vendor: 'MapBiomas',
};

LAYERS[MAPBIOMAS_CHACO_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: MAPBIOMAS_CHACO_LANDCOVER,
  hasRawLayer: true,
  display: 'Landcover (MapBiomas Chaco)',
  shortName: 'Landcover (Chaco)',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Mapbiomas%20Chaco%20Landcover.png',
  layerLegendMap: MAPBIOMAS_CHACO_LANDCOVER_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(MAPBIOMAS_CHACO_LANDCOVER_VALUE_MAP)),
  filter: (featureCollection) => _featureCollectionInBounds(featureCollection, CHACO_BBOX),
  description: (
    <>
      <div>
        The MapBiomas Chaco dataset offers annual land cover and land use maps for the Gran Chaco
        Americano, covering Argentina, Bolivia, Brazil, and Paraguay from 2000 to 2022. This
        initiative is a collaborative effort involving local experts in land use, satellite remote
        sensing, and GIS to understand the dynamic changes in this unique region. Using advanced
        cloud processing and automated classifiers on the Google Earth Engine platform, the project
        provides an open, accessible, and updated resource for monitoring environmental
        transformations in the Gran Chaco. More information about this dataset can be found{' '}
        <a href="https://chaco.mapbiomas.org/en/" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </div>
    </>
  ),
  tags: [BIODIVERSITY_TAG, LANDCOVER_TAG],
  geographicExtent: 'Argentina, Bolivia, Brazil, and Paraguay',
  polygonExtent: CHACO_BBOX,
  resolution: 30,
  mosaic: 'annual',
  license: {
    name: 'Creative Commons Attribution 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  resampling: 'nearest',
  vendor: 'MapBiomas',
};

LAYERS[SBTN_NATURAL_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: SBTN_NATURAL_LANDCOVER,
  hasRawLayer: true,
  display: 'Natural Lands Classification (WRI)',
  shortName: 'Natural Lands Classification (WRI)',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/WRI%20Landcover%20Classification.png',
  highResPreview: 'https://storage.googleapis.com/upstream-icons/layers/sbtn-highres.png',
  layerLegendMap: SBTN_NATURAL_LANDS_CLASSIFICATION_VALUE_MAP,
  renderLayerLegend: () =>
    renderColorKey(Object.values(SBTN_NATURAL_LANDS_CLASSIFICATION_VALUE_MAP)),
  description: (
    <>
      <div>
        The WRI Natural Lands Classification layer, part of the Science Based Targets Network
        (SBTN), provides a comprehensive global map of natural lands as of 2020, designed to support
        companies in setting "no conversion of natural ecosystems" targets. Developed by WRI's Land
        & Carbon Lab in collaboration with the World Wildlife Fund and Systemiq, this dataset
        combines the best available global and local geospatial data to establish a common baseline
        for visualizing natural land extent. The map helps companies assess their responsibility for
        land conversion post-2020 and make informed decisions about their operations and supply
        chains. See technical documentation
        <a
          href="https://sciencebasedtargetsnetwork.org/wp-content/uploads/2024/09/Technical-Guidance-2024-Step3-Land-v1-Natural-Lands-Map.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' here '}
        </a>
        for more detail.
      </div>
    </>
  ),
  tags: [BIODIVERSITY_TAG, LANDCOVER_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  resolution: 30,
  mosaic: 'annual',
  license: {
    name: 'Creative Commons Noncommercial Sharealike 3.0 Intergovernmental Organization License',
    url: 'https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en',
  },
  resampling: 'nearest',
  vendor: 'WRI',
};

LAYERS[METAWRI_CANOPY_HEIGHT] = {
  type: 'data',
  resolutionCategory: 'data',
  indexCategory: 'index',
  key: METAWRI_CANOPY_HEIGHT,
  hasRawLayer: true,
  display: 'Canopy Height (Meta WRI)',
  dataRange: [0, 35],
  dataLabels: ['0', '35 Meters'],
  gradientStops: GREENS_GRADIENT_STOPS,
  shortName: 'Canopy Height',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/meta-canopy-height-thumbnail.png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/meta-canopy-height-highres.png',
  description: (
    <>
      <div>
        The Global Canopy Height dataset provides 1m resolution maps of canopy height, developed
        using self-supervised learning models trained on aerial imagery and GEDI Lidar. This dataset
        was created using sub-meter satellite imagery from Maxar between 2009 through 2020, making
        it a helpful supplement to baselines. This high-resolution data was developed to drive
        transparency and accountability in carbon markets and conservation efforts. More information
        can be found in
        <a
          href="https://www.sciencedirect.com/science/article/pii/S003442572300439X"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' this research paper. '}
        </a>
      </div>
    </>
  ),
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  resolution: 1,
  mosaic: (_d) => [new Date('2009-01-01'), new Date('2020-12-31')],
  license: {
    name: 'Creative Commons 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/deed.en',
  },
  vendor: 'Meta/WRI',
};

LAYERS[TMF_ANNUAL_CHANGE_LANDCOVER] = {
  type: 'image',
  resolutionCategory: 'data',
  key: TMF_ANNUAL_CHANGE_LANDCOVER,
  hasRawLayer: true,
  display: 'Tropical Moist Forest Landcover (EC)',
  shortName: 'Tropical Moist Forest Landcover',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/TMF%20Annual%20Change.png',
  layerLegendMap: TMF_ANNUAL_CHANGE_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(TMF_ANNUAL_CHANGE_VALUE_MAP)),
  description: (
    <>
      <div>
        The annual change collection dataset, developed by the European Commission’s Joint Research
        Centre (JRC), provides annual classifications of tropical moist forest (TMF) land cover from
        2000 to 2023, available in tropical forest regions only. It classifies the forest into six
        categories: intact/undisturbed, degraded, deforested, regrowing, water, and other. The
        dataset is a valuable tool for tracking changes in tropical forest conditions and monitoring
        changes such as deforestation, forest degradation, and regrowth over time.{' '}
        <a
          href="https://forobs.jrc.ec.europa.eu/TMF/data"
          target="_blank"
          rel="noopener noreferrer"
        >
          See here for more information
        </a>
        .
      </div>
    </>
  ),
  tags: [BIODIVERSITY_TAG, FOREST_TAG, LANDCOVER_TAG],
  geographicExtent: 'Tropical forest regions',
  license: {name: 'None (Public Use)', url: ''},
  resolution: 30,
  mosaic: 'annual',
  resampling: 'nearest',
  vendor: 'ECJRC',
};

LAYERS[S2_NDWI_M] = {
  type: 'data',
  key: S2_NDWI_M,
  hasRawLayer: true,
  display: 'Leaf Moisture (S2)',
  shortName: 'Leaf Moisture',
  dataRange: [-0.2, 0.45],
  dataLabels: ['Lower', 'Higher'],
  gradientStops: S2_NDWI_GRADIENT_STOPS,
  vendor: 'ESA',
};

LAYERS[NAIP_NDVI] = {
  type: 'data',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'index',
  key: NAIP_NDVI,
  dataRange: [-0.1, 0.5],
  dataLabels: ['None', 'High Vigor'],
  display: 'Vegetation (NAIP)',
  shortName: 'Vegetation',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Vegetation%20(NAIP).png',
  gradientStops: NDVI_GRADIENT_STOPS,
  filter: NAIP_LAYER_FILTER,
  description:
    "Vegetation vigor is a measure of photosynthetic activity, or how much plant growth is occurring. It's derived from the Normalized Difference Vegetation Index (NDVI) using visible and near-infrared reflectance. The vegetation layer ranges from 0 to 1, with low values indicating no vegetation present in white or yellow and high values showing areas with vigorous vegetation in dark green. This layer is derived from USDA NAIP aerial data, which is captured in the continental United States on average every three years going back to the early 2000s.",
  tags: [VEGETATION_TAG],
  geographicExtent: US_GEOGRAPHIC_EXTENT,
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Public domain',
    url: 'https://www.fsa.usda.gov/help/policies-and-links/',
  },
  vendor: 'USDA',
};

LAYERS[NAIP_NDWI] = {
  type: 'data',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'index',
  key: NAIP_NDWI,
  dataRange: [0, 0.55],
  dataLabels: ['Dry', 'Water'],
  display: 'Surface Water (NAIP)',
  shortName: 'Surface Water',
  gradientStops: S2_NDWI_GRADIENT_STOPS,
  filter: NAIP_LAYER_FILTER,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Surface%20Water%20(NAIP).png',
  description:
    'The Surface Water layer is derived from the Normalized Difference Water Index (NDWI) and shows which parts of a property have standing water present. Values range from -0.1 to 0.4, where low values show dry land and higher values in blue show areas with water, such as rivers or ponds. This layer is derived from USDA NAIP aerial data, which is captured in the continental United States on average every three years going back to the early 2000s.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: CONTINENTAL_US_GEOGRAPHIC_EXTENT,
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Public domain',
    url: 'https://www.fsa.usda.gov/help/policies-and-links/',
  },
  vendor: 'USDA',
};

LAYERS[NAIP_NDWI_MOISTURE] = {
  type: 'data',
  resolutionCategory: 'higher-resolution',
  indexCategory: 'index',
  key: NAIP_NDWI_MOISTURE,
  dataRange: [-0.25, 0.3],
  dataLabels: ['Dry', 'Saturated'],
  display: 'Surface Moisture (NAIP)',
  shortName: 'Surface Moisture',
  gradientStops: S2_NDWI_GRADIENT_STOPS,
  filter: NAIP_LAYER_FILTER,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Surface%20Moisture%20(NAIP).png',
  description:
    'The Surface Moisture layer uses infrared and visible spectrum public satellite data to identify moist bare soil and very shallow water. Derived from the Normalized Difference Water Index (NDWI), this layer is best used in areas with bare ground or minimal vegetation to understand saturated ground, water bodies, and streams. This layer is derived from USDA NAIP aerial data, which is captured in the continental United States on average every three years going back to the early 2000s.',
  tags: [HYDROLOGY_TAG],
  geographicExtent: CONTINENTAL_US_GEOGRAPHIC_EXTENT,
  onlyDisplayCaptureDate: true,
  license: {
    name: 'Public domain',
    url: 'https://www.fsa.usda.gov/help/policies-and-links/',
  },
  vendor: 'USDA',
};

LAYERS[PLANET_FOREST_DILIGENCE_PACKAGE] = {
  type: 'image',
  key: PLANET_FOREST_DILIGENCE_PACKAGE,
  resolutionCategory: 'data',
  display: 'Forest Carbon Diligence Package',
  shortName: 'Forest Carbon Diligence Package',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Planet%20Diligence%20(3m)%20Carbon',
  description: (
    <>
      Planet's Forest Carbon Diligence Package provides insight into historic forest conditions with
      annual 30m data for aboveground live carbon, canopy cover, and canopy height back to 2013,
      anywhere in the world. This data bundle can be purchased in the order pane for individual
      properties at $0.04 per acre (or $0.10 per hectare). Created using deep learning models
      trained on airborne lidar and multi-sensor satellite fusion (Landsat 8, Sentinel-2, and
      ALOS-PALSAR), this archive package supports evaluating trends of forest change and project
      baselining.
      <div style={{marginTop: '1rem'}}>
        <a
          href="https://docs.planet.com/data/planetary-variables/forest-carbon-diligence/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about the methodology here.
        </a>
      </div>
    </>
  ),
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Planet License Agreement',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
};

LAYERS[PLANET_FOREST_CANOPY_COVER] = {
  type: 'data',
  key: PLANET_FOREST_CANOPY_COVER,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'salo',
  display: 'Planet Canopy Cover (30m)',
  shortName: 'Canopy Cover',
  dataRange: [0, 100],
  dataLabels: ['0%', '100%'],
  dataUnit: '%',
  gradientStops: PLANET_FOREST_CARBON_CANOPY_COVER_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/canopy-cover-30m.png',
  description:
    'Canopy Cover indicates the horizontal cover fraction occupied by tree canopies, measured as a percentage. An area with 0% canopy cover would have no trees at all, and 90+% would be a dense forest.',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Planet License Agreement',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
};

LAYERS[PLANET_FOREST_CANOPY_HEIGHT] = {
  type: 'data',
  key: PLANET_FOREST_CANOPY_HEIGHT,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'salo',
  display: 'Planet Canopy Height (30m)',
  shortName: 'Canopy Height',
  dataRange: [0, 28],
  dataLabels: ['0', '28+ meters'],
  dataUnit: 'm',
  gradientStops: PLANET_FOREST_CARBON_CANOPY_HEIGHT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Canopy%20Height%20(30m).png',
  description:
    'Canopy Height shows the distance between the ground and the top of the canopy. Canopy height is an indicator of aboveground biomass and the amount of foliage that may be consumed in a crown fire and is measured in meters.',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Planet License Agreement',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
};

LAYERS[PLANET_FOREST_ABOVEGROUND_CARBON] = {
  type: 'data',
  key: PLANET_FOREST_ABOVEGROUND_CARBON,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 30,
  display: 'Planet Aboveground Carbon (30m)',
  shortName: 'Aboveground Carbon',
  dataRange: [0, 255],
  dataLabels: ['0 MgC/ha', '100+ MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Aboveground%20Carbon%20(30m).png',
  description:
    'The Aboveground Carbon layer shows aggregate annual carbon stocks in a forested area. This layer calculates carbon at 30m resolution in megagrammes, or tons, and can be used to assess project baselines and track carbon sequestration over time. ',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Planet License Agreement',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
};

LAYERS[PLANET_FOREST_MONITORING_PACKAGE] = {
  type: 'image',
  key: PLANET_FOREST_MONITORING_PACKAGE,
  resolutionCategory: 'data',
  display: 'Forest Carbon Monitoring Package',
  shortName: 'Forest Carbon Monitoring Package',
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Forest%20Carbon%20Monitoring%20(3m).png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/planet-forest-carbon-package-highres.png',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: (date) => {
    const startDate = new Date(date);
    startDate.setMonth(date.getMonth() - 3);
    startDate.setDate(date.getDate() + 1);
    return [startDate, date];
  },
  license: {
    name: 'Planet License Agreement',
    url: 'https://assets.planet.com/docs/Master_Content_License_Agreement_2022.2.pdf',
  },
  vendor: 'Planet',
  description: (
    <>
      Planet's Forest Carbon Monitoring Package offers 3m data on canopy height, cover, and
      aboveground live carbon stocks to track forest changes at a granular level. With global
      coverage and quarterly updates, this dataset allows for more frequent monitoring of forest
      conditions and project tracking. The data is generated using advanced machine learning
      algorithms trained on a variety of satellite sources including Planet SkySat. Add this package
      and purchase for your properties at $0.10 per acre (or $0.25 per hectare) through the order
      pane.
      <a
        href="https://docs.planet.com/data/planetary-variables/forest-carbon-monitoring/"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Learn more about this data here.
      </a>
    </>
  ),
};

LAYERS[PLANET_FOREST_ABOVEGROUND_CARBON_3M] = {
  type: 'data',
  key: PLANET_FOREST_ABOVEGROUND_CARBON_3M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 3,
  display: 'Planet Aboveground Carbon (3m)',
  shortName: 'Aboveground Carbon',
  dataRange: [0, 255],
  dataLabels: ['0 MgC/ha', '100+ MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Aboveground%20Carbon%20(30m).png',
  description:
    'The Aboveground Carbon layer shows aggregate annual carbon stocks in a forested area. This layer calculates carbon at 3m resolution in megagrammes, or tons, and can be used to assess project baselines and track carbon sequestration over time. ',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: LAYERS[PLANET_FOREST_MONITORING_PACKAGE].mosaic,
  license: LAYERS[PLANET_FOREST_MONITORING_PACKAGE].license,
  vendor: 'Planet',
};
LAYERS[PLANET_FOREST_CANOPY_COVER_3M] = {
  type: 'data',
  key: PLANET_FOREST_CANOPY_COVER_3M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 3,
  display: 'Planet Canopy Cover (3m)',
  shortName: 'Canopy Cover',
  dataRange: [0, 100],
  dataLabels: ['0%', '100%'],
  dataUnit: '%',
  gradientStops: PLANET_FOREST_CARBON_CANOPY_COVER_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/canopy-cover-30m.png',
  description:
    'Canopy Cover indicates the horizontal cover fraction occupied by tree canopies, measured as a percentage. An area with 0% canopy cover would have no trees at all, and 90+% would be a dense forest.',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: LAYERS[PLANET_FOREST_MONITORING_PACKAGE].mosaic,
  license: LAYERS[PLANET_FOREST_MONITORING_PACKAGE].license,
  vendor: 'Planet',
};
LAYERS[PLANET_FOREST_CANOPY_HEIGHT_3M] = {
  type: 'data',
  key: PLANET_FOREST_CANOPY_HEIGHT_3M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 3,
  display: 'Planet Canopy Height (3m)',
  shortName: 'Canopy Height',
  dataRange: [0, 28],
  dataLabels: ['0', '28+ meters'],
  dataUnit: 'm',
  gradientStops: PLANET_FOREST_CARBON_CANOPY_HEIGHT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Canopy%20Height%20(30m).png',
  description:
    'Canopy Height shows the distance between the ground and the top of the canopy. Canopy height is an indicator of aboveground biomass and the amount of foliage that may be consumed in a crown fire and is measured in meters.',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: LAYERS[PLANET_FOREST_MONITORING_PACKAGE].mosaic,
  license: LAYERS[PLANET_FOREST_MONITORING_PACKAGE].license,
  vendor: 'Planet',
};

LAYERS[SALO_ABOVEGROUND_CARBON_30M] = {
  type: 'data',
  key: SALO_ABOVEGROUND_CARBON_30M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 30,
  display: 'Aboveground Carbon (30m)',
  shortName: 'Aboveground Carbon',
  dataRange: [0, 250],
  dataLabels: ['0 MgC/ha', '100+ MgC/ha'],
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Aboveground%20Carbon%20(30m).png',
  description:
    'The Aboveground Carbon layer shows aggregate annual carbon stocks in a forested area. This layer calculates carbon at 30m resolution in megagrammes, or tons, and can be used to assess project baselines and track carbon sequestration over time. ',
  tags: [],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Salo Sciences End User License',
    url: 'https://salo.ai/carbon-eula',
  },
  vendor: 'Salo Sciences',
};

LAYERS[SALO_CANOPY_COVER_30M] = {
  type: 'data',
  key: SALO_CANOPY_COVER_30M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'salo',
  resolution: 30,
  display: 'Canopy Cover (30m)',
  shortName: 'Canopy Cover',
  dataRange: [0, 90],
  dataLabels: ['0%', '90+%'],
  gradientStops: SALO_GREEN_VEGETATION_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/canopy-cover-30m.png',
  description:
    'Canopy Cover indicates the horizontal cover fraction occupied by tree canopies, measured as a percentage. An area with 0% canopy cover would have no trees at all, and 90+% would be a dense forest.',
  tags: [],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Salo Sciences End User License',
    url: 'https://salo.ai/carbon-eula',
  },
  vendor: 'Salo Sciences',
};

LAYERS[SALO_CANOPY_HEIGHT_30M] = {
  type: 'data',
  key: SALO_CANOPY_HEIGHT_30M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Canopy Height (30m)',
  shortName: 'Canopy Height',
  resolution: 30,
  dataRange: [0, 20],
  dataLabels: ['0', '20+ meters'],
  gradientStops: SALO_GREEN_VEGETATION_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Canopy%20Height%20(30m).png',
  description:
    'Canopy Height shows the distance between the ground and the top of the canopy. Canopy height is an indicator of aboveground biomass and the amount of foliage that may be consumed in a crown fire and is measured in meters.',
  tags: [],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Salo Sciences End User License',
    url: 'https://salo.ai/carbon-eula',
  },
  vendor: 'Salo Sciences',
};

LAYERS[SALO_ABOVEGROUND_CARBON_3M] = {
  type: 'data',
  key: SALO_ABOVEGROUND_CARBON_3M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 3,
  display: 'Aboveground Carbon (3m)',
  shortName: 'Aboveground Carbon',
  dataRange: [0, 250],
  dataLabels: ['0 MgC/ha', '100+ MgC/ha'],
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Aboveground%20Carbon%20(30m).png',
  description:
    'The Aboveground Carbon layer shows aggregate annual carbon stocks in a forested area. This layer calculates carbon at 30m resolution in megagrammes, or tons, and can be used to assess project baselines and track carbon sequestration over time. ',
  tags: [],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Salo Sciences End User License',
    url: 'https://salo.ai/carbon-eula',
  },
  vendor: 'Salo Sciences',
};

LAYERS[SALO_CANOPY_COVER_3M] = {
  type: 'data',
  key: SALO_CANOPY_COVER_3M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 3,
  indexCategory: 'salo',
  display: 'Canopy Cover (3m)',
  shortName: 'Canopy Cover',
  dataRange: [0, 90],
  dataLabels: ['0%', '90+%'],
  gradientStops: SALO_GREEN_VEGETATION_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/canopy-cover-30m.png',
  description:
    'Canopy Cover indicates the horizontal cover fraction occupied by tree canopies, measured as a percentage. An area with 0% canopy cover would have no trees at all, and 90+% would be a dense forest.',
  tags: [],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Salo Sciences End User License',
    url: 'https://salo.ai/carbon-eula',
  },
  vendor: 'Salo Sciences',
};

LAYERS[SALO_CANOPY_HEIGHT_3M] = {
  type: 'data',
  key: SALO_CANOPY_HEIGHT_3M,
  hasRawLayer: true,
  resolutionCategory: 'data',
  resolution: 3,
  display: 'Canopy Height (3m)',
  shortName: 'Canopy Height',
  dataRange: [0, 20],
  dataLabels: ['0', '20+ meters'],
  gradientStops: SALO_GREEN_VEGETATION_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Canopy%20Height%20(30m).png',
  description:
    'Canopy Height shows the distance between the ground and the top of the canopy. Canopy height is an indicator of aboveground biomass and the amount of foliage that may be consumed in a crown fire and is measured in meters.',
  tags: [],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Salo Sciences End User License',
    url: 'https://salo.ai/carbon-eula',
  },
  vendor: 'Salo Sciences',
};

LAYERS[CHLORIS_ABOVEGROUND_WOODY_BIOMASS] = {
  type: 'data',
  key: CHLORIS_ABOVEGROUND_WOODY_BIOMASS,
  resolutionCategory: 'data',
  display: 'Chloris Biomass (4.6km)',
  shortName: 'Chloris Biomass',
  dataRange: [0, 250],
  dataLabels: ['0', '750k tons'],
  gradientStops: VIRIDIS_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/Aboveground%20Carbon%20(30m).png',
  description:
    'The Chloris Global Biomass data estimates tons of aboveground biomass carbon stocks for terrestrial woody vegetation ecosystems using NASA MODIS data. Annual data is available from 2003 to 2019 at 4.6 km spatial resolution across the globe. Data is not available for areas without woody vegetation, such as Greenland and the Sahara Desert. This layer is ideal for getting a rough sense how biomass carbon storage has changed over recent decades.',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Creative Commons Attribution Non Commercial Share Alike 4.0 International',
    url: 'https://spdx.org/licenses/CC-BY-NC-SA-4.0.html',
  },
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_CARBON_STOCK] = {
  type: 'data',
  key: CHLORIS_CARBON_STOCK,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Carbon Stock',
  shortName: 'Carbon Stock',
  dataRange: [0, 500],
  dataLabels: ['0 MgC/ha', '500+ MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: CHLORIS_CARBON_STOCK_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/under_construction.jpg',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_FOREST_COVER] = {
  type: 'image',
  key: CHLORIS_FOREST_COVER,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Forest Cover',
  shortName: 'Forest Cover',
  layerLegendMap: CHLORIS_FOREST_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(CHLORIS_FOREST_VALUE_MAP)),
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/under_construction.jpg',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_30_PACKAGE] = {
  type: 'image',
  key: CHLORIS_30_PACKAGE,
  resolutionCategory: 'data',
  display: 'Forest Carbon Package (30m)',
  shortName: 'Forest Carbon Package (30m)',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/chloris-30-carbon-stock.png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/chloris-30-carbon-stock-highres.png',
  description: (
    <>
      <p>
        The Chloris Geospatial 30m Biomass and Forest Cover Package provides annual data on
        above-ground biomass stock and forest cover, with coverage back to 2000. This data bundle
        can be purchased in the order pane for individual properties at $0.04/acre, and the entire
        archive of annual data is included at no extra cost. The data bundle includes carbon stock,
        carbon stock change, and forest cover.
      </p>
      <p>
        The data is generated using Chloris' proprietary ML and AI technology, which leverages
        biomass estimates from spaceborne and airborne LiDAR alongside earth observations, processed
        with a Bayesian time series approach to deliver robust, noise-filtered estimates of
        above-ground biomass and forest cover. Learn more about{' '}
        <a href="https://www.chloris.earth/key-documents" target="_blank" rel="noopener noreferrer">
          Chloris data methodology and validation here
        </a>
        {', '}
        and our{' '}
        <a
          href="https://support.upstream.tech/article/170-chloris-forest-carbon"
          target="_blank"
          rel="noopener noreferrer"
        >
          support doc here
        </a>
        .
      </p>
    </>
  ),
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_30_CARBON_STOCK] = {
  type: 'data',
  key: CHLORIS_30_CARBON_STOCK,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Carbon Stock (30m)',
  shortName: 'Carbon Stock',
  dataRange: [0, 500],
  dataLabels: ['0 MgC/ha', '500+ MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: CHLORIS_CARBON_STOCK_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/under_construction.jpg',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  resampling: 'nearest',
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_30_CARBON_STOCK_CHANGE] = {
  type: 'data',
  key: CHLORIS_30_CARBON_STOCK_CHANGE,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Carbon Stock Change (30m)',
  shortName: 'Carbon Stock Change (MgC/ha)',
  dataRange: [-350, 350],
  dataLabels: ['-350 MgC/ha', '350 MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: SEISMIC_R_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/under_construction.jpg',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  resampling: 'nearest',
  vendor: 'Chloris Geospatial',
};
LAYERS[CHLORIS_30_FOREST_COVER] = {
  type: 'image',
  key: CHLORIS_30_FOREST_COVER,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Forest Cover (30m)',
  shortName: 'Forest Cover',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/under_construction.jpg',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  layerLegendMap: CHLORIS_FOREST_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(CHLORIS_FOREST_VALUE_MAP)),
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  resampling: 'nearest',
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_10_PACKAGE] = {
  type: 'image',
  key: CHLORIS_10_PACKAGE,
  resolutionCategory: 'data',
  display: 'Forest Carbon Package (10m)',
  shortName: 'Forest Carbon Package (10m)',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/chloris-10-package.png',
  highResPreview: 'https://storage.googleapis.com/upstream-icons/layers/chloris-10-package.png',
  description: (
    <>
      <p>
        The Chloris Geospatial 10m Biomass and Forest Cover Package provides annual data on
        above-ground biomass stock and forest cover, with coverage back to 2017. This data bundle
        can be purchased in the order pane for individual properties at $0.20/acre, and the entire
        archive of annual data is included at no extra cost. The data bundle includes carbon stock,
        carbon stock change, and forest cover.
      </p>
      <p>
        The data is generated using Chloris' proprietary ML and AI technology, which leverages
        biomass estimates from spaceborne and airborne LiDAR alongside earth observations, processed
        with a Bayesian time series approach to deliver robust, noise-filtered estimates of
        above-ground biomass and forest cover. Learn more about{' '}
        <a href="https://www.chloris.earth/key-documents" target="_blank" rel="noopener noreferrer">
          Chloris data methodology and validation here
        </a>
        {', '}
        and our{' '}
        <a
          href="https://support.upstream.tech/article/170-chloris-forest-carbon"
          target="_blank"
          rel="noopener noreferrer"
        >
          support doc here
        </a>
        .
      </p>
    </>
  ),
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_10_CARBON_STOCK] = {
  type: 'data',
  key: CHLORIS_10_CARBON_STOCK,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Carbon Stock (10m)',
  shortName: 'Carbon Stock',
  dataRange: [0, 500],
  dataLabels: ['0 MgC/ha', '500+ MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: CHLORIS_CARBON_STOCK_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/chloris-10-package.png',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  resampling: 'nearest',
  vendor: 'Chloris Geospatial',
};

LAYERS[CHLORIS_10_CARBON_STOCK_CHANGE] = {
  type: 'data',
  key: CHLORIS_10_CARBON_STOCK_CHANGE,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Carbon Stock Change (10m)',
  shortName: 'Carbon Stock Change (MgC/ha)',
  gradientStops: SEISMIC_R_GRADIENT_STOPS,
  dataRange: [-350, 350],
  dataLabels: ['-350 MgC/ha', '350 MgC/ha'],
  dataUnit: 'MgC',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/chloris-10-package.png',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  resampling: 'nearest',
  vendor: 'Chloris Geospatial',
};
LAYERS[CHLORIS_10_FOREST_COVER] = {
  type: 'image',
  key: CHLORIS_10_FOREST_COVER,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Chloris Forest Cover (10m)',
  shortName: 'Forest Cover',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/chloris-10-package.png',
  description: '',
  tags: [FOREST_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  layerLegendMap: CHLORIS_FOREST_VALUE_MAP,
  renderLayerLegend: () => renderColorKey(Object.values(CHLORIS_FOREST_VALUE_MAP)),
  license: {
    name: 'Chloris Geospatial EULA',
    url: 'https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf',
  },
  resampling: 'nearest',
  vendor: 'Chloris Geospatial',
};

LAYERS[MOBI_SPECIES_RICHNESS_ALL] = {
  type: 'data',
  key: MOBI_SPECIES_RICHNESS_ALL,
  resolutionCategory: 'data',
  display: 'MoBI Species Richness (990m)',
  shortName: 'MoBI Species Richness',
  dataRange: [0, 14],
  dataLabels: ['0', '14 species'],
  gradientStops: MAGMA_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/MoBI%20Species%20Richness.png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/MoBI%20Species%20Richness%20-%20HighRes.png',
  description:
    'The Map of Biodiversity Importance (MoBI) Species Richness Layer provides information about the number of species in the continental US that are protected by the Endangered Species Act and/or considered to be in danger of extinction. Species richness for all species includes vertebrates, freshwater invertebrates, pollinators, and vascular plants and the richness value represents the number of species with habitat overlapping a given 990 meter cell. As a synthesis of predictive models, they cannot guarantee either the presence or absence of imperiled species at a given location. This data is ideal for assisting in identifying areas that may be high priority for on-the-ground biodiversity conservation. NatureServe reserves all rights in all intellectual property provided. Distribution of the data or any intellectual property in whole or in part, or any products derived from the data or any intellectual property for commercial purposes is strictly prohibited.',
  tags: [FOREST_TAG, BIODIVERSITY_TAG],
  geographicExtent: CONTINENTAL_US_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'NatureServe Terms and Conditions',
    url: 'https://www.natureserve.org/terms',
  },
  vendor: 'NatureServe',
};

LAYERS[GLOBAL_FOREST_CHANGE_LOSSYEAR] = {
  type: 'image',
  key: GLOBAL_FOREST_CHANGE_LOSSYEAR,
  hasRawLayer: true,
  resolutionCategory: 'data',
  display: 'Global Forest Loss (30m)',
  shortName: 'Global Forest Loss',
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  layerLegendMap: GLOBAL_FOREST_CHANGE_LOSSYEAR_VALUE_MAP,
  renderLayerLegend: () => (
    <div>{renderColorKey(Object.values(GLOBAL_FOREST_CHANGE_LOSSYEAR_VALUE_MAP))}</div>
  ),
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/Global%20Forest%20Loss.png',
  description: (
    <>
      The University of Maryland Global Forest Change layer displays 30m data showing gross forest
      loss for each year from 2001 to 2023, derived from Landsat imagery. Areas where forest has
      transitioned to non-forest during a given year are displayed in red on the map. Forest loss
      could be a result of natural or human-induced causes. This dataset makes it possible to assess
      the timing and annual extent of forest loss over the last two decades, which can support site
      assessments and baselining. Data and methodology were originally{' '}
      <a
        href="https://www.science.org/doi/10.1126/science.1244693"
        target="_blank"
        rel="noopener noreferrer"
      >
        published
      </a>{' '}
      by Hansen et. al and this work is a collaboration between the Global Land Analysis & Discovery
      Lab at the University of Maryland, Google, USGS, and NASA.
    </>
  ),
  tags: [VEGETATION_TAG, FOREST_TAG],
  mosaic: 'annual',
  license: {
    name: 'Creative Commons Attribution 4.0 International (CC BY 4.0)',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  resampling: 'nearest',
  vendor: 'University of Maryland',
};

LAYERS[IMPACT_OBSERVATORY_BIODIVERSITY] = {
  type: 'data',
  key: IMPACT_OBSERVATORY_BIODIVERSITY,
  resolutionCategory: 'data',
  display: 'Biodiversity Intactness (100m)',
  shortName: 'Biodiversity Intactness',
  dataRange: [0, 1],
  dataLabels: ['', 'Intact'],
  gradientStops: GREENS_GRADIENT_STOPS,
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/layers/impact-observatory-biodiversity_thumb.png',
  highResPreview:
    'https://storage.googleapis.com/upstream-icons/layers/impact-observatory-biodiversity_banner.png',
  description: (
    <>
      This data depicts the terrestrial intactness of biodiversity globally for the years 2017-2020
      at a 100-meter resolution. The intactness of biodiversity is based both on the abundance of
      individual species as well the species composition compared to an intact baseline. This
      dataset can help identify areas of critical remaining intact habitat and support spatial
      planning and management. Learn more about the methodology in{' '}
      <a
        href="https://ai4edatasetspublicassets.blob.core.windows.net/assets/pdfs/io-biodiversity/Biodiversity_Intactness_whitepaper.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        this white paper
      </a>
      .
    </>
  ),
  tags: [FOREST_TAG, BIODIVERSITY_TAG],
  geographicExtent: GLOBAL_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Creative Commons Attribution 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  vendor: 'Impact Observatory',
};

LAYERS[SPACE_INTELLIGENCE_CARBON] = {
  type: 'data',
  key: SPACE_INTELLIGENCE_CARBON,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'space-intelligence',
  display: 'CarbonMapper (25m)',
  shortName: 'CarbonMapper',
  dataRange: [0, 250],
  dataLabels: ['0 MgC/ha', '250+ MgC/ha'],
  dataUnit: 'MgC',
  gradientStops: SPACE_INTELLIGENCE_ABOVEGROUND_CARBON_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/CarbonMapper%20(25m).png',
  description:
    'CarbonMapper estimates per-pixel biomass calculations for carbon stocks to inform carbon storage over time.',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  vendor: 'Space Intelligence',
};

LAYERS[SPACE_INTELLIGENCE_CARBON_TREE_CANOPY] = {
  type: 'data',
  key: SPACE_INTELLIGENCE_CARBON_TREE_CANOPY,
  resolutionCategory: 'data',
  indexCategory: 'space-intelligence',
  display: 'CarbonMapper: Tree Carbon (0.1m)',
  shortName: 'CarbonMapper',
  dataRange: [0, 8500],
  dataLabels: ['0 kgC/tree', '8,500+ kgC/tree'],
  gradientStops: SPACE_INTELLIGENCE_ABOVEGROUND_CARBON_GRADIENT_STOPS,
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/CarbonMapper%20(25m).png',
  description: 'This CarbonMapper layer shows the kg of carbon per tree.',
  tags: [FOREST_TAG, VEGETATION_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  vendor: 'Space Intelligence',
};

LAYERS[SPACE_INTELLIGENCE_HABITAT] = {
  type: 'image',
  key: SPACE_INTELLIGENCE_HABITAT,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'space-intelligence',
  display: 'HabitatMapper (25m)',
  shortName: 'HabitatMapper',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/HabitatMapper%20(25m).png',
  layerLegendMap: SPACE_INTELLIGENCE_HABITAT_VALUE_MAP,
  renderLayerLegend: () => (
    <div>
      <p>
        This layer shows{' '}
        <a href="https://www.space-intelligence.com/habitatmapper-landcover-mapping/">
          Space Intelligence's HabitatMapper™
        </a>{' '}
        classifications of land cover.
      </p>
      {renderColorKey(Object.values(SPACE_INTELLIGENCE_HABITAT_VALUE_MAP))}
    </div>
  ),
  description:
    'HabitatMapper provides landcover classifications to support assessments of land use change over time.',
  tags: [LANDCOVER_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  resampling: 'nearest',
  vendor: 'Space Intelligence',
};

LAYERS[SPACE_INTELLIGENCE_HABITAT_URBAN] = {
  type: 'image',
  key: SPACE_INTELLIGENCE_HABITAT_URBAN,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'space-intelligence',
  display: 'HabitatMapper Urban Classes (25m)',
  shortName: 'HabitatMapper (Urban Classes)',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/HabitatMapper%20(25m).png',
  layerLegendMap: SPACE_INTELLIGENCE_HABITAT_URBAN_VALUE_MAP,
  renderLayerLegend: () =>
    renderColorKey(Object.values(SPACE_INTELLIGENCE_HABITAT_URBAN_VALUE_MAP)),
  description:
    'HabitatMapper provides landcover classifications to support assessments of land use change over time.',
  tags: [LANDCOVER_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  resampling: 'nearest',
  vendor: 'Space Intelligence',
};

LAYERS[SPACE_INTELLIGENCE_HABITAT_10_CLASS] = {
  type: 'image',
  key: SPACE_INTELLIGENCE_HABITAT_10_CLASS,
  hasRawLayer: true,
  resolutionCategory: 'data',
  indexCategory: 'space-intelligence',
  display: 'Landcover (Space Intelligence)',
  resolution: 10,
  shortName: 'Landcover',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/HabitatMapper%20(10m).png',
  layerLegendMap: SPACE_INTELLIGENCE_HABITAT_10_CLASS_VALUE_MAP,
  renderLayerLegend: () =>
    renderColorKey(Object.values(SPACE_INTELLIGENCE_HABITAT_10_CLASS_VALUE_MAP)),
  description: (
    <>
      Space Intelligence landcover data, or HabitatMapper, provides country-specific land cover
      data, enabling project developers and investors to inform and validate forest carbon project
      baselines. By offering local calibration, the dataset captures regional factors for enhanced
      precision, allowing users to screen areas, review project activity, and perform due diligence.
      Delivered through Lens and as GeoTIFF files for requested areas, this data helps monitor
      habitat composition and changes over time. Trusted in the voluntary carbon market,
      HabitatMapper supports critical baseline assumptions and impact measurements for forest
      conservation projects.
      <a
        href="https://support.upstream.tech/article/172-space-intelligence"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Learn more about available geographies and pricing here{' '}
      </a>
      or email us at <a href="mailto:lens@upstream.tech">lens@upstream.tech</a> to request data for
      your areas of interest.
    </>
  ),
  tags: [LANDCOVER_TAG],
  geographicExtent: CUSTOM_GEOGRAPHIC_EXTENT,
  mosaic: 'annual',
  license: {
    name: 'Space Intelligence License Agreement',
    url: 'https://142260897.fs1.hubspotusercontent-eu1.net/hubfs/142260897/Legal%20Documents/Space%20Intelligence%20EULA.pdf',
  },
  resampling: 'nearest',
  libraryAction: {
    text: 'Request',
    link: 'https://form.asana.com/?k=enNwaZ1O-_S01_P5R5caYw&d=253187955686276',
  },
  allowedTiers: [LENS_TIER_PLUS, LENS_TIER_ENTERPRISE],
  vendor: 'Space Intelligence',
};

LAYERS[PRISM_M_PRECIPITATION] = {
  type: 'data',
  resolutionCategory: 'higher-frequency',
  indexCategory: 'index',
  key: PRISM_M_PRECIPITATION,
  hasRawLayer: true,
  dataUnit: 'in',
  mosaic: 'monthly',
  // PRISM serves the data in mm. Below we are converting a dataRange of 0-1016mm to inches
  // to make this more understandable for users.
  dataRange: [0, 40],
  dataLabels: ['0 in', '40+ in'],
  display: 'Precipitation (PRISM)',
  shortName: 'Precipitation',
  thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/layers/PRISM_precip.png',
  gradientStops: COOL_GRADIENT_STOPS,
  isNotDisplayedOnMap: false,
  description: (
    <>
      Monthly precipitation data from the PRISM Climate Group at Oregon State University offers
      insights into monthly patterns across the United States. The PRISM data uses a weighted
      regression scheme to account for complex climate regimes associated with orography, rain
      shadows, temperature inversions, slope aspect, coastal proximity, and other factors. By
      assimilating station data from various networks nationwide, PRISM provides a detailed
      understanding of precipitation which can be used to contextualize other Lens datasets,
      identify risks such as drought conditions, and support water resource management decisions.{' '}
      <a
        href="https://www.prism.oregonstate.edu/documents/PRISM_datasets.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more about PRISM datasets here
      </a>
      .
    </>
  ),
  tags: [HYDROLOGY_TAG],
  geographicExtent: US_GEOGRAPHIC_EXTENT,
  allowedTiers: [LENS_TIER_PLUS, LENS_TIER_ENTERPRISE],
  vendor: 'PRISM Climate Group',
};
export function renderColorKey(colorsAndLabels: {color: string; label: string}[], sort = true) {
  if (sort) {
    colorsAndLabels = sortBy(colorsAndLabels, 'label');
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {colorsAndLabels.map(({color, label}) => (
        <div
          key={color}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            lineHeight: '20px',
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: color,
              borderRadius: 2,
            }}
          />
          <div
            style={{
              paddingLeft: '10px',
            }}
          >
            {label}
          </div>
        </div>
      ))}
    </div>
  );
}
