import {bbox} from '@turf/turf';
import geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {ANALYZE_POLYGON_POPUP_WIDTH} from 'app/components/AnalyzePolygonPopup/AnalyzePolygonPopup';
import AnalyzePolygonTool from 'app/components/AnalyzePolygonPopup/AnalyzePolygonTool';
import ConnectedMap from 'app/components/ConnectedMap';
import {RenderMapCallbackOpts} from 'app/components/ConnectedMap/view';
import DraggableLegendWithSlider from 'app/components/DeclarativeMap/DraggableLegendWidthSlider';
import DrawLayer from 'app/components/DeclarativeMap/DrawLayer';
import FeatureCollectionVector from 'app/components/DeclarativeMap/FeatureCollectionVector';
import FeatureRaster from 'app/components/DeclarativeMap/FeatureRaster';
import GeoJsonFitter from 'app/components/DeclarativeMap/GeoJsonFitter';
import GeometryOverlaysContent from 'app/components/DeclarativeMap/GeometryOverlaysContent';
import MapSettingsControl from 'app/components/DeclarativeMap/MapSettingsControl';
import MapShowPropertyBoundariesControl from 'app/components/DeclarativeMap/MapShowPropertyBoundariesControl';
import MapStyle from 'app/components/DeclarativeMap/MapStyle';
import MapTilesLoadingControl from 'app/components/DeclarativeMap/MapTilesLoadingControl';
import MeasureToolButton from 'app/components/DeclarativeMap/MeasureToolButton';
import NoteVector from 'app/components/DeclarativeMap/NoteVector';
import PixelInfo from 'app/components/DeclarativeMap/PixelInspector/PixelInfo';
import ShareControl from 'app/components/DeclarativeMap/ShareControl';
import TerrainControl from 'app/components/DeclarativeMap/TerrainControl';
import TileCostDebugger from 'app/components/DeclarativeMap/TileCostDebugger';
import ZoomCenterControl from 'app/components/DeclarativeMap/ZoomCenterControl';
import EditNoteGeometry from 'app/components/EditNoteGeometry';
import {OrderImageryState} from 'app/components/OrderImagerySidebar';
import {SidebarViewState} from 'app/components/TabbedSidebar/TabbedSidebar';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  GeometryOverlaySetting,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {
  ApiOrganization,
  ApiOrganizationUser,
  MeasurementSystem,
} from 'app/modules/Remote/Organization';
import {GeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {NotesActions, NotesState, StateApiNote} from 'app/stores/NotesStore';
import {recordEvent} from 'app/tools/Analytics';
import * as CONSTANTS from 'app/utils/constants';
import {BBox2d} from 'app/utils/geoJsonUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {useStateWithDeps} from 'app/utils/hookUtils';
import * as layers from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import {Mode} from 'app/utils/mapUtils/modes';

import {ImageRefIndex} from './MapStateProvider';
import MiniMapControl from './MiniMapControl';
import {OVERLAY_POPUP_INSET} from './utils';
import {MapTool} from './view';
import {PolygonDispatch, PolygonState} from '../../providers/MapPolygonStateProvider';

interface Props {
  firebaseToken: string;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.ImmutableOf<ApiOrganization>;

  selectedFeatureCollection: HydratedFeatureCollection;
  selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;
  setSelectedFeatureIds: (featureLensIds: I.Set<string>) => void;

  imageRefs: mapUtils.MapImageRefs;
  featureData: I.ImmutableListOf<ApiFeatureData> | null;

  mode: Mode;
  activeTool: MapTool | null;
  setActiveTool: (t: MapTool | null) => void;
  focusedGeometry: geojson.GeoJSON | null;
  zoomToGeometry: (geometry: geojson.GeoJSON | null) => void;
  defaultCameraOptions: {
    center: mapboxgl.LngLat;
    pitch: number;
    bearing: number;
    zoom: number;
  } | null;

  notesState: NotesState;
  notesActions: NotesActions;
  showNotes: boolean;
  onClickNoteMarker: (g: geojson.GeoJSON | null, n: StateApiNote | undefined) => void;

  polygonState: PolygonState;
  polygonDispatch: PolygonDispatch;
  openAnalyzePolygonPopup: (f: geojson.Feature<geojson.Polygon | geojson.MultiPolygon>) => void;

  overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
  geoJsonFeaturesLoader: GeoJsonFeaturesLoader;
  isOverlayVisibleByName: Record<string, boolean>;
  overlaySettings: GeometryOverlaySetting[];

  mapPaddingOptions: mapboxgl.PaddingOptions;
  mapStyle: mapUtils.MapStyle;
  animationDuration: number;
  onMapMoved?: (e: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>) => void;
  measurementSystem?: MeasurementSystem;

  popupEl: HTMLDivElement | null;

  filterNotesCallback?: (note: StateApiNote) => boolean;

  showCostDebugger: boolean;
  setShowCostDebugger: (b: boolean) => void;

  orderImageryState: OrderImageryState;
  setOrderImageryState: (s: React.SetStateAction<OrderImageryState>) => void;

  tabbedSidebarState: SidebarViewState;
}

const DEFAULT_CONTROL_POSITION = 'top-right';

const Map: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  firebaseToken,
  profile,
  organization,
  selectedFeatureCollection,
  selectedFeatures,
  setSelectedFeatureIds,
  imageRefs,
  featureData,
  mode,
  activeTool,
  setActiveTool,
  focusedGeometry,
  zoomToGeometry,
  notesState,
  notesActions,
  showNotes,
  onClickNoteMarker,
  polygonState,
  polygonDispatch,
  openAnalyzePolygonPopup,
  overlayFeatureCollections,
  geoJsonFeaturesLoader,
  isOverlayVisibleByName,
  overlaySettings,
  mapPaddingOptions,
  mapStyle,
  animationDuration,
  onMapMoved,
  defaultCameraOptions,
  popupEl,
  filterNotesCallback,
  showCostDebugger,
  setShowCostDebugger,
  orderImageryState,
  setOrderImageryState,
  tabbedSidebarState,
  measurementSystem,
}) => {
  const {overlayMask} = polygonState;

  const selectedFeatureIds = React.useMemo(
    () => selectedFeatures.map((f) => f!.get('id')).toSet(),
    [selectedFeatures]
  );

  const firstLayerKey = imageRefs[0].layerKey;

  const fitBoundsOptions = {
    padding: mapUtils.insetPadding(
      mapUtils.insetPadding(mapPaddingOptions, [
        30,
        40,
        30,

        // Room on the left for the popup, if it’s open
        popupEl ? popupEl.clientWidth + OVERLAY_POPUP_INSET : 0,
      ]),
      10
    ),
    maxZoom:
      layerUtils.isLayerKeyHighResTruecolor(firstLayerKey) || firstLayerKey === layers.NONE
        ? 18
        : 15,
    duration: animationDuration,
  };

  const currentFeatureBounds = React.useMemo(() => {
    if (selectedFeatures.isEmpty()) {
      return null;
    } else {
      // as any because there’s some disconnect between the geojson types and
      // the turf types around GeometryCollection.
      return bbox(geoJsonUtils.featureCollection(selectedFeatures.toJS()) as any) as BBox2d;
    }
  }, [selectedFeatures]);

  const currentFeatureGeometry: ApiFeature['geometry'] = React.useMemo(() => {
    if (selectedFeatures.isEmpty() || selectedFeatures.size !== 1) {
      return null;
    } else {
      return selectedFeatures.first().toJS().geometry;
    }
  }, [selectedFeatures]);

  const [showTerrain, setShowTerrain] = React.useState(false);

  const [showPropertyBoundaries, setShowPropertyBoundaries] = React.useState(true);

  // Given some internal mapbox-gl race conditions, we want to ensure that any
  // unmounting behavior that happens as a result of updating the active tool
  // happens before we set the terrain property on the map style.
  const toggleShowTerrain = React.useCallback(() => {
    setShowTerrain((prevShowTerrain) => {
      const nextShowTerrain = !prevShowTerrain;

      // Close measure distance tool when switching to 3D viewpoint.
      if (nextShowTerrain && activeTool === 'measureDistance') {
        setActiveTool(null);
      }

      recordEvent(nextShowTerrain ? 'Entered 3D mode' : 'Exited 3D mode');

      return nextShowTerrain;
    });
  }, [activeTool, setActiveTool]);

  const setSubsetFeature = React.useCallback(
    (f: geojson.Feature | null) =>
      setOrderImageryState((oldState) => {
        // We shouldn’t get non-polygons but just to be safe
        if (f === oldState.subsetFeature || (f && f.geometry.type !== 'Polygon')) {
          return oldState;
        } else {
          return {...oldState, subsetFeature: f as geojson.Feature<geojson.Polygon> | null};
        }
      }),
    [setOrderImageryState]
  );

  const clickHandlers = React.useMemo(() => {
    return {
      [CONSTANTS.PRODUCT_USER_POINT]: (f) => {
        const url = f.getIn(['properties', 'url']);
        if (url) {
          window.open(url, '_blank');
        }
      },
    };
  }, []);

  return (
    <ConnectedMap
      imageRefs={imageRefs}
      featureData={featureData}
      mode={mode}
      globalCursor={
        notesState.addPendingNoteGeometryMode === 'point' || activeTool === 'pixelInspector'
          ? 'crosshair'
          : undefined
      }
      firebaseToken={firebaseToken}
      onMapMoved={onMapMoved}
      onMapClick={(event: mapboxgl.MapMouseEvent) => {
        // If we are locating a pending note, update the pending note geometry with
        // the selected coordinates, then exit.
        //
        // TODO(fiona): Handle this in a mapbox-gl-draw mode
        if (notesState.addPendingNoteGeometryMode === 'point') {
          event.preventDefault();

          const {lng, lat} = event.lngLat;

          const feature = geoJsonUtils.point([lng, lat], {}, {id: 'point-from-click'});
          notesActions.setPendingNoteGeometryFeature(feature);
          notesActions.stopEditingPendingGeometry();
        }
      }}
      selectedFeatureCollection={selectedFeatureCollection}
      selectedFeatureIds={selectedFeatureIds}
      selectedFeatures={selectedFeatures}
      moveCompareSliderToOnlyShowLatestImage={
        notesState.addPendingNoteGeometryMode === 'rect' ||
        notesState.addPendingNoteGeometryMode === 'polygon' ||
        (activeTool === 'analyzePolygon' && !notesState.pendingNoteGeometryFeature) ||
        activeTool === 'measureArea' ||
        activeTool === 'measureDistance'
      }
      compareFullScreenOffset={
        // Offset by the width of the AnalyzePolygonPopup plus 20 pixels margin
        activeTool === 'analyzePolygon' ? ANALYZE_POLYGON_POPUP_WIDTH + 20 : undefined
      }
      controlPosition={DEFAULT_CONTROL_POSITION}
      controlPadding={mapPaddingOptions}
      defaultCameraOptions={defaultCameraOptions}
      hideNavigationControl
      measurementSystem={measurementSystem}
      organization={organization}
    >
      {({map, isMapLoaded, index, cursor, layerKey}: RenderMapCallbackOpts) => {
        // If overlayMask is available (it’s typically maintained by
        // MapPolygonStateProvider) then we defer to it for the current
        // cursor, since it won’t update its cursor value until the mask is
        // done.
        //
        // This lets us wait until the mask is calculated for the new
        // cursor before switching to it, which prevents a flash of either an
        // unmasked layer or the old mask applied to the new layer.

        // We check overlayMask’s existence as well as cursorIdx here, so
        // it’s safe to use "as" and "!" below.
        const useMask =
          overlayMask &&
          overlayMask.cursors.length === imageRefs.length &&
          overlayMask.layerKey === layerKey;

        const layer = layerUtils.getLayer(layerKey);

        return (
          <MapInteractionProvider
            features={selectedFeatureCollection.get('features')}
            selectedFeatureIds={selectedFeatureIds}
            setSelectedFeatureIds={setSelectedFeatureIds}
          >
            {({selectFeatureById, hoveredFeatureIds, hoverFeatureById}) => (
              <>
                {notesState.addPendingNoteGeometryMode === 'point' && (
                  <mapUtils.DisableClickableFeatures />
                )}
                <ZoomCenterControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  featureBounds={currentFeatureBounds}
                  zoomToGeometry={zoomToGeometry}
                  fitBoundsOptions={fitBoundsOptions}
                />
                <TerrainControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  showTerrain={showTerrain}
                  toggleShowTerrain={toggleShowTerrain}
                />
                <FeatureRaster
                  map={map}
                  isMapLoaded={isMapLoaded}
                  activeLayerKey={layerKey}
                  cursor={useMask ? overlayMask!.cursors[index] : cursor}
                  featureCollection={selectedFeatureCollection}
                  featureData={featureData || I.List([])}
                  mask={useMask ? overlayMask!.masks[index] : null}
                />
                <FeatureCollectionVector
                  map={map}
                  isMapLoaded={isMapLoaded}
                  featureCollection={selectedFeatureCollection}
                  selectedFeatureIds={selectedFeatureIds}
                  selectFeatureById={selectFeatureById}
                  hoveredFeatureIds={hoveredFeatureIds}
                  hoverFeatureById={hoverFeatureById}
                  showPropertyBoundaries={showPropertyBoundaries}
                />
                <GeometryOverlaysContent
                  map={map}
                  isMapLoaded={isMapLoaded}
                  selectedFeatures={selectedFeatures}
                  overlayFeatureCollections={overlayFeatureCollections}
                  geoJsonFeaturesLoader={geoJsonFeaturesLoader}
                  isOverlayVisibleByName={isOverlayVisibleByName}
                  overlaySettings={overlaySettings}
                  openAnalyzePolygonPopup={openAnalyzePolygonPopup}
                  clickHandlers={clickHandlers}
                  readyToSelectOverlayPolygon={
                    polygonState.analyzeAreaDrawMode === 'selectOverlayPolygon'
                  }
                />
                {showCostDebugger && (
                  <TileCostDebugger
                    map={map}
                    isMapLoaded={isMapLoaded}
                    features={selectedFeatures}
                  />
                )}
                <GeoJsonFitter
                  map={map}
                  geoJson={focusedGeometry}
                  fitBoundsOptions={fitBoundsOptions}
                />
                <MapStyle map={map} style={mapStyle} />

                <MapSettingsControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  position={DEFAULT_CONTROL_POSITION}
                  activeTool={activeTool}
                  setActiveTool={setActiveTool}
                  profile={profile}
                  showCostDebugger={showCostDebugger}
                  setShowCostDebugger={setShowCostDebugger}
                />

                <ShareControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  position={DEFAULT_CONTROL_POSITION}
                  imageRefs={imageRefs}
                />
                {featureData && (
                  <DraggableLegendWithSlider
                    imageRefs={imageRefs}
                    layer={layer}
                    index={index}
                    offset={{left: mapPaddingOptions.left}}
                    firebaseToken={firebaseToken}
                    featureData={featureData}
                    polygonDispatch={polygonDispatch}
                    polygon={
                      currentFeatureGeometry as geojson.Polygon | geojson.MultiPolygon | null
                    }
                    overlayMask={overlayMask}
                  />
                )}
                <MapShowPropertyBoundariesControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  position={DEFAULT_CONTROL_POSITION}
                  showPropertyBoundaries={showPropertyBoundaries}
                  setShowPropertyBoundaries={setShowPropertyBoundaries}
                />
                <MapTilesLoadingControl map={map} isMapLoaded={isMapLoaded} />
                <MeasureToolButton
                  imageRefs={imageRefs}
                  notesState={notesState}
                  notesActions={notesActions}
                  map={map}
                  isMapLoaded={isMapLoaded}
                  position={DEFAULT_CONTROL_POSITION}
                  isOpen={activeTool === 'measureDistance' || activeTool === 'measureArea'}
                  setIsOpen={(isOpen) => setActiveTool(isOpen ? 'measureDistance' : null)}
                  disabled={showTerrain}
                  measurementSystem={measurementSystem}
                  polygonDispatch={polygonDispatch}
                  polygonState={polygonState}
                  activeTool={activeTool}
                  setActiveTool={setActiveTool}
                  mapIndex={index as ImageRefIndex}
                />
                <PixelInfo
                  map={map}
                  isMapLoaded={isMapLoaded}
                  position={DEFAULT_CONTROL_POSITION}
                  cursor={cursor}
                  firebaseToken={firebaseToken}
                  featureData={featureData}
                  polygon={currentFeatureGeometry}
                  activeLayerKey={layerKey}
                  featureCollection={selectedFeatureCollection}
                  disabled={!!overlayMask}
                  panelIndex={index}
                />
                <AnalyzePolygonTool
                  map={map}
                  isMapLoaded={isMapLoaded}
                  polygonState={polygonState}
                  polygonDispatch={polygonDispatch}
                  preventDrawLayer={
                    notesState.addPendingNoteGeometryMode === 'polygon' ||
                    notesState.addPendingNoteGeometryMode === 'rect'
                  }
                  drawingMode={
                    polygonState.analyzeAreaDrawMode === 'drawRectangle'
                      ? 'draw_rectangle'
                      : 'draw_polygon'
                  }
                  isOpen={activeTool === 'analyzePolygon' || activeTool === 'measureArea'}
                />

                {tabbedSidebarState.isExpanded &&
                  tabbedSidebarState.currentViewTitle === 'Order' &&
                  orderImageryState.orderSubset &&
                  activeTool !== 'analyzePolygon' && (
                    <DrawLayer
                      map={map}
                      isMapLoaded={isMapLoaded}
                      visible={true}
                      drawingMode="draw_rectangle"
                      editRectangle={true}
                      feature={orderImageryState.subsetFeature}
                      setFeature={setSubsetFeature}
                    />
                  )}
                <MiniMapControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  selectedFeatureIds={selectedFeatureIds}
                  featureCollection={selectedFeatureCollection}
                  selectFeatureById={selectFeatureById}
                  hoveredFeatureId={hoveredFeatureIds.first() ?? null}
                  hoverFeatureById={hoverFeatureById}
                />
                <NoteVector
                  map={map}
                  notesState={notesState}
                  notesActions={notesActions}
                  isMapLoaded={isMapLoaded}
                  isVisible={showNotes}
                  onClickNoteMarkerCallback={onClickNoteMarker}
                  filterNotesCallback={filterNotesCallback}
                />
                <EditNoteGeometry
                  map={map}
                  isMapLoaded={isMapLoaded}
                  visible={showNotes}
                  notesState={notesState}
                  notesActions={notesActions}
                />
              </>
            )}
          </MapInteractionProvider>
        );
      }}
    </ConnectedMap>
  );
};

export default Map;

interface MapInteractionProviderValue {
  selectFeatureById: (fId: number) => void;
  hoveredFeatureIds: I.Set<number>;
  hoverFeatureById: (fId: number | null) => void;
}

/**
 * Provider to manage hovering and clicking for the FeatureCollectionVector and
 * MiniMapControl.
 *
 * Translates hovers and clicks appropriately for multi-feature properties. For
 * example, clicking on a part of a multi-feature property will select the whole
 * of it, unless you’re switching from another piece of that same property.
 *
 * Likewise, hovering will match the same behavior as clicking.
 */
export const MapInteractionProvider: React.FunctionComponent<{
  features: I.ImmutableOf<ApiFeature[]>;
  selectedFeatureIds: I.Set<number>;
  setSelectedFeatureIds: (lensIds: I.Set<string>) => void;
  children: (val: MapInteractionProviderValue) => React.ReactElement;
}> = ({features, selectedFeatureIds, setSelectedFeatureIds, children}) => {
  const featuresById = React.useMemo(
    () =>
      features
        .groupBy((f) => f!.get('id'))
        // ids are unique so we know we’ll have exactly 1 per group
        .map((a) => a!.first())
        .toMap(),
    [features]
  );

  const featuresByName = React.useMemo(
    () => features.groupBy((f) => f!.getIn(['properties', 'name'])).toMap(),
    [features]
  );

  const selectedFeatureName = !selectedFeatureIds.isEmpty()
    ? featuresById.get(selectedFeatureIds.first()).getIn(['properties', 'name'])
    : null;

  const [hoveredFeatureIds, setHoveredFeatureIds] = useStateWithDeps<I.Set<number>>(I.Set(), [
    selectedFeatureIds,
  ]);

  const selectFeatureById = React.useCallback(
    (id: number) => {
      const feature = featuresById.get(id);
      const newSelectedFeatureName = feature.getIn(['properties', 'name']);

      if (selectedFeatureName === newSelectedFeatureName) {
        // If the new feature has the same name as the old, only select the new
        // feature. We’re navigating among a multi-feature property.
        setSelectedFeatureIds(I.Set([feature.getIn(['properties', 'lensId'])!]));
      } else {
        // Otherwise, select every property with the same name as the one being
        // clicked on. For single properties, that will be the one, but for
        // multi-feature properties this will lead to the zoomed out,
        // all-selected view.
        setSelectedFeatureIds(
          featuresByName
            .get(newSelectedFeatureName)
            .map((f) => f!.getIn(['properties', 'lensId'])!)
            .toSet()
        );
      }
    },
    [setSelectedFeatureIds, featuresById, featuresByName, selectedFeatureName]
  );

  const hoverFeatureById = React.useCallback(
    (hoveredFeatureId: number | null) => {
      setHoveredFeatureIds((oldHoveredFeatureIds) => {
        if (hoveredFeatureId === null) {
          return I.Set();
        }

        const hoveredFeatureName = featuresById.get(hoveredFeatureId).getIn(['properties', 'name']);

        let newHoveredFeatureIds: I.Set<number>;

        if (hoveredFeatureName === selectedFeatureName) {
          newHoveredFeatureIds = I.Set([hoveredFeatureId]);
        } else {
          newHoveredFeatureIds = featuresByName
            .get(hoveredFeatureName)
            .map((f) => f!.get('id'))
            .toSet();
        }

        // Avoid unnecessary re-rendering when moving the mouse over properties
        // by trying to maintain object equality.
        return I.is(oldHoveredFeatureIds, newHoveredFeatureIds)
          ? oldHoveredFeatureIds
          : newHoveredFeatureIds;
      });
    },
    [featuresById, featuresByName, selectedFeatureName, setHoveredFeatureIds]
  );

  return children({
    selectFeatureById,
    hoveredFeatureIds,
    hoverFeatureById,
  });
};
