import * as turf from '@turf/turf';
import geojson from 'geojson';
import I from 'immutable';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  ApiFeatureCollectionLayersView,
  ApiFeatureCollectionLensView,
  ApiFeatureCollectionTableAggregateView,
  ApiFeatureCollectionView,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {ApiProject, FeatureCollectionKind} from 'app/modules/Remote/Project';
import * as CONSTANTS from 'app/utils/constants';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';

import {UTMArea} from './geoJsonUtils';
import {findProjectInProjects} from './projectUtils';

const FIRST_PROCESSED_IMAGERY_YEAR = 2019;

export function uniqueFeatureCollectionNames(
  overlayFeatureCollections: I.ImmutableListOf<ApiFeatureCollection>
) {
  return (
    overlayFeatureCollections
      .map((fc) => fc!.get('name'))
      // uniquifies the names
      .toSet()
      .sort((nameA, nameB) => featureUtils.alphanumericSort(nameA, nameB))
      // need a list so we have a numeric index
      .toList()
  );
}

export function getTableViews(featureCollection: HydratedFeatureCollection) {
  return (
    featureCollection
      .get('views')
      // "any" because the Immutable wrappers don’t play super well with
      // discriminated unions.
      .filter((v: I.ImmutableOf<any>) => v.get('type') === CONSTANTS.VIEW_TYPE_TABLE) as I.Iterable<
      number,
      I.ImmutableOf<ApiFeatureCollectionTableAggregateView>
    >
  );
}

export function getAggregateViews(featureCollection: HydratedFeatureCollection) {
  return (
    featureCollection
      .get('views')
      // "any" because the Immutable wrappers don’t play super well with
      // discriminated unions.
      .filter(
        (v: I.ImmutableOf<any>) => v.get('type') === CONSTANTS.VIEW_TYPE_AGGREGATE
      ) as I.Iterable<number, I.ImmutableOf<ApiFeatureCollectionTableAggregateView>>
  );
}

export function getLayersView(featureCollection: I.ImmutableOf<ApiFeatureCollection>) {
  return (
    featureCollection
      .get('views')
      // "any" because the Immutable wrappers don’t play super well with
      // discriminated unions.
      .find((v: I.ImmutableOf<any>) => v.get('type') === CONSTANTS.VIEW_TYPE_LAYERS) as
      | I.ImmutableOf<ApiFeatureCollectionLayersView>
      | undefined
  );
}

export function getLensView(featureCollection: I.ImmutableOf<ApiFeatureCollection>) {
  return (
    featureCollection
      .get('views')
      // "any" because the Immutable wrappers don’t play super well with
      // discriminated unions.
      .find((v: I.ImmutableOf<any>) => v.get('type') === CONSTANTS.VIEW_TYPE_LENS) as
      | I.ImmutableOf<ApiFeatureCollectionLensView>
      | undefined
  );
}

/**
 * Uses an updater function to return a value that can be used to update a
 * feature collection’s Lens view (adding it if it doesn’t exist).
 */
export function updateLensView(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  updater: (
    v: I.ImmutableOf<ApiFeatureCollectionLensView>
  ) => I.ImmutableOf<ApiFeatureCollectionLensView>
): I.MergesInto<I.ImmutableOf<ApiFeatureCollection>> {
  let views = featureCollection.get('views', I.List<I.ImmutableOf<ApiFeatureCollectionView>>());

  const lensViewIdx = views.findIndex((v: any) => v.get('type') === 'lens');

  if (lensViewIdx >= 0) {
    views = views.update(lensViewIdx, (view) =>
      updater(view as I.ImmutableOf<ApiFeatureCollectionLensView>)
    );
  } else {
    const lensView: ApiFeatureCollectionLensView = {
      type: 'lens',
      id: 'lens',
      name: 'Lens',
    };

    views = views.push(updater(I.fromJS(lensView)));
  }

  return {views};
}

export function getBounds(
  featureCollection: HydratedFeatureCollection,
  features = I.Set<I.ImmutableOf<ApiFeature>>()
) {
  if (features.size) {
    return turf.bbox(geoJsonUtils.featureCollection(features.toJS()));
  } else if (featureCollection.get('bounds')) {
    return turf.bbox(
      geoJsonUtils.featureCollection([
        geoJsonUtils.feature(featureCollection.get('bounds')?.toJS(), {}),
      ])
    );
  }
}

/**
 * Checks whether the bounds of the featureCollection intersect with the bounds
 * of the contiguous US. Option to include Alaska (AK), Hawaii (HI), and/or
 * Puerto Rico (PR) in the evaluation.
 *
 * Caveat: Since we are using bounding boxes here it is possible for this still
 * to return true for areas outside the US.
 */
export function featureCollectionInUS(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  options?: {include?: geoJsonUtils.USNonContiguousStates[]}
): boolean {
  const fcBounds = featureCollection.get('bounds');

  // Bounds are null if the feature collection is empty, in which case we return
  // `false` because we can’t evaluate its intersection with the U.S.
  if (!fcBounds) {
    return false;
  }

  const bboxes: geojson.Polygon[] = [
    geoJsonUtils.US_CONTIGUOUS_BBOX,
    // Add a bounding box for each requested non-contiguous state.
    ...(options?.include || []).map((s) => geoJsonUtils.US_NONCONTIGUOUS_BBOXES[s]),
  ];

  const fcBoundsJS: geojson.Polygon | geojson.MultiPolygon = fcBounds.toJS();
  return bboxes.some((bbox) => geoJsonUtils.intersectGeometries(fcBoundsJS, bbox));
}

/**
 * Checks whether the bounds of the featureCollection intersect with the
 * intended bounds
 *
 * Caveat: Since we are often using bounding boxes here it is possible for this still
 * to return true for areas outside the selected region.
 */
export function featureCollectionInBounds(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  bounds: geojson.Polygon | geojson.MultiPolygon
): boolean {
  const fcBounds = featureCollection.get('bounds');

  // Bounds are null if the feature collection is empty
  if (!fcBounds) {
    return false;
  }

  const fcBoundsJS: geojson.Polygon | geojson.MultiPolygon = fcBounds.toJS();
  return !!geoJsonUtils.intersectGeometries(fcBoundsJS, bounds);
}

export function featureInUS(
  feature: I.ImmutableOf<ApiFeature>,
  options?: {include?: geoJsonUtils.USNonContiguousStates[]}
) {
  const bboxes: geojson.Polygon[] = [
    geoJsonUtils.US_CONTIGUOUS_BBOX,
    // Add a bounding box for each requested non-contiguous state.
    ...(options?.include || []).map((s) => geoJsonUtils.US_NONCONTIGUOUS_BBOXES[s]),
  ];

  return bboxes.some((bbox) =>
    geoJsonUtils.intersectGeometries(feature.get('geometry').toJS(), bbox)
  );
}

export function featureInBounds(
  feature: I.ImmutableOf<ApiFeature>,
  bounds: geojson.Polygon | geojson.MultiPolygon
) {
  return !!geoJsonUtils.intersectGeometries(feature.get('geometry').toJS(), bounds);
}

/**
 * Returns the primary featureCollection for a project, or null if non exist.
 */
export function findPrimaryFeatureCollection(
  project: I.ImmutableOf<ApiProject>,
  featureCollectionsById: I.Map<number, I.ImmutableOf<ApiFeatureCollection>>
): I.ImmutableOf<ApiFeatureCollection> | null {
  const [primaryFeatureCollection] = getFeatureCollectionsByType(project, featureCollectionsById);
  return primaryFeatureCollection;
}

/**
 * Returns the primary `featureCollection` for a project and a list of the `overlayFeatureCollections`. Filters out
 * any archived featureCollections
 */
export function getFeatureCollectionsByType(
  project: I.ImmutableOf<ApiProject>,
  featureCollectionsById: I.Map<number, I.ImmutableOf<ApiFeatureCollection>>
): [I.ImmutableOf<ApiFeatureCollection> | null, I.ImmutableOf<ApiFeatureCollection[]>] {
  let featureCollectionsMap: I.Map<
    FeatureCollectionKind,
    I.ImmutableOf<ApiFeatureCollection[]>
  > = I.Map();

  project.get('featureCollections').forEach((pfc) => {
    const kind = pfc!.get('kind');
    const featureCollection = featureCollectionsById.get(pfc!.get('id'));

    // We should always have a featureCollection, since all projects should reference real feature
    // collections, but we check for it just in case.
    // There may be archived featureCollections that are still listed on the project, it is important we filter
    // these out here so we don't select a primary featureCollection that has been archived. It is possible
    // to have multiple primary featureCollections that are archived, but only one should still be valid.
    if (!featureCollection || featureCollection.get('isArchived') === true) {
      return;
    }

    featureCollectionsMap = featureCollectionsMap.update(kind, I.List([]), (list) =>
      list.push(featureCollection)
    );
  });

  // We assume there is only one non-archived primary featureCollection per project. Return
  // null if no primary featureCollection is found
  const primaryFeatureCollection = featureCollectionsMap.get('primary', I.List()).first() || null;
  const overlayFeatureCollections = featureCollectionsMap.get('overlay', I.List());

  return [primaryFeatureCollection, overlayFeatureCollections];
}

/**
 * Returns the total area of all the features in a feature collection in the
 * desired unit (Acres or Hectares)
 */
export function featureCollectionArea(
  featureCollection: HydratedFeatureCollection,
  areaUnit: typeof CONSTANTS.UNIT_AREA_ACRE | typeof CONSTANTS.UNIT_AREA_HECTARE
) {
  return featureUtils.featureM2ToArea(
    UTMArea(featureCollection.toJS() as geojson.FeatureCollection),
    {
      unit: areaUnit,
    }
  );
}

/**
 * Creates a list of years, most recent to least recent, that we’ve processed
 * imagery for. If processing start date is null, the oldest year included is
 * the first year we have imagery availability for in Lens. If the processing
 * end date is null, the most recent year included is the current year. The
 * current year may be overriden by providing an optional argument to avoid test
 * failures when we enter a new year.
 *
 * HACK: Throughout the application, we use the browser’s locale to deduce the
 * year. Because processing start dates are usually the beginning of the year in
 * UTC (e.g., 2021-01-01T00:00:00+00:00), this is parsed as the previous year
 * (e.g., 2020) in timezones between the international date line and prime
 * meridian. We don’t really want to include this year in our list of processed
 * imagery years because in practice, it only represents a few hours at the end
 * of the year, which is unlikely to contain any images or notes. We eventually
 * may want to use UTC throughout the application to improve our precision, but
 * for now, it’s probably fine to just use UTC for the start year since this
 * list is really only used as a shortcut filter (if there are images or notes
 * from the excluded window, they will still be accessible in the feature page).
 */
export function getProcessedImageryYears(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  currentYear: number = new Date().getFullYear()
) {
  const startDate = featureCollection.get('processingStartDate');
  const endDate = featureCollection.get('processingEndDate');

  const startYear = startDate ? new Date(startDate).getUTCFullYear() : FIRST_PROCESSED_IMAGERY_YEAR;
  const endYear = endDate ? new Date(endDate).getFullYear() : currentYear;

  const years: number[] = [];

  for (let y = endYear; y >= startYear; y--) {
    years.push(y);
  }

  return years;
}

export const getPrimaryFeatureCollectionIdFromProjectId = (
  projectId: string,
  projects: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<ApiProject>>>,
  featureCollectionsById: I.Map<number, I.ImmutableOf<ApiFeatureCollection>>
): number | undefined => {
  const project = findProjectInProjects(projects, projectId);
  const featureCollection = findPrimaryFeatureCollection(project!, featureCollectionsById);
  return featureCollection?.get('id');
};

/**
 * Used as a proxy to see if a source for an orderable
 * scene is highResTruecolor in places where we don't have direct
 * information about the layer for the scene
 */
export const sourceHasHighResTruecolor = (
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  sourceId: string
) => {
  return !!featureCollection
    .getIn(['processingConfig', 'enrolledLayers', sourceId])
    ?.contains('high-res-truecolor');
};
/**
 * Convert a feature collection and set of features to a hydrated feature collection.
 */
export function hydratedFeatureCollection(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  features: I.List<I.ImmutableOf<ApiFeature>>
): HydratedFeatureCollection {
  // We cant cast straight to hydrated feature collection because we wont have features
  //  at the time of the cast (though we do immediately aftewards.)
  return (featureCollection as unknown as HydratedFeatureCollection).set('features', features);
}

/**
 * Casts a HydratedFeatureCollection as an unhydrated one to use in util functions that can
 * apply to either. If a util function does not reference the features, it does not need to
 * be hydrated so they are typed to support I.ImmutableOf<ApiFeatureCollection>
 */
export function hydratedFeatureCollectionAsApiFeatureCollection(
  hydratedFeatureCollection: HydratedFeatureCollection
): I.ImmutableOf<ApiFeatureCollection> {
  return hydratedFeatureCollection as unknown as I.ImmutableOf<ApiFeatureCollection>;
}
