import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import cs from './LensLibraryView.styl';
import {LibraryDatasetType} from './LibraryTypes';

export const LibraryItemCard: React.FunctionComponent<
  React.PropsWithChildren<{
    added?: boolean;
    thumbnailUrl?: string;
    heading: string | JSX.Element;
    subheading?: string | JSX.Element;
    resolution?: string | JSX.Element;
    detail?: string | JSX.Element;
    actionButton: JSX.Element;
    layerKey?: string;
    setDetailsModalOpen?: (layerKey: string) => void;
    noDetailView?: boolean;
    type?: LibraryDatasetType;
  }>
> = ({
  thumbnailUrl,
  heading,
  subheading,
  resolution,
  detail,
  actionButton,
  layerKey,
  setDetailsModalOpen,
  noDetailView = false,
  type,
}) => {
  return (
    <div
      className={classnames(cs.card, {
        [cs.noDetailViewCard]: noDetailView,
        [cs.noModalCard]: !setDetailsModalOpen,
      })}
      onClick={() => (setDetailsModalOpen && layerKey ? setDetailsModalOpen(layerKey) : null)}
    >
      <div className={cs.cardThumbnail}>
        {thumbnailUrl && <img src={thumbnailUrl} />}
        <div className={cs.cardActionButton}>{actionButton}</div>
      </div>
      <div className={cs.cardInfo}>
        <div>
          <div className={cs.cardHeader}>
            <div className={cs.cardHeaderText}>{heading}</div>
            <div className={cs.cardHeaderTags}>
              {type && (
                <div className={classnames(cs.cardHeaderTag, cs.datasetType)}>
                  <B.Tag minimal round>
                    {type}
                  </B.Tag>
                </div>
              )}
              <div className={cs.cardHeaderTag}>{resolution}</div>
            </div>
          </div>
          <div className={cs.cardSubhead}>{subheading}</div>
        </div>
        {!noDetailView && <div className={cs.cardDetail}>{detail || ''}</div>}
      </div>
    </div>
  );
};

export const layerRequestCard = () => {
  return (
    <LibraryItemCard
      actionButton={
        <B.Button
          text={'Request a dataset'}
          onClick={() =>
            window.open(
              'https://form.asana.com/?k=rhN6WoSQ2DkRh0Xu2k2VYw&d=253187955686276',
              '_blank'
            )
          }
        />
      }
      heading={"Have an idea for a dataset you'd like to see in Lens?"}
      subheading={"We'd love to hear from you"}
      noDetailView={true}
      thumbnailUrl={'https://storage.googleapis.com/upstream-icons/layers/Request%20Layers.png'}
    />
  );
};
