import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import * as I from 'immutable';
import React from 'react';

import List from 'app/components/List';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {FeaturesActions} from 'app/providers/FeaturesProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as projectUtils from 'app/utils/projectUtils';

import cs from './MovePropertiesMenu.styl';

/**
 * Popover-based dropdown to show the list of portfolios a user can move
 * properties to.
 *
 * The children passed to this component will be the button that triggers the
 * popover.
 */
const MovePropertiesMenu: React.FunctionComponent<{
  featureCollectionId: number;
  projectId: string;
  features: I.ImmutableOf<ApiFeature[]>;
  children: JSX.Element;
  moveFeatures: FeaturesActions['moveFeatures'];
  setIsUpdatingFeatures: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  featureCollectionId,
  projectId,
  features,
  moveFeatures,
  setIsUpdatingFeatures,
  children,
}) => {
  const [projects, featureCollectionsById] = useProjects();

  const confirmMoveFeatures = async (pId, pName) => {
    const multiple = features.size > 1;
    const message = `Are you sure you want to move ${
      multiple ? 'these properties' : 'this property'
    } to the ${pName} portfolio?`;

    const project = projects && projectUtils.findProjectInProjects(projects, pId);

    const newFeatureCollectionId =
      project &&
      featureCollectionUtils
        .findPrimaryFeatureCollection(project, featureCollectionsById)
        ?.get('id');

    // This shouldn't be possible, but if it is alert the user instead of failing silently
    if (!project || !newFeatureCollectionId) {
      window.alert('We weren’t able to move your properties right now. Please try again.');
      Sentry.captureException(
        new Error(
          `Missing project or featureCollectionId: ${project} / ${newFeatureCollectionId}. Cannot move features.`
        )
      );
      return;
    }

    const featureIds: number[] = features.map((f) => f!.get('id')).toArray();

    if (confirm(message)) {
      setIsUpdatingFeatures(true);
      await moveFeatures({
        currentFeatureCollectionId: featureCollectionId,
        newFeatureCollectionId,
        newProjectId: project.get('id'),
        featureIds,
      });
      setIsUpdatingFeatures(false);
    }
  };

  const projectListItems = projects
    ? projects
        .map((p) => {
          return {id: p!.get('id'), text: p!.get('name'), isDisabled: p!.get('id') === projectId};
        })
        .sortBy((project) => project?.text, featureUtils.alphanumericSort)
        .toArray()
    : [
        {
          id: 'no-valid',
          text: 'There are no other portfolios to move properties to',
          isDisabled: true,
        },
      ];

  return (
    <B.Popover
      position={B.Position.BOTTOM_RIGHT}
      modifiers={{arrow: {enabled: false}}}
      content={
        <List
          className={cs.menu}
          items={[
            {id: 'move', text: 'Select a portfolio...', isDisabled: true},
            {id: 'sep', text: '', isSeparator: true},
            ...projectListItems,
          ]}
          onClickItem={({id, text}) => confirmMoveFeatures(id, text)}
        />
      }
    >
      {children}
    </B.Popover>
  );
};

export default MovePropertiesMenu;
