import {bbox} from '@turf/turf';
import geojson from 'geojson';
import isEqual from 'lodash/isEqual';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {BBox2d} from 'app/utils/geoJsonUtils';
import * as mapUtils from 'app/utils/mapUtils';

interface Props {
  map: mapboxgl.Map;
  geoJson: geojson.GeoJSON | null;
  fitBoundsOptions?: mapboxgl.FitBoundsOptions;
  resizeBeforeFitting?: boolean;
  geoJsonComparison?: 'shallow' | 'deep';
}

export default class GeoJsonFitter extends React.Component<Props> {
  static defaultProps: Pick<Props, 'geoJsonComparison'> = {
    geoJsonComparison: 'deep',
  };

  componentDidMount() {
    this.zoomToGeoJson(true);
  }

  /**
   * Determine if geoJson prop has changed using a shallow or deep equality
   * comparison. The shallow equality comparison is used in the
   * ManageFeaturesMap to always refit the map to the clicked feature, even if
   * it’s already selected.
   */

  isGeoJsonEqual(nextGeoJson: Props['geoJson']) {
    const {geoJson, geoJsonComparison} = this.props;

    return geoJsonComparison === 'shallow'
      ? nextGeoJson !== geoJson
      : !isEqual(nextGeoJson, geoJson);
  }

  /**
   * We do a comprehensive change check here because we don’t want this
   * component to cause the map to move just because its parent re-rendered.
   *
   * Note that we *don’t* re-render on changes to the fit bounds options, since
   * they only have bearing on the next time the geoJSON changes.
   */
  shouldComponentUpdate(nextProps: Props) {
    return this.isGeoJsonEqual(nextProps.geoJson) || nextProps.map !== this.props.map;
  }

  componentDidUpdate() {
    this.zoomToGeoJson();
  }

  render() {
    return null;
  }

  public zoomToGeoJson(instantaneously = false) {
    const {map, geoJson, fitBoundsOptions, resizeBeforeFitting} = this.props;

    if (geoJson) {
      resizeBeforeFitting && map.resize();

      const bounds = bbox(geoJson) as BBox2d;

      // This call does not need to be gated on whether or not the map’s style
      // has loaded. If we have a Map instance then we know it has done it’s
      // init event and it’s safe to zoom.
      mapUtils.safeFitBounds(
        map,
        bounds,
        {
          ...fitBoundsOptions,
          // Keeps us from zooming when the map mounts, which is janky esp. when
          // loading compare mode.
          ...(instantaneously ? {duration: 0} : {}),
        },
        {source: 'GeoJsonFitter', bounds, padding: fitBoundsOptions?.padding}
      );
    }
  }
}
