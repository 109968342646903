import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';
import uuid from 'uuid/v4';

import {FeaturesApi} from 'app/modules/Remote/api';
import api from 'app/modules/Remote/api';
import {ApiNote} from 'app/modules/Remote/Feature';
import {TagSetting} from 'app/modules/Remote/FeatureCollection/types';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {useFirebaseStorage} from 'app/tools/Firebase';
import {ApiResponseError} from 'app/utils/apiUtils';
import * as CONSTANTS from 'app/utils/constants';
import {NOTE_TYPE_USER} from 'app/utils/constants';
import * as firebaseUtils from 'app/utils/firebaseUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as mathUtils from 'app/utils/mathUtils';
import * as noteUtils from 'app/utils/noteUtils';
import * as userUtils from 'app/utils/userUtils';

export type PendingNoteGeometryMode = 'point' | 'polygon' | 'rect';

export interface NotesState {
  /** True if we’re in the process of fetching notes  */
  loading: boolean;

  /**
   * True if there was an error loading the features.
   *
   * We track loading error as a separate boolean because note loading is very
   * much a side-effect of navigating in the UI. For direct operations, like
   * delete / edit / create, the triggering component should catch errors and
   * show appropriate messages directly.
   */
  loadingError: boolean;

  /** Current notes for the current featureId */
  notes: StateApiNote[];

  hoveredNoteId: number | string | null;
  focusedNoteId: number | string | null;

  addPendingNoteGeometryMode: PendingNoteGeometryMode | false;
  /**
   * We track pending note geometry as a feature so we can have an ID to
   * reference from the drawing library.
   */
  pendingNoteGeometryFeature: geojson.Feature | null;

  mayCreateNotes: boolean;
}

/**
 * Focused down to only the parts of the API we need, so we know what to fake
 * in tests.
 */
export type NotesApi = Pick<FeaturesApi, 'listNotes' | 'createNote' | 'patchNote'>;

export type AlertNoteType = 'vegetationDrop' | 'ownershipChange' | 'customAlert';

// These two predicate functions determine what kind of alert we're dealing with
export const isVegetationDropAlert = (note: StateApiNote): boolean =>
  note.userId === CONSTANTS.ALERT_VEGETATION_DROP_USER_ID;

export const isOwnershipChangeAlert = (note: StateApiNote): boolean =>
  note.userId === CONSTANTS.ALERT_OWNERSHIP_CHANGE_USER_ID;

export const getAlertNoteType = (note: StateApiNote): AlertNoteType => {
  if (isVegetationDropAlert(note)) return 'vegetationDrop';
  else if (isOwnershipChangeAlert(note)) return 'ownershipChange';
  else return 'customAlert';
};

/**
 * Parts of a Note that are required for creating a new one.
 */
export type NewApiNote = Pick<
  StateApiNote,
  'text' | 'geometry' | 'attachments' | 'imageRefs' | 'graph' | 'tagIds' | 'properties'
>;

/**
 * API note + some additional data we calculate.
 *
 * Removes cursor and layerKey to force everyone to use imageRefs.
 */
export type StateApiNote = Omit<ApiNote, 'cursorKey' | 'cursorType' | 'layerKey'> & {
  name: string | null;
  isAuthor: boolean;
  isCreating: boolean;
  isUpdating: boolean;

  imageRefs: mapUtils.MapImageRefs;
};

/**
 * A little utility type which is StateApiNote plus tagSettings, so that we can easily
 * reference all tag metadata for a given note. Keeping this as a separate type for now
 * because we can't always derive tagSettings from tagIds unless we're in a context of a
 * featureCollection.
 */
export interface StateApiNoteWithTagSettings extends StateApiNote {
  tagSettings: TagSetting[] | null;
}

export const DEFAULT_NOTES_STATE: NotesState = {
  loading: false,
  loadingError: false,

  notes: [],

  hoveredNoteId: null,
  focusedNoteId: null,

  addPendingNoteGeometryMode: false as const,
  pendingNoteGeometryFeature: null,

  mayCreateNotes: false,
};

/**
 * Interface for NotesActions operations so we can fake it out without worrying
 * about the private elements in NotesActionsImpl.
 */
export interface NotesActions {
  loadNotes(): Promise<void>;
  deleteNote(note: StateApiNote): Promise<StateApiNote>;
  updateNote(note: StateApiNote, update: Partial<StateApiNote>): Promise<StateApiNote>;
  createNote(note: NewApiNote, opts?: {focus?: boolean}): Promise<StateApiNote>;
  hoverNote(noteId: number | string | null): void;
  focusNote(noteId: number | string | null): void;
  startNewPendingGeometry(mode: PendingNoteGeometryMode): void;
  startEditingPendingGeometry(): void;
  stopEditingPendingGeometry(): void;
  setPendingNoteGeometryFeature(feature: geojson.Feature): void;
  removePendingNoteGeometryFeatureById(id: geojson.Feature['id']): void;
  popPendingNoteGeometry(state: NotesState): geojson.Geometry | null;
  uploadAttachment(file: File): Promise<string>;
  hydrateNote(note: ApiNote): StateApiNote;
}

/**
 * Operations for managing notes: note editing, adding points, &c.
 *
 * In redux terms, think of this as a dispatch function implemented as a class
 * instance, which makes it easier to capture async actions and maintain
 * dependencies.
 *
 * You’ll get an instance of this class, along with a NotesState value, by using
 * the WithNotes component below. The instance will be stable until its
 * dependencies change (in practice, just FeatureCollection).
 */
export class NotesActionsImpl implements NotesActions {
  /**
   * It’s important that we restrict this to the "updater" API for setState. The
   * hook version of setState requires the complete value (it won’t
   * shallow-merge an object for you), so we want to be applying to the latest state always.
   */
  private readonly setState: (r: (prevState: NotesState) => NotesState) => void;

  private readonly profile: I.ImmutableOf<ApiOrganizationUser>;
  /**
   * Map of email -> name for all members of the user’s organization. We create
   * this from the list of ApiOrganizationUsers in the construtor so we can do
   * O(1) name lookups in hydrateNote, which gets called for every note as it’s
   * fetched.
   */
  private readonly emailToNameMap: Map<string, string>;

  private readonly projectId: string;
  private readonly featureId: number | null;
  private readonly notesApi: NotesApi;
  private readonly storage: firebase.storage.Storage;

  constructor(
    setState: (r: (prevState: NotesState) => NotesState) => void,
    profile: I.ImmutableOf<ApiOrganizationUser>,
    organizationUsers: ApiOrganizationUser[],
    projectId: string,
    // Can be null, you just don’t get any notes.
    featureId: number | null,
    notesApi: NotesApi,
    storage: firebase.storage.Storage
  ) {
    this.setState = setState;

    this.profile = profile;
    this.projectId = projectId;
    this.featureId = featureId;
    this.notesApi = notesApi;
    this.storage = storage;

    this.emailToNameMap = new Map();
    organizationUsers.forEach((user) => {
      this.emailToNameMap.set(user.email, user.name);
    });
  }

  /**
   * Loads notes for the current featureID. No-op if there isn’t one.
   */
  public async loadNotes() {
    if (!this.featureId) {
      return;
    }

    this.setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const newNotes: ApiNote[] = (
        await this.notesApi.listNotes(
          this.projectId,
          this.featureId,
          {perPage: 100},
          {
            getAllPages: 2,
          }
        )
      )
        .get('data')
        .toJS();

      this.setState((s) => ({
        ...s,
        // TODO(fiona): Have the API give us the raw JSON to begin with.
        notes: newNotes.map(this.hydrateNote),
        loading: false,
        loadingError: false,
      }));
    } catch (e) {
      this.setState((s) => ({
        ...s,
        notes: [],
        loading: false,
        loadingError: true,
      }));

      throw (e as ApiResponseError).error || e;
    }
  }

  private getNoteName = (note: ApiNote): string | null => {
    if (userUtils.isSystemUserId(note.userId)) {
      return 'Upstream System';
    } else if (userUtils.isUpstreamTeamEmail(note.userId)) {
      return 'Upstream Tech';
    } else {
      // fallback to the note's userId (email address)
      // this case can occur when a user is removed from an organization.
      return this.emailToNameMap.get(note.userId) || note.userId;
    }
  };

  public hydrateNote = (note: ApiNote): StateApiNote => ({
    ...note,
    isCreating: false,
    isUpdating: false,
    isAuthor:
      note.noteType === CONSTANTS.NOTE_TYPE_USER
        ? note.userId === this.profile.get('email')
        : // HACK: All users who have write permissions are "authors" of
          // notifications so that they can delete them.
          getMayCreateNotes(this.profile),
    name: this.getNoteName(note),
    imageRefs: noteUtils.makeImageRefsFromNote(note),
  });

  /**
   * Deletes a note by marking it as archived on the server. Updates our state
   * optimistically. Returns the archived note after the request succeeds.
   */
  public deleteNote(note: StateApiNote) {
    return this.patchNote(note.featureId, note.id, {isArchived: true});
  }

  /**
   * Modifies the provided note by updating with partial note changes.
   * Optimistically updates the container state. Returns the updated note once
   * the request has gone through.
   */
  public updateNote(note: StateApiNote, update: Partial<StateApiNote>) {
    return this.patchNote(note.featureId, note.id, update);
  }

  private async patchNote(
    featureId: number,
    noteId: number | string,
    update: Partial<StateApiNote>
  ): Promise<StateApiNote> {
    if (typeof noteId === 'string') {
      throw new Error('Trying to patch a pending note');
    }

    // We save out the note as it’s in the state now, if it’s there.
    let savedNote: StateApiNote | undefined;

    // Set `isUpdating` property on the note we're about to patch.
    this.setState((s) => ({
      ...s,
      notes: s.notes.map((n) => {
        if (n.id === noteId) {
          savedNote = n;
          return {...n, isUpdating: true};
        } else {
          return n;
        }
      }),
    }));

    let patchedNote: ApiNote;

    if (update.imageRefs) {
      update = {
        ...update,
        ...noteUtils.notePropertiesFromImageRefs(update.imageRefs),
      };

      delete update.imageRefs;
    }

    try {
      patchedNote = (await this.notesApi.patchNote(this.projectId, featureId, noteId, update))
        .get('data')
        .toJS();
    } catch (e) {
      // If there was an error, set it back. This is not 100% right because the
      // note might have changed in the meantime, and we’re reverting to the
      // checkpoint that we made earlier in this method.
      //
      // TODO(fiona): Is it worth triggering a full reload of the notes from the
      // server at this point?
      this.setState((s) => ({
        ...s,
        notes: s.notes.map((n) => (n.id === noteId && savedNote) || n),
      }));

      throw e;
    }

    const hydratedPatchedNote = this.hydrateNote(patchedNote);

    this.setState((s) => ({
      ...s,
      notes: s.notes
        .map((n) => (n.id === patchedNote.id ? hydratedPatchedNote : n))
        .filter((n) => !n.isArchived),
    }));

    return hydratedPatchedNote;
  }

  /**
   * Creates a new note. Adds it to the store optimistically. Returns the note
   * after it’s been successfully created.
   *
   * Note: while the request is active, the note in the store will have a
   * "pending" note ID. It will be updated to the server’s numeric ID once the
   * request completes.
   */
  public async createNote(note: NewApiNote, opts: {focus?: boolean} = {}) {
    if (!this.featureId) {
      throw new Error('Trying to create a new note when no feature is selected');
    }

    const createdAt = new Date().toISOString();

    // This is the note that’s optimistically added to the notes[] state while
    // we’re waiting for the API request to complete. There’s some dummy data in
    // here so that the ApiNote typing checks out.
    const pendingNote: StateApiNote = {
      id: `pending-${mathUtils.randomCharacters()}`,
      displayNumber: null,
      featureId: this.featureId,
      userId: this.profile.get('email'),

      featureCollectionId: -1,
      organizationId: '',
      projectId: this.projectId,

      isArchived: false,

      createdAt,
      updatedAt: createdAt,

      noteType: NOTE_TYPE_USER,

      ...note,

      isCreating: true,
      isUpdating: false,
      isAuthor: true,
      name: this.profile.get('name'),
    };

    // Only add the note to the list if it matches our current feature ID.
    this.setState((s) => ({
      ...s,
      notes: [...s.notes, pendingNote],
      // If "focus" is set, we update the focused note to the one just
      // created so that it gets highlighted and scrolled into view.
      focusedNoteId: opts.focus ? pendingNote.id : s.focusedNoteId,
    }));

    let newNote: StateApiNote;

    try {
      const newApiNote = await this.notesApi.createNote(this.projectId, this.featureId, {
        text: note.text,
        attachments: note.attachments,
        geometry: note.geometry,
        graph: note.graph,
        tagIds: note.tagIds,
        properties: note.properties,
        ...noteUtils.notePropertiesFromImageRefs(note.imageRefs),
      });

      newNote = this.hydrateNote(newApiNote.get('data').toJS());
    } catch (e) {
      // If there was an error, delete the pending note
      this.setState((s) => ({
        ...s,
        notes: s.notes.filter((n) => n.id !== pendingNote.id),
      }));

      throw e;
    }

    this.setState((s) => ({
      ...s,
      notes: s.notes.map((n) => (n.id === pendingNote.id ? newNote : n)),

      // catch the case where we’re focused / hovered on the note in its pending
      // state. Need to update to the new ID the server gave us.
      focusedNoteId: s.focusedNoteId === pendingNote.id ? newNote.id : s.focusedNoteId,
      hoveredNoteId: s.hoveredNoteId === pendingNote.id ? newNote.id : s.hoveredNoteId,
    }));

    return newNote;
  }

  public hoverNote(noteId: number | string | null) {
    this.setState((s) => (s.hoveredNoteId !== noteId ? {...s, hoveredNoteId: noteId} : s));
  }

  public focusNote(noteId: number | string | null) {
    this.setState((s) => (s.focusedNoteId !== noteId ? {...s, focusedNoteId: noteId} : s));
  }

  /**
   * Helper to be consistent about how we switch into an editing mode.
   */
  private applyPendingNoteGeometryMode(state: NotesState, mode: PendingNoteGeometryMode | false) {
    return {
      ...state,
      // If we’re drawing a polygon, we need to de-focus any existing
      // notes so we don’t get cluttered with polygons on the screen. We
      // leave it up for points, however, since users might want to put
      // the point in a polygon.
      focusedNoteId: mode === 'polygon' || mode === 'rect' ? null : state.focusedNoteId,
      addPendingNoteGeometryMode: mode,
    };
  }

  public startNewPendingGeometry(mode: PendingNoteGeometryMode) {
    this.setState((s) =>
      this.applyPendingNoteGeometryMode({...s, pendingNoteGeometryFeature: null}, mode)
    );
  }

  public startEditingPendingGeometry() {
    this.setState((s) => {
      if (s.pendingNoteGeometryFeature) {
        const mode = modeFromPendingGeometry(s.pendingNoteGeometryFeature.geometry);
        return this.applyPendingNoteGeometryMode(s, mode);
      } else {
        return s;
      }
    });
  }

  public stopEditingPendingGeometry() {
    this.setState((s) => this.applyPendingNoteGeometryMode(s, false));
  }

  public setPendingNoteGeometryFeature(feature: geojson.Feature) {
    this.setState((s) => ({...s, pendingNoteGeometryFeature: feature}));
  }

  /**
   * Removes the given feature if it is, in fact, the current pending note
   * geometry.
   */
  public removePendingNoteGeometryFeatureById(id: geojson.Feature['id']) {
    this.setState((s) =>
      s.pendingNoteGeometryFeature && s.pendingNoteGeometryFeature.id === id
        ? {...s, pendingNoteGeometryFeature: null}
        : s
    );
  }

  /**
   * Returns the pending note geometry and clears it from the store. Use this
   * when collecting the pending geometry for creating a note.
   */
  public popPendingNoteGeometry(state: NotesState): geojson.Geometry | null {
    const feature = state.pendingNoteGeometryFeature;

    this.setState((s) => ({
      ...s,
      addPendingNoteGeometryMode: false,
      pendingNoteGeometryFeature: null,
    }));

    return feature && feature.geometry;
  }

  // Uploads a file to Cloud Storage for Firebase, returning a URL that may then
  // be saved on the note’s `attachments` property.
  //
  // We use the “Resize Images” Firebase extension to automatically create
  // resized images when a note image attachment is uploaded to Cloud Storage.
  // It works by running the ext-storage-resize-images-generateResizedImage
  // function every time an image is uploaded to the monitron-dev.appspot.com
  // bucket with a /monitoring/*/notes path. Two resized versions of the image
  // are created, one with a maximum dimension of 300px and one with a maximum
  // dimension of 1500px, and saved in the /monitoring/*/notes/*/resized
  // directory. More information about the configuration may be found in the
  // Firebase console:
  // https://console.firebase.google.com/project/monitron-dev/extensions.
  //
  // These resized image URLs may then be used in interfaces that benefit from
  // smaller image sizes, such as thumbnails and PDF exports. Note that any note
  // image attachments uploaded before 06/29/2021 will not have resized images.
  // Any interface code where a resized image is used should have conditional
  // logic to fallback to the original image URL in the case where the desired
  // resized image URL is missing.
  public async uploadAttachment(file: File) {
    const fileId = uuid();
    const filepath = `monitoring/${this.featureId}/notes/${fileId}/${file.name}`;
    return firebaseUtils.uploadFile(this.storage, file, filepath);
  }
}

/**
 * Maintains a NotesState/NotesActions pair. Child must be a function whose
 * arguments are a NotesState and a NotesActions, in that order.
 *
 * Gives a fresh NotesActions object and loads the new notes when any of the
 * dependencies (projectId, featureCollectionId, featureId) change.
 *
 * This "provider" does not actually set up a context, since in our current
 * use cases just having the notesStore/notesActions values lexically scoped
 * from the children render function is fine.
 */
export const NotesProvider: React.FunctionComponent<{
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organizationUsers: ApiOrganizationUser[];
  projectId: string;
  featureCollectionId: number;
  featureId: number | null;
  initialStateForTest?: NotesState;
  children: (state: NotesState, actions: NotesActions) => JSX.Element | null;
  focusedNoteId: number | null;
}> = ({
  profile,
  organizationUsers,
  projectId,
  featureCollectionId,
  featureId,
  focusedNoteId,
  initialStateForTest,
  children,
}) => {
  const storage = useFirebaseStorage();

  const [state, setState] = hookUtils.useStateWithDeps(
    initialStateForTest || {
      ...DEFAULT_NOTES_STATE,
      focusedNoteId,
      mayCreateNotes: getMayCreateNotes(profile),
    },
    [profile.get('email'), projectId, featureCollectionId, featureId]
  );

  const actions = React.useMemo(
    () =>
      new NotesActionsImpl(
        setState,
        profile,
        organizationUsers,
        projectId,
        featureId,
        api.featureCollections.features(featureCollectionId),
        storage
      ),
    [setState, profile, organizationUsers, projectId, featureId, featureCollectionId, storage]
  );

  // We trigger loadNotes when setState changes, since that’s the signal that
  // we’ve changed features and need to load new notes. We don’t depend on
  // actions since it could technically change since useMemo is not a guarantee.
  React.useEffect(() => {
    // We don’t fetch if we’re faking our state for tests. Prevents us from showing
    // Firebase auth errors in Storybook / Storyshots.
    if (!initialStateForTest) {
      actions.loadNotes();
    }
  }, [setState]);

  return children(state, actions);
};

function getMayCreateNotes(profile: I.ImmutableOf<ApiOrganizationUser>) {
  // READONLY users can’t make notes.
  return profile.get('role') !== CONSTANTS.USER_ROLE_READONLY;
}

function modeFromPendingGeometry(
  pendingGeometry: geojson.Geometry
): PendingNoteGeometryMode | false {
  switch (pendingGeometry.type) {
    case 'Polygon':
      return 'polygon';
    case 'Point':
      return 'point';
    default:
      console.warn('Unsupported pending geometry for editing', pendingGeometry);
      return false;
  }
}

export function getAttachmentsByType(attachments: string[] | null) {
  const imageExtensions = ['.jpg', '.jpeg', '.tif', '.gif', '.png'];

  const out: {images: string[]; documents: string[]} = {
    images: [],
    documents: [],
  };

  (attachments || []).forEach((a) => {
    const fileName = firebaseUtils.getFileNameFromObjectDownloadUrl(a);

    if (fileName) {
      // Regex `i` flag is important for matching capitalized file extensions (e.g., `.JPG`).
      const isImage = imageExtensions.some((ext) => fileName.match(new RegExp(`${ext}$`, 'i')));
      if (isImage) {
        out.images.push(a);
      } else {
        out.documents.push(a);
      }
    }
  });

  return out;
}

export function handleDeleteNotes(
  notesActions: NotesActions,
  notes: StateApiNote[],
  skipConfirm = false
): boolean {
  // Exit condition:
  //  We have an empty array of notes: we have nothing, so do nothing.
  if (notes.length === 0) {
    return false;
  }
  const isBulk = notes.length > 1;
  const [noteTypes, isHomogeneous] = notes.reduce(
    ([t, homogeneous], n) => (n.noteType === t ? [t, homogeneous] : [t, false]),
    [notes[0].noteType, true]
  );

  // Exit condition:
  //  We're attempting to dismiss a bunch of different types. This is almost
  //    certainly an error because of the different contexts within which we
  //    present each. If this _isnt_ an error for a specific use, remove the
  //    homogeneous check, above.
  if (!isHomogeneous) {
    return false;
  }

  const messaging = {
    general: `Are you sure you want to archive ${
      isBulk ? 'these notifications' : 'this notification'
    }?`,
    notes: `Are you sure you want to archive ${isBulk ? 'these notes' : 'this note'}?`,
    alerts: `Are you sure you want to archive ${isBulk ? 'these alerts' : 'this alert'}?`,
  };

  let message = messaging.general;
  if (noteTypes === 'user') {
    message = messaging.notes;
  } else if (noteTypes === 'alert') {
    message = messaging.alerts;
  }

  if (skipConfirm || confirm(message)) {
    notes.forEach((n) => notesActions.deleteNote(n));
    return true;
  }
  return false;
}
