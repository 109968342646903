import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import debounce from 'lodash/debounce';
import React from 'react';
import {useDrag, useDrop} from 'react-dnd';
import shortUuid from 'short-uuid';

import MapOverlayDialog from 'app/components/MapOverlayDialog/MapOverlayDialog';
import {ApiFeatureCollection, TagSetting} from 'app/modules/Remote/FeatureCollection';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import {useContinuity, useStateWithDeps} from 'app/utils/hookUtils';
import * as immutableUtils from 'app/utils/immutableUtils';
import * as tagUtils from 'app/utils/tagUtils';

import cs from './CustomizeTagsDialog.styl';
import SwatchColorPicker, {DEFAULT_COLORS} from './SwatchColorPicker';

const DEFAULT_COLOR = DEFAULT_COLORS[0];

/**
 * Modal dialog for modifying a feature collection’s tag settings (name, color,
 * &c.).
 */
const CustomizeTagsModal: React.FunctionComponent<
  React.PropsWithChildren<{
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    kind: tagUtils.TAG_KIND;
    updateFeatureCollection: (
      id: number,
      updates: I.MergesInto<I.ImmutableOf<ApiFeatureCollection>>
    ) => Promise<void> | void;
    onClose: () => void;
  }>
> = ({featureCollection, kind, onClose, updateFeatureCollection}) => {
  const tagSettings = tagUtils.getTagSettings(featureCollection, kind);
  // Continuity so this can be a dep below.
  const tagIds = useContinuity(tagSettings.keySeq().toSet(), I.is);

  // We debounce so that this can be called in response to arrow keys, which
  // might be pressed quickly.
  const debouncedUpdateFeatureCollection = React.useMemo(
    () => debounce(updateFeatureCollection, 250),
    [updateFeatureCollection]
  );

  // We pre-generate a new tag ID so that we can save it to the backend if it
  // gets used.
  const nextTagIdRef = React.useRef(shortUuid().generate());

  // This indicates that our previous tag has been successfully saved to the
  // backend, so we need to generate a new ID so there can be a new "create tag"
  // row.
  if (tagSettings.has(nextTagIdRef.current)) {
    nextTagIdRef.current = shortUuid().generate();
  }

  // Helper function to save our tag definitions based on the settings and their
  // order. Used both by editing an individual tag and when reordering.
  const saveTags = async (
    updatedTagSettings: I.OrderedMap<string, I.ImmutableOf<TagSetting>>,
    updatedOrder: string[]
  ) => {
    await debouncedUpdateFeatureCollection(
      featureCollection.get('id'),
      featureCollectionUtils.updateLensView(featureCollection, (v) => {
        return v.set(
          kind === tagUtils.TAG_KIND.FEATURE ? 'tags' : 'noteTags',
          I.List(updatedOrder.map((id) => updatedTagSettings.get(id)))
        );
      })
    );
  };

  // During the dragging, we reorder the elements in this array to reflect the
  // latest positions. On drop we’ll update the Feature Collection with an API
  // call.
  //
  // Updates when the available IDs change to preserve the invariant that we can
  // iterate over these and look up settings from getTagSettings’s result.
  const [draggedTagIds, setDraggedTagIds] = useStateWithDeps(tagSettings.keySeq().toArray(), [
    tagIds,
  ]);

  // We have an outer drop target around the entire content so that rows can be
  // considered "dropped" when they’re let go here. If they’re let go outside of
  // this drop target, resetTagPosition is called.
  const [, drop] = useDrop({accept: 'tag'});

  // Called when a drop is successful.
  const saveTagPositions = () => {
    saveTags(tagSettings, draggedTagIds);
  };

  // Called when the drop happens not over a target, to put things back to the
  // last received order from the server. (E.g. the user dragged out of the
  // modal.)
  const resetTagPositions = () => {
    setDraggedTagIds(tagSettings.keySeq().toArray());
  };

  const moveIdToIdx = (id: string, idx: number) => {
    // Safety check for keyboard control, which does not check its bounds.
    if (idx < 0 || idx >= draggedTagIds.length) {
      return;
    }

    // We remove the tag id that’s moving, then insert it at the given number.
    // This has the right effect of being able to move to the front of the array
    // (since it will get inserted at index 0) and at the end of the array,
    // since everything before the end will be shifted up one index (filling in
    // the hole left by the thing we’re moving) before it’s inserted.
    setDraggedTagIds((s) => {
      const updatedSettings = s.filter((i) => i !== id);
      updatedSettings.splice(idx, 0, s.find((i) => i === id)!);
      return updatedSettings;
    });
  };

  // Modifies and saves the tag setting with the given ID.
  const updateTagSetting = async (t: I.ImmutableOf<TagSetting>) => {
    const updatedTagSettings = tagSettings.set(t.get('id'), t);
    // This will include t at the end if t is new, since tagSettings is an OrderedMap.
    const updatedTagIds = updatedTagSettings.keySeq().toArray();

    await saveTags(updatedTagSettings, updatedTagIds);
  };

  return (
    <B.Overlay isOpen={true} hasBackdrop={true} onClose={onClose} canOutsideClickClose={false}>
      <MapOverlayDialog
        title={`Customize ${tagUtils.TAG_KIND_DISPLAY[kind]} Tags`}
        onClose={onClose}
        className={cs.dialog}
      >
        <div className={cs.content} ref={drop}>
          {/* nextTagIdRef is added to the array rather than rendering after it so that
              after we save to the server it has the same key / position and all of
              the elements and focus and such get maintained. */}
          {[...draggedTagIds, nextTagIdRef.current].map((id, idx) =>
            id === nextTagIdRef.current ? (
              <TagRow
                key={id}
                tagSetting={immutableUtils.toImmutableMap<TagSetting>({
                  id: nextTagIdRef.current,
                  text: '',
                  color: DEFAULT_COLOR,
                  isArchived: false,
                })}
                currentIdx={idx}
                updateTagSetting={updateTagSetting}
                isNewTagRow
                kind={kind}
              />
            ) : (
              <TagRow
                key={id}
                tagSetting={tagSettings.get(id)}
                currentIdx={idx}
                moveIdToIdx={moveIdToIdx}
                saveTagPositions={saveTagPositions}
                resetTagPositions={resetTagPositions}
                updateTagSetting={updateTagSetting}
                kind={kind}
              />
            )
          )}
        </div>
      </MapOverlayDialog>
    </B.Overlay>
  );
};

export default CustomizeTagsModal;

interface DragItem {
  type: 'tag';
  id: string;
}

/**
 * Component to render a draggable row or a "new tag" field.
 */
const TagRow: React.FunctionComponent<
  React.PropsWithChildren<
    {
      tagSetting: I.ImmutableOf<TagSetting>;
      currentIdx: number;
      updateTagSetting: (t: I.ImmutableOf<TagSetting>) => Promise<void> | void;
      kind: tagUtils.TAG_KIND;
    } & (
      | {
          isNewTagRow?: false;
          moveIdToIdx: (id: string, idx: number) => void;
          saveTagPositions: () => void;
          resetTagPositions: () => void;
        }
      | {
          isNewTagRow: true;
          moveIdToIdx?: undefined;
          saveTagPositions?: undefined;
          resetTagPositions?: undefined;
        }
    )
  >
> = ({
  tagSetting,
  moveIdToIdx,
  currentIdx,
  saveTagPositions,
  resetTagPositions,
  isNewTagRow,
  updateTagSetting,
  kind,
}) => {
  const id = tagSetting.get('id');
  const item: DragItem = {type: 'tag', id};

  const [isEditing, setIsEditing] = React.useState(false);
  const [tagName, setTagName] = React.useState(tagSetting.get('text'));
  const [tagColor, setTagColor] = React.useState(tagSetting.get('color', DEFAULT_COLOR));

  const textInputRef = React.useRef<HTMLInputElement | null>(null);

  // This use of drag and drop is based on React DnD’s “Cancel on Drop Outside”
  // example:
  // https://react-dnd.github.io/react-dnd/examples/sortable/cancel-on-drop-outside
  const [{isDragging}, drag, preview] = useDrag(
    () => ({
      type: 'tag',
      item: item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      // This is from the perspective of the row being dragged. If we’re dropped
      // somewhere, then save the current order. Otherwise reset to before
      // dragging started.
      end: (_, monitor) => {
        if (monitor.didDrop()) {
          saveTagPositions?.();
        } else {
          resetTagPositions?.();
        }
      },
    }),
    [id, saveTagPositions, resetTagPositions]
  );

  const [, drop] = useDrop({
    accept: 'tag',

    // This is here because rows are hoverable (so that we can dynamically show
    // the new order) but "dropping" ultimately happens at the container level
    // (with an effect to essentially "confirm" the most recent order that the
    // hovering has determined).
    //
    // (You literally can’t drop on another row because as soon as you hover it
    // scoots out of the way to show where the row you’re dragging would go.)
    canDrop: () => false,

    hover({id: draggedId}: DragItem) {
      if (draggedId !== id) {
        moveIdToIdx?.(draggedId, currentIdx);
      }
    },
  });

  const saveTag = async (): Promise<void> => {
    let updatedTagSetting = tagSetting.set('color', tagColor);
    // if tagName.trim is empty then reset tagName
    if (tagName.trim()) {
      updatedTagSetting = updatedTagSetting.set('text', tagName);
    } else {
      setTagName(tagSetting.get('text'));
    }
    try {
      setIsEditing(true);
      await updateTagSetting(updatedTagSetting);
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <div
      className={cs.tagRow}
      ref={!isNewTagRow ? (el) => preview(drop(el)) : undefined}
      style={{opacity: isDragging ? 0 : 1}}
    >
      <div
        ref={!isNewTagRow ? drag : undefined}
        className={!isNewTagRow ? cs.tagHandle : ''}
        tabIndex={0}
        // Keyboard control when the handle is focused. Up and down arrows move
        // the tag up or down.
        onKeyDown={(ev) => {
          let offset = 0;
          if (ev.key === 'ArrowDown') {
            offset = 1;
          } else if (ev.key === 'ArrowUp') {
            offset = -1;
          }

          if (offset !== 0) {
            ev.preventDefault();
            moveIdToIdx?.(tagSetting.get('id'), currentIdx + offset);
            saveTagPositions?.();
          }
        }}
      >
        <B.Icon
          title={`Move handle for tag ${tagSetting.get('text')}`}
          icon={!isNewTagRow ? 'drag-handle-vertical' : 'blank'}
        />
      </div>

      <B.InputGroup
        aria-label="Tag name"
        value={tagName}
        className={cs.tagText}
        inputRef={(el) => (textInputRef.current = el)}
        fill
        placeholder={
          isNewTagRow
            ? `Type here to create a new ${tagUtils.TAG_KIND_DISPLAY[kind]} Tag`
            : `${tagUtils.TAG_KIND_DISPLAY[kind]} Tag name`
        }
        disabled={isEditing}
        onChange={(e) => setTagName(e.currentTarget.value)}
        onBlur={() => !isNewTagRow && saveTag()}
        onKeyPress={(ev) => {
          // "save on blur" isn't always discoverable, so pressing enter is a
          // good addition option
          if (ev.key === 'Enter') {
            saveTag();
            setTimeout(() => {
              textInputRef.current?.focus();
            }, 0);
          }
        }}
      />

      <SwatchColorPicker
        color={tagColor}
        setColor={(c) => {
          setTagColor(c);
        }}
        size={30}
        position="right"
        onClosed={() => !isNewTagRow && saveTag()}
        closeOnSelect
      />

      {isNewTagRow ? (
        <B.Tooltip content={`Add this ${tagUtils.TAG_KIND_DISPLAY[kind]} Tag`}>
          <B.Button
            icon={<B.Icon icon="plus" iconSize={12} />}
            aria-label="Add"
            disabled={isEditing || !tagName}
            minimal
            small
            style={{marginLeft: '0.25rem'}}
            onClick={saveTag}
          />
        </B.Tooltip>
      ) : (
        <B.Tooltip content={`Delete this ${tagUtils.TAG_KIND_DISPLAY[kind]} Tag`}>
          <B.Button
            icon={<B.Icon icon="trash" iconSize={12} />}
            aria-label="Delete"
            disabled={isEditing}
            minimal
            small
            style={{marginLeft: '0.25rem'}}
            onClick={async () => {
              try {
                setIsEditing(true);

                if (
                  confirm(
                    `Are you sure you want to delete this tag? It will be removed from all ${
                      kind === tagUtils.TAG_KIND.FEATURE ? 'properties' : 'notes'
                    } in this portfolio.`
                  )
                ) {
                  await updateTagSetting(tagSetting.set('isArchived', true));
                }
              } finally {
                setIsEditing(false);
              }
            }}
          />
        </B.Tooltip>
      )}
    </div>
  );
};
