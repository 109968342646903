import chroma from 'chroma-js';

import {DataRange} from 'app/components/AnalyzePolygonChart/types';
import colors from 'app/styles/colors.json';

import {GradientStops} from './gradientStops';

/**
 * Given GradientStops from a layer definition and the dataRange that the
 * layer’s data will be within, creates a Chroma scale to map graph data to
 * matching palette colors.
 *
 * This involves re-scaling the gradient stops and reversing them because we
 * store gradients in the wrong direction in layerUtils.
 */
export function graphScaleFromGradientStops(stops: GradientStops, dataRange: DataRange) {
  const reversedStops = stops.slice().reverse();

  // Inverts the values (0->1, 1->0) and then scales them to match the range
  // that the graph is displayed in.
  return chroma
    .scale(reversedStops.map(([, color]) => color))
    .domain(
      reversedStops.map(([offset]) => (1 - offset) * (dataRange[1] - dataRange[0]) + dataRange[0])
    );
}

export function getColorsFromScale(colorStops: string[] | undefined = undefined, numColors = 2) {
  const scale = chroma.scale(colorStops);
  return [...Array(numColors)].map((_, i) => scale(i / (numColors - 1)).hex());
}

export function isWhite(color: string | null | undefined) {
  if (!color) {
    return false;
  }

  try {
    return chroma(color).hex() === '#ffffff';
  } catch (err) {
    return false;
  }
}

export function isTransparent(color: string | null | undefined) {
  if (!color) {
    return false;
  }

  try {
    // chroma-js does not support the `transparent` keyword, so we return `true`
    // if that is the provided color.
    //
    // "as" because the chroma-js types don’t capture that "alpha" with no args
    // returns a number: https://gka.github.io/chroma.js/#color-alpha
    return color === 'transparent' ? true : (chroma(color).alpha() as unknown as number) === 0;
  } catch (err) {
    return false;
  }
}

export function getTextContrastColor(hex: string): string {
  // TODO: Add a test for hexes
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the luminance: https://poynton.ca/notes/colour_and_gamma/ColorFAQ.html#RTFToC9
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? colors.black : colors.white;
}

export default colors;
