import mapboxgl from 'mapbox-gl';
import React from 'react';

import * as cs from './MapMouseButtonPopup.styl';

interface MousePopupProps {
  isOpen: boolean;
  projectedMousePostion: mapboxgl.LngLat | null;
  headerContent?: HTMLElement;
  bodyContent: HTMLElement;
  map: mapboxgl.Map;
  mapIndex: number;
  closeCallback?: () => void;
}

export const MapMousePopup: React.FC<MousePopupProps> = ({
  isOpen,
  projectedMousePostion,
  headerContent,
  bodyContent,
  map,
  mapIndex,
  closeCallback,
}) => {
  const popupRef = React.useRef<mapboxgl.Popup | null>(null);
  const mapId = `${mapIndex}`;

  React.useEffect(() => {
    popupRef.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: cs.mapMousePopup,
      offset: 20,
    });

    return () => {
      popupRef.current?.remove();
    };
  }, [mapId]);

  React.useEffect(() => {
    if (!popupRef.current) return;

    if (isOpen && projectedMousePostion) {
      const wrapper = document.createElement('div');

      const headerWrapper = document.createElement('div');
      headerWrapper.className = cs.popupHeaderWrapper;

      headerWrapper.appendChild(headerContent || document.createElement('span'));

      if (closeCallback) {
        const closeBtn = document.createElement('button');
        closeBtn.className = cs.popupCloseButton;
        closeBtn.innerHTML = '×';

        closeBtn.onclick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          closeCallback();
        };

        headerWrapper.appendChild(closeBtn);
      }

      wrapper.appendChild(headerWrapper);
      bodyContent.className = cs.popupBodyWrapper;
      wrapper.appendChild(bodyContent);

      try {
        // it's possible that the there may not longer be a canvas context for the
        // popup due to mapbox unmounting it, so we catch the error here.
        popupRef.current.setDOMContent(wrapper).setLngLat(projectedMousePostion).addTo(map);
      } catch (e) {
        console.error(e);
      }
    } else {
      popupRef.current?.remove();
    }
  }, [isOpen, projectedMousePostion, bodyContent, map, closeCallback, headerContent]);

  return null;
};
