import {bbox, toMercator} from '@turf/turf';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import COLORS from 'app/styles/colors.json';
import {BBox2d, bboxPolygon} from 'app/utils/geoJsonUtils';
import {useStateWithDeps} from 'app/utils/hookUtils';

import cs from './styles.styl';

import {THUMBNAIL_BLOCKED_SOURCES} from '.';

const DEFAULT_STROKE_WIDTH = 3;
const MAX_ZOOM = 2;

const PREVIEW_IMAGE_CLIP_PATH_ID = 'preview-image-clipping';

const ImageryPreviewImage: React.FunctionComponent<
  React.PropsWithChildren<{
    feature: I.ImmutableOf<ApiFeature>;
    url: string | undefined;
    bounds: geojson.Polygon | geojson.MultiPolygon | BBox2d;
    maxWidth: number;
    maxHeight: number;
    sourceId: string;
    sceneGeometry: BBox2d | geojson.Polygon | geojson.MultiPolygon;
    subsetFeature?: geojson.Feature<geojson.Polygon> | null;
    blockedSources?: string[];
    isFeatureHidden?: boolean;
    orderedGeometry?: geojson.Polygon | geojson.MultiPolygon;
  }>
> = ({
  feature,
  url,
  bounds,
  maxWidth,
  maxHeight,
  sourceId,
  sceneGeometry,
  blockedSources = THUMBNAIL_BLOCKED_SOURCES,
  isFeatureHidden,
  subsetFeature,
  orderedGeometry,
}) => {
  // If we are showing the preview without a url, set the dimensions to the max width
  // and height so we can still show the outlines of the feature and the order area
  const [dimensions, setDimensions] = useStateWithDeps<{
    width: number;
    height: number;
  } | null>(url ? null : {width: maxWidth, height: maxHeight}, [url, maxHeight, maxWidth]);

  const geometry: geojson.Polygon | geojson.MultiPolygon = React.useMemo(
    () => feature.get('geometry').toJS(),
    [feature]
  );
  const mercatorFeatureGeometry = toMercator(geometry);
  const mercatorBbox = bbox(mercatorFeatureGeometry);

  const [w, s, e, n] = mercatorBbox;

  // Used to properly position image in the canvas
  const mercatorImageBoundsFeature = toMercator(
    Array.isArray(bounds) ? bboxPolygon(bounds).geometry : bounds
  );
  const mercatorImageBbox = bbox(mercatorImageBoundsFeature);
  const mercatorImageWidth = mercatorImageBbox[2] - mercatorImageBbox[0];
  const mercatorImageHeight = mercatorImageBbox[3] - mercatorImageBbox[1];

  const mercatorSubsetGeometry = subsetFeature && toMercator(subsetFeature?.geometry);
  const mercatorOrderedGeometry = orderedGeometry && toMercator(orderedGeometry);
  const mercatorSceneGeometry =
    sceneGeometry &&
    toMercator(Array.isArray(sceneGeometry) ? bboxPolygon(sceneGeometry).geometry : sceneGeometry);

  // Based on the bounding box of the feature in mercator coordinates, this is used
  // to translate the thumbnail and all features into canvas xy for rendering our svgs.
  // Overall we base the scale and positioning on the feature and the image is placed
  // in reference to that. Except if it will cause the image to become too zoomed, then
  // we factor the image size into acount for scaling.
  //
  // This will be null until the image loads because we don’t have its pixel
  // dimensions until then.
  const mercatorToCanvasXY = React.useMemo(() => {
    if (!dimensions) {
      return null;
    }

    // We don't want to stretch the image too much becuase it becomes
    // unitelligable so cap it at 2x. This zoom check will only be relevant to
    // images smaller than our max dimensions
    const width = Math.min(dimensions.width * MAX_ZOOM, maxWidth);
    const height = Math.min(dimensions.height * MAX_ZOOM, maxHeight);

    const xScale = width / (e - w);
    const yScale = height / (n - s);
    let scale = Math.min(xScale, yScale);

    // Images that are larger than our max dimensions but still may get
    // too zoomed we have to handle in a seperate case. Calculate what the pixel width
    // and height would be using our current scale value. If this is more than our max
    // zoom(2x), reset the scale to the max zoom.
    const potentialImagePixelWidth = mercatorImageWidth * scale;
    const potentialImagePixelHeight = mercatorImageHeight * scale;

    if (
      potentialImagePixelWidth > dimensions.width * MAX_ZOOM ||
      potentialImagePixelHeight > dimensions.height * MAX_ZOOM
    ) {
      const newXScale = (dimensions.width * MAX_ZOOM) / mercatorImageWidth;
      const newYScale = (dimensions.height * MAX_ZOOM) / mercatorImageHeight;
      scale = Math.min(newXScale, newYScale);
    }

    // Need to add an x or y term to center the elements in the canvas
    const xTerm = (maxWidth - (e - w) * scale) / 2;
    const yTerm = (maxHeight - (n - s) * scale) / 2;

    // We subtract imageHeight because DOM/SVG Y dimension is flipped relative to geographic Y dimension.
    return ([x, y]: [number, number]) => [
      (x - w) * scale + xTerm,
      maxHeight - ((y - s) * scale + yTerm),
    ];
  }, [w, n, e, s, dimensions, maxWidth, maxHeight, mercatorImageWidth, mercatorImageHeight]);

  // top and bottom may be wrong here but it works
  const leftTopImage =
    mercatorToCanvasXY && mercatorToCanvasXY([mercatorImageBbox[0], mercatorImageBbox[1]]);
  const rightBottomImage =
    mercatorToCanvasXY && mercatorToCanvasXY([mercatorImageBbox[2], mercatorImageBbox[3]]);

  const previewImageLeft = leftTopImage ? leftTopImage[0] : undefined;
  const previewImageTop = rightBottomImage ? rightBottomImage[1] : undefined;

  return (
    <div className={cs.previewImageWrapper} style={{width: maxWidth, height: maxHeight}}>
      {!isFeatureHidden && mercatorToCanvasXY && (
        <svg className={cs.previewFeature}>
          {renderGeojsonSvg(mercatorFeatureGeometry, mercatorToCanvasXY, {
            style: {
              stroke: 'transparent',
              strokeWidth: DEFAULT_STROKE_WIDTH,
              // Don't show red fill if we aren't displaying a thumbnail
              fill: url ? 'rgba(200, 0, 0, 0.6)' : 'transparent',
            },
          })}
        </svg>
      )}

      <img
        className={cs.previewImage}
        style={{
          display: mercatorToCanvasXY ? undefined : 'none',
          position: 'absolute',
          width:
            leftTopImage && rightBottomImage ? rightBottomImage[0] - leftTopImage[0] : undefined,
          height:
            leftTopImage && rightBottomImage ? leftTopImage[1] - rightBottomImage[1] : undefined,
          left: previewImageLeft,
          top: previewImageTop,
          clipPath: `url(#${PREVIEW_IMAGE_CLIP_PATH_ID})`,
        }}
        src={blockedSources.includes(sourceId) ? '' : url}
        onLoad={(ev) =>
          setDimensions({width: ev.currentTarget.width, height: ev.currentTarget.height})
        }
      />

      {mercatorToCanvasXY && mercatorSceneGeometry && (
        <svg height="0" width="0">
          <defs>
            <clipPath
              id={PREVIEW_IMAGE_CLIP_PATH_ID}
              style={makePreviewImageClipPathStyle({previewImageLeft, previewImageTop})}
            >
              {renderGeojsonSvg(mercatorSceneGeometry, mercatorToCanvasXY)}
            </clipPath>
          </defs>
        </svg>
      )}

      {!isFeatureHidden && mercatorToCanvasXY && (
        <>
          {mercatorOrderedGeometry && (
            <svg className={cs.previewFeature}>
              {/* Already ordered Imagery */}
              {renderGeojsonSvg(mercatorOrderedGeometry, mercatorToCanvasXY, {
                style: {
                  stroke: COLORS.brightBlue,
                  strokeWidth: 1,
                  fill: 'rgba(19, 124, 189, 0.6)',
                },
              })}
            </svg>
          )}
          <svg className={cs.previewFeature}>
            {/* Feature outline */}
            {renderGeojsonSvg(mercatorFeatureGeometry, mercatorToCanvasXY, {
              style: {
                stroke: COLORS.brightBlue,
                strokeWidth: DEFAULT_STROKE_WIDTH,
                fill: 'transparent',
              },
            })}
          </svg>
          {mercatorSubsetGeometry && (
            <svg className={cs.previewFeature}>
              {/* AOI outline */}
              {renderGeojsonSvg(mercatorSubsetGeometry, mercatorToCanvasXY, {
                style: {
                  stroke: 'yellow',
                  strokeWidth: DEFAULT_STROKE_WIDTH,
                  fill: 'transparent',
                },
              })}
            </svg>
          )}
        </>
      )}
    </div>
  );
};

function coordToStr(c: [number, number], transform: ([x, y]: [number, number]) => number[]) {
  const [x, y] = transform(c);
  return `${x.toFixed(3)} ${y.toFixed(3)}`;
}

function coordsToPathCommands(
  coordinates: geojson.Polygon['coordinates'],
  transform: ([x, y]: [number, number]) => number[]
): string | undefined {
  return coordinates
    .map((ring) =>
      ring
        .map((c, i) => `${i === 0 ? 'M' : 'L'}${coordToStr(c as [number, number], transform)}`)
        .join(' ')
    )
    .join(' ');
}

export function renderGeojsonSvg(
  geometry: geojson.Polygon | geojson.MultiPolygon,
  transform: ([x, y]: [number, number]) => number[],
  props: React.SVGProps<SVGPathElement> = {}
): React.ReactNode {
  if (geometry.type === 'Polygon') {
    return <path {...props} d={coordsToPathCommands(geometry.coordinates, transform)} />;
  }

  if (geometry.type === 'MultiPolygon') {
    return geometry.coordinates.map((poly, i) => (
      <path key={i} {...props} d={coordsToPathCommands(poly, transform)} />
    ));
  }

  return null;
}

export default ImageryPreviewImage;

function makePreviewImageClipPathStyle({
  previewImageLeft,
  previewImageTop,
}: {
  previewImageLeft?: number;
  previewImageTop?: number;
}): React.CSSProperties {
  const style: React.CSSProperties = {};

  if (previewImageLeft === undefined || previewImageTop === undefined) {
    /*
    If we don’t have preview image coordinates, we don’t know where to clip.
    */
    style.display = 'none';
  } else {
    /*
    We clip the preview image to the scene geometry in order to accurately
    communicate the area to be ordered. The scene geometry SVG path coordinates
    are relative to the top-left corner of the canvas, not the top-left corner
    of the preview image, which is the underlying assumption when applying an
    SVG path as a clip path. Thus, we will translate the path in the equal and
    opposite direction as the preview image so they align.
    */
    style.transform = `translate(${-1 * previewImageLeft}px, ${-1 * previewImageTop}px)`;
  }

  return style;
}
