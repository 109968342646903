import * as B from '@blueprintjs/core';
import * as S from '@blueprintjs/select';
import React from 'react';

import {useUserInfo} from 'app/providers/AuthProvider';
import {LayerInfo} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';

import {LayersLibraryExploreFooter} from '../LayersMenu';
import * as cs from './styles.styl';

export const DatasetSelect: React.FunctionComponent<{
  onItemSelect: (layerKey: string) => void;
  activeLayerInfo: LayerInfo;
  availableLayerKeys: string[];
}> = ({onItemSelect, activeLayerInfo, availableLayerKeys}) => {
  const [organization] = useUserInfo();

  const matchBolder = (text: string, query: string): React.ReactNode => {
    const matchIndex = text.toLowerCase().indexOf(query.toLowerCase());
    if (matchIndex === -1) return text;
    return [
      text.slice(0, matchIndex),
      <strong key={0}>{text.slice(matchIndex, matchIndex + query.length)}</strong>,
      text.slice(matchIndex + query.length),
    ];
  };

  const itemRenderer = (layerKey, {handleClick, query}) => (
    <B.MenuItem
      key={layerKey}
      onClick={handleClick}
      text={matchBolder(layerUtils.getLayer(layerKey).display, query)}
      active={activeLayerInfo.key === layerKey}
    />
  );

  const renderMenu: S.ItemListRenderer<string> = ({filteredItems, itemsParentRef, renderItem}) => {
    const renderedItems = filteredItems.map(renderItem).filter((item) => item != null);
    return (
      <>
        <B.Menu role="listbox" ulRef={itemsParentRef}>
          {renderedItems.length ? renderedItems : <B.MenuItem disabled={true} text="No results." />}
        </B.Menu>
        <LayersLibraryExploreFooter organization={organization!} />
      </>
    );
  };

  const layerFilter = (query, items) =>
    items.filter((layerKey) =>
      layerUtils.getLayer(layerKey).display.toLowerCase().includes(query.toLowerCase())
    );

  const select = (
    <S.Select<string>
      items={availableLayerKeys}
      itemListPredicate={layerFilter}
      itemRenderer={itemRenderer}
      itemListRenderer={renderMenu}
      onItemSelect={onItemSelect}
      popoverProps={{usePortal: false}}
      className={cs.datasetSelectPopover}
    >
      <B.Button text={activeLayerInfo.display} rightIcon="caret-down" />
    </S.Select>
  );

  return select;
};
