import shpwrite from '@mapbox/shp-write';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {ApiNote} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {StateApiNote} from 'app/stores/NotesStore';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as layerUtils from 'app/utils/layerUtils';
import {getEnrolledLayerKeysForFeatureCollection} from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as tagUtils from 'app/utils/tagUtils';

import {UTMArea} from './geoJsonUtils';
import * as layers from './layers';

export const BULLET = '∙';

export interface NoteFeatureProperties {
  displayNumber: number | null;
  id: string | number;
  noteType: StateApiNote['noteType'];
  isHovered?: boolean;
  isFocused?: boolean;
  isPending?: boolean;
  isPolygon?: boolean;

  // These are useful for our shapefile exports, and need to be <= 8 chars
  // (shapefiles/dbf files are typically 10 chars, but for whatever reason the
  // JS exports are truncated to 8.)
  text: string;
  userId: string;
  created: string;
  updated: string;
}

export interface NotesFilterState {
  dateRange: DateRange;
  noteTagIds: string[];
}

/*
This file contains utility functions to standardize treatment of note
features while the data structure is in development.
*/
export function makeNoteFeature(
  note: StateApiNote
): geojson.Feature<geojson.Geometry, NoteFeatureProperties> | null {
  if (!note.geometry) {
    return null;
  }

  const properties: NoteFeatureProperties = {
    id: note.id,
    displayNumber: note.displayNumber,
    text: note.text,
    noteType: note.noteType,
    userId: note.userId,
    created: note.createdAt,
    updated: note.updatedAt,
    // Adds attach1, attach2 properties
    ...(note.attachments || []).reduce(
      (acc, attachment, i) => ({...acc, ['attach' + (i + 1)]: attachment}),
      {}
    ),
  };

  return geoJsonUtils.feature(note.geometry, properties);
}

export function getGeometryNotes<T extends Pick<StateApiNote, 'geometry'>>(notes: T[]): T[] {
  return notes.filter((note) => !!note.geometry);
}

export function notesToFeatureCollection(
  notes: StateApiNote[]
): geojson.FeatureCollection<geojson.Geometry, NoteFeatureProperties> {
  return geoJsonUtils.featureCollection(
    getGeometryNotes(notes)
      .map(makeNoteFeature)
      .filter((n): n is geojson.Feature<geojson.Geometry, NoteFeatureProperties> => !!n)
  );
}

// A function to make a list of non-archived notes with geometries.
export function getNoteFeatures(notes: StateApiNote[]) {
  // We filter out notes w/o geometry so that map’s index will count just the
  // geometry. "!" on makeNoteFeature because we know it will return a geometry.
  return getGeometryNotes(notes).map((note) => makeNoteFeature(note)!);
}

export function formatImageRefs(imageRefs: mapUtils.MapImageRefs) {
  const firstLayerDisplay = layerUtils.getLayer(imageRefs[0].layerKey).display;

  let formattedCursor: string | null = null;
  let layerDisplay: string | React.ReactNode = firstLayerDisplay;

  if (imageRefs.length === 1) {
    const {cursor, layerKey} = imageRefs[0];
    formattedCursor = imageryUtils.formatDate(cursor!, layerKey);
  } else if (imageRefs.length === 2) {
    formattedCursor = imageRefs
      .filter(({cursor}) => !!cursor)
      .map(({cursor, layerKey}) => imageryUtils.formatDate(cursor!, layerKey))
      .join(' & ');

    const secondLayerDisplay = layerUtils.getLayer(imageRefs[1]?.layerKey).display;
    if (firstLayerDisplay !== secondLayerDisplay) {
      layerDisplay = <i>Multiple Layers</i>;
    }
  }

  return formattedCursor ? (
    <span>
      <span style={{marginRight: '0.25rem'}}>{layerDisplay}</span>
      {BULLET}
      <span style={{marginLeft: '0.25rem'}}>{formattedCursor}</span>
    </span>
  ) : (
    layerDisplay
  );
}

/**
 * Returns true if the imageRefs are referring to a layer and not just the
 * basemap. We need a little function for this because we can’t check for null
 * or a length of 0.
 */
export function imageRefsArentBasemap(imageRefs: mapUtils.MapImageRefs): boolean {
  return imageRefs.length > 1 || imageRefs[0].layerKey !== layers.NONE;
}

export function mayEditNote(note: StateApiNote, profile: I.ImmutableOf<ApiOrganizationUser>) {
  return note.isAuthor || profile.get('role') === 'owner';
}

export type DateRange = [Date | null, Date | null];

/**
 * A function that determines whether a note's creation date complies with the
 * provided date range.
 */
export function getIsValidNoteDate(note: Pick<StateApiNote, 'createdAt'>, dateRange: DateRange) {
  const {createdAt} = note;
  const [startDate, endDate] = dateRange;

  const hasValidStartDate = !startDate || createdAt >= startDate.toISOString();
  const hasValidEndDate = !endDate || createdAt <= endDate.toISOString();

  return hasValidStartDate && hasValidEndDate;
}

/**
 * A function that determines whether a Date object is valid or not. Used to
 * check for "Invalid Date" Date objects returned from Blueprint’s
 * DateRangePicker component.
 */
export function getIsValidDate(date: Date) {
  return !!date && date instanceof Date && !isNaN(date.getTime());
}

/**
 * Check to see if any of the imagerefs have invalid note layers.
 */
export function noteHasInvalidLayersOrCursors(imagerefs: mapUtils.MapImageRefs): boolean {
  if (imagerefs.length === 1) {
    // Notes for a single layer should always be valid.
    return false;
  } else {
    // Notes for compare mode cannot include a basemap.
    return (
      imagerefs[0].layerKey === layers.NONE ||
      imagerefs[0].cursor === null ||
      imagerefs[1].layerKey === layers.NONE ||
      imagerefs[1].cursor === null
    );
  }
}

/**
 * A callback function that filters for valid notes.
 */
export function filterNotesCallback(
  note: StateApiNote,
  noteType: StateApiNote['noteType'],
  notesFilterState: NotesFilterState
) {
  if (note.noteType !== noteType) {
    return false;
  }

  if (note.isArchived) {
    return false;
  }

  if (noteType === 'user') {
    if (!getIsValidNoteDate(note, notesFilterState.dateRange)) {
      return false;
    }
    if (notesFilterState.noteTagIds.length) {
      const tagIds = tagUtils.getNoteTagIds(I.fromJS(note));
      if (!tagIds.some((tagId) => notesFilterState.noteTagIds.includes(tagId!))) {
        return false;
      }
    }
  }

  return true;
}

/**
 * A function that returns true if a note is associated to an unenrolled layer.
 * TODO(eva): this currently returns a boolean; might be nice in the future to
 * have it actually return the layerKey.
 */
export function noteHasUnenrolledLayers(note: StateApiNote, enrolledLayerKeys: string[]) {
  return !!note.imageRefs.find(
    (i) => i.layerKey && !enrolledLayerKeys.includes(i.layerKey) && i.layerKey !== 'none'
  );
}
/**
 * A function to determine whether we should try to load a given note's imageRefs
 * on the map. Checks that it isn't referring to the basemap and that the imageRefs
 * aren't for any unenrolled layers
 */
export function shouldLoadImageRefOnMap(
  note: StateApiNote,
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  const enrolledLayerKeys = getEnrolledLayerKeysForFeatureCollection(featureCollection);
  return imageRefsArentBasemap(note.imageRefs) && !noteHasUnenrolledLayers(note, enrolledLayerKeys);
}

export function makeImageRefsFromNote(
  note: Pick<ApiNote, 'cursorKey' | 'layerKey'>
): mapUtils.MapImageRefs {
  if (note.cursorKey === null || note.layerKey === null) {
    return [{layerKey: layers.NONE, cursor: null}];
  } else {
    // We handle the following cases:
    // - one cursor / one layer key
    // - two cursors / two layer keys
    // - two cursors / one layer key
    //
    // This latter case is the legacy compare mode. We apply the same layerKey
    // to each.
    const cursors = note.cursorKey.split('|');
    const layerKeys = note.layerKey.split('|');

    if (cursors.length === 1) {
      return [{cursor: cursors[0], layerKey: layerKeys[0]}];
    } else {
      return [
        {cursor: cursors[0], layerKey: layerKeys[0]},
        {cursor: cursors[1], layerKey: layerKeys.length > 1 ? layerKeys[1] : layerKeys[0]},
      ];
    }
  }
}

export function notePropertiesFromImageRefs(
  imageRefs: mapUtils.MapImageRefs
): Pick<ApiNote, 'cursorKey' | 'cursorType' | 'layerKey'> {
  const includesBasemap = imageRefs.reduce(
    (hasBasemap, iR) => hasBasemap || iR.layerKey === layers.NONE,
    false
  );
  if (imageRefs.length === 1) {
    return {
      cursorType: includesBasemap ? null : 'datetime',
      cursorKey: imageRefs[0].cursor,
      layerKey: imageRefs[0].layerKey,
    };
  } else {
    // Practically we should never get here with the guards in NoteForm around compare mode with basemaps, but just in case.
    return {
      cursorType: includesBasemap ? null : 'compareDatetime',
      cursorKey: imageRefs[0].cursor + '|' + imageRefs[1].cursor,
      layerKey: imageRefs[0].layerKey + '|' + imageRefs[1].layerKey,
    };
  }
}

/**
 * Scrolls to the currently focused note
 * Parameters:
 *  focusedNoteCardRef: ref of the focused note
 *  noteCardRefContainer: optional ref of the nearest ancestor that comprises the scrollable
 *    container, since it's not always the immediate parent which does so
 *   */
export function scrollToFocusedNote(
  focusedNoteCardRef: React.RefObject<HTMLElement>,
  noteCardRefContainer?: React.RefObject<HTMLDivElement>
) {
  const SCROLL_PADDING = 10;
  const noteDiv = focusedNoteCardRef.current;
  // Use the provided container if it exists, otherwise, find our parent from our note card
  const parent = noteCardRefContainer?.current ?? (noteDiv && noteDiv.parentElement);

  // EdgeHTML <= 18 doesn’t support scrollTo, so we just don’t bother to
  // scroll in that case, rather than have an exception throw.
  if (noteDiv && parent && parent.scrollTo) {
    const {offsetTop: activeTop, offsetHeight: activeHeight} = noteDiv;

    const {
      offsetTop: parentOffsetTop,
      scrollTop: parentScrollTop,
      clientHeight: parentHeight,
    } = parent;

    // compute the two edges of the active item for comparison, including parent padding
    const activeBottomEdge = activeTop + activeHeight - parentOffsetTop;
    const activeTopEdge = activeTop - parentOffsetTop;

    // offscreen bottom: align bottom of item with bottom of viewport
    if (activeBottomEdge >= parentScrollTop + parentHeight) {
      parent.scrollTo({
        top: activeBottomEdge - parentHeight + SCROLL_PADDING,
        behavior: 'smooth',
      });
      // offscreen bottom: align top edge with top of viewport, minus scroll padding
    } else if (activeTopEdge <= parentScrollTop) {
      parent.scrollTo({
        top: activeTopEdge - SCROLL_PADDING,
        behavior: 'smooth',
      });
    }
  }
}

export function downloadNotesShapefile(notes: StateApiNote[], featureName: string) {
  const fileName = featureName.replace(/\s+/g, '_') + `_Note${notes.length > 1 ? 's' : ''}`;

  shpwrite.download(notesToFeatureCollection(notes), {
    filename: fileName,
    folder: fileName,
    // shapefiles can only contain one type of geometry, so we provide file
    // names for the split out types.
    types: {point: 'point-notes', polygon: 'polygon-notes'},
    compression: 'STORE',
    outputType: 'blob',
  });
}

export function cleanNotesFilterState(notesFilterState: NotesFilterState) {
  // The DateRangePicker value prop expects each array element to either
  // be a Date or undefined, but Blueprint returns null on some
  // shortcuts. If the date is null, undefined, or an invalid Date
  // object, fallback to undefined.
  const dateRange = notesFilterState.dateRange.map((d) =>
    !!d && getIsValidDate(d) ? d : null
  ) as DateRange;
  return {...notesFilterState, dateRange};
}

/**
 * Get a note geometry’s area in square meters for area-in-range tooltip
 * calculations, where percent area-in-range is also expressed in absolute area
 * in the organization’s preferred unit.
 *
 * We can’t assume that if a note has a graph attached that is has a geometry.
 * This is due to a bug that allowed users to delete the note geometry without
 * also deleting the graph. However, if there is a geometry, it must be a
 * Polygon or MultiPolygon.
 */
export function getNoteAreaInM2(note: StateApiNote) {
  return note.geometry ? UTMArea(note.geometry as geojson.Polygon | geojson.MultiPolygon) : 0;
}
