import React from 'react';
import {useLocation} from 'react-router-dom';

const ScrollOnRouteChange: React.FunctionComponent = () => {
  // We rely on useLocation rather than listening to a history object so it’s
  // easier to gate the scrolling on specific dependency changes.
  const {pathname, hash} = useLocation();

  React.useEffect(() => {
    if (!window) {
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    if (hash !== '') {
      const id = hash.replace('#', '');
      const el = document.getElementById(id);

      if (el) {
        el.scrollIntoView();
      }
    }
  }, [hash]);

  return null;
};

export default ScrollOnRouteChange;
