import {
  AIRBUS_PLEIADES_ARCHIVE_HIGH_RES_TRUECOLOR,
  AIRBUS_PLEIADES_HIGH_RES_TRUECOLOR,
  AIRBUS_PNEO_HIGH_RES_TRUECOLOR,
  AIRBUS_SPOT_ARCHIVE_HIGH_RES_TRUECOLOR,
  AIRBUS_SPOT_HIGH_RES_TRUECOLOR,
  CHLORIS_10_PACKAGE,
  CHLORIS_30_PACKAGE,
  DIGITAL_GLOBE_30_HIGH_RES_TRUECOLOR,
  DIGITAL_GLOBE_50_HIGH_RES_TRUECOLOR,
  ESA_WORLDCOVER_LANDCOVER,
  GLOBAL_FOREST_CHANGE_LOSSYEAR,
  IMPACT_OBSERVATORY_BIODIVERSITY,
  IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER,
  IMPACT_OBSERVATORY_LANDCOVER,
  MAPBIOMAS_BRAZIL_LANDCOVER,
  MAPBIOMAS_CHACO_LANDCOVER,
  METAWRI_CANOPY_HEIGHT,
  MOBI_SPECIES_RICHNESS_ALL,
  NAIP_HIGH_RES_TRUECOLOR,
  NAIP_NDVI,
  NEARMAP_15_HIGH_RES_TRUECOLOR,
  NEARMAP_50_HIGH_RES_TRUECOLOR,
  NLCD_LANDCOVER,
  PLANET_BASEMAP_HIGH_RES_TRUECOLOR,
  PLANET_FOREST_DILIGENCE_PACKAGE,
  PLANET_FOREST_MONITORING_PACKAGE,
  PLANET_SKYSAT_COLLECT_HIGH_RES_TRUECOLOR,
  PRISM_M_PRECIPITATION,
  S1_WATER_CLASSIFICATION,
  S2_EVI,
  S2_FALSECOLOR_SWIR_NIR_GREEN,
  S2_NBR,
  S2_NDCI,
  S2_NDTI,
  S2_NDVI,
  S2_NDWI,
  S2_NDWI_MOISTURE,
  S2_TRUECOLOR,
  SBTN_NATURAL_LANDCOVER,
  SPACE_INTELLIGENCE_HABITAT_10_CLASS,
  TMF_ANNUAL_CHANGE_LANDCOVER,
} from 'app/utils/layers';

import {LayerDataset, LibraryDataset} from './LibraryTypes';

// Sometimes we want to display layers in the library before they're actually available to give people a heads up.
// Those can be stored here to send back to the library view.
export const PREVIEW_LAYERS_CONFIG: {[key: string]: LibraryDataset<LayerDataset>} = {};

export type SwimLane = {
  layers: string[];
};

// Library featured layers
export const FEATURED_LAYER_KEYS = [
  NEARMAP_15_HIGH_RES_TRUECOLOR,
  SPACE_INTELLIGENCE_HABITAT_10_CLASS,
  CHLORIS_10_PACKAGE,
];

// Swim Lane definition for layers library
export const SWIM_LANES: {
  [key: string]: SwimLane;
} = {
  'Land Conservation Essentials': {
    layers: [
      PLANET_SKYSAT_COLLECT_HIGH_RES_TRUECOLOR,
      AIRBUS_PLEIADES_HIGH_RES_TRUECOLOR,
      AIRBUS_PLEIADES_ARCHIVE_HIGH_RES_TRUECOLOR,
      AIRBUS_PNEO_HIGH_RES_TRUECOLOR,
      AIRBUS_SPOT_HIGH_RES_TRUECOLOR,
      AIRBUS_SPOT_ARCHIVE_HIGH_RES_TRUECOLOR,
      S2_TRUECOLOR,
      S2_NDVI,
      DIGITAL_GLOBE_50_HIGH_RES_TRUECOLOR,
      DIGITAL_GLOBE_30_HIGH_RES_TRUECOLOR,
      NEARMAP_15_HIGH_RES_TRUECOLOR,
      NEARMAP_50_HIGH_RES_TRUECOLOR,
      NAIP_HIGH_RES_TRUECOLOR,
      NAIP_NDVI,
      PRISM_M_PRECIPITATION,
      'Parcel Data',
    ],
  },
  'Forests and Carbon': {
    layers: [
      PLANET_FOREST_MONITORING_PACKAGE,
      PLANET_FOREST_DILIGENCE_PACKAGE,
      CHLORIS_10_PACKAGE,
      CHLORIS_30_PACKAGE,
      IMPACT_OBSERVATORY_LANDCOVER,
      S2_NBR,
      IMPACT_OBSERVATORY_BIODIVERSITY,
      GLOBAL_FOREST_CHANGE_LOSSYEAR,
      ESA_WORLDCOVER_LANDCOVER,
      S2_TRUECOLOR,
      S2_NDVI,
      IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER,
      MAPBIOMAS_BRAZIL_LANDCOVER,
      MAPBIOMAS_CHACO_LANDCOVER,
      METAWRI_CANOPY_HEIGHT,
      S2_EVI,
      'Indigenous Territories',
      '2020 Intact Forest Landscapes',
      '2016 Intact Forest Landscapes',
      '2013 Intact Forest Landscapes',
      '2000 Intact Forest Landscapes',
    ],
  },
  'Changing Landscapes': {
    layers: [
      SPACE_INTELLIGENCE_HABITAT_10_CLASS,
      IMPACT_OBSERVATORY_LANDCOVER,
      S2_NBR,
      IMPACT_OBSERVATORY_BIODIVERSITY,
      GLOBAL_FOREST_CHANGE_LOSSYEAR,
      ESA_WORLDCOVER_LANDCOVER,
      S2_NDWI_MOISTURE,
      S2_NDWI,
      S2_TRUECOLOR,
      S2_NDVI,
      IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER,
      MOBI_SPECIES_RICHNESS_ALL,
      NLCD_LANDCOVER,
      MAPBIOMAS_BRAZIL_LANDCOVER,
      MAPBIOMAS_CHACO_LANDCOVER,
      'Flowlines',
      'Biodiversity Hotspots',
      SBTN_NATURAL_LANDCOVER,
      TMF_ANNUAL_CHANGE_LANDCOVER,
    ],
  },
  'Watershed Stewardship': {
    layers: [
      S2_NDTI,
      S2_NDCI,
      S1_WATER_CLASSIFICATION,
      GLOBAL_FOREST_CHANGE_LOSSYEAR,
      S2_FALSECOLOR_SWIR_NIR_GREEN,
      S2_NDWI_MOISTURE,
      S2_NDWI,
      S2_TRUECOLOR,
      S2_NDVI,
      NAIP_HIGH_RES_TRUECOLOR,
      NAIP_NDVI,
      PRISM_M_PRECIPITATION,
      'Flowlines',
      'EPA Superfund Sites',
      'US Protected Areas',
      'Massachusetts Wetlands Inventory',
    ],
  },
  Orderable: {
    layers: [
      CHLORIS_10_PACKAGE,
      CHLORIS_30_PACKAGE,
      PLANET_FOREST_MONITORING_PACKAGE,
      PLANET_FOREST_DILIGENCE_PACKAGE,
      PLANET_SKYSAT_COLLECT_HIGH_RES_TRUECOLOR,
      AIRBUS_PLEIADES_HIGH_RES_TRUECOLOR,
      AIRBUS_PLEIADES_ARCHIVE_HIGH_RES_TRUECOLOR,
      AIRBUS_PNEO_HIGH_RES_TRUECOLOR,
      AIRBUS_SPOT_HIGH_RES_TRUECOLOR,
      AIRBUS_SPOT_ARCHIVE_HIGH_RES_TRUECOLOR,
      IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER,
      DIGITAL_GLOBE_50_HIGH_RES_TRUECOLOR,
      DIGITAL_GLOBE_30_HIGH_RES_TRUECOLOR,
      NEARMAP_15_HIGH_RES_TRUECOLOR,
      NEARMAP_50_HIGH_RES_TRUECOLOR,
      PLANET_BASEMAP_HIGH_RES_TRUECOLOR,
      SPACE_INTELLIGENCE_HABITAT_10_CLASS,
    ],
  },
};
