import * as B from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';

import * as cs from './styles.styl';

export interface ExpandableListProps {
  items: React.ReactNode[];
  initialDisplayNum?: number;
  buttonProps?: B.ButtonProps;
  className?: string;
}

export const ExpandableList: React.FC<React.PropsWithChildren<ExpandableListProps>> = ({
  items,
  initialDisplayNum = 5,
  buttonProps,
  className,
}) => {
  const [displayNum, setDisplayNum] = React.useState(initialDisplayNum);

  return (
    <div className={classNames(cs.container, className)}>
      {items.slice(0, displayNum)}
      {initialDisplayNum < items.length ? (
        <B.Button
          onClick={() =>
            items.length === displayNum
              ? setDisplayNum(initialDisplayNum)
              : setDisplayNum(items.length)
          }
          intent={B.Intent.PRIMARY}
          minimal
          {...buttonProps}
        >
          {items.length === displayNum
            ? 'Show less'
            : `${items.length - initialDisplayNum} more...`}
        </B.Button>
      ) : (
        <div /> // Filler to maintain spacing
      )}
    </div>
  );
};
