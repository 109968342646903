import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {SOURCE_DETAILS_BY_ID} from 'app/utils/constants';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';

import {DEFAULT_GRADIENT_STOPS, GradientStops} from './gradientStops';
import {
  ANY_TRUECOLOR_HIGH_RES,
  DataLayerInfo,
  ImageLayerInfo,
  LAYERS,
  LAYER_PACKAGES,
  LayerInfo,
  NONE,
  S2_NDVI,
  S2_TRUECOLOR,
} from './layers';

export function isLayerKeyHighResTruecolor(layerKey: string | null | undefined) {
  return layerKey?.endsWith('_high-res-truecolor') || false;
}

export function isLayerKeyRaw(layerKey: string) {
  return layerKey.endsWith('_raw');
}

export function isLayerPaid(layerKey: string) {
  const {sourceId} = parseLayerKey(layerKey);
  return SOURCE_DETAILS_BY_ID[sourceId]?.paid || false;
}

export function getRawLayerKey(layerKey: string): string | null {
  const layer = getLayer(layerKey);
  if (layer.hasRawLayer) {
    return layerKey + '_raw';
  }
  return null;
}

const DEFAULT_LAYER_KEY_BY_PRODUCT_AND_VARIANT: Record<string, string> = {
  all: S2_NDVI,
  'remote-imagery': S2_TRUECOLOR,
  lens: ANY_TRUECOLOR_HIGH_RES,
  'lens-commercial-free': ANY_TRUECOLOR_HIGH_RES,
  'lens-landsat': ANY_TRUECOLOR_HIGH_RES,
  'lens-experimental': ANY_TRUECOLOR_HIGH_RES,
  'lens-carbon': ANY_TRUECOLOR_HIGH_RES,
  'lens-carbon-legacy': ANY_TRUECOLOR_HIGH_RES,
  'lens-carbon-space-intelligence': ANY_TRUECOLOR_HIGH_RES,
  wetlands: ANY_TRUECOLOR_HIGH_RES,
};

export function getDefaultLayerKeyForFeatureCollection(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  // If we've defined a layer view use the first key as default.
  const layerView = featureCollectionUtils.getLayersView(featureCollection);
  if (layerView) {
    return layerView.get('columns').toArray()[0];
  }

  const product = featureCollection.get('product');

  return (
    DEFAULT_LAYER_KEY_BY_PRODUCT_AND_VARIANT[product] ||
    DEFAULT_LAYER_KEY_BY_PRODUCT_AND_VARIANT.all
  );
}

export function getLayerKeyFromSourceAndLayer(source: string, layer: string): string {
  return source + '_' + layer;
}

export function createLayerKeysFromLayerObject(layers: Record<string, string[]>) {
  return Object.entries(layers)
    .map(([source, listofKeys]) => listofKeys.map((key) => `${source}_${key}`))
    .flat();
}

export function createLayerObjectFromLayerKeys(layerKeys: string[]): Record<string, string[]> {
  const layersObject = layerKeys.reduce((acc, layerKey) => {
    const {sourceId, layerId} = parseLayerKey(layerKey);
    acc[sourceId] = acc[sourceId] ? [...acc[sourceId], layerId] : [layerId];
    return acc;
  }, {});
  return layersObject;
}

export function getPackageLayerKeys(layerKey: string): string[] {
  return LAYER_PACKAGES[layerKey] || [layerKey];
}

export function checkLayerIsProcessingStatus(
  layerKey: string,
  metadata: ApiFeatureCollection['processingConfig']['processingMetadata']
): boolean {
  // Derive our source and layer keys from layerKey
  const {sourceId, layerId} = parseLayerKey(layerKey);
  // Grab our processing key, if it exists.
  const {processing_status} = (metadata[sourceId] || {})[layerId] || {};
  // Check if status is processing. If complete or null, we aren't processing.
  return processing_status === 'processing';
}

export function checkFeatureCollectionIsProcessingStatus(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
): boolean {
  const metadata = getProcessingMetadataForFeatureCollection(featureCollection);
  // The approach here is to reduce our metadata object to a boolean: whether we're processing anything
  return Object.keys(metadata).reduce<boolean>((isProcessing, source) => {
    // If we've found a processing layer in a previous loop, stop looking, we've already found something.
    if (isProcessing) {
      return isProcessing;
    }
    // If we haven't found anything yet, look through all the layers for processing things.
    return Object.keys(metadata[source]).reduce<boolean>(
      (isProcessing, layer) =>
        isProcessing || metadata[source][layer].processing_status === 'processing',
      false
    );
  }, false);
}

export function getSupportedLayerKeysForFeatureCollection(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  return createLayerKeysFromLayerObject(
    featureCollection.get('supportedLayersForProduct').toJS()
  ).filter((layerKey) => {
    const layer = LAYERS[layerKey];
    if (layer?.filter) {
      return layer.filter(featureCollection);
    } else {
      return true;
    }
  });
}

export function getEnrolledLayerKeysForFeatureCollection(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  return createLayerKeysFromLayerObject(
    featureCollection.getIn(['processingConfig', 'enrolledLayers']).toJS()
  );
}

// TODO(anthony): This is a bit unlike the other accessors, above, which use createLayerKeysFromLayerObject.
//  Should we flatten the returned structure to something more like { [layerKey: string]: boolean }?
export function getProcessingMetadataForFeatureCollection(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  return featureCollection.getIn(['processingConfig', 'processingMetadata']).toJS();
}

export function getLayerMenuOptionsForFeatureCollection(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  const layerKeys = getEnrolledLayerKeysForFeatureCollection(featureCollection);

  // filter out highResTruecolor layerKeys. This function is used for displaying options in the
  // layers dropdown or other contexts where we don't distingush between different highResTruecolor layers.
  // filter out raw layerKeys, we also don't need to show those in the dropdown
  const filteredLayerKeys = layerKeys.filter(
    (layerKey) => !isLayerKeyHighResTruecolor(layerKey) && !isLayerKeyRaw(layerKey)
  );

  return [...filteredLayerKeys, NONE, ANY_TRUECOLOR_HIGH_RES];
}

export function getLayer(layerKey: string | undefined, includeHiRes?: boolean) {
  if (!layerKey) {
    throw 'layerKey cannot be undefined';
  }

  // We don’t have separate data for the various truecolor high res sources.
  if (isLayerKeyHighResTruecolor(layerKey) && !includeHiRes) {
    layerKey = ANY_TRUECOLOR_HIGH_RES;
  }

  if (LAYERS[layerKey]) {
    return LAYERS[layerKey];
  } else {
    return makeDefaultLayer(layerKey);
  }
}

function makeDefaultLayer(layerKey: string): LayerInfo {
  return {
    type: 'image',
    key: layerKey,
    display: layerKey,
    shortName: layerKey,
    vendor: 'none',
    gradientStops: DEFAULT_GRADIENT_STOPS,
  };
}

export function isImageLayer(layerInfo: LayerInfo): layerInfo is ImageLayerInfo {
  return layerInfo.type === 'image';
}

export function isDataLayer(layerInfo: LayerInfo): layerInfo is DataLayerInfo {
  return layerInfo.type === 'data';
}

export function renderGradientStops(stops: GradientStops) {
  return stops.map(([offset, color], i) => (
    <stop key={i} offset={`${offset * 100}%`} stopColor={color} />
  ));
}

// A function to conditionally adjust display dates
// based on the layer. For some annual/monthly layers, we want
// to show the date as the last day of the time period.
export function conditionallyAdjustLayerDate(
  date: Date | string | moment.Moment,
  layerKey: string | undefined = ''
): moment.Moment {
  const layer = LAYERS[layerKey];
  if (layer?.mosaic == 'annual') {
    return moment(date).utc().endOf('year');
  } else if (layer?.mosaic == 'monthly') {
    return moment(date).utc().endOf('month');
  } else {
    return moment(date);
  }
}

// Returns a list of moments representing the constant mosaic range for the layer, or
// null if not applicable.
export function staticLayerMosaicRange(
  layerKey: string | undefined,
  date: Date | string | moment.Moment
): moment.Moment[] | null {
  if (layerKey === undefined) {
    return null;
  }

  const layer = LAYERS[layerKey];
  const momentDate = moment(date).utc();
  if (layer?.mosaic == 'annual') {
    const startDate = moment(momentDate).startOf('year');
    const endDate = moment(momentDate).endOf('year');
    return [startDate, endDate];
  } else if (layer?.mosaic == 'monthly') {
    const startDate = moment(momentDate).startOf('month');
    const endDate = moment(momentDate).endOf('month');
    return [startDate, endDate];
  } else if (typeof layer?.mosaic === 'function') {
    const [startDate, endDate] = layer.mosaic(momentDate.toDate());
    return [moment(startDate), moment(endDate)];
  }

  return null;
}

export function onlyShowCaptureDate(layerKey: string | undefined): boolean {
  if (!layerKey) {
    return false;
  }
  const layer = LAYERS[layerKey];
  return !!layer?.onlyDisplayCaptureDate;
}

export interface LayerKey {
  sourceId: string;
  layerId: string;
}

/**
 * The layerKeys we use throughout the frontend are a composite of two distinct pieces of data:
 * the sourceId and layerId (the backend also sometimes calls the layerId a layerKey).
 * This function parses the combined layerKey into a LayerKey object so each field can be
 * referenced distinctly.
 */
export function parseLayerKey(combinedLayerKey: string): LayerKey {
  const sourceId = combinedLayerKey.split('_', 1)[0];
  const layerId = combinedLayerKey.replace(`${sourceId}_`, '');
  return {
    sourceId,
    layerId,
  };
}

/**
 *
 * Returns the resolution of a layer given its layerKey. If we haven't
 * specified an override on the LAYERS object, default to the source resolution.
 */
export function getLayerResolution(layerKey: string): number {
  const {sourceId} = parseLayerKey(layerKey);
  const layerResolution = getLayer(layerKey)?.resolution;

  return layerResolution || SOURCE_DETAILS_BY_ID[sourceId]?.resolution;
}
