import * as geojson from 'geojson';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import React from 'react';

import {NoteGraph} from 'app/components/AnalyzePolygonChart/types';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {PolygonFeature} from 'app/providers/MapPolygonStateProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import {NotesActions, NotesState, StateApiNote, handleDeleteNotes} from 'app/stores/NotesStore';
import {FEATURE_WAITING_FOR_PROCESSING_STATUS, NOTE_TYPE_USER} from 'app/utils/constants';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as mapUtils from 'app/utils/mapUtils';
import {shouldLoadImageRefOnMap} from 'app/utils/noteUtils';

import NoteForm from './NoteForm';
import NoteView from './NoteView';

// Pass through NoteForm for convenience.
export {NoteForm};

export type NoteCardMode = 'view' | 'edit';

/**
 * Component that renders an editable note card. If permitted, the card will
 * switch to the NoteForm UI when it enters editing mode.
 *
 * NoteForm is exported separately for cases where it can be used to create a
 * new note.
 */
const NoteCard: React.FunctionComponent<
  React.PropsWithChildren<{
    imageRefs: mapUtils.MapImageRefs;

    profile: I.ImmutableOf<ApiOrganizationUser>;
    organization: I.ImmutableOf<ApiOrganization>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    feature: I.ImmutableOf<ApiFeature>;
    updateFeatureCollection: ProjectsActions['updateFeatureCollection'];

    mode: NoteCardMode;
    onChangeMode: (mode: NoteCardMode) => void;

    cameraOptions: mapUtils.CameraOptions | null;

    notesState: NotesState;
    notesActions: NotesActions;

    /** Note exists when viewing a note, or editing an existing note. */
    note: StateApiNote;

    onSelectImageRefs: (imageRefs: mapUtils.MapImageRefs) => unknown;

    /** Reference to focused card to support behaviors like auto-scrolling. */
    focusedNoteCardRef?: React.RefObject<HTMLDivElement>;
    unsetFocusedNoteId: () => void;

    /** Optional geometry to use other than the one in NotesState */
    geometry?: geojson.Geometry;

    onNoteCreated?: (note: StateApiNote) => void;
    onSelectNote?: (noteId: number | string) => unknown;
    openAnalyzePolygonPopup?: (feature: PolygonFeature) => unknown;
    promptForCoordinates?: () => unknown;
    zoomToGeometry?: (geometry: geojson.GeoJSON) => unknown;

    /** Optional flags for disabling note add-ons and actions. */
    disableEditing?: boolean;
    disableGeometry?: boolean;

    graph?: NoteGraph | null;
    isChartLoading?: boolean;

    enrolledLayerKeys?: string[];

    //Optional props we pass in if we need to be able to render the RerunChartAnalysisTool
    firebaseToken?: string;
    featureData?: I.ImmutableListOf<ApiFeatureData> | null;
  }>
> = ({
  imageRefs,
  profile,
  organization,
  featureCollection,
  feature,
  cameraOptions,
  notesState,
  notesActions,
  mode,
  onChangeMode,
  note,
  focusedNoteCardRef,
  unsetFocusedNoteId,
  geometry,
  onNoteCreated,
  onSelectImageRefs,
  onSelectNote,
  promptForCoordinates,
  zoomToGeometry,
  disableEditing,
  disableGeometry,
  graph,
  openAnalyzePolygonPopup,
  isChartLoading,
  enrolledLayerKeys,
  firebaseToken,
  featureData,
  updateFeatureCollection,
}) => {
  const isFocused = note.id === notesState.focusedNoteId;

  const onFocusNote = React.useCallback(() => {
    if (note) {
      notesActions.focusNote(note.id);

      if (onSelectNote) {
        onSelectNote(note.id);
      }

      if (shouldLoadImageRefOnMap(note, featureCollection)) {
        onSelectImageRefs(note.imageRefs);
      }

      if (zoomToGeometry) {
        // When you click on a note, zoom the map to the note's geometry. If there
        // is no geometry associated with the note, zoom to the whole feature.
        zoomToGeometry(note.geometry || feature.get('geometry').toJS());
      }
    }
  }, [
    feature,
    featureCollection,
    note,
    notesActions,
    onSelectImageRefs,
    onSelectNote,
    zoomToGeometry,
  ]);

  const [showRerunChartAnalysisTool, setShowRerunChartAnalysisTool] = React.useState(false);

  const isProcessingData =
    feature.getIn(['properties', 'status']) === FEATURE_WAITING_FOR_PROCESSING_STATUS;

  return (
    <div ref={isFocused ? focusedNoteCardRef : null}>
      {mode === 'view' ? (
        <NoteView
          profile={profile}
          organization={organization}
          featureCollection={featureCollection}
          feature={feature}
          note={note}
          cameraOptions={cameraOptions}
          enrolledLayerKeys={enrolledLayerKeys}
          isAADisabled={isProcessingData}
          onEdit={() => {
            if (note.noteType !== NOTE_TYPE_USER) {
              // Only allow editing of user type notes
              return;
            }

            // Focus the note.
            notesActions.focusNote(note.id);

            const existingGeometry = geometry || note.geometry;

            if (existingGeometry) {
              // Zoom to the geometry, if zooming function is provided.
              if (zoomToGeometry) {
                zoomToGeometry(existingGeometry);
              }

              // Update the central notes store pending geometry with the note's geometry, if it's different.
              if (!isEqual(existingGeometry, notesState.pendingNoteGeometryFeature?.geometry)) {
                notesActions.setPendingNoteGeometryFeature(
                  geoJsonUtils.feature(existingGeometry, {}, {id: `pending-note-location-feature`})
                );
              }
            }

            onChangeMode('edit');
          }}
          onDelete={() => {
            handleDeleteNotes(notesActions, [note!]);
            unsetFocusedNoteId();
          }}
          isFocused={isFocused}
          disableEditing={disableEditing}
          onFocusNote={onFocusNote}
          onHoverNote={() => notesActions.hoverNote(note.id)}
          onUnhoverNote={() => notesActions.hoverNote(null)}
          openAnalyzePolygonPopup={openAnalyzePolygonPopup}
          isChartLoading={isChartLoading}
          setShowRerunChartAnalysisTool={setShowRerunChartAnalysisTool}
        />
      ) : (
        <NoteForm
          showRerunChartAnalysisTool={showRerunChartAnalysisTool}
          firebaseToken={firebaseToken}
          featureData={featureData}
          organization={organization}
          profile={profile}
          featureCollection={featureCollection}
          imageRefs={imageRefs}
          notesState={notesState}
          notesActions={notesActions}
          note={note}
          onClose={() => {
            setShowRerunChartAnalysisTool(false);
            onChangeMode('view');
          }}
          onNoteCreated={onNoteCreated}
          onSelectImageRefs={onSelectImageRefs}
          promptForCoordinates={promptForCoordinates}
          zoomToGeometry={zoomToGeometry}
          disableGeometry={disableGeometry}
          isFocused={isFocused}
          graph={graph}
          updateFeatureCollection={updateFeatureCollection}
        />
      )}
    </div>
  );
};

export default NoteCard;
