import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';

import {useManageFeatures} from './ManageFeaturesProvider';
import ManageFeaturesView from './ManageFeaturesView';

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
}

const ManagePropertiesUpload: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
}) => {
  const {manageFeaturesState, manageFeaturesActions, loading} = useManageFeatures();

  return (
    <ManageFeaturesView
      history={history}
      profile={profile}
      organization={organization}
      manageFeaturesState={manageFeaturesState}
      manageFeaturesActions={manageFeaturesActions}
      loggedInUserActions={loggedInUserActions}
      mode={'upload'}
      loading={loading}
      firebaseToken={null}
    />
  );
};

export default ManagePropertiesUpload;
