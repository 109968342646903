import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {FeaturesActions} from 'app/providers/FeaturesProvider';
import {NotesActions, NotesState, StateApiNote, handleDeleteNotes} from 'app/stores/NotesStore';
import {recordEvent} from 'app/tools/Analytics';
import colors from 'app/utils/colorUtils';
import {ALERT_OWNERSHIP_CHANGE_USER_ID, ALERT_VEGETATION_DROP_USER_ID} from 'app/utils/constants';
import featureFlags from 'app/utils/featureFlags';
import * as mapUtils from 'app/utils/mapUtils';
import * as noteUtils from 'app/utils/noteUtils';

import {useAlertPolicies} from './AlertPolicyProvider';
import {AlertsPoliciesPanel} from './AlertsPoliciesPanel';
import cs from './styles.styl';
import AlertCard from '../NoteCard/AlertCard';
import paneCs from '../NotesReports/styles.styl';
import {usePushNotification} from '../Notification';
import LookoutIcon from './LookoutIcon';

export interface Props {
  feature: I.ImmutableOf<ApiFeature>;
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;
  allFeatures: I.ImmutableListOf<ApiFeature>;
  notesState: NotesState;
  notesActions: NotesActions;
  onSelectImageRefs: (imageRefs: mapUtils.MapImageRefs) => void;
  zoomToGeometry: (g: geojson.GeoJSON) => unknown;
  onSelectNote: (nId: number | string) => unknown;
  updateFeature: FeaturesActions['updateFeature'];
  enrolledLayerKeys: string[];
  organization: I.ImmutableOf<ApiOrganization>;
}

export interface FilterOption {
  id: number;
  label: string;
  color?: string;
  className: string;
  icon: React.ReactElement | null;
  filter: (n: StateApiNote) => boolean;
}

const noop = (_: StateApiNote) => true;
const isVegetationDropAlert = (n: StateApiNote) => n.userId === ALERT_VEGETATION_DROP_USER_ID;
const isOwnershipChangeAlert = (n: StateApiNote) => n.userId === ALERT_OWNERSHIP_CHANGE_USER_ID;

const DEFAULT_FILTER_OPTIONS: FilterOption[] = [
  {id: 0, label: 'All', className: '', icon: null, filter: noop},
  {
    id: 1,
    label: 'Vegetation Drop',
    className: cs.vegetation,
    color: colors.green,
    icon: <B.Icon icon="tree" color={colors.green} />,
    filter: isVegetationDropAlert,
  },
  {
    id: 2,
    label: 'Ownership Change',
    className: cs.ownership,
    color: colors.orange,
    icon: <B.Icon icon="key" color={colors.green} />,
    filter: isOwnershipChangeAlert,
  },
];

const Alerts: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  feature,
  featureCollection,
  allFeatures,
  notesState,
  notesActions,
  updateFeature,
  onSelectImageRefs,
  zoomToGeometry,
  onSelectNote,
  organization,
}) => {
  const [filterId, setFilterId] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');
  const [selectedNotes, setSelectedNotes] = React.useState<Set<number | string>>(new Set());
  const noteScrollContainer = React.useRef<HTMLDivElement>(null);
  const focusedNoteCardRef = React.useRef<HTMLDivElement>(null);
  const prevFocusedNoteIdRef = React.useRef<string | number | null>(notesState.focusedNoteId);
  const allJSFeatures = React.useMemo(() => allFeatures.toJS(), [allFeatures]);

  const {alertPolicies} = useAlertPolicies();
  const pushNotification = usePushNotification();

  const orgHasAccessToAlerts = featureFlags.LOOKOUTS(organization);

  // count of how many alert enrollments this feature has, so that we can distinguish between a
  // true blankstate (user hasn't enrolled this feature in any properties) and a zero state
  // (this feature is enrolled in x policies, but none have active alerts)
  const featurePolicyEnrollmentCount = React.useMemo(() => {
    let customPoliciesCount: number = alertPolicies.reduce(
      (acc, currentPolicy) =>
        acc +
        currentPolicy.enrollments.filter((enrollment) => enrollment.featureId === feature.get('id'))
          .length,
      0
    );
    if (feature.getIn(['properties', '__app', 'processVegetationDrop'])) {
      customPoliciesCount++;
    }
    if (feature.getIn(['properties', '__app', 'processOwnershipChange'])) {
      customPoliciesCount++;
    }
    return customPoliciesCount;
  }, [alertPolicies, feature]);

  const onFocusNote = React.useCallback(
    (note: StateApiNote) => {
      if (note) {
        notesActions.focusNote(note.id);

        onSelectImageRefs(note.imageRefs);

        if (onSelectNote) {
          onSelectNote(note.id);
        }

        if (note.geometry && zoomToGeometry) {
          zoomToGeometry(note.geometry);
        }
      }
    },
    [notesActions, onSelectImageRefs, onSelectNote, zoomToGeometry]
  );

  const onCreateNoteFromAlert = React.useCallback(
    async (note: StateApiNote) => {
      // create a new note (defaults to user type) and delete the alert
      const newNote = await notesActions.createNote(
        {
          text: note.text,
          geometry: note.geometry,
          attachments: note.attachments,
          imageRefs: note.imageRefs,
          graph: note.graph,
          tagIds: note.tagIds,
          properties: note.properties,
        },
        {focus: true}
      );
      pushNotification({
        message: 'Saved as note',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.SUCCESS,
        },
      });
      // skip confirmation
      handleDeleteNotes(notesActions, [note!], true);
      recordEvent('Created note from Lookout notification', {
        projectId: newNote.projectId,
        featureCollectionId: newNote.featureCollectionId,
        noteId: newNote.id,
      });
    },
    [notesActions, pushNotification]
  );

  // Handles alerts focused by clicking on the map
  React.useEffect(() => {
    // If a focusedNoteId is set, and is different from a previous focused note, scroll to the new note.
    if (focusedNoteCardRef && prevFocusedNoteIdRef.current !== notesState.focusedNoteId) {
      noteUtils.scrollToFocusedNote(focusedNoteCardRef, noteScrollContainer);
    }
    // Update our ref so that we can detect new values
    prevFocusedNoteIdRef.current = notesState.focusedNoteId;
  }, [notesState.focusedNoteId]);

  // Cleanup function for component unmount
  React.useEffect(
    () => () => notesActions.stopEditingPendingGeometry(),

    []
  );

  // Track which notes are selected
  const toggleNoteSelected = (id) => {
    selectedNotes.has(id) ? selectedNotes.delete(id) : selectedNotes.add(id);
    setSelectedNotes(new Set(selectedNotes));
  };

  // Enables us to apply the default 'All' filter when a specific filter is disabled
  const toggleFilter = (id) => (id === filterId ? setFilterId(0) : setFilterId(id));

  const toggleSortDirection = () =>
    sortDirection === 'asc' ? setSortDirection('desc') : setSortDirection('asc');

  const availableNotes = notesState.notes.filter((n) => !n.isArchived && n.noteType === 'alert');

  // Our list of notes should already be sorted, since the notes endpoint provides this.
  // Reverse is an in place operation, so we don't need to reassign.
  if (sortDirection === 'desc') {
    availableNotes.reverse();
  }

  const selectedFilters = [
    ...DEFAULT_FILTER_OPTIONS,
    ...alertPolicies.map((alertPolicy, idx) => ({
      id: idx + DEFAULT_FILTER_OPTIONS.length, // Hack to account for the three default filter options
      label: alertPolicy.name,
      className: cs.custom,
      icon: <LookoutIcon />,
      color: colors.primaryIntent,
      filter: (n: StateApiNote) => n.properties?.alert_policy_id === alertPolicy.id,
    })),
  ].map((fo) => ({...fo, selected: fo.id === filterId}));
  // Apply selected filtering
  // Note, we are using a non-null assertion operator here, but it should be
  // relatively safe to do so because we're using these same options to set values.
  const visibleNotes = availableNotes.filter(selectedFilters.find((f) => f.selected)!.filter);

  const dismissSelected = React.useCallback(() => {
    // Get all selected notes (even if they're currently filtered)
    const pendingDeletion = availableNotes.filter((n) => selectedNotes.has(n.id));
    // Last parameter here is false to ensure we display a confirm dialog before deleting these
    const deleteSucceeded = handleDeleteNotes(notesActions, pendingDeletion, false);
    // If the delete succeeded and wasnt confirm cancelled, clear our selected cards
    if (deleteSucceeded) {
      setSelectedNotes(new Set());
    }
  }, [availableNotes, notesActions, selectedNotes]);

  if (notesState.loading) {
    // Render loading container.
    return (
      <div className={cs.loadingContainer}>
        <B.Spinner />
      </div>
    );
  }

  return (
    <div ref={noteScrollContainer} className={classnames(cs.body, cs.container)}>
      {orgHasAccessToAlerts && (
        <>
          <AlertsPoliciesPanel
            {...{updateFeature, feature, featureCollection, allFeatures: allJSFeatures}}
          />
          <B.Divider />
          <StatusLine
            visibleCount={visibleNotes.length}
            totalCount={availableNotes.length}
            filterOptions={selectedFilters}
            toggleFilter={toggleFilter}
            sortDirection={sortDirection}
            toggleSortDirection={toggleSortDirection}
            selectedCount={selectedNotes.size}
            dismissSelected={dismissSelected}
          />
          {visibleNotes.length === 0 &&
            (featurePolicyEnrollmentCount === 0 ? (
              <B.NonIdealState
                className={paneCs.bodyEmpty}
                iconMuted={false}
                icon={<LookoutIcon size={60} />}
              >
                <p>
                  Automatically look out for changes on your property. We’ll notify you as soon as
                  anything happens so you can rest easy.
                </p>
                <p>
                  <strong>Enroll in a preset policy or create your own!</strong>
                </p>
              </B.NonIdealState>
            ) : (
              <p>No new changes detected on this property.</p>
            ))}

          <div className={cs.alerts}>
            {visibleNotes.map((note) => (
              <AlertCard
                key={note.id}
                note={note}
                toggleSelected={toggleNoteSelected}
                onSelectNote={onFocusNote}
                onCreateNoteFromAlert={onCreateNoteFromAlert}
                onDelete={() => {
                  // If the note is focused, unfocus it before deletion
                  if (notesState.focusedNoteId === note.id) notesActions.focusNote(null);
                  handleDeleteNotes(notesActions, [note!]);
                }}
                isFocused={note.id === notesState.focusedNoteId}
                onHoverNote={() => notesActions.hoverNote(note.id)}
                onUnhoverNote={() => notesActions.hoverNote(null)}
                isSelected={selectedNotes.has(note.id)}
              />
            ))}
          </div>
        </>
      )}

      {!orgHasAccessToAlerts && (
        <B.NonIdealState
          className={paneCs.bodyEmpty}
          iconMuted={false}
          icon={<LookoutIcon size={60} />}
        >
          <p>
            <a href="https://support.upstream.tech/article/164-lens-lookout">Lens Lookout</a> helps
            you automatically identify changes based on your specific criteria.
          </p>
          <p>
            <strong>Available in Standard and Enterprise plans only.</strong> Reach out to{' '}
            <a href="mailto:lens@upstream.tech">lens@upstream.tech</a> about upgrading today.
          </p>
        </B.NonIdealState>
      )}
    </div>
  );
};

type SelectedFilterOption = FilterOption & {selected: boolean};

export interface StatusLineProps {
  visibleCount: number;
  totalCount: number;
  filterOptions: SelectedFilterOption[];
  toggleFilter: (id: number) => void;
  sortDirection: 'asc' | 'desc';
  toggleSortDirection: () => void;
  selectedCount: number;
  dismissSelected: () => void;
}

export const StatusLine = ({
  visibleCount,
  totalCount,
  filterOptions,
  toggleFilter,
  sortDirection,
  toggleSortDirection,
  selectedCount,
  dismissSelected,
}: StatusLineProps) => {
  const selectedFilter = filterOptions.find((fo) => fo.selected);

  // Filter popover contents
  const filterOptionsContent = () => (
    <>
      {filterOptions.map((fo) => {
        return (
          <div key={fo.id} className={cs.filterOption}>
            <a className={fo.className} onClick={() => toggleFilter(fo.id)}>
              <B.Icon className={cs.filterTick} icon={fo.selected ? 'tick' : 'blank'} />
              <span className={cs.filterLabel}>
                <span>{fo.label}</span>
              </span>
            </a>
          </div>
        );
      })}
    </>
  );

  // If we don't have any alerts, don't render anything, since there's nothing to action.
  if (totalCount < 1) {
    return null;
  }

  return (
    <div className={cs.statusLine}>
      <span>
        <strong>{`Review change notifications (${visibleCount})`}</strong>
      </span>
      <B.ButtonGroup minimal>
        <B.Button
          icon={sortDirection === 'asc' ? 'sort-asc' : 'sort-desc'}
          onClick={toggleSortDirection}
        />

        <B.Popover className={cs.filterPopover} content={filterOptionsContent()}>
          <B.Button
            icon={'filter'}
            text={
              selectedFilter && selectedFilter.icon
                ? React.cloneElement(selectedFilter.icon, {
                    color: selectedFilter.color,
                    style: {margin: '0 0.25rem'},
                    className: selectedFilter.className,
                  })
                : selectedFilter!.label
            }
          />
        </B.Popover>

        {selectedCount > 0 && (
          <B.Button
            className={cs.dismissButton}
            icon={'trash'}
            onClick={dismissSelected}
          >{`Dismiss (${selectedCount})`}</B.Button>
        )}
      </B.ButtonGroup>
    </div>
  );
};

export default Alerts;
