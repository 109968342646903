import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import MapOverlayDialog from 'app/components/MapOverlayDialog/MapOverlayDialog';
import {MAPBOX_STYLES, MapStyle} from 'app/utils/mapUtils';

import * as cs from './ManageLayersDialog.styl';

interface BasemapProps {
  onChangeMapStyle: (mapStyle: MapStyle) => void;
  selectedStyle: MapStyle;
  onClose: () => void;
}

/**
 * Dialog for viewing and customizing vector overlays and basemaps
 * Implemented with MapOverlayDialog so that most of the map is visible while
 * you’re tweaking colors.
 */
const ManageBasemapDialog: React.ForwardRefRenderFunction<HTMLDivElement, BasemapProps> = ({
  onClose,

  selectedStyle,
  onChangeMapStyle,
}) => {
  return (
    <MapOverlayDialog
      title="Change Basemap"
      titleIcon={<B.Icon icon="layers" style={{marginTop: 2}} />}
      className={cs.dialog}
      onClose={onClose}
      canOutsideClickClose={true}
    >
      <BasemapView selectedStyle={selectedStyle} onChangeMapStyle={onChangeMapStyle} />
    </MapOverlayDialog>
  );
};

export const BasemapView: React.FunctionComponent<
  React.PropsWithChildren<{
    selectedStyle: MapStyle;
    onChangeMapStyle: (styleId: MapStyle) => void;
  }>
> = ({selectedStyle, onChangeMapStyle}) => {
  return (
    <div>
      {MAPBOX_STYLES.map((style) => {
        return (
          <div
            className={classnames(
              {[cs.overlaySelected]: style.id === selectedStyle},
              cs.overlayRow
            )}
            key={style.id}
            onClick={() => {
              onChangeMapStyle(style.id);
            }}
          >
            <div
              className={cs.basemapThumbnail}
              style={{backgroundImage: `url(${style.thumbnailSrc})`}}
            />
            <div className={cs.basemapText}>
              <div className={cs.basemapName}>{style.title}</div>
              <div className={cs.basemapDescription}>{style.description}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

// forwardRef so that our parent can get access to the div for sizing
export default React.forwardRef(ManageBasemapDialog);
