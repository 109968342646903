import React from 'react';

import {usePushNotification} from 'app/components/Notification';
import {useGeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {WithImageryPrices} from 'app/providers/ImageryPricesProvider';
import {WithLoadedOrgUsers} from 'app/providers/OrgUsersProvider';
import {NotesProvider} from 'app/stores/NotesStore';
import * as historyUtils from 'app/utils/historyUtils';
import * as layers from 'app/utils/layers';
import * as routeUtils from 'app/utils/routeUtils';

import {useMapState} from './MapStateProvider';
import View, {Props} from './view';
import MapPolygonStateProvider, {
  WithMapPolygonState,
} from '../../providers/MapPolygonStateProvider';

const MonitorProjectViewDataProvider: React.FunctionComponent<
  Omit<
    Props,
    | 'organizationUsers'
    | 'pushNotification'
    | 'notesState'
    | 'notesActions'
    | 'polygonState'
    | 'polygonDispatch'
    | 'mode'
    | 'imageRefs'
    | 'mapStateDispatch'
    | 'imageRefFromUrl'
    | 'geoJsonFeaturesLoader'
    | 'featureData'
    | 'imageryPrices'
    | 'refreshImageryPrices'
  >
> = (props) => {
  const {
    history,
    selectedFeature,
    selectedFeatures,
    profile,
    selectedProject,
    selectedFeatureCollection,
    getFeatureData,
    invalidateOrderableScenes,
  } = props;

  const selectedProjectId = selectedProject.get('id');
  const selectedFeatureCollectionId = selectedFeatureCollection.get('id');
  const selectedFeatureId = selectedFeature && selectedFeature?.get('id');

  React.useEffect(() => {
    //eslint-disable-next-line no-console
    console.log(
      `Selection changed: <project: ${selectedProjectId}> <featureCollection: ${selectedFeatureCollectionId}> <featureIds: ${selectedFeatureId}>`
    );
  }, [selectedProjectId, selectedFeatureCollectionId, selectedFeatureId]);

  // Parse the focused note ID from the URL.
  //
  // TODO(fiona): Is this better handled in an
  // componentDidMount/componentDidUpdate check inside the view?
  let focusedNoteId: number | null = null;
  const noteIdMatch = historyUtils.matchInHash(history, routeUtils.NOTE_URL_PARAM_REGEX);

  // First match group is input, second match group is `note-`, third match
  // group is note ID.
  if (noteIdMatch && noteIdMatch.length > 2) {
    focusedNoteId = parseInt(noteIdMatch[2], 10);
  }

  const [mapState, mapStateDispatch] = useMapState(
    selectedFeatureCollection,
    // useMapState needs the database IDs of the features, so we can’t use
    // selectedFeatureIdParams. This delays loading feature data slightly during
    // an initial page load that’s a direct link to the feature, since we need
    // to wait for the entire feature collection to load before loading any
    // featuredata.
    selectedFeatures.map((f) => f!.get('id')).toSet(),
    getFeatureData,
    !!focusedNoteId
  );

  const cursorMatch = historyUtils.matchInHash(history, routeUtils.SCENE_URL_PARAM_REGEX);
  const cursorFromUrl = cursorMatch && cursorMatch[3];
  // layerKey is optional because we previously didn’t use it.
  const layerKeyFromUrl = cursorMatch && cursorMatch[2] ? cursorMatch[2].slice(0, -1) : null;

  // TODO(fiona): Could push the StatusMaybe further down, to allow for partial
  // display. But for now all the code assumes that FeatureData is complete when
  // presented, so we don’t want to send a partial list.
  const featureDataMaybe =
    selectedFeatureId === null
      ? null
      : getFeatureData(selectedFeatureId, {
          // We want FeatureData within the last hour.
          afterMs: Date.now() - 1000 * 60 * 60,
          allowStaleMs: true,
        });
  const featureData = featureDataMaybe?.status === 'some' ? featureDataMaybe.value : null;

  React.useEffect(() => {
    // Clear out the hash because as of now we’re not maintaining the cursor key
    // in the hash, but don’t do it until featureData is loaded so we can
    // know whether to go to the cursor or pop up an order imagery box.
    if (cursorMatch && featureData) {
      historyUtils.updateHash(history, routeUtils.SCENE_URL_PARAM_REGEX, null);
    }
  });

  const geoJsonFeaturesLoader = useGeoJsonFeaturesLoader();

  // The purpose of this is to invlidate orderableScenes once an order is done processing
  // and is ready to view. FeatureData updates in this case so we use that update to trigger
  // the invalidation. This is not a perfect solution and invalidates orderableScenes
  // way more than necesary (e.g. when switching between features). But we don't currently have
  // a cleaner way of doing this.
  React.useEffect(() => {
    if (selectedFeatureId) {
      invalidateOrderableScenes(selectedFeatureId);
    }
  }, [selectedFeatureId, featureData, invalidateOrderableScenes]);

  const pushNotification = usePushNotification();

  return (
    <WithLoadedOrgUsers refreshOnMount>
      {(organizationUsers) => (
        <WithImageryPrices>
          {({prices: imageryPrices, refresh: refreshImageryPrices}) => (
            <MapPolygonStateProvider featureId={selectedFeatureId}>
              <WithMapPolygonState>
                {(polygonState, polygonDispatch) => (
                  <NotesProvider
                    profile={profile}
                    organizationUsers={organizationUsers}
                    projectId={selectedProject.get('id')}
                    featureCollectionId={selectedFeatureCollection.get('id')}
                    featureId={selectedFeatureId}
                    focusedNoteId={focusedNoteId}
                  >
                    {(notesState, notesActions) => (
                      <View
                        {...props}
                        organizationUsers={organizationUsers}
                        pushNotification={pushNotification}
                        notesState={notesState}
                        notesActions={notesActions}
                        polygonState={polygonState}
                        polygonDispatch={polygonDispatch}
                        featureData={featureData}
                        mode={mapState.mode}
                        imageRefs={mapState.imageRefs}
                        mapStateDispatch={mapStateDispatch}
                        imageRefFromUrl={
                          cursorFromUrl
                            ? {
                                cursor: cursorFromUrl,
                                // Fall back to truecolor for old URLs
                                layerKey: layerKeyFromUrl ?? layers.ANY_TRUECOLOR_HIGH_RES,
                              }
                            : null
                        }
                        geoJsonFeaturesLoader={geoJsonFeaturesLoader}
                        focusedNoteId={focusedNoteId}
                        imageryPrices={imageryPrices}
                        refreshImageryPrices={refreshImageryPrices}
                      />
                    )}
                  </NotesProvider>
                )}
              </WithMapPolygonState>
            </MapPolygonStateProvider>
          )}
        </WithImageryPrices>
      )}
    </WithLoadedOrgUsers>
  );
};

export default MonitorProjectViewDataProvider;
