import * as B from '@blueprintjs/core';
import * as S from '@blueprintjs/select';
import classnames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import debounce from 'lodash/debounce';
import escapeRegExp from 'lodash/escapeRegExp';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiProject} from 'app/modules/Remote/Project';
import {ApiResponse} from 'app/utils/apiUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as routeUtils from 'app/utils/routeUtils';

import cs from './styles.styl';

export interface Props {
  isOpen: boolean;
  history: History;
  projects: I.List<I.ImmutableOf<ApiProject>>;
  searchFeatures: (
    q: string,
    activeLensOnly: boolean,
    featureCollectionIds: number[]
  ) => Promise<ApiResponse<ApiFeature[]>>;
  onClose?: () => unknown;
  staticSearchbar?: boolean;
  placeholderText?: string;
}

type Item = ApiProject | ApiFeature;

const Omnisearch: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  isOpen,
  history,
  projects,
  onClose,
  searchFeatures,
  staticSearchbar = false,
  placeholderText = 'Search...',
}) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [matchingProjects, setMatchingProjects] = React.useState<ApiProject[] | null>(null);
  const [matchingFeatures, setMatchingFeatures] = React.useState<ApiFeature[] | null>(null);
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);

  const projectsByPrimaryFeatureCollectionId = React.useMemo(() => {
    return projects.reduce((acc, p) => {
      const primaryFeatureCollectionId = p!
        .get('featureCollections')
        .find((fc) => fc?.get('kind') === 'primary')
        .get('id');
      return acc!.set(primaryFeatureCollectionId, p!);
    }, I.Map<number, I.ImmutableOf<ApiProject>>());
  }, [projects]);

  const isApiFeature = (item): item is ApiFeature => {
    return item.type === 'Feature';
  };

  const findFeatureProject = (feature: ApiFeature) =>
    projectsByPrimaryFeatureCollectionId.get(feature.properties.featureCollectionId);

  const onItemSelect = (item: Item) => {
    setSearchQuery('');
    setMatchingProjects(null);
    setMatchingFeatures(null);

    if (isApiFeature(item)) {
      const project = findFeatureProject(item);
      history.push(
        routeUtils.makeProjectDashboardUrl(
          project.get('organizationId'),
          project.get('id'),
          'map',
          [item.properties.lensId!]
        )
      );
    } else {
      const organizationId = item.organizationId;
      const projectId = item.id;
      history.push(routeUtils.makeProjectDashboardUrl(organizationId, projectId));
    }

    !!onClose && onClose();
  };

  const renderItem = (
    item: ApiProject | ApiFeature,
    {handleClick, modifiers, query}: S.ItemRendererProps
  ) => {
    let name, icon, labelElement;
    if (isApiFeature(item)) {
      name = featureDisplayName(item);
      icon = 'geolocation';
      const project = findFeatureProject(item);
      labelElement = (
        <div
          className={classnames(
            cs.featureProjectName,
            modifiers.active ? cs.featureProjectNameActive : cs.featureProjectNameInactive
          )}
        >
          {project.get('name')}
        </div>
      );
    } else {
      name = item.name;
      icon = 'globe';
      labelElement = null;
    }

    const text = highlightText(name, escapeRegExp(query));

    return (
      <B.MenuItem
        icon={icon}
        active={modifiers.active}
        disabled={modifiers.disabled}
        labelElement={labelElement}
        key={item.id}
        onClick={handleClick}
        text={text}
        labelClassName={cs.menuItemLabel}
      />
    );
  };

  const renderNoResults = () => {
    const showNoResults = searchQuery.length > 0 && !isRequesting;

    if (showNoResults) {
      return (
        <div className={cs.noResultsContainer}>
          <B.NonIdealState icon="search" title="No results..." iconMuted={false} />
        </div>
      );
    } else {
      return (
        <div className={cs.loadingContainer}>
          <B.Spinner size={B.SpinnerSize.SMALL} />
        </div>
      );
    }
  };

  const onChangeInput = (query: string) => {
    setSearchQuery(query);
    setIsRequesting(!!query.length);
    if (query.length) {
      onSearchQueryChange(query);
    } else {
      setMatchingProjects(null);
      setMatchingFeatures(null);
    }
  };

  const renderQueryList = (listProps: S.QueryListRendererProps<Item>) => {
    return (
      <B.Overlay
        isOpen={isOpen}
        className={S.Classes.OMNIBAR_OVERLAY}
        hasBackdrop={true}
        backdropClassName={cs.omnibarBackdrop}
        onClose={() => {
          setSearchQuery('');
          setMatchingProjects(null);
          setMatchingFeatures(null);
          !!onClose && onClose();
        }}
      >
        <div
          className={classnames(listProps.className, S.Classes.OMNIBAR, cs.omnibar)}
          onKeyDown={listProps.handleKeyDown}
          onKeyUp={listProps.handleKeyUp}
        >
          <B.InputGroup
            autoFocus={true}
            large={true}
            leftIcon="search"
            placeholder={placeholderText}
            value={searchQuery}
            onChange={(ev) => onChangeInput(ev.currentTarget.value)}
          />
          {listProps.itemList}
        </div>
      </B.Overlay>
    );
  };

  const onSearchQueryChange = React.useCallback(
    debounce(async (query: string) => {
      if (!query) {
        return;
      }

      const featureSearchResponse = await searchFeatures(
        query,
        false,
        projectsByPrimaryFeatureCollectionId.keySeq().toJS()
      );

      // We convert back from Immutable before setting in state so that we have
      // a stable JS object. (Rather than doing .toJS() during every render.)
      setMatchingProjects(
        projects
          .filter((p) => p!.get('name').toLowerCase().includes(query.toLowerCase()))
          .toJS()
          .sort((p1: ApiProject, p2: ApiProject) => featureUtils.alphanumericSort(p1.name, p2.name))
      );
      setMatchingFeatures(
        (featureSearchResponse.get('data').toJS() as ApiFeature[]).sort((f1, f2) => {
          // Sort by project name to group features by project
          // Within a project, sort by feature name
          if (f1.properties.featureCollectionId === f2.properties.featureCollectionId) {
            return featureUtils.alphanumericSort(featureDisplayName(f1), featureDisplayName(f2));
          } else {
            return featureUtils.alphanumericSort(
              findFeatureProject(f1).get('name'),
              findFeatureProject(f2).get('name')
            );
          }
        })
      );
      setIsRequesting(false);
    }, 300),
    []
  );

  if (staticSearchbar) {
    const items = !isRequesting ? [...(matchingProjects || []), ...(matchingFeatures || [])] : [];
    return (
      <div className={cs.staticSearchbar}>
        <S.Suggest
          initialContent={
            <div className={cs.noResultsContainer}>
              <B.NonIdealState icon="search" title="No results..." iconMuted={false} />
            </div>
          }
          resetOnClose={true}
          openOnKeyDown={false}
          itemPredicate={() => true}
          itemRenderer={renderItem}
          noResults={renderNoResults()}
          items={items}
          fill
          onItemSelect={onItemSelect}
          popoverProps={{
            minimal: true,
            popoverClassName: cs.staticSearchbarPopover,
            portalClassName: cs.staticSearchbarPortal,
            position: 'bottom',
          }}
          inputProps={{
            placeholder: placeholderText,
            leftIcon: 'search',
          }}
          query={!items.length ? undefined : searchQuery}
          onQueryChange={(q) => {
            onChangeInput(q);
          }}
        />
      </div>
    );
  }

  return (
    <S.QueryList
      initialContent={null}
      // We’re only ever passing in items that match the query (hence the
      // predicate that always returns true) but we need to give a value for
      // query to make QueryList choose between its "initialContent" and
      // "results"/"no results" states.
      query={searchQuery}
      renderer={renderQueryList}
      itemPredicate={() => true}
      itemRenderer={renderItem}
      noResults={renderNoResults()}
      items={!isRequesting ? [...(matchingProjects || []), ...(matchingFeatures || [])] : []}
      onItemSelect={onItemSelect}
    />
  );
};

function featureDisplayName(feature: ApiFeature) {
  return `${feature.properties.name} ${feature.properties.multiFeaturePartName || []}`;
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query.split(/\s+/).filter((word) => word.length > 0);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens: React.ReactNode[] = [];

  for (;;) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

export default Omnisearch;
