import * as B from '@blueprintjs/core';
import React from 'react';

import * as cs from './styles.styl';

/** A component to override some Blueprint styles and allow for a
 * large button with a large icon positioned above the Button text.
 */
export const ButtonLarge = ({intent, icon, text, onClick}) => {
  return (
    <B.Button
      className={cs.buttonLarge}
      intent={intent}
      text={text}
      onClick={onClick}
      icon={<B.Icon icon={icon} size={48} />}
    ></B.Button>
  );
};
