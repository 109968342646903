import React from 'react';

import GeometryFileFieldSelector from 'app/components/ManagePortfolio/GeometryFileFieldSelector';
import {GeometryFileUploader} from 'app/components/ManagePortfolio/GeometryFileUploader';

import {NumberedFeatureCollection} from './ManageFeaturesProvider';

import {ManagePropertiesMode} from '.';

export interface Props {
  needsPartName: boolean;
  isConvertingFile: boolean;
  problems: string[] | null;
  filename: string | null;
  onFilenameChange: (file: File) => void;
  uploadedFeatureCollection: NumberedFeatureCollection | null;
  nameField: string | null;
  partNameField: string | null;
  setNameField: React.Dispatch<React.SetStateAction<string | null>>;
  setPartNameField: React.Dispatch<React.SetStateAction<string | null>>;
  mode: ManagePropertiesMode;
}

const ManagePropertiesFileUploader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  needsPartName,
  isConvertingFile,
  problems,
  filename,
  uploadedFeatureCollection,
  nameField,
  partNameField,
  setNameField,
  setPartNameField,
  onFilenameChange,
  mode,
}) => {
  return (
    <div>
      <GeometryFileUploader
        isConvertingFile={isConvertingFile}
        conversionErrors={problems}
        filename={filename}
        onFilenameChange={onFilenameChange}
        hasSelection={!!uploadedFeatureCollection}
        supportDocUrl={
          'https://support.upstream.tech/article/147-preparing-geospatial-data-for-lens'
        }
        title={`Upload your new ${mode === 'overlay' ? 'overlay' : 'properties'}`}
      />
      {!problems && uploadedFeatureCollection && uploadedFeatureCollection.features && (
        <GeometryFileFieldSelector
          features={uploadedFeatureCollection.features}
          needsPartName={needsPartName}
          nameField={nameField}
          partNameField={partNameField}
          setNameField={setNameField}
          setPartNameField={setPartNameField}
          mode={mode}
        />
      )}
    </div>
  );
};

export default ManagePropertiesFileUploader;
