import * as turf from '@turf/turf';
import * as geojson from 'geojson';
import * as I from 'immutable';

import {displayLayerInMenu} from 'app/components/LayersMenu';
import * as Remote from 'app/modules/Remote';
import {
  AlertEnrollmentKeys,
  AlertPolicy,
  ApiFeature,
  ApiFeatureCommonProperties,
  ApiFeatureData,
  ApiOrderableScene,
  FeatureDatumBounds,
  isOptionalFeatureMeasurement,
} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import {filterFeatureDataByType} from 'app/stores/RasterCalculationStore';
import {SORT_ASC, SortDirection} from 'app/utils/frontendConstants';
import {BBox2d} from 'app/utils/geoJsonUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import * as CONSTANTS from './constants';
import * as conversionUtils from './conversionUtils';
import {UTMArea} from './geoJsonUtils';
import {useCachedApiGet} from './hookUtils';
import * as layers from './layers';
import {TagStatusMap} from './tagUtils';

// The following represents a non-exhaustive, best-effort list of feature attributes, ususally for filtering.
// Reconsider using these if you need a robust method to identify attributes.
export namespace FeatureAttributes {
  // Non-exhaustive list of hidden properties.
  export const hiddenProperties: string[] = [
    '__app',
    '__system',
    '__feature_source',
    '__rdm',
    '__user_updated_properties',
    '__UPSTREAM_PROBLEMS',
    '__mapped_feature_ids',
    '__shapefile_migrate_match',
    '_area',
    '_ownershipChangeProcessedDate',
    '_ownershipInfo',
    '_vegetationDropRecentProcessedDates',
    '_UPSTREAM_ACRES',
  ];

  // Attributes which describe specific entities
  export const identifiers: string[] = ['lensId', 'name', 'multiFeaturePartName'];

  // Attributes internal to Upstream that have no real meaning outside the context of our system
  export const internalIdentifiers: string[] = ['id', 'featureCollectionId'];

  // System metadata
  export const systemMetadata: string[] = [
    'isArchived',
    'updatedAt',
    'createdAt',
    'validatedGeometry',
    'originalPolygon',
    'status',
  ];

  export const all = [
    ...hiddenProperties,
    ...identifiers,
    ...internalIdentifiers,
    ...systemMetadata,
  ];
}

export namespace FeatureAttributeExport {
  // Feature attributes which should be moved to the top / front of export lists for readability
  export const EXPORT_ID = 'Lens ID';
  export const EXPORT_NAME = 'Name';
  export const EXPORT_LOCATION_NAME = 'Location';
  export const EXPORT_AREA_HECTARE = 'Hectares';
  export const EXPORT_AREA_ACRE = 'Acres';
  export const EXPORT_AREA: {[values in ApiOrganizationAreaUnit]: string} = {
    areaHectare: EXPORT_AREA_HECTARE,
    areaAcre: EXPORT_AREA_ACRE,
  };
  export const EXPORT_NOTES = 'Notes';
  export const EXPORT_ALERTS = 'Alerts';
  export const EXPORT_ASSIGNEE = 'Assignee';
  export const EXPORT_TAGS = 'Tags';
  export const EXPORT_DUE_DATE = 'Due Date';
}

// Utility for getting attributes from a nested object structure.
// Expects path a period delimited string, e.g. "properties.__app.assigneeEmail"
export function getIn(f: ApiFeature, path: string, defaultValue?: any) {
  try {
    const value = path.split('.').reduce((obj, part) => obj[part], f);
    if (value === undefined) {
      return defaultValue;
    }
    return value;
  } catch (_) {
    return undefined;
  }
}

export function getImageRefViaOffset<K>(
  activeLayerKey: string,
  imageRefsList: I.List<mapUtils.MapImageRef>,
  featureCursor: K,
  offset: number
) {
  const currentIndex = imageRefsList.findIndex(
    (k) => (k!.cursor as any) === featureCursor && k!.layerKey === activeLayerKey
  );
  const nextIndex = currentIndex + offset;

  if (imageRefsList && nextIndex > -1) {
    return imageRefsList.get(nextIndex);
  }
}

export function toMapById(list: I.List<I.ImmutableOf<ApiFeature>> = I.List()) {
  return list.reduce(
    (acc, item) => acc!.set(item!.get('id'), item!),
    I.Map<number, I.ImmutableOf<ApiFeature>>()
  );
}

export function getIdSet<ID, T extends {id: ID}>(list: I.Iterable<number, I.MapAsRecord<T>>) {
  return list.map((i) => i!.get('id')).toSet();
}

export function getCursorKeyForLayerKey(
  _featureCollection: unknown,
  _layerKey: unknown
): keyof ApiFeatureData {
  return 'date';
}

export function findFeatureInFeatureCollection(
  featureId: number,
  featureCollection: HydratedFeatureCollection
) {
  if (!featureCollection || !featureId) {
    return null;
  }

  const features = featureCollection.get('features') || I.List();
  return features.find((f) => f!.getIn(['properties', 'id']) === featureId);
}

export function getFeatures(featureCollection: HydratedFeatureCollection) {
  return featureCollection.get('features') || I.List();
}

/**
 * Hook for getting all features associated with a feature collection. This is
 * used to get the feature count for the primary feature collection associated
 * with the project on a given contract.
 */
export function useFeatures() {
  return useCachedApiGet(async function (_, featureCollectionId: number) {
    return (
      await Remote.api.featureCollections
        .features(featureCollectionId)
        .list({perPage: 200}, {getAllPages: true})
    ).get('data');
  }, []);
}

export function getStringForComplianceEnum(complianceEnum: number) {
  switch (complianceEnum) {
    case -3:
      return 'No compliance period defined';
    case -2:
      return 'Processing';
    case -1:
      return 'Data unavailable for period';
    case 0:
      return 'Not in compliance';
    case 1:
      return 'For review';
    case 2:
      return 'In compliance';
  }
}

export function getAttribution(
  sourceDetails: ImagerySourceDetails,
  featureDataYear: number,
  license: 'link-license' | 'no-license' = 'no-license'
): string {
  let formattedAttribution = sourceDetails.copyright_template.replace(
    '<YEAR>',
    featureDataYear.toString()
  );

  // If we have a license tag and link, format the copyright template to include a link to the license.
  // This regex matches LICENSE opening and closing tags, and has a capture group for operator name.
  const matchLicense = /<LICENSE>(?<operatorName>.*)<\/LICENSE>/;
  const matched = formattedAttribution.match(matchLicense);
  const oepratorName = matched?.groups?.operatorName;
  // Check if we matched a license marker and have an operator name. If not, do nothing.
  if (oepratorName) {
    const licenseHref = sourceDetails?.licenseLink;
    // Check to see if we have a license attribute
    if (license === 'link-license' && licenseHref) {
      // Format our copyright template to include an link to the license
      // Note: the target for our anchor here must be _top. We'd normally do _blank, but CSP makes
      // this difficult for embedded contexts. This has to be paired with a allow-top-navigation-by-user-activation
      // sandbox directive on embed iframes.
      formattedAttribution = formattedAttribution.replace(
        matchLicense,
        `<a href="${licenseHref}" rel="noopener noreferrer" target="_top">${oepratorName}</a>`
      );
    } else {
      // Omit license since we didn't match a license tag in our template.
      formattedAttribution = formattedAttribution.replace(matchLicense, oepratorName);
    }
  }

  return formattedAttribution;
}

export interface ImagerySourceDetails {
  name: string;
  resolution?: number;
  revisit?: number;
  operator: string;
  hq?: boolean;
  copyright_template: string;
  shareable: boolean;
  licenseLink?: string;
}

// URL: "https://storage.googleapis.com/upstream-imagery/org/0e4fd0c5-a574-4dee-b775-78ae8d871488/112072942/2020-03-08T15%3A45%3A44Z/DIGITAL-GLOBE-30_high-res-truecolor.png"
export function sourceIdFromUrl(url: string) {
  const fileName = url.split('/').pop();
  return fileName?.split('_')[0] || null;
}

// Provide a source details key type here, so that we can constrain use of the following function.
export type SourceDetailsId = keyof typeof CONSTANTS.SOURCE_DETAILS_BY_ID;

// For a source details id, return an ImagerySourceDetails, which should be matched by SOURCE_DETAILS_BY_ID.
// This object is not exhastive though and the result may be undefined in the case of a sourceId like COG-TRUECOLOR.
export function getSourceDetails(sourceId: SourceDetailsId): ImagerySourceDetails | undefined {
  return CONSTANTS.SOURCE_DETAILS_BY_ID[sourceId];
}

/**
 * This function automatically takes the layerKey being high-res into account.
 * Any high-res layer key will match against all other high-res layer keys,
 * since this is currently the behavior we want in the app for dropdowns and
 * such.
 */
export function filterFeatureData(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string,
  sortDirection: SortDirection = SORT_ASC
) {
  const layerkeyPredicate = makeLayerKeyPredicate(layerKey);

  const filteredFeatureData = I.List<I.ImmutableOf<ApiFeatureData>>(
    featureData.filter((d) => d!.get('measurements') && layerkeyPredicate(d!))
  );

  return sortFeatureDataByDate(filteredFeatureData, sortDirection);
}

export function sortFeatureDataByDate(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  sortDirection: SortDirection = SORT_ASC
) {
  let comparator: (a: I.ImmutableOf<ApiFeatureData>, b: I.ImmutableOf<ApiFeatureData>) => number;

  if (sortDirection == SORT_ASC) {
    comparator = (a, b) => Date.parse(a.get('date')) - Date.parse(b.get('date'));
  } else {
    comparator = (a, b) => Date.parse(b.get('date')) - Date.parse(a.get('date'));
  }

  return I.List<I.ImmutableOf<ApiFeatureData>>(featureData.sort(comparator));
}

/**
 * Returns a predicate function that returns true if the ApiFeatureData object
 * contains the given layerKey.
 *
 * Treats all high-res sources the same, so passing
 * AIRBUS-PLEIADES_high-res-truecolor as the layerKey would make a predicate
 * that returned true for e.g. DIGITAL-GLOBE-30_high-res-truecolor.
 */
export function makeLayerKeyPredicate(
  layerKey: string
): (value?: I.MapAsRecord<I.ImmutableFields<ApiFeatureData>> | undefined) => boolean {
  if (layerUtils.isLayerKeyHighResTruecolor(layerKey)) {
    return (d) => !!d!.get('types').find((key) => layerUtils.isLayerKeyHighResTruecolor(key!));
  } else {
    return (d) => d!.get('types').includes(layerKey!);
  }
}

/**
 * Returns the layerKey of the first layer in the ApiFeatureData object that has
 * a high res image ordered.
 *
 * Does this by looking for the presence of templateUrls that indicate
 * processing has completed.
 *
 * Will not return ALL_high-res-truecolor.
 */
export function findFirstProcessedHighResTruecolorLayerKey(
  datum: I.ImmutableOf<ApiFeatureData>
): string | null {
  const templateUrls = datum.get('images').get('templateUrls');
  return templateUrls?.findKey((_, k) => layerUtils.isLayerKeyHighResTruecolor(k!)) || null;
}

/**
 * Returns a specific high res truecolor layer key prioritizing non-"archive" sources.
 *
 * We need this so that when the same Airbus scene is available in archive and
 * non-archive versions we show the user the non-archive one.
 */
export function findBestHighResTruecolorLayerKey(
  datum: I.ImmutableOf<ApiFeatureData>
): string | null {
  const highResTypes = datum
    .get('types')
    .filter((t) => layerUtils.isLayerKeyHighResTruecolor(t!))
    .toArray();

  // This is not… principled. But works for now.
  const nonArchiveType = highResTypes.find((t) => !t.toLocaleLowerCase().includes('archive'));

  if (nonArchiveType) {
    return nonArchiveType;
  } else {
    return highResTypes[0] || null;
  }
}

/**
 * Returns the dates that the sensing happened in.
 *
 * If the 'all-scene-sensing-times' key is not present, uses the feature datum’s
 * date as the only sensing date returned.
 */
export function getFeatureDataSensingDates(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  layerKey: string | undefined
): Date[] {
  const measurements = featureDatum.get('measurements');

  let sensingTimes: I.List<string> | null = measurements.get(`${layerKey}_all-scene-sensing-times`);
  if (!sensingTimes) {
    // Older data might not have have the sensing times for this layerKey, in that case
    // try to find the sensing times for nearest match.
    sensingTimes = measurements.find((_, key) => {
      const keyBits = key!.split('_');
      return keyBits.pop() === 'all-scene-sensing-times';
    });
  }

  const sensingDates =
    sensingTimes && !sensingTimes.isEmpty()
      ? sensingTimes.map((t) => new Date(t!)).toArray()
      : [new Date(featureDatum.get('date'))];

  sensingDates.sort((a, b) => a.getTime() - b.getTime());

  return sensingDates;
}

// Filter out all high-resolution, truecolor images that do not have an
// associated template URL.
export function getProcessedFeatureData(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string
): I.ImmutableOf<ApiFeatureData[]> {
  // If our layerKey is highResTruecolor, we want to look for any highResTruecolor
  // layer
  const filterLayerKey = layerUtils.isLayerKeyHighResTruecolor(layerKey)
    ? layers.ANY_TRUECOLOR_HIGH_RES
    : layerKey;

  return featureData.filter((d) => isFeatureDatumProcessed(d!, filterLayerKey)).toList();
}

export function isFeatureDatumProcessed(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  layerKey: string
) {
  const isPaidImagery = getIsPaid(featureDatum);
  const hasTemplateUrl = getHasTemplateUrl(featureDatum, layerKey);
  return !isPaidImagery || (isPaidImagery && hasTemplateUrl);
}

export const ORDER_IMAGERY_STATE_AVAILABLE = 'available';
export const ORDER_IMAGERY_STATE_PROCESSING = 'processing';
export const ORDER_IMAGERY_STATE_PROCESSED = 'processed';
type OrderImageryState = 'available' | 'processing' | 'processed';

// Determine the processing state for a given feature datum.
export function getOrderImageryState(
  scenes: I.ImmutableOf<[string, string][]> | undefined,
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  layerKey: string
): OrderImageryState {
  const isPaidImagery = getIsPaid(featureDatum);
  const hasTemplateUrl = getHasTemplateUrl(featureDatum, layerKey);

  const isOrderedScene = getIsOrderedScene(scenes, featureDatum);
  const isHighResTruecolor = getIsHighResTruecolor(featureDatum);
  const isOrderedHighResTruecolor = isOrderedScene && isHighResTruecolor;

  // We only make this processing check for ordered highResTruecolor images.
  // In the planetary image bundle case, we have a scene that gets ordered
  // but never gets a template url, and would show as processing forever. We
  // want to filter out this scene.
  // Limitation: right we will not show processing status for anything that's
  // not highResTruecolor. This will need to change if we add non highRes sources
  // that aren't set up like this bundle.
  if (isPaidImagery && !hasTemplateUrl && isOrderedHighResTruecolor) {
    return ORDER_IMAGERY_STATE_PROCESSING;
  } else if (isPaidImagery && !hasTemplateUrl) {
    return ORDER_IMAGERY_STATE_AVAILABLE;
  } else {
    return ORDER_IMAGERY_STATE_PROCESSED;
  }
}

export function getIsOrderedScene(
  scenes: I.ImmutableOf<[string, string][]> | undefined,
  featureDatum: I.ImmutableOf<ApiFeatureData>
): boolean {
  return (
    !!scenes &&
    !!scenes.filter(
      (i) =>
        I.is(i!.get(0), featureDatum!.get('sources').first()) &&
        I.is(new Date(i!.get(1)), new Date(featureDatum!.get('date')))
    ).size
  );
}

export function isOrderableSceneHidden(
  feature: I.ImmutableOf<ApiFeature>,
  orderableScene: I.ImmutableOf<ApiOrderableScene>
) {
  // Used to make sure we don’t hide already-ordered imagery
  const isOrdered = orderableScene.get('isOrdered');

  return (
    !isOrdered &&
    isSourceDateHidden(feature, [orderableScene.get('sourceId')], orderableScene.get('sensingTime'))
  );
}

/**
 * Returns true if there’s a date with one of the given source IDs in the
 * feature’s hiddenScenes property.
 *
 * Note that ordered imagery can appear in the list (it’s not removed on
 * ordering) so you’ll need another check if you’re just going by date and
 * sources.
 *
 * If you have access to the FeatureData object, use isSceneHidden.
 */
export function isSourceDateHidden(
  feature: I.ImmutableOf<ApiFeature>,
  sourceIds: string[],
  date: string
) {
  const appProperties = feature.getIn(['properties', '__app']);
  const hiddenScenes = appProperties?.get('hiddenScenes') || I.List();

  return !!hiddenScenes.find((scene) => {
    const hiddenSourceId = scene!.get(0);
    const hiddenDate = scene!.get(1);

    return sourceIds.includes(hiddenSourceId) && hiddenDate === date;
  });
}

export function getSceneCoverage(
  orderableScene: I.ImmutableOf<ApiOrderableScene>,
  feature: I.ImmutableOf<ApiFeature>
): number {
  const intersectionPolygon = geoJsonUtils.intersectGeometries(
    orderableScene.get('sceneGeometry').toJS(),
    turf.cleanCoords(feature.get('geometry').toJS())
  );

  // Throw an error since a scene geometry should always intersect, at least
  // partially, with the feature geometry.
  if (!intersectionPolygon) {
    throw new Error('Scene and feature geometries do not intersect');
  }

  const intersectionArea = UTMArea(intersectionPolygon.geometry);
  const featureArea = UTMArea(feature.get('geometry').toJS() as GeoJSON.Geometry);
  return Number((intersectionArea / featureArea).toFixed(3));
}

export function imageAndSourceDetailsFromScene(orderableScene: I.ImmutableOf<ApiOrderableScene>) {
  const sourceId = orderableScene.get('sourceId');
  const sourceDetails = CONSTANTS.SOURCE_DETAILS_BY_ID[sourceId];

  const imageUrls = orderableScene.get('urls').toJS() as Record<string, string>;

  // We prefer high-res truecolor images, but if they’re not available, we’ll
  // use the first image we find with the same source. If this isn’t available
  // we'll leave the URL undefined.
  let url: string | undefined = imageUrls[`${sourceId}_high-res-truecolor`];
  if (!url) {
    url = Object.entries(imageUrls).find(([key, _]) => key.startsWith(sourceId))?.[1];
  }

  return {
    url,
    sourceDetails,
    sourceId,
  };
}

/**
 * Returns the source for a given layerKey.
 *
 * Assumes that all layerKeys are of the format <source id>_<layer type>
 */
export function sourceIdFromLayerKey(layerKey: string): string {
  const [source] = layerKey.split('_');

  if (source === 'ALL' || source === 'ANY') {
    throw new Error(`Non-specific layerKey ${layerKey} passed to sourceFromLayerKey`);
  }

  return source;
}

/**Given a list of featureDatums and a layerKey, this will unroll each featureDatum into
 * multiple featureDatums per source within the featureDatum, then return that unrolled list.
 *  */
export function unrollFeatureDataBySource(
  featureData: any,
  layerKey: string
): I.ImmutableListOf<ApiFeatureData> {
  const newFeatureData = featureData.flatMap((d) =>
    d!.get('sources').map((source) => {
      return pruneFeatureDatumBySourceAndLayerKey(d, source, layerKey);
    })
  );
  return newFeatureData;
}

/**Small utility function that, given a featureDatum, a source, and a layerKey, returns
the featureDatum whittled down to only contain relevant data for that source and layer. */
export function pruneFeatureDatumBySourceAndLayerKey(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  source: string,
  layerKey: string
) {
  //The reason we need to pass in source separately rather than getting it from
  //parseLayerKey below is because we need to get the source from the
  //featureDatum, rather than from whatever source the activeLayerKey passed into
  //this function is. This allows us to keep track of imageRefs that have different layerKeys.
  const layerToCompare = layerUtils.parseLayerKey(layerKey).layerId;

  const filteredTypes = featureDatum
    .get('types')
    .filter((type) => {
      const typeLayerInfo = layerUtils.parseLayerKey(type!);
      return typeLayerInfo.sourceId === source && typeLayerInfo.layerId === layerToCompare;
    })
    .sort((typeA, typeB) => alphanumericSort(typeA, typeB))
    .toList();

  const filteredUrls = featureDatum
    .getIn(['images', 'urls'])
    .filter((_value, key) => key!.startsWith(source))
    .toMap();

  const filteredTemplateUrls = featureDatum
    .getIn(['images', 'templateUrls'])
    .filter((_value, key) => key!.startsWith(source))
    .toMap();

  const filteredMeasurements = featureDatum
    .get('measurements')
    .filter((_value, key) => key?.startsWith(source!) || isOptionalFeatureMeasurement(key!))
    .toMap();

  const modifiedFeatureDatum: I.ImmutableOf<ApiFeatureData> = featureDatum
    .set('sources', I.List([source]))
    .set('types', filteredTypes)
    .set('measurements', filteredMeasurements)
    .setIn(['images', 'urls'], filteredUrls)
    .setIn(['images', 'templateUrls'], filteredTemplateUrls);

  return modifiedFeatureDatum;
}

const ALWAYS_OVERRIDABLE_SOURCES = ['UserData', 'COG-TRUECOLOR', 'WMS-TRUECOLOR', 'WMTS-TRUECOLOR'];
export function imageAndSourceDetails(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  /**
   * Should be a specific layerKey contained in featureDatum, rather than
   * ANY_high-res-truecolor.
   */
  layerKey: string,
  organization: I.ImmutableOf<ApiOrganization>
) {
  const imageUrls = featureDatum.getIn(['images', 'urls']);
  const url = imageUrls.get(layerKey, imageUrls.first());

  const {sourceId} = layerUtils.parseLayerKey(layerKey);

  // We assume a scene coverage of 1, if it isn’t stated (not all scenes have
  // been backfilled).
  const sceneCoverage = featureDatum.getIn(
    ['measurements', `${layerKey}_scene-coverage-ratio`],
    1
  ) as number;

  let sourceDetails = CONSTANTS.SOURCE_DETAILS_BY_ID[sourceId];

  // Source details can be overridden by values on the feature datum.
  // If there's a value specific to this source then use it (e.g. COG-TRUECOLOR_copyright),
  // falling back to a unscoped override (e.g. copyright).
  // There's a chance a feature datum might contain multiple imagery sources if they had the
  // same capture dates. So, adding some restrictions to when we can apply these overrides:
  // Either, if there are no sourceDetails available, if the source's sourceDetails should always be overridden,
  // or only allow overrides if the name and operator are the same between the featureDatum and the overrides.

  const shouldAllowOverride =
    !sourceDetails ||
    ALWAYS_OVERRIDABLE_SOURCES.includes(sourceId) ||
    (featureDatum.getIn(['measurements', 'vendor_name']) === sourceDetails.operator &&
      featureDatum.getIn(['measurements', 'sensor_name']) === sourceDetails.name);

  const layerResolution = layerUtils.getLayerResolution(layerKey);
  if (layerResolution != null) {
    sourceDetails = {
      ...sourceDetails,
      resolution: layerResolution,
    };
  }

  const sceneResolution = featureDatum.getIn(['measurements', `${sourceId}_resolution`]);
  if (sceneResolution != null) {
    sourceDetails = {
      ...sourceDetails,
      resolution: sceneResolution,
    };
  }

  const operator = featureDatum.getIn(
    ['measurements', `${sourceId}_vendor_name`],
    featureDatum.getIn(['measurements', 'vendor_name'])
  );

  if (operator != null && shouldAllowOverride) {
    sourceDetails = {
      ...sourceDetails,
      operator,
    };
  }

  const copyright = featureDatum.getIn(
    ['measurements', `${sourceId}_copyright`],
    featureDatum.getIn(['measurements', 'copyright'])
  );
  if (copyright != null && shouldAllowOverride) {
    sourceDetails = {
      ...sourceDetails,
      copyright_template: copyright,
    };
  }

  if (!sourceDetails?.copyright_template) {
    sourceDetails = {
      ...sourceDetails,
      copyright_template: `${operator ? operator : organization.get('name')} <YEAR>`,
    };
  }

  const name = featureDatum.getIn(
    ['measurements', `${sourceId}_sensor_name`],
    featureDatum.getIn(['measurements', 'sensor_name'])
  );
  if (name != null && shouldAllowOverride) {
    sourceDetails = {
      ...sourceDetails,
      name,
    };
  }

  //UserData, COG-TRUECOLOR, and WMTS-TRUECOLOR have special cases for overriding because they may
  //be missing fields above. So we apply these overrides last if the sourceId is from one of these.
  if (ALWAYS_OVERRIDABLE_SOURCES.includes(sourceId)) {
    sourceDetails = {
      ...sourceDetails,
      name: (sourceDetails && sourceDetails.name) || 'Imagery',
      operator: (sourceDetails && sourceDetails.operator) || organization.get('name'),
    };
  }

  return {
    url,
    sourceDetails,
    sceneCoverage,
    sourceId,
  };
}

export function getPaidImagery(feature: I.ImmutableOf<ApiFeature>) {
  return feature.getIn([
    'properties',
    CONSTANTS.SYSTEM_PROPERTIES_KEY,
    CONSTANTS.SYSTEM_PROPERTY_PAID_IMAGERY_KEY,
  ]);
}

// Check if a featureDatum is for a high res truecolor layer by checking if their
// is a highResTruecolor layerkey in the types list
export function getIsHighResTruecolor(featureDatum: I.ImmutableOf<ApiFeatureData>): boolean {
  return !!featureDatum
    .get('types')
    .find((layerKey) => layerUtils.isLayerKeyHighResTruecolor(layerKey!));
}

export function getIsPaid(featureDatum: I.ImmutableOf<ApiFeatureData>): boolean {
  return !!featureDatum.get('types').find((layerKey) => layerUtils.isLayerPaid(layerKey!));
}

// Existance of a template url on a highResTruecolor layer means that it
// has been ordered and processed
export function getHasTemplateUrl(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  layerKey: string
): boolean {
  if (layerKey === layers.ANY_TRUECOLOR_HIGH_RES) {
    return !!findFirstProcessedHighResTruecolorLayerKey(featureDatum);
  } else {
    return !!featureDatum.getIn(['images', 'templateUrls', layerKey]);
  }
}

export type AppProperty =
  | typeof CONSTANTS.APP_PROPERTY_ASSIGNEE_EMAIL
  | typeof CONSTANTS.APP_PROPERTY_REPORTING_DUE_DATE;

export const APP_PROPERTY_NAMES = {
  [CONSTANTS.APP_PROPERTY_TAG_IDS]: 'Property Tags',
  [CONSTANTS.APP_PROPERTY_ASSIGNEE_EMAIL]: 'Assignee',
  [CONSTANTS.APP_PROPERTY_REPORTING_DUE_DATE]: 'Due Date',
};

export function isImmutableFeature(
  f: I.ImmutableOf<ApiFeature> | ApiFeature
): f is I.ImmutableOf<ApiFeature> {
  return 'toJS' in f;
}

// gets the backend-calculated area and falls back to calculating the area on the fly when not available
export function getFeatureAreaM2(f: I.ImmutableOf<ApiFeature> | ApiFeature): number {
  if (isImmutableFeature(f)) {
    return UTMArea(f.get('geometry').toJS() as GeoJSON.Geometry);
  } else {
    return UTMArea(f.geometry);
  }
}

/**
 * Algorithm for acreage of a feature as we account for it with imagery
 * ordering. We convert the meters^2 to acreage, then multiply by any coverage
 * ratio.
 *
 * We take the floor, so we’re not charging for partial acres, but we still keep
 * a minimum of 1 acre.
 */
export function featureM2ToArea(
  areaInM2: number,
  {
    coverage = 1,
    unit = CONSTANTS.UNIT_AREA_ACRE,
  }: {
    coverage?: number;
    unit?: typeof CONSTANTS.UNIT_AREA_ACRE | typeof CONSTANTS.UNIT_AREA_HECTARE;
  } = {}
) {
  return Math.max(
    1,
    Math.floor(coverage * conversionUtils.convert(areaInM2, CONSTANTS.UNIT_AREA_M2, unit))
  );
}

/**
 * A function to return a 2D GeoJSON bounding box given a bounds feature datum
 * property.
 */
export function getBboxFromBounds(
  bounds: I.ImmutableOf<BBox2d | geojson.Polygon | geojson.MultiPolygon>
) {
  const boundsJs = bounds.toJS();

  if (Array.isArray(boundsJs)) {
    // If bounds is an array, we know from the type that it’s a 2D GeoJSON
    // bounding box already.
    return boundsJs as BBox2d;
  } else {
    // Otherwise, we know it’s a GeoJSON geometry, so we get the bounding box of
    // the geometry. We don’t have 3D features, so we can safely cast this as
    // BBox2d. This allows the output to be a compatible parameter to functions
    // that specify a 2D bounding box.
    return turf.bbox(boundsJs) as BBox2d;
  }
}

export type GenericObject = Record<string, any>;
/*
  Moves object fields to the "top" of the object, so that they appear first in iterators or serialized formats.
  Example: Writing feature attributes, we want lens id, name, and multi part name to appear first in the attribute table.
  Note: The usefulness of this rests on insertion order having some bearing on serialization order for JS objects.
*/
export const moveFieldsToTop = (object: GenericObject, fields: string[]) => {
  // We don't want to mutate our parameter, so we copy it.
  const _object = Object.assign({}, object);
  let updatedObject = {};

  fields.forEach((field) => {
    updatedObject[field] = _object[field];
    delete _object[field];
  });

  // Copy the remainder of the properties over
  updatedObject = {...updatedObject, ..._object};

  return updatedObject;
};

const ALPHANUMERIC_SPLIT_REGEXP = /([0-9]+)/gm;
/**
 * Copied from https://github.com/tannerlinsley/react-table/blob/master/src/sortTypes.js
 *
 * MIT LICENSE
 *
 * We use this for consistency between the dropdown menu and the overview table.
 *
 */
export function alphanumericSort(
  aField: string | number | undefined,
  bField: string | number | undefined
) {
  const aIsUndefined = aField === undefined;
  const bIsUndefined = bField === undefined;

  if (aIsUndefined && !bIsUndefined) {
    return 1;
  } else if (!aIsUndefined && bIsUndefined) {
    return -1;
  } else if (aIsUndefined && bIsUndefined) {
    return 0;
  }

  // if both fields are numbers we can just do a simple sort
  if (typeof aField === 'number' && typeof bField === 'number') {
    return aField - bField;
  }
  // make sure values are strings so we can split below
  const a = aField!.toString().toLowerCase();
  const b = bField!.toString().toLowerCase();

  // sort empty fields to the end of the list
  if (a === '') {
    return 1;
  }
  if (b === '') {
    return -1;
  }

  // Split on number groups, but keep the delimiter
  // Then remove falsey split values
  const aBits = a.split(ALPHANUMERIC_SPLIT_REGEXP).filter(Boolean);
  const bBits = b.split(ALPHANUMERIC_SPLIT_REGEXP).filter(Boolean);

  // While
  while (aBits.length && bBits.length) {
    const aa = aBits.shift()!;
    const bb = bBits.shift()!;

    const an = parseInt(aa, 10);
    const bn = parseInt(bb, 10);

    const combo = [an, bn].sort();

    // Both are string
    if (isNaN(combo[0])) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }
      continue;
    }

    // One is a string, one is a number
    if (isNaN(combo[1])) {
      return isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }

  return aBits.length - bBits.length;
}

// Type predicate for determining if a string is an AlertEnrollmentKeys
export function isAlertEnrollmentKey(key: string): key is AlertEnrollmentKeys {
  return (
    key === CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY ||
    key === CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY
  );
}

// Checks if a key is in app properties
export function keyInAppProperties(
  selectedFeature: I.ImmutableOf<ApiFeature>,
  key: AlertEnrollmentKeys
): boolean {
  const properties = selectedFeature.getIn(['properties', CONSTANTS.APP_PROPERTIES_KEY]) || I.Map();
  return properties.get(key, false);
}

// Returns true if any alerts are enabled for this feature.
export function anyAlertsEnabled(
  feature: I.ImmutableOf<ApiFeature> | undefined,
  alertPolicies: AlertPolicy[]
): boolean {
  if (!feature) {
    return false;
  }

  // Enrollment keys may be true or false, but if the properties arent
  // set this could be undefined, so we coerce them to boolean.
  // Check that the feature is enrolled in Veg Alerts, Ownership alerts,
  // or at least one Custom Alert before displaying a count in the table.
  return !!(
    feature.getIn([
      'properties',
      CONSTANTS.APP_PROPERTIES_KEY,
      CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY,
    ]) ||
    feature.getIn([
      'properties',
      CONSTANTS.APP_PROPERTIES_KEY,
      CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY,
    ]) ||
    alertPolicies.some((alertPolicy) =>
      alertPolicy.enrollments.some((enrollment) => enrollment.featureId === feature.get('id'))
    )
  );
}

// Generates a patch for ApiFeatures used to update alert enrollment
export function makeAlertEnrollmentAppPropertyPatch(
  key: AlertEnrollmentKeys,
  value: boolean
): I.MergesInto<I.ImmutableOf<ApiFeature>> {
  return I.fromJS({
    properties: {[CONSTANTS.APP_PROPERTIES_KEY]: {[key]: value}},
  });
}

export type CollectionConsistency = 'all' | 'some' | 'none';

// Returns one of 'all', 'some', or 'none' for a selection of features based on whether
// all, some, or none are enrolled in updates for the alert type provided by key.
// Note: If an empty list is passed in, this function will return 'none', since it wouldn't
// be helpful for a downstream consumer to take action on this set as though all are enrolled.
export function enrollmentStatusForUpstreamAlert(
  selectedFeatures: I.List<I.ImmutableOf<ApiFeature>>,
  key: AlertEnrollmentKeys
): CollectionConsistency {
  if (selectedFeatures.size < 1) {
    return 'none';
  }
  const enabledCount = selectedFeatures.count((f) => (f ? keyInAppProperties(f, key) : false));
  return enabledCount === selectedFeatures.size ? 'all' : enabledCount === 0 ? 'none' : 'some';
}

// Returns one of 'all', 'some', or 'none' for a selection of features based on whether
// all, some, or none are enrolled in a given custom alert policy.
export function enrollmentStatusForCustomAlert(
  selectedFeatures: I.List<I.ImmutableOf<ApiFeature>>,
  alertPolicy: AlertPolicy
): CollectionConsistency {
  if (selectedFeatures.size < 1) {
    return 'none';
  }
  const enrollmentFeatureIds = alertPolicy.enrollments.map((enrollment) => enrollment.featureId);
  const enabledCount = selectedFeatures.count((f) =>
    f ? enrollmentFeatureIds.includes(f.get('id')) : false
  );
  return enabledCount === selectedFeatures.size ? 'all' : enabledCount === 0 ? 'none' : 'some';
}

/**
 * Given Immutable feature datum image bounds, return the image bounds as a
 * GeoJSON Polygon or MultiPolygon geometry.
 */
export function getImageBoundsGeometry(
  imageBounds: FeatureDatumBounds
): geojson.Polygon | geojson.MultiPolygon {
  return Array.isArray(imageBounds) ? turf.bboxPolygon(imageBounds).geometry : imageBounds;
}

/**
 * Given feature data and a layer key, return the combined image bounds for all
 * the layer’s feature data as a GeoJSON Polygon or MultiPolygon geometry.
 * Return null if there is no feature data for the given layer key.
 *
 * This funcion will also skip over invalid geometry, since it's not integral to determining feature bounds.
 * Enable warnings in your browser console to see these messages.
 *
 * This function is really only used in Analyze Area.
 */
export function getCombinedFeatureDataBounds(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string
): geojson.Polygon | geojson.MultiPolygon | null {
  const dataForLayer = featureData.filter((datum) => datum!.get('types').includes(layerKey));

  const allUniqueBounds = dataForLayer.map(
    (datum) => datum && getFeatureBoundsforSource(datum, layerKey)
  );

  const uniqueGeometries = allUniqueBounds
    .map((imageBounds) => getImageBoundsGeometry(imageBounds!))
    .toArray();

  if (!uniqueGeometries.length) {
    return null;
  }

  return compileGeometry(uniqueGeometries, (_e, index) =>
    console.warn(
      'Error compiling feature image collection geometry: ',
      dataForLayer.get(index + 1).toJS()
    )
  );
}

export function compileGeometry(
  geometries: (geojson.Polygon | geojson.MultiPolygon)[],
  onError?: (error: Error, index: number) => void
): geojson.Polygon | geojson.MultiPolygon | null {
  const firstGeometry = geometries.shift()!;

  return geometries.reduce((combinedGeometry, geometry, index) => {
    if (geoJsonUtils.polygonContainsOrEquals(combinedGeometry!, geometry!)) {
      return combinedGeometry!;
    } else {
      let nextCombinedFeature;
      try {
        nextCombinedFeature = turf.union(
          turf.featureCollection([turf.feature(combinedGeometry!), turf.feature(geometry!)])
        );
      } catch (e) {
        onError && onError(e as Error, index);
      }
      return nextCombinedFeature?.geometry || combinedGeometry!;
    }
  }, firstGeometry);
}

/**
 * Get polygon and multipolygons from a collection of geometries or features.
 */
export function getPolygonGeometryTypesFrom(
  geometries: geojson.Geometry[] | geojson.Feature[]
): (geojson.Polygon | geojson.MultiPolygon)[] {
  // Type predicate to discern whether we have features or just geometries
  const isGeojsonFeature = (entity): entity is geojson.Feature[] => entity[0].type === 'Feature';

  // Do some handling here, depending on what's passed in.
  let _geometries: geojson.Geometry[] = [];
  if (geometries.length > 0 && isGeojsonFeature(geometries)) {
    _geometries = geometries.map((f) => f.geometry);
  } else {
    _geometries = geometries as geojson.Geometry[];
  }

  // Often we only care about polygon or multipolygon geometries, like for overlays.
  // map over our geometries, casting and returning the two we care about, null for the rest, then filter nulls.
  // We cast our result because as of this comment TS seems to be getting confused about our return type.
  return _geometries
    .map((g) => {
      if (g.type === 'Polygon') {
        return g as geojson.Polygon;
      } else if (g.type === 'MultiPolygon') {
        return g as geojson.MultiPolygon;
      } else {
        return null;
      }
    })
    .filter((g) => g) as (geojson.Polygon | geojson.MultiPolygon)[];
}

export function tagStatusMapToPropertiesChanges(
  tagStatusMap: TagStatusMap
): I.MergesInto<I.ImmutableOf<ApiFeature>> {
  const properties: Partial<ApiFeatureCommonProperties> = {
    [CONSTANTS.APP_PROPERTIES_KEY]: {
      [CONSTANTS.APP_PROPERTY_TAG_IDS]: tagStatusMap,
    },
  };
  return I.fromJS({properties});
}

export function getFeatureDatumFromLayerKey(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string,
  cursor: string,
  featureCollection: HydratedFeatureCollection
): I.ImmutableOf<ApiFeatureData> | undefined {
  const filteredFeatureData = filterFeatureDataByType(featureData, layerKey);
  const cursorKey = getCursorKeyForLayerKey(featureCollection, layerKey);
  const featureDatum = filteredFeatureData.findLast((d) => d!.get(cursorKey) === cursor);
  return featureDatum;
}

export function getAnalysisLayerKeysForFeature(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  featureData: I.ImmutableListOf<ApiFeatureData>,
  paidScenes: I.ImmutableListOf<[string, string]> | undefined,
  feature: I.ImmutableOf<ApiFeature>
) {
  return layerUtils
    .getLayerMenuOptionsForFeatureCollection(featureCollection)
    .filter((key) => {
      return (
        // Use the same filtering as what we show in the layers menu, plus check that it
        // has a raw layerKey
        displayLayerInMenu(featureData, feature, paidScenes, key, featureCollection) &&
        layerUtils.getRawLayerKey(key)
      );
    })
    .sort((a, b) =>
      alphanumericSort(layerUtils.getLayer(a).display, layerUtils.getLayer(b).display)
    );
}

/**
 * Get the bounds from a feature datum for a given source.
 *
 * This matches the logic in the backend, where if there exists a geometry namespaced
 * to the source id in the `data` column of the feature image collection (which gets
 * sent to the frontend as `measurements`). Otherwise we use the `bounds` of the
 * feature datum.
 */
export function getFeatureBoundsforSource(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  activeLayerKey: string
): FeatureDatumBounds | null {
  const featureDatumJS = featureDatum.toJS() as ApiFeatureData;

  const {sourceId} = layerUtils.parseLayerKey(activeLayerKey);

  let bounds =
    featureDatumJS?.measurements?.[
      `${sourceId}_${CONSTANTS.LENS_WINDOW_GEOMETRY_SOURCE_ID_KEY_SUFFIX}`
    ];

  if (!bounds) {
    bounds = featureDatumJS?.images?.['bounds'];
  }

  return bounds;
}
