import React from 'react';

interface ReportPortalState {
  reportWindow: Window | null;
  reportEl: HTMLElement | null;
  open: (newTab: boolean) => void;
}

export const ReportPortalContext = React.createContext<ReportPortalState>({
  reportWindow: null,
  reportEl: null,
  open: () => {},
});

export const ReportPortalProvider = ({children}) => {
  const [reportWindow, setReportWindow] = React.useState<Window | null>(null);
  const [reportEl, setReportEl] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      if (reportWindow) {
        reportWindow.close();
      }
    };
  }, []);

  const open = (newTab: boolean) => {
    const newReportWindow = window.open(
      '',
      '_blank',
      newTab ? undefined : 'toolbar=no,status=no,location=no,width=890,height=780'
    );

    if (newReportWindow) {
      newReportWindow.addEventListener('unload', () => {
        setReportWindow(null);
        setReportEl(null);
      });

      const reportHtmlElement = newReportWindow.document.querySelector('html')!;

      while (reportHtmlElement.firstChild) {
        reportHtmlElement.removeChild(reportHtmlElement.firstChild);
      }

      reportHtmlElement.setAttribute('mozNoMarginBoxes', 'mozNoMarginBoxes');
      reportHtmlElement.setAttribute('mozDisallowSelectionPrint', 'mozDisallowSelectionPrint');

      setReportWindow(newReportWindow);
      setReportEl(reportHtmlElement);
    }
  };

  return (
    <ReportPortalContext.Provider value={{reportWindow, reportEl, open}}>
      {children}
    </ReportPortalContext.Provider>
  );
};
