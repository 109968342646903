import * as CONSTANTS from 'app/utils/constants';

export const DEFAULT_DECIMALS = 2;
export const DEFAULT_DECIMALS_SCALED = null;
export const MILISECONDS_PER_YEAR = 31536000000;

// Not updating these to use constants since we will be using math.js to handle
// this logic.
export const conversions = {
  [CONSTANTS.UNIT_LENGTH_M]: {
    lengthf: 3.28084,
    lengthmi: 0.00062137121212121,
  },
  [CONSTANTS.UNIT_AREA_M2]: {
    [CONSTANTS.UNIT_AREA_F2]: 10.7639,
    areaMI2: 3.861e-7,
    [CONSTANTS.UNIT_AREA_ACRE]: 0.000247105,
    [CONSTANTS.UNIT_AREA_HECTARE]: 0.0001,
  },
};

export function hasConversion(fromUnit: string, toUnit: string) {
  return !!(conversions[fromUnit] && conversions[fromUnit][toUnit]);
}

export function getConversion(fromUnit: string, toUnit: string): number {
  if (hasConversion(fromUnit, toUnit)) {
    return conversions[fromUnit][toUnit];
  }

  return 1; // conversion is multiplied, so this is identity
}

export function convert(value: number, fromUnit: string, toUnit: string) {
  return value * getConversion(fromUnit, toUnit);
}

export function numberWithCommas(s: string | number) {
  // Add thousands comma separators for each group of 3 digits.
  return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// The native parseInt doesn't take numbers, and we sometimes union strings and numbers.
export function flexibleParseInt(n: string | number, radix?: number): number {
  if (typeof n === 'string') {
    return parseInt(n, radix);
  } else {
    return n;
  }
}

// Roughly analogous to how HF gets water year.
//  mono/hf-web/src/lib/dates.ts, getWaterYearDateRange
export function getWaterYear(date: Date): number {
  return date.getMonth() >= 9 ? date.getFullYear() + 1 : date.getFullYear();
}

export function milisecondsToYears(miliseconds: number) {
  return miliseconds / MILISECONDS_PER_YEAR;
}
