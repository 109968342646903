import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';
import Gravatar from 'react-gravatar';
import {Column, useFlexLayout, useTable} from 'react-table';

import InviteUsersModal from 'app/components/InviteUsersModal/InviteUsersModal';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {OrgUsersActions, WithOrgUsers} from 'app/providers/OrgUsersProvider';
import {useSignupTokens} from 'app/providers/SignupTokenProvider';
import {alphanumericSort} from 'app/utils/featureUtils';
import * as inviteUtils from 'app/utils/inviteUtils';
import {getIsOrgOverSeatLimit} from 'app/utils/organizationUtils';

import * as GlobalSettings from './GlobalSettings';
import TeamMemberModal from './TeamMemberModal';
import cs from './TeamView.styl';

const TeamViewTable: React.FunctionComponent<
  React.PropsWithChildren<{
    organizationUsers: ApiOrganizationUser[];
    getCanEditUser: (userId: string) => boolean;
    onEditUser: (userId: string) => void;
  }>
> = ({organizationUsers, getCanEditUser, onEditUser}) => {
  const columns = React.useMemo<Column<ApiOrganizationUser>[]>(
    () => [
      {
        Header: 'Name',
        // Accessors are only used for sorting and filtering. While we show both
        // the name and email address in this column, we would likely only sort
        // and filter by the user’s name.
        accessor: (user) => user.name,
        Cell: ({cell}) => {
          const user = cell.row.original;
          const name = user.name;
          const email = user.email;

          return (
            <div className={cs.tableNameCell}>
              <Gravatar email={email} />
              <div className={cs.tableNameCellText}>
                <div>{name}</div>
                <div className={cs.tableNameCellTextEmail}>{email}</div>
              </div>
            </div>
          );
        },
        width: 3,
      },
      {
        Header: 'Role',
        accessor: (user) => inviteUtils.ROLE_NAMES[user.role],
        width: 1,
      },
      {
        id: 'Edit',
        accessor: (user) => user.id,
        Cell: ({cell}) => {
          const userId = cell.value;

          return (
            getCanEditUser(userId) && (
              <div className={cs.tableEditCell}>
                <a onClick={() => onEditUser(userId)}>Edit</a>
              </div>
            )
          );
        },
        width: 1,
      },
    ],
    [getCanEditUser, onEditUser]
  );

  const data = React.useMemo<ApiOrganizationUser[]>(
    () =>
      organizationUsers
        .filter((u) => !u!.deletedLocally)
        .sort((a, b) => alphanumericSort(a.name, b.name)),

    [organizationUsers]
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{id: 'Name'}],
      },
    },
    useFlexLayout
  );

  return (
    <div className={cs.scrollRegion}>
      <table className={cs.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps()} key={i}>
                  <div>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps()} key={i}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TeamView: React.FunctionComponent<
  React.PropsWithChildren<{
    actions: OrgUsersActions;
    organizationUsers: ApiOrganizationUser[] | null;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
  }>
> = ({organizationUsers, organization, profile, actions, loggedInUserActions}) => {
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = React.useState<boolean>(false);
  const [editingUserId, setEditingUserId] = React.useState<string | null>(null);

  const tokens = useSignupTokens();

  if (!organizationUsers) {
    return <GlobalSettings.CenteredSpinner />;
  }

  const isOrgOverSeatLimit = getIsOrgOverSeatLimit(organization, organizationUsers.length);

  return (
    <GlobalSettings.Body
      title="Team"
      titleActions={
        tokens !== 'not allowed' && (
          <B.Tooltip
            disabled={!isOrgOverSeatLimit}
            content={
              <>
                It looks like you've filled all the user seats included in your plan. Reach out to{' '}
                <a href="mailto:lens@upstream.tech">lens@upstream.tech</a> to upgrade your
                subscription.
              </>
            }
          >
            <B.Button
              intent={B.Intent.PRIMARY}
              text="Invite"
              onClick={() => setIsInviteUsersModalOpen(true)}
              disabled={tokens === 'loading' || isOrgOverSeatLimit}
            />
          </B.Tooltip>
        )
      }
    >
      <TeamViewTable
        organizationUsers={organizationUsers}
        getCanEditUser={(userId) => {
          const id = profile.get('id');
          const role = profile.get('role');
          // Owners can edit anyone. Anyone can edit themselves.
          return role === 'owner' || id === userId;
        }}
        onEditUser={(userId) => setEditingUserId(userId)}
      />

      {tokens !== 'loading' && tokens !== 'not allowed' && (
        <InviteUsersModal
          tokens={tokens}
          isOpen={isInviteUsersModalOpen}
          onClose={() => setIsInviteUsersModalOpen(false)}
          permissions={['orders', 'teamManagement', 'dashboard', 'properties']}
        />
      )}

      <TeamMemberModal
        editingUserId={editingUserId}
        profile={profile}
        organizationUsers={organizationUsers}
        actions={actions}
        onClose={() => setEditingUserId(null)}
        loggedInUserActions={loggedInUserActions}
      />
    </GlobalSettings.Body>
  );
};

const TeamViewDataProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
  }>
> = ({profile, organization, loggedInUserActions}) => (
  // Fetches the latest users and prevents stale ones from being shown.
  <WithOrgUsers refreshOnMount="clear">
    {(organizationUsers, _, actions) => (
      <TeamView
        actions={actions}
        organizationUsers={organizationUsers}
        organization={organization}
        profile={profile}
        loggedInUserActions={loggedInUserActions}
      />
    )}
  </WithOrgUsers>
);

export default TeamViewDataProvider;
