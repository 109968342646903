import mapboxgl from 'mapbox-gl';
import React from 'react';

import * as mapUtils from 'app/utils/mapUtils';

interface Props {
  map: mapboxgl.Map;
  style: string;
}

export default class MapStyle extends React.Component<Props> {
  static defaultProps: Pick<Props, 'style'> = {
    style: mapUtils.getMapboxUri(mapUtils.DEFAULT_STYLE),
  };

  componentDidMount() {
    this.maybeUpdateStyle();
  }

  componentDidUpdate(prevProps: Props) {
    const {style} = this.props;
    const {style: prevStyle} = prevProps;

    if (style !== prevStyle) {
      this.maybeUpdateStyle();
    }
  }

  maybeUpdateStyle() {
    const {map, style} = this.props;
    /*
     * If the map style hasn't loaded set it, otherwise check if the style has changed
     */
    if (!map.isStyleLoaded()) {
      this.updateStyle();
      return;
    }
    const prevStyle = map.getStyle();
    const prevStyleId = prevStyle ? prevStyle.metadata['mapbox:origin'] : null;
    if (style !== prevStyleId) {
      this.updateStyle();
    }
  }

  async updateStyle() {
    const {map, style} = this.props;
    /*
     * Set diff to false because by default setStyle removes the layers we added
     */
    map.setStyle(mapUtils.getMapboxUri(style), {diff: false});
    await mapUtils.loadIconImages(map);
  }

  render() {
    return null;
  }
}
