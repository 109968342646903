import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {
  ApiImageryContract,
  ApiImageryContractWithProjects,
  ApiImageryPurchaseBillingRecord,
} from 'app/modules/Remote/Project';
import {
  useAccessSubscription,
  useCurrentImageryContract,
  useImageryContracts,
  useImageryPurchaseBillingRecords,
} from 'app/providers/ImageryContractProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {findPrimaryFeatureCollection} from 'app/utils/featureCollectionUtils';
import {useFeatures} from 'app/utils/featureUtils';
import {useTimestampMs} from 'app/utils/hookUtils';

import cs from './BillingView.styl';
import {
  BillingInfo,
  ContractHistory,
  PlanInformation,
  UpcomingInvoices,
} from './ContractHistory/ContractHistory';
import ImageryContractTable from './ContractHistory/ImageryContractTable';
import * as GlobalSettings from './GlobalSettings';

// Small utility function for returning an Immutable Map of features, indexed by feature id.
function makeFeatureById(
  features: I.ImmutableListOf<ApiFeature>
): I.Map<number, I.ImmutableOf<ApiFeature>> {
  return features.reduce(
    (map, f) => map!.set(f!.get('id'), f!),
    I.Map<number, I.ImmutableOf<ApiFeature>>()
  );
}

const today = moment();
const hasEnded = (end: string | null) => end && moment(end).isBefore(today);

const BillingView: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    organizationUsers: ApiOrganizationUser[] | null;
    profile: I.ImmutableOf<ApiOrganizationUser>;
  }>
> = ({organization, organizationUsers, profile}) => {
  const [projects, featureCollectionsById] = useProjects();
  // Doubles as a switch for which table to show. If we have a project selected, show that table. If not, show the other.
  const [selectedProject, setSelectedProject] = React.useState<{id: string; name: string} | null>(
    null
  );
  const [projectSelectOpen, setProjectSelectOpen] = React.useState(false);

  const [getFeatures] = useFeatures();
  const [getImageryContracts, {invalidate: invalidateContracts}] = useImageryContracts();
  const [getCurrentImageryContract] = useCurrentImageryContract();
  const [getImageryPurchaseBillingRecords] = useImageryPurchaseBillingRecords();
  const [componentLoadTimestampMs] = useTimestampMs();
  const [getAccessSubscription, cancelSubscription] = useAccessSubscription();

  const organizationId = organization.get('id');

  // API response for all contracts in the organization.
  const contractsMaybe = React.useMemo(
    () => getImageryContracts(organizationId),
    [getImageryContracts, organizationId]
  );

  //API response for the organization's access subscription.
  const accessSubscriptionMaybe = React.useMemo(
    () => getAccessSubscription(organizationId),
    [getAccessSubscription, organizationId]
  );
  const currentLensTier = organization.getIn(['tiers', 'Lens']);

  // Associate project ids with names, for our dropdown
  const projectList: {id: string; name: string}[] = React.useMemo(
    () =>
      (projects
        ?.map((project) => ({id: project?.get('id'), name: project?.get('name') || ''}))
        .toArray()
        .filter(({id}) => !!id) as {id: string; name: string}[]) || [],
    [projects]
  );

  const featuresById: I.Map<number, I.ImmutableOf<ApiFeature>> | null = React.useMemo(() => {
    // Case: no project has been selected yet, so there aren't any features we can get.
    if (!selectedProject) {
      return null;
    }
    const project = projects?.find((p) => p?.get('id') === selectedProject.id);
    // Purely for type narrowing, since selectedProject is generated from projects
    if (!project) {
      return null;
    }
    const primaryFeatureCollection = findPrimaryFeatureCollection(project, featureCollectionsById);
    const maybeFeatures = getFeatures(primaryFeatureCollection!.get('id'));
    if (maybeFeatures.status === 'some') {
      return makeFeatureById(maybeFeatures.value);
    } else {
      return null;
    }
  }, [selectedProject, projects, featureCollectionsById, getFeatures]);

  // Get all imagery contracts. This is required for the downstream table, but also to
  // check if this org has an imagery contract and should let the user select projects.
  const [allImageryContracts, hasContracts, allImagerySubscriptionIds] = React.useMemo(() => {
    const allActiveContracts =
      contractsMaybe.status === 'some'
        ? contractsMaybe.value.filter((c) => {
            return !hasEnded(c.endDate);
          })
        : null;

    const hasContracts = allActiveContracts && allActiveContracts.length > 0;

    const allImagerySubscriptionIds = allActiveContracts
      // we don't want to fetch invoices for test mode contracts, because the customers
      // will not receive invoices for those
      ?.filter((c) => !c.stripeTest)
      .map((c) => c.stripeSubscriptionId);

    return [allActiveContracts, hasContracts, allImagerySubscriptionIds];
  }, [contractsMaybe.status, contractsMaybe.value]);

  // Once we have a selected project we can fetch the contract for it, and then fetch
  // imagery purchases, which we filter by project because the table expects this.
  const [currentImageryContract, imageryPurchaseBillingRecords] = React.useMemo(() => {
    if (!selectedProject) {
      return [null, null];
    }

    const currentContract = getCurrentImageryContract(selectedProject.id, {
      afterMs: componentLoadTimestampMs,
      allowStaleMs: true,
    });

    let imageryPurchases;

    // If we found a current contract, grab purchases
    if (currentContract.status === 'some' && currentContract.value != null) {
      const maybeImageryPurchases = getImageryPurchaseBillingRecords(currentContract.value!.id, {
        afterMs: componentLoadTimestampMs,
        allowStaleMs: true,
      });
      if (maybeImageryPurchases.status === 'some') {
        // If we have some purchases, filter by selected project
        imageryPurchases = maybeImageryPurchases.value
          .toJS()
          .filter((ip) => ip.projectId === selectedProject.id);
      } else {
        imageryPurchases = null;
      }
    }

    return [currentContract.value || null, imageryPurchases];
  }, [
    componentLoadTimestampMs,
    getCurrentImageryContract,
    getImageryPurchaseBillingRecords,
    selectedProject,
  ]);

  // Dont allow user to select a portfolio if:
  // 1. there are no portfolios to select,
  // 2. or we don't know if they have imagery contracts or dont have one
  const projectSelectionDisabled = projectList.length < 1 || !hasContracts;

  return (
    <GlobalSettings.Body title="Plan and Billing">
      <>
        <div className={cs.billingViewCards}>
          <PlanInformation
            cancelSubscription={cancelSubscription}
            accessSubscriptionMaybe={accessSubscriptionMaybe}
            organization={organization}
            profile={profile}
            hasEnded={hasEnded}
            currentLensTier={currentLensTier}
            contractsMaybe={contractsMaybe}
            organizationUsers={organizationUsers}
            projects={projects}
          />
          <BillingInfo organizationId={organizationId} role={profile.get('role')} />
          <UpcomingInvoices
            isLoadingContracts={contractsMaybe.status === 'unknown'}
            subscriptionIds={allImagerySubscriptionIds}
          />
        </div>
        <h2>Contracts and Order History</h2>

        <div className={cs.tableSelection}>
          <B.AnchorButton
            minimal
            className={cs.tableButtons}
            intent="primary"
            active={!selectedProject}
            onClick={() => setSelectedProject(null)}
          >
            Contracts Overview
          </B.AnchorButton>
          <B.Popover
            minimal
            isOpen={projectSelectOpen}
            onClose={() => setProjectSelectOpen(false)}
            disabled={projectList.length < 1}
            usePortal={false}
            content={
              <B.Menu>
                {projectList.map((projectSelection) => (
                  <B.MenuItem
                    key={projectSelection.id}
                    text={projectSelection.name}
                    icon={selectedProject?.id === projectSelection.id ? 'small-tick' : 'blank'}
                    onClick={() => setSelectedProject(projectSelection)}
                  />
                ))}
              </B.Menu>
            }
          >
            <B.AnchorButton
              minimal
              className={cs.tableButtons}
              intent="primary"
              active={!!selectedProject}
              onClick={() => setProjectSelectOpen(true)}
              disabled={projectSelectionDisabled}
            >
              Order History by Portfolio{selectedProject ? `: ${selectedProject.name} ` : ' '}
              <span className={cs.caretSpace}>
                <B.Icon icon={'caret-down'} />
              </span>
            </B.AnchorButton>
          </B.Popover>
        </div>
        {selectedProject ? (
          <ContractTable
            currentImageryContract={currentImageryContract}
            allImageryContracts={allImageryContracts}
            billingRecords={imageryPurchaseBillingRecords}
            featuresById={featuresById}
          />
        ) : (
          <ContractHistory
            contracts={contractsMaybe}
            reloadContracts={() => invalidateContracts([organizationId, ''], true)}
            organizationId={organization.get('id')}
          />
        )}
      </>
    </GlobalSettings.Body>
  );
};

// Thin wrapper around the Imagery Contract Table, requiring mostly identical props, and returning
// a spinner if any of its props are not yet available, e.g. between portfolio selection and load.
const ContractTable: React.FunctionComponent<
  React.PropsWithChildren<{
    currentImageryContract: ApiImageryContract | null;
    allImageryContracts: ApiImageryContractWithProjects[] | null;
    billingRecords: ApiImageryPurchaseBillingRecord[] | null;
    featuresById: I.Map<number, I.ImmutableOf<ApiFeature>> | null;
  }>
> = ({currentImageryContract, allImageryContracts, billingRecords, featuresById}) => {
  if (!currentImageryContract || !allImageryContracts || !billingRecords || !featuresById) {
    return <GlobalSettings.CenteredSpinner />;
  }

  return (
    <ImageryContractTable
      imageryContracts={allImageryContracts}
      currentImageryContract={currentImageryContract}
      billingRecordsForProject={billingRecords}
      featuresById={featuresById}
    />
  );
};

export default BillingView;
