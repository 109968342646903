import {History} from 'history';
import * as I from 'immutable';
import React from 'react';
import {Redirect} from 'react-router';

import {usePushNotification} from 'app/components/Notification';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {useGeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {findPrimaryFeatureCollection} from 'app/utils/featureCollectionUtils';
import {hydratedFeatureCollection} from 'app/utils/featureCollectionUtils';
import {findProjectInProjects} from 'app/utils/projectUtils';
import * as routeUtils from 'app/utils/routeUtils';
import {makeProjectDashboardUrl} from 'app/utils/routeUtils';

import {PropertyUpdateView} from './PropertyUpdateView';

const PropertyUpdateProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
    projectId: string;
  }>
> = ({history, organization, profile, loggedInUserActions, projectId}) => {
  const [projects, featureCollections, projectsActions, {loading}] = useProjects();
  const pushNotification = usePushNotification();
  // useGeoJsonFeatures doesn't distinguish a loading state and will just return an empty list
  // until data is returned
  const getFeatures = useGeoJsonFeaturesLoader();

  const project = React.useMemo(() => {
    if (!projectId || !projects) {
      return null;
    }
    return findProjectInProjects(projects, projectId);
  }, [projectId, projects]);

  const existingFeatureCollection: HydratedFeatureCollection | null = React.useMemo(() => {
    if (!project) {
      return null;
    }
    const unhydratedFeatureCollection = findPrimaryFeatureCollection(project, featureCollections);

    return unhydratedFeatureCollection
      ? // if a featureCollection exists, return it as a HydratedFeatureCollection
        hydratedFeatureCollection(
          unhydratedFeatureCollection,
          getFeatures(unhydratedFeatureCollection)
        )
      : null;
  }, [project, featureCollections, getFeatures]);

  const [matchedFeaturesMap, setMatchedFeaturesMap] = React.useState<Record<
    number,
    ApiFeature
  > | null>(null);
  const [unmatchedFeatures, setUnmatchedFeatures] = React.useState<ApiFeature[] | null>(null);
  const [gcsFilePath, setGcsFilePath] = React.useState<string | null>(null);

  const onSuccess = async () => {
    if (!existingFeatureCollection || !project) {
      throw new Error('must have existingFeatureCollection and project');
    }
    // Clear cached featureCollection and refetch so correct processing
    // status is reflected immediately
    await projectsActions.refreshFeatureCollection(existingFeatureCollection.get('id'));

    const onSuccessUrl = makeProjectDashboardUrl(organization, project);
    history.push(onSuccessUrl);
  };

  if (!loading && !project) {
    pushNotification({
      message:
        'There was a problem loading that Portfolio. Ensure you are signed in to the correct organization.',
      autoHideDuration: 4000,
    });

    // The only case I can think of for this is if they somehow clicked on an updater link for an org they don't have access to
    const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));
    return <Redirect to={`/${orgIdPrefix}/projects`} />;
  }

  return (
    <PropertyUpdateView
      history={history}
      organization={organization}
      profile={profile}
      loading={loading}
      loggedInUserActions={loggedInUserActions}
      portfolioName={project?.get('name') || ''}
      existingFeatureCollection={existingFeatureCollection}
      matchedFeaturesMap={matchedFeaturesMap}
      setMatchedFeaturesMap={setMatchedFeaturesMap}
      unmatchedFeatures={unmatchedFeatures}
      setUnmatchedFeatures={setUnmatchedFeatures}
      setGcsFilePath={setGcsFilePath}
      gcsFilePath={gcsFilePath}
      onSuccess={onSuccess}
    />
  );
};

export default PropertyUpdateProvider;
