import geojson from 'geojson';
import {sortBy} from 'lodash';
import React from 'react';

import {ApiFeatureCollection, GeometryOverlaySetting} from 'app/modules/Remote/FeatureCollection';

export const OverlayLegend: React.FunctionComponent<
  React.PropsWithChildren<{
    featureCollections: (Pick<ApiFeatureCollection, 'name' | 'id' | 'tiles'> &
      geojson.FeatureCollection)[];
    isOverlayVisibleByName: Record<string, boolean>;
    overlaySettings: GeometryOverlaySetting[];
  }>
> = ({featureCollections, isOverlayVisibleByName, overlaySettings}) => {
  // We need to get the name for each legend item. If only one feature is
  // visible, we use the feature’s name. Otherwise we use the name of the
  // feature collection.
  //
  // This is a map from FC name -> name that appears in the legend.
  const legendNameByName = React.useMemo(
    () =>
      featureCollections.reduce(
        (acc, fc) =>
          !isOverlayVisibleByName[fc.name]
            ? acc
            : // The || acc[fc.name] catches the case where two different feature

              // collections have the same name, so we need to use the FC name
              // rather than either individual feature name (since they’ll have
              // the same color).
              fc.features.length > 1 || acc[fc.name] || fc.tiles
              ? {...acc, [fc.name]: fc.name}
              : fc.features.length === 1
                ? {...acc, [fc.name]: fc.features[0].properties?.name || fc.name}
                : acc,
        {} as Record<string, string>
      ),
    [featureCollections, isOverlayVisibleByName]
  );

  // Pairs of name and color for everything going in the legend, sorted
  // alphabetically.
  const legendInfo = React.useMemo(
    () =>
      sortBy(
        Array.from(Object.entries(legendNameByName)).map(([fcName, legendName]) => [
          legendName,
          overlaySettings.find((s) => s.name === fcName)?.color || '#000',
        ]),
        ([legendName]) => legendName
      ),
    [legendNameByName, overlaySettings]
  );

  return (
    <div className="note-overlay-legend">
      {legendInfo.map(([name, color], idx) => (
        // We use idx for the key because don’t guarantee name or color uniqueness
        <div key={idx} className="note-overlay-legend-item">
          <div className="note-overlay-legend-swatch" style={{backgroundColor: color}} /> {name}
        </div>
      ))}
    </div>
  );
};
