import * as geojson from 'geojson';
import * as I from 'immutable';

import {NoteGraph} from 'app/components/AnalyzePolygonChart/types';
import {
  ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY,
  ALERT_VEGETATION_DROP_ENROLLMENT_KEY,
  APP_PROPERTIES_KEY,
  APP_PROPERTY_ASSIGNEE_EMAIL,
  APP_PROPERTY_REPORTING_DUE_DATE,
  APP_PROPERTY_STREAMIMAGERY_API_KEY,
  APP_PROPERTY_WMTS_URL,
  CUSTOM_ALERT_TYPE_CATEGORY,
  CUSTOM_ALERT_TYPE_THRESHOLD,
  FEATURE_PROCESSING_COMPLETE_STATUS,
  FEATURE_WAITING_FOR_PROCESSING_STATUS,
  RDM_PROPERTIES_COMPLIANCE_KEY,
  RDM_PROPERTIES_KEY,
  THRESHOLD_DIRECTION_ABOVE,
  THRESHOLD_DIRECTION_BELOW,
} from 'app/utils/constants';
import {BBox2d} from 'app/utils/geoJsonUtils';
import {MapImageRef} from 'app/utils/mapUtils';
import {TagStatusMap} from 'app/utils/tagUtils';

import {ApiOrganizationUser} from '../Organization';

export interface ApiFeature extends geojson.Feature<geojson.Geometry, ApiFeatureCommonProperties> {
  id: number;
}

/**
 * Properties is allowed to have more keys / values, but there’s not a good
 * way to type those with Immutable and also preserve the known types of the
 * common properties. Cast this to any as necessary.
 */
export interface ApiFeatureCommonProperties {
  id: number;
  featureCollectionId: number;

  lensId?: string;

  name: string;
  /** An optional name for a feature in a multi-feature Lens property. By default, we order the
   * features by their ID and assign a unique letter in alphabetical order. */
  multiFeaturePartName?: string;

  /** an optional property to reference an external url resource related to the
   * feature. For now, this allows us to link user-point vectors in Lens with
   * corresponding timeseries in Grafana. */
  url?: string;

  isArchived: boolean;

  validatedGeometry?: geojson.Geometry;
  originalPolygon?: geojson.Polygon;

  /** ISO8601 date */
  createdAt: string;
  /** ISO8601 date */
  updatedAt: string;

  status: FeatureStatus;

  [APP_PROPERTIES_KEY]?: {
    [APP_PROPERTY_ASSIGNEE_EMAIL]?: string;
    /** Date in the YYYY-MM-DD format */
    [APP_PROPERTY_REPORTING_DUE_DATE]?: string;
    /** First element is the source ID, second is the cursor (+00:00 timezone) */
    hiddenScenes?: [string, string][];
    /**
     * Ids of labels for this property. See TagSetting in the LENS
     * FeatureCollection view. Stored as a map so we can use merging on the
     * server to add / remove a tag from a bunch of features in bulk w/o messing
     * with other tags.
     */
    tagIds?: Record<string, boolean>;
    [ALERT_VEGETATION_DROP_ENROLLMENT_KEY]?: boolean;
    [ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY]?: boolean;

    [APP_PROPERTY_WMTS_URL]?: string;
    [APP_PROPERTY_STREAMIMAGERY_API_KEY]?: string;
  };

  [RDM_PROPERTIES_KEY]?: {
    [RDM_PROPERTIES_COMPLIANCE_KEY]?: [{year: number; compliance: boolean}];
  };

  __system?: {
    paidImagery?: SystemPaidImagery;
  };
}

export type FeatureStatus =
  | typeof FEATURE_PROCESSING_COMPLETE_STATUS
  | typeof FEATURE_WAITING_FOR_PROCESSING_STATUS
  | null;

export type AlertEnrollmentKeys =
  | typeof ALERT_VEGETATION_DROP_ENROLLMENT_KEY
  | typeof ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY;

export type AlertTypeProperties = ThresholdAlertProperties | CategoryAlertProperties;

export interface ThresholdAlertProperties {
  value: number;
  direction: AlertThresholdDirection;
  percentOfProperty: number;
  numConsecutiveScenes: number;
}

export interface CategoryAlertProperties {
  fromCategories: string[];
  toCategories: string[];
}
export type APIAlertPolicyProperties =
  | APIThresholdAlertPropertyConfiguration
  | APICategoryAlertPropertyConfiguration;

export interface APIThresholdAlertPropertyConfiguration {
  direction: AlertThresholdDirection;
  value: number;
  percent_of_property: number;
  num_consecutive_scenes: number;
}

export interface APICategoryAlertPropertyConfiguration {
  from_categories: string[];
  to_categories: string[];
}

export enum AlertThresholdDirection {
  Above = THRESHOLD_DIRECTION_ABOVE,
  Below = THRESHOLD_DIRECTION_BELOW,
}

export type AlertPolicy = {
  id: number;
  featureCollectionId: string | number;
  sourceId: string;
  layerId: string;
  name: string;
  description: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  isArchived: boolean;
  enrollments: ApiAlertEnrollment[];
} & (
  | {alertType: typeof CUSTOM_ALERT_TYPE_THRESHOLD; properties: ThresholdAlertProperties}
  | {alertType: typeof CUSTOM_ALERT_TYPE_CATEGORY; properties: CategoryAlertProperties}
);

export type ApiAlertPolicy = Omit<AlertPolicy, 'enrollments'>;

export interface ApiAlertEnrollment {
  id: number;
  alertPolicyId: number;
  featureId: number;
  geometry: geojson.Geometry;
  status: 'inactive' | 'active';
}

export interface SystemPaidImagery {
  limit: number;
  /**
   * First element is the source ID, second is the sensing time (Z timezone,
   * which is _not_ the same as cursors, which use +00:00 timezone)
   */
  scenes: [string, string][];
}

export type ApiFeatureCustomPropertyValue = string | number | boolean | undefined;
export type ApiFeatureCustomProperties = Record<string, ApiFeatureCustomPropertyValue>;

export interface RasterSourceDefinition {
  bounds: [number, number, number, number];
  format: 'png';
  maxzoom: number;
  minzoom: number;
  tilejson: string;
  tiles: string[];
}

const OPTIONAL_FEATURE_MEASUREMENTS = [
  'copyright',
  'sensor_name',
  'vendor_name',
  'cloud_cover_percentage',
];
export type OptionalFeatureMeasurement = (typeof OPTIONAL_FEATURE_MEASUREMENTS)[number];
export function isOptionalFeatureMeasurement(value: string): boolean {
  return OPTIONAL_FEATURE_MEASUREMENTS.includes(value);
}

export type FeatureDatumBounds = BBox2d | geojson.Polygon | geojson.MultiPolygon;
export interface ApiFeatureData {
  images: {
    bounds: FeatureDatumBounds;
    /** ISO8601 date */
    date: string;
    featureId: number;
    templateUrls: Record<string, RasterSourceDefinition>;
    urls: Record<string, string>;
    cogUrls?: Record<
      string,
      Record<
        string,
        {
          url: string;
          urls: string;
        }
      >
    >;
  };

  isFullyOrdered: boolean;

  /** ISO8601 */
  date: string;

  measurements: {
    [key: string]: null | number | string | FeatureDatumBounds | any;
    //the following values are stored in the OPTIONAL_FEATURE_MEASUREMENTS type above
    copyright?: string;
    sensor_name?: string;
    vendor_name?: string;
    cloud_cover_percentage?: number;
  };

  types: string[];
  sources: string[];
}

export interface ApiFeaturePropertyHistoryItem {
  id: number;
  featureId: number;
  featureCollectionId: number;

  /** ISO8601 date */
  createdAt: string;
  userId: string;

  properties: ApiFeatureCustomProperties;
  changes: ApiFeatureCustomProperties;
}
export interface ApiNote {
  // Notes have numeric IDs in the database, but "pending" notes have string IDs
  id: number | string;
  // User notes have a sequential number (separate from the id) that's displayed
  displayNumber: number | null;

  projectId: string;
  featureCollectionId: number;
  featureId: number;
  organizationId: string;
  /** Email address of the author */
  userId: string;
  userName?: string;

  /** ISO8601 string */
  createdAt: string;
  /** ISO8601 string */
  updatedAt: string;

  geometry: null | geojson.Geometry;
  layerKey: null | string;

  isArchived: boolean;

  text: string;
  attachments: null | string[];

  cursorKey: null | string;
  cursorType: null | 'datetime' | 'compareDatetime';

  noteType: 'user' | 'alert' | 'system-new-imagery-available' | 'system-new-imagery-processed';

  graph: NoteGraph | null;

  tagIds: TagStatusMap | null;

  properties?: {
    alert_policy_id?: number;
    alert_policy_enrollment_id?: number;
  };
}

export interface ApiReport<T extends ReportState | string> {
  id: number;
  featureId: ApiFeature['id'];
  userId: ApiOrganizationUser['id'];
  title: string;
  createdAt: string;
  // firebase user id
  createdBy: string;
  createdByUser: ReportUser;
  updatedAt: string;
  // firebase user id
  updatedBy: string;
  updatedByUser: ReportUser;
  isArchived: boolean;
  isMultilocation: boolean;
  data: T;
}

export interface ReportUser {
  id: string;
  email: string;
  name: string;
}

export interface ReportState {
  coverPage: ReportCoverPage;
  overviewPages: ReportOverviewPage[];
  notePages: ReportNotePage[];
}

export interface ReportCoverPage {
  title: string;
  subtitle: string;
  company: string;
  date: string;
  description: string;
  overflowPages?: string[];
}

export interface ReportOverviewPage {
  hidden: boolean;
  title: string;
  id: string;
  currentPropertyPartIndex: number;
  showNotePoints: boolean;
  showMapScale?: boolean;
  //we used to track cursor on the overview page from legacy report days.
  //now we track imageRefs. any new report overview page will have an imageRef,
  //but we can't guarantee the presence of this field in old saved reports.
  cursor?: string;
  imageRef?: MapImageRef;
}

export interface ReportNotePage {
  hidden: boolean;
  title: string;
  chartHidden: boolean;
  attachmentsHidden: boolean;
  id: string | number;
  legendsHidden: boolean;
  currentPropertyPartIndex: number;
  imageAlignment?: 'horizontal' | 'vertical';
  overlayVisibilityByName?: Record<string, boolean>;
  mapBounds?: geojson.BBox;
  fullPageImageLayout?: boolean;
  showMapScale?: boolean;
}

export interface ApiLensImageryRecord {
  date: string;
  feature_id: number;
  source_id: string;
}

export interface ApiImageryOrder {
  sourceId: string;
  sensingTime: string;
  centsPerAcre: number;
  geometry?: geojson.Polygon | geojson.MultiPolygon;
  billableAcres: number;
}

export interface ApiOrderableScene {
  thumbnailBbox: BBox2d | geojson.Polygon | geojson.MultiPolygon;
  canBeOrdered: boolean;
  cloudCoverPercentage: number;
  isOrdered: boolean;
  isFullyOrdered: boolean;
  orderedGeometry: null | geojson.Geometry;
  orderProcessed: boolean;
  sceneGeometry: geojson.Geometry;
  sensingTime: string;
  sourceId: string;
  urls: Record<string, string>;
}

export type State = I.MapAsRecord<{
  dataByFeatureId: I.Map<number, I.ImmutableOf<ApiFeatureData[]>>;
}>;
