import {History} from 'history';

import {isProd} from 'app/utils/envUtils';

export const ROOT_URL = isProd ? 'https://app.upstream.tech' : 'http://localhost:9999';

export function matchInHash(history: Pick<History, 'location'>, regexp: RegExp) {
  const {hash} = history.location;

  const hashBits = hash ? hash.split('|').map(decodeURIComponent) : [];

  return hashBits.reduce((acc: null | RegExpMatchArray, bit) => bit.match(regexp) || acc, null);
}

export function updateHash(
  history: Pick<History, 'location' | 'replace'>,
  regexp: RegExp,
  newHashBit: string | null
) {
  const {hash} = history.location;

  let hashBits = hash ? hash.split('|').map(decodeURIComponent) : [];
  hashBits = hashBits.filter((b) => !b.match(regexp));

  if (newHashBit) {
    hashBits.push(newHashBit);
  }

  return history.replace({
    ...history.location,
    hash: hashBits.map(encodeURIComponent).join('|'),
  });
}
