import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import moment from 'moment-timezone';
import React from 'react';

import * as cs from './styles.styl';

const TOAST_KEY = 'DateChangeToast';

/**
 * Puts a notification on the provided B.OverlayToaster when the activeCursors changes.
 * (Note: has no effect if there is more than one active cursor, which means
 * we’re in compare mode.)
 *
 * Does not notify if we’re changing features at the same time.
 *
 * We take an `ToasterInstance` prop rather than render a `<B.Toast>` element because
 * the CSS animations don’t work unless `<B.Toast>` is a direct child of
 * `<B.OverlayToaster>`, without any wrapper components in between.
 */
const DateChangeToast: React.FunctionComponent<
  React.PropsWithChildren<{
    featureId: number;
    activeCursors: I.List<string>;
    toaster: B.ToasterInstance;
  }>
> = ({featureId, activeCursors, toaster}) => {
  const lastFeatureIdRef = React.useRef<number | null>(null);
  const lastDateRef = React.useRef<string | null>(null);

  const currentDate = !activeCursors || activeCursors.size !== 1 ? null : activeCursors.first();

  React.useEffect(() => {
    const lastDate = lastDateRef.current;
    lastDateRef.current = currentDate;

    const lastFeatureId = lastFeatureIdRef.current;
    lastFeatureIdRef.current = featureId;

    // We use the presence of message to determine if we want a toast to appear
    // or if we should close the existing one. We need to make sure to either
    // call toaster.dismiss or run the setTimeout every time through this
    // function to avoid toasts sticking around (because we cancel the
    // setTimeout as the cleanup handler).
    let message: string | null = null;

    // We don’t notify if you’re changing features along with the date change.
    if (lastDate && currentDate && lastFeatureId === featureId) {
      const lastDateMoment = moment(lastDate);
      const delta = moment(currentDate).diff(lastDateMoment);

      if (delta !== 0) {
        message = `${moment.duration(delta).humanize()} ${delta < 0 ? 'earlier' : 'later'}`;
        // Uppercase the first letter (e.g. "a day" -> "A day")
        message = message.charAt(0).toUpperCase() + message.slice(1);
      }
    }

    if (!message) {
      toaster.dismiss(TOAST_KEY);
    } else {
      toaster.show(
        {
          message,
          icon: 'calendar',
          className: cs.dateChangeToast,
          timeout: 0,
        },
        TOAST_KEY
      );

      // We do our own timeout rather than use the Toast’s timeout value because
      // we want to keep the toast open 2 seconds from its last update, not 2
      // seconds from when it first appeared.
      const dismissTimeout = setTimeout(() => {
        toaster.dismiss(TOAST_KEY);
      }, 2000);

      return () => {
        clearTimeout(dismissTimeout);
      };
    }
  }, [toaster, featureId, currentDate]);

  return null;
};

export default DateChangeToast;
