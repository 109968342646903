import {centroid} from '@turf/turf';
import * as geojson from 'geojson';
import mapboxgl, {Expression} from 'mapbox-gl';
import React, {useEffect, useMemo} from 'react';

import {NotesActions, NotesState, StateApiNote} from 'app/stores/NotesStore';
import colors from 'app/styles/colors.json';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as makeLayers from 'app/utils/mapUtils/makeLayers';
import * as noteUtils from 'app/utils/noteUtils';

import MapContent from './MapContent';

const NOTES_MARKERS_ID = 'notes';
const NOTES_POLYGONS_ID = 'notes_polygons';

const PENDING_GEOMETRY_SOURCE_ID = 'pending_note_geometry';
const PENDING_GEOMETRY_MARKER_LAYER_ID = 'pending_note_geometry_markers';
const PENDING_GEOMETRY_POLYGON_LAYER_ID = 'pending_note_geometry_polygons';

const MAP_POINTER = 'map_pointer';

export interface Props {
  map: mapboxgl.Map;
  notesState: NotesState;
  notesActions: NotesActions;
  isMapLoaded: boolean;
  isVisible: boolean;
  onClickNoteMarkerCallback?: (
    geometry: geojson.GeoJSON | null,
    note: StateApiNote | undefined
  ) => void;
  filterNotesCallback?: (note: StateApiNote) => boolean;
}

const IS_HOVERED_EXPRESSION = ['==', ['get', 'isHovered'], true];
const IS_FOCUSED_EXPRESSION = ['==', ['get', 'isFocused'], true];
const IS_NOT_FOCUSED_EXPRESSION = ['==', ['get', 'isFocused'], false];
const IS_POLYGON_POINT_EXPRESSION = ['==', ['get', 'isPolygon'], true];
const IS_PENDING_EXPRESSION = ['==', ['get', 'isPending'], true];
const IS_NOTE_TYPE_USER_EXPRESSION = ['==', ['get', 'noteType'], 'user'];
const IS_NOTE_TYPE_ALERT_EXPRESSION = ['==', ['get', 'noteType'], 'alert'];

/**
 * Clicking on a feature from the notes marker layer focuses it.
 */
function handleClickNoteMarker(
  notesActions: NotesActions,
  notes: NotesState['notes'],
  onClickNoteMarkerCallback: (
    geometry: geojson.GeoJSON | null,
    note: StateApiNote | undefined
  ) => void,
  ev: mapboxgl.MapLayerMouseEvent
) {
  const feature = ev.features?.[0];
  const properties = feature?.properties;

  if (feature && properties) {
    notesActions.focusNote(properties.id);
    onClickNoteMarkerCallback(
      feature.geometry,
      notes.find(({id}) => id === properties.id)
    );
  }
}

/**
 * Clicking on a feature from the pending geometry layer re-opens editing of it.
 */
function handleClickPendingGeometry(notesActions: NotesActions) {
  notesActions.startEditingPendingGeometry();
}

// We use mousemove instead of mouseenter because the latter doesn’t trigger if
// you move from one feature to another continuously (e.g. the markers are
// clumped together). notesStore is tolerant of not updating its state if we
// call hoverNote redundantly.
function handleMouseMoveNoteMarker(notesActions: NotesActions, ev: mapboxgl.MapLayerMouseEvent) {
  if (ev.originalEvent.buttons === 1) return; // disable hover on drag
  const properties = ev.features?.[0].properties;
  if (properties) {
    notesActions.hoverNote(properties.id);
  }
}

/**
 * Declarative component to add the geometry from a list of notes to the map.
 */
const NoteVector: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  isVisible,
  notesState,
  notesActions,
  onClickNoteMarkerCallback = () => {},
  filterNotesCallback,
}) => {
  const {addPendingNoteGeometryMode, notes, focusedNoteId} = notesState;

  const filteredNotes = filterNotesCallback ? notes.filter(filterNotesCallback) : notes;

  // track whether at least one note is focused so that we can style the unfocused note markers
  const anyNoteIsFocused = !!focusedNoteId;

  const makeNotesLayers = React.useCallback((anyNoteIsFocused: boolean) => {
    return [
      makeLineLayer(NOTES_POLYGONS_ID),
      // We use the same source for pending notes, since we’re not doing
      // the shenanigans around hiding polygons when they’re not being
      // hovered or moving the marker around.
      makeLineLayer(PENDING_GEOMETRY_SOURCE_ID, {
        layerId: PENDING_GEOMETRY_POLYGON_LAYER_ID,
      }),
      makeMarkerSymbolLayer(NOTES_MARKERS_ID, undefined, anyNoteIsFocused),
      makeMarkerSymbolLayer(
        PENDING_GEOMETRY_SOURCE_ID,
        {
          layerId: PENDING_GEOMETRY_MARKER_LAYER_ID,
        },
        anyNoteIsFocused
      ),
    ];
  }, []);

  useEffect(() => {
    if (addPendingNoteGeometryMode) {
      return;
    }

    // Need to save these out so we can reference them in "off"
    const onClickNoteMarker = handleClickNoteMarker.bind(
      null,
      notesActions,
      notes,
      onClickNoteMarkerCallback
    );
    const onClickPendingGeometry = handleClickPendingGeometry.bind(null, notesActions);
    const onMouseMoveNoteMarker = handleMouseMoveNoteMarker.bind(null, notesActions);
    const onMouseLeaveNoteMarker = () => notesActions.hoverNote(null);

    map.on('click', NOTES_MARKERS_ID, onClickNoteMarker);
    map.on('click', PENDING_GEOMETRY_POLYGON_LAYER_ID, onClickPendingGeometry);
    map.on('click', PENDING_GEOMETRY_MARKER_LAYER_ID, onClickPendingGeometry);
    map.on('mousemove', NOTES_MARKERS_ID, onMouseMoveNoteMarker);
    map.on('mouseleave', NOTES_MARKERS_ID, onMouseLeaveNoteMarker);

    return () => {
      map.off('click', NOTES_MARKERS_ID, onClickNoteMarker);
      map.off('click', PENDING_GEOMETRY_POLYGON_LAYER_ID, onClickPendingGeometry);
      map.off('click', PENDING_GEOMETRY_MARKER_LAYER_ID, onClickPendingGeometry);
      map.off('mousemove', NOTES_MARKERS_ID, onMouseMoveNoteMarker);
      map.off('mouseleave', NOTES_MARKERS_ID, onMouseLeaveNoteMarker);
    };
  }, [map, addPendingNoteGeometryMode, notes, notesActions, onClickNoteMarkerCallback]);

  const sources = useMemo(
    () => [
      {
        id: NOTES_MARKERS_ID,
        source: makeNotesPointSource({...notesState, notes: filteredNotes}),
      },
      {
        id: NOTES_POLYGONS_ID,
        source: makeNotesPolygonSource({...notesState, notes: filteredNotes}),
      },
      {id: PENDING_GEOMETRY_SOURCE_ID, source: makePendingNoteSource(notesState)},
    ],
    [notesState, filteredNotes]
  );

  return (
    <MapContent
      map={map}
      isMapLoaded={isMapLoaded}
      sources={sources}
      layers={isVisible ? makeNotesLayers(anyNoteIsFocused) : []}
    />
  );
};

export default NoteVector;

/**
 * Given the currently focused and hovered notes, generates a function that
 * updates the "isFocused" and "isHovered" properties of a list of features.
 */
const addFocusHoverProperties =
  ({focusedNoteId, hoveredNoteId}: Pick<NotesState, 'focusedNoteId' | 'hoveredNoteId'>) =>
  (features: geojson.Feature<geojson.Geometry, noteUtils.NoteFeatureProperties>[]) =>
    features.map((f) => ({
      ...f,
      properties: {
        ...f.properties,
        isFocused: f.properties.id === focusedNoteId,
        isHovered: f.properties.id === hoveredNoteId,
      },
    }));

const findNorthernmostPoint = (polygon: geojson.Polygon | geojson.MultiPolygon) => {
  // We sort the polygon points to find the northernmost one to use as our
  // anchor for the label. This means that if we put the label above this point,
  // it won’t intersect with any of the polygon. (Since we do fix the map’s
  // orientation so north === up.)

  let points: geojson.Position[] = [];

  if (polygon.type === 'MultiPolygon') {
    // if shape is a MultiPolygon get the northernmost point of each of the polygons
    // within it
    points = polygon.coordinates.map((shape) => {
      const shapePoints = [...shape[0]];
      shapePoints.sort((a, b) => b[1] - a[1]);

      return shapePoints[0];
    });
  } else {
    points = [...polygon.coordinates[0]];
  }

  points.sort((a, b) => b[1] - a[1]);
  return geoJsonUtils.point(points[0] as [number, number], {}).geometry;
};

/**
 * Function that turns all polygon features into their particular points. If the
 * feature is focused, puts the point on the first vertex.
 */
const replacePolygonFeatures = (
  features: geojson.Feature<geojson.Geometry, noteUtils.NoteFeatureProperties>[]
) =>
  features.map((f) =>
    f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon'
      ? {
          ...f,
          properties: {
            ...f.properties,
            isPolygon: true,
          },
          geometry: f.properties.isFocused
            ? findNorthernmostPoint(f.geometry)
            : centroid(f.geometry).geometry,
        }
      : f
  );

const featureCollectionToSource = (fc: geojson.FeatureCollection): mapboxgl.AnySourceData => ({
  type: 'geojson',
  data: fc,
});

/**
 * Makes a MapboxGL source with GeoJSON data from the notes’ geometries.
 * Converts all polygonal note geometries to a point.
 */
const makeNotesPointSource = ({
  notes,
  focusedNoteId,
  hoveredNoteId,
}: NotesState): mapboxgl.AnySourceData =>
  featureCollectionToSource(
    geoJsonUtils.featureCollection(
      replacePolygonFeatures(
        addFocusHoverProperties({
          focusedNoteId,
          hoveredNoteId,
        })(noteUtils.getNoteFeatures(notes))
      )
    )
  );

/**
 * Makes a MapboxGL source with any note polygons.
 */
const makeNotesPolygonSource = ({
  notes,
  focusedNoteId,
  hoveredNoteId,
}: NotesState): mapboxgl.AnySourceData =>
  featureCollectionToSource(
    geoJsonUtils.featureCollection(
      addFocusHoverProperties({focusedNoteId, hoveredNoteId})(
        ((features) =>
          features.filter(
            (f) => f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon'
          ))(noteUtils.getNoteFeatures(notes))
      )
    )
  );

/**
 * Creates a MapboxGL source for any pending note geometry. We only render
 * polygons if we’re not editing them, since the mapbox-gl-draw control renders
 * them if they are being edited.
 */
const makePendingNoteSource = ({
  pendingNoteGeometryFeature,
  addPendingNoteGeometryMode,
}: NotesState): mapboxgl.AnySourceData => {
  const features: geojson.Feature<geojson.Geometry, any>[] = [];

  if (
    pendingNoteGeometryFeature &&
    addPendingNoteGeometryMode !== 'polygon' &&
    addPendingNoteGeometryMode !== 'rect'
  ) {
    features.push({
      ...pendingNoteGeometryFeature,
      properties: {
        ...pendingNoteGeometryFeature.properties,
        isPending: true,
        isPolygon:
          pendingNoteGeometryFeature.geometry.type === 'Polygon' ||
          pendingNoteGeometryFeature.geometry.type === 'MultiPolygon',
      },
    });
  }

  return featureCollectionToSource(geoJsonUtils.featureCollection(features));
};

function makeMarkerSymbolLayer(
  sourceId: string,
  opts: {layerId?: string} = {},
  anyNoteIsFocused: boolean
): mapboxgl.SymbolLayer {
  return {
    id: opts.layerId || sourceId,
    maxzoom: 22,
    minzoom: 2,
    type: 'symbol',
    source: sourceId,
    filter: ['!', ['all', IS_PENDING_EXPRESSION, IS_POLYGON_POINT_EXPRESSION]],
    layout: {
      'icon-size': 0.5,
      'icon-image': MAP_POINTER,
      'icon-anchor': ['case', IS_POLYGON_POINT_EXPRESSION, 'center', 'bottom'],
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-offset': [
        'case',
        // We don’t want the icon to overlap the polygon, so we move it up a
        // bit. The icon is positioned at the top-most vertex of the polygon.
        ['all', IS_POLYGON_POINT_EXPRESSION, IS_FOCUSED_EXPRESSION],
        ['literal', [0, -40]],
        ['literal', [0, 0]],
      ],
    },
    paint: {
      'icon-opacity': [
        'case',
        // if a map pointer is focused, it should never be transparent
        IS_FOCUSED_EXPRESSION,
        1,
        // else when we hover over a map pointer, show it semi-transparent
        IS_HOVERED_EXPRESSION,
        0.7,
        // if any note is focused, but not this note, show the pointer VERY
        // transparent (will still get the hover semi-transparency)
        ['all', IS_NOT_FOCUSED_EXPRESSION, anyNoteIsFocused],
        0.25,
        // default: if NOTHING is focused, just show everything at full opacity
        1,
      ],
    },
  };
}

const COLOR_EXPRESSION: Expression = [
  'case',
  IS_NOTE_TYPE_USER_EXPRESSION,
  colors.yellow,
  IS_NOTE_TYPE_ALERT_EXPRESSION,
  colors.darkRed,
  colors.primary,
];

/**
 * Returns a layer for styling polygonal note features. These are hidden
 * normally (leaving just the marker), but become apparent on hover and focus.
 */
function makeLineLayer(sourceId: string, opts: {layerId?: string} = {}): mapboxgl.LineLayer {
  return {
    id: opts.layerId || sourceId,
    maxzoom: 22,
    minzoom: 2,
    // We don’t have to filter on geometry type because mapbox-gl won’t try to
    // render a "line" layer for "point" values.
    source: sourceId,
    ...makeLayers.makeLine({
      color: COLOR_EXPRESSION,
      width: 4,
      opacity: ['case', ['any', IS_FOCUSED_EXPRESSION, IS_PENDING_EXPRESSION], 1, 0],
    }),
  };
}
