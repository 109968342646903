import * as B from '@blueprintjs/core';
import React from 'react';

import {ReportNotePage} from 'app/modules/Remote/Feature';
import {StateApiNoteWithTagSettings} from 'app/stores/NotesStore';

export const NotePageControls: React.FunctionComponent<
  React.PropsWithChildren<{
    moveObservation?: (indexDiff: number) => void;
    disableMoveUp?: boolean;
    disableMoveDown?: boolean;
    isSelected: boolean;
    setReportState: (ReportStatePath) => void;
    reportNoteState: ReportNotePage;
    noteIndex: number;
    imageRefArr: any;
    alignment: 'horizontal' | 'vertical';
    areAttachedImagesVisible: boolean;
    images: string[];
    note: StateApiNoteWithTagSettings;
    isGraphVisible: boolean;
    isFullPageImageLayout: boolean;
  }>
> = ({
  moveObservation,
  disableMoveUp,
  disableMoveDown,
  isSelected,
  reportNoteState,
  setReportState,
  noteIndex,
  imageRefArr,
  alignment,
  areAttachedImagesVisible,
  images,
  note,
  isGraphVisible,
  isFullPageImageLayout,
}) => {
  return (
    <div className="note-control-btns no-print">
      {moveObservation && (
        <B.ButtonGroup vertical>
          <B.AnchorButton
            title="Move up"
            icon="caret-up"
            disabled={disableMoveUp}
            onClick={() => {
              moveObservation(-1);
            }}
          />

          <B.AnchorButton
            title="Move down"
            icon="caret-down"
            disabled={disableMoveDown}
            onClick={() => {
              moveObservation(1);
            }}
          />
        </B.ButtonGroup>
      )}

      <B.ButtonGroup vertical>
        <B.AnchorButton
          title="Include note in report"
          icon="eye-open"
          active={isSelected}
          onClick={() => {
            setReportState([['notePages', noteIndex, 'hidden'], false]);
          }}
        />
        <B.AnchorButton
          title="Exclude note from report"
          icon="eye-off"
          active={!isSelected}
          onClick={() => {
            setReportState([['notePages', noteIndex, 'hidden'], true]);
          }}
        />
      </B.ButtonGroup>

      <B.AnchorButton
        title={isFullPageImageLayout ? 'Default page layout' : 'Full page images'}
        icon={isFullPageImageLayout ? 'minimize' : 'maximize'}
        onClick={() => {
          setReportState([['notePages', noteIndex, 'fullPageImageLayout'], !isFullPageImageLayout]);
        }}
      />

      {imageRefArr.length === 2 && !isFullPageImageLayout && (
        <B.AnchorButton
          title={
            alignment === 'horizontal' ? 'Orient images vertically' : 'Orient images side-by-side'
          }
          icon="image-rotate-right"
          onClick={() => {
            setReportState([
              ['notePages', noteIndex, 'imageAlignment'],
              alignment === 'vertical' ? 'horizontal' : 'vertical',
            ]);
          }}
        />
      )}

      {images.length > 0 && (
        <B.AnchorButton
          title={
            areAttachedImagesVisible
              ? 'Exclude attached images from report'
              : 'Include attached images in report'
          }
          icon="paperclip"
          active={!areAttachedImagesVisible}
          onClick={() => {
            setReportState([
              ['notePages', noteIndex, 'attachmentsHidden'],
              !reportNoteState.attachmentsHidden,
            ]);
          }}
        />
      )}

      {note.graph && (
        <B.AnchorButton
          title={isGraphVisible ? 'Exclude graph from report' : 'Include graph in report'}
          icon="timeline-area-chart"
          active={isGraphVisible}
          onClick={() => {
            setReportState([['notePages', noteIndex, 'chartHidden'], !reportNoteState.chartHidden]);
          }}
        />
      )}
    </div>
  );
};
