import * as B from '@blueprintjs/core';
import {DateRangeInput3, DateRangeShortcut} from '@blueprintjs/datetime2';
import classnames from 'classnames';
import * as I from 'immutable';
import moment from 'moment-timezone';
import React from 'react';

import {ApiFeatureCollection, TagSetting} from 'app/modules/Remote/FeatureCollection';
import {DEFAULT_REPORT_EXPORT_DATES} from 'app/pages/MonitorProjectView/view';
import {StateApiNote} from 'app/stores/NotesStore';
import * as noteUtils from 'app/utils/noteUtils';
import * as tagUtils from 'app/utils/tagUtils';

import cs from './styles.styl';
import List from '../List';

export const Filters: React.FunctionComponent<
  React.PropsWithChildren<{
    notesFilterState: noteUtils.NotesFilterState;
    setNotesFilterState: (newNotesFilterState: noteUtils.NotesFilterState) => void;
    onClose: () => void;
    notes: StateApiNote[];
    description?: React.ReactElement;
    footer?: React.ReactElement;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    className?: string;
  }>
> = ({
  notesFilterState,
  setNotesFilterState,
  onClose,
  notes,
  footer,
  featureCollection,
  description,
  className,
}) => {
  const [showDateRangeInput, setShowDateRangeInput] = React.useState<boolean>(
    !!(notesFilterState.dateRange[0] || notesFilterState.dateRange[1])
  );
  const [showNoteTagsFilter, setShowNoteTagsFilter] = React.useState(
    !!notesFilterState.noteTagIds.length
  );

  const shortcuts: DateRangeShortcut[] = React.useMemo(() => {
    const firstYear = Math.min(...notes.map((n) => parseInt(n.createdAt)));
    const currYear = new Date().getFullYear();

    // Initialize our list of shortcuts with the option to select the "Past 12
    // months". Showing this first since it corresponds to the date range input
    // default.
    const shortcutEls: DateRangeShortcut[] = [
      {
        dateRange: [
          moment().subtract(1, 'year').toDate(),
          // Unbounded end date.
          null,
        ],
        label: 'Past 12 months',
      },
    ];

    // Create an array of year shortcuts from the earliest note until now, starting with the most recent year.
    for (let y = currYear; y >= firstYear; y--) {
      // Appending the month and day are important for ensuring that the year is the start of year
      // in the browser's timezone instead of UTC
      const yearStart = moment(`${y}-01-01`);
      const yearEnd = yearStart.clone().endOf('year');

      shortcutEls.push({
        dateRange: [yearStart.toDate(), yearEnd.toDate()],
        label: `${y}`,
      });
    }

    // Finally, add a shortcut for selecting all notes.
    shortcutEls.push({
      // Unbounded start and end dates.
      dateRange: [null, null],
      label: 'All',
    });

    return shortcutEls;
  }, [notes]);

  const inputProps = {
    className: classnames(cs.importExportToolbarDatePicker),
    small: true,
  };

  const noteTagSettings = React.useMemo(
    () => tagUtils.getTagSettings(featureCollection, tagUtils.TAG_KIND.NOTE),
    [featureCollection]
  );

  return (
    <div className={classnames(cs.filtersContainer, className)}>
      <div className={cs.popoverHeader}>
        <div className={cs.popoverHeaderTitle}>Filters</div>
        <B.ButtonGroup>
          <B.Tooltip content={'Remove all filters'}>
            <B.Button
              icon={'filter-remove'}
              minimal
              onClick={() => {
                setNotesFilterState({noteTagIds: [], dateRange: [null, null]});
                setShowDateRangeInput(false);
              }}
            />
          </B.Tooltip>
          <B.Button icon={'cross'} minimal onClick={onClose} />
        </B.ButtonGroup>
      </div>
      {description}
      <div className={cs.filtersBody}>
        <div className={cs.filterRow}>
          <B.Checkbox
            checked={showDateRangeInput}
            onChange={(e) => {
              const isChecked = e.currentTarget.checked;
              setShowDateRangeInput(isChecked);
              if (!isChecked) {
                setNotesFilterState({...notesFilterState, dateRange: [null, null]});
              } else {
                setNotesFilterState({
                  ...notesFilterState,
                  dateRange: DEFAULT_REPORT_EXPORT_DATES(),
                });
              }
            }}
          />
          <div className={cs.filterContainer}>
            <div className={cs.filterLabel}>Date range</div>
            {showDateRangeInput && (
              <DateRangeInput3
                className={cs.dateRangeInput}
                popoverProps={{
                  captureDismiss: true,
                }}
                formatDate={(date) => moment(date).format('L')}
                parseDate={(str) => moment(str, 'L').toDate()}
                value={notesFilterState.dateRange}
                onChange={(dateRange) =>
                  setNotesFilterState(
                    noteUtils.cleanNotesFilterState({...notesFilterState, dateRange})
                  )
                }
                allowSingleDayRange={true}
                startInputProps={inputProps}
                endInputProps={inputProps}
                shortcuts={shortcuts}
              />
            )}
          </div>
        </div>
        <div className={cs.filterRow}>
          <B.Checkbox
            checked={showNoteTagsFilter}
            onChange={(e) => {
              const isChecked = e.currentTarget.checked;
              setShowNoteTagsFilter(isChecked);
              if (!isChecked) {
                setNotesFilterState({...notesFilterState, noteTagIds: []});
              }
            }}
          />
          <div className={cs.filterContainer}>
            <div className={cs.filterLabel}>Note tags</div>
            {showNoteTagsFilter && (
              <TagFilter
                noteTagSettings={noteTagSettings}
                notesFilterState={notesFilterState}
                setNotesFilterState={setNotesFilterState}
              />
            )}
          </div>
        </div>
      </div>
      {footer}
    </div>
  );
};

const TagFilter: React.FunctionComponent<
  React.PropsWithChildren<{
    noteTagSettings: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<TagSetting>>>;
    notesFilterState: noteUtils.NotesFilterState;
    setNotesFilterState: (newNotesFilterState: noteUtils.NotesFilterState) => void;
  }>
> = ({noteTagSettings, notesFilterState, setNotesFilterState}) => {
  const activeNoteTagSettings = React.useMemo(() => {
    return noteTagSettings
      .filter((tagSetting) => notesFilterState.noteTagIds.includes(tagSetting!.get('id')))
      .toList();
  }, [noteTagSettings, notesFilterState.noteTagIds]);

  return (
    <div>
      <B.Popover
        position="right"
        content={
          <List
            items={tagUtils.makeTagListItems(noteTagSettings, (tagId) =>
              notesFilterState.noteTagIds.includes(tagId)
            )}
            onClickItem={({id}, ev) => {
              ev.preventDefault();

              if (notesFilterState.noteTagIds.includes(id)) {
                return setNotesFilterState({
                  ...notesFilterState,
                  noteTagIds: notesFilterState.noteTagIds.filter((noteTagId) => noteTagId !== id),
                });
              } else {
                setNotesFilterState({
                  ...notesFilterState,
                  noteTagIds: [...notesFilterState.noteTagIds, id],
                });
              }
            }}
          />
        }
      >
        <B.Button className={cs.filterText} outlined fill small text="Select tags" />
      </B.Popover>
      {!!activeNoteTagSettings.size && (
        <div className={cs.filterTags}>
          <>
            {activeNoteTagSettings.map((tagSetting) => (
              <tagUtils.Tag
                key={tagSetting!.get('id')}
                setting={tagSetting!}
                onRemove={(_e, tagProps) => {
                  const tagId = tagProps.id;
                  if (!tagId) {
                    return;
                  }
                  setNotesFilterState({
                    ...notesFilterState,
                    noteTagIds: notesFilterState.noteTagIds.filter(
                      (noteTagId) => noteTagId !== tagId
                    ),
                  });
                }}
              />
            ))}
          </>
        </div>
      )}
    </div>
  );
};
