import * as B from '@blueprintjs/core';
import React from 'react';

/**
 * B.Icon component (for use with Blueprint) for our custom Layers SVG.
 */
const LayersIcon: React.FunctionComponent<
  React.PropsWithChildren<{
    color?: string;
    size?: number;
  }>
> = ({color, size = 16}) => (
  <B.Icon
    icon={
      // Re-using bp5-icon here gives us consistent color / fill handling with
      // Blueprint’s built-in icons.
      <span className="bp5-icon">
        <svg width={size} height={size} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_318_6171)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M1.25361 8.89L8.25363 11.89C8.39363 11.96 8.54363 12 8.70363 12C8.86363 12 9.01363 11.96 9.15363 11.89L16.1536 8.89C16.4736 8.73 16.7036 8.39 16.7036 8C16.7036 7.59 16.4536 7.23 16.0936 7.08L9.10363 4.08C8.97363 4.03 8.84363 4 8.70363 4C8.56363 4 8.43363 4.03 8.31363 4.08L1.32361 7.08C0.953614 7.23 0.703613 7.59 0.703613 8C0.703613 8.39 0.933614 8.73 1.25361 8.89ZM15.7036 11C15.5436 11 15.3936 11.04 15.2536 11.11L8.70363 14L2.15363 11.1C2.01363 11.04 1.86363 11 1.70363 11C1.15363 11 0.703625 11.45 0.703625 12C0.703625 12.39 0.933626 12.73 1.25363 12.89L8.25363 15.89C8.39363 15.96 8.54363 16 8.70363 16C8.86363 16 9.01363 15.96 9.15363 15.89L16.1536 12.89C16.4736 12.73 16.7036 12.39 16.7036 12C16.7036 11.45 16.2536 11 15.7036 11Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_318_6171">
              <rect width={size} height={size} fill="white" transform="translate(0.703613)" />
            </clipPath>
          </defs>
        </svg>
      </span>
    }
  />
);

export default LayersIcon;
