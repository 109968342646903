import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import * as mapUtils from 'app/utils/mapUtils';

import MapContent from './MapContent';
import cs from './StyleControl.styl';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
  showPropertyBoundaries: boolean;
  setShowPropertyBoundaries: (isOpen: boolean) => void;
}

export default class MapShowPropertyBoundariesControl extends React.Component<Props> {
  private mapShowPropertyBoundariesControlButtonEl = document.createElement('div');
  private mapShowPropertyBoundariesControlButton = new mapUtils.PortalControl(
    this.mapShowPropertyBoundariesControlButtonEl
  );

  render() {
    const {map, isMapLoaded, position, showPropertyBoundaries, setShowPropertyBoundaries} =
      this.props;

    const tooltipText = showPropertyBoundaries
      ? 'Hide property boundaries'
      : 'Show property boundaries';

    return (
      <React.Fragment>
        <MapContent
          map={map}
          isMapLoaded={isMapLoaded}
          controls={[this.mapShowPropertyBoundariesControlButton]}
          controlPosition={position}
        />
        {ReactDOM.createPortal(
          <B.Tooltip content={tooltipText} position={B.Position.RIGHT}>
            <B.AnchorButton
              icon={showPropertyBoundaries ? 'eye-off' : 'eye-open'}
              className={cs.mapIcon}
              onClick={() => setShowPropertyBoundaries(!showPropertyBoundaries)}
            />
          </B.Tooltip>,
          this.mapShowPropertyBoundariesControlButtonEl
        )}
      </React.Fragment>
    );
  }
}
