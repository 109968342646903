import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';
import {Link} from 'react-router-dom';

import {ApiProject} from 'app/modules/Remote/Project';
import * as featureUtils from 'app/utils/featureUtils';
import {S2_NDVI} from 'app/utils/layers';
import {parseLayerKey} from 'app/utils/layerUtils';
import {useCurrentOrgIdPrefix} from 'app/utils/routeUtils';

import {LayersLibraryMode} from './LensLibraryProvider';
import cs from './LensLibraryView.styl';
import {LayerDataset, LibraryDataset, LibraryDatasetType, OverlayDataset} from './LibraryTypes';
import {FEATURED_OVERLAY_METADATA} from './overlaysConfig';

export const makeLayerActionButton = (
  layer: LibraryDataset<LayerDataset>,
  fcIdToProjectIdMap: Record<number, ApiProject>,
  projects: I.ImmutableOf<ApiProject[]>,
  addLibraryItem: (
    projectIds: string[],
    layerKey: string,
    addTracking: boolean,
    name: string,
    type: LibraryDatasetType
  ) => Promise<void>,
  removeLibraryItem: (
    projectIds: string[],
    layerKey: string,
    addTracking: boolean,
    name: string,
    type: LibraryDatasetType
  ) => Promise<void>,
  onOpenCheckoutModal: (layerKey: string) => void,
  subscribedPremiumSourceIds: string[],
  mode: LayersLibraryMode
) => {
  const added = layer.enrolledFeatureCollectionIds.length > 0;
  const enrolledProjectIds = layer.enrolledFeatureCollectionIds.map(
    (fcId) => fcIdToProjectIdMap[fcId].id
  );
  const supportedProjectIds = layer.supportedFeatureCollectionIds.map(
    (fcId) => fcIdToProjectIdMap[fcId].id
  );
  const supportedProjects = projects
    .filter((p) => supportedProjectIds.includes(p!.get('id')))
    .toList();

  const restricted = layer.restricted;
  // NOTE: making assumption that all restrictions are limited
  // to standard and enterprise tier restrictions. This assumption
  // is also made in a slightly different way in overlays.
  const restrictionReason = 'Standard and Enterprise only';

  const {sourceId} = parseLayerKey(layer.layerKey);

  let actionButton: JSX.Element;
  if (restricted) {
    actionButton = (
      <B.Button icon={'lock'} active={false}>
        {restrictionReason}
      </B.Button>
    );
  } else if (layer.libraryAction) {
    // This will ALWAYS show the custom action, never the portfolio dropdown
    actionButton = (
      <B.Button
        icon={'plus'}
        intent={layer.libraryAction.intent ?? 'none'}
        onClick={(event) => {
          window.open(layer.libraryAction!.link, '_blank');
          event.stopPropagation();
        }}
        text={layer.libraryAction.text}
      />
    );
  } else if (layer.premium && !subscribedPremiumSourceIds.includes(sourceId)) {
    actionButton = (
      <B.Button
        icon={'plus'}
        onClick={(event) => {
          onOpenCheckoutModal(layer.layerKey);
          event.stopPropagation();
        }}
      >
        Checkout
      </B.Button>
    );
  } else if (mode === 'singlePortfolio') {
    actionButton = (
      <B.Button
        icon={added ? 'cross' : 'plus'}
        text={added ? 'Remove from portfolio' : 'Add to portfolio'}
        onClick={(event) => {
          added
            ? removeLibraryItem(
                supportedProjectIds,
                layer.layerKey,
                false,
                layer.name,
                LibraryDatasetType.LAYER
              )
            : addLibraryItem(
                supportedProjectIds,
                layer.layerKey,
                false,
                layer.name,
                LibraryDatasetType.LAYER
              );
          event.stopPropagation();
        }}
      />
    );
  } else {
    let addButtonText = 'Add to portfolios';
    if (added) {
      addButtonText = `Update portfolios (${layer.enrolledFeatureCollectionIds.length})`;
    }

    actionButton = (
      <LibraryItemDropdown
        name={layer.name}
        keyName={layer.layerKey}
        type={LibraryDatasetType.LAYER}
        enrolledProjectIds={enrolledProjectIds}
        addTracking={true}
        projects={supportedProjects}
        disabled={false}
        addLibraryItem={addLibraryItem}
        removeLibraryItem={removeLibraryItem}
      >
        <B.Button icon={added ? 'cog' : 'plus'} text={addButtonText} />
      </LibraryItemDropdown>
    );
  }
  return actionButton;
};

export const makeOverlayActionButton = (
  overlay: LibraryDataset<OverlayDataset>,
  enrolledProjectIds: string[],
  mode: LayersLibraryMode,
  updatableProjectIds: string[],
  restrictions: {
    [featureCollectionId: number]: boolean;
  },
  addLibraryItem: (
    projectIds: string[],
    featureCollectionId: string,
    addTracking: boolean,
    name: string,
    type: LibraryDatasetType
  ) => Promise<void>,
  removeLibraryItem: (
    projectIds: string[],
    featureCollectionId: string,
    addTracking: boolean,
    name: string,
    type: LibraryDatasetType
  ) => Promise<void>,
  projects: I.ImmutableListOf<ApiProject>,
  selectedOverlayIds: number[] | undefined,
  setSelectedOverlayIds: React.Dispatch<React.SetStateAction<number[]>> | undefined
) => {
  const keyName = overlay.featureCollectionId.toString();
  const name = overlay.name;
  const restricted = restrictions[keyName];
  const restrictionReason = FEATURED_OVERLAY_METADATA[name]?.restriction || 'Unavailable';
  const added =
    mode === 'singlePortfolio'
      ? !!updatableProjectIds.find((projectId) => enrolledProjectIds.includes(projectId)) ||
        (!!selectedOverlayIds && selectedOverlayIds.includes(overlay.featureCollectionId))
      : enrolledProjectIds.length > 0;

  const storeForNewPortfolio =
    mode === 'singlePortfolio' &&
    !!setSelectedOverlayIds &&
    !!updatableProjectIds.find((projectId) => projectId === 'fake-id');

  let actionButton: JSX.Element;

  if (restricted) {
    actionButton = (
      <B.Button icon={'lock'} active={false}>
        {restrictionReason}
      </B.Button>
    );
  } else if (mode === 'singlePortfolio') {
    actionButton = (
      <B.Button
        icon={added ? 'cross' : 'plus'}
        intent={added ? 'none' : B.Intent.SUCCESS}
        text={added ? 'Remove' : 'Add'}
        onClick={(event) => {
          if (storeForNewPortfolio) {
            setSelectedOverlayIds(
              (prevSelected) => [...prevSelected, overlay.featureCollectionId] as number[]
            );
          } else {
            if (added) {
              removeLibraryItem(
                updatableProjectIds,
                keyName,
                false,
                name,
                LibraryDatasetType.OVERLAY
              );
            } else {
              addLibraryItem(updatableProjectIds, keyName, false, name, LibraryDatasetType.OVERLAY);
            }
          }
          event.stopPropagation();
        }}
      />
    );
  } else {
    actionButton = (
      <LibraryItemDropdown
        name={name}
        keyName={keyName.toString()}
        type={LibraryDatasetType.OVERLAY}
        enrolledProjectIds={enrolledProjectIds}
        addTracking={overlay.uploadedBy === 'Upstream System'}
        projects={projects}
        disabled={false}
        addLibraryItem={addLibraryItem}
        removeLibraryItem={removeLibraryItem}
      >
        <B.Button icon={added ? 'tick' : 'plus'} intent={added ? 'none' : B.Intent.SUCCESS}>
          {added ? 'Added' : 'Add'}
        </B.Button>
      </LibraryItemDropdown>
    );
  }

  return actionButton;
};

// Note: It's helpful to know here that it's technically possible for a premium
// layer to have been added manually to a fc, though this shouldn't be a valid
// state for customer data. If this is the case, we want our yet-to-be-purchased
// layer to look as though it hasn't been added until it's purchased.
export const isLayerAdded = (
  layer: LibraryDataset<LayerDataset>,
  subscribedPremiumSourceIds: string[]
) => {
  const {sourceId} = parseLayerKey(layer.layerKey);
  // A card is added if it has enrolled feature collections and isnt premium
  // OR is premium and has a source entry in subscribedPremiumSourceIds
  return !!(
    ((layer.enrolledFeatureCollectionIds.length > 0 && !layer.premium) ||
      (layer.enrolledFeatureCollectionIds.length > 0 &&
        layer.premium &&
        subscribedPremiumSourceIds.includes(sourceId))) &&
    // Don't show added state for layers with custom actions
    !layer.libraryAction
  );
};

export const LibraryItemDropdown: React.FunctionComponent<
  React.PropsWithChildren<{
    name: string;
    keyName: string;
    type: LibraryDatasetType;
    addTracking: boolean;
    projects: I.ImmutableOf<ApiProject[]>;
    enrolledProjectIds: string[];
    addLibraryItem: (
      projectIds: string[],
      keyName: string,
      addTracking: boolean,
      name: string,
      type: LibraryDatasetType
    ) => Promise<void>;
    removeLibraryItem: (
      projectIds: string[],
      keyName: string,
      addTracking: boolean,
      name: string,
      type: LibraryDatasetType
    ) => Promise<void>;
    disabled: boolean;
  }>
> = ({
  name,
  keyName,
  type,
  enrolledProjectIds: projectIds,
  projects,
  addTracking,
  addLibraryItem,
  removeLibraryItem,
  disabled,
  children,
}) => {
  const orgIdPrefix = useCurrentOrgIdPrefix();
  const selectedProjectIds = projects
    .map((p) => p!.get('id'))
    .filter((projId) => !!(projId && projectIds.includes(projId)))
    .toArray();

  const unselectedProjectIds = projects
    .map((p) => p!.get('id'))
    .filter((projId) => !projectIds!.includes(projId!))
    .toArray();

  //we need to explicitly handle whether the dropdown popover isOpen because if a library item
  //is filtered out of the library without the user having closed the popover, the next unfiltered
  //library item's dropdown is rendered open instead. so we want to force the dropdown to close
  //anytime a user de-selects EVERY project in the dropdown.
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean | undefined>(undefined);

  return (
    <B.Popover
      // when the popover is closed, ensure the isOpen status is reset to undefined
      // as if it is "false" the user will not be able to interact with it
      isOpen={dropdownOpen}
      onClosed={() => setDropdownOpen(undefined)}
      disabled={disabled}
      position="bottom-left"
      onInteraction={(nextOpenState) => setDropdownOpen(nextOpenState)}
      minimal
      content={
        projects.size ? (
          <B.Menu className={cs.libraryItemProjectsMenu} onClick={(ev) => ev.stopPropagation()}>
            {keyName !== S2_NDVI && (
              <>
                <B.Checkbox
                  checked={
                    //this box only checked if every project has this item
                    projectIds.length === projects.size
                  }
                  onChange={(ev) => {
                    if (ev.currentTarget.checked) {
                      addLibraryItem(
                        // on checking "All Portfolios" box, batch add the item to any projects it's not already in
                        unselectedProjectIds,
                        keyName,
                        addTracking,
                        name,
                        type
                      );
                    } else {
                      removeLibraryItem(
                        // on unchecking "All Portfolios", batch remove the item only from projects it's already in
                        // (this is functionally the same as checking "No Portfolios"
                        selectedProjectIds,
                        keyName,
                        addTracking,
                        name,
                        type
                      );
                      // force dropdown to close if user has unchecked every project
                      setDropdownOpen(false);
                    }
                  }}
                >
                  All portfolios
                </B.Checkbox>
                <B.Checkbox
                  checked={projectIds.length === 0}
                  onChange={(ev) => {
                    if (ev.currentTarget.checked) {
                      // on checking "No Portfolios", batch remove the item from any projects it's already in
                      removeLibraryItem(selectedProjectIds, keyName, addTracking, name, type);
                      // force dropdown to close if user has unchecked every project
                      setDropdownOpen(false);
                    }
                  }}
                >
                  No portfolios
                </B.Checkbox>
                <B.MenuDivider />
              </>
            )}
            {keyName === S2_NDVI && (
              <B.Callout intent="warning">
                Disabling this layer will unenroll all properties in your portfolio from vegetation
                alerts
              </B.Callout>
            )}
            <>
              {projects
                .sortBy((p) => p!.get('name'), featureUtils.alphanumericSort)
                .map((p) => (
                  <B.Checkbox
                    key={p!.get('id')}
                    checked={projectIds.includes(p!.get('id'))}
                    onChange={(ev) => {
                      if (ev.currentTarget.checked) {
                        // if we are checking a box, add that item to that project
                        addLibraryItem(
                          [p!.get('id')], // array of 1 project id to add the item to
                          keyName,
                          addTracking,
                          name,
                          type
                        );
                      } else {
                        // when unchecking, remove that item from that project
                        removeLibraryItem(
                          [p!.get('id')], // array of 1 project id to remove the item from
                          keyName,
                          addTracking,
                          name,
                          type
                        );
                        // force dropdown to close if user has unchecked the ONLY project
                        if (projects.size === 1) {
                          setDropdownOpen(false);
                        }
                      }
                    }}
                  >
                    {p!.get('name')}
                  </B.Checkbox>
                ))}
            </>
          </B.Menu>
        ) : (
          <div className={cs.noProjectsMessage} onClick={(e) => e.stopPropagation()}>
            <Link to={`/${orgIdPrefix}/admin/manageProperties`}>Create your first portfolio</Link>{' '}
            to start customizing your Lens library
          </div>
        )
      }
    >
      {/* Need to stopPropagation so that clicking on the dropdown button for a layer
      does not open the details modal. Handling that with a wrapper div around the child
      so we can track open state just within the component */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen((dropdownState) => !dropdownState);
        }}
      >
        {children}
      </div>
    </B.Popover>
  );
};
