import * as I from 'immutable';

import {ApiOrganization} from 'app/modules/Remote/Organization';
import * as CONSTANTS from 'app/utils/constants';

const ORGANIZATION_JS: ApiOrganization = {
  DefaultMapCenter: null,
  childOrganizations: [],
  id: '6e04f009-7309-4e38-a442-476948c4d7f5',
  isSuper: null,
  name: 'Natel Energy',
  nameShort: null,
  parentOrganizationId: null,
  products: ['Lens', 'HydroForecast'],

  dashboardLastLoadedAt: '2019-11-23T00:00:00+00:00',
  isArchived: false,

  settings: {},
  stripeCustomerId: 'cus_HBgkOvRCixJf5e',
  stripeSubscriptionIds: {
    Lens: 'lens-access-subscription-id',
  },
  tiers: {
    Lens: 'lens-focus',
  },

  defaultLayersBySource: {
    S2: ['truecolor'],
    L8: ['truecolor'],
  },
  supportedLayersBySource: {
    S2: ['truecolor'],
    L8: ['truecolor'],
  },
};

export const ORGANIZATION: I.ImmutableOf<ApiOrganization> = I.fromJS(ORGANIZATION_JS);
export const FOCUS_ORGANIZATION = ORGANIZATION;
export const PLUS_ORGANIZATION = ORGANIZATION.setIn(['tiers', 'Lens'], 'lens-plus');
export const ENTERPRISE_ORGANIZATION = ORGANIZATION.setIn(['tiers', 'Lens'], 'lens-enterprise');

export const INTERNAL_ORGANIZATION: I.ImmutableOf<ApiOrganization> = I.fromJS({
  DefaultMapCenter: null,
  childOrganizations: [ORGANIZATION],
  id: CONSTANTS.INTERNAL_ORGANIZATION_ID,
  isSuper: true,
  name: 'Upstream Tech',
  nameShort: 'Upstream',
  parentOrganizationId: null,
  products: ['Lens', 'HydroForecast'],

  contractType: 'paid',
  createdAt: '2019-10-30T00:00:00+00:00',
  dashboardLastLoadedAt: '2019-11-23T00:00:00+00:00',
  isArchived: false,

  settings: {},
  stripeCustomerId: '',
  stripeSubscriptionIds: {
    Lens: 'lens-access-subscription-id',
  },
  tiers: {
    Lens: 'lens-plus',
  },
});
