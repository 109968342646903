import * as turf from '@turf/turf';
import geojson from 'geojson';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {formatRegridAddress} from 'app/components/DeclarativeMap/GeometryOverlaysContent';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';

import {
  NumberedFeature,
  NumberedFeatureCollection,
  useManageFeatures,
} from './ManageFeaturesProvider';
import ManageFeaturesView from './ManageFeaturesView';
import {DrawPropertiesState, validateDrawnProperty} from './ManagePropertiesDraw';

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
  firebaseToken: string | null;
}

const ManagePropertiesSelectParcels: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
  firebaseToken,
}) => {
  const {manageFeaturesState, manageFeaturesActions, loading} = useManageFeatures();

  const [featureToEdit, setFeatureToEdit] = React.useState<null | geojson.Feature>(null);
  const [editMode, setEditMode] = React.useState<boolean>(false);

  const {setUploadedFeatureCollection} = manageFeaturesActions;
  const {uploadedFeatureCollection} = manageFeaturesState;

  // Handles adding a new feature and editing an existing feature
  React.useEffect(() => {
    if (featureToEdit) {
      setUploadedFeatureCollection((fc) => {
        const currentFeatureCollection: NumberedFeatureCollection = fc || {
          type: 'FeatureCollection',
          features: [],
        };

        const featureProblems = validateDrawnProperty(featureToEdit);

        // editing an existing feature
        if (currentFeatureCollection.features.find((f) => f.id === featureToEdit.id)) {
          const newFC: NumberedFeatureCollection = {
            ...currentFeatureCollection,
            features: fc!.features.map((f) => {
              if (f.id === featureToEdit.id) {
                const updatedFeature: NumberedFeature = {
                  ...f,
                  properties: {
                    name: f.properties?.name,
                    __UPSTREAM_PROBLEMS: featureProblems.length ? featureProblems : null,
                  },
                  geometry: featureToEdit.geometry,
                };
                return updatedFeature;
              }
              return f;
            }),
          };
          return newFC;
        }
        // adding a new feature
        else {
          // Get whatever the last feature's id was and add 1 to it. Can't rely just on using length
          // since features can be deleted
          const id = getNextId(currentFeatureCollection);
          const regridAddress = formatRegridAddress(featureToEdit.properties);
          const newFeature: NumberedFeature = {
            ...featureToEdit,
            id: id,
            properties: {
              name: regridAddress !== 'Uknown' ? regridAddress : `New Property ${id}`,
              __UPSTREAM_PROBLEMS: featureProblems.length ? featureProblems : null,
            },
          };
          const newFc: NumberedFeatureCollection = {
            ...currentFeatureCollection,
            features: [...currentFeatureCollection.features, newFeature],
          };
          return newFc;
        }
      });
    }
  }, [featureToEdit, setFeatureToEdit, setUploadedFeatureCollection]);

  // Get whatever the last feature's id was and add 1 to it. Can't rely just on using length
  // since features can be deleted
  const getNextId = (fc: NumberedFeatureCollection | null) => {
    if (!fc) {
      return 1;
    }
    return fc.features.length ? fc.features[fc.features.length - 1].id + 1 : 1;
  };

  const onParcelClick = (
    newFeature: geojson.Feature<geojson.Polygon | geojson.MultiPolygon>,
    ev: mapboxgl.MapLayerMouseEvent
  ) => {
    // Avoid accidentally creating new parcel features while editing an existing
    // shape
    if (editMode) {
      return;
    }
    const shiftKeyPressed = ev.originalEvent.shiftKey;

    if (shiftKeyPressed) {
      // Add selection to latest edited feature
      setFeatureToEdit((existingFeatureToEdit) => {
        if (existingFeatureToEdit) {
          const unionedGeometry: geojson.Polygon | geojson.MultiPolygon =
            turf.union(
              turf.featureCollection([
                existingFeatureToEdit as geojson.Feature<geojson.Polygon | geojson.MultiPolygon>,
                newFeature,
              ])
            )?.geometry ||
            (existingFeatureToEdit.geometry as geojson.Polygon | geojson.MultiPolygon);

          // After joining the existing shape with the new parcel a user selected, it's
          // possible to have interior rings or other types of undesired geometry form.
          // There's a check that prevents invalid geometry from being uploaded in
          // validateDrawnProperty. But removing those interioir rings and cleaning
          // multi polygons helps make more of the shapes a user can create with the parcel
          // uploader tool be valid and pass that check.

          // Flatten the multipolygon into it's own polygons
          const flattenedFc = turf.flatten(unionedGeometry);

          // For each of those polygons, only keep the first array in coordinates.
          // Any subsequent arrays are interior rings which we do not want. If there are
          // multiple polygons, join them back together as one multipolygon.
          const cleanedFeature =
            flattenedFc.features.length === 1
              ? turf.polygon([flattenedFc.features[0].geometry.coordinates[0]])
              : turf.multiPolygon(flattenedFc.features.map((f) => [f.geometry.coordinates[0]]));

          return {
            ...existingFeatureToEdit,
            geometry: cleanedFeature.geometry,
          };
        } else {
          return newFeature;
        }
      });
    } else {
      // Add a new feature. Important that we set the id here so that when we
      // add to this feature, we find the correct id in the feature collection to
      // modify.
      setFeatureToEdit({
        ...newFeature,
        id: getNextId(uploadedFeatureCollection),
      });
    }
  };

  const drawPropertiesState: DrawPropertiesState = {
    editMode: editMode,
    setEditMode: setEditMode,
    featureToEdit: featureToEdit,
    setFeatureToEdit: setFeatureToEdit,
    onMapFeatureClick: onParcelClick,
  };

  return (
    <ManageFeaturesView
      history={history}
      profile={profile}
      organization={organization}
      manageFeaturesState={manageFeaturesState}
      manageFeaturesActions={manageFeaturesActions}
      loggedInUserActions={loggedInUserActions}
      mode={'parcel'}
      drawPropertiesState={drawPropertiesState}
      loading={loading}
      firebaseToken={firebaseToken}
    />
  );
};

export default ManagePropertiesSelectParcels;
