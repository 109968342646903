import * as B from '@blueprintjs/core';
import React, {SyntheticEvent} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import Label from 'app/components/Label';
import Modal from 'app/components/Modal/Modal';
import * as C from 'app/utils/constants';
import * as inviteUtils from 'app/utils/inviteUtils';

import cs from './InviteUsersModal.styl';

const InviteUsersModal: React.FunctionComponent<
  React.PropsWithChildren<{
    tokens: inviteUtils.SignupTokens;
    isOpen: boolean;
    onClose: (event: SyntheticEvent<HTMLElement> | undefined) => unknown;
    permissions: inviteUtils.PERMISSIONS_TYPE[];
  }>
> = ({tokens, isOpen, onClose, permissions}) => {
  const [selectedRoleId, setSelectedRoleId] = React.useState<inviteUtils.RoleId>(
    C.USER_ROLE_READONLY
  );
  const selectedRoleLink = inviteUtils.getInviteLinkForRole(tokens, selectedRoleId);
  const lastValidTokenDate = inviteUtils.getLastValidTokenLocaleDateString(tokens, selectedRoleId);

  return (
    <Modal isOpen={isOpen} headerText="Invite Users" onClose={onClose} hideActions={true}>
      <div className={cs.container}>
        <div className={cs.form}>
          <div>
            <Label text="Role" />
            <B.HTMLSelect
              value={selectedRoleId}
              className={cs.role}
              onChange={(e) => setSelectedRoleId(e.target.value as inviteUtils.RoleId)}
            >
              {inviteUtils.roleIds.map((roleId) => (
                <option key={roleId} value={roleId}>
                  {inviteUtils.ROLE_NAMES[roleId]}
                </option>
              ))}
            </B.HTMLSelect>
          </div>
          <div>
            <Label text="Shareable link*" />
            <CopyToClipboard text={selectedRoleLink}>
              <div>
                <B.InputGroup
                  className={cs.link}
                  type="text"
                  value={selectedRoleLink}
                  readOnly
                  rightElement={<B.Button>Copy</B.Button>}
                />
              </div>
            </CopyToClipboard>
          </div>
        </div>
        <B.Callout className={cs.callout} intent={B.Intent.PRIMARY} icon={null}>
          {inviteUtils.ROLE_SUMMARIES[selectedRoleId]}{' '}
          <a href={inviteUtils.ROLE_SUPPORT_DOC_LINK} target="_blank" rel="noopener noreferrer">
            See support doc here.
          </a>
          <table>
            <tbody>
              {permissions.map((permission) => {
                const permissionDef = inviteUtils.PERMISSIONS.find((p) => p.type === permission);
                if (permissionDef) {
                  const {label, actions} = permissionDef;

                  const listActions = actions.filter(
                    (a) => a.roles.includes(selectedRoleId) && !!a.text
                  );
                  const noteActions = actions.filter(
                    (a) => a.roles.includes(selectedRoleId) && !!a.note
                  );

                  return (
                    <tr key={permission}>
                      <th>{label}</th>
                      <td>
                        {noteActions.length ? (
                          <>
                            {noteActions.map((a, i) => (
                              <em key={i}>{a.note}</em>
                            ))}
                          </>
                        ) : null}
                        {listActions.length ? (
                          <ul>
                            {listActions.map((a, i) => (
                              <li key={i}>{a.text}</li>
                            ))}
                          </ul>
                        ) : null}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </B.Callout>
        <span style={{fontSize: '1rem'}}>
          *For your security, signup links are automatically refreshed after 14 days. The current
          link will reset on {lastValidTokenDate}.{' '}
        </span>
      </div>
    </Modal>
  );
};

export default InviteUsersModal;
