import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {PortalControl} from 'app/utils/mapUtils';

import MapContent from './MapContent';
import * as cs from './ZoomCenterControl.styl';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
}

// Note: mapbox has its own fullscreen control, but we're using a custom one here
// so we can have a custom tooltip and icon

const MapFullscreenControl: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  position,
}) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  // Watch for fullscreenchange
  React.useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(!!document.fullscreenElement);
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const fullscreenControlEl = React.useRef(document.createElement('div'));
  const fullscreenControl = React.useMemo(
    () => new PortalControl(fullscreenControlEl.current),
    [fullscreenControlEl]
  );

  return (
    <>
      {ReactDOM.createPortal(
        <B.Tooltip content={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'} position="left">
          <B.AnchorButton
            onClick={() =>
              isFullscreen ? document.exitFullscreen() : document.body.requestFullscreen()
            }
            icon={isFullscreen ? 'minimize' : 'maximize'}
            className={cs.btn}
          />
        </B.Tooltip>,
        fullscreenControlEl.current
      )}

      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[fullscreenControl]}
        controlPosition={position}
      />
    </>
  );
};
export default MapFullscreenControl;
