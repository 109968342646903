import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {PortalControl} from 'app/utils/mapUtils';

import MapContent from './MapContent';
import cs from './MapTilesLoadingControl.styl';

const MapTilesLoadingControl: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
  }>
> = ({map, isMapLoaded}) => {
  const tilesLoadingControlEl = React.useRef(document.createElement('div'));
  const tilesLoadingControl = React.useMemo(
    () => new PortalControl(tilesLoadingControlEl.current),
    [tilesLoadingControlEl]
  );

  const [areTilesLoading, setAreTilesLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const updateAreTilesLoading = () => {
      /*
        In the future, if we wanted to make the loading spinner track the progrss of downloaded tiles we could use
        the same logic that areTilesLoaded uses to get the percentage of tiles downloaded.
        areTilesLoaded uses a couple internal _ properties so it may not be the safest thing for us to replicate
        https://github.com/mapbox/mapbox-gl-js/blob/0d2bf58e758ca3f530aa00aeadb611d6309da916/src/ui/map.js#L1967
      */
      setAreTilesLoading(!map.areTilesLoaded());
    };

    map.on('sourcedata', updateAreTilesLoading);

    return () => {
      map.off('sourcedata', updateAreTilesLoading);
    };
  }, [map]);

  return (
    <>
      {ReactDOM.createPortal(
        <div className={cs.container}>
          {/*
            Also check  map.areTilesLoaded() because a couple times during manual testing areTilesLoading === true even though
            all of the tiles were loaded. I wasn't able to intentionally replicate this issue but haven't seen it happen since adding
            the map.areTilesLoaded() call as a safeguard
          */}
          {areTilesLoading && !map.areTilesLoaded() && (
            <B.Spinner size={15} intent={B.Intent.PRIMARY} />
          )}
        </div>,
        tilesLoadingControlEl.current
      )}

      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[tilesLoadingControl]}
        controlPosition="bottom-left"
      />
    </>
  );
};

export default MapTilesLoadingControl;
