import {ExportToCsv} from 'export-to-csv';
import * as I from 'immutable';
import moment from 'moment-timezone';

import {ApiFeatureData, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {
  ApiImageryContractWithProjects,
  ApiImageryPurchaseBillingRecord,
  ApiProject,
} from 'app/modules/Remote/Project';
import * as featureUtils from 'app/utils/featureUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import * as layers from './layers';

/**
 * A function to format a date consistently.
 */
export function formatDate(
  date: Date | string | moment.Moment,
  layerKey: string | undefined = undefined
) {
  const momentDate = layerUtils.conditionallyAdjustLayerDate(date, layerKey);
  return layerUtils.onlyShowCaptureDate(layerKey)
    ? momentDate.utc().format('MM/DD/YYYY')
    : momentDate.format('L');
}

/**
 * A function to format dates and times with user timezone. E.g. "March 25, 2020 10:08 AM PDT"
 * Optionally takes descriptive param to display as "March 25, 2020 at 10:08 AM PDT"
 */
function formatDateWithTime(date: Date | string | moment.Moment, descriptive = false) {
  const momentDate = moment(date);
  const timezoneAbr = moment.tz(momentDate, moment.tz.guess()).format('z');

  if (descriptive) {
    return `${momentDate.format('LL')} at ${momentDate.format('LT')} ${timezoneAbr}`;
  } else {
    return `${momentDate.format('LLL')} ${timezoneAbr}`;
  }
}

/**
 * A function that formats the capture time for a layer, accounting for the range
 * contributing to a mosaic.
 */
export function formatCaptureDate(
  date: Date | string | moment.Moment,
  layerKey: string | undefined,
  {
    featureDatum = undefined,
    showCaptureTime = false,
    descriptive = false,
  }: {
    featureDatum?: I.MapAsRecord<I.ImmutableFields<ApiFeatureData>> | undefined;
    showCaptureTime?: boolean;
    descriptive?: boolean;
  } = {}
) {
  // If the LayerInfo is configured to never show a time (e.g. for a source that doesn't have accurate
  // capture times, only dates) then we'll ignore showCaptureTime, and always render dates in UTC
  // rather than converting them to the user's timezone (to prevent it from shifting around depending
  // on the user's offset from UTC).
  const granularity =
    layerUtils.onlyShowCaptureDate(layerKey) || !(showCaptureTime || descriptive) ? 'day' : 'hour';

  const layerMosaicDates = layerUtils.staticLayerMosaicRange(layerKey, date);
  const sensingDates = featureDatum
    ? featureUtils.getFeatureDataSensingDates(featureDatum, layerKey)
    : [];
  const momentDate = moment(date);

  // A layer configured as mosaic (by setting the mosaic field on the LayerInfo object)
  // has a static date range, e.g. the start/end of a month for a montly mosaic.
  if (layerMosaicDates) {
    // Don't convert the static range to the user's timezone to prevent the range (in UTC)
    // from shifting earlier/later (e.g. which would cause 4/1 to become 3/31).
    const start = layerMosaicDates[0].format('MMMM DD, YYYY');
    const end = layerMosaicDates[1].format('MMMM DD, YYYY');
    return `Includes data captured between ${start} and ${end}`;
  } else if (sensingDates.length > 1) {
    // If the FeatureDatum has a specific mosaic range on it we should display it.
    const startDate = moment(sensingDates[0]);
    const endDate = moment(sensingDates[sensingDates.length - 1]);
    const isMosaic = !startDate.isSame(date, granularity) || !endDate.isSame(date, granularity);
    if (isMosaic) {
      let start: string, end: string;
      if (layerUtils.onlyShowCaptureDate(layerKey)) {
        // Display the date in UTC (not localized)
        start = startDate.utc().format('MMMM DD, YYYY');
        end = endDate.utc().format('MMMM DD, YYYY');
      } else if (granularity === 'day') {
        // Display the date converted to the user's timezone
        start = startDate.format('LL');
        end = endDate.format('LL');
      } else {
        start = formatDateWithTime(startDate, descriptive);
        end = formatDateWithTime(endDate, descriptive);
      }
      return `Includes data captured between ${start} and ${end}`;
    }
  }

  // This is a single capture (not a mosaic)
  if (granularity === 'day') {
    if (layerUtils.onlyShowCaptureDate(layerKey)) {
      // Display the date in UTC (not localized)
      return momentDate.utc().format('MMMM DD, YYYY');
    }
    // Display the date converted to the user's timezone
    return momentDate.format('LL');
  } else {
    return formatDateWithTime(date, descriptive);
  }
}

/**
 * A function that, given a billing rercord object, returns a formatted scene date.
 */
function formatSceneDate(purchase: ApiImageryPurchaseBillingRecord) {
  const sceneDate = purchase.sceneSensingTime;
  return sceneDate ? formatDate(sceneDate) : null;
}

/**
 * A function that, given a billing record object, returns a formatted order date.
 */
function formatOrderDate(purchase: ApiImageryPurchaseBillingRecord) {
  const purchasedAt = purchase.purchasedAt;
  return purchasedAt ? formatDate(purchasedAt) : null;
}

/*
 * A function that, given an MapImageRef, returns a formatted string with the ImageRef's details.
 */
export function formatImageRef(
  featureData: I.ImmutableOf<ApiFeatureData>,
  imageRef: mapUtils.MapImageRef | undefined
): string {
  if (!imageRef) {
    return '';
  }

  const layer = layerUtils.getLayer(imageRef.layerKey);
  const details = featureUtils.imageAndSourceDetails(featureData, imageRef.layerKey!, I.fromJS({}));

  return `${layer.shortName} | ${formatDate(
    moment(imageRef.cursor || undefined),
    imageRef.layerKey
  )} ${details.sourceDetails.operator} ${details.sourceDetails.name} (${
    details.sourceDetails.resolution
  }m)`;
}

export function getLayerKeyForScene(scene: I.ImmutableOf<ApiOrderableScene>) {
  const layerKeys = scene
    .get('urls')
    .filter((_value, key) => !key?.endsWith('_raw') && !!key?.startsWith(scene.get('sourceId')))
    .keySeq()
    .toArray();

  // For the planet forest carbon package, there will be multiple urls on the scene
  // representing all the layers the package includes. For the purposes of displaying
  // this package, we want to use just one layerKey that represents the package as a whole
  // and ignore the individual layers
  if (`${scene.get('sourceId')}_package` in layers.LAYER_PACKAGES) {
    return `${scene.get('sourceId')}_package`;
  } else {
    // In all other cases we should only have one thing here
    return layerKeys[0];
  }
}

export function canLoadOrderableSceneOnMap(scene: I.ImmutableOf<ApiOrderableScene>) {
  const layerKey = getLayerKeyForScene(scene);

  // NOTE: right now we only allow highResTruecolor imagery to be loaded from the order
  // pane. This could change in the future.
  // ALSO NOTE: layerKey will only be undefined if getLayerKeyForScene comes back undefined,
  // which will only happen if there are no urls available for the scene.
  return !!layerKey?.endsWith('high-res-truecolor');
}

export type BillingPeriod = {
  start: string;
  end: string;
};

/**
 * A function that given a contract datum, which contains contract and purchase
 * record data, exports a CSV of individual purchase records.
 */
export function exportRecordsCsv({
  fileName,
  billingRecords,
  contracts,
}: {
  fileName: string;
  billingRecords: (ApiImageryPurchaseBillingRecord & {billingPeriod: BillingPeriod})[];
  contracts: ApiImageryContractWithProjects[];
}) {
  const contractsById = contracts.reduce<Record<string, ApiImageryContractWithProjects>>(
    (acc, c) => ({...acc, [c.id]: c}),
    {}
  );

  const projectsById = contracts.reduce<Record<string, ApiProject>>(
    (acc, c) => c.projects.reduce((acc, p) => ({...acc, [p.id]: p}), acc),
    {}
  );

  const PROPERTIES = 'Properties';
  const CAPTURE_DATE = 'Capture Date';
  const ACREAGE = 'Acreage';
  const COST_IN_USD = 'Cost (USD)';
  const SOURCE_OPERATOR = 'Source Operator';
  const SOURCE_NAME = 'Source Name';
  const SOURCE_RESOLUTION = 'Source Resolution';
  const ORDER_DATE = 'Order Date';
  const ORDERED_BY = 'Ordered By';
  const PROJECT = 'Portfolio';
  const CONTRACT = 'Contract';
  const BILLING_PERIOD_START = 'Billing Period Start';
  const BILLING_PERIOD_END = 'Billing Period End';

  const csvExporter = new ExportToCsv({
    showLabels: true,
    useBom: false,
    filename: fileName,
    headers: [
      PROPERTIES,
      CAPTURE_DATE,
      ACREAGE,
      COST_IN_USD,
      SOURCE_OPERATOR,
      SOURCE_NAME,
      SOURCE_RESOLUTION,
      ORDER_DATE,
      ORDERED_BY,
      PROJECT,
      CONTRACT,
      BILLING_PERIOD_START,
      BILLING_PERIOD_END,
    ],
  });

  // Fall back to empty strings for null or undefined row values to produce
  // empty CSV cells.
  const rows = billingRecords.map((ipbr) => {
    const sceneSourceId = ipbr.sceneSourceId;
    const sceneSourceDetails = sceneSourceId && featureUtils.getSourceDetails(sceneSourceId);

    const contract = contractsById[ipbr.imageryContractId];
    const project: ApiProject | null = projectsById[ipbr.projectId] || null;

    const costInCents = ipbr.billedAcres * ipbr.priceCents;

    return {
      [PROPERTIES]: ipbr.imageryPurchases
        .map((purchase, index) => {
          let rowName = index === 0 ? '' : ' | ';
          rowName += purchase.featureProperties.name;
          if (purchase.featureProperties.multiFeaturePartName) {
            rowName += ` - (${purchase.featureProperties.multiFeaturePartName})`;
          }
          return rowName;
        })
        .join(''),

      [CAPTURE_DATE]: formatSceneDate(ipbr!) || '',

      [ACREAGE]: ipbr.billedAcres,

      [COST_IN_USD]: (costInCents / 100).toFixed(2),

      [SOURCE_OPERATOR]: sceneSourceDetails?.operator || '',
      [SOURCE_NAME]: sceneSourceDetails?.name || '',
      [SOURCE_RESOLUTION]: `${sceneSourceDetails?.resolution}m` || '',

      [ORDER_DATE]: formatOrderDate(ipbr!) || '',
      [ORDERED_BY]: ipbr.purchasedByName || '',

      [PROJECT]: project?.name || '',

      [CONTRACT]: contract.name,

      [BILLING_PERIOD_START]: ipbr.billingPeriod?.start || 'N/A',
      [BILLING_PERIOD_END]: ipbr.billingPeriod?.end || 'N/A',
    };
  });

  csvExporter.generateCsv(rows);
}
