import React from 'react';

import {GradientStops} from 'app/utils/gradientStops';
import {DataLabels} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';

import {MapControlPosition} from '../DeclarativeMap';

interface Props {
  gradientStops?: GradientStops;
  dataLabels?: DataLabels;
  horizontal?: boolean;
  width?: number;
  height?: number;
  cornerRadius?: number;
  map?: mapboxgl.Map;
  isMapLoaded?: boolean;
  onMap?: boolean;
  position?: MapControlPosition;
  hideLabels?: boolean;
}

const CONTAINER_TEXT_PADDING = 20;

const MAIN_AXIS_LENGTH = 200;
const MINOR_AXIS_LENGTH = 30;

const MINOR_AXIS_TEXT_PADDING = 5;

let LAYER_LEGEND_ID = 0;

const LayerLegend: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  gradientStops,
  dataLabels = [],
  horizontal = true,
  height = horizontal ? MINOR_AXIS_LENGTH : MAIN_AXIS_LENGTH,
  width = horizontal ? MAIN_AXIS_LENGTH : MINOR_AXIS_LENGTH,
  cornerRadius = 0,
  hideLabels = false,
}) => {
  // Has to be unique on the page.
  const gradientId = React.useMemo(() => `LayerLegend${LAYER_LEGEND_ID++}`, []);

  return (
    <svg height={height + (horizontal && !hideLabels ? CONTAINER_TEXT_PADDING : 0)} width={'100%'}>
      <linearGradient
        id={gradientId}
        x1={horizontal ? width : 0}
        x2={0}
        y1={0}
        y2={horizontal ? 0 : height}
        gradientUnits="userSpaceOnUse"
      >
        {gradientStops && layerUtils.renderGradientStops(gradientStops)}
      </linearGradient>

      <rect
        fill={`url(#${gradientId})`}
        x={0}
        y={0}
        height={height}
        width={width}
        rx={cornerRadius}
        ry={cornerRadius}
      />

      {!hideLabels &&
        dataLabels
          .slice()
          .reverse()
          .map((label, i, arr) => {
            return (
              <text
                key={i}
                x={
                  horizontal
                    ? i === 0
                      ? width - i * (width / (arr.length - 1)) - 2 // position equal spaced depending on number of legend entries
                      : 2 // a bit of adjustment to have the text line up with the rect
                    : width + MINOR_AXIS_TEXT_PADDING
                }
                y={horizontal ? height + MINOR_AXIS_TEXT_PADDING : i * (height / (arr.length - 1))}
                textAnchor={i === 0 && horizontal ? 'end' : 'start'}
                dominantBaseline={i === 0 || horizontal ? 'hanging' : 'baseline'}
                fontSize={12}
              >
                {label}
              </text>
            );
          })}
    </svg>
  );
};

export default LayerLegend;
