import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import {VelocityTransitionGroup} from 'velocity-react';

import cs from './TabbedSidebar.styl';

export interface SidebarView {
  title: string;
  className?: string;
  icon: React.ReactElement;
  disabled?: boolean;
  /** Callback for rendering the view’s content if it’s being displayed. */
  render?: () => React.ReactNode;
  /** If a view has no content to render, you can hide the panel.*/
  hidePanelDrawer?: boolean;
  /** Callback to be executed on clicking the sidebar, separate from its render. */
  onClick?: () => void;
}

export interface SidebarViewState {
  currentViewTitle: string | null;
  isExpanded: boolean;
}

export const DEFAULT_SIDEBAR_VIEW_STATE: SidebarViewState = {
  currentViewTitle: null,
  isExpanded: false,
};

const ICON_SIZE = 24;

// Copied in from TabbedSidebar.styl. We use hard-coded values because the
// Velocity animations make it tricky to use clientWidth to dynamically know the
// width of these elements as they’re updated.
const SIDEBAR_WIDTH = 70; // 7rem
const SIDEBAR_CONTENT_WIDTH = 360; //36rem

export const TOGGLE_SIDEBAR_BUTTON_TEST_ID = 'toggle-sidebar-button';

/**
 * Component for a left-hand set of tabs that have slide-out content. This
 * component is controlled, so you’ll need to pass state and setState.
 *
 * The currentViewTitle should always match one of the views, even if the
 * component is collapsed. That way if the user toggles the sidebar open it
 * opens to where they left off.
 */
const TabbedSidebar: React.FunctionComponent<
  React.PropsWithChildren<{
    hidden?: boolean;
    views: SidebarView[];

    state: SidebarViewState;
    setState: (s: SidebarViewState) => void;

    /**
     * Called when the size of the sidebar changes so that the map can update to
     * keep itself from being obscured.
     */
    setPaddingOptions?: (padding: mapboxgl.PaddingOptions) => void;
  }>
> = ({
  views,
  hidden = false,
  setPaddingOptions,
  state: {currentViewTitle, isExpanded},
  setState,
}) => {
  React.useEffect(() => {
    const contentWidth = isExpanded ? SIDEBAR_CONTENT_WIDTH : 0;

    setPaddingOptions &&
      setPaddingOptions({
        top: 0,
        right: 0,
        bottom: 0,
        left: hidden // if sidebar is hidden, we need 0 padding
          ? 0
          : // if we are rendering a view that hides the SidebarView panel,
            // only leave padding for the sidebar, not the panel
            views.find((view) => view.title === currentViewTitle)?.hidePanelDrawer
            ? SIDEBAR_WIDTH
            : SIDEBAR_WIDTH + contentWidth,
      });
  }, [isExpanded, setPaddingOptions, hidden, currentViewTitle, views]);

  // Defaulting to the 1st on "null" lets us have a common default state that
  // works for all values of the views prop.
  const currentView =
    (currentViewTitle && views.find(({title}) => title === currentViewTitle)) || views[0];

  const makeOnViewButtonClick = (title: string) => () => {
    if (title === currentView.title && isExpanded) {
      setState({
        isExpanded: false,
        currentViewTitle: currentView.title,
      });
    } else {
      setState({
        isExpanded: true,
        currentViewTitle: title,
      });
    }
  };

  const disableToggle = !currentView || currentView.disabled;

  React.useEffect(() => {
    if (currentView.onClick && isExpanded) {
      currentView.onClick();
    }
  }, [currentView, isExpanded]);

  return (
    <>
      <VelocityTransitionGroup
        enter={{animation: 'transition.slideLeftBigIn', duration: 200}}
        leave={{animation: 'transition.slideLeftBigOut', duration: 200}}
      >
        {!hidden && (
          <div className={cs.sidebarContainer}>
            <div className={cs.sidebar}>
              <div className={cs.buttonGroup}>
                {views.map(({title, className, icon: IconComponent, disabled}) => {
                  const modifiedIcon = React.cloneElement(IconComponent, {
                    size: ICON_SIZE,
                  });
                  return (
                    <B.Button
                      key={title}
                      className={classnames(cs.button, className)}
                      onClick={makeOnViewButtonClick(title)}
                      active={isExpanded && title === currentView.title && !disabled}
                      disabled={disabled}
                      minimal
                    >
                      {modifiedIcon}
                      {title}
                    </B.Button>
                  );
                })}
              </div>

              <B.Button
                className={cs.button}
                minimal
                // Have to use testid because the icon’s title gets stuck in a <desc>
                // element.
                data-testid={TOGGLE_SIDEBAR_BUTTON_TEST_ID}
                onClick={() =>
                  setState({isExpanded: !isExpanded, currentViewTitle: currentView.title})
                }
                disabled={disableToggle}
                style={{
                  opacity: disableToggle ? 0 : 1,
                  transition: 'opacity 250ms',
                }}
              >
                <B.Icon
                  style={{
                    transform: `rotateY(${isExpanded ? '180deg' : '0'})`,
                    transition: 'transform 250ms',
                  }}
                  icon="double-chevron-right"
                  iconSize={ICON_SIZE}
                  title={isExpanded ? 'Collapse sidebar' : 'Expand sidebar'}
                />
              </B.Button>
            </div>

            <VelocityTransitionGroup
              enter={{animation: 'transition.slideLeftBigIn', duration: 300}}
              leave={{animation: 'transition.slideLeftBigOut', duration: 300}}
            >
              {isExpanded &&
                currentView &&
                !currentView.disabled &&
                !currentView.hidePanelDrawer &&
                !!currentView.render && (
                  <div className={cs.sidebarContent}>{currentView.render()}</div>
                )}
            </VelocityTransitionGroup>
          </div>
        )}
      </VelocityTransitionGroup>
    </>
  );
};

/** Helper wrapper for those times when you want an uncontrolled TabbedSidebar. */
export const WithTabbedSidebarState: React.FunctionComponent<{
  initialState?: SidebarViewState;
  children: (state: SidebarViewState, setState: (s: SidebarViewState) => void) => JSX.Element;
}> = ({initialState = DEFAULT_SIDEBAR_VIEW_STATE, children}) => {
  const [state, setState] = React.useState(initialState);
  return children(state, setState);
};

export default TabbedSidebar;
