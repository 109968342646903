import classnames from 'classnames';
import React from 'react';
import Slider from 'react-slick';

import * as cs from './styles.styl';

// more settings available in the React-Slick docs:
//https://react-slick.neostack.com/docs/example/simple-slider
export interface SliderSettings {
  beforeChange?: (oldIndex, newIndex) => void;
  slidesToShow?: number; // count of slides visible
  slidesToScroll?: number; // count of slides scrolling at once
  accessibility?: boolean; // allow keyboard tabbing
  dots?: boolean; // show clickable progress dots
  arrows?: boolean; // show clickable arrows to progress through carousel
  className?: string;
  fade?: boolean;
  infinite?: boolean; // wrap list around at end
  autoplay?: boolean; // scroll through slides automatically
  autoplaySpeed?: number;
  pauseOnHover?: boolean;
  speed?: number;
  variableWidth?: boolean;
  responsive?: {breakpoint: number; settings: SliderSettings}[];
}

const defaultSettings: SliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  variableWidth: false,
  accessibility: true,
  dots: true, // dots that show progress through the slider
  arrows: true,
  className: cs.grayArrows, // for now, replacing arrows.
  fade: false,
  infinite: false,
  autoplay: false,
  pauseOnHover: true,
};

/** Wrapper around the react-slick component to apply some default styling to the buttons.
 *
 */
export const CarouselSlider: React.FunctionComponent<
  React.PropsWithChildren<{
    settings?: SliderSettings;
    dotSize?: 'smallDots' | 'mediumDots' | 'largeDots';
  }>
> = ({settings = {}, dotSize = 'mediumDots', children}) => {
  // We need to do some className handling based on flags
  let className = defaultSettings.className || '';
  // small is component default, medium is our default.
  if (dotSize !== 'smallDots') {
    className = classnames(className, cs[dotSize]);
  }
  // Apply defaults, then override with provided settings
  const settingsWithOverrides = {...defaultSettings, ...{className}, ...settings};
  return <Slider {...settingsWithOverrides}>{children}</Slider>;
};
