import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import I from 'immutable';
import {groupBy} from 'lodash';
import React from 'react';

import {LibraryItemDetailsModal} from 'app/components/GlobalSettings/Library/modal';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {SOURCE_DETAILS_BY_ID} from 'app/utils/constants';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import {SORT_DESC} from 'app/utils/frontendConstants';
import {ANY_TRUECOLOR_HIGH_RES, LayerInfo, NONE} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import {getOrgPrefix} from 'app/utils/organizationUtils';

import cs from './styles.styl';
import {LibraryDatasetType} from '../GlobalSettings/Library/LibraryTypes';

type MENU_CATEGORY = 'truecolor' | 'data' | 'basemap';
const MENU_CATEGORIES_IN_ORDER: MENU_CATEGORY[] = ['truecolor', 'data', 'basemap'];

/**
 * A component that renders a Blueprint MenuItem for the provided layer key.
 */
const LayerMenuItem: React.FunctionComponent<
  React.PropsWithChildren<{
    layer: LayerInfo;

    activeLayerKey: string;
    isProcessing: boolean;

    onChangeLayer: (key: string) => void;

    className?: string;
    onlyUnorderedImageryAvailable: boolean;
  }>
> = ({
  layer,
  activeLayerKey,
  isProcessing,
  onChangeLayer,
  className,
  onlyUnorderedImageryAvailable,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const {key, display} = layer;
  const isAnalysisOnlyLayer = layer.type === 'data' && layer.isNotDisplayedOnMap;
  const isSelectedLayerKey = layerUtils.isLayerKeyHighResTruecolor(activeLayerKey)
    ? ANY_TRUECOLOR_HIGH_RES
    : activeLayerKey;
  const source = layerUtils.parseLayerKey(key).sourceId;
  const isSelected = key === isSelectedLayerKey;

  const isDisabled = onlyUnorderedImageryAvailable || isAnalysisOnlyLayer;

  const text = (
    <div className={cs.menuItemText}>
      <span className={cs.menuItemTextLabel}>
        <B.Tooltip
          position="top"
          disabled={!isDisabled}
          content={
            onlyUnorderedImageryAvailable
              ? 'Order data from the left pane'
              : 'View this data in the Analysis tab'
          }
        >
          {display}
        </B.Tooltip>
      </span>

      {isProcessing && (
        <B.Tooltip content="Imagery is loading" position="top">
          <B.Tag minimal round intent="warning" icon={<B.Icon icon="time" iconSize={14} />} />
        </B.Tooltip>
      )}
    </div>
  );

  const sourceName =
    `${SOURCE_DETAILS_BY_ID[source]?.operator} ${SOURCE_DETAILS_BY_ID[source]?.name}`.trim();

  return (
    <>
      <B.MenuItem
        // Using a custom class instead of List’s
        // MENU_ITEM_WITH_LABEL_ON_HOVER_CLASS because we don’t even want to
        // display the label until it’s hovered over. This gives us a little extra
        // room to render more of the layer name.
        className={classnames(cs.displayLabelOnHover, className)}
        htmlTitle={display}
        icon={isSelected ? 'tick' : 'blank'}
        disabled={isDisabled}
        text={text}
        shouldDismissPopover={false}
        onClick={() => onChangeLayer(key)}
        labelElement={
          layer.description ? (
            <B.AnchorButton
              minimal
              icon={'info-sign'}
              onClick={(e) => {
                e.stopPropagation();
                setModalIsOpen((s) => !s);
              }}
            />
          ) : (
            <></>
          )
        }
      />
      <LibraryItemDetailsModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        actionButton={<></>}
        name={layer.shortName}
        source={sourceName}
        description={layer.description}
        geographicExtent={layer.geographicExtent}
        resolution={layerUtils.getLayerResolution(key)}
        thumbnailUrl={layer.thumbnailUrl}
        tags={layer.tags}
        license={layer.license}
        type={LibraryDatasetType.LAYER}
      />
    </>
  );
};

const LayersMenu: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    activeLayerKey: string;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;

    /** Pass null if there is more than one feature selected */
    featureData: I.List<I.ImmutableOf<ApiFeatureData>> | null;
    onChangeLayer: (key: string) => void;
    feature: I.ImmutableOf<ApiFeature>;
  }>
> = ({organization, featureCollection, featureData, activeLayerKey, onChangeLayer, feature}) => {
  const processingMetadata =
    layerUtils.getProcessingMetadataForFeatureCollection(featureCollection);
  const paidImagery = featureUtils.getPaidImagery(feature);
  const paidScenes = paidImagery?.get('scenes');

  const groupedSortedLayers = React.useMemo(() => {
    const layerKeys = layerUtils.getLayerMenuOptionsForFeatureCollection(featureCollection);

    const layers = layerKeys
      .map((key) => layerUtils.getLayer(key))
      .filter((layer) =>
        displayLayerInMenu(featureData, feature, paidScenes, layer.key, featureCollection)
      );

    return groupBy(layers, (layer) => {
      if (layer.indexCategory === 'truecolor') {
        return 'truecolor';
      } else if (layer.key === NONE) {
        return 'basemap';
      } else {
        return 'data';
      }
    }) as {
      [K in MENU_CATEGORY]?: LayerInfo[];
    };
  }, [feature, featureCollection, featureData, paidScenes]);

  const menu_categories = React.useMemo(
    () => MENU_CATEGORIES_IN_ORDER.filter((category) => !!groupedSortedLayers[category]),
    [groupedSortedLayers]
  );

  const renderLayersMenu = () => {
    return menu_categories.map((category, i) => (
      <React.Fragment key={category}>
        {groupedSortedLayers[category]
          ?.sort((layerA, layerB) => {
            // Sort high res truecolor to top of section. Otherwise alphabetical
            if (category === 'truecolor') {
              return layerA.key === ANY_TRUECOLOR_HIGH_RES ? -1 : 1;
            } else {
              return featureUtils.alphanumericSort(layerA.display, layerB.display);
            }
          })
          .map((layer) => {
            const isProcessing = layerUtils.checkLayerIsProcessingStatus(
              layer.key,
              processingMetadata
            );

            const onlyUnorderedImageryAvailable = hasOnlyUnorderedImagery(
              featureData,
              paidScenes,
              layer.key
            );
            return (
              <LayerMenuItem
                key={layer.key}
                layer={layer}
                activeLayerKey={activeLayerKey}
                isProcessing={isProcessing}
                onChangeLayer={onChangeLayer}
                onlyUnorderedImageryAvailable={onlyUnorderedImageryAvailable}
              />
            );
          })}
        {/* Add divider between each layer category */}
        {i < menu_categories.length - 1 && <B.Divider />}
      </React.Fragment>
    ));
  };

  return (
    <div className={cs.container}>
      <div className={cs.list}>
        <B.Menu>{featureData ? renderLayersMenu() : skeletonMenuItems()}</B.Menu>
      </div>
      <div className={cs.footer} style={{padding: '1rem'}}>
        <a
          href={`/${getOrgPrefix(organization)}/settings/layers`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Add or remove datasets from the Library
        </a>
      </div>
    </div>
  );
};

export const LayersLibraryExploreFooter: React.FunctionComponent<{
  organization: I.ImmutableOf<ApiOrganization>;
}> = ({organization}) => {
  return (
    <div className={cs.footer}>
      <a
        href={`/${getOrgPrefix(organization)}/settings/layers`}
        rel="noopener noreferrer"
        target="_blank"
      >
        Explore the Lens Library
      </a>
      <B.AnchorButton
        style={{marginLeft: '0.25rem'}}
        icon={'info-sign'}
        minimal
        small
        title="Adding Layers"
        href="https://support.upstream.tech/article/138-lens-library-overview#layers"
        target="_blank"
      />
    </div>
  );
};

export function displayLayerInMenu(
  featureData: I.List<I.ImmutableOf<ApiFeatureData>> | null,
  feature: I.ImmutableOf<ApiFeature>,
  scenes: I.ImmutableListOf<[string, string]> | undefined,
  layerKey: string,
  featureCollection: I.ImmutableOf<ApiFeatureCollection>
) {
  // we always want to show the Basemap
  if (layerKey === NONE) {
    return true;
  }

  //filter our featureData down to data associated with this layerKey
  const filteredFeatureData = featureUtils.filterFeatureData(
    featureData?.toList() || I.List([]),
    layerKey,
    SORT_DESC
  );

  //check whether the featureData associated to this layerKey has at least one processed image
  const hasAtLeastOneProcessedImage = filteredFeatureData.some(
    (featureDatum) =>
      !!featureDatum &&
      featureUtils.getOrderImageryState(scenes, featureDatum!, layerKey) !==
        featureUtils.ORDER_IMAGERY_STATE_AVAILABLE
  );

  const layer = layerUtils.getLayer(layerKey);

  // check whether the feature associated with this layer is outside the geographic bounds
  // where this layer is available
  const featureOutsideAvailableRegion =
    layer.polygonExtent && !featureCollectionUtils.featureInBounds(feature, layer.polygonExtent);

  // If the layer has a `filter` property value, call it to determine if
  // we should exclude or include the layer; otherwise, include it.
  const {filter: layerFilter} = layer;
  const layerGetsFiltered = layerFilter ? layerFilter(featureCollection) : true;

  return (
    layerGetsFiltered &&
    !featureOutsideAvailableRegion &&
    filteredFeatureData.size &&
    hasAtLeastOneProcessedImage
  );
}

export function hasOnlyUnorderedImagery(
  featureData: I.List<I.ImmutableOf<ApiFeatureData>> | null,
  scenes: I.ImmutableListOf<[string, string]> | undefined,
  layerKey: string
) {
  // we always want to show the Basemap
  if (layerKey === NONE) {
    return false;
  }
  //filter our featureData down to data associated with this layerKey
  const filteredFeatureData = featureData
    ? featureUtils.filterFeatureData(featureData.toList(), layerKey, SORT_DESC)
    : I.List<I.ImmutableOf<ApiFeatureData>>();

  //check whether the featureData associated to this layerKey has at least one processed image
  const hasAtLeastOneProcessedImage = filteredFeatureData.some(
    (featureDatum) =>
      !!featureDatum &&
      featureUtils.getOrderImageryState(scenes, featureDatum, layerKey) !==
        featureUtils.ORDER_IMAGERY_STATE_AVAILABLE
  );

  return !!filteredFeatureData.size && !hasAtLeastOneProcessedImage;
}

const skeletonMenuItems = () => {
  //heights taken from styles file
  return (
    <>
      <div className="bp5-skeleton" style={{height: '3rem', margin: '.2rem'}} />
      <div className="bp5-skeleton" style={{height: '3rem', margin: '.2rem'}} />
      <div className="bp5-skeleton" style={{height: '3rem', margin: '.2rem'}} />
    </>
  );
};

export default LayersMenu;
