import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import groupBy from 'lodash/groupBy';
import React, {FormEvent, Reducer} from 'react';

import AppNav from 'app/components/AppNav';
import GeometryFileBehaviorCallout from 'app/components/ManagePortfolio/GeometryFileBehvaiorCallout';
import GeometryFileFieldSelector from 'app/components/ManagePortfolio/GeometryFileFieldSelector';
import {GeometryFileUploader} from 'app/components/ManagePortfolio/GeometryFileUploader';
import {api} from 'app/modules/Remote';
import {ApiLensConvertFeatureUpdateFile} from 'app/modules/Remote/Admin';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {
  ApiOrganization,
  ApiOrganizationUser,
  getMeasurementSystem,
} from 'app/modules/Remote/Organization';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import {getAreaUnit} from 'app/modules/Remote/Organization';
import * as uploaderStyles from 'app/pages/ManageProperties/styles.styl';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {recordEvent} from 'app/tools/Analytics';
import {FEATURE_NAME, MULTIFEATURE_PART_NAME, UPLOAD_PROBLEMS} from 'app/utils/constants';
import {
  calculateAreaInUnit,
  formatDifferenceInAreaAsString,
  getDifferenceInAreaAsString,
  makeAreaDisplayString,
} from 'app/utils/geoJsonUtils';
import {isMultiLocationProperty} from 'app/utils/multiFeaturePropertyUtils';

import cs from './PropertyUpdate.styl';
import ManageFeaturesMap from '../ManageProperties/ManageFeaturesMap';
import {GroupPropertiesOptions} from '../ManageProperties/ManageFeaturesProvider';
import {BLANK_HYDRATED_FEATURE_COLLECTION} from '../ManageProperties/ManageFeaturesView';
import {MapInteractionProvider} from '../MonitorProjectView/Map';

// TODO - just importing styles from uploader for now since I imagine a lot will be the same. think
// about where to move these to share

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
  loading: boolean;
  existingFeatureCollection: HydratedFeatureCollection | null;
  portfolioName: string;
  matchedFeaturesMap: Record<number, ApiFeature> | null;
  setMatchedFeaturesMap: React.Dispatch<React.SetStateAction<Record<number, ApiFeature> | null>>;
  unmatchedFeatures: ApiFeature[] | null;
  setUnmatchedFeatures: React.Dispatch<React.SetStateAction<ApiFeature[] | null>>;
  gcsFilePath: string | null;
  setGcsFilePath: React.Dispatch<React.SetStateAction<string | null>>;
  onSuccess: () => Promise<void>;
}

namespace FilePropsReducer {
  interface GroupPropertiesAction {
    type: 'groupProperties';
    payload: GroupPropertiesOptions;
  }
  interface NameFieldAction {
    type: 'nameField';
    payload: string | null;
  }
  interface PartNameFieldAction {
    type: 'partNameField';
    payload: string | null;
  }
  interface NameValuesAction {
    type: 'nameValues';
    payload: Record<number, string>;
  }
  interface PartNameValuesAction {
    type: 'partNameValues';
    payload: Record<number, string>;
  }
  interface PrunePartNameValuesAction {
    type: 'prunePartNameValues';
    payload: number[];
  }
  interface UpdateFeaturesAction {
    type: 'updateFeatures';
    payload: {
      features: ApiFeature[];
      matchedFeatures: Record<number, ApiFeature>;
    };
  }

  export type FilePropsAction =
    | GroupPropertiesAction
    | NameFieldAction
    | PartNameFieldAction
    | NameValuesAction
    | PartNameValuesAction
    | PrunePartNameValuesAction
    | UpdateFeaturesAction;

  export interface FilePropsState {
    groupProperties: GroupPropertiesOptions;
    nameField: string;
    partNameField: string | null;
    nameValues: Record<number, string>;
    partNameValues: Record<number, string>;
    features: Record<number, ApiFeature>;
    matchedFeatures: Record<number, number>;
  }

  export const initialState: FilePropsState = {
    groupProperties: 'group',
    nameField: FEATURE_NAME,
    partNameField: null,
    nameValues: {},
    partNameValues: {},
    features: {},
    matchedFeatures: {},
  };

  export const reducer: Reducer<FilePropsState, FilePropsAction> = (
    state: FilePropsState,
    action: FilePropsAction
  ): FilePropsState => {
    switch (action.type) {
      case 'groupProperties': {
        const newGroupProperties = action.payload;
        if (newGroupProperties === 'group') {
          // multi-location case (revert names of anything that has a name values to origianl value)
          const {features, matchedFeatures, nameField} = state;
          const nameUpdates = {};
          const partNameUpdates = {};
          // Going from null or individual to group means we reset name values, and clear part name values
          Object.entries(features).forEach(([id, feature]) => {
            nameUpdates[id] = matchedFeatures[id]
              ? feature.properties[FEATURE_NAME]
              : feature.properties[nameField];
            partNameUpdates[id] = matchedFeatures[id]
              ? feature.properties[MULTIFEATURE_PART_NAME]
              : '';
          });
          return {
            ...state,
            groupProperties: action.payload,
            nameValues: nameUpdates,
            partNameValues: partNameUpdates,
          };
        } else if (newGroupProperties === 'individual') {
          // Concatenate names with partnames, clear partNameValues
          const {features, matchedFeatures, nameValues, partNameValues} = state;
          const nameUpdates = {...nameValues};
          const partNameUpdates = {...partNameValues};
          Object.entries(features).forEach(([id]) => {
            // We don't want to change name values for existing properties
            if (!matchedFeatures[id]) {
              nameUpdates[id] = `${nameValues[id]}${
                partNameValues[id] ? ` - ${partNameValues[id]}` : ''
              }`;
            }
            partNameUpdates[id] = '';
          });
          return {
            ...state,
            groupProperties: action.payload,
            nameValues: nameUpdates,
            partNameValues: partNameUpdates,
          };
        }
        return {...state, groupProperties: action.payload};
      }
      case 'updateFeatures': {
        const {nameField, partNameField} = state;
        const {features, matchedFeatures} = action.payload;

        // Invert the relationship from existing: newFeature to newFeatureId: existing id.
        // We want this because it makes "is new" checks easy.
        const newFeaturesToExistingIds = Object.fromEntries(
          Object.entries(matchedFeatures).map(([existingId, feature]) => [
            parseInt(feature.id.toString()),
            parseInt(existingId),
          ])
        );
        const featuresById = {};
        const nameValues = {};
        const partNameValues = {};
        features.forEach((feature) => {
          featuresById[feature.id] = feature;
          // If this is a feature we've matched, fall back on the copied information from the existing feature
          nameValues[feature.id] = newFeaturesToExistingIds[feature.id]
            ? feature.properties[FEATURE_NAME]
            : feature.properties[nameField];
          // Same as above, except if we dont have a partNameField, then show nothing.
          partNameValues[feature.id] = newFeaturesToExistingIds[feature.id]
            ? feature.properties[MULTIFEATURE_PART_NAME]
            : partNameField
              ? feature.properties[partNameField]
              : '';
        });
        return {
          ...state,
          nameValues,
          partNameValues,
          features: featuresById,
          matchedFeatures: newFeaturesToExistingIds,
        };
      }
      case 'nameField': {
        // Do nothing if something attempts to null this value
        if (action.payload === null) {
          return state;
        }

        const {features, matchedFeatures} = state;
        const newNameField = action.payload;
        // If the part name field has changed, alter all part names.
        const nameValues = newNameField
          ? Object.fromEntries(
              Object.values(features).map((feature) => [
                feature.id,
                String(
                  matchedFeatures[feature.id]
                    ? feature.properties[FEATURE_NAME]
                    : feature.properties[newNameField]
                ),
              ])
            )
          : {};

        // When the name field changes, we want to reset part name values, except for those
        // we've matched. We want to restore those to the standard part names we provided.
        const partNameValues = Object.fromEntries(
          Object.keys(matchedFeatures).map((id) => [
            id,
            features[id].properties[MULTIFEATURE_PART_NAME],
          ])
        );

        return {
          ...state,
          nameValues,
          partNameValues,
          nameField: action.payload,
          partNameField: null,
        };
      }
      case 'partNameField': {
        // Do nothing if something attempts to null this value
        if (action.payload === null) {
          return state;
        }
        const {features, matchedFeatures} = state;
        const newPartNameField = action.payload;
        // If the part name field has changed, alter all part names.
        const partNameValues = newPartNameField
          ? Object.fromEntries(
              Object.values(features).map((feature) => [
                feature.id,
                matchedFeatures[feature.id]
                  ? feature.properties[MULTIFEATURE_PART_NAME]
                  : feature.properties[newPartNameField],
              ])
            )
          : {};

        return {
          ...state,
          partNameValues,
          partNameField: action.payload,
        };
      }
      case 'nameValues': {
        const nameValues = state.nameValues;
        const partNameValues = state.partNameValues;
        const nameValueUpdate = action.payload;
        const id = Object.keys(nameValueUpdate)[0];
        if (id in partNameValues) {
          partNameValues[id] = '';
        }
        return {
          ...state,
          partNameValues: {...partNameValues},
          nameValues: {...nameValues, ...nameValueUpdate},
        };
      }
      case 'partNameValues': {
        const partNameValues = state.partNameValues;
        const partNameUpdates = action.payload;
        return {...state, partNameValues: {...partNameValues, ...partNameUpdates}};
      }
      case 'prunePartNameValues': {
        const partNameValues = state.partNameValues;
        const idsToKeep = action.payload;
        const newPartNameValues: FilePropsState['partNameValues'] = {};
        Object.entries(partNameValues).forEach(([id, value]) => {
          // We shouldnt need to parse id, since our part name ids are number, but
          // Object.entries doesn't let us provide type args for keys, only values.
          if (idsToKeep.includes(parseInt(id))) {
            newPartNameValues[id] = value;
          }
        });
        return {...state, partNameValues: newPartNameValues};
      }
    }
  };
}

// TODO fix this up to acommodate new features that have matching names
// on second thought... move this into the reducer?
export const getNameCollisions = (
  fieldPropsState: FilePropsReducer.FilePropsState
): [(string | number)[][], boolean] => {
  const {nameValues, partNameValues, features, matchedFeatures} = fieldPropsState;
  // Get id, name, part name tuples. We want the ids to uniquely identify features, and names to check for collisions.
  // If a property has a part name, we don't care about collisions.
  const idNamePartNameSets = Object.values(features)
    .map((nF) => [nF.id, (nameValues[nF.id] || '').trim(), partNameValues[nF.id] || ''])
    .filter(([, name]) => !!name);
  // Group pairs by name, which will group name-conflicting sets together
  const groupsByName = groupBy(idNamePartNameSets, ([, name]) => name);
  let needPartNameUpdates: (string | number)[][] = [];
  let hasMatchingNames = false;
  Object.entries(groupsByName).forEach(([, group]) => {
    // Case: multiple properties were grouped have the same name. Check partNames
    if (group.length > 1) {
      // Check if we found any new features in any matched groups
      // We short circuit here to retain this value, and ensure a later group check doesn't flip this to false again.
      hasMatchingNames = hasMatchingNames || !!group.find(([id]) => !matchedFeatures[id]);
      // The filter here removes elements that may already have part names, which wont ever conflict
      // We still want to consider that new property in conflict so that the user adds a part name
      const needUpdates = group.filter(([, , partName]) => !partName).map(([id]) => id!);
      if (needUpdates.length > 0) {
        needPartNameUpdates = [...needPartNameUpdates, needUpdates];
      }
    }
  });
  return [needPartNameUpdates, hasMatchingNames];
};

export const PropertyUpdateView: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
  loading,
  matchedFeaturesMap,
  unmatchedFeatures,
  setMatchedFeaturesMap,
  setUnmatchedFeatures,
  setGcsFilePath,
  portfolioName,
  existingFeatureCollection,
  gcsFilePath,
  onSuccess,
}) => {
  const measurementSystem = getMeasurementSystem(organization);
  const areaUnit = getAreaUnit(organization);

  const [filePropsState, filePropsDispatch] = React.useReducer(
    FilePropsReducer.reducer,
    FilePropsReducer.initialState
  );
  const [checkedFeatureIds, setCheckedFeatureIds] = React.useState<I.Set<number>>(I.Set([]));
  React.useEffect(() => {
    if (!matchedFeaturesMap) {
      return;
    }
    // Check if features have problems, otherwise add them to default checked
    // Unmatched features will be added as new features, but we should still check them for errors
    setCheckedFeatureIds(
      I.Set(
        Object.values(matchedFeaturesMap)
          .concat(unmatchedFeatures ?? [])
          .filter(
            (feature) =>
              filePropsState.nameValues[feature.id] &&
              (!feature.properties[UPLOAD_PROBLEMS] ||
                (feature.properties[UPLOAD_PROBLEMS] &&
                  feature.properties[UPLOAD_PROBLEMS].length < 1))
          )
          .map((feature) => feature.id)
      )
    );
    // TODO - are there going to be indivdual features we want to disable like in the uploader?
  }, [matchedFeaturesMap, unmatchedFeatures, setCheckedFeatureIds, filePropsState.nameValues]);

  React.useEffect(() => {
    if (matchedFeaturesMap) {
      const plainFeatures = Object.values(matchedFeaturesMap).concat(unmatchedFeatures ?? []);
      filePropsDispatch({
        type: 'updateFeatures',
        payload: {
          features: plainFeatures,
          matchedFeatures: matchedFeaturesMap,
        },
      });
    }
  }, [matchedFeaturesMap, unmatchedFeatures]);

  const updatedFeatureCollection = React.useMemo(() => {
    if (!matchedFeaturesMap) {
      return null;
    }

    const plainFeatures = Object.values(matchedFeaturesMap).concat(unmatchedFeatures ?? []);

    const features: I.List<I.ImmutableOf<ApiFeature>> = I.List(
      plainFeatures
        .map((feature) => I.fromJS(feature) as I.ImmutableOf<ApiFeature>)
        .filter((f) => checkedFeatureIds.has(Number(f.get('id'))))
    );
    return BLANK_HYDRATED_FEATURE_COLLECTION.set('features', features);
  }, [matchedFeaturesMap, unmatchedFeatures, checkedFeatureIds]);

  // Checks to see if any of the existing features we are updating are part of
  // a multi-location feature
  const includesMultiLocationProperty = React.useMemo(() => {
    if (!matchedFeaturesMap || !existingFeatureCollection) {
      return false;
    }

    const selectedExistingFeatureIds = I.Set(
      Object.entries(matchedFeaturesMap)
        .filter(([, feature]) => checkedFeatureIds.has(feature.id))
        .map(([existingFeatureId]) => existingFeatureId)
    );
    const selectedExistingFeatures = existingFeatureCollection
      .get('features')
      .filter((f) => selectedExistingFeatureIds.has(f!.get('id').toString()))
      .toList();

    return selectedExistingFeatures.some((feature) => {
      return isMultiLocationProperty(existingFeatureCollection.get('features'), feature!);
    });
  }, [matchedFeaturesMap, checkedFeatureIds, existingFeatureCollection]);

  // Used for focusing map to feature when clicked on in table
  const [selectedFeature, setSelectedFeature] = React.useState<ApiFeature | null>(null);

  const newFeatures = updatedFeatureCollection?.get('features') || I.List([]);
  const existingFeatures = existingFeatureCollection?.get('features') || I.List([]);

  // TODO - this comment is from the uploader. this is mostly true but i'm
  // not doing the safety thing to make sure the featureIds start at 1 instead
  // of 0. It's kinda a hassle to deal w so seeing if we can avoid
  // We can be confident these ids wont overlap because the new features
  // returned from the convertFile endpoint always start at 1 through
  // the number of features while the exising features are high up into the 9
  // digit range now so it's very unlikely there will be overlap.
  const allFeatures: I.ImmutableOf<ApiFeature[]> = I.List(newFeatures.concat(existingFeatures));

  const [needsPartNameUpdates, hasMatchingNames] = React.useMemo(() => {
    return getNameCollisions(filePropsState);
  }, [filePropsState]);

  return (
    <div>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      <MapInteractionProvider
        features={allFeatures}
        selectedFeatureIds={I.Set([])}
        setSelectedFeatureIds={() => null}
      >
        {({hoveredFeatureIds, hoverFeatureById}) => {
          return (
            <div className={uploaderStyles.content}>
              {loading ? (
                <B.Spinner />
              ) : (
                <>
                  <div className={uploaderStyles.form}>
                    <div className={uploaderStyles.formBody}>
                      <h2
                        className={uploaderStyles.header}
                      >{`Update properties in ${portfolioName}`}</h2>
                      <div className={uploaderStyles.formContent}>
                        {existingFeatureCollection && !matchedFeaturesMap && (
                          <div className={cs.description}>
                            <p>
                              {
                                'Upload a file to update Lens property boundaries, or do a full portfolio \
                            refresh with both updated boundaries and property additions. Any notes and \
                            imagery within the new property boundaries will persist.'
                              }
                            </p>
                            <p>
                              {
                                "We'll match the properties based on geography and you'll be able to review \
                            the changes in a table and on the map before finalizing."
                              }
                            </p>
                          </div>
                        )}
                        <PropertyUpdateFileUpload
                          matchedFeaturesMap={matchedFeaturesMap}
                          setMatchedFeaturesMap={setMatchedFeaturesMap}
                          setUnmatchedFeatures={setUnmatchedFeatures}
                          setGcsFilePath={setGcsFilePath}
                          existingFcId={existingFeatureCollection?.get('id')}
                        />
                        {unmatchedFeatures && unmatchedFeatures.length > 0 && (
                          <GeometryFileFieldSelector
                            features={unmatchedFeatures}
                            needsPartName={hasMatchingNames}
                            nameField={filePropsState.nameField}
                            partNameField={filePropsState.partNameField}
                            setNameField={(payload) =>
                              filePropsDispatch({type: 'nameField', payload})
                            }
                            setPartNameField={(payload) =>
                              filePropsDispatch({type: 'partNameField', payload})
                            }
                            mode={'upload'}
                            instructionText={
                              "Select the attribute you'd like to use for Property Name on new features"
                            }
                          />
                        )}
                        {(needsPartNameUpdates.length > 0 || hasMatchingNames) && (
                          <GeometryFileBehaviorCallout
                            groupProperties={filePropsState.groupProperties}
                            setGroupProperties={(payload) =>
                              filePropsDispatch({type: 'groupProperties', payload})
                            }
                            mode={'upload'}
                          />
                        )}
                        {existingFeatureCollection && matchedFeaturesMap && (
                          <PropertyUpdateTable
                            existingFeatureCollection={existingFeatureCollection}
                            matchedFeaturesMap={matchedFeaturesMap}
                            unmatchedFeatures={unmatchedFeatures}
                            filePropsState={filePropsState}
                            filePropsDispatch={filePropsDispatch}
                            needsPartNameUpdates={needsPartNameUpdates}
                            areaUnit={areaUnit}
                            checkedFeatureIds={checkedFeatureIds}
                            setCheckedFeatureIds={setCheckedFeatureIds}
                            setSelectedFeature={setSelectedFeature}
                            includesMultiLocationProperty={includesMultiLocationProperty}
                          />
                        )}
                      </div>
                    </div>
                    {existingFeatureCollection && matchedFeaturesMap && gcsFilePath && (
                      <PropertyUpdateFooter
                        existingFeatureCollection={existingFeatureCollection}
                        matchedFeaturesMap={matchedFeaturesMap}
                        unmatchedFeatures={unmatchedFeatures}
                        nameValues={filePropsState.nameValues}
                        partNameValues={filePropsState.partNameValues}
                        needsPartNameUpdates={needsPartNameUpdates.length > 0}
                        areaUnit={areaUnit}
                        checkedFeatureIds={checkedFeatureIds}
                        includesMultiLocationProperty={includesMultiLocationProperty}
                        gcsFilePath={gcsFilePath}
                        onSuccess={onSuccess}
                      />
                    )}
                  </div>
                  <ManageFeaturesMap
                    existingFeatureCollection={existingFeatureCollection}
                    newFeatureCollection={updatedFeatureCollection}
                    hoverFeatureById={hoverFeatureById}
                    hoveredFeatureIds={hoveredFeatureIds}
                    selectedFeature={selectedFeature}
                    measurementSystem={measurementSystem}
                    firebaseToken={null}
                    mode={'upload'}
                  />
                </>
              )}
            </div>
          );
        }}
      </MapInteractionProvider>
    </div>
  );
};

const PropertyUpdateFooter: React.FunctionComponent<
  React.PropsWithChildren<{
    matchedFeaturesMap: Record<number, ApiFeature>;
    unmatchedFeatures: ApiFeature[] | null;
    nameValues: Record<number, string>;
    partNameValues: Record<number, string>;
    needsPartNameUpdates: boolean;
    existingFeatureCollection: HydratedFeatureCollection;
    areaUnit: ApiOrganizationAreaUnit;
    checkedFeatureIds: I.Set<number>;
    includesMultiLocationProperty: boolean;
    gcsFilePath: string;
    onSuccess: () => Promise<void>;
  }>
> = ({
  matchedFeaturesMap,
  unmatchedFeatures,
  nameValues,
  partNameValues,
  needsPartNameUpdates,
  existingFeatureCollection,
  areaUnit,
  checkedFeatureIds,
  includesMultiLocationProperty,
  gcsFilePath,
  onSuccess,
}) => {
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  // TODO: make sure this state clears when you select a new file
  const [problems, setProblems] = React.useState<string[] | null>(null);

  const submitUpdates = async () => {
    try {
      setIsUpdating(true);

      const matchedUpdates = Object.fromEntries(
        Object.entries(matchedFeaturesMap)
          .map(([featureId, shapeFeature]) => [featureId, shapeFeature.id])
          .filter(([, shapeFeatureId]) =>
            checkedFeatureIds.has(
              typeof shapeFeatureId === 'string' ? parseInt(shapeFeatureId) : shapeFeatureId
            )
          )
      );

      const newFeatureIds: number[] = (unmatchedFeatures || [])
        .filter(({id}) => checkedFeatureIds.has(typeof id === 'string' ? parseInt(id) : id))
        .map((uF) => uF.id);

      // Filter out any values that are empty
      // We'd want to do this for names too, but those will be unchecked and not updated or added
      const partNameEdits = Object.fromEntries(
        Object.entries(partNameValues).filter(([, value]) => !!value)
      );

      const {problems} = (
        await api.lens.uploader.submitUpdates(
          existingFeatureCollection.get('id'),
          gcsFilePath,
          matchedUpdates,
          newFeatureIds,
          nameValues,
          partNameEdits
        )
      ).toJS();

      if (problems.length) {
        setProblems(problems);
        return;
      }
      // TODO: think if there is any metadata we want to capture here about the update
      recordEvent('Updated properties');

      // refreshes the featureCollection and directs the user to the portfolio overview page
      await onSuccess();
    } catch (e) {
      setProblems(
        (e as any)?.body?.problems || [
          (e as any)?.error?.message || 'Processing error, please try again.',
        ]
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const changeInArea = React.useMemo(() => {
    const {existingFeaturesArea, newFeaturesArea} = Object.entries(matchedFeaturesMap).reduce(
      (acc, [existingFeatureId, newFeature]) => {
        if (checkedFeatureIds.has(Number(newFeature.id))) {
          const existingFeature = existingFeatureCollection
            .get('features')
            .find((f) => f?.get('id').toString() === existingFeatureId);
          const existingFeatureArea = calculateAreaInUnit(
            existingFeature.get('geometry').toJS(),
            areaUnit
          );
          const newFeatureArea = calculateAreaInUnit(newFeature.geometry, areaUnit);

          // this should not happen since our geometries are guaranteed to be
          // polygons or multi polygons but the types don't support that
          if (!existingFeatureArea || !newFeatureArea) {
            return acc;
          }

          acc.existingFeaturesArea += existingFeatureArea;
          acc.newFeaturesArea += newFeatureArea;
        }
        return acc;
      },
      {existingFeaturesArea: 0, newFeaturesArea: 0}
    );

    return formatDifferenceInAreaAsString(newFeaturesArea - existingFeaturesArea, areaUnit);
  }, [existingFeatureCollection, checkedFeatureIds, matchedFeaturesMap, areaUnit]);

  const newArea = React.useMemo(() => {
    if (!unmatchedFeatures) {
      return [0, ''];
    }
    // calculateAreaInUnit returns null if geometry isn't a polygon or multipolygon
    // so add a shortcut here to do nothing if we got here and have something different
    const newAreaValue = unmatchedFeatures
      .filter((feature) => checkedFeatureIds.has(Number(feature.id)))
      .reduce((area, feature) => area + (calculateAreaInUnit(feature.geometry, areaUnit) ?? 0), 0);

    return formatDifferenceInAreaAsString(newAreaValue, areaUnit);
  }, [unmatchedFeatures, checkedFeatureIds, areaUnit]);

  const locationCount = checkedFeatureIds.size;
  const [updateCount, newCount] = React.useMemo(
    () => [
      I.Set(
        Object.values(matchedFeaturesMap)
          .filter((feature) => checkedFeatureIds.has(feature.id))
          .map((feature) => feature.id)
      ).size,
      I.Set(
        (unmatchedFeatures || [])
          .filter((feature) => checkedFeatureIds.has(feature.id))
          .map((feature) => feature.id)
      ).size,
    ],
    [checkedFeatureIds, matchedFeaturesMap, unmatchedFeatures]
  );

  const readyToSubmit = !!locationCount && !needsPartNameUpdates;

  return (
    <div>
      {problems && (
        <B.Callout className={uploaderStyles.callout} intent={B.Intent.DANGER} icon={null}>
          <ul>
            {problems.map((p, x) => (
              <li key={x}>{p}</li>
            ))}
          </ul>
        </B.Callout>
      )}
      <div className={uploaderStyles.uploadFooter}>
        <B.Callout
          className={uploaderStyles.callout}
          intent={readyToSubmit ? B.Intent.SUCCESS : B.Intent.NONE}
          icon={null}
        >
          <div>
            <div>
              {updateCount} {updateCount === 1 ? 'property' : 'properties'}{' '}
              {includesMultiLocationProperty &&
                `across ${locationCount} ${locationCount === 1 ? 'location' : 'locations'}`}{' '}
              will be updated resulting in a change of {changeInArea}.{' '}
            </div>
            <div>
              {newCount > 0 &&
                `${newCount} ${newCount === 1 ? 'property' : 'properties'} from your file ${
                  newCount === 1 ? 'was' : 'were'
                } not matched to existing features in your portfolio and will be added as new ${
                  newCount === 1 ? 'property' : 'properties'
                } totaling ${newArea}.`}
            </div>
          </div>
          {/* We don't have any tooltip info to display here but including this allows for
          the styling to be shared completely with the uploader */}
          <B.Tooltip>
            <B.AnchorButton
              className={uploaderStyles.uploadButton}
              text="Confirm"
              intent={readyToSubmit ? B.Intent.SUCCESS : B.Intent.NONE}
              disabled={!readyToSubmit}
              onClick={submitUpdates}
              loading={isUpdating}
            />
          </B.Tooltip>
        </B.Callout>
      </div>
    </div>
  );
};

const MutableTableCell: React.FunctionComponent<
  React.PropsWithChildren<{
    id: number;
    value: string;
    setValue: (pair: Record<number, string>) => void;
  }>
> = ({id, value, setValue}) => (
  <div className={uploaderStyles.nameCell}>
    <B.InputGroup
      type="text"
      className={uploaderStyles.nameInput}
      value={value}
      onChange={(event: FormEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget as HTMLInputElement;
        setValue({[id]: value});
      }}
    />
  </div>
);

const PropertyUpdateTable: React.FunctionComponent<
  React.PropsWithChildren<{
    matchedFeaturesMap: Record<number, ApiFeature>;
    unmatchedFeatures: ApiFeature[] | null;
    filePropsState: FilePropsReducer.FilePropsState;
    filePropsDispatch: (action: FilePropsReducer.FilePropsAction) => void;
    needsPartNameUpdates: (string | number)[][];
    existingFeatureCollection: HydratedFeatureCollection;
    areaUnit: ApiOrganizationAreaUnit;
    setCheckedFeatureIds: React.Dispatch<React.SetStateAction<I.Set<number>>>;
    checkedFeatureIds: I.Set<number>;
    setSelectedFeature: React.Dispatch<React.SetStateAction<ApiFeature | null>>;
    includesMultiLocationProperty: boolean;
  }>
> = ({
  matchedFeaturesMap,
  unmatchedFeatures,
  filePropsState,
  filePropsDispatch,
  needsPartNameUpdates,
  existingFeatureCollection,
  areaUnit,
  checkedFeatureIds,
  setCheckedFeatureIds,
  setSelectedFeature,
  includesMultiLocationProperty,
}) => {
  const allFeaturesSelected =
    checkedFeatureIds.size ===
    Object.entries(matchedFeaturesMap).length + (unmatchedFeatures ?? []).length;

  const hasProblems = Object.values(matchedFeaturesMap)
    .concat(unmatchedFeatures ?? [])
    .some((feature) => !!feature.properties[UPLOAD_PROBLEMS]);

  const {partNameValues} = filePropsState;
  // If we have an exsiting multi-location property, need part names, or have part names
  const shouldShowLocationColumn =
    includesMultiLocationProperty ||
    needsPartNameUpdates.length > 0 ||
    Object.values(partNameValues).some((v) => !!v);

  const hasNew = unmatchedFeatures && unmatchedFeatures.length > 0;
  const needsUpdatesFlat = needsPartNameUpdates.flat();

  return (
    <div className={cs.tableWrapper}>
      <table className={classnames(uploaderStyles.table, cs.table)}>
        <thead>
          <tr>
            <th>
              <B.Checkbox
                className={uploaderStyles.checkbox}
                checked={allFeaturesSelected}
                onChange={() => {
                  const {nameValues} = filePropsState;
                  // if all are selected, unselect all. otherwise select all
                  if (allFeaturesSelected) {
                    setCheckedFeatureIds(I.Set([]));
                  } else {
                    // Select all except those that dont have name values
                    setCheckedFeatureIds(
                      I.Set(
                        Object.entries(matchedFeaturesMap)
                          .map(([, feature]) => feature.id)
                          .concat((unmatchedFeatures ?? []).map((uF) => uF.id))
                          .filter((id) => !!nameValues[id])
                      )
                    );
                  }
                }}
              />
            </th>
            {(hasProblems || hasNew) && <th></th>}
            <th>Property Name</th>
            {shouldShowLocationColumn && <th>Location Name</th>}
            <th>Updated Area</th>
            <th>Change in Area</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(matchedFeaturesMap)
            .concat(unmatchedFeatures?.map((uF) => ['', uF]) ?? [])
            .map(([key, feature]) => {
              let existingFeature: I.ImmutableOf<ApiFeature> | null = null;
              if (key) {
                existingFeature = existingFeatureCollection
                  .get('features')
                  .find((f) => f?.get('id').toString() === key);
              }
              const newFeatureArea = makeAreaDisplayString(feature.geometry, areaUnit);
              const difference = existingFeature
                ? getDifferenceInAreaAsString(
                    existingFeature.get('geometry').toJS(),
                    feature.geometry,
                    areaUnit
                  )
                : newFeatureArea;

              const {nameValues, partNameValues} = filePropsState;
              const featureId = feature.id;
              const featureChecked = checkedFeatureIds.has(featureId);

              // For existing features we'll always use these fields.
              let featureName = feature.properties.name;
              let featurePartName = partNameValues[featureId];

              const isNew = !key;
              const needsPartUpdates = needsUpdatesFlat.includes(featureId);
              const hasPartNameUpdates = !!partNameValues[featureId];

              // If the property is new, check if we have write in values and use those.
              // Otherwise, use the key that key field name selection gave us.
              if (isNew) {
                featureName = nameValues[featureId];
                featurePartName =
                  needsPartUpdates || hasPartNameUpdates ? partNameValues[featureId] : '';
              }

              const problems = feature.properties[UPLOAD_PROBLEMS] && (
                <>
                  {feature.properties[UPLOAD_PROBLEMS].map((problem, i) => (
                    <div key={i}>{problem}</div>
                  ))}
                </>
              );

              return (
                <tr
                  key={featureId}
                  onClick={() => {
                    if (checkedFeatureIds.has(featureId)) {
                      setSelectedFeature({...feature});
                    }
                  }}
                >
                  <td>
                    <B.Checkbox
                      className={uploaderStyles.checkbox}
                      checked={featureChecked && !problems}
                      disabled={!!problems || !nameValues[featureId]}
                      onChange={() => {
                        if (featureChecked) {
                          setCheckedFeatureIds(checkedFeatureIds.delete(featureId));
                        } else {
                          setCheckedFeatureIds(checkedFeatureIds.add(featureId));
                        }
                      }}
                    />
                  </td>
                  {(hasProblems || hasNew) && (
                    <td>
                      {problems && (
                        <B.Tooltip content={problems} position={'bottom-right'}>
                          <span>
                            <B.Icon intent={B.Intent.DANGER} icon="cross" size={16} />
                          </span>
                        </B.Tooltip>
                      )}
                      {isNew && (
                        <B.Tooltip content={'New Property'} position={'bottom-right'}>
                          <span>
                            <B.Icon intent={B.Intent.SUCCESS} icon="plus" size={16} />
                          </span>
                        </B.Tooltip>
                      )}
                    </td>
                  )}
                  <td>
                    {isNew ? (
                      <MutableTableCell
                        id={feature.id}
                        value={featureName}
                        setValue={(payload) => {
                          filePropsDispatch({type: 'nameValues', payload});
                        }}
                      />
                    ) : (
                      featureName
                    )}
                  </td>
                  {shouldShowLocationColumn && (
                    <td>
                      {needsPartUpdates || hasPartNameUpdates ? (
                        <MutableTableCell
                          id={feature.id}
                          value={featurePartName || ''}
                          setValue={(payload) => {
                            filePropsDispatch({type: 'partNameValues', payload});
                          }}
                        />
                      ) : (
                        feature.properties.multiFeaturePartName
                      )}
                    </td>
                  )}
                  <td>{newFeatureArea}</td>
                  <td>{difference}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const PropertyUpdateFileUpload: React.FunctionComponent<
  React.PropsWithChildren<{
    matchedFeaturesMap: Record<number, ApiFeature> | null;
    setMatchedFeaturesMap: React.Dispatch<React.SetStateAction<Record<number, ApiFeature> | null>>;
    setUnmatchedFeatures: React.Dispatch<React.SetStateAction<ApiFeature[] | null>>;
    setGcsFilePath: React.Dispatch<React.SetStateAction<string | null>>;
    existingFcId: number | undefined;
    nameProp?: string;
  }>
> = ({
  matchedFeaturesMap,
  setMatchedFeaturesMap,
  setUnmatchedFeatures,
  setGcsFilePath,
  existingFcId,
}) => {
  const [conversionErrors, setConversionErrors] = React.useState<null | string[]>(null);
  const [isConvertingFile, setIsConvertingFile] = React.useState<boolean>(false);
  const [filename, setFilename] = React.useState<string | null>(null);

  const convertFile = async (file: File) => {
    try {
      // reset state when a new file is selected
      setConversionErrors(null);
      setIsConvertingFile(true);
      setMatchedFeaturesMap(null);
      setUnmatchedFeatures(null);
      setGcsFilePath(null);

      if (!existingFcId) {
        // TODO - see if we can put this check earlier
        throw new Error('featureCollection required to update properties');
      }

      const {
        featureIdToShapefileFeatures,
        unmatchedShapefileFeatures,
        problems,
        gcsGisFilePath,
      }: ApiLensConvertFeatureUpdateFile = (
        await api.lens.uploader.convertUpdateFile(file, existingFcId)
      ).toJS();

      if (problems.length) {
        setConversionErrors(problems);
        return;
      }

      if (!Object.keys(featureIdToShapefileFeatures).length) {
        setConversionErrors(['No matched features were found in this file']);
        return;
      }

      setMatchedFeaturesMap(featureIdToShapefileFeatures);
      setUnmatchedFeatures(unmatchedShapefileFeatures);
      setGcsFilePath(gcsGisFilePath);
    } catch (e) {
      setConversionErrors(
        //errors caught here could either be intentionally handled problems, or unexpected exceptions
        //hence this type vagueness on what error is coming back from the API
        (e as any)?.body?.problems || [
          (e as any)?.error?.message || 'Processing error, please try again.',
        ]
      );
      // We are displaying these problems to the user and not letting them proceed onwards. Not logging
      // these errors in Sentry because it may just become noise and displaying an error here
      // is things working as intended.
    } finally {
      setIsConvertingFile(false);
    }
  };

  return (
    <GeometryFileUploader
      conversionErrors={conversionErrors}
      isConvertingFile={isConvertingFile}
      onFilenameChange={(file) => {
        setFilename(file.name);
        setMatchedFeaturesMap(null);
        setUnmatchedFeatures(null);
        setConversionErrors(null);
        convertFile(file);
      }}
      hasSelection={!!matchedFeaturesMap}
      filename={filename}
      supportDocUrl="https://support.upstream.tech/article/147-preparing-geospatial-data-for-lens"
      title={'Upload your property updates'}
    />
  );
};
