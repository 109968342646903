import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import * as featureUtils from 'app/utils/featureUtils';
import {useContinuity} from 'app/utils/hookUtils';
import * as mapUtils from 'app/utils/mapUtils';

import MapContent from './MapContent';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;

  featureData: I.ImmutableOf<ApiFeatureData[]> | null;
  imageRefs: mapUtils.MapImageRefs;

  organization: I.ImmutableOf<ApiOrganization>;
  featureCollection?: HydratedFeatureCollection;
  filterDuplicates?: boolean;
}

const Attribution: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  featureData,
  imageRefs,
  organization,
  featureCollection,
  filterDuplicates = false,
}) => {
  // Some places create a new `I.Set([cursor])` when rendering this component.
  // Because searching in FeatureData is O(n) we decide to err on this check to
  // avoid re-calculating attributionText every render.
  const consistentRefs = useContinuity(imageRefs);

  const attributionText = React.useMemo(() => {
    if (!consistentRefs || !consistentRefs.length || !featureData || !featureData.size) {
      return '';
    }

    let attributions = consistentRefs
      .map(({layerKey, cursor}) => {
        const cursorKey = featureUtils.getCursorKeyForLayerKey(featureCollection, layerKey);
        const featureDataForCursor = featureData.find(
          (d) => d!.get('types').includes(layerKey) && d!.get(cursorKey) === cursor
        );

        if (!featureDataForCursor) {
          return;
        }

        const {sourceDetails} = featureUtils.imageAndSourceDetails(
          featureDataForCursor,
          layerKey,
          organization
        );

        if (!sourceDetails.copyright_template) {
          return;
        }

        // A few hacks here. We can’t type .get(cursorKey) because we don’t know
        // statically what field cursorKey is accessing (it’s date. they’re all
        // date.). Also, this use of parseInt is relying on the cursor being an
        // ISO8601 date string, which leads off with a 4-digit year. Maybe a
        // little goofy, but it works.
        const featureDataYear = parseInt(featureDataForCursor.get(cursorKey) as string);
        return featureUtils.getAttribution(sourceDetails, featureDataYear, 'link-license');
      })
      .filter((a) => !!a);

    if (filterDuplicates) {
      // Filter out duplicate attributions so the text isn't as long
      attributions = attributions.filter(
        (attr, index, arr) => !!attr && arr.indexOf(attr) === index
      );
    }
    return attributions.join(', ');
  }, [featureData, consistentRefs, featureCollection, organization, filterDuplicates]);

  // Hack! In order to keep the attribution control in a consistent place, we
  // need to make it be a consistent object. So, we stash options separately and
  // mutate it as the attribution changes. mapboxgl has no API for explicitly
  // changing the "customAttribution" value.
  const optionsRef = React.useRef({
    customAttribution: attributionText,
    compact: false,
  });

  // Because the things that cause us to change the attribution are the same
  // things that cause the control to "update" (i.e. re-render) itself, we
  // don’t, in practice, need to trigger an update to make this new attribution
  // visible in the control.
  optionsRef.current.customAttribution = attributionText;

  const controlRef = React.useRef(new mapboxgl.AttributionControl(optionsRef.current));

  return (
    <MapContent
      map={map}
      isMapLoaded={isMapLoaded}
      controls={[controlRef.current]}
      controlPosition="bottom-right"
    />
  );
};

export default Attribution;
