import React from 'react';

/**
 * Class that manages a map of “ready states” that are tokens associated with
 * whether or not they’re ready. The entire reporter is “ready” if all of the
 * currently vended tokens have reported themselves as “ready.”
 *
 * Used in ReportExport so that individual Leaflet maps can tell the parent
 * whether or not they’re fully loaded, so that the print button can be
 * enabled/disabled.
 *
 * Built so that the parent does not need to know a priori how many maps it’s
 * waiting for readiness for.
 */
export class ReadyStateReporter {
  private isReadyByDefault: boolean;
  private setIsReady: React.Dispatch<React.SetStateAction<boolean>>;

  private readyMap = new Map<number, boolean>();
  private nextToken = 0;

  /**
   * @param setIsReady A callback (probably tied to a useState setter in
   * useReadyState) that this object can use to indicate to a parent its overall
   * readiness.
   * @param isReadyByDefault If true, should consider itself “ready” even if no
   * tokens have been vended, or if a token has been vended but not reported on.
   */
  constructor(
    setIsReady: React.Dispatch<React.SetStateAction<boolean>>,
    isReadyByDefault: boolean
  ) {
    this.isReadyByDefault = isReadyByDefault;
    this.setIsReady = setIsReady;
  }

  /**
   * Returns a token that a child component can (typically with
   * useReadyStateReporter) use to report on whether or not it’s ready.
   *
   * Calls to newToken should be balanced by a disposeToken call on unmount.
   */
  public newToken() {
    const key = this.nextToken++;

    this.readyMap.set(key, this.isReadyByDefault);
    this.sync();

    return key;
  }

  /**
   * Indicates that a token previously acquired by newToken is no longer
   * relevant, probably because it was used by a component that has unmounted.
   */
  public disposeToken(token: number) {
    this.readyMap.delete(token);
    this.sync();
  }

  public setTokenIsReady(token: number, isReady: boolean) {
    const current = this.readyMap.get(token);

    // Filter out both no-ops and cases where the token has been disposed.
    if (current === undefined || current === isReady) {
      return;
    }

    this.readyMap.set(token, isReady);
    this.sync();
  }

  private sync() {
    if (this.readyMap.size === 0) {
      this.setIsReady(this.isReadyByDefault);
    } else {
      let allReady = true;

      this.readyMap.forEach((r) => {
        allReady = allReady && r;
      });

      this.setIsReady(allReady);
    }
  }
}

/**
 * Hook to set up a "ReadyStateReporter" that can be used with
 * useReadyStateReporter. This lets children report up to a parent about if
 * they’re "ready" in some way. Part of the goal is to be flexible so that the
 * parent doesn’t need to know how many children it should be monitoring, and
 * those children can come and go dynamically.
 *
 * Parents should use this hook, and pass the 2nd return value to children, who
 * will use the useReadyStateReporter hook to report on their status.
 */
export function useReadyState(isReadyByDefault = false): [boolean, ReadyStateReporter] {
  const [isReady, setIsReady] = React.useState(isReadyByDefault);

  // useRef here because useMemo is not guaranteed to persist. We don’t care
  // that this is creating unnecessary objects on rerenders.
  const readyState = React.useRef<ReadyStateReporter>(
    new ReadyStateReporter(setIsReady, isReadyByDefault)
  );

  return [isReady, readyState.current];
}

/**
 * Used by child components to report their state up to a parent that's using
 * useReadyState. Returns a function of one argument: the child’s readiness.
 *
 * Because the returned function mutates state, it should not be called
 * synchronously during a render.
 */
export function useReadyStateReporter(readyState: ReadyStateReporter) {
  const tokenRef = React.useRef<number>();

  // In useLayoutEffect since newToken mutates state, so we can’t have it in the
  // render path.
  React.useLayoutEffect(() => {
    const token = readyState.newToken();
    tokenRef.current = token;

    return () => {
      readyState.disposeToken(token);
      tokenRef.current = undefined;
    };
  }, [readyState]);

  return React.useCallback(
    (isReady: boolean) => {
      if (tokenRef.current === undefined) {
        console.warn(
          'WARNING: useReadyStateReporter reported a ready state before token was generated'
        );
      } else {
        readyState.setTokenIsReady(tokenRef.current, isReady);
      }
    },
    [readyState]
  );
}
