import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';
import {Link} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import LensLogo from 'app/components/Logo/LensLogo';
import UserAvatar from 'app/components/UserAvatar/UserAvatar';
import {WithActiveUsers} from 'app/modules/Realtime/ActiveUsersProvider';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {WithOrgUsers} from 'app/providers/OrgUsersProvider';
import {openHubspotWidget} from 'app/tools/Hubspot';
import * as CONSTANTS from 'app/utils/constants';
import {LENS_TIER_SETTINGS, getOrgPrefix} from 'app/utils/organizationUtils';
import * as routeUtils from 'app/utils/routeUtils';
import * as userUtils from 'app/utils/userUtils';

import {Onboarding} from './Onboarding';
import cs from './styles.styl';

export const AppNavToasterContext = React.createContext<B.ToasterInstance | null>(null);

export interface Props {
  history: History;

  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.ImmutableOf<ApiOrganization>;
  loggedInUserActions: LoggedInUserActions;

  selectedProject: I.ImmutableOf<ApiProject> | null;
  isMinimal?: boolean;
  showHotkeys?: boolean;
  logoComponent?: React.ReactChild;

  openMenuForTest?: boolean;
}

const AppNav: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  selectedProject,
  children,
  isMinimal,
  logoComponent,
  openMenuForTest,
  loggedInUserActions,
  showHotkeys,
}) => {
  const [toaster, setToaster] = React.useState<B.ToasterInstance | null>(null);
  const [isOptionClick, setIsOptionClick] = React.useState(false);
  const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));

  return (
    <AppNavToasterContext.Provider value={toaster}>
      <nav className={classnames(cs.appNav)}>
        <div className={cs.content}>
          <div className={cs.left}>
            <Link to={`/${orgIdPrefix}/projects`}>
              {logoComponent || <LensLogo noText={isMinimal} style={{height: '2.4rem'}} />}
            </Link>

            {children}
          </div>

          {!children
            ? (() => {
                const isManageTeamPage = location.pathname === '/admin/team';

                let features: React.ReactNode[] = [];
                let name: string;

                if (selectedProject && !isManageTeamPage) {
                  name = selectedProject.get('name');

                  features = features.concat([
                    <li key="home">
                      <Link to={`/${orgIdPrefix}/projects`} style={{display: 'flex'}}>
                        <B.Icon icon="home" iconSize={18} />
                      </Link>
                    </li>,
                    <li key="project">
                      <B.Icon icon="chevron-right" iconSize={14} className={cs.chevronIcon} />

                      <Link
                        to={routeUtils.makeProjectDashboardUrl(organization, selectedProject)}
                        title={name}
                      >
                        {name}
                      </Link>
                    </li>,
                  ]);
                }

                return <ul className={cs.breadcrumbs}>{features}</ul>;
              })()
            : null}

          <div className={classnames(cs.users)}>
            <WithOrgUsers>
              {(organizationUsers) => (
                <WithActiveUsers>
                  {(activeUserEmails) => (
                    <TransitionGroup className={cs.realtimeUsers}>
                      {organizationUsers &&
                        organizationUsers
                          .filter(
                            (user) =>
                              user.email != profile.get('email') &&
                              activeUserEmails.includes(user!.email)
                          )
                          .map((user, i) => (
                            <CSSTransition
                              classNames={{
                                enter: cs.userEnter,
                                enterActive: cs.userEnterActive,
                                exit: cs.userLeave,
                                exitActive: cs.userLeaveActive,
                              }}
                              timeout={250}
                              key={i}
                            >
                              <UserAvatar isTooltipEnabled={true} profile={user} />
                            </CSSTransition>
                          ))}
                    </TransitionGroup>
                  )}
                </WithActiveUsers>
              )}
            </WithOrgUsers>

            {/* conditionally renders onboarding popover depending on user settings */}
            <Onboarding
              profile={profile}
              organization={organization}
              loggedInUserActions={loggedInUserActions}
            />

            <B.Popover
              isOpen={openMenuForTest}
              className={cs.helpMenuPopover}
              content={(() => {
                return (
                  <B.Menu>
                    <B.MenuItem
                      icon="key-command"
                      disabled={!showHotkeys}
                      htmlTitle={!showHotkeys ? 'No shortcuts available' : ''}
                      text="Keyboard Shortcuts"
                      onClick={() => {
                        document.dispatchEvent(
                          new KeyboardEvent('keydown', {
                            key: '?',
                            shiftKey: true,
                          })
                        );
                      }}
                    />

                    {
                      <B.MenuItem
                        icon="lightbulb"
                        text="Lens Suggestions"
                        href="https://form.asana.com/?k=JOLtmP1IMAJHYWgWcoHmZg&d=253187955686276"
                        target="_blank"
                      />
                    }

                    <B.MenuItem
                      icon="info-sign"
                      text="Knowledge Base"
                      href="https://support.upstream.tech"
                      target="_blank"
                    />

                    <B.MenuItem
                      icon="learning"
                      text="Schedule Team Training"
                      href="https://calendar.app.google/rutgwPtVXg6a3rVK6"
                      target="_blank"
                    />

                    <B.MenuItem icon="help" text="Support" onClick={() => openHubspotWidget()} />
                  </B.Menu>
                );
              })()}
              interactionKind="click"
              position={B.Position.BOTTOM_LEFT}
            >
              <div className={cs.helpMenu}>
                <span>Help</span>
                <B.Icon className={cs.buttonIconSpacing} icon="chevron-down" />
              </div>
            </B.Popover>
            <B.Popover
              isOpen={openMenuForTest}
              className={cs.popoverTarget}
              content={(() => {
                let lensTierItem: JSX.Element | null = null;

                const lensTier = organization.getIn(['tiers', 'Lens']);

                if (lensTier) {
                  lensTierItem = (
                    <>
                      <div className={classnames('bp5-menu-item', cs.productTier)}>
                        <div className="bp5-fill">
                          <div className={cs.productTierLabel}>{organization.get('name')}</div>
                          <div className={cs.productTierName}>
                            {LENS_TIER_SETTINGS[lensTier].name}
                          </div>
                        </div>

                        <div className="bp5-menu-item-label">
                          <B.AnchorButton
                            minimal
                            small
                            title="About subscription plans"
                            icon="info-sign"
                            href="https://support.upstream.tech/article/117-checking-your-subscription"
                            target="_blank"
                          />
                        </div>
                      </div>

                      <B.MenuDivider />
                    </>
                  );
                }

                const showInternalToolsLink = userUtils.showInternalTools(
                  profile,
                  organization,
                  isOptionClick
                );

                const childOrgSelected = profile.get('organizationKey') !== organization.get('id');
                const belongsToMultipleOrgs = profile.get('organizations').size > 1;
                const selectedOrgHasChildren = !organization.get('childOrganizations').isEmpty();
                const isInternalOrg =
                  profile.get('organizationKey') === CONSTANTS.INTERNAL_ORGANIZATION_ID;

                return (
                  <B.Menu>
                    {lensTierItem}

                    {/*
                       If the current org doesn’t match the user’s profile org, then they can switch.
                       There's a special case for users logged into the internal Upstream org here because
                       when it's selected from the org switcher it won't have its childOrganizations expanded (that happens for the
                       profileOrganization in useOrganization, but the internal org is also included as a child org so it can be selected)
                       and the profile & selected org ids will match.
                    */}
                    {/**TODO(eva): with the redirects to /welcome for onboarding slides, users in their very first session are unable to
                     * navigate to /organizations. fix that!
                     */}
                    {(belongsToMultipleOrgs ||
                      childOrgSelected ||
                      selectedOrgHasChildren ||
                      isInternalOrg) && (
                      <>
                        <B.MenuItem
                          icon="home"
                          text="Switch Organization"
                          onClick={() => {
                            loggedInUserActions.resetCurrentOrganizationToProfileOrganization();
                            history.push('/organizations');
                          }}
                        />
                      </>
                    )}

                    <B.MenuItem
                      icon="cog"
                      text="Settings"
                      onClick={() => {
                        history.push(`/${getOrgPrefix(organization)}/settings/organization`);
                      }}
                    />

                    {showInternalToolsLink && (
                      <B.MenuItem
                        icon="wrench"
                        text="Internal Tools"
                        onClick={() => history.push('/internal-tools')}
                      />
                    )}

                    <B.MenuDivider />

                    <B.MenuItem
                      icon="log-out"
                      text="Sign Out"
                      onClick={() => {
                        history.push('/signout');
                      }}
                    />
                  </B.Menu>
                );
              })()}
              interactionKind="click"
              position={B.Position.BOTTOM_LEFT}
            >
              <div className={cs.currentUser} onClick={(ev) => setIsOptionClick(ev.altKey)}>
                <UserAvatar profile={profile.toJS()} />

                <div className={cs.currentUserName}>{profile.get('name')}</div>

                <B.Icon className={cs.buttonIconSpacing} icon="chevron-down" />
              </div>
            </B.Popover>
          </div>
        </div>
      </nav>

      {/* We don’t use a portal so we can control that this appears right under the navbar */}
      <div className={cs.toasterContainer}>
        <B.OverlayToaster
          ref={(newToaster) => setToaster(newToaster)}
          usePortal={false}
          className={cs.toaster}
        />
      </div>
    </AppNavToasterContext.Provider>
  );
};

export default AppNav;
