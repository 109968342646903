import {kinks} from '@turf/turf';
import * as geojson from 'geojson';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React, {useMemo} from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  GeometryOverlaySetting,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {GeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import * as CONSTANTS from 'app/utils/constants';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import {compileGeometry, getPolygonGeometryTypesFrom} from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as hookUtils from 'app/utils/hookUtils';
import {
  FeatureCollectionSourceRecord,
  getSourceForFeatureCollection,
  useClickableFeatures,
  useFilledPolygonLayer,
} from 'app/utils/mapUtils';
import loadTiledFeatureCollectionAsGeoJSON from 'app/utils/mapUtils/loadTileAsGeoJSON';

import cs from './GeometryOverlaysContent.styl';
import MapContent from './MapContent';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;
  overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
  geoJsonFeaturesLoader: GeoJsonFeaturesLoader;
  isOverlayVisibleByName: Record<string, boolean>;
  overlaySettings: GeometryOverlaySetting[];
  openAnalyzePolygonPopup: (f: geojson.Feature<geojson.Polygon | geojson.MultiPolygon>) => void;
  /** Click handlers keyed by the feature collection’s product */
  clickHandlers?: Record<
    string,
    undefined | ((f: I.ImmutableOf<ApiFeature>, fc: HydratedFeatureCollection) => void)
  >;
  readyToSelectOverlayPolygon?: boolean;
}

const USER_LAYERS_POPUP = new mapboxgl.Popup({
  className: cs.popup,
  closeButton: false,
  closeOnClick: false,
  offset: 5,
});

const handleMouseMoveFill = (
  featureCollection: HydratedFeatureCollection,
  event: mapboxgl.MapLayerMouseEvent,
  isPopupFrozen?: boolean
) => {
  if (!event.features?.length) return;

  let htmlContent = '';

  const fcKey =
    featureCollection.get('product') === CONSTANTS.PRODUCT_REGRID
      ? 'regrid'
      : featureCollection.get('id');

  // Defne handlers for the specific overlay type
  switch (fcKey) {
    case 'regrid':
      htmlContent = `<ul>
        <li>Parcel ID: ${event.features[0].properties?.parcelnumb}</li>
        <li>Owner: ${event.features[0].properties?.owner || 'Unknown'}</li>
        <li>Address: ${formatRegridAddress(event.features[0].properties)}</li>
      </ul>`;
      break;

    case CONSTANTS.INDIGENOUS_TERRITORIES_FEATURE_COLLECTION_ID: {
      const territories: string[] = event.features
        ? event.features.map(
            (f) =>
              `<li><a href=${f.properties?.description} target="_blank" rel="noopener noreferrer">${
                f.properties!.name
              }</a></li>`
          )
        : [``];
      const uniqueTerritories = Array.from(new Set(territories));
      htmlContent = `<strong>${featureCollection.get('name')}</strong>
                     <ul>${uniqueTerritories.join('')}</ul>`;
      break;
    }

    case CONSTANTS.HR_FLOWLINES_FEATURE_COLLECTION_ID: {
      const riverNames = Array.from(
        new Set(
          event.features
            .filter((f) => !!f.properties?.gnis_name)
            .map((f) => f.properties!.gnis_name)
        )
      ).join(', ');

      if (!riverNames) return;

      htmlContent = `<strong>${featureCollection.get('name')}</strong><p>${riverNames}</p>`;
      break;
    }

    case CONSTANTS.EPA_SUPERFUND_SITES_FEATURE_COLLECTION_ID:
      htmlContent = `<strong>${featureCollection.get('name')}</strong>
                     <p>${event.features[0].properties?.SITE_NAME}</p>
                     <a href=${
                       event.features[0].properties?.URL_ALIAS_TXT
                     } target='_blank'>Read more here...</a>`;
      break;

    default:
      // If we're here, there's no match on a specific handler so we'll just display the name
      if (event.features[0].properties?.name) {
        htmlContent = `<strong>${featureCollection.get('name')}</strong><p>${
          event.features[0].properties.name
        }</p>`;
      } else {
        // No content to display
        return;
      }
  }

  // Add info about click to pin
  htmlContent += `<p class=${cs.popupHelper}>Click to ${isPopupFrozen ? 'unpin' : 'pin'}</p>`;

  return USER_LAYERS_POPUP.setLngLat(event.lngLat).setHTML(htmlContent).addTo(event.target);
};

interface OverlayIdToTileOverlayFCs {
  id: number;
  featureCollection: geojson.FeatureCollection | null;
}

const GeometryOverlaysContent: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  selectedFeatures,
  overlayFeatureCollections,
  geoJsonFeaturesLoader,
  isOverlayVisibleByName,
  overlaySettings,
  clickHandlers = {},
  openAnalyzePolygonPopup,
  readyToSelectOverlayPolygon = false,
}) => {
  const isAKeyPressed = hookUtils.useKeyPress('KeyA');
  const [tileOverlayFeatureCollections, setTileOverlayFeatureCollections] =
    React.useState<OverlayIdToTileOverlayFCs | null>(null);

  React.useEffect(() => {
    let shouldStillFetch = true;
    // Fetch tiles for overlay features that are enabled.
    // Don't fetch tiles for ones that arent, since they cant be used to select.
    overlayFeatureCollections
      .filter((fc) => !!isOverlayVisibleByName[fc!.get('name')])
      .forEach(fetchTileOverlayFeatureCollections);
    // Don't set anything if the component is being cleaned up.
    // TODO(anthony): Consider an abort controller if this operation gains mass.
    return () => {
      shouldStillFetch = false;
    };

    async function fetchTileOverlayFeatureCollections(
      ofc: I.ImmutableOf<ApiFeatureCollection> | undefined
    ) {
      // Guard against immutable nonsense about collection elements being nullable or optional.
      if (!ofc || selectedFeatures.size === 0) {
        return;
      }
      const ofcId: number = ofc.get('id');
      const selectedFeatureGeometry = selectedFeatures.first().get('geometry').toJS();
      // maxZoom: 10 here represents the zoom level we want to fetch tiles at.
      const tileFeatureCollection: geojson.FeatureCollection | null =
        await loadTiledFeatureCollectionAsGeoJSON(ofc, selectedFeatureGeometry, {maxZoom: 10});
      /**
       * The features we get back from this come from the intersection of tiles and feature geometry. In
       * some cases we get back many features that don't intersect the property that don't have unique ids
       * (ex: Building Envelopes). Since we use the id to select the correct tiled features on user click,
       * we want to filter out any gometries outside the property that have non-unique ids before hand. We
       * are doing this premtively instead of upon user click because the intersect is an expensive opperation
       * and can cause a delay between the user click and the shape getting selected on the map. In the case of
       * really large & complicated overlays it can cause the app to hang
       */
      if (tileFeatureCollection) {
        tileFeatureCollection.features = tileFeatureCollection.features.filter((f) => {
          try {
            return (
              (f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon') &&
              geoJsonUtils.intersectGeometries(
                selectedFeatureGeometry,
                f.geometry as geojson.Polygon | geojson.MultiPolygon
              )
            );
          } catch {
            // Ignore errors and filter out the features that caused them
            return false;
          }
        });
      }
      if (tileFeatureCollection && tileFeatureCollection.features.length > 0 && shouldStillFetch) {
        setTileOverlayFeatureCollections(
          (prev) =>
            ({
              ...prev,
              [ofcId]: tileFeatureCollection,
            }) as OverlayIdToTileOverlayFCs
        );
      }
    }
    /**
     * Note(anthony): loadTiledFeatureCollectionAsGeoJSON makes requests, so
     * if these values change it'll affect the number of requests to the api.
     */
  }, [selectedFeatures, isOverlayVisibleByName, overlayFeatureCollections]);
  const maybeOpenAnalyzePolygonPopup = React.useCallback(
    async (
      overlayFeature: I.ImmutableOf<ApiFeature>,
      overlayFeatureCollection: I.ImmutableOf<ApiFeatureCollection>
    ) => {
      // Analyze Area uses feature data, which is loaded for one feature at once.
      if (selectedFeatures.size !== 1) {
        return;
      }
      const selectedFeature = selectedFeatures.first();

      // Selected in this instance means which overlay feature we're going to use here
      const selectedOverlayFeature = overlayFeature.toJS();
      // Analyze Area only supports Polygon and MultiPolygon geometries.
      const overlayFeatureType = selectedOverlayFeature.geometry.type;
      if (overlayFeatureType !== 'Polygon' && overlayFeatureType !== 'MultiPolygon') {
        return;
      }

      let selectedOverlayFeatureGeometry = selectedOverlayFeature.geometry;

      // If we have overlay features from tiles, these will more accurately cover the active feature
      if (
        tileOverlayFeatureCollections &&
        tileOverlayFeatureCollections[overlayFeatureCollection.get('id')]
      ) {
        // Fetch the features that cover this tile as a fc
        const tileFeatureCollection =
          tileOverlayFeatureCollections[overlayFeatureCollection.get('id')];

        if (tileFeatureCollection) {
          // Our overlay features list from tile coverage should have ids that match the clicked overlay feature
          const filterCriteria = overlayFeature.get('id');
          const fitleredTileFeatures: geojson.Feature[] = tileFeatureCollection.features.filter(
            (f) => filterCriteria == f.id
          );

          if (2 <= fitleredTileFeatures.length) {
            // While fetching and setting our tile features we've already filtered down to Polygons and MultiPolygons
            // But we want to filter down to multipolygon types anyway to ensure we have the right types for compiling them
            selectedOverlayFeatureGeometry = compileGeometry(
              getPolygonGeometryTypesFrom(fitleredTileFeatures)
            );
          } else if (fitleredTileFeatures.length === 1) {
            selectedOverlayFeatureGeometry = fitleredTileFeatures[0].geometry;
          }
        }
      }

      const intersectedFeature = geoJsonUtils.intersectGeometries(
        selectedFeature.get('geometry').toJS(),
        selectedOverlayFeatureGeometry
      );

      // We try to run Analyze Area on the part of the overlay feature that
      // intersects with the selected feature, since that's the only area we can
      // guarantee feature data for.
      // Unfortunately the result of turf/intersect can be an invalid
      // geometry with kinks in it. If this is the case, fall back to the
      // overlay feature. It's possible that this may be outside the bounds
      // of the feature, but Analyze Area will handle that with a more informative
      // error down the line.
      let validOverlayFeature;
      if (intersectedFeature && !kinks(intersectedFeature.geometry).features.length) {
        validOverlayFeature = intersectedFeature;
      } else {
        validOverlayFeature = overlayFeature.toJS();
      }

      const {geometry: intersectedGeometry} = validOverlayFeature;
      openAnalyzePolygonPopup(
        geoJsonUtils.feature(
          intersectedGeometry,
          {...overlayFeature.get('properties').toJS()},
          {id: `${intersectedGeometry.type}-from-overlay`}
        )
      );
    },
    [tileOverlayFeatureCollections, selectedFeatures, openAnalyzePolygonPopup]
  );

  const onClick = React.useCallback(
    (f: I.ImmutableOf<ApiFeature>, fc: HydratedFeatureCollection) => {
      if (isAKeyPressed || readyToSelectOverlayPolygon) {
        return maybeOpenAnalyzePolygonPopup(
          f,
          fc as unknown as I.ImmutableOf<ApiFeatureCollection>
        );
      } else {
        const clickHandler = clickHandlers[fc.get('product')];
        return clickHandler?.(f, fc);
      }
    },
    [maybeOpenAnalyzePolygonPopup, clickHandlers, readyToSelectOverlayPolygon, isAKeyPressed]
  );

  const overlayHydratedFeatureCollections: I.List<HydratedFeatureCollection> = React.useMemo(() => {
    return overlayFeatureCollections
      .map((fc) =>
        featureCollectionUtils.hydratedFeatureCollection(fc!, geoJsonFeaturesLoader(fc!))
      )
      .toList();
  }, [overlayFeatureCollections, geoJsonFeaturesLoader]);

  return (
    <>
      {overlayHydratedFeatureCollections
        .map((fc) => {
          const isVisible = !!isOverlayVisibleByName[fc!.get('name')];

          // If the overlay is not visible, don't render the GeometryOverlayContent for it.
          // This improves performance for portfolios with complicated, large, or numerous
          // overlays to not have to do this work until it's needed. It does mean that when
          // you turn on an overlay, it may take a second to appear and is not instant.
          if (!isVisible) {
            USER_LAYERS_POPUP.remove();
            return;
          }

          const overlaySetting = overlaySettings.find(({name}) => name === fc!.get('name'));
          return (
            <GeometryOverlayContent
              key={fc!.get('id')}
              map={map}
              color={overlaySetting?.color || '#000'}
              isMapLoaded={isMapLoaded}
              hydratedFeatureCollection={fc!}
              onClick={onClick}
              isVisible={isVisible}
              defaultFilled={overlaySetting?.defaultFilled || false}
              defaultUnfilled={overlaySetting?.defaultUnfilled || false}
            />
          );
        })
        .toArray()}
    </>
  );
};

const GeometryOverlayContent: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    hydratedFeatureCollection: HydratedFeatureCollection;
    color: string;
    isVisible: boolean;
    onClick:
      | undefined
      | ((
          f: I.ImmutableOf<ApiFeature>,
          fc: HydratedFeatureCollection,
          ev: mapboxgl.MapLayerMouseEvent
        ) => void);
    defaultFilled: boolean;
    defaultUnfilled: boolean;
    // percentage decimal value from 0 and 1
    fillOpacityOverride?: number;
  }>
> = ({
  map,
  isMapLoaded,
  hydratedFeatureCollection,
  color,
  isVisible,
  onClick,
  defaultFilled,
  defaultUnfilled,
  fillOpacityOverride,
}) => {
  const [isPopupFrozen, setIsPopupFrozen] = React.useState<boolean>(false);
  const featureCollectionId = hydratedFeatureCollection.get('id');

  const source = useMemo<FeatureCollectionSourceRecord>(
    () => getSourceForFeatureCollection(hydratedFeatureCollection),
    [hydratedFeatureCollection]
  );

  const [fillLayer, lineLayer, pointLayer] = useFilledPolygonLayer(
    {
      idPrefix: `UserLayersVector-${featureCollectionId}`,
      sourceId: source.id,
      sourceLayer: source.sourceLayer,
      color,
      defaultFilled,
      defaultUnfilled,
      fillOpacityOverride,
    },
    USER_LAYERS_POPUP
  );

  React.useEffect(() => {
    const onMouseMove = (event: mapboxgl.MapMouseEvent) => {
      if (!isPopupFrozen) {
        handleMouseMoveFill(hydratedFeatureCollection, event);
      }
    };
    const onMouseLeave = () => {
      if (!isPopupFrozen) {
        USER_LAYERS_POPUP.remove();
      }
    };
    const onClick = (event: mapboxgl.MapMouseEvent) => {
      if (!isPopupFrozen) {
        handleMouseMoveFill(hydratedFeatureCollection, event, true);
      }
      setIsPopupFrozen((popupFrozenState) => !popupFrozenState);
    };
    map.on('mousemove', lineLayer.id, onMouseMove);
    map.on('mouseleave', lineLayer.id, onMouseLeave);
    map.on('mousemove', fillLayer.id, onMouseMove);
    map.on('mouseleave', fillLayer.id, onMouseLeave);
    map.on('mousemove', pointLayer.id, onMouseMove);
    map.on('mouseleave', pointLayer.id, onMouseLeave);
    map.on('click', fillLayer.id, onClick);
    map.on('click', lineLayer.id, onClick);
    map.on('click', pointLayer.id, onClick);

    return () => {
      map.off('mousemove', lineLayer.id, onMouseMove);
      map.off('mouseleave', lineLayer.id, onMouseLeave);
      map.off('mousemove', fillLayer.id, onMouseMove);
      map.off('mouseleave', fillLayer.id, onMouseLeave);
      map.off('mousemove', pointLayer.id, onMouseMove);
      map.off('mouseleave', pointLayer.id, onMouseLeave);
      map.off('click', fillLayer.id, onClick);
      map.off('click', lineLayer.id, onClick);
      map.off('click', pointLayer.id, onClick);
    };
  }, [map, lineLayer, fillLayer, pointLayer, hydratedFeatureCollection, isPopupFrozen]);

  const onClickFeature = React.useMemo(
    () =>
      onClick &&
      ((f: mapboxgl.MapboxGeoJSONFeature, ev: mapboxgl.MapLayerMouseEvent) => {
        let feature: I.ImmutableOf<ApiFeature> | undefined;
        if (source.source.type === 'vector') {
          feature = I.fromJS(geoJsonUtils.feature(f.geometry, f.properties, {id: f.id}));
        } else {
          // We look up the original feature because the one we get from mapbox
          // may be clipped to the viewport or otherwise modified.
          feature = hydratedFeatureCollection
            .get('features')
            .find((fcFeature) => fcFeature!.get('id') === f.id);
        }

        if (feature) {
          onClick(feature, hydratedFeatureCollection, ev);
        }
      }),
    [hydratedFeatureCollection, onClick, source.source.type]
  );

  useClickableFeatures(map, [fillLayer, lineLayer, pointLayer], isVisible && onClickFeature);
  return (
    <MapContent
      map={map}
      isMapLoaded={isMapLoaded}
      sources={[source]}
      layers={isVisible ? [fillLayer, lineLayer, pointLayer] : []}
    />
  );
};

export {GeometryOverlaysContent as default, GeometryOverlayContent};

/**
 * Format the Regrid parcel address by joining the constituent parts. Only
 * include the city, state, and zip code if they are all present to avoid
 * confusion (e.g., if a parcel is in Albany, TX but the TX is missing, one
 * could assume Albany, NY).
 *
 * I considered formatting the address with better casing by generically
 * capitalizing every word in the street and city, and every word in the state
 * if it's spelled out or every letter in the state if it's an abbreviation.
 * This wouldn't have been clever enough, though, to property format city names
 * like "DePaul" with internal capitalizations. It felt like a small can of
 * worms, so leaving everything uppercased for now.
 */
export function formatRegridAddress(properties: geojson.GeoJsonProperties) {
  const {address, scity, state2, szip} = properties || {};
  const parts = [address, ...(address && scity && state2 && szip ? [scity, state2, szip] : [])];
  return parts.join(', ') || 'Unknown';
}
