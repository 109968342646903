import * as B from '@blueprintjs/core';
import React, {CSSProperties} from 'react';

/**
 * B.Icon component (for use with Blueprint) for our custom "Lookout" SVG.
 */
const LookoutIcon: React.FunctionComponent<
  React.PropsWithChildren<{
    style?: CSSProperties;
    size?: number;
    color?: string;
  }>
> = ({style, size = 16, color}) => (
  <B.Icon
    icon={
      <span className="bp5-icon" style={style}>
        <svg
          color={color}
          width={size}
          height={size}
          version="1.1"
          id="_x32_"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <g>
            <path
              d="M510.868,327.816c-0.61-4.281-1.471-8.474-2.528-12.572l0.733-0.095c0,0-42.276-112.08-51.077-151.655
		c-7.37-29.951-28.899-53.445-56.26-64.216c-0.697-2.495-1.275-4.744-1.706-6.664c-5.963-24.275-29.473-40.554-54.752-36.975
		c-25.276,3.58-43.345,25.75-42.336,50.727c0.203,3.229,0.466,7.382,0.765,12.102c-18.842,17.961-29.939,43.56-28.831,70.96
		c0.047,0.765,0.096,1.546,0.143,2.359h-38.042c0.047-0.813,0.095-1.594,0.148-2.359c1.104-27.401-9.986-52.999-28.836-70.96
		c0.299-4.719,0.563-8.873,0.766-12.102c1.008-24.977-17.057-47.147-42.337-50.727c-25.276-3.58-48.789,12.7-54.752,36.975
		c-0.43,1.921-1.008,4.169-1.706,6.664c-27.36,10.77-48.889,34.265-56.26,64.216C45.199,203.069,2.923,315.149,2.923,315.149
		l0.733,0.095c-1.056,4.098-1.917,8.291-2.523,12.572c-8.765,61.872,34.296,119.143,96.168,127.905
		c61.872,8.761,119.14-34.296,127.901-96.168c0.606-4.289,0.94-8.546,1.064-12.779l0.733,0.112c0,0,0.195-3.029,0.538-8.267h56.922
		c0.343,5.238,0.538,8.267,0.538,8.267l0.734-0.112c0.124,4.233,0.458,8.49,1.064,12.779
		c8.762,61.872,66.029,104.929,127.901,96.168C476.576,446.96,519.629,389.688,510.868,327.816z M187.861,354.268
		c-5.848,41.24-44.022,69.955-85.274,64.112c-41.248-5.844-69.952-44.031-64.108-85.27c5.84-41.248,44.018-69.956,85.266-64.113
		C164.996,274.842,193.7,313.012,187.861,354.268z M409.41,418.38c-41.252,5.843-79.426-22.872-85.274-64.112
		c-5.839-41.256,22.864-79.426,64.116-85.271c41.252-5.843,79.426,22.864,85.267,64.113
		C479.362,374.35,450.658,412.537,409.41,418.38z"
            />
          </g>
        </svg>
      </span>
    }
  />
);

export default LookoutIcon;
