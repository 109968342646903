import * as I from 'immutable';

import {ApiFeature} from 'app/modules/Remote/Feature';

export interface MultiFeaturePropertyPart {
  partName: string | number | null;
  feature: I.ImmutableOf<ApiFeature>;
  isActive?: boolean;
}

export type MultiFeaturePropertyParts = MultiFeaturePropertyPart[];

/**
 * Given a list of features and a selected feature name, determine if the selected
 * feature is part of a multi-location property
 */
export function isMultiLocationProperty(
  features: I.ImmutableListOf<ApiFeature>,
  selectedFeature: I.ImmutableOf<ApiFeature> | null
) {
  const namePath = ['properties', 'name'] as any;
  return features.filter((f) => f!.getIn(namePath) === selectedFeature?.getIn(namePath)).size > 1;
}

/**
 * Given a list of features and a selected feature, create a list of features
 * that comprise the multi-feature property to which the selected feature
 * belongs.
 */
export function getMultiFeaturePropertyParts(
  features: I.ImmutableListOf<ApiFeature>,
  selectedFeature: I.ImmutableOf<ApiFeature> | null
): MultiFeaturePropertyParts {
  const namePath = ['properties', 'name'] as any;

  return features
    .filter((f) => f!.getIn(namePath) === selectedFeature?.getIn(namePath))
    .map((f) => ({
      // Fallback to an empty string. This allows us to keep partName required,
      // sort alphanumerically, etc but will evaluate to falsey in
      // presentational code where we want to display a stylized placeholder.
      partName: f!.getIn(['properties', 'multiFeaturePartName'], ''),
      feature: f!,
      isActive: f!.get('id') === selectedFeature?.get('id'),
    }))
    .sort(sortMultiFeaturePropertyPartsCallback)
    .toArray();
}

/**
 * Sort parts alphanumerically by part name, relegating empty strings to the end
 * of the list. In the event two parts have the same name (e.g., both are empty
 * strings), sort by feature ID for some consistency.
 */
export function sortMultiFeaturePropertyPartsCallback(
  a: MultiFeaturePropertyPart,
  b: MultiFeaturePropertyPart
) {
  if (a.partName !== b.partName) {
    if (!a.partName) {
      return 1;
    } else if (!b.partName) {
      return -1;
    } else {
      return a.partName.toString().localeCompare(b.partName.toString());
    }
  } else {
    return a.feature.get('id') - b.feature.get('id');
  }
}
