import * as Sentry from '@sentry/react';
import {toMercator} from '@turf/projection';
import {bbox, booleanIntersects, booleanOverlap} from '@turf/turf';
import classnames from 'classnames';
import geojson from 'geojson';
import * as I from 'immutable';
import leaflet from 'leaflet';
import * as mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React from 'react';

import LayerLegend from 'app/components/LayerLegend';
import LensLogoSvg from 'app/components/Logo/LensLogoSvg';
import ResizedImage from 'app/components/ResizedImage';
import {ApiFeatureData, ReportNotePage} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, GeometryOverlaySetting} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, getAreaUnit, getMeasurementSystem} from 'app/modules/Remote/Organization';
import {adjustCursor, findAppropriateLayerKey} from 'app/pages/MonitorProjectView/MapStateProvider';
import {StateApiNoteWithTagSettings, getAttachmentsByType} from 'app/stores/NotesStore';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import {LayerInfo} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as noteUtils from 'app/utils/noteUtils';

import {bboxToLeafletBounds} from '../../LeafletMap/LeafletMap';
import LocationSvg from '../LocationSvg';
import {ReadyStateReporter} from '../readyState';
import {
  DATA_NOTE_ID_PREFIX,
  booleanContainsAtLeastOnePart,
  formatDate,
  formatSource,
  makeDefaultOverlaySettings,
} from '../ReportExportFeature';
import ReportExportFeatureMap from '../ReportExportFeatureMap';
import {AnalyzeAreaGraph} from './AnalyzeAreaGraph';
import {AttachedImagePage} from './AttachedImagePage';
import {CategoryLegendsPage} from './CategoryLegendsPage';
import {NotePageControls} from './NotePageControls';
import {OverlayLegend} from './OverlayLegend';

const FULL_PAGE_IMAGE_WIDTH_INCHES = 7.5; //on an 8.5x11 sheet of paper, we allow 7.5in for a full width image for .5in marigns
const FULL_PAGE_IMAGE_MAX_HEIGHT = 9; //on an 8.5x11 sheet, allow the max height to grow up to 9in to allow 2in for bottom page content
const CONDENSED_IMAGE_WIDTH_INCHES = 5; // when in condensed image mode, allow 5in max for image width
const WEB_PPI = 96; // pixels per inch

function splitTextIntoParagraphs(text: string): string[] {
  //this regex ensures that we're splitting on newlines, but without dropping the newline
  return text.split(/(?=[\n])/g);
}

function guessParagraphLineCount(text: string, charactersPerLineGuess: number): number {
  return Math.max(Math.ceil(text.length / charactersPerLineGuess), 1);
}

const calculateNextParagraphBreak = (
  paragraph: string,
  remainingNumberLines: number,
  charactersPerLine: number
): [string, string] => {
  // find the last word break that fits in for our current paragraph's remaining lines
  const lastWordBreakIdx = paragraph
    .substring(0, remainingNumberLines * charactersPerLine)
    .lastIndexOf(' ');
  //keep everything we can fit up until our last word break
  const beforeBreak = paragraph.substring(0, lastWordBreakIdx);
  //this is the remaining part of the paragraph after we split off from the page
  const afterBreak = paragraph.substring(lastWordBreakIdx, paragraph.length);
  return [beforeBreak, afterBreak];
};

const CHARACTERS_PER_FULL_WIDTH = 130;
const getLinesPerPage = (notePageIdx, bigMode, hasGraph) => {
  switch (true) {
    case notePageIdx > 0:
      return 52;
    case hasGraph && bigMode:
      return 4;
    case bigMode:
      return 12;
    case hasGraph:
      return 14;
    default:
      return 26;
  }
};

export const NoteObservationPages: React.FunctionComponent<
  React.PropsWithChildren<{
    firebaseToken: string;
    readyStateReporter: ReadyStateReporter;
    areFontsReady: boolean;
    note: StateApiNoteWithTagSettings;
    featureJs: geojson.Feature;
    featureData: I.ImmutableOf<ApiFeatureData[]>;
    isMultiPartProperty: boolean;
    isCombinedMultiPart: boolean;

    overlayFeatureCollections: (Pick<ApiFeatureCollection, 'name' | 'id' | 'tiles'> &
      geojson.FeatureCollection)[];
    overlaySettings: GeometryOverlaySetting[];

    moveObservation?: (indexDiff: number) => void;
    disableMoveUp?: boolean;
    disableMoveDown?: boolean;

    defaultAlignment?: 'horizontal' | 'vertical';
    organization: I.ImmutableOf<ApiOrganization>;
    reportNoteState: ReportNotePage;
    noteIndex: number;
    setReportState: (ReportStatePath) => void;
  }>
> = ({
  firebaseToken,
  readyStateReporter,
  areFontsReady,
  note,
  featureJs,
  featureData,
  overlayFeatureCollections,
  overlaySettings,
  moveObservation,
  disableMoveUp,
  disableMoveDown,
  defaultAlignment,
  organization,
  isMultiPartProperty,
  isCombinedMultiPart,
  reportNoteState,
  setReportState,
  noteIndex,
}) => {
  const createdAt = formatDate(note.createdAt);
  const areaUnit = getAreaUnit(organization);

  // Use filter to get rid of imageRefs that have no cursor up front, so we
  // don’t need to keep checking later on.
  //
  // This may mean that there are no entries in here, making it no longer a
  // valid MapImageRefs.
  const imageRefArr = note.imageRefs.filter(
    (ref): ref is {layerKey: string; cursor: string} => !!ref.cursor
  );

  // Need a consistent object to prevent looping re-renders as the map tries to
  // zoom to the new geometry over and over.
  const noteAreaFeature = React.useMemo(
    () => (note.geometry ? geoJsonUtils.feature(note.geometry, {}) : featureJs),
    [note, featureJs]
  );

  // We'll supply a default note title to hydrate the report with, until a user
  // edits that note title, in which case always supply the edited note title.
  const title = React.useMemo(() => {
    return reportNoteState.title.length > 0
      ? reportNoteState.title
      : 'Note ' +
          (noteIndex + 1).toString() +
          `${note.tagSettings?.length ? `: ` + note.tagSettings.map((tag) => ` ${tag.text}`) : ''}`;
  }, [reportNoteState.title, noteIndex, note.tagSettings]);

  const [bounds, setBounds] = React.useState<leaflet.LatLngBounds | undefined>();

  // If we've loaded a saved report, convert the saved bbox to leafletBounds and pass it to the map.
  const savedBounds = React.useMemo(
    () => (reportNoteState.mapBounds ? bboxToLeafletBounds(reportNoteState.mapBounds) : undefined),
    [reportNoteState.mapBounds]
  );

  const alignment = React.useMemo<'vertical' | 'horizontal'>(() => {
    return reportNoteState.imageAlignment
      ? reportNoteState.imageAlignment
      : imageRefArr.length === 1
        ? 'vertical'
        : (defaultAlignment ?? guessAlignment(noteAreaFeature));
  }, [reportNoteState.imageAlignment, imageRefArr.length, noteAreaFeature, defaultAlignment]);

  // We filter the overlays’ features down to just what will appear in the image
  // so that we’re not generating a legend with every possible overlay.
  //
  // The list of featureCollections here is filtered down those that have at
  // least one feature in the bounds.
  const boundsFilteredOverlayFeatureCollections = React.useMemo(() => {
    if (!bounds) {
      return [];
    }

    const boundsFeature = geoJsonUtils.bboxPolygon([
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ]);

    return overlayFeatureCollections
      .map((fc) =>
        fc.tiles
          ? // Tiled feature collections are always assumed to be visible, since we can’t easily filter them.
            fc
          : {
              ...fc,
              features: fc.features.filter(
                (f) =>
                  booleanContainsAtLeastOnePart(boundsFeature, f) ||
                  (f.geometry.type === 'Polygon'
                    ? booleanOverlap(boundsFeature, f)
                    : f.geometry.type === 'MultiPolygon'
                      ? booleanOverlap(
                          // HACK(fiona): booleanOverlap needs the features to
                          // have the same geometry, so we hack ourselves a
                          // MultiPolygon when necessary here.
                          geoJsonUtils.feature(
                            {
                              type: 'MultiPolygon',
                              coordinates: [boundsFeature.geometry.coordinates],
                            },
                            {}
                          ),
                          f
                        )
                      : f.geometry.type === 'LineString' || f.geometry.type === 'MultiLineString'
                        ? booleanIntersects(boundsFeature, f)
                        : false)
              ),
            }
      )
      .filter((fc) => fc.tiles || fc.features.length > 0);
  }, [bounds, overlayFeatureCollections]);

  const isSelected = !reportNoteState.hidden;
  const areAttachedImagesVisible = !reportNoteState.attachmentsHidden;
  const areAttachedLegendsVisible = !reportNoteState.legendsHidden;
  const isGraphVisible = !reportNoteState.chartHidden;

  const noteCursorData: {
    cursor: string;
    layerKey: string;
    datum: I.ImmutableOf<ApiFeatureData> | undefined;
  }[] = imageRefArr.map(({layerKey, cursor}) => {
    // Make the same adjustments as we do for notes. See SET_IMAGE_REFS reducer
    // action in MapStateProvider.
    // Ensure we are using a valid, precise layerKey if it’s available (e.g.,
    // to migrate from notes that reference outmoded layer keys)
    // If the cursor doesn't exist, adjust it to the closest cursor to account
    // for changes in cursors when data is reprocessed.
    layerKey = findAppropriateLayerKey(layerKey, cursor, featureData);
    cursor = adjustCursor(layerKey, cursor, featureData) || cursor;
    return {
      cursor,
      layerKey,
      datum: featureData.find(
        (d) => d!.get('types').includes(layerKey) && d!.get('date') === cursor
      ),
    };
  });

  // Check on mount to see if we should report missing data. We’re conservative
  // here and only do it on mount to avoid over-reporting on each render.
  //
  // This should be okay since FeatureData is assumed to already be loaded when
  // this component is used.
  React.useEffect(() => {
    // No-op for combined, multi-location reports because we can’t assume all
    // FeatureData has loaded for all parts, which can lead to noisy alerts as
    // FeatureData streams in. Avoiding this check in those cases does mean we
    // may miss alerts for actually missing FeatureData for combined,
    // multi-location parts; these would fail silently and the user will see
    // “Image no longer available” next to the note. This is a rare edge case
    // and I think the tradeoff is worth it. Making these alerts quieter will
    // allow us to do a better job investigating the alerts that this exception
    // does catch.
    if (isMultiPartProperty && isCombinedMultiPart) {
      return;
    }

    noteCursorData.forEach(({cursor, layerKey, datum}) => {
      if (!datum) {
        Sentry.captureException(
          new Error(`Missing FeatureData for note in report: ${cursor} / ${layerKey}`),
          {
            extra: {
              cursor,
              layerKey,
              noteId: note.id,
              featureId: note.featureId,
              featureCollectionId: note.featureCollectionId,
            },
          }
        );
      }
    });
  }, []);

  // We go back to note.imageRefs since the local imageRefs variable might be an
  // empty array.
  const layersFromNoteImageRefs = note.imageRefs.map((imageRef) =>
    layerUtils.getLayer(imageRef.layerKey)
  );

  // We only include image attachments in the report (i.e., not PDFs or CSVs).
  const {images} = getAttachmentsByType(note.attachments);

  // given our note text, and constraints about the page, split the note text up so it will overflow
  // gracefully into the correct number of pages.
  const notePages = React.useMemo<string[]>(() => {
    const paragraphWithLineCount = splitTextIntoParagraphs(note.text).map((line) => {
      return {text: line, height: guessParagraphLineCount(line, CHARACTERS_PER_FULL_WIDTH)};
    });

    //instantiate variables to track as we iterate over each paragraph
    const splitNotePages: string[] = [''];
    let currentNotePageIdx: number = 0;
    let accumulatedParagraphHeight: number = 0;
    let allowedLinesPerPage: number = getLinesPerPage(
      currentNotePageIdx,
      reportNoteState.fullPageImageLayout,
      !!note.graph && !reportNoteState.chartHidden
    );
    let currentParagraph;

    // iterate over each paragraph, and based on how many lines it takes up, decide
    // whether it belongs on current page, or should be split and overflow into a new page
    for (let i = 0; i < paragraphWithLineCount.length; i++) {
      currentParagraph = paragraphWithLineCount[i];

      // if our currentParagraph won't overflow the current page, add it to the current page
      if (currentParagraph.height + accumulatedParagraphHeight <= allowedLinesPerPage) {
        splitNotePages[currentNotePageIdx] = splitNotePages[currentNotePageIdx].concat(
          currentParagraph.text
        );
        // track how much space we've taken up on the current page and continue our loop
        accumulatedParagraphHeight += currentParagraph.height;
      } else {
        // uh oh! if we in this else, adding our current paragraph would overflow the page.
        // estimate where to break our current paragraph to fill the rest of our current page,
        // then start the next page
        let remainingRoom = allowedLinesPerPage - accumulatedParagraphHeight;
        let [partToKeep, remainingSpillover] = calculateNextParagraphBreak(
          currentParagraph.text,
          remainingRoom,
          CHARACTERS_PER_FULL_WIDTH
        );

        // append the part of the paragraph we're keeping to the end of our current page
        splitNotePages[currentNotePageIdx] = splitNotePages[currentNotePageIdx].concat(partToKeep);

        //start a new overflow page, reset how many lines of it we've used so far, and make sure
        //we re-check the allowed number of lines per page
        currentNotePageIdx++;
        accumulatedParagraphHeight = 0;
        allowedLinesPerPage = getLinesPerPage(
          currentNotePageIdx,
          reportNoteState.fullPageImageLayout,
          !!note.graph && !reportNoteState.chartHidden
        );

        //how big is the rest of our paragraph? in case it's arbitrarily long paragraph that spans multiple pages,
        //we need to handle putting this paragraph onto multiple pages before continuing onto the next paragraph
        const remainingSpilloverLines = guessParagraphLineCount(
          remainingSpillover,
          CHARACTERS_PER_FULL_WIDTH
        );
        const pagesOfSpillover = Math.ceil(remainingSpilloverLines / allowedLinesPerPage);

        // we know how many pages this paragraph will take up. iterate through them and calculate where
        // to cut off each block of text.
        for (let spilloverPageIdx = 0; spilloverPageIdx < pagesOfSpillover; spilloverPageIdx++) {
          remainingRoom = allowedLinesPerPage - accumulatedParagraphHeight;
          const [newPartToKeep, newSpillover] = calculateNextParagraphBreak(
            remainingSpillover,
            remainingRoom,
            CHARACTERS_PER_FULL_WIDTH
          );

          // add our cut off block of text to our next page, and set remainingSpillover to what's left
          splitNotePages.push(newPartToKeep);
          remainingSpillover = newSpillover;

          //if this paragraph filled a whole extra page and we're in a second iteration of
          //this loop, increment our page number again
          if (spilloverPageIdx > 0) {
            currentNotePageIdx++;
            allowedLinesPerPage = getLinesPerPage(
              currentNotePageIdx,
              reportNoteState.fullPageImageLayout,
              !!note.graph && !reportNoteState.chartHidden
            );
          }

          // if we're on the last page of a single paragraph spilling over
          //  (ie NOT overflowing), make sure we keep the end of the spillover
          if (spilloverPageIdx === pagesOfSpillover - 1) {
            splitNotePages[currentNotePageIdx] =
              splitNotePages[currentNotePageIdx].concat(remainingSpillover);
          }
        }
      }
    }

    return splitNotePages.map((page, i) =>
      page.concat(splitNotePages.length > 1 && i < splitNotePages.length - 1 ? '...' : '')
    );
  }, [note.text, note.graph, reportNoteState.chartHidden, reportNoteState.fullPageImageLayout]);

  // We only set a hero image if (1) we are not displaying a map, which we do
  // when cursor data is available, and (2) we have at least one attached image.
  const [heroImage, setHeroImage] = React.useState((!noteCursorData.length && images[0]) || null);
  const attachmentImages = images.filter((i) => i !== heroImage);

  //include one category legend per imageRef with a category legend
  const categoryLegends = Array.from(
    new Set(
      layersFromNoteImageRefs.filter(
        (layer) => layer && 'layerLegendMap' in layer && !!layer.layerLegendMap
      )
    )
  );

  const isOverlayVisibleByName =
    reportNoteState.overlayVisibilityByName ?? makeDefaultOverlaySettings(overlaySettings);

  const hasOverlays =
    boundsFilteredOverlayFeatureCollections.filter((fc) => isOverlayVisibleByName[fc.name]).length >
    0;

  return (
    <>
      {/**If we are NOT in fullPageImageLayout mode, render all note content on one page. */}
      {!reportNoteState.fullPageImageLayout && (
        <section
          {...{[DATA_NOTE_ID_PREFIX]: `${note.id}`}}
          className={!reportNoteState.hidden ? 'note' : 'note note-unselected no-print'}
        >
          <NotePageControls
            moveObservation={moveObservation}
            disableMoveUp={disableMoveUp}
            disableMoveDown={disableMoveDown}
            isSelected={isSelected}
            reportNoteState={reportNoteState}
            setReportState={setReportState}
            noteIndex={noteIndex}
            imageRefArr={imageRefArr}
            alignment={alignment}
            areAttachedImagesVisible={areAttachedImagesVisible}
            images={images}
            note={note}
            isGraphVisible={isGraphVisible}
            isFullPageImageLayout={!!reportNoteState.fullPageImageLayout}
          />
          <div className="title-and-lens-watermark">
            <h2
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => {
                const target = e.target as HTMLElement;
                setReportState([['notePages', noteIndex, 'title'], target.innerText]);
              }}
            >
              {title}
            </h2>
            <div className="lens-watermark">
              <LensLogoSvg />
            </div>
          </div>
          <div className="note-imagery-and-source">
            <div>
              <div
                className={classnames(
                  'note-images-condensed',
                  alignment === 'horizontal' ? 'horizontal' : 'vertical'
                )}
              >
                {noteCursorData.map(({cursor, layerKey}, i) => {
                  const layer = layerUtils.getLayer(layerKey);
                  return (
                    <div key={`${i}-${layerKey}`} className="note-images-and-legends">
                      <ReportExportFeatureMap
                        key={cursor}
                        firebaseToken={firebaseToken}
                        readyStateReporter={readyStateReporter}
                        feature={featureJs}
                        featureData={featureData}
                        layerKey={layerKey}
                        cursor={cursor}
                        allowEdits={i === 0}
                        bounds={i > 0 ? bounds : undefined}
                        onBoundsChanged={i === 0 ? setBounds : undefined}
                        saveMapState={(fieldToUpdate, update) =>
                          setReportState([['notePages', noteIndex, fieldToUpdate], update])
                        }
                        savedBounds={savedBounds}
                        overlayFeatureCollections={boundsFilteredOverlayFeatureCollections}
                        overlaySettings={overlaySettings}
                        savedIsOverlayVisibleByName={isOverlayVisibleByName}
                        noteAreaFeature={noteAreaFeature}
                        {...(alignment === 'vertical'
                          ? // in "vertical" alignment, wide images are stacked on top of each other

                            // in "horizontal" alignnment, skinny images are stacked next to each other
                            {
                              widthInches: CONDENSED_IMAGE_WIDTH_INCHES,
                              maxHeightInches: CONDENSED_IMAGE_WIDTH_INCHES / imageRefArr.length,
                            }
                          : {
                              widthInches: CONDENSED_IMAGE_WIDTH_INCHES / imageRefArr.length,
                              maxHeightInches: CONDENSED_IMAGE_WIDTH_INCHES,
                              // HACK(fiona): We specify a very tall, skinny aspect
                              // ratio and let the "maxHeightInches" squish it down to
                              // size.
                              aspectRatio: 1 / 10,
                            })}
                        initialShowScale={reportNoteState.showMapScale}
                        measurementSystem={getMeasurementSystem(organization)}
                      />
                      <div className="note-legend">
                        {layer.type === 'data' && (
                          <LayerLegend
                            gradientStops={layer?.gradientStops}
                            // This typecheck is handled by the layer?.type === 'data' statement for isData
                            dataLabels={(layer as any)?.dataLabels}
                            width={
                              (alignment === 'horizontal'
                                ? // allow the layer legends to be as wide as the images
                                  CONDENSED_IMAGE_WIDTH_INCHES / 2
                                : CONDENSED_IMAGE_WIDTH_INCHES) * WEB_PPI
                            }
                            // but only 0.125in high (in px)
                            height={0.125 * WEB_PPI}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {areAttachedImagesVisible && !!heroImage && (
                <div className={classnames('hero-image')}>
                  <ResizedImage imageUrl={heroImage} dimension={1500} />
                </div>
              )}
              {hasOverlays && (
                <div className="note-legends-condensed">
                  <OverlayLegend
                    featureCollections={boundsFilteredOverlayFeatureCollections}
                    isOverlayVisibleByName={isOverlayVisibleByName}
                    overlaySettings={overlaySettings}
                  />
                </div>
              )}
            </div>
            <div>
              {note.geometry && (
                <LocationSvg
                  activeGeometry={note.geometry}
                  feature={featureJs}
                  width={2.25 * 96}
                  height={2 * 96}
                  windowBounds={
                    bounds &&
                    new mapboxgl.LngLatBounds([
                      bounds.getWest(),
                      bounds.getSouth(),
                      bounds.getEast(),
                      bounds.getNorth(),
                    ])
                  }
                />
              )}
              <div className="note-imagery-info-condensed">
                {noteCursorData.map(({datum, layerKey}, i) => {
                  const {sourceDetails} = datum
                    ? featureUtils.imageAndSourceDetails(datum, layerKey, organization)
                    : {sourceDetails: null};

                  const layer = layerUtils.getLayer(layerKey);

                  return (
                    <div key={i}>
                      <ImageSourceInfo
                        sourceDetails={sourceDetails}
                        layer={layer}
                        layerKey={layerKey}
                        datum={datum}
                        imageTitle={
                          imageRefArr.length === 1
                            ? 'Image'
                            : i === 0
                              ? `Image, ${alignment === 'vertical' ? 'top' : 'left'}`
                              : `Image, ${alignment === 'vertical' ? 'bottom' : 'right'}`
                        }
                      />
                    </div>
                  );
                })}
                <NoteInfo
                  noteAreaFeature={noteAreaFeature}
                  note={note}
                  createdAt={createdAt}
                  areaUnit={areaUnit}
                />
              </div>
            </div>
          </div>
          <div
            className={classnames(
              'note-content-container',
              guessParagraphLineCount(notePages[0], CHARACTERS_PER_FULL_WIDTH) < 10
                ? 'note-and-chart-vertical'
                : 'note-and-chart-horizontal'
            )}
          >
            <div className="note-text">
              {/**for long notes, overflow note pages will come after the initial note page */}
              <NoteText noteText={notePages[0]} />
            </div>

            {!!note.graph &&
              (noteAreaFeature.geometry.type === 'Polygon' ||
                noteAreaFeature.geometry.type === 'MultiPolygon') &&
              isGraphVisible && (
                <AnalyzeAreaGraph
                  noteGraph={note.graph}
                  imageRefs={note.imageRefs}
                  areaInM2={noteUtils.getNoteAreaInM2(note)}
                  areaUnit={getAreaUnit(organization)}
                  areFontsReady={areFontsReady}
                />
              )}
          </div>
        </section>
      )}
      {/**If we ARE in fullPageImageLayout mode, render one page for each noteCursor so we can make the images bigger
       * and remove some content. */}
      {reportNoteState.fullPageImageLayout && (
        <>
          {noteCursorData.map(({cursor, layerKey, datum}, i) => {
            const layer = layerUtils.getLayer(layerKey);

            const {sourceDetails} = datum
              ? featureUtils.imageAndSourceDetails(datum, layerKey, organization)
              : {sourceDetails: null};

            return (
              <section
                key={layerKey + i}
                {...{[DATA_NOTE_ID_PREFIX]: `${note.id}`}}
                className={!reportNoteState.hidden ? 'note' : 'note note-unselected no-print'}
              >
                <NotePageControls
                  moveObservation={moveObservation}
                  disableMoveUp={disableMoveUp}
                  disableMoveDown={disableMoveDown}
                  isSelected={isSelected}
                  reportNoteState={reportNoteState}
                  setReportState={setReportState}
                  noteIndex={noteIndex}
                  imageRefArr={imageRefArr}
                  alignment={alignment}
                  areAttachedImagesVisible={areAttachedImagesVisible}
                  images={images}
                  note={note}
                  isGraphVisible={isGraphVisible}
                  isFullPageImageLayout={!!reportNoteState.fullPageImageLayout}
                />
                <div className="title-and-lens-watermark">
                  <h2
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => {
                      const target = e.target as HTMLElement;
                      setReportState([['notePages', noteIndex, 'title'], target.innerText]);
                    }}
                  >
                    {title} | Image {noteCursorData.length > 1 && i + 1}
                  </h2>
                  <div className="lens-watermark">
                    <LensLogoSvg />
                  </div>
                </div>

                <div key={`${i}-${layerKey}`}>
                  <ReportExportFeatureMap
                    key={cursor}
                    firebaseToken={firebaseToken}
                    readyStateReporter={readyStateReporter}
                    feature={featureJs}
                    featureData={featureData}
                    layerKey={layerKey}
                    cursor={cursor}
                    allowEdits={i === 0}
                    bounds={i > 0 ? bounds : undefined}
                    onBoundsChanged={i === 0 ? setBounds : undefined}
                    saveMapState={(fieldToUpdate, update) =>
                      setReportState([['notePages', noteIndex, fieldToUpdate], update])
                    }
                    savedBounds={savedBounds}
                    overlayFeatureCollections={boundsFilteredOverlayFeatureCollections}
                    overlaySettings={overlaySettings}
                    savedIsOverlayVisibleByName={isOverlayVisibleByName}
                    noteAreaFeature={noteAreaFeature}
                    //hardcode width in full page layout mode: this is the full width minus margins
                    widthInches={FULL_PAGE_IMAGE_WIDTH_INCHES}
                    maxHeightInches={FULL_PAGE_IMAGE_MAX_HEIGHT}
                    initialShowScale={false}
                    measurementSystem={getMeasurementSystem(organization)}
                  />
                  {layer.type === 'data' && (
                    <LayerLegend
                      gradientStops={layer?.gradientStops}
                      // This typecheck is handled by the layer?.type === 'data' statement for isData
                      dataLabels={(layer as any)?.dataLabels}
                      width={FULL_PAGE_IMAGE_WIDTH_INCHES * WEB_PPI}
                      height={0.125 * WEB_PPI}
                    />
                  )}
                </div>
                {hasOverlays && (
                  <OverlayLegend
                    featureCollections={boundsFilteredOverlayFeatureCollections}
                    isOverlayVisibleByName={isOverlayVisibleByName}
                    overlaySettings={overlaySettings}
                  />
                )}

                <div className="note-content-container">
                  <div className="note-text">
                    <ImageSourceInfo
                      sourceDetails={sourceDetails}
                      layer={layer}
                      layerKey={layerKey}
                      datum={datum}
                      imageTitle="Image"
                    />
                    <NoteInfo
                      noteAreaFeature={noteAreaFeature}
                      note={note}
                      createdAt={createdAt}
                      areaUnit={areaUnit}
                    />
                    {/**for long notes, overflow note pages will come after the initial note page */}
                    <NoteText noteText={notePages[0]} />
                  </div>
                  {!!note.graph &&
                    (noteAreaFeature.geometry.type === 'Polygon' ||
                      noteAreaFeature.geometry.type === 'MultiPolygon') &&
                    isGraphVisible && (
                      <AnalyzeAreaGraph
                        noteGraph={note.graph}
                        imageRefs={note.imageRefs}
                        areaInM2={noteUtils.getNoteAreaInM2(note)}
                        areaUnit={getAreaUnit(organization)}
                        areFontsReady={areFontsReady}
                      />
                    )}
                </div>
              </section>
            );
          })}
        </>
      )}

      {/**most notes will only take up one page, but
       * here are the overflow pages for very long notes. */}
      {notePages.slice(1, notePages.length).map((page, i) => {
        return <OverflowNoteTextPage noteText={page} key={i} />;
      })}
      {/**CATEGORY LEGENDS PAGE */}
      {categoryLegends.length > 0 && (
        <CategoryLegendsPage
          reportNoteState={reportNoteState}
          areAttachedLegendsVisible={areAttachedLegendsVisible}
          setReportState={setReportState}
          noteIndex={noteIndex}
          title={title}
          categoryLegends={categoryLegends}
          alignment={alignment}
        />
      )}
      {/** IMAGE ATTACHMENTS PAGE */}
      {!!attachmentImages.length && (
        <AttachedImagePage
          reportNoteState={reportNoteState}
          areAttachedImagesVisible={areAttachedImagesVisible}
          setReportState={setReportState}
          noteIndex={noteIndex}
          title={title}
          attachmentImages={attachmentImages}
          heroImage={heroImage}
          setHeroImage={setHeroImage}
          alignment={alignment}
        />
      )}
    </>
  );
};

export const ImageSourceInfo: React.FunctionComponent<
  React.PropsWithChildren<{
    datum: I.MapAsRecord<I.ImmutableFields<ApiFeatureData>> | undefined;
    sourceDetails: featureUtils.ImagerySourceDetails;
    layer: LayerInfo;
    layerKey: string;
    imageTitle: string;
  }>
> = ({datum, sourceDetails, layer, layerKey, imageTitle}) => {
  return (
    <>
      <b>{imageTitle}: </b>
      {datum && sourceDetails ? (
        <>
          {imageryUtils.formatCaptureDate(datum.get('date'), layerKey, {
            featureDatum: datum,
          })}
          {'. '}
          Source: {layer.shortName}, {formatSource(sourceDetails)}.{' '}
          {featureUtils.getAttribution(sourceDetails, moment(datum.get('date')).get('year'))}
        </>
      ) : (
        <div style={{fontStyle: 'italic'}}>Image no longer available</div>
      )}
    </>
  );
};

const NoteInfo: React.FunctionComponent<
  React.PropsWithChildren<{
    noteAreaFeature:
      | geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties>
      | geojson.Feature<geojson.Geometry, {}>;
    note: StateApiNoteWithTagSettings;
    createdAt: string;
    areaUnit: 'areaAcre' | 'areaHectare';
  }>
> = ({noteAreaFeature, note, createdAt, areaUnit}) => {
  return (
    <>
      <div>
        <b>
          {noteAreaFeature.geometry.type === 'Polygon' ||
          noteAreaFeature.geometry.type === 'MultiPolygon'
            ? 'Center: '
            : 'Location: '}
        </b>
        {geoJsonUtils.makeCoordinateDisplayString(noteAreaFeature.geometry) || 'Unknown location'}
      </div>
      <div>
        {(noteAreaFeature.geometry.type === 'Polygon' ||
          noteAreaFeature.geometry.type === 'MultiPolygon') && (
          <>
            <b>Area: </b>
            {geoJsonUtils.makeAreaDisplayString(noteAreaFeature.geometry, areaUnit) ||
              'Unknown area'}
          </>
        )}
      </div>
      <div>
        {note.name && (
          <>
            <b>Interpretation: </b>
            {note.name} on {createdAt}
          </>
        )}
      </div>
    </>
  );
};

const NoteText: React.FunctionComponent<
  React.PropsWithChildren<{
    noteText: string;
    isOverflow?: boolean;
  }>
> = ({noteText, isOverflow = false}) => {
  const formattedNoteParagraphs = noteText.split(/(\n)/);

  return (
    <>
      <b>Note: {isOverflow ? ` (cont'd from previous page)` : ''}</b>
      {formattedNoteParagraphs.map((t, i) =>
        t ? (
          <span key={i}>
            {formattedNoteParagraphs.length === 1 ? <span key={i}>{t}</span> : <p key={i}>{t}</p>}
          </span>
        ) : (
          <></>
        )
      )}
    </>
  );
};

export const OverflowNoteTextPage: React.FunctionComponent<
  React.PropsWithChildren<{
    noteText: string;
  }>
> = ({noteText}) => {
  return (
    <section>
      <div className="title-and-lens-watermark">
        <div></div>
        <div className="lens-watermark">
          <LensLogoSvg />
        </div>
      </div>
      <div className="note-content-container">
        <div className="note-text">
          <NoteText noteText={noteText} isOverflow={true} />
        </div>
      </div>
    </section>
  );
};

//Utility to figure out whether we want to default display note images side by side
//or top and bottom.
function guessAlignment(feature: geojson.Feature): 'vertical' | 'horizontal' {
  const boundsXY = bbox(toMercator(feature));

  const width = boundsXY[2] - boundsXY[0];
  const height = boundsXY[3] - boundsXY[1];

  if (width / height < 3 / 4) {
    return 'horizontal';
  } else {
    return 'vertical';
  }
}
