import * as B from '@blueprintjs/core';
import React from 'react';

import {CompareSliderSnapPosition} from 'app/components/CompareMap';
import * as layers from 'app/utils/layers';

interface Props {
  maps: mapboxgl.Map | [mapboxgl.Map, mapboxgl.Map];
  snapCompareSlider: (position: CompareSliderSnapPosition) => void;
}

const OPACITY_KEY = 'raster-opacity';
const CONTRAST_KEY = 'raster-contrast';
const BRIGHTNESS_KEY = 'raster-brightness-min';
function clamp(num: number, min: number, max: number): number {
  if (num == null) {
    return min;
  }

  return Math.max(Math.min(max, num), min);
}

export default class MapHotkeys extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const {maps, snapCompareSlider} = this.props;

    const hotkeys = [
      {
        global: true,
        combo: 'plus',
        label: 'Increase layer brightness',
        onKeyDown: this.makeMapControl(maps, BRIGHTNESS_KEY, 0.1, 0, 0.6, 0),
      },
      {
        global: true,
        combo: 'minus',
        label: 'Decrease layer brightness',
        onKeyDown: this.makeMapControl(maps, BRIGHTNESS_KEY, -0.1, 0, 0.6, 0),
      },
      {
        global: true,
        combo: ']',
        label: 'Increase layer contrast',
        onKeyDown: this.makeMapControl(maps, CONTRAST_KEY, 0.1, -0.8, 0.8, 0),
      },
      {
        global: true,
        combo: '[',
        label: 'Decrease layer contrast',
        onKeyDown: this.makeMapControl(maps, CONTRAST_KEY, -0.1, -0.8, 0.8, 0),
      },
      {
        global: true,
        combo: '>',
        label: 'Increase layer opacity',
        onKeyDown: this.makeMapControl(maps, OPACITY_KEY, 0.2, 0, 1, 1),
      },
      {
        global: true,
        combo: '<',
        label: 'Decrease layer opacity',
        onKeyDown: this.makeMapControl(maps, OPACITY_KEY, -0.2, 0, 1, 1),
      },
      {
        global: true,
        combo: 'h',
        label: 'Snap compare mode slider left',
        onKeyDown: this.makeSliderControl('left', snapCompareSlider),
      },
      {
        global: true,
        combo: 'l',
        label: 'Snap compare mode slider right',
        onKeyDown: this.makeSliderControl('right', snapCompareSlider),
      },
    ];

    return (
      <B.HotkeysTarget2 hotkeys={hotkeys}>
        <></>
      </B.HotkeysTarget2>
    );
  }

  private makeSliderControl(
    snapPosition: CompareSliderSnapPosition,
    snapCompareSlider: (position: CompareSliderSnapPosition) => void
  ): (e: KeyboardEvent) => any {
    return () => {
      snapCompareSlider(snapPosition);
    };
  }

  private makeMapControl(
    maps: mapboxgl.Map | [mapboxgl.Map, mapboxgl.Map],
    paintPropertyKey: string,
    adjustment: number,
    min: number,
    max: number,
    defaultValue: number
  ): (e: KeyboardEvent) => any {
    return () => {
      // Maps may be a tuple or a single map. We want to narrow it to just an array type to ease handling.
      const targets = Array.isArray(maps) ? maps : [maps];
      targets.forEach((map) => {
        const style = map.getStyle();
        if (!style || !style.layers) {
          return;
        }
        const featureRasterLayerIds = style.layers
          .filter((l) => l.type === 'raster' && l.id.startsWith(layers.UPSTREAM_RASTER_PREFIX))
          .map((l) => l.id);

        featureRasterLayerIds.forEach((featureRasterLayerId) => {
          const previousValue =
            map.getPaintProperty(featureRasterLayerId, paintPropertyKey) || defaultValue;
          map.setPaintProperty(
            featureRasterLayerId,
            paintPropertyKey,
            clamp(previousValue + adjustment, min, max)
          );
        });
      });
    };
  }
}
