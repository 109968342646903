import * as I from 'immutable';

import {ApiOrganization} from 'app/modules/Remote/Organization';
import * as C from 'app/utils/constants';
import * as routeUtils from 'app/utils/routeUtils';

interface LENS_TIER_SETTING {
  name: string;
  seatLimit: number | null;
}

export const LENS_TIER_SETTINGS: {[tier in C.LENS_TIERS_TYPE]: LENS_TIER_SETTING} = {
  [C.LENS_TIER_FOCUS]: {
    name: 'Lens Lite',
    seatLimit: 3,
  },
  [C.LENS_TIER_PLUS]: {
    name: 'Lens Standard',
    seatLimit: 10,
  },
  [C.LENS_TIER_ENTERPRISE]: {
    name: 'Lens Enterprise',
    seatLimit: null,
  },
};

export function getLensTier(organization: I.ImmutableOf<ApiOrganization>): C.LENS_TIERS_TYPE {
  // If we don't have a tier set assume the lowest
  return organization.getIn(['tiers', 'Lens']) || C.LENS_TIER_FOCUS;
}

export function getIsOrgOverSeatLimit(
  organization: I.ImmutableOf<ApiOrganization>,
  organizationUsersCount: number
): boolean {
  const orgSeatLimit = organization.getIn(['settings', C.ORG_SETTING_SEAT_LIMIT]);
  if (orgSeatLimit) {
    return organizationUsersCount >= orgSeatLimit;
  }

  const orgTier = getLensTier(organization);
  const seatLimit = LENS_TIER_SETTINGS[orgTier].seatLimit;
  return !!seatLimit && organizationUsersCount >= seatLimit;
}

export const AT_LEAST_PLUS: C.LENS_TIERS_TYPE[] = [C.LENS_TIER_PLUS, C.LENS_TIER_ENTERPRISE];

// Checks if the provided org's tier is in the provided allowed tiers.
export const orgIsAllowedTier = (
  organization: I.ImmutableOf<ApiOrganization>,
  allowedTiers: C.LENS_TIERS_TYPE | C.LENS_TIERS_TYPE[]
): boolean => {
  const orgTier = getLensTier(organization);
  // We can avoid checking anything if we have as many allowed tiers as defined tiers
  if (C.LENS_TIERS.length === allowedTiers.length) {
    return true;
  }
  // Otherwise, lets see if org tier is in the list of allowed tiers
  if (Array.isArray(allowedTiers)) {
    return allowedTiers.includes(orgTier);
  } else {
    return allowedTiers == orgTier;
  }
};

export const getOrgPrefix = (organization: I.ImmutableOf<ApiOrganization>) =>
  routeUtils.makeUuidPrefix(organization.get('id'));
