import * as turf from '@turf/turf';
import * as geojson from 'geojson';
import proj4 from 'proj4';

import type {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as C from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';

export declare type BBox2d = [number, number, number, number];

// The shared constants have an arbitrary type since they are automatically
// generated from JavaScript and JSON. We recast as their GeoJSON types so
// consumers can use them as such.
export const US_CONTIGUOUS_BBOX = C.US_CONTIGUOUS_BBOX as geojson.Polygon;
export const US_AK_BBOX = C.US_AK_BBOX as geojson.Polygon;
export const US_HI_BBOX = C.US_HI_BBOX as geojson.Polygon;
export const US_PR_BBOX = C.US_PR_BBOX as geojson.Polygon;
export const CHACO_BBOX = C.CHACO_BBOX as geojson.Polygon;
export const BRAZIL_SHAPE = C.BRAZIL_SHAPE as geojson.Polygon;

// From 21 U.S. Code § 802(26): The term “State” means a State of the United
// States, the District of Columbia, and any commonwealth, territory, or
// possession of the United States. So, using “states” here even though Puerto
// Rico is a territory with deserved statehood.
export type USNonContiguousStates = 'AK' | 'HI' | 'PR';
export const US_NONCONTIGUOUS_BBOXES: {[key in USNonContiguousStates]: geojson.Polygon} = {
  AK: US_AK_BBOX,
  HI: US_HI_BBOX,
  PR: US_PR_BBOX,
};

export function makeCoordinateDisplayString(
  geometry: geojson.Geometry | null,
  significantDigits: number = 5
): string | null {
  if (!geometry) {
    return null;
  }
  switch (geometry.type) {
    case 'Point': {
      return geometry.coordinates
        .slice() // make sure the next reverse doesn't mutate original array
        .reverse() // reverse to transform from geoJSON's "lng,lat" order to conventional "lat,lng" order
        .map((c) => c.toFixed(significantDigits))
        .join(', ');
    }

    case 'Polygon':
    case 'MultiPolygon': {
      const centroidFeature: geojson.Feature<geojson.Point> | null = turf.centroid(geometry);
      // Recur into makeCoordinateDisplayString to format the centroid as a point.
      return (
        centroidFeature && makeCoordinateDisplayString(centroidFeature.geometry, significantDigits)
      );
    }

    default: {
      return null;
    }
  }
}

export function calculateAreaInUnit(
  geometry: geojson.Geometry,
  unit: ApiOrganizationAreaUnit | 'areaF2' | 'areaM2'
) {
  if (!geometry || (geometry.type !== 'Polygon' && geometry.type !== 'MultiPolygon')) {
    return null;
  }
  return conversionUtils.convert(UTMArea(geometry), C.UNIT_AREA_M2, unit);
}

export function makeAreaDisplayString(
  geometry: geojson.Geometry | null,
  unit: ApiOrganizationAreaUnit | 'areaF2' | 'areaM2',
  hideUnits?: boolean
) {
  const areaConverted = geometry && calculateAreaInUnit(geometry, unit);

  return areaConverted ? formatAreaString(areaConverted, unit, hideUnits) : null;
}

function formatAreaString(
  area: number,
  unit: ApiOrganizationAreaUnit | 'areaF2' | 'areaM2',
  hideUnits?: boolean
) {
  const areaConvertedStr =
    Math.abs(area) >= 1000
      ? conversionUtils.numberWithCommas(Math.round(area).toString())
      : (Math.round(area * 100) / 100).toFixed(2);

  switch (true) {
    case hideUnits:
    default:
      return areaConvertedStr;
    case unit === 'areaHectare':
      return `${areaConvertedStr} hectares`;
    case unit === 'areaF2':
      return `${areaConvertedStr} square feet`;
    case unit === 'areaM2':
      return `${areaConvertedStr} square meters`;
    case unit === 'areaAcre':
      return `${areaConvertedStr} acres`;
  }
}
/**
 * Gets intersection of two polygons in Acres for imagery ordering
 */
export function getIntersectionInAcres(
  geomOne: geojson.Polygon | geojson.MultiPolygon,
  geomTwo: geojson.Polygon | geojson.MultiPolygon
) {
  const intersectionPolygon = intersectGeometries(geomOne, geomTwo);
  const intersectionArea =
    intersectionPolygon &&
    conversionUtils.convert(UTMArea(intersectionPolygon), C.UNIT_AREA_M2, 'areaAcre');

  return intersectionArea ? Math.max(Math.floor(intersectionArea), 1) : 0;
}

export function getDifferenceInAreaAsString(
  geomOne: geojson.Geometry,
  geomTwo: geojson.Geometry,
  unit: ApiOrganizationAreaUnit
) {
  if (
    (geomOne.type !== 'Polygon' && geomOne.type !== 'MultiPolygon') ||
    (geomTwo.type !== 'Polygon' && geomTwo.type !== 'MultiPolygon')
  ) {
    return null;
  }
  const areaOneConverted = conversionUtils.convert(UTMArea(geomOne), C.UNIT_AREA_M2, unit);
  const areaTwoConverted = conversionUtils.convert(UTMArea(geomTwo), C.UNIT_AREA_M2, unit);
  const difference = areaTwoConverted - areaOneConverted;

  return formatDifferenceInAreaAsString(difference, unit);
}

export function formatDifferenceInAreaAsString(difference: number, unit: ApiOrganizationAreaUnit) {
  if (difference == 0) {
    return 'No Change';
  }

  return `${difference > 0 ? '+' : ''}${formatAreaString(difference, unit)}`;
}

/**
 * GeoJSON utilities lifted from @turf/helpers since that library is incompatible
 * with ES modules and consequently breaks Storybook builds. See
 * https://git.upstream.tech/upstreamtech/mono/merge_requests/1617.
 *
 * Converted to TypeScript by Fiona, 8/6/19
 */

interface FeatureOptions {
  id?: number | string;
  bbox?: geojson.BBox;
}

export function feature<
  G extends geojson.Geometry,
  P extends geojson.GeoJsonProperties = geojson.GeoJsonProperties,
>(geom: G, properties: P, options: FeatureOptions = {}) {
  const feat: geojson.Feature<G, P> = {
    type: 'Feature',
    id: options.id === 0 || options.id ? options.id : undefined,
    bbox: options.bbox,
    properties,
    geometry: geom,
  };

  return feat;
}

export function point<P extends geojson.GeoJsonProperties = geojson.GeoJsonProperties>(
  coordinates: [number, number],
  properties: P,
  options?: FeatureOptions
) {
  const geom: geojson.Point = {
    type: 'Point',
    coordinates: coordinates,
  };

  return feature(geom, properties, options);
}

export function lineString(
  coordinates: number[][],
  properties: geojson.GeoJsonProperties = {},
  options?: FeatureOptions
) {
  if (coordinates.length < 2) {
    throw new Error('coordinates must be an array of two or more positions');
  }
  const geom: geojson.LineString = {
    type: 'LineString',
    coordinates: coordinates,
  };
  return feature(geom, properties, options);
}

export function featureCollection<T = unknown, G extends geojson.Geometry = geojson.Geometry>(
  features: geojson.Feature<G, T>[],
  options: FeatureOptions = {}
) {
  const fc: geojson.FeatureCollection<G, T> & {id?: number | string} = {
    type: 'FeatureCollection',
    id: options.id,
    bbox: options.bbox,
    features,
  };

  return fc;
}

export function polygon(
  coordinates: number[][][],
  properties: geojson.GeoJsonProperties = {},
  options?: FeatureOptions
) {
  for (const ring of coordinates) {
    if (ring.length < 4) {
      throw new Error('Each LinearRing of a Polygon must have 4 or more Positions.');
    }
    for (let j = 0; j < ring[ring.length - 1].length; j++) {
      // Check if first point of Polygon contains two numbers
      if (ring[ring.length - 1][j] !== ring[0][j]) {
        throw new Error('First and last Position are not equivalent.');
      }
    }
  }
  const geom: geojson.Polygon = {
    type: 'Polygon',
    coordinates,
  };
  return feature(geom, properties, options);
}

export function bboxPolygon(
  bbox: geojson.BBox,
  options: {id?: number; properties?: geojson.GeoJsonProperties} = {}
) {
  const west = bbox[0];
  const south = bbox[1];
  const east = bbox[2];
  const north = bbox[3];

  const lowLeft = [west, south];
  const topLeft = [west, north];
  const topRight = [east, north];
  const lowRight = [east, south];

  return polygon([[lowLeft, lowRight, topRight, topLeft, lowLeft]], options.properties, {
    bbox,
    id: options.id,
  });
}

/**
 * Returns true if two bbox have the same values. Returns false if bbox have different arity.
 */
export function bboxAreEqual(a: geojson.BBox, b: geojson.BBox): boolean {
  if (a.length !== b.length) {
    return false;
  }
  return a.reduce((equal, bound, i) => equal && bound === b[i], true);
}

/**
 * Returns the latitude of the first point of a feature, or 0 if no feature is
 * provided.
 *
 * Useful for calculations that take into account how the meters per x axis step
 * changes with latitude with EPSG:3857.
 */
export function latitudeForFeature(
  feature: geojson.Feature<geojson.Polygon | geojson.MultiPolygon | geojson.LineString> | undefined
) {
  if (!feature) {
    return 0;
  }

  let firstPoint: geojson.Position;

  switch (feature.geometry.type) {
    case 'LineString':
      firstPoint = feature.geometry.coordinates[0];
      break;

    case 'Polygon':
      firstPoint = feature.geometry.coordinates[0][0];
      break;

    case 'MultiPolygon':
      firstPoint = feature.geometry.coordinates[0][0][0];
      break;
  }

  return firstPoint ? firstPoint[1] : 0;
}

/**
 *
 * Takes a polygon or multiploygon and applies a given function to every position in the geometry.
 *
 */
export function applyToCoordinates(
  geometry: geojson.Polygon | geojson.MultiPolygon | undefined,
  func: (position: geojson.Position) => geojson.Position
) {
  if (!geometry) {
    return undefined;
  }

  let coordinates;

  switch (geometry.type) {
    case 'Polygon':
      coordinates = geometry.coordinates.map((rings) =>
        rings.map((position: geojson.Position) => func(position))
      );
      break;

    case 'MultiPolygon':
      coordinates = geometry.coordinates.map((polygons) =>
        polygons.map((rings) => rings.map((position: geojson.Position) => func(position)))
      );
      break;
  }

  const newGeometry: geojson.Polygon | geojson.MultiPolygon = {
    ...geometry,
    coordinates,
  };

  return newGeometry;
}

/**
 * Returns the number of meters across for a webtile (256px) at a given
 * latitude, at a given zoom. At zoom 0, this is ~40m, covering the
 * circumference of the Earth.
 *
 * TODO(emily): I think there’s an error in the description above, as this
 * function returns meters across per *pixel*, not meters across per *webtile*.
 * We should confirm that we’re not misusing the output elsewhere.
 */
export function calculateResolution(lat: number, zoom = 18) {
  // Fun fact: 6378137 is the radius of the spherical approximation of the Earth, in meters
  return (Math.cos((lat * Math.PI) / 180) * 2 * Math.PI * 6378137) / (256 * 2 ** zoom);
}

/**
 * Applies the standard imagery buffer for Lens. Returns the buffered feature
 * and rectangular buffer.
 *
 * Copied from window.py
 */
export function applyImageryBuffer(
  featureCollection: geojson.FeatureCollection<geojson.Polygon | geojson.MultiPolygon>
): [geojson.FeatureCollection<geojson.Polygon | geojson.MultiPolygon>, geojson.BBox] {
  const firstFeature = featureCollection.features[0];

  if (!firstFeature) {
    return [featureCollection, [0, 0, 0, 0]];
  }

  const featureLatitude = latitudeForFeature(firstFeature);
  const resolution = calculateResolution(featureLatitude);

  let marginCoefficient: number;
  let maxMargin: number;
  let minMargin: number;

  if (resolution > 10) {
    marginCoefficient = 1.75;
    maxMargin = 2500;
    minMargin = 1250;
  } else if (resolution >= 2.5) {
    marginCoefficient = 0.5;
    maxMargin = 1000;
    minMargin = 250;
  } else {
    marginCoefficient = 0.1;
    maxMargin = 250;
    minMargin = 50;
  }

  const boundsXY = turf.bbox(turf.toMercator(featureCollection));

  const maxDimension = Math.max(boundsXY[2] - boundsXY[0], boundsXY[3] - boundsXY[1]);
  const margin = Math.max(Math.min(maxDimension * marginCoefficient, maxMargin), minMargin);

  const windowXY: geojson.BBox = [
    boundsXY[0] - margin,
    boundsXY[1] - margin,
    boundsXY[2] + margin,
    boundsXY[3] + margin,
  ];

  // The "margin" here is in meters at the equator, which is why we did all of
  // the above stuff rather than just say "50m."
  return [
    // The casting here is because Turf’s geojson types don’t use string literals
    // for "type".
    turf.buffer(featureCollection, margin, {
      units: 'meters',
    }) as geojson.FeatureCollection<geojson.Polygon | geojson.MultiPolygon>,
    windowXY,
  ];
}

/**
 * Download a GeoJSON file. See https://stackoverflow.com/a/30800715.
 */
export function exportGeoJson(geoJson: geojson.GeoJSON, filename: string) {
  const data = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(geoJson));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', data);
  downloadAnchorNode.setAttribute('download', filename + '.geojson');
  document.body.appendChild(downloadAnchorNode); // Required for Firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

/**
 * Returns true if first geometry contains second geometry or if the geometries
 * are identical.
 *
 * Despite its type declarations specifying otherwise, booleanContains does not
 * support MultiPolygon geometries in practice, so we adapt it here:
 *
 * 1. If the first geometry is a Polygon and the second geometry is a Polygon,
 *    returns true if the second geometry is completely contained by the first
 *    geometry.
 * 2. If the first geometry is a Polygon and the second geometry is a
 *    MultiPolygon, returns true if every second geometry part is completely
 *    contained by the first geometry.
 * 3. If the first geometry is a MultiPolygon and the second geometry is a
 *    Polygon, returns true if the second geometry is completely contained by
 *    any first geometry part.
 * 4. If the first geometry is a MultiPolygon and the second geometry is a
 *    MultiPolygon, returns true if every second geometry part is completely
 *    contained any first geometry part.
 */
export function polygonContainsOrEquals(
  geometry1: geojson.Polygon | geojson.MultiPolygon,
  geometry2: geojson.Polygon | geojson.MultiPolygon
): boolean {
  const nestCoordinates = (
    geometry: geojson.Polygon | geojson.MultiPolygon
  ): geojson.MultiPolygon['coordinates'] =>
    geometry.type === 'Polygon' ? [geometry.coordinates] : geometry.coordinates;

  const polygon = (coordinates: geojson.Polygon['coordinates']): geojson.Polygon => ({
    type: 'Polygon',
    coordinates,
  });

  const nestedCoordinates1 = nestCoordinates(geometry1);
  const nestedCoordinates2 = nestCoordinates(geometry2);

  return nestedCoordinates2.every((coordinates2) =>
    nestedCoordinates1.some((coordinates1) => {
      const polygon1 = polygon(coordinates1);
      const polygon2 = polygon(coordinates2);
      return turf.booleanContains(polygon1, polygon2) || turf.booleanEqual(polygon1, polygon2);
    })
  );
}

/**
 * Returns true for geometries that are above the equator, returns
 * false for geometries that intersect the equator or are below it
 */
export function isFullyInNorthernHemisphere(geometry: geojson.Geometry) {
  const bbox: BBox2d = turf.bbox(geometry) as BBox2d;
  const minLat = bbox[1];

  return minLat > 0;
}

/**
 * Previous versions of turf.intersect took 2 geometries. This is a useful helper
 * to replace instances of that with the new syntax
 */
export function intersectGeometries(
  geometry1: geojson.Polygon | geojson.MultiPolygon,
  geometry2: geojson.Polygon | geojson.MultiPolygon
) {
  return turf.intersect(turf.featureCollection([turf.feature(geometry1), turf.feature(geometry2)]));
}

/**
 * Calculate the area of a polygon ring in projected space using Shoelace formula
 * https://en.wikipedia.org/wiki/Shoelace_formula
 */
export function calculatePolygonArea(ring: number[][]): number {
  let area = 0;

  for (let i = 0; i < ring.length - 1; i++) {
    const [x1, y1] = ring[i];
    const [x2, y2] = ring[i + 1];
    area += x1 * y2 - x2 * y1;
  }

  return Math.abs(area) / 2;
}

/**
 * Calculate area in acres using UTM projection similar to backend's shapely approach
 * This mimics the backend's process by:
 * 1. Finding the centroid of the geometry to determine the appropriate UTM zone
 * 2. Reprojecting to that UTM zone
 * 3. Calculating area in the projected space
 */
export function UTMArea(
  geojson: geojson.Geometry | geojson.Feature | geojson.FeatureCollection
): number {
  // Handle FeatureCollection - sum areas of all features
  if (geojson.type === 'FeatureCollection') {
    return geojson.features.reduce((totalArea, feature) => totalArea + UTMArea(feature), 0);
  }

  // Extract geometry if a Feature is provided
  const geometry = geojson.type === 'Feature' ? geojson.geometry : geojson;

  // Get centroid of the geometry to determine UTM zone
  const centroid = turf.centroid(geometry);
  const [lon, lat] = centroid.geometry.coordinates;

  // Calculate UTM zone number based on longitude.
  // Each zone is 6 degrees wide
  const zoneNumber = Math.floor((lon + 180) / 6) + 1;

  // Determine hemisphere (north or south) based on latitude
  const hemisphere = lat >= 0 ? 'N' : 'S';

  // Define the UTM projection string
  // Set units to meters and don't use any defaults (no_defs)
  const utmProjection = `+proj=utm +zone=${zoneNumber}${hemisphere === 'S' ? ' +south' : ''} +datum=WGS84 +units=m +no_defs`;

  // Define WGS84 projection string
  const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';

  // Function to convert a coordinate from WGS84 to UTM
  const transformToUTM = (coord: number[]): number[] => {
    return proj4(wgs84Projection, utmProjection, coord);
  };

  // Transform all coordinates to UTM
  let utmGeometry: geojson.Geometry;

  if (geometry.type === 'Polygon') {
    // Transform polygon coordinates
    const transformedCoords = geometry.coordinates.map((ring) =>
      ring.map((coord) => transformToUTM(coord))
    );
    utmGeometry = {
      type: 'Polygon',
      coordinates: transformedCoords,
    };
  } else if (geometry.type === 'MultiPolygon') {
    // Transform MultiPolygon coordinates
    const transformedCoords = geometry.coordinates.map((polygon) =>
      polygon.map((ring) => ring.map((coord) => transformToUTM(coord)))
    );
    utmGeometry = {
      type: 'MultiPolygon',
      coordinates: transformedCoords,
    };
  } else {
    throw new Error(`Unsupported geometry type: ${geometry.type}`);
  }

  // Calculate area in square meters in projected space
  // Now that we're in UTM, we can use a standard polygon area calculation
  let areaInSquareMeters = 0;

  if (utmGeometry.type === 'Polygon') {
    areaInSquareMeters = calculatePolygonArea(utmGeometry.coordinates[0]);

    // Subtract the area of holes
    for (let i = 1; i < utmGeometry.coordinates.length; i++) {
      areaInSquareMeters -= calculatePolygonArea(utmGeometry.coordinates[i]);
    }
  } else if (utmGeometry.type === 'MultiPolygon') {
    for (const polygon of utmGeometry.coordinates) {
      // Add area of outer ring
      areaInSquareMeters += calculatePolygonArea(polygon[0]);

      // Subtract area of holes
      for (let i = 1; i < polygon.length; i++) {
        areaInSquareMeters -= calculatePolygonArea(polygon[i]);
      }
    }
  }

  return areaInSquareMeters;
}

export function calculateBillableUTMAreaInAcres(
  geojson: geojson.Geometry | geojson.Feature | geojson.FeatureCollection
): number {
  const areaInSquareMeters = UTMArea(geojson);
  const areaInAcres = conversionUtils.convert(areaInSquareMeters, C.UNIT_AREA_M2, C.UNIT_AREA_ACRE);
  return Math.max(Math.floor(areaInAcres), 1);
}
