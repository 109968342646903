import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import * as C from 'app/utils/constants';
import {loginAppUrl} from 'app/utils/environmentUtils';

/**
 * The raw tokens for authorizing signup into an org. The server should ensure
 * that none of these are null in practice.
 */
export type SignupTokens = {
  [K in RoleId]: {
    token: string;
    createdAt: string;
  } | null;
};

export type RoleId =
  | typeof C.USER_ROLE_OWNER
  | typeof C.USER_ROLE_REGULAR
  | typeof C.USER_ROLE_READONLY;

export const roleIds: RoleId[] = [C.USER_ROLE_READONLY, C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER];

export const ROLE_NAMES: {[key in RoleId]: string} = {
  [C.USER_ROLE_OWNER]: 'Admin',
  [C.USER_ROLE_REGULAR]: 'Member',
  [C.USER_ROLE_READONLY]: 'Read-only',
};

interface InviteLinkDictionary {
  ownerLink: string;
  regularLink: string;
  readonlyLink: string;
}

export function getInviteLinks(tokens: SignupTokens): InviteLinkDictionary {
  // We keep the user role in the URL just so it’s clear what the invitation is
  // for. It doesn’t have any bearing on the role the new user will get.

  // Read, write, invite others
  const ownerLink = `${loginAppUrl}/signup/admin/${tokens.owner?.token}`;
  // Read + write
  const regularLink = `${loginAppUrl}/signup/member/${tokens.regular?.token}`;
  const readonlyLink = `${loginAppUrl}/signup/readonly/${tokens.readonly?.token}`;

  return {ownerLink, regularLink, readonlyLink};
}

export function getInviteLinkForRole(tokens: SignupTokens, roleId: RoleId) {
  const {readonlyLink, regularLink, ownerLink} = getInviteLinks(tokens);
  switch (roleId) {
    case C.USER_ROLE_READONLY:
      return readonlyLink;
    case C.USER_ROLE_REGULAR:
      return regularLink;
    case C.USER_ROLE_OWNER:
      return ownerLink;
    default:
      return undefined;
  }
}

export function getLastValidTokenLocaleDateString(tokens: SignupTokens, roleId: RoleId): string {
  const currentToken = tokens[roleId];
  if (!currentToken) return 'Unknown';
  const tokenCreationDate = new Date(currentToken.createdAt);
  const expirationDate = new Date(tokenCreationDate);
  expirationDate.setDate(tokenCreationDate.getDate() + C.SIGNUP_TOKEN_VALIDITY_DAYS);
  return (
    expirationDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }) +
    ' at ' +
    expirationDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })
  );
}

export function canInviteOthers(role: ApiOrganizationUser['role']) {
  return role === C.USER_ROLE_OWNER;
}

const PERMISSIONS_TYPE_TEAM_MANAGEMENT = 'teamManagement';
const PERMISSIONS_TYPE_DASHBOARD = 'dashboard';
const PERMISSIONS_TYPE_PROPERTIES = 'properties';
const PERMISSIONS_TYPE_SUMMARY = 'summary';
const PERMISSIONS_TYPE_ORDERS = 'orders';

export type PERMISSIONS_TYPE =
  | typeof PERMISSIONS_TYPE_TEAM_MANAGEMENT
  | typeof PERMISSIONS_TYPE_DASHBOARD
  | typeof PERMISSIONS_TYPE_PROPERTIES
  | typeof PERMISSIONS_TYPE_SUMMARY
  | typeof PERMISSIONS_TYPE_ORDERS;

export const PERMISSIONS: {
  type: PERMISSIONS_TYPE;
  label: string;
  actions: {
    roles: RoleId[];
    text?: string;
    note?: string;
  }[];
}[] = [
  {
    type: PERMISSIONS_TYPE_TEAM_MANAGEMENT,
    label: 'Team Management',
    actions: [
      {
        roles: [C.USER_ROLE_READONLY],
        text: 'Read-only users are not allowed to view the team management page.',
      },
      {roles: [C.USER_ROLE_REGULAR], text: 'See team members'},
      {roles: [C.USER_ROLE_OWNER], text: 'See, invite, and remove team members'},
      {roles: [C.USER_ROLE_OWNER], text: 'Change team member roles'},
    ],
  },
  {
    type: PERMISSIONS_TYPE_DASHBOARD,
    label: 'Portfolios',
    actions: [
      {roles: [C.USER_ROLE_READONLY], text: 'See portfolios'},
      {roles: [C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER], text: 'See and rename portfolios'},
      {roles: [C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER], text: 'Add new portfolios'},
    ],
  },
  {
    type: PERMISSIONS_TYPE_PROPERTIES,
    label: 'Properties',
    actions: [
      {roles: [C.USER_ROLE_READONLY], text: 'See property details and tags'},
      {roles: [C.USER_ROLE_READONLY], text: 'See property notes'},
      {roles: [C.USER_ROLE_READONLY], text: 'See saved reports'},
      {
        roles: [C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER],
        text: 'See and edit property details and tags',
      },
      {
        roles: [C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER],
        text: 'See, create, edit, and delete property notes',
      },
      {
        roles: [C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER],
        text: 'See, save, rename, and delete reports',
      },
      {roles: [C.USER_ROLE_REGULAR, C.USER_ROLE_OWNER], text: 'Add properties'},
      {roles: [C.USER_ROLE_OWNER], text: 'Order high-resolution imagery'},
      {roles: [C.USER_ROLE_OWNER], text: 'Customize tags'},
    ],
  },
  {
    type: PERMISSIONS_TYPE_ORDERS,
    label: 'Orders',
    actions: [
      {roles: [C.USER_ROLE_OWNER], text: 'Order commercial imagery'},
      {roles: [C.USER_ROLE_REGULAR], text: 'View commercial imagery available to order'},
      {
        roles: [C.USER_ROLE_READONLY],
        text: 'Order pane is not visible.',
      },
    ],
  },
];

export const ROLE_SUMMARIES: {[key in RoleId]: string} = {
  [C.USER_ROLE_OWNER]:
    'Admins have full permission to spend money and utilize all features. They can invite other admins and edit other user roles.',
  [C.USER_ROLE_REGULAR]:
    'Members can utilize all features but cannot spend money. They can invite other Member or Read-only users and edit only their own user profiles.',
  [C.USER_ROLE_READONLY]: 'Read-only users have limited view-only access to features.',
};

export const ROLE_SUPPORT_DOC_LINK =
  'https://support.upstream.tech/article/165-user-roles-and-permissions';
