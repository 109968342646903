import React from 'react';

/**
 * Transfers mouse events from one window to another. Necessary because Mapbox
 * listens on the execution window for mousemove and mouseup, regardless of what
 * window the map is actually running in.
 *
 * https://stackoverflow.com/a/15318321/51835
 */
export function transferMouseEvents(fromWindow: Window, toEl: HTMLElement) {
  const handleMouseEvent = (srcEvent: MouseEvent) => {
    if (!toEl.ownerDocument) {
      return;
    }

    // Create a new event for the this window
    const dstEvent = toEl.ownerDocument.createEvent('MouseEvents');

    // We'll need this to offset the mouse move appropriately
    const boundingClientRect = toEl.getBoundingClientRect();

    // Initialize the event, copying exiting event values
    // for the most part
    dstEvent.initMouseEvent(
      srcEvent.type,
      true, // bubbles
      false, // not cancelable
      window,
      srcEvent.detail,
      srcEvent.screenX,
      srcEvent.screenY,
      srcEvent.clientX + boundingClientRect.left,
      srcEvent.clientY + boundingClientRect.top,
      srcEvent.ctrlKey,
      srcEvent.altKey,
      srcEvent.shiftKey,
      srcEvent.metaKey,
      srcEvent.button,
      null // no related element
    );

    // Dispatch the mousemove event on the iframe element
    toEl.dispatchEvent(dstEvent);
  };

  fromWindow.addEventListener('mousemove', handleMouseEvent);
  fromWindow.addEventListener('mouseup', handleMouseEvent);
}

/**
 * Artificially creates an AbortError exception. This creates an error with code
 * 20, which is exposed via DOMException.ABORT_ERR.
 */
export function createAbortError() {
  return new DOMException('Aborted', 'AbortError');
}

/**
 * Makes a Promise abortable. Accepts a signal object which allows the request
 * to listen for abort events. If aborted, the Promise will reject with an
 * AbortError exception, which can be checked for via the error code.
 */
export function makeAbortable<ReturnValueType>(
  promise: Promise<ReturnValueType>,
  signal: AbortSignal
): Promise<ReturnValueType> {
  if (signal.aborted) {
    return Promise.reject(createAbortError());
  }

  return new Promise((resolve, reject) => {
    const abortHandler = () => {
      reject(createAbortError());
    };

    signal.addEventListener('abort', abortHandler);

    promise
      .then((val) => resolve(val))
      .catch((error) => reject(error))
      .finally(() => {
        signal.removeEventListener('abort', abortHandler);
      });
  });
}

export interface WindowDimensions {
  width: number;
  height: number;
}

function getWindowDimensions(): WindowDimensions {
  const {innerWidth: width, innerHeight: height} = window;

  return {width, height};
}

export function useWindowDimensions(): WindowDimensions {
  const [dimensions, setDimensions] = React.useState<WindowDimensions>(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return dimensions;
}
