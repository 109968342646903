import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {usePushNotification} from 'app/components/Notification';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiProject} from 'app/modules/Remote/Project';
import {BLANK_API_FEATURE_COLLECTION} from 'app/pages/ManageProperties/ManageFeaturesView';
import {useUserInfo} from 'app/providers/AuthProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {createLayerKeysFromLayerObject, createLayerObjectFromLayerKeys} from 'app/utils/layerUtils';

import {LensLibraryProvider} from './LensLibraryProvider';
import cs from './LensLibraryView.styl';
import OverlaysProvider from './OverlaysProvider';

export const PortfolioLayersSelector: React.FunctionComponent<
  React.PropsWithChildren<{
    project: I.ImmutableOf<ApiProject>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    onClose: () => void;
    isOpen: boolean;
  }>
> = ({project, featureCollection, onClose, isOpen}) => {
  const [, , {addLayersToFeatureCollections, removeLayersFromFeatureCollections}] = useProjects();
  const [organization, profile] = useUserInfo();
  const projects: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<ApiProject>>> = I.OrderedMap(
    [[project.get('id'), project]]
  );
  const pushNotification = usePushNotification();
  return (
    <OverlaysProvider organization={organization!}>
      <B.Dialog isOpen={isOpen} onClose={() => onClose()} className={cs.selectLayersModal}>
        <LensLibraryProvider
          organization={organization!}
          profile={profile!}
          addLayersToFeatureCollections={async (ids, layers) => {
            const numLayers = Object.keys(layers).length;
            const layerText = numLayers === 1 ? 'layer' : 'layers';
            try {
              const response = await addLayersToFeatureCollections(ids, layers);
              pushNotification({
                message: `${numLayers} ${layerText} added`,
                options: {intent: B.Intent.SUCCESS},
              });
              return response;
            } catch (error) {
              pushNotification({
                message: `There was a problem adding the ${layerText}. Please try again`,
                options: {intent: B.Intent.DANGER},
              });
              console.error(error);
            }
          }}
          removeLayersFromFeatureCollections={async (ids, layers) => {
            const numLayers = Object.keys(layers).length;
            const layerText = numLayers === 1 ? 'layer' : 'layers';
            try {
              const response = await removeLayersFromFeatureCollections(ids, layers);
              pushNotification({
                message: `${numLayers} ${layerText} removed`,
                options: {intent: B.Intent.SUCCESS},
              });
              return response;
            } catch (error) {
              pushNotification({
                message: `There was a problem removing the ${layerText}. Please try again`,
                options: {intent: B.Intent.DANGER},
              });
              console.error(error);
            }
          }}
          mode={'singlePortfolio'}
          projects={projects}
          featureCollectionsById={I.Map([[featureCollection.get('id'), featureCollection]])}
          areProjectsLoading={false}
          onClose={onClose}
        />
      </B.Dialog>
    </OverlaysProvider>
  );
};

export const NewPortfolioLayersSelector: React.FunctionComponent<
  React.PropsWithChildren<{
    onClose: () => void;
    isOpen: boolean;
    selectedLayerKeys: string[];
    setSelectedLayerKeys: (layerKeys: string[]) => void;
    selectedOverlayIds: number[];
    setSelectedOverlayIds: React.Dispatch<React.SetStateAction<number[]>>;
  }>
> = ({
  onClose,
  isOpen,
  selectedLayerKeys,
  setSelectedLayerKeys,
  selectedOverlayIds,
  setSelectedOverlayIds,
}) => {
  const [organization, profile] = useUserInfo();

  const featureCollection = I.fromJS(BLANK_API_FEATURE_COLLECTION)
    .setIn(
      ['processingConfig', 'enrolledLayers'],
      I.fromJS(createLayerObjectFromLayerKeys(selectedLayerKeys))
    )
    .set('supportedLayersForProduct', I.fromJS(organization!.get('supportedLayersBySource')));

  const fakeProjectJS: ApiProject = {
    id: 'fake-id',
    createdAt: 'fake-created-at',
    updatedAt: 'fake-updated-at',
    name: 'fake-project',
    organizationId: organization!.get('id'),
    isArchived: false,
    featureCollections: [{id: featureCollection.get('id'), kind: 'primary'}],
  };
  const fakeProject: I.ImmutableOf<ApiProject> = I.fromJS(fakeProjectJS);
  const projects: I.OrderedMap<string, I.ImmutableOf<ApiProject>> = I.OrderedMap([
    [fakeProject.get('id'), fakeProject],
  ]);
  const featureCollectionsById: I.Map<number, I.ImmutableOf<ApiFeatureCollection>> = I.OrderedMap([
    [featureCollection.get('id'), featureCollection],
  ]);

  return (
    <OverlaysProvider organization={organization!}>
      <B.Dialog isOpen={isOpen} onClose={() => onClose()} className={cs.selectLayersModal}>
        <LensLibraryProvider
          organization={organization!}
          profile={profile!}
          addLayersToFeatureCollections={async (
            _ids: number[],
            layers: Record<string, string[]>
          ) => {
            const layerKeysToAdd = createLayerKeysFromLayerObject(layers);
            setSelectedLayerKeys([...selectedLayerKeys, ...layerKeysToAdd]);
          }}
          removeLayersFromFeatureCollections={async (
            _ids: number[],
            layers: Record<string, string[]>
          ) => {
            const layerKeysToRemove = createLayerKeysFromLayerObject(layers);
            setSelectedLayerKeys(
              selectedLayerKeys.filter(
                (selectedLayerKey) => !layerKeysToRemove.includes(selectedLayerKey)
              )
            );
          }}
          selectedOverlayIds={selectedOverlayIds}
          setSelectedOverlayIds={setSelectedOverlayIds}
          mode={'singlePortfolio'}
          projects={projects}
          featureCollectionsById={featureCollectionsById}
          areProjectsLoading={false}
          onClose={onClose}
        />
      </B.Dialog>
    </OverlaysProvider>
  );
};
