import * as I from 'immutable';
import React from 'react';

import history from 'app/history';
import {
  useCurrentImageryContract,
  useImageryContracts,
  useImageryPurchases,
} from 'app/providers/ImageryContractProvider';
import * as routeUtils from 'app/utils/routeUtils';

import View, {Props} from './view';

const ProjectDashboardSwitchDataProvider: React.FunctionComponent<
  React.PropsWithChildren<
    Omit<Props, 'getImageryContracts' | 'getCurrentImageryContract' | 'getImageryPurchases'>
  >
> = (props) => {
  // Keep these at this level so that they can be shared by PropertyOverview and
  // MonitorProjectView.
  const [getImageryContracts] = useImageryContracts();
  const [getCurrentImageryContract] = useCurrentImageryContract();
  const [getImageryPurchases] = useImageryPurchases();

  return (
    <View
      {...props}
      getImageryContracts={getImageryContracts}
      getCurrentImageryContract={getCurrentImageryContract}
      getImageryPurchases={getImageryPurchases}
    />
  );
};

// HACK(fiona): This matches the behavior of Redux’s connect by not re-rendering
// the component if the props are all shallowly equal.
//
// Currently necessary to keep the overview page’s row-clicking working because
// of a number of issues with how it causes a re-render on row focus, which
// breaks click events due to issues with reconciliation (the mouseDown and
// mouseUp events end up happening in different identical DOM elements).
export default React.memo(ProjectDashboardSwitchDataProvider);

export interface FeatureSelection {
  organizationId: string;
  projectId: string;
  featureIds?: I.Set<number | string> | null;
}

/**
 * Helper function to navigate to the correct URL for a project/feature
 * collection/features location.
 */
export function changeSelection(selection: FeatureSelection) {
  const {organizationId, projectId, featureIds = I.Set<number | string>()} = selection;

  const queryString = history.location.search;

  const path =
    routeUtils.makeProjectDashboardUrl(
      organizationId,
      projectId,
      featureIds ? 'map' : undefined,
      featureIds?.toArray() ?? undefined
    ) + queryString;

  const currentPath = history.location.pathname + queryString;

  if (path != currentPath) {
    history.push(path);
  }
}
