/**
 * First element in the tuple is the offset from 0.0–1.0. Second element is the
 * CSS color, including the '#' for hex colors.
 *
 * Note that the offset is as you would color a graph from top to bottom, so the
 * color at offset 0 is the color that represents a value of 1.0 (if the range
 * is 0–1).
 *
 * See the `print_mapbox_color_map` function in colormaps.py for how to derive
 * gradient stops from a python colormap. Use the `reverse=True` kwarg so the values
 * line up with expectations here.
 */

// Scale gradient stops from [0, 1] to [vmin, vmax].
export function scaleGradientStops(
  stops: GradientStops,
  vmin: number,
  vmax: number
): GradientStops {
  const rangeDiff = vmax - vmin;
  return stops.map(([offset, color]) => [rangeDiff * offset + vmin, color]);
}

export type GradientStops = readonly Readonly<[number, string]>[];

export const DEFAULT_GRADIENT_STOPS: GradientStops = [
  [1, '#291bdd'],
  [1, '#291bdd'],
];

export const S1_WATER_GRADIENT_STOPS: GradientStops = [
  [0.0, '#221b38'],
  [0.004, '#221c39'],
  [0.008, '#221d3a'],
  [0.012, '#221e3a'],
  [0.016, '#221f3b'],
  [0.02, '#23203c'],
  [0.024, '#23213d'],
  [0.027, '#23223d'],
  [0.031, '#23233e'],
  [0.035, '#23243f'],
  [0.039, '#24253f'],
  [0.043, '#242640'],
  [0.047, '#242741'],
  [0.051, '#242742'],
  [0.055, '#242842'],
  [0.059, '#242943'],
  [0.063, '#252a44'],
  [0.067, '#252b45'],
  [0.071, '#252c45'],
  [0.075, '#252d46'],
  [0.078, '#252e47'],
  [0.082, '#252f48'],
  [0.086, '#253048'],
  [0.09, '#253149'],
  [0.094, '#25324a'],
  [0.098, '#25334b'],
  [0.102, '#25344c'],
  [0.106, '#25354c'],
  [0.11, '#25364d'],
  [0.114, '#25374e'],
  [0.118, '#25374f'],
  [0.122, '#253850'],
  [0.125, '#253951'],
  [0.129, '#253a51'],
  [0.133, '#243b52'],
  [0.137, '#243c53'],
  [0.141, '#243d54'],
  [0.145, '#243e55'],
  [0.149, '#243f56'],
  [0.153, '#234056'],
  [0.157, '#234157'],
  [0.161, '#234258'],
  [0.165, '#224359'],
  [0.169, '#22445a'],
  [0.173, '#21455a'],
  [0.176, '#21465b'],
  [0.18, '#21475c'],
  [0.184, '#20485d'],
  [0.188, '#1f485d'],
  [0.192, '#1f495e'],
  [0.196, '#1e4a5f'],
  [0.2, '#1e4b5f'],
  [0.204, '#1d4c60'],
  [0.208, '#1c4d61'],
  [0.212, '#1c4e61'],
  [0.216, '#1b4f62'],
  [0.22, '#1a5063'],
  [0.224, '#1a5163'],
  [0.227, '#195264'],
  [0.231, '#185364'],
  [0.235, '#175465'],
  [0.239, '#165565'],
  [0.243, '#165666'],
  [0.247, '#155766'],
  [0.251, '#145867'],
  [0.255, '#135967'],
  [0.259, '#125a67'],
  [0.263, '#115b68'],
  [0.267, '#105c68'],
  [0.271, '#0f5d69'],
  [0.275, '#0e5e69'],
  [0.278, '#0d5f69'],
  [0.282, '#0c5f6a'],
  [0.286, '#0b606a'],
  [0.29, '#0a616a'],
  [0.294, '#09626b'],
  [0.298, '#08636b'],
  [0.302, '#07646b'],
  [0.306, '#07656b'],
  [0.31, '#06666c'],
  [0.314, '#05676c'],
  [0.318, '#05686c'],
  [0.322, '#04696c'],
  [0.325, '#046a6c'],
  [0.329, '#046b6d'],
  [0.333, '#046b6d'],
  [0.337, '#036c6d'],
  [0.341, '#036d6d'],
  [0.345, '#036e6d'],
  [0.349, '#036f6d'],
  [0.353, '#04706d'],
  [0.357, '#04716e'],
  [0.361, '#04726e'],
  [0.365, '#05736e'],
  [0.369, '#05746e'],
  [0.373, '#06746e'],
  [0.376, '#06756e'],
  [0.38, '#07766e'],
  [0.384, '#08776e'],
  [0.388, '#09786e'],
  [0.392, '#0a796e'],
  [0.396, '#0b7a6e'],
  [0.4, '#0c7b6e'],
  [0.404, '#0e7c6e'],
  [0.408, '#0f7c6e'],
  [0.412, '#117d6e'],
  [0.416, '#127e6e'],
  [0.42, '#147f6e'],
  [0.424, '#15806e'],
  [0.427, '#17816e'],
  [0.431, '#19816e'],
  [0.435, '#1b826e'],
  [0.439, '#1c836e'],
  [0.443, '#1e846e'],
  [0.447, '#20856e'],
  [0.451, '#22866e'],
  [0.455, '#24866e'],
  [0.459, '#26876e'],
  [0.463, '#29886e'],
  [0.467, '#2b896e'],
  [0.471, '#2d896e'],
  [0.475, '#2f8a6e'],
  [0.478, '#318b6e'],
  [0.482, '#348b6d'],
  [0.486, '#368c6d'],
  [0.49, '#388d6d'],
  [0.494, '#3a8d6d'],
  [0.498, '#3d8e6e'],
  [0.502, '#3f8f6e'],
  [0.506, '#418f6e'],
  [0.51, '#43906e'],
  [0.514, '#46916e'],
  [0.518, '#48916e'],
  [0.522, '#4a926e'],
  [0.525, '#4c936e'],
  [0.529, '#4f936e'],
  [0.533, '#51946f'],
  [0.537, '#53946f'],
  [0.541, '#55956f'],
  [0.545, '#57956f'],
  [0.549, '#599670'],
  [0.553, '#5b9770'],
  [0.557, '#5d9770'],
  [0.561, '#5f9871'],
  [0.565, '#619871'],
  [0.569, '#649971'],
  [0.573, '#659a72'],
  [0.576, '#679a72'],
  [0.58, '#699b72'],
  [0.584, '#6b9b73'],
  [0.588, '#6d9c73'],
  [0.592, '#6f9c74'],
  [0.596, '#719d74'],
  [0.6, '#739d75'],
  [0.604, '#759e75'],
  [0.608, '#779f75'],
  [0.612, '#799f76'],
  [0.616, '#7aa076'],
  [0.62, '#7ca077'],
  [0.624, '#7ea177'],
  [0.627, '#80a178'],
  [0.631, '#82a278'],
  [0.635, '#83a379'],
  [0.639, '#85a37a'],
  [0.643, '#87a47a'],
  [0.647, '#89a47b'],
  [0.651, '#8aa57b'],
  [0.655, '#8ca57c'],
  [0.659, '#8ea67c'],
  [0.663, '#90a77d'],
  [0.667, '#91a77d'],
  [0.671, '#93a87e'],
  [0.675, '#95a87e'],
  [0.678, '#96a97f'],
  [0.682, '#98a980'],
  [0.686, '#9aaa80'],
  [0.69, '#9caa81'],
  [0.694, '#9dab81'],
  [0.698, '#9fac82'],
  [0.702, '#a1ac82'],
  [0.706, '#a2ad83'],
  [0.71, '#a4ad84'],
  [0.714, '#a6ae84'],
  [0.718, '#a8ae85'],
  [0.722, '#a9af85'],
  [0.725, '#abb086'],
  [0.729, '#adb087'],
  [0.733, '#aeb187'],
  [0.737, '#b0b188'],
  [0.741, '#b2b289'],
  [0.745, '#b4b289'],
  [0.749, '#b5b38a'],
  [0.753, '#b7b38b'],
  [0.757, '#b9b48c'],
  [0.761, '#bab58d'],
  [0.765, '#bcb58d'],
  [0.769, '#beb68e'],
  [0.773, '#c0b68f'],
  [0.776, '#c1b790'],
  [0.78, '#c3b791'],
  [0.784, '#c4b892'],
  [0.788, '#c6b994'],
  [0.792, '#c8b995'],
  [0.796, '#c9ba96'],
  [0.8, '#cbba98'],
  [0.804, '#ccbb99'],
  [0.808, '#cdbc9b'],
  [0.812, '#cfbc9c'],
  [0.816, '#d0bd9e'],
  [0.82, '#d1bea0'],
  [0.824, '#d2bfa2'],
  [0.827, '#d3bfa4'],
  [0.831, '#d4c0a6'],
  [0.835, '#d5c1a8'],
  [0.839, '#d5c2aa'],
  [0.843, '#d6c3ac'],
  [0.847, '#d7c4ae'],
  [0.851, '#d7c5b0'],
  [0.855, '#d8c6b2'],
  [0.859, '#d8c7b4'],
  [0.863, '#d9c8b6'],
  [0.867, '#dac9b8'],
  [0.871, '#dacaba'],
  [0.875, '#dbcbbc'],
  [0.878, '#dbccbe'],
  [0.882, '#dbcdbf'],
  [0.886, '#dccec1'],
  [0.89, '#dccfc3'],
  [0.894, '#ddd0c5'],
  [0.898, '#ddd1c7'],
  [0.902, '#ded3c9'],
  [0.906, '#ded4cb'],
  [0.91, '#dfd5cd'],
  [0.914, '#dfd6cf'],
  [0.918, '#e0d7d1'],
  [0.922, '#e0d8d3'],
  [0.925, '#e1d9d4'],
  [0.929, '#e2dad6'],
  [0.933, '#e2dbd8'],
  [0.937, '#e3dcda'],
  [0.941, '#e3dddc'],
  [0.945, '#e4dedd'],
  [0.949, '#e4dfdf'],
  [0.953, '#e5e1e0'],
  [0.957, '#e6e2e2'],
  [0.961, '#e7e3e3'],
  [0.965, '#e8e4e5'],
  [0.969, '#e8e5e6'],
  [0.973, '#e9e6e8'],
  [0.976, '#eae7e9'],
  [0.98, '#ebe8eb'],
  [0.984, '#ebe9ec'],
  [0.988, '#eceaee'],
  [0.992, '#edebf0'],
  [0.996, '#eeecf1'],
  [1.0, '#eeedf3'],
];

export const NDVI_GRADIENT_STOPS: GradientStops = [
  [0.0, '#011d01'],
  [0.01, '#012001'],
  [0.02, '#012301'],
  [0.03, '#012601'],
  [0.04, '#012901'],
  [0.051, '#012c01'],
  [0.061, '#012f01'],
  [0.071, '#013201'],
  [0.081, '#013401'],
  [0.091, '#023601'],
  [0.101, '#023901'],
  [0.111, '#023b01'],
  [0.121, '#023e01'],
  [0.131, '#014101'],
  [0.141, '#014400'],
  [0.152, '#014700'],
  [0.162, '#004a00'],
  [0.172, '#004e00'],
  [0.182, '#015200'],
  [0.192, '#025600'],
  [0.202, '#035a01'],
  [0.212, '#045e01'],
  [0.222, '#056201'],
  [0.232, '#0a6501'],
  [0.242, '#0f6901'],
  [0.253, '#146c01'],
  [0.263, '#196f01'],
  [0.273, '#1e7201'],
  [0.283, '#237601'],
  [0.293, '#287901'],
  [0.303, '#2e7c01'],
  [0.313, '#337f01'],
  [0.323, '#398301'],
  [0.333, '#3e8601'],
  [0.343, '#428901'],
  [0.354, '#458b01'],
  [0.364, '#498e00'],
  [0.374, '#4d9000'],
  [0.384, '#509300'],
  [0.394, '#549500'],
  [0.404, '#579700'],
  [0.414, '#5b9900'],
  [0.424, '#5f9c00'],
  [0.434, '#629e00'],
  [0.444, '#66a000'],
  [0.455, '#69a200'],
  [0.465, '#6ba300'],
  [0.475, '#6ea501'],
  [0.485, '#70a701'],
  [0.495, '#73a801'],
  [0.505, '#77aa03'],
  [0.515, '#7ead07'],
  [0.525, '#85af0b'],
  [0.535, '#8cb210'],
  [0.545, '#92b414'],
  [0.556, '#99b718'],
  [0.566, '#9fb91d'],
  [0.576, '#a5ba21'],
  [0.586, '#abbc26'],
  [0.596, '#b1be2a'],
  [0.606, '#b7bf2f'],
  [0.616, '#bdc133'],
  [0.626, '#c3c238'],
  [0.636, '#c9c43c'],
  [0.646, '#cfc541'],
  [0.657, '#d5c745'],
  [0.667, '#dbc84a'],
  [0.677, '#e1ca4f'],
  [0.687, '#e7cb53'],
  [0.697, '#edcd58'],
  [0.707, '#f3cf5c'],
  [0.717, '#f9d061'],
  [0.727, '#fcd062'],
  [0.737, '#fbcd61'],
  [0.747, '#facb60'],
  [0.758, '#f9c85f'],
  [0.768, '#f8c65d'],
  [0.778, '#f7c35c'],
  [0.788, '#f6c05b'],
  [0.798, '#f5be59'],
  [0.808, '#f4bb58'],
  [0.818, '#f3b957'],
  [0.828, '#f2b656'],
  [0.838, '#efb253'],
  [0.848, '#ecab4e'],
  [0.859, '#e9a54a'],
  [0.869, '#e69f46'],
  [0.879, '#e29841'],
  [0.889, '#df923d'],
  [0.899, '#dc8e3e'],
  [0.909, '#d98b40'],
  [0.919, '#d68741'],
  [0.929, '#d38343'],
  [0.939, '#d08044'],
  [0.949, '#d28a56'],
  [0.96, '#dba178'],
  [0.97, '#e4b99a'],
  [0.98, '#edd0bb'],
  [0.99, '#f6e8dd'],
  [1.0, '#ffffff'],
];

export const NDWI_GRADIENT_STOPS: GradientStops = [
  [0.0, '#0d0887'],
  [0.2, '#6a00a8'],
  [0.4, '#b12a90'],
  [0.6, '#e16462'],
  [0.8, '#fca636'],
  [1.0, '#f0f921'],
];

export const S2_NDWI_GRADIENT_STOPS: GradientStops = [
  [0, '#221b38'],
  [0.004, '#221c39'],
  [0.008, '#221d3a'],
  [0.012, '#221e3a'],
  [0.016, '#221f3b'],
  [0.02, '#23203c'],
  [0.024, '#23213d'],
  [0.027, '#23223d'],
  [0.031, '#23233e'],
  [0.035, '#23243f'],
  [0.039, '#24253f'],
  [0.043, '#242640'],
  [0.047, '#242741'],
  [0.051, '#242742'],
  [0.055, '#242842'],
  [0.059, '#242943'],
  [0.063, '#252a44'],
  [0.067, '#252b45'],
  [0.071, '#252c45'],
  [0.075, '#252d46'],
  [0.078, '#252e47'],
  [0.082, '#252f48'],
  [0.086, '#253048'],
  [0.09, '#253149'],
  [0.094, '#25324a'],
  [0.098, '#25334b'],
  [0.102, '#25344c'],
  [0.106, '#25354c'],
  [0.11, '#25364d'],
  [0.114, '#25374e'],
  [0.118, '#25374f'],
  [0.122, '#253850'],
  [0.125, '#253951'],
  [0.129, '#253a51'],
  [0.133, '#243b52'],
  [0.137, '#243c53'],
  [0.141, '#243d54'],
  [0.145, '#243e55'],
  [0.149, '#243f56'],
  [0.153, '#234056'],
  [0.157, '#234157'],
  [0.161, '#234258'],
  [0.165, '#224359'],
  [0.169, '#22445a'],
  [0.173, '#21455a'],
  [0.176, '#21465b'],
  [0.18, '#21475c'],
  [0.184, '#20485d'],
  [0.188, '#1f485d'],
  [0.192, '#1f495e'],
  [0.196, '#1e4a5f'],
  [0.2, '#1e4b5f'],
  [0.204, '#1d4c60'],
  [0.208, '#1c4d61'],
  [0.212, '#1c4e61'],
  [0.216, '#1b4f62'],
  [0.22, '#1a5063'],
  [0.224, '#1a5163'],
  [0.227, '#195264'],
  [0.231, '#185364'],
  [0.235, '#175465'],
  [0.239, '#165565'],
  [0.243, '#165666'],
  [0.247, '#155766'],
  [0.251, '#145867'],
  [0.255, '#135967'],
  [0.259, '#125a67'],
  [0.263, '#115b68'],
  [0.267, '#105c68'],
  [0.271, '#0f5d69'],
  [0.275, '#0e5e69'],
  [0.278, '#0d5f69'],
  [0.282, '#0c5f6a'],
  [0.286, '#0b606a'],
  [0.29, '#0a616a'],
  [0.294, '#09626b'],
  [0.298, '#08636b'],
  [0.302, '#07646b'],
  [0.306, '#07656b'],
  [0.31, '#06666c'],
  [0.314, '#05676c'],
  [0.318, '#05686c'],
  [0.322, '#04696c'],
  [0.325, '#046a6c'],
  [0.329, '#046b6d'],
  [0.333, '#046b6d'],
  [0.337, '#036c6d'],
  [0.341, '#036d6d'],
  [0.345, '#036e6d'],
  [0.349, '#036f6d'],
  [0.353, '#04706d'],
  [0.357, '#04716e'],
  [0.361, '#04726e'],
  [0.365, '#05736e'],
  [0.369, '#05746e'],
  [0.373, '#06746e'],
  [0.376, '#06756e'],
  [0.38, '#07766e'],
  [0.384, '#08776e'],
  [0.388, '#09786e'],
  [0.392, '#0a796e'],
  [0.396, '#0b7a6e'],
  [0.4, '#0c7b6e'],
  [0.404, '#0e7c6e'],
  [0.408, '#0f7c6e'],
  [0.412, '#117d6e'],
  [0.416, '#127e6e'],
  [0.42, '#147f6e'],
  [0.424, '#15806e'],
  [0.427, '#17816e'],
  [0.431, '#19816e'],
  [0.435, '#1b826e'],
  [0.439, '#1c836e'],
  [0.443, '#1e846e'],
  [0.447, '#20856e'],
  [0.451, '#22866e'],
  [0.455, '#24866e'],
  [0.459, '#26876e'],
  [0.463, '#29886e'],
  [0.467, '#2b896e'],
  [0.471, '#2d896e'],
  [0.475, '#2f8a6e'],
  [0.478, '#318b6e'],
  [0.482, '#348b6d'],
  [0.486, '#368c6d'],
  [0.49, '#388d6d'],
  [0.494, '#3a8d6d'],
  [0.498, '#3d8e6e'],
  [0.502, '#3f8f6e'],
  [0.506, '#418f6e'],
  [0.51, '#43906e'],
  [0.514, '#46916e'],
  [0.518, '#48916e'],
  [0.522, '#4a926e'],
  [0.525, '#4c936e'],
  [0.529, '#4f936e'],
  [0.533, '#51946f'],
  [0.537, '#53946f'],
  [0.541, '#55956f'],
  [0.545, '#57956f'],
  [0.549, '#599670'],
  [0.553, '#5b9770'],
  [0.557, '#5d9770'],
  [0.561, '#5f9871'],
  [0.565, '#619871'],
  [0.569, '#649971'],
  [0.573, '#659a72'],
  [0.576, '#679a72'],
  [0.58, '#699b72'],
  [0.584, '#6b9b73'],
  [0.588, '#6d9c73'],
  [0.592, '#6f9c74'],
  [0.596, '#719d74'],
  [0.6, '#739d75'],
  [0.604, '#759e75'],
  [0.608, '#779f75'],
  [0.612, '#799f76'],
  [0.616, '#7aa076'],
  [0.62, '#7ca077'],
  [0.624, '#7ea177'],
  [0.627, '#80a178'],
  [0.631, '#82a278'],
  [0.635, '#83a379'],
  [0.639, '#85a37a'],
  [0.643, '#87a47a'],
  [0.647, '#89a47b'],
  [0.651, '#8aa57b'],
  [0.655, '#8ca57c'],
  [0.659, '#8ea67c'],
  [0.663, '#90a77d'],
  [0.667, '#91a77d'],
  [0.671, '#93a87e'],
  [0.675, '#95a87e'],
  [0.678, '#96a97f'],
  [0.682, '#98a980'],
  [0.686, '#9aaa80'],
  [0.69, '#9caa81'],
  [0.694, '#9dab81'],
  [0.698, '#9fac82'],
  [0.702, '#a1ac82'],
  [0.706, '#a2ad83'],
  [0.71, '#a4ad84'],
  [0.714, '#a6ae84'],
  [0.718, '#a8ae85'],
  [0.722, '#a9af85'],
  [0.725, '#abb086'],
  [0.729, '#adb087'],
  [0.733, '#aeb187'],
  [0.737, '#b0b188'],
  [0.741, '#b2b289'],
  [0.745, '#b4b289'],
  [0.749, '#b5b38a'],
  [0.753, '#b7b38b'],
  [0.757, '#b9b48c'],
  [0.761, '#bab58d'],
  [0.765, '#bcb58d'],
  [0.769, '#beb68e'],
  [0.773, '#c0b68f'],
  [0.776, '#c1b790'],
  [0.78, '#c3b791'],
  [0.784, '#c4b892'],
  [0.788, '#c6b994'],
  [0.792, '#c8b995'],
  [0.796, '#c9ba96'],
  [0.8, '#cbba98'],
  [0.804, '#ccbb99'],
  [0.808, '#cdbc9b'],
  [0.812, '#cfbc9c'],
  [0.816, '#d0bd9e'],
  [0.82, '#d1bea0'],
  [0.824, '#d2bfa2'],
  [0.827, '#d3bfa4'],
  [0.831, '#d4c0a6'],
  [0.835, '#d5c1a8'],
  [0.839, '#d5c2aa'],
  [0.843, '#d6c3ac'],
  [0.847, '#d7c4ae'],
  [0.851, '#d7c5b0'],
  [0.855, '#d8c6b2'],
  [0.859, '#d8c7b4'],
  [0.863, '#d9c8b6'],
  [0.867, '#dac9b8'],
  [0.871, '#dacaba'],
  [0.875, '#dbcbbc'],
  [0.878, '#dbccbe'],
  [0.882, '#dbcdbf'],
  [0.886, '#dccec1'],
  [0.89, '#dccfc3'],
  [0.894, '#ddd0c5'],
  [0.898, '#ddd1c7'],
  [0.902, '#ded3c9'],
  [0.906, '#ded4cb'],
  [0.91, '#dfd5cd'],
  [0.914, '#dfd6cf'],
  [0.918, '#e0d7d1'],
  [0.922, '#e0d8d3'],
  [0.925, '#e1d9d4'],
  [0.929, '#e2dad6'],
  [0.933, '#e2dbd8'],
  [0.937, '#e3dcda'],
  [0.941, '#e3dddc'],
  [0.945, '#e4dedd'],
  [0.949, '#e4dfdf'],
  [0.953, '#e5e1e0'],
  [0.957, '#e6e2e2'],
  [0.961, '#e7e3e3'],
  [0.965, '#e8e4e5'],
  [0.969, '#e8e5e6'],
  [0.973, '#e9e6e8'],
  [0.976, '#eae7e9'],
  [0.98, '#ebe8eb'],
  [0.984, '#ebe9ec'],
  [0.988, '#eceaee'],
  [0.992, '#edebf0'],
  [0.996, '#eeecf1'],
  [1, '#eeedf3'],
];

export const CMOCAEN_TEMPO_GRADIENT_STOPS: GradientStops = [
  [0.0, '#151d44'],
  [0.004, '#151e44'],
  [0.008, '#151f45'],
  [0.012, '#162045'],
  [0.016, '#162146'],
  [0.02, '#162246'],
  [0.024, '#162347'],
  [0.027, '#172447'],
  [0.031, '#172548'],
  [0.035, '#172648'],
  [0.039, '#172749'],
  [0.043, '#17284a'],
  [0.047, '#18294a'],
  [0.051, '#182a4b'],
  [0.055, '#182b4b'],
  [0.059, '#182c4c'],
  [0.063, '#182d4c'],
  [0.067, '#192e4d'],
  [0.071, '#192f4d'],
  [0.075, '#19304e'],
  [0.078, '#19314f'],
  [0.082, '#19324f'],
  [0.086, '#1a3350'],
  [0.09, '#1a3450'],
  [0.094, '#1a3551'],
  [0.098, '#1a3651'],
  [0.102, '#1a3652'],
  [0.106, '#1a3753'],
  [0.11, '#1b3853'],
  [0.114, '#1b3954'],
  [0.118, '#1b3a54'],
  [0.122, '#1b3b55'],
  [0.125, '#1b3c56'],
  [0.129, '#1b3d56'],
  [0.133, '#1b3e57'],
  [0.137, '#1b3f57'],
  [0.141, '#1c4058'],
  [0.145, '#1c4158'],
  [0.149, '#1c4259'],
  [0.153, '#1c425a'],
  [0.157, '#1c435a'],
  [0.161, '#1c445b'],
  [0.165, '#1c455b'],
  [0.169, '#1c465c'],
  [0.173, '#1c475d'],
  [0.176, '#1c485d'],
  [0.18, '#1c495e'],
  [0.184, '#1c4a5e'],
  [0.188, '#1c4b5f'],
  [0.192, '#1c4c5f'],
  [0.196, '#1c4c60'],
  [0.2, '#1c4d61'],
  [0.204, '#1c4e61'],
  [0.208, '#1c4f62'],
  [0.212, '#1c5062'],
  [0.216, '#1c5163'],
  [0.22, '#1c5263'],
  [0.224, '#1c5364'],
  [0.227, '#1c5465'],
  [0.231, '#1c5565'],
  [0.235, '#1b5666'],
  [0.239, '#1b5766'],
  [0.243, '#1b5867'],
  [0.247, '#1b5867'],
  [0.251, '#1b5968'],
  [0.255, '#1b5a68'],
  [0.259, '#1a5b69'],
  [0.263, '#1a5c6a'],
  [0.267, '#1a5d6a'],
  [0.271, '#1a5e6b'],
  [0.275, '#1a5f6b'],
  [0.278, '#19606c'],
  [0.282, '#19616c'],
  [0.286, '#19626d'],
  [0.29, '#19636d'],
  [0.294, '#18646e'],
  [0.298, '#18656e'],
  [0.302, '#18656f'],
  [0.306, '#17666f'],
  [0.31, '#176770'],
  [0.314, '#176870'],
  [0.318, '#166971'],
  [0.322, '#166a71'],
  [0.325, '#166b72'],
  [0.329, '#156c72'],
  [0.333, '#156d73'],
  [0.337, '#146e73'],
  [0.341, '#146f73'],
  [0.345, '#147074'],
  [0.349, '#137174'],
  [0.353, '#137275'],
  [0.357, '#137375'],
  [0.361, '#127476'],
  [0.365, '#127576'],
  [0.369, '#127676'],
  [0.373, '#117677'],
  [0.376, '#117777'],
  [0.38, '#117878'],
  [0.384, '#117978'],
  [0.388, '#117a78'],
  [0.392, '#117b79'],
  [0.396, '#117c79'],
  [0.4, '#117d79'],
  [0.404, '#117e7a'],
  [0.408, '#117f7a'],
  [0.412, '#11807a'],
  [0.416, '#12817b'],
  [0.42, '#12827b'],
  [0.424, '#13837b'],
  [0.427, '#13847b'],
  [0.431, '#14847c'],
  [0.435, '#15857c'],
  [0.439, '#16867c'],
  [0.443, '#16877c'],
  [0.447, '#17887d'],
  [0.451, '#19897d'],
  [0.455, '#1a8a7d'],
  [0.459, '#1b8b7d'],
  [0.463, '#1c8c7e'],
  [0.467, '#1e8d7e'],
  [0.471, '#1f8d7e'],
  [0.475, '#218e7e'],
  [0.478, '#228f7e'],
  [0.482, '#24907f'],
  [0.486, '#25917f'],
  [0.49, '#27927f'],
  [0.494, '#29937f'],
  [0.498, '#2a937f'],
  [0.502, '#2c947f'],
  [0.506, '#2e9580'],
  [0.51, '#309680'],
  [0.514, '#329780'],
  [0.518, '#349880'],
  [0.522, '#369880'],
  [0.525, '#389981'],
  [0.529, '#3a9a81'],
  [0.533, '#3b9b81'],
  [0.537, '#3d9c81'],
  [0.541, '#3f9c81'],
  [0.545, '#419d82'],
  [0.549, '#439e82'],
  [0.553, '#459f82'],
  [0.557, '#479f82'],
  [0.561, '#49a083'],
  [0.565, '#4ba183'],
  [0.569, '#4ea183'],
  [0.573, '#50a284'],
  [0.576, '#52a384'],
  [0.58, '#54a484'],
  [0.584, '#56a485'],
  [0.588, '#57a585'],
  [0.592, '#59a685'],
  [0.596, '#5ba686'],
  [0.6, '#5da786'],
  [0.604, '#5fa887'],
  [0.608, '#61a987'],
  [0.612, '#63a988'],
  [0.616, '#65aa88'],
  [0.62, '#67ab89'],
  [0.624, '#69ab89'],
  [0.627, '#6bac8a'],
  [0.631, '#6dad8a'],
  [0.635, '#6fad8b'],
  [0.639, '#71ae8b'],
  [0.643, '#72af8c'],
  [0.647, '#74af8d'],
  [0.651, '#76b08d'],
  [0.655, '#78b18e'],
  [0.659, '#7ab18e'],
  [0.663, '#7cb28f'],
  [0.667, '#7db390'],
  [0.671, '#7fb390'],
  [0.675, '#81b491'],
  [0.678, '#83b592'],
  [0.682, '#85b593'],
  [0.686, '#86b693'],
  [0.69, '#88b794'],
  [0.694, '#8ab795'],
  [0.698, '#8bb896'],
  [0.702, '#8db997'],
  [0.706, '#8fba97'],
  [0.71, '#91ba98'],
  [0.714, '#92bb99'],
  [0.718, '#94bc9a'],
  [0.722, '#96bc9b'],
  [0.725, '#97bd9c'],
  [0.729, '#99be9d'],
  [0.733, '#9bbe9e'],
  [0.737, '#9cbf9f'],
  [0.741, '#9ec09f'],
  [0.745, '#a0c0a0'],
  [0.749, '#a1c1a1'],
  [0.753, '#a3c2a2'],
  [0.757, '#a4c3a3'],
  [0.761, '#a6c3a4'],
  [0.765, '#a8c4a5'],
  [0.769, '#a9c5a6'],
  [0.773, '#abc5a8'],
  [0.776, '#acc6a9'],
  [0.78, '#aec7aa'],
  [0.784, '#b0c8ab'],
  [0.788, '#b1c8ac'],
  [0.792, '#b3c9ad'],
  [0.796, '#b4caae'],
  [0.8, '#b6cbaf'],
  [0.804, '#b7cbb0'],
  [0.808, '#b9ccb2'],
  [0.812, '#bacdb3'],
  [0.816, '#bcceb4'],
  [0.82, '#bdceb5'],
  [0.824, '#bfcfb6'],
  [0.827, '#c0d0b7'],
  [0.831, '#c2d1b9'],
  [0.835, '#c3d1ba'],
  [0.839, '#c5d2bb'],
  [0.843, '#c6d3bc'],
  [0.847, '#c8d4be'],
  [0.851, '#c9d4bf'],
  [0.855, '#cbd5c0'],
  [0.859, '#ccd6c1'],
  [0.863, '#ced7c3'],
  [0.867, '#cfd8c4'],
  [0.871, '#d1d8c5'],
  [0.875, '#d2d9c7'],
  [0.878, '#d3dac8'],
  [0.882, '#d5dbc9'],
  [0.886, '#d6dccb'],
  [0.89, '#d8ddcc'],
  [0.894, '#d9ddcd'],
  [0.898, '#dbdecf'],
  [0.902, '#dcdfd0'],
  [0.906, '#dde0d1'],
  [0.91, '#dfe1d3'],
  [0.914, '#e0e2d4'],
  [0.918, '#e2e2d6'],
  [0.922, '#e3e3d7'],
  [0.925, '#e4e4d8'],
  [0.929, '#e6e5da'],
  [0.933, '#e7e6db'],
  [0.937, '#e9e7dd'],
  [0.941, '#eae8de'],
  [0.945, '#ebe9df'],
  [0.949, '#edeae1'],
  [0.953, '#eeeae2'],
  [0.957, '#f0ebe4'],
  [0.961, '#f1ece5'],
  [0.965, '#f2ede7'],
  [0.969, '#f4eee8'],
  [0.973, '#f5efea'],
  [0.976, '#f7f0eb'],
  [0.98, '#f8f1ed'],
  [0.984, '#f9f2ee'],
  [0.988, '#fbf3f0'],
  [0.992, '#fcf4f1'],
  [0.996, '#fdf5f3'],
  [1.0, '#fff6f4'],
];

// 'Greens' from matplotlib.
export const GREENS_GRADIENT_STOPS: GradientStops = [
  [0.0, '#00441b'],
  [0.004, '#00451c'],
  [0.008, '#00471c'],
  [0.012, '#00481d'],
  [0.016, '#00491d'],
  [0.02, '#004a1e'],
  [0.024, '#004c1e'],
  [0.027, '#004d1f'],
  [0.031, '#004e1f'],
  [0.035, '#005020'],
  [0.039, '#005120'],
  [0.043, '#005221'],
  [0.047, '#005321'],
  [0.051, '#005522'],
  [0.055, '#005622'],
  [0.059, '#005723'],
  [0.063, '#005924'],
  [0.067, '#005a24'],
  [0.071, '#005b25'],
  [0.075, '#005c25'],
  [0.078, '#005e26'],
  [0.082, '#005f26'],
  [0.086, '#006027'],
  [0.09, '#006227'],
  [0.094, '#006328'],
  [0.098, '#006428'],
  [0.102, '#006529'],
  [0.106, '#006729'],
  [0.11, '#00682a'],
  [0.114, '#00692a'],
  [0.118, '#006b2b'],
  [0.122, '#006c2c'],
  [0.125, '#006d2c'],
  [0.129, '#016e2d'],
  [0.133, '#026f2e'],
  [0.137, '#03702e'],
  [0.141, '#05712f'],
  [0.145, '#067230'],
  [0.149, '#077331'],
  [0.153, '#087432'],
  [0.157, '#097532'],
  [0.161, '#0a7633'],
  [0.165, '#0b7734'],
  [0.169, '#0c7735'],
  [0.173, '#0d7836'],
  [0.176, '#0e7936'],
  [0.18, '#107a37'],
  [0.184, '#117b38'],
  [0.188, '#127c39'],
  [0.192, '#137d39'],
  [0.196, '#147e3a'],
  [0.2, '#157f3b'],
  [0.204, '#16803c'],
  [0.208, '#17813d'],
  [0.212, '#18823d'],
  [0.216, '#19833e'],
  [0.22, '#1a843f'],
  [0.224, '#1c8540'],
  [0.227, '#1d8640'],
  [0.231, '#1e8741'],
  [0.235, '#1f8742'],
  [0.239, '#208843'],
  [0.243, '#218944'],
  [0.247, '#228a44'],
  [0.251, '#238b45'],
  [0.255, '#248c46'],
  [0.259, '#258d47'],
  [0.263, '#268e47'],
  [0.267, '#278f48'],
  [0.271, '#289049'],
  [0.275, '#29914a'],
  [0.278, '#2a924a'],
  [0.282, '#2b934b'],
  [0.286, '#2c944c'],
  [0.29, '#2d954d'],
  [0.294, '#2e964d'],
  [0.298, '#2f974e'],
  [0.302, '#2f984f'],
  [0.306, '#309950'],
  [0.31, '#319a50'],
  [0.314, '#329b51'],
  [0.318, '#339c52'],
  [0.322, '#349d53'],
  [0.325, '#359e53'],
  [0.329, '#369f54'],
  [0.333, '#37a055'],
  [0.337, '#38a156'],
  [0.341, '#39a257'],
  [0.345, '#3aa357'],
  [0.349, '#3ba458'],
  [0.353, '#3ca559'],
  [0.357, '#3da65a'],
  [0.361, '#3ea75a'],
  [0.365, '#3fa85b'],
  [0.369, '#3fa95c'],
  [0.373, '#40aa5d'],
  [0.376, '#42ab5d'],
  [0.38, '#43ac5e'],
  [0.384, '#45ad5f'],
  [0.388, '#46ae60'],
  [0.392, '#48ae60'],
  [0.396, '#4aaf61'],
  [0.4, '#4bb062'],
  [0.404, '#4db163'],
  [0.408, '#4eb264'],
  [0.412, '#50b264'],
  [0.416, '#52b365'],
  [0.42, '#53b466'],
  [0.424, '#55b567'],
  [0.427, '#56b567'],
  [0.431, '#58b668'],
  [0.435, '#5ab769'],
  [0.439, '#5bb86a'],
  [0.443, '#5db96b'],
  [0.447, '#5eb96b'],
  [0.451, '#60ba6c'],
  [0.455, '#62bb6d'],
  [0.459, '#63bc6e'],
  [0.463, '#65bd6f'],
  [0.467, '#66bd6f'],
  [0.471, '#68be70'],
  [0.475, '#6abf71'],
  [0.478, '#6bc072'],
  [0.482, '#6dc072'],
  [0.486, '#6ec173'],
  [0.49, '#70c274'],
  [0.494, '#72c375'],
  [0.498, '#73c476'],
  [0.502, '#75c477'],
  [0.506, '#76c578'],
  [0.51, '#78c679'],
  [0.514, '#79c67a'],
  [0.518, '#7ac77b'],
  [0.522, '#7cc87c'],
  [0.525, '#7dc87e'],
  [0.529, '#7fc97f'],
  [0.533, '#80ca80'],
  [0.537, '#81ca81'],
  [0.541, '#83cb82'],
  [0.545, '#84cc83'],
  [0.549, '#86cc85'],
  [0.553, '#87cd86'],
  [0.557, '#88ce87'],
  [0.561, '#8ace88'],
  [0.565, '#8bcf89'],
  [0.569, '#8dd08a'],
  [0.573, '#8ed08b'],
  [0.576, '#90d18d'],
  [0.58, '#91d28e'],
  [0.584, '#92d28f'],
  [0.588, '#94d390'],
  [0.592, '#95d391'],
  [0.596, '#97d492'],
  [0.6, '#98d594'],
  [0.604, '#99d595'],
  [0.608, '#9bd696'],
  [0.612, '#9cd797'],
  [0.616, '#9ed798'],
  [0.62, '#9fd899'],
  [0.624, '#a0d99b'],
  [0.627, '#a2d99c'],
  [0.631, '#a3da9d'],
  [0.635, '#a4da9e'],
  [0.639, '#a5db9f'],
  [0.643, '#a7dba0'],
  [0.647, '#a8dca2'],
  [0.651, '#a9dca3'],
  [0.655, '#aadda4'],
  [0.659, '#abdda5'],
  [0.663, '#acdea6'],
  [0.667, '#aedea7'],
  [0.671, '#afdfa8'],
  [0.675, '#b0dfaa'],
  [0.678, '#b1e0ab'],
  [0.682, '#b2e0ac'],
  [0.686, '#b4e1ad'],
  [0.69, '#b5e1ae'],
  [0.694, '#b6e2af'],
  [0.698, '#b7e2b1'],
  [0.702, '#b8e3b2'],
  [0.706, '#bae3b3'],
  [0.71, '#bbe4b4'],
  [0.714, '#bce4b5'],
  [0.718, '#bde5b6'],
  [0.722, '#bee5b8'],
  [0.725, '#c0e6b9'],
  [0.729, '#c1e6ba'],
  [0.733, '#c2e7bb'],
  [0.737, '#c3e7bc'],
  [0.741, '#c4e8bd'],
  [0.745, '#c6e8bf'],
  [0.749, '#c7e9c0'],
  [0.753, '#c8e9c1'],
  [0.757, '#c9eac2'],
  [0.761, '#caeac3'],
  [0.765, '#cbeac4'],
  [0.769, '#cbebc5'],
  [0.773, '#ccebc6'],
  [0.776, '#cdecc7'],
  [0.78, '#ceecc8'],
  [0.784, '#cfecc9'],
  [0.788, '#d0edca'],
  [0.792, '#d1edcb'],
  [0.796, '#d2edcc'],
  [0.8, '#d3eecd'],
  [0.804, '#d4eece'],
  [0.808, '#d5efcf'],
  [0.812, '#d6efd0'],
  [0.816, '#d7efd1'],
  [0.82, '#d8f0d2'],
  [0.824, '#d9f0d3'],
  [0.827, '#daf0d4'],
  [0.831, '#dbf1d5'],
  [0.835, '#dbf1d6'],
  [0.839, '#dcf2d7'],
  [0.843, '#ddf2d8'],
  [0.847, '#def2d9'],
  [0.851, '#dff3da'],
  [0.855, '#e0f3db'],
  [0.859, '#e1f3dc'],
  [0.863, '#e2f4dd'],
  [0.867, '#e3f4de'],
  [0.871, '#e4f5df'],
  [0.875, '#e5f5e0'],
  [0.878, '#e5f5e1'],
  [0.882, '#e6f5e1'],
  [0.886, '#e7f6e2'],
  [0.89, '#e7f6e3'],
  [0.894, '#e8f6e3'],
  [0.898, '#e8f6e4'],
  [0.902, '#e9f7e5'],
  [0.906, '#e9f7e5'],
  [0.91, '#eaf7e6'],
  [0.914, '#ebf7e7'],
  [0.918, '#ebf7e7'],
  [0.922, '#ecf8e8'],
  [0.925, '#ecf8e8'],
  [0.929, '#edf8e9'],
  [0.933, '#edf8ea'],
  [0.937, '#eef8ea'],
  [0.941, '#eff9eb'],
  [0.945, '#eff9ec'],
  [0.949, '#f0f9ec'],
  [0.953, '#f0f9ed'],
  [0.957, '#f1faee'],
  [0.961, '#f1faee'],
  [0.965, '#f2faef'],
  [0.969, '#f2faf0'],
  [0.973, '#f3faf0'],
  [0.976, '#f4fbf1'],
  [0.98, '#f4fbf2'],
  [0.984, '#f5fbf2'],
  [0.988, '#f5fbf3'],
  [0.992, '#f6fcf4'],
  [0.996, '#f6fcf4'],
  [1.0, '#f7fcf5'],
];

export const SALO_GREEN_VEGETATION_GRADIENT_STOPS: GradientStops = [
  [0.0, '#325900'],
  [0.25, '#8cb925'],
  [0.5, '#b3c54e'],
  [0.75, '#d4dc92'],
  [1.0, '#f9fae5'],
];

export const COOL_GRADIENT_STOPS: GradientStops = [
  [0.0, '#ff00ff'],
  [0.004, '#fe01ff'],
  [0.008, '#fd02ff'],
  [0.012, '#fc03ff'],
  [0.016, '#fb04ff'],
  [0.02, '#fa05ff'],
  [0.024, '#f906ff'],
  [0.027, '#f807ff'],
  [0.031, '#f708ff'],
  [0.035, '#f609ff'],
  [0.039, '#f50aff'],
  [0.043, '#f40bff'],
  [0.047, '#f30cff'],
  [0.051, '#f20dff'],
  [0.055, '#f10eff'],
  [0.059, '#f00fff'],
  [0.063, '#ef10ff'],
  [0.067, '#ee11ff'],
  [0.071, '#ed12ff'],
  [0.075, '#ec13ff'],
  [0.078, '#eb14ff'],
  [0.082, '#ea15ff'],
  [0.086, '#e916ff'],
  [0.09, '#e817ff'],
  [0.094, '#e718ff'],
  [0.098, '#e619ff'],
  [0.102, '#e51aff'],
  [0.106, '#e41bff'],
  [0.11, '#e31cff'],
  [0.114, '#e21dff'],
  [0.118, '#e11eff'],
  [0.122, '#e01fff'],
  [0.125, '#df20ff'],
  [0.129, '#de21ff'],
  [0.133, '#dd22ff'],
  [0.137, '#dc23ff'],
  [0.141, '#db24ff'],
  [0.145, '#da25ff'],
  [0.149, '#d926ff'],
  [0.153, '#d827ff'],
  [0.157, '#d728ff'],
  [0.161, '#d629ff'],
  [0.165, '#d52aff'],
  [0.169, '#d42bff'],
  [0.173, '#d32cff'],
  [0.176, '#d22dff'],
  [0.18, '#d12eff'],
  [0.184, '#d02fff'],
  [0.188, '#cf30ff'],
  [0.192, '#ce31ff'],
  [0.196, '#cd32ff'],
  [0.2, '#cc33ff'],
  [0.204, '#cb34ff'],
  [0.208, '#ca35ff'],
  [0.212, '#c936ff'],
  [0.216, '#c837ff'],
  [0.22, '#c738ff'],
  [0.224, '#c639ff'],
  [0.227, '#c53aff'],
  [0.231, '#c43bff'],
  [0.235, '#c33cff'],
  [0.239, '#c23dff'],
  [0.243, '#c13eff'],
  [0.247, '#c03fff'],
  [0.251, '#bf40ff'],
  [0.255, '#be41ff'],
  [0.259, '#bd42ff'],
  [0.263, '#bc43ff'],
  [0.267, '#bb44ff'],
  [0.271, '#ba45ff'],
  [0.275, '#b946ff'],
  [0.278, '#b847ff'],
  [0.282, '#b748ff'],
  [0.286, '#b649ff'],
  [0.29, '#b54aff'],
  [0.294, '#b44bff'],
  [0.298, '#b34cff'],
  [0.302, '#b24dff'],
  [0.306, '#b14eff'],
  [0.31, '#b04fff'],
  [0.314, '#af50ff'],
  [0.318, '#ae51ff'],
  [0.322, '#ad52ff'],
  [0.325, '#ac53ff'],
  [0.329, '#ab54ff'],
  [0.333, '#aa55ff'],
  [0.337, '#a956ff'],
  [0.341, '#a857ff'],
  [0.345, '#a758ff'],
  [0.349, '#a659ff'],
  [0.353, '#a55aff'],
  [0.357, '#a45bff'],
  [0.361, '#a35cff'],
  [0.365, '#a25dff'],
  [0.369, '#a15eff'],
  [0.373, '#a05fff'],
  [0.376, '#9f60ff'],
  [0.38, '#9e61ff'],
  [0.384, '#9d62ff'],
  [0.388, '#9c63ff'],
  [0.392, '#9b64ff'],
  [0.396, '#9a65ff'],
  [0.4, '#9966ff'],
  [0.404, '#9867ff'],
  [0.408, '#9768ff'],
  [0.412, '#9669ff'],
  [0.416, '#956aff'],
  [0.42, '#946bff'],
  [0.424, '#936cff'],
  [0.427, '#926dff'],
  [0.431, '#916eff'],
  [0.435, '#906fff'],
  [0.439, '#8f70ff'],
  [0.443, '#8e71ff'],
  [0.447, '#8d72ff'],
  [0.451, '#8c73ff'],
  [0.455, '#8b74ff'],
  [0.459, '#8a75ff'],
  [0.463, '#8976ff'],
  [0.467, '#8877ff'],
  [0.471, '#8778ff'],
  [0.475, '#8679ff'],
  [0.478, '#857aff'],
  [0.482, '#847bff'],
  [0.486, '#837cff'],
  [0.49, '#827dff'],
  [0.494, '#817eff'],
  [0.498, '#807fff'],
  [0.502, '#7f80ff'],
  [0.506, '#7e81ff'],
  [0.51, '#7d82ff'],
  [0.514, '#7c83ff'],
  [0.518, '#7b84ff'],
  [0.522, '#7a85ff'],
  [0.525, '#7986ff'],
  [0.529, '#7887ff'],
  [0.533, '#7788ff'],
  [0.537, '#7689ff'],
  [0.541, '#758aff'],
  [0.545, '#748bff'],
  [0.549, '#738cff'],
  [0.553, '#728dff'],
  [0.557, '#718eff'],
  [0.561, '#708fff'],
  [0.565, '#6f90ff'],
  [0.569, '#6e91ff'],
  [0.573, '#6d92ff'],
  [0.576, '#6c93ff'],
  [0.58, '#6b94ff'],
  [0.584, '#6a95ff'],
  [0.588, '#6996ff'],
  [0.592, '#6897ff'],
  [0.596, '#6798ff'],
  [0.6, '#6699ff'],
  [0.604, '#659aff'],
  [0.608, '#649bff'],
  [0.612, '#639cff'],
  [0.616, '#629dff'],
  [0.62, '#619eff'],
  [0.624, '#609fff'],
  [0.627, '#5fa0ff'],
  [0.631, '#5ea1ff'],
  [0.635, '#5da2ff'],
  [0.639, '#5ca3ff'],
  [0.643, '#5ba4ff'],
  [0.647, '#5aa5ff'],
  [0.651, '#59a6ff'],
  [0.655, '#58a7ff'],
  [0.659, '#57a8ff'],
  [0.663, '#56a9ff'],
  [0.667, '#55aaff'],
  [0.671, '#54abff'],
  [0.675, '#53acff'],
  [0.678, '#52adff'],
  [0.682, '#51aeff'],
  [0.686, '#50afff'],
  [0.69, '#4fb0ff'],
  [0.694, '#4eb1ff'],
  [0.698, '#4db2ff'],
  [0.702, '#4cb3ff'],
  [0.706, '#4bb4ff'],
  [0.71, '#4ab5ff'],
  [0.714, '#49b6ff'],
  [0.718, '#48b7ff'],
  [0.722, '#47b8ff'],
  [0.725, '#46b9ff'],
  [0.729, '#45baff'],
  [0.733, '#44bbff'],
  [0.737, '#43bcff'],
  [0.741, '#42bdff'],
  [0.745, '#41beff'],
  [0.749, '#40bfff'],
  [0.753, '#3fc0ff'],
  [0.757, '#3ec1ff'],
  [0.761, '#3dc2ff'],
  [0.765, '#3cc3ff'],
  [0.769, '#3bc4ff'],
  [0.773, '#3ac5ff'],
  [0.776, '#39c6ff'],
  [0.78, '#38c7ff'],
  [0.784, '#37c8ff'],
  [0.788, '#36c9ff'],
  [0.792, '#35caff'],
  [0.796, '#34cbff'],
  [0.8, '#33ccff'],
  [0.804, '#32cdff'],
  [0.808, '#31ceff'],
  [0.812, '#30cfff'],
  [0.816, '#2fd0ff'],
  [0.82, '#2ed1ff'],
  [0.824, '#2dd2ff'],
  [0.827, '#2cd3ff'],
  [0.831, '#2bd4ff'],
  [0.835, '#2ad5ff'],
  [0.839, '#29d6ff'],
  [0.843, '#28d7ff'],
  [0.847, '#27d8ff'],
  [0.851, '#26d9ff'],
  [0.855, '#25daff'],
  [0.859, '#24dbff'],
  [0.863, '#23dcff'],
  [0.867, '#22ddff'],
  [0.871, '#21deff'],
  [0.875, '#20dfff'],
  [0.878, '#1fe0ff'],
  [0.882, '#1ee1ff'],
  [0.886, '#1de2ff'],
  [0.89, '#1ce3ff'],
  [0.894, '#1be4ff'],
  [0.898, '#1ae5ff'],
  [0.902, '#19e6ff'],
  [0.906, '#18e7ff'],
  [0.91, '#17e8ff'],
  [0.914, '#16e9ff'],
  [0.918, '#15eaff'],
  [0.922, '#14ebff'],
  [0.925, '#13ecff'],
  [0.929, '#12edff'],
  [0.933, '#11eeff'],
  [0.937, '#10efff'],
  [0.941, '#0ff0ff'],
  [0.945, '#0ef1ff'],
  [0.949, '#0df2ff'],
  [0.953, '#0cf3ff'],
  [0.957, '#0bf4ff'],
  [0.961, '#0af5ff'],
  [0.965, '#09f6ff'],
  [0.969, '#08f7ff'],
  [0.973, '#07f8ff'],
  [0.976, '#06f9ff'],
  [0.98, '#05faff'],
  [0.984, '#04fbff'],
  [0.988, '#03fcff'],
  [0.992, '#02fdff'],
  [0.996, '#01feff'],
  [1.0, '#00ffff'],
];

export const VIRIDIS_GRADIENT_STOPS: GradientStops = [
  [0.0, '#fde725'],
  [0.004, '#fbe723'],
  [0.008, '#f8e621'],
  [0.012, '#f6e620'],
  [0.016, '#f4e61e'],
  [0.02, '#f1e51d'],
  [0.024, '#efe51c'],
  [0.027, '#ece51b'],
  [0.031, '#eae51a'],
  [0.035, '#e7e419'],
  [0.039, '#e5e419'],
  [0.043, '#e2e418'],
  [0.047, '#dfe318'],
  [0.051, '#dde318'],
  [0.055, '#dae319'],
  [0.059, '#d8e219'],
  [0.063, '#d5e21a'],
  [0.067, '#d2e21b'],
  [0.071, '#d0e11c'],
  [0.075, '#cde11d'],
  [0.078, '#cae11f'],
  [0.082, '#c8e020'],
  [0.086, '#c5e021'],
  [0.09, '#c2df23'],
  [0.094, '#c0df25'],
  [0.098, '#bddf26'],
  [0.102, '#bade28'],
  [0.106, '#b8de29'],
  [0.11, '#b5de2b'],
  [0.114, '#b2dd2d'],
  [0.118, '#b0dd2f'],
  [0.122, '#addc30'],
  [0.125, '#aadc32'],
  [0.129, '#a8db34'],
  [0.133, '#a5db36'],
  [0.137, '#a2da37'],
  [0.141, '#a0da39'],
  [0.145, '#9dd93b'],
  [0.149, '#9bd93c'],
  [0.153, '#98d83e'],
  [0.157, '#95d840'],
  [0.161, '#93d741'],
  [0.165, '#90d743'],
  [0.169, '#8ed645'],
  [0.173, '#8bd646'],
  [0.176, '#89d548'],
  [0.18, '#86d549'],
  [0.184, '#84d44b'],
  [0.188, '#81d34d'],
  [0.192, '#7fd34e'],
  [0.196, '#7cd250'],
  [0.2, '#7ad151'],
  [0.204, '#77d153'],
  [0.208, '#75d054'],
  [0.212, '#73d056'],
  [0.216, '#70cf57'],
  [0.22, '#6ece58'],
  [0.224, '#6ccd5a'],
  [0.227, '#69cd5b'],
  [0.231, '#67cc5c'],
  [0.235, '#65cb5e'],
  [0.239, '#63cb5f'],
  [0.243, '#60ca60'],
  [0.247, '#5ec962'],
  [0.251, '#5cc863'],
  [0.255, '#5ac864'],
  [0.259, '#58c765'],
  [0.263, '#56c667'],
  [0.267, '#54c568'],
  [0.271, '#52c569'],
  [0.275, '#50c46a'],
  [0.278, '#4ec36b'],
  [0.282, '#4cc26c'],
  [0.286, '#4ac16d'],
  [0.29, '#48c16e'],
  [0.294, '#46c06f'],
  [0.298, '#44bf70'],
  [0.302, '#42be71'],
  [0.306, '#40bd72'],
  [0.31, '#3fbc73'],
  [0.314, '#3dbc74'],
  [0.318, '#3bbb75'],
  [0.322, '#3aba76'],
  [0.325, '#38b977'],
  [0.329, '#37b878'],
  [0.333, '#35b779'],
  [0.337, '#34b679'],
  [0.341, '#32b67a'],
  [0.345, '#31b57b'],
  [0.349, '#2fb47c'],
  [0.353, '#2eb37c'],
  [0.357, '#2db27d'],
  [0.361, '#2cb17e'],
  [0.365, '#2ab07f'],
  [0.369, '#29af7f'],
  [0.373, '#28ae80'],
  [0.376, '#27ad81'],
  [0.38, '#26ad81'],
  [0.384, '#25ac82'],
  [0.388, '#25ab82'],
  [0.392, '#24aa83'],
  [0.396, '#23a983'],
  [0.4, '#22a884'],
  [0.404, '#22a785'],
  [0.408, '#21a685'],
  [0.412, '#21a585'],
  [0.416, '#20a486'],
  [0.42, '#20a386'],
  [0.424, '#1fa287'],
  [0.427, '#1fa187'],
  [0.431, '#1fa188'],
  [0.435, '#1fa088'],
  [0.439, '#1f9f88'],
  [0.443, '#1f9e89'],
  [0.447, '#1e9d89'],
  [0.451, '#1e9c89'],
  [0.455, '#1e9b8a'],
  [0.459, '#1f9a8a'],
  [0.463, '#1f998a'],
  [0.467, '#1f988b'],
  [0.471, '#1f978b'],
  [0.475, '#1f968b'],
  [0.478, '#1f958b'],
  [0.482, '#1f948c'],
  [0.486, '#20938c'],
  [0.49, '#20928c'],
  [0.494, '#20928c'],
  [0.498, '#21918c'],
  [0.502, '#21908d'],
  [0.506, '#218f8d'],
  [0.51, '#218e8d'],
  [0.514, '#228d8d'],
  [0.518, '#228c8d'],
  [0.522, '#228b8d'],
  [0.525, '#238a8d'],
  [0.529, '#23898e'],
  [0.533, '#23888e'],
  [0.537, '#24878e'],
  [0.541, '#24868e'],
  [0.545, '#25858e'],
  [0.549, '#25848e'],
  [0.553, '#25838e'],
  [0.557, '#26828e'],
  [0.561, '#26828e'],
  [0.565, '#26818e'],
  [0.569, '#27808e'],
  [0.573, '#277f8e'],
  [0.576, '#277e8e'],
  [0.58, '#287d8e'],
  [0.584, '#287c8e'],
  [0.588, '#297b8e'],
  [0.592, '#297a8e'],
  [0.596, '#29798e'],
  [0.6, '#2a788e'],
  [0.604, '#2a778e'],
  [0.608, '#2a768e'],
  [0.612, '#2b758e'],
  [0.616, '#2b748e'],
  [0.62, '#2c738e'],
  [0.624, '#2c728e'],
  [0.627, '#2c718e'],
  [0.631, '#2d718e'],
  [0.635, '#2d708e'],
  [0.639, '#2e6f8e'],
  [0.643, '#2e6e8e'],
  [0.647, '#2e6d8e'],
  [0.651, '#2f6c8e'],
  [0.655, '#2f6b8e'],
  [0.659, '#306a8e'],
  [0.663, '#30698e'],
  [0.667, '#31688e'],
  [0.671, '#31678e'],
  [0.675, '#31668e'],
  [0.678, '#32658e'],
  [0.682, '#32648e'],
  [0.686, '#33638d'],
  [0.69, '#33628d'],
  [0.694, '#34618d'],
  [0.698, '#34608d'],
  [0.702, '#355f8d'],
  [0.706, '#355e8d'],
  [0.71, '#365d8d'],
  [0.714, '#365c8d'],
  [0.718, '#375b8d'],
  [0.722, '#375a8c'],
  [0.725, '#38598c'],
  [0.729, '#38588c'],
  [0.733, '#39568c'],
  [0.737, '#39558c'],
  [0.741, '#3a548c'],
  [0.745, '#3a538b'],
  [0.749, '#3b528b'],
  [0.753, '#3b518b'],
  [0.757, '#3c508b'],
  [0.761, '#3c4f8a'],
  [0.765, '#3d4e8a'],
  [0.769, '#3d4d8a'],
  [0.773, '#3e4c8a'],
  [0.776, '#3e4a89'],
  [0.78, '#3e4989'],
  [0.784, '#3f4889'],
  [0.788, '#3f4788'],
  [0.792, '#404688'],
  [0.796, '#404588'],
  [0.8, '#414487'],
  [0.804, '#414287'],
  [0.808, '#424186'],
  [0.812, '#424086'],
  [0.816, '#423f85'],
  [0.82, '#433e85'],
  [0.824, '#433d84'],
  [0.827, '#443b84'],
  [0.831, '#443a83'],
  [0.835, '#443983'],
  [0.839, '#453882'],
  [0.843, '#453781'],
  [0.847, '#453581'],
  [0.851, '#463480'],
  [0.855, '#46337f'],
  [0.859, '#46327e'],
  [0.863, '#46307e'],
  [0.867, '#472f7d'],
  [0.871, '#472e7c'],
  [0.875, '#472d7b'],
  [0.878, '#472c7a'],
  [0.882, '#472a7a'],
  [0.886, '#482979'],
  [0.89, '#482878'],
  [0.894, '#482677'],
  [0.898, '#482576'],
  [0.902, '#482475'],
  [0.906, '#482374'],
  [0.91, '#482173'],
  [0.914, '#482071'],
  [0.918, '#481f70'],
  [0.922, '#481d6f'],
  [0.925, '#481c6e'],
  [0.929, '#481b6d'],
  [0.933, '#481a6c'],
  [0.937, '#48186a'],
  [0.941, '#481769'],
  [0.945, '#481668'],
  [0.949, '#481467'],
  [0.953, '#471365'],
  [0.957, '#471164'],
  [0.961, '#471063'],
  [0.965, '#470e61'],
  [0.969, '#470d60'],
  [0.973, '#460b5e'],
  [0.976, '#460a5d'],
  [0.98, '#46085c'],
  [0.984, '#46075a'],
  [0.988, '#450559'],
  [0.992, '#450457'],
  [0.996, '#440256'],
  [1.0, '#440154'],
];

export const SPACE_INTELLIGENCE_ABOVEGROUND_CARBON_GRADIENT_STOPS: GradientStops = [
  [0.0, '#d2e2bb'],
  [0.004, '#d3e0b8'],
  [0.008, '#d3e0b8'],
  [0.012, '#d4dfb6'],
  [0.016, '#d5ddb4'],
  [0.02, '#d5ddb4'],
  [0.024, '#d6dbb2'],
  [0.027, '#d8d9b1'],
  [0.031, '#d9d6af'],
  [0.035, '#d9d6af'],
  [0.039, '#dbd4ae'],
  [0.043, '#dcd2ad'],
  [0.047, '#dcd2ad'],
  [0.051, '#ded0ac'],
  [0.055, '#dfcdac'],
  [0.059, '#dfcdac'],
  [0.063, '#e1cbab'],
  [0.067, '#e2c8ab'],
  [0.071, '#e4c6ab'],
  [0.075, '#e4c6ab'],
  [0.078, '#e5c3ac'],
  [0.082, '#e6c1ac'],
  [0.086, '#e6c1ac'],
  [0.09, '#e8bfad'],
  [0.094, '#e9bcae'],
  [0.098, '#e9bcae'],
  [0.102, '#e9bab0'],
  [0.106, '#eab7b1'],
  [0.11, '#ebb5b3'],
  [0.114, '#ebb5b3'],
  [0.118, '#ebb3b5'],
  [0.122, '#ebb1b7'],
  [0.125, '#ebb1b7'],
  [0.129, '#ebafb9'],
  [0.133, '#ebadbb'],
  [0.137, '#ebadbb'],
  [0.141, '#eaabbd'],
  [0.145, '#eaa9c0'],
  [0.149, '#e9a7c2'],
  [0.153, '#e9a7c2'],
  [0.157, '#e7a6c5'],
  [0.161, '#e6a5c8'],
  [0.165, '#e6a5c8'],
  [0.169, '#e4a3ca'],
  [0.173, '#e2a2cd'],
  [0.176, '#e0a1cf'],
  [0.18, '#e0a1cf'],
  [0.184, '#dda0d2'],
  [0.188, '#db9fd4'],
  [0.192, '#db9fd4'],
  [0.196, '#d89fd6'],
  [0.2, '#d49ed9'],
  [0.204, '#d49ed9'],
  [0.208, '#d19edb'],
  [0.212, '#cd9edc'],
  [0.216, '#c99ede'],
  [0.22, '#c99ede'],
  [0.224, '#c59edf'],
  [0.227, '#c19ee1'],
  [0.231, '#c19ee1'],
  [0.235, '#bd9fe2'],
  [0.239, '#b89fe2'],
  [0.243, '#b89fe2'],
  [0.247, '#b4a0e3'],
  [0.251, '#afa0e3'],
  [0.255, '#aba1e2'],
  [0.259, '#aba1e2'],
  [0.263, '#a6a2e2'],
  [0.267, '#a1a3e1'],
  [0.271, '#a1a3e1'],
  [0.275, '#9ca4e0'],
  [0.278, '#98a5de'],
  [0.282, '#98a5de'],
  [0.286, '#93a6dd'],
  [0.29, '#8ea7db'],
  [0.294, '#8aa8d8'],
  [0.298, '#8aa8d8'],
  [0.302, '#85a9d5'],
  [0.306, '#81aad2'],
  [0.31, '#81aad2'],
  [0.314, '#7dabcf'],
  [0.318, '#79accb'],
  [0.322, '#75adc8'],
  [0.325, '#75adc8'],
  [0.329, '#72aec3'],
  [0.333, '#6eaebf'],
  [0.337, '#6eaebf'],
  [0.341, '#6bafbb'],
  [0.345, '#68b0b6'],
  [0.349, '#68b0b6'],
  [0.353, '#66b0b1'],
  [0.357, '#64b1ac'],
  [0.361, '#62b1a6'],
  [0.365, '#62b1a6'],
  [0.369, '#60b1a1'],
  [0.373, '#5fb19c'],
  [0.376, '#5fb19c'],
  [0.38, '#5eb196'],
  [0.384, '#5db191'],
  [0.388, '#5db191'],
  [0.392, '#5db08b'],
  [0.396, '#5cb086'],
  [0.4, '#5daf80'],
  [0.404, '#5daf80'],
  [0.408, '#5dae7b'],
  [0.412, '#5ead75'],
  [0.416, '#5ead75'],
  [0.42, '#5fab70'],
  [0.424, '#60aa6b'],
  [0.427, '#60aa6b'],
  [0.431, '#62a866'],
  [0.435, '#64a762'],
  [0.439, '#66a55d'],
  [0.443, '#66a55d'],
  [0.447, '#68a359'],
  [0.451, '#6ba055'],
  [0.455, '#6ba055'],
  [0.459, '#6d9e52'],
  [0.463, '#709c4e'],
  [0.467, '#73994b'],
  [0.471, '#73994b'],
  [0.475, '#769649'],
  [0.478, '#799346'],
  [0.482, '#799346'],
  [0.486, '#7c9044'],
  [0.49, '#7f8d43'],
  [0.494, '#7f8d43'],
  [0.498, '#838a41'],
  [0.502, '#868740'],
  [0.506, '#898440'],
  [0.51, '#898440'],
  [0.514, '#8c813f'],
  [0.518, '#8f7e3f'],
  [0.522, '#8f7e3f'],
  [0.525, '#927a40'],
  [0.529, '#957740'],
  [0.533, '#957740'],
  [0.537, '#977441'],
  [0.541, '#9a7143'],
  [0.545, '#9c6e44'],
  [0.549, '#9c6e44'],
  [0.553, '#9e6b46'],
  [0.557, '#a06748'],
  [0.561, '#a06748'],
  [0.565, '#a1654b'],
  [0.569, '#a3624d'],
  [0.573, '#a45f50'],
  [0.576, '#a45f50'],
  [0.58, '#a45c53'],
  [0.584, '#a55a56'],
  [0.588, '#a55a56'],
  [0.592, '#a5575a'],
  [0.596, '#a5555d'],
  [0.6, '#a5555d'],
  [0.604, '#a45361'],
  [0.608, '#a45164'],
  [0.612, '#a34f68'],
  [0.616, '#a34f68'],
  [0.62, '#a14e6b'],
  [0.624, '#9f4c6f'],
  [0.627, '#9f4c6f'],
  [0.631, '#9d4b72'],
  [0.635, '#9b4a75'],
  [0.639, '#9b4a75'],
  [0.643, '#984979'],
  [0.647, '#96487c'],
  [0.651, '#92477f'],
  [0.655, '#92477f'],
  [0.659, '#8f4782'],
  [0.663, '#8c4784'],
  [0.667, '#8c4784'],
  [0.671, '#884686'],
  [0.675, '#844689'],
  [0.678, '#844689'],
  [0.682, '#80468a'],
  [0.686, '#7b478c'],
  [0.69, '#77478d'],
  [0.694, '#77478d'],
  [0.698, '#72478e'],
  [0.702, '#6d488f'],
  [0.706, '#6d488f'],
  [0.71, '#694990'],
  [0.714, '#644990'],
  [0.718, '#5f4a8f'],
  [0.722, '#5f4a8f'],
  [0.725, '#5a4b8f'],
  [0.729, '#564c8e'],
  [0.733, '#564c8e'],
  [0.737, '#514d8d'],
  [0.741, '#4c4e8b'],
  [0.745, '#4c4e8b'],
  [0.749, '#484f8a'],
  [0.753, '#435088'],
  [0.757, '#3f5185'],
  [0.761, '#3f5185'],
  [0.765, '#3b5283'],
  [0.769, '#365280'],
  [0.773, '#365280'],
  [0.776, '#33537c'],
  [0.78, '#2f5479'],
  [0.784, '#2f5479'],
  [0.788, '#2c5575'],
  [0.792, '#285572'],
  [0.796, '#25566e'],
  [0.8, '#25566e'],
  [0.804, '#235669'],
  [0.808, '#205765'],
  [0.812, '#205765'],
  [0.816, '#1e5761'],
  [0.82, '#1c575c'],
  [0.824, '#1c575c'],
  [0.827, '#1a5758'],
  [0.831, '#195753'],
  [0.835, '#17574f'],
  [0.839, '#17574f'],
  [0.843, '#16574a'],
  [0.847, '#165645'],
  [0.851, '#165645'],
  [0.855, '#155641'],
  [0.859, '#15553c'],
  [0.863, '#155438'],
  [0.867, '#155438'],
  [0.871, '#155334'],
  [0.875, '#165230'],
  [0.878, '#165230'],
  [0.882, '#16512c'],
  [0.886, '#174f28'],
  [0.89, '#174f28'],
  [0.894, '#184e24'],
  [0.898, '#194c21'],
  [0.902, '#1a4a1d'],
  [0.906, '#1a4a1d'],
  [0.91, '#1c491a'],
  [0.914, '#1d4717'],
  [0.918, '#1d4717'],
  [0.922, '#1f4515'],
  [0.925, '#204312'],
  [0.929, '#204312'],
  [0.933, '#224110'],
  [0.937, '#233e0e'],
  [0.941, '#253c0d'],
  [0.945, '#253c0d'],
  [0.949, '#273a0b'],
  [0.953, '#28380a'],
  [0.957, '#28380a'],
  [0.961, '#2a3509'],
  [0.965, '#2b3308'],
  [0.969, '#2b3308'],
  [0.973, '#2c3108'],
  [0.976, '#2e2f07'],
  [0.98, '#2f2c07'],
  [0.984, '#2f2c07'],
  [0.988, '#302a07'],
  [0.992, '#312807'],
  [0.996, '#312807'],
  [1.0, '#322608'],
];

export const MAGMA_GRADIENT_STOPS: GradientStops = [
  [0.0, '#fcfdbf'],
  [0.004, '#fcfbbd'],
  [0.008, '#fcf9bb'],
  [0.012, '#fcf7b9'],
  [0.016, '#fcf6b8'],
  [0.02, '#fcf4b6'],
  [0.024, '#fcf2b4'],
  [0.027, '#fcf0b2'],
  [0.031, '#fceeb0'],
  [0.035, '#fcecae'],
  [0.039, '#fdebac'],
  [0.043, '#fde9aa'],
  [0.047, '#fde7a9'],
  [0.051, '#fde5a7'],
  [0.055, '#fde3a5'],
  [0.059, '#fde2a3'],
  [0.063, '#fde0a1'],
  [0.067, '#fddea0'],
  [0.071, '#fddc9e'],
  [0.075, '#fdda9c'],
  [0.078, '#fed89a'],
  [0.082, '#fed799'],
  [0.086, '#fed597'],
  [0.09, '#fed395'],
  [0.094, '#fed194'],
  [0.098, '#fecf92'],
  [0.102, '#fecd90'],
  [0.106, '#fecc8f'],
  [0.11, '#feca8d'],
  [0.114, '#fec88c'],
  [0.118, '#fec68a'],
  [0.122, '#fec488'],
  [0.125, '#fec287'],
  [0.129, '#fec185'],
  [0.133, '#febf84'],
  [0.137, '#febd82'],
  [0.141, '#febb81'],
  [0.145, '#feb97f'],
  [0.149, '#feb77e'],
  [0.153, '#feb67c'],
  [0.157, '#feb47b'],
  [0.161, '#feb27a'],
  [0.165, '#feb078'],
  [0.169, '#feae77'],
  [0.173, '#feac76'],
  [0.176, '#feaa74'],
  [0.18, '#fea973'],
  [0.184, '#fea772'],
  [0.188, '#fea571'],
  [0.192, '#fea36f'],
  [0.196, '#fea16e'],
  [0.2, '#fe9f6d'],
  [0.204, '#fe9d6c'],
  [0.208, '#fd9b6b'],
  [0.212, '#fd9a6a'],
  [0.216, '#fd9869'],
  [0.22, '#fd9668'],
  [0.224, '#fd9467'],
  [0.227, '#fd9266'],
  [0.231, '#fc9065'],
  [0.235, '#fc8e64'],
  [0.239, '#fc8c63'],
  [0.243, '#fc8a62'],
  [0.247, '#fc8961'],
  [0.251, '#fb8761'],
  [0.255, '#fb8560'],
  [0.259, '#fb835f'],
  [0.263, '#fa815f'],
  [0.267, '#fa7f5e'],
  [0.271, '#fa7d5e'],
  [0.275, '#f97b5d'],
  [0.278, '#f9795d'],
  [0.282, '#f9785d'],
  [0.286, '#f8765c'],
  [0.29, '#f8745c'],
  [0.294, '#f7725c'],
  [0.298, '#f7705c'],
  [0.302, '#f66e5c'],
  [0.306, '#f66c5c'],
  [0.31, '#f56b5c'],
  [0.314, '#f4695c'],
  [0.318, '#f4675c'],
  [0.322, '#f3655c'],
  [0.325, '#f2645c'],
  [0.329, '#f2625d'],
  [0.333, '#f1605d'],
  [0.337, '#f05f5e'],
  [0.341, '#ef5d5e'],
  [0.345, '#ee5b5e'],
  [0.349, '#ed5a5f'],
  [0.353, '#ec5860'],
  [0.357, '#eb5760'],
  [0.361, '#ea5661'],
  [0.365, '#e95462'],
  [0.369, '#e85362'],
  [0.373, '#e75263'],
  [0.376, '#e55064'],
  [0.38, '#e44f64'],
  [0.384, '#e34e65'],
  [0.388, '#e24d66'],
  [0.392, '#e04c67'],
  [0.396, '#df4a68'],
  [0.4, '#de4968'],
  [0.404, '#dc4869'],
  [0.408, '#db476a'],
  [0.412, '#d9466b'],
  [0.416, '#d8456c'],
  [0.42, '#d6456c'],
  [0.424, '#d5446d'],
  [0.427, '#d3436e'],
  [0.431, '#d2426f'],
  [0.435, '#d0416f'],
  [0.439, '#cf4070'],
  [0.443, '#cd4071'],
  [0.447, '#cc3f71'],
  [0.451, '#ca3e72'],
  [0.455, '#c83e73'],
  [0.459, '#c73d73'],
  [0.463, '#c53c74'],
  [0.467, '#c43c75'],
  [0.471, '#c23b75'],
  [0.475, '#c03a76'],
  [0.478, '#bf3a77'],
  [0.482, '#bd3977'],
  [0.486, '#bc3978'],
  [0.49, '#ba3878'],
  [0.494, '#b83779'],
  [0.498, '#b73779'],
  [0.502, '#b5367a'],
  [0.506, '#b3367a'],
  [0.51, '#b2357b'],
  [0.514, '#b0357b'],
  [0.518, '#ae347b'],
  [0.522, '#ad347c'],
  [0.525, '#ab337c'],
  [0.529, '#aa337d'],
  [0.533, '#a8327d'],
  [0.537, '#a6317d'],
  [0.541, '#a5317e'],
  [0.545, '#a3307e'],
  [0.549, '#a1307e'],
  [0.553, '#a02f7f'],
  [0.557, '#9e2f7f'],
  [0.561, '#9c2e7f'],
  [0.565, '#9b2e7f'],
  [0.569, '#992d80'],
  [0.573, '#982d80'],
  [0.576, '#962c80'],
  [0.58, '#942c80'],
  [0.584, '#932b80'],
  [0.588, '#912b81'],
  [0.592, '#902a81'],
  [0.596, '#8e2a81'],
  [0.6, '#8c2981'],
  [0.604, '#8b2981'],
  [0.608, '#892881'],
  [0.612, '#882781'],
  [0.616, '#862781'],
  [0.62, '#842681'],
  [0.624, '#832681'],
  [0.627, '#812581'],
  [0.631, '#802582'],
  [0.635, '#7e2482'],
  [0.639, '#7c2382'],
  [0.643, '#7b2382'],
  [0.647, '#792282'],
  [0.651, '#782281'],
  [0.655, '#762181'],
  [0.659, '#752181'],
  [0.663, '#732081'],
  [0.667, '#721f81'],
  [0.671, '#701f81'],
  [0.675, '#6e1e81'],
  [0.678, '#6d1d81'],
  [0.682, '#6b1d81'],
  [0.686, '#6a1c81'],
  [0.69, '#681c81'],
  [0.694, '#671b80'],
  [0.698, '#651a80'],
  [0.702, '#641a80'],
  [0.706, '#621980'],
  [0.71, '#601880'],
  [0.714, '#5f187f'],
  [0.718, '#5d177f'],
  [0.722, '#5c167f'],
  [0.725, '#5a167e'],
  [0.729, '#59157e'],
  [0.733, '#57157e'],
  [0.737, '#56147d'],
  [0.741, '#54137d'],
  [0.745, '#52137c'],
  [0.749, '#51127c'],
  [0.753, '#4f127b'],
  [0.757, '#4e117b'],
  [0.761, '#4c117a'],
  [0.765, '#4a1079'],
  [0.769, '#491078'],
  [0.773, '#471078'],
  [0.776, '#451077'],
  [0.78, '#440f76'],
  [0.784, '#420f75'],
  [0.788, '#400f74'],
  [0.792, '#3f0f72'],
  [0.796, '#3d0f71'],
  [0.8, '#3b0f70'],
  [0.804, '#390f6e'],
  [0.808, '#38106c'],
  [0.812, '#36106b'],
  [0.816, '#341069'],
  [0.82, '#331067'],
  [0.824, '#311165'],
  [0.827, '#2f1163'],
  [0.831, '#2d1161'],
  [0.835, '#2c115f'],
  [0.839, '#2a115c'],
  [0.843, '#29115a'],
  [0.847, '#271258'],
  [0.851, '#251255'],
  [0.855, '#241253'],
  [0.859, '#221150'],
  [0.863, '#21114e'],
  [0.867, '#20114b'],
  [0.871, '#1e1149'],
  [0.875, '#1d1147'],
  [0.878, '#1c1044'],
  [0.882, '#1a1042'],
  [0.886, '#19103f'],
  [0.89, '#180f3d'],
  [0.894, '#160f3b'],
  [0.898, '#150e38'],
  [0.902, '#140e36'],
  [0.906, '#130d34'],
  [0.91, '#120d31'],
  [0.914, '#110c2f'],
  [0.918, '#100b2d'],
  [0.922, '#0e0b2b'],
  [0.925, '#0d0a29'],
  [0.929, '#0c0926'],
  [0.933, '#0b0924'],
  [0.937, '#0a0822'],
  [0.941, '#090720'],
  [0.945, '#08071e'],
  [0.949, '#07061c'],
  [0.953, '#06051a'],
  [0.957, '#060518'],
  [0.961, '#050416'],
  [0.965, '#040414'],
  [0.969, '#030312'],
  [0.973, '#03030f'],
  [0.976, '#02020d'],
  [0.98, '#02020b'],
  [0.984, '#020109'],
  [0.988, '#010108'],
  [0.992, '#010106'],
  [0.996, '#010005'],
  [1.0, '#000004'],
];

export const BURN_GRADIENT_STOPS: GradientStops = [
  [0.0, '#fcffa4'],
  [0.004, '#fafda1'],
  [0.008, '#f9fc9d'],
  [0.012, '#f8fb9a'],
  [0.016, '#f6fa96'],
  [0.02, '#f5f992'],
  [0.024, '#f4f88e'],
  [0.027, '#f3f68a'],
  [0.031, '#f3f586'],
  [0.035, '#f2f482'],
  [0.039, '#f2f27d'],
  [0.043, '#f1f179'],
  [0.047, '#f1ef75'],
  [0.051, '#f1ed71'],
  [0.055, '#f1ec6d'],
  [0.059, '#f2ea69'],
  [0.063, '#f2e865'],
  [0.067, '#f2e661'],
  [0.071, '#f3e55d'],
  [0.075, '#f3e35a'],
  [0.078, '#f4e156'],
  [0.082, '#f4df53'],
  [0.086, '#f4dd4f'],
  [0.09, '#f5db4c'],
  [0.094, '#f5d949'],
  [0.098, '#f6d746'],
  [0.102, '#f6d543'],
  [0.106, '#f7d340'],
  [0.11, '#f7d13d'],
  [0.114, '#f8cf3a'],
  [0.118, '#f8cd37'],
  [0.122, '#f9cb35'],
  [0.125, '#f9c932'],
  [0.129, '#f9c72f'],
  [0.133, '#fac62d'],
  [0.137, '#fac42a'],
  [0.141, '#fac228'],
  [0.145, '#fac026'],
  [0.149, '#fbbe23'],
  [0.153, '#fbbc21'],
  [0.157, '#fbba1f'],
  [0.161, '#fbb81d'],
  [0.165, '#fbb61a'],
  [0.169, '#fcb418'],
  [0.173, '#fcb216'],
  [0.176, '#fcb014'],
  [0.18, '#fcae12'],
  [0.184, '#fcac11'],
  [0.188, '#fcaa0f'],
  [0.192, '#fca80d'],
  [0.196, '#fca60c'],
  [0.2, '#fca50a'],
  [0.204, '#fca309'],
  [0.208, '#fca108'],
  [0.212, '#fc9f07'],
  [0.216, '#fb9d07'],
  [0.22, '#fb9b06'],
  [0.224, '#fb9906'],
  [0.227, '#fb9706'],
  [0.231, '#fb9606'],
  [0.235, '#fa9407'],
  [0.239, '#fa9207'],
  [0.243, '#fa9008'],
  [0.247, '#f98e09'],
  [0.251, '#f98c0a'],
  [0.255, '#f98b0b'],
  [0.259, '#f8890c'],
  [0.263, '#f8870e'],
  [0.267, '#f8850f'],
  [0.271, '#f78410'],
  [0.275, '#f78212'],
  [0.278, '#f68013'],
  [0.282, '#f67e14'],
  [0.286, '#f57d15'],
  [0.29, '#f57b17'],
  [0.294, '#f47918'],
  [0.298, '#f37819'],
  [0.302, '#f3761b'],
  [0.306, '#f2741c'],
  [0.31, '#f1731d'],
  [0.314, '#f1711f'],
  [0.318, '#f06f20'],
  [0.322, '#ef6e21'],
  [0.325, '#ef6c23'],
  [0.329, '#ee6a24'],
  [0.333, '#ed6925'],
  [0.337, '#ec6726'],
  [0.341, '#eb6628'],
  [0.345, '#eb6429'],
  [0.349, '#ea632a'],
  [0.353, '#e9612b'],
  [0.357, '#e8602d'],
  [0.361, '#e75e2e'],
  [0.365, '#e65d2f'],
  [0.369, '#e55c30'],
  [0.373, '#e45a31'],
  [0.376, '#e35933'],
  [0.38, '#e25734'],
  [0.384, '#e15635'],
  [0.388, '#e05536'],
  [0.392, '#df5337'],
  [0.396, '#de5238'],
  [0.4, '#dd513a'],
  [0.404, '#db503b'],
  [0.408, '#da4e3c'],
  [0.412, '#d94d3d'],
  [0.416, '#d84c3e'],
  [0.42, '#d74b3f'],
  [0.424, '#d54a41'],
  [0.427, '#d44842'],
  [0.431, '#d34743'],
  [0.435, '#d24644'],
  [0.439, '#d04545'],
  [0.443, '#cf4446'],
  [0.447, '#ce4347'],
  [0.451, '#cc4248'],
  [0.455, '#cb4149'],
  [0.459, '#ca404a'],
  [0.463, '#c83f4b'],
  [0.467, '#c73e4c'],
  [0.471, '#c63d4d'],
  [0.475, '#c43c4e'],
  [0.478, '#c33b4f'],
  [0.482, '#c13a50'],
  [0.486, '#c03a51'],
  [0.49, '#bf3952'],
  [0.494, '#bd3853'],
  [0.498, '#bc3754'],
  [0.502, '#ba3655'],
  [0.506, '#b93556'],
  [0.51, '#b73557'],
  [0.514, '#b63458'],
  [0.518, '#b43359'],
  [0.522, '#b3325a'],
  [0.525, '#b1325a'],
  [0.529, '#b0315b'],
  [0.533, '#ae305c'],
  [0.537, '#ad305d'],
  [0.541, '#ab2f5e'],
  [0.545, '#a92e5e'],
  [0.549, '#a82e5f'],
  [0.553, '#a62d60'],
  [0.557, '#a52c60'],
  [0.561, '#a32c61'],
  [0.565, '#a22b62'],
  [0.569, '#a02a63'],
  [0.573, '#9f2a63'],
  [0.576, '#9d2964'],
  [0.58, '#9b2964'],
  [0.584, '#9a2865'],
  [0.588, '#982766'],
  [0.592, '#972766'],
  [0.596, '#952667'],
  [0.6, '#932667'],
  [0.604, '#922568'],
  [0.608, '#902568'],
  [0.612, '#8f2469'],
  [0.616, '#8d2369'],
  [0.62, '#8c2369'],
  [0.624, '#8a226a'],
  [0.627, '#88226a'],
  [0.631, '#87216b'],
  [0.635, '#85216b'],
  [0.639, '#84206b'],
  [0.643, '#82206c'],
  [0.647, '#801f6c'],
  [0.651, '#7f1e6c'],
  [0.655, '#7d1e6d'],
  [0.659, '#7c1d6d'],
  [0.663, '#7a1d6d'],
  [0.667, '#781c6d'],
  [0.671, '#771c6d'],
  [0.675, '#751b6e'],
  [0.678, '#741a6e'],
  [0.682, '#721a6e'],
  [0.686, '#71196e'],
  [0.69, '#6f196e'],
  [0.694, '#6d186e'],
  [0.698, '#6c186e'],
  [0.702, '#6a176e'],
  [0.706, '#69166e'],
  [0.71, '#67166e'],
  [0.714, '#65156e'],
  [0.718, '#64156e'],
  [0.722, '#62146e'],
  [0.725, '#61136e'],
  [0.729, '#5f136e'],
  [0.733, '#5d126e'],
  [0.737, '#5c126e'],
  [0.741, '#5a116e'],
  [0.745, '#59106e'],
  [0.749, '#57106e'],
  [0.753, '#550f6d'],
  [0.757, '#540f6d'],
  [0.761, '#520e6d'],
  [0.765, '#510e6c'],
  [0.769, '#4f0d6c'],
  [0.773, '#4d0d6c'],
  [0.776, '#4c0c6b'],
  [0.78, '#4a0c6b'],
  [0.784, '#490b6a'],
  [0.788, '#470b6a'],
  [0.792, '#450a69'],
  [0.796, '#440a68'],
  [0.8, '#420a68'],
  [0.804, '#400a67'],
  [0.808, '#3e0966'],
  [0.812, '#3d0965'],
  [0.816, '#3b0964'],
  [0.82, '#390963'],
  [0.824, '#380962'],
  [0.827, '#360961'],
  [0.831, '#340a5f'],
  [0.835, '#320a5e'],
  [0.839, '#310a5c'],
  [0.843, '#2f0a5b'],
  [0.847, '#2d0b59'],
  [0.851, '#2b0b57'],
  [0.855, '#290b55'],
  [0.859, '#280b53'],
  [0.863, '#260c51'],
  [0.867, '#240c4f'],
  [0.871, '#230c4c'],
  [0.875, '#210c4a'],
  [0.878, '#1f0c48'],
  [0.882, '#1e0c45'],
  [0.886, '#1c0c43'],
  [0.89, '#1b0c41'],
  [0.894, '#190c3e'],
  [0.898, '#180c3c'],
  [0.902, '#160b39'],
  [0.906, '#150b37'],
  [0.91, '#140b34'],
  [0.914, '#120a32'],
  [0.918, '#110a30'],
  [0.922, '#10092d'],
  [0.925, '#0e092b'],
  [0.929, '#0d0829'],
  [0.933, '#0c0826'],
  [0.937, '#0b0724'],
  [0.941, '#0a0722'],
  [0.945, '#09061f'],
  [0.949, '#08051d'],
  [0.953, '#07051b'],
  [0.957, '#060419'],
  [0.961, '#050417'],
  [0.965, '#040314'],
  [0.969, '#040312'],
  [0.973, '#030210'],
  [0.976, '#02020e'],
  [0.98, '#02020c'],
  [0.984, '#02010a'],
  [0.988, '#010108'],
  [0.992, '#010106'],
  [0.996, '#010005'],
  [1.0, '#000004'],
];

export const PLANET_FOREST_CARBON_CANOPY_COVER_STOPS: GradientStops = [
  [0.0, '#234d23'],
  [0.004, '#234e23'],
  [0.008, '#224e23'],
  [0.012, '#224f24'],
  [0.016, '#214f24'],
  [0.02, '#215024'],
  [0.024, '#215124'],
  [0.027, '#205125'],
  [0.031, '#205225'],
  [0.035, '#1f5225'],
  [0.039, '#1f5325'],
  [0.043, '#1f5426'],
  [0.047, '#1e5426'],
  [0.051, '#1e5526'],
  [0.055, '#1d5526'],
  [0.059, '#1d5627'],
  [0.063, '#1c5727'],
  [0.067, '#1c5727'],
  [0.071, '#1c5827'],
  [0.075, '#1b5827'],
  [0.078, '#1b5928'],
  [0.082, '#1a5a28'],
  [0.086, '#1a5a28'],
  [0.09, '#1a5b28'],
  [0.094, '#195b29'],
  [0.098, '#195c29'],
  [0.102, '#185c29'],
  [0.106, '#185d29'],
  [0.11, '#185e2a'],
  [0.114, '#175e2a'],
  [0.118, '#175f2a'],
  [0.122, '#165f2a'],
  [0.125, '#16602b'],
  [0.129, '#16612b'],
  [0.133, '#15612b'],
  [0.137, '#15622b'],
  [0.141, '#14622b'],
  [0.145, '#14632c'],
  [0.149, '#14642c'],
  [0.153, '#13642c'],
  [0.157, '#13652c'],
  [0.161, '#12652d'],
  [0.165, '#12662d'],
  [0.169, '#11672d'],
  [0.173, '#11672d'],
  [0.176, '#11682e'],
  [0.18, '#10682e'],
  [0.184, '#10692e'],
  [0.188, '#0f6a2e'],
  [0.192, '#0f6a2f'],
  [0.196, '#0f6b2f'],
  [0.2, '#0e6b2f'],
  [0.204, '#0e6c2f'],
  [0.208, '#0d6d2f'],
  [0.212, '#0d6d30'],
  [0.216, '#0d6e30'],
  [0.22, '#0c6e30'],
  [0.224, '#0c6f30'],
  [0.227, '#0b7031'],
  [0.231, '#0b7031'],
  [0.235, '#0b7131'],
  [0.239, '#0a7131'],
  [0.243, '#0a7232'],
  [0.247, '#097332'],
  [0.251, '#097332'],
  [0.255, '#0b7431'],
  [0.259, '#0c7431'],
  [0.263, '#0e7530'],
  [0.267, '#0f7630'],
  [0.271, '#11762f'],
  [0.275, '#12772f'],
  [0.278, '#14772e'],
  [0.282, '#15782d'],
  [0.286, '#17792d'],
  [0.29, '#18792c'],
  [0.294, '#1a7a2c'],
  [0.298, '#1b7a2b'],
  [0.302, '#1d7b2b'],
  [0.306, '#1e7b2a'],
  [0.31, '#207c2a'],
  [0.314, '#217d29'],
  [0.318, '#237d29'],
  [0.322, '#247e28'],
  [0.325, '#267e27'],
  [0.329, '#277f27'],
  [0.333, '#298026'],
  [0.337, '#2b8026'],
  [0.341, '#2c8125'],
  [0.345, '#2e8125'],
  [0.349, '#2f8224'],
  [0.353, '#318324'],
  [0.357, '#328323'],
  [0.361, '#348422'],
  [0.365, '#358422'],
  [0.369, '#378521'],
  [0.373, '#388621'],
  [0.376, '#3a8620'],
  [0.38, '#3b8720'],
  [0.384, '#3d871f'],
  [0.388, '#3e881f'],
  [0.392, '#40891e'],
  [0.396, '#41891e'],
  [0.4, '#438a1d'],
  [0.404, '#448a1c'],
  [0.408, '#468b1c'],
  [0.412, '#478c1b'],
  [0.416, '#498c1b'],
  [0.42, '#4a8d1a'],
  [0.424, '#4c8d1a'],
  [0.427, '#4d8e19'],
  [0.431, '#4f8f19'],
  [0.435, '#508f18'],
  [0.439, '#529018'],
  [0.443, '#539017'],
  [0.447, '#559116'],
  [0.451, '#569216'],
  [0.455, '#589215'],
  [0.459, '#599315'],
  [0.463, '#5b9314'],
  [0.467, '#5c9414'],
  [0.471, '#5e9513'],
  [0.475, '#5f9513'],
  [0.478, '#619612'],
  [0.482, '#629611'],
  [0.486, '#649711'],
  [0.49, '#659810'],
  [0.494, '#679810'],
  [0.498, '#68990f'],
  [0.502, '#6a9a10'],
  [0.506, '#6c9b13'],
  [0.51, '#6f9d16'],
  [0.514, '#719e19'],
  [0.518, '#73a01c'],
  [0.522, '#76a11f'],
  [0.525, '#78a322'],
  [0.529, '#7aa525'],
  [0.533, '#7da628'],
  [0.537, '#7fa82b'],
  [0.541, '#81a92e'],
  [0.545, '#84ab31'],
  [0.549, '#86ac34'],
  [0.553, '#88ae37'],
  [0.557, '#8baf3a'],
  [0.561, '#8db13d'],
  [0.565, '#8fb240'],
  [0.569, '#92b443'],
  [0.573, '#94b546'],
  [0.576, '#96b749'],
  [0.58, '#99b94c'],
  [0.584, '#9bba4f'],
  [0.588, '#9dbc52'],
  [0.592, '#a0bd55'],
  [0.596, '#a2bf58'],
  [0.6, '#a4c05b'],
  [0.604, '#a7c25e'],
  [0.608, '#a9c361'],
  [0.612, '#abc563'],
  [0.616, '#adc666'],
  [0.62, '#b0c869'],
  [0.624, '#b2c96c'],
  [0.627, '#b4cb6f'],
  [0.631, '#b7cc72'],
  [0.635, '#b9ce75'],
  [0.639, '#bbd078'],
  [0.643, '#bed17b'],
  [0.647, '#c0d37e'],
  [0.651, '#c2d481'],
  [0.655, '#c5d684'],
  [0.659, '#c7d787'],
  [0.663, '#c9d98a'],
  [0.667, '#ccda8d'],
  [0.671, '#cedc90'],
  [0.675, '#d0dd93'],
  [0.678, '#d3df96'],
  [0.682, '#d5e099'],
  [0.686, '#d7e29c'],
  [0.69, '#dae49f'],
  [0.694, '#dce5a2'],
  [0.698, '#dee7a5'],
  [0.702, '#e1e8a8'],
  [0.706, '#e3eaab'],
  [0.71, '#e5ebae'],
  [0.714, '#e8edb1'],
  [0.718, '#eaeeb4'],
  [0.722, '#ecf0b7'],
  [0.725, '#eef1b9'],
  [0.729, '#f1f3bc'],
  [0.733, '#f3f4bf'],
  [0.737, '#f5f6c2'],
  [0.741, '#f8f8c5'],
  [0.745, '#faf9c8'],
  [0.749, '#fcfbcb'],
  [0.753, '#fdfbcc'],
  [0.757, '#fdfbcc'],
  [0.761, '#fdfbcc'],
  [0.765, '#fdfbcc'],
  [0.769, '#fdfbcc'],
  [0.773, '#fdfbcc'],
  [0.776, '#fdfbcc'],
  [0.78, '#fdfbcc'],
  [0.784, '#fdfbcc'],
  [0.788, '#fdfbcc'],
  [0.792, '#fdfbcc'],
  [0.796, '#fdfbcc'],
  [0.8, '#fdfbcc'],
  [0.804, '#fdfbcc'],
  [0.808, '#fdfbcc'],
  [0.812, '#fdfbcc'],
  [0.816, '#fdfbcc'],
  [0.82, '#fdfbcc'],
  [0.824, '#fdfbcc'],
  [0.827, '#fdfbcc'],
  [0.831, '#fdfbcc'],
  [0.835, '#fdfbcc'],
  [0.839, '#fdfbcc'],
  [0.843, '#fdfbcc'],
  [0.847, '#fdfbcc'],
  [0.851, '#fdfbcc'],
  [0.855, '#fdfbcc'],
  [0.859, '#fdfbcc'],
  [0.863, '#fdfbcc'],
  [0.867, '#fdfbcc'],
  [0.871, '#fdfbcc'],
  [0.875, '#fdfbcc'],
  [0.878, '#fdfbcc'],
  [0.882, '#fdfbcc'],
  [0.886, '#fdfbcc'],
  [0.89, '#fdfbcc'],
  [0.894, '#fdfbcc'],
  [0.898, '#fdfbcc'],
  [0.902, '#fdfbcc'],
  [0.906, '#fdfbcc'],
  [0.91, '#fdfbcc'],
  [0.914, '#fdfbcc'],
  [0.918, '#fdfbcc'],
  [0.922, '#fdfbcc'],
  [0.925, '#fdfbcc'],
  [0.929, '#fdfbcc'],
  [0.933, '#fdfbcc'],
  [0.937, '#fdfbcc'],
  [0.941, '#fdfbcc'],
  [0.945, '#fdfbcc'],
  [0.949, '#fdfbcc'],
  [0.953, '#fdfbcc'],
  [0.957, '#fdfbcc'],
  [0.961, '#fdfbcc'],
  [0.965, '#fdfbcc'],
  [0.969, '#fdfbcc'],
  [0.973, '#fdfbcc'],
  [0.976, '#fdfbcc'],
  [0.98, '#fdfbcc'],
  [0.984, '#fdfbcc'],
  [0.988, '#fdfbcc'],
  [0.992, '#fdfbcc'],
  [0.996, '#fdfbcc'],
  [1.0, '#fdfbcc'],
];

export const PLANET_FOREST_CARBON_CANOPY_HEIGHT_STOPS: GradientStops = [
  [0.0, '#325900'],
  [0.004, '#335a00'],
  [0.008, '#335b00'],
  [0.012, '#345c00'],
  [0.016, '#345c01'],
  [0.02, '#355d01'],
  [0.024, '#365e01'],
  [0.027, '#365f01'],
  [0.031, '#376001'],
  [0.035, '#376101'],
  [0.039, '#386102'],
  [0.043, '#386202'],
  [0.047, '#396302'],
  [0.051, '#3a6402'],
  [0.055, '#3a6502'],
  [0.059, '#3b6602'],
  [0.063, '#3b6603'],
  [0.067, '#3c6703'],
  [0.071, '#3d6803'],
  [0.075, '#3d6903'],
  [0.078, '#3e6a03'],
  [0.082, '#3e6b03'],
  [0.086, '#3f6c03'],
  [0.09, '#406c04'],
  [0.094, '#406d04'],
  [0.098, '#416e04'],
  [0.102, '#416f04'],
  [0.106, '#427004'],
  [0.11, '#427104'],
  [0.114, '#437105'],
  [0.118, '#447205'],
  [0.122, '#447305'],
  [0.125, '#457405'],
  [0.129, '#457505'],
  [0.133, '#467605'],
  [0.137, '#477705'],
  [0.141, '#477706'],
  [0.145, '#487806'],
  [0.149, '#487906'],
  [0.153, '#497a06'],
  [0.157, '#4a7b06'],
  [0.161, '#4a7c06'],
  [0.165, '#4b7c07'],
  [0.169, '#4b7d07'],
  [0.173, '#4c7e07'],
  [0.176, '#4c7f07'],
  [0.18, '#4d8007'],
  [0.184, '#4e8107'],
  [0.188, '#4e8108'],
  [0.192, '#4f8208'],
  [0.196, '#4f8308'],
  [0.2, '#508408'],
  [0.204, '#518508'],
  [0.208, '#518509'],
  [0.212, '#528609'],
  [0.216, '#538709'],
  [0.22, '#53870a'],
  [0.224, '#54880a'],
  [0.227, '#55890a'],
  [0.231, '#55890b'],
  [0.235, '#568a0b'],
  [0.239, '#578b0b'],
  [0.243, '#578c0c'],
  [0.247, '#588c0c'],
  [0.251, '#598d0c'],
  [0.255, '#598e0d'],
  [0.259, '#5a8e0d'],
  [0.263, '#5b8f0d'],
  [0.267, '#5b900e'],
  [0.271, '#5c900e'],
  [0.275, '#5d910e'],
  [0.278, '#5d920f'],
  [0.282, '#5e920f'],
  [0.286, '#5f930f'],
  [0.29, '#5f9410'],
  [0.294, '#609410'],
  [0.298, '#619510'],
  [0.302, '#619611'],
  [0.306, '#629711'],
  [0.31, '#639711'],
  [0.314, '#639812'],
  [0.318, '#649912'],
  [0.322, '#659912'],
  [0.325, '#659a13'],
  [0.329, '#669b13'],
  [0.333, '#679b13'],
  [0.337, '#679c14'],
  [0.341, '#689d14'],
  [0.345, '#699d14'],
  [0.349, '#699e15'],
  [0.353, '#6a9f15'],
  [0.357, '#6b9f15'],
  [0.361, '#6ba016'],
  [0.365, '#6ca116'],
  [0.369, '#6da216'],
  [0.373, '#6da217'],
  [0.376, '#6ea317'],
  [0.38, '#6fa417'],
  [0.384, '#6fa418'],
  [0.388, '#70a518'],
  [0.392, '#71a618'],
  [0.396, '#71a619'],
  [0.4, '#72a719'],
  [0.404, '#73a71a'],
  [0.408, '#73a81a'],
  [0.412, '#74a81b'],
  [0.416, '#75a91b'],
  [0.42, '#76a91c'],
  [0.424, '#76aa1c'],
  [0.427, '#77aa1d'],
  [0.431, '#78aa1d'],
  [0.435, '#78ab1e'],
  [0.439, '#79ab1e'],
  [0.443, '#7aac1f'],
  [0.447, '#7aac1f'],
  [0.451, '#7bad20'],
  [0.455, '#7cad20'],
  [0.459, '#7dad21'],
  [0.463, '#7dae21'],
  [0.467, '#7eae22'],
  [0.471, '#7faf23'],
  [0.475, '#7faf23'],
  [0.478, '#80b024'],
  [0.482, '#81b024'],
  [0.486, '#82b025'],
  [0.49, '#82b125'],
  [0.494, '#83b126'],
  [0.498, '#84b226'],
  [0.502, '#84b227'],
  [0.506, '#85b327'],
  [0.51, '#86b328'],
  [0.514, '#86b428'],
  [0.518, '#87b429'],
  [0.522, '#88b429'],
  [0.525, '#89b52a'],
  [0.529, '#89b52a'],
  [0.533, '#8ab62b'],
  [0.537, '#8bb62c'],
  [0.541, '#8bb72c'],
  [0.545, '#8cb72d'],
  [0.549, '#8db72d'],
  [0.553, '#8eb82e'],
  [0.557, '#8eb82e'],
  [0.561, '#8fb92f'],
  [0.565, '#90b92f'],
  [0.569, '#90ba30'],
  [0.573, '#91ba30'],
  [0.576, '#92ba31'],
  [0.58, '#92bb31'],
  [0.584, '#93bb32'],
  [0.588, '#94bc32'],
  [0.592, '#95bc33'],
  [0.596, '#95bd33'],
  [0.6, '#96bd34'],
  [0.604, '#97bd36'],
  [0.608, '#98be37'],
  [0.612, '#99be39'],
  [0.616, '#9abf3a'],
  [0.62, '#9bbf3c'],
  [0.624, '#9cc03d'],
  [0.627, '#9dc03f'],
  [0.631, '#9ec140'],
  [0.635, '#a0c142'],
  [0.639, '#a1c243'],
  [0.643, '#a2c245'],
  [0.647, '#a3c346'],
  [0.651, '#a4c348'],
  [0.655, '#a5c449'],
  [0.659, '#a6c44b'],
  [0.663, '#a7c54c'],
  [0.667, '#a8c54e'],
  [0.671, '#a9c650'],
  [0.675, '#aac651'],
  [0.678, '#abc753'],
  [0.682, '#acc754'],
  [0.686, '#adc856'],
  [0.69, '#aec857'],
  [0.694, '#afc959'],
  [0.698, '#b0c95a'],
  [0.702, '#b2ca5c'],
  [0.706, '#b3ca5d'],
  [0.71, '#b4cb5f'],
  [0.714, '#b5cb60'],
  [0.718, '#b6cc62'],
  [0.722, '#b7cc63'],
  [0.725, '#b8cd65'],
  [0.729, '#b9cd66'],
  [0.733, '#bace68'],
  [0.737, '#bbce6a'],
  [0.741, '#bccf6b'],
  [0.745, '#bdcf6d'],
  [0.749, '#bed06e'],
  [0.753, '#bfd070'],
  [0.757, '#c0d171'],
  [0.761, '#c1d173'],
  [0.765, '#c2d274'],
  [0.769, '#c4d276'],
  [0.773, '#c5d377'],
  [0.776, '#c6d379'],
  [0.78, '#c7d47a'],
  [0.784, '#c8d47c'],
  [0.788, '#c9d57d'],
  [0.792, '#cad57f'],
  [0.796, '#cbd680'],
  [0.8, '#ccd682'],
  [0.804, '#cdd784'],
  [0.808, '#ced786'],
  [0.812, '#cfd888'],
  [0.816, '#d0d98a'],
  [0.82, '#d0da8c'],
  [0.824, '#d1da8e'],
  [0.827, '#d2db90'],
  [0.831, '#d3dc92'],
  [0.835, '#d4dc93'],
  [0.839, '#d5dd95'],
  [0.843, '#d6de97'],
  [0.847, '#d7de99'],
  [0.851, '#d7df9b'],
  [0.855, '#d8e09d'],
  [0.859, '#d9e19f'],
  [0.863, '#dae1a1'],
  [0.867, '#dbe2a3'],
  [0.871, '#dce3a5'],
  [0.875, '#dde3a7'],
  [0.878, '#dee4a9'],
  [0.882, '#dfe5ab'],
  [0.886, '#dfe6ad'],
  [0.89, '#e0e6af'],
  [0.894, '#e1e7b1'],
  [0.898, '#e2e8b3'],
  [0.902, '#e3e8b4'],
  [0.906, '#e4e9b6'],
  [0.91, '#e5eab8'],
  [0.914, '#e6eaba'],
  [0.918, '#e6ebbc'],
  [0.922, '#e7ecbe'],
  [0.925, '#e8edc0'],
  [0.929, '#e9edc2'],
  [0.933, '#eaeec4'],
  [0.937, '#ebefc6'],
  [0.941, '#ecefc8'],
  [0.945, '#edf0ca'],
  [0.949, '#eef1cc'],
  [0.953, '#eef2ce'],
  [0.957, '#eff2d0'],
  [0.961, '#f0f3d2'],
  [0.965, '#f1f4d4'],
  [0.969, '#f2f4d5'],
  [0.973, '#f3f5d7'],
  [0.976, '#f4f6d9'],
  [0.98, '#f5f6db'],
  [0.984, '#f5f7dd'],
  [0.988, '#f6f8df'],
  [0.992, '#f7f9e1'],
  [0.996, '#f8f9e3'],
  [1.0, '#f9fae5'],
];

export const COPPER_GRADIENT_STOPS: GradientStops = [
  [0.0, '#ffc77f'],
  [0.004, '#ffc67e'],
  [0.008, '#ffc67e'],
  [0.012, '#ffc57d'],
  [0.016, '#ffc47d'],
  [0.02, '#ffc37c'],
  [0.024, '#ffc37c'],
  [0.027, '#ffc27b'],
  [0.031, '#ffc17b'],
  [0.035, '#ffc07a'],
  [0.039, '#ffbf7a'],
  [0.043, '#ffbf79'],
  [0.047, '#ffbe79'],
  [0.051, '#ffbd78'],
  [0.055, '#ffbc78'],
  [0.059, '#ffbb77'],
  [0.063, '#ffbb77'],
  [0.067, '#ffba76'],
  [0.071, '#ffb976'],
  [0.075, '#ffb875'],
  [0.078, '#ffb875'],
  [0.082, '#ffb774'],
  [0.086, '#ffb674'],
  [0.09, '#ffb573'],
  [0.094, '#ffb473'],
  [0.098, '#ffb472'],
  [0.102, '#ffb372'],
  [0.106, '#ffb271'],
  [0.11, '#ffb171'],
  [0.114, '#ffb170'],
  [0.118, '#ffb070'],
  [0.122, '#ffaf6f'],
  [0.125, '#ffae6f'],
  [0.129, '#ffad6e'],
  [0.133, '#ffad6e'],
  [0.137, '#ffac6d'],
  [0.141, '#ffab6d'],
  [0.145, '#ffaa6c'],
  [0.149, '#ffaa6c'],
  [0.153, '#ffa96b'],
  [0.157, '#ffa86b'],
  [0.161, '#ffa76a'],
  [0.165, '#ffa66a'],
  [0.169, '#ffa669'],
  [0.173, '#ffa569'],
  [0.176, '#ffa468'],
  [0.18, '#ffa368'],
  [0.184, '#ffa267'],
  [0.188, '#ffa267'],
  [0.192, '#fea166'],
  [0.196, '#fda066'],
  [0.2, '#fc9f65'],
  [0.204, '#fb9f65'],
  [0.208, '#fa9e64'],
  [0.212, '#f89d64'],
  [0.216, '#f79c64'],
  [0.22, '#f69b63'],
  [0.224, '#f59b63'],
  [0.227, '#f39a62'],
  [0.231, '#f29962'],
  [0.235, '#f19861'],
  [0.239, '#f09861'],
  [0.243, '#ee9760'],
  [0.247, '#ed9660'],
  [0.251, '#ec955f'],
  [0.255, '#eb945f'],
  [0.259, '#e9945e'],
  [0.263, '#e8935e'],
  [0.267, '#e7925d'],
  [0.271, '#e6915d'],
  [0.275, '#e5915c'],
  [0.278, '#e3905c'],
  [0.282, '#e28f5b'],
  [0.286, '#e18e5b'],
  [0.29, '#e08d5a'],
  [0.294, '#de8d5a'],
  [0.298, '#dd8c59'],
  [0.302, '#dc8b59'],
  [0.306, '#db8a58'],
  [0.31, '#d98958'],
  [0.314, '#d88957'],
  [0.318, '#d78857'],
  [0.322, '#d68756'],
  [0.325, '#d48656'],
  [0.329, '#d38655'],
  [0.333, '#d28555'],
  [0.337, '#d18454'],
  [0.341, '#d08354'],
  [0.345, '#ce8253'],
  [0.349, '#cd8253'],
  [0.353, '#cc8152'],
  [0.357, '#cb8052'],
  [0.361, '#c97f51'],
  [0.365, '#c87f51'],
  [0.369, '#c77e50'],
  [0.373, '#c67d50'],
  [0.376, '#c47c4f'],
  [0.38, '#c37b4f'],
  [0.384, '#c27b4e'],
  [0.388, '#c17a4e'],
  [0.392, '#bf794d'],
  [0.396, '#be784d'],
  [0.4, '#bd784c'],
  [0.404, '#bc774c'],
  [0.408, '#bb764b'],
  [0.412, '#b9754b'],
  [0.416, '#b8744a'],
  [0.42, '#b7744a'],
  [0.424, '#b67349'],
  [0.427, '#b47249'],
  [0.431, '#b37148'],
  [0.435, '#b27048'],
  [0.439, '#b17047'],
  [0.443, '#af6f47'],
  [0.447, '#ae6e46'],
  [0.451, '#ad6d46'],
  [0.455, '#ac6d45'],
  [0.459, '#aa6c45'],
  [0.463, '#a96b44'],
  [0.467, '#a86a44'],
  [0.471, '#a76943'],
  [0.475, '#a66943'],
  [0.478, '#a46842'],
  [0.482, '#a36742'],
  [0.486, '#a26641'],
  [0.49, '#a16641'],
  [0.494, '#9f6540'],
  [0.498, '#9e6440'],
  [0.502, '#9d633f'],
  [0.506, '#9c623f'],
  [0.51, '#9a623e'],
  [0.514, '#99613e'],
  [0.518, '#98603d'],
  [0.522, '#975f3d'],
  [0.525, '#955f3c'],
  [0.529, '#945e3c'],
  [0.533, '#935d3b'],
  [0.537, '#925c3b'],
  [0.541, '#915b3a'],
  [0.545, '#8f5b3a'],
  [0.549, '#8e5a39'],
  [0.553, '#8d5939'],
  [0.557, '#8c5838'],
  [0.561, '#8a5738'],
  [0.565, '#895737'],
  [0.569, '#885637'],
  [0.573, '#875536'],
  [0.576, '#855436'],
  [0.58, '#845435'],
  [0.584, '#835335'],
  [0.588, '#825234'],
  [0.592, '#805134'],
  [0.596, '#7f5033'],
  [0.6, '#7e5033'],
  [0.604, '#7d4f32'],
  [0.608, '#7c4e32'],
  [0.612, '#7a4d31'],
  [0.616, '#794d31'],
  [0.62, '#784c30'],
  [0.624, '#774b30'],
  [0.627, '#754a2f'],
  [0.631, '#74492f'],
  [0.635, '#73492e'],
  [0.639, '#72482e'],
  [0.643, '#70472d'],
  [0.647, '#6f462d'],
  [0.651, '#6e462c'],
  [0.655, '#6d452c'],
  [0.659, '#6b442b'],
  [0.663, '#6a432b'],
  [0.667, '#69422a'],
  [0.671, '#68422a'],
  [0.675, '#674129'],
  [0.678, '#654029'],
  [0.682, '#643f28'],
  [0.686, '#633e28'],
  [0.69, '#623e27'],
  [0.694, '#603d27'],
  [0.698, '#5f3c26'],
  [0.702, '#5e3b26'],
  [0.706, '#5d3b25'],
  [0.71, '#5b3a25'],
  [0.714, '#5a3924'],
  [0.718, '#593824'],
  [0.722, '#583723'],
  [0.725, '#563723'],
  [0.729, '#553622'],
  [0.733, '#543522'],
  [0.737, '#533421'],
  [0.741, '#523421'],
  [0.745, '#503320'],
  [0.749, '#4f3220'],
  [0.753, '#4e311f'],
  [0.757, '#4d301f'],
  [0.761, '#4b301e'],
  [0.765, '#4a2f1e'],
  [0.769, '#492e1d'],
  [0.773, '#482d1d'],
  [0.776, '#462d1c'],
  [0.78, '#452c1c'],
  [0.784, '#442b1b'],
  [0.788, '#432a1b'],
  [0.792, '#41291a'],
  [0.796, '#40291a'],
  [0.8, '#3f2819'],
  [0.804, '#3e2719'],
  [0.808, '#3d2618'],
  [0.812, '#3b2518'],
  [0.816, '#3a2517'],
  [0.82, '#392417'],
  [0.824, '#382316'],
  [0.827, '#362216'],
  [0.831, '#352215'],
  [0.835, '#342115'],
  [0.839, '#332014'],
  [0.843, '#311f14'],
  [0.847, '#301e13'],
  [0.851, '#2f1e13'],
  [0.855, '#2e1d12'],
  [0.859, '#2c1c12'],
  [0.863, '#2b1b11'],
  [0.867, '#2a1b11'],
  [0.871, '#291a10'],
  [0.875, '#281910'],
  [0.878, '#26180f'],
  [0.882, '#25170f'],
  [0.886, '#24170e'],
  [0.89, '#23160e'],
  [0.894, '#21150d'],
  [0.898, '#20140d'],
  [0.902, '#1f140c'],
  [0.906, '#1e130c'],
  [0.91, '#1c120b'],
  [0.914, '#1b110b'],
  [0.918, '#1a100a'],
  [0.922, '#19100a'],
  [0.925, '#170f09'],
  [0.929, '#160e09'],
  [0.933, '#150d08'],
  [0.937, '#140c08'],
  [0.941, '#130c07'],
  [0.945, '#110b07'],
  [0.949, '#100a06'],
  [0.953, '#0f0906'],
  [0.957, '#0e0905'],
  [0.961, '#0c0805'],
  [0.965, '#0b0704'],
  [0.969, '#0a0604'],
  [0.973, '#090503'],
  [0.976, '#070503'],
  [0.98, '#060402'],
  [0.984, '#050302'],
  [0.988, '#040201'],
  [0.992, '#020201'],
  [0.996, '#010100'],
  [1.0, '#000000'],
];

export const CHLORIS_CARBON_STOCK_STOPS: GradientStops = [
  [0.0, '#bf148c'],
  [0.004, '#ba138b'],
  [0.008, '#b5138a'],
  [0.012, '#b01288'],
  [0.016, '#ab1287'],
  [0.02, '#a61186'],
  [0.024, '#a11185'],
  [0.027, '#9c1084'],
  [0.031, '#971083'],
  [0.035, '#930f81'],
  [0.039, '#8e0f80'],
  [0.043, '#890e7f'],
  [0.047, '#840e7e'],
  [0.051, '#7f0d7d'],
  [0.055, '#7a0d7c'],
  [0.059, '#750c7a'],
  [0.063, '#700c79'],
  [0.067, '#6b0b78'],
  [0.071, '#660b77'],
  [0.075, '#610a76'],
  [0.078, '#5c0a74'],
  [0.082, '#570973'],
  [0.086, '#520972'],
  [0.09, '#4d0871'],
  [0.094, '#480870'],
  [0.098, '#43076f'],
  [0.102, '#41076f'],
  [0.106, '#420770'],
  [0.11, '#420872'],
  [0.114, '#420873'],
  [0.118, '#430875'],
  [0.122, '#430877'],
  [0.125, '#440878'],
  [0.129, '#44097a'],
  [0.133, '#44097b'],
  [0.137, '#45097d'],
  [0.141, '#45097e'],
  [0.145, '#460a80'],
  [0.149, '#460a82'],
  [0.153, '#460a83'],
  [0.157, '#470a85'],
  [0.161, '#470a86'],
  [0.165, '#470b88'],
  [0.169, '#480b89'],
  [0.173, '#470c8a'],
  [0.176, '#460e8b'],
  [0.18, '#44108b'],
  [0.184, '#43128c'],
  [0.188, '#42148c'],
  [0.192, '#40168d'],
  [0.196, '#3f188d'],
  [0.2, '#3e1a8e'],
  [0.204, '#3c1c8f'],
  [0.208, '#3b1e8f'],
  [0.212, '#3a2090'],
  [0.216, '#382290'],
  [0.22, '#372491'],
  [0.224, '#362691'],
  [0.227, '#342892'],
  [0.231, '#332a92'],
  [0.235, '#322c93'],
  [0.239, '#302e93'],
  [0.243, '#2f3094'],
  [0.247, '#2e3294'],
  [0.251, '#2c3495'],
  [0.255, '#2b3695'],
  [0.259, '#2a3896'],
  [0.263, '#283a96'],
  [0.267, '#273c97'],
  [0.271, '#263e97'],
  [0.275, '#244098'],
  [0.278, '#234298'],
  [0.282, '#224499'],
  [0.286, '#20469a'],
  [0.29, '#1f489a'],
  [0.294, '#1e4a9b'],
  [0.298, '#1c4c9b'],
  [0.302, '#1b4e9c'],
  [0.306, '#1a509c'],
  [0.31, '#18529d'],
  [0.314, '#17549d'],
  [0.318, '#16569e'],
  [0.322, '#15589e'],
  [0.325, '#15599e'],
  [0.329, '#155b9f'],
  [0.333, '#155c9f'],
  [0.337, '#145e9f'],
  [0.341, '#145fa0'],
  [0.345, '#1461a0'],
  [0.349, '#1463a0'],
  [0.353, '#1464a1'],
  [0.357, '#1466a1'],
  [0.361, '#1467a1'],
  [0.365, '#1469a2'],
  [0.369, '#136aa2'],
  [0.373, '#136ca2'],
  [0.376, '#136ea3'],
  [0.38, '#136fa3'],
  [0.384, '#1371a3'],
  [0.388, '#1372a4'],
  [0.392, '#1374a4'],
  [0.396, '#1275a4'],
  [0.4, '#1277a5'],
  [0.404, '#1279a5'],
  [0.408, '#127aa5'],
  [0.412, '#127ca6'],
  [0.416, '#127da6'],
  [0.42, '#127fa6'],
  [0.424, '#1280a7'],
  [0.427, '#1182a7'],
  [0.431, '#1184a7'],
  [0.435, '#1185a8'],
  [0.439, '#1187a8'],
  [0.443, '#1188a5'],
  [0.447, '#1189a1'],
  [0.451, '#118a9d'],
  [0.455, '#118c99'],
  [0.459, '#118d95'],
  [0.463, '#118e91'],
  [0.467, '#118f8d'],
  [0.471, '#119089'],
  [0.475, '#119285'],
  [0.478, '#119382'],
  [0.482, '#11947e'],
  [0.486, '#11957a'],
  [0.49, '#119776'],
  [0.494, '#119872'],
  [0.498, '#11996e'],
  [0.502, '#119a6a'],
  [0.506, '#119b66'],
  [0.51, '#119d62'],
  [0.514, '#119e5e'],
  [0.518, '#119f5a'],
  [0.522, '#11a056'],
  [0.525, '#11a253'],
  [0.529, '#11a34f'],
  [0.533, '#11a44b'],
  [0.537, '#11a547'],
  [0.541, '#11a644'],
  [0.545, '#13a842'],
  [0.549, '#15a941'],
  [0.553, '#16aa40'],
  [0.557, '#18ab3f'],
  [0.561, '#19ac3e'],
  [0.565, '#1bae3d'],
  [0.569, '#1caf3b'],
  [0.573, '#1eb03a'],
  [0.576, '#20b139'],
  [0.58, '#21b338'],
  [0.584, '#23b437'],
  [0.588, '#24b536'],
  [0.592, '#26b634'],
  [0.596, '#27b733'],
  [0.6, '#29b932'],
  [0.604, '#2bba31'],
  [0.608, '#2cbb30'],
  [0.612, '#2ebc2e'],
  [0.616, '#2fbe2d'],
  [0.62, '#31bf2c'],
  [0.624, '#32c02b'],
  [0.627, '#34c12a'],
  [0.631, '#36c229'],
  [0.635, '#39c32a'],
  [0.639, '#3cc42a'],
  [0.643, '#3fc52b'],
  [0.647, '#42c62b'],
  [0.651, '#45c72c'],
  [0.655, '#48c82c'],
  [0.659, '#4bc92d'],
  [0.663, '#4ec92d'],
  [0.667, '#51ca2e'],
  [0.671, '#54cb2e'],
  [0.675, '#57cc2f'],
  [0.678, '#5acd2f'],
  [0.682, '#5dce30'],
  [0.686, '#60cf30'],
  [0.69, '#63d031'],
  [0.694, '#66d131'],
  [0.698, '#69d132'],
  [0.702, '#6cd232'],
  [0.706, '#6fd333'],
  [0.71, '#72d433'],
  [0.714, '#75d534'],
  [0.718, '#78d634'],
  [0.722, '#7bd735'],
  [0.725, '#7ed835'],
  [0.729, '#81d936'],
  [0.733, '#84d936'],
  [0.737, '#87da37'],
  [0.741, '#8adb38'],
  [0.745, '#8ddb3b'],
  [0.749, '#91dc3f'],
  [0.753, '#94dc42'],
  [0.757, '#98dc45'],
  [0.761, '#9bdc48'],
  [0.765, '#9edc4c'],
  [0.769, '#a2dd4f'],
  [0.773, '#a5dd52'],
  [0.776, '#a9dd55'],
  [0.78, '#acdd59'],
  [0.784, '#afdd5c'],
  [0.788, '#b3de5f'],
  [0.792, '#b6de62'],
  [0.796, '#bade66'],
  [0.8, '#bdde69'],
  [0.804, '#c0df6c'],
  [0.808, '#c4df70'],
  [0.812, '#c7df73'],
  [0.816, '#cbdf76'],
  [0.82, '#cedf79'],
  [0.824, '#d1e07d'],
  [0.827, '#d5e080'],
  [0.831, '#d7e083'],
  [0.835, '#d7df85'],
  [0.839, '#d8df87'],
  [0.843, '#d8df89'],
  [0.847, '#d9de8b'],
  [0.851, '#d9de8e'],
  [0.855, '#d9dd90'],
  [0.859, '#dadd92'],
  [0.863, '#dadc94'],
  [0.867, '#dadc96'],
  [0.871, '#dbdb99'],
  [0.875, '#dbdb9b'],
  [0.878, '#dbdb9d'],
  [0.882, '#dcda9f'],
  [0.886, '#dcdaa1'],
  [0.89, '#dcd9a3'],
  [0.894, '#ddd9a6'],
  [0.898, '#ddd8a8'],
  [0.902, '#ddd8aa'],
  [0.906, '#ded8ac'],
  [0.91, '#ded7ae'],
  [0.914, '#ded7b1'],
  [0.918, '#dfd6b3'],
  [0.922, '#e0d7b5'],
  [0.925, '#e1d9b9'],
  [0.929, '#e2dabc'],
  [0.933, '#e4dcc0'],
  [0.937, '#e5dec4'],
  [0.941, '#e7e0c7'],
  [0.945, '#e8e2cb'],
  [0.949, '#eae4ce'],
  [0.953, '#ebe6d2'],
  [0.957, '#ece8d5'],
  [0.961, '#eee9d9'],
  [0.965, '#efebdc'],
  [0.969, '#f1ede0'],
  [0.973, '#f2efe3'],
  [0.976, '#f3f1e7'],
  [0.98, '#f5f3ea'],
  [0.984, '#f6f5ee'],
  [0.988, '#f8f6f1'],
  [0.992, '#f9f8f5'],
  [0.996, '#fbfaf8'],
  [1.0, '#ffffff'],
];

export const GNBU_REVERSE_GRADIENT_STOPS: GradientStops = [
  [0.0, '#f7fcf0'],
  [0.004, '#f6fcef'],
  [0.008, '#f6fbef'],
  [0.012, '#f5fbee'],
  [0.016, '#f4fbed'],
  [0.02, '#f3fbed'],
  [0.024, '#f3faec'],
  [0.027, '#f2faeb'],
  [0.031, '#f1faeb'],
  [0.035, '#f1f9ea'],
  [0.039, '#f0f9e9'],
  [0.043, '#eff9e9'],
  [0.047, '#eef9e8'],
  [0.051, '#eef8e7'],
  [0.055, '#edf8e7'],
  [0.059, '#ecf8e6'],
  [0.063, '#ebf7e5'],
  [0.067, '#ebf7e5'],
  [0.071, '#eaf7e4'],
  [0.075, '#e9f7e3'],
  [0.078, '#e9f6e3'],
  [0.082, '#e8f6e2'],
  [0.086, '#e7f6e2'],
  [0.09, '#e6f6e1'],
  [0.094, '#e6f5e0'],
  [0.098, '#e5f5e0'],
  [0.102, '#e4f5df'],
  [0.106, '#e4f4de'],
  [0.11, '#e3f4de'],
  [0.114, '#e2f4dd'],
  [0.118, '#e1f4dc'],
  [0.122, '#e1f3dc'],
  [0.125, '#e0f3db'],
  [0.129, '#dff3da'],
  [0.133, '#dff2da'],
  [0.137, '#def2d9'],
  [0.141, '#ddf2d8'],
  [0.145, '#ddf2d7'],
  [0.149, '#dcf1d7'],
  [0.153, '#dcf1d6'],
  [0.157, '#dbf1d5'],
  [0.161, '#daf1d5'],
  [0.165, '#daf0d4'],
  [0.169, '#d9f0d3'],
  [0.173, '#d8f0d3'],
  [0.176, '#d8f0d2'],
  [0.18, '#d7efd1'],
  [0.184, '#d7efd1'],
  [0.188, '#d6efd0'],
  [0.192, '#d5efcf'],
  [0.196, '#d5eece'],
  [0.2, '#d4eece'],
  [0.204, '#d3eecd'],
  [0.208, '#d3eecc'],
  [0.212, '#d2edcc'],
  [0.216, '#d1edcb'],
  [0.22, '#d1edca'],
  [0.224, '#d0edca'],
  [0.227, '#d0ecc9'],
  [0.231, '#cfecc8'],
  [0.235, '#ceecc8'],
  [0.239, '#ceecc7'],
  [0.243, '#cdebc6'],
  [0.247, '#ccebc6'],
  [0.251, '#ccebc5'],
  [0.255, '#cbeac4'],
  [0.259, '#c9eac4'],
  [0.263, '#c8eac3'],
  [0.267, '#c7e9c3'],
  [0.271, '#c6e9c2'],
  [0.275, '#c5e8c2'],
  [0.278, '#c4e8c1'],
  [0.282, '#c3e7c1'],
  [0.286, '#c2e7c0'],
  [0.29, '#c0e6c0'],
  [0.294, '#bfe6bf'],
  [0.298, '#bee6bf'],
  [0.302, '#bde5be'],
  [0.306, '#bce5be'],
  [0.31, '#bbe4bd'],
  [0.314, '#bae4bd'],
  [0.318, '#b9e3bc'],
  [0.322, '#b7e3bc'],
  [0.325, '#b6e3bb'],
  [0.329, '#b5e2bb'],
  [0.333, '#b4e2ba'],
  [0.337, '#b3e1ba'],
  [0.341, '#b2e1b9'],
  [0.345, '#b1e0b9'],
  [0.349, '#afe0b8'],
  [0.353, '#aedfb8'],
  [0.357, '#addfb7'],
  [0.361, '#acdfb7'],
  [0.365, '#abdeb6'],
  [0.369, '#aadeb6'],
  [0.373, '#a9ddb5'],
  [0.376, '#a7ddb5'],
  [0.38, '#a6dcb6'],
  [0.384, '#a5dcb6'],
  [0.388, '#a3dbb7'],
  [0.392, '#a2dbb7'],
  [0.396, '#a0dab8'],
  [0.4, '#9fdab8'],
  [0.404, '#9ed9b8'],
  [0.408, '#9cd9b9'],
  [0.412, '#9bd8b9'],
  [0.416, '#99d7ba'],
  [0.42, '#98d7ba'],
  [0.424, '#97d6bb'],
  [0.427, '#95d6bb'],
  [0.431, '#94d5bc'],
  [0.435, '#92d5bc'],
  [0.439, '#91d4bd'],
  [0.443, '#8fd4bd'],
  [0.447, '#8ed3be'],
  [0.451, '#8dd3be'],
  [0.455, '#8bd2bf'],
  [0.459, '#8ad2bf'],
  [0.463, '#88d1c0'],
  [0.467, '#87d1c0'],
  [0.471, '#86d0c0'],
  [0.475, '#84cfc1'],
  [0.478, '#83cfc1'],
  [0.482, '#81cec2'],
  [0.486, '#80cec2'],
  [0.49, '#7fcdc3'],
  [0.494, '#7dcdc3'],
  [0.498, '#7cccc4'],
  [0.502, '#7accc4'],
  [0.506, '#79cbc5'],
  [0.51, '#77cac5'],
  [0.514, '#76c9c6'],
  [0.518, '#75c8c6'],
  [0.522, '#73c8c7'],
  [0.525, '#72c7c7'],
  [0.529, '#70c6c8'],
  [0.533, '#6fc5c8'],
  [0.537, '#6ec5c8'],
  [0.541, '#6cc4c9'],
  [0.545, '#6bc3c9'],
  [0.549, '#69c2ca'],
  [0.553, '#68c1ca'],
  [0.557, '#67c1cb'],
  [0.561, '#65c0cb'],
  [0.565, '#64bfcc'],
  [0.569, '#62becc'],
  [0.573, '#61bdcd'],
  [0.576, '#5fbdcd'],
  [0.58, '#5ebcce'],
  [0.584, '#5dbbce'],
  [0.588, '#5bbacf'],
  [0.592, '#5abacf'],
  [0.596, '#58b9d0'],
  [0.6, '#57b8d0'],
  [0.604, '#56b7d0'],
  [0.608, '#54b6d1'],
  [0.612, '#53b6d1'],
  [0.616, '#51b5d2'],
  [0.62, '#50b4d2'],
  [0.624, '#4fb3d3'],
  [0.627, '#4db2d3'],
  [0.631, '#4cb1d2'],
  [0.635, '#4bb0d1'],
  [0.639, '#4aafd1'],
  [0.643, '#49add0'],
  [0.647, '#48accf'],
  [0.651, '#47abcf'],
  [0.655, '#46aace'],
  [0.659, '#45a8cd'],
  [0.663, '#43a7cd'],
  [0.667, '#42a6cc'],
  [0.671, '#41a5cb'],
  [0.675, '#40a4cb'],
  [0.678, '#3fa2ca'],
  [0.682, '#3ea1c9'],
  [0.686, '#3da0c9'],
  [0.69, '#3c9fc8'],
  [0.694, '#3b9dc7'],
  [0.698, '#3a9cc7'],
  [0.702, '#389bc6'],
  [0.706, '#379ac5'],
  [0.71, '#3699c5'],
  [0.714, '#3597c4'],
  [0.718, '#3496c3'],
  [0.722, '#3395c3'],
  [0.725, '#3294c2'],
  [0.729, '#3192c1'],
  [0.733, '#3091c1'],
  [0.737, '#2f90c0'],
  [0.741, '#2d8fbf'],
  [0.745, '#2c8ebf'],
  [0.749, '#2b8cbe'],
  [0.753, '#2a8bbe'],
  [0.757, '#298abd'],
  [0.761, '#2889bc'],
  [0.765, '#2788bc'],
  [0.769, '#2687bb'],
  [0.773, '#2586bb'],
  [0.776, '#2484ba'],
  [0.78, '#2283ba'],
  [0.784, '#2182b9'],
  [0.788, '#2081b8'],
  [0.792, '#1f80b8'],
  [0.796, '#1e7fb7'],
  [0.8, '#1d7eb7'],
  [0.804, '#1c7cb6'],
  [0.808, '#1b7bb6'],
  [0.812, '#1a7ab5'],
  [0.816, '#1979b5'],
  [0.82, '#1878b4'],
  [0.824, '#1677b3'],
  [0.827, '#1576b3'],
  [0.831, '#1475b2'],
  [0.835, '#1373b2'],
  [0.839, '#1272b1'],
  [0.843, '#1171b1'],
  [0.847, '#1070b0'],
  [0.851, '#0f6faf'],
  [0.855, '#0e6eaf'],
  [0.859, '#0d6dae'],
  [0.863, '#0b6cae'],
  [0.867, '#0a6aad'],
  [0.871, '#0969ad'],
  [0.875, '#0868ac'],
  [0.878, '#0867ab'],
  [0.882, '#0866a9'],
  [0.886, '#0864a8'],
  [0.89, '#0863a7'],
  [0.894, '#0862a5'],
  [0.898, '#0861a4'],
  [0.902, '#085fa3'],
  [0.906, '#085ea1'],
  [0.91, '#085da0'],
  [0.914, '#085c9f'],
  [0.918, '#085a9d'],
  [0.922, '#08599c'],
  [0.925, '#08589b'],
  [0.929, '#085799'],
  [0.933, '#085598'],
  [0.937, '#085497'],
  [0.941, '#085395'],
  [0.945, '#085294'],
  [0.949, '#085093'],
  [0.953, '#084f91'],
  [0.957, '#084e90'],
  [0.961, '#084d8e'],
  [0.965, '#084b8d'],
  [0.969, '#084a8c'],
  [0.973, '#08498a'],
  [0.976, '#084889'],
  [0.98, '#084688'],
  [0.984, '#084586'],
  [0.988, '#084485'],
  [0.992, '#084384'],
  [0.996, '#084182'],
  [1.0, '#084081'],
];

export const SEISMIC_R_GRADIENT_STOPS: GradientStops = [
  [0.0, '#00004c'],
  [0.004, '#00004f'],
  [0.008, '#000052'],
  [0.012, '#000055'],
  [0.016, '#000058'],
  [0.02, '#00005b'],
  [0.024, '#00005d'],
  [0.027, '#000060'],
  [0.031, '#000063'],
  [0.035, '#000066'],
  [0.039, '#000068'],
  [0.043, '#00006b'],
  [0.047, '#00006e'],
  [0.051, '#000071'],
  [0.055, '#000074'],
  [0.059, '#000077'],
  [0.063, '#000079'],
  [0.067, '#00007c'],
  [0.071, '#00007f'],
  [0.075, '#000082'],
  [0.078, '#000084'],
  [0.082, '#000087'],
  [0.086, '#00008a'],
  [0.09, '#00008d'],
  [0.094, '#000090'],
  [0.098, '#000093'],
  [0.102, '#000095'],
  [0.106, '#000098'],
  [0.11, '#00009b'],
  [0.114, '#00009e'],
  [0.118, '#0000a0'],
  [0.122, '#0000a3'],
  [0.125, '#0000a6'],
  [0.129, '#0000a9'],
  [0.133, '#0000ac'],
  [0.137, '#0000ae'],
  [0.141, '#0000b1'],
  [0.145, '#0000b4'],
  [0.149, '#0000b7'],
  [0.153, '#0000ba'],
  [0.157, '#0000bc'],
  [0.161, '#0000bf'],
  [0.165, '#0000c2'],
  [0.169, '#0000c5'],
  [0.173, '#0000c8'],
  [0.176, '#0000cb'],
  [0.18, '#0000cd'],
  [0.184, '#0000d0'],
  [0.188, '#0000d3'],
  [0.192, '#0000d6'],
  [0.196, '#0000d8'],
  [0.2, '#0000db'],
  [0.204, '#0000de'],
  [0.208, '#0000e1'],
  [0.212, '#0000e4'],
  [0.216, '#0000e6'],
  [0.22, '#0000e9'],
  [0.224, '#0000ec'],
  [0.227, '#0000ef'],
  [0.231, '#0000f2'],
  [0.235, '#0000f4'],
  [0.239, '#0000f7'],
  [0.243, '#0000fa'],
  [0.247, '#0000fd'],
  [0.251, '#0101ff'],
  [0.255, '#0505ff'],
  [0.259, '#0909ff'],
  [0.263, '#0d0dff'],
  [0.267, '#1111ff'],
  [0.271, '#1515ff'],
  [0.275, '#1919ff'],
  [0.278, '#1d1dff'],
  [0.282, '#2121ff'],
  [0.286, '#2525ff'],
  [0.29, '#2929ff'],
  [0.294, '#2d2dff'],
  [0.298, '#3131ff'],
  [0.302, '#3535ff'],
  [0.306, '#3939ff'],
  [0.31, '#3d3dff'],
  [0.314, '#4141ff'],
  [0.318, '#4545ff'],
  [0.322, '#4949ff'],
  [0.325, '#4d4dff'],
  [0.329, '#5151ff'],
  [0.333, '#5555ff'],
  [0.337, '#5959ff'],
  [0.341, '#5d5dff'],
  [0.345, '#6161ff'],
  [0.349, '#6565ff'],
  [0.353, '#6969ff'],
  [0.357, '#6d6dff'],
  [0.361, '#7171ff'],
  [0.365, '#7575ff'],
  [0.369, '#7979ff'],
  [0.373, '#7d7dff'],
  [0.376, '#8181ff'],
  [0.38, '#8585ff'],
  [0.384, '#8989ff'],
  [0.388, '#8d8dff'],
  [0.392, '#9191ff'],
  [0.396, '#9595ff'],
  [0.4, '#9999ff'],
  [0.404, '#9d9dff'],
  [0.408, '#a1a1ff'],
  [0.412, '#a5a5ff'],
  [0.416, '#a9a9ff'],
  [0.42, '#adadff'],
  [0.424, '#b1b1ff'],
  [0.427, '#b5b5ff'],
  [0.431, '#b9b9ff'],
  [0.435, '#bdbdff'],
  [0.439, '#c1c1ff'],
  [0.443, '#c5c5ff'],
  [0.447, '#c9c9ff'],
  [0.451, '#cdcdff'],
  [0.455, '#d1d1ff'],
  [0.459, '#d5d5ff'],
  [0.463, '#d9d9ff'],
  [0.467, '#ddddff'],
  [0.471, '#e1e1ff'],
  [0.475, '#e5e5ff'],
  [0.478, '#e9e9ff'],
  [0.482, '#ededff'],
  [0.486, '#f1f1ff'],
  [0.49, '#f5f5ff'],
  [0.494, '#f9f9ff'],
  [0.498, '#fdfdff'],
  [0.502, '#fffdfd'],
  [0.506, '#fff9f9'],
  [0.51, '#fff5f5'],
  [0.514, '#fff1f1'],
  [0.518, '#ffeded'],
  [0.522, '#ffe9e9'],
  [0.525, '#ffe5e5'],
  [0.529, '#ffe1e1'],
  [0.533, '#ffdddd'],
  [0.537, '#ffd9d9'],
  [0.541, '#ffd5d5'],
  [0.545, '#ffd1d1'],
  [0.549, '#ffcdcd'],
  [0.553, '#ffc9c9'],
  [0.557, '#ffc5c5'],
  [0.561, '#ffc1c1'],
  [0.565, '#ffbdbd'],
  [0.569, '#ffb9b9'],
  [0.573, '#ffb5b5'],
  [0.576, '#ffb1b1'],
  [0.58, '#ffadad'],
  [0.584, '#ffa9a9'],
  [0.588, '#ffa5a5'],
  [0.592, '#ffa1a1'],
  [0.596, '#ff9d9d'],
  [0.6, '#ff9999'],
  [0.604, '#ff9595'],
  [0.608, '#ff9191'],
  [0.612, '#ff8d8d'],
  [0.616, '#ff8989'],
  [0.62, '#ff8585'],
  [0.624, '#ff8181'],
  [0.627, '#ff7d7d'],
  [0.631, '#ff7979'],
  [0.635, '#ff7575'],
  [0.639, '#ff7171'],
  [0.643, '#ff6d6d'],
  [0.647, '#ff6969'],
  [0.651, '#ff6565'],
  [0.655, '#ff6161'],
  [0.659, '#ff5d5d'],
  [0.663, '#ff5959'],
  [0.667, '#ff5555'],
  [0.671, '#ff5151'],
  [0.675, '#ff4d4d'],
  [0.678, '#ff4949'],
  [0.682, '#ff4545'],
  [0.686, '#ff4141'],
  [0.69, '#ff3d3d'],
  [0.694, '#ff3939'],
  [0.698, '#ff3535'],
  [0.702, '#ff3131'],
  [0.706, '#ff2d2d'],
  [0.71, '#ff2929'],
  [0.714, '#ff2525'],
  [0.718, '#ff2121'],
  [0.722, '#ff1d1d'],
  [0.725, '#ff1919'],
  [0.729, '#ff1515'],
  [0.733, '#ff1111'],
  [0.737, '#ff0d0d'],
  [0.741, '#ff0909'],
  [0.745, '#ff0505'],
  [0.749, '#ff0101'],
  [0.753, '#fe0000'],
  [0.757, '#fc0000'],
  [0.761, '#fa0000'],
  [0.765, '#f80000'],
  [0.769, '#f60000'],
  [0.773, '#f30000'],
  [0.776, '#f20000'],
  [0.78, '#f00000'],
  [0.784, '#ee0000'],
  [0.788, '#ec0000'],
  [0.792, '#ea0000'],
  [0.796, '#e80000'],
  [0.8, '#e60000'],
  [0.804, '#e30000'],
  [0.808, '#e20000'],
  [0.812, '#e00000'],
  [0.816, '#de0000'],
  [0.82, '#dc0000'],
  [0.824, '#da0000'],
  [0.827, '#d80000'],
  [0.831, '#d60000'],
  [0.835, '#d30000'],
  [0.839, '#d20000'],
  [0.843, '#d00000'],
  [0.847, '#ce0000'],
  [0.851, '#cc0000'],
  [0.855, '#ca0000'],
  [0.859, '#c80000'],
  [0.863, '#c60000'],
  [0.867, '#c30000'],
  [0.871, '#c20000'],
  [0.875, '#c00000'],
  [0.878, '#be0000'],
  [0.882, '#bc0000'],
  [0.886, '#ba0000'],
  [0.89, '#b80000'],
  [0.894, '#b60000'],
  [0.898, '#b30000'],
  [0.902, '#b20000'],
  [0.906, '#b00000'],
  [0.91, '#ae0000'],
  [0.914, '#ac0000'],
  [0.918, '#aa0000'],
  [0.922, '#a80000'],
  [0.925, '#a60000'],
  [0.929, '#a30000'],
  [0.933, '#a20000'],
  [0.937, '#a00000'],
  [0.941, '#9e0000'],
  [0.945, '#9c0000'],
  [0.949, '#9a0000'],
  [0.953, '#980000'],
  [0.957, '#960000'],
  [0.961, '#930000'],
  [0.965, '#920000'],
  [0.969, '#900000'],
  [0.973, '#8e0000'],
  [0.976, '#8c0000'],
  [0.98, '#8a0000'],
  [0.984, '#880000'],
  [0.988, '#860000'],
  [0.992, '#840000'],
  [0.996, '#820000'],
  [1.0, '#800000'],
];
