import * as B from '@blueprintjs/core';
import {Elements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import classnames from 'classnames';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {StringParam, useQueryParam} from 'use-query-params';

import {api} from 'app/modules/Remote';
import {ApiOrganizationUser, ApiPaidLayerInvoice} from 'app/modules/Remote/Organization';
import * as C from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import {StatusMaybe, useApiGet} from 'app/utils/hookUtils';
import {parseLayerKey} from 'app/utils/layerUtils';
import {STRIPE_API_KEY} from 'app/utils/stripeUtils';

import cs from './LensLibraryView.styl';
import type {LayerDataset, LibraryDataset} from './LibraryTypes';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_API_KEY);

export const LibraryCheckoutModal: React.FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean;
    closeCheckoutModal: (extraQueryParamsToClear: string[]) => void;
    closeAllModals: (extraQueryParamsToClear?: string[]) => void;
    isOtherModalOpen: boolean;
    layer: LibraryDataset<LayerDataset>;
    organizationId: string;
    purchasePaidLayer: (
      sourceId: string,
      billingInterval: string,
      subscriptionProrationDate: number,
      billingCycleAnchor: number,
      couponId: string | undefined
    ) => Promise<string>;
    role: ApiOrganizationUser['role'];
  }>
> = ({
  isOpen,
  closeCheckoutModal,
  closeAllModals,
  isOtherModalOpen,
  layer,
  organizationId,
  purchasePaidLayer,
  role,
}) => {
  const {sourceId} = parseLayerKey(layer.layerKey);
  const [_checkoutPromotionCode, _setCheckoutPromotionCode] = useQueryParam(
    'promotionCode',
    StringParam
  );
  const checkoutPromotionCode = _checkoutPromotionCode || '';
  const setCheckoutPromotionCode = (newPromotionCode: typeof _checkoutPromotionCode) =>
    _setCheckoutPromotionCode(newPromotionCode, 'replaceIn');

  const [_selectedBillingInterval, _setSelectedBillingInterval] = useQueryParam(
    'billingInterval',
    StringParam
  );
  const setSelectedBillingInterval = (newPromotionCode: typeof _selectedBillingInterval) =>
    _setSelectedBillingInterval(newPromotionCode, 'replaceIn');

  const selectedBillingInterval =
    _selectedBillingInterval || (layer.billingIntervals ? layer.billingIntervals[0] : '');

  const [previewInvoice] = useApiGet(
    async (organizationId, sourceId, selectedBillingInterval, checkoutPromotionCode) =>
      await (
        await api.organizations.previewPaidLayerInvoice(
          organizationId,
          sourceId,
          selectedBillingInterval,
          {
            promotionCode: checkoutPromotionCode || '',
          }
        )
      ).get('data'),
    [organizationId, sourceId, selectedBillingInterval, checkoutPromotionCode]
  );

  return (
    <B.Dialog
      isOpen={isOpen}
      onClose={() => closeAllModals(['billingInterval', 'promotionCode'])}
      className={classnames(cs.libraryModal, cs.detailsModal)}
      transitionDuration={isOtherModalOpen ? 0 : undefined}
      backdropClassName={classnames({[cs.backdrop]: isOtherModalOpen})}
    >
      <div className={cs.detailsModalHeader}>
        {/* When clicking the back button we want to only close the checkout modal */}
        <B.Button
          minimal
          icon={'arrow-left'}
          onClick={() => closeCheckoutModal(['billingInterval', 'promotionCode'])}
          style={!isOtherModalOpen ? {visibility: 'hidden'} : undefined}
        />
        {/* When clicking the x button or clicking outside the modal, we want to
        close all the open modals */}
        <B.Button
          minimal
          icon={'cross'}
          onClick={() => closeAllModals(['billingInterval', 'promotionCode'])}
        />
      </div>
      <div className={cs.modalBody}>
        <div className={cs.detailsInfo}>
          <h2>
            Checkout for {layer.name} {layer.source}
          </h2>
          <div
            className={classnames(cs.detailsThumbnail, cs.checkoutModalThumbnail, cs.detailsText)}
          >
            <img src={layer.thumbnailUrl} />
          </div>

          <div className={cs.checkoutModalLayerInfoContainer}>
            <div className={cs.detailsText}>
              {!!layer.resolution && <div>{`Spatial Resolution: ${layer.resolution}m`}</div>}
            </div>
            {!!layer.tags?.length && (
              <div className={classnames(cs.detailsText, cs.tags)}>
                {layer.tags.map((t) => (
                  <B.Tag key={t.label} round minimal large style={{backgroundColor: t.color}}>
                    {t.label}
                  </B.Tag>
                ))}
              </div>
            )}
          </div>
          <div className={cs.detailsText}>{layer.description}</div>
        </div>
        <div className={cs.verticalDivider} />
        <Elements stripe={stripePromise}>
          <LibraryCheckoutInvoicePreview
            maybeInvoice={previewInvoice}
            layer={layer}
            setCheckoutPromotionCode={setCheckoutPromotionCode}
            defaultPromotionCode={checkoutPromotionCode}
            purchasePaidLayer={purchasePaidLayer}
            selectedBillingInterval={selectedBillingInterval}
            setSelectedBillingInterval={setSelectedBillingInterval}
            organizationId={organizationId}
            role={role}
          />
        </Elements>
      </div>
    </B.Dialog>
  );
};

function getCheckoutErrorText(
  errorMessage: string,
  errorStatus: number,
  layer: LibraryDataset<LayerDataset>
): string {
  // 402 is the error we raise for Stripe payment errors. Stripe provides an informative error message which we can use directly
  if (errorStatus === 402) {
    return errorMessage;
  } else if (errorMessage.includes('already has a subscription')) {
    return `${layer.source} ${layer.name} has already been purchased. Please return to the Lens Library to add this layer to your portfolios.`;
  } else {
    return "Something went wrong, but we're on it";
  }
}

const BILLING_INTERVAL_DISPLAYS = {
  month: 'Monthly',
  year: 'Annual',
};

const LibraryCheckoutInvoicePreview: React.FunctionComponent<
  React.PropsWithChildren<{
    maybeInvoice: StatusMaybe<I.ImmutableOf<ApiPaidLayerInvoice>>;
    layer: LibraryDataset<LayerDataset>;
    defaultPromotionCode: string;
    setCheckoutPromotionCode: (promotionCode: string | undefined) => void;
    purchasePaidLayer: (
      sourceId: string,
      billingInterval: string,
      subscriptionProrationDate: number,
      billingCycleAnchor: number,
      couponId: string | undefined
    ) => Promise<string>;
    selectedBillingInterval: string;
    setSelectedBillingInterval: (billingInterval: string) => void;
    organizationId: string;
    role: ApiOrganizationUser['role'];
  }>
> = ({
  maybeInvoice,
  layer,
  defaultPromotionCode,
  setCheckoutPromotionCode,
  purchasePaidLayer,
  selectedBillingInterval,
  setSelectedBillingInterval,
  organizationId,
  role,
}) => {
  const [isSubmittingCheckout, setIsSubmittingCheckout] = React.useState(false);
  const [checkoutError, setCheckoutError] = React.useState('');
  const [checkoutSucceeded, setCheckoutSucceeded] = React.useState(false);

  const stripe = useStripe();

  const billingIntervalSwitch = layer.billingIntervals && layer.billingIntervals.length > 1 && (
    <B.ButtonGroup className={cs.checkoutBillingIntervalsSwitch}>
      {layer.billingIntervals?.map((bi) => {
        return (
          <B.Button
            key={bi}
            text={BILLING_INTERVAL_DISPLAYS[bi]}
            active={bi === selectedBillingInterval}
            onClick={() => setSelectedBillingInterval(bi)}
            disabled={!maybeInvoice.value || checkoutSucceeded}
          />
        );
      })}
    </B.ButtonGroup>
  );

  if (maybeInvoice.value && stripe) {
    const invoice = maybeInvoice.value;
    const subscriptionItem = invoice.getIn(['lines', 'data']).first();
    const taxRateObject = invoice.get('total_tax_amounts')?.first()?.get('tax_rate');

    const percent_off = invoice.getIn(['discount', 'coupon', 'percent_off'], 0);

    const defaultPaymentMethod: ApiPaidLayerInvoice['default_payment_method'] = invoice
      .get('default_payment_method')
      ?.toJS();

    return (
      <div className={cs.checkoutInvoiceContainer}>
        {billingIntervalSwitch}
        <h3>Order summary</h3>
        <LibraryCheckoutPromotionCodeInput
          defaultPromotionCode={defaultPromotionCode}
          validPromotionCode={!!percent_off}
          isLoading={isSubmittingCheckout || checkoutSucceeded}
          setCheckoutPromotionCode={setCheckoutPromotionCode}
        />
        {!!percent_off && (
          <>
            <div className={cs.checkoutLineItem}>
              <span>
                {invoice.getIn(['discount', 'coupon', 'name'])} with code {defaultPromotionCode}
              </span>
            </div>
            <div className={cs.divider} />
          </>
        )}
        <div className={cs.checkoutLineItemsContainer}>
          <div className={cs.checkoutLineItem}>
            <div>
              <div>
                {layer.name} {layer.source}
              </div>
              <div className={cs.prorationText}>
                Prorated{' '}
                {moment(subscriptionItem.getIn(['period', 'start']) * 1000).format('MMMM Do, YYYY')}{' '}
                to{' '}
                {moment(subscriptionItem.getIn(['period', 'end']) * 1000).format('MMMM Do, YYYY')}
              </div>
            </div>
            <span>
              $
              {conversionUtils.numberWithCommas(
                (invoice.get('subtotal_excluding_tax') / 100).toFixed(2)
              )}
            </span>
          </div>
          {taxRateObject && (
            <div className={cs.checkoutLineItem}>
              <span>
                {taxRateObject.get('display_name')}{' '}
                {!!invoice.get('tax') && `(${taxRateObject.get('percentage')}%)`}
              </span>
              <span>
                ${conversionUtils.numberWithCommas((invoice.get('tax') / 100).toFixed(2))}
              </span>
            </div>
          )}
        </div>
        <div className={cs.divider} />
        <div className={cs.checkoutLineItemsContainer}>
          <div className={classnames(cs.total, cs.checkoutLineItem)}>
            <span>Total due today</span>
            <span>
              ${conversionUtils.numberWithCommas((invoice.get('total') / 100).toFixed(2))}
            </span>
          </div>
        </div>
        <div className={cs.checkoutLineItemsContainer}>
          <PaymentMethodEdit
            defaultPaymentMethod={defaultPaymentMethod}
            organizationId={organizationId}
            setCheckoutError={setCheckoutError}
            layer={layer}
            role={role}
          />
        </div>

        {role == C.USER_ROLE_OWNER ? (
          <div className={cs.checkoutButtonWrapper}>
            <B.Tooltip
              disabled={!!defaultPaymentMethod}
              content={'Add a payment method before purchasing'}
              interactionKind="hover"
              position={B.Position.BOTTOM}
            >
              <B.Button
                className={cs.checkoutButton}
                text={checkoutSucceeded ? 'Success' : 'Confirm and pay now'}
                intent={checkoutSucceeded ? 'success' : 'primary'}
                large
                loading={isSubmittingCheckout}
                disabled={checkoutSucceeded || !defaultPaymentMethod}
                onClick={async () => {
                  const {sourceId} = parseLayerKey(layer.layerKey);
                  setIsSubmittingCheckout(true);
                  setCheckoutError('');
                  try {
                    const clientSecret = await purchasePaidLayer(
                      sourceId,
                      selectedBillingInterval,
                      invoice.get('subscription_proration_date'),
                      subscriptionItem.getIn(['period', 'end']),
                      invoice.get('discount')
                        ? invoice.getIn(['discount', 'coupon', 'id'])
                        : undefined
                    );

                    const result = await stripe.confirmPayment({
                      clientSecret,
                      confirmParams: {
                        return_url: window.location.href,
                      },
                      redirect: 'if_required',
                    });
                    if (result.error) {
                      setCheckoutError(result.error.message!);
                    } else {
                      setCheckoutSucceeded(true);
                    }
                  } catch (e) {
                    const err = e as {body: {error: string}; error: Error; status: number};
                    setCheckoutError(getCheckoutErrorText(err.body.error, err.status, layer));
                  } finally {
                    setIsSubmittingCheckout(false);
                  }
                }}
              />
            </B.Tooltip>
            {!isSubmittingCheckout && checkoutError && (
              <div className={cs.checkoutError}>{checkoutError}</div>
            )}
            {checkoutSucceeded && (
              <div className={cs.checkoutMessage}>
                {layer.name} {layer.source} has been added to your portfolios
              </div>
            )}
          </div>
        ) : (
          <B.Callout className={cs.roleCallout} icon={null} intent={B.Intent.WARNING}>
            <p>
              Only organization admins may purchase layers. Click the Copy Link button to get a
              sharable URL for this layer.
            </p>
            <CopyToClipboard text={window.location.href}>
              <B.Button intent={B.Intent.PRIMARY} large text="Copy Link" type="submit" />
            </CopyToClipboard>
          </B.Callout>
        )}
      </div>
    );
  } else if (maybeInvoice.error) {
    return (
      <div className={cs.checkoutInvoiceContainer}>
        <p>{getCheckoutErrorText(maybeInvoice.error.message, maybeInvoice.error.status, layer)}</p>
      </div>
    );
  } else {
    return (
      <div className={cs.checkoutInvoiceContainer}>
        {billingIntervalSwitch}
        <h3>Order summary</h3>
        <LibraryCheckoutPromotionCodeInput
          defaultPromotionCode={defaultPromotionCode}
          validPromotionCode={false}
          isLoading={true}
          setCheckoutPromotionCode={setCheckoutPromotionCode}
        />
        <div className={cs.checkoutLineItemsContainer}>
          <div className={classnames('bp5-skeleton')}>
            <span>Placeholder text</span>
            <br />
            <span>Placeholder text</span>
          </div>

          {defaultPromotionCode && (
            <div className={classnames('bp5-skeleton')}>
              <span>Placeholder text</span>
            </div>
          )}

          <div className={classnames('bp5-skeleton')}>
            <span>Placeholder text</span>
          </div>
        </div>
        <div className={cs.divider} />
        <div className={cs.checkoutLineItemsContainer}>
          <div className={classnames('bp5-skeleton')}>
            <span>Placeholder text</span>
          </div>
        </div>
      </div>
    );
  }
};

const LibraryCheckoutPromotionCodeInput: React.FunctionComponent<
  React.PropsWithChildren<{
    defaultPromotionCode: string;
    validPromotionCode: boolean;
    isLoading: boolean;
    setCheckoutPromotionCode: (promotionCode: string | undefined) => void;
  }>
> = ({defaultPromotionCode, validPromotionCode, isLoading, setCheckoutPromotionCode}) => {
  const [promotionCode, setPromotionCode] = React.useState(defaultPromotionCode);

  return (
    <div>
      <B.InputGroup
        type="text"
        placeholder="Add promotion code"
        value={promotionCode}
        onChange={(event) => setPromotionCode(event.target.value.trim())}
        disabled={isLoading || validPromotionCode}
        rightElement={
          <B.Button
            text={!validPromotionCode ? 'Add' : undefined}
            icon={validPromotionCode ? 'cross' : undefined}
            disabled={!promotionCode || isLoading}
            onClick={() => {
              if (validPromotionCode) {
                setPromotionCode('');
                setCheckoutPromotionCode(undefined);
              } else {
                setCheckoutPromotionCode(promotionCode);
              }
            }}
          />
        }
      />
      {defaultPromotionCode &&
        defaultPromotionCode === promotionCode &&
        !isLoading &&
        !validPromotionCode && <div className={cs.checkoutError}>This code is invalid</div>}
    </div>
  );
};

const PaymentMethodEdit: React.FunctionComponent<
  React.PropsWithChildren<{
    defaultPaymentMethod: ApiPaidLayerInvoice['default_payment_method'];
    organizationId: string;
    setCheckoutError: (text: string) => void;
    layer: LibraryDataset<LayerDataset>;
    role: ApiOrganizationUser['role'];
  }>
> = ({defaultPaymentMethod, organizationId, setCheckoutError, layer, role}) => {
  const [isLoadingBillingSessionUrl, setIsLoadingBillingSessionUrl] = React.useState(false);
  const hasPermissions = role === C.USER_ROLE_OWNER;

  const editButton = (
    <B.Tooltip disabled={hasPermissions} content={'Only admins can edit payment method'}>
      <B.Button
        minimal
        small
        icon="edit"
        loading={isLoadingBillingSessionUrl}
        disabled={!hasPermissions}
        onClick={async () => {
          setIsLoadingBillingSessionUrl(true);
          try {
            const response = await api.organizations.getBillingSession(
              organizationId,
              window.location.href
            );
            window.location.href = response.getIn(['data', 'url']);
          } catch (e) {
            const err = e as {body: {error: string} | undefined; error: Error; status: number};
            setCheckoutError(getCheckoutErrorText(err.body?.error || '', err.status, layer));
            setIsLoadingBillingSessionUrl(false);
          }
        }}
      />
    </B.Tooltip>
  );

  return (
    <div className={cs.checkoutEditPayment}>
      {defaultPaymentMethod ? (
        'card' in defaultPaymentMethod ? (
          <span>
            {defaultPaymentMethod.card.brand} ****
            {defaultPaymentMethod.card.last4}
          </span>
        ) : (
          <span>**** {defaultPaymentMethod.us_bank_account.last4}</span>
        )
      ) : (
        'Add payment method'
      )}
      {editButton}
    </div>
  );
};
