import * as B from '@blueprintjs/core';

import * as C from 'app/utils/constants';
import {LayerTag} from 'app/utils/layers';

export enum LibraryDatasetType {
  LAYER = 'layer',
  OVERLAY = 'overlay',
}

export type OverlayDataset = {
  type: LibraryDatasetType.OVERLAY;
  featureCollectionId: number;
  allowedTiers: C.LENS_TIERS_TYPE[];
  projectIds: string[];
  restriction?: string;
  uploadedBy: string;
};

export type LayerDataset = {
  type: LibraryDatasetType.LAYER;
  layerKey: string;
  restricted: boolean;
  supportedFeatureCollectionIds: number[];
  enrolledFeatureCollectionIds: number[];
  processingFeatureCollectionIds: number[];
  resolution: number;
};

export type LibraryDataset<T extends OverlayDataset | LayerDataset> = T & {
  libraryKey: string;
  name: string;
  source: string;
  thumbnailUrl: string;
  highResPreview?: string;
  premium?: boolean;
  description: string | React.ReactNode;
  tags: LayerTag[];
  license?: {
    name: string;
    url: string | null;
  };
  libraryAction?: {text: string; link: string; intent?: B.Intent};
  geographicExtent?: string;
  billingIntervals?: string[];
};
