import * as B from '@blueprintjs/core';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {OverlayMaskFilter, PolygonDispatch} from 'app/providers/MapPolygonStateProvider';
import {DataLayerInfo} from 'app/utils/layers';
import * as mapUtils from 'app/utils/mapUtils';

import cs from './LegendWithSlider.styl';
import {DataRange} from '../AnalyzePolygonChart/types';

const LegendThresholdMaskSlider: React.FunctionComponent<{
  firebaseToken: string | null;
  layer: DataLayerInfo;
  imageRefs: mapUtils.MapImageRefs;
  featureData: I.ImmutableListOf<ApiFeatureData>;
  polygonDispatch: PolygonDispatch;
  polygon: geojson.Polygon | geojson.MultiPolygon | null;
  updateOverlayMask: (overlayMaskFilter: OverlayMaskFilter) => void;
  width: number;
  dataRange: DataRange;
  overlayThreshold: [number, number];
  setOverlayThreshold: React.Dispatch<React.SetStateAction<[number, number] | null>>;
}> = ({updateOverlayMask, width, dataRange, overlayThreshold, setOverlayThreshold}) => {
  React.useEffect(() => {
    updateOverlayMask({type: 'threshold', threshold: overlayThreshold});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{width: width}} className={cs.sliderAndInputs}>
        <div className={cs.slider}>
          <B.RangeSlider
            value={overlayThreshold}
            min={dataRange[0]}
            max={dataRange[1]}
            stepSize={0.01}
            onChange={(val) => {
              setOverlayThreshold(val);
              updateOverlayMask({type: 'threshold', threshold: val});
            }}
            labelRenderer={false}
            onRelease={(val) => setOverlayThreshold(val)}
          />
        </div>
        <div className={cs.inputs}>
          <B.NumericInput
            style={{width: width / 3}} //give each input a third of the total width
            clampValueOnBlur
            type="number"
            min={dataRange[0]}
            max={dataRange[1]}
            value={overlayThreshold[0].toFixed(2)}
            onValueChange={(newValue) => {
              setOverlayThreshold((prevOverlayThreshold) => [
                newValue,
                prevOverlayThreshold ? prevOverlayThreshold[1] : dataRange[1],
              ]);
              updateOverlayMask({type: 'threshold', threshold: [newValue, overlayThreshold[1]]});
            }}
            stepSize={0.1}
            minorStepSize={0.01}
            buttonPosition="none"
            placeholder={dataRange[0].toString()}
          ></B.NumericInput>
          <B.NumericInput
            style={{width: width / 3}} //give each input a third of the total width
            clampValueOnBlur
            type="number"
            min={dataRange[0]}
            max={dataRange[1]}
            value={overlayThreshold[1].toFixed(2)}
            onValueChange={(newValue) => {
              setOverlayThreshold((prevOverlayThreshold) => [
                prevOverlayThreshold ? prevOverlayThreshold[0] : dataRange[0],
                newValue,
              ]);

              updateOverlayMask({type: 'threshold', threshold: [overlayThreshold[0], newValue]});
            }}
            stepSize={0.1}
            minorStepSize={0.01}
            buttonPosition="none"
            placeholder={dataRange[1].toString()}
          ></B.NumericInput>
        </div>
      </div>
    </>
  );
};

export default LegendThresholdMaskSlider;
