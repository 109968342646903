import * as B from '@blueprintjs/core';
import React from 'react';

import {usePushNotification} from 'app/components/Notification';
import {useFirebaseDatabase} from 'app/tools/Firebase';

/**
 * Needs to be rendered underneath AuthProvider so that the firebase app is set
 * up.
 */
const VersionUpdateMonitor: React.FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const database = useFirebaseDatabase();
  const versionRef = React.useRef<number | undefined>(undefined);
  const pushNotification = usePushNotification();

  const pushNotificationRef = React.useRef(pushNotification);

  React.useEffect(() => {
    pushNotificationRef.current = pushNotification;
  }, [pushNotification]);

  React.useEffect(() => {
    const realtimeVersionRef = database.ref('realtime/versions/lens');

    // TODO(fiona): The old code had this debounced by 3000ms. Is that still
    // important?
    const handleVersionValue = (snapshot: firebase.database.DataSnapshot) => {
      const version: number = snapshot.val();
      if (versionRef.current === undefined) {
        versionRef.current = version;
      } else if (version > versionRef.current) {
        console.info(
          `New version is available. Previous version ${versionRef.current}. New version ${version}.`
        );

        pushNotificationRef.current({
          message:
            'A new version of the application is now available! Please refresh to get the latest version.',
          autoHideDuration: 0,
          options: {intent: B.Intent.WARNING, icon: 'automatic-updates'},
        });
      }
    };

    realtimeVersionRef.on('value', handleVersionValue);

    return () => {
      realtimeVersionRef.off('value', handleVersionValue);
    };
  }, [database]);

  return null;
};

export default VersionUpdateMonitor;
