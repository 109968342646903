import firebase from 'firebase/app';
import I from 'immutable';
import 'firebase/auth';
import mapboxgl from 'mapbox-gl';
import queryString from 'query-string';

import {isDev, isProd, isTest, useLocalApi} from 'app/utils/envUtils';

import {isPublicLensUrl} from './routeUtils';

export type Unpromise<F> = F extends (...args: any[]) => Promise<infer P> ? P : never;

// Params may just be string -> string, but sometimes there are things like
// ImmutableJS Sets objects.
export type ApiParams = Record<
  string,
  | string[]
  | string
  | number
  | number[]
  | boolean
  | null
  | undefined
  | I.Iterable.Indexed<string | number>
  | I.Iterable.Set<string | number>
>;

export type Paging = I.MapAsRecord<{
  pageIndex?: number;
  isFinishedPaging?: boolean;
}>;

/**
 * This is the common structure of API responses from our backend.
 */
export type ApiResponse<T> = I.MapAsRecord<{
  data: I.ImmutableOf<T>;
  paging?: Paging;
  isRedirected?: boolean;
  error?: string;
  _loaded?: boolean;
}>;

/* Utility type for thrown errors. */
/* makeRequest throws a few different types of things, which this type is meant to superficially describe */
export interface ApiResponseError {
  error: Error;
  cause?: Error;
  body?: any;
  status?: Response['status'];
  message?: string;
  shouldRetry?: boolean;
}

/**
 * Used to mark when we don’t care about the returned data. If you actually need
 * the data from a method that returns this, update that method to a more
 * accurate response type.
 */
export interface NeverResponse {}

/**
 * contextOrganizationId is to keep track of the organization a user is viewing in Lens.
 * We add this as a header to all API calls to support parent organizations. User's who belong to a parent
 * organization are able to experience Lens as a member of any of their child orgs, but this means the
 * user profile and token are no longer sufficent for knowing what org a request is for, so we need to
 * specify the org. This also avoids the API having to infer the the organization from information like
 * the featureCollection in non parent org cases.
 *
 * setContextOrganizationId is called whenever the organizationId changes to keep the contextOrganizationId global up to date
 */
export let contextOrganizationId: string | null = null;
export const setContextOrganizationId = (id: string | null) => {
  contextOrganizationId = id;
};

function serialize(obj: null | Record<string, string[] | string | number | boolean>): string {
  if (!obj || !Object.keys(obj).length) {
    return '';
  }

  let str: string[] = [];
  for (const p in obj) {
    const val = obj[p];

    if (Array.isArray(val)) {
      str = str.concat(val.map((v) => `${encodeURIComponent(p)}=${encodeURIComponent(v)}`));
    } else if (Object.getOwnPropertyDescriptor(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(val));
    }
  }
  return '?' + str.join('&');
}

const USE_LOCAL_API = useLocalApi && !isProd;

let API_ROOT = 'https://api.upstream.tech';
let HIGH_VOLUME_API_ROOT = 'https://high-volume-api.upstream.tech';
let SLOW_API_ROOT = 'https://slow-api.upstream.tech';
if (USE_LOCAL_API) {
  API_ROOT = 'http://localhost:8080';
  HIGH_VOLUME_API_ROOT = 'http://localhost:8080';
  SLOW_API_ROOT = 'http://localhost:8080';
}

export function getApiRoot(highVolumeApi = false, slowApi = false): string {
  if (slowApi) {
    return SLOW_API_ROOT;
  } else if (highVolumeApi) {
    return HIGH_VOLUME_API_ROOT;
  }
  return API_ROOT;
}

interface ApiRouteOptions {
  highVolumeApi?: boolean;
  slowApi?: boolean;
}

export function getApiRoute(
  route: string,
  params: queryString.ParsedQuery<string> | null = {},
  options: ApiRouteOptions | null = {}
): string {
  // Serializes any ImmutableJS into plain arrays and such and discards missing
  // values.
  const jsParams = params
    ? I.fromJS(params)
        .filter((v) => v != null)
        .toJS()
    : params;

  const apiRoot = getApiRoot(options?.highVolumeApi, options?.slowApi);
  if (route.startsWith(apiRoot)) {
    return `${route}${serialize(jsParams)}`;
  } else {
    return `${apiRoot}/${route}${serialize(jsParams)}`;
  }
}

const DEFEAULT_REQUEST_OPTIONS: RequestInit = {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
};

const RETRY_STATUS_CODES = [
  401, // Unauthorized
  403, // Forbidden
  408, // Request timeout
  412, // Precondition failed
  413, // Request entity too large
  417, // Expectation failed
  429, // Too many requests
  444, // No response
  449, // Retry with
  499, // Client closed request
  500, // Internal server error
  502, // Bad gateway
  503, // Service unavailable
  504, // Gateway timeout
  507, // Insufficient storage
  508, // Loop detected
  509, // Bandwidth limit exceeded
  511, // Network authentication required
  598, // Network read timeout error
  599, // Network connect timeout error
];

async function makeRequest<T>(route: string, opts: RequestInit): Promise<ApiResponse<T>> {
  // Rarely a fetch will fail, which will throw an object containing information unhelpful to the user layer.
  // In this case we want to throw something that components making api requests can use to present good status messages.
  let response;
  try {
    response = await fetch(route, opts);
  } catch (e) {
    // Provide the original fetch error for cases where it might be useful
    throw {
      error: new Error('There was a technical problem while making this request.'),
      cause: e,
      status: 500,
      shouldRetry: true,
    };
  }

  const status = response.status;
  let responseText: string;
  let immutableResponseBody: ApiResponse<T>;

  try {
    responseText = await response.text();
    const data = JSON.parse(responseText);
    immutableResponseBody = I.fromJS(data);
  } catch (error) {
    console.error(`Server responded with non-JSON response. ${status} - (${route})`);
    // Add response details to our thrown type, in the event they're useful to any consumers.
    if (isDev) {
      // In dev mode in containers, at least on Mac OS X, we often run into the
      // Python server’s JSON responses being truncated. This makes that a
      // retryable error so that the whole app doesn’t need to be reloaded.
      throw {error, status, shouldRetry: true};
    } else {
      throw {error, status, response};
    }
  }

  // Redirected requests return a 200 status code, but are distinguished by a
  // truthy `redirected` property. In this event, pass back an `isRedirected`
  // property on the response body so that the selection may be changed.
  if (response.redirected) {
    immutableResponseBody = immutableResponseBody.set('isRedirected', true);
  }

  if (RETRY_STATUS_CODES.indexOf(response.status) > -1) {
    console.error(`SERVER RESPONDED WITH ${response.status}. (${route})`, opts);
    throw {
      error: new Error(immutableResponseBody.get('error')),
      status,
      shouldRetry: true,
    };
  } else if (response.status === 404) {
    throw {error: new Error(immutableResponseBody.get('error')), status};
  } else if (response.status === 200) {
    return immutableResponseBody;
  } else {
    throw {
      error: new Error(immutableResponseBody.get('error') ?? 'Unhandled response.'),
      body: immutableResponseBody.toJS(),
      status,
    };
  }
}

export async function makeApiRequest<T>(
  route: string,
  params: null | ApiParams = null,
  options: RequestInit = {},
  {
    noContextOrg = false,
    highVolumeApi = false,
    slowApi = false,
  }: {noContextOrg?: boolean; highVolumeApi?: boolean; slowApi?: boolean} = {}
): Promise<ApiResponse<T>> {
  const opts = {
    ...DEFEAULT_REQUEST_OPTIONS,
    ...options,
  };

  // TODO(anthony): the following line was comitted 5 years ago, but is causing type errors now? investigate this
  // @ts-ignore
  const requestBase = getApiRoute(route, params, {highVolumeApi, slowApi});

  if (route.startsWith('http') && route.indexOf(API_ROOT) === -1) {
    return makeRequest<T>(route, options);
  } else {
    // TODO(fiona): Would be nice to have this come in via the FirebaseProvider
    // or AuthProvider.
    const auth = firebase.auth();
    const headers = new Headers();

    // POST requests with form data are assigned a Content-Type header based on
    // the type of encoding.
    const isFormPost = options.method === 'POST' && options.body instanceof FormData;
    if (!isFormPost) {
      headers.append('Content-Type', 'application/json');
    }

    if (isPublicLensUrl()) {
      const publicLensConfigId = window.location.pathname.split('/p/')[1];
      const token = `publicLensConfigId:${publicLensConfigId}`;
      headers.append('Authorization', token);
    } else if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      if (token) {
        headers.append('Authorization', token);
      }
    }

    if (contextOrganizationId && !noContextOrg) {
      headers.append('x-context-organization-id', contextOrganizationId);
    }

    return makeRequest<T>(requestBase, {...opts, headers});
  }
}

async function _getImageBlob(url: string, opts: RequestInit): Promise<Blob> {
  let response;
  try {
    response = await fetch(url, opts);
  } catch (e) {
    // Provide the original fetch error for cases where it might be useful
    throw {
      error: new Error('There was a technical problem while making this request.'),
      cause: e,
      shouldRetry: true,
    };
  }

  if (RETRY_STATUS_CODES.indexOf(response.status) > -1) {
    throw {error: new Error(response.statusText), response: response, shouldRetry: true};
  }

  if (!response.ok) {
    throw {error: new Error(response.statusText), response: response};
  }

  return await response.blob();
}

export async function makeImageApiRequest(route: string): Promise<HTMLImageElement | null> {
  const auth = firebase.auth();
  const headers = new Headers();
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    if (token) {
      headers.append('Authorization', token);
    }
  }

  // retry with backoffs if there were any issues fetching the image
  const it = backOffRetryGen(() => _getImageBlob(route, {method: 'GET', headers}), true);
  let item: IteratorResult<Error, Blob> | null = null;
  try {
    item = await it.next();
    while (!item.done) {
      item = await it.next();
    }
  } catch (e) {
    const {response} = e as {error: Error; response: Response};
    // If fetch threw, we won't have a response, so rethrow.
    // If our response is anything but a 404, also rethrow.
    if (!response || response.status !== 404) {
      throw e;
    }
  }

  if (item && item.value instanceof Blob) {
    const imageBlob = item.value;
    const imageObjectURL = URL.createObjectURL(imageBlob);

    const image = await new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();

      image.crossOrigin = 'Anonymous';
      image.onload = () => resolve(image);
      image.onerror = reject;

      image.src = imageObjectURL;
    });

    return image;
  } else {
    return null;
  }
}

const fib = function (n: number) {
  let x = 0;
  let y = 1;
  if (n <= 2) {
    return n - 1;
  }
  for (let i = 0; i < n; i++) {
    const tempY = y;
    y = tempY + x;
    x = tempY;
  }
  return y;
};

/**
 * Async generator for doing exponential backoffs. Returns the result of the
 * function, or yields its error. Calling the iterator’s `next` after a yield
 * continues with a backoff.
 *
 * Throws the fn’s error if it’s not retryable or if the maximum retries have
 * been reached.
 *
 * To cancel the retries, just stop calling `next`.
 */
export async function* backOffRetryGen<T>(
  fn: () => Promise<T>,
  returnResponse = false,
  maxTries = 3,
  delay = isTest ? 0 : 2000
) {
  let tryCount = 0;

  for (;;) {
    try {
      return await fn();
    } catch (e) {
      // Unwrap the "error" object added by makeRequest, if it exists.
      const {error, shouldRetry} = e as {error: Error; shouldRetry: boolean};

      if (shouldRetry && tryCount < maxTries) {
        tryCount += 1;

        yield (error || e) as Error;

        const delayTime = fib(tryCount) * delay;
        await new Promise((resolve) => {
          setTimeout(resolve, delayTime);
        });
      } else {
        if (returnResponse) {
          throw e;
        }
        throw error || e;
      }
    }
  }
}

export interface ListOptions {
  // Pass a number to set the number of concurrent requests. Defaults to 8.
  getAllPages?: boolean | number;
}

export interface FetchOptions {
  noContextOrg?: boolean;
}

export function _createUpdateApiResource<T, K>(resourceName: string) {
  return {
    list(params: ApiParams = {}, options: ListOptions = {}) {
      const request = (p: ApiParams) => makeApiRequest<T[]>(resourceName, p);

      if (options.getAllPages) {
        return getAllPages(
          request,
          params,
          typeof options.getAllPages === 'number' ? options.getAllPages : undefined
        );
      } else {
        return request(params);
      }
    },

    /**
     * Returns an async generator that gradually yields the complete array of T,
     * then returns it.
     */
    listGen(params: ApiParams = {perPage: 200}, options: ListOptions = {getAllPages: 2}) {
      const request = (p: ApiParams) => makeApiRequest<T[]>(resourceName, p);
      return collectPages(request, params, options);
    },

    fetch(id: K, params: ApiParams = {}, options: FetchOptions = {}) {
      return makeApiRequest<T>(
        `${resourceName}/${id}`,
        params,
        {},
        {
          noContextOrg: !!options.noContextOrg,
        }
      );
    },

    update(id: K, changes: I.MergesInto<I.ImmutableOf<T>> | Partial<T>) {
      // TODO(fiona): Can likely remove this
      if (!changes) {
        throw new Error('No changes passed to `update`');
      }

      return makeApiRequest<T>(`${resourceName}/${id}`, null, {
        method: 'PATCH',
        body: JSON.stringify(changes),
      });
    },
  };
}

// Use P to specify a version of the type for POSTing that might be different
// from the default type.
export function _createApiResource<T, K, P = Omit<T, 'id'>>(resourceName: string) {
  return {
    ..._createUpdateApiResource<T, K>(resourceName),

    post(data: P | I.MapAsRecord<P>, params: ApiParams = {}) {
      return makeApiRequest<T>(resourceName, params, {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },

    delete(id: K, params: ApiParams = {}) {
      return makeApiRequest<T>(`${resourceName}/${id}`, params, {
        method: 'DELETE',
      });
    },
  };
}

/*
Response comes back like this:
{
  'data': [item for item in pagination.items],
  'paging': {
      'pageIndex': pagination.page,
  }
}
*/

function _updateAcc<T>(acc: ApiResponse<T[]>, response: ApiResponse<T[]>): ApiResponse<T[]> {
  acc = acc
    .set('data', I.List(acc.get('data').concat(response.get('data'))))
    .set('paging', response.get('paging'));

  // TODO(fiona): It might be nice to do this so that if you get less than your
  // page size we stop iteration there.
  if (response.get('data').isEmpty()) {
    acc = acc.set('_loaded', true);
  }

  return acc;
}

export async function getAllPages<T>(
  request: (params: ApiParams) => Promise<ApiResponse<T[]>>,
  params: ApiParams,
  numConcurrentRequests = 8,
  pageIndex = 1
): Promise<ApiResponse<T[]>> {
  let acc: ApiResponse<T[]> = I.Map([
    ['data', I.List<T>()],
    ['paging', I.Map()],
  ]);
  let startPage = pageIndex || 1;

  for (;;) {
    const promises = Array.from(Array(numConcurrentRequests).keys()).map((i) =>
      request({...params, page: startPage + i})
    );
    const responses = await Promise.all(promises);
    responses.forEach((resp) => {
      acc = _updateAcc(acc, resp);
    });

    if (acc.get('_loaded')) {
      return acc;
    }

    startPage += numConcurrentRequests;
  }
}

/**
 * Yields pages of the particular data. Pages are returned in order. May yield
 * undefined if a page was being retried.
 *
 * Unlike getAllPages, this function has per-page backoff retries built in.
 *
 * Returns void after all pages have been yielded.
 */
export async function* generatePages<T>(
  request: (params: ApiParams) => Promise<ApiResponse<T[]>>,
  params: ApiParams,
  numConcurrentRequests = 8
) {
  let startPage = 1;

  // We don’t know how many pages there will be, so we just iterate until a
  // request returns empty.
  for (;;) {
    const iterators = Array.from(Array(numConcurrentRequests).keys()).map((i) =>
      backOffRetryGen(() => request({...params, page: startPage + i}))
    );

    // For the parallelism to work we need to call next on each iterator first
    // so that it makes its request.
    const startingNextPromises = iterators.map((it) => it.next());

    for (let i = 0; i < iterators.length; ++i) {
      let item = await startingNextPromises[i];

      while (!item.done) {
        // In this case the iterator has yielded an error from its backoff. We
        // yield an undefined to our own caller and leave it up to them if they
        // want to iterate over us again to trigger the retry to happen.
        //
        // This behavior lets us implicitly cancel further backoff retries if
        // the caller has moved on and doesn’t care about our output.
        yield undefined;

        item = await iterators[i].next();
      }

      // A "done" from a retry iterator is a page of data from the server.
      const page = item.value.get('data');

      if (page.size === 0) {
        // If we’re getting a 0-size page from the server then we know we’re
        // done paginating.
        return;
      } else {
        yield page;
      }
    }

    startPage += numConcurrentRequests;
  }
}

export function getMapBounds(
  mapInstance: mapboxgl.Map
): {top: number; right: number; bottom: number; left: number} | undefined {
  if (!mapInstance) {
    return;
  }

  const bounds = mapInstance.getBounds();
  if (!bounds) {
    return;
  }

  const {lng: right, lat: top} = bounds.getNorthEast();
  const {lng: left, lat: bottom} = bounds.getSouthWest();
  return {top, right, bottom, left};
}

/**
 * Helper that accummulates the page results from generatePages.
 *
 * yields the intermediate lists and then returns the complete list.
 *
 * This matches the signature used by useApiGetGen, so you can "return yield*
 * ..." a method that uses this function.
 */
export async function* collectPages<T>(
  request: (p: ApiParams) => Promise<ApiResponse<T[]>>,
  params: ApiParams,
  options: ListOptions
) {
  let acc = I.List<I.ImmutableOf<T>>([]);

  for await (const value of generatePages(
    request,
    params,
    typeof options.getAllPages === 'number' ? options.getAllPages : undefined
  )) {
    // value might be undefined if generatePages yielded due to a retry.
    acc = value ? acc.concat(value).toList() : acc;

    yield acc;
  }

  return acc;
}

export function strToCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

// Convert all keys in an object from snake_case to camelCase
export function camelCaseKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => camelCaseKeys(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result, key) => {
        const newKey = strToCamelCase(key);
        result[newKey] = camelCaseKeys(obj[key]);
        return result;
      },
      {} as Record<string, any>
    );
  }
  return obj;
}

export function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

// Convert all keys in an object from camelCase to snake_case
export function snakeCaseKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => snakeCaseKeys(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result, key) => {
        const newKey = camelToSnakeCase(key);
        result[newKey] = snakeCaseKeys(obj[key]);
        return result;
      },
      {} as Record<string, any>
    );
  }
  return obj;
}
