import * as B from '@blueprintjs/core';
import {DateRangePicker} from '@blueprintjs/datetime';
import classnames from 'classnames';
import * as I from 'immutable';
import moment from 'moment';
import React, {ChangeEvent} from 'react';
import {Link} from 'react-router-dom';
import {
  Column,
  ColumnInstance,
  FilterType,
  useFilters,
  useFlexLayout,
  useSortBy,
  useTable,
} from 'react-table';

import ImageryPreviewImage from 'app/components/OrderImageryModal/ImageryPreviewImage';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {
  ApiImageryContract,
  ApiImageryContractWithProjects,
  ApiImageryPurchaseBillingRecord,
} from 'app/modules/Remote/Project';
import * as conversionUtils from 'app/utils/conversionUtils';
import {calculateCost, formatCost} from 'app/utils/costUtils';
import * as featureUtils from 'app/utils/featureUtils';
import {BBox2d} from 'app/utils/geoJsonUtils';
import * as routeUtils from 'app/utils/routeUtils';

import cs from './ImageryContractTable.styl';

const DATE_FORMAT = 'MM/DD/YYYY';

const IMAGE_WIDTH = 150;
const IMAGE_HEIGHT = 150;

const IMAGE = 'Image';
const PROPERTY = 'Property';
const CAPTURE_DATE = 'Capture Date';
const ACREAGE = 'Acreage';
const SOURCE = 'Source';
const ORDER_DATE = 'Order Date';
const ORDERED_BY = 'Ordered By';
const CONTRACT = 'Contract';
const COST = 'Cost';

type MultiSelectOptions = Record<string, string>;

/**
 * Text filter for table columns.
 */
const TextFilter: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
  }>
> = ({column: {preFilteredRows, filterValue, setFilter}}) => {
  const count = preFilteredRows.length;

  return (
    <B.Menu>
      <B.InputGroup
        // Hard coding  the input ID is technically okay because only one
        // filter popup will be open at once.
        id="filterValue"
        placeholder={`Search ${count} records…`}
        value={filterValue || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value || undefined)}
        autoFocus
        rightElement={
          <B.Button
            minimal
            icon="small-cross"
            disabled={filterValue === undefined}
            onClick={() => setFilter(undefined)}
          />
        }
      />
    </B.Menu>
  );
};

/**
 * Date filter for table columns.
 */
const DateFilter: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
  }>
> = ({column: {filterValue, setFilter}}) => {
  const allMonths = moment.monthsShort();

  // We set the default value fallback here instead of when we destructure the
  // component props so that we have an accurate active state for the filter
  // button in the column header.
  const months = filterValue?.months;
  const before = filterValue?.before || null;
  const after = filterValue?.after || null;

  const beforeStr = before ? moment(before).format(DATE_FORMAT) : '';
  const afterStr = after ? moment(after).format(DATE_FORMAT) : '';

  return (
    <B.Menu>
      <B.MenuItem
        disabled
        multiline
        text={
          <B.ButtonGroup>
            {allMonths.map((m) => (
              <B.Button
                key={m}
                text={m}
                active={months?.includes(m)}
                onClick={() => {
                  const prevMonths = months || [];
                  let nextMonths: string[] = [];

                  if (prevMonths.includes(m)) {
                    const filteredMonths = prevMonths.filter((prevM) => prevM !== m);

                    // If we don't have any months left after filtering, set the
                    // next months property to undefined to clear that part of
                    // the filter.
                    nextMonths = filteredMonths.length ? filteredMonths : undefined;
                  } else {
                    // Add the month to the list of selected months.
                    nextMonths = [...prevMonths, m];
                  }

                  // If we don't have any filter values, set the entire filter
                  // to undefined to clear it out.
                  setFilter(
                    nextMonths || before || after
                      ? {
                          ...filterValue,
                          months: nextMonths,
                        }
                      : undefined
                  );
                }}
              />
            ))}
          </B.ButtonGroup>
        }
      />

      <B.MenuDivider />

      <B.MenuItem
        text={
          before && after
            ? `${afterStr} to ${beforeStr}`
            : before
              ? `Before ${beforeStr}`
              : after
                ? `After ${afterStr}`
                : 'Custom range…'
        }
        popoverProps={{captureDismiss: true}}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            allowSingleDayRange={true}
            value={[after, before]}
            onChange={([nextAfter, nextBefore]) => {
              // If we don't have any filter values, set the entire filter to
              // undefined to clear it out.
              setFilter(
                months || nextAfter || nextBefore
                  ? {
                      ...filterValue,
                      after: nextAfter,
                      before: nextBefore,
                    }
                  : undefined
              );
            }}
          />
        </div>
      </B.MenuItem>

      <B.MenuDivider />

      <B.MenuItem
        text="Clear filter"
        onClick={() => setFilter(undefined)}
        shouldDismissPopover={false}
      />
    </B.Menu>
  );
};

/**
 * A function that is used to filter date column data.
 */
function makeGetDateFilter(header: string): FilterType<ApiImageryPurchaseBillingRecord> {
  return function (rows, _, {after, before, months}) {
    return rows.filter((row) => {
      const value = row.values[header];

      if (!value) {
        return false;
      }

      const valueMoment = moment(value);

      const isValidAfter = !after || valueMoment.isAfter(moment(after));
      const isValidBefore = !before || valueMoment.isBefore(moment(before));
      const isValidMonth = !months || months.includes(valueMoment.format('MMM'));

      return isValidAfter && isValidBefore && isValidMonth;
    });
  };
}

/**
 * Number range filter for table columns.
 */
const NumberRangeFilter: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
  }>
> = ({column: {preFilteredRows, filterValue, setFilter, id}}) => {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;

    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });

    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <B.Menu>
      <div className={cs.tableNumberRangeInput}>
        <B.InputGroup
          value={filterValue?.[0] ?? ''}
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const {value} = e.target;

            setFilter((prevFilter) => {
              const nextMin = parseMaybeNumber(value);
              const prevMax = prevFilter?.[1];

              // If we're not setting a minimum or maximum value, set the entire
              // filter to undefined in order to clear it.
              return nextMin == undefined && prevMax == undefined ? undefined : [nextMin, prevMax];
            });
          }}
          placeholder={min}
        />
        to
        <B.InputGroup
          value={filterValue?.[1] ?? ''}
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const {value} = e.target;

            setFilter((prevFilter) => {
              const prevMin = prevFilter?.[0];
              const nextMax = parseMaybeNumber(value);

              // If we're not setting a minimum or maximum value, set the entire
              // filter to undefined in order to clear it.
              return prevMin == undefined && nextMax == undefined ? undefined : [prevMin, nextMax];
            });
          }}
          placeholder={max}
        />
      </div>

      <B.MenuDivider />

      <B.MenuItem
        text="Clear filter"
        onClick={() => setFilter(undefined)}
        shouldDismissPopover={false}
      />
    </B.Menu>
  );
};

/**
 * A function that returns filter with selectable list of string options for
 * table columns.
 */
function makeMultiSelectFilter(options: MultiSelectOptions) {
  return function ({column: {filterValue, setFilter}}) {
    return (
      <B.Menu>
        {Object.keys(options).map((key) => {
          const isSelected = filterValue?.includes(key);

          return (
            <B.MenuItem
              key={key}
              text={options[key]}
              icon={isSelected ? 'small-tick' : 'blank'}
              onClick={() => {
                const prevKeys = filterValue || [];
                let nextKeys: string[] = [];

                if (prevKeys.includes(key)) {
                  const filteredKeys = prevKeys.filter((k) => k !== key);

                  // If we don't have any options left after filtering, set the
                  // next options to undefined to clear the filter.
                  nextKeys = filteredKeys.length ? filteredKeys : undefined;
                } else {
                  // Add the option to the list of selected options.
                  nextKeys = [...prevKeys, key];
                }

                // If we don't have any filter values, set the entire filter to
                // undefined to clear it out.
                setFilter(nextKeys);
              }}
              shouldDismissPopover={false}
            />
          );
        })}

        <B.MenuDivider />

        <B.MenuItem
          text="Clear filter"
          onClick={() => setFilter(undefined)}
          shouldDismissPopover={false}
        />
      </B.Menu>
    );
  };
}

/**
 * TextFilter target tag.
 */
export const TextFilterTag: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
    openPopoverForTest?: boolean;
  }>
> = ({column, openPopoverForTest}) => (
  <B.Popover
    popoverClassName={cs.tableFilter}
    isOpen={openPopoverForTest}
    content={<TextFilter column={column} />}
  >
    <B.Tag
      minimal
      round
      className={classnames(cs.filterTag, {[cs.activeFilterTag]: column.filterValue})}
    >
      <strong>{column.id}</strong>: {column.filterValue || 'All'}
    </B.Tag>
  </B.Popover>
);

/**
 * DateFilter target tag.
 */
export const DateFilterTag: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
    openPopoverForTest?: boolean;
  }>
> = ({column, openPopoverForTest}) => {
  const months = column.filterValue?.months;
  const before = column.filterValue?.before || null;
  const after = column.filterValue?.after || null;

  const afterStr = after ? moment(after).format('MM/DD/YYYY') : '';
  const beforeStr = before ? moment(before).format('MM/DD/YYYY') : '';

  const datesStr =
    after && before
      ? `${afterStr} to ${beforeStr}`
      : after
        ? `After ${afterStr}`
        : before
          ? `Before ${beforeStr}`
          : '';

  const monthsStr = months && (months.length <= 3 ? months.join(', ') : 'Some months');

  return (
    <B.Popover
      popoverClassName={cs.tableFilter}
      isOpen={openPopoverForTest}
      content={<DateFilter column={column} />}
    >
      <B.Tag
        minimal
        round
        className={classnames(cs.filterTag, {[cs.activeFilterTag]: column.filterValue})}
      >
        <strong>{column.id}</strong>:{' '}
        {after || before || months
          ? datesStr && monthsStr
            ? `${monthsStr}; ${datesStr}`
            : datesStr || monthsStr
          : 'All'}
      </B.Tag>
    </B.Popover>
  );
};

/**
 * NumberRangeFilter target tag.
 */
export const NumberRangeFilterTag: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
    openPopoverForTest?: boolean;
  }>
> = ({column, openPopoverForTest}) => {
  const min = column.filterValue?.[0];
  const max = column.filterValue?.[1];

  const minStr = min?.toString() ?? '';
  const maxStr = max?.toString() ?? '';

  const numberStr =
    minStr && maxStr
      ? `${minStr} to ${maxStr}`
      : minStr
        ? `≥${minStr}`
        : maxStr
          ? `≤${maxStr}`
          : 'All';

  return (
    <B.Popover
      popoverClassName={cs.tableFilter}
      isOpen={openPopoverForTest}
      content={<NumberRangeFilter column={column} />}
    >
      <B.Tag
        minimal
        round
        className={classnames(cs.filterTag, {[cs.activeFilterTag]: column.filterValue})}
      >
        <strong>{column.id}</strong>: {numberStr}
      </B.Tag>
    </B.Popover>
  );
};

/**
 * MultiSelectFilter target tag.
 */
export const MultiSelectFilterTag: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<ApiImageryPurchaseBillingRecord>;
    options: MultiSelectOptions;
    openPopoverForTest?: boolean;
  }>
> = ({column, options, openPopoverForTest}) => (
  <B.Popover
    popoverClassName={cs.tableFilter}
    isOpen={openPopoverForTest}
    content={makeMultiSelectFilter(options)({column})}
  >
    <B.Tag
      minimal
      round
      className={classnames(cs.filterTag, {[cs.activeFilterTag]: column.filterValue})}
    >
      <strong>{column.id}</strong>:{' '}
      {!column.filterValue?.length
        ? 'All'
        : column.filterValue?.length === 1
          ? options[column.filterValue?.[0]]
          : 'Multiple'}
    </B.Tag>
  </B.Popover>
);

/**
 * Sortable and filterable table that summarizes the imagery order history.
 */
const ImageryContractTable: React.FunctionComponent<
  React.PropsWithChildren<{
    imageryContracts: ApiImageryContractWithProjects[];
    currentImageryContract: ApiImageryContract;
    billingRecordsForProject: ApiImageryPurchaseBillingRecord[];
    featuresById: I.Map<number, I.ImmutableOf<ApiFeature>>;
    callout?: React.ReactNode;
  }>
> = ({
  imageryContracts,
  currentImageryContract,
  billingRecordsForProject,
  featuresById,
  callout,
}) => {
  // Formatted sources in the selected contract.
  const sourceOptions: MultiSelectOptions = React.useMemo(() => {
    return billingRecordsForProject
      .map((billingRecord) => {
        const sceneSourceId = billingRecord.sceneSourceId;

        const sceneSourceDetails = sceneSourceId
          ? featureUtils.getSourceDetails(sceneSourceId)
          : undefined;

        return sceneSourceDetails
          ? `${sceneSourceDetails.operator} ${sceneSourceDetails.name} (${sceneSourceDetails.resolution}m)`
          : undefined;
      })
      .reduce((accum, opt) => ({...accum, [opt!]: opt!}), {});
  }, [billingRecordsForProject]);

  // Image orderers in the selected contract.
  const orderedByOptions: MultiSelectOptions = React.useMemo(() => {
    return billingRecordsForProject
      .map((billingRecord) => billingRecord.purchasedByName)
      .reduce((accum, opt) => ({...accum, [opt!]: opt!}), {});
  }, [billingRecordsForProject]);

  // Contract names associated with the selected project, indexed by ID.
  const contractOptions: MultiSelectOptions = React.useMemo(
    () => imageryContracts.reduce((accum, c) => ({...accum, [`${c.id}`]: c.name}), {}),
    [imageryContracts]
  );

  const columns = React.useMemo<Column<ApiImageryPurchaseBillingRecord>[]>(
    () => [
      {
        id: IMAGE,
        Header: IMAGE,
        Cell: ({cell}) => {
          const billingRecord = cell.row.original;
          // There may be many imagery purchases with different thumbnails,
          // we'll just show a preview of the first one for this purpose.
          const ipForThumbnail = billingRecord.imageryPurchases[0];
          const feature = featuresById.get(ipForThumbnail.featureId);
          const thumbnailUrl = billingRecord.imageryPurchases[0].sceneThumbnailUrl;

          return thumbnailUrl ? (
            <B.Popover
              interactionKind="hover"
              position="right"
              content={
                // Use DeferredComponent so that the toJS on sceneBounds is only done if the popover is opened
                <DeferredComponent
                  content={() => (
                    <div className={cs.imagePopover}>
                      {ipForThumbnail.sceneBounds && feature ? (
                        <ImageryPreviewImage
                          url={thumbnailUrl}
                          bounds={ipForThumbnail.sceneBounds as BBox2d}
                          feature={feature}
                          maxWidth={IMAGE_WIDTH}
                          maxHeight={IMAGE_HEIGHT}
                          // HACK(fiona): Since this image was ordered, we want to display
                          // it regardless of whether we’re blocking thumbnails from a
                          // provider. So, fake a sourceId that won’t be blocked.
                          sourceId={''}
                          sceneGeometry={ipForThumbnail.sceneBounds! as BBox2d}
                        />
                      ) : (
                        <img
                          src={thumbnailUrl}
                          style={{maxWidth: IMAGE_WIDTH, maxHeight: IMAGE_HEIGHT}}
                        />
                      )}
                    </div>
                  )}
                />
              }
            >
              <B.Icon icon="media" />
            </B.Popover>
          ) : null;
        },
        disableFilters: true,
        width: 45,
      },
      {
        id: PROPERTY,
        Header: PROPERTY,
        Cell: ({cell}) => {
          const billingRecord = cell.row.original;
          return billingRecord.imageryPurchases.map((ip, index) => (
            <Link
              title={`${ip.featureProperties.name}${ip.featureProperties.multiFeaturePartName ? ` - ${ip.featureProperties.multiFeaturePartName}` : ''}`}
              key={ip.id}
              to={routeUtils.makeHighResSceneUrl({
                organizationId: currentImageryContract.organizationId,
                projectId: billingRecord.projectId,
                featureLensId:
                  featuresById.get(ip.featureId)?.getIn(['properties', 'lensId']) || '',
                sourceId: billingRecord.sceneSourceId,
                date: billingRecord.sceneSensingTime,
                includeRoot: false,
              })}
            >
              {index > 0 ? ', ' : ''}
              {ip.featureProperties.name}
              {ip.featureProperties.multiFeaturePartName
                ? ` - ${ip.featureProperties.multiFeaturePartName}`
                : ''}
            </Link>
          ));
        },
        Filter: TextFilter,
      },
      {
        id: CAPTURE_DATE,
        Header: CAPTURE_DATE,
        accessor: (billingRecord) => billingRecord.sceneSensingTime,
        Cell: ({cell}) => (cell.value ? moment(cell.value).format(DATE_FORMAT) : null),
        Filter: DateFilter,
        filter: makeGetDateFilter(CAPTURE_DATE),
        width: 100,
      },
      {
        id: ACREAGE,
        Header: ACREAGE,
        accessor: (billingRecord) => billingRecord.billedAcres,
        Cell: ({cell}) => (
          <div style={{textAlign: 'right'}}>{conversionUtils.numberWithCommas(cell.value)}</div>
        ),
        Filter: NumberRangeFilter,
        filter: 'between',
        width: 80,
      },
      {
        id: COST,
        Header: COST,
        accessor: (billingRecord) => {
          return calculateCost(billingRecord.billedAcres, billingRecord.priceCents);
        },
        Cell: ({cell}) => {
          return <div style={{textAlign: 'right'}}>{formatCost(cell.value)}</div>;
        },
        Filter: NumberRangeFilter,
        filter: 'between',
        width: 80,
      },
      {
        id: SOURCE,
        Header: SOURCE,
        accessor: (billingRecord) => {
          const sceneSourceId = billingRecord.sceneSourceId;
          const sceneSourceDetails = sceneSourceId && featureUtils.getSourceDetails(sceneSourceId);

          return (
            sceneSourceDetails &&
            `${sceneSourceDetails.operator} ${sceneSourceDetails.name} (${sceneSourceDetails.resolution}m)`
          );
        },
        Filter: makeMultiSelectFilter(sourceOptions),
        filter: 'includes',
        width: 140,
      },
      {
        id: ORDER_DATE,
        Header: ORDER_DATE,
        accessor: (billingRecord) => billingRecord.purchasedAt,
        Cell: ({cell}) => (cell.value ? moment(cell.value).format(DATE_FORMAT) : null),
        Filter: DateFilter,
        filter: makeGetDateFilter(ORDER_DATE),
        width: 100,
      },
      {
        id: ORDERED_BY,
        Header: ORDERED_BY,
        accessor: (billingRecord) => billingRecord.purchasedByName,
        Filter: makeMultiSelectFilter(orderedByOptions),
        filter: 'includes',
        width: 100,
      },
      {
        id: CONTRACT,
        Header: CONTRACT,
        accessor: (billingRecord) => `${billingRecord.imageryContractId}`,
        Cell: ({cell}) => contractOptions[cell.value],
        Filter: makeMultiSelectFilter(contractOptions),
        filter: 'includes',
        width: 100,
      },
    ],
    [
      sourceOptions,
      orderedByOptions,
      contractOptions,
      featuresById,
      currentImageryContract.organizationId,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {sortBy},
    toggleSortBy,
  } = useTable(
    {
      columns,
      data: billingRecordsForProject,
      initialState: {
        sortBy: [{id: ORDER_DATE, desc: true}],
        filters: [{id: CONTRACT, value: [`${currentImageryContract.id}`]}],
      },
    },
    useFlexLayout,
    useFilters,
    useSortBy
  );

  // We know that this will always be set because we pass in an initial state
  // and don't provide a UI for clearing the sort.
  const {id: sortId, desc: sortDesc} = sortBy[0];

  return (
    <div className={cs.container}>
      <div className={cs.tableMeta}>
        <div className={cs.tableMetaHeader}>
          <div className={cs.sortByInput}>
            <div>Sorted by</div>

            <B.HTMLSelect
              value={sortId}
              onChange={(e) => toggleSortBy(e.target.value, !!sortDesc, false)}
            >
              {columns.map(({id}, i) => (
                <option key={i}>{id}</option>
              ))}
            </B.HTMLSelect>

            <B.Button
              small
              minimal
              icon={sortDesc ? 'sort-desc' : 'sort-asc'}
              onClick={() => toggleSortBy(sortId, !sortDesc, false)}
              title="Toggle sort direction"
            />
          </div>
        </div>

        {callout}
      </div>

      <div className={cs.tableWrapper}>
        <table {...getTableProps()} className={cs.table}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} key={i}>
                    <div>{column.render('Header')}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td {...cell.getCellProps()} key={i}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ImageryContractTable;

/**
 * A function to parse a provided value as a number. If the provided value is
 * an empty string or undefined, returns undefined.
 */
function parseMaybeNumber(value: string | undefined) {
  return value ? parseInt(value, 10) : undefined;
}

/**
 * Defers its children rendering to when it’s actually rendered.
 *
 * Used when a component’s props are expensive (like toJS of a geometry) but it
 * may not actually be rendered (like as part of a Popover).
 */
const DeferredComponent: React.FunctionComponent<
  React.PropsWithChildren<{
    content: () => React.ReactElement;
  }>
> = ({content}) => content();
