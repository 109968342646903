import SphericalMercator from '@mapbox/sphericalmercator';
import tileCover from '@mapbox/tile-cover';
import tilebelt from '@mapbox/tilebelt';
import * as Sentry from '@sentry/react';
import {bbox} from '@turf/turf';
import * as geojson from 'geojson';
import * as I from 'immutable';
import moment from 'moment';

import {DataRange, GraphRange} from 'app/components/AnalyzePolygonChart/types';
import {ApiFeatureData, RasterSourceDefinition} from 'app/modules/Remote/Feature';
import {OverlayMaskFilter} from 'app/providers/MapPolygonStateProvider';
import colors from 'app/styles/colors.json';
import {makeImageApiRequest} from 'app/utils/apiUtils';
import * as domUtils from 'app/utils/domUtils';
import {isFeatureDatumProcessed} from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as mapUtils from 'app/utils/mapUtils';

// sphericalmercator uses the non-standard "Google" ID for EPSG:3857
const WEB_MERCATOR_PROJECTION_ID = '900913';
const UNCERTAINTY_MIN_CHANNEL = 1;
const UNCERTAINTY_MAX_CHANNEL = 2;
const ALPHA_CHANNEL = 3;
const DEFAULT_SCALE = 1;

// Minimum zoom level for which we create webtiles.
const TILE_MIN_ZOOM = 10;

// Maximum zoom level for which we create webtiles.
const TILE_MAX_ZOOM = 14;

// Number of pixels on each side of a tile.
export const TILE_SIDE_PX = 256;

// The maximum number of tiles in the x or y direction that we’d like to write
// into the canvas buffer when assembling webtile images. This is a suggested
// value because it’s possible that we may need to exceed this with
// exceptionally tall and/or wide properties, even at the minimum zoom.
const MAX_DIRECTIONAL_TILE_COUNT = 4;

export type Tile = [number, number, number];

export interface TileSpan {
  x: {
    min: number;
    max: number;
    count: number;
  };
  y: {
    min: number;
    max: number;
    count: number;
  };
}

export interface TileData {
  zoom: number;
  tiles: Tile[];
  span: TileSpan;
  bounds: geojson.BBox;
}

export interface TileDataOptions {
  minZoom?: number;
  maxZoom?: number;
  maxDirectionalTileCount?: number;
}

/**
 * A type that matches things we can draw on a canvas.
 */
export type RasterImageType = CanvasImageSource & {width: number; height: number};

export interface RasterCalculationResult {
  sum: number;
  mean: number;
  lowerUncertaintyMean: number;
  upperUncertaintyMean: number;

  percentWithinThreshold: number;

  // These are pixel counts (unweighted)
  totalPixelCount: number;
  missingPixelCount: number;
  wholePixelCount: number;
  partialPixelCount: number;

  // These are pixel counts weighted by the pixel's alpha channel
  weightedPixelsWithValue: number;
  weightedPixelsInThreshold: number;
  weightedPixelsWithValueByValue: Record<number, number>;

  inspector: {
    image: RasterImageType;
    imageBounds: geojson.BBox;
    imageOrigin: {x: number; y: number};
    imageDataUrl: string;
    clippedImageDataUrl: string;
    presenceImageDataUrl: string;
    thresholdImageDataUrl: string;
  };
}

export interface DateRange {
  label: string;
  value?: number;
  unit?: moment.unitOfTime.DurationConstructor;
}

export const ONE_YEAR_DATE_RANGE_LABEL = '1 Year';
export const TWO_YEAR_DATE_RANGE_LABEL = '2 Years';
export const ALL_TIME_DATE_RANGE_LABEL = 'All time';

export const DATE_RANGES: DateRange[] = [
  {label: ONE_YEAR_DATE_RANGE_LABEL, value: 1, unit: 'year'},
  {label: TWO_YEAR_DATE_RANGE_LABEL, value: 2, unit: 'year'},
  {label: ALL_TIME_DATE_RANGE_LABEL},
];

/**
 * Similar to Promise.all but takes an array of no-arg functions and only runs
 * maxParallel of them at once.
 *
 * Used to limit the number of outgoing requests we make for AA because Chrome
 * will time out requests that wait more than 3m in the queue.
 *
 * This is set up so that as a Promise completes, it pulls the next one right
 * away (rather than say, waiting for all maxParallel promises to complete
 * before awaiting the next chunk).
 */
export function limitedParallelAll<T>(
  funcs: (() => Promise<T>)[],
  maxParallel: number
): Promise<T[]> {
  // Copy it for safety since if it gets modifyied during our iteration we could
  // crash or be weird.
  funcs = [...funcs];

  return new Promise<T[]>((resolve, reject) => {
    // Used to short circuit future work if we know we’re failing.
    let rejected = false;

    let nextIdx = 0;
    let numCompleted = 0;

    const outArr: T[] = new Array(funcs.length);

    const doWork = () => {
      if (rejected || nextIdx >= funcs.length) {
        return;
      }

      const currentIdx = nextIdx;
      nextIdx++;

      funcs[currentIdx]().then(
        (val) => {
          outArr[currentIdx] = val;
          numCompleted++;

          // We’ve done all the work, so send it out.
          if (numCompleted === funcs.length) {
            resolve(outArr);
          } else {
            doWork();
          }
        },
        (err) => {
          rejected = true;
          reject(err);
        }
      );
    };

    // By priming with maxParallel, we know we’ll have exactly that many work
    // streams going.
    for (let i = 0; i < maxParallel; ++i) {
      doWork();
    }
  });
}

/**
 * Metadata for points that come from FeatureData.
 *
 * @see RasterTimeSeriesCalculator#fromTiledFeatureData
 * @see RasterTimeSeriesCalculator#fromFeatureData
 */
export interface FeatureDataMeta {
  cursor: string;
}

const makeDateRange = (
  mostRecentImageDate: Date,
  graphRange: GraphRange,
  absoluteStartDate?: Date | undefined
) => {
  let dateRange: [Date, Date];
  if (graphRange.type === 'custom') {
    const start = graphRange.range[0];
    const end = graphRange.range[1];
    // If start or end is undefined we default to earliest possible date or today.
    dateRange = [start ?? new Date(-386380800000), end ?? new Date()];
  } else if (graphRange.type === 'simple' && graphRange.label !== ALL_TIME_DATE_RANGE_LABEL) {
    const simpleDateRange = DATE_RANGES.find(({label}) => label === graphRange.label);
    if (!simpleDateRange) {
      throw new Error('Unrecognized date range label: ' + graphRange.label);
    }
    if (absoluteStartDate) {
      dateRange = [new Date(absoluteStartDate), new Date(mostRecentImageDate)];
    } else {
      dateRange = [
        moment(mostRecentImageDate).subtract(simpleDateRange.value, simpleDateRange.unit).toDate(),
        new Date(),
      ];
    }
  } else {
    // All time - post sputnik :)
    dateRange = [new Date(-386380800000), new Date()];
  }
  return dateRange;
};

const filterImagesByDateRange = (
  images: [Date, any, FeatureDataMeta][],
  dateRange: [Date, Date]
) => {
  return images.filter(([date]) => {
    const imageDate = date.getTime();
    const startDate = dateRange[0].getTime();
    const endDate = dateRange[1].getTime();
    return startDate <= imageDate && imageDate <= endDate;
  });
};

export function pixelValueToDataValue(pixelVal: number, dataRange: DataRange) {
  // Value between 0 and 1.
  const normalizedPixelValue = (255 - pixelVal) / 255;

  const dataMin = dataRange[0];
  const dataMax = dataRange[1];

  return normalizedPixelValue * (dataMax - dataMin) + dataMin;
}

/**
 * Can run our raster image calculations over a series of RasterCalculator
 * objects.
 *
 * Use the #fromFeatureData factory method to create one from image-based
 * ApiFeatureData, or #fromTiledFeatureData factory method to create one from
 * webtile-based ApiFeatureData.
 *
 * This object and RasterCalculator use async factory methods to offload loading
 * and error states to the consumers.
 *
 * TODO(fiona): Would be nice to remove the I.Immutable bits, but given that
 * ApiFeatureData is universally wrapped in Immutable, it doesn’t make sense to
 * require us to unwrap it for just this class.
 */
export class RasterTimeSeriesCalculator<T> {
  public readonly rawLayerKey: string;
  private readonly calculators: [Date, RasterCalculator, T][];

  constructor(rawLayerKey, calculators: [Date, RasterCalculator, T][]) {
    this.rawLayerKey = rawLayerKey;
    this.calculators = calculators;
  }

  public static async fromFeatureData(
    featureData: I.ImmutableOf<ApiFeatureData[]>,
    rawLayerKey: string | null | undefined,
    dataRange: DataRange,
    graphRange: GraphRange,
    tileBounds: geojson.BBox,
    setLoadingProgress: React.Dispatch<React.SetStateAction<number>>,
    absoluteStartDate: Date | undefined
  ): Promise<RasterTimeSeriesCalculator<FeatureDataMeta>> {
    if (!rawLayerKey) {
      return new RasterTimeSeriesCalculator(rawLayerKey, []);
    }

    const filteredFeatureData = filterFeatureDataByType(featureData, rawLayerKey);

    let images: [Date, {url: string; bounds: geojson.BBox}, FeatureDataMeta][] = filteredFeatureData
      .map((d) => {
        const url = d!.getIn(['images', 'urls', rawLayerKey]);

        if (!url) {
          return null;
        }

        return [new Date(d!.get('date')), {url, bounds: tileBounds}, {cursor: d!.get('date')}];
      })
      .filter((info) => !!info)
      .toJS();

    if (images.length === 0) {
      // bail out now before we need to find the mostRecentDate.
      return new RasterTimeSeriesCalculator(rawLayerKey, []);
    }

    images.sort(([dateA], [dateB]) => +dateA - +dateB);

    const mostRecentDate = images[images.length - 1][0];
    const dateRange = makeDateRange(mostRecentDate, graphRange, absoluteStartDate);
    images = filterImagesByDateRange(images, dateRange);

    if (images.length === 0) {
      // if after filtering to the desired dates we have no images,
      // show no data state
      return new RasterTimeSeriesCalculator(rawLayerKey, []);
    }

    const numTotalImages = images.length;
    let currImageCount = 0;
    let erroredImageCount = 0;

    const calculators = await limitedParallelAll(
      images.map(
        ([date, {url, bounds}, meta]) =>
          async (): Promise<[Date, RasterCalculator | null, FeatureDataMeta]> => [
            date,
            await RasterCalculator.fromImage(url, bounds, dataRange)
              .then((calculator) => {
                currImageCount += 1;
                setLoadingProgress(currImageCount / numTotalImages);
                return calculator;
              })
              .catch(() => {
                //if we can't find a thumbnail, for now gracefully drop that data point so that the user
                //can still see the rest of the data. (if too many thumbnails are missing, we'll throw
                //an error at the end in the outer loop.)
                erroredImageCount += 1;
                console.warn(`Missing thumbnail for ${date}`);
                return null;
              }),
            meta,
          ]
      ),
      24
    ).finally(() => {
      // if more than 2, or more than 25%, of thumbnails couldn't be found, send back an error to
      // the user because that's probably too many missing thumbnails to try to run AA.
      if (erroredImageCount > 2 || erroredImageCount / numTotalImages > 0.25) {
        throw Error(`${erroredImageCount} images could not be found for ${rawLayerKey}`);
      } else if (erroredImageCount > 0) {
        // if ANY images error (but not enough to block the user), still capture a Sentry exception
        Sentry.captureException(
          `${erroredImageCount} images could not be found for ${rawLayerKey}`
        );
      }
    });

    // Filter out calculators which are null because of 404s, which we ignore
    const filteredCalculators: [Date, RasterCalculator, FeatureDataMeta][] = calculators.filter(
      ([, calculator]: [Date, RasterCalculator | null, FeatureDataMeta]) => !!calculator
    ) as unknown as [Date, RasterCalculator, FeatureDataMeta][];

    return new RasterTimeSeriesCalculator(rawLayerKey, filteredCalculators);
  }

  public static async fromTiledFeatureData(
    firebaseToken: string | null,
    tileData: TileData,
    data: I.ImmutableOf<ApiFeatureData[]>,
    rawLayerKey: string | undefined | null,
    dataRange: DataRange,
    graphRange: GraphRange,
    setLoadingProgress: React.Dispatch<React.SetStateAction<number>>,
    absoluteStartDate: Date | undefined,
    onLoadError?: (s: string) => void,
    abortSignal?: AbortSignal
  ): Promise<RasterTimeSeriesCalculator<FeatureDataMeta>> {
    if (!rawLayerKey) {
      return new RasterTimeSeriesCalculator(rawLayerKey, []);
    }
    const filteredFeatureData = filterFeatureDataByType(data, rawLayerKey);

    let images: [Date, {templateUrl: string; bounds: geojson.BBox}, FeatureDataMeta][] =
      filteredFeatureData
        .map((d) => [
          new Date(d!.get('date')),
          {templateUrl: getTemplateUrl(d!, rawLayerKey)},
          {cursor: d!.get('date')},
        ])
        .toJS();

    // Some older scenes might not be processed for webtiles so we need to
    // ignore them.
    images = images.filter(([, {templateUrl}]) => !!templateUrl);

    if (images.length === 0) {
      // bail out now before we need to find the mostRecentDate.
      return new RasterTimeSeriesCalculator(rawLayerKey, []);
    }

    images.sort(([dateA], [dateB]) => +dateA - +dateB);

    // Date ranges are always relative to the most recent sensing date.
    const mostRecentDate = images[images.length - 1][0];
    const dateRange = makeDateRange(mostRecentDate, graphRange, absoluteStartDate);
    images = filterImagesByDateRange(images, dateRange);

    if (images.length === 0) {
      // if after filtering to the desired dates we have no images,
      // show no data state
      return new RasterTimeSeriesCalculator(rawLayerKey, []);
    }

    const numTotalImages = images.length;
    let currImageCount = 0;

    const calculators = await limitedParallelAll(
      images.map(
        ([date, {templateUrl}, meta]) =>
          async (): Promise<[Date, RasterCalculator, FeatureDataMeta]> => [
            date,
            await RasterCalculator.fromTiles(
              tileData,
              templateUrl,
              dataRange,
              firebaseToken,
              onLoadError,
              abortSignal
            ).then((calculator) => {
              currImageCount += 1;
              setLoadingProgress(currImageCount / numTotalImages);
              return calculator;
            }),
            meta,
          ]
      ),
      // It takes 1-3 seconds to retrieve a single S2 tile on demand, fan out widely
      // to account for the higher latency (relative to retrieving precomputed webtiles).
      24
    );

    return new RasterTimeSeriesCalculator(rawLayerKey, calculators);
  }

  public async calculate(
    polygon: geojson.Polygon | geojson.MultiPolygon,
    threshold?: [number, number],
    /** Get updated on calculation progress. Return "false" to cancel the
     * calculation. */
    setProgress?: (progress: number) => boolean | undefined
  ) {
    const out: [Date, RasterCalculationResult, T][] = [];

    let lastBreak = performance.now();

    for (let i = 0; i < this.calculators.length; ++i) {
      const [date, calculator, meta] = this.calculators[i];
      out.push([date, calculator.calculate(polygon, threshold), meta]);

      if (performance.now() - lastBreak > 50) {
        const progressResult = setProgress && setProgress((i + 1) / this.calculators.length);

        // If they return false explicitly from progressResult then we abort the
        // calculation.
        if (progressResult === false) {
          return null;
        }

        // We need to tick over with setTimeout to allow the event loop to run.
        // Just awaiting a Promise isn’t enough.
        await new Promise((resolve) => setTimeout(resolve, 0));

        lastBreak = performance.now();
      }
    }

    return out;
  }
}

/**
 * Tool for calculating average values of polygonal regions of images. Creates a
 * clipping path on an internal <canvas> element and draws the image into it.
 *
 * All images are assumed to be projected into EPSG:3857 (a.k.a. Web Mercator).
 *
 * Currently by default assumes that darker areas are higher values. E.g. a
 * value of 255 (white) maps to the layer’s lower bounds, and 0 (black) maps to
 * the upper bounds.
 */
export class RasterCalculator {
  private image: RasterImageType;

  // These are the lat/lng bounds of the image in geojson BBox format.
  private imageGeoBounds: geojson.BBox;

  /** TODO(emily): Revisit this for layers with an image type. */
  private dataRange: DataRange;

  constructor(image: RasterImageType, imageGeoBounds: geojson.BBox, dataRange: DataRange) {
    this.image = image;
    this.imageGeoBounds = imageGeoBounds;
    this.dataRange = dataRange;
  }

  /**
   * Factory method for RasterCalculator that loads an <img> element by URL.
   */
  public static async fromImage(url: string, tileBounds: geojson.BBox, dataRange: DataRange) {
    // Fetch data within the tileData.value.bounds. We aren't fetching using tiles,
    // but fetching the same extent as tiled data allows for user adjustments to
    // the area to result in the smallest amount of new data needing to be fetched.
    const boundsAsPolygon = geoJsonUtils.bboxPolygon(tileBounds).geometry;

    // TODO: clean this up
    const credentialsUrl = mapUtils.formatTileUrl(url, null, false);
    const urlWithBounds = `${credentialsUrl}&window_geometry=${JSON.stringify(boundsAsPolygon)}`;

    const image = await makeImageApiRequest(urlWithBounds);

    if (!image) {
      return null;
    } else {
      return new RasterCalculator(image, tileBounds, dataRange);
    }
  }

  /**
   * Factory method for RasterCalculator that loads an <img> element by
   * requesting and compositing together webtiles.
   */
  public static async fromTiles(
    {tiles, span, bounds}: TileData,
    templateUrl: string,
    dataRange: DataRange,
    firebaseToken: string | null,
    onLoadError?: (s: string) => void,
    abortSignal?: AbortSignal
  ) {
    const canvas = document.createElement('canvas');

    canvas.width = span.x.count * TILE_SIDE_PX;
    canvas.height = span.y.count * TILE_SIDE_PX;

    const ctx = canvas.getContext('2d')!;

    const tileImageBitmaps = await Promise.all(
      tiles.map((tile) => {
        const promise = new Promise<ImageBitmap | void>(async (resolve) => {
          const headers = new Headers();
          const url = getTileUrl(tile, templateUrl);
          if (firebaseToken && mapUtils.isTilerUrl(url)) {
            headers.append('Authorization', firebaseToken);
          }
          try {
            const response = await fetch(url, {
              headers,
            });
            const imageBitmap = await createImageBitmap(await response.blob());
            resolve(imageBitmap);
          } catch (error) {
            // We don’t want to reject on errors because they could be caused by
            // bad webtile requests (e.g., a user drawing a polygon outside of
            // the image’s bounds). If a callback is provided, call it with a
            // custom error message. Then, resolve with no data which we’ll
            // no-op on when drawing webtile images onto the canvas.
            if (onLoadError) {
              onLoadError(
                'There was an issue loading some of the data. Ensure the area of interest is within the property boundaries.'
              );
              resolve();
            }
          }
        });

        return abortSignal ? domUtils.makeAbortable(promise, abortSignal) : promise;
      })
    );

    tileImageBitmaps.forEach((imageBitmap, idx) => {
      if (!imageBitmap) {
        return;
      }

      const tile = tiles[idx];

      ctx.drawImage(
        imageBitmap,
        (tile[0] - span.x.min) * TILE_SIDE_PX,
        (tile[1] - span.y.min) * TILE_SIDE_PX,
        imageBitmap.width,
        imageBitmap.height
      );
    });

    return new RasterCalculator(canvas, bounds, dataRange);
  }

  private drawScaledImage(
    ctx: CanvasRenderingContext2D,
    origin: {x: number; y: number},
    scale = DEFAULT_SCALE
  ) {
    ctx.drawImage(
      this.image,
      origin.x,
      origin.y,
      ctx.canvas.width / scale,
      ctx.canvas.height / scale,
      0,
      0,
      ctx.canvas.width,
      ctx.canvas.height
    );
  }

  private drawImage(ctx: CanvasRenderingContext2D, origin: {x: number; y: number}) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.save();

    this.drawScaledImage(ctx, origin);

    ctx.restore();

    return ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  private drawClippedImage(
    ctx: CanvasRenderingContext2D,
    paths: [number, number][][][],
    origin: {x: number; y: number}
  ) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.save();

    this.drawPolygonPaths(ctx, paths, origin);
    ctx.clip();

    this.drawScaledImage(ctx, origin);

    ctx.restore();

    return ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  private drawFilledPolygon(
    ctx: CanvasRenderingContext2D,
    paths: [number, number][][][],
    origin: {x: number; y: number}
  ) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.save();

    this.drawPolygonPaths(ctx, paths, origin);
    ctx.fillStyle = '#ffffff';
    ctx.fill();

    ctx.restore();

    return ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  /**
   * Returns the poylgon’s coordinates as [x, y] points in the image’s space.
   * This involves converting lng/lat to EPSG:3857, offsetting by the image’s
   * origin, and scaling to fit the image’s scale. And inverting the Y axis.
   *
   * Also returns a function that maps from image x/y back to lat/lng so that
   * callers can determine accurate BBoxes for any canvases they generate.
   */
  private geoJsonPolygonToImagePoints(polygon: geojson.Polygon | geojson.MultiPolygon) {
    const sphericalMercator = new SphericalMercator();
    const xyBounds = sphericalMercator.convert(this.imageGeoBounds, WEB_MERCATOR_PROJECTION_ID);

    // west, south, east, north in an x/y projection
    const [xw, ys, xe, yn] = xyBounds;

    const xScale = this.image.width / (xe - xw);
    const yScale = this.image.height / (yn - ys);

    return [
      (polygon.type === 'Polygon' ? [polygon.coordinates] : polygon.coordinates).map((ring) =>
        ring.map((points) =>
          points
            .map((lngLat) => sphericalMercator.forward(lngLat))
            // Subtracting from height to invert the Y axis to match <canvas>
            // co-ordinate system. We subtract the west and south values to stay
            // consistent with the subtraction order when making the scales.
            .map(([x, y]): [number, number] => [
              (x - xw) * xScale,
              this.image.height - (y - ys) * yScale,
            ])
        )
      ),
      // Function to convert image x/y back to lng/lat
      ([x, y]: [number, number]): [number, number] =>
        sphericalMercator.inverse([x / xScale + xw, (this.image.height - y) / yScale + ys]),
    ] as const;
  }

  /**
   * Draws the polygon paths, which are specified in image space, on the given
   * ctx. We offset by the origin and potentially scale (for debug rendering).
   */
  private drawPolygonPaths(
    ctx: CanvasRenderingContext2D,
    paths: [number, number][][][],
    origin: {x: number; y: number},
    scale = DEFAULT_SCALE
  ) {
    ctx.beginPath();

    paths.forEach((rings) =>
      rings.forEach((path) => {
        const [first, ...rest] = path;
        ctx.moveTo((first[0] - origin.x) * scale, (first[1] - origin.y) * scale);
        rest.forEach((p) => ctx.lineTo((p[0] - origin.x) * scale, (p[1] - origin.y) * scale));
      })
    );
  }

  /**
   * Given a polygon in lat/lng, projects it into the image’s coordinate space,
   * and returns a <canvas> element that’s sized to contain it. Also returns the
   * paths themselves (in image space) and the origin of the canvas relative to
   * the entire image.
   *
   * We do this so that we can, in drawScaledImage, just draw the portion of the
   * image that contains the clipping polygon. This speeds up calculations,
   * especially when the user is interested in a small part of a larger
   * property.
   */
  private canvasFromPolygonBounds(
    polygon: geojson.Polygon | geojson.MultiPolygon,
    scale: number = DEFAULT_SCALE
  ) {
    const [projectedPaths, imgXYToLngLat] = this.geoJsonPolygonToImagePoints(polygon);

    // These two points are the bounds of the polygon in image space. We use
    // them to only create a canvas that’s this big, and only copy this many
    // pixels to it.
    const topLeft = {x: Infinity, y: Infinity};
    const bottomRight = {x: -Infinity, y: -Infinity};

    projectedPaths.forEach((rings) =>
      rings.forEach((points) =>
        points.forEach(([x, y]) => {
          if (x < topLeft.x) {
            topLeft.x = x;
          }

          if (y < topLeft.y) {
            topLeft.y = y;
          }

          if (x > bottomRight.x) {
            bottomRight.x = x;
          }

          if (y > bottomRight.y) {
            bottomRight.y = y;
          }
        })
      )
    );

    // We want to remain on pixel boundaries
    topLeft.x = Math.floor(topLeft.x);
    topLeft.y = Math.floor(topLeft.y);
    bottomRight.x = Math.ceil(bottomRight.x);
    bottomRight.y = Math.ceil(bottomRight.y);

    const canvas = document.createElement('canvas');
    canvas.width = (bottomRight.x - topLeft.x) * scale;
    canvas.height = (bottomRight.y - topLeft.y) * scale;

    /** When the willReadFrequently option is passed, TypeScript inexplicably
     * types the context as an ImageBitmapRenderingContext instead of
     * CanvasRenderingContext2D, which is its type in practice and what we
     * expect for a 2d type. */
    const ctx = canvas.getContext('2d', {willReadFrequently: true}) as CanvasRenderingContext2D;
    ctx.imageSmoothingEnabled = false;

    return {
      canvas,
      paths: projectedPaths,
      origin: topLeft,
      bounds: [
        // Mixing of topLeft and bottomRight here is purposeful to be in the
        // right order for BBoxes.
        ...imgXYToLngLat([topLeft.x, bottomRight.y]),
        ...imgXYToLngLat([bottomRight.x, topLeft.y]),
      ] as geojson.BBox,
    };
  }

  /**
   * Evenly scales a pixel from 0–255 to the layer’s data range.
   *
   * @param pixelVal Value from 0 to 255 for a pixel in the image. Values closer
   * to 0 (darker) are assumed to map to the higher end of the dataRange.
   */
  private pixelValueToDataValue(pixelVal: number, dataRange: DataRange) {
    return pixelValueToDataValue(pixelVal, dataRange);
  }

  /**
   * Given a polygon in lat/lng and a threshold range containing a minimum and
   * maximum value, return image data filtered to pixels whose values fall
   * within the threshold range.
   *
   * The mask is cropped to the polygon’s boundaries since it’s used to describe
   * percentage or absolute acreage that falls within the threshold range.
   */
  public generateMask(
    polygon: geojson.Polygon | geojson.MultiPolygon,
    overlayMaskFilter: OverlayMaskFilter
  ): {image: ImageData; bounds: geojson.BBox} {
    const {canvas, paths, origin, bounds} = this.canvasFromPolygonBounds(polygon);
    const ctx = canvas.getContext('2d')!;

    const imageData = this.drawClippedImage(ctx, paths, origin);

    // imageData is an array where every 4 bytes is a pixel.
    for (let i = 0; i < imageData.data.length; i = i + 4) {
      if (imageData.data[i + ALPHA_CHANNEL] === 0) {
        continue;
      }

      // all channels are the same, so we look at red
      const pixelVal = imageData.data[i];
      const dataVal = this.pixelValueToDataValue(pixelVal, this.dataRange);

      if (overlayMaskFilter.type === 'threshold') {
        if (dataVal < overlayMaskFilter.threshold[0] || dataVal > overlayMaskFilter.threshold[1]) {
          imageData.data[i + ALPHA_CHANNEL] = 0;
        }
      } else if (overlayMaskFilter.type === 'category') {
        if (!overlayMaskFilter.categories.has(dataVal.toString())) {
          imageData.data[i + ALPHA_CHANNEL] = 0;
        }
      }
    }

    return {image: imageData, bounds};
  }

  // Helper to prepare the canvas, draw the clipped image, and get related data.
  private prepareCanvas(polygon: geojson.Polygon | geojson.MultiPolygon) {
    const {canvas, paths, origin} = this.canvasFromPolygonBounds(polygon);
    const ctx = canvas.getContext('2d')!;

    const imageData = this.drawImage(ctx, origin);
    const imageDataUrl = ctx.canvas.toDataURL();

    const clippedImageData = this.drawClippedImage(ctx, paths, origin);
    const clippedImageDataUrl = ctx.canvas.toDataURL();

    return {ctx, paths, origin, imageData, imageDataUrl, clippedImageData, clippedImageDataUrl};
  }

  // Helper to create a inspector (for calculate)
  private createInspector(ctx: CanvasRenderingContext2D, clippedImageData: ImageData) {
    const actions = {
      // Clone the iteratee image data.
      cloneImageData: () =>
        new ImageData(
          new Uint8ClampedArray(clippedImageData.data),
          clippedImageData.width,
          clippedImageData.height
        ),

      // Change pixel color in place.
      colorImageData: (
        imageData: ImageData,
        index: number,
        [red, green, blue, alpha]: [number, number, number, number]
      ) => {
        imageData.data[index + 0] = red;
        imageData.data[index + 1] = green;
        imageData.data[index + 2] = blue;
        imageData.data[index + 3] = alpha;
      },

      // Transform image data to a data URL.
      makeImageDataUrl: (imageData: ImageData) => {
        const newCanvas = document.createElement('canvas');
        const newContext = newCanvas.getContext('2d');
        newCanvas.width = ctx.canvas.width;
        newCanvas.height = ctx.canvas.height;
        newContext?.putImageData(imageData, 0, 0);
        return newCanvas.toDataURL();
      },
    };

    return {
      ...actions,
      presenceImageData: actions.cloneImageData(),
      thresholdImageData: actions.cloneImageData(),
    };
  }

  public calculate(
    polygon: geojson.Polygon | geojson.MultiPolygon,
    threshold: readonly [number, number] = this.dataRange
  ): RasterCalculationResult {
    const {ctx, paths, origin, imageData, imageDataUrl, clippedImageData, clippedImageDataUrl} =
      this.prepareCanvas(polygon);

    const polygonImageData = this.drawFilledPolygon(ctx, paths, origin);

    const inspector = this.createInspector(ctx, clippedImageData);

    let sum = 0;
    let lowerUncertaintySum = 0;
    let upperUncertaintySum = 0;
    let totalPixelCount = 0;
    let weightedPixelsWithValue = 0;
    let weightedPixelsInThreshold = 0;
    let missingPixelCount = 0;
    let wholePixelCount = 0;
    let partialPixelCount = 0;

    const weightedPixelsWithValueByValue: RasterCalculationResult['weightedPixelsWithValueByValue'] =
      {};

    // An array where every 4 bytes is a pixel.
    for (let i = 0; i < clippedImageData.data.length; i = i + 4) {
      const polygonPixel = polygonImageData.data[i];

      // Use the unclipped image data to avoid using anti-aliased values at the
      // edge of the clipped image, which is important for categorical (e.g.,
      // landuse) data layers where the exact pixel value is meaningful.
      const pixelVal = imageData.data[i];

      // We use the first channgel (red) for the actual data values.
      // If we have uncertainty data, it will be in the next two channels (green and blue).
      // If there is no uncertainity data, all channels will be the same. We calculate
      // uncertainty infomation using these 2 channels regardless since we don't know
      // at this stage whether we have meanigful data here or not. Later on we can ignore
      // these values if they are the same as our average calculations
      const lowerUncertaintyPixelVal = imageData.data[i + UNCERTAINTY_MIN_CHANNEL];
      const upperUncertaintyPixelVal = imageData.data[i + UNCERTAINTY_MAX_CHANNEL];

      // Alpha could go below 1 on the edges of the clipped polygon, or within
      // cloud masks. Use the clipped image data so we get values below 1 for
      // partial pixels, which is important for data accuracy.
      const imageAlpha = clippedImageData.data[i + ALPHA_CHANNEL] / 255;

      if (polygonPixel === 0) {
        // We’re outside of the polygon, so just ignore this pixel.
        continue;
      }

      totalPixelCount++;
      if (imageAlpha === 0) {
        missingPixelCount++;
        inspector.colorImageData(inspector.presenceImageData, i, [255, 0, 0, 255]); // Red
        continue;
      }
      if (imageAlpha === 1) {
        wholePixelCount++;
        inspector.colorImageData(inspector.presenceImageData, i, [0, 128, 0, 255]); // Green
      } else {
        partialPixelCount++;
        inspector.colorImageData(inspector.presenceImageData, i, [255, 165, 0, 255]); // Orange
      }

      const val = this.pixelValueToDataValue(pixelVal, this.dataRange);
      const lowerUncertaintyVal = this.pixelValueToDataValue(
        lowerUncertaintyPixelVal,
        this.dataRange
      );
      const upperUncertaintyVal = this.pixelValueToDataValue(
        upperUncertaintyPixelVal,
        this.dataRange
      );

      sum += val * imageAlpha;
      lowerUncertaintySum += lowerUncertaintyVal * imageAlpha;
      upperUncertaintySum += upperUncertaintyVal * imageAlpha;
      weightedPixelsWithValue += imageAlpha;

      if (val >= threshold[0] && val <= threshold[1]) {
        weightedPixelsInThreshold += imageAlpha;
        inspector.colorImageData(inspector.thresholdImageData, i, [0, 128, 0, 255]);
      } else {
        inspector.colorImageData(inspector.thresholdImageData, i, [0, 128, 0, 0]);
      }

      weightedPixelsWithValueByValue[val] = (weightedPixelsWithValueByValue[val] ?? 0) + imageAlpha;
    }

    return {
      sum,
      mean: sum / weightedPixelsWithValue,
      lowerUncertaintyMean: lowerUncertaintySum / weightedPixelsWithValue,
      upperUncertaintyMean: upperUncertaintySum / weightedPixelsWithValue,
      percentWithinThreshold: weightedPixelsInThreshold / weightedPixelsWithValue,
      totalPixelCount,
      missingPixelCount,
      wholePixelCount,
      partialPixelCount,
      weightedPixelsWithValue,
      weightedPixelsInThreshold,
      weightedPixelsWithValueByValue,
      inspector: {
        image: this.image,
        imageBounds: this.imageGeoBounds,
        imageOrigin: origin,
        imageDataUrl,
        clippedImageDataUrl,
        presenceImageDataUrl: inspector.makeImageDataUrl(inspector.presenceImageData),
        thresholdImageDataUrl: inspector.makeImageDataUrl(inspector.thresholdImageData),
      },
    };
  }

  public getImageAndBounds(): {
    image: RasterImageType;
    bounds: geojson.BBox;
  } {
    return {
      image: this.image,
      bounds: this.imageGeoBounds,
    };
  }

  public renderForTest(polygon: geojson.Polygon | geojson.MultiPolygon, scale: number) {
    const {canvas, paths, origin} = this.canvasFromPolygonBounds(polygon, scale);
    const ctx = canvas.getContext('2d')!;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.save();

    this.drawPolygonPaths(ctx, paths, origin, scale);
    ctx.clip();

    this.drawScaledImage(ctx, origin, scale);

    ctx.restore();

    ctx.save();

    this.drawPolygonPaths(ctx, paths, origin, scale);

    ctx.fillStyle = 'rgba(170, 215, 224, 0.3)';
    ctx.fill();

    ctx.strokeStyle = `${colors.lightBlue}`;
    ctx.stroke();

    ctx.restore();

    return canvas;
  }
}

/**
 * A function that identifies which raster tiles are required to cover the
 * provided polygon geometry within the limits provided on the options argument.
 * It also returns convenience statistics about the tile coordinates’ range span
 * in both directions.
 *
 * There’s an edge case in which the tiles returned at the minimum zoom still
 * exceed the maxDirectionalTileCount value, which is the maximum desired number
 * of tiles in the x or y direction. When that happens, we prioritize not
 * exceeding the minimum zoom, since we may not be able to request tiles at
 * smaller zoom levels.
 */
export function getTileData(
  polygon: geojson.Polygon | geojson.MultiPolygon,
  options?: TileDataOptions
): TileData | null {
  // Maximum zoom level we can request webtiles at.
  const minZoom = options?.minZoom ?? TILE_MIN_ZOOM;

  // Minimum zoom level we can request webtiles at.
  const maxZoom = options?.maxZoom ?? TILE_MAX_ZOOM;

  // Maximum number of tiles in the x or y direction.
  const maxDirectionalTileCount = options?.maxDirectionalTileCount ?? MAX_DIRECTIONAL_TILE_COUNT;

  let tiles: Tile[] | null = null;
  let span: TileSpan | null = null;

  let zoom = maxZoom + 1;

  while (zoom > minZoom) {
    zoom--;

    // @mapbox/tile-cover returns the mininum tiles required to cover a GeoJSON
    // geometry using tiles between the provided minimum and maximum zoom
    // levels. Because these tiles do not overlap, we need to request tiles at a
    // single zoom at a time to ensure we are getting all tiles needed to cover
    // the property at a single zoom level.
    tiles = tileCover.tiles(polygon, {
      min_zoom: zoom,
      max_zoom: zoom,
    }) as Tile[];

    span = getTileSpan(tiles);

    const hasValidXCount = span.x.count <= maxDirectionalTileCount;
    const hasValidYCount = span.y.count <= maxDirectionalTileCount;

    if (hasValidXCount && hasValidYCount) {
      break;
    }
  }

  if (tiles && span) {
    const bounds = getBoundsFromTileData(zoom, span);
    return {tiles, zoom, span, bounds};
  } else {
    return null;
  }
}

/**
 * A function that returns a GeoJSON bounding box given a tile data object.
 */
function getBoundsFromTileData(zoom: number, span: TileSpan) {
  const tileNw: Tile = [span.x.min, span.y.min, zoom];
  const tileSe: Tile = [span.x.max, span.y.max, zoom];

  const featureNw = tileToFeature(tileNw);
  const featureSe = tileToFeature(tileSe);

  const fc = geoJsonUtils.featureCollection([featureNw, featureSe]);

  return bbox(fc);
}

/**
 * A function that given an array of tiles, returns the minimum coordinate,
 * maximum coordinate, and tile count in the x and y directions.
 */
export function getTileSpan(tiles: Tile[]): TileSpan {
  return {
    x: getDirectionalTileSpan(tiles.map((t) => t[0])),
    y: getDirectionalTileSpan(tiles.map((t) => t[1])),
  };
}

/**
 * A function that calculates the minimum, maximum, and count given an array of
 * numbers. We use the difference between the maximum and minimum coordinates to
 * calculate the count because even if the tiles are not contiguous, we use the
 * count to determine the dimensions of the canvas, which must accurately
 * represent the full extent of the tiles collectively.
 */
function getDirectionalTileSpan(arr: number[]) {
  const uniqueArr = arr.filter((n, i) => arr.indexOf(n) === i);

  const min = Math.min(...uniqueArr);
  const max = Math.max(...uniqueArr);

  return {min, max, count: max - min + 1};
}

/**
 * A function that replaces coordinate parameters in a raster tile template URL
 * with the values from the provided tile.
 */
export function getTileUrl(tile: Tile, templateUrl: string) {
  return templateUrl
    .replace(/\{x\}/, tile[0].toString())
    .replace(/\{y\}/, tile[1].toString())
    .replace(/\{z\}/, tile[2].toString());
}

/**
 * Function that converts tile coordinates to a GeoJSON feature.
 */
function tileToFeature(tile: Tile) {
  const geometry = tilebelt.tileToGeoJSON(tile);

  return geoJsonUtils.feature(geometry, {});
}

/**
 * Function to get the raster source definition for a provided feature datum and
 * layer key. Returns undefined if the provided feature datum does not have a
 * raster source definition for the provided layer key.
 */
export function getRasterSourceDefinition(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  layerKey: string
): I.ImmutableOf<RasterSourceDefinition> | undefined {
  return featureDatum.getIn(['images', 'templateUrls'])?.get(layerKey);
}

/**
 * Function to get the template URL for a provided feature datum and layer key.
 * Returns undefined if the provided feature datum does not have a raster source
 * definition with a tiles property for the provided layer key.
 */
export function getTemplateUrl(
  featureDatum: I.ImmutableOf<ApiFeatureData>,
  layerKey: string,
  firebaseToken: string | null = null
): string | undefined {
  const definition = getRasterSourceDefinition(featureDatum, layerKey);
  const templateUrl: string | undefined = definition?.get('tiles')?.first();

  if (templateUrl) {
    // We'll send credentials in a header when fetching tiles (so they'll get cached longer).
    // The apiToken expires after an hour resulting in a cache miss when it's part of the URL.
    return mapUtils.formatTileUrl(templateUrl, firebaseToken);
  } else {
    return templateUrl;
  }
}

/**
 * Function that filters provided feature data by entries with a `types`
 * property containing the provided layer key.
 */
export function filterFeatureDataByType(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string
) {
  return featureData.filter(
    (d) => isFeatureDatumProcessed(d!, layerKey) && d!.get('types').includes(layerKey)
  );
}

/**
 * Function that constructs a TileDataOptions object using the minimum and
 * maximum zoom values available on the provided layer’s feature data.
 */
export function getTileDataOptions(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string
): TileDataOptions {
  const options: TileDataOptions = {};

  featureData.forEach((featureDatum) => {
    if (featureDatum!.get('types').includes(layerKey)) {
      const definition = getRasterSourceDefinition(featureDatum!, layerKey);
      const minZoom = definition?.get('minzoom');
      const maxZoom = definition?.get('maxzoom');

      if (minZoom !== undefined && (options.minZoom === undefined || minZoom > options.minZoom)) {
        options.minZoom = minZoom;
      }

      if (maxZoom !== undefined && (options.maxZoom === undefined || maxZoom < options.maxZoom)) {
        options.maxZoom = maxZoom;
      }
    }
  });

  return options;
}
/**
 * Function that returns true if the provided feature data contains
 * on demand thumbnail urls for the provided layer key for every datum.
 */
export function getFeatureDataHasThumbnailUrls(
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  rawLayerKey: string | null
) {
  if (!rawLayerKey) {
    return false;
  }

  const filteredFeatureData = filterFeatureDataByType(featureData, rawLayerKey);

  return (
    !!filteredFeatureData.size &&
    !!filteredFeatureData.every((d) => {
      const url = d!.getIn(['images', 'urls', rawLayerKey]);
      return !!url && mapUtils.isTilerUrl(url);
    })
  );
}
