import firebase from 'firebase/app';
import React from 'react';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBZnrCwNoDgg5DFzEP6Smq9cscYxT-9hSo',
  authDomain: 'monitron-dev.firebaseapp.com',
  databaseURL: 'https://monitron-dev.firebaseio.com',
  projectId: 'monitron-dev',
  storageBucket: 'monitron-dev.appspot.com',
  messagingSenderId: '579167012312',
};

interface FirebaseObjects {
  app: firebase.app.App;
  auth: firebase.auth.Auth;
  database: firebase.database.Database;
  storage: firebase.storage.Storage;
}

export const FirebaseContext = React.createContext<FirebaseObjects | undefined>(undefined);

// Allows access to the Firebase app and some of its sub-objects.
//
// Will initialize the app if it hasn’t been, but does not try to delete it on
// unmount.
const FirebaseProvider: React.FunctionComponent<React.PropsWithChildren<{config?: object}>> = ({
  config = FIREBASE_CONFIG,
  children,
}) => {
  let firebaseApp: firebase.app.App;

  try {
    firebaseApp = firebase.app();
  } catch {
    firebaseApp = firebase.initializeApp(config);
  }

  // We definitely need these objects to stay consistent while the app is
  // being used, since they’re dependencies for any useEffect hooks that
  // interact with Firebase.
  const firebaseObjects = React.useMemo(
    () => ({
      app: firebaseApp,
      auth: firebase.auth(firebaseApp),
      database: firebase.database(firebaseApp),
      storage: firebase.storage(firebaseApp),
    }),
    [firebaseApp]
  );

  return <FirebaseContext.Provider value={firebaseObjects}>{children}</FirebaseContext.Provider>;
};

const useFirebase = () => {
  const firebaseObjects = React.useContext(FirebaseContext);

  if (!firebaseObjects) {
    throw new Error('This hook must be called beneath FirebaseProvider');
  }

  return firebaseObjects;
};

export const useFirebaseAuth = () => {
  const {auth} = useFirebase();
  return auth;
};

export const useFirebaseDatabase = () => {
  const {database} = useFirebase();
  return database;
};

export const useFirebaseStorage = () => {
  const {storage} = useFirebase();
  return storage;
};

export default FirebaseProvider;
