import * as B from '@blueprintjs/core';
import {booleanContains, centerOfMass} from '@turf/turf';
import geojson from 'geojson';
import * as I from 'immutable';
import {cloneDeep, set} from 'lodash';
import moment from 'moment';
import React from 'react';

import List, {ListItem} from 'app/components/List';
import {api} from 'app/modules/Remote';
import {
  ApiFeature,
  ApiFeatureData,
  ApiReport,
  ReportNotePage,
  ReportOverviewPage,
  ReportState,
} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, GeometryOverlaySetting} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, getMeasurementSystem} from 'app/modules/Remote/Organization';
import {GeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {StateApiNoteWithTagSettings} from 'app/stores/NotesStore';
import * as C from 'app/utils/constants';
import {hydratedFeatureCollection} from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as layers from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import {MapImageRef} from 'app/utils/mapUtils';
import {randomCharacters} from 'app/utils/mathUtils';
import {
  MultiFeaturePropertyPart,
  MultiFeaturePropertyParts,
} from 'app/utils/multiFeaturePropertyUtils';
import * as noteUtils from 'app/utils/noteUtils';

import LocationSvg from './LocationSvg';
import {ReadyStateReporter} from './readyState';
import ReportExportFeatureMap from './ReportExportFeatureMap';
import {ReportExportTitlePage} from './ReportExportTitlePage';
import {ReportExportToolbar} from './ReportExportToolbar';
import {ReportError} from './ReportExportWindow';
import {ImageSourceInfo, NoteObservationPages} from './ReportNoteObservation/ReportNoteObservation';
import LensLogoSvg from '../Logo/LensLogoSvg';

export const DATA_NOTE_ID_PREFIX = 'data-note-id';
const PROPERTY_OVERVIEW_ID_PREFIX = 'data-property-overview-id';
export const ICON_COLOR = '#182026';

export interface ReportFeatureInfo {
  feature: I.ImmutableOf<ApiFeature>;
  featureData: I.ImmutableOf<ApiFeatureData[]>;
  notes: StateApiNoteWithTagSettings[];
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

type ReportStatePath = (string | number)[];

export function formatSource(sourceDetails: featureUtils.ImagerySourceDetails) {
  let formattedSource = `${sourceDetails.operator} ${sourceDetails.name}`;
  if (sourceDetails.resolution) {
    formattedSource = `${formattedSource} (${sourceDetails.resolution}m)`;
  }
  return formattedSource;
}

export function formatDate(date?: Date | string, includeTime?: boolean) {
  if (includeTime) {
    return moment(date).format('MMMM Do YYYY, h:mm a');
  }
  return moment(date).format('LL');
}

function formatObservationPageTitle(
  isCombinedMultiPart: boolean,
  isMultiPartProperty: boolean,
  currentPropertyPart: MultiFeaturePropertyPart
) {
  return `${
    isCombinedMultiPart
      ? currentPropertyPart.partName! || 'Location'
      : `Property Overview${
          isMultiPartProperty ? `, ${currentPropertyPart.partName || 'Location'}` : ''
        }`
  }`;
}

function filterNotes(
  notes: StateApiNoteWithTagSettings[],
  enrolledLayerKeys: string[],
  filterNotesCallback?: (note: StateApiNoteWithTagSettings) => boolean
) {
  return notes.filter(
    (note) =>
      // Only show user notes
      note.noteType === C.NOTE_TYPE_USER &&
      // Satisfy the filter notes callback, if provided
      (!filterNotesCallback || filterNotesCallback(note)) &&
      // don't include notes with unenrolled layers
      !noteUtils.noteHasUnenrolledLayers(note, enrolledLayerKeys)
  );
}

export function makeDefaultOverlaySettings(overlaySettings: GeometryOverlaySetting[]) {
  return overlaySettings.reduce(
    (acc: Record<string, boolean>, {name, defaultEnabled}) => ({
      ...acc,
      [name]: defaultEnabled,
    }),
    {}
  );
}

/**
 * This component generates all pages of the features+notes report, as well as its cover
 * page and toolbar.
 * It tracks the state of user-editable content, so that when the user saves the report,
 * any changes they've made to text fields, page order, the map, etc are saved.
 * It takes a list of `featureInfos`: For most reports, we are only passing in one featureInfo
 * (ie, the featureData and notes for a given feature). But for multilocation property reports,
 * we are passing in multiple featureInfos -- notes and featureData for all features within the
 * multilocation feature.
 */
export const ReportExportFeatureWithNotes: React.FunctionComponent<
  React.PropsWithChildren<{
    firebaseToken: string;
    organization: I.ImmutableOf<ApiOrganization>;
    projectId: string;
    geoJsonFeaturesLoader: GeoJsonFeaturesLoader;
    isMultiPartProperty: boolean;
    isCombinedMultiPart: boolean;
    /** one of the feature values in this array must match the feature prop */
    propertyParts: MultiFeaturePropertyParts;
    filterNotesCallback: (note: StateApiNoteWithTagSettings) => boolean;
    overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
    overlaySettings: GeometryOverlaySetting[];
    areMapsReadyReporter: ReadyStateReporter;
    areFontsReady: boolean;
    enrolledLayerKeys: string[];
    areMapsReady: boolean;
    savedReportId?: number;
    reportTitle: string;
    featureInfos: ReportFeatureInfo[];
    refetchReports?: () => Promise<void>;
    reportWindow: Window | null;
    selectedFeature: I.ImmutableOf<ApiFeature>;
  }>
> = ({
  firebaseToken,
  organization,
  projectId,
  geoJsonFeaturesLoader,
  overlaySettings,
  overlayFeatureCollections,
  propertyParts,
  isMultiPartProperty,
  isCombinedMultiPart,
  filterNotesCallback,
  areMapsReadyReporter,
  areFontsReady,
  areMapsReady,
  enrolledLayerKeys,
  savedReportId,
  featureInfos,
  reportTitle,
  refetchReports,
  reportWindow,
  selectedFeature,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const focusedNoteIdRef = React.useRef<string | null>(null);
  const focusedOverviewIdRef = React.useRef<string | null>(null);

  const overlayFeatureCollectionsJs = React.useMemo<
    (Pick<ApiFeatureCollection, 'name' | 'id' | 'tiles'> & geojson.FeatureCollection)[]
  >(
    () =>
      overlayFeatureCollections
        .map((fc) => hydratedFeatureCollection(fc!, geoJsonFeaturesLoader(fc!)))
        .toJS(),
    [overlayFeatureCollections, geoJsonFeaturesLoader]
  );

  // Flatmap because we need a single array of all notes pulled off all of the features present
  // in the report (only relevant that it's a flatmap if this is a CombinedMultiPart report)
  const featureInfoNotes = featureInfos.flatMap((fI) => fI.notes);
  const [filteredNotes, setFilteredNotes] = React.useState<StateApiNoteWithTagSettings[]>(
    filterNotes(featureInfoNotes, enrolledLayerKeys, filterNotesCallback)
  );

  // Only update list of notes as a side effect if the note filter has changed (e.g., date range has
  // been updated).
  React.useEffect(() => {
    setFilteredNotes(filterNotes(featureInfoNotes, enrolledLayerKeys, filterNotesCallback));
  }, [filterNotesCallback]);

  // If filtered notes change and we have a truthy focused note ID reference, find the element
  // corresponding to the focused note ID and scroll it into view.
  React.useLayoutEffect(() => {
    if (focusedNoteIdRef.current) {
      const el = containerRef.current?.ownerDocument?.querySelector(
        `[${DATA_NOTE_ID_PREFIX}="${focusedNoteIdRef.current}"]`
      );

      if (el) {
        el.scrollIntoView();
      }

      focusedNoteIdRef.current = null;
    }
  }, [filteredNotes]);

  // For right now, only allow hi-res truecolor images for overviewPages.
  const coverLayerKey = layers.ANY_TRUECOLOR_HIGH_RES;

  // A list of the coverFeatureData available for each featureInfo. Each featureInfo
  // will get a list of truecolor images that can be selected from for the overview page.
  const coverFeatureDatas: I.List<I.MapAsRecord<I.ImmutableFields<ApiFeatureData>>>[] =
    featureInfos.map(({featureData}) => {
      const unrolledFeatureData = featureUtils.unrollFeatureDataBySource(
        featureData,
        coverLayerKey
      );

      return featureUtils.filterFeatureData(
        featureUtils.getProcessedFeatureData(unrolledFeatureData, coverLayerKey),
        coverLayerKey,
        'descending'
      );
    });

  // apiReport tracks the report object returned from the API, so that we can reference metatdata
  // about the report like title and updatedAt within this view.
  const [apiReport, setApiReport] = React.useState<ApiReport<ReportState | string> | undefined>();
  // reportState specifically tracks the ReportState object that we save to the API
  const [reportState, setReportState] = React.useReducer(
    (
      state: ReportState,
      [path, statePartial]: [
        ReportStatePath,
        (
          | DeepPartial<ReportState>
          | string
          | number
          | boolean
          | ReportOverviewPage[]
          | ReportNotePage[]
        ),
      ]
    ) => {
      // Lodash `set` mutates objects in place. We don't want to mutate state directly (and
      // also mutating state directly does not trigger a rerender). So we pass in a deep copy
      // of state to `set` instead and return that as our new state.

      // This means that we rerender everything every time we make an edit to the reportState.
      // If that gets to be too much we might need to memoize some stuff.
      const stateCopy = cloneDeep(state);
      return set(stateCopy, path, statePartial);
    },
    {
      // Pass in strings here, the defaults are controlled by the component.
      coverPage: {
        title: '',
        subtitle: '',
        company: '',
        date: '',
        description: '',
      },
      overviewPages:
        //we map over featureInfos so that we can get an overviewPage for every
        //propertyPart of a multilocation property, but we don't provide an
        //overviewPage if there's no hi-res truecolor imagery for that featureInfo
        featureInfos
          // no overview page for features that don't have any truecolor imagery available
          .filter((_fI, idx) => coverFeatureDatas[idx].size > 0)
          .map(({feature}, idx) => {
            const currentPropertyPartIndex = propertyParts.findIndex((p) => p.feature === feature)!;
            const currentPropertyPart = propertyParts[currentPropertyPartIndex];

            const coverFeatureData = coverFeatureDatas[idx];

            return {
              title: formatObservationPageTitle(
                isCombinedMultiPart,
                isMultiPartProperty,
                currentPropertyPart
              ),
              hidden: false,
              imageRef: {
                //grab the first cursor and layerKey from our list of coverFeatureData
                //as our default image selection for the overview page
                cursor: coverFeatureData.first().get('date'),
                layerKey: layerUtils.getLayerKeyFromSourceAndLayer(
                  coverFeatureData.first().get('sources').first(),
                  'high-res-truecolor'
                ),
              },
              // Using the cursor as well as an id to deal with potentially
              // having multiple of the same cursor
              id: randomCharacters(),
              currentPropertyPartIndex: currentPropertyPartIndex,
              showNotePoints: false,
              showMapScale: true,
            };
          }),

      notePages: filteredNotes.map((note) => {
        const currentPropertyPartIndex = propertyParts.findIndex(
          (p) => p.feature.get('id') === note.featureId
        )!;

        return {
          id: note.id,
          title: '',
          hidden: false,
          attachmentsHidden: false,
          chartHidden: false,
          legendsHidden: false,
          currentPropertyPartIndex: currentPropertyPartIndex,
          overlayVisibilityByName: makeDefaultOverlaySettings(overlaySettings),
          fullPageImageLayout: false,
          showMapScale: false,
        };
      }),
    }
  );

  // If overview pages are added or reordered and we have a reference, find the element
  // corresponding to the focused property overview ID and scroll it into view.
  React.useLayoutEffect(() => {
    if (focusedOverviewIdRef.current) {
      const el = containerRef.current?.ownerDocument?.querySelector(
        `[${PROPERTY_OVERVIEW_ID_PREFIX}="${focusedOverviewIdRef.current}"]`
      );

      if (el) {
        el.scrollIntoView();
      }

      focusedOverviewIdRef.current = null;
    }
  }, [reportState.overviewPages]);

  const reportsApi = React.useMemo(() => {
    return api.reports(selectedFeature.get('id'));
  }, [selectedFeature]);

  const saveReport = React.useCallback(
    async (report: ReportState, title: string, isMultiLocation: boolean) => {
      return reportsApi.create(report, title, isMultiLocation);
    },
    [reportsApi]
  );

  const getReport = React.useCallback(
    async (reportId: number) => {
      return await reportsApi.get(reportId);
    },
    [reportsApi]
  );

  const updateReport = React.useCallback(
    async (reportId: number, reportState: ReportState) => {
      return reportsApi.update(reportId, reportState);
    },
    [reportsApi]
  );

  const archiveReport = React.useCallback(
    async (reportId: number) => {
      return reportsApi.archive(reportId);
    },
    [reportsApi]
  );

  // If we're viewing a saved report, load up the saved report data and then
  // hydrate reportState with it.
  const [loadingSavedReport, setLoadingSavedReport] = React.useState(false);
  const [savedReportError, setSavedReportError] = React.useState(false);
  React.useEffect(() => {
    // Start in a loading state no matter what to ensure no jitter
    setLoadingSavedReport(true);
    if (savedReportId) {
      const fetchReport = async () => {
        try {
          const savedReportData = (
            await getReport(savedReportId).then((response) => response.get('data'))
          ).toJS();
          setApiReport(savedReportData);
          setReportState([['coverPage'], savedReportData.data.coverPage]);
          setReportState([['overviewPages'], savedReportData.data.overviewPages]);
          setReportState([['notePages'], savedReportData.data.notePages]);
          setLoadingSavedReport(false);
        } catch {
          setLoadingSavedReport(false);
          setSavedReportError(true);
        }
      };
      fetchReport();
    } else {
      // Turn off load state immediately if we're not fetching a saved report
      setLoadingSavedReport(false);
    }
  }, [savedReportId, getReport]);

  // This is used to display a warning if we have a report with saved noteIds for deleted notes
  const hasMissingNotes =
    reportState.notePages.filter(
      (notePage) => !filteredNotes.find((note) => note.id === notePage.id)
    ).length > 0;

  return loadingSavedReport ? (
    <div style={{width: '30%', margin: '10rem auto'}}>
      <B.ProgressBar intent="primary" />
    </div>
  ) : savedReportError ? (
    <ReportError />
  ) : (
    <>
      <ReportExportToolbar
        areMapsReady={areMapsReady}
        apiReport={apiReport}
        setApiReport={setApiReport}
        saveReport={(newReportTitle) =>
          saveReport(reportState, newReportTitle, isCombinedMultiPart)
        }
        updateReport={apiReport ? () => updateReport(apiReport.id, reportState) : undefined}
        archiveReport={apiReport ? () => archiveReport(apiReport.id) : undefined}
        hasMissingNotes={hasMissingNotes}
        refetchReports={refetchReports}
        reportWindow={reportWindow}
        reportTitle={reportTitle}
      />
      {/* <div> rather than a <> because we need some DOM element to ref that we can later use to get ownerDocument. */}
      <div ref={containerRef}>
        <ReportExportTitlePage
          organization={organization}
          projectId={projectId}
          feature={featureInfos[0].feature}
          filteredNotes={filteredNotes}
          propertyParts={propertyParts}
          isMultiPartProperty={isMultiPartProperty}
          isCombinedMultiPart={isCombinedMultiPart}
          defaultReportSubtitle="Remote Monitoring Report"
          reportCoverPageState={reportState.coverPage}
          setReportState={setReportState}
          firebaseToken={firebaseToken}
          reportWindow={reportWindow}
        />
        {/**for every featureInfo (which will be 1 for most reports but >1 for multilocation property reports),
         * render any overviewPages (one by default, more if added) and then all notes for that feature.
         */}
        {featureInfos.map(({feature, featureData, notes}, featureInfoIdx) => {
          // Convert our geojson to JS since it needs to be in that format to pass to Leaflet.
          const featureJs: ApiFeature = feature.toJS();
          const currentPropertyPartIndex = propertyParts.findIndex((p) => p.feature === feature)!;
          const currentPropertyPart = propertyParts[currentPropertyPartIndex];

          const featureNoteGeometries: geojson.Geometry[] = filteredNotes
            .filter((note) => !!note.geometry && note.featureId === feature.get('id'))
            .map((note) => note.geometry!);

          return (
            <>
              {/** We interleave the overviewPages and then notePages for each featureInfo   */}
              {reportState.overviewPages.map((overviewPage, i) => {
                if (overviewPage.currentPropertyPartIndex === currentPropertyPartIndex) {
                  return (
                    <OverviewPage
                      key={i}
                      coverFeatureData={coverFeatureDatas[featureInfoIdx]}
                      reportState={reportState}
                      reportOverviewPageState={overviewPage}
                      reportOverviewPageIndex={i}
                      setReportState={setReportState}
                      featureData={featureData}
                      focusedOverviewIdRef={focusedOverviewIdRef}
                      organization={organization}
                      isCombinedMultiPart={isCombinedMultiPart}
                      isMultiPartProperty={isMultiPartProperty}
                      currentPropertyPart={currentPropertyPart}
                      propertyParts={propertyParts}
                      firebaseToken={firebaseToken}
                      areMapsReadyReporter={areMapsReadyReporter}
                      featureJs={featureJs}
                      overlaySettings={overlaySettings}
                      currentPropertyPartIndex={currentPropertyPartIndex}
                      coverLayerKey={coverLayerKey}
                      noteGeometries={featureNoteGeometries}
                    />
                  );
                } else {
                  return <></>;
                }
              })}

              {reportState.notePages.map((reportNoteState, i) => {
                const note = notes.find((note) => note.id === reportNoteState.id);
                // we're tracking missing notes and will display a warning to the user,
                // so if we can't find a note here just render empty jsx
                if (note) {
                  return (
                    <NoteObservationPages
                      key={note.id}
                      firebaseToken={firebaseToken}
                      readyStateReporter={areMapsReadyReporter}
                      areFontsReady={areFontsReady}
                      note={note}
                      featureJs={featureJs}
                      featureData={featureData}
                      overlayFeatureCollections={overlayFeatureCollectionsJs}
                      overlaySettings={overlaySettings}
                      moveObservation={(indexDiff: number) => {
                        // Save the note ID so that we can scroll it into view when reordered notes have been
                        // saved to state.
                        focusedNoteIdRef.current = `${note.id}`;

                        setFilteredNotes((notes) => {
                          const newNotes = [...notes];
                          const prevIndex = newNotes.findIndex((n) => `${n.id}` === `${note.id}`);
                          const prevNote = newNotes.splice(prevIndex, 1)[0];
                          newNotes.splice(prevIndex + indexDiff, 0, prevNote);

                          //TODO(eva) make this less duplicative. not sure we can fully get rid
                          //of filteredNotes, but maybe we could hydrate reportState with filteredNotes?
                          const newReportNotes = [...reportState.notePages];
                          const prevReportNote = newReportNotes.splice(prevIndex, 1)[0];
                          newReportNotes.splice(prevIndex + indexDiff, 0, prevReportNote);
                          setReportState([['notePages'], newReportNotes]);
                          return newNotes;
                        });
                      }}
                      disableMoveUp={
                        //don't allow moving a note up if it's the first note in general or for the currentPropertyPart
                        i === 0 ||
                        reportNoteState.currentPropertyPartIndex !==
                          reportState.notePages[i - 1].currentPropertyPartIndex
                      }
                      disableMoveDown={
                        //don't allow moving a note down if it's the last note in general or for the currentPropertyPart
                        i === reportState.notePages.length - 1 ||
                        reportNoteState.currentPropertyPartIndex !==
                          reportState.notePages[i + 1].currentPropertyPartIndex
                      }
                      organization={organization}
                      isMultiPartProperty={isMultiPartProperty}
                      isCombinedMultiPart={isCombinedMultiPart}
                      reportNoteState={reportNoteState}
                      setReportState={setReportState}
                      noteIndex={i}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </>
          );
        })}
      </div>
    </>
  );
};

const OverviewPage: React.FunctionComponent<
  React.PropsWithChildren<{
    reportState: ReportState;
    setReportState: (ReportStatePath) => void;
    reportOverviewPageState: ReportOverviewPage;
    reportOverviewPageIndex: number;
    coverFeatureData: I.List<I.MapAsRecord<I.ImmutableFields<ApiFeatureData>>>;
    focusedOverviewIdRef: React.MutableRefObject<string | null>;
    organization: I.ImmutableOf<ApiOrganization>;
    isCombinedMultiPart: boolean;
    isMultiPartProperty: boolean;
    currentPropertyPart: MultiFeaturePropertyPart;
    firebaseToken: string;
    areMapsReadyReporter: ReadyStateReporter;
    featureJs: geojson.Feature;
    featureData: I.ImmutableOf<ApiFeatureData[]>;
    overlaySettings: GeometryOverlaySetting[];
    currentPropertyPartIndex: number;
    propertyParts: MultiFeaturePropertyParts;
    coverLayerKey: string;
    noteGeometries?: geojson.Geometry[];
  }>
> = ({
  reportState,
  reportOverviewPageState,
  reportOverviewPageIndex,
  setReportState,
  coverFeatureData,
  focusedOverviewIdRef,
  organization,
  isCombinedMultiPart,
  isMultiPartProperty,
  currentPropertyPart,
  firebaseToken,
  areMapsReadyReporter,
  featureJs,
  featureData,
  overlaySettings,
  currentPropertyPartIndex,
  propertyParts,
  coverLayerKey,
  noteGeometries,
}) => {
  const selectedCursor = React.useMemo(() => {
    //use the imageRef saved to our overview page state's imageRef to instantiate the selected cursor.
    //some legacy saved reports may have only a cursor saved rather than an imageRef. if so,
    //use that saved cursor to find the layerKey associated.
    return reportOverviewPageState.imageRef
      ? reportOverviewPageState.imageRef.cursor
      : (reportOverviewPageState.cursor ?? coverFeatureData.first()?.get('date'));
  }, [reportOverviewPageState.imageRef, reportOverviewPageState.cursor, coverFeatureData]);

  const layer = layerUtils.parseLayerKey(coverLayerKey).layerId;
  const coverFeatureDatum = coverFeatureData.find((d) => d!.get('date') === selectedCursor);
  //though featureDatums can have multiple sources on the same cursor, we have unrolled our featureData into
  //a list where we can have repeated dates with single sources. so we can rely on sources.first() here.
  const source = coverFeatureDatum?.get('sources').first();
  const layerKeyForSource = layerUtils.getLayerKeyFromSourceAndLayer(source, layer);

  // We use imageRefs to save chosen imagery to overview pages instead of cursors. This is because we can
  // have two images with identical cursors but different layerKeys (e.g. PLANET-FOREST-CARBON-30 & PLANET-FOREST-CARBON-3).
  const imageRef: MapImageRef = React.useMemo(() => {
    return (
      reportOverviewPageState.imageRef ?? {
        cursor: selectedCursor,
        layerKey: layerKeyForSource,
      }
    );
  }, [reportOverviewPageState.imageRef, selectedCursor, layerKeyForSource]);

  const [combinedPartFeature, combinedPartGeometries] = React.useMemo(
    () => combinePropertyParts(propertyParts),
    [propertyParts]
  );

  const {sourceDetails: coverSourceDetails} = coverFeatureDatum
    ? featureUtils.imageAndSourceDetails(coverFeatureDatum, layerKeyForSource, organization)
    : {sourceDetails: null};

  const isHidden = reportOverviewPageState.hidden;

  const createNewOverviewPageWithDefaults = (newCursor: string): ReportOverviewPage => {
    return {
      cursor: newCursor,
      id: randomCharacters(),
      title: formatObservationPageTitle(
        isCombinedMultiPart,
        isMultiPartProperty,
        currentPropertyPart
      ),
      hidden: false,
      currentPropertyPartIndex: currentPropertyPartIndex,
      showNotePoints: false,
    };
  };

  return (
    <>
      <section
        key={reportOverviewPageState.id}
        {...{[PROPERTY_OVERVIEW_ID_PREFIX]: `${reportOverviewPageState.id}`}}
        className={isHidden ? 'note-unselected no-print' : ''}
      >
        <div className="note-control-btns no-print">
          <B.ButtonGroup vertical>
            <B.AnchorButton
              title="Move up"
              icon="caret-up"
              disabled={
                //Disable if this is the first overview page (can't move it up),
                //or if it's the first overview page for this property part.
                reportOverviewPageIndex === 0 ||
                reportState.overviewPages[reportOverviewPageIndex - 1].currentPropertyPartIndex !==
                  currentPropertyPartIndex
              }
              onClick={() => {
                const newOverviewPages = [...reportState.overviewPages];
                const prevIndex = newOverviewPages.findIndex(
                  (page) => page.id === reportOverviewPageState.id
                );
                const prevCursor = newOverviewPages.splice(prevIndex, 1)[0];
                newOverviewPages.splice(prevIndex - 1, 0, prevCursor);
                focusedOverviewIdRef.current = `${prevCursor.id}`;
                setReportState([['overviewPages'], newOverviewPages]);
              }}
            />

            <B.AnchorButton
              title="Move down"
              icon="caret-down"
              disabled={
                //Disable if this is the last overview page (can't move it down),
                //or if it's the last overview page for this property part.
                reportOverviewPageIndex === reportState.overviewPages.length - 1 ||
                reportState.overviewPages[reportOverviewPageIndex + 1].currentPropertyPartIndex !==
                  currentPropertyPartIndex
              }
              onClick={() => {
                const newOverviewPages = [...reportState.overviewPages];
                const prevIndex = newOverviewPages.findIndex(
                  (page) => page.id === reportOverviewPageState.id
                );
                const prevCursor = newOverviewPages.splice(prevIndex, 1)[0];
                newOverviewPages.splice(prevIndex + 1, 0, prevCursor);
                focusedOverviewIdRef.current = `${prevCursor.id}`;
                setReportState([['overviewPages'], newOverviewPages]);
              }}
            />
          </B.ButtonGroup>
          <B.ButtonGroup vertical>
            <B.AnchorButton
              title="Include in report"
              icon="eye-open"
              active={!isHidden}
              onClick={() => {
                setReportState([['overviewPages', reportOverviewPageIndex, 'hidden'], false]);
              }}
            />
            <B.AnchorButton
              title="Exclude from report"
              icon="eye-off"
              active={isHidden}
              onClick={() => {
                setReportState([['overviewPages', reportOverviewPageIndex, 'hidden'], true]);
              }}
            />
          </B.ButtonGroup>

          <B.ButtonGroup vertical>
            <B.AnchorButton
              icon={'duplicate'}
              title="Add additional overview image page"
              className="map-btn"
              onClick={() => {
                // Find next cursor in featureData to add
                const featureDataIndex = coverFeatureData.findIndex(
                  (f) => f?.get('date') === reportOverviewPageState.cursor
                );
                const newCursor =
                  featureDataIndex + 1 < coverFeatureData.size
                    ? coverFeatureData.get(featureDataIndex + 1).get('date')
                    : coverFeatureData.get(featureDataIndex).get('date');

                const newOverviewPage = createNewOverviewPageWithDefaults(newCursor);

                // Insert new property overview page after current page
                const newPageRecords = [...reportState.overviewPages];
                const currOverviewPageIndex = newPageRecords.findIndex(
                  (overviewPage) => reportOverviewPageState.id === overviewPage.id
                );
                newPageRecords.splice(currOverviewPageIndex + 1, 0, newOverviewPage);

                // Scroll to new overviewPage
                focusedOverviewIdRef.current = `${newOverviewPage.id}`;

                // Update state
                setReportState([['overviewPages'], newPageRecords]);
              }}
            />
            <B.AnchorButton
              icon={'trash'}
              title="Remove this overview image page"
              disabled={
                reportState.overviewPages.filter(
                  (page) => page.currentPropertyPartIndex === currentPropertyPartIndex
                ).length === 1
              }
              className="map-btn"
              onClick={() => {
                setReportState([
                  ['overviewPages'],
                  reportState.overviewPages.filter(
                    (cursor) => reportOverviewPageState.id !== cursor.id
                  ),
                ]);
              }}
            />
          </B.ButtonGroup>
        </div>

        <div className="overview-page">
          <div className="title-and-lens-watermark">
            <h2
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => {
                const target = e.target as HTMLElement;
                setReportState([
                  ['overviewPages', reportOverviewPageIndex, 'title'],
                  target.innerText,
                ]);
              }}
            >
              {reportState.overviewPages[reportOverviewPageIndex].title}
            </h2>
            <div className="lens-watermark">
              <LensLogoSvg />
            </div>
          </div>

          <ReportExportFeatureMap
            firebaseToken={firebaseToken}
            readyStateReporter={areMapsReadyReporter}
            feature={featureJs}
            featureData={featureData}
            layerKey={imageRef.layerKey}
            cursor={imageRef.cursor!}
            widthInches={7.5}
            maxHeightInches={8}
            aspectRatio="auto"
            // We don’t show overlays on the overview image
            overlayFeatureCollections={[]}
            overlaySettings={overlaySettings}
            savedIsOverlayVisibleByName={{}}
            renderDateDropdown={() => (
              // Happily we’re not seeming to miss the Stylus styles for this
              // component.
              <List
                className="date-dropdown-list"
                itemHeight={50}
                items={coverFeatureData
                  .map(makeDatePickerItem(organization, imageRef.layerKey, imageRef.cursor!))
                  .toArray()}
                onClickItem={({cursor, layerKey}, ev) => {
                  const newOverviewPages = [...reportState.overviewPages];
                  newOverviewPages[reportOverviewPageIndex].imageRef = {
                    cursor: cursor,
                    layerKey: layerKey,
                  };
                  setReportState([['overviewPages'], newOverviewPages]);
                  ev.stopPropagation();
                }}
              />
            )}
            measurementSystem={getMeasurementSystem(organization)}
            noteGeometries={noteGeometries}
            saveMapState={(fieldToUpdate, update) =>
              setReportState([['overviewPages', reportOverviewPageIndex, fieldToUpdate], update])
            }
            savedShowNotePoints={reportOverviewPageState.showNotePoints}
            initialShowScale={reportOverviewPageState.showMapScale}
          />

          <div className="property-overview-info">
            <div className="property-overview-image-details">
              {coverFeatureDatum && coverSourceDetails && (
                <ImageSourceInfo
                  datum={coverFeatureDatum}
                  sourceDetails={coverSourceDetails}
                  layer={layerUtils.getLayer(layer)}
                  layerKey={layer}
                  imageTitle={'Image'}
                />
              )}
            </div>

            {isCombinedMultiPart && (
              <LocationSvg
                feature={combinedPartFeature}
                activeGeometry={combinedPartGeometries[currentPropertyPartIndex]}
                activeGeometryStyle={{fill: 'rgba(0,76,92,0.3)', stroke: '#004C5C'}}
                height={1.5 * 96}
                width={3 * 96}
                alignment="top-right"
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const makeDatePickerItem =
  (organization: I.ImmutableOf<ApiOrganization>, coverLayerKey: string, coverCursor: string) =>
  (
    d: I.MapAsRecord<I.ImmutableFields<ApiFeatureData>> | undefined
  ): ListItem<{
    cursor: string;
    layerKey: string;
  }> => {
    const source = d!.get('sources').first();
    const layer = layerUtils.parseLayerKey(coverLayerKey).layerId;
    const layerKeyForSource = layerUtils.getLayerKeyFromSourceAndLayer(source!, layer);

    const {
      sourceDetails: {name: sourceName, operator: operatorName, resolution: sourceResolution},
    } = featureUtils.imageAndSourceDetails(d!, layerKeyForSource, organization);

    return {
      cursor: d!.get('date'),
      layerKey: layerKeyForSource,
      isSelected: d!.get('date') === coverCursor && layerKeyForSource === coverLayerKey,
      text: (
        <>
          <div style={{fontWeight: 'bold'}}>
            {layerUtils.conditionallyAdjustLayerDate(d!.get('date'), layerKeyForSource).format('L')}
          </div>

          {/* Nearmap has an operatorName but not a sourceName */}
          {(sourceName || operatorName) && (
            <div>
              {operatorName} {sourceName}
              {sourceResolution && (
                <>
                  <span> (</span>
                  <span style={{fontWeight: 'bold'}}>{sourceResolution}m</span>
                  <span>)</span>
                </>
              )}
            </div>
          )}
        </>
      ),
    };
  };

/**
 * booleanContains does not support MultiPolygons. We adapt it here so that if
 * at least one part of a MultiPolygon is in the bounds then we return true.
 *
 * We also kinda assume it doesn't work for LineString, but we don’t have a test
 * of that. So we do the same check anyway.
 */
export function booleanContainsAtLeastOnePart(
  boundsFeature: geojson.Feature<geojson.Polygon, geojson.GeoJsonProperties>,
  f: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties>
): boolean {
  if (f.geometry.type === 'MultiPolygon') {
    return (
      f.geometry.coordinates
        .map((c) => geoJsonUtils.feature({type: 'Polygon', coordinates: c}, {}))
        .map((f) => booleanContains(boundsFeature, f))
        .filter((res) => res).length > 0
    );
  } else if (f.geometry.type === 'MultiLineString') {
    return (
      f.geometry.coordinates
        .map((c) => geoJsonUtils.feature({type: 'LineString', coordinates: c}, {}))
        .map((f) => booleanContains(boundsFeature, f))
        .filter((res) => res).length > 0
    );
  } else {
    return booleanContains(boundsFeature, f);
  }
}

export const MultiPartPropertyKeyPage: React.FunctionComponent<
  React.PropsWithChildren<{
    propertyParts: MultiFeaturePropertyParts;
  }>
> = ({propertyParts}) => {
  const [combinedFeature, geometries] = React.useMemo(
    () => combinePropertyParts(propertyParts),
    [propertyParts]
  );

  const labelGeometry: geojson.MultiPoint = {
    type: 'MultiPoint',
    coordinates: geometries.map((g) => centerOfMass(g).geometry.coordinates),
  };

  const labelText = propertyParts.map((p) => p.partName?.toString() || '');

  const activePropertyPartIndex = propertyParts.findIndex((p) => p.isActive);
  const activeGeometry =
    activePropertyPartIndex >= 0 ? geometries[activePropertyPartIndex] : undefined;

  return (
    <section>
      <div className="title-and-lens-watermark">
        <h2>Property Overview</h2>
        <div className="lens-watermark">
          <LensLogoSvg />
        </div>
      </div>
      <LocationSvg
        feature={combinedFeature}
        labelGeometry={labelGeometry}
        labelText={labelText}
        activeGeometry={activeGeometry}
        activeGeometryStyle={{fill: 'rgba(0,76,92,0.3)', stroke: '#004C5C'}}
        height={9 * 96}
        width={7.5 * 96}
      />
    </section>
  );
};

/**
 * Takes MultiFeaturePropertyParts and returns an array of its geometfalseries and a
 * MultiPolygon feature made up of its individual parts.
 */
function combinePropertyParts(
  propertyParts: MultiFeaturePropertyParts
): [geojson.Feature<geojson.MultiPolygon, {}>, (geojson.Polygon | geojson.MultiPolygon)[]] {
  const geometries = propertyParts.map<geojson.Polygon | geojson.MultiPolygon>(
    (p) => p.feature.get('geometry').toJS() as geojson.Polygon | geojson.MultiPolygon
  );

  const combinedGeometry: geojson.MultiPolygon = {
    type: 'MultiPolygon',
    coordinates: geometries.reduce(
      (acc: geojson.Position[][][], geometry) =>
        geometry.type === 'Polygon'
          ? [...acc, geometry.coordinates]
          : [...acc, ...geometry.coordinates],
      []
    ),
  };

  const combinedFeature = geoJsonUtils.feature(combinedGeometry, {});

  return [combinedFeature, geometries];
}
