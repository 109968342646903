import mapboxgl from 'mapbox-gl';
import React from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as mapUtils from 'app/utils/mapUtils';

import MapCursorProvider, {CursorType} from './MapCursorProvider';
import cs from './styles.styl';

mapboxgl.accessToken = mapUtils.MAPBOX_ACCESS_TOKEN;

export type MapControlPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

export interface Props {
  firebaseToken: string | null;

  className: string;
  mapOptions: Partial<mapboxgl.MapboxOptions>;
  controlPosition: MapControlPosition;
  controlPadding?: mapboxgl.PaddingOptions;
  globalCursor?: CursorType | undefined;

  // callbacks
  onMapInitialize: (map: mapboxgl.Map) => unknown;
  onMapLoad: (map: mapboxgl.Map) => unknown;
  onMapClick: (ev: mapboxgl.MapMouseEvent, map: mapboxgl.Map) => unknown;
  onMapRender: (map: mapboxgl.Map) => unknown;
  onMapMoved: (
    ev: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>,
    map: mapboxgl.Map
  ) => unknown;
  /** Fires every time the map reaches a steady state where its tiles are
   * loaded. Will fire repeatedly during the loading process. */
  onMapTilesLoaded: (map: mapboxgl.Map) => unknown;

  children: (map: mapboxgl.Map, isMapLoaded: boolean) => React.ReactNode;

  hideNavigationControl?: boolean;
}

interface State {
  // map is kept in state because we use it to conditionally render the children
  // function.
  map: mapboxgl.Map | null;
  isMapLoaded: boolean;
}

export default class MapComponent extends React.Component<Props, State> {
  static defaultProps: Omit<Props, 'firebaseToken'> = {
    children: () => null,
    className: cs.map,
    controlPosition: 'top-left',
    mapOptions: {},
    onMapInitialize: () => {},
    onMapLoad: () => {},
    onMapClick: () => {},
    onMapRender: () => {},
    onMapMoved: () => {},
    onMapTilesLoaded: () => {},
  };

  state: State = {
    map: null,
    isMapLoaded: false,
  };

  private transformMapRequest: mapboxgl.TransformRequestFunction = (url: string) => {
    const {firebaseToken} = this.props;
    url = mapUtils.formatTileUrl(url, firebaseToken);
    return {
      url,
    };
  };

  createMap = (el: HTMLDivElement | null) => {
    const {mapOptions, onMapInitialize, hideNavigationControl, controlPosition, className} =
      this.props;

    if (!el) {
      return;
    }

    // We need to make an element with document.createElement because mapboxgl’s
    // "instanceof" check for its container fails on Chrome when the container
    // comes from a separate window from where the JS is running. (This seems to
    // be because Chrome’s HTMLElement classes are distinct across windows.)
    //
    // This means we can’t rely on React to create the DOM node that Mapbox
    // attaches to, but have to create a new element using the JS window’s
    // document.
    const mapContainerEl = document.createElement('div');
    mapContainerEl.className = className;

    el.insertAdjacentElement('afterend', mapContainerEl);

    const map = new mapboxgl.Map({
      container: mapContainerEl,
      attributionControl: false,
      keyboard: false,
      trackResize: true,
      transformRequest: this.transformMapRequest,
      style: mapUtils.getMapboxUri(mapUtils.MAPBOX_SATELLITE_STREETS_STYLE),
      ...mapOptions,
    });

    if (!hideNavigationControl) {
      map.addControl(new mapboxgl.NavigationControl({showCompass: false}), controlPosition);
    }

    // These will be undefined when Jest is running Storyshots
    map.dragRotate && map.dragRotate.disable();
    map.touchZoomRotate && map.touchZoomRotate.disableRotation();

    map.on('click', this.onMapClick);
    map.on('load', this.onMapLoad);
    map.on('render', this.onMapRender);
    map.on('moveend', this.onMapMoved);
    map.on('sourcedata', this.onMapTilesLoaded);

    this.setState({map}, () => {
      onMapInitialize(map);
    });
  };

  componentWillUnmount() {
    const {map} = this.state;

    if (map) {
      map.off('load', this.onMapLoad);
      map.off('click', this.onMapClick);
      map.off('render', this.onMapRender);
      map.off('sourcedata', this.onMapTilesLoaded);

      // Need to clear our element out of the DOM since React won’t necessarily
      // do it for us.
      map.getContainer().remove();

      // We do the remove on a setTimeout because componentWillUnmount is called
      // before our childrens’ componentWillUnmount. We want our children to be
      // able to naïvely remove controls and such from the map in their own
      // componentWillUnmount methods, but calling removeControl on a removed
      // map leads to an exception.
      window.setTimeout(() => map.remove(), 0);
    }
  }

  componentDidUpdate() {
    const {controlPadding} = this.props;
    const {map, isMapLoaded} = this.state;

    if (map && isMapLoaded && controlPadding) {
      mapUtils.applyPaddingToMapboxControls(
        map.getContainer().getElementsByClassName('mapboxgl-control-container')[0] as
          | HTMLDivElement
          | undefined,
        controlPadding
      );
    }
  }

  render() {
    const {children, globalCursor} = this.props;
    const {map, isMapLoaded} = this.state;

    if (map) {
      map.on('click', (e) => {
        try {
          // We can't use ctrl + click because that's a right click on MacOS.
          if (e.originalEvent.altKey) {
            navigator.clipboard.writeText(`${e.lngLat.lat}, ${e.lngLat.lng}`);
          }
        } catch (_) {
          // Do nothing for now. Add more robust handling later.
        }
      });
    }

    return (
      <>
        {/* We use this element to know where in the DOM to insert our map.
            See createMap for info about why we can’t use a React-created
            <div> as the container. */}
        {!map && <div style={{display: 'none'}} ref={this.createMap} />}

        {map && (
          // The children of <DeclarativeMap> mostly don’t render child DOM
          // nodes, just React component that interact with the map instance, so
          // this part doesn’t need to be in the mapbox DOM. (If they’re
          // controls, their rendering is usually into mapbox.Control elements
          // via portals.)
          //
          // One exception is the measure tool, so that has to position relative
          // to the map’s parent, not the map container itself.
          <MapCursorProvider map={map} isMapLoaded={isMapLoaded} globalCursor={globalCursor}>
            {children(map, isMapLoaded)}
          </MapCursorProvider>
        )}
      </>
    );
  }

  private onMapLoad = async (ev: mapboxgl.MapboxEvent) => {
    const {onMapLoad} = this.props;

    // We pull the map off of the event because the value of this.state.map
    // cannot be guaranteed depending on the timing of setState and the loading
    // event firing.
    const map = ev.target;

    // We need the icons completely loaded and added to the map before we can
    // use them in styles, otherwise particular zoom levels straight won’t have
    // them. See: https://github.com/mapbox/mapbox-gl-js/issues/6231
    await mapUtils.loadIconImages(map);

    this.setState({isMapLoaded: true}, () => {
      onMapLoad(map);
    });
  };

  private onMapClick = (event: mapboxgl.MapMouseEvent) => {
    const {onMapClick} = this.props;
    onMapClick(event, event.target);
  };

  private onMapMoved = (
    event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>
  ) => {
    const {onMapMoved} = this.props;
    onMapMoved(event, event.target);
  };

  private onMapRender = (ev) => {
    const {onMapRender} = this.props;

    // We pull the map off of the event because the value of this.state.map
    // cannot be guaranteed depending on the timing of setState and the loading
    // event firing.
    const map = ev.target;

    onMapRender(map);
  };

  private onMapTilesLoaded = (ev) => {
    const {onMapTilesLoaded} = this.props;

    // We pull the map off of the event because the value of this.state.map
    // cannot be guaranteed depending on the timing of setState and the loading
    // event firing.
    const map = ev.target;

    if (map.areTilesLoaded()) {
      onMapTilesLoaded(map);
    }
  };
}
