import React from 'react';

import {CarouselSlider, SliderSettings} from 'app/components/CarouselSlider';

import cs from './LensLibraryView.styl';

// NOTE: these breakpoint values correspond to browser width values. If any of the
// spacing on the page changes, these may need to change too.
const SETTINGS_MODE_BREAKPOINT_LOWEST_VALUE = 1350;
const SETTINGS_MODE_BREAKPOINT_INCREMENT = 300;
export const SETTINGS_MODE_BREAKPOINTS = [
  {
    breakpoint: SETTINGS_MODE_BREAKPOINT_LOWEST_VALUE + SETTINGS_MODE_BREAKPOINT_INCREMENT * 3,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: SETTINGS_MODE_BREAKPOINT_LOWEST_VALUE + SETTINGS_MODE_BREAKPOINT_INCREMENT * 2,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: SETTINGS_MODE_BREAKPOINT_LOWEST_VALUE + SETTINGS_MODE_BREAKPOINT_INCREMENT,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: SETTINGS_MODE_BREAKPOINT_LOWEST_VALUE,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
];

export const Swimlane: React.FunctionComponent<
  React.PropsWithChildren<{
    header: string;
    sliderSettings?: SliderSettings;
    removeTopPadding?: boolean;
  }>
> = ({header, sliderSettings, removeTopPadding = false, children}) => {
  return (
    <div className={cs.swimlane} style={removeTopPadding ? {padding: 0} : {}}>
      <h2 className={cs.swimlaneHeader}>{header}</h2>
      <CarouselSlider
        settings={{
          slidesToShow: 7,
          slidesToScroll: 7,
          responsive: SETTINGS_MODE_BREAKPOINTS,
          ...sliderSettings,
        }}
      >
        {children}
      </CarouselSlider>
    </div>
  );
};
