import React from 'react';
import {Route, Switch} from 'react-router-dom';

import NotFound from './notFound';
import ServerError from './serverError';
import classNames from './styles.styl';

export default () => (
  <div className={classNames.error}>
    <Switch>
      <Route path="/error/server" render={() => <ServerError />} />
      <Route path="/error/notfound" render={() => <NotFound />} />
    </Switch>
  </div>
);
