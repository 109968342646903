import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as geojson from 'geojson';
import mapboxgl from 'mapbox-gl';
import DrawRectangleMode from 'mapbox-gl-draw-rectangle-mode';
import React from 'react';

import {useStateWithDeps} from 'app/utils/hookUtils';
import {BlankDrawMode, useDisableClickableFeatures} from 'app/utils/mapUtils';
import {TxCenter, TxRectMode} from 'app/vendor/mapbox-gl-draw-rotate-scale-rect-mode';

import {useMap} from './MapContent';

export type DrawingMode = 'draw_polygon' | 'draw_rectangle' | 'draw_line_string' | 'simple_select';

const EDIT_RECTANGLE_OPTIONS = {scaleCenter: TxCenter.Opposite, canRotate: false};

/**
 * Adds a MapboxDraw layer to the map. Optimized for adding a single feature,
 * such as a polygon.
 */
const DrawLayer: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    visible: boolean;
    drawingMode: DrawingMode;
    /**
     * "Feature" in this context is the drawn GeoJSON feature, not a property
     * monitoring feature.
     */
    feature: geojson.Feature | null;
    setFeature: (f: geojson.Feature | null) => void;

    /**
     * Called after each render instance with the feature currently being drawn or
     * edited.
     */
    onRenderFeature?: (f: geojson.Feature | null) => void;

    /**
     * Called when the user is done drawing a feature or has clicked off of it
     * while it was direct-selected.
     */
    onDeselectFeature?: () => void;
    editRectangle?: boolean;
    /**
     * Deselect the shape after it is drawn. This makes the line turn solid
     * and it can't be edited until clicked again. The default is for a completed
     * shape to be in simple_select mode
     */
    deselectOnCreation?: boolean;
    /**
     * Deselect the shape after it is drawn or changed. This makes the line turn
     * solid and it can't be edited until clicked again. The default is for a
     * completed shape to be in simple_select mode
     */
    deselectOnFeatureUpdate?: boolean;
  }>
> = ({
  map,
  isMapLoaded,
  visible,
  drawingMode,
  feature,
  setFeature,
  onDeselectFeature,
  onRenderFeature,
  editRectangle = false,
  deselectOnCreation = false,
  deselectOnFeatureUpdate = false,
}) => {
  const drawRef = useDrawRef(map, isMapLoaded, visible);

  const currentDrawingMode = useDrawEvents({
    map,
    drawRef,
    drawingMode,
    feature,
    editRectangle,
    deselectOnCreation,
    setFeature,
    onDeselectFeature,
    onRenderFeature,
  });
  useFeatureInDrawControl(
    map,
    drawRef,
    drawingMode,
    feature,
    editRectangle,
    deselectOnCreation,
    deselectOnFeatureUpdate
  );

  useMapKeyboardEvents(map, visible, setFeature, onDeselectFeature);

  // We disable other clickable features if the user is actively putting points
  // down to make a shape. If we’re just visible (e.g. analyze area after the
  // polygon has been drawn) we don’t prevent clicking on other map features.
  useDisableClickableFeatures(
    visible &&
      (currentDrawingMode === 'draw_polygon' ||
        currentDrawingMode === 'draw_rectangle' ||
        currentDrawingMode === 'draw_line_string')
  );

  return null;
};

/**
 * Adds the MapboxDraw control to the MapboxGL map if we’re visible. The return
 * value’s "current" property will be set to null once the control leaves the
 * map, since MapboxDraw throws exceptions if you try to use it after it’s been
 * removed.
 *
 * Note that, unlike useRef, the return value of this function will change to a
 * new object when the MapboxDraw component changes, so it’s safe to use as a
 * dependency in useEffect calls.
 */
function useDrawRef(map: mapboxgl.Map, isMapLoaded: boolean, visible: boolean) {
  const [drawRef, setDrawRef] = React.useState<{current: MapboxDraw | null} | null>(null);

  useMap(
    (map) => {
      if (!visible) {
        // MapboxDraw controls collide if there’s more than one on the map, so
        // we only render a visible one.
        return;
      }

      const draw = new MapboxDraw({
        controls: {},
        displayControlsDefault: false,
        defaultMode: 'blank',
        modes: {
          draw_rectangle: DrawRectangleMode,
          edit_rectangle: TxRectMode,
          blank: BlankDrawMode,
          ...MapboxDraw.modes,
        },
      });

      map.addControl(draw);

      const ourDrawRef = {current: draw};
      setDrawRef(ourDrawRef);

      return () => {
        map.removeControl(draw);
        // Anyone who has closed over this "drawRef" instance will now know it’s
        // stale.
        ourDrawRef.current = null;
        setDrawRef(null);
      };
    },
    [visible, setDrawRef],
    {map, isMapLoaded}
  );

  return drawRef;
}

/**
 * Listens to MapboxDraw events on the Map and calls setFeature and
 * onDeselectFeature in response.
 */
function useDrawEvents({
  map,
  drawRef,
  drawingMode,
  feature,
  editRectangle,
  deselectOnCreation,
  setFeature,
  onDeselectFeature,
  onRenderFeature,
}: {
  map: mapboxgl.Map;
  drawRef: {
    current: MapboxDraw | null;
  } | null;
  drawingMode: string;
  feature: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties> | null;
  editRectangle: boolean;
  deselectOnCreation: boolean;
  setFeature: (f: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties> | null) => void;
  onDeselectFeature: (() => void) | undefined;
  onRenderFeature: ((f: geojson.Feature | null) => void) | undefined;
}) {
  const [currentDrawingMode, setCurrentDrawingMode] = useStateWithDeps(drawingMode, [drawRef]);

  React.useEffect(() => {
    // We don’t actually call any MapboxDraw functions so we don’t need to gate
    // on drawRef.current. We still only listen when our own drawRef is on the
    // map, though, to avoid overhearing events from other MapboxDraw controls.
    // (MapboxDraw fires its events through the Map object.)
    if (!drawRef) {
      return;
    }

    // All of these event handers go through setTimeout() so that the Mapbox
    // Draw routines can run to completion before our code that reacts to
    // changes can run.
    const onDrawModeChange = (ev) => {
      const {mode} = ev;

      setCurrentDrawingMode(mode);
      if (mode === 'simple_select') {
        // Going to 'simple_select' signals that the user has done some UI thing
        // (clicking away, pressing enter) that got the map to stop editing.
        if (onDeselectFeature) {
          setTimeout(onDeselectFeature, 0);
        } else if (drawRef && drawRef.current && !feature) {
          // Without this, the user would get trapped in "simple_select" mode
          // and not be able to draw new things.
          drawRef.current.changeMode(drawingMode);
        } // This is necessary to prevent you from editing a rectangle like a
        // polygon but also prevents you from ever clicking away from the edit mode
        else if (drawRef && drawRef.current && feature && editRectangle) {
          drawRef.current.changeMode('edit_rectangle', {
            featureId: feature.id,
            ...EDIT_RECTANGLE_OPTIONS,
          });
        }
      }
    };

    const onDrawCreateOrChange = (ev) => {
      setTimeout(() => setFeature(ev.features[0] || null), 0);
    };

    const onDrawCreate = (ev) => {
      onDrawCreateOrChange(ev);
      if (deselectOnCreation) {
        // There is no deselect function, so a workaround is to switch to
        // simple_select mode with no features selected
        setTimeout(() => drawRef.current.changeMode('simple_select', {}), 0);
      }
    };

    // This callback only happens if mapbox-gl-draw is rendering its own delete
    // button. Deletion via the keyboard is handled through
    // useMapKeyboardEvents.
    const onDrawDelete = (ev) => {
      setTimeout(
        // Only update to delete the feature if the deleted feature is the one
        // we are manging.
        () => {
          if (ev.features.find(({id}) => id === feature?.id)) {
            setFeature(null);
            // If the feature being edited has been deleted, we treat that as
            // "deselecting" to trigger the same "editing done" behavior as
            // other interactions.
            onDeselectFeature?.();
          }
        },
        0
      );
    };

    // This is in the render path so we only define it if it’s actually wanted,
    // rather than having callbacks firing on a no-op.
    const onDrawRender = onRenderFeature
      ? () => {
          const fc = drawRef.current.getAll();
          const f = fc.features[0];

          if (f) {
            setTimeout(() => onRenderFeature(f), 0);
          }
        }
      : null;

    map.on('draw.modechange', onDrawModeChange);
    map.on('draw.create', onDrawCreate);
    map.on('draw.update', onDrawCreateOrChange);
    map.on('draw.delete', onDrawDelete);

    if (onDrawRender) {
      map.on('draw.render', onDrawRender);
    }

    return () => {
      map.off('draw.modechange', onDrawModeChange);
      map.off('draw.create', onDrawCreate);
      map.off('draw.update', onDrawCreateOrChange);
      map.off('draw.delete', onDrawDelete);

      if (onDrawRender) {
        map.off('draw.render', onDrawRender);
      }
    };
  }, [
    map,
    drawRef,
    drawingMode,
    setCurrentDrawingMode,
    feature,
    setFeature,
    onDeselectFeature,
    onRenderFeature,
    editRectangle,
    deselectOnCreation,
  ]);

  return currentDrawingMode;
}

/**
 * Updates the draw control to match the feature from props.
 */
function useFeatureInDrawControl(
  map: mapboxgl.Map,
  drawRef: {current: MapboxDraw | null} | null,
  drawingMode: string,
  feature: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties> | null,
  editRectangle: boolean,
  deselectOnCreation: boolean,
  deselectOnFeatureUpdate: boolean
) {
  const prevFeatureRef = React.useRef<geojson.Feature<
    geojson.Geometry,
    geojson.GeoJsonProperties
  > | null>(null);

  React.useEffect(() => {
    if (!drawRef || !drawRef.current) {
      return;
    }
    const prevFeature = prevFeatureRef.current;
    prevFeatureRef.current = feature;

    if (feature) {
      drawRef.current.add(feature);

      if (editRectangle) {
        drawRef.current.changeMode('edit_rectangle', {
          featureId: feature.id,
          ...EDIT_RECTANGLE_OPTIONS,
        });
      }
      // Using a reference to the previous feature to determine
      // if this was an edit to a feature or a creation of a new feature.
      // We only want to deselect if it was the inital creation to prevent
      // the feature from deselecting mid edit
      else if (deselectOnCreation && !prevFeature) {
        // There is no deselect function, so a workaround is to switch to
        // simple_select mode with no features selected
        drawRef.current.changeMode('simple_select', {});
      } else if (deselectOnFeatureUpdate) {
        drawRef.current.changeMode('simple_select', {});
      } else {
        drawRef.current.changeMode('direct_select', {
          featureId: feature.id,
        });
      }
    } else {
      drawRef.current.changeMode(drawingMode);
    }

    return () => {
      // Turn off the warning that says that drawRef.current may have changed,
      // because we’re ok with it changing. That’s kinda the point, actually.
      //

      feature && drawRef.current && drawRef.current.delete(feature.id);
    };
  }, [
    map,
    drawRef,
    feature,
    drawingMode,
    editRectangle,
    deselectOnCreation,
    deselectOnFeatureUpdate,
  ]);
}

/**
 * Listens for keyboard events on the map when we’re visible.
 *
 * In particular, deletes the feature when the "delete" key is pressed.
 */
function useMapKeyboardEvents(
  map: mapboxgl.Map,
  visible: boolean,
  setFeature: (f: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties> | null) => void,
  onDeselectFeature: (() => void) | undefined
) {
  React.useEffect(() => {
    if (!visible) {
      return;
    }

    // Removes the feature if the user presses "backspace"
    const onMapKeyUp = (ev: KeyboardEvent) => {
      if (ev.key === 'Backspace') {
        setFeature(null);
        // If the feature being edited has been deleted, we treat that as
        // "deselecting" to trigger the same "editing done" behavior as
        // other interactions.
        onDeselectFeature?.();
        ev.stopPropagation();
        ev.preventDefault();
      }
    };

    map.getContainer().addEventListener('keydown', onMapKeyUp);

    return () => {
      map.getContainer().removeEventListener('keydown', onMapKeyUp);
    };
  }, [map, visible, setFeature, onDeselectFeature]);
}

export default DrawLayer;
