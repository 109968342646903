import * as B from '@blueprintjs/core';
import React from 'react';

import colors from 'app/utils/colorUtils';
import {GradientStops} from 'app/utils/gradientStops';
import * as layers from 'app/utils/layers';

import * as cs from './styles.styl';
import {DataRange} from '../AnalyzePolygonChart/types';

interface ComboSliderProps {
  onChangeValue: (thresholdValue: number) => void;
  value: number;
  min;
  max;
  labels: [string, string];
  gradientStops?: GradientStops;
  step?: number;
}

interface ThresholdSliderProps {
  layer: layers.DataLayerInfo;
  onChangeValue: (thresholdValue: number) => void;
  thresholdValue: number;
}

const DEFAULT_GRADIENT_STOPS: GradientStops = [
  [0, colors.brightBlue],
  [1, colors.lightGray],
];

const getDefaultStep = (min: number, max: number) => {
  // Calculate the order of magnitude of the range
  const range = max - min;
  const orderOfMagnitude = Math.floor(Math.log10(range));
  // Calculate the step as a power of ten based on the order of magnitude
  let step = Math.pow(10, orderOfMagnitude - 1);

  // Adjust step by 1 level if it's too large or too small based on the range
  if (range / step > 100) {
    step *= 10;
  } else if (range / step < 10) {
    step /= 10;
  }
  return step;
};

/** A custom slider built on range and number html inputs */
export const ComboSlider = ({
  min,
  max,
  onChangeValue,
  value,
  labels,
  gradientStops = DEFAULT_GRADIENT_STOPS,
  step = getDefaultStep(min, max),
}: ComboSliderProps) => {
  return (
    <div className={cs.sliderContainer}>
      <div className={cs.comboSlider}>
        <input
          className={B.Classes.INPUT}
          type="number"
          min={min}
          max={max}
          value={value}
          onChange={(ev) => {
            // Clamp value to range
            // Handles the case where a user types a value into the input that is outside the range
            const newValue = Math.min(Math.max(Number(ev.currentTarget.value), min), max);
            onChangeValue(newValue);
          }}
          step={step}
        ></input>
        <input
          //inline styles so we can dynamically generate the gradient background for our slider
          style={{
            background: `linear-gradient(to left, ${gradientStops.map((stop) => stop[1], stop[0])})`,
            width: '100%',
          }}
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={(ev) => {
            onChangeValue(Number(ev.currentTarget.value));
          }}
          step={step}
        ></input>
        <div className={cs.sliderLabels}>
          {labels.map((label) => (
            <div key={label}>{label}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

/** A custom slider built on range and number html inputs so we can render a gradient as the slider background */
export const ThresholdSlider = ({layer, onChangeValue, thresholdValue}: ThresholdSliderProps) => {
  const [dataRangeMin, dataRangeMax]: DataRange = layer.dataRange;
  const labels: layers.DataLabels | undefined = layer.dataLabels;

  const formattedLabels: [string, string] = React.useMemo(() => {
    let finalLabel1: string = dataRangeMin.toString();
    let finalLabel2: string = dataRangeMax.toString();

    // if we have labels, display them along with the min and max if they're not redundant!
    // assume not redundant if both labels don't have at least 1 exact number match to the mins.
    if (labels && layer.key) {
      if (
        labels[0].match(/[0-9]+/)?.toString() !== dataRangeMin.toString() ||
        labels[1].match(/[0-9]+/)?.toString() !== dataRangeMax.toString()
      ) {
        finalLabel1 = `${labels[0]} (${dataRangeMin.toString()})`;
        finalLabel2 = `${labels[1]} (${dataRangeMax.toString()})`;
      } else {
        finalLabel1 = labels[0];
        finalLabel2 = labels[1];
      }
    }
    return [finalLabel1, finalLabel2];
  }, [labels, layer.key, layer.dataRange]);

  const gradientStops: readonly (readonly [number, string])[] | (string | number)[][] =
    React.useMemo(() => {
      // the colors for Planet Forest Carbon would be misleading here (because the colors represent
      // relative values on the map, so the dataMax doesn't always correspond to the gradient's max)
      // so using a solid blue line.
      // TODO(eva) if more layers start having a case like this where the gradient would be misleading
      // in this context, we should probably store that info with the layerInfo.
      return layer.key === layers.PLANET_FOREST_ABOVEGROUND_CARBON
        ? [
            [0, colors.brightBlue],
            [100, colors.brightBlue],
          ]
        : layer.gradientStops;
    }, [layer]);

  return (
    <ComboSlider
      labels={formattedLabels}
      min={dataRangeMin}
      max={dataRangeMax}
      onChangeValue={onChangeValue}
      value={thresholdValue}
      gradientStops={gradientStops}
    />
  );
};
