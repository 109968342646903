import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import * as featureUtils from 'app/utils/featureUtils';
import {ANY_TRUECOLOR_HIGH_RES} from 'app/utils/layers';
import {isLayerKeyHighResTruecolor} from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {FeatureSelection, changeSelection} from '../ProjectDashboardSwitch';
import {MapStateDispatch} from './MapStateProvider';

const OFFSET_NEXT = 1;
const OFFSET_PREVIOUS = -1;

const Hotkeys: React.FunctionComponent<
  React.PropsWithChildren<{
    organizationId: string;
    selectedProjectId: string;
    selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
    selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;
    imageRefs: mapUtils.MapImageRefs;
    featureData: I.ImmutableOf<ApiFeatureData[]> | null;
    mapStateDispatch: MapStateDispatch;
    features: I.ImmutableOf<ApiFeature[]>;
  }>
> = ({
  organizationId,
  selectedProjectId,
  selectedFeatureCollection,
  selectedFeatures,
  imageRefs,
  mapStateDispatch: setMapState,
  featureData,
  features,
}) => {
  const [toggledImageRefs, setToggledImageRefs] = React.useState<mapUtils.MapImageRefs | undefined>(
    undefined
  );

  const changeImageRef = React.useCallback(
    (nextImageRefs: mapUtils.MapImageRef | undefined) => {
      if (!nextImageRefs) {
        return;
      }
      setMapState({type: 'SET_IMAGE_REFS', imageRefs: [nextImageRefs]});
    },
    [setMapState]
  );

  const toggleTruecolor = React.useCallback(
    (activeLayerKey: string, imageRefs: mapUtils.MapImageRefs) => {
      // we do not want to swap to Truecolor if they are already viewing Truecolor
      if (!activeLayerKey.includes('high-res-truecolor')) {
        // store the imageRefs the user was previously viewing
        setToggledImageRefs(imageRefs);
        // toggle truecolor ON
        setMapState({type: 'SET_LAYER', layerKey: ANY_TRUECOLOR_HIGH_RES});
      } else if (toggledImageRefs) {
        // toggle truecolor OFF
        setMapState({
          type: 'SET_IMAGE_REFS',
          imageRefs: toggledImageRefs,
        });
        // reset stored imageRefs to undefined when toggle set to "truecolor off", otherwise
        // if user changes maps when in "ON" mode, we get the wrong map when they toggle back.
        setToggledImageRefs(undefined);
      }
    },
    [toggledImageRefs]
  );

  const activeLayerKey = React.useMemo(() => imageRefs[0].layerKey, [imageRefs[0].layerKey]);
  const currentCursor = React.useMemo(() => imageRefs[0].cursor, [imageRefs[0].cursor]);
  const cursorKey = featureUtils.getCursorKeyForLayerKey(selectedFeatureCollection, activeLayerKey);

  const processedFeatureData = React.useMemo(
    () => featureUtils.getProcessedFeatureData(featureData || I.List([]), activeLayerKey),
    [featureData, activeLayerKey]
  );

  const unrolledFeatureData = React.useMemo(
    () => featureUtils.unrollFeatureDataBySource(processedFeatureData, activeLayerKey),
    [processedFeatureData, activeLayerKey]
  );

  const findHighResTruecolor = isLayerKeyHighResTruecolor(activeLayerKey);

  // construct the list of imageRefs we have available in the dropdown so we can nav between them
  const imageRefsList: I.List<mapUtils.MapImageRef> = React.useMemo(
    () =>
      I.List(
        // only include imageRefs that we have templateUrls for, otherwise we might
        // include scenes we don't have tiles for (including unordered scenes)
        unrolledFeatureData
          .toSeq()
          .filter(
            (d) =>
              d!.get('measurements') &&
              d!.getIn(['images', 'templateUrls']).size > 0 &&
              (findHighResTruecolor
                ? !!d!.get('types').find((t) => isLayerKeyHighResTruecolor(t!))
                : d!.get('types').includes(activeLayerKey))
          )
          .map((d) => {
            return {
              cursor: d!.get(cursorKey as any),
              //because we unrolled our featureData by source, we can assume the only types remaining on the
              //featureDatum are associated with the source (eg NAIP) and layer (eg high-res-truecolor).
              layerKey: d!.get('types').first(),
            };
          })
          .sortBy((imageRef) => imageRef!.cursor, featureUtils.alphanumericSort)
      ),
    [activeLayerKey, cursorKey, findHighResTruecolor, unrolledFeatureData]
  );

  const hotkeys: B.HotkeyConfig[] = [];

  if (selectedFeatures.size > 0) {
    // Sort here matches the sorting behavior in FeatureList
    const sortedFeatures = features
      .sortBy((f) => f!.getIn(['properties', 'name']), featureUtils.alphanumericSort)
      .toList();

    hotkeys.push(
      {
        global: true,
        combo: 'down',
        label: 'Select next property location',
        onKeyDown: () => {
          const selection: FeatureSelection = {
            organizationId,
            projectId: selectedProjectId,
          };

          if (selectedFeatures.size > 1) {
            selection.featureIds = I.Set([selectedFeatures.first().get('id')]);
          } else {
            const selectedFeatureIndex = sortedFeatures.findIndex(
              (f) => f!.get('id') === selectedFeatures.first().get('id')
            );
            const nextSelectedFeatureIndex = selectedFeatureIndex + 1;
            if (nextSelectedFeatureIndex >= sortedFeatures.size) {
              return;
            }

            const nextFeature = sortedFeatures.get(nextSelectedFeatureIndex);
            selection.featureIds = I.Set([nextFeature.getIn(['properties', 'lensId'])!]);
          }
          changeSelection(selection);
        },
      },
      {
        global: true,
        combo: 'up',
        label: 'Select previous property location',
        onKeyDown: () => {
          const selection: FeatureSelection = {
            organizationId,
            projectId: selectedProjectId,
          };

          if (selectedFeatures.size > 1) {
            selection.featureIds = I.Set([selectedFeatures.last().get('id')]);
          } else {
            const selectedFeatureIndex = sortedFeatures.findIndex(
              (f) => f!.get('id') === selectedFeatures.first().get('id')
            );
            const nextSelectedFeatureIndex = selectedFeatureIndex - 1;
            if (nextSelectedFeatureIndex < 0) {
              return;
            }

            const nextFeature = sortedFeatures.get(nextSelectedFeatureIndex);
            selection.featureIds = I.Set([nextFeature.getIn(['properties', 'lensId'])!]);
          }
          changeSelection(selection);
        },
      }
    );
  }

  if (
    selectedFeatureCollection &&
    selectedFeatures.size == 1 &&
    unrolledFeatureData.size &&
    imageRefs.length === 1 &&
    currentCursor !== null
  ) {
    const nextImageRef = featureUtils.getImageRefViaOffset(
      activeLayerKey,
      imageRefsList,
      currentCursor,
      OFFSET_NEXT
    );

    const prevImageRef = featureUtils.getImageRefViaOffset(
      activeLayerKey,
      imageRefsList,
      currentCursor,
      OFFSET_PREVIOUS
    );

    hotkeys.push(
      {
        global: true,
        combo: 'left',
        label: 'Select previous date for selected layer',
        onKeyDown: (ev) => {
          if (!ev.defaultPrevented) {
            changeImageRef(prevImageRef);
          }
        },
      },
      {
        global: true,
        combo: 'right',
        label: 'Select next date for selected layer',
        onKeyDown: (ev) => {
          if (!ev.defaultPrevented) {
            changeImageRef(nextImageRef);
          }
        },
      },
      {
        global: true,
        combo: 'shift + t',
        label: 'Toggle the most recent Truecolor image on/off',
        onKeyDown: () => toggleTruecolor(activeLayerKey, imageRefs),
      },
      {
        global: true,
        combo: 'alt + a',
        label: 'Open an overlay polygon in Analyze Area (press keys and click polygon to select)',
        // No "onKeyDown" here; this is simply letting users know about the already existing keyboard shortcut
        // built out in the GeometryOverlaysContent component. This is different from a regular Blueprint hotkey
        // because it requires handling clicks as well as keypresses.
      }
    );
  }

  hotkeys.push(
    {
      global: true,
      combo: 'alt',
      label: 'Show filled overlays (when zoomed in)',
    },
    {
      global: true,
      combo: 'alt + left click',
      label: 'Copy coordinate from map at cursor location',
    }
  );

  return (
    <B.HotkeysTarget2 hotkeys={hotkeys}>
      <></>
    </B.HotkeysTarget2>
  );
};

export default Hotkeys;
