import chroma from 'chroma-js';
import geojson from 'geojson';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {ApiFeatureData, FeatureDatumBounds} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {getDataRange} from 'app/providers/MapPolygonStateProvider';
import {pixelValueToDataValue} from 'app/stores/RasterCalculationStore';
import colors from 'app/styles/colors.json';
import * as colorUtils from 'app/utils/colorUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {useApiGet} from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';

import MapContent, {SourceRecord} from './MapContent';

const IMAGE_SOURCE_ID = 'FeatureRaster-image';

// "source" and "id" are omitted because raster sources have to have unique ids
// since they can’t be updated.
//
// Note on terminology: a "raster" layer can render "raster" (webtile), "image"
// (single image), and "canvas" sources.
const DEFAULT_RASTER_LAYER: Omit<mapboxgl.RasterLayer, 'id' | 'source'> = {
  type: 'raster',
  minzoom: 0,
  maxzoom: 22,
  paint: {
    // Disables the fade animation so you can more clearly observe changes when
    // switching back and forth between dates, e.g. with the arrow keys.
    'raster-fade-duration': 0,
    'raster-resampling': 'linear',
  },
};

/**
 * Returns a source and 2 layers to put a translucent background behind the
 * raster image and a white border around it. Used in the webtile, canvas, and
 * solid image cases.
 *
 * The "fill" layer should be rendered behind the image layer, and the "border"
 * layer should be rendered above it.
 */
function makeBoundsSourceAndLayers(
  idSuffix: string,
  bounds: FeatureDatumBounds | null
): {source: SourceRecord; fill: mapboxgl.FillLayer; border: mapboxgl.LineLayer} {
  let data: mapboxgl.GeoJSONSourceRaw['data'] = geoJsonUtils.featureCollection([]);
  if (Array.isArray(bounds)) {
    data = geoJsonUtils.bboxPolygon(bounds);
  } else if (bounds?.type == 'MultiPolygon' || bounds?.type == 'Polygon') {
    data = geoJsonUtils.feature(bounds, {});
  }
  const source = {
    id: `FeatureRaster-bounds-${idSuffix}`,
    source: {
      type: 'geojson' as const,
      data,
    },
  };

  const fill: mapboxgl.FillLayer = {
    id: `FeatureRaster-bounds-fill-${idSuffix}`,
    source: source.id,
    type: 'fill',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-outline-color': 'white',
      'fill-opacity': 0.5,
      'fill-color': 'black',
    },
  };

  const border: mapboxgl.LineLayer = {
    id: `FeatureRaster-bounds-line-${idSuffix}`,
    source: source.id,
    type: 'line',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'line-color': colors.darkestGray,
      'line-width': 2,
    },
  };

  return {source, fill, border};
}

export function getRasterSourceDefinition(
  featureDatum: I.MapAsRecord<I.ImmutableFields<ApiFeatureData>> | null,
  activeLayerKey: string
) {
  return featureDatum && featureDatum.getIn(['images', 'templateUrls', activeLayerKey]);
}

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  /** Pass an empty Immutable List if the feature data hasn’t loaded. */
  featureData: I.ImmutableOf<ApiFeatureData[]>;
  activeLayerKey: string;
  cursor: string | null;
  mask?: {image: ImageData; bounds: geojson.BBox} | null;
  featureCollection?: HydratedFeatureCollection;
}

const FeatureRaster: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  featureCollection,
  featureData,
  activeLayerKey,
  cursor,
  mask = null,
}) => {
  const cursorKey = featureUtils.getCursorKeyForLayerKey(featureCollection, activeLayerKey);

  // We need to aggressively type this to include null because Immutable’s
  // findLast won’t do it for us, even thought it’s a clear possible return
  // value.
  const featureDatum = React.useMemo<I.ImmutableOf<ApiFeatureData> | null>(
    () =>
      featureData.findLast(
        (d) => d!.get('types').includes(activeLayerKey) && d!.get(cursorKey) === cursor
      ) || null,
    [featureData, activeLayerKey, cursorKey, cursor]
  );

  // We need to consistently return the same MapContent so that it holds our
  // place in the layer stack. These sub-components will render sources and
  // layers in their MapContents based on whether they apply to the given
  // featureDatum.
  return (
    <>
      <RasterLayerMapContent
        map={map}
        isMapLoaded={isMapLoaded}
        featureDatum={featureDatum}
        activeLayerKey={activeLayerKey}
        cursorKey={cursorKey}
        mask={mask}
      />

      <ImageLayerMapContent
        map={map}
        isMapLoaded={isMapLoaded}
        featureDatum={featureDatum}
        activeLayerKey={activeLayerKey}
        mask={mask}
      />
    </>
  );
};

/**
 * Renders a tiling layer. If a mask is provided, such as the one returned by
 * the RasterCalculationStore’s generateTiledThresholdMask method, we create a
 * source from a canvas with the mask image data painted onto it. Otherwise, we
 * create a raster source that references the tile URL.
 */
const RasterLayerMapContent: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    featureDatum: I.ImmutableOf<ApiFeatureData> | null;
    cursorKey: keyof ApiFeatureData;
    activeLayerKey: string;
    mask: {image: ImageData; bounds: geojson.BBox} | null;
  }>
> = ({map, isMapLoaded, featureDatum, cursorKey, activeLayerKey, mask}) => {
  // Used to generate unique IDs for our canvas sources, since they can’t be
  // updated.
  const sourceIdSuffixRef = React.useRef(0);

  // useMemo because we don’t want to re-draw the canvas and increment the
  // masked layer’s source ID (which can cause issues with attempting to remove
  // sources that have not yet been attached to the map) if we don’t have to.
  const {bounds, overlaySource, overlayLayer} = React.useMemo(() => {
    let bounds: geojson.Polygon | geojson.MultiPolygon | geoJsonUtils.BBox2d | null = null;
    let overlaySource: SourceRecord | null = null;
    let overlayLayer: mapboxgl.RasterLayer | null = null;

    const sourceDefinition = getRasterSourceDefinition(featureDatum, activeLayerKey);
    const layerInfo = layerUtils.getLayer(activeLayerKey);

    if (sourceDefinition) {
      const featureId = featureDatum && featureDatum.getIn(['images', 'featureId']);
      const cursor = featureDatum && featureDatum.get(cursorKey);
      const id = `FeatureRaster-${featureId}-${cursor}-${activeLayerKey}`;

      bounds = featureDatum && featureUtils.getFeatureBoundsforSource(featureDatum, activeLayerKey);

      if (mask) {
        const canvas = document.createElement('canvas');
        canvas.width = mask.image.width;
        canvas.height = mask.image.height;

        const ctx = canvas.getContext('2d')!;
        ctx.imageSmoothingEnabled = false;
        ctx.putImageData(mask.image, 0, 0);

        // This rescales the layer’s gradient so we can use the byte values that
        // we read off of the image array.
        const colorScale =
          layerInfo.type === 'data' &&
          colorUtils.graphScaleFromGradientStops(layerInfo.gradientStops, [0, 255]);

        const dataRange = getDataRange(layerInfo);

        // For tiled sources, the mask.image is a mosaic of tiled raw images, so
        // we need to apply the layer’s color palette to it ourselves.

        // We work on a copy of the data rather than mutate mask.image directly.
        const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

        // imageData is an array where every 4 bytes is a pixel.
        for (let i = 0; i < imageData.data.length; i = i + 4) {
          // if alpha is 0 don’t bother to change
          if (imageData.data[i + 3] === 0) {
            continue;
          }

          // all channels are the same, so we look at red
          const pixelVal = imageData.data[i];

          let color;
          if (layerInfo.type === 'data' && colorScale) {
            // Subtract from 255 to reverse black / white since the raw layer is
            // built like that.
            color = colorScale(255 - pixelVal);
          } else {
            // category data
            const layerLegendMap = layerInfo.type === 'image' && layerInfo.layerLegendMap;
            const dataVal = pixelValueToDataValue(pixelVal, dataRange);
            const hexColor = layerLegendMap?.[dataVal]?.color || colors.black;

            color = chroma(hexColor);
          }
          const [r, g, b] = color.rgb();

          imageData.data[i] = r;
          imageData.data[i + 1] = g;
          imageData.data[i + 2] = b;
        }

        ctx.putImageData(imageData, 0, 0);

        // [minX, maxY], [maxX, maxY], [maxX, minY], [minX, minY]
        const box = [
          [mask.bounds[0], mask.bounds[3]],
          [mask.bounds[2], mask.bounds[3]],
          [mask.bounds[2], mask.bounds[1]],
          [mask.bounds[0], mask.bounds[1]],
        ];

        overlaySource = {
          id: `${id}-${sourceIdSuffixRef.current++}`,
          source: {
            type: 'canvas' as const,
            canvas,
            coordinates: box,
            animate: false,
          },
        };
      } else {
        overlaySource = {
          id: id,
          source: {
            ...sourceDefinition.toJS(),
            type: 'raster' as const,
            tileSize: 256,
          },
        };
      }

      overlayLayer = {
        ...DEFAULT_RASTER_LAYER,
        id,
        source: overlaySource.id,
      };

      if (overlayLayer?.paint) {
        if (layerInfo.resampling) {
          overlayLayer.paint = {...overlayLayer.paint, 'raster-resampling': layerInfo.resampling};
        } else if (layerInfo.type === 'data') {
          overlayLayer.paint = {...overlayLayer.paint, 'raster-resampling': 'nearest'};
        }
      }
    }

    return {bounds, overlaySource, overlayLayer};
  }, [activeLayerKey, cursorKey, featureDatum, mask]);

  const {
    source: boundsSource,
    fill: boundsFill,
    border: boundsBorder,
  } = makeBoundsSourceAndLayers('FeatureRaster-RasterLayerMapContent', bounds);

  return (
    <MapContent
      map={map}
      isMapLoaded={isMapLoaded}
      sources={overlaySource ? [boundsSource, overlaySource] : []}
      layers={overlaySource && overlayLayer ? [boundsFill, overlayLayer, boundsBorder] : []}
    />
  );
};

/**
 * Single image layer. Used to render our lower-res S2 layers. We add the entire
 * image to the map, rather than load in tiles based on zoom / area.
 *
 * This also handles dynamically masking the display using a canvas data source
 * for cases when the analyze area threshold tool is active.
 */
const ImageLayerMapContent: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    featureDatum: I.ImmutableOf<ApiFeatureData> | null;
    activeLayerKey: string;
    mask: {image: ImageData; bounds: geojson.BBox} | null;
  }>
> = ({map, isMapLoaded, featureDatum, activeLayerKey, mask}) => {
  const url = featureDatum && featureDatum.getIn(['images', 'urls', activeLayerKey]);

  // Used to generate unique IDs for our canvas sources, since they can’t be
  // updated.
  const sourceIdSuffixRef = React.useRef(0);

  // We need to download the raster to an <img> element so we can draw it into
  // the canvas.
  const [{value: image = null}] = useApiGet(
    async (url, hasMask) => {
      // We don’t bother fetching the image if there’s no mask to work with.
      if (!url || !hasMask) {
        return null;
      }

      return new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = url;
      });
    },
    [url, !!mask] as const
  );

  // useMemo because we don’t want to re-draw the canvas if we don’t have to.
  const {bounds, overlaySource, overlayLayer} = React.useMemo(() => {
    // We need to handle the case where we have both the single image URL as
    // well as the templated webtile URLs. If sourceDefinition exists, we won’t
    // add any layers here, since the layer display will be handled by
    // RasterLayerMapContent.
    //
    // (In practice, the "url" in this case will be pointing to the thumbnail
    // for the layer, which we definitely don’t want to render over the map.)
    const sourceDefinition =
      featureDatum && featureDatum.getIn(['images', 'templateUrls', activeLayerKey]);

    // Keeps us from showing "preview" thumbnails as full-screen images
    const isHighRes = featureDatum && featureUtils.getIsHighResTruecolor(featureDatum);

    // Mask image data for tiled raster data is handled via
    // RasterLayerMapContent.
    if (!url || sourceDefinition || isHighRes) {
      return {bounds: null, overlaySource: null, overlayLayer: null};
    }

    // Get the bounds for the feature datum for the selected source Id
    const imageBounds =
      featureDatum &&
      featureUtils.getBboxFromBounds(
        I.fromJS(featureUtils.getFeatureBoundsforSource(featureDatum, activeLayerKey))
      );

    // [smallest, largest] [largest largest] [largest smallest] [smallest smallest]
    const box = [
      [imageBounds[0], imageBounds[3]],
      [imageBounds[2], imageBounds[3]],
      [imageBounds[2], imageBounds[1]],
      [imageBounds[0], imageBounds[1]],
    ];

    const bounds = imageBounds;
    let overlaySource: SourceRecord | null = null;

    if (mask) {
      const canvas = document.createElement('canvas');

      // We render a blank canvas until the image has loaded.
      if (image) {
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d')!;
        ctx.putImageData(mask.image, 0, 0);
        ctx.globalCompositeOperation = 'source-in';
        ctx.drawImage(image, 0, 0);
      }

      overlaySource = {
        id: `${IMAGE_SOURCE_ID}-${sourceIdSuffixRef.current++}`,
        source: {
          type: 'canvas' as const,
          canvas,
          coordinates: box,
          animate: false,
        },
      };
    } else {
      overlaySource = {
        // We can use a constant ID since image sources can be updated
        id: IMAGE_SOURCE_ID,
        source: {
          type: 'image' as const,
          url,
          coordinates: box,
        },
      };
    }

    const overlayLayer = {
      ...DEFAULT_RASTER_LAYER,
      id: IMAGE_SOURCE_ID,
      source: overlaySource.id,
    };

    return {bounds, overlaySource, overlayLayer};
  }, [featureDatum, image, mask, url, activeLayerKey]);

  const {
    source: boundsSource,
    fill: boundsFill,
    border: boundsBorder,
  } = makeBoundsSourceAndLayers('FeatureRaster-ImageLayerMapContent', bounds);

  return (
    <MapContent
      map={map}
      isMapLoaded={isMapLoaded}
      sources={overlaySource ? [boundsSource, overlaySource] : []}
      layers={overlaySource && overlayLayer ? [boundsFill, overlayLayer, boundsBorder] : []}
    />
  );
};

export default FeatureRaster;
