import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React, {useMemo} from 'react';

import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {StateApiNote} from 'app/stores/NotesStore';
import * as tagUtils from 'app/utils/tagUtils';

import cs from './styles.styl';

export const Sorts: React.FunctionComponent<
  React.PropsWithChildren<{
    noteSortingMethodology: NoteSortingMethod;
    setNoteSortingMethodology: (method: NoteSortingMethod) => void;
  }>
> = ({noteSortingMethodology, setNoteSortingMethodology}) => {
  const options = useMemo(() => Object.keys(noteSorters) as NoteSortingMethod[], [noteSorters]);
  return (
    <>
      <div className={cs.popoverHeader}>
        <div className={cs.popoverHeaderTitle}>Sort</div>
      </div>
      <div className={cs.sortsList}>
        <B.RadioGroup
          selectedValue={noteSortingMethodology}
          onChange={(selection) => {
            setNoteSortingMethodology(selection.currentTarget.value as NoteSortingMethod);
          }}
        >
          {options.map((sortingMethod) => (
            <B.Radio
              key={sortingMethod}
              checked={sortingMethod === noteSortingMethodology}
              label={noteSorters[sortingMethod].displayName}
              value={sortingMethod}
            />
          ))}
        </B.RadioGroup>
      </div>
    </>
  );
};

export interface NoteSortDictEntry {
  sortFunction: NoteSortFn;
  displayName: string;
  icon?: B.IconName;
}

export type NoteSortingMethod = 'descending' | 'ascending' | 'tagOrder';

export type NoteSortFn = (args: {
  notes: StateApiNote[];
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;
}) => StateApiNote[];

/** Dictionary of note sorting methods and configurations */
export const noteSorters: Record<string, NoteSortDictEntry> = {
  descending: {
    sortFunction: ({notes}: {notes: StateApiNote[]}) => [...notes].reverse(),
    displayName: 'Newest to oldest',
    icon: 'sort-desc',
  },
  ascending: {
    sortFunction: ({notes}: {notes: StateApiNote[]}) => notes,
    displayName: 'Oldest to newest',
    icon: 'sort-asc',
  },
  tagOrder: {
    sortFunction: ({
      notes,
      featureCollection,
    }: {
      notes: StateApiNote[];
      featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    }) => {
      const tagOrder = tagUtils.getTagOrder(featureCollection);
      const sortedNotes = [...notes].sort((a, b) => tagUtils.compareTagRanks(a, b, tagOrder));
      return sortedNotes;
    },
    displayName: 'Note tag order',
  },
  // ...add additional note sorts here and in the NoteSortingMethod definition
};
