import * as B from '@blueprintjs/core';
import React from 'react';

import cs from './MapLegend.styl';

export interface LayerKey {
  color: string;
  name: string;
}

interface Props {
  layers: LayerKey[];
  className?: string;
}

const MapLegend: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  layers,
  className,
}) => (
  <B.Menu className={className}>
    {layers.map((layer) => (
      <B.MenuItem
        key={layer.name}
        text={layer.name}
        labelElement={<div className={cs.overlaySwatch} style={{backgroundColor: layer.color}} />}
      />
    ))}
  </B.Menu>
);

export default MapLegend;
