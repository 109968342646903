import * as I from 'immutable';
import React from 'react';

import {api} from 'app/modules/Remote';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {useApiGet} from 'app/utils/hookUtils';
import * as inviteUtils from 'app/utils/inviteUtils';

type SignupTokensValue = inviteUtils.SignupTokens | 'loading' | 'not allowed';

const SignupTokenContext = React.createContext<undefined | SignupTokensValue>(undefined);

/**
 * Small provider to load the signup tokens to display on the manage team page.
 *
 * Necessary seam so we can render ManageOrg in stories without making API
 * calls.
 */
const SignupTokenProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    organizationId: string;
    profile: I.ImmutableOf<ApiOrganizationUser>;
  }>
> = ({children, organizationId, profile}) => {
  const profileRole = profile.get('role');
  const tokenDefault = inviteUtils.canInviteOthers(profileRole) ? 'loading' : 'not allowed';

  const [{value: tokens = tokenDefault}] = useApiGet(
    async (organizationId, profileRole) => {
      if (!inviteUtils.canInviteOthers(profileRole)) {
        return 'not allowed';
      }

      const tokenList = (await api.organizations.signupTokens(organizationId)).get('data');

      const tokens: inviteUtils.SignupTokens = {
        owner: null,
        regular: null,
        readonly: null,
      };

      tokenList.forEach((t) => {
        tokens[t!.get('role')] = {token: t!.get('token'), createdAt: t!.get('createdAt')};
      });

      return tokens;
    },
    [organizationId, profileRole] as const
  );

  return <SignupTokenContext.Provider value={tokens}>{children}</SignupTokenContext.Provider>;
};

export function useSignupTokens(): SignupTokensValue {
  const value = React.useContext(SignupTokenContext);

  if (value === undefined) {
    throw new Error('useSignupTokens must be beneath a SignupTokenProvider');
  }

  return value;
}

export const FakeSignupTokenProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    value: SignupTokensValue;
  }>
> = ({children, value}) => (
  <SignupTokenContext.Provider value={value}>{children}</SignupTokenContext.Provider>
);

export default SignupTokenProvider;
