import * as B from '@blueprintjs/core';
import React, {CSSProperties} from 'react';

/**
 * B.Icon component (for use with Blueprint) for our custom "bearing" SVG.
 */
const BearingIcon: React.FunctionComponent<
  React.PropsWithChildren<{
    style?: CSSProperties;
    size?: number;
  }>
> = ({style, size = 16}) => (
  <B.Icon
    icon={
      // Re-using bp5-icon here gives us consistent color / fill handling with
      // Blueprint’s built-in icons.
      <span className="bp5-icon" style={style}>
        <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C10.2428 0 10.4637 0.14065 10.5664 0.360697L14.9414 9.7357C15.0195 9.90323 15.0195 10.0968 14.9414 10.2643L10.5664 19.6393C10.4637 19.8594 10.2428 20 10 20C9.75717 20 9.53632 19.8594 9.43364 19.6393L5.05864 10.2643C4.98045 10.0968 4.98045 9.90323 5.05864 9.7357L9.43364 0.360697C9.53632 0.14065 9.75717 0 10 0ZM10 16.875L6.875 10H13.125L10 16.875Z"
          />
        </svg>
      </span>
    }
  />
);

export default BearingIcon;
