// Use singletons since this is only analyzed when the bundle is loaded, so no
// harm, which also allow us to export a literal instead of needing a func.
export let nodeEnv,
  isDev,
  isTest,
  isProd,
  isDevelopment,
  isProduction,
  useStripeTestEnv,
  useLocalTiler,
  useLocalLoginApp,
  useLocalApi,
  gitSha;

getEnvValues();

function getEnvValues() {
  nodeEnv = process.env.NODE_ENV;
  isTest = process.env.NODE_ENV === 'test';
  isDev = process.env.NODE_ENV === 'development';
  isDevelopment = isDev;
  isProd = process.env.NODE_ENV === 'production';
  isProduction = isProd;
  useStripeTestEnv = !!process.env.VITE_USE_STRIPE_TEST_ENV;
  useLocalTiler = !!process.env.VITE_USE_LOCAL_TILER;
  useLocalLoginApp = !!process.env.VITE_USE_LOCAL_LOGIN_APP;
  useLocalApi = !!process.env.VITE_USE_LOCAL_API;
  gitSha = process.env.VITE_GIT_SHA;
}
