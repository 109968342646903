import * as geojson from 'geojson';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import DrawLayer, {DrawingMode} from 'app/components/DeclarativeMap/DrawLayer';
import {PolygonDispatch, PolygonState} from 'app/providers/MapPolygonStateProvider';

/**
 * Adds the Analyze Area the polygon draw layer to the map when the tool is active.
 */
const AnalyzePolygonTool: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;

    polygonState: PolygonState;
    polygonDispatch: PolygonDispatch;

    isOpen: boolean;

    drawingMode?: DrawingMode;

    /** Hack to prevent 2 draw layers at the same time. */
    preventDrawLayer?: boolean;
  }>
> = ({
  map,
  isMapLoaded,
  polygonState,
  polygonDispatch,
  isOpen,
  preventDrawLayer = false,
  drawingMode = 'draw_polygon',
}) => {
  const {polygonFeature, analyzeAreaDrawMode} = polygonState;

  const setFeature = React.useCallback(
    (f: geojson.Feature | null) => {
      polygonDispatch({
        type: 'setPolygonFeature',
        polygonFeature:
          f?.geometry.type === 'Polygon' || f?.geometry.type === 'MultiPolygon'
            ? (f as geojson.Feature<geojson.Polygon | geojson.MultiPolygon>)
            : null,
      });
    },
    [polygonDispatch]
  );

  return (
    <>
      {/* We need to render these as two separate components in this way, otherwise we run into a race condition:
        Mapbox Draw routines need to run to completion before our code that reacts to changes can run. */}
      {analyzeAreaDrawMode === 'selectOverlayPolygon' && (
        <DrawLayer
          map={map}
          isMapLoaded={isMapLoaded}
          visible={isOpen && !preventDrawLayer}
          drawingMode={'simple_select'}
          feature={polygonFeature}
          setFeature={setFeature}
          deselectOnCreation={true}
        />
      )}

      {analyzeAreaDrawMode !== 'selectOverlayPolygon' && (
        <DrawLayer
          map={map}
          isMapLoaded={isMapLoaded}
          visible={isOpen && !preventDrawLayer}
          drawingMode={drawingMode}
          feature={polygonFeature}
          setFeature={setFeature}
          deselectOnCreation={true}
          deselectOnFeatureUpdate={analyzeAreaDrawMode === 'clipPolygon'}
        />
      )}
    </>
  );
};

export default AnalyzePolygonTool;
