import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {MapTool} from 'app/pages/MonitorProjectView/view';
import * as mapUtils from 'app/utils/mapUtils';
import * as userUtils from 'app/utils/userUtils';

import MapContent from './MapContent';
import cs from './StyleControl.styl';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
  activeTool: MapTool | null;
  setActiveTool: (t: MapTool | null) => void;

  /** Props to support toggling the hidden cost debugger layer */
  profile?: I.ImmutableOf<ApiOrganizationUser>;
  showCostDebugger: boolean;
  setShowCostDebugger: (b: boolean) => void;
}

export default class MapSettingsControl extends React.Component<Props> {
  private mapSettingsControlButtonEl = document.createElement('div');
  private mapSettingsControlButton = new mapUtils.PortalControl(this.mapSettingsControlButtonEl);

  render() {
    const {
      map,
      isMapLoaded,
      position,
      activeTool,
      setActiveTool,
      showCostDebugger,
      setShowCostDebugger,
      profile,
    } = this.props;

    return (
      <React.Fragment>
        <MapContent
          map={map}
          isMapLoaded={isMapLoaded}
          controls={[this.mapSettingsControlButton]}
          controlPosition={position}
        />
        {ReactDOM.createPortal(
          <B.ButtonGroup vertical>
            <B.Tooltip content="Change basemap" position={B.Position.RIGHT}>
              <B.AnchorButton
                id="map-basemap-control"
                icon="layer"
                active={activeTool === 'manageBasemap'}
                className={cs.mapIcon}
                onClick={() =>
                  setActiveTool(activeTool === 'manageBasemap' ? null : 'manageBasemap')
                }
              />
            </B.Tooltip>
            <B.Tooltip content="Change overlays" position={B.Position.RIGHT}>
              <B.AnchorButton
                id="map-overlays-control"
                icon="layers"
                active={activeTool === 'manageLayers'}
                className={cs.mapIcon}
                onClick={(ev) => {
                  setActiveTool(activeTool === 'manageLayers' ? null : 'manageLayers');

                  // Support turning on a hidden cool tile cost debugger overlay by
                  // holding the alt key down while clicking the layers icon
                  if (profile && ev.altKey && userUtils.isUpstreamEmail(profile.get('email'))) {
                    setShowCostDebugger(!showCostDebugger);
                  }
                }}
              />
            </B.Tooltip>
          </B.ButtonGroup>,
          this.mapSettingsControlButtonEl
        )}
      </React.Fragment>
    );
  }
}
