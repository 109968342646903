import * as B from '@blueprintjs/core';
import emojiRegex from 'emoji-regex';
import * as I from 'immutable';
import * as React from 'react';
import tinycolor from 'tinycolor2';

import {ListItem} from 'app/components/List';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, TagSetting} from 'app/modules/Remote/FeatureCollection';
import {StateApiNote} from 'app/stores/NotesStore';
import * as CONSTANTS from 'app/utils/constants';

import * as featureCollectionUtils from './featureCollectionUtils';
import {getIn, isImmutableFeature} from './featureUtils';

export enum TAG_KIND {
  FEATURE,
  NOTE,
}

export type TagStatusMap = Record<string, boolean>;

export const TAG_KIND_DISPLAY = {
  [TAG_KIND.FEATURE]: 'Property',
  [TAG_KIND.NOTE]: 'Note',
};

/**
 * Returns settings for all unarchived tags.
 */
export function getTagSettings(fc: I.ImmutableOf<ApiFeatureCollection>, kind: TAG_KIND) {
  const view = featureCollectionUtils.getLensView(fc);

  const settings: I.ImmutableOf<TagSetting[]> =
    view?.get(kind === TAG_KIND.FEATURE ? 'tags' : 'noteTags') || I.List();

  // Returning an ordered map because display order is important.
  return I.OrderedMap<string, I.ImmutableOf<TagSetting>>(
    settings
      .filter((s) => !s!.get('isArchived'))
      .map((s) => [s!.get('id'), s!] as const)
      .toArray()
  );
}

/**
 * Returns the IDs for all tags that a feature currently has.
 *
 * Note: IDs may still be for archived tags, so this needs to be
 * cross-referenced with the result of getTagSettings.
 */
export function getFeatureTagIds(feature: ApiFeature): string[];
export function getFeatureTagIds(feature: I.ImmutableOf<ApiFeature>): I.Set<string>;
export function getFeatureTagIds(
  feature: I.ImmutableOf<ApiFeature> | ApiFeature
): I.Set<string> | string[] {
  if (!isImmutableFeature(feature)) {
    const tagIds = getIn(
      feature,
      `properties.${CONSTANTS.APP_PROPERTIES_KEY}.${CONSTANTS.APP_PROPERTY_TAG_IDS}`
    );
    return tagIds
      ? Object.entries(tagIds)
          .filter(([_, enabled]) => enabled)
          .map(([id]) => id)
      : [];
  } else {
    return (
      feature
        .getIn(['properties', CONSTANTS.APP_PROPERTIES_KEY])
        ?.get(CONSTANTS.APP_PROPERTY_TAG_IDS)

        ?.entrySeq()
        // entrySeq is sequence of [tagId: string, isSet: boolean]
        ?.filter((e) => !!e![1])
        ?.map((e) => e![0])
        ?.toSet() || I.Set()
    );
  }
}

function getFeatureTagStatusMap(feature: I.ImmutableOf<ApiFeature>): I.ImmutableOf<TagStatusMap> {
  return (
    feature
      .getIn(['properties', CONSTANTS.APP_PROPERTIES_KEY])
      ?.get(CONSTANTS.APP_PROPERTY_TAG_IDS) || I.Map()
  );
}

export function getFeaturesTagStatusMaps(
  features: I.ImmutableOf<ApiFeature[]>
): I.ImmutableOf<TagStatusMap[]> {
  return features.map((f) => getFeatureTagStatusMap(f!)).toList();
}

/**
 * Returns the IDs for all tags that a list of features currently has.
 *
 * Note: IDs may still be for archived tags, so this needs to be
 * cross-referenced with the result of getTagSettings.
 */
export function getFeaturesTagIds(features: I.ImmutableOf<ApiFeature[]>): I.Set<string> {
  const tagIds = features.map((feature) => getFeatureTagIds(feature!));
  return tagIds.flatten().toSet();
}

/**
 * Returns the IDs for all tags that a note currently has.
 *
 * Note: IDs may still be for archived tags, so this needs to be
 * cross-referenced with the result of getTagSettings.
 */
export function getNoteTagIds(note: I.ImmutableOf<StateApiNote>): I.Set<string> {
  return (
    note
      .get('tagIds')
      ?.entrySeq()
      // entrySeq is sequence of [tagId: string, isSet: boolean]
      ?.filter((e) => !!e![1])
      ?.map((e) => e![0])
      ?.toSet() || I.Set()
  );
}

/**
 * Returns the IDs for all tags in a tag status map.
 *
 * Note: IDs may still be for archived tags, so this needs to be
 * cross-referenced with the result of getTagSettings.
 */

export function getTagIds(tagStatusMap: I.ImmutableOf<TagStatusMap> | null): I.Set<string> {
  return tagStatusMap
    ? tagStatusMap
        .entrySeq()
        // entrySeq is sequence of [tagId: string, isSet: boolean]
        ?.filter((e) => !!e![1])
        ?.map((e) => e![0])
        ?.toSet()
    : I.Set();
}

const COLLAPSE_MATCH_REGEX = new RegExp('(' + emojiRegex().source + ')|[A-Z\u00C0-\u00DC]', 'g');

export function collapseTagText(txt: string): string {
  let out = txt.match(COLLAPSE_MATCH_REGEX)?.map((v) => v) || [];

  if (out.length === 0) {
    out = txt
      .split(/\s+/g)
      .filter((s) => s.length > 0)
      .map((s) => s[0]);
  }

  return out.join('');
}

export const Tag: React.FunctionComponent<
  React.PropsWithChildren<{
    setting: I.ImmutableOf<TagSetting>;
    small?: boolean;
    indeterminate?: boolean;
    className?: string | undefined;
    onRemove?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tagProps: B.TagProps) => void;
  }>
> = ({setting, className, small = false, indeterminate = false, onRemove}) => {
  const settingColor = setting.get('color');
  const indeterminateColor = maybeDarkenColor(settingColor);

  const backgroundColor = indeterminate ? '#fff' : settingColor;

  const color = indeterminate
    ? indeterminateColor
    : tinycolor(backgroundColor).isLight()
      ? '#000'
      : '#fff';

  return (
    <B.Tag
      minimal
      className={className}
      style={{
        backgroundColor,
        color,
        margin: small ? '0 0 0 0.25rem' : '0.125rem 0 0.125rem 0.25rem',
        // A little JavaScript dance to avoid writing a `border: undefined`
        // style attribute if the indeterminate parameter is falsey.
        ...(indeterminate && {border: `1px dashed ${indeterminateColor}`}),
      }}
      title={setting.get('text')}
      id={setting.get('id')}
      onRemove={onRemove}
    >
      {small ? collapseTagText(setting.get('text')) : setting.get('text')}
    </B.Tag>
  );
};

/**
 * A function to recursively darken a color in incremenets of 5 (on a scale of 0
 * to 100) until it is no longer light. Used to ensure the border and text of
 * indeterminate tags are visible.
 */
function maybeDarkenColor(color: string) {
  const tinyColor = tinycolor(color);

  if (tinyColor.isLight()) {
    return maybeDarkenColor(tinyColor.darken(5));
  } else {
    return tinyColor.toHexString();
  }
}

/**
 * Generates ListItems for the provided set of tags. The checkedness of
 * particular tags is determined by the isSelectedFn, and tags can be selected,
 * not selected, or a mixed selection.
 */
export function makeTagListItems(
  settings: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<TagSetting>>>,
  isSelectedFn: (tagId: string) => boolean | 'mixed'
): ListItem<{id: string}>[] {
  return settings
    .filter((t) => !t!.get('isArchived'))
    .map((t) => {
      let icon: B.IconName;

      const state = isSelectedFn(t!.get('id'));
      if (state === true) {
        icon = 'tick';
      } else if (state === 'mixed') {
        icon = 'minus';
      } else {
        icon = 'blank';
      }

      return {
        id: t!.get('id'),
        text: <Tag setting={t!} />,
        icon: icon,
        isSelected: false,
        height: 32.5,
        centerVertically: true,
      } as const;
    })
    .toArray();
}

/**
 * Generates a map of tag ID to number of times it appears in the selected
 * features.
 */

export function getFeaturesTagCounts(features: I.ImmutableOf<ApiFeature[]>) {
  return getTagCounts(getFeaturesTagStatusMaps(features));
}

/**
 * Generates a map of tag ID to number of times it appears in the selected
 * tag status maps.
 */
export function getTagCounts(tagStatusMaps: I.ImmutableOf<TagStatusMap[]>) {
  return tagStatusMaps.reduce(
    (acc, tagStatusMap) =>
      getTagIds(tagStatusMap!).reduce((acc, id) => acc!.set(id!, (acc!.get(id!) || 0) + 1), acc),
    I.Map<string, number>()
  );
}

/**
 * Returns a dictionary of tag IDs according to their order in
 * the tag settings of the feature collection.
 */
export function getTagOrder(featureCollection: I.ImmutableOf<ApiFeatureCollection>) {
  const tagSettings = getTagSettings(featureCollection, TAG_KIND.NOTE).toJS();
  return Object.keys(tagSettings).reduce((acc, key, index) => {
    acc[key] = index;
    return acc;
  }, {});
}

/**
 * From a note and its Tag Order, return an array of tagOrder.length mapping
 * tags to their order. Then sort the array and fill any missing tag gaps
 * with null. note => [a,c,d,null]
 */
export function getTagRank(
  note: StateApiNote,
  tagOrder: Record<string, number>
): (number | null)[] {
  const rankLength = Object.keys(tagOrder).length;
  const tags = note.tagIds || {};
  const ranks: number[] = Object.keys(tags)
    .filter((tagId) => tags[tagId]) // remove tag entries that are false
    .map((key) => tagOrder[key])
    .sort((a, b) => a - b);
  const fill = new Array(rankLength - ranks.length).fill(null);
  return ranks.concat(fill);
}

/**
 * Compare tags on a note such that:
 * -sorted by tag order: [a], [b]
 * -...ranking ALL tags: [a,b,c] [a,c,d]
 * -notes with tags before those without: [a,b], [a, null]
 */
export function compareTagRanks(
  a: StateApiNote,
  b: StateApiNote,
  tagOrder: Record<string, number>
): number {
  const aRank = getTagRank(a, tagOrder);
  const bRank = getTagRank(b, tagOrder);
  const maxLength = Object.keys(tagOrder).length;

  for (let i = 0; i < maxLength; i++) {
    // do nothing and return if both values are null
    if ([aRank[i], bRank[i]].every((value) => value === null)) return 0;

    const {aDigit, bDigit} = {aDigit: aRank[i], bDigit: bRank[i]};
    if (aDigit === null) return 1; // b exists and a does not, swap
    if (bDigit === null) return -1; // a exists and b does not
    if (aDigit < bDigit) return -1; // a is ranked higher than b
    if (aDigit > bDigit) return 1; // b is ranked higher than a, swap
    // implicit else: continue loop if both are equal
  }
  return 0;
}
