import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {recordEvent} from 'app/tools/Analytics';
import * as mapUtils from 'app/utils/mapUtils';

import MapContent from './MapContent';
import cs from './StyleControl.styl';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  onChangeMapStyle: (mapStyle: mapUtils.MapStyle) => void;
  styles: mapUtils.MapStyleInfo[];
  selectedStyle: mapUtils.MapStyle;
  position: MapControlPosition;
}

export default class StyleControl extends React.Component<Props> {
  private styleControlButtonEl = document.createElement('div');
  private styleControlButton = new mapUtils.PortalControl(this.styleControlButtonEl);

  private renderContent() {
    const {styles, selectedStyle, onChangeMapStyle} = this.props;
    return (
      <B.Popover
        position={B.Position.RIGHT}
        content={
          <B.Menu>
            {styles.map((style) => (
              <B.MenuItem
                key={style.id}
                active={style.id === selectedStyle}
                onClick={() => {
                  recordEvent('Changed basemap', {style: style.title});
                  onChangeMapStyle(style.id);
                }}
                text={style.title}
              />
            ))}
          </B.Menu>
        }
      >
        <B.Tooltip content="Change basemap" position={B.Position.RIGHT}>
          <B.AnchorButton icon="map" className={cs.mapIcon} />
        </B.Tooltip>
      </B.Popover>
    );
  }

  render() {
    const {map, isMapLoaded, position} = this.props;
    return (
      <React.Fragment>
        <MapContent
          map={map}
          isMapLoaded={isMapLoaded}
          controls={[this.styleControlButton]}
          controlPosition={position}
        />
        {ReactDOM.createPortal(this.renderContent(), this.styleControlButtonEl)}
      </React.Fragment>
    );
  }
}
