import * as Sentry from '@sentry/react';
import * as geojson from 'geojson';
import * as I from 'immutable';
import {isEqual} from 'lodash';
import {abs, median} from 'mathjs';
import React from 'react';

import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {MapStateDispatch} from 'app/pages/MonitorProjectView/MapStateProvider';
import {
  OverlayMaskSource,
  PolygonDispatch,
  PolygonState,
  getDataRange,
  useOverlayMask,
} from 'app/providers/MapPolygonStateProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import {NotesActions, NotesState} from 'app/stores/NotesStore';
import {
  FeatureDataMeta,
  RasterCalculationResult,
  RasterTimeSeriesCalculator,
  TileData,
  getFeatureDataHasThumbnailUrls,
} from 'app/stores/RasterCalculationStore';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {StatusMaybe, useContinuity, useMemoAsync, useStateWithDeps} from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import AnalyzePolygonPopup, {
  AreaModePreset,
  ExtraGraphOptions,
  GraphMode,
  TimeSeriesCalculatorData,
  defaultOverlayThresholdForDataRange,
} from './AnalyzePolygonPopup';
import {
  DEFAULT_EXTRA_GRAPH_OPTIONS,
  DEFAULT_GRAPH_MODE,
  DEFAULT_GRAPH_RANGE,
  getLayerSettings,
} from './layerSettings';
import {fetchTileData, getGraphModes, getGraphRanges} from './utils';
import {GraphRange} from '../AnalyzePolygonChart/types';
import {hasOnlyUnorderedImagery} from '../LayersMenu';

export const POLYGON_BOUNDS_ERROR = 'Data does not fully cover selected area';
export const TILE_COVERAGE_ERROR = 'Invalid tile coverage data';

/**
 * A wrapper around AnalyzePolygonPopup that takes the GeoJSON polygon and the
 * FeatureCollection/FeatureData and actually runs the calculations.
 *
 * Includes state to manage the async loading of RasterTimeSeriesCalculator and
 * the calculation process.
 *
 * This is the equivalent of "connected," just without Redux.
 */
export const AnalyzePolygonPopupWithState: React.ForwardRefRenderFunction<
  HTMLDivElement,
  {
    firebaseToken: string | null;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    feature: I.ImmutableOf<ApiFeature>;
    featureData: I.ImmutableOf<ApiFeatureData[]>;
    imageRefs: mapUtils.MapImageRefs;
    notesState: NotesState;
    notesActions: NotesActions;
    polygonState: PolygonState;
    polygonDispatch: PolygonDispatch;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    mapStateDispatch: MapStateDispatch;
    isOpen: boolean;
    onClose: () => void;
    updateFeatureCollection: ProjectsActions['updateFeatureCollection'];
  }
> = (
  {
    firebaseToken,
    featureCollection,
    feature,
    featureData,
    imageRefs,
    notesState,
    notesActions,
    polygonState,
    polygonDispatch,
    organization,
    profile,
    mapStateDispatch,
    isOpen,
    onClose,
    updateFeatureCollection,
  },
  ref
) => {
  const cursorKey = featureUtils.getCursorKeyForLayerKey(featureCollection, imageRefs[0].layerKey);

  const {polygon, analyzeAreaDrawMode} = polygonState;

  const paidImagery = featureUtils.getPaidImagery(feature);
  const paidScenes = paidImagery?.get('scenes');

  // useMemo because this is an array and we want to maintain shallow equality.
  //
  // The FeatureCollection object can change due to realtime updates. Since we
  // assume that none of those updates will warrant re-calculating the
  // layerKeys, we’re safe to ignore it as a dependency.
  //
  // (We can keep this assumption because Lens does not use a Layers view to set
  // the available layers.)
  const layerKeys = React.useMemo(() => {
    return featureUtils.getAnalysisLayerKeysForFeature(
      featureCollection,
      featureData,
      paidScenes,
      feature
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Tracks whether the user is currently editing their Analyze Area selections. We only display
  // the graph once they have committed to their selections (though the graph will load and update
  // in the background.) When not editing, we display the graph and the controls are disabled.
  const [displayEditableControls, setDisplayEditableControls] = React.useState(true);

  // if we didn't get back any layerKeys that are valid AA layers, we still need to pass in a valid
  // layerKey here so that the page doesn't error trying to fetch a layer to begin AA.
  const [graphLayerKey, setGraphLayerKey] = React.useState<string>(
    layerKeys.length ? layerKeys[0] : 'none'
  );

  const [extraGraphOptions, setExtraGraphOptions] = React.useState<ExtraGraphOptions>(
    DEFAULT_EXTRA_GRAPH_OPTIONS
  );

  const [graphRange, _setGraphRange] = React.useState<GraphRange>(DEFAULT_GRAPH_RANGE);

  // Update graphLayerKey to match the imageRef so that AA opens with the current map layer
  React.useEffect(() => {
    if (!layerKeys.length || isOpen) return; // Don't change the layer key when the tool is open
    let targetLayerKey = layerKeys.find((layerKey) => layerKey === imageRefs[0].layerKey);
    if (!targetLayerKey && imageRefs[1])
      targetLayerKey = layerKeys.find((layerKey) => layerKey === imageRefs[1]?.layerKey); // Match on the second image ref if the first doesn't match
    if (targetLayerKey) setGraphLayerKey(targetLayerKey);
  }, [imageRefs, isOpen]);

  /**
   * Update extra graph options whenever the time range updates, in case values are no longer valid.
   * e.g. show cumulative yoy being invalid for non-yoy time ranges.
   */
  const setGraphRange = React.useCallback(
    (gr: React.SetStateAction<GraphRange>) => {
      setExtraGraphOptions({...extraGraphOptions, showCumulativeValues: false});
      _setGraphRange(gr);
    },
    [_setGraphRange, setExtraGraphOptions]
  );

  const layer = layerUtils.getLayer(graphLayerKey);

  // We default to 0.4 at the bottom so that when you switch to area you see
  // more than just a solid “100%” block.
  const [overlayThreshold, setOverlayThreshold] = React.useState<[number, number]>(
    defaultOverlayThresholdForDataRange(getDataRange(layer))
  );

  const [graphMode, setGraphMode] = React.useState<GraphMode>(DEFAULT_GRAPH_MODE);
  const [graphModePreset, setGraphModePreset] = React.useState<AreaModePreset | null>(null);

  const hasThumbnailUrls = React.useMemo(
    () => getFeatureDataHasThumbnailUrls(featureData, layerUtils.getRawLayerKey(graphLayerKey)),
    [featureData, graphLayerKey]
  );

  /** Check if the polygon Feature in our selected polygon state contains a
   * note graph layer key property. If so, also check that we still support
   * analysis for the note graph layer key. */
  const validNoteGraphLayerKey = React.useMemo<string | null>(() => {
    const noteGraphLayerKey = polygonState.polygonFeature?.properties?.noteGraphLayerKey;

    return noteGraphLayerKey && layerKeys.includes(noteGraphLayerKey) ? noteGraphLayerKey : null;
  }, [polygonState, layerKeys]);

  /** If our valid note graph layer key has changed and is truthy, change the
   * graph layer key selection. This allows us to pre-select the layer key to
   * match the one used to create a note’s saved graph. */
  React.useEffect(() => {
    if (validNoteGraphLayerKey) {
      setGraphLayerKey(validNoteGraphLayerKey);
    }
  }, [validNoteGraphLayerKey]);

  /**
   * If we are switching to a layer with custom graph configurations, update the
   * graph controls accordingly. If we are switching away from a layer with
   * custom graph configurations, restore the graph controls to their default
   * values *if* the current selection is not supported by the new layer.
   *
   * Also to note, each setter checks validity independently with a snapshot of
   * state, and setters are not guaranteed to run their actions in invocation order.
   */
  React.useEffect(() => {
    const {defaultGraphMode, defaultGraphRange} = getLayerSettings(graphLayerKey);

    setGraphMode((prevGraphMode) => {
      const isGraphModeValid = getGraphModes(graphLayerKey).includes(prevGraphMode);
      return isGraphModeValid ? prevGraphMode : (defaultGraphMode ?? DEFAULT_GRAPH_MODE);
    });

    setGraphRange((prevGraphRange) => {
      const isGraphRangeValid = getGraphRanges(graphLayerKey, graphMode).some(
        (gr) =>
          isEqual(prevGraphRange, gr) || (prevGraphRange.type === 'custom' && gr.type === 'custom')
        // TODO (maya): if we constrict the date selector to only go as far back as the first data
        // point we might get an issue here. Would need to clear or fall back to a default somewhere
      );

      return isGraphRangeValid ? prevGraphRange : (defaultGraphRange ?? DEFAULT_GRAPH_RANGE);
    });
  }, [graphLayerKey]);

  const tileData: StatusMaybe<TileData> = React.useMemo(() => {
    return fetchTileData(graphLayerKey, polygon, featureData);
  }, [graphLayerKey, polygon, featureData]);

  // Calculates the average area of a pixel in square meters at the tile’s zoom
  // level and the polygon’s average latitude, if tile data is known.
  const pixelAreaInM2 = React.useMemo(() => {
    if (tileData.value) {
      const {bounds, zoom} = tileData.value;
      const lat = (bounds[1] + bounds[3]) / 2;
      const distPerPixel = geoJsonUtils.calculateResolution(lat, zoom);
      return distPerPixel ** 2;
    }
  }, [tileData]);

  const [
    rasterTimeSeriesCalculator,
    loadingProgress,
    loadingWarning,
    loadingError,
    retryLoadingCalculator,
  ] = useRasterTimeSeriesCalculator(
    firebaseToken,
    featureData,
    graphLayerKey,
    isOpen,
    graphRange,
    tileData,
    hasThumbnailUrls
  );

  const calculatorData = useCalculatorData(
    overlayThreshold,
    polygon,
    rasterTimeSeriesCalculator,
    loadingProgress
  );

  const [updateOverlayMask, clearOverlayMask, isCalculatingMask] = useOverlayMask(
    firebaseToken,
    polygonDispatch,
    imageRefs,
    featureData,
    cursorKey,
    graphMode,
    graphLayerKey,
    polygon,
    tileData,
    hasThumbnailUrls,
    OverlayMaskSource.ANALYSIS
  );

  // Clear Overlay Mask when closing the popup
  React.useEffect(() => {
    if (!isOpen) {
      clearOverlayMask();
    }
  }, [clearOverlayMask, isOpen]);

  const selectEntireFeature = React.useCallback(() => {
    // Main Lens features will only be Polygons or Multipolygons. We can’t
    // really do type conditionals in a great way here, so we just hard cast.
    const polygonFeature = feature.toJS() as geojson.Feature<
      geojson.Polygon | geojson.MultiPolygon
    >;

    polygonDispatch({
      type: 'setPolygonFeature',
      polygonFeature,
    });
  }, [polygonDispatch, feature]);

  // There may be cases where the data doesn't fully cover the property or the AOI being analyzed.
  // We don't want these to prevent analysis, but we still want to flag it to the user so they
  // are aware
  const geometryWarning = React.useMemo(() => {
    if (!polygon) {
      return null;
    }
    const bounds = featureUtils.getCombinedFeatureDataBounds(featureData, graphLayerKey);
    const dataCoversAOI = bounds && geoJsonUtils.polygonContainsOrEquals(bounds, polygon);

    return !dataCoversAOI ? POLYGON_BOUNDS_ERROR : null;
  }, [featureData, graphLayerKey, polygon]);

  const hasLoadingError =
    polygon &&
    (!!loadingError || (tileData.status === 'error' && tileData.error === TILE_COVERAGE_ERROR));

  // Ref tracking the last open state, to detect when the tool is closed
  const prevIsOpen = React.useRef<boolean>(isOpen);

  // This clears the pending note polygon on closing the analyze tool and resets the popup to edit mode.
  // Functions as the counterpart to the useEffect updating central notes store in AnalyzePolygonPopup
  React.useEffect(() => {
    if (prevIsOpen.current && !isOpen) {
      notesActions.removePendingNoteGeometryFeatureById('pending-note-location-feature');
      setDisplayEditableControls(true);
    }
    prevIsOpen.current = isOpen;
  }, [isOpen, notesActions]);

  const layerVisibilityByKey = Object.fromEntries(
    layerKeys.map((layerKey) => [
      layerKey,
      {onlyUnorderedImageryAvailable: hasOnlyUnorderedImagery(featureData, paidScenes, layerKey)},
    ])
  );

  return isOpen ? (
    <AnalyzePolygonPopup
      ref={ref}
      polygonState={polygonState}
      warning={loadingWarning || geometryWarning}
      error={hasLoadingError ? 'loading-error' : null}
      feature={feature}
      calculatorData={calculatorData}
      graphLayerKey={graphLayerKey}
      availableLayerKeys={layerKeys}
      setLayerKey={(key) => setGraphLayerKey(key)}
      notesState={notesState}
      notesActions={notesActions}
      imageRefs={imageRefs}
      graphRange={graphRange}
      isCalculatingMask={isCalculatingMask}
      setGraphRange={setGraphRange}
      onClose={() => {
        setDisplayEditableControls(true);
        onClose();
      }}
      onReset={() => {
        polygonDispatch({type: 'setPolygonFeature', polygonFeature: null});
        polygonDispatch({
          type: 'setAnalyzeAreaDrawMode',
          analyzeAreaDrawMode: 'drawPolygon',
        });
        setDisplayEditableControls(true);
        clearOverlayMask();
      }}
      onRetry={retryLoadingCalculator}
      mapStateDispatch={mapStateDispatch}
      graphMode={graphMode}
      graphModePreset={graphModePreset}
      extraGraphOptions={extraGraphOptions}
      setExtraGraphOptions={setExtraGraphOptions}
      setGraphMode={(mode, preset) => {
        setGraphMode(mode);
        // Leaving off a preset does not reset the threshold so that you can
        // start from a preset and then switch into "custom" to tweak.
        setGraphModePreset(preset || null);
      }}
      overlayThreshold={overlayThreshold}
      setOverlayThreshold={setOverlayThreshold}
      selectEntireFeature={selectEntireFeature}
      organization={organization}
      profile={profile}
      pixelAreaInM2={pixelAreaInM2}
      setAnalyzeAreaDrawMode={(analyzeAreaDrawMode) =>
        polygonDispatch({
          type: 'setAnalyzeAreaDrawMode',
          analyzeAreaDrawMode: analyzeAreaDrawMode,
        })
      }
      analyzeAreaDrawMode={analyzeAreaDrawMode}
      displayEditableControls={displayEditableControls}
      setDisplayEditableControls={setDisplayEditableControls}
      layerVisibilityByKey={layerVisibilityByKey}
      clearOverlayMask={clearOverlayMask}
      updateOverlayMask={updateOverlayMask}
      featureCollection={featureCollection}
      updateFeatureCollection={updateFeatureCollection}
    />
  ) : (
    <></>
  );
};

/**
 * Returns a RasterTimeSeriesCalculator for the requested layerKey within the
 * provided polygon. Keeps a cache of calculators, though, so as long as the
 * feature data or timeseries doesn’t change they don’t have to be re-loaded.
 *
 * @returns [calculator, error, retry]
 */
export function useRasterTimeSeriesCalculator(
  firebaseToken: string | null,
  featureData: I.ImmutableOf<ApiFeatureData[]>,
  layerKey: string | undefined,
  isOpen: boolean,
  graphRange: GraphRange,
  tileData: StatusMaybe<TileData>,
  hasThumbnailUrls: boolean,
  // Optionally pass an absolute start date to be used when calculating the dateRange for the timeseries
  absoluteStartDate?: Date | undefined
): [
  RasterTimeSeriesCalculator<FeatureDataMeta> | null,
  number,
  string | null,
  string | null,
  () => void,
] {
  const abortController = React.useRef(new AbortController());

  tileData = useContinuity(tileData);

  // Map of layerKey -> RasterTimeSeriesCalculator. Cached between layerKeys. We
  // only need to reset it when featureData or graphRange changes.
  //
  // undefined: not set
  // null: loading
  const [calculators, setCalculators] = useStateWithDeps<
    Record<string, RasterTimeSeriesCalculator<FeatureDataMeta> | undefined | null>
  >({}, [featureData, graphRange]);

  const [loadingWarning, setLoadingWarning] = React.useState<string | null>(null);

  const [loadingError, setLoadingError] = React.useState<string | null>(null);
  const [loadingProgress, setLoadingProgress] = useStateWithDeps<number>(0, [
    featureData,
    graphRange,
    layerKey,
    loadingError,
  ]);

  // Since tileData has the useContinuity treatment, we know that any
  // changes to it require an entirely fresh data fetch for all layer keys. We
  // do this by setting the layer calculator to null to undefined to signal to
  // the next useEffect hook that we want to load new data.
  React.useEffect(() => {
    setCalculators({});
  }, [tileData, setCalculators]);

  // If we close the pop-up, abort webtile requests.
  React.useEffect(() => {
    if (!isOpen) {
      abortController.current.abort();
    }
  }, [isOpen]);

  // Load the calculators from the info in FeatureData. We include the visibilty
  // check so that we don’t incur the loading cost if the popup is never opened,
  // but we are able to keep our state if the popup is closed.
  //
  // We work to put this all in one useEffect so that we can rely on
  // react-hooks/exhaustive-deps to make sure we have our dependencies all
  // correct.
  React.useEffect(() => {
    // If the popup is closed, we don’t have work to do. If we don’t have a
    // layer key, we don’t know what work to do.
    if (!isOpen || !layerKey) {
      return;
    }

    // If layerCalculator is null then we’re already loading, and if it’s
    // defined then we have nothing to do and can just return it.
    const shouldLoad = calculators[layerKey] === undefined;

    // A separate useEffect hook is responsible for clearing out the calculator
    // when we get fresh tile data.
    if (!shouldLoad || tileData.status !== 'some') {
      return;
    }

    // If we’ve gotten to this point, we can abort in-flight webtile requests
    // since we know from our `shouldLoad` check that we haven’t started
    // requesting webtiles for the selected layer. We also save a new
    // instantiation since controllers cannnot be reset/reused.
    abortController.current.abort();
    abortController.current = new AbortController();

    setLoadingWarning(null);
    setLoadingError(null);
    setCalculators((calculators) => ({...calculators, [layerKey]: null}));

    const layerInfo = layerUtils.getLayer(layerKey);
    const dataRange = getDataRange(layerInfo);
    const rawLayerKey = layerUtils.getRawLayerKey(layerKey);

    const calculatorPromise = hasThumbnailUrls
      ? RasterTimeSeriesCalculator.fromFeatureData(
          featureData,
          rawLayerKey,
          dataRange,
          graphRange,
          tileData.value.bounds,
          setLoadingProgress,
          absoluteStartDate
        )
      : RasterTimeSeriesCalculator.fromTiledFeatureData(
          firebaseToken,
          tileData.value,
          featureData,
          rawLayerKey,
          dataRange,
          graphRange,
          setLoadingProgress,
          absoluteStartDate,
          (s) => setLoadingWarning(s),
          abortController.current.signal
        );

    calculatorPromise
      .then((calculator) => {
        setCalculators((calculators) => ({...calculators, [layerKey]: calculator}));
      })
      .catch((e) => {
        // If the webtile requests have been aborted, clear out the calculator
        // cache and return, as we don’t need to display an error message.
        if (e.code == DOMException.ABORT_ERR) {
          setCalculators((calculators) => ({...calculators, [layerKey]: undefined}));
          return;
        }

        // We want to let Sentry know, at least for now, if this errors so we
        // can diagnose why it’s failing with some regularity during demos.
        Sentry.captureException(e);
        console.error(e);
        setLoadingError(e.toString());
      });
  }, [
    isOpen,
    graphRange,
    featureData,
    layerKey,
    calculators,
    setCalculators,
    tileData,
    firebaseToken,
    hasThumbnailUrls,
    setLoadingProgress,
    absoluteStartDate,
  ]);

  // Resetting the calculator to undefined will make us try to load it again.
  const retry = () => {
    if (layerKey) {
      setCalculators((calculators) => ({...calculators, [layerKey]: undefined}));
    }
  };

  return [
    (layerKey && calculators[layerKey]) || null,
    loadingProgress,
    loadingWarning,
    loadingError,
    retry,
  ];
}

/**
 * Calculates a rolling median for each value in the dataset. Then, filters out any values from
 * the dataset which are more than 15% different from the rolling median. Optionally, include
 * a direction if you only want to filter out outliers above/below the rolling median rather than
 * any direction.
 */

const ROLLING_MEDIAN_S2_NDVI_THRESHOLD = 0.15;

function filterDataOutliersRollingMedian(
  data: [Date, RasterCalculationResult, FeatureDataMeta][] | null,
  threshold: number,
  directionToFilterOut?: 'above' | 'below'
) {
  if (!data || data.length === 0) {
    return data;
  }

  // Filter out any values that do not have means to prevent errors, but don't drop out 0's.
  const calculationResultsWithMeans = data.filter(
    ([, rcr]) => !isNaN(rcr.mean) && rcr.mean !== null
  );

  // Returns the start and end indicies of a window around idx,
  //  e.g. with idx = 3 and array length >= 6 it returns [2, 5], and when passed to slice, gives values at index 2, 3, 4.
  const getWindowFromCenter = (idx) => [
    Math.max(0, idx - 1),
    // .slice()'s end index is not inclusive, hence returning idx + 2 as our end index as long as
    // we're not at the end of the list
    idx === calculationResultsWithMeans.length - 1 ? calculationResultsWithMeans.length : idx + 2,
  ];

  const rollingMedians = calculationResultsWithMeans.map((_, i) => {
    //for each entry in the data, look at the value at the previous index, the current index,
    //and the next index. then calculate the median for the values at those 3 indices.
    return median(
      calculationResultsWithMeans.slice(...getWindowFromCenter(i)).map(([, rcr]) => rcr.mean)
    );
  });
  //filter out any entries which have a value more than a specified % different than the rolling median.
  const filteredData = calculationResultsWithMeans.filter(([, rcr], i) => {
    // For some i, check to see if our mean value is surrounded by zero values. If it is, don't filter this value.
    const context = calculationResultsWithMeans
      .slice(...getWindowFromCenter(i))
      .map(([, {mean}]) => mean);

    const shouldNotFilter = () => {
      switch (true) {
        // don't filter if the surrounding values are 0's
        case context.length === 3 && context[0] === 0 && context[2] === 0:
          return true;
        // if we only want to filter out outliers BELOW our rolling median,
        // don't filter if our current value is greater than rolling median
        case directionToFilterOut === 'below':
          return rollingMedians[i] < rcr.mean;
        // if we only want to filter out outliers ABOVE our rolling median,
        // don't filter if our current value is less than rolling median
        case directionToFilterOut === 'above':
          return rollingMedians[i] > rcr.mean;
        // otherwise, let the value be filtered out if it meets the criteria
        default:
          return false;
      }
    };

    // Filter value if it's not surrounded by zeroes and meets our threshold
    return shouldNotFilter() || abs(rcr.mean - rollingMedians[i]) < threshold;
  });
  return filteredData;
}

/**
 * Calculates the graph values for the polygon from the given
 * rasterTimeSeriesCalculator.
 */
export function useCalculatorData(
  threshold: [number, number],
  polygon: geojson.Polygon | geojson.MultiPolygon | null,
  rasterTimeSeriesCalculator: RasterTimeSeriesCalculator<FeatureDataMeta> | null,
  dataLoadingProgress: number
): TimeSeriesCalculatorData {
  const [calculatorProgress, setCalculatorProgress] = useStateWithDeps<number | null>(
    rasterTimeSeriesCalculator && 0,
    [polygon, threshold, rasterTimeSeriesCalculator]
  );

  const [result, error] = useMemoAsync(
    async (checkExpired) => {
      if (!rasterTimeSeriesCalculator || !polygon) {
        // rasterTimeSeriesCalculator will be null if we’re still loading it.
        // Setting progress to null means we’ll display the "unknown amount of
        // time" loading indicator.
        setCalculatorProgress(null);
        return;
      }

      try {
        let values = await rasterTimeSeriesCalculator.calculate(polygon, threshold, (progress) => {
          if (checkExpired()) {
            return false;
          }

          setCalculatorProgress(progress);
          return true;
        });

        // TODO configure which layers get outlier filtering centrally (like in layer utils)
        // for now, only filter for S2 vegetation, and only filter out outliers below the
        // rolling median (to prevent low outliers due to cloud cover)
        if (rasterTimeSeriesCalculator.rawLayerKey.startsWith('S2_NDVI')) {
          values = filterDataOutliersRollingMedian(
            values,
            ROLLING_MEDIAN_S2_NDVI_THRESHOLD,
            'below'
          );
        }

        return {
          values,
          threshold,
        };
      } catch (e) {
        // Having the data calculation fail is not tremendously expected, so we
        // want to let Sentry know.
        Sentry.captureException(e);
        console.error(e);
        throw e;
      }
    },
    [polygon, rasterTimeSeriesCalculator, setCalculatorProgress, threshold]
  );

  if (result && result.values) {
    return {
      status: 'loaded',
      values: result.values,
      threshold: result.threshold,
    };
  } else if (error) {
    // We don’t expose the "retry" function from useMemoAsync because we assume
    // our calculation is deterministic, so if it fails once it’ll fail again.
    // This is different from useRasterTimeSeriesCalculator’s failure modes,
    // which could be network-related and therefore worth retrying.
    return {status: 'error'};
  } else if (dataLoadingProgress !== 1) {
    return {
      status: 'loading-data',
      progress: dataLoadingProgress,
    };
  } else {
    return {
      status: 'loading',
      progress: calculatorProgress,
    };
  }
}

export default React.forwardRef(AnalyzePolygonPopupWithState);
