import {
  ApiFeatureCollection,
  ApiFeatureCollectionStatus,
} from 'app/modules/Remote/FeatureCollection';

import {FC_PROCESSING_COMPLETE_STATUS} from './constants';

export const REGRID_OVERLAY: ApiFeatureCollection = {
  bounds: null,
  id: 4153,
  name: 'Parcel Data',
  createdAt: '2021-12-13T20:14:15+00:00',
  updatedAt: '2023-01-30T16:38:42+00:00',
  processingStartDate: null,
  processingEndDate: null,
  isArchived: false,
  product: 'regrid',
  status: null,
  tiles: {
    urls: ['regrid/tiles/{z}/{x}/{y}.mvt'],
    sourceLayer: 'a7bca649f8e586762e0e72a50073d9f4d928c681',
    type: 'vector',
  },
  schema: {
    fields: {},
    geoType: 'POLYGON',
  },
  views: [
    {
      id: 'overlay_metadata',
      type: 'overlay_metadata',
      name: 'Overlay Metadata',
      thumbnail_url: 'https://storage.googleapis.com/upstream-icons/overlays/parcel.png',
    },
  ],
  organizationId: 'system@upstream.tech',
  staticBounds: null,
  type: 'FeatureCollection',
  processingConfig: {
    enrolledLayers: {},
    processingMetadata: {},
  },
  supportedLayersForProduct: {},
} as unknown as ApiFeatureCollection;

export function overlayIsReady(status: ApiFeatureCollectionStatus) {
  return status === null || status === FC_PROCESSING_COMPLETE_STATUS;
}
