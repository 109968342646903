import * as B from '@blueprintjs/core';
import React from 'react';

/**
 * B.Icon component (for use with Blueprint) for our custom "ruler" SVG.
 */
const RulerIcon: React.FunctionComponent<
  React.PropsWithChildren<{
    color?: string;
    size?: number;
  }>
> = ({color, size = 16}) => (
  <B.Icon
    icon={
      // Re-using bp5-icon here gives us consistent color / fill handling with
      // Blueprint’s built-in icons.
      <span className="bp5-icon">
        <svg width={size} height={size} viewBox="0 0 21 22" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.67946 6.72312L7.37934 2.67947L8.29245 3.74077L5.7909 5.89304C5.70717 5.96508 5.6977 6.09135 5.76974 6.17509L6.48716 7.00894C6.5592 7.09267 6.68548 7.10214 6.76921 7.0301L9.27076 4.87784L10.2491 6.01491L8.80877 7.25409C8.72504 7.32613 8.71556 7.45241 8.7876 7.53614L9.50503 8.36999C9.57707 8.45372 9.70334 8.4632 9.78707 8.39116L11.2274 7.15198L12.2057 8.28904L9.70411 10.4413C9.62038 10.5133 9.6109 10.6396 9.68294 10.7234L10.4004 11.5572C10.4724 11.6409 10.5987 11.6504 10.6824 11.5784L13.184 9.42611L14.1623 10.5632L12.722 11.8024C12.6383 11.8744 12.6288 12.0007 12.7008 12.0844L13.4182 12.9183C13.4903 13.002 13.6166 13.0115 13.7003 12.9394L15.1406 11.7002L16.1189 12.8373L13.6173 14.9896C13.5336 15.0616 13.5241 15.1879 13.5962 15.2716L14.3136 16.1055C14.3856 16.1892 14.5119 16.1987 14.5956 16.1267L17.0972 13.9744L17.945 14.9598L13.2451 19.0035L2.67946 6.72312ZM16.4504 10.3095L15.733 9.47561L12.5372 5.76118L11.8198 4.92733L8.23265 0.758046C7.87244 0.339389 7.24106 0.292001 6.8224 0.652202L0.758034 5.86981C0.339376 6.23002 0.291989 6.8614 0.65219 7.28006L12.3918 20.9249C12.752 21.3435 13.3834 21.3909 13.8021 21.0307L19.8664 15.8131C20.2851 15.4529 20.3325 14.8215 19.9723 14.4029L16.4504 10.3095Z"
            fill={color}
          />
        </svg>
      </span>
    }
  />
);

export default RulerIcon;
