import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import {booleanPointInPolygon} from '@turf/turf';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {usePushNotification} from 'app/components/Notification';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {getDataRange, useMapPolygonState} from 'app/providers/MapPolygonStateProvider';
import {
  RasterCalculator,
  TileData,
  getTemplateUrl,
  pixelValueToDataValue,
} from 'app/stores/RasterCalculationStore';
import * as featureUtils from 'app/utils/featureUtils';
import {bboxPolygon} from 'app/utils/geoJsonUtils';
import {StatusMaybe} from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {MapControlPosition} from '..';
import {fetchTileData} from '../../AnalyzePolygonPopup/utils';
import MapContent from '../MapContent';
import cs from '../StyleControl.styl';
import {mouseCoordinateToRawPixel} from './logic';
import {usePixelInfoPopup} from './PixelInfoProvider';

interface PixelInfoProps {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
  cursor: string | null;
  firebaseToken: string;
  featureData: I.ImmutableListOf<ApiFeatureData> | null;
  polygon: ApiFeature['geometry'];
  activeLayerKey: string;
  featureCollection: HydratedFeatureCollection;
  disabled: boolean;
  panelIndex: number;
}

const PixelInfo: React.FC<PixelInfoProps> = ({
  map,
  isMapLoaded,
  position,
  cursor,
  featureData,
  firebaseToken,
  polygon,
  activeLayerKey,
  featureCollection,
  disabled,
  panelIndex,
}) => {
  const pixelInspectorControlButtonEl = React.useRef(document.createElement('div'));
  const pixelInspectorControlButton = React.useMemo(
    () => new mapUtils.PortalControl(pixelInspectorControlButtonEl.current),
    []
  );
  const {
    state: {isOpen, isPopupFrozen},
    actions: {
      setIsOpen,
      setProjectedMousePostion,
      setScaledPixelValue,
      setPixelInspectorReady,
      setDebugData,
      setPanelIndex: setPanelIndex,
      setMap,
      togglePopupFrozen,
      setElevation,
    },
  } = usePixelInfoPopup();
  const pushNotification = usePushNotification();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {overlayMask} = useMapPolygonState();

  // State for the pixel info calculator, a RasterCalculator instance.
  const [pixelInspectorCalculator, setPixelInspectorCalculator] =
    React.useState<RasterCalculator | null>(null);

  const [bounds, setBounds] = React.useState(map.getBounds());

  const tileData: StatusMaybe<TileData> = React.useMemo(() => {
    const rawLayerKey = layerUtils.getRawLayerKey(activeLayerKey);
    if (!isOpen || !rawLayerKey) return {status: 'unknown'};

    if (!bounds) {
      return {
        status: 'error',
        error: 'unable to determine bounds',
      };
    }

    const geojsonBounds: GeoJSON.BBox = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ];
    const boundsGeometry = bboxPolygon(geojsonBounds).geometry;

    // This is very expensive and a large number of tiles can break the app.
    const tileData = fetchTileData(activeLayerKey, boundsGeometry, featureData);
    return tileData;
  }, [activeLayerKey, bounds, featureData, isOpen]);

  const featureDatum = React.useMemo(() => {
    if (!featureData || !cursor) return null;
    return featureUtils.getFeatureDatumFromLayerKey(
      featureData,
      activeLayerKey,
      cursor,
      featureCollection
    );
  }, [featureData, activeLayerKey, cursor, featureCollection]);

  // Update bounds when map changes to make sure we load the correct tiles.
  React.useEffect(() => {
    const onMapChange = () => {
      setBounds(map.getBounds());
    };

    map.on('moveend', onMapChange);
    return () => {
      map.off('moveend', onMapChange);
    };
  }, [map]);

  const boundMouseMoveHandler = React.useCallback(
    (
      event: mapboxgl.MapMouseEvent,
      {
        // If true, we will force an update even if the popup is frozen.
        forceUpdate = false,
      } = {}
    ) => {
      if (isPopupFrozen && !forceUpdate) return;
      setMap(map);
      setProjectedMousePostion(event.lngLat);
      setPanelIndex(panelIndex);
      setElevation(map.queryTerrainElevation(event.lngLat, {exaggerated: false}));

      // If the mouse is not in the polygon, publish null for the display value
      // which will hide the popup
      if (
        !booleanPointInPolygon(
          event.lngLat.toArray(),
          polygon as GeoJSON.Polygon | GeoJSON.MultiPolygon
        )
      ) {
        setScaledPixelValue({
          displayValue: null,
          coords: event.lngLat,
          dataRange: getDataRange(layerUtils.getLayer(activeLayerKey)),
        });
        setDebugData(null);
        return;
      }

      // This is a case where in compare view it's possible to have a data layer
      // next to a true color layer.
      if (!pixelInspectorCalculator) {
        setScaledPixelValue({
          displayValue: 'N/A',
          coords: event.lngLat,
          dataRange: getDataRange(layerUtils.getLayer(activeLayerKey)),
        });
        setDebugData(null);
        return;
      }

      const layer = layerUtils.getLayer(activeLayerKey);
      const layerLegendMap = layer.type === 'image' ? layer.layerLegendMap : undefined;
      const dataRange = getDataRange(layer);
      const {pixelData, imageData, coordinateDebugger} = mouseCoordinateToRawPixel(
        event,
        pixelInspectorCalculator
      );

      if (pixelData) {
        const scaledPixelData = pixelValueToDataValue(pixelData[0], [...dataRange]);
        const [r, g, b, a] = pixelData;
        // Pixel presence is stored in the alpha channel. If a = 0 then the pixel
        // is not present.
        let displayValue = a === 0 ? 'No Data' : scaledPixelData.toFixed(2);
        if (layerLegendMap) {
          displayValue = layerLegendMap[scaledPixelData]?.label || 'N/A';
        }
        setScaledPixelValue({
          displayValue: displayValue,
          coords: event.lngLat,
          dataRange: getDataRange(layerUtils.getLayer(activeLayerKey)),
        });
        setDebugData({
          pixelColor: `rgba(${r}, ${g}, ${b}, ${a})`,
          emulatedImageData: imageData,
          markerCoordinates: coordinateDebugger || null,
          rawPixelReading: pixelData[0],
          tileBounds: bounds,
          tileData: tileData,
        });
      } else {
        setScaledPixelValue(null);
        setDebugData(null);
      }
    },
    [
      activeLayerKey,
      bounds,
      isPopupFrozen,
      map,
      pixelInspectorCalculator,
      polygon,
      setDebugData,
      setMap,
      setProjectedMousePostion,
      setPanelIndex,
      panelIndex,
      setScaledPixelValue,
      setElevation,
      tileData,
    ]
  );

  const togglePixelInspector = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  // If we don't manually set the event, the popup will not move to where
  // the new click happened, it will wait until the next mousemove update.
  const togglePopupFrozenWithClickHandling = React.useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      togglePopupFrozen();
      boundMouseMoveHandler(event, {forceUpdate: true});
    },
    [boundMouseMoveHandler, togglePopupFrozen]
  );

  // Lifecycle for pixel info tool: will clean up the mousemove listener when unmounting
  // when feature is switched.
  React.useEffect(() => {
    if (isOpen) {
      map.on('mousemove', boundMouseMoveHandler);
      map.on('click', togglePopupFrozenWithClickHandling);
    }

    return () => {
      map.off('mousemove', boundMouseMoveHandler);
      map.off('click', togglePopupFrozenWithClickHandling);
    };
  }, [map, boundMouseMoveHandler, isOpen, togglePopupFrozenWithClickHandling]);

  // Update the pixel info calculator with the RAW layer key.
  React.useEffect(() => {
    if (!isOpen) return;
    setPixelInspectorReady(panelIndex, false);

    const rawLayerKey = layerUtils.getRawLayerKey(activeLayerKey);
    if (!rawLayerKey || !featureDatum || !tileData.value) {
      setPixelInspectorReady(panelIndex, true);
      setPixelInspectorCalculator(null);
      return;
    }

    const layerInfo = layerUtils.getLayer(activeLayerKey);
    const dataRange = getDataRange(layerInfo);
    const layerTemplateUrl = getTemplateUrl(featureDatum, rawLayerKey);

    // Load the calculator with the given tile data.
    // Adjust the errorredTileCount as needed.
    const loadCalculator = async (tileData: TileData) => {
      let erroredTileCount = 0;
      const calculator = await RasterCalculator.fromTiles(
        tileData,
        layerTemplateUrl!,
        dataRange,
        firebaseToken,
        () => {
          erroredTileCount++;
        }
      );
      if (erroredTileCount > 0) {
        console.warn('Could not load all tiles for pixel info');
        return null;
      } else {
        return calculator;
      }
    };

    // We use the window bounds to determine which tiles to load at a
    // given zoom level. For some layers, like NLCD, tiles are not available
    // within the entire min/max zoom range. In this case, we use the polygon
    // bounds to determine which tiles to load. We don't "fail" until
    // we've attempted to load the calculator with both the window and polygon
    // bounds.
    const updatePixelInspectorCalculator = async (currentTileData: TileData) => {
      try {
        let calculator = await loadCalculator(currentTileData); // Default window bounds
        if (!calculator) {
          console.warn(
            'Could not load Pixel Info calculator with window bounds, attempting to use polygon bounds'
          );
          const fallbackTileData = fetchTileData(activeLayerKey, polygon, featureData);
          if (!fallbackTileData.value) {
            throw new Error('Pixel Info: Tiles could not be fetched from polygon bounds');
          } else {
            calculator = await loadCalculator(fallbackTileData.value); // Fall back to polygon bounds
            if (!calculator) {
              throw new Error(
                'Pixel Info Calculator could not be built with either window or polygon bounds'
              );
            }
          }
        }
        setPixelInspectorCalculator(calculator);
      } catch (error) {
        console.error(error);
        setPixelInspectorCalculator(null);
        setIsOpen(false);
        pushNotification({
          message: 'Could not load Pixel Info for the active layer',
          options: {
            intent: B.Intent.DANGER,
          },
        });
        Sentry.captureException(error, {
          data: {
            rawLayerKey,
          },
        });
      } finally {
        setPixelInspectorReady(panelIndex, true);
      }
    };

    updatePixelInspectorCalculator(tileData.value);
  }, [
    activeLayerKey,
    tileData,
    firebaseToken,
    featureDatum,
    polygon,
    featureData,
    isOpen,
    setIsOpen,
    pushNotification,
    setPixelInspectorReady,
    panelIndex,
  ]);

  return (
    <React.Fragment>
      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[pixelInspectorControlButton]}
        controlPosition={position}
      />
      {ReactDOM.createPortal(
        <B.Tooltip
          interactionKind="hover"
          content={
            disabled
              ? overlayMask
                ? 'Pixel info is disabled because of an active range filter'
                : 'Pixel info is disabled'
              : isOpen
                ? 'Disable pixel info'
                : 'Enable pixel info'
          }
          position={B.Position.RIGHT}
        >
          <B.AnchorButton
            id="pixel-inspector-control"
            icon="target"
            className={cs.mapIcon}
            disabled={disabled}
            onClick={togglePixelInspector}
            active={isOpen}
          />
        </B.Tooltip>,
        pixelInspectorControlButtonEl.current
      )}
    </React.Fragment>
  );
};

export default PixelInfo;
