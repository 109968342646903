import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import geojson from 'geojson';
import * as I from 'immutable';
import React, {MouseEvent} from 'react';

import AnalyzePolygonChart from 'app/components/AnalyzePolygonChart/AnalyzePolygonChart';
import ResizedImage from 'app/components/ResizedImage';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser, getAreaUnit} from 'app/modules/Remote/Organization';
import {PolygonFeature} from 'app/providers/MapPolygonStateProvider';
import {useShareLink} from 'app/providers/ShareLinkProvider';
import {StateApiNote, getAttachmentsByType} from 'app/stores/NotesStore';
import colors from 'app/styles/colors.json';
import * as firebaseUtils from 'app/utils/firebaseUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as noteUtils from 'app/utils/noteUtils';
import * as tagUtils from 'app/utils/tagUtils';
import * as userUtils from 'app/utils/userUtils';

import cs from './NoteCard.styl';
import {SceneLocationPopover} from './SceneLocationPopover';
import {UnenrolledLayerMessage} from './UnenrolledLayerMessage';
import {parseGraphIsoStringsAsDates} from '../AnalyzePolygonChart/utils';

const NoteView: React.FunctionComponent<
  React.PropsWithChildren<{
    profile: I.ImmutableOf<ApiOrganizationUser>;
    organization: I.ImmutableOf<ApiOrganization>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    feature: I.ImmutableOf<ApiFeature>;
    note: StateApiNote;
    isAADisabled?: boolean;
    cameraOptions: mapUtils.CameraOptions | null;
    onDelete: (event: MouseEvent<HTMLElement>) => void;
    onEdit: (event: MouseEvent<HTMLElement>) => void;
    onFocusNote: (event: MouseEvent<HTMLElement>) => void;
    onHoverNote: (event: MouseEvent<HTMLElement>) => void;
    onUnhoverNote: (event: MouseEvent<HTMLElement>) => void;
    disableEditing?: boolean;
    isFocused?: boolean;
    openAnalyzePolygonPopup?: (feature: PolygonFeature) => unknown;
    isChartLoading?: boolean;
    enrolledLayerKeys?: string[];
    setShowRerunChartAnalysisTool?: (showRerunChartAnalysisTool: boolean) => void;
  }>
> = ({
  profile,
  organization,
  featureCollection,
  feature,
  note,
  isAADisabled,
  onDelete,
  onEdit,
  onFocusNote,
  onHoverNote,
  onUnhoverNote,
  disableEditing,
  isFocused,
  openAnalyzePolygonPopup,
  isChartLoading,
  enrolledLayerKeys,
  setShowRerunChartAnalysisTool,
}) => {
  const {documents, images} = getAttachmentsByType(note.attachments);

  const isEditor = noteUtils.mayEditNote(note, profile);

  const [isOptionPressed, setIsOptionPressed] = React.useState(false);

  const hasGeometry = !!note.geometry;
  const hasLayer = noteUtils.imageRefsArentBasemap(note.imageRefs);

  //check if the note is associated with an unenrolled layer
  const hasUnenrolledLayer =
    hasLayer && enrolledLayerKeys && noteUtils.noteHasUnenrolledLayers(note, enrolledLayerKeys);

  const hasPolygonalGeometry =
    note.geometry?.type === 'Polygon' || note.geometry?.type === 'MultiPolygon';

  const activeTags = React.useMemo(() => {
    const tagIds = tagUtils.getNoteTagIds(I.fromJS(note));
    const tagSettings = tagUtils.getTagSettings(featureCollection, tagUtils.TAG_KIND.NOTE);

    return tagSettings.filter((t) => tagIds.includes(t!.get('id'))).toArray();
  }, [featureCollection, note]);

  const {hasUnshareableConfiguration, openShareLinkModalWithNote} = useShareLink();
  const unshareableNote = hasUnshareableConfiguration(note.imageRefs);

  // We don’t want to switch to a loader if we’re just updating the tags.
  if (note.isCreating || note.isUpdating) {
    return (
      <div className={cs.card}>
        <B.Spinner className={cs.spinner} size={B.SpinnerSize.SMALL} />
      </div>
    );
  }

  const headerActions = (
    <div className={cs.headerActions}>
      {(hasGeometry || hasLayer) && <SceneLocationPopover note={note} />}
      <B.Popover
        position="bottom"
        minimal
        interactionKind={B.PopoverInteractionKind.HOVER}
        modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
        content={
          <B.Menu style={{minWidth: 0}}>
            {isEditor && (
              <>
                <B.MenuItem icon="edit" text="Edit" onClick={onEdit} disabled={disableEditing} />

                {hasPolygonalGeometry && (
                  <B.MenuItem
                    icon="download"
                    text="Export shapefile"
                    onClick={() =>
                      noteUtils.downloadNotesShapefile(
                        [note],
                        feature.getIn(['properties', 'name'])
                      )
                    }
                  />
                )}
              </>
            )}

            {hasPolygonalGeometry && openAnalyzePolygonPopup && (
              <B.MenuItem
                disabled={isAADisabled}
                icon="timeline-line-chart"
                text={isAADisabled ? 'Open analysis (loading)' : 'Open analysis'}
                onClick={() => {
                  const geometry = note.geometry! as geojson.Polygon | geojson.MultiPolygon;
                  openAnalyzePolygonPopup(
                    geoJsonUtils.feature(
                      geometry,
                      {noteGraphLayerKey: note.graph?.layerKey},
                      {id: `${geometry.type}-from-note`}
                    )
                  );
                }}
              />
            )}

            {hasLayer && (
              <B.Tooltip
                disabled={!unshareableNote}
                content={
                  'This note includes a commercial dataset that is not available for public sharing.'
                }
                className={cs.menuTooltip}
              >
                <B.MenuItem
                  icon="share"
                  text="Share"
                  disabled={unshareableNote}
                  onClick={(e) => {
                    e.stopPropagation();
                    openShareLinkModalWithNote(
                      note.imageRefs,
                      note.text,
                      note.attachments,
                      note.graph,
                      note.geometry
                    );
                  }}
                />
              </B.Tooltip>
            )}

            {isEditor && (
              <>
                <B.MenuDivider />
                <B.MenuItem
                  icon="trash"
                  text="Delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(e);
                  }}
                />
              </>
            )}

            {userUtils.showAnalyzeAreaInternalTools(profile, organization, isOptionPressed) &&
              !!setShowRerunChartAnalysisTool && (
                <>
                  <B.MenuDivider />
                  <B.MenuItem
                    icon="lightning"
                    text="Rerun Analysis"
                    onClick={(e) => {
                      setShowRerunChartAnalysisTool(true);
                      onEdit(e);
                    }}
                    disabled={disableEditing}
                  />
                </>
              )}
          </B.Menu>
        }
      >
        <B.AnchorButton
          small
          minimal
          icon={
            <B.Icon
              icon="more"
              color={colors.gray}
              size={14}
              onClick={(e) => {
                e.stopPropagation();
                setIsOptionPressed(e.altKey);
              }}
            />
          }
        />
      </B.Popover>
    </div>
  );

  let headerText = `${note.name} ${noteUtils.BULLET} ${imageryUtils.formatDate(note.createdAt)}`;
  if (note.createdAt !== note.updatedAt) {
    headerText += ` (updated ${imageryUtils.formatDate(note.updatedAt)})`;
  }

  return (
    <B.Card
      interactive
      className={classnames(cs.card, {
        [cs.focusedNote]: isFocused,
      })}
      onClick={onFocusNote}
      onMouseEnter={onHoverNote}
      onMouseLeave={onUnhoverNote}
    >
      {hasUnenrolledLayer && <UnenrolledLayerMessage note={note} />}

      <div className={cs.body}>
        <div className={cs.header}>
          <div className={cs.headerText}>
            <div className={cs.headerTextChild} title={headerText}>
              {headerText}
            </div>
          </div>
          {headerActions}
        </div>

        <p>{note.text}</p>

        {!!note.graph && !hasUnenrolledLayer && (
          <AnalyzePolygonChart
            graph={parseGraphIsoStringsAsDates(note.graph)}
            imageRefs={note.imageRefs}
            areaInM2={noteUtils.getNoteAreaInM2(note)}
            areaUnit={getAreaUnit(organization)}
            disablePanZoom={true}
            hideLegend={false}
            showTitle={true}
            isLegendInteractive={false}
            loading={isChartLoading}
            graphStyle={{height: 150}}
            isExpanded={false}
          />
        )}

        {!!documents.length && (
          <div className={cs.files}>
            {documents.map((attachment, i) => (
              <a key={i} href={attachment} target="_blank" rel="noopener noreferrer">
                {firebaseUtils.getFileNameFromObjectDownloadUrl(attachment!)}
              </a>
            ))}
          </div>
        )}

        {!!images.length && (
          <div className={cs.images}>
            {images.map((image, i) => (
              <a
                key={i}
                target="_blank"
                rel="noopener noreferrer"
                download="file"
                href={image}
                title={firebaseUtils.getFileNameFromObjectDownloadUrl(image!) || undefined}
              >
                <ResizedImage imageUrl={image} dimension={300} />
              </a>
            ))}
          </div>
        )}
        <React.Fragment>
          {activeTags.map((tag) => (
            <tagUtils.Tag key={tag!.get('id')} setting={tag!} className={cs.tagMargins} />
          ))}
        </React.Fragment>
      </div>
    </B.Card>
  );
};

export default NoteView;
