import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {AlertPolicy, ApiFeature} from 'app/modules/Remote/Feature/types';
import {AlertThresholdDirection} from 'app/modules/Remote/Feature/types';
import {CategoryAlertProperties, ThresholdAlertProperties} from 'app/modules/Remote/Feature/types';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import colors from 'app/utils/colorUtils';
import {CUSTOM_ALERT_TYPE_CATEGORY, CUSTOM_ALERT_TYPE_THRESHOLD} from 'app/utils/constants';
import {alphanumericSort} from 'app/utils/featureUtils';
import {DataLayerInfo, ImageLayerInfo, NLCD_LANDCOVER} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';

import * as cs from './styles.styl';
import {ComboSlider, ThresholdSlider} from '../Sliders';
import {useAlertPolicies} from './AlertPolicyProvider';
import {FeatureMultiSelect} from '../FeatureMultiSelect';
import {CategorySelectorPair} from './CategorySelectorPair';
import {DatasetSelect} from './DatasetSelect';

export interface AlertConfigurationModalProps {
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;
  allFeatures: ApiFeature[];
  selectedFeatureId: number;
  alertPolicy?: AlertPolicy;
  isOpen: boolean;
  onClose: () => void;
}

interface GenericFormState {
  name: string;
  description: string;
  enrolledIds: number[];
}
type CategoryAlertFormState = GenericFormState & {
  alertType: typeof CUSTOM_ALERT_TYPE_CATEGORY;
  layer: ImageLayerInfo;
  properties: CategoryAlertProperties;
};

type ThresholdAlertFormState = GenericFormState & {
  alertType: typeof CUSTOM_ALERT_TYPE_THRESHOLD;
  layer: DataLayerInfo;
  properties: ThresholdAlertProperties;
};

export type AlertFormState = CategoryAlertFormState | ThresholdAlertFormState;

const errorMessages = {
  MISSING_NAME: 'Please name the Lookout policy',
  MISSING_LAYER: 'Please make a layer selection',
  DUPLICATE_NAME: 'Another Lookout policy exists with this name, these must be unique',
  MISSING_CATEGORIES: 'Please select From and To categories for your Lookout policy',
  ALERT_TYPE_CHANGE:
    'This dataset uses a different alert type. To use this dataset, please create a new lookout policy instead.',
};

const getFormStateForAlertPolicy = (alertPolicy: AlertPolicy): AlertFormState => {
  const layerKey = layerUtils.getLayerKeyFromSourceAndLayer(
    alertPolicy.sourceId,
    alertPolicy.layerId
  );
  const layer = layerUtils.getLayer(layerKey);

  return {
    ...(alertPolicy.alertType === CUSTOM_ALERT_TYPE_THRESHOLD
      ? {
          properties: {
            ...alertPolicy.properties,
            percentOfProperty: alertPolicy.properties.percentOfProperty
              ? Math.round(alertPolicy.properties.percentOfProperty * 100)
              : 100,
            numConsecutiveScenes: alertPolicy.properties.numConsecutiveScenes || 1,
          },
          layer: layer as DataLayerInfo,
          alertType: CUSTOM_ALERT_TYPE_THRESHOLD,
        }
      : {
          properties: alertPolicy.properties,
          layer: layer as ImageLayerInfo,
          alertType: CUSTOM_ALERT_TYPE_CATEGORY,
        }),
    name: alertPolicy.name,
    description: alertPolicy.description,
    enrolledIds: alertPolicy.enrollments.map((enrollment) => enrollment.featureId),
  };
};

const getDefaultFormState = (layerKey: string): AlertFormState => {
  const layer = layerUtils.getLayer(layerKey);

  return layer.type === 'data'
    ? {
        properties: {
          value: layer.dataRange[0],
          direction: AlertThresholdDirection.Above,
          percentOfProperty: 100,
          numConsecutiveScenes: 1,
        },
        layer: layer as DataLayerInfo,
        alertType: CUSTOM_ALERT_TYPE_THRESHOLD,
        name: '',
        description: '',
        enrolledIds: [],
      }
    : {
        properties: {
          // If not data layer, assume categorical
          fromCategories: [],
          toCategories: [],
        },
        layer: layer as ImageLayerInfo,
        alertType: CUSTOM_ALERT_TYPE_CATEGORY,
        name: '',
        description: '',
        enrolledIds: [],
      };
};

const checkFeatureSelectionsEqual = (a: number[], b: number[]): boolean => {
  if (a.length !== b.length) return false;
  return a.every((id, i) => id === b[i]);
};

export const AlertConfigurationModal: React.FC<AlertConfigurationModalProps> = ({
  featureCollection,
  allFeatures,
  alertPolicy,
  isOpen,
  onClose,
  selectedFeatureId,
}) => {
  const updateMode = !!alertPolicy;

  // Adapted from AnalyzePlygonPopupWithState, but we aren't checking whether
  // the individual features have data for each layerKey.
  const availableLayerKeys = React.useMemo(
    () => {
      return layerUtils
        .getLayerMenuOptionsForFeatureCollection(featureCollection)
        .filter((key) => {
          const layer = layerUtils.getLayer(key);
          const {filter: layerFilter} = layer;
          const filterByLayer = layerFilter ? layerFilter(featureCollection) : true;
          const rawLayerFilter = filterByLayer && layerUtils.getRawLayerKey(key);

          // NOTE: For now we are hardcoding out this layer. The lookouts backend currently
          // only supports layers using cogs and NLCD still uses tiles. This is the only relevant
          // layer on tiles and we don't have an easy generalized way for checking that here
          const isNLCD = key === NLCD_LANDCOVER;

          return rawLayerFilter && !isNLCD;
        })
        .sort((a, b) =>
          alphanumericSort(layerUtils.getLayer(a).display, layerUtils.getLayer(b).display)
        );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // see AnalyzePolygonPopupWithState, ok to ignore as FeatureCollection experiences realtime updates
  );

  const initialFormState = React.useMemo(
    () =>
      updateMode
        ? getFormStateForAlertPolicy(alertPolicy)
        : getDefaultFormState(availableLayerKeys[0]),
    [updateMode, alertPolicy, availableLayerKeys]
  );

  function reducer(formState, action): AlertFormState {
    switch (action.type) {
      case 'SET_THRESHOLD_SLIDER_VALUE':
        return {
          ...formState,
          properties: {
            ...formState.properties,
            value: action.value,
          },
        };
      case 'SET_THRESHOLD_DIRECTION':
        return {
          ...formState,
          properties: {
            ...formState.properties,
            direction: action.direction,
          },
        };
      case 'SET_THRESHOLD_PERCENTAGE':
        return {
          ...formState,
          properties: {
            ...formState.properties,
            percentOfProperty: action.value,
          },
        };
      case 'SET_NUM_SCENES':
        return {
          ...formState,
          properties: {
            ...formState.properties,
            numConsecutiveScenes: action.value,
          },
        };
      case 'SET_LAYER':
        return Object.assign(getDefaultFormState(action.layer.key), {
          // Properties we don't want to reset between layer changes go here
          name: formState.name,
          description: formState.description,
          enrolledIds: formState.enrolledIds,
        });
      case 'SET_NAME':
        return {...formState, name: action.name};
      case 'SET_DESCRIPTION':
        return {...formState, description: action.description};
      case 'SET_IDS_TO_ENROLL':
        return {...formState, enrolledIds: action.enrolledIds};
      case 'SET_TO_CATEGORIES':
        return {
          ...formState,
          properties: {
            ...formState.properties,
            toCategories: action.categories,
          },
        };
      case 'SET_FROM_CATEGORIES':
        return {
          ...formState,
          properties: {
            ...formState.properties,
            fromCategories: action.categories,
          },
        };
      case 'RESET':
        return initialFormState;
      case 'CLOSE':
        return getDefaultFormState(availableLayerKeys[0]);
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  const [formState, dispatch] = React.useReducer(reducer, initialFormState);

  /**
   * Two additional actions are needed for the reset button and the close button
   * as the default behavior of the blueprint dialog is to have the modal
   * persist on the page. As such, it's state does not update when the modal is
   * closed and reopened.
   */
  const handleReset = () => {
    dispatch({type: 'RESET'});
    // If the layer has a dataRange, set the threshold value to the minimum
    if (!updateMode && 'dataRange' in formState.layer) {
      dispatch({type: 'SET_THRESHOLD_SLIDER_VALUE', value: formState.layer.dataRange[0]});
    }
  };

  const handleClose = () => {
    dispatch({type: 'CLOSE'});
    onClose();
  };

  const {
    alertPolicies,
    actions: {
      createAlertPolicy,
      updateAlertPolicy,
      enrollFeaturesInAlertPolicy,
      unenrollFeaturesInAlertPolicy,
    },
    meta: {isFetching},
  } = useAlertPolicies();

  const alertPolicyNames = React.useMemo(
    () => alertPolicies.map((alertPolicy) => alertPolicy.name),
    [alertPolicies]
  );

  const featuresEnrolledInAlert: ApiFeature[] = React.useMemo(() => {
    if (!updateMode) return [];
    const enrolledFeatureIDs = alertPolicy.enrollments.map((enrollment) => enrollment.featureId);
    return allFeatures.filter((feature) => enrolledFeatureIDs.includes(feature?.id));
  }, [alertPolicy, updateMode, allFeatures]);

  const currentFeature: ApiFeature | undefined = React.useMemo(() => {
    return allFeatures.find((feature) => feature?.id === selectedFeatureId);
  }, [allFeatures, selectedFeatureId]);

  const validateNameIsNotDuplicate = (name: string) => {
    return (updateMode && alertPolicy.name === name) || !alertPolicyNames.includes(name);
  };

  const compareFeatureIDLists = (
    previous: number[],
    current: number[]
  ): {
    inPreviousButNotCurrent: number[];
    inCurrentButNotPrevious: number[];
  } => {
    const previousDiff = previous.filter((id) => !current.includes(id));
    const currentDiff = current.filter((id) => !previous.includes(id));
    return {
      inPreviousButNotCurrent: previousDiff,
      inCurrentButNotPrevious: currentDiff,
    };
  };

  const validateCategoriesSelected = (formState: AlertFormState): boolean => {
    if (formState.alertType !== CUSTOM_ALERT_TYPE_CATEGORY) return true;
    return (
      !!formState.properties.toCategories.length && !!formState.properties.fromCategories.length
    );
  };

  const validateAlertTypeNotChanged = (formState: AlertFormState): boolean => {
    // Only applies in update mode
    if (!updateMode || !alertPolicy) return true;

    // Check if alert type has changed
    return formState.alertType === alertPolicy.alertType;
  };

  const validateAlertPolicy = (formState: AlertFormState): [false, string] | [true, null] => {
    const validations: [boolean, string][] = [
      [!!formState.name, errorMessages.MISSING_NAME],
      [validateNameIsNotDuplicate(formState.name), errorMessages.DUPLICATE_NAME],
      [!!formState.layer, errorMessages.MISSING_LAYER],
      [validateCategoriesSelected(formState), errorMessages.MISSING_CATEGORIES],
      [validateAlertTypeNotChanged(formState), errorMessages.ALERT_TYPE_CHANGE],
    ];

    for (const [validation, error] of validations) {
      if (!validation) {
        return [false, error];
      }
    }
    return [true, null];
  };

  const handleEnrollmentUpdates = async (alertPolicy: AlertPolicy, formState: AlertFormState) => {
    const updatedAlertPolicy = await updateAlertPolicy(alertPolicy, formState);
    const {inPreviousButNotCurrent, inCurrentButNotPrevious} = compareFeatureIDLists(
      alertPolicy.enrollments.map((enrollment) => enrollment.featureId),
      formState.enrolledIds
    );
    const updatedPolicyWithEnrollments = {
      ...updatedAlertPolicy,
      enrollments: alertPolicy.enrollments,
    } as AlertPolicy;
    const arraysAreEqual =
      inPreviousButNotCurrent.length === 0 && inCurrentButNotPrevious.length === 0;
    if (arraysAreEqual) return;
    if (inCurrentButNotPrevious.length)
      await enrollFeaturesInAlertPolicy(
        updatedPolicyWithEnrollments,
        inCurrentButNotPrevious,
        true
      );
    if (inPreviousButNotCurrent.length)
      await unenrollFeaturesInAlertPolicy(updatedPolicyWithEnrollments, inPreviousButNotCurrent);
  };

  const submitForm = async () => {
    const [isValid, error] = validateAlertPolicy(formState);
    if (!isValid) console.error(error); // User should not hit this case with current validations.
    if (updateMode) {
      await handleEnrollmentUpdates(alertPolicy, formState);
    } else {
      const newAlertPolicy = await createAlertPolicy(featureCollection, formState);
      if (formState.enrolledIds.length) {
        await enrollFeaturesInAlertPolicy(
          {...(newAlertPolicy as AlertPolicy), enrollments: []},
          formState.enrolledIds,
          true
        );
      }
    }
    handleClose();
  };

  const getEnrollmentDiffText = (previous: number[], current: number[]): string => {
    const {inPreviousButNotCurrent, inCurrentButNotPrevious} = compareFeatureIDLists(
      previous,
      current
    );
    if (inPreviousButNotCurrent.length === 0 && inCurrentButNotPrevious.length === 0) return '';
    const returnStringArray: string[] = [];
    if (inCurrentButNotPrevious.length)
      returnStringArray.push(
        `${inCurrentButNotPrevious.length} ${
          inCurrentButNotPrevious.length === 1 ? 'property' : 'properties'
        } will be enrolled`
      );
    if (inPreviousButNotCurrent.length)
      returnStringArray.push(
        `${inPreviousButNotCurrent.length} ${
          inPreviousButNotCurrent.length === 1 ? 'property' : 'properties'
        } will be unenrolled`
      );
    return returnStringArray.join(' and ') + '.';
  };

  const alertConfigurationForm: JSX.Element = (
    <div className={cs.dialogPanel}>
      <B.FormGroup
        label="Name"
        subLabel={
          !validateNameIsNotDuplicate(formState.name) ? (
            <span style={{color: colors.red}}>{errorMessages.DUPLICATE_NAME}</span>
          ) : (
            'A descriptive name will help identify change notifications later.'
          )
        }
      >
        <B.InputGroup
          intent={!validateNameIsNotDuplicate(formState.name) ? B.Intent.DANGER : B.Intent.NONE}
          value={formState.name}
          onChange={({currentTarget: {value}}) => dispatch({type: 'SET_NAME', name: value})}
        />
      </B.FormGroup>

      <B.FormGroup
        label={
          <div className={cs.datasetLabelContainer}>
            <span>Dataset</span>
            {updateMode && (
              <B.Tooltip
                content={
                  <div className={cs.datasetTooltipContent}>
                    <p>
                      You're editing a {alertPolicy.alertType} lookout. Only {alertPolicy.alertType}{' '}
                      datasets can be selected. To choose a different type of dataset, create a new
                      lookout.
                    </p>
                  </div>
                }
                position={B.Position.BOTTOM}
              >
                <B.Icon icon="info-sign" size={12} className={cs.datasetInfoIcon} />
              </B.Tooltip>
            )}
          </div>
        }
      >
        <div className={cs.datasetSelectContainer}>
          <DatasetSelect
            onItemSelect={(layerKey) => {
              const newLayer = layerUtils.getLayer(layerKey);
              dispatch({type: 'SET_LAYER', layer: newLayer});
            }}
            activeLayerInfo={formState.layer}
            availableLayerKeys={
              // Filter out layers that don't match the alert type in update mode
              updateMode && alertPolicy
                ? availableLayerKeys.filter((layerKey) => {
                    const layer = layerUtils.getLayer(layerKey);
                    const layerType =
                      layer.type === 'data'
                        ? CUSTOM_ALERT_TYPE_THRESHOLD
                        : CUSTOM_ALERT_TYPE_CATEGORY;
                    return layerType === alertPolicy.alertType;
                  })
                : availableLayerKeys
            }
          />
        </div>
      </B.FormGroup>

      {formState.alertType === CUSTOM_ALERT_TYPE_THRESHOLD && (
        <>
          <B.FormGroup
            label="Threshold value"
            subLabel="Drag range slider or enter a number to select your threshold."
          >
            <ThresholdSlider
              layer={formState.layer}
              thresholdValue={formState.properties.value}
              onChangeValue={(value) => dispatch({type: 'SET_THRESHOLD_SLIDER_VALUE', value})}
            />
          </B.FormGroup>
          <B.FormGroup label="Threshold direction">
            <B.RadioGroup
              onChange={({currentTarget: {value}}) => {
                dispatch({type: 'SET_THRESHOLD_DIRECTION', direction: value});
              }}
              selectedValue={formState.properties.direction}
            >
              <B.Radio label="Above" value="above"></B.Radio>
              <B.Radio label="Below" value="below"></B.Radio>
            </B.RadioGroup>
          </B.FormGroup>
          <B.FormGroup
            label="Percentage of property area"
            subLabel="Specify the minimum percent of property area where threshold criteria are met."
          >
            <ComboSlider
              min={0}
              max={100}
              step={1}
              value={formState.properties.percentOfProperty}
              onChangeValue={(value) => dispatch({type: 'SET_THRESHOLD_PERCENTAGE', value})}
              labels={['0%', '100%']}
            />
          </B.FormGroup>
          <B.FormGroup
            label="Number of scenes"
            subLabel="Specify how many consecutive scenes must meet the criteria before a notification is sent."
          >
            <B.NumericInput
              fill={true}
              className={cs.compressedNumericInput}
              value={formState.properties.numConsecutiveScenes}
              onValueChange={(value) => dispatch({type: 'SET_NUM_SCENES', value})}
              min={1}
            />
          </B.FormGroup>
        </>
      )}

      {formState.alertType === CUSTOM_ALERT_TYPE_CATEGORY && formState.layer.layerLegendMap && (
        <>
          <B.FormGroup label="Categories">
            <CategorySelectorPair
              layerLegendMap={formState.layer.layerLegendMap}
              onChangeLeft={(items) => dispatch({type: 'SET_FROM_CATEGORIES', categories: items})}
              onChangeRight={(items) => dispatch({type: 'SET_TO_CATEGORIES', categories: items})}
              leftSelectedItems={formState.properties.fromCategories}
              rightSelectedItems={formState.properties.toCategories}
            />
          </B.FormGroup>
        </>
      )}

      <B.FormGroup
        label="Description"
        subLabel="Add additional context for you and your team to reference later."
        labelInfo="(optional)"
      >
        <B.TextArea
          fill={true}
          value={formState.description}
          onChange={({currentTarget: {value}}) =>
            dispatch({type: 'SET_DESCRIPTION', description: value})
          }
        />
      </B.FormGroup>
      {formState.alertType === CUSTOM_ALERT_TYPE_THRESHOLD ? (
        <p>
          You will be notified when {formState.layer.display} values{' '}
          {formState.properties.numConsecutiveScenes > 1 &&
            `in the last ${formState.properties.numConsecutiveScenes} scenes`}{' '}
          are {formState.properties.direction} {formState.properties.value} on{' '}
          {formState.properties.percentOfProperty}% of your property.
        </p>
      ) : (
        <p>
          You will be notified when {formState.layer.display} data changes based on the categories
          selected above.
        </p>
      )}
    </div>
  );

  const alertEnrollmentForm: JSX.Element = (
    <div className={cs.dialogPanel}>
      <B.FormGroup label="Select properties to enroll:">
        <FeatureMultiSelect
          features={allFeatures}
          onSelectionUpdate={(selection) => {
            const selectionIDs = selection.map((f) => f.properties.id);
            if (
              formState.enrolledIds &&
              !checkFeatureSelectionsEqual(selectionIDs, formState.enrolledIds)
            )
              dispatch({
                type: 'SET_IDS_TO_ENROLL',
                enrolledIds: selectionIDs,
              });
          }}
          initialSelection={
            updateMode ? featuresEnrolledInAlert : currentFeature ? [currentFeature] : []
          }
          placeholder="Click or type here to select properties to enroll in this alert."
          radioSelectionProps={{
            title: null,
            someText: 'Or manually select specific properties:',
          }}
          resetButtonProps={{
            hidden: !updateMode,
            tooltip: 'Reset selection to properties currently enrolled in alert',
          }}
        />
      </B.FormGroup>
      <span>
        {getEnrollmentDiffText(
          alertPolicy?.enrollments.map((enrollment) => enrollment.featureId) || [],
          formState.enrolledIds
        )}
      </span>
    </div>
  );

  return (
    <B.MultistepDialog
      className={cs.alertConfigurationModal}
      isOpen={isOpen}
      title={updateMode ? `Update Lookout Policy: ${formState.name}` : 'Create Lookout Policy'}
      finalButtonProps={{
        text: updateMode ? 'Submit updates' : 'Finish',
        onClick: submitForm,
        loading: isFetching,
      }}
      onClose={handleClose}
      onOpening={handleReset}
      canEscapeKeyClose={!isFetching}
      backButtonProps={{
        text: 'Back',
      }}
      canOutsideClickClose={false}
    >
      <B.DialogStep
        id="configure-alert"
        title="Configure"
        nextButtonProps={{
          disabled: !validateAlertPolicy(formState)[0],
        }}
        panel={alertConfigurationForm}
      />
      <B.DialogStep id="enroll-properties" title="Enroll" panel={alertEnrollmentForm} />
    </B.MultistepDialog>
  );
};
