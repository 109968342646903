import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import DateSelectCard, {HiddenImageAction} from 'app/components/DateSelectCard';
import FeatureMiniMap from 'app/components/FeatureMiniMap/FeatureMiniMap';
import {THUMBNAIL_BLOCKED_SOURCES} from 'app/components/OrderImageryModal';
import history from 'app/history';
import {ApiFeature, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {useAuth} from 'app/providers/AuthProvider';
import {OrderableScenesLoader} from 'app/providers/OrderableScenesProvider';
import * as featureUtils from 'app/utils/featureUtils';
import {useContinuity, useStateWithDeps} from 'app/utils/hookUtils';
import {canLoadOrderableSceneOnMap, getLayerKeyForScene} from 'app/utils/imageryUtils';

import * as cs from './ImageryPurchaseDrawer.styl';

const ImageryPurchaseDrawer: React.FunctionComponent<
  React.PropsWithChildren<{
    year: number;
    features: I.ImmutableOf<ApiFeature[]>;
    organization: I.ImmutableOf<ApiOrganization>;

    orderableScenesLoader: OrderableScenesLoader;
    makeSceneUrl: (featureLensId: string, sourceId: string, date: string) => string;
    blockedSources?: string[];

    onPreviewOrderableScene: (
      feature: I.ImmutableOf<ApiFeature>,
      orderableScene: I.ImmutableOf<ApiOrderableScene>
    ) => void;
    onClose: () => void;
    /** Pass null if the user is not allowed to change visibility */
    setIsImageHidden:
      | null
      | ((
          f: I.ImmutableOf<ApiFeature>,
          orderableScene: I.ImmutableOf<ApiOrderableScene>,
          isHidden: boolean
        ) => void);

    activeFeatureId?: number;
  }>
> = ({
  features,
  organization,
  year,
  orderableScenesLoader,
  onPreviewOrderableScene,
  onClose,
  setIsImageHidden,
  makeSceneUrl,
  blockedSources = THUMBNAIL_BLOCKED_SOURCES,
  activeFeatureId,
}) => {
  const featureIds = useContinuity(
    features.map((f) => f!.get('id')),
    I.is
  );

  // Resets to the first feature ID if the list of feature IDs changes.
  const [currentFeatureId, setCurrentFeatureId] = useStateWithDeps<number | undefined>(
    activeFeatureId ?? featureIds.first(),
    [featureIds]
  );

  const currentFeature =
    currentFeatureId === undefined
      ? undefined
      : features.find((f) => f!.get('id') === currentFeatureId);

  const currentFeatureLensId = currentFeature?.getIn(['properties', 'lensId']);

  const orderableScenesStatusMaybe =
    currentFeatureId === undefined ? undefined : orderableScenesLoader(currentFeatureId);

  const auth = useAuth();
  const firebaseToken = auth.status === 'logged in' ? auth.firebaseToken : null;

  const allOrderableScenes: I.ImmutableOf<ApiOrderableScene[]> =
    orderableScenesStatusMaybe && orderableScenesStatusMaybe.value
      ? orderableScenesStatusMaybe.value
      : I.List();
  const orderableScenes = allOrderableScenes.filter(
    (d) => moment(d!.get('sensingTime')).year() === year
  );

  const purchasedScenes = React.useMemo(
    () => currentFeature && orderableScenes.filter((d) => d!.get('isOrdered')),
    [orderableScenes, currentFeature]
  );

  const availableScenes = React.useMemo(
    () =>
      currentFeature &&
      orderableScenes.filter(
        (d) => !d?.get('isOrdered') && !featureUtils.isOrderableSceneHidden(currentFeature, d!)
      ),
    [orderableScenes, currentFeature]
  );

  const hiddenScenes = React.useMemo(
    () =>
      currentFeature &&
      orderableScenes?.filter(
        (d) => !d?.get('isOrdered') && featureUtils.isOrderableSceneHidden(currentFeature, d!)
      ),
    [orderableScenes, currentFeature]
  );
  const [hoveredFeatureId, setHoveredFeatureId] = React.useState<number | null>(null);
  const [showHiddenImagery, setShowHiddenImagery] = React.useState(false);

  return (
    <B.Drawer isOpen={!!currentFeature} size={460} className={cs.drawer} onClose={() => onClose()}>
      {currentFeature && (
        <>
          <div className={cs.drawerHeader}>
            <B.Button minimal icon="small-cross" onClick={() => onClose()} />
            <h3>{currentFeature.getIn(['properties', 'name'])}</h3>
          </div>

          <div className={cs.drawerContentWrapper}>
            {currentFeatureId !== undefined && (
              <div className={cs.miniMap}>
                <FeatureMiniMap
                  features={features}
                  selectedFeatureId={currentFeatureId}
                  setSelectedFeatureId={setCurrentFeatureId}
                  hoveredFeatureId={hoveredFeatureId}
                  hoverFeatureById={setHoveredFeatureId}
                  width={420}
                  height={200}
                />

                {features.size > 1 && (
                  <div className={cs.miniMapNote}>{features.size} locations</div>
                )}
              </div>
            )}

            <div className={cs.drawerContent}>
              {orderableScenesStatusMaybe?.status === 'unknown' ||
              !availableScenes ||
              !purchasedScenes ||
              !hiddenScenes ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <B.Spinner />
                </div>
              ) : (
                <>
                  {orderableScenes.isEmpty() && (
                    <B.NonIdealState className={cs.message} iconMuted={false}>
                      This location does not have any high-res imagery for the selected year.
                    </B.NonIdealState>
                  )}

                  {!purchasedScenes.isEmpty() && (
                    <>
                      <h4 className={cs.imageryHeader}>Ordered</h4>

                      {purchasedScenes.map((scene) => {
                        const canLoadSceneOnMap = canLoadOrderableSceneOnMap(scene!);
                        const layerKey = getLayerKeyForScene(scene!);
                        return (
                          <DateSelectCard
                            key={scene!.get('sensingTime') + scene!.get('sourceId')}
                            style={{cursor: canLoadSceneOnMap ? 'pointer' : 'default'}}
                            layerKey={layerKey}
                            orderableScene={scene!}
                            organization={organization}
                            isOrdered
                            blockedSources={blockedSources}
                            onClick={(ev) => {
                              ev.stopPropagation();

                              if (scene!.get('orderProcessed') && canLoadSceneOnMap) {
                                const url = makeSceneUrl(
                                  currentFeatureLensId!,
                                  scene!.get('sourceId'),
                                  scene!.get('sensingTime')
                                );

                                if (ev.shiftKey || ev.metaKey) {
                                  window.open(url, ev.metaKey ? '_blank' : undefined);
                                } else {
                                  // hack to remove the domain
                                  history.push(url.replace(/^https?:\/\/[^/]+/, ''));
                                }
                              }
                            }}
                            renderRightElement={() =>
                              canLoadSceneOnMap ? (
                                <B.Tag intent={B.Intent.NONE} minimal>
                                  {scene!.get('orderProcessed') ? 'View' : 'Processing'}
                                </B.Tag>
                              ) : null
                            }
                            firebaseToken={firebaseToken}
                          />
                        );
                      })}
                    </>
                  )}

                  {!availableScenes.isEmpty() && (
                    <>
                      <h4 className={cs.imageryHeader}>Available to Order</h4>

                      {availableScenes.map((scene) => (
                        <DateSelectCard
                          key={scene!.get('sensingTime') + scene!.get('sourceId')}
                          layerKey={getLayerKeyForScene(scene!)}
                          orderableScene={scene!}
                          organization={organization}
                          isOrdered={false}
                          onClick={() => {
                            onPreviewOrderableScene(currentFeature, scene!);
                          }}
                          blockedSources={blockedSources}
                          renderRightElement={() =>
                            makeDateSelectButton(
                              currentFeature,
                              scene!,
                              false,
                              (orderableScene: I.ImmutableOf<ApiOrderableScene>) =>
                                onPreviewOrderableScene(currentFeature, orderableScene),
                              setIsImageHidden
                            )
                          }
                          firebaseToken={firebaseToken}
                        />
                      ))}
                    </>
                  )}

                  {!hiddenScenes.isEmpty() && (
                    <>
                      <div className={cs.imageryHeaderRow}>
                        <h4 className={cs.imageryHeader}>Hidden Imagery</h4>
                        <a
                          onClick={(ev) => {
                            ev.preventDefault();
                            setShowHiddenImagery(!showHiddenImagery);
                          }}
                        >
                          {showHiddenImagery ? 'Hide' : `Show ${hiddenScenes.size}`}
                        </a>
                      </div>

                      {showHiddenImagery &&
                        hiddenScenes.map((scene) => (
                          <DateSelectCard
                            key={scene!.get('sensingTime') + scene!.get('sourceId')}
                            layerKey={getLayerKeyForScene(scene!)}
                            orderableScene={scene!}
                            organization={organization}
                            isOrdered={false}
                            blockedSources={blockedSources}
                            renderRightElement={() =>
                              makeDateSelectButton(
                                currentFeature,
                                scene!,
                                true,
                                (orderableScene: I.ImmutableOf<ApiOrderableScene>) =>
                                  onPreviewOrderableScene(currentFeature, orderableScene),
                                setIsImageHidden
                              )
                            }
                            firebaseToken={firebaseToken}
                          />
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </B.Drawer>
  );
};

function makeDateSelectButton(
  feature: I.ImmutableOf<ApiFeature>,
  orderableScene: I.ImmutableOf<ApiOrderableScene>,
  isHidden: boolean,
  onPreviewOrderableScene: (orderableScene: I.ImmutableOf<ApiOrderableScene>) => void,
  setIsImageHidden:
    | null
    | ((
        f: I.ImmutableOf<ApiFeature>,
        orderableScene: I.ImmutableOf<ApiOrderableScene>,
        isHidden: boolean
      ) => void)
) {
  if (!orderableScene.get('isOrdered')) {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        {setIsImageHidden && (
          <HiddenImageAction
            feature={feature}
            orderableScene={orderableScene}
            isHidden={isHidden}
            setIsImageHidden={setIsImageHidden}
          />
        )}

        <B.Tag
          style={{cursor: 'pointer'}}
          intent={B.Intent.PRIMARY}
          minimal
          onClick={(e) => {
            e.stopPropagation();
            onPreviewOrderableScene(orderableScene);
          }}
        >
          Preview
        </B.Tag>
      </div>
    );
  } else if (orderableScene.get('isOrdered') && !orderableScene.get('orderProcessed')) {
    return <B.Tag minimal>Processing</B.Tag>;
  }

  return null;
}

export default ImageryPurchaseDrawer;
