import * as B from '@blueprintjs/core';
import * as S from '@blueprintjs/select';
import React, {ReactElement} from 'react';

import {getTextContrastColor} from 'app/utils/colorUtils';
import {LayerLegendMap} from 'app/utils/layers';

import * as cs from './styles.styl';

interface CategorySelectorProps {
  layerLegendMap: LayerLegendMap;
  onChangeLeft: (items: string[]) => void;
  onChangeRight: (items: string[]) => void;
  leftSelectedItems: string[];
  rightSelectedItems: string[];
}

export interface LayerLegendListItem {
  color: string;
  label: string;
  key: string;
}

export const CategorySelectorPair: React.FC<CategorySelectorProps> = ({
  leftSelectedItems,
  rightSelectedItems,
  onChangeLeft,
  onChangeRight,
  layerLegendMap,
}) => {
  return (
    <div className={cs.categorySelectorPair}>
      <CategorySelectorColumn
        onChange={onChangeLeft}
        categoryLabel={
          <>
            When pixels change <b>from</b> the following{' '}
            {!!leftSelectedItems.length && `(${leftSelectedItems.length})`}{' '}
            {leftSelectedItems.length === 1 ? 'class' : 'classes'}
          </>
        }
        selectedItems={leftSelectedItems}
        layerLegendMap={layerLegendMap}
      />
      <CategorySelectorColumn
        onChange={onChangeRight}
        categoryLabel={
          <>
            <b>To</b> the following{' '}
            {!!rightSelectedItems.length && `(${rightSelectedItems.length})`}{' '}
            {rightSelectedItems.length === 1 ? 'class' : 'classes'} in the next available scene
          </>
        }
        selectedItems={rightSelectedItems}
        layerLegendMap={layerLegendMap}
      />
    </div>
  );
};

interface CategorySelectorColumnProps {
  layerLegendMap: LayerLegendMap;
  onChange: (items: string[]) => void;
  categoryLabel: ReactElement;
  selectedItems: string[];
}

export const CategorySelectorColumn: React.FC<CategorySelectorColumnProps> = ({
  layerLegendMap,
  onChange,
  categoryLabel,
  selectedItems,
}) => {
  const MultiSelect = S.MultiSelect.ofType<string>();

  const push = (itemKey: string) => {
    onChange([...selectedItems, itemKey]);
  };

  const remove = (itemKey: string) => {
    onChange(selectedItems.filter((key) => key !== itemKey));
  };

  const handleItemSelect = (key: string) => {
    if (selectedItems.includes(key)) {
      remove(key);
    } else {
      push(key);
    }
  };

  const layerLegendKeys = React.useMemo(() => Object.keys(layerLegendMap), [layerLegendMap]);

  return (
    <B.FormGroup subLabel={categoryLabel}>
      <MultiSelect
        fill={true}
        selectedItems={selectedItems}
        items={['any', 'divider', ...layerLegendKeys]}
        placeholder="Select..."
        resetOnSelect
        itemRenderer={(key, {modifiers, handleClick}) => {
          const layerLegendItem = layerLegendMap[key];
          switch (key) {
            case 'divider':
              return <B.MenuDivider key={key} />;
            case 'any':
              return (
                <B.MenuItem
                  key={key}
                  text={'Any'}
                  icon={selectedItems.length === layerLegendKeys.length ? 'tick' : 'blank'}
                  onClick={(e) => {
                    e.stopPropagation();

                    // Unselect all items if all are currently selected
                    const newItems =
                      selectedItems.length === layerLegendKeys.length ? [] : layerLegendKeys;

                    onChange(newItems);
                  }}
                />
              );
            default:
              // NOTE: we need to check that we have a valid
              // layerLegendItem because when switching between
              // layers, this itemRender has the old category keys
              // still.
              // TODO (maya): figure out why ^ and if we can avoid
              if (!layerLegendItem) {
                return null;
              }

              return (
                <B.MenuItem
                  key={key}
                  text={
                    <B.Tag
                      style={{
                        backgroundColor: layerLegendItem.color,
                        color: getTextContrastColor(layerLegendItem.color),
                      }}
                      key={key}
                    >
                      {layerLegendItem.label}
                    </B.Tag>
                  }
                  onClick={handleClick}
                  icon={selectedItems.includes(key) ? 'tick' : 'blank'}
                  active={modifiers.active}
                />
              );
          }
        }}
        itemPredicate={(query, item) => {
          // If there isn't a query string: show all items
          // If we are querying for something: only show categories where the query string
          // is part of the category label string
          return (
            !query ||
            (layerLegendMap[item]?.label.toLowerCase().includes(query.toLowerCase()) &&
              !selectedItems.includes(item))
          );
        }}
        popoverProps={{minimal: true}}
        tagRenderer={(key) => {
          const layerLegendItem = layerLegendMap[key];
          // If the key isn't a valid category, don't render anything.
          // This can be from a user typing in the select field
          if (!layerLegendItem) {
            return null;
          }
          return (
            <span
              style={{
                backgroundColor: layerLegendItem.color,
                color: getTextContrastColor(layerLegendItem.color),
              }}
            >
              {layerLegendItem.label}
            </span>
          );
        }}
        tagInputProps={{
          tagProps: (_tagNode, index) => {
            const targetKeyInSelection = selectedItems[index];
            const layerLegendItem = layerLegendMap[targetKeyInSelection];
            return {
              minimal: true,
              style: {
                backgroundColor: layerLegendItem.color,
              },
              onRemove: () => {
                remove(targetKeyInSelection);
              },
            };
          },
        }}
        onItemSelect={handleItemSelect}
      />
    </B.FormGroup>
  );
};
