import {Feature, FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import isEqual from 'lodash/isEqual';
import mapboxgl from 'mapbox-gl';
import React, {DependencyList, useCallback, useEffect} from 'react';

import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as mathUtils from 'app/utils/mathUtils';

import {MapControlPosition} from '.';

/**
 * Sources don’t have built-in IDs, so we need a data structure to keep track of
 * them.
 */
export interface SourceRecord {
  id: string;
  source: mapboxgl.AnySourceData;
}

export interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  controls: (mapboxgl.Control | mapboxgl.IControl)[];
  sources: readonly SourceRecord[];
  layers: readonly mapboxgl.AnyLayer[];
  /**
   * Position for all controls. If you need some controls in another place, use another MapContent.
   */
  controlPosition: MapControlPosition;
}

/**
 * Returns true if the map is capable of having source / style updates applied
 * to it.
 *
 * Mapbox GL won’t let us modify a style if it’s in the initial stages of
 * loading. In practice, this means that we’ve set the map to a particular
 * style ("satellite") but it’s still loading the JSON definition of that
 * style from the servers.
 *
 * What we need is an easy check that will return true if we can add sources
 * and layers, or false if we can’t but will receive an event when we can.
 *
 * That doesn’t exactly exist.
 *
 * Internally, as of this writing and mapbox-gl 1.1.1, the Style’s mutators
 * are gated by an "_loaded" boolean that is false when the style object is
 * created (which happens in Map’s setStyle) and turns true as soon as the
 * initial JSON is processed (either from a web request or from calling
 * setStyle with JSON).
 *
 * However, the Style#loaded method, which is what Map#isStyleLoaded uses,
 * checks not only _loaded, but also sources, caches, and an image manager,
 * and will return false if any of those are in the process of loading.
 * Unfortunately for us, there’s no reliable event that’s guaranteed to fire
 * after those all have sorted out.
 *
 * So, we’re left with accessing _loaded directly, because the style.load
 * event *will* file consistently once the JSON has been processed. That means
 * we can either do something when isMapReady is true, or wait and do it when
 * style.load fires.
 */
export function isMapReady(map: mapboxgl.Map) {
  // as any because we’re accessing internals.
  return !!((map as any).style && (map as any).style._loaded);
}

/**
 * Helper for DeclarativeMap that manages adding / removing sources, layers, and
 * controls as they or the map updates.
 *
 * Adds controls, sources, and layers when map is loaded. Then re-adds sources
 * and layers when the map style changes, since changing style implictly removes
 * all the layers and sources from the map.
 *
 * Controls are not updated when the map style changes. This is only an issue if
 * the control is managing a source or layer of its own. The control either
 * should be updated to be sensitive to style events, or, if it’s from a third
 * party, try the useMap hook below to write your own code that can respond to
 * style events.
 *
 * Monitors changes to its props and updates / removes things from the map as
 * necessary.
 *
 * Maintains the order of the layers as they appear in the props.
 *
 * Note: the ordering of layers from separate MapComponent instances is based on
 * the order that they attach themselves to the map, since that’s the order that
 * the events will trigger. If you want this to match the order that they’re
 * declared in the component, you’ll need to keep your MapContent components
 * rendering in a consistent order. It’s ok for them to render with no layers
 * (e.g. for the raster overlay when no overlay is selected) but if you
 * dynamically add / remove / reorder entire MapContent components the rendered
 * layer order may not be what you expect.
 *
 * This component is best for "display and forget" use cases. If you need to
 * know when the components are actually added or removed, try the useMap hook.
 */
export default class MapContent extends React.PureComponent<Props> {
  static defaultProps: Omit<Props, 'map' | 'isMapLoaded'> = {
    controls: [],
    sources: [],
    layers: [],
    controlPosition: 'top-left',
  };

  /** Used to track that we’re waiting for a style.load event to update layers
   * and sources. */
  private dirty = false;

  /** Controls we’ve added to the map. Stored as a Set because order doesn’t matter. */
  private controls = new Set<mapboxgl.IControl | mapboxgl.Control>();
  /** Source order also doesn’t matter, but we use a Map by ID since source
   * objects don’t have an id field. */
  private sources = new Map<string, mapboxgl.AnySourceData>();
  /** Array of layers. We need to maintain the right ordering. */
  private layers: readonly mapboxgl.AnyLayer[] = [];

  /**
   * Random ID for a marker we can use to establish our place in the map’s layer
   * stack, even when we’re not directly rendering any layers at the moment.
   * Installed when we first attach to the map (or after a style update) as a
   * bookmark.
   */
  private markerLayerId = `MapContentMarker.${mathUtils.randomCharacters()}`;

  componentDidMount() {
    this.attachToMap();
  }

  componentWillUnmount() {
    this.detachFromMap(this.props.map);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.map !== prevProps.map) {
      // New map? Fresh start. Though this is kinda academic and not something
      // we expect to happen at runtime.
      this.detachFromMap(prevProps.map);
      this.attachToMap();
    } else {
      this.maybeUpdateMap();
    }
  }

  render() {
    // No visible content, just affects the map.
    return null;
  }

  private attachToMap() {
    const {map} = this.props;

    map.on('style.load', this.onStyleLoad);
    map.on('styledataloading', this.onStyleDataLoading);

    this.maybeUpdateMap();
  }

  private detachFromMap(map: mapboxgl.Map) {
    map.off('style.load', this.onStyleLoad);
    map.off('styledataloading', this.onStyleDataLoading);

    this.controls.forEach((c) => {
      map.removeControl(c);
    });

    this.layers.forEach(({id}) => {
      map.removeLayer(id);
    });

    // Might not exist if the map never finished loading in the first place
    if (map.getLayer(this.markerLayerId)) {
      map.removeLayer(this.markerLayerId);
    }

    this.sources.forEach((_, id) => {
      map.removeSource(id);
    });

    this.controls.clear();
    this.sources.clear();
    this.layers = [];
  }

  private onStyleLoad = () => {
    this.updateMap();
  };

  private onStyleDataLoading = () => {
    // If this handler fires it means that the style has been reset and we need
    // to re-add both sources and layers.
    this.sources.clear();
    this.layers = [];

    this.maybeUpdateMap();
  };

  private maybeUpdateMap() {
    this.dirty = true;

    // If we’re ready now we update. If we’re not ready, a future 'style.load'
    // event will fire and we’ll update then.
    if (isMapReady(this.props.map)) {
      this.updateMap();
    }
  }

  private updateMap() {
    // Be defensive because we’ve seen some event-firing recursion from mapbox.
    if (!this.dirty) {
      return;
    }

    this.dirty = false;

    const {map, isMapLoaded, controlPosition} = this.props;

    const newControls = new Set(this.props.controls);
    const newSources = new Map(this.props.sources.map(({id, source}) => [id, source]));
    const newLayers = this.props.layers;

    // add new controls
    newControls.forEach((c) => {
      if (!this.controls.has(c)) {
        map.addControl(c, controlPosition);
      }
    });

    // remove old controls
    this.controls.forEach((c) => {
      if (!newControls.has(c)) {
        map.removeControl(c);
      }
    });

    this.controls = newControls;

    // add / update sources
    newSources.forEach((source, id) => {
      if (!this.sources.has(id)) {
        map.addSource(id, source);
      } else if (!isEqual(this.sources.get(id), source)) {
        const mapSource = map.getSource(id);

        switch (mapSource.type) {
          case 'geojson':
            if (source.type === 'geojson') {
              if (source.data) {
                mapSource.setData(
                  source.data as
                    | Feature<Geometry, GeoJsonProperties>
                    | FeatureCollection<Geometry, GeoJsonProperties>
                );
              } else {
                console.warn(`GeoJSON source ${id} has undefined data`);
              }
            } else {
              console.error(`Cannot update GeoJSON source ${id} with non-GeoJSON source:`, source);
            }
            break;

          case 'image':
            if (source.type === 'image') {
              mapSource.updateImage(source);
            } else {
              console.error(`Cannot update image source ${id} with non-image source:`, source);
            }
            break;

          default:
            // We have to throw up our hands here rather than do a remove/add
            // because Mapbox will error out if we remove a source that is
            // referenced by an existing layer.
            console.warn(`Source ${id} changed, but is of a type we can’t update:`, source);
        }
      }
    });

    // We want the ordering of map layers to be declarative across all
    // MapContent componets that get attached to the map, but we don’t have any
    // tools for doing that explicitly. So, we have each MapContent drop a layer
    // in the order that they’re attached (which is component source order so
    // long as you’re consistent). This layer marks the "top" of our layer
    // stack, as the other layers are all inserted before it.
    if (!map.getLayer(this.markerLayerId)) {
      // Mapbox GL requires a fair amount to make an empty layer. :-P
      map.addLayer({
        id: this.markerLayerId,
        type: 'line',
        source: {type: 'geojson', data: geoJsonUtils.featureCollection([])},
      });
    }

    // We only add our layers if the map is marked as "loaded". This makes sure
    // that any icons referenced are already loaded. See:
    // https://github.com/mapbox/mapbox-gl-js/issues/6231
    if (isMapLoaded && !isEqual(newLayers, this.layers)) {
      // OK layers are hard because we need to preserve order and such. They’re
      // tricky enough that when they change we just remove all of them and
      // re-add in the right order, rather than try to re-order them or
      // anything. This isn’t a huge cost because remote data is stored at the
      // source level. Layers are (relatively) cheap (we assume).

      this.layers.forEach(({id}) => {
        map.removeLayer(id);
      });

      // This adds the layers such that they’ll render with the first element in
      // the array on the bottom.
      newLayers.forEach((layer) => {
        map.addLayer(layer, this.markerLayerId);
      });

      this.layers = newLayers;
    }

    // remove old sources — this has to be after changing layers, because you
    // aren’t allowed to remove a source if a layer is still referencing it.
    this.sources.forEach((_, id) => {
      if (!newSources.has(id)) {
        map.removeSource(id);
      }
    });

    this.sources = newSources;
  }
}

/**
 * Hook implementation of the core of MapContent. Will call "add" when the map
 * is ready to accept layers and controls and such. "add" can return a cleanup
 * handler that will get called when the hook is removed.
 *
 * Like MapContent, this hook is sensitive to the map changing style. If the
 * style is being changed, it will call the cleanup handler (with an argument of
 * "true") and will then call "add" again when the style is loaded.
 *
 * Use the deps parameter to control what values should cause us to consider
 * "add" a different function, and lead to a remove/add cycle.
 *
 * The parameter order is to be compatible with the ESLint
 * react-hooks/exhaustive-deps rule, which, for "additionalHooks", wants the
 * callback first and the deps list right after.
 */
export function useMap(
  add: (map: mapboxgl.Map) => void | ((changingStyle: boolean) => void),
  deps: DependencyList,
  {map, isMapLoaded}: {map: mapboxgl.Map; isMapLoaded: boolean}
) {
  // OK, add function. By the rules of hooks, we need to put "add" in the
  // dependency list for useEffect. That way, if the caller changes "add" (for
  // example, because they want different things added to the map) then we want
  // to re-run.
  //
  // Nevertheless, if we did that then callers would need to take care not to
  // constantly change the value of "add" on their own, or we would be regularly
  // adding and removing things from the map. This would be surprising, since
  // other effectish hooks (like useEffect) don’t have this behavior.
  //
  // So, we accept our own deps to go along with the add method so that we
  // behave like useEffect. This gives the callers that need it a mechanism for
  // re-triggering the remove/add, but doesn’t surprise everyone else by being
  // inconsistent with the built-in hooks.
  //
  // We have to disable exhaustive-deps just for this line because deps isn’t an
  // array literal. We assume that it has been checked by exhaustive-deps at the
  // caller level.
  //

  const stablizedAdd = useCallback(add, deps);

  useEffect(() => {
    if (!isMapLoaded) {
      return;
    }

    let haveAddedToMap = false;
    let remove: ReturnType<typeof add> = undefined;

    // This gets fired once the style has loaded from the network and the map
    // can now accept additional sources and layers. We don’t need to do the
    // isMapReady check because we know it will be ready when this gets
    // called.
    const onStyleLoad = () => {
      if (!haveAddedToMap) {
        remove = stablizedAdd(map);
        haveAddedToMap = true;
      }
    };

    // If this handler fires it means that the style has been reset and our
    // styles and layers are gone. We gate with haveAddedToMap because this
    // will fire several times during the loading process.
    const onStyleDataLoading = () => {
      if (haveAddedToMap) {
        if (remove) {
          remove(true);
          remove = undefined;
        }
        haveAddedToMap = false;
      }
    };

    // For details on how all of this, check the component above
    map.on('style.load', onStyleLoad);
    map.on('styledataloading', onStyleDataLoading);

    // If the map is ready on first use, we need to call onStyleLoad
    // ourselves. If the map isn’t ready now, the "style.load" event will
    // trigger onStyleLoad once it is.
    if (isMapReady(map)) {
      onStyleLoad();
    }

    return () => {
      if (haveAddedToMap && remove) {
        remove(false);
      }

      map.off('style.load', onStyleLoad);
      map.off('styledataloading', onStyleDataLoading);
    };
  }, [map, isMapLoaded, stablizedAdd]);
}

interface StyleLoadedMarker {
  loaded: boolean;
}

/**
 * Returns either null if the map’s style is not currently loaded, or a
 * StyleLoadedMarker if it is. Use this to gate operations that fail if the
 * style is not completely loaded.
 *
 * (Even though "false" is more semantically correct, "null" is used so we can
 * chain with ?.)
 *
 * The contract is that you’ll get a fresh StyleLoadedMarker instance when the
 * style goes from unloaded -> loaded. This lets you use it as a dependency for
 * other hooks.
 *
 * If the style becomes unloaded, the existing StyleLoadedMarker will be mutated
 * to have "false" for its "loaded" property. This will let you avoid calling
 * operations even if you’ve closed over a previously-good StyleLoadedMarker.
 *
 * In practical terms, this happens in our app when switching between basemap
 * styles.
 */
export function useStyleLoaded(map: mapboxgl.Map): StyleLoadedMarker | null {
  const [marker, setMarker] = React.useState<StyleLoadedMarker | null>(
    map.isStyleLoaded() ? {loaded: true} : null
  );

  React.useEffect(() => {
    const onDataLoading = (ev: mapboxgl.MapDataEvent) => {
      if (ev.dataType === 'style') {
        setMarker((s) => {
          if (s) {
            s.loaded = false;
          }

          return null;
        });
      }
    };

    const onStyleLoad = () => {
      setMarker((s) => {
        if (s) {
          s.loaded = false;
        }

        return {loaded: true};
      });
    };

    map.on('dataloading', onDataLoading);
    map.on('style.load', onStyleLoad);

    return () => {
      map.off('dataloading', onDataLoading);
      map.off('style.load', onStyleLoad);
    };
  }, [map]);

  return marker;
}
