export default `
/* We inline Lato so that we don’t have a font flash when things load. */

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AUi-qNiXg7eU0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AXC-qNiXg7Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Get everything on border-box */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;

  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 9.5pt;
  color: #4F4F4F;

  /* Forces background colors and images to export/print, which is required for */
  /* rendering elements like the overlay legend swatches. Firefox requires the */
  /* color-adjust attribute. See the following for more details: */
  /*  * https://stackoverflow.com/questions/3893986/css-media-print-issues-with-background-color. */
  /*  * https://stackoverflow.com/questions/35625178/what-is-the-alternate-for-webkit-print-color-adjust-in-firefox-and-ie */
  color-adjust: exact;
  -webkit-print-color-adjust: exact !important;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  position: relative;
}

section>* {
  max-width: 100%;
}

section.cover-page {
  padding: 0.5in;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

section.cover-page h1 {
  font-size: 27pt;
  margin-bottom: 0.5in;
}

section.cover-page h2 {
  font-size: 18pt;
  font-weight: normal;
  margin-bottom: 0.5in;
}

section.cover-page h3 {
  font-size: 13.5pt;
  font-weight: normal;
  margin-bottom: 0.25in;
  color: #4F4F4F;
}

.cover-page-footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cover-page-footer p {
  font-size: 11pt;
}

.cover-page-footer-logo {
  height: .75in;
  width: 3in;
  position: relative;
}

.cover-page-footer-logo > * {
  position: absolute;
  bottom: 0;
  max-height: 100%;
  max-width: 100%;
}

.overview-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.property-overview-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between
}

.property-overview-image-details {
  line-height: 1.4;
}

h1, h2, h3 {
  font-weight: bold;
  line-height: 1.2;

  color: #004c5c;

  align-self: flex-start;
}

h1 {
  font-size: 24pt;
  margin-bottom: .25in;
}

h2 {
  font-size: 18pt;
  margin-bottom: .25in;
}

h3 {
  margin-bottom: 0.0675in;
}

p {
  line-height: 1.4;
  width: 100%;
}

b {
  font-weight: bold;
  color: #004c5c;
}

p+p {
  margin-top: 8px;
}

.title-and-lens-watermark {
  display: flex;
  justify-content: space-between;
}

.lens-watermark {
  min-width: 0.75in;
  opacity: 0.5;
}

.note-imagery-and-source {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

.note-images-condensed {
  width: 5in;
  display: flex;
}

.note-images-condensed.vertical {
  flex-direction: column;
}

.note-images-condensed.horizontal {
  flex-direction: row;
}

.note-images-and-legends {
  flex-direction: column;
}

.note-legends-condensed {
  width: 5in;
  align-self: flex-start;
}

.note-legend {
  margin-top: 0.1in;
}

.note-overlay-legend {
  display: flex;
  flex-wrap: wrap;
  /* Compensate for bottom margin on items */
  margin-bottom: -0.1in;
}

.note-overlay-legend-item {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0.1in 1em 0.1in 0;
}

.note-overlay-legend-swatch {
  width: 16px;
  height: 16px;
  border: 1px solid #5c7080;
  border-radius: 2px;
  margin-right: 0.35em;
}

.note-category-legend-container {
  display: flex;
  width: 100%;
}

.note-category-legend {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px; //gap between title and legend items
  font-size: 10pt;
}

.note-category-legend > b {
  font-weight: 700;
  text-decoration: underline;
  color: black;
}

.note-imagery-info-condensed {
  width: 2.25in;
  line-height: 1.4;
}

.note-imagery-info-condensed>* {
  margin-top: 0.125in;
}

.note-interpretation-images {
  margin-top: 0.0675in;
  flex-grow: 1;
  display: flex;
  height: 0;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}

.note-interpretation-image {
  padding: 0.03375in;
  display: flex;
}

.note-interpretation-image img {
  max-height: 100%;
  max-width: 100%;
}

.note-interpretation-image:hover .set-hero-image-btn {
  visibility: visible;
}

.note-content-container {
  margin-top: 0.25in;
}

.note-and-chart-horizontal {
  display: flex;
  justify-content: space-between;
}

.note-and-chart-vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25in;
}

.note-text {
  line-height: 1.4
}

.note-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.note-graph {
  margin-top: 0.0675in;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  position: relative;
}

.note-graph-chart {
  width: 3.25in;
  position: relative;
  z-index: 0;
}

.note-graph-caption {
  width: 2.25in;
}

.note-graph-caption p {
  font-size: 7.5pt !important;
}

.note-graph-dot-cursor svg {
  stroke: #D9593D;
  stroke-opacity: 1;
  fill: #D9593D !important;
  fill-opacity: 0;
}

.note-graph-dot-cursor-estimated svg {
  stroke: #D9593D;
  stroke-opacity: 0.5;
  fill: #D9593D !important;
  fill-opacity: 0.2;
}

.aa-container {
  position: relative;
}

.aa-container text {
  font-family: Lato, Arial, Helvetica, sans-serif !important;
  fill: #4F4F4F !important;
  font-size: 7.5pt !important;
}

.aa-container:hover .aa-controls {
  visibility: visible;
}

.aa-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background: #F7F8FB;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.aa-controls {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  visibility: hidden;
}

/* To override only-visible-on-hover in editing mode */
.visible {
  visibility: visible !important;
}

/* For consistency with map controls */
.aa-controls svg {
  fill: #182026 !important;
}

.aa-controls>*:not(:last-child) {
  margin-right: 2px;
}

.map {
  display: block;
  margin: 0 auto;
  background: transparent;
  overflow: hidden;
  /* Leaflet makes much use of z-index, so we need to start a stack at 0 to keep its z-indexing contained.
     Otherwise map panes overlap buttons and the header. */
  z-index: 0;
}

.map-overlay-menu {
  max-height: 200px;
  overflow-y: scroll;
}

.map-container {
  position: relative;
  margin-bottom: 0.1in;
  margin-right: 0.1in;
  flex: 1;
}

.map-container:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.map-control-btns {
  position: absolute;
  top: 0;
  margin: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
}

.map-control-btns>*:not(:last-child) {
  margin-bottom: 10px;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-improve-map {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

section.multi-part-key {
  align-items: flex-start;
}

.report-toolbar {
  position: fixed;
  z-index: 100;

  top: 0;
  left: 0;
  right: 0;
  height: 40px;

  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* slate */
  background-color: #5c7080;
}

.report-toolbar-warning {
  color: white;
  flex: 1;
}

.report-toolbar .bp5-button {
  background-color: #fff;
  margin-left: 6px;
}

.date-dropdown-list {
  max-height: 350px;
  overflow-y: scroll;
}

.hero-image {
  max-width: 5in;
  max-height: 5in;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0.1in;
  margin-right: 0.1in;
}

.hero-image img {
  max-width: 100%;
  max-height: 100%;
}

.set-hero-image-btn {
  position: absolute;
  margin: 10px;
  visibility: hidden;
}

@media print {
  @page {
    /* setting to 0 hides header / footers in Chrome */
    margin: 0;
  }

  section {
    padding: 0.5in 0.5in;
    page-break-after: always;
    width: 8.5in;
    /* minus 1px keeps Firefox from generating blank pages */
    height: calc(11in - 1px);
  }

  .no-print {
    display: none !important;
  }
}

@media screen {
  body {
    background: #eeeeee;

    /* space for the toolbar */
    padding-top: 40px;
  }

  section {
    background: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
    margin: 20px auto;
    padding: .5in .5in;
    width: 8.5in;
    height: 11in;

    /* move to the right to give room to the side controls */
    position: relative;
    left: 20px;
  }

  .active-map {
    background-image: linear-gradient(-45deg, transparent 75%, #F5F8FA 75%),
    linear-gradient(45deg, transparent 75%, #F5F8FA 75%),
    linear-gradient(45deg, #F5F8FA 25%, transparent 0),
    linear-gradient(-45deg, #F5F8FA 25%, transparent 25%);

    background-size: 12px 12px;
    background-position: 0 0, 0 -6px, 6px 0, -6px -6px;
  }

  .map-btn {
    background-color: white;
    box-shadow: 0 0 0 2px rgba(0,0,0,.1);
    margin-right: 10px;
  }

  .overlay-menu-item {
    /* important to override .leaflet-container a */
    color: #4F4F4F !important;
    align-items: center;
    font-size: 13px;
  }

  .overlay-menu-swatch {
    width: 16px;
    height: 16px;
    border: 1px solid #5c7080;
    border-radius: 2px;
  }

  .note-control-btns {
    position: absolute;
    align-self: start;
    top: 0px;
    left: -40px;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  .note-control-btns>*:not(:last-child) {
    margin-bottom: 10px;
  }

  .note-unselected {
    background: repeating-linear-gradient(
      -55deg,
      rgba(247,248,251,0.6),
      rgba(247,248,251,0.6) 10px,
      rgba(224,224,224,0.6) 10px,
      rgba(224,224,224,0.6) 20px
    );
  }

  /* Copied from our main styles. We globally replace the default dark Tooltip background with white. */
  .bp5-tooltip .bp5-popover-content {
    background: white;
    color: black;
  }

  .bp5-tooltip .bp5-popover-arrow-fill {
    fill: white;
  }

  .bp5-tooltip .bp5-popover-arrow-border {
    fill: black;
    fill-opacity: 0.2;
  }

}

/* Prevents placeholder text from appearing in the printed report */
@media print {
  ::-webkit-input-placeholder { /* WebKit browsers */
      color: transparent;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: transparent;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: transparent;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
      color: transparent;
  }
}

::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}

/* Remove all default styling from textarea and prevent scroll */
textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
  border-radius: 3px;
}

.freeform-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  flex: 1;
  width: 100%;
  margin-bottom: 40px;
  color: #4F4F4F;
  border: 2px solid rgb(0,0,0,0);
  box-sizing: content-box;
  margin-left: -4px;
}

textarea:hover {
  border: 2px dashed #4F4F4F;
}

textarea:focus {
  border: 2px solid rgb(0,0,0,0);
}
`;
