import * as B from '@blueprintjs/core';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {ManagePropertiesMode} from 'app/pages/ManageProperties';
import {NumberedFeature} from 'app/pages/ManageProperties/ManageFeaturesProvider';

import cs from './styles.styl';

export interface Props {
  features: NumberedFeature[] | ApiFeature[] | null;
  needsPartName: boolean;
  nameField: string | null;
  partNameField: string | null;
  setNameField: (fieldName: string | null) => void;
  setPartNameField: (fieldName: string | null) => void;
  mode: ManagePropertiesMode;
  instructionText?: string;
}

const GeometryFileFieldSelector: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  features,
  needsPartName,
  nameField,
  partNameField,
  setNameField,
  setPartNameField,
  mode,
  instructionText,
}) => {
  return (
    <div>
      <div>
        <h4>
          {mode === 'overlay' ? (
            <>
              Select the attribute you'd like to display on hover{' '}
              <span className={cs.disabledText}>(optional)</span>
            </>
          ) : (
            (instructionText ?? `Select the attribute you'd like to use for Property Name`)
          )}
        </h4>
        <div className={cs.nameSelects}>
          <div>
            {needsPartName && <div className={cs.label}>Name</div>}
            <B.HTMLSelect
              id="select-name"
              onChange={(ev) => {
                setNameField(ev.currentTarget.value);
                setPartNameField(null);
              }}
              value={nameField ?? 'none'}
            >
              <option disabled value="none">
                Select an attribute…
              </option>
              <option disabled>─────────────</option>
              {features &&
                features.length > 0 &&
                features[0].properties &&
                Object.keys(features[0].properties).map(
                  (key) =>
                    key !== '_UPSTREAM_ACRES' &&
                    key !== '__UPSTREAM_PROBLEMS' && (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    )
                )}
            </B.HTMLSelect>
            {mode === 'overlay' && (
              <B.Tooltip content="Remove hover label" position="bottom" intent="primary">
                <B.Button
                  icon="cross"
                  minimal
                  onClick={() => {
                    setNameField(null);
                  }}
                />
              </B.Tooltip>
            )}
          </div>
          {needsPartName && (
            <div>
              <div className={cs.label}>Location Name</div>
              <B.HTMLSelect
                id="select-secondary-name"
                onChange={(ev) => setPartNameField(ev.currentTarget.value)}
                value={partNameField ?? 'none'}
              >
                <option disabled value="none">
                  Select an attribute…
                </option>
                <option disabled>─────────────</option>

                {features &&
                  features[0].properties &&
                  Object.keys(features[0].properties).map((key) => {
                    return (
                      key !== '_UPSTREAM_ACRES' &&
                      key !== '__UPSTREAM_PROBLEMS' && (
                        <option disabled={key === nameField} key={key} value={key}>
                          {key}
                        </option>
                      )
                    );
                  })}
              </B.HTMLSelect>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeometryFileFieldSelector;
