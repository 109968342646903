import * as B from '@blueprintjs/core';
import React, {FormEvent} from 'react';

import cs from './styles.styl';

/**
 * Form element used in sign up forms. Allows for easy sharing of styles and reduces duplication of code.
 *
 * TODO (maya): add place for field validation errors to this component. (Error message below the text input
 * when fields aren't filled out and are required)
 */

interface Props {
  label: string;
  value: undefined | string;
  name: string; // Used by onChange functions to update the corresponding piece of state
  inputType: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  options?: Partial<B.InputGroupProps>;
}
const FormField: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  label,
  name,
  inputType,
  onChange,
  options,
}) => {
  return (
    <B.FormGroup label={label}>
      <B.InputGroup
        value={value}
        name={name}
        type={inputType}
        className={cs.inputGroup}
        onChange={onChange}
        {...options}
      />
    </B.FormGroup>
  );
};

export default FormField;
