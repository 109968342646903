import {History} from 'history';
import * as I from 'immutable';
import queryString from 'query-string';
import React from 'react';
import {Helmet} from 'react-helmet';
import {Redirect} from 'react-router-dom';
import {Filters, SortingRule} from 'react-table';

import {AlertPolicyContextProvider} from 'app/components/Alerts/AlertPolicyProvider';
import Loading from 'app/components/Loading';
import {ShowPushNotification} from 'app/components/Notification';
import ActiveUsersProvider from 'app/modules/Realtime/ActiveUsersProvider';
import FeatureCollectionMonitor from 'app/modules/Realtime/FeatureCollectionMonitor';
import * as Remote from 'app/modules/Remote';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {
  ApiImageryContract,
  ApiImageryContractWithProjects,
  ApiImageryPurchase,
  ApiProject,
} from 'app/modules/Remote/Project';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {FeaturesActions, FeaturesStatus, useFeaturesState} from 'app/providers/FeaturesProvider';
import ImageryPricesProvider from 'app/providers/ImageryPricesProvider';
import {
  OrderableScenesActions,
  useOrderableScenesLoader,
} from 'app/providers/OrderableScenesProvider';
import {WithProject} from 'app/providers/ProjectsProvider';
import {HideHubspotWidget} from 'app/tools/Hubspot';
import {
  CachedApiGetter,
  CachedApiGetterOptions,
  StatusMaybe,
  useCachedApiGet,
  useStateWithDeps,
} from 'app/utils/hookUtils';
import * as routeUtils from 'app/utils/routeUtils';

import MonitorProjectView from '../MonitorProjectView';
import {getSelectedFeature} from '../MonitorProjectView/utils';
import PropertyOverview from '../PropertyOverview';

export interface Props {
  history: History;

  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.ImmutableOf<ApiOrganization>;
  loggedInUserActions: LoggedInUserActions;
  firebaseToken: string;

  selectedProjectIdParam: string;
  selectedFeatureIdsParam: string | null;

  getImageryContracts: (
    organizationId: string,
    projectId?: string
  ) => StatusMaybe<ApiImageryContractWithProjects[]>;

  getCurrentImageryContract: (
    projectId: string,
    opts?: CachedApiGetterOptions
  ) => StatusMaybe<ApiImageryContract | null>;

  getImageryPurchases: (
    imageryContractId: number,
    opts?: CachedApiGetterOptions
  ) => StatusMaybe<I.ImmutableOf<ApiImageryPurchase[]>>;
}

export interface FeatureDataActions {
  /** numeric arg is the feature ID */
  getFeatureData: CachedApiGetter<[number], I.ImmutableOf<ApiFeatureData[]>>;
  invalidateFeatureData: (featureId: number) => void;
}

/**
 * A provider component to share features across the `PropertyOverview` and
 * `MonitoringProjectView` pages so they aren't refetched when switching between
 * the property list and single property view.
 */
export const WithMonitoringFeatures: React.FunctionComponent<{
  selectedProject: I.ImmutableOf<ApiProject>;
  selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
  /** Split out from the URL parameter. May contain feature IDs, UUIDs, or the
   * first 8 chars of a UUID. */
  selectedFeatureIdParams: I.Set<string>;
  refreshFeatureCollection: (featureCollectionId: number) => Promise<void> | void;
  children: (args: {
    features: I.ImmutableOf<ApiFeature[]>;
    featuresStatus: FeaturesStatus;
    selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;
    selectedFeature: I.ImmutableOf<ApiFeature> | null;
    featuresActions: FeaturesActions;
    featureDataActions: FeatureDataActions;
    orderableScenesActions: OrderableScenesActions;
  }) => JSX.Element | null;
}> = ({
  selectedProject,
  selectedFeatureCollection,
  selectedFeatureIdParams,
  refreshFeatureCollection,
  children,
}) => {
  // It’s important to note that this value will stream in. It starts as an
  // empty I.List and will get updated as features are loaded.
  const [features, featuresStatus, featuresActions] = useFeaturesState({
    featureCollection: selectedFeatureCollection,
    getAllPages: true,
  });

  /**
   * Note that during loading selectedFeatureIdParams will have values (taken
   * from the router) even though selectedFeature(s) doesn’t because it’s
   * loading
   */
  const selectedFeatures = React.useMemo(
    () =>
      selectedFeatureIdParams
        .map((param) => features.find((f) => routeUtils.featureMatchesParam(f!, param!)))
        .filter((f) => !!f)
        .toSet(),
    [selectedFeatureIdParams, features]
  );

  const selectedFeature = getSelectedFeature(selectedFeatures);

  const [getFeatureData, {invalidate: invalidateFeatureData}] = useCachedApiGet(
    async function* ([fcId], fId: number) {
      // we use dataGen here so that this load will be interrupted if we stop
      // needing it.
      return yield* Remote.api.featureCollections
        .features(fcId)
        .dataGen(fId, {perPage: 100}, {getAllPages: 6});
    },
    [selectedFeatureCollection.get('id')]
  );

  const [getOrderableScenes, invalidateOrderableScenes] = useOrderableScenesLoader(
    selectedFeatureCollection.get('id'),
    selectedProject.get('id')
  );

  return (
    <>
      <FeatureCollectionMonitor
        featureCollectionId={selectedFeatureCollection.get('id')}
        refreshFeatureCollection={refreshFeatureCollection}
        // We don’t really care if the feature ID matches the feature
        // collection, since invalidating a feature ID that no one is fetching
        // is a no-op.
        invalidateFeature={({featureId}) => featuresActions.invalidateFeature(featureId)}
        invalidateFeatureData={({featureId}) => invalidateFeatureData(featureId)}
        invalidateOrderableScenes={invalidateOrderableScenes}
        refreshFeatures={featuresActions.refreshFeatures}
      />

      {children({
        features,
        featuresStatus,
        selectedFeatures,
        selectedFeature,
        featuresActions,
        featureDataActions: {getFeatureData, invalidateFeatureData},
        orderableScenesActions: {getOrderableScenes, invalidateOrderableScenes},
      })}
    </>
  );
};

/**
 * A component to save the property overview table's filter state as an initial
 * filter state to be used on subsequent renders. This store is hoisted up in
 * order to preserve the table filters while viewing individual properties.
 *
 * The initial filter state resets when a new project is selected.
 */
export const MonitoringFilterState: React.FunctionComponent<{
  history: History;
  selectedProjectId: string;
  children: (args: {
    initialFilterState: Filters<I.ImmutableOf<ApiFeature[]>>;
    onFilterStateChange: (filters: Filters<I.ImmutableOf<ApiFeature[]>>) => void;
    initialSortState: SortingRule<I.ImmutableListOf<ApiFeature>>[];
    onSortStateChange: (sortBy: SortingRule<I.ImmutableListOf<ApiFeature>>[]) => void;
  }) => JSX.Element | null;
}> = ({history, selectedProjectId, children}) => {
  // Converts every key/value in the parsed hash string into a filter entry,
  // until it hits the "&sortBy" string. Converts the remaining parsed hash
  // strings into a sorting rule.
  // This only needs to run once, since we don’t update the filters based on
  // changes in the hash after the component has loaded, so we depend on
  // "history" which will state consistent.

  const splitHash: string[] = history.location.hash.split('&sortBy');
  const filterHash: string = splitHash[0];
  const sortHash: string = splitHash[1];

  const defaultFilterState = React.useMemo<Filters<I.ImmutableOf<ApiFeature[]>>>(
    () =>
      Object.entries(queryString.parse(filterHash)).map(([key, value]) => ({
        id: key,
        value: value,
      })),
    [filterHash]
  );

  const defaultSortState = React.useMemo<SortingRule<I.ImmutableListOf<ApiFeature>>[]>(
    () =>
      Object.entries(queryString.parse(sortHash)).map(([key, desc]) => ({
        id: key,
        desc: desc === 'true' ? true : false, //need to convert this string of a boolean to a boolean. assume asc sort order.
      })),
    [sortHash]
  );

  const [filterState, setFilterState] = useStateWithDeps<Filters<I.ImmutableOf<ApiFeature[]>>>(
    defaultFilterState,
    [selectedProjectId]
  );

  const [sortState, setSortState] = useStateWithDeps<SortingRule<I.ImmutableListOf<ApiFeature>>[]>(
    defaultSortState,
    [selectedProjectId]
  );

  return children({
    initialFilterState: filterState,
    onFilterStateChange: setFilterState,
    initialSortState: sortState,
    onSortStateChange: setSortState,
  });
};

const ProjectDashboardSwitch: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  profile,
  organization,
  loggedInUserActions,
  firebaseToken,
  selectedProjectIdParam,
  selectedFeatureIdsParam,
  getCurrentImageryContract,
}) => {
  return (
    <WithProject projectIdParam={selectedProjectIdParam} renderLoading={() => <Loading />}>
      {(selectedProject, projectFeatureCollections, projectsActions, {error}) => {
        const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));

        if (!selectedProject || error) {
          return (
            <>
              <ShowPushNotification message="There was a problem loading that portfolio. Ensure you are signed in to the correct organization." />

              <Redirect to={`/${orgIdPrefix}/projects`} />
            </>
          );
        }

        const selectedProjectId = selectedProject.get('id');

        const selectedFeatureIdParams = I.Set<string>(
          (selectedFeatureIdsParam || '')
            .split(',')
            .map((id) => id.trim())
            .filter(
              (id) =>
                id.match(/^\d+$/) ||
                id.match(routeUtils.UUID_PREFIX_REGEXP) ||
                id.match(routeUtils.UUID_REGEXP)
            )
        );

        // We only support a single “primary” feature collection in a portfolio.
        const selectedFeatureCollection = projectFeatureCollections
          .get('primary', I.List())
          .first();

        if (!selectedFeatureCollection) {
          return (
            <>
              <ShowPushNotification message="There was a problem loading that portfolio. It does not have any data in it." />

              <Redirect to={`/${orgIdPrefix}/projects`} />
            </>
          );
        }

        const overlayFeatureCollections = projectFeatureCollections.get('overlay', I.List());

        return (
          <ActiveUsersProvider projectId={selectedProjectId} profile={profile}>
            <Helmet>
              <title>{selectedProject.get('name')} | Lens</title>
            </Helmet>

            <HideHubspotWidget />
            <ImageryPricesProvider>
              <WithMonitoringFeatures
                selectedProject={selectedProject}
                selectedFeatureCollection={selectedFeatureCollection}
                selectedFeatureIdParams={selectedFeatureIdParams}
                refreshFeatureCollection={projectsActions.refreshFeatureCollection}
              >
                {({
                  features,
                  featuresStatus,
                  selectedFeatures,
                  selectedFeature,
                  featuresActions,
                  featureDataActions,
                  orderableScenesActions,
                }) => (
                  <MonitoringFilterState history={history} selectedProjectId={selectedProjectId}>
                    {({
                      initialFilterState,
                      onFilterStateChange,
                      initialSortState,
                      onSortStateChange,
                    }) => {
                      if (selectedFeatureIdParams.isEmpty()) {
                        return (
                          <AlertPolicyContextProvider featureCollection={selectedFeatureCollection}>
                            <PropertyOverview
                              history={history}
                              profile={profile}
                              organization={organization}
                              loggedInUserActions={loggedInUserActions}
                              selectedFeatureCollection={selectedFeatureCollection}
                              selectedProject={selectedProject}
                              getCurrentImageryContract={getCurrentImageryContract}
                              features={features}
                              featuresStatus={featuresStatus}
                              featuresActions={featuresActions}
                              projectsActions={projectsActions}
                              initialFilterState={initialFilterState}
                              onFilterStateChange={onFilterStateChange}
                              initialSortState={initialSortState}
                              onSortStateChange={onSortStateChange}
                              orderableScenesLoader={orderableScenesActions.getOrderableScenes}
                              invalidateOrderableScenes={
                                orderableScenesActions.invalidateOrderableScenes
                              }
                            />
                          </AlertPolicyContextProvider>
                        );
                      } else {
                        return (
                          <MonitorProjectView
                            firebaseToken={firebaseToken}
                            history={history}
                            profile={profile}
                            organization={organization}
                            loggedInUserActions={loggedInUserActions}
                            selectedProject={selectedProject}
                            selectedFeatureCollection={selectedFeatureCollection}
                            selectedFeatureIdParams={selectedFeatureIdParams}
                            overlayFeatureCollections={overlayFeatureCollections}
                            getCurrentImageryContract={getCurrentImageryContract}
                            features={features}
                            selectedFeatures={selectedFeatures}
                            selectedFeature={selectedFeature}
                            featuresActions={featuresActions}
                            projectsActions={projectsActions}
                            getFeatureData={featureDataActions.getFeatureData}
                            orderableScenesLoader={orderableScenesActions.getOrderableScenes}
                            invalidateOrderableScenes={
                              orderableScenesActions.invalidateOrderableScenes
                            }
                          />
                        );
                      }
                    }}
                  </MonitoringFilterState>
                )}
              </WithMonitoringFeatures>
            </ImageryPricesProvider>
          </ActiveUsersProvider>
        );
      }}
    </WithProject>
  );
};

export default ProjectDashboardSwitch;
