import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {StringParam, useQueryParam} from 'use-query-params';

import api from 'app/modules/Remote/api';
import {
  ApiOrganization,
  ApiOrganizationAreaUnit,
  ApiOrganizationSettings,
  ApiOrganizationUser,
} from 'app/modules/Remote/Organization';
import {ORGANIZATION} from 'app/tests/__fixtures__/organizations';
import {REGULAR_USER} from 'app/tests/__fixtures__/organizationUsers';
import {useFirebaseAuth, useFirebaseDatabase} from 'app/tools/Firebase';
import {setContextOrganizationId} from 'app/utils/apiUtils';
import * as CONSTANTS from 'app/utils/constants';
import {loginAppUrl} from 'app/utils/environmentUtils';
import {StatusMaybe, useApiGet, useStateWithDeps} from 'app/utils/hookUtils';
import {MapStyle} from 'app/utils/mapUtils';
import {CurrentOrgIdPrefix, isPublicLensUrl, setWindowLocationHref} from 'app/utils/routeUtils';

export const POST_SIGNIN_PATH_KEY = 'n';

export class AuthStore {
  static fromFirebase() {}
}

export interface LoggedInAuth {
  status: 'logged in';
  profile: I.ImmutableOf<ApiOrganizationUser> | null;
  /**
   * The organization that the user is logged in as. This org will match their
   * profile’s organization ID. If this is a super organization then they’ll
   * have the option to switch their current organization to another one.
   */
  profileOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>>>;
  /**
   * The “current” organization that the user is operating the app under. May
   * not be the same as the profile’s organization, if the user is a member of a
   * parent org and has switched into this one.
   */
  currentOrganization: I.ImmutableOf<ApiOrganization> | null;
  firebaseUid: string;
  firebaseEmail: string;
  firebaseToken: string;
  firebaseIsEmailVerified: boolean;
  firebaseLastSignInTime: Date | undefined;
  firebaseCreationTime: Date | undefined;

  actions: LoggedInUserActions;
}

export interface LoggedInUserActions {
  signOut: () => Promise<void> | void;
  sendVerificationEmail: () => Promise<void> | void;
  setCurrentOrganization: (organization: I.ImmutableOf<ApiOrganization | null>) => void;
  resetCurrentOrganizationToProfileOrganization: () => void;
  refreshOrganizations: () => Promise<void> | void;
  setOrganizationName: (orgId: string, name: string) => Promise<void>;
  setOrganizationLogo: (orgId: string, file?: File) => Promise<void>;
  setOrganizationUnits: (orgId: string, units: ApiOrganizationAreaUnit) => Promise<void>;
  setOrganizationBasemap: (orgId: string, basemapStyle: MapStyle) => Promise<void>;
  setOrganizationShowSmartSummaries: (
    orgId: string,
    shouldShowSmartSummaries: boolean
  ) => Promise<void>;
  confirmPassword: (password: string) => Promise<void>;
  updateSettings: (settingsUpdate: Partial<ApiOrganizationUser['settings']>) => Promise<void>;
}

export interface LoggedOutAuth {
  status: 'logged out';
  signIn: (email: string, password: string) => Promise<void>;
  makeUser: (args: MakeUserArgs) => Promise<unknown>;
  resetPassword: (email: string) => Promise<void>;
}

type Auth = LoggedInAuth | LoggedOutAuth;

const AuthContext = React.createContext<Auth | undefined>(undefined);

interface MakeUserArgs {
  email: string;
  password: string;
}

// A small util to check if a user is logging in for the first time or not.
export const isFirebaseFirstTimeUser = (auth: LoggedInAuth) => {
  if (localStorage.getItem('hasLoggedIn')) {
    return false;
  } else if (!auth.firebaseLastSignInTime) {
    return true;
  } else if (auth.firebaseCreationTime) {
    // Allow for a difference of 60 seconds between user creation time and sign in time to account for the
    // time it takes to create a user in the login app, generate a token, redirect to lens, and sign in to Lens.
    // 60 seconds is more time than we need but it is nice to have a buffer.
    return (
      (auth.firebaseLastSignInTime.getTime() - auth.firebaseCreationTime.getTime()) / 1000 < 60
    );
  } else {
    return false;
  }
};

const AuthProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    renderLoading: () => JSX.Element;
  }>
> = ({children, renderLoading}) => {
  const firebaseAuth = useFirebaseAuth();
  const firebaseDatabase = useFirebaseDatabase();

  let firebaseUser = useFirebaseUser(firebaseAuth);
  // Hack so we don't try to load anything related to the firebase user when someone goes
  // to a public url while logged in
  if (isPublicLensUrl()) {
    firebaseUser = null;
  }
  // Updating the query param state wasn't causing this component to re-render
  // so mirror it with our own state hook to ensure that we fetch
  // the user's profile, etc, after exchanging the custom token param for a JWT.
  const [_token, _setToken] = useQueryParam('token', StringParam);
  const [token, setToken] = React.useState(_token);
  // We want to avoid making any requests for the user's profile, etc, until after
  // we've exchanged the custom token param for a fresh JWT to prevent a race condition
  // when switching orgs using a signup link. Otherwise if a user had already authenticated
  // (with the old org) the backend's auth cache would return the wrong org for the user.
  // Using a new JWT clears the cache and prevents API errors.
  if (token) {
    firebaseUser = null;
  }

  const idToken = useFirebaseIdToken(firebaseAuth, firebaseUser);
  const [profileResult] = useProfile(firebaseAuth, firebaseDatabase, firebaseUser);
  const [profileOrganizationResult, profileOrganizationMeta] = useOrganization(profileResult);
  const [currentOrganization, setCurrentOrganization] = useStateWithDeps(
    profileOrganizationResult.value?.get(0),
    [profileOrganizationResult.value?.get(0)?.get('id')]
  );

  // Workaround to assign the right role based on the current organization
  const profile = React.useMemo(() => {
    if (!profileResult.value || !currentOrganization) return null;
    const profile = profileResult.value;

    const roleInCurrentOrg = profile
      .get('organizations')
      .find((org) => org?.get('id') === currentOrganization.get('id'))
      ?.get('role'); // Check if role for user is directly assigned in an org on the profile
    const roleInParentOrg = profile
      .get('organizations')
      .find((org) => org?.get('id') === currentOrganization.get('parentOrganizationId'))
      ?.get('role'); // Check if role for user is assigned in the parent of the current org

    if (roleInCurrentOrg) {
      return profile.set('role', roleInCurrentOrg);
    } else if (roleInParentOrg) {
      return profile.set('role', roleInParentOrg);
    } else return profile;
  }, [currentOrganization, profileResult]);

  // Handles the case where the profile organization’s value changes (due to
  // perhaps a reloading) and we want to update the currentOrganization value to
  // match it.
  React.useEffect(() => {
    setCurrentOrganization((currentOrganization) => {
      if (!currentOrganization || !profileOrganizationResult.value) {
        return currentOrganization;
      }

      const allOrganizations = getAllOrganiations(profileOrganizationResult.value);
      const target = allOrganizations.find(
        (org) => org?.get('id') === currentOrganization.get('id')
      );
      if (!target) console.error('Could not find current organization in profile organizations'); // TODO-AROHAN: Figure out how to handle this error

      return target;
    });
  }, [profileOrganizationResult.value, setCurrentOrganization]);

  const [auth, setAuth] = React.useState<Auth | undefined>(undefined);

  // If status maybe has come back with a result, profileOrganizationResult.value will not be null
  const profileOrganizations = profileOrganizationResult.value as I.List<
    I.MapAsRecord<I.ImmutableFields<ApiOrganization>>
  >;
  const currentOrganizationId = currentOrganization?.get('id') ?? null;

  React.useEffect(() => {
    setContextOrganizationId(currentOrganizationId);
  }, [currentOrganizationId]);

  // Function to optimistically update the current organization’s name.
  const setOrganizationName = React.useCallback(
    async (organizationId: string, name: string) => {
      // We currently only support updates to the current organization. You
      // cannot, for example, change the logo on the parent organization from a
      // child organization.
      if (organizationId !== currentOrganizationId) {
        throw new Error('You cannot update this organization');
      }

      let prevName: string | null = null;

      try {
        setCurrentOrganization((currentOrganization) => {
          prevName = currentOrganization!.get('name');
          return currentOrganization!.set('name', name);
        });
        await api.organizations.update(organizationId, {name});
        // Refresh profile organization to propagate changes everywhere.
        profileOrganizationMeta.refresh();
      } catch (error) {
        setCurrentOrganization((currentOrganization) =>
          prevName ? currentOrganization!.set('name', prevName) : currentOrganization
        );
        throw error;
      }
    },
    [currentOrganizationId, profileOrganizationMeta, setCurrentOrganization]
  );

  // Function to add, update, and remove an organization’s logo. The value is
  // not updated optimistically because we need a source URL to render an image.
  // If a file is provided, its Firebase Storage URL is set on the organization
  // settings logo property, overriding whatever value is already set. If a file
  // is not provided, the property value is unset.
  const setOrganizationLogo = React.useCallback(
    async (organizationId: string, file?: File) => {
      // We currently only support updates to the current organization. You
      // cannot, for example, change the logo on the parent organization from a
      // child organization.
      if (organizationId !== currentOrganizationId) {
        throw new Error('You cannot update this organization');
      }

      try {
        const response = await api.organizations.setLogo(organizationId, file);
        const logo = response.getIn(['data', 'settings', 'logo']);
        // Even though this will also be done as a side effect from the profile
        // organization refresh, this updates the interface a little faster.
        setCurrentOrganization((currentOrganization) =>
          currentOrganization!.setIn(['settings', 'logo'], logo)
        );
        // Refresh profile organization to propagate changes everywhere.
        profileOrganizationMeta.refresh();
      } catch (error) {
        throw error;
      }
    },
    [currentOrganizationId, profileOrganizationMeta, setCurrentOrganization]
  );

  const updateOrganizationSettings = React.useCallback(
    (id: string, updates: Partial<ApiOrganizationSettings>): Promise<void> =>
      new Promise((resolve, reject) =>
        setCurrentOrganization((organization) => {
          // These correspond to the immutable settings values we'll optimistically set or roll back to
          const original: I.ImmutableOf<ApiOrganizationSettings> = organization!.get('settings');
          const optimistic = original.merge(I.fromJS(updates));

          api.organizations.update(id, {settings: updates}).then(
            (res) => {
              setCurrentOrganization(() => res.get('data'));
              resolve();
            },
            (err) => {
              setCurrentOrganization((organization) =>
                // We only reset back to the original we hadn’t modified in the
                // meantime. This is not perfect, but as long as at least
                // something eventually succeeds writing to the server, we’ll
                // converge with that as truth.
                organization?.update('settings', (settings) =>
                  settings === optimistic ? original : settings
                )
              );
              reject(err);
            }
          );

          return organization!.set('settings', optimistic);
        })
      ),
    [setCurrentOrganization]
  );

  const setOrganizationBasemap = React.useCallback(
    async (organizationId: string, basemap: MapStyle) => {
      await updateOrganizationSettings(organizationId, {
        [CONSTANTS.ORG_SETTING_DEFAULT_BASEMAP]: basemap,
      });
    },
    [updateOrganizationSettings]
  );

  const setOrganizationUnits = React.useCallback(
    async (organizationId: string, areaUnit: ApiOrganizationAreaUnit) => {
      await updateOrganizationSettings(organizationId, {
        [CONSTANTS.ORG_SETTING_DISPLAY_AREA_UNIT]: areaUnit,
      });
    },
    [updateOrganizationSettings]
  );

  const setOrganizationShowSmartSummaries = React.useCallback(
    async (organizationId: string, shouldShowSmartSummaries: boolean) => {
      await updateOrganizationSettings(organizationId, {
        [CONSTANTS.ORG_SETTING_SHOW_SMART_SUMMARIES]: shouldShowSmartSummaries,
      });
    },
    [updateOrganizationSettings]
  );

  const confirmPassword = React.useCallback(
    async (password: string) => {
      if (!firebaseUser) {
        throw new Error('Error confirming password');
      }
      const credential = firebase.auth.EmailAuthProvider.credential(firebaseUser.email!, password);
      await firebaseUser.reauthenticateWithCredential(credential);
    },
    [firebaseUser]
  );

  const updateSettings = React.useCallback(
    async (settingsUpdate: Partial<ApiOrganizationUser['settings']>) => {
      setAuth((a) => {
        if (a && a.status === 'logged in' && a.profile) {
          const profile = a.profile.set(
            'settings',
            a.profile.get('settings').merge(I.fromJS(settingsUpdate))
          );
          return {...a, profile};
        }
      });
      await api.organizations.updateSettings(settingsUpdate);
    },
    [setAuth]
  );

  // This useEffect ensures a continuity of rendering pages. When the app is
  // first running and we don’t know if the user is logged in or not, auth is
  // undefined and we render a loading indicator. This loading indicator
  // continues while the profile and organization are loading (if the user is
  // actually logged in).
  //
  // If the user is logged out and logs in, the state says as a "logged out"
  // auth object until the profile and organization have completely loaded
  // (either with values or null responses). This keeps us from unmounting all
  // components, which would happen if we switched to a top level loading
  // indicator.

  const [isLoggingOut, setLoggingOut] = React.useState(false);

  React.useEffect(() => {
    async function signInWithCustomToken(signInToken: string) {
      try {
        await firebaseAuth.signInWithCustomToken(signInToken);
        // Force getting a new token to prevent a race when switching
        // orgs via signup links.
        await firebaseAuth.currentUser?.getIdToken(true);
        setToken(undefined);
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            token: signInToken,
          },
        });
        const url = new URL(window.location.href);
        url.searchParams.delete('token');
        setWindowLocationHref(`${loginAppUrl}?redirect=${url.href}`);
      }
    }

    const route = window.location.pathname.split('/')[1];
    if (token) {
      signInWithCustomToken(token);
    } else if (firebaseUser === null) {
      // Don't redirect to the login app if we are on the signup pages or on a publlic url
      if (route === 'signup' || route === 'signupSuccess' || route === 'p') {
        setAuth(makeLoggedOutAuth(firebaseAuth));
      }
      // Don't redirect to the login app if we are in the process of logging out of Lens.
      // Redirect for that scenario is handled in the signOut function
      else if (!isLoggingOut) {
        setWindowLocationHref(`${loginAppUrl}?redirect=${window.location.href}`);
      }
    } else if (
      firebaseUser &&
      idToken &&
      profileResult.status === 'some' &&
      currentOrganization !== undefined &&
      profileOrganizations !== undefined
    ) {
      const resetCurrentOrganizationToProfileOrganization = () => {
        const targetProfileOrganizationKey = profile?.get('organizationKey');
        const allOrganizations = getAllOrganiations(profileOrganizations);
        const targetOrganization = allOrganizations.find(
          (org) => org?.get('id') === targetProfileOrganizationKey
        );
        setCurrentOrganization(targetOrganization);
      };

      setAuth(
        makeLoggedInAuth(
          firebaseAuth,
          firebaseUser,
          idToken,
          profile,
          currentOrganization,
          profileOrganizations,
          {
            setCurrentOrganization,
            resetCurrentOrganizationToProfileOrganization,
            refreshOrganizations: profileOrganizationMeta.refresh,
            setOrganizationName,
            setOrganizationLogo,
            setOrganizationUnits,
            setOrganizationBasemap,
            setOrganizationShowSmartSummaries,
            confirmPassword,
            updateSettings,
            signOut: async () => {
              setLoggingOut(true);
              await firebaseAuth.signOut();
              window.location.href = `${loginAppUrl}/signOut`;
            },
            sendVerificationEmail: () => firebaseUser!.sendEmailVerification(),
          }
        )
      );

      // If we were logged in and then the situation changes, we clear auth. If
      // we’re still logged in (and this ran because the profile or organization
      // objects changed) then we’re going to immediately re-setAuth with
      // another logged in state. If we logged out (meaning firebaseUser is
      // null) we’ll immediately setAuth with a logged-out state.
      //
      // The other case is if we switch auth from one user to another without an
      // intermediate log out, in which case we don’t want to render logged in
      // until the profile and organization change, but we don’t want to render
      // logged out because that might cause redirects. Instead, we’ll render
      // the loading indicator (auth === undefined) until organization and
      // profile load, in which case we’ll setAuth back to the logged in state.
      return () => {
        setAuth(undefined);
      };
    }
  }, [
    firebaseAuth,
    firebaseUser,
    idToken,
    profileResult,
    profile,
    currentOrganization,
    profileOrganizations,
    profileOrganizationMeta.refresh,
    setCurrentOrganization,
    setOrganizationName,
    setOrganizationLogo,
    setOrganizationUnits,
    setOrganizationBasemap,
    setOrganizationShowSmartSummaries,
    confirmPassword,
    token,
    setToken,
    isLoggingOut,
    updateSettings,
  ]);

  if (auth === undefined) {
    return renderLoading();
  } else {
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  }
};

function makeLoggedOutAuth(auth: firebase.auth.Auth): LoggedOutAuth {
  return {
    status: 'logged out',
    // In the old code, signIn and resetPassword were behind an
    // exponential backoff retry that retried once in case of failure. Is
    // that still necessary?
    signIn: async (email: string, password: string) => {
      await auth.signInWithEmailAndPassword(email, password);
    },
    makeUser: makeUser.bind(null, auth),
    resetPassword: async (email: string) => {
      await auth.sendPasswordResetEmail(email);
    },
  };
}

function makeLoggedInAuth(
  auth: firebase.auth.Auth,
  firebaseUser: firebase.User,
  idToken: string,
  immutableProfile: I.ImmutableOf<ApiOrganizationUser | null>,
  currentOrganization: I.ImmutableOf<ApiOrganization | null>,
  profileOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>>>,
  actions: LoggedInUserActions
): LoggedInAuth {
  return {
    status: 'logged in',
    firebaseUid: firebaseUser.uid,
    firebaseEmail: firebaseUser.email!,
    firebaseToken: idToken,
    firebaseIsEmailVerified: !!firebaseUser.emailVerified,
    firebaseLastSignInTime: auth.currentUser?.metadata.lastSignInTime
      ? new Date(auth.currentUser?.metadata.lastSignInTime)
      : undefined,
    firebaseCreationTime: auth.currentUser?.metadata.creationTime
      ? new Date(auth.currentUser?.metadata.creationTime)
      : undefined,
    profile: immutableProfile,
    currentOrganization,
    profileOrganizations,
    actions,
  };
}

async function makeUser(auth: firebase.auth.Auth, args: MakeUserArgs) {
  const {email, password} = args;

  const firebaseUser = (await auth.createUserWithEmailAndPassword(email, password)).user;

  if (!firebaseUser) {
    throw new Error('User creation was unsuccessful, but did not throw an error');
  }

  return firebaseUser;
}

/**
 * Hook for getting the currently-authed Firebase user.
 *
 * @returns undefined if login is pending (at app start-up), null if the user is
 * logged out, and the firebase.auth.User object if they’re logged in.
 */
function useFirebaseUser(auth: firebase.auth.Auth) {
  const [firebaseUser, setFirebaseUser] = React.useState<firebase.User | undefined | null>(
    undefined
  );

  React.useEffect(() => {
    return auth.onAuthStateChanged(
      (firebaseUser) => {
        setFirebaseUser(firebaseUser);
      },
      (err) => {
        // TODO(fiona): Not sure what to do in this case; as an internal error,
        // logging the user out may just be the best thing we can do. Perhaps
        // this should go to Sentry? The old code conflated these errors with
        // e.g. "wrong password" errors as all stored in the authError state,
        // but they’re reached in different ways.
        console.error(err);
        setFirebaseUser(null);
      }
    );
  }, [auth]);

  return firebaseUser;
}

/**
 * Maintains the current user’s "idToken", aka JWT token for API authentication.
 * Though this is always accessible from the getIdToken() method on
 * firebase.auth.User, we keep the latest in state so we can provide it
 * synchronously for APIs that couldn’t deal with getIdToken’s Promise return
 * value. (Such as Mapbox’s transformRequest)
 */
function useFirebaseIdToken(
  auth: firebase.auth.Auth,
  firebaseUser: firebase.User | null | undefined
) {
  const [idToken, setIdToken] = useStateWithDeps<string | null>(null, [
    firebaseUser && firebaseUser.uid,
  ]);

  React.useEffect(() => {
    if (!firebaseUser) {
      return;
    }

    // This will "immediately" return a value (after promise resolution) on
    // subscribe, as well as call us back when there are changes. We don’t have
    // to proactively keep refreshing when the idTokens expire (they last an
    // hour) because we rely on our connection to the realtime database to do
    // that for us.
    return auth.onIdTokenChanged(async (currentUser) => {
      if (currentUser && currentUser.uid === firebaseUser.uid) {
        setIdToken(await currentUser.getIdToken());
      }
    });
  }, [auth, firebaseUser, setIdToken]);

  return idToken;
}

/**
 * If the user is logged in, loads and returns the ApiOrganizationUser.
 *
 * Returns null if user is not logged in as well as during the loading process.
 *
 * Keeps a listener on the realtime record for the user so that if it changes
 * the profile is re-fetched.
 * Especially important during the signup process, since the user will be logged
 * in before the server creates the user record. This lets us hear that that has
 * happened.
 */
function useProfile(
  auth: firebase.auth.Auth,
  database: firebase.database.Database,
  firebaseUser: firebase.User | null | undefined
) {
  const lastProfileUpdateRef = React.useRef<string | null>(null);

  // Register a watch on the realtime key for this user so we can refetch the profile
  // if it changed (triggered by the backend when an update is made).
  React.useEffect(() => {
    if (!firebaseUser) {
      lastProfileUpdateRef.current = null;
      return;
    }

    const uid = firebaseUser.uid;
    const realtimeProfileRef = database.ref(`realtime/users/${uid}/profile`);

    const profileListener = realtimeProfileRef.on('value', async (snapshot) => {
      const updateVal: string | null = snapshot.val();

      if (!isEqual(lastProfileUpdateRef.current, updateVal)) {
        // If the user’s profile has changed then we refresh the ID token. The
        // backend uses the issue time of ID tokens to control its caches of
        // Firebase data. Without this, if the user switches orgs while being
        // logged in, they’ll still see the last org’s projects until either an
        // hour passes or they explicitly log out/log in.
        try {
          await firebaseUser.getIdToken(true);
        } catch (e) {
          Sentry.captureException(e, {
            extra: {
              uid: firebaseUser.uid,
              lastProfileRef: lastProfileUpdateRef.current,
              updatedProfileRef: updateVal,
            },
          });
          return <Redirect to={'/signin'} />;
        }
      }

      lastProfileUpdateRef.current = updateVal;
    });

    return () => {
      realtimeProfileRef.off('value', profileListener);
    };
  }, [auth, database, firebaseUser]);

  return useApiGet(
    async (firebaseUser, _lastProfileUpdatedRef) => {
      if (!firebaseUser) {
        return null;
      }
      return (await api.user.fetch()).get('data');
    },
    [firebaseUser, lastProfileUpdateRef.current]
  );
}

/**
 * Returns the Organization for the given user profile.
 */
export function useOrganization(profile: StatusMaybe<I.ImmutableOf<ApiOrganizationUser | null>>) {
  const organizations = profile.value?.get('organizations');

  return useApiGet(
    async (organizations) => {
      if (!organizations) return;
      const hydratedOrganizations = organizations.map(async (organization) => {
        const organizationKey = organization?.get('id');
        if (!organizationKey) {
          return null;
        }

        try {
          // We don’t send the context org here because we may be fetching for our
          // logged-in org when switched into a child org.
          const organizationPromise = api.organizations
            .fetch(organizationKey, {}, {noContextOrg: true})
            .then((d) => d.get('data'));

          // We want internal admins to be able to switch into any org, even if it doesn't
          // have a Lens access subscription yet, so they can setup an access subscription after
          // creating a new org. Otherwise we'd need to log into the new org directly to setup access.
          const isInternalOrg = organizationKey === CONSTANTS.INTERNAL_ORGANIZATION_ID;
          const childOrganizationsParams = isInternalOrg ? {} : {withProduct: 'Lens'};
          const organizationChildrenPromise = api.organizations
            .childOrganizations(organizationKey, childOrganizationsParams)
            .then((d) => d.get('data'));

          const [organization, children] = await Promise.all([
            organizationPromise,
            organizationChildrenPromise,
          ]);
          return organization.set('childOrganizations', children);
        } catch (e) {
          // A 404 loading the Organization means it doesn't exist, or has been archived.
          if (((e as any).error as Error).message === 'Not found') {
            return null;
          }
          console.error('Error fetching organization', e);
          throw e;
        }
      });
      const promises = await Promise.all(hydratedOrganizations.toArray());
      return I.List(promises);
    },
    [organizations]
  );
}

export const useAuth = () => {
  const auth = React.useContext(AuthContext);
  // AuthProvider enforces a contract that it will never render its children
  // unless auth is defined, so this is a programmer error rather than an odd
  // state.
  if (auth === undefined) {
    throw new Error('Auth consumer used outside of AuthProvider');
  }

  return auth;
};

// A little util much like useAuth, providing a user's profile and
// organization-- helpful for feature flagging.
export const useUserInfo = (): [
  I.ImmutableOf<ApiOrganization> | null,
  I.ImmutableOf<ApiOrganizationUser> | null,
] => {
  const auth = React.useContext(AuthContext);

  if (auth === undefined) {
    throw new Error('Auth consumer used outside of AuthProvider');
  }
  if (auth.status === 'logged in') {
    return [auth.currentOrganization, auth.profile];
  } else {
    return [null, null];
  }
};

export const WithAuth: React.FunctionComponent<{
  children: (auth: Auth) => JSX.Element;
}> = ({children}) => {
  const auth = useAuth();
  return children(auth);
};

/**
 * Renders its child function prop if auth is logged out, otherwise redirects to
 * redirectPath.
 */
export const WithLoggedOutAuth: React.FunctionComponent<{
  children: (auth: LoggedOutAuth) => JSX.Element;
}> = ({children}) => {
  const auth = useAuth();
  const {search} = useLocation();

  if (auth.status === 'logged out') {
    return children(auth);
  } else {
    const redirectPath = (queryString.parse(search)[POST_SIGNIN_PATH_KEY] || '/projects') as string;
    if (isFirebaseFirstTimeUser(auth)) {
      return <Redirect to={`/welcome?` + redirectPath} />;
    }
    return <Redirect to={redirectPath} />;
  }
};

const getAllOrganiations = (
  profileOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>> | null>
) => {
  let allOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>>> = I.List();
  profileOrganizations?.forEach((org) => {
    if (!org) return;
    allOrganizations = allOrganizations.push(org);
    org.get('childOrganizations')?.forEach((childOrg) => {
      if (!childOrg) return;
      allOrganizations = allOrganizations.push(childOrg);
    });
  });
  return allOrganizations;
};

/**
 * Renders its child function prop if auth is logged in, otherwise redirects to
 * redirectPath.
 *
 * Ensures that the user has both a profile and an organization, which are
 * passed as additional args to the children function.
 *
 * If you want to handle cases where the user is signed in but does not have a
 * profile or organization (perhaps they were removed from them, but they still
 * have a Firebase account) use WithAuth.
 */
export const WithLoggedInAuth: React.FunctionComponent<{
  children: (
    auth: LoggedInAuth,
    profile: I.ImmutableOf<ApiOrganizationUser>,
    orgs: {
      currentOrganization: I.ImmutableOf<ApiOrganization>;
      profileOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>>>;
    },
    actions: LoggedInUserActions
  ) => JSX.Element;
}> = ({children}) => {
  const auth = useAuth();
  const {pathname, hash} = useLocation();
  const currentOrgIdPrefix = React.useContext(CurrentOrgIdPrefix);

  React.useEffect(() => {
    if (auth.status === 'logged in') {
      const {profileOrganizations, currentOrganization} = auth;

      const allOrganizations = getAllOrganiations(profileOrganizations);

      // This check allows us to set the currentOrganization based on the Organization ID prefix slug in a URL.
      // The CurrentOrgIdPrefix context sets this from a route. This is needed to build URLs from a child org
      // that a user in the parent org can open (since otherwise we won't know which child org the URL is for).
      // If we've updated current org optimistically and the right org is still set, do nothing.
      const originalUserOrg = auth.profile?.get('organizationKey');
      const profileOrganization = allOrganizations.find(
        (org) => org?.get('id') === originalUserOrg
      );
      const profileOrgMatchesPrefix = profileOrganization
        ?.get('id')
        .startsWith(currentOrgIdPrefix!);
      const currentOrgMatchesPrefix =
        currentOrgIdPrefix && currentOrganization?.get('id').startsWith(currentOrgIdPrefix);
      const matchOrg =
        currentOrgIdPrefix &&
        allOrganizations?.find((org) => {
          return !!org?.get('id').startsWith(currentOrgIdPrefix);
        });

      if (currentOrgIdPrefix && !profileOrgMatchesPrefix && !currentOrgMatchesPrefix && matchOrg) {
        auth.actions.setCurrentOrganization(matchOrg);
      }
    }
  }, [auth, currentOrgIdPrefix]);

  if (auth.status === 'logged in') {
    const {profile, currentOrganization, profileOrganizations} = auth;

    if (!profile) {
      // This is the case when the account has been removed from its organization.
      alert(
        'Sorry, this account was removed from its organization. Contact your administrator to get a new invitation link.'
      );

      auth.actions.signOut();
      return null;
    }

    if (!currentOrganization || !profileOrganizations) {
      // This case is not ever expected to happen with our current schema.
      alert(
        'No organization for this user. Contact team@upstream.tech and we’ll get your account set up!'
      );

      auth.actions.signOut();
      return null;
    }

    const allOrganizations = getAllOrganiations(profileOrganizations);
    const aProfileOrgsHasLens = allOrganizations.some(
      (org) => !!org?.get('products').includes('Lens')
    );
    const isLensUser = currentOrganization.get('products').includes('Lens') || aProfileOrgsHasLens;
    if (!isLensUser) {
      // In this case the user does not have a valid lens product, and is probably associated with another product.
      alert(
        "This user isn't associated with Lens. Contact your administrator for more information."
      );

      auth.actions.signOut();
      return null;
    }

    return children(auth, profile, {currentOrganization, profileOrganizations}, auth.actions);
  } else {
    const redirectPath = `/signin?${queryString.stringify({
      [POST_SIGNIN_PATH_KEY]: `${pathname}${hash || ''}${window.location.search || ''}`,
    })}`;
    return <Redirect to={redirectPath} />;
  }
};

export const FakeLoggedInAuthProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    action?: (name: string) => () => any;
  }>
> = ({children, action = () => () => {}}) => {
  const value: LoggedInAuth = {
    status: 'logged in',
    profile: REGULAR_USER,
    profileOrganizations: I.List([ORGANIZATION]),
    currentOrganization: ORGANIZATION,
    firebaseUid: 'firebaseUid',
    firebaseEmail: 'firebaseEmail',
    firebaseToken: 'firebaseToken',
    firebaseIsEmailVerified: true,
    firebaseLastSignInTime: undefined,
    firebaseCreationTime: undefined,
    actions: {
      signOut: action('signOut'),
      sendVerificationEmail: action('sendVerificationEmail'),
      setCurrentOrganization: action('setCurrentOrganization'),
      resetCurrentOrganizationToProfileOrganization: action(
        'resetCurrentOrganizationToProfileOrganization'
      ),
      refreshOrganizations: action('refreshOrganizations'),
      setOrganizationName: action('setOrganizationName'),
      setOrganizationLogo: action('setOrganizationLogo'),
      setOrganizationUnits: action('setOrganizationUnits'),
      setOrganizationBasemap: action('setOrganizationBasemap'),
      setOrganizationShowSmartSummaries: action('setOrganizationShowSmartSummaries'),
      confirmPassword: action('confirmPassword'),
      updateSettings: action('confirmPassword'),
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
