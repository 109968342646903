import {IconName} from '@blueprintjs/core';

export interface SlideContent {
  title: string;
  description: string;
  imgLocation: string;
  imgAlt: string;
  leftButtonLabel?: string;
  leftButtonIcon?: IconName | undefined;
  rightButtonLabel?: string;
  rightButtonIcon?: IconName | undefined;
}

export const slides: SlideContent[] = [
  {
    title: 'Welcome to Lens!',
    description:
      "We're here to help you take your monitoring to new heights. Here's a quick overview of what's possible with Lens so you can hit the ground running.",
    imgLocation: 'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-1-logo.png',
    imgAlt: 'Lens icon',
    leftButtonLabel: 'Skip',
    leftButtonIcon: undefined,
    rightButtonLabel: 'Learn More',
    rightButtonIcon: undefined,
  },
  {
    title: "You're in good company",
    description:
      "With Lens, you've joined hundreds of organizations using data to monitor, manage, and protect land and natural resources.",
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-2-biomes.png',
    imgAlt: 'Aerial shots of forest, river, grassland, and desert biomes',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Add properties',
    description:
      'Add places to monitor, called properties, by uploading a geospatial file or drawing on a map. Properties are organized in portfolios.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-3-upload.png',
    imgAlt: 'Screenshot of Draw and Upload buttons in Lens',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Invite your team',
    description:
      'Add colleagues to Lens and select the right permission level. After all, monitoring is often a team effort.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-4-teammates.png',
    imgAlt: 'Screenshot of team list in Lens',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Choose the right imagery for you',
    description:
      "Order recent high-resolution archive imagery from several vendors and only pay for what's within your property.",
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-5-order.png',
    imgAlt: 'Screenshot of Order Imagery button in Lens',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Add data from the Lens Library',
    description:
      'Choose the data you need for each portfolio, such as land cover classification, building footprints, multi-spectral public data, and more.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-6-layers.png',
    imgAlt: 'Screenshot of Layers Library layer in Lens',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Document your observations',
    description:
      'Add notes associated with specific data layers and parts of your property to point changes over time.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-7-notes.png',
    imgAlt: 'Screenshot of Notes button in Lens',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Analyze data',
    description:
      'Investigate trends over time, such as vegetation, water, land use change, and more. Automated alerts are also available in some plans.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-8-analyze.png',
    imgAlt: 'Screenshot of vegetation trend chart in Lens',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: 'Generate reports',
    description:
      'Package up your notes, imagery, and more into organized PDF reports. These are great for record-keeping, stakeholder engagement, and more.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-9-reports.png',
    imgAlt: 'Screenshot of Lens report cover page',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: 'arrow-right',
  },
  {
    title: "We're always here to help",
    description:
      'If you need support with anything, reach out at lens@upstream.tech. You can also check out our support docs by clicking the username dropdown and selecting Knowledge Base.',
    imgLocation:
      'https://storage.googleapis.com/upstream-icons/onboarding-slides/slide-10-help.png',
    imgAlt: 'Screenshot of Chat button in Lens',
    rightButtonLabel: 'Done',
    leftButtonIcon: 'arrow-left',
    rightButtonIcon: undefined,
  },
];
