import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React, {FormEvent} from 'react';

import MemoizedFeatureArea from 'app/components/MemoizedFeatureArea';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as conversionUtils from 'app/utils/conversionUtils';
import {featureM2ToArea} from 'app/utils/featureUtils';

import {
  GroupPropertiesOptions,
  NumberedFeature,
  NumberedFeatureCollection,
} from './ManageFeaturesProvider';
import {DrawPropertiesState} from './ManagePropertiesDraw';
import cs from './styles.styl';

import {ManagePropertiesMode} from '.';

const FEATURE_TYPES_WITH_NO_AREA = ['Point', 'MultiPoint', 'LineString', 'MultiLineString'];
function isFeatureTypeWithNoArea(value: string): boolean {
  return FEATURE_TYPES_WITH_NO_AREA.includes(value);
}

export interface Props {
  selectedFeatureIds: I.Set<number>;
  allUploadableFeatureIds: I.Set<number>;
  setSelectedFeatureIds: React.Dispatch<React.SetStateAction<I.Set<number>>>;
  needsPartName: boolean;
  partNameValues: I.Map<number, string | null> | null;
  areaUnit: ApiOrganizationAreaUnit;
  groupProperties: GroupPropertiesOptions;
  nameValues: I.Map<number, string>;
  nameField: string | null;
  setNameValues: React.Dispatch<React.SetStateAction<I.Map<number, string> | null>>;
  setPartNameValues: React.Dispatch<React.SetStateAction<I.Map<number, string | null> | null>>;
  warningsMap: object;
  sortedFeatures: NumberedFeature[];
  hoveredFeatureIds: I.Set<number>;
  hoverFeatureById: (fId: number | null) => void;
  setSelectedFeature: (value: React.SetStateAction<NumberedFeature | null>) => void;
  setUploadedFeatureCollection: React.Dispatch<
    React.SetStateAction<NumberedFeatureCollection | null>
  >;
  mode: ManagePropertiesMode;
  drawPropertiesState?: DrawPropertiesState;
}
const ManageFeaturesTable: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedFeatureIds,
  allUploadableFeatureIds,
  setSelectedFeatureIds,
  partNameValues,
  needsPartName,
  nameField,
  areaUnit,
  groupProperties,
  nameValues,
  warningsMap,
  sortedFeatures,
  hoverFeatureById,
  hoveredFeatureIds,
  setSelectedFeature,
  setNameValues,
  setPartNameValues,
  setUploadedFeatureCollection,
  drawPropertiesState,
  mode,
}) => {
  const noFeaturesHaveArea = sortedFeatures.every((f) => isFeatureTypeWithNoArea(f.geometry.type));

  return (
    <div>
      <div className={cs.tableWrapper}>
        <table className={cs.table}>
          <thead>
            <tr>
              <th>
                <B.Checkbox
                  className={cs.checkbox}
                  checked={selectedFeatureIds.size === allUploadableFeatureIds.size}
                  onChange={() => {
                    // if all are selected, unselect all. otherwise select all
                    if (selectedFeatureIds.size === allUploadableFeatureIds.size) {
                      setSelectedFeatureIds(I.Set([]));
                    } else {
                      setSelectedFeatureIds(allUploadableFeatureIds);
                    }
                  }}
                />
              </th>
              <th>{mode === 'overlay' ? nameField : 'Property Name'}</th>
              {needsPartName && partNameValues && groupProperties === 'group' && (
                <th>Location Name</th>
              )}
              {!noFeaturesHaveArea && <th>{areaUnit === 'areaHectare' ? 'Hectares' : 'Acres'}</th>}
              {/* Empty header here for the draw actions column */}
              {(mode === 'draw' || mode === 'parcel') && <th></th>}
            </tr>
          </thead>
          <tbody>
            {sortedFeatures.map((feature) => {
              // This should never not exist, but the type allows it to be null.
              // In that case we have no info to render!
              if (!feature.properties) {
                return;
              }
              const featureId = feature.id;
              const featureSelected = selectedFeatureIds.has(featureId);
              let featureProblems: string[] | null = feature.properties['__UPSTREAM_PROBLEMS'];

              const featureName = nameValues.get(featureId);
              //overlay features can be nameless
              if (!featureName && mode !== 'overlay') {
                featureProblems = ['Feature has no name'];
              }
              const featureLocationName = partNameValues?.get(featureId);
              const featureWarnings: string[] | null =
                (feature.id && warningsMap[feature.id]) ?? null;
              // Only show the location name in the table if the feature is part of a multi location
              // property and more than 1 location has been selected

              return (
                <tr
                  className={hoveredFeatureIds.has(featureId) ? cs.hoveredRow : undefined}
                  key={feature.id}
                  onClick={() => {
                    if (selectedFeatureIds.has(featureId)) {
                      setSelectedFeature({...feature});
                    }
                  }}
                  onMouseEnter={() => {
                    if (featureSelected) {
                      hoverFeatureById(featureId);
                    }
                  }}
                  onMouseLeave={() => {
                    hoverFeatureById(null);
                  }}
                >
                  <td>
                    <B.Checkbox
                      className={cs.checkbox}
                      checked={featureSelected}
                      disabled={!!featureProblems}
                      onChange={() => {
                        if (featureSelected) {
                          setSelectedFeatureIds(selectedFeatureIds.remove(featureId));
                        } else {
                          setSelectedFeatureIds(selectedFeatureIds.add(featureId));
                        }
                      }}
                    />
                  </td>

                  <td>
                    <B.Tooltip
                      modifiers={{
                        preventOverflow: {enabled: false},
                        hide: {enabled: false},
                        flip: {enabled: false},
                      }}
                      intent={featureProblems ? B.Intent.DANGER : B.Intent.WARNING}
                      position={'right'}
                      disabled={!(featureProblems || (featureWarnings && featureSelected))}
                      content={
                        featureProblems ? (
                          featureProblems ? (
                            featureProblems.length > 1 ? (
                              <>
                                <span className={cs.bold}>
                                  Property cannot be included in upload
                                </span>
                                <ul>
                                  {featureProblems.map((p, x) => (
                                    <li key={x}>{p}</li>
                                  ))}
                                </ul>
                              </>
                            ) : (
                              <span className={cs.bold}>
                                Property cannot be included in upload: {featureProblems[0]}
                              </span>
                            )
                          ) : undefined
                        ) : (
                          <ul>{featureWarnings?.map((p, x) => <li key={x}>{p}</li>)}</ul>
                        )
                      }
                    >
                      <div className={cs.nameCell}>
                        {featureWarnings && featureSelected && (
                          <B.Icon
                            icon="warning-sign"
                            intent={B.Intent.WARNING}
                            size={16}
                            className={cs.warningIcon}
                          />
                        )}
                        {featureProblems && (
                          <B.Icon
                            icon="error"
                            intent="danger"
                            size={16}
                            className={cs.warningIcon}
                          />
                        )}
                        <B.InputGroup
                          type="text"
                          className={cs.nameInput}
                          value={featureName}
                          onChange={(event: FormEvent<HTMLInputElement>) => {
                            const element = event.currentTarget as HTMLInputElement;
                            const {value} = element;
                            if (mode === 'upload' || mode === 'overlay') {
                              setNameValues(nameValues.set(featureId, value));
                            } else {
                              setUploadedFeatureCollection((fc) => {
                                if (!fc) {
                                  return fc;
                                }
                                const newFC: NumberedFeatureCollection = {
                                  ...fc,
                                  features: fc.features.map((f) => {
                                    if (f.id === featureId) {
                                      const updatedFeature: NumberedFeature = {
                                        ...f,
                                        properties: {...feature.properties, name: value},
                                      };
                                      return updatedFeature;
                                    }
                                    return f;
                                  }),
                                };
                                return newFC;
                              });
                            }
                          }}
                        />
                      </div>
                    </B.Tooltip>
                  </td>
                  {needsPartName && partNameValues && groupProperties === 'group' && (
                    <td>
                      <div className={cs.nameCell}>
                        {/* TODO(maya): make this check based on whether the property name is not unique.
                        For now this lets you add a location name in draw mode for any property when just
                         one needs a part name. good work around for the moment but the location
                         name will get ignored on upload */}
                        {(featureLocationName || mode === 'draw' || mode === 'parcel') && (
                          <B.InputGroup
                            type="text"
                            className={cs.nameInput}
                            value={featureLocationName || undefined}
                            onChange={(event: FormEvent<HTMLInputElement>) => {
                              const element = event.currentTarget as HTMLInputElement;
                              const {value} = element;
                              setPartNameValues(partNameValues.set(featureId, value));
                            }}
                          />
                        )}
                      </div>
                    </td>
                  )}
                  {!noFeaturesHaveArea && (
                    <td>
                      {isFeatureTypeWithNoArea(feature.geometry.type) ? (
                        'n/a'
                      ) : (
                        <MemoizedFeatureArea
                          feature={feature}
                          areaUnit={areaUnit}
                          formatAreaFn={(areaM2, unit) =>
                            conversionUtils.numberWithCommas(featureM2ToArea(areaM2, {unit}))
                          }
                        />
                      )}
                    </td>
                  )}
                  {(mode === 'draw' || mode === 'parcel') && (
                    <td>
                      <div className={cs.drawActions}>
                        <B.Tooltip content={'Edit Shape'} intent={'primary'}>
                          <B.Button
                            icon="polygon-filter"
                            minimal
                            intent={'primary'}
                            onClick={() => {
                              if (drawPropertiesState) {
                                const {setEditMode, setFeatureToEdit} = drawPropertiesState;
                                setEditMode(true);
                                setFeatureToEdit(feature);
                              }
                            }}
                          />
                        </B.Tooltip>
                        <B.Tooltip content={'Delete'} intent={'danger'}>
                          <B.Button
                            icon="trash"
                            minimal
                            intent={'primary'}
                            onClick={() => {
                              setUploadedFeatureCollection((fc) => {
                                return fc
                                  ? {
                                      ...fc,
                                      features: fc?.features.filter((f) => f.id !== featureId),
                                    }
                                  : fc;
                              });
                            }}
                          />
                        </B.Tooltip>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageFeaturesTable;
