import {bbox, toMercator} from '@turf/turf';
import geojson from 'geojson';
import React from 'react';

import {renderGeojsonSvg} from 'app/components/OrderImageryModal/ImageryPreviewImage';
import {RasterCalculationResult, RasterImageType} from 'app/stores/RasterCalculationStore';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {DataLayerInfo} from 'app/utils/layers';
import {getLayer} from 'app/utils/layerUtils';

import {
  GraphMode,
  TimeSeriesCalculatorData,
  calculateAggregatePerHectare,
} from './AnalyzePolygonPopup';

type RelativeMousePosition = {columnId: ColumnId; left: number; top: number} | null;

type TabId = 'home' | 'theory';

interface TabConfig {
  name: string;
}

type ColumnId =
  | 'area'
  | 'average'
  | 'averageLowerUncertainty'
  | 'averageUpperUncertainty'
  | 'carbonTotal'
  | 'carbonTotalLowerUncertainty'
  | 'carbonTotalUpperUncertainty'
  | 'countByRawValue'
  | 'countInDataRange'
  | 'countPresent'
  | 'countTotal'
  | 'date'
  | 'imageClipped'
  | 'imageMasked'
  | 'imagePresence'
  | 'imageRaw'
  | 'percentByRawValue'
  | 'percentInDataRange'
  | 'sum';

interface ColumnConfig {
  name: string;
  renderDescription: (options: {
    layer: DataLayerInfo;
    threshold: readonly [number, number];
  }) => React.ReactNode;
  renderCell: (options: {
    layer: DataLayerInfo;
    polygon: geojson.Polygon | geojson.MultiPolygon;
    areaInM2: number;
    date: Date;
    result: RasterCalculationResult;
    cursor: string;
    updateCursors: (cursor: string) => void;
  }) => React.ReactNode;
}

interface Props {
  window: Window | null;
  title: string;
  polygon: geojson.Polygon | geojson.MultiPolygon | null;
  areaInM2: number;
  graphMode: GraphMode;
  calculatorData: TimeSeriesCalculatorData;
  updateCursors: (cursor: string) => void;
  layerKey: string;
}

const TAB_IDS: TabId[] = ['home', 'theory'];

const TAB_CONFIG_BY_TAB_ID: {[tabId in TabId]: TabConfig} = {
  home: {
    name: 'Home',
  },
  theory: {
    name: 'Theory',
  },
};

const COLUMN_CONFIG_BY_COLUMN_ID: {[columnId in ColumnId]: ColumnConfig} = {
  area: {
    name: 'Area',
    renderDescription: () => (
      <div>
        The polygon area, measured in square meters (m<sup>2</sup>).
      </div>
    ),
    renderCell: ({areaInM2}) => <code>{formatFloat(areaInM2)}</code>,
  },
  average: {
    name: 'Average',
    renderDescription: () => (
      <div>
        The average data value for present pixels within the polygon.
        <br />
        <br />
        Calculated by dividing the <b>{COLUMN_CONFIG_BY_COLUMN_ID.sum.name}</b> by the{' '}
        <b>{COLUMN_CONFIG_BY_COLUMN_ID.countPresent.name}</b>.
      </div>
    ),
    renderCell: ({result}) => <code>{formatFloat(result.mean)}</code>,
  },
  carbonTotal: {
    name: 'Total carbon',
    renderDescription: () => (
      <div>
        The total aboveground carbon within the polygon, measured in tonnes of carbon (MgC).
        <br />
        <br />
        Calculated by multiplying the <b>{COLUMN_CONFIG_BY_COLUMN_ID.area.name}</b> converted to
        hectares; the quotient of <b>{COLUMN_CONFIG_BY_COLUMN_ID.countPresent.name}</b> divided by{' '}
        <b>{COLUMN_CONFIG_BY_COLUMN_ID.countTotal.name}</b>; and{' '}
        <b>{COLUMN_CONFIG_BY_COLUMN_ID.average.name}</b>.
      </div>
    ),
    renderCell: ({result, areaInM2}) => (
      <code>
        {formatFloat(
          calculateAggregatePerHectare(
            result.mean,
            areaInM2,
            result.missingPixelCount,
            result.weightedPixelsWithValue
          )
        )}
      </code>
    ),
  },
  carbonTotalLowerUncertainty: {
    name: 'Total carbon (90% CI lower bound)',
    renderDescription: () => (
      <div>
        Total carbon calculated using the lower bound of uncertaintiy available for this data - 5th
        percentile.
      </div>
    ),
    renderCell: ({result, areaInM2}) => (
      <code>
        {formatFloat(
          calculateAggregatePerHectare(
            result.lowerUncertaintyMean,
            areaInM2,
            result.missingPixelCount,
            result.weightedPixelsWithValue
          )
        )}
      </code>
    ),
  },
  carbonTotalUpperUncertainty: {
    name: 'Total carbon (90% CI upper bound)',
    renderDescription: () => (
      <div>
        Total carbon calculated using the upper bound of uncertaintiy available for this data - 95th
        percentile.
      </div>
    ),
    renderCell: ({result, areaInM2}) => (
      <code>
        {formatFloat(
          calculateAggregatePerHectare(
            result.upperUncertaintyMean,
            areaInM2,
            result.missingPixelCount,
            result.weightedPixelsWithValue
          )
        )}
      </code>
    ),
  },
  countByRawValue: {
    name: 'Count by raw value',
    renderDescription: () => (
      <div>The number of present pixels within the polygon, indexed by their raw value.</div>
    ),
    renderCell: ({result}) => (
      <code>
        {Object.keys(result.weightedPixelsWithValueByValue)
          .sort((a, b) => Number(a) - Number(b))
          .map((value) => (
            <div key={value}>
              {formatInteger(Number(value))}:{' '}
              {formatFloat(result.weightedPixelsWithValueByValue[value])}
            </div>
          ))}
      </code>
    ),
  },
  countInDataRange: {
    name: 'Count in data range',
    renderDescription: ({threshold}) => (
      <div>
        The number of present pixels within the polygon with a data value between{' '}
        {formatFloat(threshold[0])} and {formatFloat(threshold[1])}.
      </div>
    ),
    renderCell: ({result}) => <code>{formatFloat(result.weightedPixelsInThreshold)}</code>,
  },
  countPresent: {
    name: 'Count',
    renderDescription: () => <div>The number of present pixels within the polygon.</div>,
    renderCell: ({result}) => <code>{formatFloat(result.weightedPixelsWithValue)}</code>,
  },
  countTotal: {
    name: 'Total count',
    renderDescription: () => (
      <div>The number of pixels (present and missing) within the polygon.</div>
    ),
    renderCell: ({result}) => (
      <code>{formatFloat(result.weightedPixelsWithValue + result.missingPixelCount)}</code>
    ),
  },
  averageLowerUncertainty: {
    name: 'Average (90% CI lower bound)',
    renderDescription: () => (
      <div>
        Average calculated using the upper bound of uncertaintiy available for this data - 5th
        percentile. If we have no uncertainty data, this is the same as the average.
      </div>
    ),
    renderCell: ({result}) => <code>{formatFloat(result.lowerUncertaintyMean)}</code>,
  },
  averageUpperUncertainty: {
    name: 'Average (90% CI upper bound)',
    renderDescription: () => (
      <div>
        Average calculated using the upper bound of uncertaintiy available for this data - 95th
        percentile. If we have no uncertainty data, this is the same as the average.
      </div>
    ),
    renderCell: ({result}) => <code>{formatFloat(result.upperUncertaintyMean)}</code>,
  },
  date: {
    name: 'Date',
    renderDescription: () => (
      <div>
        The scene date. Click to select the corresponding scene on the map in the Lens application
        window.
      </div>
    ),
    renderCell: ({date, cursor, updateCursors}) => (
      <a href="javascript:void(0)" onClick={() => updateCursors(cursor)}>
        {formatDate(date)}
      </a>
    ),
  },
  imageClipped: {
    name: 'Clipped image',
    renderDescription: () => (
      <div>The image data for the requested geographic bounds, clipped to polygon boundary.</div>
    ),
    renderCell: ({polygon, result}) =>
      makeGeoJsonSvg({
        polygon,
        image: result.inspector.image,
        imageBounds: result.inspector.imageBounds,
        imageUrl: result.inspector.clippedImageDataUrl,
        imageTop: result.inspector.imageOrigin.y,
        imageLeft: result.inspector.imageOrigin.x,
      }),
  },
  imagePresence: {
    name: 'Data presence',
    renderDescription: () => (
      <div>
        Classified image data, where pixels are categorized as{' '}
        <span style={{color: 'red'}}>missing</span>, <span style={{color: 'orange'}}>partial</span>,
        or <span style={{color: 'green'}}>whole</span>.
      </div>
    ),
    renderCell: ({polygon, result}) =>
      makeGeoJsonSvg({
        polygon,
        image: result.inspector.image,
        imageBounds: result.inspector.imageBounds,
        imageUrl: result.inspector.presenceImageDataUrl,
        imageTop: result.inspector.imageOrigin.y,
        imageLeft: result.inspector.imageOrigin.x,
      }),
  },
  imageMasked: {
    name: 'Masked image',
    renderDescription: ({threshold}) => (
      <div>
        Classified image data, where pixels are categorized as{' '}
        <span style={{color: 'green'}}>
          having a data value between {formatFloat(threshold[0])} and {formatFloat(threshold[1])}
        </span>{' '}
        or not.
      </div>
    ),
    renderCell: ({polygon, result}) =>
      makeGeoJsonSvg({
        polygon,
        image: result.inspector.image,
        imageBounds: result.inspector.imageBounds,
        imageUrl: result.inspector.thresholdImageDataUrl,
        imageTop: result.inspector.imageOrigin.y,
        imageLeft: result.inspector.imageOrigin.x,
      }),
  },
  imageRaw: {
    name: 'Raw image',
    renderDescription: () => <div>The image data for the requested geographic bounds.</div>,
    renderCell: ({polygon, result}) => {
      const {image} = result.inspector;
      return makeGeoJsonSvg({
        polygon,
        image,
        imageBounds: result.inspector.imageBounds,
        imageUrl:
          image instanceof HTMLImageElement
            ? image.src
            : image instanceof HTMLCanvasElement
              ? image.toDataURL()
              : null,
        imageTop: 0,
        imageLeft: 0,
      });
    },
  },
  percentByRawValue: {
    name: 'Percent by raw value',
    renderDescription: () => (
      <div>
        The percent of present pixels within the polygon, indexed by their raw value.
        <br />
        <br />
        Calculated by diving the <b>{COLUMN_CONFIG_BY_COLUMN_ID.countByRawValue.name}</b> by the{' '}
        <b>{COLUMN_CONFIG_BY_COLUMN_ID.countPresent.name} for each value.</b>
      </div>
    ),
    renderCell: ({result}) => (
      <code>
        {Object.keys(result.weightedPixelsWithValueByValue)
          .sort((a, b) => Number(a) - Number(b))
          .map((value) => (
            <div key={value}>
              {formatInteger(Number(value))}:{' '}
              {formatPercent(
                result.weightedPixelsWithValueByValue[value] / result.weightedPixelsWithValue
              )}
            </div>
          ))}
      </code>
    ),
  },
  percentInDataRange: {
    name: 'Percent in data range',
    renderDescription: ({threshold}) => (
      <div>
        The percent of present pixels within the polygon with a data value between{' '}
        {formatFloat(threshold[0])} and {formatFloat(threshold[1])}.
        <br />
        <br />
        Calculated by dividing the <b>{COLUMN_CONFIG_BY_COLUMN_ID.countInDataRange.name}</b> by the{' '}
        <b>{COLUMN_CONFIG_BY_COLUMN_ID.countPresent.name}</b>.
      </div>
    ),
    renderCell: ({result}) => <code>{formatPercent(result.percentWithinThreshold)}</code>,
  },
  sum: {
    name: 'Sum',
    renderDescription: ({layer}) => (
      <div>
        The sum of data values within the polygon, which range from {layer.dataRange[0]} to{' '}
        {layer.dataRange[1]} per pixel.
      </div>
    ),
    renderCell: ({result}) => <code>{formatFloat(result.sum)}</code>,
  },
};

const COLUMN_IDS_BY_GRAPH_MODE: {[graphMode in GraphMode]: ColumnId[]} = {
  areaByCategory: [
    'date',
    'imageRaw',
    'imageClipped',
    'imagePresence',
    'countByRawValue',
    'countPresent',
    'percentByRawValue',
  ],
  aggregateCarbonSalo: [
    'date',
    'imageRaw',
    'imageClipped',
    'imagePresence',
    'area',
    'sum',
    'countPresent',
    'countTotal',
    'average',
    'averageLowerUncertainty',
    'averageUpperUncertainty',
    'carbonTotal',
    'carbonTotalLowerUncertainty',
    'carbonTotalUpperUncertainty',
  ],
  aggregateCarbonSpaceIntelligence: [
    'date',
    'imageRaw',
    'imageClipped',
    'imagePresence',
    'area',
    'sum',
    'countPresent',
    'countTotal',
    'average',
    'carbonTotal',
  ],
  average: [
    'date',
    'imageRaw',
    'imageClipped',
    'imagePresence',
    'sum',
    'countPresent',
    'average',
    'averageLowerUncertainty',
    'averageUpperUncertainty',
  ],
  averageMonthly: [
    'date',
    'imageRaw',
    'imageClipped',
    'imagePresence',
    'sum',
    'countPresent',
    'average',
  ],
  area: [
    'date',
    'imageRaw',
    'imageClipped',
    'imagePresence',
    'imageMasked',
    'countInDataRange',
    'countPresent',
    'percentInDataRange',
  ],
};

const COLUMN_DESCRIPTION_WIDTH = 200;
const COLUMN_DESCRIPTION_PADDING = 5;
const COLUMN_DESCRIPTION_POPOVER_WIDTH = COLUMN_DESCRIPTION_WIDTH + 2 * COLUMN_DESCRIPTION_PADDING;
const COLUMN_DESCRIPTION_BUFFER = 20;

const STANDARD_IMAGE_WIDTH = 200;

const STYLES = `
html {
  box-sizing: border-box;
}

body {
  width: fit-content;
  padding: 20px;
}

h1 {
  color: #CCCCCC;
}

h2 {
  font-size: 1.25em;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.prose {
  max-width: 960px;
}

.tab {
  color: #CCCCCC;
}

.tab:hover {
  color: lightsteelblue;
}

.tab-active {
  color: steelblue;
  text-decoration: underline;
}

.home :where(table, th, td) {
  border: 1px solid black;
  border-collapse: collapse;
  background: white;
}

.home :where(th, td) {
  padding: 5px 10px;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
}

.home table {
  margin-top: 20px;
}

.home thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  box-shadow: inset 0 1px 0 black, inset 0 -2px 0 black;
}

.home th {
  background: #eee;
}

.home .column-header {
  position: relative;
  cursor: help
}

.home .column-description {
  visibility: hidden;
  position: absolute;
  background-color: black;
  color: white;
  font-weight: normal;
  font-size: 14px;
  pointer-events: none;
  white-space: break-spaces;
}

.home .column-header:hover .column-description {
  z-index: 10;
  visibility: visible;
}

.home .image {
  position: relative;
}

.home .image img {
  position: absolute;
  transform-origin: top left;
}

.home .image svg {
  position: absolute;
  overflow: visible;
}

.home .image svg g path {
  fill: transparent;
  stroke: magenta;
  stroke-linejoin: round;
}

.theory .images {
  width: 100%;
  display: flex;
  align-items: center;
}

.theory .images img {
  max-width: 240px;
}

.theory .equation {
  width: 100%;
  display: flex;
}

.theory .equation :where(table, th, td) {
  border: none;
  border-collapse: collapse;
  background: white;
  vertical-align: middle;
  text-align: center;
}

.theory .equation :where(th, td) {
  padding: 5px 10px;
  white-space: nowrap;
}

.theory .equation .underline {
  border-bottom: 1px solid black;
}
`;

const Inspector: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  window,
  title,
  polygon,
  areaInM2,
  graphMode,
  calculatorData,
  updateCursors,
  layerKey,
}) => {
  const [activeTab, setActiveTab] = React.useState<TabId>('home');

  if (!window) {
    return null;
  }

  return (
    <>
      <head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        <style type="text/css">{STYLES}</style>
      </head>
      <body className="vertical-flex">
        <header>
          <h1 className="horizontal-flex">
            Inspector /{' '}
            <>
              {TAB_IDS.map((tabId) => {
                const tabConfig = TAB_CONFIG_BY_TAB_ID[tabId];
                return (
                  <a
                    key={tabId}
                    href="javascript:void(0)"
                    onClick={() => setActiveTab(tabId)}
                    className={tabId === activeTab ? 'tab-active' : 'tab'}
                  >
                    {tabConfig.name}
                  </a>
                );
              })}
            </>
          </h1>
        </header>
        {activeTab === 'home' && (
          <Home
            window={window}
            polygon={polygon}
            areaInM2={areaInM2}
            graphMode={graphMode}
            calculatorData={calculatorData}
            updateCursors={updateCursors}
            layerKey={layerKey}
          />
        )}
        {activeTab === 'theory' && <Theory />}
      </body>
    </>
  );
};

const Home: React.FunctionComponent<
  React.PropsWithChildren<{
    window: Window;
    polygon: geojson.Polygon | geojson.MultiPolygon | null;
    areaInM2: number;
    graphMode: GraphMode;
    calculatorData: TimeSeriesCalculatorData;
    layerKey: string;
    updateCursors: (cursor: string) => void;
  }>
> = ({window, polygon, areaInM2, graphMode, calculatorData, layerKey, updateCursors}) => {
  const columnIds = COLUMN_IDS_BY_GRAPH_MODE[graphMode];

  const [relativeMousePos, setRelativeMousePos] = React.useState<RelativeMousePosition>(null);

  if (!polygon || calculatorData.status !== 'loaded') {
    return null;
  }

  const layer = getLayer(layerKey) as DataLayerInfo;
  const {threshold} = calculatorData;

  return (
    <main className="home">
      <section className="prose">
        <p>
          The table below contains <i>{layer.display}</i> layer data for the area of interest within
          the provided time range. For each row, the scene date in the first column and the final
          statistic in the last column should correspond to a chart point in the Analyze popup. The
          intermediate columns surface intermediary steps from within the raster calculator to help
          illustrate how we arrive at the final statistic. To learn more about the data in each
          column, hover over the column header cell to see a tooltip with more information.
        </p>
        <h2>Images</h2>
        <p>
          The image columns contain two SVG paths styled with a magenta stroke. The outer path,
          often rectangular, illustrates the requested geographic bounds. The inner path
          demonstrates the area of interest. The image data is positioned approximately in the SVG
          coordinate system, and the composite image is scaled down to fit in the table reasonably.
          Because we assemble these images in the Inspector from multiple raster calculator outputs,
          we only use these images for cursory diagnostic purposes. You can open the underlying
          image source URL to see the unmanipulated image data from the raster calculator for more
          in-depth investigations.
        </p>
        <h2>Debugging</h2>
        <p>
          It’s challenging to prescribe specific debugging steps without knowing more about the
          present issue. But here are a few suggestions:
          <ol>
            <li>
              Click on the scene date. Does the color image on the map in the Lens application
              window align with the grayscale images in the table?
            </li>
            <li>Are there any abnormal missing or partial pixels in the data presence image?</li>
            <li>Do the intermediate statistics evaluate the final statistic as expected?</li>
          </ol>
        </p>
      </section>
      <table>
        <thead>
          <tr>
            {columnIds.map((columnId) => {
              const columnConfig = COLUMN_CONFIG_BY_COLUMN_ID[columnId];
              return (
                <th
                  className="column-header"
                  key={columnId}
                  onMouseMove={(event) => {
                    const bounds = event.currentTarget.getBoundingClientRect();
                    const availableWidth =
                      window.innerWidth - event.clientX - COLUMN_DESCRIPTION_BUFFER;
                    const isPopoverTooWide = availableWidth < COLUMN_DESCRIPTION_POPOVER_WIDTH;
                    const offsetX = isPopoverTooWide ? COLUMN_DESCRIPTION_POPOVER_WIDTH : 0;
                    const left = event.clientX - bounds.left - offsetX;
                    const top = event.clientY - bounds.top;
                    setRelativeMousePos({columnId, left, top});
                  }}
                  onMouseOut={() => {
                    setRelativeMousePos(null);
                  }}
                >
                  <div className="column-name">{columnConfig.name}</div>
                  <div
                    className="column-description"
                    style={
                      relativeMousePos?.columnId === columnId
                        ? {
                            width: COLUMN_DESCRIPTION_WIDTH,
                            padding: COLUMN_DESCRIPTION_PADDING,
                            left: relativeMousePos?.left ?? 0,
                            top: relativeMousePos?.top ?? 0,
                          }
                        : {}
                    }
                  >
                    {columnConfig.renderDescription({layer, threshold})}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {calculatorData.values.map(([date, result, {cursor}]) => (
            <tr key={cursor}>
              {columnIds.map((columnId) => {
                const columnConfig = COLUMN_CONFIG_BY_COLUMN_ID[columnId];
                return (
                  <td key={`${cursor}-${columnId}`}>
                    <div>
                      {columnConfig.renderCell({
                        layer,
                        polygon,
                        areaInM2,
                        date,
                        result,
                        cursor,
                        updateCursors,
                      })}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

const Theory: React.FunctionComponent<React.PropsWithChildren<{}>> = () => (
  <div className="theory prose">
    <p>
      The Analyze tool helps visualize seasonal patterns and year-over-year changes. Given the
      user-provided inputs in the Analyze popup, the underlying raster calculator fetches a raw
      image for every scene date in the specified date range and derives metrics from the
      constituent pixel values. Much like satellite bands, we can combine the resultant metrics into
      useful statistics for ecological interpretation.
    </p>
    <h2>Image processing</h2>
    <p>
      We request raw image data from the Tiler API for every scene date. Among a handful of query
      string parameters, we specify the source ID, layer key, sensing time, feature ID, and desired
      window geometry. The image data may be in a single PNG file or webtile that we assemble on a
      canvas in the correct position. Either way, the result is a grayscale image meeting the
      specifications defined in the URL.
    </p>
    <p>
      Often, we request image data for an area that extends past the polygon boundary. Once the
      image data is available, we draw a canvas just over the section of the image representing the
      polygon’s extent. Then, we clip the image data to ignore anything outside the polygon
      boundary. What remains is only the image data that falls within the polygon:
    </p>
    <div className="images">
      <img src={require('./__assets__/inspector_raw_image.png')} />
      <img src={require('./__assets__/inspector_cropped_image.png')} />
      <img src={require('./__assets__/inspector_clipped_image.png')} />
    </div>
    <p>Now, we are ready to process the pixels on the resultant canvas.</p>
    <h2>Pixel processing</h2>
    <p>
      Next, we iterate through every pixel on the canvas to obtain their grayscale values. The image
      data is an array of numbers with a length of four times the number of pixels in the canvas.
      That’s because every four bytes defines the appearance of a single pixel:
    </p>
    <ol>
      <li>
        <b>Red (R)</b>: Specifies the pixel color. Ranges from 0 to 255.
      </li>
      <li>
        <b>Green (G)</b>: Specifies the pixel color. Ranges from 0 to 255.
      </li>
      <li>
        <b>Blue (B)</b>: Specifies the pixel color. Ranges from 0 to 255.
      </li>
      <li>
        <b>Alpha (A)</b>: Specifies the pixel transparency. Ranges from 0 to 1.
      </li>
    </ol>
    <p>
      The R, G, and B channel values are equal for grayscale image data. R, G, and B channel values
      of 0 define a black pixel representing the maximum data value. R, G, and B channel values of
      255 define a white pixel representing the minimum data value. The directionality is inverted –
      the lower the raw pixel value, the higher the data value, and the higher the raw pixel value,
      the lower the data value.
    </p>
    <p>
      Because the color channels are equal, we use only the R and A channel values in a given
      pixel’s byte range to derive the following data:
    </p>
    <ul>
      <li>
        <b>Raw value</b>: This is the R channel value. We use it to calculate the data value and for
        categorical layers where the R channel value corresponds to a discrete classification.
      </li>
      <li>
        <b>Data value</b>: This is the R channel value transposed on the layer’s data range. If we
        analyze a vegetation layer with a data range of 0 to 1, a pixel with a raw value of 64 would
        represent the data value 0.75.
      </li>
      <li>
        <b>Transparency</b>: This is the A channel value. Some pixels may fall on the clipping
        boundary when we clip the image data to the polygon boundary. The A channel value, which
        defines the transparency, also tells us how present the pixel is within the polygon. Because
        this value ranges from 0 to 1, we can use it when summing pixel counts to weigh missing,
        partial, and whole pixels appropriately. We have a few different ways of classifying pixels
        by their transparency:
      </li>
      <ul>
        <li>
          <b>Missing</b>: This pixel is within the polygon and has a transparency value of 0.
        </li>
        <li>
          <b>Partial</b>: This pixel is within the polygon and has a transparency value between 0
          and 1.
        </li>
        <li>
          <b>Whole</b>: This pixel is within the polygon and has a transparency value of 1.
        </li>
        <li>
          <b>Present</b>: This pixel is either partial or whole (i.e., not missing).
        </li>
      </ul>
    </ul>
    <h2>Statistics</h2>
    <p>
      We calculate four statistics using the raw values, data values, and transparency values. For
      each statistic, we’ll use the example below. It contains an example polygon (magenta stroke)
      for a layer with a data range of 0 to 1. The polygon includes a whole pixel (left) with a raw
      pixel value of 51, which equals the data value 0.8. It also contains a half pixel (right) with
      a raw pixel value of 204 (right), which equals the data value 0.2:
    </p>
    <div className="images">
      <img src={require('./__assets__/inspector_statistics_example.png')} />
    </div>
    <h3>Average</h3>
    <p>
      This statistic is the average data value within the polygon. It helps elucidate general trends
      across an area over time. To calculate the average value, we sum the data values for present
      pixels, multiplying each data value by the transparency. Then, we divide this number by the
      sum of transparency values to get an average value within the polygon boundary:
    </p>
    <div className="equation">
      <table>
        <tr>
          <td className="underline">
            <code>0.8 * 1.0 + 0.2 * 0.5</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td className="underline">
            <code>0.9</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td rowSpan={2}>
            <code>
              <b>0.6</b>
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <code>1.0 + 0.5</code>
          </td>
          <td>
            <code>1.5</code>
          </td>
        </tr>
      </table>
    </div>
    <h3>Area in range</h3>
    <p>
      This statistic is the percent of present pixels within the polygon whose data value falls
      within a specified range. It helps illustrate changes in land classification categories over
      time, like the proportion of an area covered by water. First, we sum the transparency values
      only for pixels whose data value is between the data range minimum and maximum values,
      inclusive. Then, we divide this number by the sum of transparency values to get a percent in
      range.
    </p>
    <p>
      In our example, let’s say we’re interested in the percent area (pixels) with a data value
      between 0 and 0.4. Only the half pixel (right) with the data value 0.2 falls in the specified
      range:
    </p>
    <div className="equation">
      <table>
        <tr>
          <td className="underline">
            <code>0.5</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td className="underline">
            <code>0.5</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td rowSpan={2}>
            <code>
              <b>0.33</b>
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <code>1.0 + 0.5</code>
          </td>
          <td>
            <code>1.5</code>
          </td>
        </tr>
      </table>
    </div>
    <h3>Area by category</h3>
    <p>
      This statistic is the percent of present pixels within the polygon, disaggregated by their raw
      values. It is helpful for layers where raw values signify a specific category, such as with
      landcover classifications. First, we sum and index the transparency values by the respective
      raw values. Then, we divide each disaggregated sum by the sum of transparency values to get
      the percent area by category:
    </p>
    <p>For the raw value 51:</p>
    <div className="equation">
      <table>
        <tr>
          <td className="underline">
            <code>1.0</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td className="underline">
            <code>1.0</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td rowSpan={2}>
            <code>
              <b>0.67</b>
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <code>1.0 + 0.5</code>
          </td>
          <td>
            <code>1.5</code>
          </td>
        </tr>
      </table>
    </div>
    <p>For the raw value 204:</p>
    <div className="equation">
      <table>
        <tr>
          <td className="underline">
            <code>0.5</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td className="underline">
            <code>0.5</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td rowSpan={2}>
            <code>
              <b>0.33</b>
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <code>1.0 + 0.5</code>
          </td>
          <td>
            <code>1.5</code>
          </td>
        </tr>
      </table>
    </div>
    <h3>Aboveground carbon</h3>
    <p>
      This statistic is the sum of the aboveground carbon, measured in MgC, within the polygon. It
      is currently only used with Salo and Space Intelligence aboveground carbon layers, for which
      we encode raw image data as MgC/ha. First, we convert the polygon’s area to hectares and
      multiply it by the percent of present pixels out of the total (present and missing) pixels to
      get the hectarage over which we’ve measured carbon. Then, we multiply this hectarage by the
      average data value to get the total carbon.
    </p>
    <p>
      In our example, let’s say the polygon area is 10,000 square meters, and the layer data range
      extends from 0 to 255 for convenience. Remember that the directionality is inverted, so the
      whole pixel (left) with a raw value of 51 has the data value 204. Conversely, the half pixel
      (right) with a raw value of 204 has the data value 51:
    </p>
    <div className="equation">
      <table>
        <tr>
          <td className="underline">
            <code>10000</code>
          </td>
          <td rowSpan={2}>
            <code>*</code>
          </td>
          <td className="underline">
            <code>1.5</code>
          </td>
          <td rowSpan={2}>
            <code>*</code>
          </td>
          <td className="underline">
            <code>204 * 1.0 + 51 * 0.5</code>
          </td>
          <td rowSpan={2}>
            <code>=</code>
          </td>
          <td rowSpan={2}>
            <code>
              <b>153</b>
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <code>10000</code>
          </td>
          <td>
            <code>1.5</code>
          </td>
          <td>
            <code>1.5</code>
          </td>
        </tr>
      </table>
    </div>
  </div>
);

export default Inspector;

function formatDate(date: Date) {
  return date.toLocaleDateString();
}

function formatInteger(num: number) {
  return num.toFixed(0);
}

function formatFloat(num: number) {
  return num.toFixed(2);
}

function formatPercent(num: number) {
  return (num * 100).toFixed(0) + '%';
}

function makeGeoJsonSvg({
  polygon,
  image,
  imageBounds,
  imageUrl,
  imageTop,
  imageLeft,
}: {
  polygon: geojson.Polygon | geojson.MultiPolygon;
  image: RasterImageType;
  imageBounds: geojson.BBox;
  imageUrl: string | null;
  imageTop: number;
  imageLeft: number;
}) {
  const imageScale = STANDARD_IMAGE_WIDTH / image.width;

  const width = STANDARD_IMAGE_WIDTH;
  const height = image.height * imageScale;

  const polygonFeature = geoJsonUtils.feature(polygon, {});
  const boundsFeature = geoJsonUtils.bboxPolygon(imageBounds);

  const featureCollection = geoJsonUtils.featureCollection([polygonFeature, boundsFeature]);
  const featureCollectionXY = toMercator(featureCollection);
  const boundsXY = bbox(featureCollectionXY);

  const contentsWidth = boundsXY[2] - boundsXY[0];
  const contentsHeight = boundsXY[3] - boundsXY[1];

  const canvasWidth = width;
  const canvasHeight = height;

  const scale = Math.min(canvasWidth / contentsWidth, canvasHeight / contentsHeight);

  const offsetX = (canvasWidth - contentsWidth * scale) / 2;
  const offsetY = (canvasHeight - contentsHeight * scale) / 2;

  const featureXYToCanvasXY = ([x, y]) => [
    (x - boundsXY[0]) * scale + offsetX,
    height - (y - boundsXY[1]) * scale - offsetY,
  ];

  return (
    <div
      className="image"
      style={{
        width,
        height,
      }}
    >
      {imageUrl && (
        <img
          src={imageUrl}
          style={{
            transform: `scale(${imageScale})`,
            top: imageTop * imageScale,
            left: imageLeft * imageScale,
          }}
        />
      )}
      <svg width={width} height={height}>
        {featureCollectionXY.features.map((f) => (
          <g key={f.id}>{renderGeojsonSvg(f.geometry, featureXYToCanvasXY)}</g>
        ))}
      </svg>
    </div>
  );
}
