import * as Sentry from '@sentry/react';
import * as I from 'immutable';
import React from 'react';
import ReactDOM from 'react-dom';

import * as Remote from 'app/modules/Remote';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiResponseError} from 'app/utils/apiUtils';
import {
  StatusMaybe,
  useApiGetGen,
  useCachedApiGet,
  useRefWithDeps,
  useStateWithDeps,
} from 'app/utils/hookUtils';
import * as immutableUtils from 'app/utils/immutableUtils';

const DEFAULT_PER_PAGE = 100;
const MAX_PER_PAGE = 200;

export type FeaturesStatus = 'ready' | 'fetching' | 'done';

export interface FeaturesActions {
  loadNextFeatures: () => void;

  getFeature: (featureId: number) => StatusMaybe<I.ImmutableOf<ApiFeature>>;
  invalidateFeature: (featureId: number) => Promise<void> | void;

  updateFeature: (
    featureId: number,
    changes: I.MergesInto<I.ImmutableOf<ApiFeature>>
  ) => Promise<void> | void;

  batchPatchFeature: (args: {
    featureCollectionId: number;
    features: I.ImmutableOf<ApiFeature[]>;
    changes: I.MergesInto<I.ImmutableOf<ApiFeature>>;
  }) => Promise<void> | void;

  batchArchiveFeatures: (args: {
    featureCollectionId: number;
    features: I.ImmutableOf<ApiFeature[]>;
  }) => Promise<void> | void;

  moveFeatures: (args: {
    currentFeatureCollectionId: number;
    newFeatureCollectionId: number;
    newProjectId: string;
    featureIds: number[];
  }) => Promise<void> | void;

  refreshFeatures: (clear?: boolean | undefined) => Promise<void>;
}

/**
 * A generator to pipeline list feature collection features API requests.
 *
 * If `resolveNextPagePromiseRef` is truthy, the generator will fetch the first
 * page of features, then pause until the Promise resolves (e.g., upon clicking
 * a "Load more features" button). If `resolveNextPagePromiseRef` is `null`, the
 * generator will fetch all features. */
async function* fetchSomeFeatures(
  api: Pick<typeof Remote.api, 'featureCollections'>,
  featureCollectionId: number,
  resolveNextPagePromiseRef: React.MutableRefObject<((value?: unknown) => void) | null> | null,
  setFeaturesStatus: React.Dispatch<React.SetStateAction<FeaturesStatus>>,
  orderBy: string,
  perPage: number,
  tiledLoadingBehavior: 'first' | 'all' | 'none',

  getAllPages?: boolean
) {
  // If we want to get all pages, list all of the features using the list
  // generator API endpoint. This will make 4 parallel requests at a time,
  // yielding the intermediate result of each generator tick. One all features
  // have been listed, it will return the full list of features.
  if (getAllPages) {
    const features = (
      await api.featureCollections.features(featureCollectionId).list(
        {
          orderBy,
        },
        {getAllPages: 4}
      )
    ).get('data');

    setFeaturesStatus('done');

    return {features, page: -1};
  } else {
    // If we don't want to explicitly get all pages, manually tick over the list
    // feature request pages to provide greater control over only listing the
    // features we need (e.g., for large, tiled feature collections).
    let features: I.ImmutableOf<ApiFeature[]> = I.List();
    let page = 0;

    for (;;) {
      yield {features, page};

      // If we are returning one page of results at a time (e.g., for tiled
      // feature collections with a lot of features) and either (1) explicitly
      // do not want to load any features, or (2) have loaded one page of
      // features, await a new Promise, and store the `resolve` function in a
      // ref. This allows us to invoke the function from outside of the function
      // when the `loadNextFeatures` action is invoked.
      if (resolveNextPagePromiseRef && (tiledLoadingBehavior === 'none' || page > 0)) {
        await new Promise((resolve) => {
          resolveNextPagePromiseRef.current = resolve;
          setFeaturesStatus('ready');
        });
      }

      // Kick off the next invocation by setting our status to 'fetching' and
      // clearing out our old `resolve` function, if applicable.
      setFeaturesStatus('fetching');

      if (resolveNextPagePromiseRef) {
        resolveNextPagePromiseRef.current = null;
      }

      // Increment the page number.
      page++;

      // Fetch the features for the desired page.
      const response = (
        await api.featureCollections.features(featureCollectionId).list(
          {
            page,
            perPage,
            orderBy,
          },
          // The intent of `fetchSomeFeatures` is to loop manually to
          // accommodate tiled feature collections. Setting `getAllPages` option
          // to be explicitly false because we don't want this to also loop.
          {getAllPages: false}
        )
      ).get('data');

      // Add the new page of features to the list we already have.
      features = features.concat(response).toList();

      // If we have fewer than the maximum number of features per page, we know
      // that we have no more features to fetch and can return a `done` status.
      // This can be used to hide UI elements that would fetch more features,
      // for example.
      if (response.size < perPage) {
        setFeaturesStatus('done');
        break;
      }
    }

    return {features, page};
  }
}

/**
 * Maintains the selected feature collection's features, fetching status, and
 * actions for loading the next page of features.
 *
 * We use a generator function to pipeline the API requests, and provide the
 * functionality for pause-resume behavior.
 *
 * If the feature collection has a truthy `tiles` property, then we fetch the
 * first page of features and pause. The next page of features may be fetched by
 * calling the `loadNextFeatures` action. If the feature collection has a falsey
 * `tiles` property, the generator will automatically and sequentially fetch
 * pages of features until done.
 */
export function useFeaturesState(args: {
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;

  api?: Pick<typeof Remote.api, 'featureCollections'>;
  orderKey?: string;
  featuresPerPage?: number;
  tiledLoadingBehavior?: 'first' | 'all' | 'none';
  getAllPages?: boolean;
}): [I.ImmutableOf<ApiFeature[]>, FeaturesStatus, FeaturesActions] {
  const {
    featureCollection,
    api = Remote.api,
    orderKey,
    featuresPerPage,
    tiledLoadingBehavior = 'first',
    getAllPages,
  } = args;

  const orderBy = orderKey || '';
  const perPage = featuresPerPage ? Math.min(featuresPerPage, MAX_PER_PAGE) : DEFAULT_PER_PAGE;

  const featureCollectionId = featureCollection.get('id');
  const isTiled = !!featureCollection.get('tiles');

  // A Promise `resolve` function that, when invoked, will fetch the next page
  // of features.
  const resolveNextPagePromiseRef = useRefWithDeps<(() => void) | null>(null, [
    featureCollectionId,
    orderBy,
    perPage,
  ]);

  // The current fetching status for UI treatment.
  const [featuresStatus, setFeaturesStatus] = useStateWithDeps<FeaturesStatus>('fetching', [
    featureCollectionId,
    orderBy,
    perPage,
  ]);

  const [featuresMaybe, {updateLocal: updateFeaturesLocal, refresh: refreshFeatures}] =
    useApiGetGen(fetchSomeFeatures, [
      api,
      featureCollectionId,
      isTiled && tiledLoadingBehavior !== 'all' ? resolveNextPagePromiseRef : null,
      setFeaturesStatus,
      orderBy,
      perPage,
      tiledLoadingBehavior,
      getAllPages,
    ]);

  const features = featuresMaybe.value?.features ?? I.List();

  // A function to get an individual feature. This is useful when looking at a
  // feature that may not be included in the list of features (e.g., a feature
  // in a tiled feature collection that needs to surface properties in the UI
  // but is not present in the subset of features that have been fetched.
  // TODO(fiona): Use dataloader to batch individual gets into a bulk request.
  const [getFeature, {updateLocal: updateFeatureLocal, invalidate: invalidateFeature}] =
    useCachedApiGet(
      async ([fcId], fId: number) =>
        (await Remote.api.featureCollections.features(fcId).fetch(fId)).get('data'),
      [featureCollectionId]
    );

  // We use updateLocal to prime useCachedApiGet’s cache so that requests for
  // features that are already downloaded don’t need to be re-requested.
  React.useEffect(() => {
    features.forEach((f) => updateFeatureLocal(f!.get('id'), () => f!));
  }, [updateFeatureLocal, features]);

  // Invoking the `resolve` function that we stored in the `fetchSomeFeatures`
  // generator function. This will resolve the `await` statement that guards the
  // generator function from performing another loop with the next page number.
  const loadNextFeatures = React.useCallback<FeaturesActions['loadNextFeatures']>(() => {
    if (resolveNextPagePromiseRef.current) {
      resolveNextPagePromiseRef.current();
    }
  }, [resolveNextPagePromiseRef]);

  // A function to update our local feature and features values. It takes an
  // updater function, meaning that it can be used to update local state
  // optimistically or with server data in response to any CRUD operation.
  const updateLocal = React.useCallback(
    (
      ids: number[],
      updater: (
        feature: I.ImmutableOf<ApiFeature> | undefined
      ) => I.ImmutableOf<ApiFeature> | undefined
    ) => {
      ReactDOM.unstable_batchedUpdates(() => {
        // We need to update the per-feature cache separately from the feature
        // collection’s list of features, to handle the case where we’re
        // updating a feature that was loaded directly, but hasn’t yet been
        // paged to in the feature collection.
        ids.forEach((id) => updateFeatureLocal(id, updater));

        updateFeaturesLocal((v) => {
          if (!v) {
            return v;
          }

          return {
            features: v.features
              .map((f) => (ids!.includes(f!.get('id')) ? updater(f!)! : f!))
              .toList(),
            page: v.page,
          };
        }, true);
      });
    },
    [updateFeaturesLocal, updateFeatureLocal]
  );

  // A function that handles updating a feature with changes. It optimistically
  // updates our local values, makes the API request, and updates our local
  // values again with the new feature. In the event the API request fails, we
  // throw an error and revert to the previous feature version.
  const updateFeature = React.useCallback(
    async (featureId: number, changes: I.MergesInto<I.ImmutableOf<ApiFeature>>) => {
      let serverFeature: I.ImmutableOf<ApiFeature> | null = null;

      updateLocal([featureId], (feature) => {
        if (feature) {
          serverFeature = feature;
        }

        return feature?.mergeDeep(I.fromJS(changes));
      });

      try {
        serverFeature = (
          await api.featureCollections.features(featureCollectionId).update(featureId, changes)
        ).get('data');
      } finally {
        updateLocal([featureId], () => serverFeature || undefined);
      }
    },
    [api.featureCollections, featureCollectionId, updateLocal]
  );

  const batchPatchFeature = React.useCallback<FeaturesActions['batchPatchFeature']>(
    async ({featureCollectionId, features, changes}) => {
      // Named `maybeErrors` because successful requests will return undefineds,
      // and failed requests will return error objects.
      const maybeErrors = await Promise.all(
        immutableUtils
          // 100 gives us URLs of about ~1300 chars, which falls under the de
          // facto max browser max of 2000 chars.
          .splitIntoChunks(features.map((f) => f!.get('id')).toIndexedSeq(), 100)
          .map(async (featureIdsChunk) => {
            // Optimistically merge the changes into the specified features.
            updateLocal(featureIdsChunk!.toArray(), (feature) =>
              feature?.mergeDeep(I.fromJS(changes))
            );

            const updatedFeatures = (
              await api.featureCollections
                .features(featureCollectionId)
                .batchPatch(featureIdsChunk!.toList(), changes)
            ).get('data');

            const updatedFeaturesById = I.Map<number, I.ImmutableOf<ApiFeature>>(
              updatedFeatures.map((f) => [f!.get('id'), f!] as const)
            );

            updateLocal(updatedFeaturesById.keySeq().toArray(), (feature) =>
              updatedFeaturesById.get(feature!.get('id'))
            );
          })
          .map((p) =>
            // Catch exceptions and return them as values because otherwise
            // Promise.all will fail immediately on an exception.
            p!.then(
              () => {},
              (e) => e
            )
          )
          .toArray()
      );

      // Results will be undefineds if there was success, and the error if it
      // was a failure.
      const errors = maybeErrors.filter((e) => !!e);

      if (errors.length) {
        // If some batch updates failed then the features are in an
        // indeterminate state, so we invalidate and re-list them.
        features.forEach((f) => invalidateFeature(f!.get('id'), true));

        refreshFeatures();

        // We raise up the first failure if it exists.
        throw errors[0];
      }
    },
    [api.featureCollections, invalidateFeature, refreshFeatures, updateLocal]
  );

  const batchArchiveFeatures = React.useCallback<FeaturesActions['batchArchiveFeatures']>(
    async ({featureCollectionId, features}) => {
      // Named `maybeErrors` because successful requests will return undefineds,
      // and failed requests will return error objects.
      const maybeErrors = await Promise.all(
        immutableUtils
          // 100 gives us URLs of about ~1300 chars, which falls under the de
          // facto max browser max of 2000 chars.
          .splitIntoChunks(features.map((f) => f!.get('id')).toIndexedSeq(), 100)
          .map(async (featureIdsChunk) => {
            // Optimistically archive the selected features
            updateLocal(featureIdsChunk!.toArray(), (feature) =>
              feature?.setIn(['properties', 'isArchived'], true)
            );

            await api.featureCollections
              .features(featureCollectionId)
              .batchArchive(featureIdsChunk!.toList());
          })
          .map((p) =>
            // Catch exceptions and return them as values because otherwise
            // Promise.all will fail immediately on an exception.
            p!.then(
              () => {},
              (e) => e
            )
          )
          .toArray()
      );

      // Results will be undefineds if there was success, and the error if it
      // was a failure.
      const errors = maybeErrors.filter((e) => !!e);

      if (errors.length) {
        // If some batch updates failed then the features are in an
        // indeterminate state, so we invalidate and re-list them.
        features.forEach((f) => invalidateFeature(f!.get('id'), true));

        refreshFeatures();

        // We raise up the first failure if it exists.
        throw errors[0];
      }
    },
    [api.featureCollections, invalidateFeature, refreshFeatures, updateLocal]
  );

  // Moves features from one portfolio to another
  const moveFeatures = React.useCallback<FeaturesActions['moveFeatures']>(
    async ({currentFeatureCollectionId, newFeatureCollectionId, newProjectId, featureIds}) => {
      try {
        await Remote.api.featureCollections
          .features(currentFeatureCollectionId)
          .moveFeatures(newFeatureCollectionId, newProjectId, featureIds);
      } catch (e) {
        const {cause, error} = e as ApiResponseError;
        Sentry.captureException(cause || error);
        // Let the user know that there has been an issue, and they can try again.
        window.alert('We weren’t able to move your properties right now. Please try again.');
      }

      // Refetch all features in the portfolio
      refreshFeatures();
    },
    [refreshFeatures]
  );

  // TODO(fiona): Tweak this so that the featuresActions object doesn’t get
  // recreated on every render.
  return [
    features,
    featuresStatus,
    {
      loadNextFeatures,
      getFeature,
      updateFeature,
      batchPatchFeature,
      batchArchiveFeatures,
      invalidateFeature,
      moveFeatures,
      refreshFeatures,
    },
  ];
}

export type GeoJsonFeaturesLoader = (
  fc: I.ImmutableOf<ApiFeatureCollection>
) => I.ImmutableOf<ApiFeature[]>;

/**
 * Hook to generate a loader function (backed by useCachedApiGet) to resolve
 * Lens feature collections into their features.
 *
 * Ignores tiled feature collections (just returns an empty list).
 *
 * Used for overlays, which are not locally edited and are either tiled or we
 * load all of their features.
 *
 * The getter accepts a FeatureCollection so we can look at the tiles property,
 * but returns a list of ApiFeatures rather than a HydratedFeatureCollection.
 * This is because we’re both deprecating HydratedFeatureCollection and also
 * punting on the issue of caching a stable HydratedFeatureCollection (passing
 * an ApiFeatureCollection in to useCachedApiGet’s fetch function is problematic
 * because the current key algorithm depends on number/string/boolean args).
 */
export function useGeoJsonFeaturesLoader(): GeoJsonFeaturesLoader {
  const [getFeatures] = useCachedApiGet(
    (_, fcId: number) =>
      Remote.api.featureCollections.features(fcId).listGen({perPage: 200}, {getAllPages: 2}),
    []
  );

  // We use a wrapper because we can’t use a full ApiFeatureCollection as an arg
  // to useCachedApiGet, since it doesn’t serialize to a string.
  return React.useCallback(
    (fc: I.ImmutableOf<ApiFeatureCollection>) => {
      if (fc.get('tiles')) {
        return I.List();
      } else {
        return getFeatures(fc.get('id')).value || I.List();
      }
    },
    [getFeatures]
  );
}
