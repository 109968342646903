import * as B from '@blueprintjs/core';
import React from 'react';
import {Link} from 'react-router-dom';

import {useUserInfo} from 'app/providers/AuthProvider';
import {StateApiNote, isVegetationDropAlert} from 'app/stores/NotesStore';
import {recordEvent} from 'app/tools/Analytics';
import {getOrgPrefix} from 'app/utils/organizationUtils';

import cs from './NoteCard.styl';

export const UnenrolledLayerMessage: React.FunctionComponent<
  React.PropsWithChildren<{
    note: StateApiNote;
  }>
> = ({note}) => {
  const [organization] = useUserInfo();
  //TODO(eva): if we ever have alerts aside from Vegetation Drop associated to a layer, we may want to
  //generalize the vegetation message a bit more.
  return (
    <B.Callout style={{margin: 0}} className={cs.hasUnenrolledLayerWarning} intent="warning">
      {isVegetationDropAlert(note) ? (
        <>
          Vegetation (S2) is no longer processed for this portfolio and new Vegetation Alerts are
          not being created.{' '}
          <Link
            to={`/${getOrgPrefix(organization!)}/settings/layers`}
            onClick={() => recordEvent('Followed missing layer warning')}
            target="_blank"
          >
            Update portfolio layers
          </Link>
        </>
      ) : (
        <>
          This note references a layer that has been removed and will be hidden in reports.{' '}
          <Link
            to={`/${getOrgPrefix(organization!)}/settings/layers`}
            onClick={() => recordEvent('Followed missing layer warning')}
            target="_blank"
          >
            Update portfolio layers
          </Link>
        </>
      )}
    </B.Callout>
  );
};
