import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';
import Gravatar from 'react-gravatar';

import {ListItem} from 'app/components/List';
import {AlertPolicy, ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import * as CONSTANTS from 'app/utils/constants';
import {featureCollectionInUS} from 'app/utils/featureCollectionUtils';
import {CollectionConsistency, enrollmentStatusForCustomAlert} from 'app/utils/featureUtils';
import * as userUtils from 'app/utils/userUtils';

import cs from './PropertyOverview.styl';

/**
 * Generates an array of ListItem objects of the provided users to use in a List
 * component.
 *
 * Used both in the filter and assignment setting UIs.
 */
export function makeUserListItems(
  organizationUsers: ApiOrganizationUser[],
  isSelectedFn: (user: ApiOrganizationUser) => boolean = () => false
): ListItem<{id: string}>[] {
  return organizationUsers.map((u) => ({
    id: u!.email,
    text: userUtils.getSuffixedUserName(u!),
    icon: <Gravatar email={u!.email} size={20} />,
    isSelected: isSelectedFn(u!),
  }));
}

// Configuration object mapping collection consistency to iconography in menus
const iconsByCollectionConsistency: {[key in CollectionConsistency]: B.IconName} = {
  all: 'tick',
  some: 'minus',
  none: 'blank',
};

// Generate ListItems for bulk alert enrollment and filtering
// Will check if the selected feature collection is in the US and omit ownership alerts if not.
export function getAlertListItems(
  vegetationDropEnrollmentStatus: CollectionConsistency,
  ownershipChangeEnrollmentStatus: CollectionConsistency,
  selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>,
  alertPolicies: AlertPolicy[],
  selectedFeatures: I.List<I.MapAsRecord<I.ImmutableFields<ApiFeature>>>
): ListItem<{id: string; icon?: React.ReactElement; text: string}>[] {
  const menuItems: ListItem<{
    id: string;
    icon?: React.ReactElement;
    text: string;
  }>[] = [
    {
      id: CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY,
      icon: (
        <span className={cs.alertAccentCustomAlert}>
          <B.Icon icon={iconsByCollectionConsistency[vegetationDropEnrollmentStatus]} />
        </span>
      ),
      text: 'Vegetation drop',
    },
    {
      id: CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY,
      icon: (
        <span className={cs.alertAccentCustomAlert}>
          <B.Icon icon={iconsByCollectionConsistency[ownershipChangeEnrollmentStatus]} />
        </span>
      ),
      text: 'Parcel owner change',
    },
  ];

  alertPolicies.forEach((alertPolicy) => {
    menuItems.push({
      id: JSON.stringify(alertPolicy.id),
      text: alertPolicy.name,
      icon: (
        <span className={cs.alertAccentCustomAlert}>
          <B.Icon
            icon={
              iconsByCollectionConsistency[
                enrollmentStatusForCustomAlert(selectedFeatures, alertPolicy)
              ]
            }
          />
        </span>
      ),
    });
  });

  const fcInUS = featureCollectionInUS(selectedFeatureCollection);

  // If our feature collection is not in the US, remove the menu option corresponding to
  // ownership change alerts, since we'll be outside of regrid's service area.
  if (!fcInUS) {
    menuItems.splice(1, 1);
  }

  return menuItems;
}
