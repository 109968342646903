import {centerOfMass} from '@turf/turf';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import COLORS from 'app/styles/colors.json';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {useContinuity} from 'app/utils/hookUtils';
import * as mapUtils from 'app/utils/mapUtils';

import MapContent, {useStyleLoaded} from './MapContent';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  featureCollection: HydratedFeatureCollection;
  selectedFeatureIds: I.Set<number>;
  selectFeatureById: (id: number) => void;
  hoveredFeatureIds: I.Set<number>;
  hoverFeatureById: (arg: number | null) => void;
  labelOnHover?: boolean;
  customColor?: string;
  showPropertyBoundaries?: boolean;
  // send this fc to the top of the stack of all the layers
  // on the map
  sendToTop?: boolean;
}

/** Feature state key. True if feature is the only selected feature. */
const SELECTED_SINGLE_KEY = 'selected-single';
/** Feature state key. True if the feature is part of many selected. */
const SELECTED_MULTI_KEY = 'selected-multi';
/** Feature state key. True if the mouse is hovering over it. */
const HOVERED_KEY = 'hovered';

/**
 * Draws Lens features on a MapboxGL map.
 *
 * Unselected features are outlined in white, with a hover effect that gives
 * them a semi-transparent background and a property name label. Clicking on an
 * unselected feature triggers a call to selectFeatureById.
 *
 * Selected features are rendered differently depending on how many are
 * selected. If just one is, it is rendered as an outline with no fill or hover
 * effects.
 *
 * If multiple features are selected, they’re rendered with a semitransparent
 * background and a hover effect (but no label). Clicking on one will call
 * selectFeatureById with its feature ID.
 *
 * This multi-selection behavior is designed to support multi-feature
 * properties, which are logically the same property but are implemented as
 * multiple features for technical reasons.
 */
const FeatureCollectionVector: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  featureCollection,
  selectedFeatureIds = I.Set(),
  selectFeatureById,
  hoveredFeatureIds,
  hoverFeatureById,
  customColor,
  labelOnHover = false,
  showPropertyBoundaries = true,
  sendToTop = false,
}) => {
  // Owing to realtime updates, feature collection can sometimes change with no
  // effect on the features themselves.
  //
  // TODO(fiona): Remove FeatureCollection from this component entirely and just
  // take an array of geojson Features.
  featureCollection = useContinuity(featureCollection, I.is);
  const styleLoaded = useStyleLoaded(map);

  const polygonSource = React.useMemo(
    () => mapUtils.getSourceForFeatureCollection(featureCollection),
    [featureCollection]
  );

  // Makes a source that is used to power the property names appearing on hover.
  // We do this so we have more control than when MapboxGL places its own labels
  // as part of a marker layer. (For example, we can put one label per feature,
  // regardless of whether or not it’s multi-polygon, we don’t have to worry
  // about labels colliding, &c.)
  //
  // NOTE: This doesn’t work for tiled feature collections, though currently
  // that’s not a feature requirement for Lens.
  const labelSource = React.useMemo<{id: string; source: mapboxgl.GeoJSONSourceRaw}>(
    () => ({
      id: `${polygonSource.id}-labels`,
      source: {
        type: 'geojson',
        data: geoJsonUtils.featureCollection(
          featureCollection
            .get('features')
            .map((f) => ({
              id: f!.get('id'),
              ...centerOfMass(f!.get('geometry').toJS(), {
                properties: {
                  name: f!.getIn(['properties', 'name']),
                  partName: f!.getIn(['properties', 'multiFeaturePartName']),
                },
              }),
            }))
            .toArray()
        ),
      },
    }),
    [polygonSource.id, featureCollection]
  );

  // This is designed to have styleLoaded as its dep, so that if the style goes
  // from unloaded -> loaded it re-runs any feature state–related useEffects.
  // (Since the style changing resets all feature states.)
  const setStateForFeatureId = React.useCallback(
    (id: number | string | undefined | null, state: any) => {
      if (id !== undefined && id !== null && styleLoaded?.loaded) {
        map.setFeatureState(
          {source: polygonSource.id, sourceLayer: polygonSource.sourceLayer, id},
          state
        );
      }
    },
    [map, polygonSource.id, polygonSource.sourceLayer, styleLoaded]
  );

  const setLabelStateForFeatureId = React.useCallback(
    (id: number | string | undefined | null, state: any) => {
      if (id !== undefined && id !== null && styleLoaded?.loaded) {
        map.setFeatureState({source: labelSource.id, id}, state);
      }
    },
    [map, labelSource.id, styleLoaded]
  );

  const areClickableFeaturesDisabled = mapUtils.useAreClickableFeaturesDisabled();

  // Fills in features when more than one is selected, or if an unselected
  // feature is hovered over.
  const fillLayer: mapboxgl.FillLayer = React.useMemo(
    () => ({
      type: 'fill',

      id: `${polygonSource.id}-fill`,
      source: polygonSource.id,
      ...(polygonSource.sourceLayer ? {'source-layer': polygonSource.sourceLayer} : {}),

      layout: {
        // Keeps us from showing hover effects when clicking will have no
        // effect.
        visibility: areClickableFeaturesDisabled ? 'none' : 'visible',
      },

      paint: {
        'fill-color': customColor ?? COLORS.primary,
        'fill-opacity': [
          'case',
          [
            'all',
            ['boolean', ['feature-state', SELECTED_MULTI_KEY], false],
            ['boolean', ['feature-state', HOVERED_KEY], false],
          ],
          0.85,
          ['boolean', ['feature-state', SELECTED_MULTI_KEY], false],
          0.4,
          ['boolean', ['feature-state', HOVERED_KEY], false],
          0.6,
          0,
        ],
      },
    }),
    [polygonSource.id, polygonSource.sourceLayer, areClickableFeaturesDisabled, customColor]
  );

  // Draws a line around all of the features.
  const lineLayer: mapboxgl.LineLayer = React.useMemo(
    () => ({
      type: 'line',

      id: `${polygonSource.id}-lines`,
      source: polygonSource.id,
      ...(polygonSource.sourceLayer ? {'source-layer': polygonSource.sourceLayer} : {}),

      paint: {
        'line-color': customColor ?? '#ffffff',
        // It might be nicer to do this with different colors rather than
        // opacity, but then we’d have to worry about z-order of the features.
        // This looks good enough.
        'line-opacity': [
          'case',
          [
            'any',
            ['boolean', ['feature-state', SELECTED_SINGLE_KEY], false],
            ['boolean', ['feature-state', SELECTED_MULTI_KEY], false],
            ['boolean', ['feature-state', HOVERED_KEY], false],
          ],
          1,
          0.7,
        ],
        'line-width': [
          'case',
          [
            'any',
            ['boolean', ['feature-state', SELECTED_SINGLE_KEY], false],
            ['boolean', ['feature-state', SELECTED_MULTI_KEY], false],
          ],
          3,
          2,
        ],
      },
    }),
    [polygonSource.id, polygonSource.sourceLayer, customColor]
  );

  // Black shadow underneath the property border to help it stand out
  const lineShadowLayer: mapboxgl.LineLayer = React.useMemo(
    () => ({
      type: 'line',

      id: `${polygonSource.id}-line-shadow`,
      source: polygonSource.id,
      ...(polygonSource.sourceLayer ? {'source-layer': polygonSource.sourceLayer} : {}),

      paint: {
        'line-color': '#000000',
        'line-width': 4,
        'line-blur': 1,
        // do not show shadow on properties that are not selected
        'line-opacity': [
          'case',
          [
            'any',
            ['boolean', ['feature-state', SELECTED_SINGLE_KEY], false],
            ['boolean', ['feature-state', SELECTED_MULTI_KEY], false],
          ],
          1,
          0,
        ],
      },
    }),
    [polygonSource.id, polygonSource.sourceLayer]
  );

  // Labels for the features that appear on hover
  // usage of mapbox "format" inspired by: https://docs.mapbox.com/mapbox-gl-js/example/display-and-style-rich-text-labels/
  const labelLayer: mapboxgl.SymbolLayer = React.useMemo(
    () => ({
      type: 'symbol',

      id: `${labelSource.id}-text`,
      source: labelSource.id,

      layout: {
        'text-field': [
          'format',
          ['get', 'name'],
          {},
          '\n',
          {},
          ['get', 'partName'],
          {'font-scale': 0.8},
        ],
        'text-allow-overlap': true,
        visibility: areClickableFeaturesDisabled ? 'none' : 'visible',
      },

      paint: {
        'text-color': '#fff',
        'text-halo-color': 'rgba(0, 0, 0, 0.75)',
        'text-halo-width': 1,
        'text-opacity': [
          'case',
          // We never want to show the labels on the selected feature
          [
            'any',
            ['boolean', ['feature-state', SELECTED_SINGLE_KEY], false],
            ['boolean', ['feature-state', SELECTED_MULTI_KEY], false],
          ],
          0,
          ['boolean', ['feature-state', HOVERED_KEY], false],
          1,
          0,
        ],
      },
    }),
    [labelSource.id, areClickableFeaturesDisabled]
  );

  // Updates our feature state to indicate what’s selected.
  React.useEffect(() => {
    selectedFeatureIds.forEach((selectedFeatureId) => {
      setStateForFeatureId(selectedFeatureId, {
        [SELECTED_SINGLE_KEY]: selectedFeatureIds.size === 1,
        [SELECTED_MULTI_KEY]: selectedFeatureIds.size > 1,
      });
    });

    return () => {
      selectedFeatureIds.forEach((selectedFeatureId) => {
        setStateForFeatureId(selectedFeatureId, {
          [SELECTED_SINGLE_KEY]: false,
          [SELECTED_MULTI_KEY]: false,
        });
      });
    };
  }, [selectedFeatureIds, setStateForFeatureId]);

  const activeFeatureId = selectedFeatureIds.size === 1 ? selectedFeatureIds.first() : null;

  // Returns false if the event includes the currently selected feature, so it
  // can be ignored from clicks and hovers.
  const filterNoActiveFeature = React.useCallback(
    (ev: mapboxgl.MapLayerMouseEvent) => {
      if (activeFeatureId !== null) {
        if ((ev.features || []).find((f) => f.id === activeFeatureId)) {
          return false;
        }
      }

      return true;
    },
    [activeFeatureId]
  );

  mapUtils.useClickableFeatures(
    map,
    [fillLayer, lineLayer],
    // We can cast to number because we know our features have numeric id
    // properties. Promise.resolve() is there to get us into the next event loop
    // before changing the feature ID, since modifying the map features and
    // layers from within an event handler makes mapboxgl sad ("this.paint is
    // undefined" sad)
    (f) => Promise.resolve().then(() => selectFeatureById(f.id as number)),
    filterNoActiveFeature
  );

  // Updates the map’s feature state to reflect the hover values we get from
  // props.
  React.useEffect(() => {
    hoveredFeatureIds.forEach((id) => {
      setStateForFeatureId(id, {[HOVERED_KEY]: true});
      if (labelOnHover) {
        setLabelStateForFeatureId(id, {[HOVERED_KEY]: true});
      }
    });

    return () => {
      hoveredFeatureIds.forEach((id) => {
        setStateForFeatureId(id, {[HOVERED_KEY]: false});
        if (labelOnHover) {
          setLabelStateForFeatureId(id, {[HOVERED_KEY]: false});
        }
      });
    };
  }, [setStateForFeatureId, setLabelStateForFeatureId, hoveredFeatureIds, labelOnHover]);

  // Add handlers for hover effects on the features.
  React.useEffect(() => {
    // We track label hovering separate from feature hovering. This is so that
    // we only show one label at a time, since they’re liable to overlap if we
    // showed all labels from all parts of a multi-feature property.
    //
    // TODO(fiona): Maybe show the feature name using a different mechanism,
    // like a tool tip that follows the mouse?
    let labelHoveredFeatureId: number | null = null;

    const onMouseMove = (event: mapboxgl.MapLayerMouseEvent) => {
      // The filter function has the same semantics of Array#filter: if it
      // returns false, ignore that element.
      if (!filterNoActiveFeature(event)) {
        //If we're move our mouse to our active feature, clear the previous hover
        //and label. Otherwise we can get into a state where the hover gets stuck
        //while we're trying to look at our main feature.
        onMouseLeave();
        return;
      }

      const id = event.features?.[0].id ?? null;

      hoverFeatureById(id as number | null);

      if (id !== labelHoveredFeatureId) {
        setLabelStateForFeatureId(labelHoveredFeatureId, {[HOVERED_KEY]: false});
        setLabelStateForFeatureId(id, {[HOVERED_KEY]: true});
        labelHoveredFeatureId = id as number | null;
      }
    };

    const onMouseLeave = () => {
      // We don't have a feature ID here so we just have to unhover everything.
      // We assume that mousemove will correct this.
      hoverFeatureById(null);

      setLabelStateForFeatureId(labelHoveredFeatureId, {[HOVERED_KEY]: false});
      labelHoveredFeatureId = null;
    };

    // We need these on both the fill and the line so that features can be
    // clickable on their outline even when their fill is completely overlapped
    // by the current feature. Unfortunately this currently causes jittering as
    // the "mouseleave" event on the line layer triggers to remove the highlight
    // before the mousemove event on the fill can fix it.
    //
    // TODO(fiona): Smooth this out with a debounce or some other condition on
    // clearing the hover.
    map.on('mousemove', fillLayer.id, onMouseMove);
    map.on('mousemove', lineLayer.id, onMouseMove);
    map.on('mouseleave', fillLayer.id, onMouseLeave);
    map.on('mouseleave', lineLayer.id, onMouseLeave);

    return () => {
      map.off('mousemove', fillLayer.id, onMouseMove);
      map.off('mousemove', lineLayer.id, onMouseMove);
      map.off('mouseleave', fillLayer.id, onMouseLeave);
      map.off('mouseleave', lineLayer.id, onMouseLeave);

      setLabelStateForFeatureId(labelHoveredFeatureId, {[HOVERED_KEY]: false});
    };
  }, [
    map,
    featureCollection,
    lineLayer.id,
    fillLayer.id,
    setStateForFeatureId,
    setLabelStateForFeatureId,
    hoverFeatureById,
    filterNoActiveFeature,
  ]);

  React.useEffect(() => {
    if (map && isMapLoaded && sendToTop) {
      map.moveLayer(fillLayer.id);
      map.moveLayer(lineLayer.id);
    }
  }, [sendToTop, fillLayer.id, lineLayer.id, map, isMapLoaded]);

  return (
    <MapContent
      map={map}
      isMapLoaded={isMapLoaded}
      sources={[polygonSource, labelSource]}
      layers={showPropertyBoundaries ? [fillLayer, lineShadowLayer, lineLayer, labelLayer] : []}
    />
  );
};

export default FeatureCollectionVector;
