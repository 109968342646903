import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as geojson from 'geojson';
import {History} from 'history';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';

import {AlertPolicyContextProvider} from 'app/components/Alerts/AlertPolicyProvider';
import AlertsSidebar from 'app/components/Alerts/AlertsSidebar';
import LookoutIcon from 'app/components/Alerts/LookoutIcon';
import AnalyzePolygonPopupWithState from 'app/components/AnalyzePolygonPopup/AnalyzePolygonPopupWithState';
import AppNav from 'app/components/AppNav';
import ManageBasemapDialog from 'app/components/DeclarativeMap/ManageBasemapDialog';
import {PixelInfoPopupProvider} from 'app/components/DeclarativeMap/PixelInspector/PixelInfoProvider';
import NotesView, {Props as NotesProps} from 'app/components/NotesReports/NotesView';
import Reports, {ReportExportState} from 'app/components/NotesReports/ReportsView';
import OrderImageryModal, {orderImagery} from 'app/components/OrderImageryModal';
import OrderImagerySidebar, {
  DEFAULT_ORDER_IMAGERY_STATE,
  OrderImageryState,
} from 'app/components/OrderImagerySidebar';
import PropertyDetailsSidebar from 'app/components/PropertyDetailsSidebar/PropertyDetailsSidebar';
import ReportExportWindow from 'app/components/ReportExport/ReportExportWindow';
import {ReportPortalProvider} from 'app/components/ReportExport/ReportPortalProvider';
import TabbedSidebar, {
  DEFAULT_SIDEBAR_VIEW_STATE,
  SidebarView,
  SidebarViewState,
} from 'app/components/TabbedSidebar/TabbedSidebar';
import {ToastNotification} from 'app/modules/Notification/types';
import {ApiFeature, ApiFeatureData, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  GeometryOverlaySetting,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {ApiImageryPrices} from 'app/modules/Remote/Imagery';
import {
  ApiOrganization,
  ApiOrganizationUser,
  getMeasurementSystem,
  getOrgBasemapStyle,
} from 'app/modules/Remote/Organization';
import {ApiImageryContract, ApiProject} from 'app/modules/Remote/Project';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {FeaturesActions, GeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {
  InvalidateOrderableScenes,
  OrderableScenesLoader,
} from 'app/providers/OrderableScenesProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import {ShareLinkProvider} from 'app/providers/ShareLinkProvider';
import {NotesActions, NotesState, StateApiNote} from 'app/stores/NotesStore';
import {recordEvent} from 'app/tools/Analytics';
import * as CONSTANTS from 'app/utils/constants';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as historyUtils from 'app/utils/historyUtils';
import * as hookUtils from 'app/utils/hookUtils';
import {CachedApiGetterOptions, StatusMaybe} from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import {Mode} from 'app/utils/mapUtils/modes';
import {
  getMultiFeaturePropertyParts,
  isMultiLocationProperty,
} from 'app/utils/multiFeaturePropertyUtils';
import * as noteUtils from 'app/utils/noteUtils';
import * as routeUtils from 'app/utils/routeUtils';

import Hotkeys from './Hotkeys';
import Map from './Map';
import {MapStateDispatch} from './MapStateProvider';
import Navigation from './Navigation';
import cs from './styles.styl';
import {DEFAULT_COLORS} from './SwatchColorPicker';
import UrlSceneHandler from './UrlSceneHandler';
import {OVERLAY_POPUP_INSET, setIsImageHidden} from './utils';
import ManageLayersDialog from '../../components/DeclarativeMap/ManageLayersDialog';
import {PolygonDispatch, PolygonState} from '../../providers/MapPolygonStateProvider';
import {changeSelection} from '../ProjectDashboardSwitch';

const MAP_ZOOM_ANIMATION_DURATION_NONE = 0;
const MAP_ZOOM_ANIMATION_DURATION_EASE = 1000;

const NOTES_SIDEBAR_TITLE = 'Notes';
const LOOKOUT_SIDEBAR_TITLE = 'Lookout';
const DETAILS_SIDEBAR_TITLE = 'Details';
const ANALYZE_SIDEBAR_TITLE = 'Analysis';
const REPORTS_SIDEBAR_TILE = 'Reports';

export interface Props {
  projectsActions: ProjectsActions;
  pushNotification: (p: ToastNotification) => unknown;

  history: History;

  firebaseToken: string;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.ImmutableOf<ApiOrganization>;
  organizationUsers: ApiOrganizationUser[];
  loggedInUserActions: LoggedInUserActions;

  selectedProject: I.ImmutableOf<ApiProject>;
  selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
  selectedFeatureIdParams: I.Set<string>;

  getFeatureData: hookUtils.CachedApiGetter<[number], I.ImmutableOf<ApiFeatureData[]>>;
  featureData: I.ImmutableListOf<ApiFeatureData> | null;

  imageRefs: mapUtils.MapImageRefs;
  imageRefFromUrl: mapUtils.MapImageRef | null;
  mode: Mode;
  mapStateDispatch: MapStateDispatch;

  overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
  geoJsonFeaturesLoader: GeoJsonFeaturesLoader;

  orderableScenesLoader: OrderableScenesLoader;
  invalidateOrderableScenes: InvalidateOrderableScenes;

  notesState: NotesState;
  notesActions: NotesActions;
  polygonState: PolygonState;
  polygonDispatch: PolygonDispatch;

  getCurrentImageryContract: (
    projectId: string,
    opts?: CachedApiGetterOptions
  ) => StatusMaybe<ApiImageryContract | null>;

  features: I.ImmutableOf<ApiFeature[]>;
  selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;
  selectedFeature: I.ImmutableOf<ApiFeature> | null;
  featuresActions: FeaturesActions;
  focusedNoteId?: number | null;

  imageryPrices: ApiImageryPrices;
  refreshImageryPrices: () => void;
}

export type MapTool =
  | 'analyzePolygon'
  | 'measureDistance'
  | 'measureArea'
  | 'manageLayers'
  | 'manageBasemap'
  | 'pixelInspector';

interface State {
  /**
   * The GeoJSON for what we most recently wanted to zoom to.
   * FeatureCollection, Feature, or note Geometry.
   */
  focusedGeometry: geojson.GeoJSON | null;
  animationDuration: number;
  mapStyle: mapUtils.MapStyle;

  mapPaddingOptions: mapboxgl.PaddingOptions;
  tabbedSidebarState: SidebarViewState;

  activeTool: MapTool | null;

  // These are in state because if they get modified then we need to recalculate
  // the padding for zoom-to-fit. And, while we don't re-zoom because of it, the
  // zoom-to-fit control needs to know if it should be enabled or not.
  analyzePolygonEl: HTMLDivElement | null;
  manageLayersEl: HTMLDivElement | null;

  isOrderImageryModalOpen: boolean;
  sceneToOrder: I.ImmutableOf<ApiOrderableScene> | null;

  isAnalyzeAreaPopupOpen: boolean;

  isOverlayVisibleByName: Record<string, boolean>;

  reportExportHtmlEl: HTMLElement | null;
  notesFilterState: noteUtils.NotesFilterState;
  reportExportAllParts: boolean;
  savedReportId: number | undefined;
  pendingNewReportExportState: ReportExportState | null;
  reportTitle: string | undefined;
  refetchReports?: () => Promise<void>;

  showCostDebugger: boolean;

  // Relevant to OrderImagerySidebar but elevated here so they persist
  // when opening and closing the sidebar
  orderImageryState: OrderImageryState;

  latestCameraOptions: mapUtils.CameraOptions | null;
}

export const DEFAULT_REPORT_EXPORT_DATES = () => {
  return [
    moment().subtract(1, 'year').toDate(),
    // Unbounded end date.
    null,
  ] as noteUtils.DateRange;
};

export const DEFAULT_NOTES_FILTER_STATE = {
  dateRange: DEFAULT_REPORT_EXPORT_DATES(),
  noteTagIds: [],
};

const DEFAULT_STATE: State = {
  focusedGeometry: null,
  animationDuration: MAP_ZOOM_ANIMATION_DURATION_NONE,
  mapStyle: mapUtils.MAPBOX_DARK_STYLE,
  mapPaddingOptions: {top: 0, right: 0, bottom: 0, left: 0},
  tabbedSidebarState: DEFAULT_SIDEBAR_VIEW_STATE,
  activeTool: null,
  analyzePolygonEl: null,
  manageLayersEl: null,
  isOrderImageryModalOpen: false,
  isAnalyzeAreaPopupOpen: false,
  sceneToOrder: null,
  isOverlayVisibleByName: {},
  reportExportHtmlEl: null,
  notesFilterState: {dateRange: DEFAULT_REPORT_EXPORT_DATES(), noteTagIds: []},
  reportExportAllParts: false,
  savedReportId: undefined,
  pendingNewReportExportState: null,
  reportTitle: undefined,
  showCostDebugger: false,

  orderImageryState: DEFAULT_ORDER_IMAGERY_STATE,

  latestCameraOptions: null,
};

// RDM is basically complete coverage of the land for features it’s on, so
// having it on by default would probably get irritating. We disable system
// polgyons which are often large and make the features hard to see.
// Regrid is off by default to ensure that there's user intent before using quota.
//
// TODO(fiona): This should probably be a per-FeatureCollection setting of
// whether it makes sense to be on or off by default.
const OVERLAY_PRODUCTS_OFF_BY_DEFAULT = [
  CONSTANTS.PRODUCT_SYSTEM_POLYGON,
  CONSTANTS.PRODUCT_REGRID,
];

// These overlays should have their polygons filled in by default to make them easier click targets.
// Holding down <alt> will disable the fill (the inverse of the behavior for overlays that aren't filled by default).
const OVERLAY_PRODUCTS_FILLED_BY_DEFAULT = [CONSTANTS.PRODUCT_REGRID];

export default class MonitorProjectView extends React.Component<Props, State> {
  state = DEFAULT_STATE;

  private reportExportWindow: Window | null = null;

  componentDidMount() {
    const {selectedFeatureIdParams, organization} = this.props;

    // 0 features right now is for the tasking tool, where we don’t want to be
    // distracted by a basemap image.
    if (selectedFeatureIdParams.size <= 1) {
      this.setState({mapStyle: getOrgBasemapStyle(organization)});
    } else {
      this.setState({mapStyle: mapUtils.MAPBOX_SATELLITE_STREETS_STYLE});
    }

    // Sets visibility based on the defaults in settings.
    //
    // We’re ok limiting this to componentDidMount because there’s no path that
    // keeps this component mounted while changing projects / feature
    // collections.
    this.setState({
      isOverlayVisibleByName: this.makeOverlaySettings().reduce(
        (visible, {name, defaultEnabled}) => ({
          ...visible,
          [name]: defaultEnabled,
        }),
        {}
      ),
    });

    this.focusOnFeatureGeometry();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    /*
     * Check that selectedFeatures has the same size as selectedFeatureIds so we don't fit the map
     * until all selectedFeatures are loaded
     */

    if (
      !I.is(this.props.selectedFeatures, prevProps.selectedFeatures) &&
      this.props.selectedFeatures.size === this.props.selectedFeatureIdParams.size
    ) {
      this.focusOnFeatureGeometry();
    }

    /*
     * Default to SATELLITE_STREETS_STYLE when no feature is selected
     * and DARK_STYLE when a feature is selected
     */
    if (
      prevProps.selectedFeatureIdParams.size > 1 &&
      this.props.selectedFeatureIdParams.size <= 1
    ) {
      this.setState({mapStyle: getOrgBasemapStyle(this.props.organization)});
    } else if (
      prevProps.selectedFeatureIdParams.size <= 1 &&
      this.props.selectedFeatureIdParams.size > 1
    ) {
      this.setState({mapStyle: mapUtils.MAPBOX_SATELLITE_STREETS_STYLE});
    }

    // Things to reset when the selected features change.
    if (!I.is(this.props.selectedFeatureIdParams, prevProps.selectedFeatureIdParams)) {
      // Close the imagery ordering modal if we switch features.
      this.closeOrderImageryModal();

      // Reset subset ordering tool
      this.setOrderImageryState({
        ...this.state.orderImageryState,
        orderSubset: false,
        subsetFeature: null,
      });

      this.setState({
        // Reset the notes filter state if we switch features.
        notesFilterState: DEFAULT_STATE.notesFilterState,
        // And close all tools
        activeTool: null,
      });
    }

    // If we are not in an valid state for the MeasureTool tool, or if we
    // start drawing on the map to add a note geometry but measureDistance
    // is still set as the active tool, clear it.
    if (
      this.props.notesState.addPendingNoteGeometryMode &&
      this.state.activeTool === 'measureDistance'
    ) {
      this.setState({activeTool: null});
    }

    // If we close the sidebar OR change sidebar modes while the sidebar is open to AA,
    // we need to update the sidebar state but also clear out the AA dialog and polygons.
    // Otherwise the dialog will remain open while we do other sidebar stuff.
    if (
      prevState.tabbedSidebarState.currentViewTitle !==
        this.state.tabbedSidebarState.currentViewTitle ||
      prevState.tabbedSidebarState.isExpanded !== this.state.tabbedSidebarState.isExpanded
    ) {
      if (prevState.tabbedSidebarState.currentViewTitle === ANALYZE_SIDEBAR_TITLE) {
        this.resetAndCloseAA();
      }
    }

    // If we switch to another MapTool while we're in AA, hard clear out AA dialog and polygons.
    // This is safer than letting a user start to mess around with the map while they're drawing
    // in AA, but it might be useful in the future to have say, the Manage Layers (overlays) dialog
    // open at the same time as AA.
    if (
      prevState.activeTool !== this.state.activeTool &&
      prevState.activeTool === 'analyzePolygon'
    ) {
      this.resetAndCloseAA();
    }

    // If we close the sidebar and were ordering a subset, then reset the subset
    // ordering state
    if (
      prevState.tabbedSidebarState.isExpanded === true &&
      this.state.tabbedSidebarState.isExpanded === false
    ) {
      this.setOrderImageryState({
        ...this.state.orderImageryState,
        orderSubset: false,
        subsetFeature: null,
      });
    }

    if (this.props.notesState.notes !== prevProps.notesState.notes) {
      const {notes, focusedNoteId} = this.props.notesState;
      // Once we have received notes from the API, isolate
      // the focused note so that we can zoom to its
      // geometry. We also want to open the "Notes" sidebar
      // tab, which should automatically scroll to the
      // focused note.
      if (notes.length) {
        const focusedNote = notes.find((n) => n.id === focusedNoteId);

        if (focusedNoteId && !focusedNote) {
          this.props.pushNotification({
            message: `There was a problem loading the note.`,
            options: {intent: B.Intent.DANGER},
          });
        }

        if (focusedNote) {
          this.props.mapStateDispatch({
            type: 'SET_IMAGE_REFS',
            imageRefs: focusedNote.imageRefs,
          });

          //if the focusedNote was created before the ReportExport start date, update
          //the Report Export start date so focusedNote is included in the date range
          if (
            // need to guard against null here in case user
            // clears startDate then refetches notes
            this.state.notesFilterState.dateRange[0] &&
            focusedNote!.createdAt < this.state.notesFilterState.dateRange[0].toISOString()
          ) {
            const extendedStartDate = new Date(focusedNote!.createdAt);
            extendedStartDate.setDate(extendedStartDate.getDate() - 1);
            this.setState({
              notesFilterState: {
                ...prevState.notesFilterState,
                dateRange: [extendedStartDate, null],
              },
            });
          }

          this.zoomToGeometry(focusedNote.geometry);
          this.setState({
            tabbedSidebarState: {
              isExpanded: true,
              currentViewTitle:
                focusedNote.noteType === 'alert' ? LOOKOUT_SIDEBAR_TITLE : NOTES_SIDEBAR_TITLE,
            },
          });
        }
      }
    }

    if (prevState.activeTool !== 'analyzePolygon' && this.state.activeTool === 'analyzePolygon') {
      recordEvent('Opened Analysis');
    }

    if (prevState.activeTool !== 'measureDistance' && this.state.activeTool === 'measureDistance') {
      recordEvent('Opened measure distance');
    }
  }

  componentWillUnmount() {
    if (this.reportExportWindow) {
      this.reportExportWindow.close();
      this.reportExportWindow = null;
    }
  }

  private onMapPaddingChanged = (newMapPaddingOptions: mapboxgl.PaddingOptions) => {
    const {mapPaddingOptions} = this.state;
    if (!isEqual(mapPaddingOptions, newMapPaddingOptions)) {
      this.setState({mapPaddingOptions: newMapPaddingOptions});
    }
  };

  private setTabbedSidebarState = (newState: SidebarViewState) => {
    this.setState({tabbedSidebarState: newState});
  };

  private setOrderImageryState = (newState: React.SetStateAction<OrderImageryState>) => {
    this.setState((state) => {
      if (typeof newState === 'function') {
        return {orderImageryState: newState(state.orderImageryState)};
      } else {
        return {orderImageryState: newState};
      }
    });
  };

  private resetAndCloseAA = () => {
    // Remove any AA polygons from the map
    this.props.polygonDispatch({type: 'setPolygonFeature', polygonFeature: null});

    // Switch the AA draw mode back to its default
    this.props.polygonDispatch({
      type: 'setAnalyzeAreaDrawMode',
      analyzeAreaDrawMode: 'drawPolygon',
    });

    // Remove any polygons hanging out from pending notes
    this.props.notesActions.removePendingNoteGeometryFeatureById('pending-note-location-feature');

    // If the AA sidebar is selected when we reset and close, and reset the sidebar state so
    // AA its no longer active and close popup.
    if (this.state.tabbedSidebarState.currentViewTitle === ANALYZE_SIDEBAR_TITLE) {
      this.setTabbedSidebarState({currentViewTitle: null, isExpanded: false});
      this.setState({isAnalyzeAreaPopupOpen: false});
    } else {
      //Otherwise, if another sidebar mode is selected, just close the popup.
      this.setState({isAnalyzeAreaPopupOpen: false});
    }

    // Most of the time when we're closing AA, we want to set activeTool to null. The exception is if we're
    // clearing out AA because we've opened another MapTool-- then we want to maintain that.
    if (this.state.activeTool === 'analyzePolygon') {
      this.setState({activeTool: null});
    }
  };

  render() {
    const {
      imageRefs,
      mode,
      history,
      organization,
      loggedInUserActions,
      selectedProject,
      selectedFeature,
      selectedFeatureCollection,
      selectedFeatureIdParams,
      selectedFeatures,
      mapStateDispatch,
      profile,
      firebaseToken,
      overlayFeatureCollections,
      geoJsonFeaturesLoader,

      featureData,
      getFeatureData,
      notesState,
      notesActions,
      polygonState,
      polygonDispatch,

      features,
      projectsActions,

      imageRefFromUrl,
      orderableScenesLoader,
    } = this.props;

    const {
      mapPaddingOptions,
      tabbedSidebarState,
      isOverlayVisibleByName,
      activeTool,
      analyzePolygonEl,
      manageLayersEl,
      focusedGeometry,
      animationDuration,
      mapStyle,
      reportExportHtmlEl,
      notesFilterState,
      reportExportAllParts,
      showCostDebugger,
      orderImageryState,
      isAnalyzeAreaPopupOpen,
      latestCameraOptions,
      savedReportId,
      reportTitle,
      refetchReports,
    } = this.state;

    const hydratedFeatureCollection = featureCollectionUtils.hydratedFeatureCollection(
      selectedFeatureCollection,
      features
    );

    const overlaySettings = this.makeOverlaySettings();

    const multiFeaturePropertyParts = getMultiFeaturePropertyParts(features, selectedFeature);
    let activeNoteType: StateApiNote['noteType'] | null = null;
    if (tabbedSidebarState.isExpanded) {
      if (tabbedSidebarState.currentViewTitle === NOTES_SIDEBAR_TITLE) {
        activeNoteType = 'user';
      } else if (tabbedSidebarState.currentViewTitle === LOOKOUT_SIDEBAR_TITLE) {
        activeNoteType = 'alert';
      }
    }

    return (
      <div id="projectView">
        <Helmet>
          <title>
            {selectedFeatures.size > 0
              ? selectedFeatures.first().getIn(['properties', 'name'])
              : selectedProject.get('name')}{' '}
            | Lens
          </title>
        </Helmet>
        <PixelInfoPopupProvider
          isOpen={activeTool === 'pixelInspector'}
          setIsOpen={(isOpen) => this.setState({activeTool: isOpen ? 'pixelInspector' : null})}
          imageRefs={imageRefs}
        >
          <ShareLinkProvider
            cameraOptions={latestCameraOptions}
            feature={selectedFeature}
            featureData={featureData}
            project={selectedProject}
            overlayFeatureCollections={overlayFeatureCollections}
            overlayVisibilityByName={isOverlayVisibleByName}
          >
            <ReportPortalProvider>
              <AppNav
                history={history}
                organization={organization}
                profile={profile}
                selectedProject={selectedProject}
                isMinimal={true}
                loggedInUserActions={loggedInUserActions}
                showHotkeys
              >
                <Navigation
                  imageRefs={imageRefs}
                  featureData={featureData}
                  mode={mode}
                  // TODO(emily): The `Navigation` component assumes the feature
                  // collection is hydrated. Once we eliminate the hydrated feature
                  // collection universally, we can pass in `features` instead of the
                  // `hydratedFeatureCollection` object here.
                  selectedFeatureCollection={hydratedFeatureCollection}
                  selectedFeatureIdParams={selectedFeatureIdParams}
                  selectedFeatures={selectedFeatures}
                  organization={organization}
                  profile={profile}
                  selectedProject={selectedProject}
                  activeTool={activeTool}
                  setActiveTool={(activeTool) => this.setState({activeTool})}
                  mapStateDispatch={mapStateDispatch}
                  firebaseToken={firebaseToken}
                />
              </AppNav>
              <div className={cs.container}>
                <TabbedSidebar
                  views={this.renderSidebarViews(
                    notesState,
                    notesActions,
                    hydratedFeatureCollection
                  )}
                  hidden={!selectedFeature}
                  state={tabbedSidebarState}
                  setState={this.setTabbedSidebarState}
                  setPaddingOptions={this.onMapPaddingChanged}
                />

                <Map
                  firebaseToken={firebaseToken}
                  profile={profile}
                  organization={organization}
                  // TODO(emily): The `Map` component assumes the feature collection
                  // is hydrated. Once we eliminate the hydrated feature collection
                  // universally, we can pass in `features` instead of the
                  // `hydratedFeatureCollection` object here.
                  selectedFeatureCollection={hydratedFeatureCollection}
                  selectedFeatures={selectedFeatures}
                  setSelectedFeatureIds={(featureLensIds) =>
                    changeSelection({
                      organizationId: organization.get('id'),
                      projectId: selectedProject.get('id'),
                      featureIds: featureLensIds,
                    })
                  }
                  imageRefs={imageRefs}
                  featureData={featureData}
                  // Disables compare mode when more than one property is selected
                  mode={selectedFeatureIdParams.size === 1 ? mode : 'VIEW'}
                  activeTool={activeTool}
                  setActiveTool={(activeTool) => this.setState({activeTool})}
                  focusedGeometry={focusedGeometry}
                  zoomToGeometry={this.zoomToGeometry}
                  // We provide default camera options for continuity between view and
                  // compare modes. This will be overridden immediately by
                  // focusedGeometry if it is set, so we don’t need to worry about
                  // clearing it between features or anything.
                  //
                  // We save the camera options (center, pitch, bearing, zoom) instead
                  // of bounds because when the bearing or pitch is non-zero, the
                  // bounds will be the smallest bounds that encompas the visible
                  // region. This can create a situation where the map is fit to
                  // bounds from a pitched map, and then the pitch is re-applied to
                  // the map, causing the map to zoom out when switching modes.
                  defaultCameraOptions={latestCameraOptions}
                  onMapMoved={(e) => {
                    // We set focusedGeometry to null so that it doesn’t then override
                    // our defaultCameraOptions if the map instance gets recreated.
                    this.setState({
                      focusedGeometry: null,
                      latestCameraOptions: {
                        center: e.target.getCenter(),
                        pitch: e.target.getPitch(),
                        bearing: e.target.getBearing(),
                        zoom: e.target.getZoom(),
                      },
                    });
                  }}
                  notesState={notesState}
                  notesActions={notesActions}
                  showNotes={!!activeNoteType}
                  onClickNoteMarker={(geometry, note) => {
                    // Selecting the polygon will zoom to it. This case handles
                    // zooming to notes.
                    this.zoomToGeometry(geometry);

                    if (note) {
                      this.setFocusedNoteIdParam(note.id);
                    }
                  }}
                  polygonState={polygonState}
                  polygonDispatch={polygonDispatch}
                  overlayFeatureCollections={overlayFeatureCollections}
                  geoJsonFeaturesLoader={geoJsonFeaturesLoader}
                  isOverlayVisibleByName={isOverlayVisibleByName}
                  overlaySettings={overlaySettings}
                  mapPaddingOptions={mapPaddingOptions}
                  mapStyle={mapStyle}
                  animationDuration={animationDuration}
                  popupEl={analyzePolygonEl || manageLayersEl}
                  filterNotesCallback={(n) =>
                    !!activeNoteType &&
                    noteUtils.filterNotesCallback(n, activeNoteType, notesFilterState)
                  }
                  showCostDebugger={showCostDebugger}
                  setShowCostDebugger={(showCostDebugger) => this.setState({showCostDebugger})}
                  orderImageryState={orderImageryState}
                  setOrderImageryState={this.setOrderImageryState}
                  tabbedSidebarState={tabbedSidebarState}
                  measurementSystem={getMeasurementSystem(organization)}
                  openAnalyzePolygonPopup={this.openAnalyzePolygonPopupProp}
                />
                <div className={cs.overlayControlsWrapper}>
                  {activeTool === 'manageLayers' && (
                    <ManageLayersDialog
                      organization={organization}
                      profile={profile}
                      ref={this.setManageLayersEl}
                      onClose={() => this.setState({activeTool: null})}
                      overlaySettings={overlaySettings}
                      setOverlaySettings={(overlays) => {
                        projectsActions.updateFeatureCollection(
                          selectedFeatureCollection.get('id'),
                          featureCollectionUtils.updateLensView(selectedFeatureCollection, (v) =>
                            v.set('overlays', I.fromJS(overlays))
                          )
                        );
                      }}
                      isOverlayVisibleByName={isOverlayVisibleByName}
                      setIsOverlayVisibleByName={this.setIsOverlayVisibleByName}
                    />
                  )}
                  {activeTool === 'manageBasemap' && (
                    <ManageBasemapDialog
                      onClose={() => this.setState({activeTool: null})}
                      onChangeMapStyle={(nextMapStyle) => this.setState({mapStyle: nextMapStyle})}
                      selectedStyle={mapStyle}
                    />
                  )}
                </div>

                <div
                  style={{
                    position: 'absolute',
                    paddingTop: mapPaddingOptions.top + OVERLAY_POPUP_INSET,
                    paddingRight: mapPaddingOptions.right + OVERLAY_POPUP_INSET,
                    paddingBottom: mapPaddingOptions.bottom + OVERLAY_POPUP_INSET,
                    paddingLeft: mapPaddingOptions.left + OVERLAY_POPUP_INSET,
                  }}
                >
                  {featureData && selectedFeature ? (
                    <AnalyzePolygonPopupWithState
                      ref={this.setAnalyzePolygonEl}
                      firebaseToken={firebaseToken}
                      featureCollection={selectedFeatureCollection}
                      feature={selectedFeature}
                      featureData={featureData}
                      imageRefs={imageRefs}
                      notesState={notesState}
                      notesActions={notesActions}
                      polygonState={polygonState}
                      polygonDispatch={polygonDispatch}
                      mapStateDispatch={mapStateDispatch}
                      organization={organization}
                      profile={profile}
                      isOpen={isAnalyzeAreaPopupOpen}
                      onClose={() => {
                        this.resetAndCloseAA();
                      }}
                      updateFeatureCollection={projectsActions.updateFeatureCollection}
                    />
                  ) : (
                    <></>
                  )}
                </div>

                {this.renderOrderImageryModal()}

                {selectedFeatures && selectedFeatures.size > 1 && (
                  <div
                    className={classnames(
                      cs.infoHoverContainer,
                      cs.forLocationsMessages,
                      cs.withRoomForControls
                    )}
                  >
                    <span>
                      The <strong>{selectedFeatures.first().getIn(['properties', 'name'])}</strong>{' '}
                      property contains{' '}
                      <strong style={{whiteSpace: 'nowrap'}}>
                        {selectedFeatures.size} locations
                      </strong>
                      .
                      <br />
                      Click on the map to view imagery for a given area.
                    </span>
                  </div>
                )}

                {reportExportHtmlEl &&
                  selectedFeature &&
                  featureData &&
                  ReactDOM.createPortal(
                    <ReportExportWindow
                      firebaseToken={firebaseToken}
                      organization={organization}
                      getFeatureData={getFeatureData}
                      notesActions={notesActions}
                      projectId={selectedProject.get('id')}
                      geoJsonFeaturesLoader={geoJsonFeaturesLoader}
                      selectedFeatureCollection={selectedFeatureCollection}
                      selectedFeature={selectedFeature}
                      overlayFeatureCollections={overlayFeatureCollections}
                      overlaySettings={overlaySettings}
                      multiFeaturePropertyParts={multiFeaturePropertyParts}
                      notesFilterState={notesFilterState}
                      includeAllParts={reportExportAllParts}
                      savedReportId={savedReportId}
                      reportTitle={reportTitle}
                      refetchReports={refetchReports}
                      reportWindow={this.reportExportWindow}
                    />,
                    reportExportHtmlEl
                  )}
              </div>

              <Hotkeys
                organizationId={organization.get('id')}
                selectedFeatureCollection={selectedFeatureCollection}
                selectedFeatures={selectedFeatures}
                selectedProjectId={selectedProject.get('id')}
                features={features}
                imageRefs={imageRefs}
                featureData={featureData}
                mapStateDispatch={mapStateDispatch}
              />
              <UrlSceneHandler
                imageRefFromUrl={imageRefFromUrl}
                featureData={featureData}
                mapStateDispatch={mapStateDispatch}
                orderableScenesLoader={orderableScenesLoader}
                selectedFeature={selectedFeature}
                openOrderImageryModal={this.openOrderImageryModal}
              />
            </ReportPortalProvider>
          </ShareLinkProvider>
        </PixelInfoPopupProvider>
      </div>
    );
  }

  private setAnalyzePolygonEl = (analyzePolygonEl: HTMLDivElement | null) => {
    this.setState({analyzePolygonEl});
  };

  private setManageLayersEl = (manageLayersEl: HTMLDivElement | null) => {
    this.setState({manageLayersEl});
  };

  private renderSidebarViews(
    notesState: NotesState,
    notesActions: NotesActions,
    hydratedFeatureCollection: HydratedFeatureCollection
  ): SidebarView[] {
    const {
      firebaseToken,
      selectedFeature,
      overlayFeatureCollections,
      profile,
      organization,
      organizationUsers,
      imageRefs,
      featuresActions,
      projectsActions,
      getCurrentImageryContract,
      selectedProject,
      featureData,
      history,
      features,
    } = this.props;

    const imageryContract = getCurrentImageryContract(selectedProject.get('id'));
    const hasImageryContract = !!imageryContract.value;
    const paidImagery = selectedFeature ? featureUtils.getPaidImagery(selectedFeature) : null;

    // Only display the order imagery sidebar if it is an option to order imagery
    const showOrderImagery = hasImageryContract || !!paidImagery;

    const isProcessingData =
      selectedFeature?.getIn(['properties', 'status']) ===
      CONSTANTS.FEATURE_WAITING_FOR_PROCESSING_STATUS;

    const reportsViews: SidebarView[] = [
      {
        title: REPORTS_SIDEBAR_TILE,
        icon: <B.Icon icon="document" />,
        disabled: !selectedFeature,
        render: () => {
          if (!selectedFeature) {
            return null;
          }

          const isMultiLocation = isMultiLocationProperty(features, selectedFeature);

          return this.renderReports(selectedFeature, isMultiLocation);
        },
      },
    ];

    const notesViews: SidebarView[] = [
      {
        title: NOTES_SIDEBAR_TITLE,
        icon: <B.Icon icon="annotation" />,
        disabled: !selectedFeature,
        render: () => {
          if (!selectedFeature) {
            return null;
          }

          return this.renderNotes(
            'user',
            selectedFeature,
            {
              notesState,
              notesActions,
            },
            featuresActions.updateFeature,
            projectsActions.updateFeatureCollection
          );
        },
      },
    ];

    const orderImageryViews: SidebarView[] = showOrderImagery
      ? [
          {
            title: 'Order',
            icon: <B.Icon icon="shopping-cart" />,
            disabled: !selectedFeature,
            render: () => {
              if (!selectedFeature) {
                return null;
              }

              return this.renderOrderImagery();
            },
          },
        ]
      : [];

    const alertsViews: SidebarView[] = [
      {
        title: LOOKOUT_SIDEBAR_TITLE,
        icon: <LookoutIcon />,
        render: () => {
          if (!selectedFeature) {
            return null;
          }

          return this.renderNotes(
            'alert',
            selectedFeature,
            {
              notesState,
              notesActions,
            },
            featuresActions.updateFeature,
            projectsActions.updateFeatureCollection
          );
        },
      },
    ];

    const analyzeAreaViews: SidebarView[] = [
      {
        title: ANALYZE_SIDEBAR_TITLE,
        icon: <B.Icon icon="timeline-line-chart" />,
        disabled: !selectedFeature || isProcessingData,
        hidePanelDrawer: true,
        onClick: () => {
          if (!this.state.isAnalyzeAreaPopupOpen) {
            this.setState({
              activeTool: 'analyzePolygon',
              isAnalyzeAreaPopupOpen: true,
            });
          }
        },
      },
    ];

    const firstLayerKey = imageRefs[0].layerKey;

    return [
      ...orderImageryViews,
      ...notesViews,
      ...reportsViews,
      ...analyzeAreaViews,
      ...alertsViews,
      {
        title: DETAILS_SIDEBAR_TITLE,
        icon: <B.Icon icon="info-sign" />,
        disabled: !selectedFeature,
        render: () => {
          if (!selectedFeature) {
            return null;
          }

          return (
            <PropertyDetailsSidebar
              firebaseToken={firebaseToken}
              featureCollection={featureCollectionUtils.hydratedFeatureCollectionAsApiFeatureCollection(
                hydratedFeatureCollection
              )}
              allFeatures={hydratedFeatureCollection.get('features')}
              overlayFeatureCollections={overlayFeatureCollections}
              selectedFeatureId={selectedFeature.get('id')}
              batchPatchFeature={featuresActions.batchPatchFeature}
              batchArchiveFeature={featuresActions.batchArchiveFeatures}
              updateFeatureCollection={projectsActions.updateFeatureCollection}
              role={profile.get('role')}
              organization={organization}
              organizationUsers={organizationUsers}
              activeLayerKey={firstLayerKey}
              // We're using some hard-coded values here for width. The width of the TabbedSidebar
              // is 36rem, and the PropertyDetailsSidebar specifies 1rem of padding. Any elements
              // occupying 100% of the padded area would then be 36rem - (2 * 1rem) = 34rem or
              // 340px.
              layerLegendWidth={340}
              featureData={featureData}
              imageRefs={imageRefs}
              history={history}
              project={selectedProject}
            />
          );
        },
      },
    ];
  }

  private renderOrderImagery() {
    const {
      organization,
      mode,
      selectedFeatures,
      profile,
      featuresActions,
      imageRefs,
      mapStateDispatch,
      orderableScenesLoader,
      selectedProject,
      getCurrentImageryContract,
      firebaseToken,
    } = this.props;
    const {orderImageryState} = this.state;

    const setIsHidden = (feature, orderableScene, isHidden) => {
      setIsImageHidden(featuresActions.updateFeature, feature, orderableScene, isHidden);
    };

    return (
      <OrderImagerySidebar
        organization={organization}
        profile={profile}
        mode={mode}
        imageRefs={imageRefs}
        onChange={(imageRef) =>
          mapStateDispatch({
            type: 'SET_IMAGE_REFS',
            imageRefs: [imageRef],
          })
        }
        feature={selectedFeatures.first()}
        setIsImageHidden={profile.get('role') === 'owner' ? setIsHidden : null}
        openOrderImageryModal={this.openOrderImageryModal}
        orderImageryState={orderImageryState}
        setOrderImageryState={this.setOrderImageryState}
        orderableScenesLoader={orderableScenesLoader}
        getCurrentImageryContract={(opts) =>
          getCurrentImageryContract(selectedProject.get('id'), opts)
        }
        firebaseToken={firebaseToken}
      />
    );
  }

  private renderNotes(
    noteType: StateApiNote['noteType'],
    selectedFeature: I.ImmutableOf<ApiFeature>,
    requiredNotesProps: Pick<NotesProps, 'notesState' | 'notesActions'>,
    updateFeature: FeaturesActions['updateFeature'],
    updateFeatureCollection: ProjectsActions['updateFeatureCollection']
  ) {
    const {
      selectedFeatureCollection,
      features,
      imageRefs,
      organization,
      profile,
      mapStateDispatch,
      firebaseToken,
      featureData,
      organizationUsers,
    } = this.props;

    const {notesFilterState, latestCameraOptions} = this.state;

    const enrolledLayerKeys =
      layerUtils.getEnrolledLayerKeysForFeatureCollection(selectedFeatureCollection);

    if (noteType === 'user') {
      return (
        <NotesView
          firebaseToken={firebaseToken}
          featureData={featureData}
          className={cs.notes}
          organization={organization}
          profile={profile}
          cameraOptions={latestCameraOptions}
          zoomToGeometry={this.zoomToGeometry}
          onSelectNote={this.setFocusedNoteIdParam}
          onSelectImageRefs={(imageRefs) => mapStateDispatch({type: 'SET_IMAGE_REFS', imageRefs})}
          selectedFeatureCollection={selectedFeatureCollection}
          imageRefs={imageRefs}
          selectedFeature={selectedFeature}
          enrolledLayerKeys={enrolledLayerKeys}
          openAnalyzePolygonPopup={this.openAnalyzePolygonPopupProp}
          promptForCoordinates={() =>
            this.promptForNoteCoordinates(requiredNotesProps.notesActions)
          }
          updateFeatureCollection={updateFeatureCollection}
          organizationUsers={organizationUsers}
          notesFilterState={notesFilterState}
          setNotesFilterState={this.setNotesFilterState}
          setReportExportModalState={this.setReportExportModalState}
          unsetFocusedNoteId={this.unsetFocusedNoteId}
          {...requiredNotesProps}
        />
      );
    } else if (noteType === 'alert') {
      return (
        <AlertPolicyContextProvider featureCollection={selectedFeatureCollection}>
          <AlertsSidebar
            feature={selectedFeature}
            allFeatures={features}
            featureCollection={selectedFeatureCollection}
            enrolledLayerKeys={enrolledLayerKeys}
            updateFeature={updateFeature}
            zoomToGeometry={this.zoomToGeometry}
            onSelectNote={this.setFocusedNoteIdParam}
            onSelectImageRefs={(imageRefs) => mapStateDispatch({type: 'SET_IMAGE_REFS', imageRefs})}
            organization={organization}
            {...requiredNotesProps}
          />
        </AlertPolicyContextProvider>
      );
    }
  }

  private renderReports(
    selectedFeature: I.ImmutableOf<ApiFeature>,
    isMultiLocationProperty: boolean
  ) {
    const {selectedFeatureCollection, notesState} = this.props;
    const {notesFilterState, pendingNewReportExportState} = this.state;

    const selectedFeatureId = selectedFeature.get('id');
    const selectedFeatureName = selectedFeature.getIn(['properties', 'name']);

    return (
      <Reports
        selectedFeatureId={selectedFeatureId}
        selectedFeatureName={selectedFeatureName}
        selectedFeatureCollection={selectedFeatureCollection}
        setReportExportFilterState={this.setNotesFilterState}
        setReportExportModalState={this.setReportExportModalState}
        openReportExportModal={this.openReportExportModal}
        isMultiLocationProperty={isMultiLocationProperty}
        notesState={notesState}
        notesFilterState={notesFilterState}
        reportExportState={pendingNewReportExportState}
      />
    );
  }

  private zoomToGeometry = (geometry: geojson.GeoJSON | null) => {
    // Bit of a hack to add some imperitiveness to our
    // declarative zooming. Fixes the issue where someone
    // clicks on a note that was already selected after
    // having moved the map. We want to re-zoom to that
    // feature, so first we set the focus to null so that
    // GeoJsonFitter will pick up on a change when we
    // focus back. See #2340
    this.setState(
      {
        focusedGeometry: null,
      },
      () => {
        this.setState({
          focusedGeometry: geometry,
          animationDuration: MAP_ZOOM_ANIMATION_DURATION_EASE,
        });
      }
    );
  };

  private promptForNoteCoordinates = (noteStore: NotesActions) => {
    const latLng = prompt('Enter latitude, longitude.');
    if (!latLng || latLng == '') {
      return;
    }

    const [lat, lng] = latLng.split(/[ ,]+/);
    if (
      this.isCoordinateValid(parseFloat(lng), 180) &&
      this.isCoordinateValid(parseFloat(lat), 90)
    ) {
      // While we present coordinates as "latitude, longitude" in Lens for consistency
      // with common GIS tools, we reverse to "longitude, latitude" here for
      // compatibility with the geojson specification.
      const feature = geoJsonUtils.point([Number(lng), Number(lat)], {}, {id: 'point-from-coords'});
      noteStore.setPendingNoteGeometryFeature(feature);

      this.setState({
        focusedGeometry: feature,
      });
    }
  };

  private isCoordinateValid = (coordinate: number, range: number) => {
    return !isNaN(coordinate) && Math.abs(coordinate) <= range;
  };

  /**
   * Updates the focusedGeometry state to show either the currently selected
   * feature or, absent that, the entire feature collection’s bounds.
   *
   * Called to initialize the map and when the selected feature changes.
   */
  private focusOnFeatureGeometry() {
    const {selectedFeatureCollection, selectedFeatures} = this.props;

    let focusedGeometry: geojson.GeoJSON | null = null;

    const featureCollectionBounds = selectedFeatureCollection.get('bounds');

    if (selectedFeatures.size) {
      focusedGeometry = geoJsonUtils.featureCollection(selectedFeatures.toJS());
    } else if (featureCollectionBounds) {
      focusedGeometry = featureCollectionBounds.toJS();
    }

    this.setState({
      focusedGeometry,
      animationDuration: MAP_ZOOM_ANIMATION_DURATION_NONE,
    });
  }

  private renderOrderImageryModal() {
    const {selectedFeature, selectedProject, getCurrentImageryContract, profile, organization} =
      this.props;
    const {isOrderImageryModalOpen, sceneToOrder, orderImageryState} = this.state;

    if (!selectedFeature || !isOrderImageryModalOpen || !sceneToOrder) {
      return;
    }

    return (
      <OrderImageryModal
        role={profile.get('role')}
        modalUrl={routeUtils.makeHighResSceneUrl({
          organizationId: organization.get('id'),
          projectId: selectedProject.get('id'),
          featureLensId: selectedFeature.getIn(['properties', 'lensId']),
          sourceId: sceneToOrder.get('sourceId'),
          date: sceneToOrder.get('sensingTime'),
        })}
        feature={selectedFeature}
        orderableScene={sceneToOrder}
        organization={organization}
        onClose={this.closeOrderImageryModal}
        onSubmit={this.orderImagery}
        getCurrentImageryContract={(opts) =>
          getCurrentImageryContract(selectedProject.get('id'), opts)
        }
        subsetFeature={orderImageryState.orderSubset ? orderImageryState.subsetFeature : null}
      />
    );
  }

  private openOrderImageryModal = (d: I.ImmutableOf<ApiOrderableScene>) => {
    this.setState((s) =>
      !s.isOrderImageryModalOpen || s.sceneToOrder !== d
        ? {isOrderImageryModalOpen: true, sceneToOrder: d}
        : null
    );
  };

  private closeOrderImageryModal = () => {
    this.setState({
      isOrderImageryModalOpen: false,
      sceneToOrder: null,
    });
  };

  private orderImagery = async (billableAcres: number) => {
    const {
      pushNotification,
      selectedProject,
      selectedFeatureCollection,
      selectedFeature,
      notesActions,
      featuresActions,
      invalidateOrderableScenes,
      imageryPrices,
      refreshImageryPrices,
    } = this.props;

    const {
      sceneToOrder,
      orderImageryState: {subsetFeature, orderSubset},
    } = this.state;

    if (!selectedFeature || !sceneToOrder) {
      return;
    }

    this.closeOrderImageryModal();

    const updatedFeature = await orderImagery(
      selectedProject.get('id'),
      selectedFeatureCollection.get('id'),
      selectedFeature.get('id'),
      sceneToOrder,
      billableAcres,
      imageryPrices[sceneToOrder.get('sourceId')],
      refreshImageryPrices,
      pushNotification,
      orderSubset,
      subsetFeature?.geometry
    );

    invalidateOrderableScenes(selectedFeature.get('id'));

    if (updatedFeature) {
      featuresActions.updateFeature(updatedFeature.get('id'), updatedFeature);

      // We reload notes to catch that the imagery notifications may have
      // changed.
      notesActions.loadNotes();
    }
  };

  private openAnalyzePolygonPopup = (polygonFeature) => {
    this.props.polygonDispatch({type: 'setPolygonFeature', polygonFeature});
    this.setState({
      tabbedSidebarState: {
        isExpanded: true,
        currentViewTitle: ANALYZE_SIDEBAR_TITLE,
      },
    });
  };
  openAnalyzePolygonPopupProp = this.openAnalyzePolygonPopup.bind(this);

  private setIsOverlayVisibleByName = (payload: Record<string, boolean>) => {
    this.setState({isOverlayVisibleByName: {...this.state.isOverlayVisibleByName, ...payload}});
  };

  private makeOverlaySettings() {
    const {overlayFeatureCollections, selectedFeatureCollection} = this.props;

    const overlayNames = new Set(
      featureCollectionUtils.uniqueFeatureCollectionNames(overlayFeatureCollections).toArray()
    );

    const lensView = featureCollectionUtils.getLensView(selectedFeatureCollection);

    const overlaySettings: GeometryOverlaySetting[] = [];

    // Defensive code is defensive. There’s no guarantee that there’s a "lens"
    // view, or what its properties might be.
    lensView?.get('overlays')?.forEach((o) => {
      // This existence check filters out settings for overlays that are no
      // longer on the project.
      if (overlayNames.has(o!.get('name'))) {
        const overlayFeatureCollection = overlayFeatureCollections.find(
          (fc) => fc!.get('name') === o!.get('name')
        );
        overlaySettings.push({...o!.toJS(), status: overlayFeatureCollection.get('status')});

        // Remove it from the Set so that we don’t try to add in a default for
        // the same name.
        overlayNames.delete(o!.get('name'));
      }
    });

    featureCollectionUtils
      .uniqueFeatureCollectionNames(overlayFeatureCollections)
      // At this point, overlay names has overlays that are in the project but we
      // do not have existing preferences for. Fill them in in alphabetical order
      // with our default colors.
      .filter((name) => overlayNames.has(name!))
      .forEach((name, idx) => {
        const overlayFeatureCollection = overlayFeatureCollections.find(
          (fc) => fc!.get('name') === name
        );
        const product = overlayFeatureCollection.get('product');

        overlaySettings.push({
          name: name!,
          color: DEFAULT_COLORS[idx! % DEFAULT_COLORS.length],
          defaultEnabled: !OVERLAY_PRODUCTS_OFF_BY_DEFAULT.includes(product),
          defaultFilled: OVERLAY_PRODUCTS_FILLED_BY_DEFAULT.includes(product),
          status: overlayFeatureCollection.get('status'),
        });
      });

    return overlaySettings;
  }

  private setFocusedNoteIdParam = (noteId: string | number) => {
    if (noteId === this.props.focusedNoteId) {
      return this.unsetFocusedNoteId();
    } else {
      return historyUtils.updateHash(
        this.props.history,
        routeUtils.NOTE_URL_PARAM_REGEX,
        `${routeUtils.NOTE_URL_PARAM}${noteId}`
      );
    }
  };

  private unsetFocusedNoteId = () => {
    this.props.notesActions.focusNote(null);
    return historyUtils.updateHash(this.props.history, routeUtils.NOTE_URL_PARAM_REGEX, '');
  };

  // need to rethink this: notes just expects this to set state, reports expects this to open a modal
  // which this no longer does?
  private setNotesFilterState = (reportNotesFilterState?: noteUtils.NotesFilterState) => {
    if (!reportNotesFilterState) {
      this.setState({
        notesFilterState: DEFAULT_NOTES_FILTER_STATE,
      });
    } else {
      this.setState({
        notesFilterState: reportNotesFilterState,
      });
    }
  };

  /**
   * Sets ReportExportModal and opens the reports pane.
   */
  private setReportExportModalState = (reportExportState: ReportExportState | null) => {
    this.setState({
      pendingNewReportExportState: reportExportState,
    });
    this.setTabbedSidebarState({
      isExpanded: true,
      currentViewTitle: 'Reports',
    });
  };

  /**
   * @param newTab While in general we want to open the report export in a new
   * window so we can control the size (to make it more print-preview) and
   * disable navigation, we allow for Control- or Command-clicking the button to
   * open into a new tab to facilitate screen sharing demos. (Opening in a new
   * tab means that the presenter doesn’t have to change the window that they’re
   * screen sharing.)
   */

  private openReportExportModal = ({
    newTab,
    allParts,
    savedReportId,
    reportTitle,
    refetchReports,
  }: ReportExportState) => {
    if (this.reportExportWindow) {
      this.setState({reportExportAllParts: allParts});
    } else {
      this.reportExportWindow = window.open(
        '',
        '_blank',
        newTab ? undefined : 'toolbar=no,status=no,location=no,width=890,height=780'
      );

      if (this.reportExportWindow) {
        this.reportExportWindow.addEventListener('unload', () => {
          this.reportExportWindow = null;
          this.setState({reportExportHtmlEl: null});
        });

        const reportHtmlElement = this.reportExportWindow.document.querySelector('html')!;

        while (reportHtmlElement.firstChild) {
          reportHtmlElement.removeChild(reportHtmlElement.firstChild);
        }

        reportHtmlElement.setAttribute('mozNoMarginBoxes', 'mozNoMarginBoxes');
        reportHtmlElement.setAttribute('mozDisallowSelectionPrint', 'mozDisallowSelectionPrint');

        this.setState({
          reportExportHtmlEl: reportHtmlElement,
          reportExportAllParts: allParts,
          savedReportId: savedReportId,
          reportTitle: reportTitle,
          refetchReports: refetchReports,
        });
      }
    }
  };
}
