import * as B from '@blueprintjs/core';
import React from 'react';

import {getAreaUnit} from 'app/modules/Remote/Organization';
import {useUserInfo} from 'app/providers/AuthProvider';
import {StateApiNote} from 'app/stores/NotesStore';
import colors from 'app/styles/colors.json';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as noteUtils from 'app/utils/noteUtils';

import cs from './NoteCard.styl';

export const SceneLocationPopover: React.FunctionComponent<
  React.PropsWithChildren<{
    note: StateApiNote;
  }>
> = ({note}) => {
  const [organization] = useUserInfo();
  const hasGeometry = !!note.geometry;
  const hasLayer = noteUtils.imageRefsArentBasemap(note.imageRefs);

  const position = geoJsonUtils.makeCoordinateDisplayString(note.geometry);
  const area = geoJsonUtils.makeAreaDisplayString(note.geometry!, getAreaUnit(organization!));

  return (
    <B.Popover
      minimal
      interactionKind={B.PopoverInteractionKind.HOVER}
      modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
      position={'bottom'}
      content={
        <div className={cs.popoverContent}>
          {hasGeometry && (
            <div>
              <div className={cs.detailsLabel}>Location</div>
              {area && <div>{area}</div>}
              {position && (
                <div>
                  {`${note.geometry!.type === 'Point' ? 'Point at' : 'Centered at'} ${position}`}
                </div>
              )}
            </div>
          )}
          {hasLayer && (
            <div>
              <div className={cs.detailsLabel}>
                {note.imageRefs.length === 1 ? 'Scene' : 'Scenes'}
              </div>
              <div>
                {note.imageRefs.map(({cursor, layerKey}, i) => {
                  return (
                    <div key={i}>
                      {`${layerUtils.getLayer(layerKey).display} on ${imageryUtils.formatDate(
                        cursor || '',
                        layerKey
                      )}`}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      }
    >
      <B.AnchorButton
        small
        minimal
        icon={
          <B.Icon
            icon="area-of-interest"
            color={colors.gray}
            size={14}
            onClick={(e) => e.stopPropagation()}
          />
        }
      />
    </B.Popover>
  );
};
