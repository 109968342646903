import areHookInputsEqual from 'are-hook-inputs-equal';
import {History} from 'history';
import * as I from 'immutable';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import React from 'react';
import ReactDOM from 'react-dom';

import * as apiUtils from 'app/utils/apiUtils';

/**
 * Wrapper around values that can be indeterminate (typically due to loading).
 *
 * All options have a "value" and an "error" attribute so that TypeScript will
 * always let you read them, but the types will keep them from being defined
 * when they don’t make sense for the status.
 *
 * This doesn’t explicitly track an "updating" status (value could be unknown
 * but not be being updated, value could be known and updated), so that needs to
 * be passed out-of-band, likely in something like UseApiGetMeta.
 *
 * TODO(fiona): Start using this regularly, since the “null can mean either
 * ‘loading’ or ‘missing’” is starting to give problems.
 */
export type StatusMaybe<T> =
  | {status: 'unknown'; value?: T | undefined; error?: undefined}
  | {status: 'some'; value: T; error?: undefined}
  | {status: 'error'; value?: undefined; error: any};

/**
 * Equivalent of React’s useReducer, except when the deps change the state
 * synchronously returns to the initial state and a fresh dispatch function is
 * returned. The previous dispatch function becomes a no-op.
 *
 * For the same functionality, but with an interface modeled on useState, see
 * useStateWithDeps.
 *
 * Sets an "expired" boolean on the dispatch function so that async operations
 * can cancel themselves.
 *
 * This hook is useful any time you have IDs or keys that are props to your
 * component and async operations and state based on those values that‘s
 * available to other components. For example, a prop might be a feature ID, and
 * your state could be a collection of notes. When the feature ID changes, you
 * want your state to be immediately cleared of the notes that belonged to the
 * last feature. Likewise, if there are any pending loading operations, you
 * don’t want them to overwrite your current feature’s data with things that
 * pertain to the previous feature.
 *
 * With this hook, dep changes are immediately reflected in the new state,
 * rather than waiting for a useEffect (which is important, since if children
 * are consuming this state their useEffects will fire before this component’s).
 * Async operations can safely close over setState functions since they’ll turn
 * into no-ops if the dependencies change.
 */
export function useReducerWithDeps<R extends React.Reducer<any, any>>(
  reducer: R,
  initialState: React.ReducerState<R>,
  deps: readonly any[]
): [React.ReducerState<R>, React.Dispatch<React.ReducerAction<R>> & {expired: boolean}] {
  // So the challenge we’re dealing with here is that of being SYNCHRONOUS, but
  // also being compatible with concurrent mode. So all of our side effects need
  // to happen in useLayoutEffect blocks, but we need our return value to look
  // as if those side effects have happened.
  //
  // Our chief semantic guarantee is: on the render that deps changes, the
  // return value of this hook will be initialState. We additionally ensure that
  // the dispatch function we return changes only when the deps change, which
  // lets it be a proxy for new dependencies.

  const previousDepsRef = React.useRef<null | readonly any[]>(null);
  React.useLayoutEffect(() => {
    previousDepsRef.current = deps;
  });

  // We have to do our own deps check because React Fast Refresh causes
  // useCallback and useEffects to re-run even without the dependencies
  // changing. Because of how those are intertwined with our state, if we didn’t
  // take precautions (for example, if we just used useCallback) then our state
  // would clear out during fast refresh updates, which isn’t ideal (especially
  // when we’re holding state from network requests, which is pretty common).
  //
  // Overall we want our refresh behavior to match useState rather than
  // useCallback.
  const depsHaveChangedThisRender = !areHookInputsEqual(deps, previousDepsRef.current);

  // We wrap the state in an object to give us a seam to force useState to
  // update. This comes into play when we have setState to a value, deps change
  // and we reset to an initialState, and then setState gets called again with
  // the same value from the first time.
  //
  // Because we don’t setState when we revert to an initialState, React’s
  // internal implementation of useState would _not_ trigger a rerender in this
  // case because it only sees going from one value to the same value.
  //
  // The wrapper gives us an ability to force useState to trigger a re-render by
  // having the wrapper object’s identity change, even if the value it points to
  // stays the same.
  const [state, setState] = React.useState({value: initialState});

  // To match the behavior of useReducer, we always run the most recently
  // provided reducer, rather than the one that dispatch happened to initially
  // close over.
  const reducerRef = React.useRef(reducer);
  React.useLayoutEffect(() => {
    reducerRef.current = reducer;
  });

  // This is created every request, but only stored when useDepsChange is true.
  // (This matches how useCallback works.)
  const freshDispatch = Object.assign(
    (a: React.ReducerAction<R>) => {
      if (freshDispatch.expired) {
        // If we’re called when we’re “expired,” that is, a we’re a dispatch
        // that is relevant to a previous set of deps, we no-op.
        return;
      }

      // We wrap all of this in setState so that we’re consistent with any
      // batching or other effects.
      setState((previousSavedStateWrapper) => {
        // This is for the first setState that happens after we have been
        // (re)initialized. We use the initialState value we captured when we
        // were first defined.
        if (freshDispatch.lockedToInitialState) {
          const newState = reducerRef.current(freshDispatch.capturedInitialState, a);

          if (newState === freshDispatch.capturedInitialState) {
            // OK this is odd but it’s okay. Here’s what’s up: the reducer
            // function took the captured initialState and returned it, meaning
            // there should be no state update and no re-render. Since we’re in
            // a setState reducer callback, we need to return the argument we
            // were passed in order to tell React “the state hasn’t changed,
            // don’t re-render.”
            //
            // It’s true that this value we’re returning is not considered the
            // “current” state of the hook, since we’re in a branch where the
            // “current” state is a captured initialState. But it’s what we need
            // to do to say “no change.”
            return previousSavedStateWrapper;
          } else {
            // This case is handling moving from being “locked” at a captured
            // initial state (either the very initial one, or one re-captured
            // when deps changed) to an explicitly set state.
            freshDispatch.lockedToInitialState = false;

            // This is setting state to an new wrapper object, so we’re
            // guaranteed to get an update, even if newState matches the last
            // state stored in useState before a deps change. (A state we were
            // shadowing by returning a captured initialState.)
            return {value: newState};
          }
        } else {
          // Normal path. Just go from a previously set state to a newly set
          // state.
          const newState = reducerRef.current(previousSavedStateWrapper.value, a);

          // Small dance to preserve object equality given that we’re using
          // these wrapper objects. Prevents a re-render when the provided
          // reducer returns the value it was passed in.
          if (newState === previousSavedStateWrapper.value) {
            return previousSavedStateWrapper;
          } else {
            return {value: newState};
          }
        }
      });
    },
    {
      expired: false,
      lockedToInitialState: true,
      capturedInitialState: initialState,
    }
  );

  const capturedDispatchRef = React.useRef(freshDispatch);
  React.useLayoutEffect(() => {
    if (depsHaveChangedThisRender) {
      capturedDispatchRef.current = freshDispatch;
    }
  });

  React.useLayoutEffect(() => {
    const latestDispatch = capturedDispatchRef.current;

    // This is a little goofy, but we need this to be symmetric so when it runs
    // as part of React Fast Refresh we expire and immediately unexpire the same
    // dispatch instance.
    latestDispatch.expired = false;

    // This is the important part. On deps changes and unmount we expire the
    // dispatch function so that it stops trying to do setState (important if
    // it’s still captured by an async process) and so it can’t overwrite state
    // that’s associated with new deps.
    //
    // There is an interesting window before this useLayoutEffect cleanup runs
    // where the deps have changed and this hook is returning a fresh dispatch
    // function, but a child may still have captured the previous dispatch
    // function (perhaps in a useEffect cleanup) and could call it. If it were
    // in a child component’s cleanup handler, it would be called before this
    // cleanup handler runs and it gets expired.
    //
    // This ends up not being a problem because, although calling the old
    // dispatch at that point would succeed (rather than no-op), it would call
    // setState at a time when the state value is being shadowed by the captured
    // initialState. So there would be a re-render, but that value that was
    // “set” would never be returned or exposed to a future reducer.
    return () => {
      latestDispatch.expired = true;
    };
  }, deps);

  // If depsHaveChangedThisRender then capturedDispatchRef is out-of-date
  // because the useLayoutEffect that updates it hasn’t run yet.
  const dispatch = depsHaveChangedThisRender ? freshDispatch : capturedDispatchRef.current;

  if (dispatch.lockedToInitialState) {
    // We’re “locked” to the initialState that was captured the last time deps
    // were changed, ignoring the stale values in state.
    return [dispatch.capturedInitialState, dispatch];
  } else {
    return [state.value, dispatch];
  }
}

function useStateWithDepsReducer<S>(prevState: S, action: React.SetStateAction<S>): S {
  if (typeof action === 'function') {
    // as any because TypeScript wants us to consider the case where S is itself
    // a function, and we don’t want to.
    return (action as any)(prevState);
  } else {
    return action;
  }
}

/**
 * Wrapper around useReducerWithDeps to give it useState-like behavior.
 */
export function useStateWithDeps<S>(initialState: S, deps: readonly any[]) {
  return useReducerWithDeps(
    // Getting TypeScript to put a type on a generic function value isn’t
    // straightforward or possible, so we go to "as" for this.
    useStateWithDepsReducer as React.Reducer<S, React.SetStateAction<S>>,
    initialState,
    deps
  );
}

/**
 * Similar to the other use*WithDeps hooks, this synchronously resets a ref’s
 * "current" value to the initial one when the deps array values change.
 *
 * Note that the return value of this hook will always be a consistent object
 * (unlike, for example, the "set" function from useStateWithDeps). It’s only
 * reseting its value that’s done when the deps change.
 */
export function useRefWithDeps<S>(initialValue: S, deps: readonly any[]) {
  const ref = React.useRef<S>(initialValue);

  // Hacking useMemo to take advantage of its deps check and synchronous
  // execution.
  React.useMemo(() => {
    ref.current = initialValue;
  }, deps);

  return ref;
}

export interface UseApiGetMeta<T> {
  refresh: (clear?: boolean) => Promise<void>;
  loading: boolean;

  /**
   * Pass an updater function to modify the local cache of the value. Has no
   * effect on loading or error. Use this to handle optimistically updating
   * results ahead of API responses.
   */
  updateLocal: (
    updater: (current: T | undefined) => T | undefined,
    preserveStatus?: boolean
  ) => void;
}

/**
 * Hook for getting data from an API and persisting it as long as the arguments
 * used to fetch it stay consistent. Re-fetches data when the arguments change
 * or if the "refresh" function (returned in the 2nd, "meta" element) is called.
 *
 * Uses backOffRetryGen under the hood to retry network requests. Uses
 * useReducerWithDeps under the hood so that when args change it will
 * immediately set its status to "unknown" (and then refresh).
 *
 * @param fn An async function that fetches a value you’re interested in. Should
 * take arguments like IDs or keys.
 * @param args An array of arguments to invoke fn with. When the hook starts,
 * and every time these args change (using the same check as useEffect deps),
 * this hook will reset its status to "unknown" and call fn to get new data.
 *
 * @returns An array whose first element is a StatusMaybe of the result, lack of
 * result, or error, and whose second element is a UseApiGetMeta object.
 */
export function useApiGet<T, A extends readonly any[]>(
  fn: (...args: A) => Promise<T>,
  args: A
): [StatusMaybe<Awaited<T>>, UseApiGetMeta<Awaited<T>>] {
  return useApiGetGen(makeFetchWithBackOff(fn), args);
}

/**
 * Adapter that can be used with useApiGenGet or useCachedApiGet to add
 * exponetial backoff retries to a fetch function that just returns a promise.
 */
export function makeFetchWithBackOff<A extends readonly any[], T>(fn: (...args: A) => Promise<T>) {
  return async function* (...args: A) {
    const it = apiUtils.backOffRetryGen(() => fn(...args));

    let item = await it.next();

    while (!item.done) {
      // backOffRetryGen will yield its errors while it’s still retrying. To us,
      // that means we just want to report an undefined intermediate state and
      // we’ll let useApiGenGet call us again.
      yield undefined;

      item = await it.next();
    }

    return item.value;
  };
}

/**
 * Hook for getting data from an API and persisting it as long as the arguments
 * used to fetch it stay consistent. Re-fetches data when the arguments change
 * or if the "refresh" function (returned in the 2nd, "meta" element) is called.
 *
 * Uses useReducerWithDeps under the hood so that when args change it will
 * synchronously set its status to "unknown" (and then refresh).
 *
 * Variation of useApiGet that takes a generator function. The function is
 * expected to yield intermediate results, and then eventually return a complete
 * value. This is useful to render some pages of data while we wait for more to
 * come in.
 *
 * If we’re still in an "unknown" status, the intermediate results are set as
 * the value as they come in. If this hook has already loaded and is being
 * refreshed without clearing it doesn’t expose the intermediate results, only
 * the end result.
 *
 * @param fn An async generator function that fetches a value you’re interested
 * in and returns it. Should take arguments like IDs or keys. May yield
 * intermediate results.
 * @param args An array of arguments to invoke fn with. When the hook starts,
 * and every time these args change (using the same check as useEffect deps),
 * this hook will reset its status to "unknown" and call fn to get new data.
 *
 * @returns An array whose first element is a StatusMaybe of the result, lack of
 * result, or error, and whose second element is a UseApiGetMeta object.
 */
export function useApiGetGen<T, A extends readonly any[]>(
  fn: (...args: A) => FetchGenerator<T>,
  args: A
): [StatusMaybe<T>, UseApiGetMeta<T>] {
  // We always run the latest version of the function.
  const fnRef = React.useRef(fn);
  fnRef.current = fn;

  const [state, setState] = useStateWithDeps<StatusMaybe<T>>({status: 'unknown'}, args);

  // We start in the loading state because we’ll immediately load due to the
  // useEffect calling refresh() below. Prevents a jump from none -> loading
  // when mounting.
  const [loading, setLoading] = useStateWithDeps<boolean>(true, args);

  // We use this ref to de-dupe concurrent requests. Useful to prevent
  // duplicates when the Provider first renders with a child who has
  // refreshOnMount set.
  const refreshPromiseRef = React.useRef<Promise<void> | null>(null);

  const refresh = React.useCallback((clear?: boolean) => {
    if (clear) {
      setState((s) => (s.status === 'unknown' ? s : {status: 'unknown'}));
    }

    if (refreshPromiseRef.current) {
      return refreshPromiseRef.current;
    }

    refreshPromiseRef.current = (async () => {
      setLoading(true);

      const it = fnRef.current(...args);

      try {
        let item = await it.next();

        while (!item.done) {
          if (setState.expired) {
            // No one cares about our results, so we stop reporting them or
            // calling it.next() so that requests stop.
            //
            // This line is exercised by the "cancels retries if the arguments
            // change" test.
            return;
          }

          // We only update state for partial values if we’re currently in
          // the "unknown" status. Otherwise we let the previous "some"
          // status stay until loading is complete.
          setState((s) => (s.status === 'unknown' ? {...s, value: item.value} : s));

          item = await it.next();
        }

        setState({status: 'some', value: item.value});
      } catch (error) {
        setState({status: 'error', error});
      } finally {
        setLoading(false);
      }
    })().finally(() => {
      refreshPromiseRef.current = null;
    });

    return refreshPromiseRef.current;
  }, args);

  const updateLocal = React.useCallback<UseApiGetMeta<T>['updateLocal']>(
    (updater, preserveStatus) =>
      setState((s) => {
        const v = updater(s.value);
        if (v === undefined) {
          return s;
        } else {
          return {status: preserveStatus && s.status !== 'error' ? s.status : 'some', value: v};
        }
      }),
    [setState]
  );

  // Kicks off the loading after the args change
  React.useEffect(() => {
    refreshPromiseRef.current = null;
    refresh();
  }, args);

  const meta = React.useMemo(
    () => ({refresh, loading, updateLocal}),
    [refresh, loading, updateLocal]
  );

  return [state, meta];
}

function makeCacheKey(keys: readonly (string | number)[]) {
  return keys.join('/');
}

/**
 * Type pattern for the generator returned by the fetch function passed to
 * useCachedApiGet. It returns the ReturnValueType, can yield either the
 * ReturnValueType as an intermediate result or undefined, and does not take any
 * parameters for calling “next.”
 */
type FetchGenerator<ReturnValueType> = AsyncGenerator<
  ReturnValueType | undefined,
  ReturnValueType,
  void
>;

/**
 * Values of this type are kept in useCachedApiGet’s cache, along with the most
 * recent ReturnValueType value.
 *
 * @see UseApiGetCacheEntry
 *
 * These status records serve 2 key purposes:
 *
 * - In the generator case, we don’t automatically call “next” on the generator
 *   after it yields a value. We instead expect that a re-render will lead to
 *   another call to the getter function, and then at that point we call “next.”
 *   This behavior means that we only run generators to completion if components
 *   are actively interested in their values. For this to work, we need to save
 *   the previous generator so we have something to call “next” on.
 * - We check the value of the generator or promise before setting a new value
 *   in the cache so that a long-running stale async process cannot overwrite a
 *   more current value.
 */
type FetchGeneratorStatus<ReturnValueType> = {
  // We consistently keep time for cache invalidation by when the generator was
  // kicked off, no matter how long it took the response to come back. This is
  // used so that callers can hide data that’s out-of-date for them.
  startMs: number;
} & (
  | {status: 'error'; generator?: undefined; error: any}
  // We’re awaiting either the Promise returned by the fetch function (in the
  // non-generator case) or the Promise returned by the generator’s next
  // function.
  //
  // We save the generator or promise so we can check and make sure they’re
  // still the most current when the value comes back.
  | {
      status: 'awaiting';
      generator: FetchGenerator<ReturnValueType> | Promise<ReturnValueType>;
      error?: undefined;
    }
  // Status for after the generator’s next promise has resolved. We don’t
  // request the next element until the getter is called again.
  | {status: 'yielded'; generator: FetchGenerator<ReturnValueType>; error?: undefined}
  // The generator has run to completion and gave us a concrete value by
  // returning it. Since it has finished, we don’t save it, as it has nothing
  // left to give.
  | {status: 'done'; generator?: undefined; error?: undefined}
  // Used when CachedApiGetActions’s "invalidate" method has been called. If
  // this key gets requested again, this lets us know that we should trigger a
  // re-fetch, even if the data is there in cache.
  | {status: 'invalid'; generator?: undefined; error?: undefined}
);

/**
 * The value type for the useCachedApiGet cache.
 *
 * Tuple of the latest value and any saved generator (important because looking
 * up a key will cause incomplete generators to be prompted for their next
 * value).
 */
export type UseCachedApiGetCacheEntry<ReturnValueType> = [
  // startMs is the time of the generator that lead to this cache entry.
  StatusMaybe<ReturnValueType> & {startMs: number},
  FetchGeneratorStatus<ReturnValueType>,
];

/**
 * Type of the cache stored in useCachedApiGet.
 */
type UseCachedApiGetCache<ReturnValueType> = I.Map<
  string,
  UseCachedApiGetCacheEntry<ReturnValueType> | undefined
>;

/**
 * Type signature to allow the compound key type as an array, or, if it’s an
 * array of one element, just that element.
 *
 * If the fetcher function only needs one key, it means that the getter function
 * can be a function of just that value. But if the fetcher needs multiple keys,
 * the getter function takes those keys in an array as its first argument.
 *
 * For example:
 *
 * const [getSimple] = useCachedApiGet((_, key: string) => …, []);
 *
 * const [getComplex] = useCachedApiGet((_, key1: string, key2: number) => …,
 * []);
 *
 * const val1 = getSimple("k");
 *
 * const val2 = getComplex(["k", 4]);
 *
 * This is purely for aesthetics so that we didn’t have to write:
 *
 * const val1 = getSimple(["k"]);
 *
 * (TypeScript doesn’t currently let us type getComplex as getComplex("k", 4)
 * because we need to allow an optional last argument for the cache staleness
 * options.)
 */
export type CompoundKeyArg<FetchKeysType extends readonly (string | number)[]> =
  | FetchKeysType
  // The "never" keeps this from being applied if K is not exactly a tuple of 1
  // element.
  | (FetchKeysType extends readonly [infer E] ? E : never);

/**
 * A function that undoes the type hack from CompoundKeyArg to consistently
 * return an array of key values, even if it’s just a single-element array.
 */
function getCompoundKey<FetchKeysType extends readonly (string | number)[]>(
  compoundKeyArg: CompoundKeyArg<FetchKeysType>
): FetchKeysType {
  return Array.isArray(compoundKeyArg) ? compoundKeyArg : ([compoundKeyArg] as any);
}

/**
 * The optional last argument that the useCachedApiGet getter function takes to
 * control how it should return values based on their time in the cache.
 */
export interface CachedApiGetterOptions {
  /** Timestamp for when the data has to be fresh by. */
  afterMs?: number;
  /**
   * If true, it’s ok to serve from cache if the data predates afterMs. If it’s
   * a number, only shows if the stale data is sooner than that timestamp.
   */
  allowStaleMs?: boolean | number;
}

/**
 * The type of the function returned by useCachedApiGet.
 *
 * The first argument is either the single key value or an array of the compound
 * key values.
 *
 * The second argument is options that control what’s returned based on cache
 * staleness.
 *
 * Returns a StatusMaybe for the fetcher’s value. If the fetcher is a generator
 * that yields intermediate values, those will be returned with a status of
 * "unknown."
 *
 * The value of this function is expected to change when the underlying cache
 * changes, so this function is appropriate to use as a dep for other hooks,
 * like useMemo.
 */
export type CachedApiGetter<FetchKeysType extends readonly (string | number)[], ReturnValueType> = (
  keys: CompoundKeyArg<FetchKeysType>,
  opts?: CachedApiGetterOptions
) => StatusMaybe<ReturnValueType>;

/**
 * Type of the second return value from useCachedApiGet. Gives some functions
 * for manipulating the cache.
 */
export interface CachedApiGetActions<
  FetchKeysType extends readonly (string | number)[],
  ReturnValueType,
> {
  /**
   * Marks the given cache key as invalid, meaning the next time it is requested
   * with the getter function it will trigger a re-fetch. If clear is not true,
   * the currently cached value will still be returned while the re-fetch is
   * happening.
   *
   * This function is additionally useful to have a “retry” button in case the
   * fetch results in an error.
   */
  invalidate: (key: CompoundKeyArg<FetchKeysType>, clear?: boolean) => void;

  /**
   * Used to set or modify the current value in the cache for a given key. The
   * updater argument is passed the currently cached value, if it exists.
   */
  updateLocal: (
    key: CompoundKeyArg<FetchKeysType>,
    updater: (v: ReturnValueType | undefined) => ReturnValueType | undefined
  ) => void;
}

/**
 * Used to distinguish between a fetch function returning a Promise vs. an
 * AsyncGenerator.
 *
 * Uses the heuristic that any object with a “then” property is a Promise.
 */
function isPromise(val: any | Promise<any>): val is Promise<any> {
  return val && (val as Promise<any>).then !== undefined;
}

const DEFAULT_MAKE_DEBOUNCED_FN = (fn: () => void): (() => void) => debounce(fn);
const DEFAULT_TIMESTAMP_SHIM = () => +new Date();

/**
 * The pitch here is a hook that creates a “synchronous” getter that can be used
 * in render functions. Whereas useApiGet/useApiGetGen are one hook -> one
 * value, useCachedApiGet returns an accessor that can be used flexibly within a
 * render function to fetch many different keyed values. (Unlike a hook, the
 * accessor can be used behind a conditional, in a loop, passed down to
 * children, &c.)
 *
 * You should call this hook in the place in the hierarchy corresponding to the
 * cache lifetime that you want. You can then pass the getter function down into
 * any components that need to access it.
 *
 * The ergonomics of this are: if you want the data, just call the getter with
 * the relevant key(s). If the data is in cache, it will be synchronously
 * returned in a StatusMaybe with a status of “some.” If it’s not in cache, it
 * will be fetched, added to the cache, and a re-render will be triggered. At
 * that point, if the data is still needed, the component will call the getter
 * again with the same key, and syncronously retrieve the value from cache.
 *
 * Keys for the data can be one or more strings or numbers. They’re combined
 * together in order to generate a cache key.
 *
 * Example:
 *
 * const [getFeatureData] = useCachedApiGet(([fcId], fId: number) => await
 *   api.featureCollections.features(fcId).fetchFeatureData(fid),
 *   [selectedFeatureCollectionId] as const);
 *
 * const dataMaybe = getFeatureData(selectedFeatureId);
 *
 * if (dataMaybe.status === "some") {
 *   ...
 * }
 *
 *
 * The getter function takes optional parameters that allow it to declare a
 * certain level of cache freshness. This hook also returns a second value of
 * action functions that can be used for local state updates or invalidation.
 *
 * This hook makes use of value identity in a way that’s compatible with React
 * deps. By which I mean: the getter function’s value changes along with the
 * cache. So if you use the getter function in a useMemo, just add it to the
 * array of depenedencies. When the cache is updated and the new data might be
 * available, the useMemo will automatically run again because one of its deps
 * changed.
 *
 * Design considerations:
 * - Allow for a component to access multiple keys, possibly in a loop.
 * - Switching to a new key that’s still in cache should be synchronous, without
 *   any flashing of an unknown state.
 * - Declarative means of saying a component wants some level of data freshness.
 *
 * This hook takes a fetch function and an array of dependencies. The
 * dependencies are similar to other hook dependencies, and if their identities
 * change than this hook’s entire cache is invalidated. Dependencies are
 * typically passed as a const literal so that TypeScript treats them as a tuple
 * rather than an array.
 *
 * The fetch function is passed the dependency array as its first argument and
 * the keys to load as its second, third, &c. arguments.
 *
 * The fetch function must return either a Promise for the ReturnValueType data
 * or be an async generator that returns the ReturnValueType data. If the fetch
 * function is a generator, any non-undefined intermediate results it yields
 * will be stored in the cache (provided no previous concrete value exists for
 * the key(s)) and returned as “unknown” results from the getter function.
 *
 * This hook is designed so that continued re-renders are required in order to
 * run generators to their completion.
 *
 * For example, if the fetch function is loading paginated data and yields after
 * each page, this hook will trigger a re-render after each page. When that
 * re-render happens, any component that is still interested in the value will
 * naturally call the getter again, and that call will lead to loading the next
 * value from the generator.
 *
 * If no component calls the getter, then the generator will not be called,
 * which stops the pagination. This behavior keeps us from running pagination to
 * completion even if no component is still interested in the result.
 *
 * This component is built with the general assumption that occassional
 * re-renders should be cheap. For example, re-rendering as a signal to keep
 * generators going is totally fine.
 *
 * There is however one aspect to using the same cache for both values and
 * generator state which is that getting a new key will always lead to an
 * immediate re-render as the promise or generator is stored in the parent
 * component’s state.
 */
export function useCachedApiGet<
  ReturnValueType,
  DependenciesType extends readonly any[],
  FetchKeysType extends readonly (string | number)[],
>(
  /**
   * Fetch function to asynchronously retrieve data. Its first argument is the
   * array of dependencies that were passed to this hook. The other arguments
   * are the keys that were passed to the getter function.
   *
   * Changing the identity of this function does not invalidate the cache or
   * cause re-fetching, though the most recently-rendered version of the
   * function is used for new fetches.
   */
  fetchFn: (
    deps: DependenciesType,
    ...keys: FetchKeysType
  ) => FetchGenerator<ReturnValueType> | Promise<ReturnValueType>,

  /**
   * Array of “dependencies” for fetching. If these change (based on React hook
   * dependency changing semantics) the entire cache is invalidated and child
   * components will re-render.
   *
   * Use this for values that “scope” the getter. For example, if the getter is
   * for loading individual features’ data, the project ID and feature
   * collection ID might be put here as deps.
   */
  deps: DependenciesType,

  // These last args are only exposed for tests
  initialCache: UseCachedApiGetCache<ReturnValueType> = I.Map(),
  // We rely on debounce both to debounce the requests that come when getter is
  // called, and to tick over with setTimeout, past the current render.
  makeDebouncedFn = DEFAULT_MAKE_DEBOUNCED_FN,
  getTimestampShim = DEFAULT_TIMESTAMP_SHIM
): [
  CachedApiGetter<FetchKeysType, ReturnValueType>,
  CachedApiGetActions<FetchKeysType, ReturnValueType>,
] {
  // We always run the latest version of the function when we need to fetch, but
  // if the function’s identity changes it doesn’t cause us to re-fetch or
  // invalidate the cache.
  const fetchFnRef = React.useRef(fetchFn);
  React.useLayoutEffect(() => {
    fetchFnRef.current = fetchFn;
  });

  // Our cache persists as long as the deps stay consistent.
  //
  // The cache is keyed by the serialized fetch keys (see makeCacheKey) and
  // contains both any cached values as well as intermediate generators.
  const [cache, setCache] = useStateWithDeps<UseCachedApiGetCache<ReturnValueType>>(
    initialCache,
    deps
  );

  // Here’s how the rest of the implementation goes. This hook returns a
  // “getter” function that will be called from React render functions. If that
  // function is called and there is a cache miss, we enqueue a “request” record
  // in requestsRef.
  //
  // The requests are then processed asyncronously after the render has
  // completed, which triggers calls to the fetch function to load the necessary
  // data.
  const requestsRef = React.useRef<{compoundKey: FetchKeysType; afterMs: number}[]>([]);

  // Runs after the render to process any requests that have been added to the
  // queue.
  //
  // “Processing” here means to either kick off a fresh fetch by calling the
  // fetch function, or, if a previous fetch returned a generator that hasn’t
  // been run to completion, to await its next value.
  //
  // While you might expect this to be called from a useEffect, we have to call
  // it from setTimeout instead. This is because a useEffect only runs if the
  // component that contains it re-renders. But because useCachedApiGet’s
  // getters can be passed into child components, they are free to render and
  // re-render and call the getter without this parent component ever
  // re-rendering.
  const processRequests = React.useCallback(
    () => {
      // Can happen if several renders trigger before we’re able to process
      // once. Short-circuits to avoid any cost of unstable_batchedUpdates.
      if (requestsRef.current.length === 0) {
        return;
      }

      // We store a local copy so that we can iterate over it safely.
      const requests = requestsRef.current;
      requestsRef.current = [];

      // Since we’re in a setTimeout, we want to batch what might be several
      // setState calls so that a full rerender only happens at the end.
      ReactDOM.unstable_batchedUpdates(() => {
        // For simplicity and precision, we use a consistent startMs for any
        // generators created in this pass.
        const startMs = getTimestampShim();

        requests.forEach(({compoundKey, afterMs}) => {
          const cacheKey = makeCacheKey(compoundKey);

          // setCache in its reducer form is important here because the list of
          // requests could have duplicate keys. Calling the reducer means that
          // we have access to the latest cache, as it was modified by any
          // previous iterations of the loop.
          setCache((latestCache: UseCachedApiGetCache<ReturnValueType>) => {
            const [latestValue, latestGeneratorStatus] = latestCache.get(cacheKey) || [];

            // 3 reasons to call our fetch function: it hasn’t been called
            // before, "invalidate" was called out-of-band to invalidate the
            // cache entry, or the getter was called with an afterMs value that
            // is since the last time this data was fetched.
            const needsFreshFetch =
              latestGeneratorStatus === undefined ||
              latestGeneratorStatus.status === 'invalid' ||
              latestGeneratorStatus.startMs < afterMs;

            // This will either be the result of a new fetch, or an existing
            // generator that had previously resolved with an intermediate
            // result but has not yet run to completion.
            let generatorToAwait: FetchGenerator<ReturnValueType>;

            if (needsFreshFetch) {
              // Calling the fetch function will return either an async
              // generator or a Promise.
              const fetchResult = fetchFnRef.current(deps, ...compoundKey);

              if (isPromise(fetchResult)) {
                fetchResult.then(...makeFetchPromiseHandlers(fetchResult, cacheKey, setCache));

                // Need to add a record to the cache so that the next time this
                // key is requested we know that a request is already
                // outstanding.
                //
                // We return because the rest of this function is about handling
                // generators.
                return latestCache.set(cacheKey, [
                  latestValue && latestValue.status !== 'error'
                    ? latestValue
                    : {status: 'unknown', startMs: 0},
                  {status: 'awaiting', generator: fetchResult, startMs},
                ]);
              } else {
                // If fetchResult isn’t a Promise then it’s a generator. We’ll
                // need to call next on it to get it to start executing.
                generatorToAwait = fetchResult;
              }
            } else if (latestGeneratorStatus?.status === 'yielded') {
              // This is the case where the generator previously yielded an
              // intermediate value, but, in a re-render, the getter was called
              // again with its key, reaffirming that the value is still needed.
              generatorToAwait = latestGeneratorStatus.generator;
            } else {
              // If we get here then the iterator is already outstanding and
              // being awaited, or it’s done and recent enough that we don’t
              // need to fetch fresh data. Short-circuit. This won’t cause a
              // rerender since the state is identical.
              return latestCache;
            }

            // If we get this far then we have an iterator that needs its crank
            // turned to generate the next value.
            generatorToAwait
              .next()
              .then(...makeFetchNextPromiseHandlers(generatorToAwait, cacheKey, setCache));

            // If this key was not in the cache before, add it in as status:
            // unknown. If it was an error before we can clear that out since
            // we’re loading something new.
            //
            // Otherwise, we still need to do this set so that we overwrite the
            // iterator’s status with "awaiting." This keeps any other requests
            // from calling next() on the iterator until our above awaiting
            // resolves.
            return latestCache.set(cacheKey, [
              latestValue && latestValue.status !== 'error'
                ? latestValue
                : {status: 'unknown', startMs: 0},
              {status: 'awaiting', generator: generatorToAwait, startMs},
            ]);
          });
        });
      });
    },
    // Need to disable the linter since we’re spreading the deps array.
    //

    [getTimestampShim, setCache, ...deps]
  );

  // We debounce processRequests because it may be called multiple times if a
  // render requests multiple values from the getter. Debouncing also naturally
  // uses setTimeout, meaning that processRequests will actually execute after
  // the entire render has completed (which is good).
  const scheduleProcessRequests = React.useMemo(
    () => makeDebouncedFn(processRequests),
    [makeDebouncedFn, processRequests]
  );

  // The getter function. Purposefully changes when the cache does, so that
  // components that use it will re-render and be able to synchronously get new
  // cached data.
  const getter = React.useCallback<CachedApiGetter<FetchKeysType, ReturnValueType>>(
    (compoundKeyArg, opts = {}): StatusMaybe<ReturnValueType> => {
      const {afterMs = 0, allowStaleMs = false} = opts;

      const compoundKey = getCompoundKey(compoundKeyArg);

      const cacheKey = makeCacheKey(compoundKey);
      const [cacheValue] = cache.get(cacheKey) || [];

      // We don’t dedupe based on the key because afterMs might be different for
      // different calls. We add everything and let processRequests handle
      // de-duping.
      requestsRef.current.push({compoundKey, afterMs});
      scheduleProcessRequests();

      if (
        !cacheValue ||
        (allowStaleMs === false && cacheValue.startMs < afterMs) ||
        (typeof allowStaleMs === 'number' && cacheValue.startMs < allowStaleMs)
      ) {
        // Here we either don’t have a value yet or the option for allowStaleMs
        // indicates that the value in the cache is too old to show.
        return {status: 'unknown'};
      } else {
        return cacheValue;
      }
    },
    [cache, scheduleProcessRequests]
  );

  const invalidate = React.useCallback<
    CachedApiGetActions<FetchKeysType, ReturnValueType>['invalidate']
  >(
    (compoundKeyArg, clear = false) => {
      const compoundKey = getCompoundKey(compoundKeyArg);
      const cacheKey = makeCacheKey(compoundKey);

      setCache((latestCache) => {
        if (clear) {
          return latestCache.remove(cacheKey);
        } else {
          return latestCache.update(
            cacheKey,
            (cacheEntry) =>
              cacheEntry && [cacheEntry[0], {status: 'invalid', startMs: cacheEntry[1].startMs}]
          );
        }
      });
    },
    [setCache]
  );

  const updateLocal = React.useCallback<
    CachedApiGetActions<FetchKeysType, ReturnValueType>['updateLocal']
  >(
    (compoundKeyArg, updater) => {
      const compoundKey = getCompoundKey(compoundKeyArg);
      const cacheKey = makeCacheKey(compoundKey);

      setCache((latestCache) => {
        const startMs = getTimestampShim();

        return latestCache.update(cacheKey, (cacheEntry) => {
          const updatedValue = updater(cacheEntry?.[0]?.value);

          if (updatedValue === undefined) {
            return [
              {status: 'unknown', startMs},
              {status: 'invalid', startMs},
            ];
          } else {
            return [
              {value: updatedValue, status: 'some', startMs},
              {status: 'done', startMs},
            ];
          }
        });
      });
    },
    [setCache, getTimestampShim]
  );

  const actions = React.useMemo<CachedApiGetActions<FetchKeysType, ReturnValueType>>(
    () => ({invalidate, updateLocal}),
    [invalidate, updateLocal]
  );

  return [getter, actions];
}

/**
 * Makes the onFulfilled and onRejected handlers for a Promise returned by a
 * useCachedApiGet fetch function.
 *
 * These functions store the appropriate value and status in the cache.
 */
function makeFetchPromiseHandlers<ReturnValueType>(
  promise: Promise<ReturnValueType>,
  cacheKey: string,
  setCache: React.Dispatch<React.SetStateAction<UseCachedApiGetCache<ReturnValueType>>>
): Parameters<Promise<ReturnValueType>['then']> {
  const onFulfilled = (value: ReturnValueType) =>
    setCache((latestCache) =>
      latestCache.update(cacheKey, (entry) => {
        const [, generatorStatus] = entry || [];

        // We do a safety check that the cache is still expecting a value from
        // us and not a different promise.
        if (generatorStatus?.generator === promise) {
          return [
            {status: 'some', value, startMs: generatorStatus.startMs},
            {status: 'done', startMs: generatorStatus.startMs},
          ];
        } else {
          return entry;
        }
      })
    );

  const onRejected = (error: any) => {
    setCache((latestCache) =>
      latestCache.update(cacheKey, (entry) => {
        const [, generatorStatus] = entry || [];

        // We do a safety check that the cache is still expecting a value from
        // us and not a different promise.
        if (generatorStatus?.generator === promise) {
          return [
            {status: 'error', error, startMs: generatorStatus.startMs},
            {status: 'error', error, startMs: generatorStatus.startMs},
          ];
        } else {
          return entry;
        }
      })
    );
  };

  return [onFulfilled, onRejected];
}

/**
 * Makes the onFulfilled and onRejected handlers to pass to the Promise returned
 * by calling next() on a fetch function’s generator.
 *
 * If the generator has finished, this stores its return value in the cache
 * along with an updated generator status that indicates that it has run to
 * completion.
 *
 * If the generator has not finished, it stores any intermediate value and then
 * sets a status of “yielded.” Changing the cache will cause a re-render, which
 * will — if the value is still relevant to the UI — cause the getter to be
 * called again and lead to another call to the iterator’s next function.
 */
function makeFetchNextPromiseHandlers<ReturnValueType>(
  generator: FetchGenerator<ReturnValueType>,
  cacheKey: string,
  setCache: React.Dispatch<React.SetStateAction<UseCachedApiGetCache<ReturnValueType>>>
): Parameters<Promise<IteratorResult<ReturnValueType | undefined, ReturnValueType>>['then']> {
  const onFulfilled = ({
    value: yieldedValue,
    done,
  }: IteratorResult<ReturnValueType | undefined, ReturnValueType>) => {
    // We’ve turned the async iterator crank and have a new result.
    // Now we need to store it in the cache.
    setCache((latestCache) => {
      const [currentValue, currentGeneratorStatus] = latestCache.get(cacheKey) ?? [];

      // Fail out if our generator is not of interest anymore.
      if (currentGeneratorStatus?.generator !== generator) {
        return latestCache;
      }

      let nextValue: StatusMaybe<ReturnValueType> & {startMs: number};
      let nextGeneratorStatus: FetchGeneratorStatus<ReturnValueType>;

      if (yieldedValue === undefined) {
        // Value can be undefined if the generator function needs to
        // return but doesn’t have an intermediate value. This can
        // happen during exponential fallback retries. We need to put
        // the generator back in the cache, but we keep whatever value
        // is already in there.
        //
        // There _should_ be a value because we always put one in when
        // creating a generator, but we’re just a bit cautious so we
        // handle if it’s missing.
        nextValue = currentValue ?? {
          status: 'unknown',
          startMs: currentGeneratorStatus.startMs,
        };

        nextGeneratorStatus = {
          status: 'yielded',
          generator: generator,
          startMs: currentGeneratorStatus.startMs,
        };
      } else if (done) {
        nextValue = {
          status: 'some',
          value: yieldedValue,
          startMs: currentGeneratorStatus.startMs,
        };

        // Since we have definite value, we don’t need the iterator anymore,
        // since it will just return the same value over and over again.
        nextGeneratorStatus = {
          status: 'done',
          startMs: currentGeneratorStatus.startMs,
        };
      } else {
        // The iterator yielded an intermediate value. Putting the iterator back
        // into the cache allows future calls to the getter to call next and
        // drive it towards completion.
        //
        // We won’t overwrite an existing "some" value, though, which means if
        // we’re doing a refetch for freshness that the previous full value will
        // be returned to callers while we wait for the new full value to be
        // generated.
        //
        // (This does have the side-effect that, if there is an old value in the
        // cache, calling the getter with a "allowStaleMs" value of false means
        // that you won’t see intermediate results. You’ll just get no value
        // until the generator completes.)
        if (currentValue?.status === 'some') {
          nextValue = currentValue;
        } else {
          nextValue = {
            status: 'unknown',
            value: yieldedValue,
            startMs: currentGeneratorStatus.startMs,
          };
        }

        nextGeneratorStatus = {
          status: 'yielded',
          generator: generator,
          startMs: currentGeneratorStatus.startMs,
        };
      }

      // Because this new cache value is a guaranteed fresh array, the value of
      // the cache has changed, which will lead to re-renders and re-calling of
      // the getter function, which will further drive the generator.
      return latestCache.set(cacheKey, [nextValue, nextGeneratorStatus]);
    });
  };

  const onRejected = (error: any) => {
    setCache((latestCache) =>
      latestCache.update(cacheKey, (entry) => {
        const [, generatorStatus] = entry || [];

        // We do a safety check that the cache is still expecting a value from
        // us and not a different generator.
        if (generatorStatus?.generator === generator) {
          return [
            {status: 'error', error, startMs: generatorStatus.startMs},
            {status: 'error', error, startMs: generatorStatus.startMs},
          ];
        } else {
          return entry;
        }
      })
    );
  };

  return [onFulfilled, onRejected];
}

/**
 * Hook to keep track of a timestamp. Set when the component mounts. Can be
 * refreshed by calling the 2nd arg (refresh).
 *
 * Useful to establish an "afterMs" value for calling useCachedApiGet’s "getter"
 * function to ensure data that is at least as fresh as when the component first
 * mounted.
 *
 * TODO(fiona): This would be a good way to put in "withinMs" and "updateMs"
 * amounts to allow not-too-stale data and an automatic refresh interval.
 */
export function useTimestampMs() {
  const [ms, setMs] = React.useState(+new Date());

  const refresh = React.useCallback(() => {
    setMs(+new Date());
  }, []);

  return [ms, refresh] as const;
}

/**
 * Wrapper around useEffect that prints out which dependencies changed when it
 * fires.
 */
export function useEffectDebug(
  name: string,
  effect: React.EffectCallback,
  deps?: React.DependencyList
) {
  const lastDepsRef = React.useRef<React.DependencyList | undefined>(undefined);
  const logPrefix = name && `[${name}]`;

  React.useDebugValue(name);

  React.useEffect(() => {
    const lastDeps = lastDepsRef.current;

    if (lastDeps === undefined) {
      console.debug(logPrefix, 'initial run');
    } else {
      console.debug(
        logPrefix,
        'changed deps',
        (deps || []).map((dep, i) => (Object.is(dep, lastDeps[i]) ? '<<unchanged>>' : dep))
      );
    }

    lastDepsRef.current = deps;

    return effect();
  }, deps);
}

/**
 * Wrapper around useLayoutEffect that prints out which dependencies changed when it
 * fires.
 */
export function useLayoutEffectDebug(
  name: string,
  effect: React.EffectCallback,
  deps?: React.DependencyList
) {
  const lastDepsRef = React.useRef<React.DependencyList | undefined>(undefined);
  const logPrefix = name && `[${name}]`;

  React.useDebugValue(name);

  React.useLayoutEffect(() => {
    const lastDeps = lastDepsRef.current;

    if (lastDeps === undefined) {
      console.debug(logPrefix, 'initial run');
    } else {
      console.debug(
        logPrefix,
        'changed deps',
        (deps || []).map((dep, i) => (Object.is(dep, lastDeps[i]) ? '<<unchanged>>' : dep))
      );
    }

    lastDepsRef.current = deps;

    return effect();
  }, deps);
}

/**
 * Wrapper around useMemo that prints out which dependencies changed when it
 * fires.
 */
export function useMemoDebug<T>(name: string, factory: () => T, deps?: React.DependencyList): T {
  const lastDepsRef = React.useRef<React.DependencyList | undefined>(undefined);
  const logPrefix = `[${name}]`;

  React.useDebugValue(name);

  return React.useMemo(() => {
    const lastDeps = lastDepsRef.current;

    if (lastDeps === undefined) {
      console.debug(logPrefix, 'initial run');
    } else {
      console.debug(
        logPrefix,
        'changed deps',
        (deps || []).map((dep, i) => (Object.is(dep, lastDeps[i]) ? '<<unchanged>>' : dep))
      );
    }

    lastDepsRef.current = deps;

    return factory();
  }, deps || []);
}

/**
 * Wrapper around useCallback that prints out which dependencies changed when it
 * is called.
 */
export function useCallbackDebug<T>(
  name: string,
  callback: () => T,
  deps: React.DependencyList
): () => T {
  const lastDepsRef = React.useRef<React.DependencyList | undefined>(undefined);
  const logPrefix = `[${name}]`;

  React.useDebugValue(name);

  React.useLayoutEffect(() => {
    const lastDeps = lastDepsRef.current;

    if (lastDeps === undefined) {
      console.debug(logPrefix, 'initial run');
    } else {
      console.debug(
        logPrefix,
        'changed deps',
        (deps || []).map((dep, i) => (Object.is(dep, lastDeps[i]) ? '<<unchanged>>' : dep))
      );
    }

    lastDepsRef.current = deps;
  }, deps);

  return React.useCallback(callback, deps);
}

/**
 * Wrapper around useStateWithDeps to match a common case where we want to
 * calculate a value from a promise, and the deps for resetting the state are
 * the same as recalculating the value.
 *
 * Takes a function and deps array, just as React.useMemo does. If the deps
 * change, the returned value immediately gets set to null, and the function is
 * re-run to get a new value.
 *
 * The function called with an argument that can be called to check and see if
 * the state has “expired,” meaning that any return value will be ignored. The
 * function can use this to cancel a multi-step process.
 *
 * If the function rejects, the rejection value is returned in the 2nd element
 * as the "error" property. That object also has a "retry" property, which is a
 * function that attempts to get the value again.
 *
 * <strong>WARNING:</strong> Import this function directly, rather than using
 * the hookUtils namespace, or else ESLint’s react-hooks/exhaustive-deps won’t
 * check it. (Current as of eslint-plugin-react-hooks@2.2.0)
 */
export function useMemoAsync<T>(
  fn: (checkExpired: () => boolean) => Promise<T>,
  deps: any[]
): [T | null, {error: any; retry: () => void} | null] {
  const [value, setValue] = useStateWithDeps<T | null>(null, deps);
  const [error, setError] = useStateWithDeps<any | null>(null, deps);

  // Ensures that "load" always runs against the most recently-rendered version
  // of fn.
  const fnRef = React.useRef(fn);
  fnRef.current = fn;

  const load = React.useCallback(() => {
    const checkExpired = () => setValue.expired;

    fnRef.current(checkExpired).then(setValue).catch(setError);
  }, [setValue, setError]);

  React.useEffect(() => {
    load();
  }, [load]);

  const retry = React.useCallback(() => {
    setError(null);
    load();
  }, [load, setError]);

  return [value, error && {error, retry}];
}

/**
 * Preserves an initial value as long as subsequent values are deep equal to it.
 * Can be used to smooth over props that sometimes get new instances that equal
 * the old value, and then are used as dependencies to hooks (which only check
 * reference equality).
 */
export function useContinuity<T>(val: T, checkFn: (a: T, b: T) => boolean = isEqual): T {
  const valRef = React.useRef(val);

  if (!checkFn(valRef.current, val)) {
    valRef.current = val;
  }

  return valRef.current;
}

/**
 * Stores keys and values in the browser history state, where it’s associated
 * with the current history entry.
 *
 * Returns an array of two functions:
 *
 * - getHistoryState: given a key, returns the current value of it from the
 *   state, or the default value provided to useHistoryState.
 * - setHistoryState: given a key and value, sets that value in the history
 *   state. Other history state values are preserved.
 *
 * Takes a "prefix" used to prevent collisions across different usages of
 * history state, and a default state to return when the history state doesn’t
 * have an entry.
 *
 * Note: changes to defaultState are not noticed.
 */
export function useHistoryState<T>(
  history: History,
  prefix: string,
  defaultState: T
): [<K extends keyof T>(k: K) => T[K], <K extends keyof T>(k: K, v: T[K]) => void] {
  // Saves this in a ref since it’s likely to be provided as a object literal
  // and we don’t want to keep recreating the getter/setters all the time.
  const defaultStateRef = React.useRef(defaultState);

  const getter = React.useCallback(
    <K extends keyof T>(k: K) => {
      const state: T = {...defaultStateRef.current, ...(history.location.state || {})[prefix]};
      return state[k];
    },
    [history, prefix]
  );

  const setter = React.useCallback(
    // It's possible for a user to trigger this function and call history.replace more than
    // 100 times in 30 seconds which causes an error (not visible to the user). To prevent
    // this we need to add a third of a second throttle here.
    throttle(<K extends keyof T>(k: K, v: T[K]) => {
      history.replace({
        ...history.location,
        state: {
          ...(history.location.state as object),
          [prefix]: {...(history.location.state || {})[prefix], [k]: v},
        },
      });
    }, 330),
    [history, prefix]
  );

  return [getter, setter];
}

/**
 * Hook utility that returns width and height dimensions when the window is resized. This allows us
 * to redraw SVG elements, which may require numeric dimensions to draw in a responsive manner.
 */
export function useClientSize() {
  // Save the resize event handler in order to clean it up when the referenced element is removed.
  const resizeEventHandlerRef = React.useRef<(() => void) | null>(null);

  const [dimensions, setDimensions] = React.useState<[number | null, number | null]>([null, null]);

  const ref = React.useCallback((el: HTMLElement | null) => {
    if (el) {
      // If the referenced element exists, store the element's width and height in state, add a
      // debounced event listener that updates size when the window is resized, and save it in a
      // local reference.
      const updateSize = () => setDimensions([el.clientWidth, el.clientHeight]);

      updateSize();

      const debouncedUpdateSize = debounce(updateSize, 300);

      window.addEventListener('resize', debouncedUpdateSize);

      resizeEventHandlerRef.current = debouncedUpdateSize;
    } else if (resizeEventHandlerRef.current) {
      // If we don't have a truthy `el` value (e.g., the referenced element is no longer rendered),
      // clean up the event handler as necessary.
      window.removeEventListener('resize', resizeEventHandlerRef.current);
    }
  }, []);

  return [ref, ...dimensions] as const;
}

/**
 * Hook utility for detecting when target key(s) are pressed. Useful for coupling
 * key presses with other event types, such as clicks. Uses the event code,
 * which is agnostic to modifier keys like Shift.
 *
 * While the targetCode is typed as a genric string, it’s an enumerated type in
 * practice. You’ll need to ensure you are passing a valid argument, which can
 * be checked on websites like https://www.toptal.com/developers/keycode.
 */
export function useKeyPress(targetCodes: string | string[]) {
  const [keyPressed, setKeyPressed] = React.useState(false);

  targetCodes = Array.isArray(targetCodes) ? targetCodes : [targetCodes];

  const downHandler = (event: KeyboardEvent) => {
    if (targetCodes.includes(event.code)) {
      setKeyPressed(true);
    }
  };

  const upHandler = (event: KeyboardEvent) => {
    if (targetCodes.includes(event.code)) {
      setKeyPressed(false);
    }
  };

  React.useLayoutEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
}

/**
 * Captures a command or control click. Meta is the command key on macs
 * and the control key is used for other operating systems. We use this to
 * mimic the behavior for standard links in the browser where command or
 * control click will open a link in another tab.
 */
export function useCmdCtrlPress() {
  return useKeyPress(['MetaLeft', 'MetaRight', 'ControlLeft', 'ControlRight']);
}

/**
 * Hook utility that returns true at the mount time, then always false.
 * See https://usehooks-ts.com/react-hook/use-is-first-render.
 */
export function useIsFirstRender() {
  const isFirst = React.useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }

  return isFirst.current;
}

/**
 * Modified useEffect that skips the first render.
 * https://usehooks-ts.com/react-hook/use-update-effect.
 */
export function useUpdateEffect(effect: React.EffectCallback, deps: React.DependencyList) {
  const isFirst = useIsFirstRender();

  React.useEffect(() => {
    if (!isFirst) {
      return effect();
    }
  }, deps);
}

/**
 * Hook utility to track the previous value of something
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Hook utility to track clicks outside of a given element.
 *
 * @param callback - Function to call when a click is detected outside of the element
 * @example
 * const ref = useOutsideClick(() => {
 *  console.log('clicked outside');
 * });
 *
 * // Use the ref in a component
 * return <div ref={ref}>Click outside of me</div>
 */
export const useOutsideClick = (callback: (event?: MouseEvent) => void) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
