import {Tag} from '@blueprintjs/core';
import React from 'react';

import cs from './styles.styl';

interface Props {
  children: JSX.Element;
  hide?: boolean;
  top: string;
}

export default class MapHelptext extends React.Component<Props> {
  render() {
    const {children, hide, top = '2rem'} = this.props;

    // this component has some absolute positioning to allow it to appear on top of
    // its sibling in its div. therefore we need to always render it and control
    // show/hide based on CSS visibility property.
    return (
      <div
        style={{
          visibility: hide ? 'hidden' : 'visible',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={cs.drawHelpTextContainer} style={{top: top}}>
          <Tag round intent="primary">
            {children}
          </Tag>
        </div>
      </div>
    );
  }
}
