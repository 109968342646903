import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import {ReportCard} from 'app/components/ReportCard/ReportCard';
import {api} from 'app/modules/Remote';
import {ApiReport} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {DEFAULT_NOTES_FILTER_STATE} from 'app/pages/MonitorProjectView/view';
import {NotesState} from 'app/stores/NotesStore';
import {recordEvent} from 'app/tools/Analytics';
import * as noteUtils from 'app/utils/noteUtils';

import {Filters} from './Filters';
import * as cs from './styles.styl';

export interface ReportExportState {
  newTab: boolean;
  allParts: boolean;
  savedReportId?: number;
  reportTitle?: string;
  refetchReports?: () => Promise<void>;
}

const Reports: React.FunctionComponent<
  React.PropsWithChildren<{
    selectedFeatureId: number;
    selectedFeatureName: string;
    selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
    setReportExportFilterState: (state: noteUtils.NotesFilterState) => void;
    setReportExportModalState: (state: ReportExportState | null) => void;
    openReportExportModal: (exportModalParams: ReportExportState) => void;
    isMultiLocationProperty: boolean;
    notesState: NotesState;
    notesFilterState: noteUtils.NotesFilterState;
    reportExportState: ReportExportState | null;
  }>
> = ({
  selectedFeatureId,
  selectedFeatureName,
  selectedFeatureCollection,
  setReportExportModalState,
  setReportExportFilterState,
  openReportExportModal,
  isMultiLocationProperty,
  notesState,
  notesFilterState: filterStateFromNotes,
  reportExportState,
}) => {
  const [savedReports, setSavedReports] = React.useState<ApiReport<string>[]>([]);
  const [reportsLoading, setReportsLoading] = React.useState(false);
  const [reportsError, setReportsError] = React.useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
  const [reportAllLocations, setReportAllLocations] = React.useState(false);

  // Clarification: if we opened the reports pane from the notes pane, we initialize reports
  // filter state with the filter state they departed the notes pane with.
  const [notesFilterState, setNotesFilterState] = React.useState<noteUtils.NotesFilterState>(
    filterStateFromNotes || DEFAULT_NOTES_FILTER_STATE
  );

  const reportsApi = React.useMemo(() => {
    return api.reports(selectedFeatureId);
  }, [selectedFeatureId]);

  const getFeatureReports = React.useCallback(async () => {
    return await reportsApi.getList();
  }, [reportsApi]);

  const fetchReports = React.useCallback(async () => {
    setReportsLoading(true);
    try {
      const savedReports: ApiReport<string>[] = (await getFeatureReports()).get('data').toJS();
      // Naive comparator; recent first
      savedReports.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));
      setSavedReports(savedReports);
    } catch {
      setReportsError(true);
    }
    setReportsLoading(false);
  }, [getFeatureReports]);

  React.useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  /**
   * If reportExportState was set before this component rendered, open a report window. This
   * also adds supplementary information here like reportTitle, and refetchReports. It's possible
   * to override these values based on what reportExportState is passed.
   */
  React.useEffect(() => {
    if (reportExportState) {
      // Augment pending state with extra data.
      const params = {
        reportTitle: `${selectedFeatureName} Report`,
        refetchReports: () => fetchReports(),
        ...reportExportState,
      };
      setReportExportModalState(null);
      openReportExportModal(params);
    }
  }, []);

  const visibleNotes = React.useMemo(() => {
    return notesState.notes.filter((n) =>
      noteUtils.filterNotesCallback(n, 'user', notesFilterState)
    );
  }, [notesState.notes, notesFilterState]);

  if (reportsError) {
    return (
      <B.NonIdealState
        iconMuted={false}
        className={cs.bodyEmpty}
        description={' There was an error loading saved reports. Please refresh and try again.'}
        icon="error"
      />
    );
  }

  if (reportsLoading) {
    return (
      <div className={cs.loadingContainer}>
        <B.Spinner />
      </div>
    );
  }

  return (
    <div className={cs.reportsContainer}>
      <div className={cs.toolbar}>
        <B.Popover
          className={cs.filterPopover}
          isOpen={isFiltersOpen}
          interactionKind={B.PopoverInteractionKind.CLICK}
          onClose={() => setIsFiltersOpen(false)}
          position="bottom-left"
          content={
            <Filters
              className={cs.reportsFilter}
              notesFilterState={notesFilterState}
              setNotesFilterState={setNotesFilterState}
              onClose={() => setIsFiltersOpen(false)}
              notes={notesState.notes}
              featureCollection={selectedFeatureCollection}
              description={
                <div style={{padding: '1rem 1.5rem'}}>
                  {!!isMultiLocationProperty && (
                    <div style={{paddingBottom: '1rem'}}>
                      <div>
                        This is part of a{' '}
                        <a
                          href="https://support.upstream.tech/article/31-multi-location-properties"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          multi-location property
                        </a>
                        . You can either create a report for this location only or all locations
                      </div>
                      <B.RadioGroup
                        onChange={(e) => {
                          setReportAllLocations(!!Number(e.currentTarget.value));
                        }}
                        selectedValue={+reportAllLocations}
                        className={cs.radioOptions}
                      >
                        <B.Radio value={0} label={'Only this location'} />
                        <B.Radio value={1} label={'All locations'} />
                      </B.RadioGroup>
                      <B.Divider />
                    </div>
                  )}
                  <div>Adjust which notes to include in your report.</div>
                </div>
              }
              footer={
                <div className={cs.reportsFilterFooter}>
                  <B.Button
                    intent={'primary'}
                    icon="plus"
                    onClick={(ev: React.MouseEvent) => {
                      const exportModalState: ReportExportState = {
                        newTab: ev.ctrlKey || ev.metaKey,
                        allParts: reportAllLocations,
                        savedReportId: undefined,
                        reportTitle: `${selectedFeatureName} Report`,
                        refetchReports: () => fetchReports(),
                      };
                      setReportExportFilterState(notesFilterState);
                      openReportExportModal(exportModalState);
                      recordEvent('Created report');
                    }}
                  >
                    {`Create a report ${
                      reportAllLocations
                        ? ''
                        : `with ${visibleNotes.length}
                        ${visibleNotes.length === 1 ? 'note' : 'notes'}`
                    }`}
                  </B.Button>
                </div>
              }
            />
          }
        >
          <B.Button outlined intent={'primary'} icon="plus" onClick={() => setIsFiltersOpen(true)}>
            Create a report
          </B.Button>
        </B.Popover>
      </div>
      <div className={cs.body}>
        {!savedReports.length ? (
          <B.NonIdealState iconMuted={false} className={cs.bodyEmpty} icon="document">
            <>
              <p>
                Compile notes into a report for record-keeping or stakeholder engagement and view
                saved reports here.
              </p>
              <p>
                <strong>Create and save your first report to get started!</strong>
              </p>
            </>
          </B.NonIdealState>
        ) : (
          savedReports.map((report, i) => (
            <ReportCard
              report={report}
              key={i}
              openReportExportModalWithState={(ev: React.MouseEvent, report: ApiReport<string>) => {
                setNotesFilterState(notesFilterState);
                openReportExportModal({
                  newTab: ev.ctrlKey || ev.metaKey,
                  allParts: report.isMultilocation,
                  savedReportId: report.id,
                  reportTitle: report.title,
                  refetchReports: () => fetchReports(),
                });
              }}
              featureId={selectedFeatureId}
              fetchReports={fetchReports}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Reports;
