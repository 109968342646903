import {isEqual} from 'lodash';
import mapboxgl from 'mapbox-gl';
import MapboxCompare from 'mapbox-gl-compare';
import React from 'react';
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css';

import {DisableClickableFeatures} from 'app/utils/mapUtils';

import cs from './styles.styl';

function makeMapProxy(maps: {beforeMap: mapboxgl.Map; afterMap: mapboxgl.Map}): mapboxgl.Map {
  return new Proxy(maps, {
    get(target, propKey) {
      const {beforeMap, afterMap} = target;
      const beforeVal = beforeMap[propKey];

      if (typeof beforeVal === 'function') {
        return function (...args) {
          beforeMap[propKey].apply(beforeMap, args);
          return afterMap[propKey].apply(afterMap, args);
        };
      } else {
        return beforeVal;
      }
    },

    set() {
      // Don’t try to set properties on our fake proxy map!
      return false;
    },
  }) as any;
}

export type CompareSliderSnapPosition = 'left' | 'right' | 'unset';

interface RenderMapArgs {
  className: string;
  onMapLoad: (map: mapboxgl.Map) => unknown;
}

interface Props {
  renderBeforeMap: (args: RenderMapArgs) => React.ReactNode;
  renderAfterMap: (args: RenderMapArgs) => React.ReactNode;
  onMapLoad: (map: mapboxgl.Map) => unknown;

  /**
   * If true, moves the slider to only show the latest image. Used for when you
   * want to draw note geometry without the slider getting in the way.
   */
  moveSliderToOnlyShowLatestImage?: boolean;
  fullScreenOffset: number;
  compareSnapPosition: CompareSliderSnapPosition;
  setCompareSnapPosition: (position: CompareSliderSnapPosition) => void;
  controlPadding?: mapboxgl.PaddingOptions;
}

interface State {
  dragging: boolean;
}

export default class CompareMap extends React.Component<Props, State> {
  static defaultProps: Pick<Props, 'onMapLoad' | 'fullScreenOffset'> = {
    onMapLoad: () => {},
    fullScreenOffset: 0,
  };

  state: State = {dragging: false};

  private beforeMap: mapboxgl.Map | null = null;
  private afterMap: mapboxgl.Map | null = null;

  private compareMap: MapboxCompare | null = null;
  private compareMapContainerRef = React.createRef<HTMLDivElement>();

  /**
   * Used to keep track of the position when we force the slider over with
   * moveSliderToOnlyShowLatestImage and fullScreenOffset props. If the user
   * doesn’t move the slider at all then we’ll reset to this position when
   * moveSliderToOnlyShowLatestImage is turned off.
   */
  private savedSliderPosition: number | null = null;

  private maybeMakeCombinedMap() {
    const {beforeMap, afterMap} = this;
    const {onMapLoad, moveSliderToOnlyShowLatestImage} = this.props;

    if (beforeMap && afterMap && !this.compareMap) {
      this.compareMap = new MapboxCompare(beforeMap, afterMap);
      this.compareMap.on('slideend', this.onSlideEnd);

      if (moveSliderToOnlyShowLatestImage) {
        this.compareMap.setSlider(this.getFullScreenLatestPosition());
      }

      onMapLoad(makeMapProxy({beforeMap, afterMap}));

      beforeMap.resize();
      afterMap.resize();
    }
  }

  componentDidUpdate(oldProps: Props) {
    if (this.compareMap) {
      if (!oldProps.moveSliderToOnlyShowLatestImage && this.props.moveSliderToOnlyShowLatestImage) {
        // This is the case where we need to “full screen the latest,” meaning
        // move the slider all the way to the left so that the user can draw
        // something (which doesn’t work well across compare mode’s divider).
        this.savedSliderPosition = this.compareMap.currentPosition;
        this.compareMap.setSlider(this.getFullScreenLatestPosition());
      } else if (
        oldProps.moveSliderToOnlyShowLatestImage &&
        !this.props.moveSliderToOnlyShowLatestImage
      ) {
        // Here we’re no longer full screening the latest scene, so if the user
        // didn’t move the slider at all, reset to where it was before we moved
        // it.
        if (this.savedSliderPosition !== null) {
          this.compareMap.setSlider(this.savedSliderPosition);
          this.savedSliderPosition = null;
        }
      }

      // If an adjustment comes in when `fullScreenLatest` is already
      // `true`, set the slider position. This allows us to push the compare
      // slider all the way to the left from switching directly from the
      // TabbedSidebar to the AnalyzePolygonTool.
      if (
        this.props.moveSliderToOnlyShowLatestImage &&
        oldProps.fullScreenOffset !== this.props.fullScreenOffset
      ) {
        this.compareMap.setSlider(this.getFullScreenLatestPosition());
      }

      if (
        oldProps.compareSnapPosition !== this.props.compareSnapPosition &&
        this.props.compareSnapPosition !== 'unset'
      ) {
        this.snapSlider(this.props.compareSnapPosition);
      }

      // If padding ever changes, make sure that the slider is still visible.
      if (!isEqual(oldProps.controlPadding, this.props.controlPadding)) {
        this.ensureSliderVisible();
      }
    }
  }

  componentWillUnmount() {
    this.compareMap?.off('slideend', this.onSlideEnd);
  }

  onPointerDown = (ev: React.PointerEvent<HTMLElement>) => {
    if (ev.target instanceof HTMLElement && ev.target.classList.contains('compare-swiper')) {
      this.props.setCompareSnapPosition('unset');
      this.setState({dragging: true});
    }
  };

  onSlideEnd = () => {
    this.setState({dragging: false});

    // We reset the saved slider position so that if you drag the slider in“full
    // screen latest” mode we leave it where you put it once that mode ends.
    this.savedSliderPosition = null;

    // This fixes if you drag the slider under the sidebar or something.
    this.ensureSliderVisible();
  };

  private getFullScreenLatestPosition() {
    return (this.props.controlPadding?.left ?? 0) + this.props.fullScreenOffset;
  }

  private snapSlider(position: 'right' | 'left') {
    const {controlPadding} = this.props;
    const {compareMap, compareMapContainerRef} = this;

    if (!controlPadding || !compareMap || !compareMapContainerRef.current) return;

    const offset =
      position === 'right'
        ? compareMapContainerRef.current.clientWidth - controlPadding.right
        : controlPadding.left;

    compareMap.setSlider(offset);
  }

  private ensureSliderVisible() {
    const {controlPadding} = this.props;
    const {compareMap, compareMapContainerRef} = this;

    if (!controlPadding || !compareMap || !compareMapContainerRef.current) {
      return;
    }

    if (compareMap.currentPosition < controlPadding.left) {
      compareMap.setSlider(controlPadding.left);
    } else if (
      compareMap.currentPositition >
      compareMapContainerRef.current.clientWidth - controlPadding.right
    ) {
      compareMap.setSlider(compareMapContainerRef.current.clientWidth - controlPadding.right);
    }
  }

  render() {
    const {renderBeforeMap, renderAfterMap} = this.props;
    const {dragging} = this.state;

    return (
      <div
        className={cs.compareMapContainer}
        ref={this.compareMapContainerRef}
        onPointerDown={this.onPointerDown}
      >
        {renderBeforeMap({
          className: cs.compareMap,
          onMapLoad: (beforeMap) => {
            this.beforeMap = beforeMap;
            this.maybeMakeCombinedMap();
          },
        })}

        {renderAfterMap({
          className: cs.compareMap,
          onMapLoad: (afterMap) => {
            this.afterMap = afterMap;
            this.maybeMakeCombinedMap();
          },
        })}

        {dragging && <DisableClickableFeatures />}
      </div>
    );
  }
}
