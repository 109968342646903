import React from 'react';
import {Link} from 'react-router-dom';

import justsatSrc from 'app/assets/images/justsat.png';

import classNames from './styles.styl';

export default () => (
  <article>
    <img src={justsatSrc} className={classNames.notFoundImage} />
    <h1>Normally we are great at finding things…</h1>
    <h2>But this time, we cannot find the page you are looking for.</h2>

    <p style={{marginTop: '2.4rem'}}>
      <Link to="/">Back to our homepage</Link> or{' '}
      <a href="mailto:lens@upstream.tech">shoot us an email</a>
    </p>
  </article>
);
