import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import React from 'react';

import {api} from 'app/modules/Remote';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {ApiProject, FeatureCollectionKind} from 'app/modules/Remote/Project';
import {getFeatureCollectionsByType} from 'app/utils/featureCollectionUtils';
import {UseApiGetMeta, useStateWithDeps} from 'app/utils/hookUtils';
import * as immutableUtils from 'app/utils/immutableUtils';
import * as projectUtils from 'app/utils/projectUtils';

const MAX_PER_PAGE = 100;

export interface ProjectsActions {
  createProject(
    organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>,
    projectName: string,
    layersBySource: ApiOrganization['defaultLayersBySource'],
    overlayIds: number[]
  ): undefined | Promise<number>;
  updateProject(id: string, p: Partial<ApiProject>): void | Promise<void>;
  deleteProject(id: string): void | Promise<void>;
  updateFeatureCollection(
    id: number,
    updates: I.MergesInto<I.ImmutableOf<ApiFeatureCollection>>
  ): void | Promise<void>;

  refreshFeatureCollection(id: number): void | Promise<void>;
  addLayersToFeatureCollections(ids: number[], layers: Record<string, string[]>): Promise<void>;
  removeLayersFromFeatureCollections(
    ids: number[],
    layers: Record<string, string[]>
  ): Promise<void>;
}

interface ProjectsContextValue {
  /** Ordering is most recently created first. */
  projects: I.OrderedMap<string, I.ImmutableOf<ApiProject>> | null;
  /**
   * If projects is defined, this map will contain every FeatureCollection (by
   * id) from those projects.
   */
  featureCollections: I.Map<number, I.ImmutableOf<ApiFeatureCollection>>;

  actions: ProjectsActions;
  meta: UseApiGetMeta<never> & {error: any};
}

const ProjectsContext = React.createContext<ProjectsContextValue | undefined>(undefined);

/**
 * Maintains a list of all of the projects for a user, along with their
 * (unhydrated) feature collections.
 *
 * We load all feature collections for the projects because they’re always
 * necessary. Even when displaying the project list, we use the feature
 * collections to determine the bounds to show.
 *
 * This provider assumes:
 * - The number of projects / org is small and can be fetched in 1–2 requests.
 * - Likewise, there are not that may feature collections
 */
const ProjectsProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    /** Used so we know whether it’s safe to make API requests, and when we need
     *  to update. */
    organizationId: string | null;
  }>
> = ({organizationId, children}) => {
  // We combine this state into one object so that it has to be set atomically.
  // This allows us to preserve an invariant that any FeatureCollection
  // referenced by a Project has been loaded.
  //
  // We’re not using useApiGet here so that we can patch / delete from the
  // results using the actions.
  //
  // TODO(fiona): Since this was written, useApiGet has added an "updateLocal"
  // action that we could possibly take advantage of and remove some of this
  // code. In the meantime, we need to provide a fake updateLocal function to
  // make the UseApiGetMeta type match.
  const [state, setState] = useStateWithDeps<
    Pick<ProjectsContextValue, 'projects' | 'featureCollections'>
  >({projects: null, featureCollections: I.Map()}, [organizationId]);

  // We implicitly reset to loading=true because we’ll be immediately loading in
  // the useEffect below, and this keeps downstream components from rendering in
  // a non-loading state and then immediately re-rendering with loading. (It
  // also means that useProjects’s refreshOnMount check will properly detect
  // we’re loading when the app starts up and avoid a double-load.)
  const [loading, setLoading] = useStateWithDeps(!!organizationId, [setState]);
  const [error, setError] = useStateWithDeps<null | Error>(null, [setState]);

  const loadProjects = React.useCallback(
    async (clear?: boolean) => {
      // ProjectsProvider is not behind WithLoggedInAuth so it needs its own
      // gate on whether the user has fully been signed up. We determine this by
      // the presence of an organizationId, which means that there is an entry
      // in the realtime /users table associating the user with an organization.
      // W/o that assocation, this API request would fail with an auth error.
      if (!organizationId) {
        return;
      }

      try {
        if (clear) {
          setState((s) => ({...s, projects: null}));
        }

        setLoading(true);
        setError(null);

        // We use getAllPages for the looping / pagination. Ideally we only want
        // 1 total request, since our customers do not have more than 1 page of
        // projects, but because pagination loops until it gets a completely
        // empty page there’s a minimum of 2 requests. So we might as well do
        // them in parallel.
        const projectsList = (await api.projects.list({}, {getAllPages: 2}))
          .get('data')
          .sort(compareCreatedAtDescending)
          .toList();

        // If we were cancelled for some reason we can abort before loading the
        // feature collections.
        if (setState.expired) {
          return;
        }

        // I.Set so we dedupe if multiple projects reference the same feature
        // collections
        const featureCollectionIds = projectsList
          .map((p) => p!.get('featureCollections').map((pfc) => pfc!.get('id')))
          .flatten()
          .toSet();

        const featureCollectionIdChunks = immutableUtils.splitIntoChunks(
          featureCollectionIds.toIndexedSeq(),
          MAX_PER_PAGE
        );

        const featureCollectionChunks = await Promise.all(
          featureCollectionIdChunks
            .map(async (ids) => {
              return (await api.featureCollections.batchFetch({id: ids!})).get('data');
            })
            .toArray()
        );

        setState((s) => {
          let featureCollections = s.featureCollections;

          featureCollectionChunks.forEach((chunk) => {
            chunk.forEach((fc) => {
              // Deep equals to keep things more stable given the potential for
              // realtime feature collection updates.
              if (!isEqual(fc, featureCollections.get(fc!.get('id')))) {
                featureCollections = featureCollections.set(fc!.get('id'), fc!);
              }
            });
          });

          return {
            projects: I.OrderedMap(
              projectsList.map((p): [string, I.ImmutableOf<ApiProject>] => [p!.get('id'), p!])
            ),
            featureCollections,
          };
        });
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        }
      } finally {
        setLoading(false);
      }
    },
    [organizationId, setState, setLoading, setError]
  );

  // If loadProjects changes, it’s a sign that organizationId changed so we should
  // reload the projects, clearing out the previous ones.
  React.useEffect(() => {
    loadProjects(true);
  }, [loadProjects]);

  const actions = useProjectsActions(setState);

  const value = React.useMemo(
    (): ProjectsContextValue => ({
      projects: state.projects,
      featureCollections: state.featureCollections,
      actions,
      meta: {
        loading,
        error,
        refresh: loadProjects,
        updateLocal: async () => {
          throw new Error('Unsupported');
        },
      },
    }),
    [state.projects, state.featureCollections, loading, error, loadProjects, actions]
  );

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};

function useProjectsActions(
  setState: (
    action: React.SetStateAction<Pick<ProjectsContextValue, 'projects' | 'featureCollections'>>
  ) => void
): ProjectsActions {
  const createProject = React.useCallback(
    async (
      organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>,
      projectName: string,
      layersBySource: ApiOrganization['defaultLayersBySource'],
      overlayIds: number[]
    ) => {
      const data = (
        await api.projects.create(organization.get('id'), projectName, 'monitoring', layersBySource)
      ).get('data');

      const project = data.get('project');
      const featureCollection = data.get('featureCollection');

      await Promise.all(
        overlayIds.map((overlayId) => api.projects.addOverlay(project.get('id'), overlayId))
      );

      setState((s) => {
        const featureCollections = s.featureCollections.set(
          featureCollection.get('id'),
          featureCollection
        );
        const projects: I.OrderedMap<
          string,
          I.MapAsRecord<I.ImmutableFields<ApiProject>>
        > = s.projects
          ? s.projects.set(project.get('id'), project).toOrderedMap()
          : I.OrderedMap([]);

        return {
          projects: projects,
          featureCollections: featureCollections,
        };
      });

      return featureCollection.get('id');
    },
    [setState]
  );

  const updateProject = React.useCallback(
    async (id: string, p: Partial<ApiProject>) => {
      const updatedProject = (await api.projects.update(id, p as any)).get('data');

      // We need to re-fetch feature collections because updatedProject may
      // reference some we haven’t loaded.
      //
      //  We’re going to assume that a single project won’t have more than
      // MAX_PER_PAGE feature collections for the moment.
      const projectFeatureCollections = (
        await api.featureCollections.batchFetch({
          id: updatedProject.get('featureCollections').map((pfc) => pfc!.get('id')),
        })
      ).get('data');

      setState((s) => {
        let featureCollections = s.featureCollections;

        projectFeatureCollections.forEach((fc) => {
          if (!isEqual(fc, featureCollections.get(fc!.get('id')))) {
            featureCollections = featureCollections.set(fc!.get('id'), fc!);
          }
        });

        return {
          projects: s.projects && s.projects.set(updatedProject.get('id'), updatedProject),
          featureCollections,
        };
      });
    },
    [setState]
  );

  const deleteProject = React.useCallback(
    async (id: string) => {
      await api.projects.delete(id);

      setState(({projects, featureCollections}) => {
        return {
          projects: projects?.filter((p) => p!.get('id') !== id).toMap() || null,
          featureCollections,
        };
      });
    },
    [setState]
  );

  const updateFeatureCollection = React.useCallback(
    (id: number, updates: I.MergesInto<I.ImmutableOf<ApiFeatureCollection>>): Promise<void> =>
      new Promise((resolve, reject) =>
        setState(({projects, featureCollections}) => {
          const original = featureCollections.get(id);
          const optimistic = original.merge(I.fromJS(updates));

          api.featureCollections.update(id, updates).then(
            (res) => {
              setState(({projects, featureCollections}) => ({
                projects,
                featureCollections: featureCollections.set(id, res.get('data')),
              }));

              resolve();
            },
            (err) => {
              setState(({projects, featureCollections}) => ({
                projects,
                // We only reset back to the original we hadn’t modified in the
                // meantime. This is not perfect, but as long as at least
                // something eventually succeeds writing to the server, we’ll
                // converge with that as truth.
                featureCollections: featureCollections.update(id, (fc) =>
                  fc === optimistic ? original : fc
                ),
              }));

              reject(err);
            }
          );

          return {
            projects,
            featureCollections: featureCollections.set(id, optimistic),
          };
        })
      ),
    [setState]
  );

  const addLayersToFeatureCollections = React.useCallback(
    (ids: number[], layersObject: Record<string, string[]>): Promise<void> =>
      new Promise((resolve, reject) =>
        setState(({projects, featureCollections}) => {
          const idsToUpdates = Object.fromEntries(
            ids.map((id: number) => {
              const original = featureCollections.get(id);

              // Get a copy of existing enrolled layers
              const optimisticEnrolledLayers: Record<string, string[]> = original
                .getIn(['processingConfig', 'enrolledLayers'])
                .toJS();
              // Update optimistic values, with deduping
              // Note: empty case: fc may not have any layers from
              // this source, so the source key will be missing
              Object.entries(layersObject).forEach(([source, layers]) => {
                optimisticEnrolledLayers[source] = Array.from(
                  new Set((optimisticEnrolledLayers[source] || []).concat(layers))
                );
              });

              // Create an optimistic fc
              const optimistic = original.setIn(
                ['processingConfig', 'enrolledLayers'],
                I.fromJS(optimisticEnrolledLayers)
              );
              return [
                id,
                {
                  original,
                  optimistic,
                },
              ];
            })
          );

          Promise.all(
            ids.map((id) =>
              api.featureCollections.addLayer(id, layersObject).then(
                (res) => {
                  setState(({projects, featureCollections}) => ({
                    projects,
                    featureCollections: featureCollections.set(id, res.get('data')),
                  }));
                  resolve();
                },
                (err) => {
                  setState(({projects, featureCollections}) => ({
                    projects,
                    // We only reset back to the original we hadn’t modified in the
                    // meantime. This is not perfect, but as long as at least
                    // something eventually succeeds writing to the server, we’ll
                    // converge with that as truth.
                    featureCollections: featureCollections.update(id, (fc) =>
                      fc === idsToUpdates[id].optimistic ? idsToUpdates[id].original : fc
                    ),
                  }));
                  reject(err);
                }
              )
            )
          );

          const updatedFeatureCollections = featureCollections.withMutations((fcs) => {
            Object.keys(idsToUpdates).forEach((id) =>
              fcs.set(parseInt(id), idsToUpdates[id].optimistic)
            );
          });

          return {
            projects,
            featureCollections: updatedFeatureCollections,
          };
        })
      ),
    [setState]
  );

  const removeLayersFromFeatureCollections = React.useCallback(
    (ids: number[], layersObject: Record<string, string[]>): Promise<void> =>
      new Promise((resolve, reject) =>
        setState(({projects, featureCollections}) => {
          const idsToUpdates = Object.fromEntries(
            ids.map((id: number) => {
              const original = featureCollections.get(id);

              // Since we're going to add new values, we need to preserve existing values
              const updatedEnrolledLayers = original
                .getIn(['processingConfig', 'enrolledLayers'])
                .toJS();

              // Delete keys corresponding to the layers passed in
              Object.entries(layersObject).forEach(([source, layers]) => {
                const sourceLayers = updatedEnrolledLayers[source];
                // For each layer we're removing, look for it, and remove if found.
                layers.forEach((layer) => {
                  const index = sourceLayers.indexOf(layer);
                  if (index > -1) {
                    sourceLayers.splice(index, 1);
                  }
                });
                updatedEnrolledLayers[source] = sourceLayers;
              });

              // Create an optimistic fc
              const optimistic = original.setIn(
                ['processingConfig', 'enrolledLayers'],
                I.fromJS(updatedEnrolledLayers)
              );
              return [
                id,
                {
                  original,
                  optimistic,
                },
              ];
            })
          );

          Promise.all(
            ids.map((id) =>
              api.featureCollections.removeLayer(id, layersObject).then(
                (res) => {
                  setState(({projects, featureCollections}) => ({
                    projects,
                    featureCollections: featureCollections.set(id, res.get('data')),
                  }));
                  resolve();
                },
                (err) => {
                  setState(({projects, featureCollections}) => ({
                    projects,
                    // We only reset back to the original we hadn’t modified in the
                    // meantime. This is not perfect, but as long as at least
                    // something eventually succeeds writing to the server, we’ll
                    // converge with that as truth.
                    featureCollections: featureCollections.update(id, (fc) =>
                      fc === idsToUpdates[id].optimistic ? idsToUpdates[id].original : fc
                    ),
                  }));
                  reject(err);
                }
              )
            )
          );

          const updatedFeatureCollections = featureCollections.withMutations((fcs) => {
            Object.keys(idsToUpdates).forEach((id) =>
              fcs.set(parseInt(id), idsToUpdates[id].optimistic)
            );
          });

          return {
            projects,
            featureCollections: updatedFeatureCollections,
          };
        })
      ),
    [setState]
  );

  const refreshFeatureCollection = React.useCallback(
    async (id: number) => {
      const featureCollection = (await api.featureCollections.fetch(id)).get('data');

      setState(({projects, featureCollections}) => ({
        projects,
        featureCollections: featureCollections.set(id, featureCollection),
      }));
    },
    [setState]
  );

  const actions = React.useMemo(
    () => ({
      createProject,
      updateProject,
      deleteProject,
      updateFeatureCollection,
      refreshFeatureCollection,
      addLayersToFeatureCollections,
      removeLayersFromFeatureCollections,
    }),
    [
      createProject,
      updateProject,
      deleteProject,
      updateFeatureCollection,
      refreshFeatureCollection,
      addLayersToFeatureCollections,
      removeLayersFromFeatureCollections,
    ]
  );

  return actions;
}

/**
 * Hook to provide access to the projects and feature collections loaded by
 * ProjectsProvider.
 *
 * Use WithProjects if you’re in a class-based component.
 */
export function useProjects({
  refreshOnMount,
}: {
  refreshOnMount?: boolean | 'clear';
} = {}) {
  const value = React.useContext(ProjectsContext);

  if (!value) {
    throw new Error('This component must be rendered beneath a ProjectsProvider');
  }

  React.useEffect(() => {
    if (refreshOnMount && !value.meta.loading) {
      value.meta.refresh(refreshOnMount === 'clear');
    }

    // We only care to check refreshOnMount at actual mount time, not if its
    // prop value changes later.
    //
  }, []);

  return [value.projects, value.featureCollections, value.actions, value.meta] as const;
}

export const WithProjects: React.FunctionComponent<{
  refreshOnMount?: boolean | 'clear';
  children: (
    projects: ProjectsContextValue['projects'],
    featureCollections: ProjectsContextValue['featureCollections'],
    actions: ProjectsActions,
    meta: UseApiGetMeta<never>
  ) => React.ReactElement | null;
}> = ({refreshOnMount = false, children}) => {
  return children(...useProjects({refreshOnMount}));
};

/**
 * Helper for rendering with a specific project. Calls the renderLoading
 * callback if we’re loading projects, and then calls the children callback with
 * either the project if it was found, or null if it wasn’t.
 *
 * As a special case, if the project wasn’t found in the current list, but we
 * are currently loading, calls the loading indicator because when we’re done
 * loading the project might be there.
 *
 * Note that this helper is based off of the ProjectsContext, meaning we load
 * all the projects and feature collections for the org and then select the
 * matching one. This is fine given that the number of projects for an org is
 * assumed to be small, the project objects are small, and users are most likely
 * to load a project after already having visited the ProjectsList component,
 * which needs them all to be loaded anyway.
 *
 * (The alternative would be to allow this to target load only the necessary
 * project, in the case where the user follows a bookmark into a specific
 * project without going through the dashboard.)
 */
export const WithProject: React.FunctionComponent<{
  /** This can be the full UUID or the first 8 characters */
  projectIdParam: string;
  renderLoading: () => React.ReactElement | null;

  children: (
    project: I.ImmutableOf<ApiProject> | null,
    // We return an array here so that children don’t have to keep looking up
    // feature collections based on the project’s id list, nor do they have to
    // worry about the (should not happen) case of a project referencing a
    // feature collection that’s not in the map.
    projectFeatureCollections: I.Map<FeatureCollectionKind, I.ImmutableOf<ApiFeatureCollection[]>>,
    actions: ProjectsActions,
    meta: UseApiGetMeta<never> & {error: any}
  ) => React.ReactElement | null;
}> = ({projectIdParam, renderLoading, children}) => {
  const [projects, featureCollectionsById, actions, meta] = useProjects({});
  const project = projects && projectUtils.findProjectInProjects(projects, projectIdParam);

  // useMemo to keep the map stable
  const projectFeatureCollections = React.useMemo(() => {
    let featureCollectionsMap: I.Map<
      FeatureCollectionKind,
      I.ImmutableOf<ApiFeatureCollection[]>
    > = I.Map();

    if (project) {
      const [primaryFeatureCollection, overlayFeatureCollections] = getFeatureCollectionsByType(
        project,
        featureCollectionsById
      );
      if (primaryFeatureCollection) {
        featureCollectionsMap = featureCollectionsMap.set(
          'primary',
          I.List([primaryFeatureCollection])
        );
      }

      featureCollectionsMap = featureCollectionsMap.set('overlay', overlayFeatureCollections);
    }

    return featureCollectionsMap;
  }, [project, featureCollectionsById]);

  // We may be reloading the projects, so wait.
  if (!project && meta.loading) {
    return renderLoading();
  }

  return children(project || null, projectFeatureCollections, actions, meta);
};

export const FakeProjectsProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    projects?: I.ImmutableOf<ApiProject[]> | null;
    featureCollections?: I.ImmutableOf<ApiFeatureCollection[]> | null;
    action?: (name: string) => () => any;
    meta?: Record<string, any>;
  }>
> = ({
  children,
  projects = I.List([]),
  featureCollections = I.List([]),
  action = () => () => null,
  meta = undefined,
}) => {
  const value = React.useMemo<ProjectsContextValue>(
    () => ({
      projects: projects && projects.reduce((acc, p) => acc!.set(p!.get('id'), p!), I.OrderedMap()),
      featureCollections: featureCollections
        ? featureCollections.reduce((acc, fc) => acc!.set(fc!.get('id'), fc!), I.Map())
        : I.Map(),
      meta: {
        loading: meta ? meta.loading : false,
        refresh: action('refresh'),
        updateLocal: action('updateLocal'),
        error: null,
      },
      actions: {
        createProject: action('createProject'),
        updateProject: action('updateProject'),
        deleteProject: action('deleteProject'),
        updateFeatureCollection: action('updateFeatureCollection'),
        refreshFeatureCollection: action('refreshFeatureCollection'),
        addLayersToFeatureCollections: action('addLayersToFeatureCollections'),
        removeLayersFromFeatureCollections: action('removeLayersFromFeatureCollections'),
      },
    }),
    [action, projects, featureCollections, meta]
  );

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};

/*
Returns 0 if the elements should not be swapped.
Returns -1 (or any negative number) if valueA comes before valueB
Returns 1 (or any positive number) if valueA comes after valueB
Is pure, i.e. it must always return the same value for the same pair of values.
*/
function compareCreatedAtDescending(a: I.ImmutableOf<ApiProject>, b: I.ImmutableOf<ApiProject>) {
  const bCreatedAt = b.get('createdAt');
  const aCreatedAt = a.get('createdAt');
  if (aCreatedAt > bCreatedAt) {
    return -1;
  } else if (aCreatedAt < bCreatedAt) {
    return 1;
  } else {
    return 0;
  }
}

export default ProjectsProvider;
