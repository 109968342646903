import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import I from 'immutable';
import React from 'react';

import cs from './styles.styl';

// TODO(fiona): Change this to just be a JS object
type ActionMap = I.MapAsRecord<{
  icon: B.IconName;
  text?: string | null;
  onClick: (action: ActionMap, index: number) => unknown;
  minimal?: boolean;
}>;

export interface Props {
  className?: string;
  text: string;
  actions: I.Iterable<number, ActionMap>;
  isOptional: boolean;
  isSubtitle: boolean;
}

export default class Label extends React.Component<Props> {
  static defaultProps = {
    text: '',
    actions: I.List<ActionMap>(),
    isOptional: false,
    isSubtitle: false,
  };

  render() {
    const {className, text, isOptional, isSubtitle} = this.props;
    return (
      <div className={classnames(cs.container, className)}>
        <div
          className={classnames(cs.title, {
            [cs.subtitle]: isSubtitle,
          })}
        >
          {text}
          <span className={cs.optional}>{isOptional ? ' (optional)' : ''}</span>
        </div>
        <div>
          <>{this._renderActions()}</>
        </div>
      </div>
    );
  }

  private _renderActions() {
    const {actions} = this.props;
    return actions.map((action, index) => {
      return (
        <B.Button
          data-test={`action-${index}`}
          icon={action!.get('icon')}
          key={`action-${index}`}
          onClick={() => this._onActionItemClick(action!, index!)}
          minimal={action!.get('minimal', true)}
          small={true}
        >
          {action!.get('text', null)}
        </B.Button>
      );
    });
  }

  private _onActionItemClick = (action: ActionMap, actionIndex: number) => {
    const onClick = action.get('onClick');
    onClick(action, actionIndex);
  };
}
