import {RasterCalculator} from 'app/stores/RasterCalculationStore';

// TODO: See if there is an existing mapbox function for this.
export function project(lng: number, lat: number): {x: number; y: number} {
  const earthRadius = 6378137;
  const d = Math.PI / 180;
  const y = Math.log(Math.tan(((90 + lat) * d) / 2)) / d;
  return {
    x: earthRadius * lng * d,
    y: earthRadius * y * d,
  };
}

export function mouseCoordinateToRawPixel(
  event: mapboxgl.MapMouseEvent,
  calculator: RasterCalculator
): {
  pixelData: number[] | null;
  imageData: ImageData | null;
  coordinateDebugger?: {
    [key: string]: {x: number; y: number};
  };
} {
  if (!calculator) {
    console.warn('no calculator');
    return {pixelData: null, imageData: null};
  }

  const {lng, lat} = event.lngLat;
  const projectedPoint = project(lng, lat);
  const {bounds, image: canvas} = calculator.getImageAndBounds();

  if (!canvas || !bounds) {
    console.warn('Pixel Info: No canvas or bounds available');
    return {pixelData: null, imageData: null};
  }

  const ctx = (canvas as HTMLCanvasElement).getContext('2d', {
    // Note: `willReadFrequently` can be toggled on for faster reads, but with some quick
    // testing this comes at the cost of intense compute.
    willReadFrequently: true,
  });
  if (!ctx) {
    console.warn('Pixel Info: Could not get canvas context');
    return {pixelData: null, imageData: null};
  }

  const [minLng, minLat, maxLng, maxLat] = bounds;
  const projectedMin = project(minLng, minLat);
  const projectedMax = project(maxLng, maxLat);

  // Convert geographic coordinates to pixel coordinates on the canvas
  const x = ((lng - minLng) / (maxLng - minLng)) * canvas.width;
  const y = ((maxLat - lat) / (maxLat - minLat)) * canvas.height;

  const projectedX =
    ((projectedPoint.x - projectedMin.x) / (projectedMax.x - projectedMin.x)) * canvas.width;
  const projectedY =
    ((projectedMax.y - projectedPoint.y) / (projectedMax.y - projectedMin.y)) * canvas.height;

  // TODO: Switch between projected and lateral pixel coordinates here, pick one after determining
  // if projected coordinates are correct.
  const pixelX = projectedX;
  const pixelY = projectedY;

  if (pixelX < 0 || pixelX >= canvas.width || pixelY < 0 || pixelY >= canvas.height) {
    console.warn('Pixel Info: Mouse outside raster bounds');
    return {pixelData: null, imageData: null};
  }

  // Note: If this gets slow we can explore caching the image data.
  // Probably ok as only manageable numbers of tiles load in the window bounds.
  const pixelData = ctx.getImageData(pixelX, pixelY, 1, 1).data;
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  return {
    pixelData: Array.from(pixelData),
    imageData,
    coordinateDebugger: {
      lateralCoordinates: {
        x,
        y,
      },
      projectedCoordinates: {
        x: projectedX,
        y: projectedY,
      },
    },
  };
}
