import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as I from 'immutable';
import React from 'react';
import {
  Column,
  Row,
  TableToggleRowsSelectedProps,
  useFlexLayout,
  useRowSelect,
  useTable,
} from 'react-table';

import {usePushNotification} from 'app/components/Notification';
import {
  ApiNotificationPref,
  ApiNotificationScope,
  ApiNotificationType,
  ApiOrganization,
  ApiOrganizationUser,
} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {
  NotificationPrefsActions,
  useNotificationPrefs,
} from 'app/providers/NotificationPrefsProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {recordEvent} from 'app/tools/Analytics';
import colors from 'app/utils/colorUtils';
import * as C from 'app/utils/constants';
import featureFlags from 'app/utils/featureFlags';

import * as GlobalSettings from './GlobalSettings';
import cs from './NotificationsView.styl';

const NOTIFICATION_SCOPE_TITLES: {[key in ApiNotificationScope]: string} = {
  [C.NOTIFICATION_SCOPE_ALL]: 'All properties',
  [C.NOTIFICATION_SCOPE_MINE]: 'Assigned to me',
  [C.NOTIFICATION_SCOPE_NONE]: 'None',
};

const NOTIFICATION_SCOPES: ApiNotificationScope[] = [
  C.NOTIFICATION_SCOPE_ALL,
  C.NOTIFICATION_SCOPE_MINE,
  C.NOTIFICATION_SCOPE_NONE,
];

const HeaderCell: React.FunctionComponent<
  React.PropsWithChildren<{
    text: string;
    helperText?: string;
  }>
> = ({text, helperText}) => (
  <div className={cs.headerCell}>
    <div className={cs.headerCellText}>{text}</div>
    {helperText && (
      <B.Tooltip
        className={cs.headerCellTooltip}
        position="top"
        content={<div className={cs.headerCellTooltipContent}>{helperText}</div>}
      >
        <B.Icon className={cs.headerCellIcon} color={colors.darkestGray} icon="info-sign" />
      </B.Tooltip>
    )}
  </div>
);

const NOTIFICATION_TYPE_HEADERS: {
  [key in ApiNotificationType]: JSX.Element;
} = {
  [C.NOTIFICATION_TYPE_LENS_NOTE]: <HeaderCell text="New Notes" />,
  [C.NOTIFICATION_TYPE_LENS_NEW_IMAGERY_AVAILABLE]: (
    <HeaderCell text="High-Res Imagery Available" />
  ),
  [C.NOTIFICATION_TYPE_LENS_ALERT]: <HeaderCell text="Changes Detected" />,
  [C.NOTIFICATION_TYPE_LENS_IMAGERY_DONE_PROCESSING]: (
    <HeaderCell
      text="Imagery Ordered"
      helperText="Image orderers will always be notified when imagery processing is complete. These notification settings are additional."
    />
  ),
};

type UpdatingState = {
  [key in ApiNotificationType]?: Record<string, boolean>;
};

interface TableDatum {
  name: string;
  getScope: (t: ApiNotificationType) => ApiNotificationScope;
  getSetScope: (t: ApiNotificationType) => (s: ApiNotificationScope) => Promise<void>;
  getIsUpdating: (t: ApiNotificationType) => boolean;
  setIsUpdating: (t: ApiNotificationType, isUpdating: boolean) => void;
}

/**
 * Input element wrapper that handles indeterminate state and applies consistent
 * styling.
 */
const InputWrapper: React.FunctionComponent<
  React.PropsWithChildren<TableToggleRowsSelectedProps>
> = ({indeterminate = false, ...inputProps}) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className={cs.inputWrapper}>
      <input ref={ref} type="checkbox" {...inputProps} />
    </div>
  );
};

const NotificationScope: React.FunctionComponent<
  React.PropsWithChildren<{
    scope: ApiNotificationScope | 'multiple';
    setScope: (s: ApiNotificationScope) => Promise<void | void[]>;
    isUpdating: boolean;
  }>
> = ({scope, setScope, isUpdating}) => {
  const pushNotification = usePushNotification();

  const hasMultipleScopes = scope === 'multiple';

  return (
    <B.HTMLSelect
      minimal
      iconName={'caret-down'}
      className={classnames({[cs.selectMultiple]: hasMultipleScopes})}
      value={scope}
      disabled={isUpdating}
      onChange={async (ev) => {
        try {
          await setScope(ev.target.value as ApiNotificationScope);
        } catch {
          pushNotification({
            message: 'There was a problem updating the notification settings. Please try again.',
            autoHideDuration: 3000,
            options: {
              intent: B.Intent.DANGER,
            },
          });
        }
      }}
    >
      {hasMultipleScopes && (
        <option key={scope} value={scope} disabled>
          Multiple…
        </option>
      )}
      {NOTIFICATION_SCOPES.map((s) => (
        <option key={s} value={s}>
          {NOTIFICATION_SCOPE_TITLES[s]}
        </option>
      ))}
    </B.HTMLSelect>
  );
};

/**
 * Wrapper around NotificationScope component that handles updating the scope
 * for multiple portfolios with one interaction.
 */
const BulkNotificationScope: React.FunctionComponent<
  React.PropsWithChildren<{
    notificationType: ApiNotificationType;
    selectedRows: Row<TableDatum>[];
    isUpdating: boolean;
    setIsUpdating: (isUpdating: boolean) => void;
  }>
> = ({notificationType, selectedRows, isUpdating, setIsUpdating}) => {
  const scopes = selectedRows.map(({values}) => values[notificationType]);
  const uniqueScopes = scopes.filter((item, index, array) => array.indexOf(item) == index);

  return (
    <NotificationScope
      scope={uniqueScopes.length > 1 ? 'multiple' : uniqueScopes[0]}
      setScope={(nextScope) =>
        new Promise((resolve, reject) => {
          setIsUpdating(true);

          const selectedRowsToUpdate = selectedRows.filter(({values}) => {
            const prevScope = values[notificationType];
            return prevScope !== nextScope;
          });

          Promise.all(
            selectedRowsToUpdate.map(({original}) => {
              const setScope = original.getSetScope(notificationType);
              return setScope(nextScope);
            })
          )
            .then((values) => {
              recordEvent('Set notification preference', {
                type: notificationType,
                scope: nextScope,
                projectCount: selectedRowsToUpdate.length,
              });
              resolve(values);
            })
            .catch(reject)
            .finally(() => {
              setIsUpdating(false);
            });
        })
      }
      isUpdating={isUpdating}
    />
  );
};

const BulkActions: React.FunctionComponent<
  React.PropsWithChildren<{
    selectedRows: Row<TableDatum>[];
    notificationTypes: ApiNotificationType[];
  }>
> = ({selectedRows, notificationTypes}) => {
  // UpdatingState tells us what project and notification type(s) are updating,
  // but not how those updates were triggered. Managing this extra little piece
  // of state allows us to identify when the updates were triggered in bulk.
  const [isBulkUpdating, setIsBulkUpdating] = React.useState(false);

  const count = selectedRows.length;

  return (
    <B.Popover
      content={
        <div className={cs.bulkEditPopover}>
          {notificationTypes.map((notificationType) => (
            <B.Label key={notificationType}>
              {NOTIFICATION_TYPE_HEADERS[notificationType]}
              <BulkNotificationScope
                notificationType={notificationType}
                selectedRows={selectedRows}
                setIsUpdating={(isUpdating) => {
                  selectedRows.forEach(({original}) =>
                    original.setIsUpdating(notificationType, isUpdating)
                  );
                  setIsBulkUpdating(isUpdating);
                }}
                isUpdating={selectedRows.some(({original}) =>
                  original.getIsUpdating(notificationType)
                )}
              />
            </B.Label>
          ))}
        </div>
      }
    >
      <B.Button
        icon={isBulkUpdating && <B.Spinner size={16} className={cs.bulkEditSpinner} />}
        disabled={!count}
        intent={B.Intent.PRIMARY}
      >
        Bulk edit {count ? `${count} ${count === 1 ? 'portfolio' : 'portfolios'}` : ''}
      </B.Button>
    </B.Popover>
  );
};

const NotificationsViewTable: React.FunctionComponent<
  React.PropsWithChildren<{
    projects: I.ImmutableOf<ApiProject[]>;
    notificationPrefs: I.Iterable<string, I.Iterable<ApiNotificationType, ApiNotificationScope>>;
    getDefaultScope: (t: ApiNotificationType) => ApiNotificationScope;
    setNotificationPref: NotificationPrefsActions['setNotificationPref'];
    notificationTypes: ApiNotificationType[];
  }>
> = ({projects, notificationPrefs, getDefaultScope, setNotificationPref, notificationTypes}) => {
  const prevSelectedRowIds = React.useRef<Record<string, boolean>>({});
  const [updatingState, setUpdatingState] = React.useState<UpdatingState>({});

  const columns = React.useMemo<Column<TableDatum>[]>(
    () => [
      {
        Header: ({getToggleAllRowsSelectedProps}) => (
          <InputWrapper {...getToggleAllRowsSelectedProps()} />
        ),
        id: 'selection',
        className: cs.selectionCell,
        Cell: ({row}) => <InputWrapper {...row.getToggleRowSelectedProps()} />,
        width: 40,
      },
      {
        Header: 'Portfolio',
        id: 'portfolio',
        accessor: ({name}) => name,
        width: 100,
      },
      ...notificationTypes.map((notificationType) => ({
        Header: () => NOTIFICATION_TYPE_HEADERS[notificationType],
        id: notificationType,
        accessor: ({getScope}) => getScope(notificationType),
        Cell: ({cell}) => (
          <NotificationScope
            scope={cell.row.original.getScope(notificationType)}
            setScope={async (s) => {
              cell.row.original.setIsUpdating(notificationType, true);
              await cell.row.original.getSetScope(notificationType)(s);
              /**
               * Even though we’re only updating one project’s notification
               * preference, we keep this shape consistent with the Smartlook
               * event triggered from the bulk workflow.
               */
              recordEvent('Set notification preference', {
                type: notificationType,
                scope: s,
                projectCount: 1,
              });
              cell.row.original.setIsUpdating(notificationType, false);
            }}
            isUpdating={cell.row.original.getIsUpdating(notificationType)}
          />
        ),
        width: 100,
      })),
    ],
    [notificationTypes]
  );

  const data = React.useMemo<TableDatum[]>(
    () =>
      projects
        .map((project) => ({
          name: project!.get('name'),
          getScope: (t: ApiNotificationType) =>
            notificationPrefs.getIn([project!.get('id'), t], getDefaultScope(t)),
          getSetScope: (t: ApiNotificationType) => (s: ApiNotificationScope) =>
            setNotificationPref(project!.get('id'), t, s),
          getIsUpdating: (t: ApiNotificationType) =>
            updatingState[t]?.[project!.get('id')] ?? false,
          setIsUpdating: (t: ApiNotificationType, isUpdating: boolean) =>
            setUpdatingState((prevUpdatingState) => ({
              ...prevUpdatingState,
              [t]: {
                ...(prevUpdatingState[t] || {}),
                [project!.get('id')]: isUpdating,
              },
            })),
        }))
        .toArray(),
    [projects, notificationPrefs, setNotificationPref, getDefaultScope, updatingState]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows: selectedRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // Maintain row selection when underlying data changes.
        selectedRowIds: prevSelectedRowIds.current,
      },
    },
    useFlexLayout,
    useRowSelect
  );

  React.useEffect(() => {
    prevSelectedRowIds.current = selectedRows.reduce((acc, {id}) => ({...acc, [id]: true}), {});
  }, [selectedRows]);

  const hasProjects = projects.size > 0;

  return (
    <GlobalSettings.Body
      title="Notifications"
      titleActions={
        <BulkActions selectedRows={selectedRows} notificationTypes={notificationTypes} />
      }
      subtitle={
        hasProjects
          ? 'We’ll send you a daily digest email for the notifications you’ve selected below.'
          : 'Your organization does not have any portfolios.'
      }
    >
      {hasProjects && (
        <div className={cs.scrollRegion}>
          <table className={cs.table} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={column.className} key={column.id}>
                      <div>{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={row.id}
                    className={classnames({[cs.selected]: row.isSelected})}
                    tabIndex={0}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                        key={cell.column.id}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </GlobalSettings.Body>
  );
};

const NotificationsView: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    projects: I.ImmutableOf<ApiProject[]>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    notificationPrefs: I.ImmutableOf<ApiNotificationPref[]> | null;
    setNotificationPref: NotificationPrefsActions['setNotificationPref'];
    isLoading: boolean;
  }>
> = ({organization, projects, profile, notificationPrefs, setNotificationPref, isLoading}) => {
  const groupedNotificationPrefs =
    notificationPrefs &&
    notificationPrefs
      .groupBy((p) => p!.get('projectId'))
      .map((value) => value!.groupBy((v) => v!.get('type')).map((v) => v!.first().get('scope')));

  // Comes up with the defaults for prefs that haven’t been explicitly set by
  // the user. NOTE: this has to be kept in sync with the notifier’s behavior in
  // the same circumstances.
  const getDefaultScope = (type: ApiNotificationType): ApiNotificationScope => {
    switch (type) {
      case C.NOTIFICATION_TYPE_LENS_ALERT:
      case C.NOTIFICATION_TYPE_LENS_NEW_IMAGERY_AVAILABLE:
      case C.NOTIFICATION_TYPE_LENS_NOTE:
        return profile.get('role') === C.USER_ROLE_OWNER
          ? C.NOTIFICATION_SCOPE_ALL
          : C.NOTIFICATION_SCOPE_MINE;

      case C.NOTIFICATION_TYPE_LENS_IMAGERY_DONE_PROCESSING:
        return C.NOTIFICATION_SCOPE_NONE;
    }
  };

  // This is a little redudant since isLoading should be true if
  // groupedNotificationPrefs is null, but we need the groupedNotificationPrefs
  // condition for subsequent type checking.
  if (isLoading || groupedNotificationPrefs === null) {
    return <GlobalSettings.CenteredSpinner />;
  }

  const notificationTypes: ApiNotificationType[] = [
    C.NOTIFICATION_TYPE_LENS_NOTE,
    C.NOTIFICATION_TYPE_LENS_NEW_IMAGERY_AVAILABLE,
    C.NOTIFICATION_TYPE_LENS_IMAGERY_DONE_PROCESSING,
  ];

  if (featureFlags.LOOKOUTS(organization)) {
    notificationTypes.push(C.NOTIFICATION_TYPE_LENS_ALERT);
  }

  return (
    <NotificationsViewTable
      projects={projects}
      notificationPrefs={groupedNotificationPrefs}
      getDefaultScope={getDefaultScope}
      setNotificationPref={setNotificationPref}
      notificationTypes={notificationTypes}
    />
  );
};

const NotificationsViewDataProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
  }>
> = ({organization, profile}) => {
  const [projects, , , projectsMeta] = useProjects();
  const {
    notificationPrefs,
    meta: notificationPrefsMeta,
    actions: notificationPrefsActions,
  } = useNotificationPrefs();

  const isLoading = projectsMeta.loading || notificationPrefsMeta.loading;

  return (
    <NotificationsView
      notificationPrefs={notificationPrefs}
      organization={organization}
      profile={profile}
      projects={(projects || I.OrderedMap()).valueSeq().toList()}
      setNotificationPref={notificationPrefsActions.setNotificationPref}
      isLoading={isLoading}
    />
  );
};

export default NotificationsViewDataProvider;
