import classnames from 'classnames';
import React from 'react';

import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import AnalyzePolygonChart from '../../AnalyzePolygonChart/AnalyzePolygonChart';
import {NoteGraph} from '../../AnalyzePolygonChart/types';
import {parseGraphIsoStringsAsDates} from '../../AnalyzePolygonChart/utils';

/**
 * A function component that handles rendering the Analyze Area chart using the
 * graph data stored on the note, and the UI-friendly caption that interprets
 * the graph‘s information.
 */
export const AnalyzeAreaGraph: React.FunctionComponent<
  React.PropsWithChildren<{
    noteGraph: NoteGraph;
    imageRefs: mapUtils.MapImageRefs;
    areaInM2: number;
    areaUnit: ApiOrganizationAreaUnit;
    areFontsReady: boolean;
  }>
> = ({noteGraph, imageRefs, areaInM2, areaUnit, areFontsReady}) => {
  const {layerKey} = React.useMemo(() => parseGraphIsoStringsAsDates(noteGraph), [noteGraph]);

  const layerLabel = layerUtils.getLayer(layerKey).display;

  return (
    <div className="note-graph-container">
      <div>
        <b>{layerLabel}</b>
      </div>
      <div className="note-graph">
        <div className="note-graph-chart">
          <AnalyzePolygonChart
            graph={parseGraphIsoStringsAsDates(noteGraph)}
            imageRefs={imageRefs}
            areaInM2={areaInM2}
            areaUnit={areaUnit}
            /** Enforce a higher resolution so canvas text appears smooth in
             * exported/printed reports. From the Chart.js documentation: “For
             * applications where a chart will be [...] printed to a higher DPI
             * medium it can be desirable to render the chart at a higher
             * resolution than the default.” */
            devicePixelRatio={4}
            disablePanZoom={true}
            isLegendInteractive={false}
            hideTooltip={true}
            loading={!areFontsReady}
            loadingClassName="aa-loading"
            chartClassName="aa-container"
            controlsClassName={classnames('aa-controls no-print')}
            graphStyle={{height: 125}}
            isExpanded={false}
          />
        </div>
      </div>
    </div>
  );
};
