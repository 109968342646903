import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import {saveAs} from 'file-saver';
import {History} from 'history';
import * as I from 'immutable';
import moment from 'moment';
import React, {ReactElement, ReactNode} from 'react';
import ReactDOM from 'react-dom';

import {api} from 'app/modules/Remote';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser, getAreaUnit} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import CustomizeTagsModal from 'app/pages/MonitorProjectView/CustomizeTagsDialog';
import EditTagsMenu from 'app/pages/MonitorProjectView/EditTagsMenu';
import {FeaturesActions} from 'app/providers/FeaturesProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import {recordEvent} from 'app/tools/Analytics';
import {APP_PROPERTIES_KEY, UNIT_AREA_M2} from 'app/utils/constants';
import * as CONSTANTS from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import {featureInUS} from 'app/utils/featureCollectionUtils';
import {atLeastLensTier} from 'app/utils/featureFlags';
import * as featureUtils from 'app/utils/featureUtils';
import {UTMArea} from 'app/utils/geoJsonUtils';
import {useStateWithDeps} from 'app/utils/hookUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import {LayerInfo} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as multiFeaturePropertyUtils from 'app/utils/multiFeaturePropertyUtils';
import {orgIsAllowedTier} from 'app/utils/organizationUtils';
import * as routeUtils from 'app/utils/routeUtils';
import * as tagUtils from 'app/utils/tagUtils';
import * as userUtils from 'app/utils/userUtils';

import cs from './PropertyDetailsSidebar.styl';
import {usePushNotification} from '../Notification';
import {ParcelReportWindow} from '../ReportExport/ParcelReportWindow';
import {ReportPortalContext} from '../ReportExport/ReportPortalProvider';

const EmptyField = () => <i>None</i>;
const downloadPendingMessage =
  "We're on it! You'll receive an email with a download link within an hour.";

// The exported Blueprint AnchorButtonProps type is not surfacing instrinsic
// HTMLAnchorElement attributes, meaning that it’s not recognizing href as a
// valid prop. Thus, we infer the component props instead.
//
// TODO(emily): Consider extracting this component for usage more broadly in the
// application. The AppNav, OrderImagerySidebar, and LayersMenu
// components/directories all have similar instantiations.
export const InfoIconButton: React.FunctionComponent<
  React.PropsWithChildren<Partial<React.ComponentProps<typeof B.AnchorButton>>>
> = (buttonProps) => (
  <B.AnchorButton icon="info-sign" minimal small target="_blank" {...buttonProps} />
);

export const PropertyField: React.FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    titleAction?: JSX.Element;
    value?: string;
    setValue?: (v: string) => void;
    displayCopyButton?: boolean;
    noWrap?: boolean;
  }>
> = ({title, titleAction, value, setValue, displayCopyButton, noWrap = false, children}) => {
  const lowercaseTitle = title.toLocaleLowerCase();

  const handleEditValue =
    setValue &&
    (() => {
      const editedText = prompt(`Enter a new ${lowercaseTitle}:`, value || '');

      // If name (1) has not been changed, or (2) has been deleted, no-op.
      if (editedText === value || !editedText) {
        return;
      }

      setValue(editedText);
    });

  return (
    <div className={cs.field}>
      <div className={cs.fieldContentWrapper}>
        <div className={cs.fieldHeader}>
          <div className={cs.fieldHeaderLabel}>{title}</div>
        </div>
        <div className={classNames(cs.fieldContent, noWrap ? cs.noWrap : '')}>
          {value ?? children}
        </div>
      </div>
      {(handleEditValue || displayCopyButton || titleAction) && (
        <div className={cs.hoverButtons}>
          {handleEditValue && (
            <B.Tooltip content={`Edit ${lowercaseTitle}`}>
              <B.Button
                icon={<B.Icon icon="edit" onClick={handleEditValue} />}
                small
                minimal={true}
              />
            </B.Tooltip>
          )}
          {displayCopyButton && value && (
            <B.Tooltip content={`Copy ${title} to clipboard`}>
              <B.Button
                minimal
                small
                icon="clipboard"
                onClick={() => navigator.clipboard.writeText(value)}
              />
            </B.Tooltip>
          )}
          {titleAction}
        </div>
      )}
    </div>
  );
};

const TagField: React.FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    features: I.ImmutableOf<ApiFeature[]>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    batchPatchFeature: FeaturesActions['batchPatchFeature'];
    role: ApiOrganizationUser['role'];
    openCustomizeTagsModal: () => void;
  }>
> = ({title, featureCollection, features, role, batchPatchFeature, openCustomizeTagsModal}) => {
  const tagSettings = tagUtils.getTagSettings(featureCollection, tagUtils.TAG_KIND.FEATURE);
  const tagIds = tagUtils.getFeaturesTagIds(features);

  const featureTagSettings = tagSettings.keySeq().filter((t) => tagIds.includes(t!));

  return (
    <div className={cs.field}>
      <div className={cs.fieldHeader}>
        <div className={cs.fieldHeaderLabel}>{title}</div>
      </div>

      <div className={cs.tagsContent}>
        {featureTagSettings
          .map((id) => <tagUtils.Tag key={id} setting={tagSettings.get(id!)} />)
          .toArray()}

        {featureTagSettings.isEmpty() && <EmptyField />}
      </div>
      <div className={cs.hoverButtons}>
        {(role === 'owner' || role === 'regular') && (
          <EditTagsMenu
            featureCollectionId={featureCollection.get('id')}
            tagSettings={tagSettings}
            tagStatusMaps={tagUtils.getFeaturesTagStatusMaps(features)}
            onChange={(tagStatusMap) =>
              batchPatchFeature({
                featureCollectionId: featureCollection.get('id'),
                features,
                changes: featureUtils.tagStatusMapToPropertiesChanges(tagStatusMap),
              })
            }
            openCustomizeTagsModal={role === 'owner' ? openCustomizeTagsModal : null}
            arrow
            kind={tagUtils.TAG_KIND.FEATURE}
          >
            <B.Button icon={<B.Icon icon="more" />} minimal={true} small />
          </EditTagsMenu>
        )}
      </div>
    </div>
  );
};

const AssigneeField: React.FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    organizationUsers: ApiOrganizationUser[];
    email?: string;
  }>
> = ({title, organizationUsers, email}) => {
  const user = organizationUsers.find((u) => u.email === email);
  const value = user?.name ? userUtils.getSuffixedUserName(user) : email;
  return <PropertyField title={title}>{value || <EmptyField />}</PropertyField>;
};

const SourceInformation: React.FunctionComponent<
  React.PropsWithChildren<{
    position: 'left' | 'right' | 'single';
    featureData: I.ImmutableListOf<ApiFeatureData> | null;
    imageRef: mapUtils.MapImageRef;
    organization: I.ImmutableOf<ApiOrganization>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    selectedFeature: I.ImmutableOf<ApiFeature>;
  }>
> = ({position, featureData, imageRef, organization, featureCollection, selectedFeature}) => {
  const featureDatum = React.useMemo(() => {
    if (!featureData) {
      return null;
    }

    const cursorKey = featureUtils.getCursorKeyForLayerKey(featureCollection, imageRef.layerKey);
    const featureDatum = featureData.findLast(
      (d) => d!.get('types').includes(imageRef.layerKey) && d!.get(cursorKey) === imageRef.cursor
    );
    return featureDatum;
  }, [featureCollection, featureData, imageRef]);

  const [isDownloadingPng, setIsDownloadingPng] = React.useState(false);
  const [isDownloadingGeotiff, setIsDownloadingGeotiff] = React.useState(false);
  const [downloadPngError, setDownloadPngError] = React.useState<string | null>(null);
  const [downloadGeotiffError, setDownloadGeotiffError] = React.useState<string | null>(null);

  const [hasPendingDownload, setHasDownloadPending] = useStateWithDeps(false, [
    imageRef, // reset if the layer/source/feature changes
  ]);

  const pushNotification = usePushNotification();

  if (!featureDatum) {
    return null;
  }

  const sourceDetails: featureUtils.ImagerySourceDetails = featureUtils.imageAndSourceDetails(
    featureDatum,
    imageRef.layerKey,
    organization
  ).sourceDetails;

  let downloadPng: (() => Promise<void>) | null = null;
  const paidImagery = featureUtils.getPaidImagery(selectedFeature);
  const scenes = paidImagery?.get('scenes');
  const orderImageryState = featureUtils.getOrderImageryState(
    scenes,
    featureDatum,
    imageRef.layerKey
  );
  if (orderImageryState == featureUtils.ORDER_IMAGERY_STATE_PROCESSED) {
    downloadPng = async () => {
      setIsDownloadingPng(true);
      setDownloadPngError(null);
      try {
        const {sourceId, layerId} = layerUtils.parseLayerKey(imageRef.layerKey);
        const blob = await api.featureCollections
          .features(featureCollection.get('id'))
          .exportPng(selectedFeature.get('id'), sourceId, layerId, featureDatum.get('date'));
        const filename = `${selectedFeature.getIn(['properties', 'name'])} - ${featureDatum.get(
          'date'
        )}.png`;
        saveAs(blob, filename);
        recordEvent('Downloaded PNG', {
          featureCollectionId: featureCollection.get('id'),
          featureId: selectedFeature.get('id'),
          layerId,
          date: featureDatum.get('date'),
        });
      } catch (error) {
        Sentry.captureException(error);
        setDownloadPngError('Error downloading image');
      } finally {
        setIsDownloadingPng(false);
      }
    };
  }

  let downloadGeotiff: (() => Promise<void>) | null = null;
  const {sourceId, layerId} = layerUtils.parseLayerKey(imageRef.layerKey);
  // Very old feature data may have url (singular), not urls
  const hasCog =
    featureDatum.hasIn(['images', 'cogUrls', sourceId, layerId, 'urls']) ||
    featureDatum.hasIn(['images', 'cogUrls', sourceId, layerId, 'url']);

  if (hasCog) {
    downloadGeotiff = async () => {
      setIsDownloadingGeotiff(true);
      setDownloadGeotiffError(null);
      try {
        const filename = `${selectedFeature.getIn(['properties', 'name'])} - ${featureDatum.get(
          'date'
        )}.tif`;

        const signedUrlResponse = await api.featureCollections
          .features(featureCollection.get('id'))
          .exportGeotiff(
            selectedFeature.get('id'),
            sourceId,
            layerId,
            featureDatum.get('date'),
            filename
          );

        const signedUrl = signedUrlResponse.get('data');
        if (signedUrl === null) {
          setHasDownloadPending(true);
          pushNotification({
            message: downloadPendingMessage,
            options: {intent: B.Intent.SUCCESS},
          });
        } else {
          window.open(signedUrl, '_blank'); // open new window for download
          recordEvent('Downloaded GeoTIFF', {
            featureCollectionId: featureCollection.get('id'),
            featureId: selectedFeature.get('id'),
            sourceId,
            layerId,
            date: featureDatum.get('date'),
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        setDownloadGeotiffError('Error downloading GeoTIFF');
      } finally {
        setIsDownloadingGeotiff(false);
      }
    };
  }

  let prefix = 'Layer Image';
  if (position === 'left') {
    prefix = 'Left Image';
  } else if (position === 'right') {
    prefix = 'Right Image';
  }

  const date = moment(featureDatum.get('date'));
  return (
    <div className={cs.layerSection}>
      <SectionHeader
        sectionTitle={position === 'single' ? 'Layer Details' : `Layer Details (` + prefix + ')'}
      />

      {sourceDetails && (
        <PropertyField
          title="Layer Source"
          value={`${sourceDetails.operator} ${sourceDetails.name}`}
        />
      )}
      <PropertyField title="Capture Time">
        <div>
          {imageryUtils.formatCaptureDate(date, imageRef.layerKey, {
            featureDatum,
            showCaptureTime: true,
          })}
        </div>
      </PropertyField>
      {sourceDetails.resolution && (
        <PropertyField title="Spatial Resolution" value={`${sourceDetails.resolution}m`} />
      )}
      {downloadPng && (
        <>
          <PropertyField
            title="PNG"
            titleAction={
              <B.Tooltip content={`Learn more`}>
                <InfoIconButton href="https://support.upstream.tech/article/81-exporting-your-imagery-orders" />
              </B.Tooltip>
            }
          >
            <B.Button
              minimal
              onClick={downloadPng}
              loading={isDownloadingPng}
              disabled={isDownloadingPng}
            >
              Download
            </B.Button>
          </PropertyField>
          {downloadPngError && <div className={cs.downloadError}>{downloadPngError}</div>}
          <div className={cs.note}>
            PNGs are image files, exported at a reduced resolution, and are not georeferenced. These
            maps can be shared with landowners or stakeholders.
          </div>
        </>
      )}
      {downloadGeotiff && (
        <>
          <PropertyField
            title="GeoTIFF"
            titleAction={
              <B.Tooltip content={`Learn more`}>
                <InfoIconButton href="https://support.upstream.tech/article/81-exporting-your-imagery-orders" />
              </B.Tooltip>
            }
          >
            <B.Tooltip content={downloadPendingMessage} disabled={!hasPendingDownload}>
              <B.Button
                minimal
                onClick={() => downloadGeotiff()}
                loading={isDownloadingGeotiff}
                disabled={isDownloadingGeotiff || hasPendingDownload}
              >
                Download
              </B.Button>
            </B.Tooltip>
          </PropertyField>
          {downloadGeotiffError && <div className={cs.downloadError}>{downloadGeotiffError}</div>}
          <div className={cs.note}>
            Cloud Optimized GeoTIFFs are georeferenced image files, exported at full resolution, and
            can be used in GIS applications.
          </div>
        </>
      )}
    </div>
  );
};

export const SectionHeader: React.FunctionComponent<
  React.PropsWithChildren<{
    sectionTitle: string;
    rightElement?: ReactElement;
  }>
> = ({sectionTitle, rightElement}) => {
  //override h3 styling to give a less aggressive top margin
  if (!rightElement) {
    return <h3 style={{marginTop: '1rem'}}>{sectionTitle}</h3>;
  } else {
    return (
      <div className={cs.flexSectionHeader}>
        <h3>{sectionTitle}</h3>
        {rightElement}
      </div>
    );
  }
};

const PropertyDetailsSidebar: React.FunctionComponent<
  React.PropsWithChildren<{
    firebaseToken: string;
    overlayFeatureCollections: I.ImmutableListOf<ApiFeatureCollection>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    project: I.ImmutableOf<ApiProject>;
    allFeatures: I.ImmutableOf<ApiFeature[]>;
    selectedFeatureId: number;
    role: ApiOrganizationUser['role'];
    organization: I.ImmutableOf<ApiOrganization>;
    organizationUsers: ApiOrganizationUser[];
    activeLayerKey?: string | null;
    layerLegendWidth?: number;
    batchPatchFeature: FeaturesActions['batchPatchFeature'];
    batchArchiveFeature: FeaturesActions['batchArchiveFeatures'];
    updateFeatureCollection: ProjectsActions['updateFeatureCollection'];
    featureData: I.ImmutableListOf<ApiFeatureData> | null;
    imageRefs: mapUtils.MapImageRefs;
    history: History;
  }>
> = ({
  firebaseToken,
  overlayFeatureCollections,
  featureCollection,
  project,
  allFeatures,
  selectedFeatureId,
  role,
  organization,
  organizationUsers,
  batchPatchFeature,
  batchArchiveFeature,
  updateFeatureCollection,
  activeLayerKey,
  featureData,
  imageRefs,
  history,
}) => {
  const {reportEl, open: openReport} = React.useContext(ReportPortalContext);

  const canEdit = role === 'owner' || role === 'regular';
  const selectedFeature = allFeatures.find((f) => f!.get('id') === selectedFeatureId);
  const featureName = selectedFeature.getIn(['properties', 'name']).toString();
  const isMultiLocationProperty = multiFeaturePropertyUtils.isMultiLocationProperty(
    allFeatures,
    selectedFeature
  );
  const multiFeaturePropertyParts = React.useMemo(
    () => multiFeaturePropertyUtils.getMultiFeaturePropertyParts(allFeatures, selectedFeature),
    [allFeatures, selectedFeature]
  );

  const isParcelReportEnabled = React.useMemo(
    () => getIsParcelReportEnabled(selectedFeature, overlayFeatureCollections, organization),
    [selectedFeature, overlayFeatureCollections, organization]
  );

  const [isCustomizeTagsModalVisible, setIsCustomizeTagsModalVisible] = React.useState(false);

  const selectedFeatures = I.List(multiFeaturePropertyParts.map((p) => p.feature));

  const selectedPropertyPart = multiFeaturePropertyParts.find(
    (p) => p.feature.get('id') === selectedFeature.get('id')
  )!;

  const areaUnit = getAreaUnit(organization);
  const areaUnitName = areaUnit === 'areaHectare' ? 'hectares' : 'acres';

  const propertyAreaInDisplayUnit = React.useMemo(() => {
    const areaInM2 = multiFeaturePropertyParts.reduce(
      (acc, p) => acc + UTMArea(p.feature.toJS()),
      0
    );

    return conversionUtils.convert(areaInM2, UNIT_AREA_M2, areaUnit);
  }, [multiFeaturePropertyParts, areaUnit]);

  const partAreaInDisplayUnit = React.useMemo(
    () => conversionUtils.convert(UTMArea(selectedFeature.toJS()), UNIT_AREA_M2, areaUnit),
    [selectedFeature, areaUnit]
  );

  const appProperties = selectedFeature.getIn(['properties', APP_PROPERTIES_KEY]);

  const orgHasStreamingAccess = atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, organization);

  const wmtsUrl =
    layerUtils.isLayerKeyHighResTruecolor(activeLayerKey) &&
    appProperties?.get(CONSTANTS.APP_PROPERTY_WMTS_URL);

  const xyzUrls = React.useMemo(() => {
    const streamimageryApiKey =
      layerUtils.isLayerKeyHighResTruecolor(activeLayerKey) &&
      appProperties?.get(CONSTANTS.APP_PROPERTY_STREAMIMAGERY_API_KEY);

    if (!streamimageryApiKey || !featureData) {
      return null;
    }

    return imageRefs
      .map((imageRef) => {
        const cursorKey = featureUtils.getCursorKeyForLayerKey(
          featureCollection,
          imageRef.layerKey
        );
        const featureDatum = featureData.findLast(
          (d) =>
            d!.get('types').includes(imageRef.layerKey) && d!.get(cursorKey) === imageRef.cursor
        );
        if (!featureDatum) {
          return null;
        }

        const templateUrl = featureDatum.getIn([
          'images',
          'templateUrls',
          imageRef.layerKey,
          'tiles',
          0,
        ]);
        if (!templateUrl || !mapUtils.isTilerUrl(templateUrl)) {
          return null;
        }
        return mapUtils.formatTileUrl(templateUrl, streamimageryApiKey, true, (url: URL) => {
          url.searchParams.delete('feature_id');
          url.searchParams.set('lens_id', selectedFeature.getIn(['properties', 'lensId']));
          return url;
        });
      })
      .filter((url) => url != null);
  }, [featureCollection, selectedFeature, featureData, imageRefs, activeLayerKey, appProperties]);

  const showSourceDetails = !!featureData;

  const uniqueLegendLayers: LayerInfo[] = Array.from(
    new Set(
      imageRefs
        .map((imageRef) => layerUtils.getLayer(imageRef.layerKey))
        .filter((layer) => layer && 'renderLayerLegend' in layer && layer.renderLayerLegend)
    )
  );

  const lensIdField = (
    <PropertyField
      title="Lens ID"
      titleAction={
        <B.Tooltip content={`Learn more`}>
          <InfoIconButton href="https://support.upstream.tech/article/91-maintaining-your-lensids" />
        </B.Tooltip>
      }
      value={selectedFeature.getIn(['properties', 'lensId']) || 'unknown'}
      displayCopyButton
      noWrap
    />
  );

  const excludedFeatureProperties = featureUtils.FeatureAttributes.all;

  const customFeaturePropertyKeys = React.useMemo(
    () =>
      selectedFeature
        .get('properties')
        .keySeq()
        .filter((x) => !excludedFeatureProperties.includes(x!))
        .sort((propertyKeyA, propertyKeyB) =>
          featureUtils.alphanumericSort(propertyKeyA, propertyKeyB)
        )
        .toList(),
    [excludedFeatureProperties, selectedFeature]
  );

  const customFeaturePropertiesHtml = (
    <ul>
      {
        customFeaturePropertyKeys.map((p) => (
          <li key={p}>
            <b>{p}:</b> <span>{String(selectedFeature.get('properties').get(p!))}</span>
          </li>
        )) as unknown as ReactNode
      }
    </ul>
  );

  const [customFeatureCollapseOpen, setCustomFeatureCollapseOpen] = React.useState(false);

  const customFeatureProperties =
    customFeaturePropertyKeys.size > 0 ? (
      <>
        <PropertyField title="Additional Attributes">
          <B.Button
            minimal
            icon={customFeatureCollapseOpen ? 'chevron-down' : 'chevron-right'}
            onClick={() =>
              setCustomFeatureCollapseOpen(
                (prevCustomFeatureCollapseOpen) => !prevCustomFeatureCollapseOpen
              )
            }
          >
            {customFeatureCollapseOpen ? 'Hide' : 'View'}
          </B.Button>
        </PropertyField>
        <div className={cs.customAttributes}>
          <B.Collapse isOpen={customFeatureCollapseOpen}>{customFeaturePropertiesHtml}</B.Collapse>
        </div>
      </>
    ) : null;

  return (
    <div className={cs.content}>
      <div className={cs.body}>
        <SectionHeader sectionTitle="Property Details" />
        {/* If this isn't a multi location property, show the Lens ID below other property level fields. */}
        {!isMultiLocationProperty && lensIdField}
        <PropertyField
          title="Property Name"
          value={featureName}
          setValue={
            canEdit
              ? (newFeatureName) => {
                  batchPatchFeature({
                    featureCollectionId: featureCollection.get('id'),
                    features: selectedFeatures,
                    changes: I.fromJS({
                      properties: {
                        name: newFeatureName,
                      },
                    }),
                  });
                }
              : undefined
          }
        />
        <PropertyField
          title="Property Area"
          value={`${conversionUtils.numberWithCommas(
            propertyAreaInDisplayUnit.toFixed(2)
          )} ${areaUnitName}`}
        />
        {multiFeaturePropertyParts.length > 1 && (
          <>
            <div className={cs.divider} />

            {/** If we are viewing part of a multi-features property, allow users with editing access
          to change the part name. This updates the title and map labels in the note report. */}
            <PropertyField
              title="Location Name"
              setValue={
                canEdit
                  ? (newMultiFeaturePartName) =>
                      batchPatchFeature({
                        featureCollectionId: featureCollection.get('id'),
                        features: I.List([selectedFeature]),
                        changes: I.fromJS({
                          properties: {
                            multiFeaturePartName: newMultiFeaturePartName,
                          },
                        }),
                      })
                  : undefined
              }
            >
              {selectedPropertyPart.partName || <EmptyField />}
            </PropertyField>

            {/** If we are viewing part of a multi-features property, display the area of the selected part. */}
            <PropertyField
              title="Location Area"
              value={`${conversionUtils.numberWithCommas(
                partAreaInDisplayUnit.toFixed(2)
              )} ${areaUnitName}`}
            />
          </>
        )}
        {/* If this is a multi location property, show the Lens ID below other location level fields. */}
        {isMultiLocationProperty && lensIdField}
        <TagField
          title={featureUtils.APP_PROPERTY_NAMES.tagIds}
          features={I.List([selectedFeature])}
          featureCollection={featureCollection}
          // Modify the batchPatchFeature logic so that we only update the
          // selected feature. This allows the user to set tags on a specific
          // part of a multi-location property.
          batchPatchFeature={(args) =>
            batchPatchFeature({
              ...args,
              features: I.List([selectedFeature]),
            })
          }
          role={role}
          openCustomizeTagsModal={() => {
            setIsCustomizeTagsModalVisible(true);
          }}
        />
        <AssigneeField
          title={featureUtils.APP_PROPERTY_NAMES.assigneeEmail}
          organizationUsers={organizationUsers}
          email={appProperties?.get('assigneeEmail')}
        />
        <PropertyField title={featureUtils.APP_PROPERTY_NAMES.reportingDueDate}>
          {appProperties?.get('reportingDueDate') || <EmptyField />}
        </PropertyField>
        {/* If custom, user-provided properties exist on the GeoJSON Feature, they will be displayed here. */}
        {customFeatureProperties}
        {showSourceDetails && imageRefs.length == 1 && (
          <>
            <div className={cs.divider} />
            <SourceInformation
              position="single"
              featureData={featureData}
              imageRef={imageRefs[0]}
              organization={organization}
              featureCollection={featureCollection}
              selectedFeature={selectedFeature}
            />
          </>
        )}
        {showSourceDetails && imageRefs.length == 2 && (
          <>
            <div className={cs.divider} />
            <SourceInformation
              position="left"
              featureData={featureData}
              imageRef={imageRefs[0]}
              organization={organization}
              featureCollection={featureCollection}
              selectedFeature={selectedFeature}
            />
            <div className={cs.divider} />
            <SourceInformation
              position="right"
              featureData={featureData}
              imageRef={imageRefs[1]}
              organization={organization}
              featureCollection={featureCollection}
              selectedFeature={selectedFeature}
            />
          </>
        )}
        {/* only show this section if the org has streaming URLs,
        OR is a Lite org we want to upsell to. */}
        {(wmtsUrl || !orgHasStreamingAccess) && (
          <>
            <div className={cs.divider} />
            <div className={cs.layerSection}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <SectionHeader sectionTitle="Imagery Streaming" />
                <B.Tooltip content={`Learn more`}>
                  <InfoIconButton href="https://support.upstream.tech/article/127-wmts-streaming" />
                </B.Tooltip>
              </div>
              {wmtsUrl && (
                <>
                  <PropertyField
                    title="WMTS"
                    value={decodeURI(wmtsUrl)}
                    displayCopyButton
                    titleAction={
                      <B.Tooltip content={`Learn more`}>
                        <InfoIconButton href="https://support.upstream.tech/article/127-wmts-streaming" />
                      </B.Tooltip>
                    }
                    noWrap
                  />
                  <div className={cs.note}>
                    Copy this web map tile server (WMTS) link to add to GIS. This link will include
                    all truecolor imagery ordered for this property.
                  </div>
                  {xyzUrls?.length == 1 && (
                    <>
                      <PropertyField
                        title="XYZ"
                        value={decodeURI(xyzUrls[0]!)}
                        displayCopyButton
                        titleAction={
                          <B.Tooltip content={`Learn more`}>
                            <InfoIconButton href="https://support.upstream.tech/article/127-wmts-streaming" />
                          </B.Tooltip>
                        }
                        noWrap
                      />
                      <div className={cs.note}>
                        Copy this XYZ URL to add to GIS. It will include just one selected scene.
                      </div>
                    </>
                  )}
                  {xyzUrls?.length == 2 && (
                    <>
                      <PropertyField
                        title="XYZ (Left)"
                        value={decodeURI(xyzUrls[0]!)}
                        displayCopyButton
                        titleAction={
                          <B.Tooltip content={`Learn more`}>
                            <InfoIconButton href="https://support.upstream.tech/article/127-wmts-streaming" />
                          </B.Tooltip>
                        }
                        noWrap
                      />

                      <PropertyField
                        title="XYZ (Right)"
                        value={decodeURI(xyzUrls[1]!)}
                        displayCopyButton
                        titleAction={
                          <B.Tooltip content={`Learn more`}>
                            <InfoIconButton href="https://support.upstream.tech/article/127-wmts-streaming" />
                          </B.Tooltip>
                        }
                        noWrap
                      />
                      <div className={cs.note}>
                        Copy an XYZ URL to add to GIS. It will include just one selected scene.
                      </div>
                    </>
                  )}
                </>
              )}
              {!wmtsUrl && (
                <>
                  <div className={cs.note}>
                    <a
                      href="https://support.upstream.tech/article/127-wmts-streaming"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Imagery streaming{' '}
                    </a>
                    helps you easily view imagery ordered through Lens in other geospatial tools.
                  </div>
                  <div className={cs.note}>
                    <strong>
                      Available in Standard and Enterprise plans only. Reach out to{' '}
                      <a href="mailto:lens@upstream.tech">lens@upstream.tech</a> about upgrading
                      today.
                    </strong>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {isParcelReportEnabled && (
          <>
            <div className={cs.divider} />
            <div className={cs.layerSection}>
              <SectionHeader sectionTitle="Parcel Owner Report" />
              <div className={cs.note}>
                Generate a report of a property's parcel information, along with the parcel data of
                contiguous landowners.
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <B.Button
                    className={cs.button}
                    text={'Generate Report'}
                    outlined
                    onClick={(ev) => {
                      openReport(ev.ctrlKey || ev.metaKey);
                    }}
                  />
                </div>
              </div>
              {reportEl &&
                ReactDOM.createPortal(
                  <ParcelReportWindow
                    firebaseToken={firebaseToken}
                    organization={organization}
                    projectId={project.get('id')}
                    feature={selectedFeature}
                    featureCollection={featureCollection}
                    overlayFeatureCollections={overlayFeatureCollections}
                    multiFeaturePropertyParts={multiFeaturePropertyParts}
                  />,
                  reportEl
                )}
            </div>
          </>
        )}
        {/**render legends for any layers that have a renderLayerLegend function.*/}
        {uniqueLegendLayers.length > 0 && (
          <>
            <div className={cs.divider} />
            {uniqueLegendLayers.map(
              (layer, i) =>
                layer &&
                'renderLayerLegend' in layer &&
                !!layer.renderLayerLegend && (
                  <div className={cs.layerSection} key={'layersection' + i}>
                    <SectionHeader sectionTitle={`${layer.display} Legend`} />
                    {layer.renderLayerLegend()}
                  </div>
                )
            )}
          </>
        )}
        {role === 'owner' && (
          <div>
            <div className={cs.divider} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <B.Button
                className={cs.button}
                icon={'trash'}
                text={'Delete Property'}
                intent={B.Intent.DANGER}
                outlined
                onClick={async () => {
                  if (
                    confirm(
                      'Are you sure you want to delete this property? It will no longer be visible in Lens, so we recommend saving any reports prior to deleting.'
                    )
                  ) {
                    await batchArchiveFeature({
                      featureCollectionId: featureCollection.get('id'),
                      features: I.List([selectedFeature]),
                    });
                    /**
                     * Even though we’re only archiving one feature, we keep this
                     * shape consistent with the Smartlook event triggered from the
                     * SelectedActions component so the data is grouped together.
                     */
                    recordEvent('Archived features', {
                      featureCollectionId: featureCollection.get('id'),
                      featureCount: 1,
                    });
                    history.push(routeUtils.makeProjectDashboardUrl(organization, project));
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>

      {isCustomizeTagsModalVisible && (
        <CustomizeTagsModal
          featureCollection={featureCollection}
          updateFeatureCollection={updateFeatureCollection}
          onClose={() => setIsCustomizeTagsModalVisible(false)}
          kind={tagUtils.TAG_KIND.FEATURE}
        />
      )}
    </div>
  );
};

function getIsParcelReportEnabled(
  feature: I.ImmutableOf<ApiFeature>,
  overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>,
  organization: I.ImmutableOf<ApiOrganization>
) {
  return (
    orgIsAllowedTier(organization, [CONSTANTS.LENS_TIER_PLUS, CONSTANTS.LENS_TIER_ENTERPRISE]) &&
    overlayFeatureCollections.findIndex(
      (fc) => fc?.get('id') === CONSTANTS.REGRID_FEATURE_COLLECTION_ID
    ) > -1 &&
    featureInUS(feature)
  );
}

export default PropertyDetailsSidebar;
