import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import {LayerTag} from 'app/utils/layers';

import LayersIcon from './Icons/LayersIcon';
import cs from './LensLibraryView.styl';
import {LibraryDatasetType} from './LibraryTypes';

export const LibraryItemDetailsModal: React.FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean;
    onClose: () => void;
    actionButton: JSX.Element;
    name: string;
    source: string;
    description: string | React.ReactNode;
    license?: {
      name: string;
      url: string | null;
    };
    tags?: LayerTag[];
    resolution?: number;
    geographicExtent?: string;
    thumbnailUrl?: string;
    type: LibraryDatasetType;
  }>
> = ({
  isOpen,
  onClose,
  actionButton,
  name,
  source,
  resolution,
  geographicExtent,
  description,
  license,
  tags,
  thumbnailUrl,
  type,
}) => {
  return (
    <B.Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={classnames(cs.libraryModal, cs.detailsModal)}
    >
      <div className={cs.detailsModalHeader}>
        <B.Button minimal icon={'cross'} onClick={() => onClose()} />
      </div>
      <div className={cs.modalBody}>
        <div className={cs.detailsInfo}>
          <h2>{name}</h2>
          <h3>{source}</h3>

          <div className={cs.detailsText}>
            <div>
              Type:{' '}
              <a
                className={cs.datasetType}
                href="https://support.upstream.tech/article/169-layers-vs-overlays"
              >
                {type}
              </a>
              <span className={cs.datasetTypeIcon}>
                <B.Icon icon={type === LibraryDatasetType.LAYER ? <LayersIcon /> : 'layers'} />
              </span>
            </div>
            {!!resolution && <div>{`Spatial Resolution: ${resolution}m`}</div>}
            {geographicExtent && <div>{`Coverage: ${geographicExtent}`}</div>}
          </div>
          {!!tags?.length && (
            <div className={classnames(cs.detailsText, cs.tags)}>
              {tags.map((t) => (
                <B.Tag key={t.label} round minimal large style={{backgroundColor: t.color}}>
                  {t.label}
                </B.Tag>
              ))}
            </div>
          )}
          <div className={cs.detailsText}>
            {description}
            {license && (
              <div className={cs.detailsText}>
                License:{' '}
                {license.url ? (
                  <a href={license.url} target="_blank" rel="noopener noreferrer">
                    {license.name}
                  </a>
                ) : (
                  <>{license.name}</>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={cs.detailsThumbnail}>
          <img src={thumbnailUrl} />
          <div className={cs.cardActionButton}>{actionButton}</div>
        </div>
      </div>
    </B.Dialog>
  );
};
