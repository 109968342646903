import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as geojson from 'geojson';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import React from 'react';

import AnalyzePolygonChart from 'app/components/AnalyzePolygonChart/AnalyzePolygonChart';
import {NoteGraph} from 'app/components/AnalyzePolygonChart/types';
import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, TagSetting} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser, getAreaUnit} from 'app/modules/Remote/Organization';
import CustomizeTagsModal from 'app/pages/MonitorProjectView/CustomizeTagsDialog';
import EditTagsMenu from 'app/pages/MonitorProjectView/EditTagsMenu';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import {NewApiNote, NotesActions, NotesState, StateApiNote} from 'app/stores/NotesStore';
import * as C from 'app/utils/constants';
import * as firebaseUtils from 'app/utils/firebaseUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {toImmutableMap} from 'app/utils/immutableUtils';
import * as layers from 'app/utils/layers';
import * as mapUtils from 'app/utils/mapUtils';
import * as noteUtils from 'app/utils/noteUtils';
import * as tagUtils from 'app/utils/tagUtils';

import cs from './NoteCard.styl';
import ReplaceLayerIcon from './ReplaceLayerIcon';
import {
  parseGraphIsoStringsAsDates,
  parseGraphTimeRangeAsIsoStrings,
} from '../AnalyzePolygonChart/utils';
import RerunChartAnalysisTool from '../AnalyzePolygonPopup/RerunChartAnalysisTool';

const MAXIMUM_ATTACHMENT_COUNT = 3;

const NoteForm: React.FunctionComponent<
  React.PropsWithChildren<
    {
      organization: I.ImmutableOf<ApiOrganization>;
      profile: I.ImmutableOf<ApiOrganizationUser>;
      imageRefs: mapUtils.MapImageRefs;

      notesState: NotesState;
      notesActions: NotesActions;

      /** Note exists when viewing a note, or editing an existing note. */
      note?: StateApiNote;

      onClose?: () => void;
      onNoteCreated?: (note: StateApiNote) => void;
      promptForCoordinates?: () => unknown;
      zoomToGeometry?: (geometry: geojson.GeoJSON) => unknown;

      /** Optional flags for disabling note add-ons and actions. */
      autoUpdateLayerAndCursors?: boolean;
      canCollapse?: boolean;
      disableGeometry?: boolean;
      isFocused?: boolean;

      // If a graph prop is provided, the user will be able to include the graph
      // data in the note. If a graph prop isn’t provided but the
      // optionally-provided note contains graph data, the user will be able to
      // remove it. The component currently doesn’t reconcile the two if they’re
      // both truthy, but that situation also doesn’t arise in the application.
      graph?: NoteGraph | null;
      showRerunChartAnalysisTool?: boolean;
      renderMinimalForAA?: boolean;
      renderMinimal?: boolean;

      //Optional props we pass in if we need to be able to render the RerunChartAnalysisTool
      featureData?: I.ImmutableListOf<ApiFeatureData> | null;
      firebaseToken?: string;
    } /**if we are rendering the most minimal version of the NoteForm
     *  (ie, just the text field), we can skip a lot of props.
     */ & (
      | {
          renderMinimal: true;
          renderMinimalForAA?: never;
          onSelectImageRefs?: (imageRefs: mapUtils.MapImageRefs) => unknown | undefined;
          updateFeatureCollection?: ProjectsActions['updateFeatureCollection'] | undefined;
          featureCollection?: I.ImmutableOf<ApiFeatureCollection> | undefined;
        }
      | {
          renderMinimal?: never;
          renderMinimalForAA?: boolean;
          onSelectImageRefs: (imageRefs: mapUtils.MapImageRefs) => unknown;
          updateFeatureCollection: ProjectsActions['updateFeatureCollection'];
          featureCollection: I.ImmutableOf<ApiFeatureCollection>;
        }
    )
  >
> = ({
  organization,
  profile,
  featureCollection,
  imageRefs,
  notesState,
  notesActions,
  note,
  onClose,
  onNoteCreated,
  onSelectImageRefs,
  promptForCoordinates,
  zoomToGeometry,
  updateFeatureCollection,
  autoUpdateLayerAndCursors,
  canCollapse,
  disableGeometry,
  isFocused,
  graph,
  renderMinimalForAA,
  renderMinimal,
  showRerunChartAnalysisTool = false,
  firebaseToken,
  featureData,
}) => {
  const pendingAttachmentsRef = React.useRef<HTMLInputElement>(null);

  const defaultText = note?.text ?? '';
  const [pendingText, setPendingText] = React.useState(defaultText);

  const defaultImageRefs = note ? note.imageRefs : imageRefs;
  const [pendingImageRefs, setPendingImageRefs] = React.useState(defaultImageRefs);

  const defaultAttachments: (File | string)[] = React.useMemo(
    () => note?.attachments || [],
    [note?.attachments]
  );
  const [pendingAttachments, setPendingAttachments] = React.useState(defaultAttachments);

  const [isPosting, setIsPosting] = React.useState(false);

  const [isExpanded, setIsExpanded] = React.useState(canCollapse ? false : true);

  const defaultGraph = note ? note.graph : graph;
  const [pendingGraph, setPendingGraph] = React.useState(defaultGraph);
  const [includeGraph, setIncludeGraph] = React.useState(true);

  const defaultTagIds = note ? note.tagIds : null;
  const [pendingTagIds, setPendingTagIds] = React.useState(defaultTagIds);

  const [hasInvalidLayers, setHasInvalidLayers] = React.useState(false);

  React.useEffect(() => {
    setHasInvalidLayers(noteUtils.noteHasInvalidLayersOrCursors(imageRefs));
  }, [imageRefs]);

  // Keep local layer information up-to-date with map selection if
  // autoUpdateLayerAndCursors is truthy.
  React.useEffect(() => {
    if (autoUpdateLayerAndCursors) {
      setPendingImageRefs(imageRefs);
    }
  }, [imageRefs, autoUpdateLayerAndCursors]);

  // Keep local graph up-to-date with graph prop if graph prop is truthy.
  React.useEffect(() => {
    if (graph) {
      setPendingGraph(graph);
    }
  }, [graph]);

  // A function to reset form fields to default values.
  const resetFormAndClose = React.useCallback(() => {
    setPendingText(defaultText);
    setPendingImageRefs(defaultImageRefs);
    setPendingGraph(defaultGraph);
    setPendingAttachments(defaultAttachments);
    setIncludeGraph(true);

    if (notesState.pendingNoteGeometryFeature) {
      notesActions.popPendingNoteGeometry(notesState);
    }

    if (pendingAttachmentsRef.current) {
      (pendingAttachmentsRef.current.value as string | null) = null;
    }

    if (onClose) {
      onClose();
    }
  }, [
    defaultAttachments,
    defaultImageRefs,
    defaultGraph,
    defaultText,
    notesActions,
    notesState,
    onClose,
  ]);

  // Clear out any pending note geometries on mount. This prevents us from
  // starting the note composition experience unintentionally with a sticky
  // pending geometry from remote state.
  //
  // If the note prop is truthy, we know we’re editing an existing note instead
  // of creating a new one, and therefore want to keep the pending geometry
  // around for potential editing.
  React.useEffect(() => {
    if (!note && notesState.pendingNoteGeometryFeature) {
      notesActions.popPendingNoteGeometry(notesState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagIds = tagUtils.getTagIds(
    toImmutableMap(pendingTagIds || {}) as I.ImmutableOf<tagUtils.TagStatusMap>
  );
  // we won't have access to tagSettings if we're rendering the minimal NoteForm
  const tagSettings = !renderMinimal
    ? tagUtils.getTagSettings(featureCollection, tagUtils.TAG_KIND.NOTE)
    : I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<TagSetting>>>([]);
  const activeTags = tagSettings.filter((t) => tagIds.includes(t!.get('id'))).toList();

  const [isCustomizeTagsModalVisible, setIsCustomizeTagsModalVisible] = React.useState(false);
  const openCustomizeTagsModal = React.useMemo(
    () =>
      profile.get('role') === C.USER_ROLE_OWNER ? () => setIsCustomizeTagsModalVisible(true) : null,
    [profile]
  );

  // A function that handles creating a new note.
  const createNote = React.useCallback(
    async (attachmentUrls: string[]) => {
      const update: NewApiNote = {
        text: pendingText,
        imageRefs: pendingImageRefs,
        geometry: notesState.pendingNoteGeometryFeature?.geometry ?? null,
        attachments: attachmentUrls.length ? attachmentUrls : null,
        graph: (includeGraph && pendingGraph) || null,
        tagIds: pendingTagIds,
      };

      // We set createNote to focus the note so that we’ll automatically scroll
      // to it. The <ScrollToBottom> component would only scroll it in place if
      // we were already scrolled all the way down.
      const newNote = await notesActions.createNote(update, {focus: true});

      if (onNoteCreated) {
        onNoteCreated(newNote);
      }
    },
    [
      includeGraph,
      notesActions,
      notesState,
      onNoteCreated,
      pendingGraph,
      pendingImageRefs,
      pendingText,
      pendingTagIds,
    ]
  );

  // A function that handles updating an existing note.
  const updateNote = async (attachmentUrls: string[]) => {
    // With ! here since we call updateNote conditionally behind a check for a
    // truthy note object.
    const oldNote = note!;

    const update: Partial<StateApiNote> = {};

    if (pendingText !== defaultText) {
      update.text = pendingText;
    }

    // If any part of the raster image reference has changed, update layerKey,
    // cursorKey, and cursorType.
    if (!isEqual(pendingImageRefs, defaultImageRefs)) {
      update.imageRefs = pendingImageRefs;
    }

    if (!isEqual(oldNote.geometry, notesState.pendingNoteGeometryFeature?.geometry)) {
      update.geometry = notesState.pendingNoteGeometryFeature?.geometry ?? null;
    }

    if (!isEqual(attachmentUrls, defaultAttachments)) {
      update.attachments = attachmentUrls;
    }

    if (!isEqual(oldNote.graph, pendingGraph) || (!!oldNote.graph && !includeGraph)) {
      update.graph = (includeGraph && pendingGraph) || null;
    }

    if (!isEqual(pendingTagIds, defaultTagIds)) {
      update.tagIds = pendingTagIds;
    }

    await notesActions.updateNote(oldNote, update);
  };

  // A function that handles re-saving an existing note with a new graph.
  // For use by RerunChartAnalysisTool.
  const saveReanalyzedNoteGraph = async (newGraph: NoteGraph) => {
    // when we're in this context we are guaranteed to have a note
    const oldNote = note!;

    const update: Partial<StateApiNote> = {};

    update.graph = newGraph;

    setIsPosting(true);

    // TODO: Handle the error case.
    try {
      await notesActions.updateNote(oldNote, update);
      resetFormAndClose();
    } finally {
      setIsPosting(false);
    }
  };

  // A function that handles uploading new attachments to Firebase, setting
  // application state, and delegating the POST or PATCH request, depending on
  // if a note is being created or updated, respectively.
  const onSubmit = async (event: React.FormEvent | React.KeyboardEvent) => {
    event.preventDefault();

    if ((!pendingText && !pendingAttachments.length) || isPosting) {
      return;
    }

    setIsPosting(true);

    // TODO: Handle the error case.
    try {
      // Upload any new files to Firebase, generating an array of download
      // URLs to save on the note object. See the `uploadAttachment` function
      // documentation for more details about how we create resized versions
      // of note image attachments using a Firebase extension.
      const downloadUrls = await Promise.all(
        pendingAttachments.map(async (file) =>
          file instanceof File ? await notesActions.uploadAttachment(file) : file
        )
      );

      note ? await updateNote(downloadUrls) : await createNote(downloadUrls);

      resetFormAndClose();
    } finally {
      setIsPosting(false);
    }
  };

  const pendingNoteGeometry = notesState.pendingNoteGeometryFeature?.geometry;

  // Refocus the text area after editing the pendingNoteGeometry
  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
  React.useEffect(() => {
    textAreaRef.current?.focus();
  }, [pendingNoteGeometry]);

  const renderFooter = (small = true) => {
    const createButton = (
      <B.Button
        small={small}
        intent="primary"
        text={note ? (isPosting ? 'Saving…' : 'Save') : isPosting ? 'Creating…' : 'Create'}
        disabled={(!pendingText && !pendingAttachments.length) || isPosting || hasInvalidLayers}
        onClick={onSubmit}
      />
    );
    return (
      <div className={classnames(cs.footer, cs.formFooter)}>
        {hasInvalidLayers ? (
          <B.Popover
            interactionKind="hover"
            modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
            content={
              <div className={cs.popoverContent}>
                <div>To set a note on a basemap layer, exit compare mode.</div>
              </div>
            }
          >
            {createButton}
          </B.Popover>
        ) : (
          <>{createButton}</>
        )}

        <B.Button
          small={small}
          minimal
          text="Cancel"
          onClick={() => {
            resetFormAndClose();

            canCollapse && setIsExpanded(false);
          }}
        />
      </div>
    );
  };

  const renderTextArea = () => (
    <B.TextArea
      inputRef={(el) => (textAreaRef.current = el)}
      autoFocus={!canCollapse}
      fill
      rows={isExpanded ? 3 : 1}
      className={cs.formTextArea}
      placeholder="Add a note"
      onFocus={() => setIsExpanded(true)}
      onChange={(e) => setPendingText(e.currentTarget.value)}
      value={pendingText}
      growVertically
    />
  );

  if (renderMinimalForAA && !!graph) {
    return (
      <AnalysisNoteForm
        pendingImageRefs={pendingImageRefs}
        includeGraph={includeGraph}
        onSelectImageRefs={onSelectImageRefs}
        setIncludeGraph={setIncludeGraph}
        renderTextArea={renderTextArea}
        renderFooter={renderFooter}
      />
    );
  }

  if (renderMinimal) {
    return <MinimalNoteForm renderTextArea={renderTextArea} renderFooter={renderFooter} />;
  }

  return (
    <div className={classnames(cs.card, {[cs.focusedNote]: isFocused})}>
      <HiddenFileInput
        pendingAttachmentsRef={pendingAttachmentsRef}
        pendingAttachments={pendingAttachments}
        setPendingAttachments={setPendingAttachments}
      />

      <div className={cs.form}>
        {renderTextArea()}

        {/**aggressive null checking on this internal tool-- we are guaranteed to have all of these
         * features when generating this chart, since it is built from a chart saved to a note, but
         * we null check out here in case something is taking a bit to load async and to prevent a
         * ton of null checking in the feature itself.
         */}
        {note && !!note.graph && showRerunChartAnalysisTool && !!featureData && firebaseToken && (
          <RerunChartAnalysisTool
            isPosting={isPosting}
            organization={organization}
            featureData={featureData}
            firebaseToken={firebaseToken}
            note={note}
            saveReanalyzedNoteGraph={saveReanalyzedNoteGraph}
          />
        )}

        {!!note?.graph && !!pendingGraph && (
          <AnalyzePolygonChart
            graph={parseGraphIsoStringsAsDates(note.graph)}
            imageRefs={note.imageRefs}
            areaInM2={noteUtils.getNoteAreaInM2(note)}
            areaUnit={getAreaUnit(organization)}
            onDataRangeModeChange={(nextMode) => {
              setPendingGraph((prevPendingGraph) => {
                if (prevPendingGraph) {
                  const previousMode = prevPendingGraph.graphDataRangeMode || 'none';
                  if (!isEqual(previousMode, nextMode)) {
                    return {...prevPendingGraph, graphDataRangeMode: nextMode};
                  } else {
                    return prevPendingGraph;
                  }
                }
              });
            }}
            onDataRangeChange={(dataRange) => {
              setPendingGraph((prevPendingGraph) => {
                if (prevPendingGraph) {
                  const prevPendingGraphDataRange = prevPendingGraph.graphDataRange;
                  const nextPendingGraphDataRange = dataRange;
                  if (!isEqual(prevPendingGraphDataRange, nextPendingGraphDataRange)) {
                    return {...prevPendingGraph, graphDataRange: nextPendingGraphDataRange};
                  } else {
                    return prevPendingGraph;
                  }
                } else {
                  return prevPendingGraph;
                }
              });
            }}
            onTimeRangeChange={(timeRange) => {
              /** Update the pending graph in state with the new time range
               * formatted as ISO strings, if different. */
              setPendingGraph((prevPendingGraph) => {
                if (prevPendingGraph) {
                  const prevPendingGraphTimeRange = prevPendingGraph.graphTimeRange;
                  const nextPendingGraphTimeRange = parseGraphTimeRangeAsIsoStrings(timeRange);
                  if (!isEqual(prevPendingGraphTimeRange, nextPendingGraphTimeRange)) {
                    return {...prevPendingGraph, graphTimeRange: nextPendingGraphTimeRange};
                  } else {
                    return prevPendingGraph;
                  }
                } else {
                  return prevPendingGraph;
                }
              });
            }}
            onColumnsChange={(dataColumns) => {
              setPendingGraph((prevPendingGraph) => {
                if (prevPendingGraph) {
                  return {...prevPendingGraph, dataColumns: dataColumns};
                }
              });
            }}
            hideLegend={false}
            isLegendInteractive={true}
            controls={[
              <B.Button
                key="remove-graph-btn"
                icon="cross"
                onClick={() => {
                  if (
                    confirm(
                      'Are you sure you want to remove this graph? Graphs cannot be re-added to notes.'
                    )
                  ) {
                    setPendingGraph(null);
                  }
                }}
                small
                title="Remove graph"
              />,
            ]}
            graphStyle={{height: 125}}
            isExpanded={false}
          />
        )}

        {isExpanded && (
          <div className={cs.formAddOns}>
            <div className={cs.formAddOn}>
              <CircleIcon icon="media" />

              {noteUtils.imageRefsArentBasemap(pendingImageRefs) ? (
                <div onClick={() => onSelectImageRefs(pendingImageRefs)}>
                  {noteUtils.formatImageRefs(pendingImageRefs)}
                </div>
              ) : (
                <div className={cs.placeholderText}>No imagery</div>
              )}

              {!autoUpdateLayerAndCursors && (
                <B.Button
                  small
                  minimal
                  icon={<ReplaceLayerIcon />}
                  title="Update note image to match map"
                  disabled={isEqual(imageRefs, pendingImageRefs)}
                  onClick={() => {
                    setPendingImageRefs(imageRefs);
                  }}
                />
              )}
              {noteUtils.imageRefsArentBasemap(pendingImageRefs) ? (
                <B.Popover
                  interactionKind="hover"
                  // Allows popper.js to position the popover outside of the dialog’s
                  // bounds. The `hide: {enabled: false}` bit prevents popper.js from
                  // spamming the console with warnings.
                  modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
                  content={<div className={cs.popoverContent}>Remove layers and dates</div>}
                >
                  <B.Button
                    icon="cross"
                    minimal
                    small
                    onClick={() => {
                      // We want to do slightly different things here if editing a note vs creating a new one
                      const basemapImageRef: mapUtils.MapImageRefs = [
                        {layerKey: layers.NONE, cursor: null},
                      ];
                      if (!autoUpdateLayerAndCursors) {
                        setPendingImageRefs(basemapImageRef);
                      } else {
                        onSelectImageRefs(basemapImageRef);
                      }
                    }}
                  />
                </B.Popover>
              ) : null}
            </div>

            {!disableGeometry && (
              <div className={cs.formAddOn}>
                <CircleIcon icon="geolocation" />

                {pendingNoteGeometry ? (
                  <div
                    className={classnames({[cs.pointer]: zoomToGeometry})}
                    onClick={() => zoomToGeometry && zoomToGeometry(pendingNoteGeometry)}
                  >
                    {geoJsonUtils.makeCoordinateDisplayString(pendingNoteGeometry)}{' '}
                    {(pendingNoteGeometry.type === 'Polygon' ||
                      pendingNoteGeometry.type === 'MultiPolygon') && (
                      <>
                        (
                        {geoJsonUtils.makeAreaDisplayString(
                          pendingNoteGeometry,
                          getAreaUnit(organization)
                        )}
                        )
                      </>
                    )}
                  </div>
                ) : (
                  <div className={cs.placeholderText}>No location</div>
                )}

                {notesState.pendingNoteGeometryFeature?.geometry ? (
                  <B.Tooltip
                    disabled={!note?.graph || !pendingGraph}
                    content={'Cannot remove location from a note with a graph.'}
                  >
                    <B.Button
                      small
                      minimal
                      disabled={!!note?.graph && !!pendingGraph}
                      icon="trash"
                      title="Remove location"
                      onClick={() => notesActions.popPendingNoteGeometry(notesState)}
                    />
                  </B.Tooltip>
                ) : (
                  <B.ButtonGroup>
                    <B.Button
                      small
                      minimal
                      icon="polygon-filter"
                      title="Add map area"
                      active={notesState.addPendingNoteGeometryMode === 'polygon'}
                      onClick={() => notesActions.startNewPendingGeometry('polygon')}
                    />
                    <B.Button
                      small
                      minimal
                      icon="widget"
                      title="Add rectangle map area"
                      active={notesState.addPendingNoteGeometryMode === 'rect'}
                      onClick={() => notesActions.startNewPendingGeometry('rect')}
                    />
                    <B.Button
                      small
                      minimal
                      icon="map-marker"
                      title="Add map point"
                      active={notesState.addPendingNoteGeometryMode === 'point'}
                      onClick={() => notesActions.startNewPendingGeometry('point')}
                    />
                    <B.Button
                      small
                      minimal
                      icon="numerical"
                      title="Enter coordinates manually"
                      onClick={promptForCoordinates}
                    />
                  </B.ButtonGroup>
                )}
              </div>
            )}

            {!disableGeometry && !!notesState.addPendingNoteGeometryMode && (
              <div className={cs.formAddOn}>
                {/** Placeholder so the callout is flush with the location text. */}
                <CircleIcon icon={null} />

                <B.Callout className={cs.formCallout} intent="warning" icon={null}>
                  {notesState.pendingNoteGeometryFeature
                    ? notesState.addPendingNoteGeometryMode === 'point'
                      ? 'Click on the map to update the point location.'
                      : `Click and drag the ${
                          notesState.addPendingNoteGeometryMode === 'rect' ? 'rectangle' : 'polygon'
                        } to update the area location.`
                    : notesState.addPendingNoteGeometryMode === 'point'
                      ? 'Click on the map to add a point location.'
                      : `Click on the map to start adding an area location. ${
                          notesState.addPendingNoteGeometryMode === 'polygon'
                            ? 'Double-click to complete it.'
                            : ''
                        }`}
                </B.Callout>
              </div>
            )}

            <div className={cs.formAddOn}>
              <CircleIcon icon="paperclip" />

              {pendingAttachments.length ? (
                <div>
                  {pendingAttachments.length}{' '}
                  {pendingAttachments.length > 1 ? 'attachments' : 'attachment'}
                </div>
              ) : (
                <div className={cs.placeholderText}>No attachments</div>
              )}

              <B.Button
                small
                minimal
                icon="plus"
                title={`Add up to ${MAXIMUM_ATTACHMENT_COUNT} attachments`}
                disabled={pendingAttachments.length >= MAXIMUM_ATTACHMENT_COUNT}
                onClick={() => pendingAttachmentsRef.current?.click()}
              />
            </div>

            {!!pendingAttachments.length && (
              <div className={cs.formAddOn}>
                <CircleIcon icon={null} />

                <div className={cs.formAttachments}>
                  {pendingAttachments.map((attachment, i) => (
                    <B.Tag
                      key={i}
                      round
                      minimal
                      // TODO: We should also delete files from Google Cloud
                      // Storage when they are removed from notes.
                      onRemove={() =>
                        setPendingAttachments((prevPendingAttachments) =>
                          prevPendingAttachments.filter((_, idx) => idx !== i)
                        )
                      }
                    >
                      {attachment instanceof File
                        ? attachment.name
                        : firebaseUtils.getFileNameFromObjectDownloadUrl(attachment)}
                    </B.Tag>
                  ))}
                </div>
              </div>
            )}

            <div className={cs.formAddOn}>
              <CircleIcon icon="tag" />

              {activeTags.size ? (
                <div>
                  <>
                    {activeTags.map((tag) => (
                      <tagUtils.Tag key={tag!.get('id')} setting={tag!} />
                    ))}
                  </>
                </div>
              ) : (
                <div className={cs.placeholderText}>No Note Tags</div>
              )}

              {(profile.get('role') === 'owner' || profile.get('role') === 'regular') && (
                <EditTagsMenu
                  featureCollectionId={featureCollection.get('id')}
                  tagSettings={tagSettings}
                  tagStatusMaps={
                    I.List([toImmutableMap(pendingTagIds || {})]) as I.ImmutableOf<
                      tagUtils.TagStatusMap[]
                    >
                  }
                  onChange={(newTagIds) => {
                    // newTagIds only contains the newly selected/deselected tagIds
                    // so we merge it with the currently selected tagIds
                    setPendingTagIds({...pendingTagIds, ...newTagIds});
                  }}
                  openCustomizeTagsModal={openCustomizeTagsModal}
                  arrow
                  kind={tagUtils.TAG_KIND.NOTE}
                >
                  <B.Button
                    icon={<B.Icon icon="more" />}
                    minimal={true}
                    small
                    title="Edit Note Tags"
                  />
                </EditTagsMenu>
              )}
              {isCustomizeTagsModalVisible && !renderMinimal && (
                <CustomizeTagsModal
                  featureCollection={featureCollection}
                  updateFeatureCollection={updateFeatureCollection}
                  onClose={() => setIsCustomizeTagsModalVisible(false)}
                  kind={tagUtils.TAG_KIND.NOTE}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {isExpanded && renderFooter()}
    </div>
  );
};

/**
 * Renders a hidden multifile input that validates the number of selected files
 * against the maximum count and updates `pendingAttachments` state.
 */
const HiddenFileInput: React.FunctionComponent<
  React.PropsWithChildren<{
    pendingAttachmentsRef: React.RefObject<HTMLInputElement>;
    pendingAttachments: (File | string)[];
    setPendingAttachments: React.Dispatch<React.SetStateAction<(string | File)[]>>;
  }>
> = ({pendingAttachmentsRef, pendingAttachments, setPendingAttachments}) => (
  <input
    accept="image/*, .pdf, .csv"
    style={{display: 'none'}}
    id="file"
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      const newFiles = e.currentTarget.files;

      if (newFiles) {
        const newFilesArr = Array.from(newFiles);

        if ([...pendingAttachments, ...newFilesArr].length > MAXIMUM_ATTACHMENT_COUNT) {
          return alert(
            `You can only attach a maximum of ${MAXIMUM_ATTACHMENT_COUNT} files to a single note.`
          );
        }

        setPendingAttachments((prevPendingAttachments) => [
          ...prevPendingAttachments,
          ...newFilesArr,
        ]);
      }
    }}
    ref={pendingAttachmentsRef}
    // Passing in an empty string as the value allows us to re-select the same
    // file, triggering the onChange event each time.
    value=""
    type="file"
    multiple
  />
);

/**
 * Renders a stylized Blueprint icon with a light blue background and bright
 * blue SVG fill.
 */
const CircleIcon: React.FunctionComponent<
  React.PropsWithChildren<{
    icon: B.IconName | B.MaybeElement;
  }>
> = ({icon}) => {
  // If we aren't rendering a icon, make the background completely transparent.
  // This allow us to add blank placeholders on lines that don't have an icon
  // (e.g., danger callout when layer is unchecked).
  const backgroundColor = icon ? undefined : 'transparent';

  return (
    <div
      className={cs.circleIcon}
      style={{height: 24, width: 24, borderRadius: 12, backgroundColor}}
    >
      <B.Icon intent="primary" icon={icon} iconSize={12} />
    </div>
  );
};

export default NoteForm;

const AnalysisNoteForm: React.FC<{
  pendingImageRefs: mapUtils.MapImageRefs;
  includeGraph: boolean;
  setIncludeGraph: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectImageRefs: (imageRefs: mapUtils.MapImageRefs) => unknown;
  renderTextArea: () => React.JSX.Element;
  renderFooter: (boolean) => React.JSX.Element;
}> = ({
  pendingImageRefs,
  includeGraph,
  onSelectImageRefs,
  setIncludeGraph,
  renderTextArea,
  renderFooter,
}) => {
  return (
    <div className={cs.minimalAa}>
      <div className={cs.form}>
        {renderTextArea()}
        <div className={cs.formAddOns}>
          <div className={cs.formAddOn}>
            <CircleIcon icon="media" />

            {noteUtils.imageRefsArentBasemap(pendingImageRefs) ? (
              <div onClick={() => onSelectImageRefs(pendingImageRefs)}>
                {noteUtils.formatImageRefs(pendingImageRefs)}
              </div>
            ) : (
              <div className={cs.placeholderText}>No imagery</div>
            )}
          </div>
          <div
            className={cs.formAddOn}
            style={{cursor: 'pointer', userSelect: 'none'}}
            onClick={() => setIncludeGraph((prev) => !prev)}
          >
            <B.Checkbox
              className={cs.formAddOnCheckbox}
              checked={includeGraph}
              onChange={() => setIncludeGraph((prev) => !prev)}
            />

            <div>Include chart in note</div>
          </div>
        </div>
      </div>
      {renderFooter(false)}
    </div>
  );
};

/** A version of NoteForm that renders just the text input and submit/cancel buttons. */
const MinimalNoteForm: React.FC<{
  renderTextArea: () => React.JSX.Element;
  renderFooter: (boolean) => React.JSX.Element;
}> = ({renderTextArea, renderFooter}) => {
  return (
    <div className={cs.minimal}>
      <div>{renderTextArea()}</div>
      <div className={cs.footer}>{renderFooter(false)}</div>
    </div>
  );
};
