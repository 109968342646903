import rewind from '@mapbox/geojson-rewind';
import * as turf from '@turf/turf';
import {
  ExtendedFeature,
  ExtendedFeatureCollection,
  GeoGeometryObjects,
  GeoPath,
  GeoPermissableObjects,
  geoMercator,
  geoPath,
} from 'd3-geo';
import geojson from 'geojson';
import * as I from 'immutable';
import {chunk, flatten, groupBy} from 'lodash';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {getAreaUnit} from 'app/modules/Remote/Organization';
import COLORS from 'app/styles/colors.json';
import * as CONSTANTS from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {UTMArea} from 'app/utils/geoJsonUtils';
import * as layerUtils from 'app/utils/layerUtils';
import loadTiledFeatureCollectionAsGeoJSON from 'app/utils/mapUtils/loadTileAsGeoJSON';
import {MultiFeaturePropertyParts} from 'app/utils/multiFeaturePropertyUtils';

import {LoadingSpinner} from '../AnalyzePolygonChart/AnalyzePolygonChart';
import GlobalErrorBoundary from '../ErrorBoundaries';
import LocationSvg from './LocationSvg';
import {ReportExportTitlePage} from './ReportExportTitlePage';
import {ReportExportToolbar} from './ReportExportToolbar';
import {PrintWindowStructure, ReportError} from '../ReportExport/ReportExportWindow';

export const ParcelReportWindow: React.FunctionComponent<
  React.PropsWithChildren<{
    firebaseToken: string;
    organization: I.ImmutableOf<ApiOrganization>;
    projectId: string;
    feature: I.ImmutableOf<ApiFeature>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
    multiFeaturePropertyParts: MultiFeaturePropertyParts;
  }>
> = ({
  firebaseToken,
  organization,
  projectId,
  feature,
  featureCollection,
  overlayFeatureCollections,
  multiFeaturePropertyParts,
}) => {
  const enrolledLayerKeys = layerUtils.getEnrolledLayerKeysForFeatureCollection(featureCollection);
  multiFeaturePropertyParts = multiFeaturePropertyParts.map((p) => ({...p, isActive: false}));

  return (
    <ParcelReportContent
      firebaseToken={firebaseToken}
      organization={organization}
      projectId={projectId}
      feature={feature}
      overlayFeatureCollections={overlayFeatureCollections}
      propertyParts={multiFeaturePropertyParts}
      enrolledLayerKeys={enrolledLayerKeys}
    />
  );
};

const MAX_PARCELS_PER_PAGE = 16;

const ParcelReportContent: React.FunctionComponent<
  React.PropsWithChildren<{
    firebaseToken: string;
    organization: I.ImmutableOf<ApiOrganization>;
    projectId: string;
    feature: I.ImmutableOf<ApiFeature>;
    overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
    propertyParts: MultiFeaturePropertyParts;
    enrolledLayerKeys: string[];
  }>
> = ({
  firebaseToken,
  organization,
  projectId,
  feature,
  overlayFeatureCollections,
  propertyParts,
}) => {
  const [parcelFeatures, setParcelFeatures] = React.useState<geojson.Feature[] | null>(null);

  const parcelFc = overlayFeatureCollections.find(
    (fc) => fc?.get('id') === CONSTANTS.REGRID_FEATURE_COLLECTION_ID
  )!;
  const featureJS = React.useMemo(() => feature.toJS(), [feature]);

  let featureName = propertyParts[0].feature.getIn(['properties', 'name']);
  const activePropertyPart = propertyParts.find((p) => p.isActive);
  const isMultiPartProperty = propertyParts.length > 1;

  if (isMultiPartProperty && activePropertyPart) {
    featureName = `${featureName}, ${activePropertyPart.partName}`;
  }

  React.useEffect(() => {
    async function fetch() {
      const fc = await loadTiledFeatureCollectionAsGeoJSON(parcelFc, featureJS.geometry, {
        maxZoom: 12,
      });
      if (fc) {
        const dedupeIndex = new Set<string>();
        const intersectionFeature = turf.simplify(featureJS);
        const features = fc.features
          .reduce((acc: geojson.Feature[], f: geojson.Feature) => {
            if (dedupeIndex.has(_getParcelNumber(f))) {
              return acc;
            } else {
              dedupeIndex.add(_getParcelNumber(f));
              return [...acc, f];
            }
          }, [])
          .filter((f) => turf.booleanIntersects(intersectionFeature, f))
          .map((f, i) => {
            const a = conversionUtils.convert(
              UTMArea(f),
              CONSTANTS.UNIT_AREA_M2,
              getAreaUnit(organization)
            );

            const aUnits = getAreaUnit(organization) === 'areaHectare' ? 'hectares' : 'acres';
            let aStr: string;
            if (a >= 2) {
              aStr = conversionUtils.numberWithCommas(Math.round(a));
            } else {
              aStr = a.toFixed(2);
            }

            return {
              ...f,
              properties: {
                ...f.properties,
                label: _numToAlphaIndex(i),
                areaText: `${aStr} ${aUnits}`,
              },
            };
          });
        setParcelFeatures(features);
      }
    }

    if (!parcelFeatures) {
      fetch();
    }
  }, [parcelFc, featureJS, organization, parcelFeatures]);

  const parcelClusters = React.useMemo(() => {
    if (!parcelFeatures || !parcelFeatures.length) {
      return;
    }
    let parcelClusters: Record<string, geojson.Feature[]> | null = null;
    const clusterById = turf
      .clustersKmeans(
        {
          type: 'FeatureCollection',
          features: parcelFeatures.map((f) => {
            const centerF = turf.centerOfMass(f);
            return {...centerF, properties: {...centerF.properties, ...f.properties}};
          }),
        },
        {numberOfClusters: Math.ceil(parcelFeatures.length / MAX_PARCELS_PER_PAGE)}
      )
      .features.reduce((acc, f) => ({...acc, [_getParcelNumber(f)]: f.properties['cluster']}), {});
    parcelClusters = groupBy(parcelFeatures, (f) => clusterById[_getParcelNumber(f)]);

    // manually split any clusters with more than the max
    return Object.keys(parcelClusters).reduce<Record<string, geojson.Feature[]>>(
      (acc, clusterId) => {
        if (!parcelClusters) {
          return acc;
        }
        if (parcelClusters[clusterId].length > MAX_PARCELS_PER_PAGE) {
          const chunks = chunk(parcelClusters[clusterId], MAX_PARCELS_PER_PAGE);
          const newClusters = {};
          chunks.forEach((fs, i) => {
            newClusters[`${clusterId}-${i}`] = fs;
          });
          return {...acc, ...newClusters};
        } else {
          return {...acc, [clusterId]: parcelClusters[clusterId]};
        }
      },
      {}
    );
  }, [parcelFeatures]);

  const reportTitle = `${featureName} Parcel Report`;

  return (
    <PrintWindowStructure
      title={reportTitle}
      fontsToCheck={[]}
      onFontsReady={() => {}}
      onFontsTimeout={() => {}}
    >
      <GlobalErrorBoundary errorContent={<ReportError />}>
        {parcelFeatures ? (
          parcelFeatures.length === 0 || parcelFeatures.length > 1000 ? (
            _renderCountError(feature, parcelFeatures.length)
          ) : (
            <>
              <ReportExportToolbar areMapsReady={!!parcelClusters} reportTitle={reportTitle} />
              <ReportExportTitlePage
                organization={organization}
                firebaseToken={firebaseToken}
                projectId={projectId}
                feature={feature}
                propertyParts={propertyParts}
                isMultiPartProperty={propertyParts.length > 1}
                isCombinedMultiPart={propertyParts.length > 1}
                defaultReportSubtitle="Parcel Owner Report"
              />
              <section className="overview-page">
                <h2>Overview</h2>
                {parcelFeatures && (
                  <>
                    <Map
                      feature={featureJS}
                      parcelFeatures={parcelFeatures}
                      widthInches={7.5}
                      maxHeightInches={8}
                      aspectRatio={4 / 3}
                      fitMode="both"
                    />
                    <div style={{textAlign: 'center', marginTop: 20}}>
                      {parcelFeatures.length} parcel{parcelFeatures.length > 1 ? 's' : ''} overlap{' '}
                      <span style={{fontWeight: 700}}>{featureJS.properties!['name']}</span>.
                    </div>
                  </>
                )}
              </section>
              {parcelClusters &&
                Object.keys(parcelClusters).map((clusterIndex) => {
                  const parcels = parcelClusters[clusterIndex];
                  return (
                    <section key={clusterIndex} className="overview-page">
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: 'inherit',
                        }}
                      >
                        <LocationSvg
                          feature={featureJS}
                          activeGeometry={
                            geoJsonUtils.bboxPolygon(
                              turf.bbox({type: 'FeatureCollection', features: parcels})
                            ).geometry
                          }
                          activeGeometryStyle={{fill: 'rgba(0,76,92,0.3)', stroke: '#004C5C'}}
                          height={1.5 * WEB_PPI}
                          width={3 * WEB_PPI}
                          alignment="top-right"
                        />
                      </div>
                      <div>
                        <Map
                          feature={featureJS}
                          parcelFeatures={parcels}
                          widthInches={6}
                          maxHeightInches={3}
                          aspectRatio={2}
                          fitMode="parcels"
                          labelParcels={true}
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                          marginTop: 40,
                          display: 'grid',
                          gridTemplateColumns: '25% 25% 25% 25%',
                          gridGap: '20px 10px',
                          fontSize: 11,
                        }}
                      >
                        {parcels.map((f, i) => (
                          <div key={i}>
                            <div style={{fontWeight: 800, marginBottom: 10}}>
                              {_numToAlphaIndex(i + 1)}
                            </div>
                            <div>{_getParcelNumber(f)}</div>
                            <div>{_getOwner(f)}</div>
                            <div style={{fontFamily: 'monospace', marginTop: 10}}>
                              <div>{_getAddress(f)}</div>
                              <div>{_getCityAndState(f)}</div>
                              <div>{_getZip(f)}</div>
                              <div style={{marginTop: 10}}>{_getArea(f)}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </section>
                  );
                })}

              {parcelClusters &&
                chunk(flatten(Object.values(parcelClusters)), 15).map((parcels, i, arr) => {
                  return (
                    <section className="overview-page" key={i}>
                      <h2>
                        Table ({i + 1} of {arr.length})
                      </h2>
                      <table style={{width: '100%', textAlign: 'left'}}>
                        <thead>
                          <tr>
                            <th scope="col" style={{width: '20%'}}>
                              Parcel Number
                            </th>
                            <th scope="col" style={{width: '35%'}}>
                              Owner
                            </th>
                            <th scope="col" style={{width: '30%'}}>
                              Address
                            </th>
                            <th scope="col" style={{width: '15%'}}>
                              Area
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {parcels.map((f, i) => (
                            <tr key={i}>
                              <td>{_getParcelNumber(f)}</td>
                              <td>{_getOwner(f)}</td>
                              <td>
                                <div style={{fontFamily: 'monospace', marginTop: 10}}>
                                  <div>{_getAddress(f)}</div>
                                  <div>{_getCityAndState(f)}</div>
                                  <div>{_getZip(f)}</div>
                                </div>
                              </td>
                              <td>{_getArea(f)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </section>
                  );
                })}
            </>
          )
        ) : (
          <LoadingSpinner />
        )}
      </GlobalErrorBoundary>
    </PrintWindowStructure>
  );
};

function _renderCountError(feature: I.ImmutableOf<ApiFeature>, count: number) {
  const featureName = feature.getIn(['properties', 'name']);
  let err = `There was an error creating the parcel report for ${featureName}`;
  if (count === 0) {
    err = `Unable to create report. There are no parcels overlapping ${featureName}.`;
  } else if (count > 1000) {
    err = `Unable to create report. There are over 1000 parcels overlapping ${featureName}.`;
  }

  return <div style={{textAlign: 'center'}}>{err}</div>;
}

interface MapProps {
  feature: geojson.Feature;
  parcelFeatures: geojson.Feature[];
  widthInches: number;
  maxHeightInches: number;
  aspectRatio: number | 'auto';
  fitMode?: 'both' | 'parcels' | 'property';
  labelParcels?: boolean;
}
const WEB_PPI = 96;
const ZOOM_PADDING = 10;
const Map: React.FunctionComponent<React.PropsWithChildren<MapProps>> = ({
  feature,
  parcelFeatures,
  widthInches,
  maxHeightInches,
  aspectRatio = 4 / 3,
  fitMode = 'property',
  labelParcels = false,
}) => {
  const geometryBounds = turf.bbox(turf.toMercator(feature));
  const numericAspectRatio =
    aspectRatio === 'auto'
      ? Math.abs((geometryBounds[0] - geometryBounds[2]) / (geometryBounds[1] - geometryBounds[3]))
      : aspectRatio;

  const mapWidth = widthInches * WEB_PPI;

  const mapStyle: React.CSSProperties = {
    // We always go the full width of the page. The question is how tall the
    // image is, to fit the aspect ratio of the property.
    width: mapWidth,
    height: Math.min(
      maxHeightInches * WEB_PPI,
      (mapWidth - 2 * ZOOM_PADDING) / numericAspectRatio + 2 * ZOOM_PADDING
    ),
  };

  const makePath = React.useMemo<GeoPath<any, GeoPermissableObjects>>(() => {
    let fitFeatures: geojson.Feature[];
    if (fitMode === 'both') {
      fitFeatures = [...parcelFeatures, feature];
    } else if (fitMode === 'parcels') {
      fitFeatures = parcelFeatures;
    } else {
      fitFeatures = [feature];
    }

    return geoPath(
      geoMercator().fitSize([mapStyle.width, mapStyle.height], {
        type: 'FeatureCollection',
        features: fitFeatures,
      })
    );
  }, [parcelFeatures, feature, mapStyle, fitMode]);

  return (
    <div className="map-container">
      <svg style={mapStyle}>
        <FeaturePaths
          features={parcelFeatures}
          height={mapStyle.height as number}
          width={mapStyle.width as number}
          pathProps={{stroke: COLORS.gray, fill: 'none'}}
          makePath={makePath}
          showLabel={labelParcels}
          labelProps={{
            fontWeight: 800,
            fontSize: 10,
          }}
        />
        <FeaturePaths
          features={[feature]}
          height={mapStyle.height as number}
          width={mapStyle.width as number}
          needsRewind={true}
          pathProps={{stroke: COLORS.primary, fill: 'none'}}
          makePath={makePath}
        />
      </svg>
    </div>
  );
};

const FeaturePaths: React.FunctionComponent<
  React.PropsWithChildren<{
    features: geojson.Feature[];
    width: number;
    height: number;
    pathProps: React.SVGProps<SVGPathElement>;
    labelProps?: React.SVGProps<SVGTextElement>;
    showLabel?: boolean;
    needsRewind?: boolean;
    makePath?: GeoPath<any, GeoPermissableObjects>;
  }>
> = ({
  features,
  width,
  height,
  pathProps,
  makePath,
  labelProps = {},
  showLabel = false,
  needsRewind = false,
}) => {
  const pathsAndLabelPoints = React.useMemo((): [string, [number, number]][] => {
    // use this to keep track of locations we've set as label coordinates so far to perturb duplicates to avoid overlap
    const countByLabelPointString: Record<string, number> = {};

    const fs = features.map((f, _) => ({
      ...f,
      geometry: needsRewind ? (rewind(f.geometry, true) as GeoGeometryObjects) : f.geometry,
    }));
    const fc = {
      type: 'FeatureCollection',
      features: fs as ExtendedFeature[],
    } as ExtendedFeatureCollection;
    const makePathLocal = makePath ? makePath : geoPath(geoMercator().fitSize([width, height], fc));
    const pathsAndLabelPoints: [string, [number, number]][] = [];
    fs.forEach((f) => {
      const path = makePathLocal(f)!;
      const labelPoint = makePathLocal.centroid(f)!;
      const countAtPoint = countByLabelPointString[labelPoint.toString()];
      countByLabelPointString[labelPoint.toString()] = (countAtPoint || 0) + 1;
      pathsAndLabelPoints.push([path, _withOffset(labelPoint, countAtPoint)]);
    });

    return pathsAndLabelPoints;
  }, [features, height, width, needsRewind, makePath]);

  return (
    <g>
      {pathsAndLabelPoints.map(([p, c], i) => (
        <g key={i}>
          <path d={p} {...pathProps} />
          {showLabel && (
            <text x={c[0]} y={c[1]} {...labelProps} textAnchor="middle" dominantBaseline="middle">
              {_numToAlphaIndex(i + 1)}
            </text>
          )}
        </g>
      ))}
    </g>
  );
};

function _withOffset(labelPoint: [number, number], count: number): [number, number] {
  if (count > 0) {
    // draw cicles of 8 each
    return [
      labelPoint[0] + 12 * Math.ceil(count / 8) * Math.cos(2 * Math.PI * (count / 8)),
      labelPoint[1] + 12 * Math.ceil(count / 8) * Math.sin(2 * Math.PI * (count / 8)),
    ];
  }
  return labelPoint;
}

const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
function _numToAlphaIndex(n: number, acc = ''): string {
  let charIndex = n % ALPHA.length;
  let quotient = n / ALPHA.length;
  if (charIndex - 1 === -1) {
    charIndex = ALPHA.length;
    quotient--;
  }
  acc = ALPHA.charAt(charIndex - 1) + acc;
  if (quotient >= 1) {
    return _numToAlphaIndex(quotient, acc);
  }
  return acc;
}

// property accessors
function _getParcelNumber(f: geojson.Feature): string {
  return _getPropertyString(f, 'parcelnumb');
}

function _getOwner(f: geojson.Feature): string {
  return _getPropertyString(f, 'owner') || 'Unknown owner';
}

function _getAddress(f: geojson.Feature): string {
  return _getPropertyString(f, 'address') || 'Unknown address';
}

function _getCityAndState(f: geojson.Feature): string {
  let str = '';
  if (_getPropertyString(f, 'scity')) {
    str += _getPropertyString(f, 'scity') + ',';
  }
  str += '\n' + _getPropertyString(f, 'state2');
  return str;
}

function _getZip(f: geojson.Feature): string {
  return _getPropertyString(f, 'szip');
}

function _getArea(f: geojson.Feature): string {
  return _getPropertyString(f, 'areaText');
}

function _getPropertyString(f: geojson.Feature, property: string): string {
  if (f.properties) {
    return f.properties[property];
  }
  return '';
}
