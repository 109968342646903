import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import {bbox} from '@turf/turf';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';
import {StringParam, useQueryParam} from 'use-query-params';

import ManageLayersDialog from 'app/components/DeclarativeMap/ManageLayersDialog';
import {api} from 'app/modules/Remote';
import {ApiPublicLensResponse} from 'app/modules/Remote/api';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, GeometryOverlaySetting} from 'app/modules/Remote/FeatureCollection';
import {MapInteractionProvider} from 'app/pages/MonitorProjectView/Map';
import {OVERLAY_POPUP_INSET} from 'app/pages/MonitorProjectView/utils';
import {useGeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {DEFAULT_NOTES_STATE, NotesActions, StateApiNote} from 'app/stores/NotesStore';
import {HideHubspotWidget} from 'app/tools/Hubspot';
import {useWindowDimensions} from 'app/utils/domUtils';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import {getFileNameFromObjectDownloadUrl} from 'app/utils/firebaseUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {UTMArea} from 'app/utils/geoJsonUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {DEFAULT_COLORS} from '../../pages/MonitorProjectView/SwatchColorPicker';
import ConnectedMap from '../ConnectedMap';
import cs from './styles.styl';
import AnalyzePolygonChart from '../AnalyzePolygonChart/AnalyzePolygonChart';
import {parseGraphIsoStringsAsDates} from '../AnalyzePolygonChart/utils';
import {RenderMapCallbackOpts} from '../ConnectedMap/view';
import DraggableLegend from '../DeclarativeMap/DraggableLegend';
import FeatureCollectionVector from '../DeclarativeMap/FeatureCollectionVector';
import FeatureRaster from '../DeclarativeMap/FeatureRaster';
import GeoJsonFitter from '../DeclarativeMap/GeoJsonFitter';
import GeometryOverlaysContent from '../DeclarativeMap/GeometryOverlaysContent';
import ManageBasemapDialog from '../DeclarativeMap/ManageBasemapDialog';
import MapFullscreenControl from '../DeclarativeMap/MapFullscreenControl';
import MapSettingsControl from '../DeclarativeMap/MapSettingsControl';
import MapStyle from '../DeclarativeMap/MapStyle';
import MapTilesLoadingControl from '../DeclarativeMap/MapTilesLoadingControl';
import NoteVector from '../DeclarativeMap/NoteVector';
import TerrainControl from '../DeclarativeMap/TerrainControl';
import ZoomCenterControl from '../DeclarativeMap/ZoomCenterControl';
import ErrorPage from '../Error/ErrorPage';
import Loading from '../Loading';
import LensLogo from '../Logo/LensLogo';
import DraggableMapOverlayDialog from '../MapOverlayDialog/DraggableMapOverlayDialog';
import ResizedImage from '../ResizedImage';

interface Props {
  uuid: string;
}

export interface ShareLinkScene {
  sensing_time: string;
  source_id: string;
  layer_id: string;
}

type PublicMapTool = 'manageLayers' | 'manageBasemap';
type PublicErrorTypes = 'unavailable' | 'transient' | null;

const DESCRIPTION_X_PADDING = 15;
const DESCRIPTION_Y_PADDING = 15;
const DESCRIPTION_DEFAULT_WIDTH = 500;

const PublicMap: React.FunctionComponent<React.PropsWithChildren<Props>> = ({uuid}) => {
  // Fetched State
  // TODO: these don't need to be all stored as seperate pieces of state
  const [features, setFeatures] = React.useState<I.ImmutableOf<ApiFeature[]> | null>(null);
  const [scenes, setScenes] = React.useState<I.ImmutableOf<ShareLinkScene[]> | null>(null);
  const [data, setData] = React.useState<I.ImmutableOf<ApiFeatureData[]> | null>(null);
  const [overlayFeatureCollections, setOverlayFeatureCollections] = React.useState<I.ImmutableOf<
    ApiFeatureCollection[]
  > | null>(null);
  const [shareLinkConfig, setShareLinkConfig] = React.useState<ApiPublicLensResponse | null>(null);
  const [cameraOptions, setCameraOptions] =
    React.useState<I.ImmutableOf<mapUtils.CameraOptions> | null>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorState, setErrorState] = React.useState<PublicErrorTypes>(null);
  const [isOverlayVisibleByName, setOverlayVisibileByName] = React.useState<
    Record<string, boolean>
  >({});
  const setOverlayVisibility = React.useCallback(
    (setting: Record<string, boolean>): void => {
      setOverlayVisibileByName({...isOverlayVisibleByName, ...setting});
    },
    [isOverlayVisibleByName, setOverlayVisibileByName]
  );

  // Internal State
  const [overlaySettings, setOverlaySettings] = React.useState<GeometryOverlaySetting[]>([]);
  const [mapStyle, setMapStyle] = React.useState<mapUtils.MapStyle>(mapUtils.MAPBOX_DARK_STYLE);
  const [manageLayersEl, setManageLayersEl] = React.useState<HTMLDivElement | null>(null);
  const [activeTool, setActiveTool] = React.useState<PublicMapTool | null>(null);
  const [showTerrain, setShowTerrain] = React.useState(false);

  const windowDimensions = useWindowDimensions();

  // Query param settings
  const embedded = useQueryParam('embedded', StringParam)[0];
  const isEmbedded = embedded === 'true';

  React.useEffect(() => {
    const fetchFeatures = async () => {
      try {
        setIsLoading(true);
        const featureResponse = await api.public(uuid);
        setFeatures(featureResponse.getIn(['data', 'features']));
        setData(featureResponse.getIn(['data', 'data']));
        setScenes(featureResponse.getIn(['data', 'scenes']));
        setOverlayFeatureCollections(
          featureResponse.getIn(['data', 'overlay_feature_collections'])
        );
        setShareLinkConfig(featureResponse.get('data').toJS());

        const responseCameraOptions = featureResponse.getIn(['data', 'mapOptions']);
        if (responseCameraOptions?.get('pitch')) {
          setShowTerrain(true);
        }
        setCameraOptions(responseCameraOptions);
      } catch (e) {
        /*
          Some extra error handling, since this is a public page.
          The API tends to return 4XX series errors to communicate that no resource exists for the request,
          which in this case means a share link is no longer available or 'no longer linked'.
          We'll get 500 series errors if there's an issue with the server, which is either a bug or a transient error.
        */
        const {error, status} = e as {error: Error; status: number; shouldRetry: boolean};
        if (400 <= status && status <= 499) {
          setErrorState('unavailable');
        } else if (status >= 500) {
          setErrorState('transient');
          Sentry.captureException(error);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchFeatures();
  }, [uuid]);

  const position =
    shareLinkConfig && geoJsonUtils.makeCoordinateDisplayString(shareLinkConfig.note_geometry, 3);
  const areaAcres =
    shareLinkConfig &&
    geoJsonUtils.makeAreaDisplayString(shareLinkConfig.note_geometry, 'areaAcre');

  const mapBounds = React.useMemo(() => {
    if (!features || features.isEmpty()) {
      return null;
    } else {
      // as any because there’s some disconnect between the geojson types and
      // the turf types around GeometryCollection.
      return bbox(geoJsonUtils.featureCollection(features.toJS()) as any) as geoJsonUtils.BBox2d;
    }
  }, [features]);

  // This is similar to MonitorProjectView/Map.tsx, L170
  //  Consider consolidating this if this value changes.
  const fitBoundsOptions = {
    padding: mapUtils.insetPadding(
      mapUtils.insetPadding({top: 0, right: 0, bottom: 0, left: 0}, [
        30,
        40,
        30,
        manageLayersEl ? manageLayersEl.clientWidth + OVERLAY_POPUP_INSET : 0,
      ]),
      10
    ),
    maxZoom: 18,
    duration: 0,
  };

  const geoJsonFeaturesLoader = useGeoJsonFeaturesLoader();

  // Manage state updates
  React.useEffect(() => {
    if (!overlayFeatureCollections) {
      return;
    }

    const overlayVisibilityByName = overlayFeatureCollections
      .toJS()
      .reduce((acc, fc) => ((acc[fc.name] = true), acc), {});

    const overlaySettings = overlayFeatureCollections.toJS().map((fc, idx) => ({
      name: fc.name,
      defaultEnabled: true,
      color: DEFAULT_COLORS[idx! % DEFAULT_COLORS.length],
      status: 'complete',
    }));

    // Will state if this reruns.
    setOverlayVisibileByName(overlayVisibilityByName);
    setOverlaySettings(overlaySettings);
  }, [overlayFeatureCollections]);

  const imageRefs: mapUtils.MapImageRefs = scenes?.size
    ? scenes
        .map((scene) => ({
          layerKey: layerUtils.getLayerKeyFromSourceAndLayer(
            scene!.get('source_id'),
            scene!.get('layer_id')
          ),
          cursor: scene!.get('sensing_time'),
        }))
        .toJS()
    : [
        {
          layerKey: 'S2_NDVI',
          cursor: null,
        },
      ];

  const items: JSX.Element[] = [
    <LensLogo
      key={0}
      noText={windowDimensions.width < 610}
      className={cs.lensLogo}
      onClick={() => window.open('https://www.upstream.tech/lens', '_blank')}
    />,
    <div key={0} className={cs.divider} />, // add a vertical divider between logo and rest of info
  ];
  if (features && features.size === 1) {
    items.push(
      <div key={1} className={cs.propertyName}>
        {features.first().getIn(['properties', 'name'])}
      </div>
    );
  }
  if (scenes && data) {
    scenes.forEach((scene) => {
      const featureData = data.find((d) => d!.get('date') === scene!.get('sensing_time'));
      const layerKey = layerUtils.getLayerKeyFromSourceAndLayer(
        scene!.get('source_id'),
        scene!.get('layer_id')
      );
      const layer = layerUtils.getLayer(layerKey);
      const details = featureUtils.imageAndSourceDetails(featureData, layerKey!, I.fromJS({}));
      const layerDate = imageryUtils.formatDate(moment(scene?.get('sensing_time')), layerKey);

      // Odd spacing around the scene divider character since we want this to become just a space at lower breakpoints
      const sceneType = (
        <>
          <span>{layer.shortName}</span> <span className={cs.sceneDividerCharacter}>| </span>
          <span>{layerDate}</span>
        </>
      );
      // If there's no resolution available, don't display it. This can happen for WMTS sources.
      const resolution = details.sourceDetails.resolution
        ? ` (${details.sourceDetails.resolution}m)`
        : '';
      const sceneDetails = `${details.sourceDetails.operator} ${details.sourceDetails.name}${resolution}`;

      items.push(
        <React.Fragment key={`${layer.key}-${layerDate}`}>
          <div className={cs.divider} />
          <div className={cs.sceneDetails}>
            <div className={cs.sceneDetailsType}>{sceneType}</div>
            <div className={cs.sceneDetailsName}>{sceneDetails}</div>
          </div>
        </React.Fragment>
      );
    });
  }

  /*
    We want to track mouse overs here to enable or disable scroll behavior in an attempt to
    prevent a user from accidentally zooming an embedded map as they traverse a page. The
    idea here is that if a scroll event happens before we mouse over an embedded map, the
    user is simply scrolling the page and the map should not receive scroll events.

    In practice this might be complicated to achieve, since input from a pointing device
    can be chaotic, and there's nothing preventing a browser or OS from emitting another
    scroll event while the pointer happens to be over the map that the map scroll handler
    catches and actions on once active.
  */
  const mouseOverRef = React.useRef<HTMLDivElement | null>(null);
  const [isHovered, setHovered] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mouseOverRef.current) {
      return;
    }

    const mapContainer: HTMLDivElement = mouseOverRef.current;

    const enterHandler = () => {
      setHovered(true);
    };
    const leaveHandler = () => {
      setHovered(false);
    };

    mapContainer.addEventListener('mouseenter', enterHandler);
    mapContainer.addEventListener('mouseleave', leaveHandler);

    return () => {
      mapContainer.removeEventListener('mouseenter', enterHandler);
      mapContainer.removeEventListener('mouseleave', leaveHandler);
    };
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div ref={(el) => (mouseOverRef.current = el)} className={cs.container}>
      {features && scenes && data && overlayFeatureCollections && errorState == null ? (
        <>
          {!isEmbedded && (
            <B.Navbar>
              <B.NavbarGroup align={B.Alignment.LEFT}>{items}</B.NavbarGroup>
            </B.Navbar>
          )}
          <div className={isEmbedded ? cs.embeddedMapContainer : cs.mapContainer}>
            {isEmbedded && (
              <div className={cs.embeddedLensLogoContainer}>
                <LensLogo
                  key={0}
                  isWhite={true}
                  className={cs.lensLogoLarge}
                  onClick={() => window.open('https://www.upstream.tech/lens', '_blank')}
                />
              </div>
            )}
            <ConnectedMap
              hideNavigationControl
              hideScaleControl={isEmbedded}
              imageRefs={imageRefs}
              featureData={data}
              mode={scenes.size === 2 ? 'COMPARE' : 'VIEW'}
              selectedFeatures={features.toSet()}
              selectedFeatureCollection={featureCollectionUtils.hydratedFeatureCollection(
                I.fromJS({}),
                features
              )}
              selectedFeatureIds={I.Set()}
              organization={I.fromJS({})}
              firebaseToken=""
              onMapClick={() => {}}
              defaultCameraOptions={cameraOptions?.toJS()}
            >
              {({map, isMapLoaded, cursor, layerKey, index}: RenderMapCallbackOpts) => {
                const layer = layerUtils.getLayer(layerKey);

                // If embedded, alter scroll behavior.
                if (isEmbedded) {
                  if (isHovered) {
                    map.scrollZoom.enable();
                  } else {
                    map.scrollZoom.disable();
                  }
                }

                return (
                  <MapInteractionProvider
                    features={features}
                    selectedFeatureIds={I.Set()}
                    setSelectedFeatureIds={() => {}}
                  >
                    {({selectFeatureById, hoveredFeatureIds, hoverFeatureById}) => (
                      <>
                        <mapUtils.DisableClickableFeatures />
                        <FeatureRaster
                          map={map}
                          isMapLoaded={isMapLoaded}
                          activeLayerKey={layerKey}
                          cursor={cursor}
                          featureCollection={I.fromJS({})}
                          featureData={data}
                        />

                        <FeatureCollectionVector
                          map={map}
                          isMapLoaded={isMapLoaded}
                          featureCollection={featureCollectionUtils.hydratedFeatureCollection(
                            I.fromJS({}),
                            features
                          )}
                          selectedFeatureIds={I.Set()}
                          selectFeatureById={selectFeatureById}
                          hoveredFeatureIds={hoveredFeatureIds}
                          hoverFeatureById={hoverFeatureById}
                          showPropertyBoundaries={true}
                        />
                        <ZoomCenterControl
                          map={map}
                          isMapLoaded={isMapLoaded}
                          featureBounds={mapBounds}
                          fitBoundsOptions={fitBoundsOptions}
                        />
                        {!isEmbedded && (
                          <>
                            <TerrainControl
                              map={map}
                              isMapLoaded={isMapLoaded}
                              showTerrain={showTerrain}
                              toggleShowTerrain={() => setShowTerrain(!showTerrain)}
                              initialBearing={cameraOptions?.get('bearing')}
                              initialPitch={cameraOptions?.get('pitch')}
                            />
                            <MapSettingsControl
                              map={map}
                              isMapLoaded={isMapLoaded}
                              position={'top-right'}
                              activeTool={activeTool}
                              setActiveTool={(activeTool) =>
                                setActiveTool(activeTool as PublicMapTool | null)
                              }
                              showCostDebugger={false}
                              setShowCostDebugger={(_value) => {}}
                            />
                            <DraggableLegend imageRefs={imageRefs} layer={layer} index={index} />
                          </>
                        )}
                        {isEmbedded && (
                          <MapFullscreenControl
                            map={map}
                            isMapLoaded={isMapLoaded}
                            position={'top-right'}
                          />
                        )}
                        {overlayFeatureCollections && overlayFeatureCollections.size > 0 && (
                          <GeometryOverlaysContent
                            map={map}
                            isMapLoaded={isMapLoaded}
                            selectedFeatures={I.Set([])}
                            overlayFeatureCollections={overlayFeatureCollections}
                            geoJsonFeaturesLoader={geoJsonFeaturesLoader}
                            isOverlayVisibleByName={isOverlayVisibleByName}
                            overlaySettings={overlaySettings}
                            openAnalyzePolygonPopup={() => {}}
                          />
                        )}
                        {shareLinkConfig?.note_geometry && (
                          <NoteVector
                            map={map}
                            isMapLoaded={isMapLoaded}
                            notesState={{
                              ...DEFAULT_NOTES_STATE,
                              notes: [
                                {id: 1, geometry: shareLinkConfig.note_geometry} as StateApiNote,
                              ],
                              focusedNoteId: 1,
                            }}
                            isVisible
                            notesActions={{hoverNote: (_noteId) => null} as unknown as NotesActions}
                          />
                        )}
                        {!cameraOptions && (
                          <GeoJsonFitter
                            map={map}
                            geoJson={geoJsonUtils.featureCollection(features.toJS())}
                            fitBoundsOptions={{animate: false}}
                          />
                        )}
                        <MapTilesLoadingControl map={map} isMapLoaded={isMapLoaded} />
                        <MapStyle map={map} style={mapStyle} />
                      </>
                    )}
                  </MapInteractionProvider>
                );
              }}
            </ConnectedMap>
            {/*
              ManageLayersDialog must be a sibling of and come after the map because of the
              positioning of .overlayControlsWrapper, which mimicks MonitorProjectView's behavior.
            */}
            <div className={cs.overlayControlsWrapper}>
              {activeTool === 'manageLayers' && (
                <ManageLayersDialog
                  ref={setManageLayersEl}
                  disableConfiguration={true}
                  onClose={() => setActiveTool(null)}
                  overlaySettings={overlaySettings}
                  setOverlaySettings={setOverlaySettings}
                  isOverlayVisibleByName={isOverlayVisibleByName}
                  setIsOverlayVisibleByName={setOverlayVisibility}
                />
              )}
              {activeTool === 'manageBasemap' && (
                <ManageBasemapDialog
                  onClose={() => setActiveTool(null)}
                  onChangeMapStyle={(nextMapStyle) => setMapStyle(nextMapStyle)}
                  selectedStyle={mapStyle}
                />
              )}
            </div>
            {(!!shareLinkConfig?.description ||
              !!shareLinkConfig?.note_geometry ||
              !!shareLinkConfig?.graph ||
              !!shareLinkConfig?.attachments) && (
              <DraggableMapOverlayDialog
                isCollapsable={true}
                initialPosition={{
                  top: DESCRIPTION_Y_PADDING + (isEmbedded ? 40 : 0), // Add extra padding for embeds because the logo we show on embeds is positioned absolutely
                  left: DESCRIPTION_X_PADDING + (isEmbedded ? -5 : 0), // Take off a lil padding to line up with the logo for embeds
                }}
                style={{width: DESCRIPTION_DEFAULT_WIDTH, maxWidth: '75%'}} // magic values that felt good
                title="Description"
              >
                <div className={cs.descriptionOverlay}>
                  {shareLinkConfig?.description && <p>{shareLinkConfig.description}</p>}
                  {shareLinkConfig?.note_geometry && (
                    <div>
                      <B.Icon
                        icon={
                          shareLinkConfig.note_geometry.type === 'Point'
                            ? 'map-marker'
                            : 'polygon-filter'
                        }
                        style={{paddingRight: '.5rem'}}
                      />
                      <span style={{fontStyle: 'italic'}}>
                        {shareLinkConfig.note_geometry.type === 'Point'
                          ? `Point at ${position}`
                          : `${areaAcres} centered at ${position}`}
                      </span>
                    </div>
                  )}
                  {!!shareLinkConfig?.graph && shareLinkConfig.note_geometry && (
                    <AnalyzePolygonChart
                      graph={parseGraphIsoStringsAsDates(shareLinkConfig?.graph)}
                      imageRefs={imageRefs}
                      areaInM2={UTMArea(shareLinkConfig.note_geometry as GeoJSON.Geometry)}
                      areaUnit={'areaAcre'}
                      disablePanZoom={true}
                      hideLegend={false}
                      showTitle={true}
                      isLegendInteractive={false}
                      loading={false}
                      graphStyle={{height: 150}}
                      isExpanded={false}
                    />
                  )}
                  {!!shareLinkConfig?.attachments?.length && (
                    <div className={cs.images}>
                      {shareLinkConfig?.attachments.map((image, i) => (
                        <a
                          key={i}
                          target="_blank"
                          rel="noopener noreferrer"
                          download="file"
                          href={image}
                          title={getFileNameFromObjectDownloadUrl(image!) || undefined}
                        >
                          <ResizedImage imageUrl={image} dimension={300} />
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </DraggableMapOverlayDialog>
            )}
          </div>
        </>
      ) : (
        <ErrorPage
          message={
            errorState == 'unavailable'
              ? 'This content is no longer available.'
              : 'Something went wrong. Please wait a moment, then reload the page.'
          }
          linkTo={isEmbedded ? null : 'https://www.upstream.tech/lens'}
        />
      )}
      <HideHubspotWidget />
    </div>
  );
};

export default PublicMap;
