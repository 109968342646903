import * as I from 'immutable';

import {ApiProject} from 'app/modules/Remote/Project';

import * as routeUtils from './routeUtils';

/**
 * Takes in a projectId (which is a prefix of the full UUID) and a list of projects and finds the corresponding project
 */
export const findProjectInProjects = (
  projects: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<ApiProject>>>,
  projectId: string
): I.MapAsRecord<I.ImmutableFields<ApiProject>> => {
  return projects.find(
    (_, pId) =>
      (projectId.match(routeUtils.UUID_PREFIX_REGEXP) && pId!.startsWith(projectId)) ||
      pId === projectId
  );
};
