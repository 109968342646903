import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  ChartOptions,
  PluginOptionsByType,
} from 'chart.js';
import 'chartjs-adapter-moment';
import {uniq} from 'lodash';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import React, {CSSProperties} from 'react';

import {
  DataRange,
  Graph,
  GraphColumn,
  GraphTimeRange,
  GraphWithTimeRange,
  SomeYearsGraphRange,
} from 'app/components/AnalyzePolygonChart/types';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as colorUtils from 'app/utils/colorUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';

import {
  DataPoint,
  EXPANDED_GRAPH_FONT_SIZE,
  GRAPH_FONT_SIZE,
  TOOLTIP_TIME_FORMAT,
  formatPercent,
  makeTooltipLabelCallback,
  useHtmlLegend,
} from './utils';
import {getLanduseLayerValueMap} from '../AnalyzePolygonPopup/utils';

type CategoryDataPoint = DataPoint & {category: string};

/**
 * Component to render a stacked bar chart separated by category
 *
 * Note: the Chart.js types are a bit lacking (e.g., optional props erroneously
 * typed as required) so we have to do a bit of casting.
 */
const ByCategoryChart: React.FunctionComponent<{
  graph: Graph | GraphWithTimeRange;
  areaInM2: number;
  areaUnit: ApiOrganizationAreaUnit;
  onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
  onClickLegend: (chart: Chart) => void;
  onInitialize: (chart: Chart, timeRange: GraphTimeRange) => void;
  getDataColumn: (datasetName: string | number | undefined) => GraphColumn | undefined;
  devicePixelRatio?: number;
  hideTooltip?: boolean;
  hideLegend?: boolean;
  showTitle?: boolean;
  isLegendInteractive?: boolean;
  graphStyle?: CSSProperties;
  isExpanded: boolean;
  dataRange: DataRange;
  children: (chart: Chart) => React.ReactNode;
}> = ({
  graph,
  areaInM2,
  areaUnit,
  onClick,
  onInitialize,
  onClickLegend,
  getDataColumn,
  devicePixelRatio,
  hideTooltip,
  hideLegend,
  graphStyle,
  children,
  showTitle,
  isLegendInteractive = true,
  isExpanded,
  dataRange,
}) => {
  const chartRef = React.useRef<Chart | null>(null);

  const graphRange = graph.graphRange as SomeYearsGraphRange;
  const selectedYears = React.useMemo(
    () => graphRange.options.filter(({isSelected}) => isSelected).map(({value}) => value),
    [graphRange.options]
  );

  const [datasets, labels, minDate, maxDate] = React.useMemo(() => {
    const valueMap = getLanduseLayerValueMap(graph.layerKey);

    const dataPoints = graph.dataPoints.map(([date, value, meta]) => {
      const adjustedDate = layerUtils.conditionallyAdjustLayerDate(date, graph.layerKey);
      return {
        x: adjustedDate.valueOf(),
        y: value,
        date: adjustedDate.toDate(),
        cursor: meta.cursor,
        category: meta.category,
      } as CategoryDataPoint;
    });

    // Graph data points are sorted in AnalyzePolygonChart.
    const minDate = dataPoints[0].date;
    const maxDate = dataPoints[dataPoints.length - 1].date;

    const filteredDataPoints = dataPoints.filter((dataPoint) =>
      selectedYears.includes(dataPoint.date.getFullYear())
    );

    // labels is a list of dates that will be shown on the x-axis.
    // NOTE: We are assuming there is one date per year here since this is
    // aggregated data and only showing the year here
    const labels: ChartData<'bar'>['labels'] = Object.keys(
      groupBy(filteredDataPoints, (graphPoint) => graphPoint.date.getFullYear())
    );

    const uniqueCategories = uniq(filteredDataPoints.map(({category}) => category));
    const uniqueXValues = uniq(filteredDataPoints.map(({x}) => x));

    const groupedByCategory = uniqueCategories.reduce(
      (accum, category) => ({
        ...accum,
        /** Every value needs to have the same length, otherwise categories from
         * more recent years get shifted down and grouped into older years. So,
         * we check to see if we have a data point for that category and cursor;
         * if not, we set an empty placeholder point compatible with the
         * Chart.js plotting logic. */
        [category]: uniqueXValues.map((x) => {
          const placeholderPoint = {x, y: 0};
          return (
            filteredDataPoints.find((p) => p.category === category && p.x === x) || placeholderPoint
          );
        }),
      }),
      {}
    );

    // there is one dataset for each land use category. these datasets will get stacked on top
    // of eachother in the stacked bar graph
    const datasets = Object.keys(groupedByCategory)
      .map((category) => {
        const categoryName = valueMap?.[category]?.label;
        return {
          data: groupedByCategory[category],
          label: valueMap?.[category]?.label,
          backgroundColor: valueMap?.[category]?.color,
          category: category,
          hidden: getDataColumn(categoryName)?.isSelected === false,
        };
      })
      // Some valueMaps specify order that we want to stack the bars in. Lower order
      // values render at the bottom of the stack
      .sort((a, b) => (valueMap?.[a.category]?.order ?? 1) - (valueMap?.[b.category]?.order ?? 0));

    return [datasets, labels, minDate, maxDate];
  }, [graph.layerKey, graph.dataPoints, selectedYears, getDataColumn]);

  const tooltipPluginOptions = React.useMemo<PluginOptionsByType<'bar'>['tooltip']>(
    () =>
      ({
        enabled: !hideTooltip,
        callbacks: {
          title: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            const datum = tooltipItem.dataset.data[
              tooltipItem.dataIndex
            ] as unknown as CategoryDataPoint;
            return moment(datum.date).format(TOOLTIP_TIME_FORMAT);
          },
          label: (tooltipItem) => tooltipItem.dataset.label ?? '',
          afterLabel: makeTooltipLabelCallback(graph, areaInM2, areaUnit),
        },
      }) as PluginOptionsByType<'bar'>['tooltip'],
    [areaInM2, areaUnit, graph, hideTooltip]
  );

  const options: ChartOptions<'bar'> = React.useMemo(
    () => ({
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          max: dataRange[1],
          min: dataRange[0],
          ticks: {
            maxTicksLimit: 2,
            callback: (value) => formatPercent(Number(value)),
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPluginOptions,
        title: {
          display: showTitle,
          text: graph.layerDisplay,
          font: {weight: 'normal'} as PluginOptionsByType<'bar'>['title']['font'],
        },
      },
      onHover: (_event, elements, chart) =>
        (chart.canvas.style.cursor = elements.length ? 'pointer' : 'unset'),
      onClick,
      maintainAspectRatio: false,
      devicePixelRatio,
    }),
    [dataRange, tooltipPluginOptions, showTitle, graph.layerDisplay, onClick, devicePixelRatio]
  );

  const {legendItems, htmlLegendPlugin, renderLegend} = useHtmlLegend();

  const handleLegendClick = React.useCallback(
    (item) => {
      const chart = chartRef.current;
      if (chart) {
        const datasetIndex = item.datasetIndex;
        chart.getDatasetMeta(datasetIndex).hidden = !chart.getDatasetMeta(datasetIndex).hidden;
        chart.update();
        onClickLegend(chart);
      }
    },
    [onClickLegend]
  );

  const legend = React.useMemo(
    () => renderLegend(legendItems, isLegendInteractive, isExpanded, handleLegendClick),
    [legendItems, isLegendInteractive, isExpanded, handleLegendClick, renderLegend]
  );

  const canvasRefCallback = React.useCallback(
    (node: HTMLCanvasElement | null) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      if (node) {
        const ctx = node.getContext('2d');

        Chart.defaults.font.size = isExpanded ? EXPANDED_GRAPH_FONT_SIZE : GRAPH_FONT_SIZE;
        Chart.defaults.color = colorUtils.default.darkestGray;

        if (ctx) {
          chartRef.current = new Chart(ctx, {
            type: 'bar',
            data: {
              labels,
              datasets,
            },
            options,
            plugins: [htmlLegendPlugin],
          });

          if (onInitialize) {
            onInitialize(chartRef.current, [minDate, maxDate]);
          }
        }
      }
    },

    []
  );

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.datasets = datasets;
      chartRef.current.update();
    }
  }, [datasets]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = labels;
      chartRef.current.update();
    }
  }, [labels]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options) {
      chartRef.current.options = options;
      chartRef.current.update();
    }
  }, [options]);

  return (
    <React.Fragment>
      <div style={graphStyle}>
        <canvas ref={canvasRefCallback} />
      </div>
      {!!chartRef.current && children(chartRef.current)}
      {!hideLegend && legend}
    </React.Fragment>
  );
};

export default ByCategoryChart;
