import {toMercator} from '@turf/projection';
import {bbox} from '@turf/turf';
import classnames from 'classnames';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {renderGeojsonSvg} from 'app/components/OrderImageryModal/ImageryPreviewImage';
import {ApiFeature} from 'app/modules/Remote/Feature';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';

import * as cs from './FeatureMiniMap.styl';

/**
 * Component to display a mini-map of multi-part property features. Can have a
 * currently selected property and click to select a different one.
 */
const FeatureMiniMap: React.FunctionComponent<
  React.PropsWithChildren<{
    features: I.ImmutableOf<ApiFeature[]>;
    selectedFeatureId: number | null;
    setSelectedFeatureId: (featureId: number) => void;
    hoveredFeatureId: number | null;
    hoverFeatureById: (featureId: number | null) => void;
    width: number;
    height: number;
    padding?: number;
  }>
> = ({
  features,
  selectedFeatureId,
  setSelectedFeatureId,
  hoveredFeatureId,
  hoverFeatureById: setHoveredFeatureId,
  width,
  height,
  padding = 4,
}) => {
  const featureCollectionXY = React.useMemo<geojson.FeatureCollection>(
    () =>
      toMercator(
        geoJsonUtils.featureCollection(features.toJS()),
        // OK to mutate since we’re operating on a toJS result
        {mutate: true}
      ),
    [features]
  );

  const boundsXY = bbox(featureCollectionXY);

  const contentsWidth = boundsXY[2] - boundsXY[0];
  const contentsHeight = boundsXY[3] - boundsXY[1];

  const canvasHeight = height - 2 * padding;
  const canvasWidth = width - 2 * padding;

  const scale = Math.min(canvasWidth / contentsWidth, canvasHeight / contentsHeight);

  // offset to center the features in the SVG
  const offsetX = (canvasWidth - contentsWidth * scale) / 2;
  const offsetY = (canvasHeight - contentsHeight * scale) / 2;

  const featureXYToCanvasXY = ([x, y]) => [
    (x - boundsXY[0]) * scale + padding + offsetX,
    // canvas Y axis is flipped
    height - (y - boundsXY[1]) * scale - offsetY - padding,
  ];

  return (
    <svg width={width} height={height} className={cs.map}>
      {featureCollectionXY.features.map((f) => (
        <g key={f.id}>
          {(f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon') &&
            renderGeojsonSvg(f.geometry, featureXYToCanvasXY, {
              className: classnames(cs.featurePolygon, {
                [cs.selected]: f.id === selectedFeatureId,
                [cs.hovered]: f.id === hoveredFeatureId,
              }),
              onClick: () => setSelectedFeatureId(f.id as number),
              onMouseEnter: () => setHoveredFeatureId(f.id as number),
              onMouseLeave: () => setHoveredFeatureId(null),
            })}
        </g>
      ))}
    </svg>
  );
};

export default FeatureMiniMap;
