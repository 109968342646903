import React from 'react';
import {Link} from 'react-router-dom';

export default () => (
  <article>
    <img style={{marginBottom: '1.2rem'}} src={require('./stopwatch.svg')} />
    <h1>Running a bit behind…</h1>
    <h2>The server has taken a bit too long to respond.</h2>

    <p style={{marginTop: '2rem'}}>
      <Link to="/">Back to our homepage</Link> or{' '}
      <a href="mailto:lens@upstream.tech">shoot us an email</a>
    </p>
  </article>
);
