import {toWgs84} from '@turf/projection';
import {intersect, featureCollection as turfFeatureCollection} from '@turf/turf';
import * as geojson from 'geojson';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {UNIT_AREA_ACRE, UNIT_AREA_M2} from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';

import MapContent from './MapContent';
import * as cs from './TileCostDebugger.styl';
import MapOverlayDialog from '../MapOverlayDialog/MapOverlayDialog';

// In EPSG:3857, x axis is meters (at the equator)
const EQUATOR_CIRCUMFERENCE_M = 20037508.342789244 * 2;
const ZOOM_18_TILE_WIDTH_M = EQUATOR_CIRCUMFERENCE_M / 2 ** 18;

const AIRBUS_DOLLARS_PER_TILE = 0.017;
const MAXAR_DOLLARS_PER_TILE = 0.03;

function renderCost(tiles: number) {
  return `$${(AIRBUS_DOLLARS_PER_TILE * tiles).toFixed(2)}–$${(
    MAXAR_DOLLARS_PER_TILE * tiles
  ).toFixed(2)}`;
}

/**
 * Component to show an overlay of the zoom level 18 webtiles that would be used
 * to construct a high-res image of the currently selected features.
 *
 * Also summarizes how many of the tiles intersect the feature, how many are
 * within the margin of the feature, and how many are "wasted" and are too far
 * from the feature to be useful.
 */
const TileCostDebugger: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    features: I.Set<I.ImmutableOf<ApiFeature>>;
  }>
> = ({map, isMapLoaded, features}) => {
  const featuresJson: geojson.Feature<geojson.Polygon | geojson.MultiPolygon>[] = React.useMemo(
    () => features.toJS(),
    [features]
  );

  const areaInAcres = React.useMemo(() => {
    const areaInM2 = features.reduce((acc, f) => acc! + featureUtils.getFeatureAreaM2(f!), 0);
    // This is the pricing algorthim, which uses floor but has a min of 1.
    return Math.max(Math.floor(conversionUtils.convert(areaInM2, UNIT_AREA_M2, UNIT_AREA_ACRE)), 1);
  }, [features]);

  const [gridPolygonFeatures, bufferedFeatures] = React.useMemo(() => {
    const gridPolygonFeatures: geojson.Feature<
      geojson.Polygon,
      {intersects: boolean; close: false}
    >[] = [];

    const featureCollection = geoJsonUtils.featureCollection(
      featuresJson
    ) as geojson.FeatureCollection<geojson.Polygon | geojson.MultiPolygon>;
    const [bufferedFeatures, windowXY] = geoJsonUtils.applyImageryBuffer(featureCollection);

    const tileXMin = Math.floor(windowXY[0] / ZOOM_18_TILE_WIDTH_M);
    const tileYMin = Math.floor(windowXY[1] / ZOOM_18_TILE_WIDTH_M);
    const tileXMax = Math.ceil(windowXY[2] / ZOOM_18_TILE_WIDTH_M);
    const tileYMax = Math.ceil(windowXY[3] / ZOOM_18_TILE_WIDTH_M);

    for (let x = tileXMin; x < tileXMax; ++x) {
      for (let y = tileYMin; y < tileYMax; ++y) {
        // We make these polygons (even though they’re just making a grid) so
        // we can use the intersection algorithms below.
        const square = geoJsonUtils.polygon(
          [
            [
              toWgs84([x * ZOOM_18_TILE_WIDTH_M, y * ZOOM_18_TILE_WIDTH_M]),
              toWgs84([(x + 1) * ZOOM_18_TILE_WIDTH_M, y * ZOOM_18_TILE_WIDTH_M]),
              toWgs84([(x + 1) * ZOOM_18_TILE_WIDTH_M, (y + 1) * ZOOM_18_TILE_WIDTH_M]),
              toWgs84([x * ZOOM_18_TILE_WIDTH_M, (y + 1) * ZOOM_18_TILE_WIDTH_M]),
              toWgs84([x * ZOOM_18_TILE_WIDTH_M, y * ZOOM_18_TILE_WIDTH_M]),
            ],
          ],
          {
            intersects: false,
            close: false,
          }
        );

        featureCollection.features.forEach((feature) => {
          // unfortunately we have to pay the cost of generating the
          // intersection polygon by calling this function, even though we
          // only care about whether or not it intersects.
          if (intersect(turfFeatureCollection([feature, square]))) {
            square.properties!.intersects = true;
          }
        });

        bufferedFeatures.features.forEach((feature) => {
          if (
            !square.properties!.intersects &&
            intersect(turfFeatureCollection([feature, square]))
          ) {
            square.properties!.close = true;
          }
        });

        gridPolygonFeatures.push(square as any);
      }
    }

    return [gridPolygonFeatures, bufferedFeatures] as const;
  }, [featuresJson]);

  const totalTiles = gridPolygonFeatures.length;

  const featureTiles = gridPolygonFeatures.filter((g) => g.properties!.intersects).length;
  const closeTiles = gridPolygonFeatures.filter((g) => g.properties!.close).length;

  const wastedTiles = totalTiles - (featureTiles + closeTiles);

  return (
    <>
      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[]}
        sources={[
          {
            id: 'TileCostDebugger-grid',
            source: {
              type: 'geojson',
              data: geoJsonUtils.featureCollection(gridPolygonFeatures),
            },
          },
          {
            id: 'TileCostDebugger-buffer',
            source: {
              type: 'geojson',
              data: bufferedFeatures,
            },
          },
        ]}
        layers={[
          {
            id: 'TileCostDebugger-grid-lines',
            source: 'TileCostDebugger-grid',
            type: 'line',
            paint: {
              'line-color': '#ff00ff',
              'line-opacity': 0.5,
            },
          },
          {
            id: 'TileCostDebugger-grid-fill',
            source: 'TileCostDebugger-grid',
            type: 'fill',
            paint: {
              'fill-color': ['case', ['==', ['get', 'close'], true], '#00ffff', '#ff00ff'],
              'fill-opacity': [
                'case',
                ['any', ['==', ['get', 'intersects'], true], ['==', ['get', 'close'], true]],
                0.3,
                0.0,
              ],
            },
          },
          {
            id: 'TileCostDebugger-buffer-outline',
            source: 'TileCostDebugger-buffer',
            type: 'line',
            paint: {
              'line-color': '#ffff00',
              'line-opacity': 0.7,
              'line-width': 2,
            },
          },
        ]}
      />

      {features.size > 0 && (
        <MapOverlayDialog className={cs.container}>
          <p>
            <strong>Area:</strong> {conversionUtils.numberWithCommas(areaInAcres.toString())} acres
            ($
            {(areaInAcres * 0.08).toFixed(2)}–${(areaInAcres * 0.15).toFixed(2)})
            <br />
            <strong>Tiles in Window:</strong> {totalTiles} ({renderCost(totalTiles)})
            <br />
            <strong>Covered by Feature:</strong> {featureTiles}
            <br />
            <strong>Within Buffer:</strong> {closeTiles}
            <br />
            <strong>Wasted:</strong> {Math.round((wastedTiles / totalTiles) * 100)}% (
            {renderCost(wastedTiles)})
            <br />
          </p>
        </MapOverlayDialog>
      )}
    </>
  );
};

export default TileCostDebugger;
