import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {useShareLink} from 'app/providers/ShareLinkProvider';
import {useUserInfo} from 'app/utils/featureFlags';
import * as mapUtils from 'app/utils/mapUtils';
import {useShowInternalToolsOnAltPress} from 'app/utils/userUtils';

import MapContent from './MapContent';
import cs from './StyleControl.styl';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
  imageRefs: mapUtils.MapImageRefs;
}

const ShareControl: React.FC<Props> = ({map, isMapLoaded, position, imageRefs}) => {
  const shareControlButtonEl = document.createElement('div');
  const shareControlButton = new mapUtils.PortalControl(shareControlButtonEl);

  const {openShareLinkModal, isShareLinkModalOpen, hasUnshareableConfiguration} = useShareLink();

  const [_, profile] = useUserInfo();

  const showInternalToolsLink = useShowInternalToolsOnAltPress(profile!);

  const disableSharing = React.useMemo(
    () => hasUnshareableConfiguration(imageRefs, showInternalToolsLink),
    [hasUnshareableConfiguration, imageRefs, showInternalToolsLink]
  );

  return (
    <React.Fragment>
      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[shareControlButton]}
        controlPosition={position}
      />
      {ReactDOM.createPortal(
        // Using a popover with interactionKind=hover instead of a tooltip to allow for click inside
        <B.Popover
          interactionKind="hover"
          content={
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '1rem'}}>
              <span>
                {disableSharing
                  ? "This data isn't available to share publicly"
                  : 'Share property map'}
              </span>
              <B.AnchorButton
                icon={'info-sign'}
                href="https://support.upstream.tech/article/151-lens-share-link"
                target="_blank"
                small
                minimal
                className={cs.mapIcon}
              />
            </div>
          }
          position={B.Position.RIGHT}
        >
          <B.AnchorButton
            id="share-control"
            icon="share"
            active={isShareLinkModalOpen}
            className={cs.mapIcon}
            disabled={disableSharing}
            onClick={() => openShareLinkModal(imageRefs)}
          />
        </B.Popover>,
        shareControlButtonEl
      )}
    </React.Fragment>
  );
};

export default ShareControl;
