import * as B from '@blueprintjs/core';
import * as React from 'react';

import {getDataRange} from 'app/providers/MapPolygonStateProvider';
import {TILE_SIDE_PX, TileData} from 'app/stores/RasterCalculationStore';
import {StatusMaybe} from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';

import {ScaledPixelValue} from './PixelInfoProvider';

const PRECISION = 4;

interface Coordinate {
  x: number;
  y: number;
}

interface PixelInspectorDebuggerProps {
  emulatedImageData: ImageData;
  debugMarkerCoordinates: {
    [key: string]: Coordinate;
  };
  debugPixelColor: string | null;
  scaledPixelValue: ScaledPixelValue | null;
  activeLayerKey: string;
  rawPixelReading: number;
  tileData: StatusMaybe<TileData>;
  tileBounds: mapboxgl.LngLatBounds;
  isPixelInspectorReady: boolean;
}

// Utility function to download the canvas content as an image.
function downloadImage(canvas: HTMLCanvasElement) {
  const dataUrl = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = 'preview.png';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const PixelInspectorDebugger: React.FC<PixelInspectorDebuggerProps> = ({
  emulatedImageData,
  debugMarkerCoordinates,
  debugPixelColor,
  scaledPixelValue,
  activeLayerKey,
  rawPixelReading,
  tileData,
  tileBounds,
  isPixelInspectorReady,
}) => {
  const previewCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

  // Callback ref to draw the cropped tile preview on the canvas.
  const canvasRefCallback = React.useCallback(
    (canvas: HTMLCanvasElement | null) => {
      if (canvas && emulatedImageData && debugMarkerCoordinates) {
        previewCanvasRef.current = canvas;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          // Create an offscreen canvas and draw the full emulated image.
          const offscreen = document.createElement('canvas');
          offscreen.width = emulatedImageData.width;
          offscreen.height = emulatedImageData.height;
          const offCtx = offscreen.getContext('2d');
          if (offCtx) {
            offCtx.putImageData(emulatedImageData, 0, 0);

            // Define the desired tile size.
            const tileSize = TILE_SIDE_PX;
            const cropX =
              Math.floor(debugMarkerCoordinates.lateralCoordinates.x / tileSize) * tileSize;
            const cropY =
              Math.floor(debugMarkerCoordinates.lateralCoordinates.y / tileSize) * tileSize;

            // Prevent cropping beyond the image bounds.
            const cropWidth = Math.min(tileSize, emulatedImageData.width - cropX);
            const cropHeight = Math.min(tileSize, emulatedImageData.height - cropY);

            // Get the cropped ImageData for the current tile.
            const croppedData = offCtx.getImageData(cropX, cropY, cropWidth, cropHeight);

            // Resize the preview canvas to match the tile dimensions.
            canvas.width = cropWidth;
            canvas.height = cropHeight;

            // Draw the cropped tile into the preview canvas.
            ctx.putImageData(croppedData, 0, 0);
          }
        }
      }
    },
    [emulatedImageData, debugMarkerCoordinates]
  );

  const handleDownloadPreviewImage = () => {
    if (previewCanvasRef.current) {
      downloadImage(previewCanvasRef.current);
    }
  };

  const [showEmulatedCanvas, setShowEmulatedCanvas] = React.useState<boolean>(false);
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'i' || event.key === 'I') {
        setShowEmulatedCanvas((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    showEmulatedCanvas && (
      <div
        style={{
          position: 'absolute',
          top: 60,
          right: 50,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          border: '2px solid black',
          borderRadius: 4,
          padding: 10,
        }}
      >
        {isPixelInspectorReady ? (
          <>
            <h4 style={{marginBottom: 0}}>Emulated Canvas Preview</h4>
            <p style={{fontSize: 'smaller', marginBottom: 0}}>Press 'I' to toggle this preview.</p>
            <p style={{fontSize: 'smaller', marginBottom: 0}}>Current tile:</p>
            <div style={{position: 'relative', display: 'inline-block'}}>
              <canvas ref={canvasRefCallback} style={{border: '1px solid #000'}} />
              <>
                <div
                  style={{
                    position: 'absolute',
                    left: (debugMarkerCoordinates.lateralCoordinates.x % TILE_SIDE_PX) - 5 + 'px',
                    top: (debugMarkerCoordinates.lateralCoordinates.y % TILE_SIDE_PX) - 5 + 'px',
                    width: '10px',
                    height: '10px',
                    border: '2px solid red',
                    borderRadius: '50%',
                    pointerEvents: 'none',
                    boxSizing: 'border-box',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: (debugMarkerCoordinates.projectedCoordinates.x % TILE_SIDE_PX) - 5 + 'px',
                    top: (debugMarkerCoordinates.projectedCoordinates.y % TILE_SIDE_PX) - 5 + 'px',
                    width: '10px',
                    height: '10px',
                    border: '2px solid blue',
                    borderRadius: '50%',
                    pointerEvents: 'none',
                    boxSizing: 'border-box',
                  }}
                />
              </>
            </div>

            <div style={{fontSize: 'smaller', display: 'flex', flexDirection: 'column'}}>
              <table>
                <tbody>
                  <tr>
                    <td>Raw Layer:</td>
                    <td>{layerUtils.getRawLayerKey(activeLayerKey)}</td>
                  </tr>
                  <tr>
                    <td>Data Range:</td>
                    <td>{getDataRange(layerUtils.getLayer(activeLayerKey)).join(' to ')}</td>
                  </tr>
                  <tr>
                    <td>Tile Bounds SW:</td>
                    <td>
                      {tileBounds
                        .getSouthWest()
                        .toArray()
                        .map((coord) => coord.toFixed(PRECISION))
                        .join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td>Tile Bounds NE:</td>
                    <td>
                      {tileBounds
                        .getNorthEast()
                        .toArray()
                        .map((coord) => coord.toFixed(PRECISION))
                        .join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td>Tile Zoom:</td>
                    <td>{JSON.stringify(tileData.value?.zoom)}</td>
                  </tr>
                  <tr>
                    <td>Raw Pixel Value:</td>
                    <td>{rawPixelReading}</td>
                  </tr>
                  <tr>
                    <td>Scaled Value:</td>
                    <td>{scaledPixelValue?.displayValue}</td>
                  </tr>
                  <tr>
                    <td>Grayscale Pixel:</td>
                    <td>
                      <div
                        style={{
                          backgroundColor: debugPixelColor || 'transparent',
                          width: '80%',
                          height: 16,
                        }}
                      />
                    </td>
                  </tr>
                  {Object.keys(debugMarkerCoordinates).map((key) => {
                    const coords = debugMarkerCoordinates[key];
                    const label = key
                      .replace(/([A-Z])/g, ' $1')
                      .replace(/^./, (char) => char.toUpperCase());
                    return (
                      <tr key={key}>
                        <td>{label}:</td>
                        <td>
                          {coords.x.toFixed(PRECISION)}, {coords.y.toFixed(PRECISION)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <B.Button onClick={handleDownloadPreviewImage} style={{marginTop: '5px'}} small>
                Download Raw Tile
              </B.Button>
            </div>
          </>
        ) : (
          <h4 style={{marginBottom: 0}}>Calculating...</h4>
        )}
      </div>
    )
  );
};

export default PixelInspectorDebugger;
