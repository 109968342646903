import * as geojson from 'geojson';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import DrawLayer from 'app/components/DeclarativeMap/DrawLayer';
import {NotesActions, NotesState} from 'app/stores/NotesStore';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  visible: boolean;
  notesState: NotesState;
  notesActions: NotesActions;
}

/**
 * Component for editing pending note geometry. Changes to NotesState’s
 * addPendingNoteGeometryMode will cause it to enter editing modes.
 */
const EditNoteGeometry: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  map,
  isMapLoaded,
  visible,
  notesState,
  notesActions,
}) => {
  const {pendingNoteGeometryFeature, addPendingNoteGeometryMode} = notesState;

  const setFeature = React.useCallback(
    (f: geojson.Feature | null) => {
      if (f) {
        notesActions.setPendingNoteGeometryFeature(f);
      } else if (pendingNoteGeometryFeature) {
        notesActions.removePendingNoteGeometryFeatureById(pendingNoteGeometryFeature.id);
      }
    },
    [pendingNoteGeometryFeature, notesActions]
  );

  const onDeselectFeature = React.useCallback(
    () => notesActions.stopEditingPendingGeometry(),
    [notesActions]
  );

  const drawingMode = addPendingNoteGeometryMode === 'rect' ? 'draw_rectangle' : 'draw_polygon';
  const visibleGeometry =
    addPendingNoteGeometryMode === 'rect' || addPendingNoteGeometryMode === 'polygon';

  return (
    <DrawLayer
      map={map}
      isMapLoaded={isMapLoaded}
      drawingMode={drawingMode}
      visible={visible && visibleGeometry}
      feature={
        pendingNoteGeometryFeature && pendingNoteGeometryFeature.geometry.type === 'Polygon'
          ? pendingNoteGeometryFeature
          : null
      }
      setFeature={setFeature}
      onDeselectFeature={onDeselectFeature}
    />
  );
};

export default EditNoteGeometry;
