import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import geojson, {MultiPolygon, Polygon} from 'geojson';
import I from 'immutable';
import moment from 'moment';
import React from 'react';

import DateSelectCard, {HiddenImageAction} from 'app/components/DateSelectCard';
import {ApiFeature, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiImageryContract} from 'app/modules/Remote/Project';
import {OrderableScenesLoader} from 'app/providers/OrderableScenesProvider';
import {FEATURE_WAITING_FOR_PROCESSING_STATUS, SOURCE_DETAILS_BY_ID} from 'app/utils/constants';
import * as featureUtils from 'app/utils/featureUtils';
import {getIntersectionInAcres, intersectGeometries} from 'app/utils/geoJsonUtils';
import {CachedApiGetterOptions, StatusMaybe} from 'app/utils/hookUtils';
import {canLoadOrderableSceneOnMap, getLayerKeyForScene} from 'app/utils/imageryUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import {AT_LEAST_PLUS, getOrgPrefix, orgIsAllowedTier} from 'app/utils/organizationUtils';

import {THUMBNAIL_BLOCKED_SOURCES} from '../OrderImageryModal';
import cs from './styles.styl';
import PolygonIcon from '../PolygonIcon';

const ITEM_MARGIN_BOTTOM = 6;
const MINIMUM_AREA = 10;

export interface Props {
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  mode: string;
  feature: I.ImmutableOf<ApiFeature>;
  blockedSources?: string[];
  firebaseToken: string | null;
  /** Pass null if the user is not allowed to hide/unhide */
  setIsImageHidden:
    | null
    | ((
        f: I.ImmutableOf<ApiFeature>,
        orderableScene: I.ImmutableOf<ApiOrderableScene>,
        isHidden: boolean
      ) => void);

  openOrderImageryModal: (d: I.ImmutableOf<ApiOrderableScene>) => void;
  orderImageryState: OrderImageryState;
  setOrderImageryState: (s: OrderImageryState) => void;
  imageRefs: mapUtils.MapImageRefs;
  onChange: (imageRef: mapUtils.MapImageRef) => void;
  orderableScenesLoader: OrderableScenesLoader;
  getCurrentImageryContract: (
    opts: CachedApiGetterOptions
  ) => StatusMaybe<ApiImageryContract | null>;
}

export type DefaultProps = Pick<Props, 'blockedSources'>;

export interface OrderImageryState {
  showHiddenImagery: boolean;
  selectedSources: string[];
  sortAscending: boolean;
  showOrderedImagery: boolean;
  orderSubset: boolean;
  subsetFeature: geojson.Feature<geojson.Polygon> | null;
}

export const DEFAULT_ORDER_IMAGERY_STATE: OrderImageryState = {
  showHiddenImagery: false,
  selectedSources: [],
  sortAscending: false,
  showOrderedImagery: true,
  orderSubset: false,
  subsetFeature: null,
};

const OrderImagerySidebar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  organization,
  feature,
  setIsImageHidden,
  openOrderImageryModal,
  blockedSources = THUMBNAIL_BLOCKED_SOURCES,
  firebaseToken,
  setOrderImageryState,
  orderImageryState,
  onChange,
  imageRefs,
  orderableScenesLoader,
  getCurrentImageryContract,
}) => {
  const {
    showHiddenImagery,
    showOrderedImagery,
    sortAscending,
    orderSubset,
    subsetFeature,
    selectedSources,
  } = orderImageryState;

  const listRef = React.useRef<HTMLDivElement>(null);

  const orderableScenesStatusMaybe = orderableScenesLoader(feature.get('id'));

  const orderableScenes: I.List<I.ImmutableOf<ApiOrderableScene>> = React.useMemo(() => {
    if (orderableScenesStatusMaybe.value) {
      return orderableScenesStatusMaybe.value.toList();
    } else {
      return I.List([]);
    }
  }, [orderableScenesStatusMaybe.value]);

  const filteredOrderableScenes: I.ImmutableListOf<ApiOrderableScene> = React.useMemo(() => {
    let filteredOrderableScenes = orderableScenes
      .filter((f) => {
        const sourceId = f?.get('sourceId');

        // No selected sources is the same as all sources checked
        return selectedSources.length && sourceId ? selectedSources.includes(sourceId) : true;
      })
      .toList();
    if (sortAscending) {
      filteredOrderableScenes = filteredOrderableScenes.reverse().toList();
    }
    return filteredOrderableScenes;
  }, [orderableScenes, selectedSources, sortAscending]);

  const isProcessing =
    feature.getIn(['properties', 'status']) === FEATURE_WAITING_FOR_PROCESSING_STATUS;

  const featureGeometry: geojson.Polygon | geojson.MultiPolygon = React.useMemo(
    () => feature.get('geometry').toJS(),
    [feature]
  );

  // dividers: calculate which years we have scenes for
  const availableSceneYears = React.useMemo(
    () =>
      filteredOrderableScenes
        .map((scene) => moment(scene!.get('sensingTime')).year())
        .toList()
        .sort((yearA, yearB) => featureUtils.alphanumericSort(yearA, yearB)),
    [filteredOrderableScenes]
  );

  // create an array of years we want dividers for (current through oldest year)
  const dividerYears: number[] = React.useMemo(() => {
    const dividerYears: number[] = [];
    const oldestYear: number = availableSceneYears.first();
    const currentYear: number = moment().year();
    for (let i: number = currentYear; i >= oldestYear; i--) {
      dividerYears.push(i);
    }
    if (sortAscending) {
      dividerYears.reverse();
    }
    return dividerYears;
  }, [availableSceneYears, sortAscending]);
  let dividerYearsIndex = 0;

  const children: React.ReactElement[] = [];
  let hiddenImagesCount = 0;
  // create a list of children including scenes and year dividers
  filteredOrderableScenes.forEach((scene, i) => {
    const dateString = scene!.get('sensingTime');
    const sourceId = scene!.get('sourceId');

    const isActive =
      imageRefs[0].cursor === dateString && imageRefs[0].layerKey.startsWith(sourceId);

    const isComparedCursor =
      imageRefs.some(
        (r) =>
          r.cursor === dateString &&
          layerUtils.isLayerKeyHighResTruecolor(r.layerKey) &&
          r.layerKey.startsWith(sourceId)
      ) && !isActive;

    let rightCardTag: null | React.ReactElement = null;

    const isOrdered = scene!.get('isOrdered');
    const isProcessed = scene!.get('orderProcessed');
    const isHidden = featureUtils.isOrderableSceneHidden(feature, scene!);
    const isPartial = isOrdered && !scene!.get('isFullyOrdered');
    const canOrder = scene!.get('canBeOrdered');
    const layerKey = getLayerKeyForScene(scene!);
    const canLoadSceneOnMap = canLoadOrderableSceneOnMap(scene!);
    const maybeUpdateImageRef = () => {
      const matchesDateAndSource = imageRefs.some(
        (r) => r.cursor === dateString && r.layerKey.startsWith(sourceId)
      );
      if (!matchesDateAndSource && isProcessed && canLoadSceneOnMap) {
        onChange({cursor: dateString, layerKey: layerKey});
      }
    };

    if (isHidden) {
      hiddenImagesCount = hiddenImagesCount + 1;
    }

    // Don't show imagery that is hidden or has already been ordered
    if ((!showHiddenImagery && isHidden) || (!showOrderedImagery && isProcessed)) {
      return null;
    } else if (!isOrdered) {
      rightCardTag = (
        <>
          {setIsImageHidden && (
            <HiddenImageAction
              feature={feature}
              orderableScene={scene!}
              isHidden={isHidden}
              setIsImageHidden={setIsImageHidden}
              alwaysVisible={showHiddenImagery}
            />
          )}

          <B.Tag
            className={cs.orderTag}
            intent={B.Intent.PRIMARY}
            minimal
            onClick={(e) => {
              e.stopPropagation();
              openOrderImageryModal(scene!);
            }}
          >
            Preview
          </B.Tag>
        </>
      );
    } else if (isOrdered && !isProcessed) {
      rightCardTag = <B.Tag minimal>Processing</B.Tag>;
    } else if (isOrdered && isPartial && canOrder) {
      rightCardTag = (
        <B.Tag
          className={cs.orderTag}
          intent={B.Intent.WARNING}
          minimal
          onClick={(e) => {
            e.stopPropagation();
            maybeUpdateImageRef();
            openOrderImageryModal(scene!);
          }}
        >
          Partial
        </B.Tag>
      );
    }

    // for our initial scene, always put a divider above it
    if (i === 0) {
      children.push(
        <div key={dividerYears[dividerYearsIndex]} className={cs.yearMarker}>
          <span>{dividerYears[dividerYearsIndex]}</span>
          <div className={cs.yearMarkerLine} />
        </div>
      );
    }
    // if we are trying to insert a scene and haven't reached a divider for it,
    // continue to insert dividers until we get to the one for that scene's year
    const date = moment(dateString);
    for (let n = 0; n < dividerYears.length; n++) {
      if (
        (date.year() > dividerYears[dividerYearsIndex] && sortAscending) ||
        (date.year() < dividerYears[dividerYearsIndex] && !sortAscending)
      ) {
        dividerYearsIndex++;
        children.push(
          <div key={dividerYears[dividerYearsIndex]} className={cs.yearMarker}>
            <span>{dividerYears[dividerYearsIndex]}</span>
            <div className={cs.yearMarkerLine} />
          </div>
        );
      }
    }

    let grayOut = false;
    if (orderSubset && subsetFeature) {
      // get intersection area of drawn rectangle and feature
      let featureIntersection: geojson.Feature<Polygon | MultiPolygon, any> | null = null;

      try {
        // TODO(fiona): Report out that this failed rather than it looking
        // like there’s no intersection area. Still, it’s better than lost in
        // space.
        featureIntersection = intersectGeometries(featureGeometry, subsetFeature.geometry);
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            featureId: feature.get('id'),
            subsetFeatureGeometry: JSON.stringify(subsetFeature.geometry),
          },
        });

        console.error(e);
      }

      // check if scene intersects with the drawn rectangle
      const sceneGeometry = scene!.get('sceneGeometry').toJS();
      const intersectionArea =
        featureIntersection &&
        getIntersectionInAcres(
          sceneGeometry as Polygon | MultiPolygon,
          featureIntersection.geometry as Polygon | MultiPolygon
        );

      const minumumAcresForSource: number | undefined =
        SOURCE_DETAILS_BY_ID[sourceId]?.minimum_order_acres;

      // Gray out scenes if the intersection between the scene, property,
      // and drawn rectangle is less than the required minimum order.
      grayOut =
        !intersectionArea ||
        intersectionArea < MINIMUM_AREA ||
        (!!minumumAcresForSource && intersectionArea < minumumAcresForSource);
    }

    // For case like planetary variables package, we can have a scene that isn't loadable
    // on the map. If it's been fully ordered, disable the DateSelect card so there is no onClick event
    const fullyOrderedNonLoadableScene = isOrdered && !isPartial && !canLoadSceneOnMap;

    children.push(
      <B.Tooltip
        key={dateString + sourceId + '-tooltip'}
        disabled={!grayOut && !fullyOrderedNonLoadableScene}
        content={
          grayOut
            ? 'Partial order not available for this scene. Your drawn area may be too small or outside the scene’s coverage.'
            : fullyOrderedNonLoadableScene
              ? 'Select layer from the dropdown'
              : undefined
        }
      >
        <DateSelectCard
          key={dateString + sourceId}
          className={
            grayOut || fullyOrderedNonLoadableScene
              ? cs.isDisabled
              : classnames({
                  [cs.isActive]: isActive,
                  [cs.isComparedCursor]: isComparedCursor,
                })
          }
          style={{
            marginBottom: i === filteredOrderableScenes.size - 1 ? 0 : ITEM_MARGIN_BOTTOM,
          }}
          onClick={
            grayOut
              ? () => null
              : () => {
                  maybeUpdateImageRef();
                  if (!isOrdered || isPartial) {
                    openOrderImageryModal(scene!);
                  }
                }
          }
          blockedSources={blockedSources}
          isOrdered={isProcessed}
          renderRightElement={
            grayOut
              ? () => null
              : () => (
                  <div className={cs.cardRightElement}>
                    <div className={cs.rightCardTag}>{rightCardTag}</div>
                  </div>
                )
          }
          orderableScene={scene!}
          organization={organization}
          firebaseToken={firebaseToken}
          layerKey={layerKey}
        />
      </B.Tooltip>
    );
  });

  const atLeastOneImageAvailable = !!children.length;
  if (!atLeastOneImageAvailable) {
    //if no imagery to show, show a "processing" blankstate if we're still processing, or a
    //"no available imagery" blankstate if we're done processing and still nothing to show
    children.push(
      <div key="no-filter-imagery" style={{height: '100%'}}>
        <B.NonIdealState
          iconMuted={false}
          icon={isProcessing ? 'time' : 'media'}
          title={isProcessing ? 'Processing in progress' : 'No data matches this criteria'}
          description={
            isProcessing
              ? 'In the meantime, explore additional data in the Layers Library.'
              : 'Please update your filters and try again'
          }
          action={
            isProcessing ? (
              <B.AnchorButton
                href={`/${getOrgPrefix(organization)}/settings/layers`}
                target="_blank"
                intent="primary"
              >
                Layers Library
              </B.AnchorButton>
            ) : (
              <></>
            )
          }
        />
      </div>
    );
  }

  function renderOrderSubsetPane() {
    const intersectionArea =
      featureGeometry &&
      subsetFeature &&
      getIntersectionInAcres(featureGeometry, subsetFeature.geometry);
    const areaAcres = intersectionArea ?? 0;

    const subsetAreaHeader = (
      <div className={cs.subsetAreaHeader}>
        <h3>{subsetFeature ? `Area in Property: ${areaAcres} Acres` : `Order Partial Area`}</h3>
        <div>
          {subsetFeature && (
            <B.Tooltip content="Reset Selection">
              <B.AnchorButton
                icon="reset"
                minimal
                onClick={() =>
                  setOrderImageryState({
                    ...orderImageryState,
                    subsetFeature: null,
                  })
                }
              />
            </B.Tooltip>
          )}
          <B.Tooltip content="Help">
            <B.AnchorButton
              icon="info-sign"
              minimal
              href="https://support.upstream.tech/article/8-ordering-high-resolution-imagery#ordering-partial-imagery"
              target="_blank"
            />
          </B.Tooltip>
        </div>
      </div>
    );

    return (
      <div className={cs.subsetPane}>
        {subsetFeature ? (
          areaAcres < MINIMUM_AREA ? (
            <B.Callout icon={null} intent={B.Intent.DANGER}>
              <>
                {subsetAreaHeader}
                <p>
                  Your selection is less than the minimum area of {MINIMUM_AREA} acres within your
                  property boundary for ordering partial data. Please draw a larger rectangle.
                </p>
              </>
            </B.Callout>
          ) : (
            <B.Callout icon={null} intent={B.Intent.SUCCESS}>
              <>
                {subsetAreaHeader}
                <p>Order for the selected area from the options below.</p>
              </>
            </B.Callout>
          )
        ) : (
          <B.Callout icon={null} intent={B.Intent.PRIMARY}>
            {subsetAreaHeader}
            <p>Draw a rectangle on the map to order data for that area.</p>
          </B.Callout>
        )}
      </div>
    );
  }

  function renderSourceFilter(orderableScenes: I.ImmutableOf<ApiOrderableScene[]>) {
    // TODO(maya): In the future it would be great to have a list of all possible
    // options for an organization so this list does not change based on what is
    // currently available and ordered to generate options.
    const dataSourceFilterOptions = orderableScenes.map((f) => {
      return f?.get('sourceId');
    });

    // Add existing filters already selected. When switching between properties it is
    // possible they have different sources available. Adding them here eliminates
    // the risk of getting stuck in a state where a filter is on that you cannot uncheck
    const sourceFilterOptions = dataSourceFilterOptions
      .concat(selectedSources)
      .toSet()
      .sort((sourceA, sourceB) => featureUtils.alphanumericSort(sourceA, sourceB));

    return (
      <B.Popover
        className={cs.filterButton}
        content={
          <div className={cs.headerPopover}>
            <>
              {sourceFilterOptions.map((sourceId) => {
                if (!sourceId) {
                  return;
                }
                const sourceDetails = featureUtils.getSourceDetails(
                  sourceId as featureUtils.SourceDetailsId
                );

                // Should never happen but if we can't find source details
                // for the id here we have nothing to show for this checkbox
                if (!sourceDetails) {
                  return;
                }

                return (
                  <B.Checkbox
                    key={sourceId}
                    checked={selectedSources.includes(sourceId)}
                    labelElement={
                      <>
                        <span>{sourceDetails.operator + ' ' + sourceDetails.name} </span>
                        <span style={{fontWeight: 'bold'}}>({sourceDetails.resolution}m)</span>
                      </>
                    }
                    onChange={() =>
                      setOrderImageryState({
                        ...orderImageryState,
                        selectedSources: selectedSources.includes(sourceId)
                          ? selectedSources.filter((s) => s !== sourceId)
                          : [...selectedSources, sourceId],
                      })
                    }
                  />
                );
              })}
            </>
          </div>
        }
      >
        <B.AnchorButton icon="filter" minimal active={!!selectedSources.length} text={'Source'} />
      </B.Popover>
    );
  }

  function renderShowProcessedToggle() {
    return (
      <B.Popover
        className={cs.filterButton}
        content={
          <div className={cs.headerPopover}>
            <B.Switch
              checked={!showOrderedImagery}
              label={showOrderedImagery ? 'Hide ordered' : 'Show ordered'}
              onChange={() =>
                setOrderImageryState({
                  ...orderImageryState,
                  showOrderedImagery: !showOrderedImagery,
                })
              }
              // Necessary for vertical centering
              style={{marginBottom: 0}}
            />
          </div>
        }
      >
        <B.AnchorButton icon="shopping-cart" minimal active={!showOrderedImagery} text={'Status'} />
      </B.Popover>
    );
  }

  function renderYearSort() {
    const icon = sortAscending ? 'sort-asc' : 'sort-desc';
    return (
      <B.Tooltip content={sortAscending ? 'Sort newest to oldest' : 'Sort oldest to newest'}>
        <B.AnchorButton
          icon={icon}
          minimal
          onClick={() =>
            setOrderImageryState({...orderImageryState, sortAscending: !sortAscending})
          }
        />
      </B.Tooltip>
    );
  }

  /**
   *
   * @param hiddenImagesCount - number of hidden images, used to know whether to enable show/hide toggle
   * @param orderableScenes - orderable scenes, used here for deriving filters
   */
  function renderHeader(
    hiddenImagesCount: number,
    orderableScenes: I.ImmutableOf<ApiOrderableScene[]>
  ): React.ReactNode {
    // Hide the toolbar if there are no scenes to sort/filter/hide
    if (!orderableScenes.size) return;

    const areaInM2 = featureUtils.getFeatureAreaM2(feature);
    const acreage = featureUtils.featureM2ToArea(areaInM2);

    const paidImagery = featureUtils.getPaidImagery(feature);
    const scenes = paidImagery?.get('scenes');
    const sceneLimit = paidImagery?.get('limit') ?? 0;
    const scenesPurchased = scenes?.size ?? 0;

    const imageryContract = getCurrentImageryContract({});
    const exceedsSceneLimit =
      imageryContract.value && sceneLimit === 0 ? false : scenesPurchased >= sceneLimit;

    const disableSubsetTool =
      exceedsSceneLimit || !imageryContract.value || acreage <= MINIMUM_AREA;

    return (
      <div className={cs.header}>
        <div className={cs.filters}>
          {renderYearSort()}
          {renderSourceFilter(orderableScenes)}
          {renderShowProcessedToggle()}
        </div>
        <div style={{justifyContent: 'right'}}>
          <B.Tooltip
            content={
              acreage <= MINIMUM_AREA
                ? `Cannot order partial areas for properties under ${MINIMUM_AREA} acres`
                : disableSubsetTool
                  ? 'Cannot order partial areas for this contract type'
                  : 'Order partial area'
            }
          >
            <B.AnchorButton
              icon={<PolygonIcon />}
              minimal
              active={orderSubset}
              disabled={disableSubsetTool}
              onClick={() =>
                setOrderImageryState({...orderImageryState, orderSubset: !orderSubset})
              }
            />
          </B.Tooltip>
          <B.Tooltip
            disabled={hiddenImagesCount === 0 && !showHiddenImagery}
            content={
              showHiddenImagery
                ? `Hide ${hiddenImagesCount} hidden`
                : `Show ${hiddenImagesCount} hidden`
            }
          >
            <B.AnchorButton
              icon="eye-off"
              minimal
              disabled={hiddenImagesCount === 0 && !showHiddenImagery}
              active={showHiddenImagery}
              onClick={() =>
                setOrderImageryState({
                  ...orderImageryState,
                  showHiddenImagery: !showHiddenImagery,
                })
              }
            />
          </B.Tooltip>
        </div>
      </div>
    );
  }

  if (
    orderableScenesStatusMaybe.status === 'error' ||
    (orderableScenesStatusMaybe.status === 'some' && !isProcessing && !orderableScenes.size)
  ) {
    return <B.NonIdealState iconMuted={false} icon="media" title="No imagery available to order" />;
  }

  if (orderableScenesStatusMaybe.status === 'unknown') {
    return (
      <div className={cs.loadingContainer}>
        <B.Spinner />
      </div>
    );
  }

  return (
    <div className={classnames(cs.container)}>
      {renderHeader(hiddenImagesCount, orderableScenes)}
      {isProcessing && atLeastOneImageAvailable && renderImageryIsLoadingWarning()}
      {orderSubset && renderOrderSubsetPane()}
      <div ref={listRef} className={cs.cardsContainer}>
        <div className={cs.cards}>{children}</div>
      </div>
      {renderFooter()}
      {orgIsAllowedTier(organization, AT_LEAST_PLUS) && renderTaskingSuggestion()}
    </div>
  );
};

export default OrderImagerySidebar;

function renderFooter() {
  return (
    <div className={cs.footer}>
      {'See our '}
      <a
        href="https://support.upstream.tech/article/136-choosing-imagery"
        target="_blank"
        rel="noopener noreferrer"
      >
        support doc on choosing the best image for your needs.
      </a>
    </div>
  );
}

function renderTaskingSuggestion() {
  return (
    <div className={cs.footer}>
      {"Don't see the imagery you need or have specific timing needs? "}
      <a
        href="https://support.upstream.tech/article/72-tasking"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more about tasking here
      </a>
      {'.'}
    </div>
  );
}

function renderImageryIsLoadingWarning() {
  return (
    <B.Callout icon={null} intent={B.Intent.WARNING} style={{margin: 0}}>
      Imagery is still loading, additional images may be available later.
    </B.Callout>
  );
}
