import {toMercator} from '@turf/projection';
import {bbox} from '@turf/turf';
import * as geojson from 'geojson';
import React, {CSSProperties} from 'react';

import COLORS from 'app/styles/colors.json';
import * as geojsonUtils from 'app/utils/geoJsonUtils';

import {renderGeojsonSvg} from '../OrderImageryModal/ImageryPreviewImage';

const WIDTH = 234;
const HEIGHT = 216;

const STROKE_WIDTH = 1.5;

/**
 * Makes a small SVG that shows the position of an active geometry in the feature’s outline.
 */
const LocationSvg: React.FunctionComponent<
  React.PropsWithChildren<{
    feature: geojson.Feature;

    activeGeometry?: geojson.Geometry;
    activeGeometryStyle?: CSSProperties;

    labelGeometry?: geojson.MultiPoint;
    labelText?: string[];

    windowBounds?: mapboxgl.LngLatBounds;

    width?: number;
    height?: number;
    padding?: number;

    alignment?: 'center' | 'top-right';
  }>
> = ({
  feature,
  activeGeometry,
  windowBounds,
  labelGeometry,
  labelText,
  width = WIDTH,
  height = HEIGHT,
  // Need enough padding to keep from clipping the stroke
  padding = STROKE_WIDTH / 2,
  alignment = 'center',
  activeGeometryStyle,
}) => {
  // TODO(maya): Had to add these casts when updating the turf/projection version. Clean this up
  // so these don't have to be casted
  const featureGeometryXY: geojson.Polygon | geojson.MultiPolygon = toMercator(feature.geometry) as
    | geojson.Polygon
    | geojson.MultiPolygon;

  const activeGeometryXY: geojson.Polygon | geojson.MultiPolygon | geojson.Point | undefined =
    activeGeometry &&
    (toMercator(activeGeometry) as
      | geojson.Polygon
      | geojson.MultiPolygon
      | geojson.Point
      | undefined);

  const labelGeometryXY: geojson.MultiPoint | undefined =
    labelGeometry && toMercator(labelGeometry);

  const windowBoundsGeometryXY: geojson.Polygon | undefined =
    windowBounds &&
    toMercator(
      geojsonUtils.bboxPolygon([
        windowBounds.getWest(),
        windowBounds.getSouth(),
        windowBounds.getEast(),
        windowBounds.getNorth(),
      ]).geometry
    );

  // The overall bounds needs to include all of the geometry that we’re showing.
  const boundsXY = bbox(
    geojsonUtils.featureCollection(
      [featureGeometryXY, activeGeometryXY, windowBoundsGeometryXY]
        .filter((g) => !!g)
        .map((g) => geojsonUtils.feature(g!, {}))
    )
  );
  const featureWidth = boundsXY[2] - boundsXY[0];
  const featureHeight = boundsXY[3] - boundsXY[1];

  const canvasHeight = height - 2 * padding;
  const canvasWidth = width - 2 * padding;

  const scale = Math.min(canvasWidth / featureWidth, canvasHeight / featureHeight);

  let offsetX: number;
  let offsetY: number;

  switch (alignment) {
    case 'center':
      offsetX = (canvasWidth - featureWidth * scale) / 2;
      offsetY = (canvasHeight - featureHeight * scale) / 2;
      break;

    case 'top-right':
      offsetX = canvasWidth - featureWidth * scale;
      // canvas is flipped in the y direction
      offsetY = canvasHeight - featureHeight * scale;
      break;
  }

  const featureXYToCanvasXY = ([x, y]) => [
    (x - boundsXY[0]) * scale + padding + offsetX,
    // canvas Y axis is flipped
    height - (y - boundsXY[1]) * scale - offsetY - padding,
  ];

  const activeStyle = React.useMemo(
    () => ({
      stroke: '#f5e050',
      strokeWidth: 1.5,
      fill: 'rgba(245, 224, 80, .5)',
      ...activeGeometryStyle,
    }),
    [activeGeometryStyle]
  );

  return (
    <div className="location-svg-map">
      <svg width={width} height={height}>
        {windowBoundsGeometryXY &&
          renderGeojsonSvg(windowBoundsGeometryXY, featureXYToCanvasXY, {
            style: {
              fill: 'rgba(163,165,167, 0.15)', ///'rgba(255, 255, 255, 1)',
              stroke: COLORS.darkGray,
              strokeDasharray: '1,1',
              strokeWidth: 1,
            },
          })}

        {renderGeojsonSvg(featureGeometryXY, featureXYToCanvasXY, {
          style: {
            stroke: COLORS.darkGray,
            strokeWidth: STROKE_WIDTH,
            fill: 'transparent',
          },
        })}

        {(activeGeometryXY?.type === 'Polygon' || activeGeometryXY?.type === 'MultiPolygon') &&
          renderGeojsonSvg(activeGeometryXY, featureXYToCanvasXY, {style: activeStyle})}

        {activeGeometryXY?.type === 'Point' &&
          (([x, y]) => (
            <circle cx={x} cy={y} r={1} style={{...activeStyle, fill: activeStyle.stroke}} />
          ))(featureXYToCanvasXY(activeGeometryXY.coordinates as [number, number]))}

        {labelGeometryXY &&
          labelText &&
          labelGeometryXY.coordinates.map((coords, i) => {
            const [x, y] = featureXYToCanvasXY(coords as [number, number]);
            return (
              <text
                key={i}
                x={x}
                y={y}
                style={{fill: COLORS.primary, fontWeight: 'bold'}}
                textAnchor="middle"
              >
                {labelText[i]}
              </text>
            );
          })}
      </svg>
    </div>
  );
};

export default LocationSvg;
