import {MathJsStatic, create, formatDependencies, unitDependencies} from 'mathjs';

import * as CONSTANTS from 'app/utils/constants';

const mathjs: Pick<MathJsStatic, 'unit' | 'format'> = create(
  {unitDependencies, formatDependencies},
  {}
) as any;

// Maps units in our system to mathjs units. This allows that library to do the conversion
// for us. A list of units can be found under the reference section at the following link
// https://mathjs.org/docs/datatypes/units.html
export const UNITS = {
  [CONSTANTS.UNIT_AREA_ACRE]: 'acres',
  [CONSTANTS.UNIT_AREA_KM2]: 'km^2',
  [CONSTANTS.UNIT_AREA_M2]: 'm^2',
  [CONSTANTS.UNIT_AREA_HECTARE]: 'hectares',
  [CONSTANTS.UNIT_CURRENCY_USD]: CONSTANTS.UNIT_CURRENCY_USD,
  [CONSTANTS.UNIT_ENERGY_KWATTH]: 'kW/h',
  [CONSTANTS.UNIT_ENERGY_KWATTHY]: 'kW/h/year',
  [CONSTANTS.UNIT_FLOW_CFS]: 'ft^3/s',
  [CONSTANTS.UNIT_FLOW_CMS]: 'm^3/s',
  [CONSTANTS.UNIT_LENGTH_FT]: 'ft',
  [CONSTANTS.UNIT_LENGTH_IN]: 'in',
  [CONSTANTS.UNIT_LENGTH_KM]: 'km',
  [CONSTANTS.UNIT_LENGTH_M]: 'm',
  [CONSTANTS.UNIT_PERCENT]: CONSTANTS.UNIT_PERCENT,
  [CONSTANTS.UNIT_PERCENT_UNIT]: CONSTANTS.UNIT_PERCENT_UNIT,
  [CONSTANTS.UNIT_POWER_KWATT]: 'kW',
  [CONSTANTS.UNIT_SPEED_MPH]: 'mi/hr',
  [CONSTANTS.UNIT_SPEED_MS]: 'm/s',
  [CONSTANTS.UNIT_TEMPERATURE_FAHRENHEIT]: 'degF',
  [CONSTANTS.UNIT_TEMPERATURE_CELSIUS]: 'degC',
  [CONSTANTS.UNIT_TEMPERATURE_KELVIN]: 'K',
  [CONSTANTS.UNIT_VOLUME_ACREFTY]: '(acre * ft)/year',
  [CONSTANTS.UNIT_VOLUME_INDAY]: 'in/days',
  [CONSTANTS.UNIT_VOLUME_KGY]: 'kg/year',
  [CONSTANTS.UNIT_VOLUME_LBSY]: 'lbs/year',
  [CONSTANTS.UNIT_VOLUME_M3]: 'm^3',
  [CONSTANTS.UNIT_VOLUME_MM6HR]: 'mm/sixhrs',
  [CONSTANTS.UNIT_VOLUME_TONY]: 'tons/year',
} as const;

export const UNITS_UI = {
  [CONSTANTS.UNIT_AREA_ACRE]: 'acres',
  [CONSTANTS.UNIT_AREA_KM2]: 'km²',
  [CONSTANTS.UNIT_AREA_M2]: 'm²',
  [CONSTANTS.UNIT_AREA_HECTARE]: 'hectares',
  [CONSTANTS.UNIT_CURRENCY_USD]: 'USD',
  [CONSTANTS.UNIT_ENERGY_KWATTH]: 'kW/h',
  [CONSTANTS.UNIT_ENERGY_KWATTHY]: 'kW/h/year',
  [CONSTANTS.UNIT_FLOW_CFS]: 'ft³/s',
  [CONSTANTS.UNIT_FLOW_CMS]: 'm³/s',
  [CONSTANTS.UNIT_LENGTH_FT]: 'ft',
  [CONSTANTS.UNIT_LENGTH_IN]: 'in',
  [CONSTANTS.UNIT_LENGTH_KM]: 'km',
  [CONSTANTS.UNIT_LENGTH_M]: 'm',
  [CONSTANTS.UNIT_PERCENT]: '%',
  [CONSTANTS.UNIT_PERCENT_UNIT]: '%',
  [CONSTANTS.UNIT_POWER_KWATT]: 'kW',
  [CONSTANTS.UNIT_SPEED_MPH]: 'mi/hr',
  [CONSTANTS.UNIT_SPEED_MS]: 'm/s',
  [CONSTANTS.UNIT_TEMPERATURE_FAHRENHEIT]: '°F',
  [CONSTANTS.UNIT_TEMPERATURE_CELSIUS]: '°C',
  [CONSTANTS.UNIT_TEMPERATURE_KELVIN]: 'K',
  [CONSTANTS.UNIT_VOLUME_ACREFTY]: 'acre-ft/year',
  [CONSTANTS.UNIT_VOLUME_ACREFT_ACRE_YEAR]: 'acre-ft/acre/year',
  [CONSTANTS.UNIT_VOLUME_INDAY]: 'in/days',
  [CONSTANTS.UNIT_VOLUME_KGY]: 'kg/year',
  [CONSTANTS.UNIT_VOLUME_LBSY]: 'lbs/year',
  [CONSTANTS.UNIT_VOLUME_LBS_ACRE_YEAR]: 'lbs/acre/year',
  [CONSTANTS.UNIT_VOLUME_M3]: 'm³',
  [CONSTANTS.UNIT_VOLUME_MM6HR]: 'mm/sixhrs',
  [CONSTANTS.UNIT_VOLUME_TONY]: 'tons/year',
  [CONSTANTS.UNIT_VOLUME_TON_ACRE_YEAR]: 'tons/acre/year',
};

const UNITS_CURRENCY: string[] = [UNITS[CONSTANTS.UNIT_CURRENCY_USD]];
const UNITS_ENERGY: string[] = [
  UNITS[CONSTANTS.UNIT_ENERGY_KWATTH],
  UNITS[CONSTANTS.UNIT_ENERGY_KWATTHY],
];
const UNITS_PERCENT: string[] = [UNITS[CONSTANTS.UNIT_PERCENT], UNITS[CONSTANTS.UNIT_PERCENT_UNIT]];

export const DEFAULT_DECIMALS = 2;

export interface FormattedMeasurement {
  value: string;
  unit: string;
  valueWithUnit: string;
}

export function formatMeasurement({
  value,
  decimals = DEFAULT_DECIMALS,
  unit: unitStr,
  unitTo: unitToStr,
}: {
  value: number | string;
  decimals?: number;
  unit: string;
  unitTo?: string;
}): FormattedMeasurement {
  const originalValue = value;

  // If the value is a string, remove the commas before checking if it's a
  // number.
  if (typeof value == 'string') {
    value = Number(value.replace(/,/g, ''));
  }

  // Return the provided values if the value is not a number, or if the unit is
  // not known.
  if (isNaN(value) || !UNITS[unitStr]) {
    const originalValueWithDecimals =
      typeof originalValue === 'number' ? originalValue.toFixed(decimals) : originalValue;
    value = formatNumber(originalValueWithDecimals.toString());
    return {value: value, unit: unitStr, valueWithUnit: `${value} ${unitStr}`};
  }

  // Map the provided unit to the unit required by mathjs.
  value = Number(value);
  const mathJsUnitStr: string = UNITS[unitStr];
  let mathJsUnitToStr: string | undefined = unitToStr && UNITS[unitToStr];

  // Determine which `unitTo` to use for energy units. mathjs defaults to the SI
  // unit, which is joules for energy. Our customers, however, prefer to see
  // energy values in watts per hour (per year).
  if (UNITS_ENERGY.includes(mathJsUnitStr)) {
    mathJsUnitToStr = _formatEnergyUnitTo(value, mathJsUnitStr);
  }

  // Format and return currency value.
  if (UNITS_CURRENCY.includes(mathJsUnitStr)) {
    return _formatCurrency(value, decimals);
  }

  // Format and return percent value.
  if (UNITS_PERCENT.includes(mathJsUnitStr)) {
    return _formatPercent(value, decimals);
  }

  let mathjsVal: any;

  if (unitStr) {
    mathjsVal = mathjs.unit(value, mathJsUnitStr);
    if (mathJsUnitToStr) {
      mathjsVal = mathjsVal.to(mathJsUnitToStr);
    }
  } else {
    mathjsVal = value;
  }

  // Format the value.
  const result = mathjs.format(mathjsVal, {
    notation: 'fixed',
    precision: decimals,
  });
  return _splitMeasurement(result);
}

///////////////////////////////////////////////////////////////////////////////
// INTERNAL FUNCTIONS
///////////////////////////////////////////////////////////////////////////////

function _formatCurrency(value: number, decimals: number) {
  const valueStr = formatNumbersWithSuffix(value, decimals);
  const unit = '$';
  return {
    value: valueStr,
    unit,
    valueWithUnit: unit + valueStr,
  };
}

function _formatPercent(value: number, decimals: number) {
  const valueStr = value.toFixed(decimals);
  const unit = '%';
  return {
    value: valueStr,
    unit,
    valueWithUnit: valueStr + unit,
  };
}

function _formatEnergyUnitTo(value: string | number, unit: string) {
  // Convert all energy units to either W/h or W/h year, which allows us to
  // determine the best unit to convert to (e.g., kW/h, MW/h, GW/h).
  let normalizedUnitTo = 'W/h';
  if (unit == UNITS[CONSTANTS.UNIT_ENERGY_KWATTHY]) {
    normalizedUnitTo += '/year';
  }

  // Determine what the value is when converted to the normalized unit (e.g., 1
  // kW/h to 1000 W/h).
  const mathJsValue = mathjs.unit(typeof value === 'number' ? value : parseFloat(value), unit);
  const options: math.FormatOptions = {notation: 'fixed', precision: 0};

  const normalizedMeasurement = mathjs.format(mathJsValue.to(normalizedUnitTo), options);

  // Prepend the normalized unit (e.g., W/h) with the SI prefix (e.g., G) and
  // return the concatenation (e.g., GW/h) to be used as the new "unitTo" value.
  //
  // parseFloat automatically gets rid of the trailing unit, and math.format
  // doesn’t add any commas or anything it can’t handle.
  const {symbol} = _getSiValueAndSymbol(parseFloat(normalizedMeasurement));
  return symbol + normalizedUnitTo;
}

export function formatNumbersWithSuffix(number: number, decimals: number) {
  // Replace digits with SI symbol (e.g., 1,000 to 1k).
  const {value: siValue, symbol: siSymbol} = _getSiValueAndSymbol(number);
  return (number / siValue).toFixed(decimals).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + siSymbol;
}

function _splitMeasurement(measurement: string) {
  const matches = measurement.match(/^(-?[,.\d]*)\s?(.*)?/)!;
  const value = formatNumber(matches[1]);
  const unit = _formatUnit(matches[2]);
  return {
    value,
    unit,
    valueWithUnit: `${value} ${unit}`,
  };
}

export function formatNumber(string: string) {
  // Add thousands comma separators for each group of 3 digits
  // before the decimals point.
  const stringParts = string.split('.');
  string = stringParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (stringParts.length == 2) {
    string = `${string}.${stringParts[1]}`;
  }

  return string;
}

function _formatUnit(string: string) {
  // Replace "(acre ft)" with "acre-ft".
  string = string.replace(/\(acre ft\)/g, 'acre-ft');

  // Replace "(h year)" with "h/year".
  string = string.replace(/\(h year\)/g, 'h/year');

  // Remove spaces.
  string = string.replace(/\s/g, '');

  return string;
}

function _getSiValueAndSymbol(value: number) {
  const si = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];

  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (value >= si[i].value) {
      break;
    }
  }

  return si[i];
}

/**
 * Returns a string of randomish characters. Not cryptographically secure, but
 * useful for keeping a small number of IDs from colliding.
 */
export function randomCharacters(length = 10) {
  // https://gist.github.com/6174/6062387
  return [...Array(length)].map((_) => ((Math.random() * 36) | 0).toString(36)).join('');
}

export function roundN(n: number, places: number) {
  const scale = Math.pow(10, places);
  return Math.round(n * scale) / scale;
}

export function roundToHundreths(n: number) {
  return roundN(n, 2);
}

export function roundToThousandths(n: number) {
  return roundN(n, 3);
}
