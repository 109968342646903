import firebase from 'firebase/app';
import geojson from 'geojson';
import * as I from 'immutable';
import 'firebase/auth';

import {NoteGraph} from 'app/components/AnalyzePolygonChart/types';
import {ShareLinkScene} from 'app/components/PublicMap';
import {ApiShareLinkConfig} from 'app/components/PublicMap/PublicShareView';
import {ManagePropertiesMode} from 'app/pages/ManageProperties';
import * as apiUtils from 'app/utils/apiUtils';
import * as CONSTANTS from 'app/utils/constants';
import {CameraOptions} from 'app/utils/mapUtils';

import {ApiLensConvertFeatureFile, ApiLensConvertFeatureUpdateFile} from './Admin';
import {
  APIAlertPolicyProperties,
  AlertPolicy,
  ApiAlertEnrollment,
  ApiAlertPolicy,
  ApiFeature,
  ApiFeatureCustomProperties,
  ApiFeatureData,
  ApiFeaturePropertyHistoryItem,
  ApiImageryOrder,
  ApiLensImageryRecord,
  ApiNote,
  ApiOrderableScene,
  ApiReport,
  ReportState,
} from './Feature';
import {
  ApiAlertCountsByFeatureId,
  ApiFeatureCollection,
  ApiNoteCountsByFeatureId,
} from './FeatureCollection';
import {ApiImageryPrices} from './Imagery';
import {
  ApiAccessSubscription,
  ApiBillingSessionResponse,
  ApiDefaultPaymentMethod,
  ApiInvoice,
  ApiListPremiumSourcesResponse,
  ApiNewCustomerSignupResponse,
  ApiNotification,
  ApiNotificationPref,
  ApiNotificationScope,
  ApiNotificationType,
  ApiOrganization,
  ApiOrganizationUser,
  ApiOverlay,
  ApiPaidLayerInvoice,
  ApiSignupToken,
  ApiUserRoleValue,
} from './Organization';
import {
  ApiImageryContract,
  ApiImageryContractWithProjects,
  ApiImageryPurchase,
  ApiImageryPurchaseBillingRecord,
  ApiNewProjectFeatureCollection,
  ApiProject,
} from './Project';
import {ApiTaskingPlan, ApiTaskingVendor} from './Tasking';

export type FeaturesApi = ReturnType<typeof _makeFeaturesApi>;
export interface ApiPublicLensResponse {
  features: ApiFeature[];
  overlay_feature_collections: ApiFeatureCollection[];
  data: ApiFeatureData[];
  scenes: ShareLinkScene[];
  description: string;
  note_geometry: geojson.Geometry | null;
  attachments: string[] | null;
  graph: NoteGraph | null;
  mapOptions: CameraOptions | null;
}

export default {
  projects: _makeProjectsApi(),
  featureCollections: _makeFeatureCollectionsApi(),
  // features is not present here because it is a child of featureCollections
  organizations: _makeOrganizationsApi(),
  public: (uuid: string) =>
    apiUtils.makeApiRequest<ApiPublicLensResponse>(`p/${uuid}`, null, undefined, {
      highVolumeApi: true,
    }),
  makeShareLink: (shareDetails: ApiShareLinkConfig) =>
    apiUtils.makeApiRequest<{id: string}>('p/share', null, {
      method: 'POST',
      body: JSON.stringify(shareDetails),
    }),
  reports: (featureId: number) => _makeReportsApi(featureId),
  searchFeatureCollections: (query: string) =>
    apiUtils.makeApiRequest<ApiFeatureCollection[]>(`search/featureCollections?query=${query}`),
  searchFeatures: (query: string, activeLensOnly = false, featureCollectionIds: number[] = []) => {
    let queryString = `?query=${query}&activeLensOnly=${activeLensOnly}`;
    if (featureCollectionIds) {
      queryString += `&featureCollectionId=${featureCollectionIds.join('&featureCollectionId=')}`;
    }
    return apiUtils.makeApiRequest<ApiFeature[]>(`search/features${queryString}`);
  },
  searchProjects: (query: string) =>
    apiUtils.makeApiRequest<ApiProject[]>(`search/projects?query=${query}`),
  user: {
    fetch: () => apiUtils.makeApiRequest<ApiOrganizationUser>('user'),
  },

  signup: {
    fetch: (token: string) => apiUtils.makeApiRequest<ApiSignupToken>(`signup/${token}`),
    accept: (token: string, args: {name: string}) =>
      apiUtils.makeApiRequest<void>(`signup/${token}/accept`, null, {
        method: 'POST',
        body: JSON.stringify(args),
      }),
    subscribe: (
      organizationName: string,
      accountEmail: string,
      subscriptionType: string,
      subscriptionAddOns: string[],
      taxExempt: boolean,
      billingAddress: object,
      freeTrial: string | undefined,
      isTest: boolean
    ) =>
      apiUtils.makeApiRequest<ApiNewCustomerSignupResponse>(`signup/lens/subscribe`, null, {
        method: 'POST',
        body: JSON.stringify({
          organizationName,
          accountEmail,
          subscriptionType,
          subscriptionAddOns,
          taxExempt,
          billingAddress,
          isTest,
          ...(freeTrial && {freeTrial}),
        }),
      }),
  },

  prefs: {
    notifications: {
      fetch: (params: apiUtils.ApiParams = {}) => {
        const request = (p: apiUtils.ApiParams) =>
          apiUtils.makeApiRequest<ApiNotificationPref[]>(`prefs/notifications`, p);
        return makeRequestWithPages(request, params, {getAllPages: true});
      },
      set: (
        projectId: string,
        notificationType: ApiNotificationType,
        scope: ApiNotificationScope
      ) =>
        apiUtils.makeApiRequest<ApiNotificationPref>(
          `prefs/notifications/${projectId}/${notificationType}`,
          null,
          {
            method: 'PUT',
            body: JSON.stringify({scope}),
          }
        ),
    },
  },

  imagery: {
    purchases: {
      fetch(
        contractId: number,
        params: apiUtils.ApiParams = {},
        options: apiUtils.ListOptions = {}
      ) {
        const request = (p: apiUtils.ApiParams) =>
          apiUtils.makeApiRequest<ApiImageryPurchase[]>(
            `imagery/imagery_contracts/${contractId}/purchases`,
            p,
            {
              method: 'GET',
            }
          );

        return makeRequestWithPages(request, params, options);
      },

      fetchGen(
        contractId: number,
        params: apiUtils.ApiParams = {},
        options: apiUtils.ListOptions = {}
      ) {
        const request = (p: apiUtils.ApiParams) =>
          apiUtils.makeApiRequest<ApiImageryPurchase[]>(
            `imagery/imagery_contracts/${contractId}/purchases`,
            p,
            {method: 'GET'}
          );

        return apiUtils.collectPages(request, params, options);
      },
    },
    billingRecords: {
      fetchGen(
        contractId: number,
        params: apiUtils.ApiParams = {},
        options: apiUtils.ListOptions = {}
      ) {
        const request = (p: apiUtils.ApiParams) =>
          apiUtils.makeApiRequest<ApiImageryPurchaseBillingRecord[]>(
            `imagery/imagery_contracts/${contractId}/billingRecords`,
            p,
            {method: 'GET'}
          );

        return apiUtils.collectPages(request, params, options);
      },
      fetch(
        contractId: number,
        params: apiUtils.ApiParams = {},
        options: apiUtils.ListOptions = {}
      ) {
        const request = (p: apiUtils.ApiParams) =>
          apiUtils.makeApiRequest<ApiImageryPurchaseBillingRecord[]>(
            `imagery/imagery_contracts/${contractId}/billingRecords`,
            p,
            {method: 'GET'}
          );

        return makeRequestWithPages(request, params, options);
      },
    },
    prices: {
      fetch: () =>
        apiUtils.makeApiRequest<ApiImageryPrices[]>(`imagery/imageryPrices`, null, {
          method: 'GET',
        }),
    },
  },

  tasking: {
    optimizePlan: (featureIds: number[], vendor: ApiTaskingVendor) =>
      /** Conversion hack here because makeApiRequest returns a value that
       * assumes that the data is in a “data” property, when it’s actually just
       * the ApiTaskingPlan itself. */
      apiUtils.makeApiRequest<ApiTaskingPlan>(
        `tasking/optimizePlan`,
        null,
        {
          method: 'POST',
          body: JSON.stringify({featureIds, vendor}),
        },
        {slowApi: true}
      ) as unknown as Promise<I.ImmutableOf<ApiTaskingPlan>>,

    submitPlan: (
      plan: ApiTaskingPlan,
      startDate: string,
      endDate: string,
      spectralBands: string,
      additionalInfo: string,
      signatoryInfo: string,
      planCost: string
    ) =>
      apiUtils.makeApiRequest<void>(`tasking/submitPlan`, null, {
        method: 'POST',
        body: JSON.stringify({
          plan,
          startDate,
          endDate,
          spectralBands,
          additionalInfo,
          signatoryInfo,
          planCost,
        }),
      }),
  },

  lens: {
    uploader: {
      convertFile: (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiUtils.makeApiRequest<ApiLensConvertFeatureFile>(
          'lens/uploader/convertToGeoJSON',
          null,
          {
            method: 'POST',
            body: formData,
          },
          {slowApi: true}
        );
      },
      convertOverlay: (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiUtils.makeApiRequest<ApiLensConvertFeatureFile>(
          'lens/uploader/convertOverlayToGeoJSON',
          null,
          {
            method: 'POST',
            body: formData,
          },
          {slowApi: true}
        );
      },
      convertUpdateFile: (file: File, fcId: number, nameProp?: string) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('feature_collection_id', fcId.toString());
        if (nameProp) {
          formData.append('name_prop', nameProp);
        }

        return apiUtils.makeApiRequest<ApiLensConvertFeatureUpdateFile>(
          'lens/uploader/previewPropertyUpdate',
          null,
          {
            method: 'POST',
            body: formData,
          },
          {slowApi: true}
        );
      },
      submitUpdates: (
        fcId: number,
        gcsFilePath: string,
        matchedUpdates: Record<number, number>,
        newFeatures: number[],
        nameEdits: Record<number, string>,
        partNameEdits: Record<number, string>
      ) => {
        return apiUtils.makeApiRequest<{migrated_feature_ids: string[]; problems: string[]}>(
          'lens/uploader/submitPropertyUpdate',
          null,
          {
            method: 'POST',
            body: JSON.stringify({
              gcs_shapefile_path: gcsFilePath,
              feature_collection_id: fcId,
              matched_updates: matchedUpdates,
              new_features: newFeatures,
              name_edits: nameEdits,
              part_name_edits: partNameEdits,
            }),
          }
        );
      },
    },
  },
};

////////////////////////////////////////////////////////////////////////////////
// PROJECTS
////////////////////////////////////////////////////////////////////////////////

function _makeProjectsApi() {
  return {
    ...apiUtils._createUpdateApiResource<ApiProject, string>('projects'),

    create(
      organizationId: string,
      name: string,
      product: string,
      layersBySource: ApiOrganization['defaultLayersBySource']
    ) {
      return apiUtils.makeApiRequest<ApiNewProjectFeatureCollection>(`projects`, null, {
        method: 'POST',
        body: JSON.stringify({organizationId, name, product, layersBySource}),
      });
    },

    delete(projectId: string) {
      return apiUtils.makeApiRequest<apiUtils.NeverResponse>(`projects/${projectId}`, null, {
        method: 'DELETE',
      });
    },

    putFeatureCollectionIds(projectId: string, featureCollectionIds: number[]) {
      return apiUtils.makeApiRequest<ApiProject>(
        `projects/${projectId}/featureCollectionIds`,
        null,
        {
          method: 'PUT',
          body: JSON.stringify(featureCollectionIds),
        }
      );
    },

    currentImageryContract(projectId: string) {
      return apiUtils.makeApiRequest<ApiImageryContract | null>(
        `projects/${projectId}/imagery_contracts/current`
      );
    },

    addOverlay(projectId: string, featureCollectionId: number) {
      return apiUtils.makeApiRequest<ApiProject>(`projects/${projectId}/addOverlay`, null, {
        method: 'POST',
        body: JSON.stringify({feature_collection_id: featureCollectionId}),
      });
    },

    removeOverlay(projectId: string, featureCollectionId: number) {
      return apiUtils.makeApiRequest<ApiProject>(`projects/${projectId}/removeOverlay`, null, {
        method: 'POST',
        body: JSON.stringify({feature_collection_id: featureCollectionId}),
      });
    },
  };
}

////////////////////////////////////////////////////////////////////////////////
// FEATURE COLLECTIONS
////////////////////////////////////////////////////////////////////////////////

function _makeFeatureCollectionsApi() {
  const {
    list,
    fetch: fetchFc,
    update,
  } = apiUtils._createApiResource<ApiFeatureCollection, number>('featureCollections');

  return {
    // list just used for api tests
    list,
    fetch: fetchFc,
    update,

    batchFetch(params: apiUtils.ApiParams) {
      return apiUtils.makeApiRequest<ApiFeatureCollection[]>('featureCollections/batch', params);
    },

    features(fcId: number) {
      return _makeFeaturesApi(fcId);
    },

    uploadFeaturesFromFile(
      fcId: number,
      gcsFilePath: string,
      nameEdits: Record<number, string>,
      partNameEdits: Record<number, string | null> | null,
      excludedFeatureIds: number[],
      mode: ManagePropertiesMode,
      params: apiUtils.ApiParams = {}
    ) {
      return apiUtils.makeApiRequest<void>(`featureCollections/${fcId}/upload_features`, params, {
        method: 'POST',
        body: JSON.stringify({
          gcs_shapefile_path: gcsFilePath,
          name_props: [],
          part_name_prop: '',
          name_edits: nameEdits,
          part_name_edits: partNameEdits,
          drop_feature_indexes: excludedFeatureIds,
          mode: mode,
        }),
      });
    },

    uploadOverlay(
      name: string,
      gcsFilePath: string,
      nameEdits: Record<number, string>,
      excludedFeatureIds: number[],
      params: apiUtils.ApiParams = {}
    ) {
      return apiUtils.makeApiRequest<void>(`featureCollections/overlay`, params, {
        method: 'POST',
        body: JSON.stringify({
          name: name,
          gcs_shapefile_path: gcsFilePath,
          name_props: [],
          part_name_prop: '',
          name_edits: nameEdits,
          part_name_edits: {}, //the backend expects this field, but we're not sending anything from the frontend
          drop_feature_indexes: excludedFeatureIds,
        }),
      });
    },

    listImagerySummary(
      pId: string,
      fcId: number,
      year: number,
      params: apiUtils.ApiParams = {perPage: 500},
      options: apiUtils.ListOptions = {getAllPages: 2}
    ) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiLensImageryRecord[]>(
          `projects/${pId}/featureCollections/${fcId}/overview/imagery_summary/${year}`,
          p
        );

      return apiUtils.collectPages(request, params, options);
    },

    fetchNoteCountsByFeatureId(pId: string, fcId: number, noteType: ApiNote['noteType']) {
      return apiUtils.makeApiRequest<ApiNoteCountsByFeatureId>(
        `projects/${pId}/featureCollections/${fcId}/overview/noteCounts/${noteType}`
      );
    },

    fetchAlertCountsByFeatureId(pId: string, fcId: number) {
      return apiUtils.makeApiRequest<ApiAlertCountsByFeatureId>(
        `projects/${pId}/featureCollections/${fcId}/overview/alertCounts`
      );
    },

    archiveFeatureCollection(fcId: number, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<void>(`featureCollections/${fcId}`, params, {
        method: 'DELETE',
      });
    },

    addLayer(fcId: number, layers: Record<string, string[]>) {
      return apiUtils.makeApiRequest<ApiFeatureCollection>(
        `featureCollections/${fcId}/layers`,
        null,
        {
          method: 'POST',
          body: JSON.stringify({layers: layers}),
        }
      );
    },

    removeLayer(fcId: number, layers: Record<string, string[]>) {
      return apiUtils.makeApiRequest<ApiFeatureCollection>(
        `featureCollections/${fcId}/layers`,
        null,
        {
          method: 'DELETE',
          body: JSON.stringify({layers: layers}),
        }
      );
    },

    async export(fcId: number) {
      const auth = firebase.auth();
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        const route = apiUtils.getApiRoute(
          `featureCollections/${fcId}/export`,
          {
            apiToken: token,
            cOrgId: apiUtils.contextOrganizationId,
          },
          {slowApi: true}
        );
        // because the API properly sets the Content-Disposition attachment
        // header, we just need to open it to download!
        window.open(route);
      }
    },

    createAlertPolicy(
      params: Pick<
        AlertPolicy,
        'featureCollectionId' | 'sourceId' | 'layerId' | 'alertType' | 'description' | 'name'
      > & {
        properties: APIAlertPolicyProperties;
      }
    ) {
      const {featureCollectionId, sourceId, layerId, alertType, properties, name, description} =
        params;
      return apiUtils.makeApiRequest<ApiAlertPolicy>(
        `featureCollections/${featureCollectionId}/alertPolicies`,
        null,
        {
          method: 'POST',
          body: JSON.stringify({
            name: name,
            description: description,
            source_id: sourceId,
            layer_id: layerId,
            alert_type: alertType,
            properties: properties,
          }),
        }
      );
    },

    updateAlertPolicy(
      params: Pick<
        AlertPolicy,
        'featureCollectionId' | 'sourceId' | 'layerId' | 'alertType' | 'description' | 'name' | 'id'
      > & {
        properties: APIAlertPolicyProperties;
      }
    ) {
      const {featureCollectionId, sourceId, layerId, alertType, properties, name, description} =
        params;
      return apiUtils.makeApiRequest<ApiAlertPolicy>(
        `featureCollections/${featureCollectionId}/alertPolicies/${params.id}`,
        null,
        {
          method: 'PATCH',
          body: JSON.stringify({
            name: name,
            description: description,
            source_id: sourceId,
            layer_id: layerId,
            alert_type: alertType,
            properties: properties,
          }),
        }
      );
    },

    archiveAlertPolicy(featureCollectionId: number | string, alertPolicyId: number) {
      return apiUtils.makeApiRequest<ApiAlertPolicy>(
        `featureCollections/${featureCollectionId}/alertPolicies/${alertPolicyId}`,
        null,
        {
          method: 'PATCH',
          body: JSON.stringify({
            is_archived: true,
          }),
        }
      );
    },

    getAlertPolicies(featureCollectionId: number | string) {
      return apiUtils.makeApiRequest<ApiAlertPolicy[]>(
        `featureCollections/${featureCollectionId}/alertPolicies`,
        null,
        {method: 'GET'}
      );
    },

    getAlertPolicyEnrollments(featureCollectionId: string | number, alertPolicyId) {
      return apiUtils.makeApiRequest<ApiAlertEnrollment[]>(
        `featureCollections/${featureCollectionId}/alertPolicies/${alertPolicyId}/enrollments`,
        null,
        {method: 'GET'}
      );
    },

    enrollFeaturesInAlertPolicy(
      featureCollectionId: string | number,
      alertPolicyId: number,
      featureIds: number[]
    ) {
      return apiUtils.makeApiRequest<ApiAlertEnrollment[]>(
        `featureCollections/${featureCollectionId}/alertPolicies/${alertPolicyId}/enroll`,
        null,
        {
          method: 'POST',
          body: JSON.stringify({
            name: name,
            feature_ids: featureIds,
          }),
        }
      );
    },

    unenrollFeaturesInAlertPolicy(
      featureCollectionId: string | number,
      alertPolicyId: number,
      featureIds: number[]
    ): Promise<apiUtils.ApiResponse<{numDeletedCount: number}>> {
      return apiUtils.makeApiRequest<{numDeletedCount: number}>(
        `featureCollections/${featureCollectionId}/alertPolicies/${alertPolicyId}/unenroll`,
        null,
        {
          method: 'POST',
          body: JSON.stringify({
            name: name,
            feature_ids: featureIds,
          }),
        }
      );
    },
  };
}

////////////////////////////////////////////////////////////////////////////////
// FEATURES
////////////////////////////////////////////////////////////////////////////////

function _makeFeaturesApi(fcId: number) {
  const routeBase = `featureCollections/${fcId}/features`;
  return {
    ...apiUtils._createApiResource<ApiFeature, number>(`${routeBase}`),

    data(featureId: number, params: apiUtils.ApiParams = {}, options: apiUtils.ListOptions = {}) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiFeatureData[]>(`${routeBase}/${featureId}/data`, p);

      return makeRequestWithPages(request, params, options);
    },

    /**
     * Similar to data(), but yields streaming results before returning the
     * list of data.
     */
    dataGen(
      featureId: number,
      params: apiUtils.ApiParams = {perPage: 200},
      options: apiUtils.ListOptions = {getAllPages: 2}
    ) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiFeatureData[]>(`${routeBase}/${featureId}/data`, p);

      return apiUtils.collectPages(request, params, options);
    },

    batchFetch(params: apiUtils.ApiParams) {
      return apiUtils.makeApiRequest<ApiFeature[]>(`${routeBase}/batch`, params);
    },

    batchPatch(
      id: I.List<number>,
      changes: I.MergesInto<I.MapAsRecord<I.ImmutableFields<ApiFeature>>>
    ) {
      return apiUtils.makeApiRequest<ApiFeature[]>(
        `${routeBase}/batch`,
        {id},
        {
          method: 'PATCH',
          body: JSON.stringify(changes),
        }
      );
    },

    batchArchive(id: I.List<number>) {
      return apiUtils.makeApiRequest<apiUtils.NeverResponse>(
        `${routeBase}/batch`,
        {id},
        {
          method: 'DELETE',
        }
      );
    },

    moveFeatures(featureCollectionId: number, projectId: string, featureIds: number[]) {
      return apiUtils.makeApiRequest<ApiFeature[]>(`${routeBase}/move_features`, null, {
        method: 'POST',
        body: JSON.stringify({
          feature_ids: featureIds,
          project_id: projectId,
          feature_collection_id: featureCollectionId,
        }),
      });
    },

    properties(fId: number, changes: I.MapAsRecord<Partial<ApiFeatureCustomProperties>>) {
      return apiUtils.makeApiRequest<apiUtils.NeverResponse>(
        `${routeBase}/${fId}/properties`,
        // TODO(fiona): Double-check to make sure it’s correct to *also* pass
        // the changes as params???
        changes as any,
        {
          method: 'PATCH',
          body: JSON.stringify(changes),
        }
      );
    },

    propertyHistory(
      fId: number,
      params: apiUtils.ApiParams = {},
      options: apiUtils.ListOptions = {}
    ) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiFeaturePropertyHistoryItem[]>(
          `${routeBase}/${fId}/propertyHistory`,
          p
        );

      return makeRequestWithPages(request, params, options);
    },
    orderImagery(pId: string, fId: number, data: ApiImageryOrder) {
      return apiUtils.makeApiRequest<ApiFeature>(
        `imagery/projects/${pId}/${routeBase}/${fId}`,
        null,
        {
          method: 'POST',
          body: JSON.stringify(data),
        }
      );
    },
    orderableScenes(pId: string, fId: number, params: apiUtils.ApiParams = {}) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiOrderableScene[]>(
          `projects/${pId}/featureCollections/${fcId}/overview/${fId}/paid_scenes`,
          p
        );

      return makeRequestWithPages(request, params, {getAllPages: true});
    },
    async exportPng(fId: number, sourceId: string, layerKey: string, sensingTime: string) {
      const route = apiUtils.getApiRoute(
        `export/raster/${fId}/${sourceId}/${layerKey}/${sensingTime}.png`,
        null,
        {slowApi: true}
      );

      const auth = firebase.auth();
      const headers = new Headers();
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        if (token) {
          headers.append('Authorization', token);
        }
        if (apiUtils.contextOrganizationId) {
          headers.append('x-context-organization-id', apiUtils.contextOrganizationId);
        }
      }
      const response = await fetch(route, {method: 'GET', headers});
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const blob = await response.blob();
      return blob;
    },
    async exportGeotiff(
      fId: number,
      sourceId: string,
      layerId: string,
      sensingTime: string,
      filename: string
    ) {
      return apiUtils.makeApiRequest<string>(
        `export/raster/${fId}/${sourceId}/${layerId}/${sensingTime}.tif`,
        {filename}
      );
    },
    // TODO(anthony): add sourceIds: string[]
    async exportGif(fId: number, layerIds: string[], sensingTimes: string[]) {
      const route = apiUtils.getApiRoute(
        `export/raster/gif/${fId}`,
        // TODO(anthony): add sourceIds
        {layerIds, sensingTimes},
        {slowApi: true}
      );

      const auth = firebase.auth();
      const headers = new Headers();
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        if (token) {
          headers.append('Authorization', token);
        }
        if (apiUtils.contextOrganizationId) {
          headers.append('x-context-organization-id', apiUtils.contextOrganizationId);
        }
      }
      const response = await fetch(route, {method: 'GET', headers});
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const blob = await response.blob();
      return blob;
    },

    listNotes(
      pId: string,
      fId: number,
      params: apiUtils.ApiParams = {},
      options: apiUtils.ListOptions = {getAllPages: true}
    ) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiNote[]>(`projects/${pId}/${routeBase}/${fId}/notes`, p);

      return makeRequestWithPages(request, params, options);
    },

    createNote(
      pId: string,
      fId: number,
      data: Pick<
        ApiNote,
        | 'text'
        | 'geometry'
        | 'attachments'
        | 'cursorKey'
        | 'cursorType'
        | 'layerKey'
        | 'graph'
        | 'tagIds'
        | 'properties'
      >
    ) {
      return apiUtils.makeApiRequest<ApiNote>(`projects/${pId}/${routeBase}/${fId}/notes`, null, {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },

    patchNote(
      pId: string,
      fId: number,
      noteId: number,
      data: I.MergesInto<I.ImmutableOf<ApiNote>> | Partial<ApiNote>
    ) {
      return apiUtils.makeApiRequest<ApiNote>(
        `projects/${pId}/${routeBase}/${fId}/notes/${noteId}`,
        null,
        {
          method: 'PATCH',
          body: JSON.stringify(data),
        }
      );
    },

    summarizeNotes: async function* (
      firebaseToken: string,
      pId: string,
      fId: number,
      noteIds: (string | number)[]
    ) {
      const route = `projects/${pId}/${routeBase}/${fId}/notes/summarize`;
      const url = apiUtils.getApiRoute(route, null, {slowApi: true});
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', firebaseToken);

      if (apiUtils.contextOrganizationId) {
        headers.append('x-context-organization-id', apiUtils.contextOrganizationId);
      }
      const fetchInit = {
        method: 'POST',
        headers,
        body: JSON.stringify({noteIds}),
      };
      const response = await fetch(url, fetchInit);
      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let acc = '';
      while (true) {
        const {done, value} = await reader!.read();

        acc += decoder.decode(value);
        yield acc;

        if (done) {
          return acc;
        }
      }
    },
  };
}

////////////////////////////////////////////////////////////////////////////////
// REPORTS
////////////////////////////////////////////////////////////////////////////////

function _makeReportsApi(featureId: number) {
  const routeBase = `/features/${featureId}/reports`;
  return {
    get(reportId: number) {
      return apiUtils.makeApiRequest<ApiReport<ReportState>>(`${routeBase}/${reportId}`, null, {
        method: 'GET',
      });
    },

    getList() {
      return apiUtils.makeApiRequest<ApiReport<string>>(routeBase, null, {
        method: 'GET',
      });
    },

    create(report: ReportState, title: string, isMultilocation: boolean) {
      return apiUtils.makeApiRequest<ApiReport<ReportState>>(`${routeBase}`, null, {
        method: 'POST',
        body: JSON.stringify({
          data: report,
          title: title,
          isMultilocation: isMultilocation,
        }),
      });
    },

    update(reportId: number, report: ReportState) {
      return apiUtils.makeApiRequest<ApiReport<ReportState>>(`${routeBase}/${reportId}`, null, {
        method: 'PATCH',
        body: JSON.stringify({
          data: report,
        }),
      });
    },

    updateTitle(reportId: number, newTitle: string) {
      return apiUtils.makeApiRequest<ApiReport<ReportState>>(`${routeBase}/${reportId}`, null, {
        method: 'PATCH',
        body: JSON.stringify({
          title: newTitle,
        }),
      });
    },

    archive(reportId: number) {
      return apiUtils.makeApiRequest<ApiReport<ReportState>>(`${routeBase}/${reportId}`, null, {
        method: 'PATCH',
        body: JSON.stringify({
          isArchived: true,
        }),
      });
    },
  };
}

////////////////////////////////////////////////////////////////////////////////
// ORGANIZATIONS
////////////////////////////////////////////////////////////////////////////////

function _makeOrganizationsApi() {
  return {
    ...apiUtils._createApiResource<ApiOrganization, string, never>('organizations'),

    users(orgId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiOrganizationUser[]>(
        `organizations/${orgId}/users`,
        params,
        undefined
      );
    },

    signupTokens(orgId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiSignupToken[]>(
        `organizations/${orgId}/signup_tokens`,
        params,
        undefined
      );
    },

    contracts(orgId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiImageryContractWithProjects[]>(
        `organizations/${orgId}/contracts`,
        params,
        undefined
      );
    },

    invoices: (orgId: string) => {
      return apiUtils.makeApiRequest<ApiInvoice[]>(
        `imagery/imagery_contracts/${orgId}/invoices`,
        null,
        {
          method: 'GET',
        }
      );
    },

    upcomingInvoice(orgId: string, subscriptionId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiImageryContract[]>(
        `organizations/${orgId}/subscriptions/${subscriptionId}/upcoming_invoice`,
        params
      );
    },

    accessSubscription(orgId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiAccessSubscription>(
        `organizations/${orgId}/access_subscription`,
        params,
        undefined
      );
    },

    async deleteUser(userId: string) {
      await apiUtils.makeApiRequest<unknown>(`user/${userId}`, {}, {method: 'DELETE'});
    },

    setUserRole(userId: string, role: ApiUserRoleValue['role']) {
      const changes: ApiUserRoleValue = {user_id: userId, role};

      return apiUtils.makeApiRequest<ApiUserRoleValue>(
        `user/update_role`,
        {},
        {
          method: 'PATCH',
          body: JSON.stringify(changes),
        }
      );
    },

    // While we currently only support updates to the user’s name, this method
    // may be extended to update other properties in the future.
    updateUser(userId: string, update: Pick<ApiOrganizationUser, 'name'>) {
      return apiUtils.makeApiRequest<ApiOrganizationUser>(
        `user/update_profile`,
        {},
        {
          method: 'PATCH',
          body: JSON.stringify({user_id: userId, ...update}),
        }
      );
    },

    updateEmail(email: string) {
      return apiUtils.makeApiRequest<ApiOrganizationUser>(
        `user/update_email`,
        {},
        {
          method: 'PATCH',
          body: JSON.stringify({email}),
        }
      );
    },

    // Note that settingsUpdate is updated shallowly - keep settings keys not too deeply nested to limit complexity
    updateSettings(settingsUpdate: Partial<ApiOrganizationUser['settings']>) {
      return apiUtils.makeApiRequest<Pick<ApiOrganizationUser, 'id' | 'settings'>>(
        'user/update_settings',
        {},
        {method: 'PATCH', body: JSON.stringify(settingsUpdate)}
      );
    },

    getHubspotToken() {
      return apiUtils.makeApiRequest<{token: string}>(`user/hubspot_token`);
    },

    childOrganizations(orgId: string, params: apiUtils.ApiParams = {}) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiOrganization[]>(
          `organizations/${orgId}/children`,
          p,
          {},
          {noContextOrg: true}
        );

      return makeRequestWithPages(request, params, {getAllPages: true});
    },

    setLogo(orgId: string, file?: File) {
      const formData = new FormData();
      formData.append('logo', file || '');

      return apiUtils.makeApiRequest<ApiOrganization>(`organizations/${orgId}/logo`, null, {
        method: 'POST',
        body: formData,
      });
    },

    getOverlays(orgId: string, params: apiUtils.ApiParams = {}) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiOverlay[]>(
          `organizations/${orgId}/overlays`,
          p,
          {
            method: 'GET',
          },
          {}
        );
      return makeRequestWithPages(request, params, {});
    },

    listPremiumSources(orgId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiListPremiumSourcesResponse>(
        `organizations/${orgId}/premium_sources`,
        params,
        {
          method: 'GET',
        },
        {}
      );
    },
    previewPaidLayerInvoice(
      orgId: string,
      sourceId: string,
      billingInterval: string,
      params: apiUtils.ApiParams = {}
    ) {
      return apiUtils.makeApiRequest<ApiPaidLayerInvoice>(
        `organizations/${orgId}/paid_layer/${sourceId}/${billingInterval}`,
        params,
        {
          method: 'GET',
        },
        {}
      );
    },
    addPaidLayer(
      orgId: string,
      sourceId: string,
      billingInterval: string,
      startDate: number,
      billingCycleAnchor: number,
      couponId: string | undefined = undefined,
      params: apiUtils.ApiParams = {}
    ) {
      return apiUtils.makeApiRequest<{clientSecret: string}>(
        `organizations/${orgId}/paid_layer/${sourceId}/${billingInterval}`,
        params,
        {
          method: 'POST',
          body: JSON.stringify({
            startDate,
            billingCycleAnchor,
            couponId,
          }),
        },
        {}
      );
    },
    // This session url expires after 5 min, make this request on user click to be safe
    // and avoid having an expired url
    getBillingSession(orgId: string, returnUrl: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiBillingSessionResponse>(
        `organizations/${orgId}/billingSession`,
        params,
        {
          method: 'POST',
          body: JSON.stringify({
            returnUrl,
          }),
        },
        {}
      );
    },
    getBillingInfo(orgId: string, params: apiUtils.ApiParams = {}) {
      return apiUtils.makeApiRequest<ApiDefaultPaymentMethod>(
        `organizations/${orgId}/billingInfo`,
        params,
        {
          method: 'GET',
        },
        {}
      );
    },
    cancelSubscription(
      orgId: string,
      subscriptionId: string,
      comment: string,
      params: apiUtils.ApiParams = {}
    ) {
      return apiUtils.makeApiRequest(
        `organizations/${orgId}/subscription/${subscriptionId}`,
        params,
        {
          method: 'DELETE',
          body: JSON.stringify({
            comment,
          }),
        },
        {}
      );
    },
    listNotifications(
      orgId: string,
      params: apiUtils.ApiParams = {},
      options: apiUtils.ListOptions = {getAllPages: true}
    ) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiNotification[]>(`organizations/${orgId}/notificationEvents`, p);

      return makeRequestWithPages(request, params, options);
    },
    assignedFeatures(
      orgId: string,
      params: apiUtils.ApiParams = {},
      options: apiUtils.ListOptions = {getAllPages: true}
    ) {
      const request = (p: apiUtils.ApiParams) =>
        apiUtils.makeApiRequest<ApiFeature[]>(`organizations/${orgId}/assignedFeatures`, p);

      return makeRequestWithPages(request, params, options);
    },
  };
}

////////////////////////////////////////////////////////////////////////////////
// ADMIN
////////////////////////////////////////////////////////////////////////////////

export interface StripeContractDescription {
  isTest: boolean;
  organizationId: string;
  stripeCustomerId: string;

  startDateYyyyMmDd: string;
  endDateYyyyMmDd: string | null;
  accessSubscriptionType: CONSTANTS.LENS_ACCESS_SUBSCRIPTIONS_TYPE;

  createAccessSubscription: boolean;
  customAccessSubscriptionPrice: null | {
    amountCents: number;
    interval: 'day' | 'week' | 'month' | 'year';
    intervalCount: number;
  };

  accessSubscriptionRecurringItems: {
    addon: CONSTANTS.LENS_ADDONS_TYPE;
    amountCents: number;
  }[];

  accessSubscriptionOneTimeItems: {
    description: string;
    amountCents: number;
  }[];

  createImagerySubscription: boolean;
  imageryContractName: string;
  imageryPrepaidDollars: string;
  imageryNteDollars: string;
  imageryProjectIds: string[];
}

function makeRequestWithPages<T>(
  request: (p: apiUtils.ApiParams) => Promise<apiUtils.ApiResponse<T[]>>,
  params: apiUtils.ApiParams,
  options: apiUtils.ListOptions
) {
  if (options.getAllPages) {
    return apiUtils.getAllPages(
      request,
      params,
      typeof options.getAllPages === 'number' ? options.getAllPages : undefined
    );
  } else {
    return request(params);
  }
}
