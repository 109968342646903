import useSize from '@react-hook/size';
import React from 'react';

export default function WithWidth({children}: {children: (width: number) => React.ReactNode}) {
  const ref = React.useRef(null);
  const [width] = useSize(ref);

  return (
    <div ref={ref} style={{width: '100%'}}>
      {!!width && children(width)}
    </div>
  );
}
