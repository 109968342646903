import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import I from 'immutable';
import moment from 'moment';
import React, {CSSProperties, MouseEvent} from 'react';

import hiddenThumbnailSrc from 'app/assets/images/hidden-thumbnail.png';
import {ApiFeature, ApiFeatureData, ApiOrderableScene} from 'app/modules/Remote/Feature/types';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import COLORS from 'app/styles/colors.json';
import * as featureUtils from 'app/utils/featureUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {THUMBNAIL_BLOCKED_SOURCES} from '../OrderImageryModal';
import cs from './styles.styl';

export type Props = {
  organization: I.ImmutableOf<ApiOrganization>;
  layerKey: string;

  className?: string;
  style?: CSSProperties;

  isOrdered: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  renderRightElement?: () => null | React.ReactElement;

  blockedSources?: string[];

  firebaseToken: string | null;
  showThumbnail?: boolean;
} & (
  | {
      orderableScene: I.ImmutableOf<ApiOrderableScene>;
      featureDatum?: undefined;
    }
  | {
      featureDatum: I.ImmutableOf<ApiFeatureData>;
      orderableScene?: undefined;
    }
);

const DateSelectCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  layerKey,
  featureDatum,
  orderableScene,
  organization,
  className,
  style,
  onClick,
  renderRightElement,
  isOrdered,
  blockedSources = THUMBNAIL_BLOCKED_SOURCES,
  firebaseToken,
  showThumbnail = true,
}) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  let date: moment.Moment, earliestDate: moment.Moment, latestDate: moment.Moment;
  let url: string | undefined, sourceDetails: featureUtils.ImagerySourceDetails, sourceId: string;
  let dataKey: string;

  if (orderableScene) {
    dataKey = orderableScene.get('sensingTime');
    date = layerUtils.conditionallyAdjustLayerDate(orderableScene.get('sensingTime'), layerKey);
    const details = featureUtils.imageAndSourceDetailsFromScene(orderableScene);
    url = details.url;
    sourceDetails = details.sourceDetails;
    sourceId = details.sourceId;

    earliestDate = moment(orderableScene.get('sensingTime'));
    latestDate = moment(orderableScene.get('sensingTime'));
  } else if (featureDatum) {
    dataKey = featureDatum.get('date');
    date = layerUtils.conditionallyAdjustLayerDate(featureDatum.get('date'), layerKey);
    const details = featureUtils.imageAndSourceDetails(featureDatum, layerKey!, organization);

    url = details.url;
    sourceDetails = details.sourceDetails;
    sourceId = details.sourceId;

    const sensingDates = featureUtils.getFeatureDataSensingDates(featureDatum, layerKey);
    earliestDate = moment(sensingDates[0]);
    latestDate = moment(sensingDates[sensingDates.length - 1]);

    const layerMosiacDates = layerUtils.staticLayerMosaicRange(layerKey, date);
    if (layerMosiacDates) {
      earliestDate = moment.min(earliestDate, layerMosiacDates[0]);
      latestDate = moment.max(latestDate, layerMosiacDates[1]);
    }
  } else {
    return null;
  }
  if (url) {
    url = mapUtils.formatTileUrl(url, firebaseToken);
  }

  const sourceName = sourceDetails.name;
  const sourceOperator = sourceDetails.operator;
  const sourceResolution = sourceDetails.resolution;

  return (
    <div
      className={cs.container}
      onClick={(event: MouseEvent<HTMLElement>) => {
        if (onClick) {
          onClick(event);
        }
      }}
      data-date={dataKey} // Target of scrollToSelectedCursor in DateSelect
    >
      <div
        className={classnames(cs.card, {[cs.cardHover]: onClick}, className)}
        style={{height: 72, padding: 6, margin: '-6px -6px 6px -6px', ...style}}
      >
        <div className={cs.datumDetails}>
          {showThumbnail && (
            <div
              className={classnames(cs.imageContainer, {[cs.animatedBackground]: !isImageLoaded})}
            >
              <img
                className={cs.image}
                loading="lazy"
                src={!isOrdered && blockedSources.includes(sourceId) ? hiddenThumbnailSrc : url}
                onLoad={() => setIsImageLoaded(true)}
              />
            </div>
          )}
          <div>
            <div className={cs.dateRow}>
              <div className={cs.date}>{imageryUtils.formatDate(date, layerKey)}</div>

              {/* TODO(fiona): should we relax this check to not show the indicator if the
                captures were within a few days? */}
              {(!earliestDate.isSame(date, 'day') || !latestDate.isSame(date, 'day')) && (
                <B.Tooltip
                  content={imageryUtils.formatCaptureDate(date, layerKey, {featureDatum})}
                  position={B.Position.RIGHT}
                  // Allows popper.js to position the popover outside of the dialog’s bounds
                  modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
                >
                  <B.Icon icon="calendar" iconSize={10} className={cs.dateIcon} />
                </B.Tooltip>
              )}
            </div>

            {sourceOperator && (
              <div className={cs.source}>
                {sourceOperator} {sourceName}
                {sourceResolution && (
                  <>
                    <span> (</span>
                    <span className={cs.sourceResolution}>{sourceResolution}m</span>
                    <span>)</span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {renderRightElement && <div>{renderRightElement()}</div>}
      </div>
    </div>
  );
};

export default DateSelectCard;

export const HiddenImageAction: React.FunctionComponent<
  React.PropsWithChildren<{
    feature: I.MapAsRecord<I.ImmutableFields<ApiFeature>>;
    orderableScene: I.MapAsRecord<I.ImmutableFields<ApiOrderableScene>>;
    isHidden: boolean;
    setIsImageHidden: (
      f: I.ImmutableOf<ApiFeature>,
      orderableScene: I.ImmutableOf<ApiOrderableScene>,
      isHidden: boolean
    ) => void;
    alwaysVisible?: boolean;
  }>
> = ({feature, orderableScene, isHidden, setIsImageHidden, alwaysVisible}) => {
  return (
    <B.Tooltip
      // We specifically say "unhide" instead of "show" to avoid other confusion
      // around what "showing" an image might be.
      content={isHidden ? 'Unhide this image' : 'Hide this image (you can unhide it later)'}
    >
      <B.AnchorButton
        icon={isHidden ? 'eye-open' : 'eye-off'}
        color={COLORS.lightSlate}
        minimal
        className={classnames(cs.rightCardAction, {
          [cs.visibleOnHover]: !alwaysVisible,
        })}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          setIsImageHidden(feature, orderableScene, !isHidden);
        }}
      />
    </B.Tooltip>
  );
};
