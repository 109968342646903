import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import AppNav from 'app/components/AppNav/view';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {INTERNAL_ORGANIZATION_ID} from 'app/utils/constants';
import * as routeUtils from 'app/utils/routeUtils';

import cs from './styles.styl';

export interface Props {
  history: History;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  currentOrganization: I.ImmutableOf<ApiOrganization>;
  profileOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>>>;
  loggedInUserActions: LoggedInUserActions;
}

const OrganizationSwitch: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentOrganization,
  profileOrganizations,
  profile,
  history,
  loggedInUserActions,
}) => {
  const parentProfileOrganizations = profileOrganizations
    .filter((org) => !!org?.get('childOrganizations').size)
    .sortBy((org) => org?.get('id') === profile.get('organizationKey')); // sort the home org to the top

  const orphanProfileOrganizations = profileOrganizations.filter(
    (org) => !org?.get('childOrganizations').size
  );

  return (
    <div>
      <AppNav
        history={history}
        organization={currentOrganization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />

      {parentProfileOrganizations
        .map((profileOrganization) => {
          if (!profileOrganization) {
            return null;
          }
          const childOrganizations = profileOrganization.get('childOrganizations');
          return (
            <div className={cs.content} key={profileOrganization.get('id')}>
              <h2>{profileOrganization.get('name')}</h2>
              <p>Select an organization below to log in to the associated Lens account.</p>

              {childOrganizations.size ? (
                <div style={{display: 'flex'}}>
                  <B.Menu large>
                    {/* Only display the profile org in the list of child orgs if we are in the internal upstream org.
              so we can access our internal Upstream Tech account. For other parent organizations, the parent org
              should not be accessible from this list as it is not allowed to have it's own portfolios. */}
                    {profileOrganization.get('id') === INTERNAL_ORGANIZATION_ID && (
                      <OrgMenuItem
                        org={profileOrganization}
                        history={history}
                        loggedInUserActions={loggedInUserActions}
                      />
                    )}
                    {/* In the internal super admin structure we want to see orgs that have no product set or Lens.
                  This won't affect non internal parent orgs because we filter to just lens products in the request */}
                    <>
                      {childOrganizations
                        .sortBy((org) => org!.get('name').toLowerCase())
                        .filter(
                          (org) =>
                            org!.get('products').includes('Lens') || org!.get('products').isEmpty()
                        )
                        .map((childOrg, i) => (
                          <OrgMenuItem
                            key={i}
                            org={childOrg!}
                            history={history}
                            loggedInUserActions={loggedInUserActions}
                          />
                        ))}
                    </>
                  </B.Menu>
                </div>
              ) : (
                <p>
                  No child organizations have been set up yet. Please contact us for support at
                  lens@upstream.tech if this is unexpected.
                </p>
              )}
            </div>
          );
        })
        .toArray()}
      {orphanProfileOrganizations.size && (
        <div className={cs.content}>
          <h2>Linked Organizations</h2>
          <p>Other organizations that this user account has access to.</p>
          <div style={{display: 'flex'}}>
            <B.Menu large>
              {orphanProfileOrganizations
                .sortBy((org) => org!.get('name').toLowerCase())
                .filter(
                  (org) => org!.get('products').includes('Lens') || org!.get('products').isEmpty()
                )
                .map((org) => {
                  return (
                    <OrgMenuItem
                      key={org!.get('id')}
                      org={org!}
                      history={history}
                      loggedInUserActions={loggedInUserActions}
                    />
                  );
                })
                .toArray()}
            </B.Menu>
          </div>
        </div>
      )}
    </div>
  );
};

const OrgMenuItem: React.FunctionComponent<{
  org: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>;
  history: Props['history'];
  loggedInUserActions: LoggedInUserActions;
}> = ({org, history, loggedInUserActions}) => {
  return (
    <B.MenuItem
      key={org.get('id')}
      text={org.get('name')}
      icon={org.get('products').isEmpty() ? 'warning-sign' : undefined}
      label={org.get('products').isEmpty() ? 'Lens product not set' : undefined}
      onClick={() => {
        loggedInUserActions.setCurrentOrganization(org);
        history.push(`/${routeUtils.makeUuidPrefix(org.get('id'))}/projects`);
      }}
    />
  );
};

export default OrganizationSwitch;
