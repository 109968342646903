import firebase from 'firebase/app';
import geojson from 'geojson';
import * as I from 'immutable';
import Protobuf from 'pbf';
import 'firebase/auth';
import vt from 'vector-tile';

import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {getTileData} from 'app/stores/RasterCalculationStore';
import * as apiUtils from 'app/utils/apiUtils';

import {formatTileUrl} from '.';

export default async function loadTiledFeatureCollectionAsGeoJSON(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  filterGeometry: geojson.Polygon | geojson.MultiPolygon,
  options: {maxZoom?: number} = {}
): Promise<geojson.FeatureCollection | null> {
  if (!featureCollection.get('tiles')) {
    // Some feature collections don't have tiles. Return null instead.
    return null;
  }
  const auth = firebase.auth();
  const tileData = getTileData(filterGeometry, options);
  let features: geojson.Feature[] = [];
  if (tileData) {
    const {tiles} = tileData;
    for (const tile of tiles) {
      let url = featureCollection
        .getIn(['tiles', 'urls'])
        .first()
        .replace('{x}', tile[0].toString())
        .replace('{y}', tile[1].toString())
        .replace('{z}', tile[2].toString());

      // feature collections will define their tiles as relative paths when they are internal,
      // i.e. regrid/tiles/12/1245/1522.mvt
      if (!url.startsWith('http')) {
        url = apiUtils.getApiRoute(url);
      }

      if (url.indexOf(apiUtils.getApiRoot()) > -1 && auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        if (token) {
          url = formatTileUrl(url, token);
        }
      }
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      try {
        const fc = readTile(
          new Uint8Array(buffer),
          featureCollection.getIn(['tiles', 'sourceLayer']),
          tile
        );
        features = [...features, ...fc.features];
      } catch (e) {
        // At the time of writing, readTile(...) occasionally throws with Unimplemented Type: 3
        // while constructing a protobuf object. Report this error, but do nothing for now.
        console.error(e);
        return null;
      }
    }
    return {type: 'FeatureCollection', features};
  }
  return null;
}

function readTile(
  buffer: Uint8Array,
  layer: string,
  tileXYZ: [number, number, number]
): geojson.FeatureCollection {
  const tile = new vt.VectorTile(new Protobuf(buffer));
  let layers = layer || Object.keys(tile.layers);
  if (!Array.isArray(layers)) {
    layers = [layers];
  }

  const fc: geojson.FeatureCollection = {type: 'FeatureCollection', features: []};
  const [x, y, z] = tileXYZ;

  layers.forEach(function (layerID) {
    const layer = tile.layers[layerID];
    if (layer) {
      for (let i = 0; i < layer.length; i++) {
        const feature = layer.feature(i).toGeoJSON(x, y, z);
        if (layers.length > 1) feature.properties.vt_layer = layerID;
        fc.features.push(feature);
      }
    }
  });

  return fc;
}
