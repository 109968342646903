import {TileData, getTileData, getTileDataOptions} from 'app/stores/RasterCalculationStore';
import {StatusMaybe} from 'app/utils/hookUtils';
import {conditionallyAdjustLayerDate, getLayer} from 'app/utils/layerUtils';

import {
  GraphMode,
  TimeSeriesCalculatorData,
  calculateAggregatePerHectare,
  calculateAreaByCategory,
} from './AnalyzePolygonPopup';
import {TILE_COVERAGE_ERROR} from './AnalyzePolygonPopupWithState';
import {DEFAULT_GRAPH_MODES} from './layerSettings';
import {DEFAULT_GRAPH_RANGES} from './layerSettings';
import {DEFAULT_GRAPH_RANGE_FOR_ANNUAL_LAYERS} from './layerSettings';
import {getLayerSettings} from './layerSettings';
import {AnalyzePolygonChartProps} from '../AnalyzePolygonChart/AnalyzePolygonChart';
import {GraphPoint} from '../AnalyzePolygonChart/types';

/**
 * Utility function for getting permitted graph modes for the provided layer.
 */
export function getGraphModes(layerKey?: string) {
  const layerSettings = getLayerSettings(layerKey);
  return layerSettings.graphModes || DEFAULT_GRAPH_MODES;
}

/**
 * Utility function for getting permitted graph ranges for the provided layer and current mode.
 */
export function getGraphRanges(layerKey: string | undefined, graphMode: GraphMode) {
  if (!layerKey) {
    return DEFAULT_GRAPH_RANGES;
  }
  const layerSettings = getLayerSettings(layerKey);
  const layerConfig = getLayer(layerKey);

  switch (true) {
    case !!layerSettings.graphRanges:
      return layerSettings.graphRanges;
    case layerConfig.mosaic === 'annual':
      return DEFAULT_GRAPH_RANGE_FOR_ANNUAL_LAYERS;
    case graphMode === 'averageMonthly':
      return DEFAULT_GRAPH_RANGES.filter((range) => range.type !== 'by-year');
    default:
      return DEFAULT_GRAPH_RANGES;
  }
}

/**
 * Utility function to get the value map that indexes the category color and
 * label by the category value for landuse layers.
 */
export function getLanduseLayerValueMap(layerKey: string) {
  const layer = getLayer(layerKey);
  return layer.type === 'image' ? layer.layerLegendMap : undefined;
}

/* If the lowerUncertaintyBound and the upperUncertaintyBound are the same as the mean this
 is an indication that all bands of the raw image are the same and we have no
 uncertainty data.
*/
export function hasUncertaintyData(
  mean: number,
  lowerUncertaintyMean: number,
  upperUncertaintyMean: number
) {
  return lowerUncertaintyMean !== mean || upperUncertaintyMean !== mean;
}

/** Function for fetching tileData. Wrap in useMemo since determining which tiles
 * cover the polygon at which zoom level requires some computation. Returns undefined
 * if the feature data is not tiled, and null if feature data is tiled but could not
 *  be determined.
 * This function was pulled out of AnalyzePolygonPopupWithState so that it can be
 * closely coupled to the RerunChartAnalysis internal tool.
 */
export function fetchTileData(graphLayerKey, polygon, featureData): StatusMaybe<TileData> {
  // While the `unknown` status is usually used for loading, in this case it’s
  // more of a “no-op” status since we are awaiting a required resource. If
  // the graphLayerKey is falsey, we have no way of looking up the raster
  // source definition, which contains the webtile metadata. If the polygon is
  // falsey, we don’t know which webtiles to request.
  if (!graphLayerKey || !polygon || !featureData) {
    return {status: 'unknown'};
  }

  const options = getTileDataOptions(featureData, graphLayerKey);
  const value = getTileData(polygon, options);

  return value
    ? {status: 'some', value}
    : {
        status: 'error',
        error: TILE_COVERAGE_ERROR,
      };
}

/** This function was pulled out of AnalyzePolygonPopup so that it can be
 * closely coupled to the RerunChartAnalysis internal tool. It takes a timeseries,
 * then calculates the appropriate value for each data point for the chart type.
 */
export function calculateDataPoints(
  calculatorData: TimeSeriesCalculatorData,
  graphMode: GraphMode,
  layerKey: string,
  areaInM2: number
): GraphPoint[] {
  const dataPoints =
    calculatorData.status === 'loaded'
      ? calculatorData.values
          .map(
            ([
              date,
              {
                mean,
                percentWithinThreshold,
                missingPixelCount,
                weightedPixelsWithValue,
                weightedPixelsWithValueByValue,
              },
              {cursor},
            ]): GraphPoint[] => {
              let value;

              switch (graphMode) {
                case 'aggregateCarbonSalo':
                case 'aggregateCarbonSpaceIntelligence':
                  value = calculateAggregatePerHectare(
                    mean,
                    areaInM2,
                    missingPixelCount,
                    weightedPixelsWithValue
                  );
                  break;
                case 'average':
                case 'averageMonthly':
                  value = mean;
                  break;
                case 'area':
                  value = percentWithinThreshold;
                  break;
                case 'areaByCategory':
                  // Create a GraphPoint for each category for the given date
                  return calculateAreaByCategory(
                    layerKey,
                    weightedPixelsWithValueByValue,
                    weightedPixelsWithValue
                  ).map(({categoryValue, percentArea}) => [
                    date,
                    percentArea,
                    {cursor, category: categoryValue},
                  ]);
              }

              // For all other graphModes besides 'areaByCategory' we are creating just 1 GraphPoint
              // for each data value. We return the one point as an array so we can call .flat()
              // regardless of graphMode to end up with a final flat array of GraphPoints.
              return [[conditionallyAdjustLayerDate(date, layerKey).toDate(), value, {cursor}]];
            }
          )
          .flat()
      : [];

  return dataPoints;
}

/**This function was pulled out of AnalyzePolygonPopup so it can be closely coupled to
 * RerunChartAnalysis internal tool. It's used to display loading status on the chart.
 */
export function createLoadingProps(
  calculatorData: TimeSeriesCalculatorData
): Pick<AnalyzePolygonChartProps, 'loading' | 'loadingProgress' | 'loadingIcon' | 'loadingStatus'> {
  return {
    loading: calculatorData.status === 'loading' || calculatorData.status === 'loading-data',
    loadingProgress:
      calculatorData.status === 'loading' || calculatorData.status === 'loading-data'
        ? calculatorData.progress
        : undefined,
    loadingStatus: calculatorData.status,
    loadingIcon: calculatorData.status === 'error' ? 'warning-sign' : undefined,
  };
}
