import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {api} from 'app/modules/Remote';
import {LoggedInAuth, useAuth} from 'app/providers/AuthProvider';

const Hubspot: React.FunctionComponent = () => {
  const auth = useAuth();
  const profile: LoggedInAuth['profile'] | null = auth.status === 'logged in' ? auth.profile : null;
  const token = useQuery({
    queryKey: ['hubspotToken', profile],
    queryFn: async (): Promise<{token: string}> => {
      return (await api.organizations.getHubspotToken()).get('data').toJS();
    },
  }).data?.token;

  React.useEffect(() => {
    if (profile && token) {
      (window as any).hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: profile.get('email'),
        identificationToken: token,
      };
      (window as any)?.HubSpotConversations?.widget?.load();
    }

    return () => {
      (window as any).HubSpotConversations?.widget?.remove();
      // Reset identification
      (window as any).hsConversationsSettings = {
        loadImmediately: false,
      };
    };
  }, [profile, token]);

  return null;
};

export const HideHubspotWidget: React.FunctionComponent = () => {
  // On unmount, reload the widget
  React.useEffect(() => {
    return () => (window as any)?.HubSpotConversations?.widget.load();
  }, []);

  // Any time the widget status changes, remove it. This might be overkill, but
  // not removing on status changed caused it to appear more than we wanted.
  const status = (window as any)?.HubSpotConversations?.widget.status();
  const loaded = status?.loaded;
  const pending = status?.pending;
  React.useEffect(() => (window as any)?.HubSpotConversations?.widget.remove(), [loaded, pending]);

  return null;
};

export function openHubspotWidget() {
  // If the widget is not loaded, we need to load it before opening
  (window as any)?.HubSpotConversations?.widget.load();
  (window as any)?.HubSpotConversations?.widget.open();
}

export default Hubspot;
