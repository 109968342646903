import * as B from '@blueprintjs/core';
import React from 'react';

/**
 * B.Icon component (for use with Blueprint) for our custom "replace note layer
 * and cursors" SVG.
 */
const ReplaceLayerIcon: React.FunctionComponent<React.PropsWithChildren<{size?: number}>> = ({
  size = 16,
}) => (
  <B.Icon
    icon={
      // Re-using bp5-icon here gives us consistent color / fill handling with
      // Blueprint’s built-in icons.
      <span className="bp5-icon">
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.51077 26.72C6.28923 26.4985 6.15385 26.1908 6.15385 25.8462C6.15385 25.1692 6.70769 24.6154 7.38462 24.6154C7.72923 24.6154 8.03692 24.7508 8.25846 24.9723L10.72 27.4338C10.9415 27.6554 11.0769 27.9631 11.0769 28.3077C11.0769 28.6523 10.9415 28.96 10.72 29.1815L8.25846 31.6431C8.03692 31.8646 7.72923 32 7.38462 32C6.70769 32 6.15385 31.4462 6.15385 30.7692C6.15385 30.4246 6.28923 30.1169 6.51077 29.8954L6.88 29.5385H4.92308C2.20308 29.5385 0 27.3354 0 24.6154V20.9231C0 20.2462 0.553846 19.6923 1.23077 19.6923C1.90769 19.6923 2.46154 20.2462 2.46154 20.9231V24.6154C2.46154 25.9692 3.56923 27.0769 4.92308 27.0769H6.88L6.51077 26.72Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8462 12.3077C27.2 12.3077 28.3077 11.2 28.3077 9.84615C28.3077 8.49231 27.2 7.38461 25.8462 7.38461C24.4923 7.38461 23.3846 8.49231 23.3846 9.84615C23.3846 11.2 24.4923 12.3077 25.8462 12.3077ZM30.7692 3.69231H8.61538C7.93846 3.69231 7.38461 4.24615 7.38461 4.92308V22.1538C7.38461 22.8308 7.93846 23.3846 8.61538 23.3846H30.7692C31.4462 23.3846 32 22.8308 32 22.1538V4.92308C32 4.24615 31.4462 3.69231 30.7692 3.69231ZM29.5385 19.6923L22.1538 13.5385L19.6923 16L14.7692 9.84615L9.84615 19.6923V6.15385H29.5385V19.6923Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.92309 0H27.0769C27.7539 0 28.3077 0.553846 28.3077 1.23077V3.69231H25.8462V2.46154H6.15386V16L7.38462 13.5385V19.6923H4.92309C4.24617 19.6923 3.69232 19.1385 3.69232 18.4615V1.23077C3.69232 0.553846 4.24617 0 4.92309 0Z"
          />
        </svg>
      </span>
    }
  />
);

export default ReplaceLayerIcon;
