import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  ChartOptions,
  PluginOptionsByType,
  ScaleOptionsByType,
} from 'chart.js';
import 'chartjs-adapter-moment';
import groupBy from 'lodash/groupBy';
import React, {CSSProperties} from 'react';

import {
  DataRange,
  Graph,
  GraphPoint,
  GraphTimeRange,
  GraphWithTimeRange,
} from 'app/components/AnalyzePolygonChart/types';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as colorUtils from 'app/utils/colorUtils';
import {milisecondsToYears} from 'app/utils/conversionUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';

import {makePointColorCallback} from './SimpleChart';
import {
  DataPoint,
  EXPANDED_GRAPH_FONT_SIZE,
  GRAPH_FONT_SIZE,
  X_SCALE_ID,
  Y_SCALE_ID,
  formatUnits,
  makeTooltipFooterCallback,
  makeTooltipLabelCallback,
} from './utils';

/**
 * Component to render a bar chart with monthly average values
 *
 * Note: the Chart.js types are a bit lacking (e.g., optional props erroneously
 * typed as required) so we have to do a bit of casting.
 */
const ByMonthChart: React.FunctionComponent<{
  graph: Graph | GraphWithTimeRange;
  areaInM2: number;
  areaUnit: ApiOrganizationAreaUnit;
  onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
  onInitialize: (chart: Chart, timeRange: GraphTimeRange) => void;
  onPanComplete: (chart: Chart) => void;
  onZoomComplete: (chart: Chart) => void;
  disablePanZoom?: boolean;
  devicePixelRatio?: number;
  hideTooltip?: boolean;
  showTitle?: boolean;
  graphStyle?: CSSProperties;
  isExpanded: boolean;
  dataRange: DataRange;
  children: (chart: Chart) => React.ReactNode;
}> = ({
  graph,
  onClick,
  onInitialize,
  onPanComplete,
  onZoomComplete,
  disablePanZoom,
  devicePixelRatio,
  graphStyle,
  children,
  showTitle,
  isExpanded,
  dataRange,
  areaInM2,
  areaUnit,
  hideTooltip,
}) => {
  const chartRef = React.useRef<Chart | null>(null);

  const pointColorCallback = React.useMemo(() => makePointColorCallback(graph), [graph]);

  const [datasets, minDate, maxDate, initialTimeRange] = React.useMemo(() => {
    const averageMonthlyDataPoints = calculateAverageMonthlyGraphPoints(
      graph.dataPoints,
      graph.layerKey
    ).map(([date, value, meta]) => {
      return {
        x: date,
        y: value,
        date: date,
        cursor: meta.cursor,
      } as unknown as DataPoint;
    });

    const minDate = averageMonthlyDataPoints[0].date;
    const maxDate = averageMonthlyDataPoints[averageMonthlyDataPoints.length - 1].date;

    /** Get initial time range from graph prop, if it exists; this should only
     * be the case for graphs save on notes. */
    const initialTimeRange = graph.graphTimeRange || [minDate, maxDate];

    const dataset: ChartData<'bar', DataPoint[]>['datasets'] = [
      {
        data: averageMonthlyDataPoints,
        backgroundColor: (context) => pointColorCallback(context.parsed.y),
      },
    ];

    return [dataset, minDate, maxDate, initialTimeRange];
  }, [graph.dataPoints, graph.layerKey, pointColorCallback, graph.graphTimeRange]);

  /** Optionally display a tooltip on hover. */
  const tooltipPluginOptions = React.useMemo<PluginOptionsByType<'bar'>['tooltip']>(
    () =>
      ({
        enabled: !hideTooltip,
        displayColors: false,
        callbacks: {
          label: makeTooltipLabelCallback(graph, areaInM2, areaUnit),
          afterLabel: makeTooltipFooterCallback(graph),
        },
      }) as PluginOptionsByType<'bar'>['tooltip'],
    [areaInM2, areaUnit, graph, hideTooltip]
  );
  const xAxisOptions: ScaleOptionsByType<'time'> = React.useMemo(() => {
    const minDateTime = initialTimeRange[0].getTime();
    const maxDateTime = initialTimeRange[1].getTime();

    // If we are showing more than 2 years worth of data, we want ticks to only be at January
    // so it is easier to parse years quickly
    const timeBetweenDatesinYears = milisecondsToYears(minDateTime - maxDateTime);
    const showLabelsAtStartOfYear = timeBetweenDatesinYears > 2;
    return {
      type: 'time',
      min: minDateTime,
      max: maxDateTime,
      ticks: {
        autoSkip: true,
        autoSkipPadding: 10,
        minRotation: 0,
        maxRotation: 0,
        ...(showLabelsAtStartOfYear && {
          callback: (value) => {
            value = value.toString();
            if (value.startsWith('Jan')) {
              return value;
            }
          },
        }),
      },

      time: {
        unit: 'month',
        round: 'month',
        tooltipFormat: 'MMMM YYYY',
        displayFormats: {month: `MMM 'YY`},
      },
      grid: {
        borderDash: [1, 1],
        offset: false,
      },
    } as unknown as ScaleOptionsByType<'time'>;
  }, [initialTimeRange]);

  const yAxisOptions: ScaleOptionsByType<'linear'> = React.useMemo(() => {
    return {
      type: 'linear',
      grid: {
        drawBorder: false,
      },
      max: dataRange[1],
      min: dataRange[0],
      ticks: {
        // Show grid lines and labels at the max and min of the data, and 0 if
        // the data has negative and positive values
        callback: (value) => {
          if (value === 0 || value === dataRange[0] || value === dataRange[1]) {
            return formatUnits(value, graph.layerDataUnit);
          }
        },
      },
    } as unknown as ScaleOptionsByType<'linear'>;
  }, [dataRange, graph.layerDataUnit]);

  /** Allow chart to be optionally panned and zoomed. */
  const zoomPluginOptions = React.useMemo<PluginOptionsByType<'bar'>['zoom']>(
    () => ({
      //TODO(eva): really we should also set "limits" here so that people can't zoom
      //out off the chart and into nothingness. but, we run into issues zooming on bar
      //charts where if you hit one of the limits, it will not allow you to zoom out
      //further, which is frustrating/confusing. so for now, no limitations on how far
      //the user can zoom out.
      pan: {
        mode: 'x',
        /** HACK: The chartjs-plugin-zoom plugin does not respond to changes in
         * the pan.enabled option after initialization, but it does respond to
         * changes in pan.onPanStart. */
        enabled: true,
        onPanStart: disablePanZoom ? () => false : undefined,
        onPanComplete: ({chart}) => onPanComplete(chart),
      },
      zoom: {
        mode: 'x',
        pinch: {
          enabled: !disablePanZoom,
        },
        wheel: {
          enabled: !disablePanZoom,
        },

        onZoomComplete: ({chart}) => onZoomComplete(chart),
      },
    }),
    [disablePanZoom, onPanComplete, onZoomComplete]
  );

  const options: ChartOptions<'bar'> = React.useMemo(
    () => ({
      scales: {
        x: xAxisOptions,
        y: yAxisOptions,
      },
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPluginOptions,
        zoom: zoomPluginOptions,
        title: {
          display: showTitle,
          text: graph.layerDisplay,
          font: {weight: 'normal'} as PluginOptionsByType<'bar'>['title']['font'],
        },
      },
      onHover: (_event, elements, chart) =>
        (chart.canvas.style.cursor = elements.length ? 'pointer' : 'unset'),
      onClick,
      maintainAspectRatio: false,
      devicePixelRatio,
    }),
    [
      xAxisOptions,
      yAxisOptions,
      tooltipPluginOptions,
      zoomPluginOptions,
      showTitle,
      graph.layerDisplay,
      onClick,
      devicePixelRatio,
    ]
  );

  const canvasRefCallback = React.useCallback(
    (node: HTMLCanvasElement | null) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      if (node) {
        const ctx = node.getContext('2d');

        Chart.defaults.font.size = isExpanded ? EXPANDED_GRAPH_FONT_SIZE : GRAPH_FONT_SIZE;
        Chart.defaults.color = colorUtils.default.darkestGray;

        if (ctx) {
          const chart = new Chart(ctx, {
            type: 'bar',
            data: {
              datasets,
            },
            options,
          });

          if (onInitialize) {
            onInitialize(chart, [minDate, maxDate]);
          }

          chartRef.current = chart;
        }
      }
    },

    []
  );

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.datasets = datasets;
      chartRef.current.update();
    }
  }, [datasets]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options) {
      chartRef.current.options.onClick = onClick;
      chartRef.current.update();
    }
  }, [onClick]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.plugins) {
      chartRef.current.options.plugins.tooltip = tooltipPluginOptions;
      chartRef.current.update();
    }
  }, [tooltipPluginOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.plugins) {
      chartRef.current.options.plugins.zoom = zoomPluginOptions;
      chartRef.current.update();
    }
  }, [zoomPluginOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.scales) {
      chartRef.current.options.scales[X_SCALE_ID] = xAxisOptions;
      chartRef.current.update();
    }
  }, [xAxisOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.scales) {
      chartRef.current.options.scales[Y_SCALE_ID] = yAxisOptions;
      chartRef.current.update();
    }
  }, [yAxisOptions]);

  return (
    <React.Fragment>
      <div style={graphStyle}>
        <canvas ref={canvasRefCallback} />
      </div>
      {!!chartRef.current && children(chartRef.current)}
    </React.Fragment>
  );
};

export default ByMonthChart;

export function calculateAverageMonthlyGraphPoints(dataPoints: GraphPoint[], layerKey: string) {
  const dataPointsByMonth = groupBy(dataPoints, ([date]) => {
    const adjustedDate = layerUtils.conditionallyAdjustLayerDate(date, layerKey);

    return adjustedDate.format('YYYY-MM');
  });

  const averageMonthlyDataPoints = Object.entries(dataPointsByMonth).map(([, dataPoints]) => {
    const averageValue = dataPoints.reduce((sum, [, value]) => sum + value, 0) / dataPoints.length;
    const firstDataPointInMonth = dataPoints[0][2].cursor;
    const adjustedDate = layerUtils
      .conditionallyAdjustLayerDate(firstDataPointInMonth, layerKey)
      .toDate();

    return [adjustedDate, averageValue, {cursor: firstDataPointInMonth}] as GraphPoint;
  });

  return averageMonthlyDataPoints;
}
