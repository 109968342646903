import * as mapboxgl from 'mapbox-gl';
import React from 'react';

export const IS_HIGHLIGHTED_PROPERTY = 'isHighlighted';
export const WAS_HIGHLIGHTED_PROPERTY = 'wasHighlighted';

const IS_HIGHLIGHTED_EXPRESSION = [
  'any',
  ['==', ['get', IS_HIGHLIGHTED_PROPERTY], true],
  ['==', ['get', WAS_HIGHLIGHTED_PROPERTY], true],
];

const ZOOM_THRESHOLD = 15;

export interface PolygonLayerOpts {
  idPrefix: string;
  sourceId: string;
  color: string;
  highlightedColor?: string;
  sourceLayer?: string;
  fillPaintExtra?: mapboxgl.FillPaint;
  linePaintExtra?: mapboxgl.LinePaint;
  // Show this layer as filled by default regardless of zoom level + altDown state.
  defaultFilled?: boolean;
  // Don't show this layer as filled by default regardless of zoom level.
  // This is useful for overlays shown over medium to low resolution imagery, where you'd
  // want to review the entire property while zoomed out (without the overlay in the way).
  defaultUnfilled?: boolean;
  // percentage decimal value from 0 and 1
  fillOpacityOverride?: number;
}

/**
 * Returns an array of two layers, for polygon fill and polygon lines,
 * respectively. The fill layer is semi-transparent, but disappears at the
 * ZOOM_THRESHOLD so as not to obscure the map.
 *
 * If altDown is true, the polygon will be filled regardless of zoom level.
 * If opts.defaultFilled is true then altDown has the opposite behavior
 * (not drawing the fill regardless of zoom level).
 *
 * Slight misnomer now that this handles line strings and points.
 */
export function makeTranslucentPolygonLayer(opts: PolygonLayerOpts, altDown = false) {
  let fillZoomThreshold: number;
  if (opts.defaultUnfilled) {
    fillZoomThreshold = altDown ? 24 : 1;
  } else {
    const forceFill = opts.defaultFilled ? !altDown : altDown;
    fillZoomThreshold = forceFill ? 24 : ZOOM_THRESHOLD;
  }

  const fillLayer: mapboxgl.FillLayer = {
    type: 'fill',

    id: `${opts.idPrefix}-fill`,
    source: opts.sourceId,
    ...(opts.sourceLayer ? {'source-layer': opts.sourceLayer} : {}),

    interactive: true,

    // We want the fill to appear when zoomed out so you can see it and more
    // easily click on it, but once you get close enough that you could be looking
    // at terrain then we want to disappear.
    maxzoom: fillZoomThreshold,
    filter: [
      'all',
      ['!', IS_HIGHLIGHTED_EXPRESSION],
      // We have to explicitly disable fill for lines or else Mapbox will try,
      // and it will be a mess.
      ['!=', ['geometry-type'], 'LineString'],
      ['!=', ['geometry-type'], 'MultiLineString'],
    ],
    paint: {
      'fill-color': opts.color,
      'fill-opacity': opts.fillOpacityOverride || 0.6,
      ...(opts.fillPaintExtra || {}),
    },
  };

  const linesLayer: mapboxgl.LineLayer = {
    type: 'line',

    id: `${opts.idPrefix}-lines`,
    source: opts.sourceId,
    ...(opts.sourceLayer ? {'source-layer': opts.sourceLayer} : {}),

    interactive: true,

    paint: {
      'line-color': opts.highlightedColor
        ? ['case', IS_HIGHLIGHTED_EXPRESSION, opts.highlightedColor, opts.color]
        : opts.color,
      'line-opacity': [
        'step',
        ['zoom'],
        1.0,
        fillZoomThreshold,
        ['case', IS_HIGHLIGHTED_EXPRESSION, 1.0, 0.5],
      ],
      'line-width': ['step', ['zoom'], 1.5, fillZoomThreshold, 3],
      ...(opts.linePaintExtra || {}),
    },
  };

  const pointLayer: mapboxgl.CircleLayer = {
    type: 'circle',

    id: `${opts.idPrefix}-points`,
    source: opts.sourceId,
    ...(opts.sourceLayer ? {'source-layer': opts.sourceLayer} : {}),

    interactive: true,

    filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']],

    paint: {
      'circle-color': opts.color,
      // We have to change size with zoom to make the points look natural /
      // visible.
      'circle-radius': ['step', ['zoom'], 3, 13, 4, 16, 6],
      'circle-opacity': 0.8,
    },
  };

  return [fillLayer, linesLayer, pointLayer] as const;
}

/**
 * Hook wrapper around makeTranslucentPolygonLayer that listens for key events
 * and forces the layers to be filled when alt is held down.
 *
 * (We chose alt because mapboxgl does not call the map’s click handler when
 * shift is held down.)
 */
export function useFilledPolygonLayer(
  opts: PolygonLayerOpts,
  popup?: mapboxgl.Popup
): readonly [mapboxgl.FillLayer, mapboxgl.LineLayer, mapboxgl.CircleLayer] {
  const [isAltDown, setIsAltDown] = React.useState(false);

  React.useEffect(() => {
    const keyDownHandler = (ev: KeyboardEvent) => {
      const tagName = (ev.target as HTMLElement).tagName;

      if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
        // Keeps us from flashing when the user types fancy characters.
        return;
      }

      if (ev.keyCode === 18) {
        setIsAltDown(true);
      }
    };

    const keyUpHandler = (ev: KeyboardEvent) => {
      const tagName = (ev.target as HTMLElement).tagName;

      if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
        // Keeps us from flashing when the user types fancy characters.
        return;
      }

      if (ev.keyCode === 18) {
        setIsAltDown(false);
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('keyup', keyUpHandler);
    };
  });

  // This closes the popup when alt is lifted to reduce the chances that the
  // popup is dangling around, since mouse leave wouldn’t fire.
  React.useEffect(() => {
    if (popup && isAltDown) {
      return () => {
        popup.remove();
      };
    }
  }, [popup, isAltDown]);

  return React.useMemo(() => makeTranslucentPolygonLayer(opts, isAltDown), [opts, isAltDown]);
}
