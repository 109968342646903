import geojson from 'geojson';
import React from 'react';

import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import {UTMArea} from 'app/utils/geoJsonUtils';

/**
 * Component that memoizes UTM area calculation to avoid recalculating
 * expensive geometric operations on every render
 */
const MemoizedFeatureArea: React.FC<{
  feature: geojson.Feature;
  areaUnit: ApiOrganizationAreaUnit;
  formatAreaFn?: (areaM2: number, areaUnit: ApiOrganizationAreaUnit) => string;
}> = ({feature, areaUnit, formatAreaFn}) => {
  const areaInM2 = React.useMemo(() => UTMArea(feature), [feature]);

  // Use provided format function or just return the area as a string
  const formattedArea = formatAreaFn ? formatAreaFn(areaInM2, areaUnit) : areaInM2.toString();

  return <>{formattedArea}</>;
};

export default MemoizedFeatureArea;
