import classnames from 'classnames';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import CompareMap, {CompareSliderSnapPosition} from 'app/components/CompareMap';
import DeclarativeMap, {
  Props as DeclarativeMapProps,
  MapControlPosition,
} from 'app/components/DeclarativeMap';
import Attribution from 'app/components/DeclarativeMap/Attribution';
import MapContent from 'app/components/DeclarativeMap/MapContent';
import {CursorType} from 'app/components/DeclarativeMap/MapCursorProvider';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, MeasurementSystem} from 'app/modules/Remote/Organization';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import {BBox2d} from 'app/utils/geoJsonUtils';
import * as mapUtils from 'app/utils/mapUtils';
import MODES, {Mode} from 'app/utils/mapUtils/modes';

import MapHotkeys from './MapHotkeys';
import cs from './styles.styl';

export interface RenderMapCallbackOpts {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  index: number;
  cursor: string | null;
  layerKey: string;
}

export interface Props {
  imageRefs: mapUtils.MapImageRefs;
  featureData: I.ImmutableOf<ApiFeatureData[]> | null;
  mode: Mode;
  globalCursor?: CursorType | undefined;

  firebaseToken: string;
  children: (opts: RenderMapCallbackOpts) => React.ReactNode;

  className?: string;
  onMapLoad?: (map: mapboxgl.Map) => unknown;
  onMapClick: (ev: mapboxgl.MapMouseEvent, map: mapboxgl.Map) => unknown;
  onMapMoved?: (
    ev: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>,
    map: mapboxgl.Map
  ) => unknown;

  selectedFeatureCollection: HydratedFeatureCollection;
  selectedFeatureIds: I.Set<number>;
  selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;

  /**
   * Used to move the compare slider to one side so when you’re making a
   * polygonal note you don’t have to deal with the slider.
   */
  moveCompareSliderToOnlyShowLatestImage?: boolean;
  compareFullScreenOffset?: number;

  controlPosition: MapControlPosition;
  controlPadding?: mapboxgl.PaddingOptions;
  defaultBounds?: mapboxgl.LngLatBounds | null;
  defaultCameraOptions?: {
    center: mapboxgl.LngLat;
    pitch: number;
    bearing: number;
    zoom: number;
  } | null;
  hideNavigationControl?: boolean;
  hideScaleControl?: boolean;
  measurementSystem?: MeasurementSystem;
  organization: I.ImmutableOf<ApiOrganization>;
}

export type Maps = mapboxgl.Map | [mapboxgl.Map, mapboxgl.Map] | null;

export interface State {
  compareSnapPosition: CompareSliderSnapPosition;
  maps: Maps;
}

export default class Map extends React.PureComponent<Props, State> {
  static defaultProps: Pick<Props, 'controlPosition' | 'measurementSystem'> = {
    controlPosition: 'top-left',
    measurementSystem: 'imperial',
  };

  // We need two scale controls to handle compare mode needing separate
  // instances.
  private scaleControlOpts = {unit: this.props.measurementSystem};
  private scaleControlA = new mapboxgl.ScaleControl(this.scaleControlOpts);
  private scaleControlB = new mapboxgl.ScaleControl(this.scaleControlOpts);

  constructor(props) {
    super(props);
    this.state = {
      compareSnapPosition: 'unset',
      maps: null,
    };
  }

  componentDidUpdate(prevProps) {
    // When we switch modes, we generate new maps, so we want to clear old ones
    if (prevProps.mode !== this.props.mode) {
      this.setState({maps: null});
    }
  }

  setCompareSnapPosition = (position: CompareSliderSnapPosition): void => {
    this.setState({compareSnapPosition: position});
  };

  // This setter is called when new maps are initialized (see: onMapInitialize, below)
  // If maps is null, we set our initialized map (view mode)
  // If maps is mapboxgl.Map and we're setting another, set
  //  the existing and new map as a tuple (compare mode)
  // If maps is already a tuple, something isn't right because
  //  we shouldn't have three map views. Re-set tuple, removing oldest
  setMap = (map: mapboxgl.Map): void => {
    this.setState(({maps}: {maps: Maps}) => {
      if (maps && !Array.isArray(maps)) {
        return {maps: [map, maps]};
      } else if (Array.isArray(maps)) {
        return {maps: [map, maps[0]]};
      } else {
        return {maps: map};
      }
    });
  };

  render() {
    const {className, mode, imageRefs, hideScaleControl = false} = this.props;
    const {maps} = this.state;
    const isCompareMode = mode === MODES.COMPARE;

    return (
      <div className={classnames(className, cs.container)}>
        {maps && <MapHotkeys maps={maps} snapCompareSlider={this.setCompareSnapPosition} />}
        <mapUtils.DisableClickableFeaturesProvider>
          {/* TODO(fiona): we should switch out of COMPARE mode when a feature isn’t selected. */}
          {isCompareMode
            ? this.renderCompareMap(imageRefs, hideScaleControl)
            : this.renderViewMap(imageRefs[0], hideScaleControl)}
        </mapUtils.DisableClickableFeaturesProvider>
      </div>
    );
  }

  private renderViewMap = (imageRef: mapUtils.MapImageRef, hideScaleControl: boolean) => {
    return this.renderMap(imageRef, 0, hideScaleControl ? undefined : this.scaleControlA);
  };

  private renderCompareMap = (imageRefs: mapUtils.MapImageRefs, hideScaleControl) => {
    const {moveCompareSliderToOnlyShowLatestImage, compareFullScreenOffset, controlPadding} =
      this.props;
    const {compareSnapPosition} = this.state;

    return (
      <CompareMap
        renderBeforeMap={(props) =>
          this.renderMap(
            imageRefs[0],
            0,
            hideScaleControl ? undefined : this.scaleControlA,
            props,
            true
          )
        }
        renderAfterMap={(props) =>
          // Typically imageRefs will be 2 elements long so these length - 1s
          // are 1. But we write it this way to be tolerant of there temporarily
          // being only 1 imageRef in the array. Better to show the same thing
          // twice than crash.
          this.renderMap(
            imageRefs[imageRefs.length - 1],
            imageRefs.length - 1,
            hideScaleControl ? undefined : this.scaleControlB,
            props,
            true
          )
        }
        moveSliderToOnlyShowLatestImage={moveCompareSliderToOnlyShowLatestImage}
        fullScreenOffset={compareFullScreenOffset}
        compareSnapPosition={compareSnapPosition}
        setCompareSnapPosition={this.setCompareSnapPosition}
        controlPadding={controlPadding}
      />
    );
  };

  private renderMap = (
    imageRef: mapUtils.MapImageRef,
    index: number,
    scaleControl?: mapboxgl.Control,
    mapProps: Partial<DeclarativeMapProps> = {},
    filterDuplicateAttributions = false
  ) => {
    const {
      onMapLoad,
      onMapClick,
      onMapMoved,
      selectedFeatureCollection,
      selectedFeatures,
      imageRefs,
      featureData,
      children,
      globalCursor,
      controlPosition,
      controlPadding,
      defaultBounds,
      defaultCameraOptions,
      firebaseToken,
      hideNavigationControl,
      organization,
    } = this.props;

    let mapOptions: Partial<mapboxgl.MapboxOptions> = {};

    if (defaultCameraOptions) {
      mapOptions = {...mapOptions, ...defaultCameraOptions};
    } else if (defaultBounds) {
      mapOptions.bounds = defaultBounds;
    } else if (selectedFeatureCollection) {
      const bounds = featureCollectionUtils.getBounds(selectedFeatureCollection, selectedFeatures);

      if (bounds) {
        mapOptions.bounds = bounds as BBox2d;
      }
    }

    return (
      <DeclarativeMap
        firebaseToken={firebaseToken}
        className={cs.map}
        mapOptions={mapOptions}
        globalCursor={globalCursor}
        onMapLoad={onMapLoad}
        onMapClick={onMapClick}
        onMapInitialize={this.setMap}
        onMapMoved={onMapMoved}
        controlPosition={controlPosition}
        controlPadding={controlPadding}
        hideNavigationControl={hideNavigationControl}
        {...mapProps}
      >
        {(map, isMapLoaded) => (
          <React.Fragment>
            <Attribution
              map={map}
              isMapLoaded={isMapLoaded}
              featureCollection={selectedFeatureCollection}
              featureData={featureData}
              imageRefs={imageRefs}
              organization={organization}
              filterDuplicates={filterDuplicateAttributions}
            />

            <MapContent
              map={map}
              isMapLoaded={isMapLoaded}
              controls={scaleControl ? [scaleControl] : undefined}
              controlPosition={'bottom-left'}
            />

            {children &&
              children({
                map,
                isMapLoaded,
                layerKey: imageRef.layerKey,
                cursor: imageRef.cursor,
                index,
              })}
          </React.Fragment>
        )}
      </DeclarativeMap>
    );
  };
}
