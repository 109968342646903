import * as B from '@blueprintjs/core';
import React from 'react';

import cs from './styles.styl';

/**
 * Renders a full-height, centered loading indicator.
 */
const Loading: React.FunctionComponent = () => (
  <div className={cs.loading}>
    <B.Spinner />
  </div>
);

export default Loading;
