import * as B from '@blueprintjs/core';
import React from 'react';

import {ExpandableList} from 'app/components/ExpandableList';
import {ApiProject} from 'app/modules/Remote/Project';
import {useUserInfo} from 'app/providers/AuthProvider';
import {useImageryPrices} from 'app/providers/ImageryPricesProvider';
import colors from 'app/utils/colorUtils';
import {alphanumericSort} from 'app/utils/featureUtils';
import {useOutsideClick} from 'app/utils/hookUtils';
import {ALL_LAYER_TAGS} from 'app/utils/layers';
import {showInternalTools} from 'app/utils/userUtils';

import {LayersLibraryMode} from './LensLibraryProvider';
import cs from './LensLibraryView.styl';
import {exportLibraryDatasetsToCsv} from './libraryCsvExport';
import {LayerDataset, LibraryDataset, LibraryDatasetType, OverlayDataset} from './LibraryTypes';

export type LayerSortParams = ['name' | 'resolution', 'asc' | 'desc' | null];
export type ResolutionFilter = 'submeter' | '1-5m' | '>5m';

export interface LibraryFilterState {
  nameSearch: string;
  resolutionFilter: Set<ResolutionFilter>;
  datasetTypeFilter: Set<LibraryDatasetType>;
  tagFilter: Set<string>;
  sortParams: LayerSortParams;
  addedLayers: Set<number> | 'unenrolled' | 'enrolled';
}

export const DEFAULT_LAYER_FILTER_STATE: LibraryFilterState = {
  nameSearch: '',
  resolutionFilter: new Set(),
  datasetTypeFilter: new Set(),
  tagFilter: new Set(),
  sortParams: ['name', null],
  addedLayers: new Set(),
};

const classifyResolution = (layer: LibraryDataset<LayerDataset>) => {
  if (layer.resolution < 1) return 'submeter';
  else if (layer.resolution >= 1 && layer.resolution <= 5) return '1-5m';
  else return '>5m';
};

export function filterLibraryDatasets(
  filterState: LibraryFilterState,
  datasets: LibraryDataset<LayerDataset | OverlayDataset>[],
  fcIdToProjectMap: Record<number, ApiProject>
): LibraryDataset<LayerDataset | OverlayDataset>[] {
  const {nameSearch, resolutionFilter, tagFilter, addedLayers, datasetTypeFilter} = filterState;
  const [sortField, sortDirection] = filterState.sortParams;

  let filteredDatasets = datasets;

  if (nameSearch !== '') {
    filteredDatasets = filteredDatasets.filter(
      (dataset) =>
        dataset.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
        (dataset.type === LibraryDatasetType.LAYER &&
          dataset.source.toLowerCase().includes(nameSearch.toLowerCase()))
    );
  }

  if (datasetTypeFilter.size) {
    filteredDatasets = filteredDatasets.filter((dataset) => {
      return datasetTypeFilter.has(dataset.type);
    });
  }

  if (resolutionFilter.size) {
    filteredDatasets = filteredDatasets.filter((dataset) => {
      return (
        dataset.type === LibraryDatasetType.LAYER &&
        resolutionFilter.has(classifyResolution(dataset))
      );
    });
  }

  if (tagFilter.size) {
    filteredDatasets = filteredDatasets.filter((dataset) =>
      dataset.tags.find((tag) => tagFilter.has(tag.label))
    );
  }

  if (addedLayers instanceof Set && addedLayers.size) {
    filteredDatasets = filteredDatasets.filter((dataset) => {
      if (dataset.type === LibraryDatasetType.OVERLAY) {
        const enrolledProjects = dataset.projectIds;
        const targetProjectIds = [...addedLayers].map((fcId) => fcIdToProjectMap[fcId].id);
        return enrolledProjects.some((projectId) => targetProjectIds.includes(projectId));
      }
      if (dataset.type === LibraryDatasetType.LAYER)
        return dataset.enrolledFeatureCollectionIds.some((fcId) => addedLayers.has(fcId));
    });
  }

  if (addedLayers === 'unenrolled') {
    filteredDatasets = filteredDatasets.filter(
      (dataset) =>
        (dataset.type === LibraryDatasetType.OVERLAY && dataset.projectIds.length === 0) ||
        (dataset.type === LibraryDatasetType.LAYER &&
          dataset.enrolledFeatureCollectionIds.length === 0)
    );
  }

  if (addedLayers === 'enrolled') {
    filteredDatasets = filteredDatasets.filter(
      (dataset) =>
        (dataset.type === LibraryDatasetType.OVERLAY && dataset.projectIds.length > 0) ||
        (dataset.type === LibraryDatasetType.LAYER &&
          dataset.enrolledFeatureCollectionIds.length > 0)
    );
  }

  if (sortDirection) {
    filteredDatasets.sort((a, b) => alphanumericSort(a[sortField], b[sortField]));
    if (sortDirection === 'desc') {
      filteredDatasets.reverse();
    }
  }

  return filteredDatasets;
}

export const LibraryFilterControls: React.FunctionComponent<
  React.PropsWithChildren<{
    libraryFilterState: LibraryFilterState;
    setLibraryFilterState: React.Dispatch<React.SetStateAction<LibraryFilterState>>;
    filteredDatasets: LibraryDataset<LayerDataset | OverlayDataset>[];
    fcIdToProjectMap: Record<number, ApiProject>;
    onClose?: () => void;
    mode: LayersLibraryMode;
  }>
> = ({
  libraryFilterState,
  setLibraryFilterState,
  filteredDatasets,
  onClose,
  mode,
  fcIdToProjectMap,
}) => {
  const {nameSearch, resolutionFilter, tagFilter, addedLayers, datasetTypeFilter} =
    libraryFilterState;
  const [sortField, sortDirection] = libraryFilterState.sortParams;
  const [organization, profile] = useUserInfo();
  const [isOptionClick, setIsOptionClick] = React.useState(false);

  const {prices: imageryPrices} = useImageryPrices();

  const setFilterState = (partialLayerFilterState: Partial<LibraryFilterState>) =>
    setLibraryFilterState((filterState: LibraryFilterState) => ({
      ...filterState,
      ...partialLayerFilterState,
    }));

  const toggleTagState = (tag: string) => {
    setLibraryFilterState((fs: LibraryFilterState) => {
      const newTagFilters = new Set(fs.tagFilter);
      if (tagFilter.has(tag)) {
        newTagFilters.delete(tag);
      } else {
        newTagFilters.add(tag);
      }
      return {
        ...fs,
        tagFilter: newTagFilters,
      };
    });
  };

  const handleResolutionFilterClick = (target: ResolutionFilter) => {
    const {resolutionFilter} = libraryFilterState;
    const newSet = new Set(resolutionFilter);
    if (resolutionFilter.has(target)) newSet.delete(target);
    else newSet.add(target);
    setFilterState({resolutionFilter: newSet});
  };

  const handleDatasetTypeFilterClick = (target: LibraryDatasetType) => {
    const {datasetTypeFilter} = libraryFilterState;
    const newSet = new Set(datasetTypeFilter);
    if (datasetTypeFilter.has(target)) newSet.delete(target);
    else newSet.add(target);
    setFilterState({datasetTypeFilter: newSet});
  };

  const handleAddedLayersFilterClick = (target: number) => {
    const {addedLayers} = libraryFilterState;
    const newSet = addedLayers instanceof Set ? new Set(addedLayers) : new Set<number>();
    if (addedLayers instanceof Set && addedLayers.has(target)) newSet.delete(target);
    else newSet.add(target);
    setFilterState({addedLayers: newSet});
  };

  const hideSpatialFilter =
    datasetTypeFilter.size === 1 && datasetTypeFilter.has(LibraryDatasetType.OVERLAY);

  // referring to properties of a datatype as attribute (type, resolution)
  const [attributeFilterOpen, setAttributeFilterOpen] = React.useState(false);
  const [portfolioFilterOpen, setPortfolioFilterOpen] = React.useState(false);

  // Override is present to persist the filter after interaction, clicking outside of a
  // filter popover will close it
  const attributeFilterPopoverRef = useOutsideClick(() => setAttributeFilterOpen(false));
  const portfolioFilterPopoverRef = useOutsideClick(() => setPortfolioFilterOpen(false));

  return (
    <div className={cs.filterControlBar}>
      <div style={{justifyContent: 'space-between'}}>
        <div className={cs.filterControls} style={{gap: '1rem'}}>
          {' '}
          <SearchBoxFilter searchValue={nameSearch} setSearchValue={setFilterState} />
          <B.Popover
            position={B.Position.BOTTOM_RIGHT}
            usePortal={false}
            isOpen={attributeFilterOpen}
            popoverRef={attributeFilterPopoverRef}
            content={
              <B.Menu>
                <>
                  <B.MenuItem
                    text={'Layers'}
                    icon={datasetTypeFilter.has(LibraryDatasetType.LAYER) ? 'small-tick' : 'blank'}
                    onClick={() => handleDatasetTypeFilterClick(LibraryDatasetType.LAYER)}
                  />
                  <B.MenuItem
                    text={'Overlays'}
                    icon={
                      datasetTypeFilter.has(LibraryDatasetType.OVERLAY) ? 'small-tick' : 'blank'
                    }
                    onClick={() => handleDatasetTypeFilterClick(LibraryDatasetType.OVERLAY)}
                  />
                </>
                {!hideSpatialFilter && (
                  <>
                    <B.MenuDivider />
                    <B.MenuItem
                      text={'<1m (Submeter)'}
                      icon={resolutionFilter.has('submeter') ? 'small-tick' : 'blank'}
                      onClick={() => handleResolutionFilterClick('submeter')}
                    />
                    <B.MenuItem
                      text={'1-5m'}
                      icon={resolutionFilter.has('1-5m') ? 'small-tick' : 'blank'}
                      onClick={() => handleResolutionFilterClick('1-5m')}
                    />
                    <B.MenuItem
                      text={'>5m'}
                      icon={resolutionFilter.has('>5m') ? 'small-tick' : 'blank'}
                      onClick={() => handleResolutionFilterClick('>5m')}
                    />
                  </>
                )}
                <B.MenuDivider />
                <B.MenuItem
                  text={'Reset'}
                  icon={'blank'}
                  onClick={() =>
                    setFilterState({
                      resolutionFilter: DEFAULT_LAYER_FILTER_STATE.resolutionFilter,
                      datasetTypeFilter: DEFAULT_LAYER_FILTER_STATE.datasetTypeFilter,
                    })
                  }
                />
                {showInternalTools(profile!, organization!, isOptionClick) && (
                  <B.MenuItem
                    text={'Download library metadata'}
                    icon={'download'}
                    onClick={() => exportLibraryDatasetsToCsv(filteredDatasets, imageryPrices)}
                  />
                )}
              </B.Menu>
            }
          >
            <FilterButton
              showButtonAsTag={true}
              active={!!(resolutionFilter.size || datasetTypeFilter.size)}
              icon={'filter'}
              text={'Filter by'}
              onClick={(e) => {
                if (e) {
                  setIsOptionClick(e.altKey);
                }
                if (!attributeFilterPopoverRef.current)
                  setAttributeFilterOpen((prevAttributeFilterOpen) => !prevAttributeFilterOpen);
              }}
            />
          </B.Popover>
          {mode === 'librarySettings' && (
            <B.Popover
              position={B.Position.BOTTOM_RIGHT}
              usePortal={false}
              popoverRef={portfolioFilterPopoverRef}
              isOpen={portfolioFilterOpen}
              content={
                <B.Menu>
                  <ExpandableList
                    className={cs.filterPortfolioMenu}
                    items={Object.entries(fcIdToProjectMap)
                      .sort(([fcidA, projectA], [fcidB, projectB]) => {
                        if (addedLayers instanceof Set) {
                          const hasA = addedLayers.has(Number(fcidA));
                          const hasB = addedLayers.has(Number(fcidB));

                          if (hasA && !hasB) return -1;
                          if (!hasA && hasB) return 1;
                        }
                        return alphanumericSort(projectA.name, projectB.name);
                      })
                      .map(([fcId, project]) => (
                        <B.MenuItem
                          key={fcId}
                          text={<span className={cs.truncate}>{project.name}</span>}
                          icon={
                            addedLayers instanceof Set && addedLayers.has(Number(fcId))
                              ? 'small-tick'
                              : 'blank'
                          }
                          onClick={() => handleAddedLayersFilterClick(Number(fcId))}
                        />
                      ))}
                    initialDisplayNum={10}
                    buttonProps={{
                      alignText: 'left',
                      icon: 'blank',
                    }}
                  />

                  <B.MenuDivider />
                  <B.MenuItem
                    text={'In any portfolio'}
                    onClick={() =>
                      setFilterState(
                        addedLayers === 'enrolled'
                          ? {addedLayers: DEFAULT_LAYER_FILTER_STATE.addedLayers}
                          : {addedLayers: 'enrolled'}
                      )
                    }
                    icon={addedLayers === 'enrolled' ? 'small-tick' : 'blank'}
                  />
                  <B.MenuItem
                    text={'Not in portfolios'}
                    onClick={() =>
                      setFilterState(
                        addedLayers === 'unenrolled'
                          ? {addedLayers: DEFAULT_LAYER_FILTER_STATE.addedLayers}
                          : {addedLayers: 'unenrolled'}
                      )
                    }
                    icon={addedLayers === 'unenrolled' ? 'small-tick' : 'blank'}
                  />
                  <B.MenuItem
                    text={'Reset'}
                    icon={'blank'}
                    onClick={() =>
                      setFilterState({addedLayers: DEFAULT_LAYER_FILTER_STATE.addedLayers})
                    }
                  />
                </B.Menu>
              }
            >
              <FilterButton
                showButtonAsTag={true}
                active={
                  addedLayers instanceof Set
                    ? !!addedLayers.size
                    : ['unenrolled', 'enrolled'].includes(addedLayers)
                }
                icon={'map'}
                text={'Portfolios'}
                onClick={() => {
                  if (!portfolioFilterPopoverRef.current)
                    setPortfolioFilterOpen((prevPortfolioFilterOpen) => !prevPortfolioFilterOpen);
                }}
              />
            </B.Popover>
          )}
          <B.Popover
            position={B.Position.BOTTOM_RIGHT}
            usePortal={false}
            content={
              <B.Menu>
                <B.MenuItem
                  text={sortField === 'name' ? 'A-Z' : 'Lowest to highest'}
                  icon={sortDirection === 'asc' ? 'small-tick' : 'blank'}
                  onClick={() => setFilterState({sortParams: [sortField, 'asc']})}
                />
                <B.MenuItem
                  text={sortField === 'name' ? 'Z-A' : 'Highest to lowest'}
                  icon={sortDirection === 'desc' ? 'small-tick' : 'blank'}
                  onClick={() => setFilterState({sortParams: [sortField, 'desc']})}
                />
                <B.MenuDivider />
                <B.MenuItem
                  text={'Name'}
                  icon={sortField === 'name' ? 'small-tick' : 'blank'}
                  onClick={() => setFilterState({sortParams: ['name', sortDirection]})}
                />
                <B.MenuItem
                  text={'Spatial Resolution'}
                  icon={sortField === 'resolution' ? 'small-tick' : 'blank'}
                  onClick={() => setFilterState({sortParams: ['resolution', sortDirection]})}
                />
              </B.Menu>
            }
          >
            <FilterButton
              showButtonAsTag={true}
              active={false}
              icon={sortDirection === 'asc' ? 'sort-asc' : 'sort-desc'}
              text={'Sort by'}
              buttonProps={{intent: 'primary'}}
            />
          </B.Popover>
        </div>
        {onClose && <B.Button minimal icon="cross" onClick={() => onClose()} />}
      </div>
      <div className={cs.filterControls}>
        {ALL_LAYER_TAGS.map((tag) => {
          return (
            <B.Tag
              key={tag.label}
              large={mode === 'librarySettings'}
              round
              interactive
              htmlTitle={tag.label}
              minimal
              active={tagFilter.has(tag.label)}
              style={{
                backgroundColor: tag.color,
                border: `2px solid ${tagFilter.has(tag.label) ? colors.primaryIntent : tag.color}`,
              }}
              onClick={() => toggleTagState(tag.label)}
            >
              {tag.label}
            </B.Tag>
          );
        })}
      </div>
    </div>
  );
};

const FilterButton: React.FC<{
  showButtonAsTag: boolean;
  text: string;
  active: boolean;
  icon?: B.IconName;
  onClick?: (event?: React.MouseEvent) => void;
  buttonProps?: B.ButtonProps;
}> = ({showButtonAsTag, onClick, text, icon, active, buttonProps}) => {
  return showButtonAsTag ? (
    <B.Tag
      minimal
      round
      large
      interactive
      className={active ? undefined : cs.filterButton}
      active={active}
      icon={icon}
      onClick={onClick}
    >
      {text}
    </B.Tag>
  ) : (
    <B.Button minimal active={active} icon={icon} text={text} onClick={onClick} {...buttonProps} />
  );
};

// Simplified version otherwise borrowed from PropertyOverview/filters.
const SearchBoxFilter: React.FunctionComponent<
  React.PropsWithChildren<{
    searchValue: string;
    setSearchValue: (searchValue: Partial<LibraryFilterState>) => void;
  }>
> = ({searchValue, setSearchValue}) => {
  const queryFieldRef = React.useRef<HTMLInputElement>();
  const query = searchValue || '';

  const onChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue({nameSearch: ev.currentTarget.value});
    },
    [setSearchValue]
  );

  // Clear text button
  const rightElement = query.length ? (
    <B.Button
      minimal={true}
      icon="delete"
      onClick={() => {
        setSearchValue({nameSearch: ''});

        // We need to re-focus to keep keyboard events going
        // through the input group.
        if (queryFieldRef.current) {
          queryFieldRef.current.focus();
        }
      }}
    />
  ) : undefined;

  return (
    <B.InputGroup
      inputRef={queryFieldRef as any}
      leftIcon={<B.Icon icon="search" color={colors.darkestGray} />}
      placeholder="Search…"
      dir="auto"
      value={query}
      round
      onChange={onChange}
      rightElement={rightElement}
      style={{width: '30rem'}}
    />
  );
};
