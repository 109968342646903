import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';
import {useAnimatingToEnd} from 'react-scroll-to-bottom';

import NoteCard, {NoteForm} from 'app/components/NoteCard/NoteCard';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {PolygonFeature} from 'app/providers/MapPolygonStateProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import {NotesActions, NotesState} from 'app/stores/NotesStore';
import {recordEvent} from 'app/tools/Analytics';
import {usePrevious} from 'app/utils/hookUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as noteUtils from 'app/utils/noteUtils';

import {Filters} from './Filters';
import {ReportExportState} from './ReportsView';
import {NoteSortingMethod, Sorts, noteSorters} from './Sorts';
import cs from './styles.styl';

const NEW_NOTE_ID = 'new';

export interface Props {
  firebaseToken?: string;
  featureData?: I.ImmutableListOf<ApiFeatureData> | null;

  selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
  selectedFeature: I.ImmutableOf<ApiFeature>;

  imageRefs: mapUtils.MapImageRefs;

  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;

  notesState: NotesState;
  notesActions: NotesActions;

  cameraOptions: mapUtils.CameraOptions | null;

  className?: string;
  onSelectImageRefs: (imageRefs: mapUtils.MapImageRefs) => void;

  zoomToGeometry: (g: geojson.GeoJSON) => unknown;
  onSelectNote: (nId: number | string) => unknown;

  promptForCoordinates?: () => unknown;

  openAnalyzePolygonPopup?: (feature: PolygonFeature) => unknown;

  enrolledLayerKeys?: string[];

  updateFeatureCollection: ProjectsActions['updateFeatureCollection'];

  organizationUsers: ApiOrganizationUser[];
  setNotesFilterState: (notesFilterState?: noteUtils.NotesFilterState) => void;
  setReportExportModalState: (reportExportState: ReportExportState | null) => void;
  notesFilterState: noteUtils.NotesFilterState;
  unsetFocusedNoteId: () => void;
}

const Notes: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  organization,
  profile,
  notesState,
  selectedFeature,
  imageRefs,
  notesActions,
  cameraOptions,
  zoomToGeometry,
  promptForCoordinates,
  onSelectImageRefs,
  firebaseToken,
  featureData,
  selectedFeatureCollection,
  enrolledLayerKeys,
  onSelectNote,
  openAnalyzePolygonPopup,
  updateFeatureCollection,
  setNotesFilterState,
  setReportExportModalState,
  notesFilterState,
  unsetFocusedNoteId,
}) => {
  const [isCreateNoteCardFormVisible, setIsCreateNoteCardFormVisible] = React.useState(false);
  // Only permit creating or editing a single note at once since we rely on
  // central state logic to add locations from the map.
  const [editingNoteId, setEditingNoteId] = React.useState<number | string | null>(null);
  const [noteSortingMethodology, setNoteSortingMethodology] =
    React.useState<NoteSortingMethod>('descending');

  //for our initial filters state, use the state passed in from MonitorProjectView. this enables
  //us to sync our state across the reports tab and notes tab more easily.
  const [localNotesFilterState, setLocalNotesFilterState] =
    React.useState<noteUtils.NotesFilterState>(notesFilterState);

  /** Ref for whatever note is focused (if any). Used for auto-scrolling. */
  const focusedNoteCardRef = React.useRef<HTMLDivElement>(null);

  // we want to scroll to the focused note anytime the component updates and
  // there is a focused note (this ensures users can see the full note card,
  // incl. save buttons in edit mode), EXCEPT when the hoveredNoteId updates.
  // hoveredNoteId updates frequently as users zoom around a map with many
  // note locations and that activates scrollToFocusedNote too often.
  const previousHoveredNoteId = usePrevious(notesState.hoveredNoteId);
  React.useEffect(() => {
    if (notesState.focusedNoteId && previousHoveredNoteId === notesState.hoveredNoteId) {
      noteUtils.scrollToFocusedNote(focusedNoteCardRef);
    }
  });

  // Cleanup function runs on component unmount
  React.useEffect(() => {
    return () => notesActions.stopEditingPendingGeometry();
  }, []);

  //keep MonitorProjectView's notesFilterState up to date with whatever we change
  //this component's local filter state to
  React.useEffect(() => {
    setNotesFilterState(localNotesFilterState);
  }, [localNotesFilterState]);

  const visibleNotes = React.useMemo(() => {
    const visibleNotes = notesState.notes.filter((n) =>
      noteUtils.filterNotesCallback(n, 'user', localNotesFilterState)
    );

    const sortedNotes = noteSorters[noteSortingMethodology].sortFunction({
      notes: visibleNotes,
      featureCollection: selectedFeatureCollection,
    });

    return sortedNotes;
  }, [noteSortingMethodology, notesState.notes, localNotesFilterState, selectedFeatureCollection]);

  const numFiltersApplied =
    +!!localNotesFilterState.noteTagIds.length +
    +!!(localNotesFilterState.dateRange[0] || localNotesFilterState.dateRange[1]);

  function renderNotes(isReadyForCharts: boolean) {
    if (notesState.loading) {
      // Render loading container.
      return (
        <div className={cs.loadingContainer}>
          <B.Spinner />
        </div>
      );
    }

    if (notesState.loadingError) {
      // Return error state.
      return (
        <B.NonIdealState
          iconMuted={false}
          className={cs.bodyEmpty}
          description="We couldn’t load notes right now."
          icon="error"
          action={
            <B.Button
              type="button"
              text="Try Again"
              onClick={() => notesActions.loadNotes()}
              intent={B.Intent.PRIMARY}
            />
          }
        />
      );
    }

    if (visibleNotes.length === 0) {
      return (
        <B.NonIdealState className={cs.bodyEmpty} icon="annotation" iconMuted={false}>
          <p>
            Jot down your observations as notes so you and your team can easily refer back to them
            over time.
          </p>
          <p>
            <strong>Add your first note to get started!</strong>
          </p>
        </B.NonIdealState>
      );
    }

    return visibleNotes.map((note) => (
      <NoteCard
        firebaseToken={firebaseToken}
        featureData={featureData}
        key={note.id}
        note={note}
        cameraOptions={cameraOptions}
        focusedNoteCardRef={focusedNoteCardRef}
        imageRefs={imageRefs}
        organization={organization}
        profile={profile}
        featureCollection={selectedFeatureCollection}
        feature={selectedFeature}
        enrolledLayerKeys={enrolledLayerKeys}
        notesState={notesState}
        notesActions={notesActions}
        mode={editingNoteId === note.id ? 'edit' : 'view'}
        onChangeMode={(m) => setEditingNoteId(m === 'edit' ? note.id : null)}
        disableGeometry={false}
        disableEditing={editingNoteId === NEW_NOTE_ID}
        promptForCoordinates={promptForCoordinates}
        onSelectImageRefs={onSelectImageRefs}
        zoomToGeometry={zoomToGeometry}
        onSelectNote={onSelectNote}
        openAnalyzePolygonPopup={openAnalyzePolygonPopup}
        isChartLoading={!isReadyForCharts}
        updateFeatureCollection={updateFeatureCollection}
        unsetFocusedNoteId={unsetFocusedNoteId}
      />
    ));
  }

  const geometryNotes = React.useMemo(
    () => noteUtils.getGeometryNotes(visibleNotes),
    [visibleNotes]
  );

  const exportShapefile = React.useCallback(() => {
    noteUtils.downloadNotesShapefile(geometryNotes, selectedFeature.getIn(['properties', 'name']));
  }, [selectedFeature, geometryNotes]);

  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);

  return (
    <div
      className={classnames({
        [cs.container]: true,
        ...(className
          ? {
              [className]: true,
            }
          : {}),
      })}
    >
      <div className={cs.toolbar}>
        <B.Tooltip
          disabled={notesState.mayCreateNotes}
          content={"You don't have permissions to make a note"}
        >
          <B.Button
            icon={'plus'}
            outlined
            intent="primary"
            text={'Add a note'}
            onClick={() => {
              setIsCreateNoteCardFormVisible(true);
              setEditingNoteId(NEW_NOTE_ID);
              notesActions.focusNote(null);
            }}
            disabled={!notesState.mayCreateNotes}
          />
        </B.Tooltip>

        <B.Popover
          interactionKind={B.PopoverInteractionKind.CLICK}
          content={
            <Sorts
              noteSortingMethodology={noteSortingMethodology}
              setNoteSortingMethodology={setNoteSortingMethodology}
            />
          }
        >
          <B.Tooltip content={'Sort'}>
            <B.AnchorButton icon={noteSorters[noteSortingMethodology].icon || 'sort'} minimal />
          </B.Tooltip>
        </B.Popover>
        <B.Popover
          isOpen={isFiltersOpen}
          interactionKind={B.PopoverInteractionKind.CLICK}
          onClose={() => setIsFiltersOpen(false)}
          position="right"
          content={
            <Filters
              notesFilterState={localNotesFilterState}
              setNotesFilterState={setLocalNotesFilterState}
              onClose={() => setIsFiltersOpen(false)}
              notes={notesState.notes}
              featureCollection={selectedFeatureCollection}
            />
          }
        >
          <B.Tooltip content={'Filter'}>
            <B.AnchorButton
              icon={'filter'}
              minimal
              active={isFiltersOpen || !!numFiltersApplied}
              text={
                !!numFiltersApplied && <div className={cs.filterButton}>{numFiltersApplied}</div>
              }
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            />
          </B.Tooltip>
        </B.Popover>

        <B.Tooltip
          content={
            <>
              Create a report with notes shown here{' '}
              <B.Tag minimal>
                {`${visibleNotes.length} ${visibleNotes.length == 1 ? 'note' : 'notes'}`}
              </B.Tag>
            </>
          }
        >
          <B.Button
            icon={'document'}
            minimal
            onClick={(ev) => {
              setNotesFilterState(localNotesFilterState);
              setReportExportModalState({
                newTab: ev.ctrlKey || ev.metaKey,
                allParts: false,
              });
              recordEvent('Created report');
            }}
          />
        </B.Tooltip>

        <B.Tooltip
          content={
            <>
              Export shapefiles{' '}
              <B.Tag minimal>{`${geometryNotes.length} ${
                geometryNotes.length == 1 ? 'note' : 'notes'
              } with location`}</B.Tag>
            </>
          }
        >
          <B.AnchorButton
            minimal
            icon={'download'}
            disabled={geometryNotes.length < 1}
            onClick={exportShapefile}
          />
        </B.Tooltip>
      </div>

      <div className={cs.body}>
        {!!visibleNotes.length && !isCreateNoteCardFormVisible && (
          <div className={cs.resultsLabel}>
            {visibleNotes.length} {visibleNotes.length === 1 ? 'result' : 'results'}
          </div>
        )}
        <B.Collapse
          className={classnames({[cs.hideCollapse]: !isCreateNoteCardFormVisible})}
          isOpen={isCreateNoteCardFormVisible}
        >
          <NoteForm
            firebaseToken={firebaseToken}
            featureData={featureData}
            organization={organization}
            profile={profile}
            featureCollection={selectedFeatureCollection}
            imageRefs={imageRefs}
            notesState={notesState}
            notesActions={notesActions}
            onClose={() => {
              setEditingNoteId(null);
              setIsCreateNoteCardFormVisible(false);
            }}
            onSelectImageRefs={onSelectImageRefs}
            promptForCoordinates={promptForCoordinates}
            zoomToGeometry={zoomToGeometry}
            autoUpdateLayerAndCursors={true}
            disableGeometry={false}
            isFocused={true}
            onNoteCreated={(note) => {
              recordEvent('Created note from scratch', {
                projectId: note.projectId,
                featureCollectionId: note.featureCollectionId,
                noteId: note.id,
              });
            }}
            updateFeatureCollection={updateFeatureCollection}
          />
        </B.Collapse>
        {renderNotes(true)}
      </div>
    </div>
  );
};

export const ScrollToBottomContent: React.FunctionComponent<
  React.PropsWithChildren<{
    notesState: NotesState;
    children: (isReadyForCharts: boolean) => React.ReactNode;
  }>
> = ({notesState, children}) => {
  /** We can use the react-scroll-to-bottom library’s useAnimatingToEnd hook for
   * identifying if the panel is actively scrolling. However, this tends to
   * switch quickly between true and false when the panel is first opening,
   * perhaps due to a rendering race condition with the velocity-animate
   * library. So, we also corroborate the useAnimatingToEnd hook return value
   * with information about when we were last scrolling to smooth over those
   * initial updates. */
  const [isScrolling] = useAnimatingToEnd();
  const lastScrollStartMs = React.useRef<number | null>(null);
  const lastScrollPauseMs = React.useRef<number | null>(null);

  const [isReadyForCharts, setIsReadyForCharts] = React.useState(false);

  React.useEffect(() => {
    const nowMs = Date.now();
    if (isScrolling) {
      lastScrollStartMs.current = nowMs;
    } else {
      lastScrollPauseMs.current = nowMs;
    }
  }, [isScrolling]);

  React.useEffect(() => {
    if (notesState.loading) {
      /** Clear out any old information about scrolling and ready status. */
      lastScrollStartMs.current = null;
      lastScrollPauseMs.current = null;
      setIsReadyForCharts(false);
    } else {
      /** Poll regularly to see where we are in the scrolling process. If we
       * never started scrolling, or have paused for a while, consider the panel
       * ready to load chart canvas elements. */
      const interval = setInterval(() => {
        const neverStartedScrolling = lastScrollStartMs.current === null;

        /** “Paused for a while” means it’s been at least a half-second since we
         * last paused scrolling, and haven’t start scrolling since. We’re
         * defensive against an unlikely, or maybe impossible, scenario in which
         * we’ve paused scrolling but never started. */
        const pausedScrollingForAWhile =
          lastScrollPauseMs.current &&
          lastScrollPauseMs.current > (lastScrollStartMs.current ?? 0) &&
          Date.now() - lastScrollPauseMs.current > 500;

        if (neverStartedScrolling || pausedScrollingForAWhile) {
          setIsReadyForCharts(true);
          clearInterval(interval);
        }
      }, 500);

      /** Escape hatch so we don’t poll indefinitely in the event the
       * setInterval callback above never triggers. */
      const timeout = setTimeout(() => {
        setIsReadyForCharts(true);
        clearInterval(interval);
      }, 10000);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [notesState.loading]);

  return <React.Fragment>{children(isReadyForCharts)}</React.Fragment>;
};

export default Notes;
