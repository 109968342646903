import React from 'react';

import * as Remote from 'app/modules/Remote';
import {ApiImageryPrices} from 'app/modules/Remote/Imagery';
import {useCachedApiGet} from 'app/utils/hookUtils';

interface ImageryPricesContextValue {
  prices: ApiImageryPrices;
  refresh: () => void;
}
const ImageryPricesContext = React.createContext<undefined | ImageryPricesContextValue>(undefined);

const ImageryPricesProvider: React.FunctionComponent<React.PropsWithChildren> = ({children}) => {
  const [getPrices, {invalidate}] = useCachedApiGet(async () => {
    return (await Remote.api.imagery.prices.fetch()).get('data').toJS() as ApiImageryPrices;
  }, []);
  const pricesMaybe = getPrices([]);
  return (
    <ImageryPricesContext.Provider
      value={{prices: pricesMaybe.value || {}, refresh: () => invalidate([])}}
    >
      {children}
    </ImageryPricesContext.Provider>
  );
};

export function useImageryPrices(): ImageryPricesContextValue {
  const value = React.useContext(ImageryPricesContext);

  if (value === undefined) {
    throw new Error('useImageryPrices must be beneath an ImageryPricesProvider');
  }

  return value;
}

export const WithImageryPrices: React.FunctionComponent<{
  children: (imageryPricesContextValue: ImageryPricesContextValue) => React.ReactElement;
}> = ({children}) => {
  const ImageryPricesContextValue = React.useContext(ImageryPricesContext);

  if (ImageryPricesContextValue === undefined) {
    throw new Error('WithImageryPrices must be beneath an ImageryPricesProvider');
  }

  return children(ImageryPricesContextValue);
};

export const FAKE_IMAGERY_PRICES = {
  'AIRBUS-PLEIADES': 6,
  'AIRBUS-PLEIADES-ARCHIVE': 6,
  'AIRBUS-PNEO': 6,
  'AIRBUS-PNEO-ARCHIVE': 6,
  'AIRBUS-SPOT': 4,
  'AIRBUS-SPOT-ARCHIVE': 4,
  'DIGITAL-GLOBE-30': 6,
  'DIGITAL-GLOBE-50': 6,
  'NEARMAP-15': 100,
  'NEARMAP-50': 6,
  'PLANET-FOREST-CARBON-3': 10,
  'PLANET-FOREST-CARBON-30': 4,
  'PLANET-SKYSAT-COLLECT': 6,
};

export const FakeImageryPricesProvider: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => (
  <ImageryPricesContext.Provider
    value={{
      prices: FAKE_IMAGERY_PRICES,
      refresh: () => {},
    }}
  >
    {children}
  </ImageryPricesContext.Provider>
);

export default ImageryPricesProvider;
