import * as I from 'immutable';

import {ApiOrganizationUser} from 'app/modules/Remote/Organization';

export const ORG_USERS: ApiOrganizationUser[] = [
  {
    id: '1a',
    email: 'marshall@upstream.tech',
    name: 'Marshall Moutenot',
    organizationKey: 'demo',
    role: 'owner' as const,
    organizations: [],
    settings: {},
  },
  {
    id: '2b',
    email: 'alden@upstream.tech',
    name: 'Alden Keefe Sampson',
    organizationKey: 'demo',
    role: 'owner' as const,
    organizations: [],
    settings: {},
  },
  {
    id: '3c',
    email: 'emily@upstream.tech',
    name: 'Emily',
    organizationKey: 'demo',
    role: 'regular' as const,
    organizations: [],
    settings: {},
  },
  {
    id: '4d',
    email: 'danielkatz125@gmail.com',
    name: 'Dan',
    organizationKey: 'demo',
    role: 'regular' as const,
    organizations: [],
    settings: {},
  },
  {
    id: '5e',
    email: 'bob@upstream.tech',
    name: 'Robert Frost',
    organizationKey: 'demo',
    role: 'readonly' as const,
    organizations: [],
    deletedLocally: true,
    settings: {},
  },
  {
    id: '6f',
    email: 'fiona@upstream.tech',
    name: 'Fiona',
    organizationKey: 'demo',
    role: 'regular' as const,
    organizations: [],
    settings: {},
  },
];

export const REGULAR_USER = I.fromJS(ORG_USERS.find((u) => u!.role === 'regular'));
export const OWNER_USER = I.fromJS(ORG_USERS.find((u) => u!.role === 'owner'));
export const READONLY_USER = I.fromJS(ORG_USERS.find((u) => u!.role === 'readonly'));
