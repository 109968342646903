import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import AppNav from 'app/components/AppNav';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';

import cs from './InternalTools.styl';

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  firebaseIsEmailVerified: boolean;
  loggedInUserActions: LoggedInUserActions;
}

const InternalTools: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  firebaseIsEmailVerified,
  loggedInUserActions,
}) => {
  // The convertShapefile API endpoint requires that the Firebase account be an
  // admin, so we need to verify email addresses even in the Upstream Tech test
  // organization.
  const needsVerification = !firebaseIsEmailVerified;

  const [isVerificationEmailSent, setIsVerificationEmailSent] = React.useState(false);

  return (
    <React.Fragment>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />

      <div className={cs.container}>
        <div className={cs.header}>
          <h2>Internal Tools</h2>
        </div>

        <div className={cs.body}>
          {needsVerification && (
            <>
              <B.NonIdealState
                iconMuted={false}
                icon="envelope"
                description="This account needs its email address verified."
                action={
                  <B.Button
                    intent="primary"
                    disabled={isVerificationEmailSent}
                    onClick={async () => {
                      await loggedInUserActions.sendVerificationEmail();
                      setIsVerificationEmailSent(true);
                    }}
                  >
                    Send Verification Email
                  </B.Button>
                }
              >
                {isVerificationEmailSent && (
                  <p>
                    Verification email sent to <strong>{profile.get('email')}</strong>.{' '}
                    {profile.get('email').startsWith('team+') &&
                      'Check Hubspot for "team+" accounts.'}
                  </p>
                )}
              </B.NonIdealState>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InternalTools;
