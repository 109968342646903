import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as I from 'immutable';
import React from 'react';
import {Link} from 'react-router-dom';

import {AlertPolicy, ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {useUserInfo} from 'app/providers/AuthProvider';
import {FeaturesActions} from 'app/providers/FeaturesProvider';
import {recordEvent} from 'app/tools/Analytics';
import * as CONSTANTS from 'app/utils/constants';
import {featureCollectionInUS} from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import {S2_NDVI} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import {getOrgPrefix} from 'app/utils/organizationUtils';

import * as cs from './styles.styl';
import {ExpandableList} from '../ExpandableList';
import {AlertConfigurationModal} from './AlertConfigurationModal';
import {useAlertPolicies} from './AlertPolicyProvider';

export interface AlertsPoliciesPanelProps {
  updateFeature: FeaturesActions['updateFeature'];
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;
  feature: I.ImmutableOf<ApiFeature>;
  allFeatures: ApiFeature[];
}

export const AlertsPoliciesPanel: React.FC<React.PropsWithChildren<AlertsPoliciesPanelProps>> = ({
  updateFeature,
  featureCollection,
  feature,
  allFeatures,
}) => {
  const fcInUS = featureCollectionInUS(featureCollection);
  const enrolledLayerKeys = layerUtils.getEnrolledLayerKeysForFeatureCollection(featureCollection);
  const [configurationModalState, setConfigurationModalState] = React.useState<{
    isOpen: boolean;
    alertPolicy?: AlertPolicy;
  }>({isOpen: false});
  const [organization] = useUserInfo();

  const {
    alertPolicies,
    actions: {enrollFeaturesInAlertPolicy, unenrollFeaturesInAlertPolicy, archiveAlertPolicy},
    meta: {isFetching: isFetchingAlerts},
  } = useAlertPolicies();

  const vegetationDropAlertsEnabled = featureUtils.keyInAppProperties(
    feature,
    CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY
  );

  const ownershipChangeAlertsEnabled = featureUtils.keyInAppProperties(
    feature,
    CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY
  );

  const onVegetationEnrollmentChange = React.useCallback(() => {
    const nextIsEnabled = !vegetationDropAlertsEnabled;
    updateFeature(
      feature.get('id'),
      featureUtils.makeAlertEnrollmentAppPropertyPatch(
        CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY,
        nextIsEnabled
      )
    );
    nextIsEnabled ? recordEvent('Enabled Lookout policy') : recordEvent('Disabled Lookout policy');
  }, [feature, updateFeature, vegetationDropAlertsEnabled]);

  const onOwnershipEnrollmentChange = React.useCallback(() => {
    const nextIsEnabled = !ownershipChangeAlertsEnabled;
    updateFeature(
      feature.get('id'),
      featureUtils.makeAlertEnrollmentAppPropertyPatch(
        CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY,
        nextIsEnabled
      )
    );
    nextIsEnabled ? recordEvent('Enabled Lookout policy') : recordEvent('Disabled Lookout policy');
  }, [feature, ownershipChangeAlertsEnabled, updateFeature]);

  const unenrolledInS2Vegetation = !enrolledLayerKeys.includes(S2_NDVI);

  const defaultAlerts = [
    <AlertPolicyRow
      key="default-veg-policy"
      icon={<B.Icon icon="tree" color="green" />}
      name="Unseasonal vegetation drop"
      isEnabled={vegetationDropAlertsEnabled}
      enrollmentHandler={onVegetationEnrollmentChange}
      //switchDisabled, tooltipEnabled, and tooltipMessage are all optional props
      //that can support a tooltip displayed on hover of the AlertSwitch row
      switchDisabled={unenrolledInS2Vegetation && !vegetationDropAlertsEnabled}
      tooltipEnabled={unenrolledInS2Vegetation}
      tooltipMessage={
        vegetationDropAlertsEnabled ? (
          <div className={cs.unenrolledLayerTooltip}>
            Vegetation drop alerts are enabled, but you are not enrolled in the necessary vegetation
            layers. Add S2 Vegetation from the{' '}
            <Link to={`/${getOrgPrefix(organization!)}/settings/layers`} target="_blank">
              Layers Library page
            </Link>
            to finish enrollment.
          </div>
        ) : (
          <div className={cs.unenrolledLayerTooltip}>
            First, add the S2 Vegetation from the{' '}
            <Link
              to={`/${getOrgPrefix(organization!)}/settings/layers`}
              onClick={() => recordEvent('Followed missing layer warning')}
              target="_blank"
            >
              Layers Library page
            </Link>
            . Then, use this switch to activate vegetation alerts for this property.
          </div>
        )
      }
      actionsMenu={
        <B.AnchorButton
          small
          minimal
          icon={
            <B.Icon
              icon="info-sign"
              size={14}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          }
          href="https://support.upstream.tech/article/115-vegetation-drop-alerts"
          target="_blank"
          rel="noopener noreferrer"
        />
      }
    />,
    fcInUS && (
      <AlertPolicyRow
        key="default-ownership-policy"
        icon={<B.Icon icon="key" color="orange" />}
        name="Change in parcel ownership"
        isEnabled={ownershipChangeAlertsEnabled}
        enrollmentHandler={onOwnershipEnrollmentChange}
        actionsMenu={
          <B.AnchorButton
            small
            minimal
            icon={
              <B.Icon
                icon="info-sign"
                size={14}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            href="https://support.upstream.tech/article/129-parcel-owner-alerts"
            target="_blank"
            rel="noopener noreferrer"
          />
        }
      />
    ),
  ];

  const enrollOrUnenroll = React.useCallback(
    (alertPolicy: AlertPolicy, isEnabled: boolean, featureId: number) => {
      if (isEnabled) {
        unenrollFeaturesInAlertPolicy(alertPolicy, [featureId]);
        recordEvent('Disabled Lookout policy');
      } else {
        enrollFeaturesInAlertPolicy(alertPolicy, [featureId]);
        recordEvent('Enabled Lookout policy');
      }
    },
    [unenrollFeaturesInAlertPolicy, enrollFeaturesInAlertPolicy]
  );

  const renderAlertPolicyRow = React.useCallback(
    (alertPolicy: AlertPolicy) => {
      const featureId = feature.get('id');
      const isEnabled = !!alertPolicy.enrollments?.find(
        (enrollment) => enrollment?.featureId === featureId
      );

      return (
        <AlertPolicyRow
          key={alertPolicy.id}
          name={alertPolicy.name}
          isEnabled={isEnabled}
          enrollmentHandler={() => enrollOrUnenroll(alertPolicy, isEnabled, featureId)}
          actionsMenu={
            <PolicyConfigurationOptionsPanel
              editCallback={() =>
                setConfigurationModalState({
                  isOpen: true,
                  alertPolicy,
                })
              }
              deleteCallback={() => {
                if (confirm(`Delete ${alertPolicy.name}?`)) {
                  archiveAlertPolicy(alertPolicy.id);
                }
              }}
            />
          }
        />
      );
    },
    [feature, enrollOrUnenroll, setConfigurationModalState, archiveAlertPolicy]
  );

  const renderedAlertPolicies = React.useMemo(() => {
    return defaultAlerts.concat(alertPolicies.map(renderAlertPolicyRow));
  }, [defaultAlerts, alertPolicies, renderAlertPolicyRow]);

  return (
    <>
      <AlertConfigurationModal
        featureCollection={featureCollection}
        allFeatures={allFeatures}
        selectedFeatureId={feature.get('id')}
        alertPolicy={configurationModalState.alertPolicy}
        isOpen={configurationModalState.isOpen}
        onClose={() => setConfigurationModalState({isOpen: false})}
      />
      <div className={cs.alertSettings}>
        <B.Button
          onClick={() => setConfigurationModalState({isOpen: true})}
          icon="plus"
          text="Create a policy"
          intent="primary"
          outlined
          style={{marginBottom: '1rem'}}
        />
        {isFetchingAlerts ? (
          <B.Spinner key="alert-policy-fetching-spinner" />
        ) : (
          <ExpandableList items={renderedAlertPolicies} initialDisplayNum={4} />
        )}
      </div>
    </>
  );
};

export interface AlertPolicyRowProps {
  name: string;
  icon?: React.ReactElement;
  className?: string;
  isEnabled: boolean;
  enrollmentHandler: () => void;
  tooltipEnabled?: boolean;
  tooltipMessage?: React.ReactElement;
  switchDisabled?: boolean;
  actionsMenu?: React.ReactElement;
}

export const AlertPolicyRow = ({
  name,
  icon,
  className,
  isEnabled,
  enrollmentHandler,
  tooltipEnabled,
  tooltipMessage,
  switchDisabled,
  actionsMenu,
}: AlertPolicyRowProps) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <div
      className={classnames(cs.alertPolicyRow, className)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <B.Tooltip content={tooltipMessage} disabled={!tooltipEnabled}>
        <B.Switch disabled={switchDisabled} checked={isEnabled} onChange={enrollmentHandler} />
      </B.Tooltip>
      <span className={cs.truncate}>
        <span style={icon && {marginRight: '0.5rem'}}>{icon}</span>
        {name}
      </span>
      {!!actionsMenu && hovered ? actionsMenu : <div></div>}
    </div>
  );
};

export interface PolicyConfigurationOptionsPanelProps {
  editCallback: () => void;
  deleteCallback: () => void;
}

export const PolicyConfigurationOptionsPanel: React.FC<
  React.PropsWithChildren<PolicyConfigurationOptionsPanelProps>
> = ({editCallback, deleteCallback}) => {
  return (
    <B.Popover
      position={B.PopoverPosition.BOTTOM_LEFT}
      minimal
      interactionKind={B.PopoverInteractionKind.HOVER}
      modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
      content={
        <B.Menu style={{minWidth: 0}}>
          <B.MenuItem icon="edit" text="View/Edit" onClick={() => editCallback()} />
          <B.MenuItem icon="trash" text="Delete" onClick={() => deleteCallback()} />
        </B.Menu>
      }
    >
      <B.AnchorButton
        small
        minimal
        icon={
          <B.Icon
            icon="more"
            size={14}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
      />
    </B.Popover>
  );
};
