import React from 'react';

import {ApiOrganization} from 'app/modules/Remote/Organization';
import * as C from 'app/src/utils/constants';

import cs from './Dashboard.styl';

type DashboardPageProps = {
  organization: ApiOrganization;
};

const Dashboard: React.FC<DashboardPageProps> = ({organization}) => {
  const orgName = organization.name;
  const isEnterprise = organization.tiers.Lens === C.LENS_TIER_ENTERPRISE;

  return (
    <div className={cs.dashboardContainer} data-testid="dashboard-container">
      <h1>
        Placeholder {!isEnterprise ? 'preview' : ''} dashboard for {orgName}
      </h1>
      <p>Under construction!</p>
    </div>
  );
};

export default Dashboard;
