import * as Sentry from '@sentry/react';
import React from 'react';

import GlobalError from './view';

const GlobalErrorBoundary: React.FunctionComponent<
  React.PropsWithChildren<{
    errorContent?: React.ReactElement;
  }>
> = ({errorContent, children}) => (
  <Sentry.ErrorBoundary
    fallback={errorContent ?? <GlobalError />}
    beforeCapture={(scope) => {
      scope.setTag('ux', errorContent ? 'custom' : 'lostInSpace');
    }}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default GlobalErrorBoundary;
