import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as I from 'immutable';
import sortBy from 'lodash/sortBy';
import React from 'react';

import MapOverlayDialog from 'app/components/MapOverlayDialog/MapOverlayDialog';
import {GeometryOverlaySetting} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {getOrgPrefix} from 'app/utils/organizationUtils';
import {overlayIsReady} from 'app/utils/overlayUtils';

import * as cs from './ManageLayersDialog.styl';
import SwatchColorPicker from '../../pages/MonitorProjectView/SwatchColorPicker';

interface BaseProps {
  organization?: I.ImmutableOf<ApiOrganization>;
  profile?: I.ImmutableOf<ApiOrganizationUser>;
  disableConfiguration?: boolean;
  onClose: () => void;
}

interface OverlayProps {
  overlaySettings: GeometryOverlaySetting[];
  setOverlaySettings: (s: GeometryOverlaySetting[]) => void;
  isOverlayVisibleByName: Record<string, boolean>;
  setIsOverlayVisibleByName: (p: Record<string, boolean>) => void;
}

/**
 * Dialog for viewing and customizing vector overlays
 * Implemented with MapOverlayDialog so that most of the map is visible while
 * you’re tweaking colors.
 */
const ManageLayersDialog: React.ForwardRefRenderFunction<
  HTMLDivElement,
  BaseProps & OverlayProps
> = (
  {
    organization,
    profile,
    disableConfiguration = false,
    onClose,
    overlaySettings,
    setOverlaySettings,
    setIsOverlayVisibleByName,
    isOverlayVisibleByName,
  },
  ref
) => {
  // Create a ref that'll have the value of inside elements.
  const swatchRef = React.useRef<HTMLElement | null>(null);
  const settingsRef = React.useRef<HTMLElement | null>(null);

  overlaySettings = React.useMemo(() => sortBy(overlaySettings, 'name'), [overlaySettings]);

  const footer =
    organization && profile ? (
      <div>
        <div className={cs.divider} />
        <a
          href={`/${getOrgPrefix(organization)}/settings/layers`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div>Explore the Lens Library</div>
        </a>
      </div>
    ) : null;

  return (
    <MapOverlayDialog
      ref={ref}
      title="Change Overlays"
      titleIcon={<B.Icon icon="layers" style={{marginTop: 2}} />}
      className={cs.dialog}
      onClose={onClose}
      canOutsideClickClose={true}
      insideRefs={[swatchRef, settingsRef]}
    >
      {overlaySettings.length ? (
        <div>
          {overlaySettings.map(({color, defaultEnabled, name, defaultUnfilled, status}) => (
            <div
              key={name}
              className={classnames(
                {[cs.overlaySelected]: isOverlayVisibleByName[name]},
                cs.overlayRow
              )}
            >
              <div className={cs.colorSwatchWrapper}>
                <SwatchColorPicker
                  color={color}
                  setColor={(c) =>
                    setOverlaySettings(
                      overlaySettings.map((s) => (s.name === name ? {...s, color: c} : s))
                    )
                  }
                  fill={defaultUnfilled ? false : true}
                  setFill={(f) => {
                    setOverlaySettings(
                      overlaySettings.map((s) =>
                        s.name === name ? {...s, defaultFilled: f, defaultUnfilled: !f} : s
                      )
                    );
                  }}
                  polygon={true}
                  popoverRef={swatchRef}
                />
              </div>

              <B.Tooltip
                content={`Click to ${isOverlayVisibleByName[name] ? 'hide' : 'show'} ${name}`}
                // Allows popper.js to position the popover outside of the dialog’s bounds
                modifiers={{
                  preventOverflow: {enabled: false},
                  hide: {enabled: false},
                  flip: {enabled: false},
                }}
                position="bottom-right"
                className={cs.overlayRowTooltip}
              >
                <div
                  className={cs.overlayName}
                  onClick={() =>
                    setIsOverlayVisibleByName({
                      [name]: !isOverlayVisibleByName[name],
                    })
                  }
                >
                  {!overlayIsReady(status) && (
                    <B.Icon icon="time" intent="warning" className={cs.overlayProcessingIcon} />
                  )}
                  {name}
                </div>
              </B.Tooltip>

              {!disableConfiguration && (
                <B.Popover
                  interactionKind="click"
                  minimal
                  position="bottom-right"
                  popoverRef={settingsRef}
                  content={
                    <div className={cs.overlayRowSettings}>
                      <B.Switch
                        checked={defaultEnabled}
                        label={`${defaultEnabled ? 'Visible' : 'Hidden'} by default`}
                        onChange={() =>
                          setOverlaySettings(
                            overlaySettings.map((s) =>
                              s.name === name ? {...s, defaultEnabled: !defaultEnabled} : s
                            )
                          )
                        }
                      ></B.Switch>
                    </div>
                  }
                >
                  <B.AnchorButton
                    className={cs.overlayRowIcon}
                    minimal
                    icon={'cog'}
                    aria-label={'Overlay Settings'}
                  />
                </B.Popover>
              )}
            </div>
          ))}
          {footer}
        </div>
      ) : (
        <div>
          <div>No overlays are associated with this portfolio.</div>
          {footer}
        </div>
      )}
    </MapOverlayDialog>
  );
};

// forwardRef so that our parent can get access to the div for sizing
export default React.forwardRef(ManageLayersDialog);
