import * as geojson from 'geojson';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import React from 'react';

import {GraphMode} from 'app/components/AnalyzePolygonPopup/AnalyzePolygonPopup';
import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {
  RasterCalculator,
  TileData,
  filterFeatureDataByType,
  getTemplateUrl,
} from 'app/stores/RasterCalculationStore';
import * as featureUtils from 'app/utils/featureUtils';
import {StatusMaybe, useMemoAsync} from 'app/utils/hookUtils';
import {useReducerWithDeps} from 'app/utils/hookUtils';
import {LayerInfo} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

export type PolygonFeature = geojson.Feature<
  geojson.Polygon | geojson.MultiPolygon,
  {noteGraphLayerKey?: string} | null
> | null;

//TODO(eva): since this is specific to AA, would be nice to factor this out of this more generic
//polygon state provider
export type AnalyzePolygonDrawMode =
  | 'drawPolygon'
  | 'drawRectangle'
  | 'selectOverlayPolygon'
  | 'selectProperty'
  | 'clipPolygon';

/**
 * State we’re responsible for keeping track of. We dereference the polygon out
 * of its note or feature source for convenience.
 */
export interface SelectedPolygonState {
  // We keep the Feature as well as the polygon since the drawing mode
  // returns Feature objects.
  polygonFeature: PolygonFeature;
  polygon: geojson.Polygon | geojson.MultiPolygon | null;
}

export interface AnalyzeAreaDrawModeState {
  analyzeAreaDrawMode: AnalyzePolygonDrawMode;
}

export enum OverlayMaskSource {
  ANALYSIS = 'analysis',
  MAP = 'map',
}

export type OverlayMaskFilter =
  | {type: 'threshold'; threshold: [number, number]}
  | {type: 'category'; categories: Set<string>};

export interface OverlayMaskState {
  // TODO(fiona): Change to imageRefs
  layerKey: string;
  cursors: (string | null)[];
  masks: ({image: ImageData; bounds: geojson.BBox} | null)[];
  source: OverlayMaskSource;
}

export interface MaskState {
  overlayMask: OverlayMaskState | null;
}

export type PolygonState = SelectedPolygonState & MaskState & AnalyzeAreaDrawModeState;

type Action =
  | {
      type: 'setPolygonFeature';
      polygonFeature: PolygonFeature;
    }
  | ({type: 'setOverlayMask'} & OverlayMaskState)
  | {type: 'clearOverlayMask'}
  | {type: 'setAnalyzeAreaDrawMode'; analyzeAreaDrawMode: AnalyzePolygonDrawMode};

export type PolygonDispatch = (action: Action) => void;

/** Provider that can update the state of a polygon on the map. */
function reducer(state: PolygonState, action: Action): PolygonState {
  switch (action.type) {
    case 'setPolygonFeature':
      // There’s enough of a cost in the polygon changing (due to
      // recalculations) that it’s worth doing deep equality here to make sure
      // it _really_ has changed. Especially important since MapboxDraw does not
      // return consistent Feature objects in its events.
      return isEqual(state.polygonFeature, action.polygonFeature)
        ? state
        : {
            ...state,
            polygonFeature: action.polygonFeature,
            polygon: action.polygonFeature && action.polygonFeature.geometry,
          };

    case 'clearOverlayMask':
      return state.overlayMask !== null ? {...state, overlayMask: null} : state;

    case 'setOverlayMask': {
      // We don’t bother to try and dedupe anything here because we can’t
      // cheaply do equality checks between masks.
      const {type, ...overlayMask} = action;
      return {
        ...state,
        overlayMask,
      };
    }

    case 'setAnalyzeAreaDrawMode':
      return state.analyzeAreaDrawMode !== action.analyzeAreaDrawMode
        ? {...state, analyzeAreaDrawMode: action.analyzeAreaDrawMode}
        : state;

    default:
      return state;
  }
}

const MapPolygonStateProvider: React.FunctionComponent<{
  featureId: number | null;
  children: React.ReactNode | null;
}> = ({featureId, children}) => {
  const [state, dispatch] = useReducerWithDeps(
    reducer,
    {
      polygonFeature: null,
      polygon: null,
      overlayMask: null,
      analyzeAreaDrawMode: 'drawPolygon',
    },
    // Resets the polygon and overlay data when the property changes
    [featureId]
  );

  return (
    <MapPolygonContext.Provider value={state}>
      <MapPolygonDispatchContext.Provider value={dispatch}>
        {children}
      </MapPolygonDispatchContext.Provider>
    </MapPolygonContext.Provider>
  );
};

const MapPolygonContext = React.createContext<PolygonState | undefined>(undefined);
const MapPolygonDispatchContext = React.createContext<PolygonDispatch | undefined>(undefined);

export function useMapPolygonState(): PolygonState {
  const value = React.useContext(MapPolygonContext);

  if (!value) {
    throw new Error('useMapPolygonState must be beneath a MapPolygonStateProvider');
  }

  return value;
}

export function useMapPolygonDispatch(): PolygonDispatch {
  const value = React.useContext(MapPolygonDispatchContext);

  if (!value) {
    throw new Error('useMapPolygonDispatch must be beneath a MapPolygonStateProvider');
  }

  return value;
}

export const WithMapPolygonState: React.FunctionComponent<{
  children: (state: PolygonState, dispatch: PolygonDispatch) => React.ReactElement;
}> = ({children}) => {
  const state = useMapPolygonState();
  const dispatch = useMapPolygonDispatch();
  return children(state, dispatch);
};

export default MapPolygonStateProvider;

// Kept as a constant so it can be used in useMemoAsync deps.
const DEFAULT_DATA_RANGE = [0, 1] as const;

/** We’re making an assumption for now that any image layer we’re feeding into
 * the raster calculator can be interpreted as categorical data where different
 * categories are encoded as different raw pixel values. So, our data range
 * matches that of the RGB channels: 0 to 255. */
const IMAGE_LAYER_DATA_RANGE = [0, 255] as const;

export function getDataRange(layer: LayerInfo | null) {
  return layer
    ? layer.type === 'data'
      ? layer.dataRange
      : IMAGE_LAYER_DATA_RANGE
    : DEFAULT_DATA_RANGE;
}

/** ms to throttle how often we call the threshold masks dispatch. kind of a magic number.*/
const THRESHOLD_MASKS_THROTTLE_WAIT = 50;

/**
 * Hook to handle updating the overlay mask state of MapPolygonStateProvider.
 *
 * Returns two functions: one to set the overlay mask to a given threshold pair,
 * and one to clear it.
 */
export function useOverlayMask(
  firebaseToken: string | null,
  dispatch: PolygonDispatch,
  imageRefs: mapUtils.MapImageRefs,
  featureData: I.ImmutableListOf<ApiFeatureData>,
  cursorKey: keyof ApiFeatureData,
  graphMode: GraphMode | null,
  layerKey: string | undefined,
  polygon: geojson.Polygon | geojson.MultiPolygon | null,
  tileData: StatusMaybe<TileData>,
  hasThumbnailUrls: boolean,
  source: OverlayMaskSource
) {
  // We only mask the layer corresponding to the first image ref, though in the
  // current UI they will be the same for all data layers. (The mask won’t apply
  // to truecolor high-res.)
  const layerInfo = layerUtils.getLayer(
    (imageRefs.find((ir) => ir.layerKey === layerKey) || imageRefs[0]).layerKey
  );
  const dataRange = getDataRange(layerInfo);

  const [isCalculating, setIsCalculating] = React.useState(false);

  // We don’t bother with error and retry for this use case.
  const [maskCalculators] = useMemoAsync(async () => {
    setIsCalculating(true);
    return Promise.all(
      imageRefs.map(async ({layerKey, cursor}) => {
        // Filter feature data down to entries where the activeLayerKey is
        // present in the `types` property.
        const filteredFeatureData = filterFeatureDataByType(featureData, layerKey);

        // warning: ImmutuableJS’s findLast types don’t handle the case where
        // the thing is not found, so TypeScript is not ensuring that we’re
        // checking missing cases below.
        const featureDatum = filteredFeatureData.findLast((d) => d!.get(cursorKey) === cursor);

        let calculatorPromise: Promise<RasterCalculator> | null = null;

        const dataLayerKey = layerUtils.getRawLayerKey(layerKey);

        if (hasThumbnailUrls) {
          const url = !!dataLayerKey && featureDatum.getIn(['images', 'urls', dataLayerKey]);
          const bounds = featureUtils.getBboxFromBounds(
            I.fromJS(featureUtils.getFeatureBoundsforSource(featureDatum, layerKey))
          );

          // fromImage returns Promise<RasterCalculator | null>, so we have to await the value and check it
          const maybeCalculatorPromise =
            tileData.status === 'some' && url
              ? await RasterCalculator.fromImage(url, bounds, dataRange)
              : null;

          // Rewrap our RasterCalculator to match downstream expectations or set an explicit null
          if (maybeCalculatorPromise) {
            calculatorPromise = Promise.resolve(maybeCalculatorPromise);
          } else {
            calculatorPromise = null;
          }
        } else {
          const templateUrl =
            dataLayerKey && getTemplateUrl(featureDatum!, dataLayerKey, firebaseToken);

          // TODO: RasterCalculator.fromTiles now accepts an AbortController
          // signal if we want to make these requests cancellable.
          calculatorPromise =
            tileData.status === 'some' && templateUrl
              ? RasterCalculator.fromTiles(tileData.value, templateUrl, dataRange, firebaseToken)
              : null;
        }
        setIsCalculating(false);

        // We don’t bother to dedupe between this and the
        // useRasterTimeSeriesCalculator hook’s calculators because there will
        // only be 1 or 2 of these (so memory isn’t a big deal) and we assume
        // that browser caching will prevent the images from being loaded again.
        return {
          // It’s important to pass the cursor since the calculator creation is
          // async, so we need a way to tie the calculator to its original
          // cursor.
          cursor,
          calculator: await calculatorPromise,
        };
      })
    );
  }, [imageRefs, featureData, cursorKey, tileData, dataRange, firebaseToken, hasThumbnailUrls]);

  // Makes a throttled function that applies a threshold mask that will get
  // picked up by FeatureRaster. We want this function to close over all of its
  // dependencies (rather than pass them in as args) so that when they change we
  // get a new function with a new throttle.
  const updateOverlayMask = React.useMemo(
    () =>
      throttle(
        (overlayMaskFilter: OverlayMaskFilter) => {
          // We don’t want the mask to be applied if we're looking at a graph and the polygon
          // isn’t defined, because then the popup doesn’t show any controls for changing it.
          if (
            graphMode &&
            (!(graphMode === 'area' || graphMode === 'areaByCategory') || !polygon)
          ) {
            dispatch({type: 'clearOverlayMask'});
          } else if (maskCalculators && layerKey && polygon) {
            dispatch({
              type: 'setOverlayMask',
              masks: maskCalculators.map(
                ({calculator}) => calculator && calculator.generateMask(polygon, overlayMaskFilter)
              ),
              cursors: maskCalculators.map(({cursor}) => cursor),
              layerKey: layerKey,
              source: source,
            });
          }
        },
        THRESHOLD_MASKS_THROTTLE_WAIT,
        {leading: false}
      ),
    [graphMode, polygon, maskCalculators, layerKey, dispatch, source]
  );

  const clearOverlayMask = React.useCallback(() => {
    dispatch({type: 'clearOverlayMask'});
  }, [dispatch]);

  return [updateOverlayMask, clearOverlayMask, isCalculating] as const;
}

export const FakeMapPolygonStateProvider: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const value: PolygonState = {
    polygon: null,
    polygonFeature: null as any,
    overlayMask: null,
    analyzeAreaDrawMode: 'drawPolygon',
  };
  return <MapPolygonContext.Provider value={value}>{children}</MapPolygonContext.Provider>;
};

export const FakeMapPolygonDispatchProvider: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <MapPolygonDispatchContext.Provider value={() => null}>
      {children}
    </MapPolygonDispatchContext.Provider>
  );
};
