import * as B from '@blueprintjs/core';
import {AddressElement} from '@stripe/react-stripe-js';
import React, {FormEvent} from 'react';

import Loading from 'app/components/Loading';
import LensLogo from 'app/components/Logo/LensLogo';
import {FormState} from 'app/pages/SignUp';
import * as CONSTANTS from 'app/utils/constants';

import FormField from './FormField';
import cs from './styles.styl';

interface Props {
  onSubmit: () => void;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  isSubmitting: boolean;
  errorMessage: string;
  isLoading: boolean;
}

const NewCustomerSignUp: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  onSubmit,
  formState,
  setFormState,
  isSubmitting,
  errorMessage,
  isLoading,
}) => {
  const {
    accessSubscriptionType,
    accountEmail,
    organizationName,
    billingAddress,
    agreeToService,
    taxExempt,
    subscriptionAddOns,
  } = formState;

  const handleOnChange = (event: FormEvent<HTMLInputElement>) => {
    const element = event.currentTarget;
    const value = element.type === 'checkbox' ? element.checked : element.value;
    const name = element.name;

    setFormState((s) => ({...s, [name]: value}));
  };

  const [isAddressComplete, setIsAddressComplete] = React.useState(false);
  const [addOnWithUnsupportedCountry, setAddOnWithUnsupportedCountry] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    let addOnToSet;
    if (billingAddress.country) {
      subscriptionAddOns.forEach((addOn) => {
        const allowedCountries: string[] | undefined =
          CONSTANTS.LENS_BUNDLE_SIGNUP_OPTIONS[addOn].allowed_countries;
        if (allowedCountries && !allowedCountries.includes(billingAddress.country)) {
          addOnToSet = addOn;
        }
      });
    }
    setAddOnWithUnsupportedCountry(addOnToSet);
  }, [subscriptionAddOns, billingAddress.country]);

  const makeAddOnWithUnsupportedCountryMessage = (addOn: string) => {
    if (addOn.includes('planet_basemaps')) {
      return (
        <span>
          This offering is currently only available to customers located in North and South America.
          Located outside of this geography?{' '}
          <a
            href="https://share.hsforms.com/17hQWmdp0Q7uDslTht7Ji5gccnl3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register your interest here and we'll be in touch.
          </a>
        </span>
      );
    } else {
      return (
        <span>This offering is currently unavailable to customers in {billingAddress.country}</span>
      );
    }
  };
  return (
    <>
      {isLoading && <Loading />}
      {/*
        We need to always render this div so that the AddressElement
        starts loading.
       */}
      <div className={cs.user} style={{visibility: isLoading ? 'hidden' : 'visible'}}>
        <LensLogo
          isWhite={true}
          className={cs.logo}
          onClick={() => (window.location.href = 'https://www.upstream.tech/lens')}
        />

        <div className={cs.content}>
          <form className={cs.signupForm}>
            <h2>
              {[
                CONSTANTS.LENS_ACCESS_SUBSCRIPTION_SIGNUP_OPTIONS[accessSubscriptionType]?.name ||
                  'Lens Enterprise',
                ...subscriptionAddOns.map(
                  (addOn) => CONSTANTS.LENS_BUNDLE_SIGNUP_OPTIONS[addOn].display
                ),
              ].join(' + ')}
            </h2>
            <p>
              Sign up today to start monitoring the landscapes that matter to you more efficently
              than ever before.
            </p>
            <p>Let's begin by gathering some information about you and your organization.</p>

            {/* TODO (maya): Do we want to validate that this is a valid email? Doesn't seem like this is something we do anywhere else we collect emails in the app */}
            <FormField
              value={accountEmail}
              label={'Your email'}
              name={'accountEmail'}
              inputType={'email'}
              onChange={handleOnChange}
            />

            <AddressElement
              className="bp5-form-group"
              options={{
                mode: 'billing',
                autocomplete: {
                  mode: 'google_maps_api',
                  apiKey: 'AIzaSyAkzfPPcUv64BT0Z3ZWgOLne238MqxBtwc',
                },
                blockPoBox: true,
                display: {
                  name: 'organization',
                },
                defaultValues: {
                  name: organizationName,
                  address: billingAddress,
                },
              }}
              onChange={(event) => {
                setFormState((s) => ({
                  ...s,
                  organizationName: event.value.name || '',
                  billingAddress: event.value.address,
                }));
                setIsAddressComplete(event.complete);
              }}
            />

            <div className={B.Classes.FORM_GROUP}>
              <div className={B.Classes.FORM_CONTENT}>
                <B.Checkbox
                  checked={agreeToService}
                  labelElement={
                    <>
                      I agree to the Lens{' '}
                      <a
                        href="https://upstream.tech/licenses"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://upstream.tech/ethics"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ethics Policy
                      </a>
                      .
                    </>
                  }
                  onChange={() => {
                    setFormState((s) => ({...s, agreeToService: !agreeToService}));
                  }}
                />
              </div>
            </div>
            <div className={B.Classes.FORM_GROUP}>
              <div className={B.Classes.FORM_CONTENT}>
                <B.Checkbox
                  checked={taxExempt}
                  labelElement={
                    <>
                      <span>Check this box if your organization is exempt from sales tax.</span>
                      <br />
                      <span className={cs.taxExemptText}>
                        Please note, this is different than non-profit status and is dependent on
                        your specific state law.
                      </span>
                      {taxExempt && (
                        <>
                          <br />
                          <span className={cs.taxExemptText}>
                            To confirm sales tax exemption status, please send an exemption
                            certificate to accounting@upstream.tech within five business days.
                          </span>
                        </>
                      )}
                    </>
                  }
                  onChange={() => {
                    setFormState((s) => ({...s, taxExempt: !taxExempt}));
                  }}
                />
              </div>
            </div>

            {errorMessage && (
              <B.Callout intent={B.Intent.DANGER} icon={null}>
                {errorMessage}
              </B.Callout>
            )}
            {addOnWithUnsupportedCountry && (
              <B.Callout intent={B.Intent.WARNING} icon={null}>
                {makeAddOnWithUnsupportedCountryMessage(addOnWithUnsupportedCountry)}
              </B.Callout>
            )}
            <B.Button
              intent={B.Intent.PRIMARY}
              disabled={
                !accessSubscriptionType ||
                !accountEmail ||
                !agreeToService ||
                !isAddressComplete ||
                !!addOnWithUnsupportedCountry
              }
              onClick={onSubmit}
              loading={isSubmitting}
              text={'Proceed to Billing'}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default NewCustomerSignUp;
