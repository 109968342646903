import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import FilterableList from 'app/components/FilterableList';
import {ListItem} from 'app/components/List';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {FeatureSelection} from 'app/pages/ProjectDashboardSwitch';
import {TagFilter, TagFilterValue, featureTagFilter} from 'app/pages/PropertyOverview/filters';
import {APP_PROPERTIES_KEY, APP_PROPERTY_ASSIGNEE_EMAIL} from 'app/utils/constants';
import {hydratedFeatureCollectionAsApiFeatureCollection} from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';
import {useContinuity} from 'app/utils/hookUtils';
import * as routeUtils from 'app/utils/routeUtils';
import * as tagUtils from 'app/utils/tagUtils';

import * as cs from './styles.styl';

type FeatureListItem = ListItem<
  {
    lensIds?: I.Set<string>;
    href?: string;
  },
  string
>;

export type FilterType = {type: 'all'} | {type: 'assigned'} | {type: 'tags'; value: TagFilterValue};

export interface Props {
  selectedFeatureCollection: HydratedFeatureCollection;
  selectedFeatureLensIds: I.Set<string>;
  selectedProject: I.ImmutableOf<ApiProject>;

  // The authenticated user's profile (could be in a parent org)
  profile: I.ImmutableOf<ApiOrganizationUser>;
  // The selected organization (could be a child)
  organization: I.ImmutableOf<ApiOrganization>;

  className?: string;
  listClassName?: string;

  deselectText?: string | null;

  filter: FilterType;
  setFilter: null | ((f: FilterType) => void);

  /** We keep this on FeatureList to make it easier to test without mocking anything out. */
  changeSelection: (selection: FeatureSelection) => void;
  hideBackLink?: boolean;
}

const FeatureList: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  organization,
  profile,
  listClassName,
  className,
  selectedFeatureCollection,
  selectedFeatureLensIds,
  selectedProject,
  hideBackLink,
  changeSelection,
  filter,
  // We keep filter state outside of this component so that it persists across
  // the menu opening.
  setFilter,
}) => {
  // pull these out to ignore other updates to FeatureCollection or Profile or Project
  const features = selectedFeatureCollection.get('features');
  const profileEmail = profile.get('email');
  const selectedProjectId = selectedProject.get('id');

  const tagSettings = tagUtils.getTagSettings(
    hydratedFeatureCollectionAsApiFeatureCollection(selectedFeatureCollection),
    tagUtils.TAG_KIND.FEATURE
  );

  if (filter.type === 'tags') {
    // Handles tags that are being filtered on getting deleted.
    const activeTagIds = filter.value.tagIds
      .filter((id) => tagSettings.get(id!) && !tagSettings.get(id!).get('isArchived'))
      .toSet();

    if (!I.is(activeTagIds, filter.value.tagIds)) {
      filter = {
        type: 'tags',
        value: {
          op: filter.value.op,
          tagIds: activeTagIds,
        },
      };
    }
  }

  // Keeps us from recalculating items more than necessary when the above
  // activeTagIds check modifies filter.
  filter = useContinuity(filter);

  // Continuity on this since it may be built using map in the caller.
  selectedFeatureLensIds = useContinuity(selectedFeatureLensIds, I.is);

  const items = React.useMemo<FeatureListItem[]>(
    () =>
      features
        .groupBy((f) => f!.getIn(['properties', 'name']))
        .map((fs) => fs!.sortBy((f) => f!.get('id')))
        .filter((fs) => {
          switch (filter.type) {
            case 'all':
              return true;
            case 'assigned':
              return (
                fs!
                  .first()
                  .getIn(['properties', APP_PROPERTIES_KEY, APP_PROPERTY_ASSIGNEE_EMAIL], '') ===
                profileEmail
              );
            case 'tags':
              return featureTagFilter(fs!.toList(), filter.value);
          }
        })
        .map<FeatureListItem>((fs) => {
          const features = fs!.toList();
          const lensIds = fs!.map((f) => f!.getIn(['properties', 'lensId'])!).toSet();

          const tagIds = tagUtils.getFeaturesTagIds(features);

          const tagCounts = tagUtils.getFeaturesTagCounts(features);

          return {
            text: fs!.first().getIn(['properties', 'name']),
            lensIds,
            isSelected: selectedFeatureLensIds.intersect(lensIds).size > 0,
            href: routeUtils.makeProjectDashboardUrl(
              organization,
              selectedProjectId,
              'map',
              lensIds.toArray()
            ),
            rightEl: (
              <>
                {tagSettings
                  .filter((s) => tagIds.contains(s!.get('id')))
                  .map((s) => (
                    <tagUtils.Tag
                      key={s!.get('id')}
                      setting={s!}
                      small
                      indeterminate={tagCounts.get(s!.get('id')) !== features.size}
                    />
                  ))
                  .toArray()}
              </>
            ),
          };
        })
        .sortBy((i) => i!.text, featureUtils.alphanumericSort)
        .toArray(),
    [
      features,
      selectedFeatureLensIds,
      filter,
      profileEmail,
      selectedProjectId,
      tagSettings,
      organization,
    ]
  );

  return (
    <div className={cs.container}>
      {(!hideBackLink || setFilter) && (
        <div className={cs.toolbar}>
          <div className={cs.projectNameText}>
            {hideBackLink ? (
              // We put an empty element here so that the filter buttons still get
              // pushed to the left.
              <span>{selectedProject.get('name')}</span>
            ) : (
              <a
                onClick={(ev) => {
                  ev.preventDefault();

                  changeSelection({
                    organizationId: organization.get('id'),
                    projectId: selectedProjectId,
                    featureIds: null,
                  });
                }}
              >
                {`← Back to ${selectedProject.get('name')}`}
              </a>
            )}
          </div>

          {setFilter && (
            <B.ButtonGroup minimal>
              <B.Tooltip content="Show all properties">
                <B.AnchorButton
                  icon="globe"
                  active={
                    filter.type === 'all' ||
                    // Wee hack since w/o tagIds set the tags filter shows "all"
                    (filter.type === 'tags' && filter.value.tagIds.isEmpty())
                  }
                  onClick={() => setFilter({type: 'all'})}
                />
              </B.Tooltip>
              <B.Tooltip content="Show properties assigned to me">
                <B.AnchorButton
                  icon="person"
                  active={filter.type === 'assigned'}
                  onClick={() => setFilter({type: 'assigned'})}
                />
              </B.Tooltip>
              <TagFilter
                tagSettings={tagSettings}
                value={filter.type === 'tags' ? filter.value : {tagIds: I.Set(), op: 'all'}}
                setValue={(value) =>
                  setFilter({type: 'tags', value: value ? value : {tagIds: I.Set(), op: 'all'}})
                }
              />
            </B.ButtonGroup>
          )}
        </div>
      )}
      {items.length === 0 ? (
        <div className={cs.message}>
          {filter.type === 'assigned' && 'There aren’t any properties assigned to you.'}
          {filter.type === 'tags' && 'There aren’t any properties that match the filter.'}
        </div>
      ) : (
        <FilterableList
          items={items}
          // enough space for tags
          itemHeight={32}
          onClickItem={(
            item: FeatureListItem,
            event: React.SyntheticEvent | React.MouseEvent | undefined
          ) => {
            // If the item has an `href` property and the shift or command key
            // is being pressed, open a window instead of changing the current
            // selection.
            //
            // Casting event as `any` here since the @blueprintjs/select
            // `onItemSelect` function is invoked with an `event` parameter
            // typed as a `SyntheticEvent`, which doesn't have `shiftKey` and
            // `metaKey` properties. This may be a mistyping, since the `event`
            // provided to the callback does have those properties.
            if (item.href && event && ((event as any).shiftKey || (event as any).metaKey)) {
              event.stopPropagation();
              window.open(item.href, (event as any).metaKey ? '_blank' : undefined);
              return;
            }

            changeSelection({
              organizationId: organization.get('id'),
              projectId: selectedProjectId,
              featureIds: item.lensIds || null,
            });
          }}
          listClassName={listClassName || ''}
          className={className || ''}
        />
      )}
    </div>
  );
};

export default FeatureList;
