import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import * as turf from '@turf/turf';
import classnames from 'classnames';
import geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';

import MapOverlayDialog from 'app/components/MapOverlayDialog/MapOverlayDialog';
import {ToastNotification} from 'app/modules/Notification';
import {api} from 'app/modules/Remote';
import {ApiFeature, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiDollarUnitUsage, ApiImageryContract} from 'app/modules/Remote/Project';
import {useImageryPrices} from 'app/providers/ImageryPricesProvider';
import {recordEvent} from 'app/tools/Analytics';
import {ApiResponseError} from 'app/utils/apiUtils';
import * as C from 'app/utils/constants';
import * as CONSTANTS from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import {calculateCost, formatCost} from 'app/utils/costUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {UTMArea, calculateBillableUTMAreaInAcres} from 'app/utils/geoJsonUtils';
import {CachedApiGetterOptions, StatusMaybe, useTimestampMs} from 'app/utils/hookUtils';
import {formatCaptureDate, getLayerKeyForScene} from 'app/utils/imageryUtils';
import {
  CHLORIS_10_PACKAGE,
  CHLORIS_30_PACKAGE,
  LAYER_PACKAGES,
  PLANET_FOREST_DILIGENCE_PACKAGE,
  PLANET_FOREST_MONITORING_PACKAGE,
} from 'app/utils/layers';
import {getLayer, isLayerKeyRaw} from 'app/utils/layerUtils';
import {getOrgPrefix} from 'app/utils/organizationUtils';
import * as userUtils from 'app/utils/userUtils';

import ImageryPreviewImage from './ImageryPreviewImage';
import cs from './styles.styl';

export interface Props {
  orderableScene: I.ImmutableOf<ApiOrderableScene>;
  feature: I.ImmutableOf<ApiFeature>;
  organization: I.ImmutableOf<ApiOrganization>;
  role: ApiOrganizationUser['role'];
  modalUrl: string | null;

  disabledSources?: string[];
  blockedSources?: string[];

  getCurrentImageryContract: (
    opts: CachedApiGetterOptions
  ) => StatusMaybe<ApiImageryContract | null>;

  onClose: () => void;
  onSubmit: (billableAcres: number) => void;

  isOpen?: boolean;

  subsetFeature: geojson.Feature<geojson.Polygon> | null;
}

const DollarUnitContractDetails: React.FunctionComponent<{
  usage: ApiDollarUnitUsage;
  acreage: number;
  orderableScene: I.ImmutableOf<ApiOrderableScene>;
  pricePerAcre: number;
}> = ({usage, acreage, orderableScene, pricePerAcre}) => {
  const orderableSceneType =
    getLayerKeyForScene(orderableScene) in LAYER_PACKAGES ? 'package' : 'image';
  const usedCents = usage.purchased_cents;
  const currentOrderCents = calculateCost(acreage, pricePerAcre);

  if (usage.nte_cents === null) {
    const remainingPrepaidCents = usage.prepaid_cents - usedCents;

    if (remainingPrepaidCents <= 0) {
      return (
        <p>
          Ordering this {orderableSceneType} costs <strong>{formatCost(currentOrderCents)}</strong>.
          By clicking “Order", you agree to this charge. Please note that orders cannot be refunded
          after purchase.
        </p>
      );
    } else if (remainingPrepaidCents >= currentOrderCents) {
      return (
        <p>
          Ordering this {orderableSceneType} will use{' '}
          <strong>{formatCost(currentOrderCents)}</strong> of the remaining prepaid{' '}
          <strong>{formatCost(remainingPrepaidCents)}</strong> for this portfolio. Please note that
          orders cannot be refunded after purchase.
        </p>
      );
    } else {
      return (
        <p>
          Ordering this {orderableSceneType} costs <strong>{formatCost(currentOrderCents)}</strong>.
          By clicking “Order”, you agree to use the remaining prepaid{' '}
          <strong>{formatCost(remainingPrepaidCents)}</strong> for this portfolio and a charge of{' '}
          <strong>{formatCost(Math.max(currentOrderCents - remainingPrepaidCents))}</strong> on your
          next invoice. Please note that orders cannot be refunded after purchase.
        </p>
      );
    }
  } else {
    const remainingCents = usage.nte_cents - usedCents;

    if (usedCents + currentOrderCents <= usage.nte_cents) {
      return (
        <p>
          Ordering this {orderableSceneType} will use{' '}
          <strong>{formatCost(currentOrderCents)}</strong> of the remaining{' '}
          <strong>{formatCost(remainingCents)}</strong> for this portfolio. Please note that orders
          cannot be refunded after purchase.
        </p>
      );
    } else if (remainingCents > 0) {
      return (
        <>
          <p>
            Ordering this {orderableSceneType} costs{' '}
            <strong>{formatCost(currentOrderCents)}</strong>, but you cannot order it because there
            is only <strong>{formatCost(remainingCents)}</strong> remaining for this portfolio.
          </p>

          <p>Please have an account administrator contact us for options.</p>
        </>
      );
    } else {
      return (
        <>
          <p>
            Ordering this {orderableSceneType} costs{' '}
            <strong>{formatCost(currentOrderCents)}</strong>, but you cannot order it because there
            are no funds remaining for this portfolio.
          </p>

          <p>Please have an account administrator contact us for options.</p>
        </>
      );
    }
  }
};

export const PURCHASE_DISABLED_SOURCES = CONSTANTS.DISABLE_ORDERS_SOURCE_IDS;
export const THUMBNAIL_BLOCKED_SOURCES = [];

const OrderImageryModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  feature,
  role,
  modalUrl,
  isOpen = true,
  onClose,
  onSubmit,
  orderableScene,
  getCurrentImageryContract,
  disabledSources = PURCHASE_DISABLED_SOURCES,
  blockedSources = THUMBNAIL_BLOCKED_SOURCES,
  subsetFeature,
  organization,
}) => {
  const [isFeatureHidden, setIsFeatureHidden] = React.useState<boolean>(false);

  const {url, sourceDetails} = featureUtils.imageAndSourceDetailsFromScene(orderableScene);
  const sourceId = orderableScene.get('sourceId');

  const {prices: imageryPrices} = useImageryPrices();
  const pricePerAcre: number | null = imageryPrices[sourceId];

  let sceneCoverage = 0;
  let sceneCoverageFailed = false;

  try {
    // This can raise an exeception if the geometry is weird (polygon has
    // duplicate points, or precision is too high leading to epsilon errors).
    sceneCoverage = featureUtils.getSceneCoverage(orderableScene, feature);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      extra: {
        featureId: feature.get('id'),
        orderableSceneSource: sourceId,
        orderableSceneSensingTime: orderableScene.get('sensingTime'),
      },
    });
    sceneCoverageFailed = true;
  }

  const isPartialScene = sceneCoverage < 1;

  const {billableAcres, orderGeometryAcres} = getBillableAcres(
    feature,
    orderableScene,
    subsetFeature
  );

  const subsetFeatureAcres = React.useMemo(() => {
    return (
      subsetFeature &&
      Math.floor(conversionUtils.convert(UTMArea(subsetFeature), C.UNIT_AREA_M2, 'areaAcre'))
    );
  }, [subsetFeature]);

  const isDisabledSource = disabledSources.includes(sourceId);

  const [contractAfterMs, refreshContractAfterMs] = useTimestampMs();
  const imageryContractStatusMaybe = getCurrentImageryContract({
    afterMs: contractAfterMs,
    // We won't interrupt with a loading spinner if the last contract was
    // fetched within the last 60 seconds, but we will still fetch the latest.
    allowStaleMs: contractAfterMs - 60 * 1000,
  });

  // Guarantee that if we have an imagery contract it is of type dollarUnit. AcreUnit
  // contracts are no longer created and non are active. It still exists in the type
  // because we display info about expired contracts in the org settings
  const imageryContract =
    imageryContractStatusMaybe.value &&
    imageryContractStatusMaybe.value.contractType === CONSTANTS.CONTRACT_TYPE_DOLLAR_UNIT
      ? imageryContractStatusMaybe.value
      : null;

  const exceedsContractLimit =
    imageryContract && !canPurchaseOnContract(imageryContract, billableAcres, pricePerAcre);

  const isProcessing = orderableScene.get('isOrdered') && !orderableScene.get('orderProcessed');
  const isPartiallyOrdered =
    !orderableScene.get('isFullyOrdered') && orderableScene.get('isOrdered');
  const canOrder = orderableScene.get('canBeOrdered');
  const isOrderingSubset = !!subsetFeature;

  const sceneCloudCoverPercentage: number = orderableScene.get('cloudCoverPercentage');

  const layerKey = getLayerKeyForScene(orderableScene);
  const isPackage = layerKey in LAYER_PACKAGES;
  // Only show preview image for planet package if we are placing a partial order so
  // the user can see the area they are ordering. Otherwise the preview is unhelpful
  // since we have no image to show
  const showImageryPreview = !isPackage || isOrderingSubset;

  const orderExceedsMaximumOrderAcres =
    sourceDetails.maximum_order_acres !== undefined &&
    billableAcres > sourceDetails.maximum_order_acres;

  const canPurchase =
    !exceedsContractLimit && !isDisabledSource && !isProcessing && canOrder && !sceneCoverageFailed;

  const sourceName = sourceDetails.name;
  const sourceOperator = sourceDetails.operator;
  const sourceResolution = sourceDetails.resolution;

  const submitButtonText = React.useMemo(() => {
    let text = isOrderingSubset
      ? 'Order Subset'
      : isPartiallyOrdered
        ? 'Order Remaining Area'
        : 'Order';

    const currentOrderCents = calculateCost(billableAcres, pricePerAcre);
    text = `${text} for ${formatCost(currentOrderCents)}`;

    return text;
  }, [billableAcres, isOrderingSubset, isPartiallyOrdered, pricePerAcre]);

  const submitButton = (
    <B.AnchorButton
      intent={B.Intent.PRIMARY}
      large
      icon={isPartialScene ? 'warning-sign' : null}
      text={submitButtonText}
      disabled={!canPurchase}
      onClick={() => onSubmit(billableAcres)}
    />
  );

  let acresMessage = '';
  if (subsetFeature && subsetFeatureAcres === orderGeometryAcres) {
    // partial order that is fully contained within property
    acresMessage = `Your partial area selection, outlined in yellow, includes ${formatAcres(
      orderGeometryAcres
    )} acres within your property at ${formatCost(pricePerAcre)} per acre.`;
  } else if (subsetFeature) {
    // partial order whose shape extends outside of property lines
    acresMessage = `Your partial area selection, outlined in yellow, includes ${formatAcres(
      orderGeometryAcres
    )} new acres within your property at ${formatCost(pricePerAcre)} per acre.`;
  } else if (isPartiallyOrdered) {
    // ordering the rest of a scene that has been partially ordered
    acresMessage = `Your selection includes the remaining ${formatAcres(
      orderGeometryAcres
    )} acres available in this scene at ${formatCost(pricePerAcre)} per acre.`;
  } else {
    // standard order case
    acresMessage = `Your selection includes ${formatAcres(
      orderGeometryAcres
    )} acres at ${formatCost(pricePerAcre)} per acre.`;
  }

  if (orderGeometryAcres === billableAcres) {
    acresMessage += ' You will only be billed for the area in your property boundary.';
  }

  return (
    <B.Overlay isOpen={isOpen} hasBackdrop={true} onClose={onClose}>
      <MapOverlayDialog
        title="Order Confirmation"
        onClose={onClose}
        className={classnames(cs.dialog, {[cs.narrowDialog]: !showImageryPreview})}
        contentClassName={cs.dialogContent}
        additionalButtons={
          <>
            {!isPackage && (
              <B.Tooltip
                content={isFeatureHidden ? 'Hide boundary in preview' : 'Show boundary in preview'}
                position={B.Position.TOP}
              >
                <B.AnchorButton
                  icon={isFeatureHidden ? 'eye-open' : 'eye-off'}
                  minimal={true}
                  onClick={() => {
                    setIsFeatureHidden(!isFeatureHidden);
                  }}
                />
              </B.Tooltip>
            )}
            <B.Tooltip content="Copy link" position={B.Position.TOP}>
              <CopyToClipboard text={modalUrl}>
                <B.AnchorButton icon="clipboard" minimal={true} />
              </CopyToClipboard>
            </B.Tooltip>
          </>
        }
      >
        <div className={cs.content}>
          {imageryContractStatusMaybe.status === 'unknown' && (
            <B.Spinner className={cs.loadingSpinner} />
          )}
          {imageryContractStatusMaybe.status === 'error' && (
            <B.NonIdealState
              iconMuted={false}
              className={cs.errorMessage}
              icon="warning-sign"
              description="We weren’t able to load purchase information for this project right now."
              action={
                <B.Button intent="primary" onClick={() => refreshContractAfterMs()}>
                  Try Again
                </B.Button>
              }
            ></B.NonIdealState>
          )}

          {imageryContractStatusMaybe.status === 'some' &&
            imageryContract &&
            (billableAcres > 0 ? (
              <>
                <div className={cs.purchaseInfoContainer}>
                  <div className={cs.purchaseInfoDescription}>
                    <div>
                      {(canPurchase || isDisabledSource) && (
                        <>
                          {isPackage ? (
                            getPackageOrderText(layerKey)
                          ) : (
                            <>
                              <p>
                                This image was taken on{' '}
                                <strong>
                                  {formatCaptureDate(
                                    orderableScene.get('sensingTime'),
                                    `${orderableScene.get('sourceId')}_high-res-truecolor`,
                                    {showCaptureTime: true, descriptive: true}
                                  )}
                                </strong>
                                .
                                {sceneCloudCoverPercentage != undefined && (
                                  <span>
                                    {' '}
                                    It is part of a larger scene with{' '}
                                    <strong>{Math.round(sceneCloudCoverPercentage)}%</strong> cloud
                                    cover.
                                  </span>
                                )}
                              </p>
                              <p>
                                Please check the preview image for <strong>clouds</strong>,{' '}
                                <strong>coloration</strong>, <strong>contrast</strong>, and{' '}
                                <strong>coverage area</strong> to ensure that it meets your needs.
                              </p>
                              <p>
                                See our{' '}
                                <a
                                  href="https://support.upstream.tech/article/136-choosing-imagery"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  guidance for choosing imagery here
                                </a>
                                , or track imagery spending from the{' '}
                                <Link
                                  to={`/${getOrgPrefix(organization)}/settings/billing`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  billing page here
                                </Link>
                                .
                              </p>
                            </>
                          )}

                          {sourceId.startsWith('AIRBUS') && sourceId.endsWith('ARCHIVE') && (
                            <>
                              <hr className={cs.separator} />
                              <B.Callout icon={null} intent={B.Intent.DANGER}>
                                <p>
                                  <strong>Warning:</strong> Imagery from the Extended Archive may
                                  take up to 72 hours to process, and quality can vary. Please
                                  exercise caution and carefully review the preview for any
                                  potential issues, including cloud cover, discoloration, and
                                  geographical inaccuracies.
                                </p>
                              </B.Callout>
                            </>
                          )}
                          <hr className={cs.separator} />
                        </>
                      )}
                      <ContractDetailsDescription
                        isDisabledSource={isDisabledSource}
                        isProcessing={isProcessing}
                        sceneCoverageFailed={sceneCoverageFailed}
                        canPurchase={canPurchase}
                        orderableScene={orderableScene}
                        orderGeometryAcres={orderGeometryAcres}
                        billableAcres={billableAcres}
                        imageryContract={imageryContract}
                        acresMessage={acresMessage}
                        orderExceedsMaximumOrderAcres={orderExceedsMaximumOrderAcres}
                        role={role}
                      />
                    </div>
                  </div>

                  <div className={cs.purchaseInfoActions}>
                    {userUtils.mayOrderImagery(role) ? (
                      <>
                        {isPartialScene ? (
                          <B.Tooltip
                            content={
                              <>
                                <strong>Note:</strong> This data does not fully cover the property
                              </>
                            }
                          >
                            {submitButton}
                          </B.Tooltip>
                        ) : subsetFeature ? (
                          <B.Tooltip
                            content={
                              <>
                                <strong>Note:</strong> This is a partial area order
                              </>
                            }
                          >
                            {submitButton}
                          </B.Tooltip>
                        ) : (
                          submitButton
                        )}
                        <B.Button large text="Cancel" minimal onClick={onClose} />
                      </>
                    ) : (
                      <CopyToClipboard text={modalUrl}>
                        <B.Button intent={B.Intent.PRIMARY} large text="Copy Link" type="submit" />
                      </CopyToClipboard>
                    )}
                  </div>
                </div>

                {showImageryPreview && (
                  <div className={cs.previewArea}>
                    <div className={cs.previewImageContainer}>
                      <ImageryPreviewImage
                        feature={feature}
                        maxWidth={420}
                        maxHeight={420}
                        url={isPackage ? undefined : url}
                        bounds={orderableScene.get('thumbnailBbox').toJS()}
                        sourceId={sourceId}
                        sceneGeometry={orderableScene.get('sceneGeometry').toJS()}
                        blockedSources={blockedSources}
                        isFeatureHidden={isFeatureHidden}
                        subsetFeature={subsetFeature}
                        orderedGeometry={orderableScene.get('orderedGeometry')?.toJS()}
                      />
                    </div>
                    <div className={cs.previewDescription}>
                      <div>
                        {sourceOperator} {sourceName}
                        {sourceResolution && (
                          <>
                            {' '}
                            (<span className={cs.sourceResolution}>{sourceResolution}m</span>)
                          </>
                        )}
                      </div>
                      <div className={cs.previewDisclaimer}>
                        This is a low-resolution preview. It will be high-resolution when processed.
                        <br />
                        {isPartialScene ? (
                          <>
                            <strong style={{color: '#cc0000'}}>Red</strong> areas show where the
                            image does not cover the property.
                          </>
                        ) : (
                          'Please check for clouds and that the image covers the full property boundary.'
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <B.NonIdealState
                iconMuted={false}
                className={cs.errorMessage}
                icon="warning-sign"
                description={
                  <>
                    <p>
                      We weren’t able to find orderable data. It may be that you have already
                      ordered all the data that covers your property.
                    </p>
                    <p>
                      If parts of your property are available but missing from data you’ve already
                      ordered, contact <strong>lens@upstream.tech</strong> for support.
                    </p>
                  </>
                }
              />
            ))}
        </div>
      </MapOverlayDialog>
    </B.Overlay>
  );
};

const ContractDetailsDescription: React.FC<{
  isDisabledSource: boolean;
  isProcessing: boolean;
  sceneCoverageFailed: boolean;
  canPurchase: boolean;
  orderExceedsMaximumOrderAcres: boolean;
  orderableScene: I.ImmutableOf<ApiOrderableScene>;
  orderGeometryAcres: number;
  billableAcres: number;
  imageryContract: ApiImageryContract;
  acresMessage: string;
  role: ApiOrganizationUser['role'];
}> = ({
  isDisabledSource,
  isProcessing,
  sceneCoverageFailed,
  canPurchase,
  orderExceedsMaximumOrderAcres,
  orderableScene,
  orderGeometryAcres,
  billableAcres,
  imageryContract,
  acresMessage,
  role,
}) => {
  const {sourceDetails} = featureUtils.imageAndSourceDetailsFromScene(orderableScene);
  const sourceId = orderableScene.get('sourceId');
  const {prices} = useImageryPrices();
  const scenePricePerAcre: number | null = prices[sourceId];

  if (isDisabledSource) {
    return (
      <B.Callout icon={null} intent={B.Intent.DANGER}>
        <p>
          We’re sorry, we’re temporarily unable to process{' '}
          <strong>
            {sourceDetails.operator} {sourceDetails.name}
          </strong>{' '}
          data.
        </p>

        <p>
          Please check back soon or contact us at <strong>lens@upstream.tech</strong> with any
          questions.
        </p>
      </B.Callout>
    );
  }
  if (isProcessing) {
    return <p>We’re processing your order and will email you when it's ready.</p>;
  }
  if (sceneCoverageFailed) {
    return (
      <B.NonIdealState
        iconMuted={false}
        className={cs.sceneCoverageFailed}
        icon="warning-sign"
        description={
          <>
            <p>There is a problem with the geometry that prevents this scene from being ordered.</p>
            <p>
              Our engineers have been notified and will investigate. If this problem persists,
              please contact <strong>lens@upstream.tech</strong> for support.
            </p>
          </>
        }
      />
    );
  }
  if (!userUtils.mayOrderImagery(role)) {
    return (
      <B.Callout icon={null} intent={B.Intent.WARNING}>
        <p>Only organization admins may order data.</p>
        <p>Click the Copy Link button to get a sharable URL for this scene.</p>
      </B.Callout>
    );
  }
  if (!scenePricePerAcre) {
    Sentry.captureException(
      `OrderImageryModal rendered scene without a price. SourceId: ${sourceId}`,
      {
        extra: {
          orderableSceneSource: sourceId,
          orderableSceneSensingTime: orderableScene.get('sensingTime'),
        },
      }
    );

    return (
      <B.Callout icon={null} intent={B.Intent.DANGER}>
        <p>
          We’re sorry, we’re temporarily unable to process{' '}
          <strong>
            {sourceDetails.operator} {sourceDetails.name}
          </strong>{' '}
          data.
        </p>

        <p>
          Please check back soon or contact us at <strong>lens@upstream.tech</strong> with any
          questions.
        </p>
      </B.Callout>
    );
  }
  return (
    <B.Callout icon={null} intent={canPurchase ? B.Intent.WARNING : B.Intent.DANGER}>
      <>
        <p>{acresMessage}</p>

        {orderGeometryAcres !== billableAcres && (
          <p>
            <strong>
              The minimum order amount for {sourceDetails.operator} {sourceDetails.name} is{' '}
              {formatCost(billableAcres * scenePricePerAcre)}.
            </strong>
          </p>
        )}
        {orderExceedsMaximumOrderAcres && (
          <p>
            <strong>
              This purchase exceeds the limit for automatic processing for {sourceDetails.operator}{' '}
              {sourceDetails.name}. Once you confirm below, our team will place the order on your
              behalf within 2 business days.
            </strong>
          </p>
        )}
        <DollarUnitContractDetails
          usage={imageryContract.usage as ApiDollarUnitUsage}
          acreage={billableAcres}
          orderableScene={orderableScene}
          pricePerAcre={scenePricePerAcre}
        />
      </>
    </B.Callout>
  );
};

const getPackageOrderText = (layerKey: string) => {
  const PACKAGE_ORDER_TEXT: {[layerKey: string]: React.ReactNode} = {
    [PLANET_FOREST_DILIGENCE_PACKAGE]: (
      <>
        <p>
          The Planet Forest Carbon Diligence Package includes the following three layers. If this is
          the first time you're purchasing data for this property, the full archive of annual data
          back to 2013 will be included at no extra cost.
        </p>
        <ol>
          {LAYER_PACKAGES[PLANET_FOREST_DILIGENCE_PACKAGE].filter(
            (layerKey) => !isLayerKeyRaw(layerKey)
          ).map((layerKey) => (
            <li key={layerKey}>{getLayer(layerKey).display}</li>
          ))}
        </ol>
        <p>
          See our{' '}
          <a
            href="https://support.upstream.tech/article/157-planet-forest-carbon-diligence"
            target="_blank"
            rel="noopener noreferrer"
          >
            support doc here
          </a>{' '}
          for more details.
        </p>
      </>
    ),
    [PLANET_FOREST_MONITORING_PACKAGE]: (
      <>
        <p>
          The Planet Forest Carbon Monitoring Package includes the following three layers at 3m
          resolution. If this is the first time you're purchasing data for this property, the
          archive of quarterly data back to 2021 will be included at no extra cost.
        </p>
        <ol>
          {LAYER_PACKAGES[PLANET_FOREST_MONITORING_PACKAGE].filter(
            (layerKey) => !isLayerKeyRaw(layerKey)
          ).map((layerKey) => (
            <li key={layerKey}>{getLayer(layerKey).display}</li>
          ))}
        </ol>
        <p>
          See our{' '}
          <a
            href="https://support.upstream.tech/article/157-planet-forest-carbon-diligence#3meter"
            target="_blank"
            rel="noopener noreferrer"
          >
            support doc here
          </a>{' '}
          for more details.
        </p>
      </>
    ),
    [CHLORIS_30_PACKAGE]: (
      <>
        <p>
          The Chloris 30m package includes data on the following layers. If this is your first
          purchase for this property, the full archive of annual data back to 2000 will be included
          at no extra cost.
        </p>
        <ol>
          {LAYER_PACKAGES[CHLORIS_30_PACKAGE].filter((layerKey) => !isLayerKeyRaw(layerKey)).map(
            (layerKey) => (
              <li key={layerKey}>{getLayer(layerKey).display}</li>
            )
          )}
        </ol>
        <p>
          Chloris data is not pre-processed and may take up to 5 business days to process and
          deliver into your account. By placing this order, you agree to comply with the{' '}
          <a
            href="https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chloris End User License Agreement linked here.
          </a>
        </p>
        <p>
          See our{' '}
          <a
            href="https://support.upstream.tech/article/170-chloris-forest-carbon"
            target="_blank"
            rel="noopener noreferrer"
          >
            support doc
          </a>{' '}
          for more details.
        </p>
      </>
    ),
    [CHLORIS_10_PACKAGE]: (
      <>
        <p>
          The Chloris 10m package includes data on the following layers. If this is your first
          purchase for this property, the full archive of annual data back to 2017 will be included
          at no extra cost.
        </p>
        <ol>
          {LAYER_PACKAGES[CHLORIS_10_PACKAGE].filter((layerKey) => !isLayerKeyRaw(layerKey)).map(
            (layerKey) => (
              <li key={layerKey}>{getLayer(layerKey).display}</li>
            )
          )}
        </ol>
        <p>
          Chloris data is not pre-processed and may take up to 5 business days to process and
          deliver into your account. By placing this order, you agree to comply with the{' '}
          <a
            href="https://cdn.prod.website-files.com/6197d5aab3f545ea3c1c5438/671b9d5827701b056d11fa69_Chloris%20Geospatial%20AGB%20EULA.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chloris End User License Agreement linked here.
          </a>
        </p>
        <p>
          See our{' '}
          <a
            href="https://support.upstream.tech/article/170-chloris-forest-carbon"
            target="_blank"
            rel="noopener noreferrer"
          >
            support doc
          </a>{' '}
          for more details.
        </p>
      </>
    ),
  };
  return PACKAGE_ORDER_TEXT[layerKey];
};

function getOrderGeometryAcres(
  feature: I.ImmutableOf<ApiFeature>,
  orderableScene: I.ImmutableOf<ApiOrderableScene>,
  subsetFeature: geojson.Feature<geojson.Polygon> | null
) {
  // Calculate billableAcres if we are ordering partial imagery or if
  // partial imagery has already been ordered, otherwise just use the information
  // on the feature
  if (!orderableScene.get('orderedGeometry') && !subsetFeature) {
    const sceneCoverage = featureUtils.getSceneCoverage(orderableScene, feature);
    const featureGeometry = feature.get('geometry').toJS();

    // Calculate area using UTM projection to match backend
    const areaInAcres = calculateBillableUTMAreaInAcres(featureGeometry) * sceneCoverage;

    // Apply the same rounding logic as backend: round down and ensure minimum of 1 acre
    return Math.max(1, Math.floor(areaInAcres));
  }

  // Rounds to decimal to 6 places
  const roundDecimalPlace = (position: geojson.Position) =>
    position.map((num) => Math.floor(num * 1000000) / 1000000);

  // Start with geometry of the feature
  let billableGeometry: geojson.Polygon | geojson.MultiPolygon | undefined = feature
    .get('geometry')
    .toJS();

  // Round all coordinates to 6 decimal places to avoid floating point math errors in turf
  // calculations
  billableGeometry = geoJsonUtils.applyToCoordinates(billableGeometry, roundDecimalPlace);

  try {
    // If a subset is being ordered take the intersection of the subset geometry and the feature
    if (subsetFeature && feature) {
      const subsetFeatureGeometry = subsetFeature.geometry;
      billableGeometry =
        billableGeometry &&
        geoJsonUtils.intersectGeometries(subsetFeatureGeometry, billableGeometry)?.geometry;
    }

    // If some geometry has already been ordered for a given scene, remove that area from
    // the billableGeometry
    if (orderableScene.get('orderedGeometry')) {
      let alreadyOrderedGeometry = orderableScene.get('orderedGeometry')?.toJS() as
        | geojson.Polygon
        | geojson.MultiPolygon
        | undefined;

      // Round all coordinates to 6 decimal places to avoid floating point math errors in turf
      // calculations
      alreadyOrderedGeometry = geoJsonUtils.applyToCoordinates(
        alreadyOrderedGeometry,
        roundDecimalPlace
      );

      billableGeometry =
        billableGeometry &&
        alreadyOrderedGeometry &&
        turf.difference(
          turf.featureCollection([
            turf.feature(billableGeometry),
            turf.feature(alreadyOrderedGeometry),
          ])
        )?.geometry;
    }

    // Lastly take the intersection of the previous steps with the geometry of the scene
    const sceneGeometry = orderableScene.get('sceneGeometry').toJS() as
      | geojson.Polygon
      | geojson.MultiPolygon;

    // Calculate area using our UTM-based approach to match backend
    let billableAcres = 0;
    if (billableGeometry) {
      // Get the intersection between billable geometry and scene geometry
      const intersectionFeature = geoJsonUtils.intersectGeometries(billableGeometry, sceneGeometry);

      if (intersectionFeature) {
        // Use a UTM projection-based area calculation to match backend
        billableAcres = calculateBillableUTMAreaInAcres(intersectionFeature.geometry);
      }
    }

    return billableAcres;
  } catch (e) {
    // Calculating the difference/intersection for features that have very small differences may fail.
    // This should be handled by the API and treated as a fully ordered feature, but if not we want
    // to catch it.
    Sentry.captureException(e, {
      extra: {
        featureId: feature.get('id'),
        orderableSceneSource: orderableScene.get('sourceId'),
        orderableSceneSensingTime: orderableScene.get('sensingTime'),
        subsetGeometry: JSON.stringify(subsetFeature?.geometry ?? null),
      },
    });
    console.error(e);
    return -1;
  }
}

export function getBillableAcres(
  feature: I.ImmutableOf<ApiFeature>,
  orderableScene: I.ImmutableOf<ApiOrderableScene>,
  subsetFeature: geojson.Feature<geojson.Polygon> | null
) {
  const orderGeometryAcres = getOrderGeometryAcres(feature, orderableScene, subsetFeature);
  const minimumAcres =
    C.SOURCE_DETAILS_BY_ID[orderableScene.get('sourceId')]?.minimum_order_acres || 0;
  const billableAcres = Math.max(orderGeometryAcres, minimumAcres);
  return {orderGeometryAcres, billableAcres};
}

function formatAcres(acreage: number): React.ReactNode {
  return conversionUtils.numberWithCommas(acreage.toString());
}

function canPurchaseOnContract(
  imageryContract: ApiImageryContract,
  acreage: number,
  pricePerAcre: number
) {
  const usage = imageryContract.usage as ApiDollarUnitUsage;

  if (acreage <= 0) {
    return false;
  }

  if (usage.nte_cents === null) {
    return true;
  } else {
    const usedCents = usage.purchased_cents;
    const currentOrderCents = calculateCost(acreage, pricePerAcre);

    return usedCents + currentOrderCents <= usage.nte_cents;
  }
}

export default OrderImageryModal;

/**
 * Helper function callers can use to actually order imagery. Returns the
 * updated feature if the purchase API request was successful, otherwise null.
 */
export async function orderImagery(
  projectId: string,
  featureCollectionId: number,
  featureId: number,
  orderableScene: I.ImmutableOf<ApiOrderableScene>,
  billableAcres: number,
  centsPerAcre: number,
  refreshCentsPerAcre: () => void,
  pushNotification: (p: ToastNotification) => void,
  isOrderingSubset: boolean,
  subsetFeatureGeometry?: geojson.Polygon | undefined
) {
  try {
    // TODO(fiona): invalidate imagery purchases and the imagery contract,
    // since ordering imagery changes them.

    // Only include geometry in payload if subset geometry is passed in
    const data = {
      sourceId: orderableScene.get('sourceId'),
      sensingTime: orderableScene.get('sensingTime'),
      billableAcres,
      centsPerAcre,
      ...(!!subsetFeatureGeometry && isOrderingSubset && {geometry: subsetFeatureGeometry}),
    };

    const updatedFeature = (
      await api.featureCollections
        .features(featureCollectionId)
        .orderImagery(projectId, featureId, data)
    ).get('data');

    recordEvent('Ordered an image', {
      projectId,
      featureCollectionId,
      featureId,
      sourceId: orderableScene.get('sourceId'),
      sensingTime: orderableScene.get('sensingTime'),
    });

    pushNotification({
      message:
        'The requested data has been ordered. You will receive an email when processing is complete.',
      autoHideDuration: 5000,
    });

    return updatedFeature;
  } catch (e) {
    const {error} = e as ApiResponseError;

    Sentry.captureException(error, {
      extra: {
        featureId: featureId,
        orderableSceneSource: orderableScene.get('sourceId'),
        orderableSceneSensingTime: orderableScene.get('sensingTime'),
        subsetGeometry: JSON.stringify(subsetFeatureGeometry ?? null),
      },
    });

    if (error.message.includes('Invalid price')) {
      refreshCentsPerAcre();
      pushNotification({
        message:
          'Error ordering the requested data. The price of this image has changed. Please try again',
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } else if (error.message.includes('Requested billable acres mismatch')) {
      // Often this happens because we updated the minimum_order_acres for a source but the user hasn't refreshed Lens since that change so
      // the billable_acres we send in the api request uses the old value for minimum_order_acres
      pushNotification({
        message:
          'Error ordering the requested data. Please refresh Lens and try again. Our team has been notified and we are on it! ',
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } else {
      pushNotification({
        message: 'Error ordering the requested data. Our team has been notified and we are on it!',
        options: {
          intent: B.Intent.DANGER,
        },
      });
    }

    return null;
  }
}
