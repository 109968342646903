import * as I from 'immutable';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {DEFAULT_FORM_STATE, FormState} from 'app/pages/SignUp';
import {
  LENS_ACCESS_SUBSCRIPTIONS_TYPE,
  LENS_ACCESS_SUBSCRIPTION_SIGNUP_OPTIONS,
} from 'app/utils/constants';

import * as historyUtils from './historyUtils';

export const UUID_PREFIX_REGEXP = /^[0-9a-f]{8}$/i;
export const UUID_REGEXP =
  /^([0-9a-f]{8})-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const SCENE_URL_PARAM = 'scene-';
/**
 * Regular expression to match the layerKey and cursor part of the scene URL.
 * These are going to have the format of either:
 *
 * * scene-2021-08-12T18:33:32+00:00
 * * scene-DIGITAL-GLOBE-50;2021-08-12T18:33:32+00:00
 *
 * (Not including the layerKey is legacy but needs to still be supported because
 * emails are in the wild.)
 */
export const SCENE_URL_PARAM_REGEX = new RegExp(`(${SCENE_URL_PARAM})([\\w\\-]+;)?([\\dTZ\\-:+]+)`);

export const NOTE_URL_PARAM = 'note-';
export const NOTE_URL_PARAM_REGEX = new RegExp(`(${NOTE_URL_PARAM})(\\d*)`);

/**
 * Simple utility to make an app url
 */
export const makeAppUrl = (path: string | null) => {
  const currentOrigin = window.location.origin;
  if (!path) return historyUtils.ROOT_URL;
  // If we don't have a standard local or prod url, use whatever host we do have, which is most likely staging.
  if (!historyUtils.ROOT_URL.includes(currentOrigin)) {
    return `${origin}${path}`;
  }
  return `${historyUtils.ROOT_URL}${path}`;
};

/*
 * Returns whether the current window url is a public lens url
 */
export const isPublicLensUrl = (): boolean => window.location.pathname.startsWith('/p/');

export function featureMatchesParam(feature: I.ImmutableOf<ApiFeature>, param: string) {
  const id = feature.get('id');
  const lensId = feature.getIn(['properties', 'lensId']);

  if (param.match(/^\d+$/) && id === parseInt(param)) {
    return true;
  } else if (param.match(UUID_PREFIX_REGEXP) && lensId?.startsWith(param)) {
    return true;
  } else if (param.match(UUID_REGEXP) && lensId === param) {
    return true;
  } else {
    return false;
  }
}

export function makeUuidPrefix(uuid: string) {
  const match = uuid.match(UUID_REGEXP);

  if (match) {
    return match[1];
  } else {
    return uuid;
  }
}

export function makeProjectDashboardUrl(
  organization: I.ImmutableOf<ApiOrganization> | string,
  project: I.ImmutableOf<ApiProject> | string,
  page?: 'imagery' | 'map',
  featureLensIds?: (string | number | I.ImmutableOf<ApiFeature>)[],
  fragment?: string,
  options: {includeRoot: boolean} = {includeRoot: false}
) {
  const parts = [
    options.includeRoot ? historyUtils.ROOT_URL : '',
    makeUuidPrefix(typeof organization === 'string' ? organization : organization.get('id')),
    'projects',
    makeUuidPrefix(typeof project === 'string' ? project : project.get('id')),
  ];

  if (page) {
    parts.push(page);

    if (featureLensIds) {
      parts.push(
        featureLensIds
          .map((id) =>
            typeof id === 'object'
              ? makeUuidPrefix(id.getIn(['properties', 'lensId']) ?? id.get('id'))
              : makeUuidPrefix(id.toString())
          )
          .join(',')
      );
    }
  }

  return parts.join('/') + (fragment ? `#${fragment}` : '');
}

export function makeSceneUrlFragment(date: string, highResSourceId?: string) {
  return `${SCENE_URL_PARAM}${
    highResSourceId ? `${highResSourceId}_high-res-truecolor;` : ''
  }${date}`;
}

export function makeNoteUrlFragment(noteId) {
  return `${NOTE_URL_PARAM}${noteId}`;
}

export function makeHighResSceneUrl({
  organizationId,
  projectId,
  featureLensId,
  sourceId,
  date,
  includeRoot = true,
}: {
  organizationId: string;
  projectId: string;
  featureLensId: string;
  sourceId: string;
  date: string;
  includeRoot?: boolean;
}): string {
  return makeProjectDashboardUrl(
    organizationId,
    projectId,
    'map',
    [featureLensId],
    makeSceneUrlFragment(date, sourceId),
    {includeRoot: includeRoot}
  );
}

export const CurrentOrgIdPrefix = React.createContext<string | null>(null);
/* Hook to let components retrieve the current org id prefix. Must be rendered under CurrentOrgIdPrefix.Provider. */
export const useCurrentOrgIdPrefix = (): string => {
  const currentOrgIdPrefix = React.useContext(CurrentOrgIdPrefix);

  if (!currentOrgIdPrefix) {
    throw new Error(
      'This component must be rendered underneath a routeUtils.CurrentOrgIdPrefix.Provider'
    );
  }

  return currentOrgIdPrefix;
};

interface SignUpQueryParameters {
  subscriptionType?: LENS_ACCESS_SUBSCRIPTIONS_TYPE;
  accountEmail?: string;
  organizationName?: string;
  taxExempt?: string;
  subscriptionAddOns?: string | string[]; // If there is one add on this will be a string. If there are multiple this will be a list
  freeTrial?: string;
}
/**
 * Takes in signup query parameters and converts them into a FormState object
 */
export function parseSignUpQueryParams(params: SignUpQueryParameters): FormState {
  let subscriptionAddOns: string[];
  if (!params.subscriptionAddOns) {
    subscriptionAddOns = DEFAULT_FORM_STATE.subscriptionAddOns;
  } else if (params.subscriptionAddOns instanceof Array) {
    subscriptionAddOns = params.subscriptionAddOns;
  } else {
    subscriptionAddOns = [params.subscriptionAddOns];
  }

  //check if the user's URL subscriptionType is available in LENS_ACCESS_SUBSCRIPTION_SIGNUP_OPTIONS;
  //if it's not, check if their URL subscriptionType begins with a real subscriptionType and give them that
  //(if not, it will return undefined, and give them a default from the DEFAULT_FORM_STATE)
  const cleanedSubscriptionType: string | undefined =
    params.subscriptionType && !(params.subscriptionType in LENS_ACCESS_SUBSCRIPTION_SIGNUP_OPTIONS)
      ? Object.keys(LENS_ACCESS_SUBSCRIPTION_SIGNUP_OPTIONS).find((option) =>
          params.subscriptionType!.startsWith(option)
        )
      : params.subscriptionType;

  const defaultFormState: FormState = {
    ...DEFAULT_FORM_STATE,
    accessSubscriptionType:
      (cleanedSubscriptionType as LENS_ACCESS_SUBSCRIPTIONS_TYPE) ||
      params.subscriptionType ||
      DEFAULT_FORM_STATE.accessSubscriptionType, //give a default of plus_annual if subscription type couldn't be found
    accountEmail: params.accountEmail || DEFAULT_FORM_STATE.accountEmail,
    organizationName: params.organizationName || DEFAULT_FORM_STATE.organizationName,
    taxExempt: params.taxExempt?.toLowerCase() === 'true' || DEFAULT_FORM_STATE.taxExempt,
    freeTrial: params.freeTrial || DEFAULT_FORM_STATE.freeTrial,
    subscriptionAddOns,
  };
  return defaultFormState;
}

// Make it's own function so we can mock it out and spy on it in tests
export function setWindowLocationHref(href: string) {
  window.location.href = href;
}
