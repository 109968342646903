import React from 'react';

import Loading from 'app/components/Loading';
import {api} from 'app/modules/Remote';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import * as hookUtils from 'app/utils/hookUtils';
import * as userUtils from 'app/utils/userUtils';

export interface OrgUsersActions {
  setUserName: (userId: string, name: ApiOrganizationUser['name']) => Promise<void>;
  setUserRole: (userId: string, role: ApiOrganizationUser['role']) => Promise<void>;
  setUserEmail: (email: ApiOrganizationUser['email']) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
}

interface OrgUsersContextValue {
  organizationUsers: ApiOrganizationUser[] | null;
  meta: hookUtils.UseApiGetMeta<ApiOrganizationUser[] | null>;
  actions: OrgUsersActions;
}

const OrgUsersContext = React.createContext<OrgUsersContextValue | undefined>(undefined);

const OrgUsersProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    /**
     * It’s ok to pass a null organizationId for cases when the user is not logged
     * in.
     */
    organizationId: string | null;
  }>
> = ({organizationId, children}) => {
  const [{value: organizationUsers = null}, meta] = hookUtils.useApiGet(
    async (orgId: string | null) => {
      if (orgId === null) {
        return null;
      }

      // Filter out team+@upstream.tech addresses from organization users
      return (await api.organizations.users(orgId))
        .get('data')
        .filterNot((u) => userUtils.isUpstreamTeamEmail(u!.get('email')))
        .toList()
        .toJS();
    },
    [organizationId]
  );

  const actions = React.useMemo<OrgUsersActions>(
    () => ({
      setUserName: async (userId: string, name: ApiOrganizationUser['name']) => {
        let previousName: ApiOrganizationUser['name'] | null = null;

        try {
          // Optimistically update to display the new name immediately.
          meta.updateLocal(
            (users: ApiOrganizationUser[]) =>
              users &&
              users.map((u) => {
                if (u.id === userId) {
                  previousName = u.name;
                  return {...u, name};
                } else {
                  return u;
                }
              })
          );

          await api.organizations.updateUser(userId, {name});
        } catch (e) {
          // Rollback the optimistic update.
          meta.updateLocal(
            (users: ApiOrganizationUser[]) =>
              users &&
              users.map((u) => (previousName && u.id === userId ? {...u, name: previousName} : u))
          );

          throw e;
        }
      },
      setUserRole: async (userId: string, role: ApiOrganizationUser['role']) => {
        let previousRole: ApiOrganizationUser['role'] | null = null;

        try {
          // optimistic update, keeps the <select> box from showing the old role
          // after picking the new one.
          meta.updateLocal(
            (users: ApiOrganizationUser[]) =>
              users &&
              users.map((u) => {
                if (u.id === userId) {
                  previousRole = u.role;
                  return {...u, role};
                } else {
                  return u;
                }
              })
          );

          await api.organizations.setUserRole(userId, role);
        } catch (e) {
          meta.updateLocal(
            (users: ApiOrganizationUser[]) =>
              users && users.map((u) => (previousRole && u.id === userId ? {...u, role} : u))
          );

          throw e;
        }
      },
      setUserEmail: async (email: ApiOrganizationUser['email']) => {
        await api.organizations.updateEmail(email);
      },
      deleteUser: async (userId: string) => {
        try {
          // We optimistically mark the user as "deletedLocally" so that we can
          // show them as "removed" in the UI rather than having the row
          // disappear immediately.
          meta.updateLocal(
            (users: ApiOrganizationUser[]) =>
              users && users.map((u) => (u.id === userId ? {...u, deletedLocally: true} : u))
          );

          await api.organizations.deleteUser(userId);
        } catch (e) {
          meta.updateLocal(
            (users: ApiOrganizationUser[]) =>
              users &&
              users.map((u) => {
                const userWithoutDeletedLocally = {...u};
                delete userWithoutDeletedLocally.deletedLocally;
                return u.id === userId ? userWithoutDeletedLocally : u;
              })
          );

          throw e;
        }
      },
    }),
    [meta]
  );

  return (
    <OrgUsersContext.Provider value={{organizationUsers, meta, actions}}>
      {children}
    </OrgUsersContext.Provider>
  );
};

export const WithOrgUsers: React.FunctionComponent<{
  refreshOnMount?: boolean | 'clear';
  children: (
    organizationUsers: ApiOrganizationUser[] | null,
    meta: hookUtils.UseApiGetMeta<ApiOrganizationUser[] | null>,
    actions: OrgUsersActions
  ) => React.ReactElement | null;
}> = ({refreshOnMount = false, children}) => {
  const orgUsersContextValue = React.useContext(OrgUsersContext);

  if (!orgUsersContextValue) {
    throw new Error('WithOrgUsers must be called beneath OrgUsersProvider');
  }

  React.useEffect(() => {
    if (refreshOnMount) {
      orgUsersContextValue.meta.refresh(refreshOnMount === 'clear');
    }

    // We only care to check refreshOnMount at actual mount time, not if its
    // prop value changes later.
    //
  }, []);

  return children(
    orgUsersContextValue.organizationUsers,
    orgUsersContextValue.meta,
    orgUsersContextValue.actions
  );
};

export const WithLoadedOrgUsers: React.FunctionComponent<{
  refreshOnMount?: boolean | 'clear';
  children: (
    organizationUsers: ApiOrganizationUser[],
    meta: hookUtils.UseApiGetMeta<ApiOrganizationUser[] | null>
  ) => React.ReactElement | null;
}> = ({refreshOnMount = false, children}) => {
  return (
    <WithOrgUsers refreshOnMount={refreshOnMount}>
      {(organizationUsers, meta) =>
        organizationUsers ? children(organizationUsers, meta) : <Loading />
      }
    </WithOrgUsers>
  );
};

export const FakeOrgUsersProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    users?: ApiOrganizationUser[] | null;
    action?: (name: string) => () => any;
  }>
> = ({children, users = [], action = () => () => {}}) => {
  const value = React.useMemo<OrgUsersContextValue>(
    () => ({
      organizationUsers: users,
      meta: {
        loading: false,
        refresh: action('refresh'),
        updateLocal: action('updateLocal'),
      },
      actions: {
        deleteUser: action('deleteUser'),
        setUserName: action('setUserName'),
        setUserRole: action('setUserRole'),
        setUserEmail: action('setUserEmail'),
      },
    }),
    [users, action]
  );

  return <OrgUsersContext.Provider value={value}>{children}</OrgUsersContext.Provider>;
};

export default OrgUsersProvider;
