import * as B from '@blueprintjs/core';
import classNames from 'classnames';
import classnames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import LookoutIcon from 'app/components/Alerts/LookoutIcon';
import {ApiNotification, ApiOrganization} from 'app/modules/Remote/Organization';
import NotificationsProvider, {useNotifications} from 'app/providers/NotificationsProvider';
import colors from 'app/utils/colorUtils';
import featureFlags from 'app/utils/featureFlags';
import {ImagerySourceDetails, getSourceDetails} from 'app/utils/featureUtils';
import {useCmdCtrlPress} from 'app/utils/hookUtils';
import {
  makeHighResSceneUrl,
  makeNoteUrlFragment,
  makeProjectDashboardUrl,
} from 'app/utils/routeUtils';

import cs from './styles.styl';

interface NotificationDisplayData {
  icon: JSX.Element;
  backgroundColor?: string;
  notificationTypeLabel: string;
  details: string | JSX.Element;
  url: string;
}

const Notifications: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    history: History;
  }>
> = ({organization, history}) => {
  const [page, setPage] = React.useState(1);
  return (
    <NotificationsProvider orgId={organization.get('id')} page={page}>
      <NotificationsView history={history} organization={organization} setPage={setPage} />
    </NotificationsProvider>
  );
};

export const NotificationsView: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
    organization: I.ImmutableOf<ApiOrganization>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
  }>
> = ({organization, history, setPage}) => {
  const {notifications, loadingNotifications, atEndOfList} = useNotifications();

  const isCmdCtrlKeyPressed = useCmdCtrlPress();

  const filterOptions = [
    {key: 'all', display: 'All'},
    {key: 'new-imagery-available', display: 'Imagery'},
    {key: 'note', display: 'Notes'},
  ];
  if (featureFlags.LOOKOUTS(organization)) {
    filterOptions.push({
      key: 'alert',
      display: 'Changes',
    });
  }

  const [notificationFilter, setNotificationFilter] = React.useState(filterOptions[0].key);

  const oneWeekAgo = moment().subtract(1, 'w').format('dddd');

  const filteredNotifications = notifications.filter(
    (n) =>
      (featureFlags.LOOKOUTS(organization) || n.notificationType !== 'alert') &&
      (notificationFilter === 'all' ||
        (notificationFilter === n.notificationType &&
          filterOptions.map((f) => f.key).includes(n.notificationType)))
  );

  const loading = loadingNotifications;

  const getNotificationDisplayDetails = (n: ApiNotification): NotificationDisplayData => {
    let sourceDetails: ImagerySourceDetails | undefined;
    switch (n.notificationType) {
      case 'new-imagery-available':
        sourceDetails = getSourceDetails(n.payload.source_id);
        return {
          icon: (
            <img
              src={n.payload.image_url}
              style={{maxHeight: '100%', maxWidth: '100%'}}
              loading="lazy"
            />
          ),
          backgroundColor: colors.transparent,
          notificationTypeLabel: 'Image available',
          details: (
            <>
              {sourceDetails && (
                <>
                  {sourceDetails.operator} {sourceDetails.name} ({sourceDetails.resolution}
                  m)
                </>
              )}{' '}
              on {moment(n.payload.sensing_time).format('MMM DD, YYYY')}
            </>
          ),
          url: makeHighResSceneUrl({
            organizationId: n.organizationId,
            projectId: n.projectId,
            featureLensId: n.feature?.properties.lensId ?? '',
            sourceId: n.payload.source_id,
            date: n.payload.sensing_time,
            includeRoot: false,
          }),
        };
      case 'note':
        return {
          icon: <B.Icon icon="edit" size={28} style={{maxHeight: '100%', maxWidth: '100%'}} />,
          notificationTypeLabel: `Note from ${n.payload.note.userName || n.payload.note.userId}`,
          details: `${n.payload.note.text}`,
          url: makeProjectDashboardUrl(
            organization,
            n.projectId,
            'map',
            [n.feature.id],
            makeNoteUrlFragment(n.payload.note.id)
          ),
        };
      case 'alert': {
        let icon: JSX.Element, notificationTypeLabel: string;
        let backgroundColor: string = colors.lighterGray;
        if (n.payload.note.userId == 'vegetation_drop@upstream.tech') {
          icon = (
            <img
              src={'https://storage.googleapis.com/upstream-icons/homepage/alert2.png'}
              style={{maxHeight: '100%', maxWidth: '100%'}}
            />
          );
          notificationTypeLabel = 'Vegetation Drop Alert';
        } else if (n.payload.note.userId == 'ownership_change@upstream.tech') {
          notificationTypeLabel = 'Parcel Ownership Alert';
          icon = (
            <img
              src={'https://storage.googleapis.com/upstream-icons/homepage/alert.png'}
              style={{maxHeight: '100%', maxWidth: '100%'}}
            />
          );
        } else {
          icon = featureFlags.LOOKOUTS(organization!) ? (
            <LookoutIcon
              size={28}
              style={{maxHeight: '100%', maxWidth: '100%'}}
              color={colors.primaryIntent}
            />
          ) : (
            <B.Icon
              icon="warning-sign"
              size={28}
              style={{maxHeight: '100%', maxWidth: '100%'}}
              color={colors.primaryIntent}
            />
          );
          notificationTypeLabel = n.payload.alertPolicy?.name ?? 'Custom Alert';
          backgroundColor = colors.pastelBlue;
        }

        return {
          icon,
          notificationTypeLabel,
          backgroundColor,
          details: `${n.payload.note.text}`,
          url: makeProjectDashboardUrl(
            organization,
            n.projectId,
            'map',
            [n.feature.id],
            makeNoteUrlFragment(n.payload.note.id)
          ),
        };
      }
      default:
        return {
          icon: <B.Icon icon={'notifications'} />,
          notificationTypeLabel: '',
          details: '',
          url: '',
        };
    }
  };

  const notificationTypeEmptyState = (notficationType) => {
    switch (notficationType) {
      case 'new-imagery-available':
        return 'No new imagery available';
      case 'alert':
        // TODO add upsell here if focus
        return 'No new changes detected';
      case 'note':
        return 'No new notes';
      default:
        return 'No new notifications';
    }
  };

  return (
    <div className={cs.section}>
      <div className={cs.header}>
        <h2 className={cs.headerText}>Recent activity</h2>
        <div className={cs.filterContainer}>
          {/* Don't bother showing any filters if there's no data here */}
          {!!notifications.length &&
            filterOptions.map((fo) => (
              <div
                key={fo.key}
                className={classnames(cs.filterOption, {
                  [cs.selectedFilterOption]: fo.key === notificationFilter,
                })}
                onClick={() => setNotificationFilter(fo.key)}
              >
                {fo.display}
              </div>
            ))}
        </div>
      </div>
      <div className={cs.sectionContent}>
        {!loading && filteredNotifications && filteredNotifications.length === 0 && (
          <div className={cs.filterEmptyState}>
            {notificationTypeEmptyState(notificationFilter)}
          </div>
        )}
        {loading && skeletonRows()}
        <ul className={cs.cardList}>
          <>
            {filteredNotifications &&
              filteredNotifications.map((n) => {
                const notficationDisplayData = getNotificationDisplayDetails(n!);
                return (
                  <li
                    key={n.id}
                    onClick={() => {
                      if (isCmdCtrlKeyPressed) {
                        window.open(notficationDisplayData.url, '_blank');
                      } else {
                        history.push(notficationDisplayData.url);
                      }
                    }}
                  >
                    <div className={classNames(cs.card, cs.notificationCard)}>
                      <div
                        className={cs.notificationCardIcon}
                        style={{
                          backgroundColor:
                            notficationDisplayData.backgroundColor || colors.lighterGray,
                        }}
                      >
                        {notficationDisplayData.icon}
                      </div>
                      <div className={cs.notificationCardTextContainer}>
                        <span>
                          <b>{notficationDisplayData.notificationTypeLabel}</b>
                        </span>
                        <span className={cs.notificationCardTextDate}>
                          {moment(n.createdAt).format('MMM DD, YYYY')}
                          {' · '}
                          {n.feature?.properties.name}
                        </span>
                        <span className={cs.notificationCardTextDetails}>
                          {notficationDisplayData.details}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            <B.Button
              minimal
              small
              className={cs.loadMoreButton}
              icon={loading && <B.Spinner size={12} />}
              disabled={atEndOfList}
              onClick={() => setPage((prevPage) => prevPage + 1)}
            >
              {atEndOfList && !loading
                ? `Showing all notifications since last ${oneWeekAgo}`
                : 'Load more'}
            </B.Button>
          </>
        </ul>
      </div>
    </div>
  );
};

export const skeletonRows = () => {
  // TODO this is maya trying to make the loading state be the same as the my properties. idk if we want that actually
  return (
    <B.Menu>
      {[1, 2, 3].map((f) => (
        <B.MenuItem
          className="bp5-skeleton"
          style={{marginTop: '.5rem', height: '7rem'}}
          key={f}
          text={f}
        />
      ))}
    </B.Menu>
  );
};

export default Notifications;
