import * as B from '@blueprintjs/core';
import {
  ActiveElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartEvent,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import {
  LineWithErrorBarsChart,
  LineWithErrorBarsController,
  PointWithErrorBar,
} from 'chartjs-chart-error-bars';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import classnames from 'classnames';
import * as I from 'immutable';
import {isEqual} from 'lodash';
import React, {CSSProperties} from 'react';

import {
  DataRange,
  Graph,
  GraphColumn,
  GraphDataRangeMode,
  GraphMetadata,
  GraphTimeRange,
  GraphWithTimeRange,
  NoteGraphDataRange,
} from 'app/components/AnalyzePolygonChart/types';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as colorUtils from 'app/utils/colorUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import cs from './AnalyzePolygonChart.styl';
import ByCategoryChart from './ByCategoryChart';
import ByMonthChart from './ByMonthChart';
import ByYearChart from './ByYearChart';
import SimpleChart from './SimpleChart';
import {DataPoint, FIT_DISABLED_MODES, X_SCALE_ID, getDataRange, zoomTimeScales} from './utils';
import {ExtraGraphOptions} from '../AnalyzePolygonPopup/AnalyzePolygonPopup';

/** Register all chartJs plugins, elements, and controllers in a centralized location. If you are working with
 * chartJs and get an error a la "{x} is not a registered controller", it's likely you need to
 * register something here. Registration for each element only needs to happen once in the app so we are
 * centralizing here rather than registering individual elements by chart. Everything in this list is used.*/
Chart.register(
  annotationPlugin,
  BarController,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LineWithErrorBarsChart,
  LineWithErrorBarsController,
  PointElement,
  PointWithErrorBar,
  TimeScale,
  Title,
  Tooltip,
  zoomPlugin
);

export {SimpleChart, ByYearChart};
export interface AnalyzePolygonChartProps {
  graph: Graph | GraphWithTimeRange;
  graphMetadata?: GraphMetadata | null;
  extraGraphOptions?: ExtraGraphOptions;
  imageRefs: mapUtils.MapImageRefs;
  areaInM2: number;
  areaUnit: ApiOrganizationAreaUnit;
  onColumnsChange?: (columns: GraphColumn[]) => void;
  onTimeRangeChange?: (timeRange: GraphTimeRange) => void;
  onDataRangeChange?: (dataRange: NoteGraphDataRange) => void;
  onDataRangeModeChange?: (fitMode: GraphDataRangeMode) => void;
  updateCursors?: (cursors: string[]) => void;
  devicePixelRatio?: number;
  disablePanZoom?: boolean;
  hideLegend?: boolean;
  showTitle?: boolean;
  isLegendInteractive?: boolean;
  hideTooltip?: boolean;
  controls?: JSX.Element[];
  loading?: boolean;
  loadingProgress?: number | null;
  loadingStatus?: string | undefined;
  loadingIcon?: B.IconName | B.MaybeElement | null;
  loadingClassName?: string;
  chartClassName?: string;
  controlsClassName?: string;
  graphStyle?: CSSProperties;
  isExpanded: boolean;
}
/**
 * Wrapper component responsible for instantiating callback handlers, rendering
 * loading states, rendering controls, applying styling, and selecting the
 * correct chart component for the graph type.
 */
const AnalyzePolygonChart: React.FunctionComponent<
  React.PropsWithChildren<AnalyzePolygonChartProps>
> = ({
  graph,
  graphMetadata,
  extraGraphOptions,
  imageRefs,
  areaInM2,
  areaUnit,
  onColumnsChange,
  onTimeRangeChange,
  onDataRangeChange,
  onDataRangeModeChange,
  updateCursors,
  devicePixelRatio,
  disablePanZoom,
  hideLegend,
  isLegendInteractive,
  hideTooltip,
  showTitle,
  controls,
  loading,
  loadingProgress,
  loadingStatus,
  loadingIcon,
  loadingClassName,
  chartClassName,
  controlsClassName,
  graphStyle,
  isExpanded,
}) => {
  graph = hookUtils.useContinuity(graph);
  imageRefs = hookUtils.useContinuity(imageRefs);
  // Whether we should show cumulative values either due to extra options or graph settings.
  const showCumulativeValues =
    extraGraphOptions?.showCumulativeValues || graph.graphDataRangeMode === 'cumulative';

  /** Local caches of the initial and current time and ranges. Whether to fit data, and selected columns. */
  const [initialTimeRange, setInitialTimeRange] = React.useState<GraphTimeRange | null>(null);
  const [currentTimeRange, setCurrentTimeRange] = React.useState<GraphTimeRange | null>(null);
  const [currentSelectedColumns, setCurrentSelectedColumns] = React.useState<GraphColumn[] | null>(
    null
  );

  // State variable representing whether fit is toggled in the UI and we're generating dataRanges
  const [hasDataRangeFit, setDataRangeFit] = React.useState<boolean>(
    graph.graphDataRangeMode === 'fit' || (!graph.graphDataRangeMode && !!graph.graphDataRange)
  );

  const toggleFitDataRange = React.useCallback((): void => {
    setDataRangeFit((p) => {
      const nextFitEnabled = !p;
      if (onDataRangeModeChange) {
        // Fit should be exclusive with cumulative values, since to show cumulative we already have to fit.
        onDataRangeModeChange(nextFitEnabled ? 'fit' : 'none');
      }
      return nextFitEnabled;
    });
  }, [setDataRangeFit, onDataRangeModeChange]);

  /** Invoke the onColumnsChange callback with the chart’s current “columns”
   * configuration. The term “columns” is a relic from React Timeseries Charts;
   * they correspond to the concept of multiple datasets in Chart.js. Used on
   * chart initialization and on legend click actions. */
  const handleColumnsChange = React.useCallback(
    (chart: Chart): void => {
      /** We construct the payload using the chart’s datasets instead of
       * legend items so we don’t accidentally store information for appended
       * legend items (e.g., for bulk operations) on the note. */
      const visibleDatasetMetas = chart.getSortedVisibleDatasetMetas();
      const graphColumns: GraphColumn[] = chart.data.datasets.map((dataset) => ({
        name: dataset.label as string,
        color: dataset.backgroundColor as string,
        isSelected: !!visibleDatasetMetas.find(({label}) => label === dataset.label),
        category: dataset['category'],
      }));
      setCurrentSelectedColumns(graphColumns);
      if (onColumnsChange) {
        onColumnsChange(graphColumns);
      }
    },
    [onColumnsChange]
  );

  /** Update the initialTimeRange and currentTimeRange state values with the
   * chart’s current x-axis minimum and maximum values. */
  const handleSetInitialRanges = React.useCallback((chart: Chart): void => {
    const {min: minX, max: maxX} = chart.scales[X_SCALE_ID];
    // const {min: minY, max: maxY} = chart.scales[Y_SCALE_ID];
    const timeRange: GraphTimeRange = [new Date(minX), new Date(maxX)];
    setInitialTimeRange(timeRange);
    setCurrentTimeRange(timeRange);
  }, []);

  /** Update the currentTimeRange state value with the chart’s current x-axis
   * minimum and maximum values. */
  const handleSetTimeRange = React.useCallback((chart: Chart): void => {
    const {min: minX, max: maxX} = chart.scales[X_SCALE_ID];
    const timeRange: GraphTimeRange = [new Date(minX), new Date(maxX)];
    setCurrentTimeRange(timeRange);
  }, []);

  /** Zoom to and update the currentTimeRange state value with the chart’s
   * initial x-axis minimum and maximum values. */
  const resetTimeRange = React.useCallback(
    (chart: Chart): void => {
      if (initialTimeRange) {
        zoomTimeScales(chart, initialTimeRange);
        setCurrentTimeRange(initialTimeRange);
      }
    },
    [initialTimeRange]
  );

  /** Only display “Reset” control if time range has changed. */
  const showResetControl = React.useMemo(
    () => !disablePanZoom && !isEqual(initialTimeRange, currentTimeRange),
    [currentTimeRange, disablePanZoom, initialTimeRange]
  );

  /** Only show the fit control if we're not locked, it's not disabled for mode,
   *  and we're not showing cumulative values.
   **/
  const showFitDataControl = React.useMemo(
    () => !disablePanZoom && !showCumulativeValues && !FIT_DISABLED_MODES.includes(graph.graphMode),
    [disablePanZoom, graph.graphMode, showCumulativeValues]
  );

  /**
   * Calculate chart y-axis range.
   *
   * Priority order here isn't strict, and probably varies by context over notes, AA, other.
   * Some graphs won't come with a dataRange attached, and whose layers don't have ranges,
   * so we provide a default one of 0 to 1, such as for Global Forest Loss using areaByCategory.
   */
  const [chartDataRange, setChartDataRange] = React.useState<DataRange>(
    graph.graphDataRange || graph.layerDataRange || [0, 1]
  );

  React.useEffect(() => {
    setChartDataRange((currentDataRange) => {
      if (disablePanZoom && graph.graphDataRange) {
        // Notes case
        return graph.graphDataRange;
      } else if (showCumulativeValues) {
        // AA cumulative enabled case
        return currentDataRange;
      } else {
        // Returns default range or fit range
        return getDataRange(
          graph,
          currentTimeRange,
          hasDataRangeFit,
          currentSelectedColumns,
          currentDataRange
        );
      }
    });
  }, [
    hasDataRangeFit,
    disablePanZoom,
    showCumulativeValues,
    graph,
    currentTimeRange,
    currentSelectedColumns,
  ]);

  /** Invoke the onTimeRangeChange callback with the chart’s current x-axis
   * minimum and maximum values, which updates on chart initialization and on
   * chart pan, zoom, and reset actions.*/
  React.useEffect(() => {
    if (onTimeRangeChange && currentTimeRange) {
      onTimeRangeChange(currentTimeRange);
    }
  }, [currentTimeRange, onTimeRangeChange]);

  /**
   * Same idea as the above useEffect: we want to update our data ranges
   * when fit or the chartDataRange change.
   */

  React.useEffect(() => {
    if (onDataRangeChange && chartDataRange) {
      /**
       * Check to see if we're setting a default range. If so, just set null
       * instead and have the chart calculate it's data ranges instead of falling
       * back to a stored value. If we set a default range to graphDataRange, the
       * graph in the note will think it's fit when it's technically not.
       *
       * Additional note here: We have to ignore this if showing cumulative values
       * since this affects the range, and we don't want the default ranges reapplied.
       */
      onDataRangeChange(chartDataRange as NoteGraphDataRange);
    }
  }, [chartDataRange, onDataRangeChange]);

  /** Invoke the updateCursors callback with the next cursor(s) to display as
   * an image reference on the map using on the nearest data point(s) in the
   * chart. Supports the option to select one new cursor (to view) or one
   * additional cursor (to compare). Used on chart data point click actions. */
  const handleDataPointClick = React.useCallback(
    (event: ChartEvent, _elements: ActiveElement[], chart: Chart): void => {
      const {native} = event;

      const graphLayer = layerUtils.getLayer(graph.layerKey);
      if (graphLayer.type === 'data' && graphLayer.isNotDisplayedOnMap) return;

      if (native && updateCursors) {
        const points = chart.getElementsAtEventForMode(native, 'nearest', {intersect: true}, true);

        if (points.length) {
          const {datasetIndex, index} = points[0];
          const {cursor} = chart.data.datasets[datasetIndex].data[index] as DataPoint;
          const {shiftKey} = native as PointerEvent;

          if (shiftKey) {
            const activeCursors = imageRefs
              .map(({cursor}) => cursor)
              .filter((c): c is string => !!c);
            const allCursors = I.OrderedSet([cursor, ...(activeCursors || [])]);
            updateCursors([allCursors.first(), allCursors.last()]);
          } else {
            updateCursors([cursor]);
          }
        }
      }
    },
    [imageRefs, updateCursors, graph.layerKey]
  );

  /** Get data column configuration from graph prop, if it exists.
   * This is only be the case for graphs saved on notes.
   * This only applies to graphs with datasets that can be turned on and off
   * like byYear and byCategory. */
  const getDataColumn = React.useCallback(
    (datasetName: string | number | undefined) => {
      return graph.dataColumns?.find(({name}) => name === datasetName?.toString());
    },
    [graph.dataColumns]
  );

  const graphWithSortedFilteredDataPoints = React.useMemo(
    () => ({
      ...graph,
      dataPoints: graph.dataPoints
        .sort(([dateA], [dateB]) => +dateA - +dateB)
        .filter(([, value]) => typeof value === 'number' && !Number.isNaN(value)),
    }),
    [graph]
  );

  if (loading) {
    return (
      <LoadingContainer className={loadingClassName}>
        <LoadingSpinner loadingProgress={loadingProgress} />
        <p>{loadingStatus === 'loading' ? 'Crunching the numbers…' : 'Getting the data…'}</p>
      </LoadingContainer>
    );
  }

  if (loadingIcon) {
    return (
      <LoadingContainer>
        <B.Icon size={30} icon={loadingIcon} color={colorUtils.default.slate} />
      </LoadingContainer>
    );
  }

  if (!graphWithSortedFilteredDataPoints.dataPoints.length) {
    return <LoadingContainer>No data</LoadingContainer>;
  }

  if (graph.graphMode === 'areaByCategory') {
    return (
      <ChartContainer className={chartClassName}>
        <ByCategoryChart
          isExpanded={isExpanded}
          graph={graphWithSortedFilteredDataPoints}
          hideTooltip={hideTooltip}
          areaInM2={areaInM2}
          areaUnit={areaUnit}
          onClick={handleDataPointClick}
          onClickLegend={handleColumnsChange}
          getDataColumn={getDataColumn}
          hideLegend={hideLegend}
          showTitle={showTitle}
          isLegendInteractive={isLegendInteractive}
          graphStyle={graphStyle}
          devicePixelRatio={devicePixelRatio}
          dataRange={chartDataRange}
          /** TODO: Since we are not panning and zooming these charts, we may want to make the
           * graph time range nullable since there would be no need to store the
           * visible time range (we’re already storing the selected years in the
           * graph range). */
          onInitialize={(chart) => {
            handleSetInitialRanges(chart);
            handleColumnsChange(chart);
          }}
        >
          {(chart) => (
            <ControlsContainer
              chart={chart}
              resetTimeRange={resetTimeRange}
              showResetControl={showResetControl}
              className={controlsClassName}
              toggleFitDataRange={toggleFitDataRange}
              hasDataRangeFit={hasDataRangeFit}
              showFitDataControl={showFitDataControl}
            >
              {controls}
            </ControlsContainer>
          )}
        </ByCategoryChart>
      </ChartContainer>
    );
  }

  if (graph.graphMode === 'averageMonthly') {
    return (
      <ChartContainer className={chartClassName}>
        <ByMonthChart
          isExpanded={isExpanded}
          graph={graphWithSortedFilteredDataPoints}
          hideTooltip={hideTooltip}
          areaInM2={areaInM2}
          areaUnit={areaUnit}
          onClick={handleDataPointClick}
          showTitle={showTitle}
          graphStyle={graphStyle}
          devicePixelRatio={devicePixelRatio}
          dataRange={chartDataRange}
          onInitialize={handleSetInitialRanges}
          onPanComplete={handleSetTimeRange}
          onZoomComplete={handleSetTimeRange}
          disablePanZoom={disablePanZoom}
        >
          {(chart) => (
            <ControlsContainer
              chart={chart}
              resetTimeRange={resetTimeRange}
              showResetControl={showResetControl}
              className={controlsClassName}
              toggleFitDataRange={toggleFitDataRange}
              hasDataRangeFit={hasDataRangeFit}
              showFitDataControl={showFitDataControl}
            >
              {controls}
            </ControlsContainer>
          )}
        </ByMonthChart>
      </ChartContainer>
    );
  }

  switch (graph.graphRange.type) {
    case 'simple':
    case 'custom': {
      return (
        <ChartContainer className={chartClassName}>
          <SimpleChart
            graph={graphWithSortedFilteredDataPoints}
            isExpanded={isExpanded}
            imageRefs={imageRefs}
            areaInM2={areaInM2}
            areaUnit={areaUnit}
            onClick={handleDataPointClick}
            onInitialize={handleSetInitialRanges}
            onPanComplete={handleSetTimeRange}
            onZoomComplete={handleSetTimeRange}
            devicePixelRatio={devicePixelRatio}
            disablePanZoom={disablePanZoom}
            hideTooltip={hideTooltip}
            showTitle={showTitle}
            graphStyle={graphStyle}
            dataRange={chartDataRange}
          >
            {(chart) => (
              <ControlsContainer
                chart={chart}
                resetTimeRange={resetTimeRange}
                showResetControl={showResetControl}
                className={controlsClassName}
                toggleFitDataRange={toggleFitDataRange}
                hasDataRangeFit={hasDataRangeFit}
                showFitDataControl={showFitDataControl}
              >
                {controls}
              </ControlsContainer>
            )}
          </SimpleChart>
        </ChartContainer>
      );
    }

    case 'by-year':
      return (
        <ChartContainer className={chartClassName}>
          <ByYearChart
            isExpanded={isExpanded}
            graph={graphWithSortedFilteredDataPoints}
            selectedColumns={currentSelectedColumns}
            showCumulativeValues={showCumulativeValues}
            graphMetadata={graphMetadata}
            imageRefs={imageRefs}
            areaInM2={areaInM2}
            areaUnit={areaUnit}
            onClick={handleDataPointClick}
            onClickLegend={handleColumnsChange}
            onInitialize={(chart) => {
              handleColumnsChange(chart);
              handleSetInitialRanges(chart);
            }}
            getDataColumn={getDataColumn}
            onPanComplete={handleSetTimeRange}
            onZoomComplete={handleSetTimeRange}
            setChartDataRange={setChartDataRange}
            devicePixelRatio={devicePixelRatio}
            disablePanZoom={disablePanZoom}
            hideLegend={hideLegend}
            showTitle={showTitle}
            hideTooltip={hideTooltip}
            isLegendInteractive={isLegendInteractive}
            graphStyle={graphStyle}
            dataRange={chartDataRange}
          >
            {(chart) => (
              <ControlsContainer
                chart={chart}
                resetTimeRange={resetTimeRange}
                showResetControl={showResetControl}
                className={controlsClassName}
                toggleFitDataRange={toggleFitDataRange}
                hasDataRangeFit={hasDataRangeFit}
                showFitDataControl={showFitDataControl}
              >
                {controls}
              </ControlsContainer>
            )}
          </ByYearChart>
        </ChartContainer>
      );

    default:
      return null;
  }
};

export default AnalyzePolygonChart;

/**
 * Wrapper component for applying loading container styling.
 */
export const LoadingContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    style?: CSSProperties;
  }>
> = ({className, style, children}) => (
  <div className={classnames(cs.loading, className)} style={style}>
    {children}
  </div>
);

/**
 * Wrapper component for rendering a uniform loading spinner.
 */
export const LoadingSpinner: React.FunctionComponent<
  React.PropsWithChildren<{
    loadingProgress?: number | null;
  }>
> = ({loadingProgress}) => <B.Spinner size={30} value={loadingProgress ?? undefined} />;

/**
 * Wrapper component for applying chart container styling.
 */
const ChartContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    style?: CSSProperties;
  }>
> = ({className, style, children}) => (
  <div className={classnames(cs.chart, className)} style={style}>
    {children}
  </div>
);

/**
 * Wrapper component for applying controls container styling. Controls are
 * elements displayed in the upper-right corner on hover.
 */
const ControlsContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    chart: Chart;
    resetTimeRange: (chart: Chart) => void;
    showResetControl: boolean;
    toggleFitDataRange: () => void;
    hasDataRangeFit: boolean;
    showFitDataControl: boolean;
    className?: string;
  }>
> = ({
  chart,
  resetTimeRange,
  showResetControl,
  toggleFitDataRange,
  hasDataRangeFit,
  showFitDataControl,
  className,
  children,
}) => (
  <div className={classnames(cs.controls, className)}>
    {showResetControl && (
      <B.Button title="Reset" icon="reset" small onClick={() => resetTimeRange(chart)} />
    )}
    {showFitDataControl && (
      <B.Button
        title="Fit to Y-Axis"
        icon="arrows-vertical"
        active={hasDataRangeFit}
        small
        onClick={() => {
          toggleFitDataRange();
        }}
      />
    )}
    {children}
  </div>
);
