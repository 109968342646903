import React from 'react';

import LayerLegend from 'app/components/LayerLegend';
import DraggableMapOverlayDialog from 'app/components/MapOverlayDialog/DraggableMapOverlayDialog';
import * as layers from 'app/utils/layers';
import * as mapUtils from 'app/utils/mapUtils';

import WithWidth from '../WithWidth';

const X_PADDING = 10;
const Y_PADDING = 26;
const WIDTH = 280;

export default function DraggableLegend({
  imageRefs,
  layer,
  index,
  offset = {},
}: {
  imageRefs: mapUtils.MapImageRefs;
  layer: layers.LayerInfo;
  index: number;
  offset?: {right?: number; bottom?: number; left?: number};
}) {
  let dataLayerInfo: layers.DataLayerInfo | undefined;
  let imageLayerInfo: layers.ImageLayerInfo | undefined;

  if (layer.type === 'data') {
    dataLayerInfo = layer;
  } else if (layer.type === 'image') {
    imageLayerInfo = layer;
  }

  if (
    (!dataLayerInfo && !imageLayerInfo) ||
    (dataLayerInfo && !dataLayerInfo.dataLabels) ||
    (dataLayerInfo && !dataLayerInfo.gradientStops) ||
    (imageLayerInfo && !imageLayerInfo.renderLayerLegend)
  ) {
    return null;
  }

  let isPosRight = true;
  if (imageRefs.length === 2) {
    if (imageRefs[0].layerKey !== imageRefs[1]!.layerKey) {
      // if we are in compare mode and the layers aren't the same
      isPosRight = index !== 0;
    } else if (index === 1) {
      // layer keys are the same and we are rendering the second one, skip it
      return null;
    }
  }

  offset = {right: 0, bottom: 0, left: 0, ...offset};

  return (
    <DraggableMapOverlayDialog
      isCollapsable={true}
      initialPosition={
        isPosRight
          ? {bottom: Y_PADDING + offset.bottom!, right: X_PADDING + offset.right!}
          : {bottom: Y_PADDING + offset.bottom!, left: X_PADDING + offset.left!}
      }
      style={{width: WIDTH, maxWidth: '45%'}} // magic width that feel good, max-width for public maps on mobile
      title={(dataLayerInfo || imageLayerInfo)!.shortName}
    >
      <WithWidth>
        {(width) => {
          if (dataLayerInfo) {
            return (
              <LayerLegend
                gradientStops={dataLayerInfo.gradientStops}
                dataLabels={dataLayerInfo.dataLabels}
                width={width}
              />
            );
          }
        }}
      </WithWidth>
      {imageLayerInfo && imageLayerInfo.renderLayerLegend!()}
    </DraggableMapOverlayDialog>
  );
}
