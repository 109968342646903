import * as I from 'immutable';
import React from 'react';

import {usePushNotification} from 'app/components/Notification';
import api from 'app/modules/Remote/api';
import {
  ApiAlertCountsByFeatureId,
  ApiNoteCountsByFeatureId,
} from 'app/modules/Remote/FeatureCollection';
import {WithLoadedOrgUsers} from 'app/providers/OrgUsersProvider';
import {
  ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY,
  ALERT_OWNERSHIP_CHANGE_USER_ID,
  ALERT_VEGETATION_DROP_ENROLLMENT_KEY,
  ALERT_VEGETATION_DROP_USER_ID,
} from 'app/utils/constants';
import {useApiGet, useApiGetGen} from 'app/utils/hookUtils';

import PropertyOverview, {Props} from './PropertyOverview';

type FeatureId = number;
type Aggregation = number | 'all';
type NoteCountAggregations = I.Map<Aggregation, number>;
export type NoteCountsByFeatureId = I.Map<FeatureId, NoteCountAggregations>;

// transform the JSON keys (strings) into their proper types with a nested
// reduce. See types above.
export function parseNoteCountsByFeatureIdResult(
  noteCountsByFeatureIdResponse: I.ImmutableOf<ApiNoteCountsByFeatureId>
): NoteCountsByFeatureId {
  return noteCountsByFeatureIdResponse.reduce(
    (acc, countByAgg, fId) =>
      acc!.set(
        parseInt(fId!),
        countByAgg!.reduce((aggAcc, count, agg) => {
          // the aggregation is either "all" or a year (see Aggregation type above)
          // if the former, we need to parse into int because it was a json key
          if (!agg || !count) {
            return aggAcc!;
          }

          const parsedAgg = agg === 'all' ? agg : parseInt(agg);
          return aggAcc!.set(parsedAgg, count);
        }, I.Map() as NoteCountAggregations)
      ),
    I.Map() as NoteCountsByFeatureId
  );
}

export function parseAlertCountsByFeatureIdResult(
  alertCountsByFeatureIdResponse: I.ImmutableOf<ApiAlertCountsByFeatureId>
): ApiAlertCountsByFeatureId {
  return alertCountsByFeatureIdResponse
    .reduce(
      (acc, countByAgg, fId) =>
        acc!.set(
          parseInt(fId!),
          countByAgg!.reduce((aggAcc, count, agg) => {
            if (!agg || !count) {
              return aggAcc!;
            }

            // If we are referencing upstream custom alerts, use
            // the enrollement keys to distinguish them
            const alertKeyMap = {
              [ALERT_OWNERSHIP_CHANGE_USER_ID]: ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY,
              [ALERT_VEGETATION_DROP_USER_ID]: ALERT_VEGETATION_DROP_ENROLLMENT_KEY,
            };

            const parsedAgg = alertKeyMap[agg] || agg;

            return aggAcc!.set(parsedAgg, count);
          }, I.Map())
        ),
      I.Map()
    )
    .toJS();
}

/**
 * Wrapper around the PropertyOverview view component to load our API data.
 *
 * Keeps the current year in state since it affects the loaded data.
 */
const PropertyOverviewDataProvider: React.FunctionComponent<
  React.PropsWithChildren<
    Omit<
      Props,
      | 'organizationUsers'
      | 'pushNotification'
      | 'year'
      | 'setYear'
      | 'imageryRecords'
      | 'userNoteCountsByFeatureId'
      | 'alertNoteCountsByFeatureId'
      | 'refreshImageryRecords'
    >
  >
> = (props) => {
  const {selectedProject, selectedFeatureCollection} = props;

  const [year, setYear] = React.useState(new Date().getFullYear());

  const [imageryRecords, {refresh: refreshImageryRecords}] = useApiGetGen(
    async function* (selectedProjectId, selectedFeatureCollectionId, year) {
      return yield* api.featureCollections.listImagerySummary(
        selectedProjectId,
        selectedFeatureCollectionId,
        year,
        {perPage: 500},
        {getAllPages: 2}
      );
    },
    [selectedProject.get('id'), selectedFeatureCollection.get('id'), year] as const
  );

  const [userNoteCountsByFeatureIdResult] = useApiGet(
    async (projectId, featureCollectionId) =>
      parseNoteCountsByFeatureIdResult(
        (
          await api.featureCollections.fetchNoteCountsByFeatureId(
            projectId,
            featureCollectionId,
            'user'
          )
        )
          .get('data')
          .toMap()
      ),
    [selectedProject.get('id'), selectedFeatureCollection.get('id')] as const
  );
  const [alertNoteCountsByFeatureIdResult] = useApiGet(
    async (projectId, featureCollectionId) =>
      parseAlertCountsByFeatureIdResult(
        (await api.featureCollections.fetchAlertCountsByFeatureId(projectId, featureCollectionId))
          .get('data')
          .toMap()
      ),
    [selectedProject.get('id'), selectedFeatureCollection.get('id')] as const
  );

  const pushNotification = usePushNotification();

  return (
    // We assume that they’re already loaded, so we don’t care that this
    // potentially adds a spinner, and we don’t bother with refreshing them.
    <WithLoadedOrgUsers>
      {(organizationUsers) => (
        <PropertyOverview
          {...props}
          pushNotification={pushNotification}
          imageryRecords={imageryRecords}
          refreshImageryRecords={refreshImageryRecords}
          userNoteCountsByFeatureId={userNoteCountsByFeatureIdResult}
          alertNoteCountsByFeatureId={alertNoteCountsByFeatureIdResult}
          year={year}
          setYear={setYear}
          organizationUsers={organizationUsers}
        />
      )}
    </WithLoadedOrgUsers>
  );
};

export default PropertyOverviewDataProvider;
