import geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import {NoteGraph} from 'app/components/AnalyzePolygonChart/types';
import {ShareModal} from 'app/components/PublicMap/PublicShareView';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiProject} from 'app/modules/Remote/Project';
import {REGRID_FEATURE_COLLECTION_ID, SOURCE_DETAILS_BY_ID} from 'app/utils/constants';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

const UNSHAREABLE_OVERLAYS = [REGRID_FEATURE_COLLECTION_ID];

export interface ShareLinkConfig {
  feature: I.ImmutableOf<ApiFeature>;
  featureData: I.ImmutableListOf<ApiFeatureData>;
  project: I.ImmutableOf<ApiProject>;
  overlayFeatureCollections: I.ImmutableListOf<ApiFeatureCollection>;
  overlayVisibilityByName: Record<string, boolean>;
  imageRefs: mapUtils.MapImageRefs;
  cameraOptions?: mapUtils.CameraOptions | null;
  defaultDescription?: string | null;
  attachments?: string[] | null;
  noteGeometry?: geojson.Geometry | null;
  graph?: NoteGraph | null;
}

interface ShareLinkContextValue {
  isShareLinkModalOpen: boolean;
  openShareLinkModal: (imageRefs: ShareLinkConfig['imageRefs']) => void;
  openShareLinkModalWithNote: (
    noteImageRefs: ShareLinkConfig['imageRefs'],
    noteText: ShareLinkConfig['defaultDescription'],
    attachments: ShareLinkConfig['attachments'],
    graph: ShareLinkConfig['graph'],
    noteGeometry: ShareLinkConfig['noteGeometry']
  ) => void;
  hasUnshareableConfiguration: (
    imageRefs: ShareLinkConfig['imageRefs'],
    allowCustomCOGs?: boolean
  ) => boolean;
}

export const ShareLinkContext = React.createContext<ShareLinkContextValue>({
  isShareLinkModalOpen: false,
  openShareLinkModal: () => {},
  openShareLinkModalWithNote: () => {},
  hasUnshareableConfiguration: () => false,
});

export const ShareLinkProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    feature: I.ImmutableOf<ApiFeature> | null;
    featureData: I.ImmutableListOf<ApiFeatureData> | null;
    project: I.ImmutableOf<ApiProject>;
    overlayFeatureCollections: I.ImmutableListOf<ApiFeatureCollection>;
    overlayVisibilityByName: Record<string, boolean>;
    cameraOptions: mapUtils.CameraOptions | null;
  }>
> = ({
  feature,
  featureData,
  project,
  overlayFeatureCollections,
  overlayVisibilityByName,
  cameraOptions,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [shareDetails, setShareDetails] = React.useState<ShareLinkConfig | null>(null);

  const openShareLinkModal = (imageRefs: ShareLinkConfig['imageRefs']) => {
    if (feature && featureData) {
      setShareDetails({
        feature,
        featureData,
        project,
        overlayFeatureCollections,
        overlayVisibilityByName,
        cameraOptions,
        imageRefs,
      });
      setIsOpen(true);
    }
  };

  const openShareLinkModalWithNote = (
    noteImageRefs: ShareLinkConfig['imageRefs'],
    noteText: ShareLinkConfig['defaultDescription'],
    attachments: ShareLinkConfig['attachments'],
    graph: ShareLinkConfig['graph'],
    noteGeometry: ShareLinkConfig['noteGeometry']
  ) => {
    if (feature && featureData) {
      setShareDetails({
        feature,
        featureData,
        project,
        overlayFeatureCollections,
        overlayVisibilityByName,
        cameraOptions,
        imageRefs: noteImageRefs,
        defaultDescription: noteText,
        attachments,
        noteGeometry,
        graph,
      });
      setIsOpen(true);
    }
  };

  const close = () => {
    setShareDetails(null);
    setIsOpen(false);
  };

  const hasUnshareableConfiguration = (
    imageRefs: ShareLinkConfig['imageRefs'],
    allowCustomCOGs?: boolean
  ) => {
    const unshareableOverlays = overlayFeatureCollections
      .toJS()
      .filter((overlay) => UNSHAREABLE_OVERLAYS.includes(overlay.id));

    const hasUnshareableOverlays = unshareableOverlays.some(
      (overlay) => overlayVisibilityByName[overlay.name]
    );

    const hasUnShareableLayers = imageRefs.some((imageRef) => {
      const {sourceId} = layerUtils.parseLayerKey(imageRef.layerKey);
      const sourceDetails = SOURCE_DETAILS_BY_ID[sourceId];
      if (allowCustomCOGs && sourceId === 'COG-TRUECOLOR') return false;
      return !sourceDetails?.shareable;
    });

    return hasUnshareableOverlays || hasUnShareableLayers;
  };

  return (
    <ShareLinkContext.Provider
      value={{
        openShareLinkModal,
        openShareLinkModalWithNote,
        hasUnshareableConfiguration,
        isShareLinkModalOpen: isOpen,
      }}
    >
      <>
        {shareDetails && <ShareModal shareDetails={shareDetails} isOpen={isOpen} onClose={close} />}
        {children}
      </>
    </ShareLinkContext.Provider>
  );
};

export function useShareLink(): ShareLinkContextValue {
  const value = React.useContext(ShareLinkContext);

  if (!value) {
    throw new Error('useShareLink must be beneath a ShareLinkProvider');
  }

  return value;
}
