import geojson from 'geojson';
import * as I from 'immutable';
import leaflet from 'leaflet';
import vectorTileLayer from 'leaflet-vector-tile-layer';
import React from 'react';
import ReactDOM from 'react-dom';

import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import * as apiUtils from 'app/utils/apiUtils';
import * as C from 'app/utils/constants';
import * as featureUtils from 'app/utils/featureUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {getRasterSourceDefinition} from '../DeclarativeMap/FeatureRaster';

/**
 * Hook to attach a Leaflet map to an element ref.
 *
 * Returns the map, an object of values (currently just the map’s zoom) and a
 * setter for setting the map container element (suitable for a ref= value).
 */
export function useLeafletMap(L: typeof leaflet | null) {
  const [map, setMap] = React.useState<L.Map | null>(null);
  const [mapEl, setMapEl] = React.useState<HTMLDivElement | null>(null);
  const [currentZoom, setCurrentZoom] = React.useState(0);

  React.useEffect(() => {
    if (!mapEl || !L) {
      setMap(null);
      return;
    }

    const map = new L.Map(mapEl, {
      minZoom: 8,
      maxZoom: 19,
      // We allow fractional zoom levels to match Mapbox, and give us a bit of a
      // range for fitting the features.
      zoomSnap: 0.5,
      zoomDelta: 0.5,
      attributionControl: false,
      zoomControl: false,
      // Causes flashes after moving the map, but reduces the problem of seams
      // after dragging the map around in Firefox.
      transform3DLimit: 100,
      fadeAnimation: false,
    });

    map.on('zoomend', () => {
      setCurrentZoom(map.getZoom());
    });

    // Create a background pane to allow for placing a fill of the features behind the imagery.
    const backgroundPane = map.createPane('backgroundPane');
    backgroundPane.style.zIndex = '0';

    setMap(map);

    return () => {
      map.remove();
    };
  }, [mapEl, L]);

  return [map, {zoom: currentZoom}, setMapEl] as const;
}

/**
 * Disables interactivity if frozen is set to true.
 */
export function useLeafletMapFrozen(map: leaflet.Map | null, frozen: boolean) {
  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (frozen) {
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
    } else {
      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
    }
  }, [map, frozen]);
}

/**
 * Hook to add FeatureData imagery to a Leaflet map. Handles both tiles and
 * raster imagery (e.g. S2).
 *
 * Returns true if all of the tiles are currently loaded.
 */
export function useLeafletFeatureImagery(
  L: typeof leaflet | null,
  map: leaflet.Map | null,
  featureDatum: I.MapAsRecord<I.ImmutableFields<ApiFeatureData>> | null,
  layerKey: string,
  cursor: string | null,
  firebaseToken: string
) {
  const [tilesLoaded, setTilesLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!L || !map || !featureDatum) {
      return;
    }

    setTilesLoaded(false);

    let imageLayer: L.Layer;

    const rasterSourceDefinition = getRasterSourceDefinition(featureDatum, layerKey);

    if (rasterSourceDefinition) {
      const maxZoom = rasterSourceDefinition.get('maxzoom');
      const minZoom = rasterSourceDefinition.get('minzoom');

      let urlTemplate = rasterSourceDefinition.get('tiles').get(0);
      urlTemplate = mapUtils.formatTileUrl(urlTemplate, firebaseToken);

      imageLayer = new L.TileLayer(urlTemplate, {
        bounds: bboxToLeafletBounds(rasterSourceDefinition.get('bounds').toJS()),
        tileSize: 256,
        maxNativeZoom: maxZoom,
        minNativeZoom: minZoom,
        // We let the map’s min/max handle clamping this. Because we set the
        // native limits, the other zoom levels will scale the tiles
        // automatically by Leaflet.
        maxZoom: 20,
        minZoom: 1,
      });
    } else {
      const url = featureDatum.getIn(['images', 'urls', layerKey])!;
      const bounds = featureUtils.getBboxFromBounds(
        I.fromJS(featureUtils.getFeatureBoundsforSource(featureDatum, layerKey))
      );

      imageLayer = new L.ImageOverlay(url, bboxToLeafletBounds(bounds));
    }

    const loadingHandler = () => setTilesLoaded(false);
    const loadHandler = () => setTilesLoaded(true);

    imageLayer.on('loading', loadingHandler);
    imageLayer.on('load', loadHandler);

    map.addLayer(imageLayer);

    return () => {
      imageLayer.off('loading', loadingHandler);
      imageLayer.off('load', loadHandler);

      map.removeLayer(imageLayer);
    };
  }, [L, featureDatum, layerKey, map, cursor, firebaseToken]);

  return tilesLoaded;
}

/**
 * Hook to add GeoJSON geometry to a Leaflet map. Does not re-render when opts
 * changes.
 */
export function useLeafletGeometry(
  L: typeof leaflet | null,
  map: L.Map | null,
  geojson:
    | (Pick<ApiFeatureCollection, 'tiles'> & geojson.FeatureCollection)
    | geojson.GeoJsonObject
    | null
    | undefined,
  firebaseToken: string,
  opts: L.GeoJSONOptions = {}
) {
  const optsRef = React.useRef(opts);
  optsRef.current = opts;

  React.useEffect(() => {
    if (!L || !map) {
      return;
    }

    let layer: L.Layer;

    if (geojson?.type === 'FeatureCollection' && (geojson as ApiFeatureCollection).tiles) {
      const tiles = (geojson as ApiFeatureCollection).tiles!;

      let url = tiles.urls[0];
      const fetchOptions = {};

      if (!url.startsWith('http')) {
        url = mapUtils.formatTileUrl(apiUtils.getApiRoute(url), firebaseToken);
      }

      layer = vectorTileLayer(url, {
        fetchOptions,
        vectorTileLayerStyles: {
          [tiles.sourceLayer]: optsRef.current.style,
        },
        // maxDetailZoom allows "overzooming"
        // past the max zoom at the current level of detail, while keeping the
        // vector stroke weight visually consistent across all zoom levels.
        // https://gitlab.com/jkuebart/Leaflet.VectorTileLayer#leafletvectortilelayer
        maxDetailZoom: tiles.maxZoom ?? C.OVERLAY_MAX_ZOOM_LEVEL,
      }).addTo(map);
    } else {
      layer = new L.GeoJSON(geojson ?? undefined, optsRef.current).addTo(map);
    }

    return () => {
      layer.remove();
    };
  }, [L, map, geojson, firebaseToken]);
}

/**
 * Wrapper around useLeafletGeometry for cases when you need to add geometry in
 * a loop. Just render this component as a child.
 */
export const LeafletGeometry: React.FunctionComponent<
  React.PropsWithChildren<{
    L: typeof leaflet;
    map: L.Map;
    geojson:
      | geojson.GeoJsonObject
      | (geojson.FeatureCollection & Pick<ApiFeatureCollection, 'tiles'>);
    firebaseToken: string;
    opts?: L.GeoJSONOptions;
  }>
> = ({L, map, geojson, firebaseToken, opts}) => {
  useLeafletGeometry(L, map, geojson, firebaseToken, opts);

  return null;
};

export function bboxToLeafletBounds(bounds: geojson.BBox): L.LatLngBoundsExpression {
  return [
    [bounds[3], bounds[2]],
    [bounds[1], bounds[0]],
  ];
}

/**
 * Wraps its children in a Leaflet control.
 */
export const CustomLeafletControl: React.FunctionComponent<
  React.PropsWithChildren<{
    L: typeof leaflet;
    map: L.Map | null;
    opts?: L.ControlOptions;
  }>
> = ({L, map, children, opts = {}}) => {
  const [el, setEl] = React.useState<HTMLDivElement | null>(null);

  const controlOptsRef = React.useRef(opts);
  controlOptsRef.current = opts;

  const controlClassRef = React.useRef(
    L.Control.extend({
      onAdd: function (map: L.Map) {
        const el = map.getContainer().ownerDocument!.createElement('div');

        // We block double click events so that clicking quickly on the buttons
        // doesn’t cause Leaflet to zoom. This has to be done in DOM events
        // rather than React because Leaflet works at the DOM level, which ends
        // up handling the event before React catches it.
        el.addEventListener('dblclick', (ev) => ev.stopPropagation());

        setEl(el);

        return el;
      },

      onRemove: function () {
        setEl(null);
      },
    })
  );

  React.useEffect(() => {
    const control = new controlClassRef.current(controlOptsRef.current);
    map?.addControl(control);

    return () => {
      map?.removeControl(control);
    };
  }, [map]);

  return el && ReactDOM.createPortal(children, el);
};
