import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import {usePushNotification} from 'app/components/Notification';
import {ApiReport, ReportState} from 'app/modules/Remote/Feature';
import {recordEvent} from 'app/tools/Analytics';
import {ApiResponse} from 'app/utils/apiUtils';
import {useUserInfo} from 'app/utils/featureFlags';

import {formatDate} from './ReportExportFeature';

/**
 * Component that renders the Report toolbar buttons for printing, help, and optionally, saving & archiving.
 * */

export const ReportExportToolbar: React.FunctionComponent<
  React.PropsWithChildren<{
    areMapsReady: boolean;
    apiReport?: ApiReport<ReportState | string> | undefined;
    setApiReport?: React.Dispatch<
      React.SetStateAction<ApiReport<string | ReportState> | undefined>
    >;
    saveReport?: (reportTitle: string) => Promise<ApiResponse<ApiReport<ReportState>>>;
    updateReport?: () => Promise<ApiResponse<ApiReport<ReportState>>>;
    archiveReport?: () => void;
    hasMissingNotes?: boolean;
    refetchReports?: () => Promise<void>;
    reportWindow?: Window | null;
    reportTitle: string;
  }>
> = ({
  areMapsReady,
  apiReport,
  saveReport,
  updateReport,
  archiveReport,
  hasMissingNotes,
  setApiReport,
  refetchReports,
  reportWindow,
  reportTitle,
}) => {
  const toolbarRef = React.useRef<HTMLDivElement | null>(null);
  const [, profile] = useUserInfo();
  const [loadingReport, setLoadingReport] = React.useState(false);

  /** Use existing hook to render a toast in the parent app window. */
  const makeParentWindowToast = usePushNotification();

  /** Using a custom little toaster here so we can pin it to our report window */
  const makeReportWindowToast = (
    htmlEl: HTMLDivElement | null,
    message: string,
    intent: B.Intent
  ) => {
    if (htmlEl) {
      B.OverlayToaster.create({usePortal: false}, htmlEl).show({
        message: message,
        intent: intent,
        timeout: 3000,
      });
    }
  };

  return (
    <>
      <div
        className={classnames(
          'report-toolbar',
          'no-print',
          apiReport ? 'report-toolbar-saved' : ''
        )}
        ref={toolbarRef}
      >
        {/** DISPLAY REPORT METADATA FOR SAVED REPORTS */}
        <div className="report-toolbar-warning">
          {apiReport && (
            <>
              <B.Tooltip
                disabled={!hasMissingNotes}
                content={'This report contained a note that is no longer available'}
                /* Can’t use the default portal, since it’s in the other window. */
                usePortal={false}
              >
                <B.Icon
                  icon={hasMissingNotes ? 'warning-sign' : 'floppy-disk'}
                  style={{marginRight: '12px', marginLeft: '12px'}}
                ></B.Icon>
              </B.Tooltip>
              Report last saved {formatDate(apiReport.updatedAt, true)}
            </>
          )}
        </div>
        {/* ACTION BUTTONS */}
        <div>
          <B.Tooltip
            content={
              areMapsReady ? (
                'Print / Save to PDF'
              ) : (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <B.Spinner size={B.SpinnerSize.SMALL} />
                  <div>Loading map tiles…</div>
                </div>
              )
            }
            usePortal={false}
          >
            <B.AnchorButton
              icon="print"
              onClick={async () => {
                try {
                  toolbarRef.current?.ownerDocument?.defaultView?.print();
                } catch {
                  // Ignored. Some browsers throw an exception on cancel.
                } finally {
                  setLoadingReport(false);
                }
              }}
              disabled={!areMapsReady}
            />
          </B.Tooltip>
          {/* Don't allow read-only users to create, update, or delete reports */}
          {profile!.get('role') !== 'readonly' && saveReport && (
            <>
              <B.Tooltip
                content={apiReport ? 'Save updates to Lens' : 'Save report to Lens'}
                usePortal={false}
              >
                <B.AnchorButton
                  icon="floppy-disk"
                  onClick={async () => {
                    if (reportWindow) {
                      try {
                        setLoadingReport(true);
                        if (setApiReport) {
                          if (updateReport) {
                            // Update existing report
                            const updatedReport = (await updateReport()).get('data').toJS();
                            setApiReport(updatedReport);
                            makeReportWindowToast(
                              toolbarRef.current,
                              'Report saved!',
                              B.Intent.SUCCESS
                            );
                            if (refetchReports) {
                              refetchReports();
                            }
                          } else if (saveReport) {
                            // Save new report
                            const newReportTitle = reportWindow.prompt(
                              'Enter a name for this new report',
                              reportTitle
                            );
                            const newReport = (await saveReport(newReportTitle ?? reportTitle))
                              .get('data')
                              .toJS();
                            setApiReport(newReport);
                            if (refetchReports) {
                              refetchReports();
                            }
                          }
                        }
                        makeReportWindowToast(
                          toolbarRef.current,
                          'Report saved!',
                          B.Intent.SUCCESS
                        );
                        recordEvent('Saved report');
                      } catch (e) {
                        makeReportWindowToast(
                          toolbarRef.current,
                          'Something went wrong! Please try again!',
                          B.Intent.WARNING
                        );
                      } finally {
                        setLoadingReport(false);
                      }
                    }
                  }}
                  disabled={loadingReport}
                />
              </B.Tooltip>
              {/* Temporary button to surface functionality. This'll be available in the list view eventually. */}
              <B.Tooltip content={'Delete this report'} usePortal={false}>
                <B.AnchorButton
                  icon="trash"
                  onClick={async () => {
                    if (
                      reportWindow &&
                      reportWindow.confirm('Are you sure you want to delete this report?')
                    ) {
                      try {
                        setLoadingReport(true);
                        if (archiveReport) {
                          await archiveReport();
                          reportWindow?.close();
                          makeParentWindowToast({
                            message: 'Report deleted.',
                            autoHideDuration: 3000,
                            options: {
                              intent: B.Intent.SUCCESS,
                            },
                          });
                          if (refetchReports) {
                            refetchReports();
                          }
                        }
                      } catch {
                        makeReportWindowToast(
                          toolbarRef.current,
                          'Something went wrong! Please try again.',
                          'warning'
                        );
                      } finally {
                        setLoadingReport(false);
                      }
                    }
                  }}
                  disabled={loadingReport || !archiveReport}
                />
              </B.Tooltip>
            </>
          )}

          <B.Tooltip content="Help" usePortal={false}>
            <B.AnchorButton
              icon="help"
              href="https://support.upstream.tech/article/18-generating-reports"
              target="_blank"
            />
          </B.Tooltip>
        </div>
      </div>
    </>
  );
};
