import * as Sentry from '@sentry/react';
import geojson from 'geojson';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';
import {Redirect} from 'react-router';

import {usePushNotification} from 'app/components/Notification';
import {api} from 'app/modules/Remote';
import {ApiLensConvertFeatureFile} from 'app/modules/Remote/Admin';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {
  ApiOrganization,
  ApiOrganizationAreaUnit,
  getAreaUnit,
} from 'app/modules/Remote/Organization';
import {useGeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {recordEvent} from 'app/tools/Analytics';
import {ApiResponseError} from 'app/utils/apiUtils';
import {findPrimaryFeatureCollection} from 'app/utils/featureCollectionUtils';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import {useStateWithDeps} from 'app/utils/hookUtils';
import {createLayerKeysFromLayerObject, createLayerObjectFromLayerKeys} from 'app/utils/layerUtils';
import {getOrgPrefix} from 'app/utils/organizationUtils';
import * as projectUtils from 'app/utils/projectUtils';
import * as routeUtils from 'app/utils/routeUtils';

import {ManagePropertiesMode} from '.';

export type FeatureId = number;

export interface NumberedFeatureCollection extends geojson.FeatureCollection {
  features: NumberedFeature[];
}
export interface NumberedFeature extends geojson.Feature {
  id: FeatureId;
}

export type GroupPropertiesOptions = 'individual' | 'group' | null;

export interface ManageFeaturesState {
  filename: string | null;
  uploadedFeatureCollection: NumberedFeatureCollection | null;
  isConvertingFile: boolean;
  nameField: string | null;
  nameValues: I.Map<FeatureId, string> | null;
  partNameValues: I.Map<FeatureId, string | null> | null;
  partNameField: string | null;
  selectedFeature: NumberedFeature | null;
  groupProperties: GroupPropertiesOptions;
  problems: string[] | null;
  featureCollectionErrors: string[] | null;
  isUploading: boolean;
  selectedFeatureIds: I.Set<FeatureId>;
  areaUnit: ApiOrganizationAreaUnit;
  allUploadableFeatureIds: I.Set<FeatureId>;
  portfolioName: string | null;
  existingFeatureCollection?: HydratedFeatureCollection | null;
  warningsMap: Record<string, string | null>;
  selectedLayerKeys: string[];
  selectedOverlayIds: number[];
}

export interface ManageFeaturesActions {
  setFilename: React.Dispatch<React.SetStateAction<string | null>>;
  setUploadedFeatureCollection: React.Dispatch<
    React.SetStateAction<NumberedFeatureCollection | null>
  >;
  setFilePath: React.Dispatch<React.SetStateAction<string | null>>;
  setIsConvertingFile: React.Dispatch<React.SetStateAction<boolean>>;
  setNameField: React.Dispatch<React.SetStateAction<string | null>>;
  setNameValues: React.Dispatch<React.SetStateAction<I.Map<FeatureId, string> | null>>;
  setPartNameValues: React.Dispatch<React.SetStateAction<I.Map<FeatureId, string | null> | null>>;
  setPartNameField: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedFeature: React.Dispatch<React.SetStateAction<NumberedFeature | null>>;
  convertFile: (file: File, mode?: ManagePropertiesMode) => Promise<string | undefined>;
  uploadFeatures: () => Promise<void>;

  uploadDrawnFeatures: () => Promise<void>;
  setGroupProperties: React.Dispatch<React.SetStateAction<GroupPropertiesOptions>>;
  setSelectedFeatureIds: React.Dispatch<React.SetStateAction<I.Set<FeatureId>>>;
  setProblems: React.Dispatch<React.SetStateAction<string[] | null>>;
  setFeatureCollectionErrors: React.Dispatch<React.SetStateAction<string[] | null>>;
  setPortfolioName: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedLayerKeys: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedOverlayIds: React.Dispatch<React.SetStateAction<number[]>>;
}

interface ManageFeaturesContextValue {
  manageFeaturesState: ManageFeaturesState;
  manageFeaturesActions: ManageFeaturesActions;
  loading: boolean;
}

export const ManageFeaturesContext = React.createContext<ManageFeaturesContextValue | undefined>(
  undefined
);

const ManageFeaturesProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
    organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>;
    projectId?: string;
    mode: ManagePropertiesMode;
  }>
> = ({organization, history, projectId, mode, children}) => {
  // useProjects uses meta.loading for its loading state
  const [projects, featureCollections, projectsActions, meta] = useProjects();

  // useGeoJsonFeatures doesn't distinguish a loading state and will just return an empty list
  // until data is returned
  const getFeatures = useGeoJsonFeaturesLoader();

  // projectId param is a prefix of the full UUID
  const project = React.useMemo(() => {
    if (!projectId || !projects) {
      return null;
    }

    return projectUtils.findProjectInProjects(projects, projectId);
  }, [projectId, projects]);

  const existingFeatureCollection = React.useMemo(() => {
    if (!project) {
      return null;
    }

    const unhydratedFeatureCollection =
      project && findPrimaryFeatureCollection(project, featureCollections);

    return unhydratedFeatureCollection
      ? // if a featureCollection exists, return it as a HydratedFeatureCollection
        featureCollectionUtils.hydratedFeatureCollection(
          unhydratedFeatureCollection,
          getFeatures(unhydratedFeatureCollection)
        )
      : null;
  }, [project, featureCollections, getFeatures]);

  // In file upload mode, this featureCollection is only updated when a new file is uploaded.
  // In draw mode, it is updated whenever a new feature is drawn or edited.
  // TODO (maya): rename this variable something that makes more sense now!
  const [uploadedFeatureCollection, setUploadedFeatureCollection] =
    React.useState<NumberedFeatureCollection | null>(null);
  const [filename, setFilename] = React.useState<string | null>(null);
  const [isConvertingFile, setIsConvertingFile] = React.useState(false);
  const [problems, setProblems] = React.useState<string[] | null>(null);
  const [groupProperties, setGroupProperties] = React.useState<'individual' | 'group' | null>(
    'group'
  );
  const [selectedFeature, setSelectedFeature] = React.useState<NumberedFeature | null>(null);
  const [filePath, setFilePath] = React.useState<string | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [featureCollectionErrors, setFeatureCollectionErrors] = React.useState<string[] | null>(
    null
  );
  const [portfolioName, setPortfolioName] = React.useState<string | null>(null);
  const [partNameValues, setPartNameValues] = React.useState<I.Map<
    FeatureId,
    string | null
  > | null>(null);

  // If in drawMode, set defaults on the names here to be able to share name based grouping logic
  const [nameField, setNameField] = useStateWithDeps<string | null>(
    mode === 'draw' || mode === 'parcel' ? 'name' : null,
    [mode]
  );
  const [partNameField, setPartNameField] = useStateWithDeps<string | null>(
    mode === 'draw' || mode === 'parcel' ? 'partName' : null,
    [mode]
  );
  const [nameValues, setNameValues] = useStateWithDeps(
    // in overlays mode, if we don't have a defined nameField, we want to pass a list of empty
    // strings back as our feature nameValues. features with names that are empty strings will
    // not have a name displayed on hover.
    // in all other modes, a nameField must be selected in order to set nameValues.
    uploadedFeatureCollection && (nameField || mode === 'overlay')
      ? I.Map<FeatureId, string>(
          uploadedFeatureCollection.features.map((feature) => [
            feature.id,
            nameField ? feature.properties![nameField] || '' : '',
          ])
        )
      : null,
    [nameField, uploadedFeatureCollection]
  );

  //TODO(eva): it would be good to maintain selectedLayerKeys/selectedOverlayIds as one list.
  //once we have the notion of a shared libraryKey this would be good to tackle.
  const [selectedLayerKeys, setSelectedLayerKeys] = useStateWithDeps<string[]>(
    createLayerKeysFromLayerObject(organization.get('defaultLayersBySource').toJS()),
    [existingFeatureCollection]
  );
  const [selectedOverlayIds, setSelectedOverlayIds] = React.useState<number[]>([]);

  // All of the uploadable features that do not have problems listed
  // and have a non empty name for the nameField selected
  const allUploadableFeatureIds = React.useMemo(() => {
    return I.Set<FeatureId>(
      uploadedFeatureCollection?.features.reduce((selected: number[], f) => {
        // If there is no nameField selected yet, we default to true to allow
        // all features to appear on the map initally. After a nameFiled is selected, then
        // it will be used to filter out blank name values
        if (
          f.properties &&
          !f.properties['__UPSTREAM_PROBLEMS'] &&
          (nameField ? f.properties[nameField] !== null : true)
        ) {
          selected.push(f.id);
        }
        return selected;
      }, []) ?? []
    );
  }, [uploadedFeatureCollection, nameField]);
  // Initially select all valid uploadable featureIds
  const [selectedFeatureIds, setSelectedFeatureIds] = React.useState<I.Set<number>>(I.Set([]));
  React.useEffect(() => {
    setSelectedFeatureIds(allUploadableFeatureIds);
  }, [allUploadableFeatureIds, setSelectedFeatureIds]);

  // Filter out any features with empty nameValues from the selectedFeatureIds list, unless we
  // are in overlays mode (it's ok to upload features with empty names in overlays mode)
  React.useEffect(() => {
    if (nameValues && mode !== 'overlay') {
      setSelectedFeatureIds((selectedFeatureIdsState) =>
        selectedFeatureIdsState.filter((fId) => !!nameValues.get(fId!)).toSet()
      );
    }
  }, [nameValues, setSelectedFeatureIds, mode]);

  const areaUnit = getAreaUnit(organization);

  React.useEffect(() => {
    if (project) {
      setPortfolioName(project.get('name'));
    }
  }, [project]);

  // TODO (Maya): This needs to be expanded upon.
  // Will include geometry warnings potentially as well. But there are assumptions made currently that it is
  // only for name collisions. If it is modified, those checks will need to be changed as well
  // Right now this only is checking for names of new features matching the new features
  // matching the names of pre-existing features.
  const warningsMap: Record<string, string | null> = React.useMemo(() => {
    if (!uploadedFeatureCollection || !existingFeatureCollection || !nameValues) {
      return {};
    }

    const existingPropertyNames: I.Set<string> = I.Set(
      existingFeatureCollection.get('features').map((f) => f?.getIn(['properties', 'name']))
    );

    const selectedFeatures = uploadedFeatureCollection.features.filter((f) =>
      selectedFeatureIds.get(f.id)
    );

    return selectedFeatures?.reduce((warningsMap, newFeature) => {
      const newFeatureName: string = nameValues.get(newFeature!.id);
      const newFeatureId: string = newFeature!.id?.toString() ?? '';
      const match = existingPropertyNames.get(newFeatureName);
      return {
        ...warningsMap,
        [newFeatureId]: match
          ? ['Property name matches a feature already existing in your portfolio']
          : null,
      };
    }, {});
  }, [uploadedFeatureCollection, existingFeatureCollection, nameValues, selectedFeatureIds]);

  // Pre-compute map of name: number of instances. If the value is greater than 1
  // then we are dealing with a multi location property and will need a partName.
  const nameInstancesMap = React.useMemo(
    () => nameValues?.filter((_, key) => selectedFeatureIds.has(key!))?.countBy((n) => n!),
    [nameValues, selectedFeatureIds]
  );

  // NOTE: When the groupProperties toggle changes, we need to update the nameValues.
  // When the toggle is set to individual, we take whatever the current partNameValues
  // are and append them to the current nameValues, preserving the edits to the fields the users
  // have made.
  // The not great thing that happens here is when a user switches back to group from indivdual,
  // we are not able to keep any potential edits users have made and reset the nameValues to the
  // nameField values in the uploadedFeatureCollection.
  // Although the reset isn't great behavior, it isn't expected to be a very common situation and it doesn't
  // prevent you from getting the names you want.
  // This was seperated into its own useEffect that only updates on groupProperties changing to avoid
  // having resets of user edits at any other time that would be more destructive and keep nameValues
  // from being directly dependent on partNameValues.
  React.useEffect(() => {
    if (!nameField || !uploadedFeatureCollection) {
      return;
    }
    if (groupProperties === 'individual') {
      setNameValues(
        nameValues
          ?.map((value, key) => {
            const partNameValue = partNameValues?.get(key!);
            return partNameValue ? value + ' - ' + partNameValue : value || '';
          })
          .toMap() || null
      );
    } else {
      setNameValues(
        I.Map<FeatureId, string>(
          uploadedFeatureCollection.features.map((feature) => [
            feature.id,
            feature.properties![nameField] || '',
          ])
        )
      );
    }
  }, [groupProperties]);

  // Updating partNameValues must happen after the groupProperties useEffect above so
  // it has access to the values before they get modified here
  //
  // TODO(maya): pull nameInstancesMap, warningsMap, and nameValues out of this useEffect into their own
  // useEffect to update partNameValues without overwriting the edits users have made. Currently
  // these all cause resets to the defaults which isn't great
  React.useEffect(() => {
    if (
      nameValues &&
      partNameField &&
      uploadedFeatureCollection &&
      groupProperties !== 'individual' &&
      nameInstancesMap
    ) {
      setPartNameValues(
        I.Map<FeatureId, string | null>(
          uploadedFeatureCollection.features.map((feature) => [
            feature.id,
            nameInstancesMap.get(nameValues.get(feature.id)) > 1 || warningsMap[feature.id]
              ? feature.properties![partNameField]
              : '',
          ])
        )
      );
    } else {
      setPartNameValues(null);
    }
  }, [
    uploadedFeatureCollection,
    groupProperties,
    partNameField,
    nameInstancesMap,
    warningsMap,
    nameValues,
  ]);

  const PROCESSING_ERROR_TEXT = 'Processing error, please try again.';

  const convertFile = async (file: File, mode?: ManagePropertiesMode) => {
    try {
      setProblems(null);
      setIsConvertingFile(true);

      const {problems, featureCollection, gcs_shapefile_path}: ApiLensConvertFeatureFile = (
        mode === 'overlay'
          ? await api.lens.uploader.convertOverlay(file)
          : await api.lens.uploader.convertFile(file)
      ).toJS();

      if (problems?.length) {
        setProblems(problems);
        return;
      }

      // If no features are present in the featureCollection, flag as error to the user and prevent
      // from proceeding onwards.
      if (!featureCollection.features.length) {
        setProblems(['No features were found in this file.']);
        return;
      }

      // Endpoint returns features with ids starting at 0. Add 1 to all ids to eliminate risk of a 0 id.
      const safeIdFeatureCollection: NumberedFeatureCollection = {
        ...featureCollection,
        features: featureCollection.features.map((f) => {
          // Need to cast this id to a number since the featureCollection json response we are getting from
          // the api encodes this number as a string. Then we can safely treat this ID as a number from
          // this point onwards
          const feature = {...f, id: Number(f.id) + 1};
          return feature;
        }),
      };

      setUploadedFeatureCollection(safeIdFeatureCollection);
      setFilePath(gcs_shapefile_path ?? null);

      // Return filepath for use in draw properties workflow. Cannot rely on setState to set
      // filepath in time when uploadFeatures is called immediately afterwards.
      return gcs_shapefile_path;
    } catch (e) {
      const apiError = e as ApiResponseError;
      setProblems(
        //errors caught here could either be intentionally handled problems, or unexpected exceptions
        //hence this type vagueness on what error is coming back from the API
        apiError.body?.problems || [apiError.error.message || PROCESSING_ERROR_TEXT]
      );
      // We are displaying these problems to the user and not letting them proceed onwards. Not logging
      // these errors in Sentry because it may just become noise and displaying an error here
      // is things working as intended.
    } finally {
      setIsConvertingFile(false);
    }
  };

  const uploadFeatures = async (drawnFilePath?: string) => {
    try {
      setIsUploading(true);

      // Must subtract 1 here because when this featureCollection was created 1 was added
      // to all ids to eliminate risk of a 0 id
      const featureIdsToExclude =
        uploadedFeatureCollection?.features
          .filter((f) => !selectedFeatureIds.has(f.id))
          .map((f) => f.id - 1) ?? [];

      const gcsFilePath = drawnFilePath || filePath;
      // a user does not need nameValues in overlay mode
      if (gcsFilePath && (nameValues || mode === 'overlay')) {
        let featureCollectionId: number | undefined = existingFeatureCollection
          ? existingFeatureCollection.get('id')
          : undefined;

        // Must also subtract 1 here because when this featureCollection was created 1 was added
        // to all ids to eliminate risk of a 0 id
        const nameEdits = nameValues?.mapKeys((k) => k! - 1).toJS() || {};
        const partNameEdits = partNameValues?.mapKeys((k) => k! - 1).toJS();

        // if we are in overlay mode, create the featureCollection without creating a project
        if (mode === 'overlay') {
          // Kick off the overlay upload
          await api.featureCollections.uploadOverlay(
            portfolioName!, //portfolioName will be the overlay's name
            gcsFilePath,
            nameEdits,
            featureIdsToExclude
          );

          recordEvent('Uploaded overlay from file', {
            name: portfolioName!,
            featureCount: selectedFeatureIds.size,
          });

          //return to overlays settings, where they will be able to do stuff with the overlay
          //TODO(eva) might be good to have a toast here saying that processing may take a few min
          history.push(`/${getOrgPrefix(organization)}/settings/overlays`);

          // if we are not in overlay mode, assume we should be creating a project and associating
          // a featureCollection to it
        } else {
          // If an existing fc wansn't specified, create the portfolio and featureCollection
          if (!featureCollectionId) {
            featureCollectionId = await projectsActions.createProject(
              organization,
              portfolioName ? portfolioName : 'New Portfolio',
              createLayerObjectFromLayerKeys(selectedLayerKeys),
              selectedOverlayIds
            );

            if (!featureCollectionId) {
              setFeatureCollectionErrors([PROCESSING_ERROR_TEXT]);
              Sentry.captureException(
                new Error('Error creating project and feature collection in uploader')
              );
              return;
            }
          }

          // Kick off the feature upload
          await api.featureCollections.uploadFeaturesFromFile(
            featureCollectionId,
            gcsFilePath,
            nameEdits,
            partNameEdits,
            featureIdsToExclude,
            mode ?? 'upload' // for now assume upload if we are not explicitly in draw mode
          );

          recordEvent(
            drawnFilePath ? 'Created properties from drawing' : 'Uploaded properties from file',
            {
              projectId: projectId ?? '',
              featureCount: selectedFeatureIds.size,
            }
          );

          // Clear cached featureCollection and refetch so correct processing
          // status is reflected immediately
          await projectsActions.refreshFeatureCollection(featureCollectionId);
          const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));

          let pathToNavigateTo = `/${orgIdPrefix}/projects`;
          if (project) {
            pathToNavigateTo += `/${routeUtils.makeUuidPrefix(project.get('id'))}`;
            pushNotification({
              message: 'It may take a few moments for newly added properties to appear',
              autoHideDuration: 10000,
            });
          }

          history.push(pathToNavigateTo);
        }
      }
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
      setFeatureCollectionErrors(
        //errors caught here could either be intentionally handled problems, or unexpected exceptions
        //hence this type vagueness on what error is coming back from the API
        (e as any)?.body?.problems || [(e as any)?.error?.message || PROCESSING_ERROR_TEXT]
      );
      console.error(e);
      Sentry.captureException(e, {
        extra: {
          filePath: filePath,
          nameField,
          partNameField,
        },
      });
    }
  };

  // Upload features in draw mode. Utilizes the pre-existing endpoints for file
  // uploads by writing the drawn features to a file, uploading that file, then
  // sending the relevant info to the uploadFeaturesFromFile endpoint
  const uploadDrawnFeatures = async () => {
    if (uploadedFeatureCollection) {
      setIsUploading(true);
      const JSONstring = JSON.stringify(uploadedFeatureCollection);
      const fileObject: File = new File([JSONstring], 'drawnFeatures.geojson', {
        type: 'application/json',
      });

      try {
        const uploadedFilePath = await convertFile(fileObject);
        await uploadFeatures(uploadedFilePath);
      } catch (e) {
        setIsUploading(false);
        console.error(e);
      }
    }
  };

  const manageFeaturesState: ManageFeaturesState = {
    filename,
    uploadedFeatureCollection,
    isConvertingFile,
    nameField,
    nameValues,
    partNameField,
    partNameValues,
    selectedFeature,
    groupProperties,
    problems,
    isUploading,
    selectedFeatureIds,
    areaUnit,
    featureCollectionErrors,
    allUploadableFeatureIds,
    existingFeatureCollection,
    portfolioName,
    warningsMap,
    selectedLayerKeys,
    selectedOverlayIds,
  };

  const manageFeaturesActions: ManageFeaturesActions = {
    setFilename,
    setUploadedFeatureCollection,
    setFilePath,
    setIsConvertingFile,
    setNameField,
    setNameValues,
    setPartNameField,
    setPartNameValues,
    setSelectedFeature,
    convertFile,
    uploadFeatures,
    uploadDrawnFeatures,
    setGroupProperties,
    setSelectedFeatureIds,
    setProblems,
    setFeatureCollectionErrors,
    setPortfolioName,
    setSelectedLayerKeys,
    setSelectedOverlayIds,
  };

  const value = React.useMemo(
    (): ManageFeaturesContextValue => ({
      manageFeaturesActions: manageFeaturesActions,
      manageFeaturesState: manageFeaturesState,
      loading: meta.loading,
    }),
    [manageFeaturesActions, manageFeaturesState, meta.loading]
  );

  // Return values: either a redirect if an existingFeatureCollection can't be found with a project,
  // or provide the context.
  const pushNotification = usePushNotification();
  if (projectId && !existingFeatureCollection && !meta.loading) {
    pushNotification({
      message:
        'There was a problem loading that Portfolio. Ensure you are signed in to the correct organization.',
      autoHideDuration: 4000,
    });

    // The only case I can think of for this is if they somehow clicked on an uploader link for an org they don't have access to
    const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));
    return <Redirect to={`/${orgIdPrefix}/projects`} />;
  }

  return <ManageFeaturesContext.Provider value={value}>{children}</ManageFeaturesContext.Provider>;
};

export function useManageFeatures(): ManageFeaturesContextValue {
  const value = React.useContext(ManageFeaturesContext);

  if (!value) {
    throw new Error('useManageFeatures must be beneath a ManageFeaturesProvider');
  }

  return value;
}

export const FakeManageFeaturesProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    manageFeaturesActions: ManageFeaturesActions;
    manageFeaturesState: ManageFeaturesState;
    loading: boolean;
  }>
> = ({children, manageFeaturesActions, manageFeaturesState, loading}) => {
  const value = React.useMemo(
    (): ManageFeaturesContextValue => ({
      manageFeaturesActions: manageFeaturesActions,
      manageFeaturesState: manageFeaturesState,
      loading: loading,
    }),
    [manageFeaturesActions, manageFeaturesState, loading]
  );

  return <ManageFeaturesContext.Provider value={value}>{children}</ManageFeaturesContext.Provider>;
};

export default ManageFeaturesProvider;
