import mbxClient from '@mapbox/mapbox-sdk';
import mbxStatic from '@mapbox/mapbox-sdk/services/static';
import * as I from 'immutable';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, {CSSProperties} from 'react';

import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {getBboxFromBounds} from 'app/utils/featureUtils';
import * as mapUtils from 'app/utils/mapUtils';

const baseClient = mbxClient({accessToken: mapUtils.MAPBOX_ACCESS_TOKEN});
const staticClient = mbxStatic(baseClient);

// Mapbox styles need to be public in order to use them with the Static API.
// Public style URLs can be used by any Mapbox user with their access token.
// This doesn‘t seem like it would be an issue at the moment, but we err on the
// side of using an out-of-the-box map style from Mapbox so we can keep our
// styles private in case we ever update them include proprierty information
// like custom dataset layers.
const STATIC_IMAGE_OWNER_ID = 'mapbox';
const STATIC_IMAGE_STYLE_ID = 'satellite-streets-v12';

export interface Props {
  featureCollection?: I.ImmutableOf<ApiFeatureCollection>;
  height: number;
  width: number;
  style: CSSProperties;
}

export type DefaultProps = Pick<Props, 'height' | 'width' | 'style'>;

class StaticMap extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    height: 400,
    width: 400,
    style: {},
  };

  render() {
    const {featureCollection, height, width, style} = this.props;

    // If we have the bounds of a featureCollection, use that to generate the
    // static map. If not set position to auto and mapbox will generate a default
    // map
    const bounds = featureCollection?.get('bounds');
    const bboxBounds = bounds && getBboxFromBounds(bounds as any);
    const position = bboxBounds ? {bbox: bboxBounds} : 'auto';

    const staticImageUrl = staticClient
      .getStaticImage({
        ownerId: STATIC_IMAGE_OWNER_ID,
        styleId: STATIC_IMAGE_STYLE_ID,
        width,
        height,
        position,
        logo: false,
        attribution: false,
      })
      .url();

    return (
      <div>
        <img
          height={height}
          width={width}
          src={staticImageUrl}
          style={{
            display: 'block',
            ...style,
          }}
        />
      </div>
    );
  }
}

export default StaticMap;
