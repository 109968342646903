import * as B from '@blueprintjs/core';
import * as S from '@blueprintjs/select';
import classnames from 'classnames';
import matchSorter from 'match-sorter';
import React from 'react';

import List, {ListItem, Props as ListProps} from 'app/components/List';
import {useContinuity} from 'app/utils/hookUtils';

import cs from './styles.styl';

// The text of FilterableList items _must_ be a string so that we can search on
// it and so that we can use it to uniquely identify items even if their object
// values change.
type Props<E> = ListProps<E, string> & {
  onClickItem: (
    item: ListItem<E, string>,

    // Event may be triggered either by pressing `Enter` button or by clicking
    // the menu item
    event: React.SyntheticEvent | React.MouseEvent | undefined
  ) => unknown;

  className?: string;
  listClassName?: string;
  isSearchOnBottom?: boolean;
};

const firstSelectedItemText = <E extends unknown>(items: ListItem<E, string>[]) =>
  items.find(({isSelected}) => !!isSelected)?.text ?? null;

const itemsEqual = <E extends unknown>(a: ListItem<E, string>, b: ListItem<E, string>) =>
  a.text === b.text;

/**
 * Creates a query box and list, where typing into the query box searches the
 * list with Fuse.
 *
 * ## Test Plan
 *
 * _Notes:_ Always make sure to test the up/down arrows because they are based
 * on QueryList’s internal sense of the selected element, which can — when there
 * are bugs — be different from the visually selected element.
 *
 * 1. Open feature list dropdown when no particular feature is active. Should
 *    highlight “View all.” Query box should have focus.
 * 1. Pressing up / down arrows should change the selected element.
 * 1. Starting a search should filter / reorder the elements and the selection
 *    should jump to the top of the list.
 * 1. Pressing up / down will move through the filtered list.
 * 1. Pressing enter should cause the UI to jump to the selected feature. The
 *    element should stay selected. The query should not change.
 * 1. Pressing up / down should change the selected element.
 * 1. Pressing escape should close the dropdown
 * 1. Opening the dropdown with a feature selected on the map should show it
 *    selected in the dropdown. Up and down arrows should work.
 * 1. Clicking the "X" button when there’s a query should clear the query,
 *    restore the full list of items, select the first, and the search box
 *    should maintain keyboard focus so that up/down still work.
 */
const FilterableList = function <E>({
  items,
  onClickItem,
  className,
  listClassName,
  isSearchOnBottom,
  ...listProps
}: Props<E>) {
  // Keeps us from recreating renderItemList every render loop.
  listProps = useContinuity(listProps);

  // We keep our own state and have it control QueryList, rather than let
  // QueryList keep track of it, so that we can clear the query via the "X"
  // button, and also set a default activeItem when the component opens up.
  //
  // We use the text rather than the item itself to better handle when objects
  // change.
  const [activeItemText, setActiveItemText] = React.useState<string | null>(null);

  React.useEffect(() => {
    setActiveItemText(firstSelectedItemText(items));
  }, [items]);

  const [query, setQuery] = React.useState('');
  const queryFieldRef = React.useRef<HTMLInputElement>();

  const onQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  }, []);

  // This callback has to be a stable value or else QueryList will infinite loop
  // in its componentDidUpdate, since it tries to check and see if it changed.
  const itemListPredicate = React.useCallback(() => {
    if (query === '') {
      return items;
    }

    // Match if item contains query
    const conainsStringItems = matchSorter(items, query, {
      keys: ['text'],
      threshold: matchSorter.rankings.CONTAINS,
    });

    // Match if item contains acronym query
    const acronymItems = matchSorter(items, query, {
      keys: ['text'],
      threshold: matchSorter.rankings.ACRONYM,
    });

    // Create set from both matched items
    const filteredItems = new Set([...conainsStringItems, ...acronymItems]);

    // Keeps the items in the same sort order as they originally had.
    return items.filter((i) => filteredItems.has(i));
  }, [items, query]);

  const renderQueryListContents = React.useCallback(
    ({
      query,
      handleKeyDown,
      handleKeyUp,
      itemList,
    }: S.QueryListRendererProps<ListItem<E, string>>) => (
      <>
        <div className={classnames(className, cs.container)}>
          {isSearchOnBottom ? itemList : null}

          <div
            className={classnames(cs.filterContainer, {
              [cs.filterContainerTop]: !isSearchOnBottom,
              [cs.filterContainerBottom]: isSearchOnBottom,
            })}
          >
            <B.InputGroup
              autoFocus={true}
              leftIcon="search"
              placeholder="Search…"
              dir="auto"
              // "as any" because the type is for the callback format but it
              // accepts new-style refs as well.
              inputRef={queryFieldRef as any}
              value={query}
              onChange={onQueryChange}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              rightElement={
                query.length ? (
                  <B.Button
                    minimal={true}
                    icon="delete"
                    onClick={() => {
                      setQuery('');

                      // We need to re-focus to keep keyboard events going
                      // through the input group.
                      if (queryFieldRef.current) {
                        queryFieldRef.current.focus();
                      }
                    }}
                  />
                ) : undefined
              }
            />
          </div>
          {!isSearchOnBottom ? itemList : null}
        </div>
      </>
    ),
    [className, isSearchOnBottom, onQueryChange]
  );

  const renderItemList = React.useCallback<S.ItemListRenderer<ListItem<E, string>>>(
    ({filteredItems, activeItem}) => {
      return (
        <div
          className={classnames(cs.listContainer, {
            [cs.listContainerTop]: isSearchOnBottom,
            [cs.listContainerBottom]: !isSearchOnBottom,
          })}
        >
          <List
            {...listProps}
            className={listClassName}
            onClickItem={onClickItem}
            items={filteredItems.map((item) => {
              const isSelected =
                !!activeItem && itemsEqual(item, activeItem as ListItem<E, string>);

              // optimize for not creating idential new hash objects.
              if (!!item.isSelected === isSelected) {
                return item;
              } else {
                return {
                  ...item,
                  isSelected,
                };
              }
            })}
          />
        </div>
      );
    },
    [listClassName, isSearchOnBottom, onClickItem, listProps]
  );

  const activeItem = items.find(({text}) => text === activeItemText) || null;

  return (
    <S.QueryList<ListItem<E, string>>
      query={query}
      items={items}
      activeItem={activeItem}
      itemListPredicate={itemListPredicate}
      itemsEqual={itemsEqual}
      // Since we’re using itemListRenderer, we don’t need this.
      itemRenderer={() => null}
      itemListRenderer={renderItemList}
      onItemSelect={onClickItem}
      renderer={renderQueryListContents}
    />
  );
};

export default FilterableList;
