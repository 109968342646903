import * as B from '@blueprintjs/core';
import {Geocoder} from '@mapbox/search-js-react';
import * as geojson from 'geojson';
import isEqual from 'lodash/isEqual';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {BBox2d} from 'app/utils/geoJsonUtils';
import {PortalControl, safeFitBounds} from 'app/utils/mapUtils';

import MapContent from './MapContent';
import * as cs from './ZoomCenterControl.styl';

/**
 * Custom navigation control with zoom in/zoom out/center on feature buttons.
 *
 * Has some mechanisms to try and gray out "center on feature" when the map is
 * already in that position. Unfortunately, due to Mapbox’s lack of a
 * calculateMapBoundsFromFit kind of function, we have to awkwardly listen to
 * zoomend events generated by GeoJsonFitter to know when the map has come to
 * rest on the feature so we can store the map’s bounds.
 */
const ZoomCenterControl: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    featureBounds: BBox2d | null;
    zoomToGeometry?: (geometry: geojson.GeoJSON | null) => void;
    fitBoundsOptions?: mapboxgl.FitBoundsOptions;
    zoomToFitButtonTooltip?: string | JSX.Element;
  }>
> = ({
  map,
  isMapLoaded,
  featureBounds,
  fitBoundsOptions,
  zoomToGeometry,
  zoomToFitButtonTooltip = 'Zoom to fit property',
}) => {
  const zoomControlEl = React.useRef(document.createElement('div'));
  const zoomControl = React.useMemo(
    () => new PortalControl(zoomControlEl.current),
    [zoomControlEl]
  );

  // We duplicate map values into state so that they can affect the enabling /
  // disabling of the buttons.
  const [minZoom, setMinZoom] = React.useState(map.getMinZoom());
  const [maxZoom, setMaxZoom] = React.useState(map.getMaxZoom());
  const [currentZoom, setCurrentZoom] = React.useState(map.getZoom());
  const [currentMapBounds, setCurrentMapBounds] = React.useState(safeGetBounds(map));

  const [featureLastMapBounds, setFeatureLastMapBounds] = React.useState<
    [number, number, number, number] | null
  >(null);

  // We need to consider padding so that if you open a sidebar or popup (which
  // lead to a change in padding) that we offer you recentering.
  const [featureLastPadding, setFeatureLastPadding] = React.useState<
    number | mapboxgl.PaddingOptions | null
  >(null);

  React.useEffect(() => {
    const updateZoomState = () => {
      setMinZoom(map.getMinZoom());
      setMaxZoom(map.getMaxZoom());
      setCurrentZoom(map.getZoom());
    };

    // "any" because EventData was merged in
    const updateLatestBounds = (ev: any) => {
      if (ev.source === 'GeoJsonFitter' || ev.source === 'ZoomCenterControl') {
        // If the user zoomed to a position that matched our feature bounds, we
        // save the bounds that the map ended up on.
        //
        // We can use isEqual here since the calculation of these bounds is just
        // min/max of values in the Feature(s) and so won’t be affected by
        // floating point rounding.
        if (isEqual(ev.bounds, featureBounds)) {
          setFeatureLastMapBounds(safeGetBounds(map));
          setFeatureLastPadding(ev.padding || null);
        }
      }

      setCurrentMapBounds(safeGetBounds(map));
    };

    map.on('zoom', updateZoomState);
    map.on('moveend', updateLatestBounds);

    return () => {
      map.off('zoom', updateZoomState);
      map.off('moveend', updateLatestBounds);
    };
  }, [map, featureBounds]);

  const centerOnFeature = () => {
    if (featureBounds) {
      safeFitBounds(map, featureBounds, fitBoundsOptions, {
        source: 'ZoomCenterControl',
        bounds: featureBounds,
        padding: fitBoundsOptions?.padding,
      });
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <B.ButtonGroup vertical style={{alignItems: 'flex-end'}}>
          <B.Tooltip content={'Zoom in'} position="left">
            <B.AnchorButton
              disabled={currentZoom >= maxZoom}
              onClick={() => {
                map.zoomIn();
              }}
              icon="zoom-in"
              className={cs.btn}
            />
          </B.Tooltip>
          <B.Tooltip content={'Zoom out'} position="left">
            <B.AnchorButton
              disabled={currentZoom <= minZoom}
              onClick={() => {
                map.zoomOut();
              }}
              icon="zoom-out"
              className={cs.btn}
            />
          </B.Tooltip>
          <B.Tooltip content={zoomToFitButtonTooltip} position="left">
            <B.AnchorButton
              disabled={
                featureBounds === null ||
                (isEqual(fitBoundsOptions?.padding || null, featureLastPadding) &&
                  isEqual(currentMapBounds, featureLastMapBounds))
              }
              onClick={centerOnFeature}
              icon="zoom-to-fit"
              className={cs.btn}
            />
          </B.Tooltip>
          {zoomToGeometry && <MapGeocoderControl map={map} zoomToGeometry={zoomToGeometry} />}
        </B.ButtonGroup>,
        zoomControlEl.current
      )}

      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[zoomControl]}
        controlPosition="top-right"
      />
    </>
  );
};

export default ZoomCenterControl;

function roundN(n: number, places: number) {
  const scale = Math.pow(10, places);
  return Math.round(n * scale) / scale;
}

/**
 * Takes a MapboxGL LngLatBounds object and converts it to the literal format
 * [W, S, E, N] and rounds all values to 5 decimal places.
 *
 * Important so that we can compare bounds, which are not going to be perfectly
 * consistent after e.g. zooming in one level and zooming out one level.
 */
function truncateMapBounds(b: mapboxgl.LngLatBounds): [number, number, number, number] {
  const precision = 5;
  const [[w, s], [e, n]] = b.toArray();
  return [roundN(w, precision), roundN(s, precision), roundN(e, precision), roundN(n, precision)];
}

/**
 * A function that wraps the mapbox-gl map.getBounds method in a try/catch
 * statement, and converts the bounds into a literal, rounded format. This
 * prevents internal mapbox-gl-js errors from bubbling up and crashing the
 * application.
 *
 * This function is recommended when setTerrain is also acting on the map since
 * "Invalid LngLat object: (NaN, NaN)" errors have been observed when the style
 * terrain is still being set.
 */
function safeGetBounds(map: mapboxgl.Map) {
  try {
    const bounds = map.getBounds();
    return truncateMapBounds(bounds);
  } catch (_error) {
    // If the fitBounds method invocation throws an error, return null so that
    // consumers know the method errored and can handle appropriately.
    return null;
  }
}

const MapGeocoderControl: React.FunctionComponent<{
  map: mapboxgl.Map;
  zoomToGeometry: (geometry: geojson.GeoJSON | null) => void;
}> = ({map, zoomToGeometry}) => {
  const [searchControlOpen, setSearchControlOpen] = React.useState(false);

  return (
    <div style={{display: 'flex'}}>
      {searchControlOpen && (
        <div className={cs.geocoderControl}>
          <Geocoder
            accessToken={mapboxgl.accessToken}
            map={map}
            mapboxgl={mapboxgl}
            marker={false}
            popoverOptions={{flip: true}}
            placeholder="Location search"
            onRetrieve={(r) => {
              zoomToGeometry(r.geometry);
              setSearchControlOpen(false);
            }}
          />
        </div>
      )}
      <B.Tooltip content={'Location search'} position="left" disabled={searchControlOpen}>
        <B.AnchorButton
          onClick={() => setSearchControlOpen(!searchControlOpen)}
          icon="geosearch"
          className={cs.btn}
          active={searchControlOpen}
        />
      </B.Tooltip>
    </div>
  );
};
