import * as B from '@blueprintjs/core';
import * as geojson from 'geojson';
import * as I from 'immutable';
import React from 'react';

import AnalyzePolygonChart from 'app/components/AnalyzePolygonChart/AnalyzePolygonChart';
import {GraphWithTimeRange, NoteGraph} from 'app/components/AnalyzePolygonChart/types';
import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiOrganization, getAreaUnit} from 'app/modules/Remote/Organization';
import {getDataRange} from 'app/providers/MapPolygonStateProvider';
import {StateApiNote} from 'app/stores/NotesStore';
import {TileData, getFeatureDataHasThumbnailUrls} from 'app/stores/RasterCalculationStore';
import {UTMArea} from 'app/utils/geoJsonUtils';
import {StatusMaybe} from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';
import {getNoteAreaInM2} from 'app/utils/noteUtils';

import {defaultOverlayThresholdForDataRange} from './AnalyzePolygonPopup';
import {useCalculatorData, useRasterTimeSeriesCalculator} from './AnalyzePolygonPopupWithState';
import {calculateDataPoints, createLoadingProps, fetchTileData} from './utils';
import {
  parseGraphDatesAsIsoStrings,
  parseGraphIsoStringsAsDates,
} from '../AnalyzePolygonChart/utils';

// This is an internal-only tool used for debugging AA issues. In a situation where a bug in AA
// led to an incorrect chart being created, this tool can be used to rerun the analysis with the
// same parameters as the saved chart (ie, same date range, chart type, etc). You can then view the
// old chart and new chart side by side, then save the new chart. No other field is updated.
const RerunChartAnalysisTool: React.FunctionComponent<
  React.PropsWithChildren<{
    featureData: I.ImmutableListOf<ApiFeatureData>;
    isPosting: boolean;
    firebaseToken: string;
    organization: I.ImmutableOf<ApiOrganization>;
    note: StateApiNote;
    saveReanalyzedNoteGraph: (newGraph: NoteGraph) => void;
  }>
> = ({firebaseToken, featureData, organization, note, isPosting, saveReanalyzedNoteGraph}) => {
  // Convert the existing NoteGraph into a Graph (with Dates instead of IsoStrings).
  const existingGraph: GraphWithTimeRange = React.useMemo(
    () => parseGraphIsoStringsAsDates(note.graph!),
    [note]
  );

  // Use existing note's polygon and graph's layerKey to fetch tileData and hasThumbnailUrls
  const polygon = note!.geometry as geojson.Polygon;
  const areaInM2 = React.useMemo(() => (polygon ? UTMArea(polygon) : 0), [polygon]);

  const tileData: StatusMaybe<TileData> = React.useMemo(() => {
    return fetchTileData(existingGraph.layerKey, polygon, featureData);
  }, [existingGraph, polygon, featureData]);

  const hasThumbnailUrls = React.useMemo(
    () =>
      !!existingGraph.layerKey &&
      getFeatureDataHasThumbnailUrls(
        featureData!,
        layerUtils.getRawLayerKey(existingGraph.layerKey)
      ),
    [featureData, existingGraph]
  );

  // Reconstruct information about the existing graph that's not stored on the graph.
  const earliestGraphDate = existingGraph.graphTimeRange[0];
  const layer = layerUtils.getLayer(existingGraph.layerKey);
  const overlayThreshold =
    existingGraph.dataThreshold ?? defaultOverlayThresholdForDataRange(getDataRange(layer));

  // Re-calculate the AA calculators using the existing graph's features
  const [rasterTimeSeriesCalculator, loadingProgress] = useRasterTimeSeriesCalculator(
    firebaseToken,
    featureData,
    existingGraph.layerKey,
    true,
    existingGraph.graphRange,
    tileData,
    hasThumbnailUrls,
    earliestGraphDate
  );

  // Re-calculate the graphData using the rasterTimeSeriesCalculator output
  const graphData = useCalculatorData(
    overlayThreshold,
    polygon,
    rasterTimeSeriesCalculator,
    loadingProgress
  );

  const reconstructedGraph: GraphWithTimeRange | null =
    React.useMemo<GraphWithTimeRange | null>(() => {
      // Re-construct the existing graph using the newly constructed timeseries
      const dataPoints = calculateDataPoints(
        graphData,
        existingGraph.graphMode,
        existingGraph.layerKey,
        areaInM2
      );

      return {
        ...existingGraph,
        dataPoints: dataPoints,
      };
    }, [areaInM2, existingGraph, graphData]);

  // Loading props to show a spinner over the graph.
  const loadingProps = React.useMemo(() => createLoadingProps(graphData), [graphData]);

  return reconstructedGraph ? (
    <>
      New, updated graph (non-interactive, for visual comparison only):
      <AnalyzePolygonChart
        graph={reconstructedGraph}
        imageRefs={note.imageRefs}
        areaInM2={getNoteAreaInM2(note)}
        areaUnit={getAreaUnit(organization)}
        loading={loadingProps.loading}
        loadingStatus={loadingProps.loadingStatus}
        loadingProgress={loadingProps.loadingProgress}
        loadingIcon={loadingProps.loadingIcon}
        graphStyle={{height: 125}}
        isExpanded={false}
        disablePanZoom
        isLegendInteractive={false}
      />
      <B.Button
        intent="warning"
        small
        disabled={isPosting}
        text="Save new graph to note"
        onClick={() => {
          saveReanalyzedNoteGraph(parseGraphDatesAsIsoStrings(reconstructedGraph));
        }}
      />
      <B.Divider />
      Pre-Existing Graph:
    </>
  ) : (
    <></>
  );
};

export default RerunChartAnalysisTool;
