import * as B from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import {Column, useFlexLayout, useTable} from 'react-table';

import Modal from 'app/components/Modal/Modal';
import * as Remote from 'app/modules/Remote';
import {api} from 'app/modules/Remote';
import {
  ApiAccessSubscription,
  ApiDefaultPaymentMethod,
  ApiOrganization,
  ApiOrganizationUser,
  ApiPaidLayerInvoice,
  ApiPaidLayerInvoiceLineItem,
} from 'app/modules/Remote/Organization';
import {
  ApiDollarUnitUsage,
  ApiImageryContractWithProjects,
  ApiImageryPurchaseBillingRecord,
} from 'app/modules/Remote/Project';
import {useUpcomingInvoices} from 'app/providers/ImageryContractProvider';
import colors from 'app/styles/colors.json';
import * as CONSTANTS from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import * as costUtils from 'app/utils/costUtils';
import featureFlags, {useUserInfo} from 'app/utils/featureFlags';
import {StatusMaybe, useApiGet} from 'app/utils/hookUtils';
import * as imageryUtils from 'app/utils/imageryUtils';

import cs from './ContractHistory.styl';

const NA_TAG = (
  <B.Tag minimal round>
    N/A
  </B.Tag>
);

const contractHasAnyOrders = (contract: ApiImageryContractWithProjects) =>
  contract.contractType === 'acreUnit'
    ? contract.usage.purchased_acres || contract.usage.voided_acres
    : contract.usage.purchased_acres || contract.usage.voided_acres;

const appendBillingPeriodsToBillingRecords = async (
  billingRecords: ApiImageryPurchaseBillingRecord[],
  api: Pick<typeof Remote.api, 'organizations'>,
  organizationId: string
): Promise<
  (Remote.Project.ApiImageryPurchaseBillingRecord & {billingPeriod: imageryUtils.BillingPeriod})[]
> => {
  const invoices = (await api.organizations.invoices(organizationId)).toJS();

  const invoiceMap = invoices.reduce((acc, invoice) => {
    acc[invoice.id] = invoice;
    return acc;
  }, {});

  const purchasesWithBillingPeriods = billingRecords.map((billingRecord) => {
    const invoice = invoiceMap[billingRecord.invoiceId];
    if (
      !invoice ||
      billingRecord.invoiceId === 'unknown' ||
      !invoice.period_start ||
      !invoice.period_end
    ) {
      return {
        ...billingRecord,
        billingPeriod: {
          start: 'N/A',
          end: 'N/A',
        },
      };
    }
    const billingPeriodStart = moment.unix(invoice.period_start).format('MMM DD, YYYY');
    const billingPeriodEnd = moment.unix(invoice.period_end).format('MMM DD, YYYY');
    const billingPeriod = {
      start: billingPeriodStart,
      end: billingPeriodEnd,
    };
    return {...billingRecord, billingPeriod} as ApiImageryPurchaseBillingRecord & {
      billingPeriod: imageryUtils.BillingPeriod;
    };
  });

  return purchasesWithBillingPeriods;
};

/**
 * Component that takes care of loading state for access subscription, ultimately rendering
 * a box containing human-readable information about the current contract.
 */
export const PlanInformation: React.FunctionComponent<
  React.PropsWithChildren<{
    accessSubscriptionMaybe: StatusMaybe<ApiAccessSubscription>;
    hasEnded: (end: string | null) => boolean | '' | null;
    currentLensTier: 'lens-focus' | 'lens-plus' | 'lens-enterprise' | undefined;
    organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    cancelSubscription?: (
      organizationId: string,
      subscriptionId: string,
      comment?: string
    ) => Promise<void>;
    contractsMaybe: StatusMaybe<ApiImageryContractWithProjects[]>;
    organizationUsers: ApiOrganizationUser[] | null;
    projects: any;
  }>
> = ({
  accessSubscriptionMaybe,
  cancelSubscription,
  hasEnded,
  currentLensTier,
  organization,
  profile,
  contractsMaybe,
  organizationUsers,
  projects,
}) => {
  const lensTierLabel = {
    'lens-focus': 'Lens Lite',
    'lens-plus': 'Lens Standard',
    'lens-enterprise': 'Lens Enterprise',
  };

  const formattedRenewalDate = React.useMemo(() => {
    const accessSubscription = accessSubscriptionMaybe.value;
    if (accessSubscription && accessSubscription.endDate && !hasEnded(accessSubscription.endDate)) {
      // if there is a subscription with an endDate that has not yet ended, the subscription is active
      // and set to end on a defined date without autorenewing.
      return `Ends on ${moment(accessSubscription.endDate).format('MMMM D, YYYY')}`;
    } else if (
      // if there is a subscription with a currentPeriodEnd and no endDate, that subscription will auto-
      // renew on the currentPeriodEnd date.
      accessSubscription &&
      accessSubscription.currentPeriodEnd &&
      !accessSubscription.endDate
    ) {
      return `Automatically renews on ${moment(accessSubscription.currentPeriodEnd).format(
        'MMMM D, YYYY'
      )}`;
    } else {
      return '';
    }
  }, [accessSubscriptionMaybe.value, hasEnded]);

  const isLoading = accessSubscriptionMaybe.status === 'unknown';

  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

  const accessSubscription =
    accessSubscriptionMaybe.status === 'some' && accessSubscriptionMaybe.value;

  const contracts = contractsMaybe.status === 'some' ? contractsMaybe.value : [];

  return (
    <div className={cs.planInformationBox}>
      {accessSubscriptionMaybe.status === 'error' && (
        <div style={{marginBottom: '1rem'}}>We couldn't load your plan right now</div>
      )}

      {/** some organizations (like the demo org and ones with special trial contract situations)
       * have an accessTier without having a non-demo accessSubscription; we are displaying their access
       * tier regardless of if they have an accessSubscription or not.
       */}
      {currentLensTier && (
        <>
          <div className={cs.planInformationHeader}>Current Plan</div>
          <div className={classnames(cs.planName, isLoading ? 'bp5-skeleton' : '')}>
            {lensTierLabel[currentLensTier]}
          </div>
        </>
      )}

      {/** we need to check that the value has attributes, as we get back an empty object if no
       * accessSubscription is found*/}
      {!isLoading && accessSubscription && accessSubscription.lensLevel && (
        <div className={cs.planDate}>{formattedRenewalDate}</div>
      )}

      {isLoading && (
        <div className={cs.planDate}>
          <span className={'bp5-skeleton'}>Automatically renews on MMM, DD, YYYY</span>
        </div>
      )}

      {/** always render this link regardless of accessSubscription */}
      <a href="https://www.upstream.tech/lens/plans" target="_blank" rel="noopener noreferrer">
        See features and compare plans
      </a>
      {accessSubscription &&
        !accessSubscription.endDate &&
        accessSubscription.currentPeriodEnd &&
        !hasEnded(accessSubscription.currentPeriodEnd) &&
        featureFlags.SHOW_CANCELLATION_LINK(organization, profile, accessSubscription) &&
        cancelSubscription && (
          <>
            <div className={cs.divider} />
            <a onClick={() => setIsCancelModalOpen(true)}>Cancel subscription</a>
            <CancelSubscriptionModal
              cancelSubscription={cancelSubscription}
              subscription={accessSubscription}
              orgId={organization.get('id')}
              isOpen={isCancelModalOpen}
              onClose={() => setIsCancelModalOpen(false)}
              contracts={contracts}
              organizationUsers={organizationUsers}
              projects={projects}
            />
          </>
        )}
    </div>
  );
};

export const BillingInfo: React.FunctionComponent<
  React.PropsWithChildren<{
    organizationId: string;
    role: ApiOrganizationUser['role'];
  }>
> = ({organizationId, role}) => {
  const [isLoadingBillingSessionUrl, setIsLoadingBillingSessionUrl] = React.useState(false);
  const [billingSessionUrlError, setBillingSessionUrlError] = React.useState<null | string>(null);

  const [billingInfoMaybe] = useApiGet(
    async (organizationId) =>
      await (await api.organizations.getBillingInfo(organizationId)).get('data'),
    [organizationId]
  );

  const defaultPaymentMethod: ApiDefaultPaymentMethod | null = billingInfoMaybe.value?.toJS();
  const hasPermissions = role === CONSTANTS.USER_ROLE_OWNER;

  return (
    <div className={cs.planInformationBox}>
      <div className={cs.planInformationHeader}>Payment Method</div>
      {billingInfoMaybe.error ? (
        <div style={{marginBottom: '1rem'}}>We couldn't load your payment method right now</div>
      ) : (
        <>
          <div
            className={classnames(
              cs.planName,
              billingInfoMaybe.status === 'unknown' ? 'bp5-skeleton' : ''
            )}
          >
            {/* TEMP HACK: this check seems very redundant. Our types assume that the only defaultPaymentMethods
            are cards or bank accounts but a 3rd method snuck through. If that happens, just show the empty
            state for now while we are working on removing the 3rd option from existing */}
            {defaultPaymentMethod &&
            ('card' in defaultPaymentMethod || 'us_bank_account' in defaultPaymentMethod) ? (
              'card' in defaultPaymentMethod ? (
                <>
                  <span style={{textTransform: 'capitalize'}}>
                    {defaultPaymentMethod.card.brand}
                  </span>
                  <span>**** **** **** {defaultPaymentMethod.card.last4}</span>
                </>
              ) : (
                <span>Bank account ending in {defaultPaymentMethod.us_bank_account.last4}</span>
              )
            ) : (
              'No payment method specified'
            )}
          </div>

          <div className={classnames(billingInfoMaybe.status === 'unknown' ? 'bp5-skeleton' : '')}>
            <B.Tooltip disabled={hasPermissions} content={'Only admins can edit payment method'}>
              <div style={{display: 'flex'}}>
                <a
                  style={{pointerEvents: hasPermissions ? undefined : 'none'}}
                  onClick={async () => {
                    setIsLoadingBillingSessionUrl(true);
                    try {
                      // Must wait until click to fetch billingSession url becuase
                      // it expires after a few min
                      const response = await api.organizations.getBillingSession(
                        organizationId,
                        window.location.href
                      );
                      window.location.href = response.getIn(['data', 'url']);
                    } catch (e) {
                      const err = e as {
                        body: {error: string} | undefined;
                        error: Error;
                        status: number;
                      };
                      setBillingSessionUrlError(err.body?.error || '');
                      setIsLoadingBillingSessionUrl(false);
                    }
                  }}
                >
                  Update billing information
                </a>
                {isLoadingBillingSessionUrl && <B.Spinner size={14} />}
              </div>
            </B.Tooltip>
            {billingSessionUrlError && <div style={{color: 'red'}}>{billingSessionUrlError}</div>}
          </div>
        </>
      )}
    </div>
  );
};

function formatLineItem(lineItem: ApiPaidLayerInvoiceLineItem) {
  return (
    <li style={{display: 'flex', justifyContent: 'space-between'}}>
      <div>{lineItem.description}</div>
      <div>{costUtils.formatCost(lineItem.amount)}</div>
    </li>
  );
}

export const UpcomingInvoices: React.FunctionComponent<
  React.PropsWithChildren<{
    isLoadingContracts: boolean;
    subscriptionIds: string[] | undefined;
  }>
> = ({subscriptionIds, isLoadingContracts}) => {
  const [organization] = useUserInfo();
  const [upcomingInvoicesStatusMaybe] = useUpcomingInvoices(
    organization!.get('id'),
    subscriptionIds
  );
  const upcomingInvoices = upcomingInvoicesStatusMaybe.value;
  const isLoading = isLoadingContracts || upcomingInvoicesStatusMaybe.status === 'unknown';
  const [lineItemModalOpen, setLineItemModalOpen] = React.useState(false);

  const parseNextPayment = (invoice) => {
    if (!invoice) {
      //this case shouldn't happen but prevent blowups if it does
      return {
        parsedDueDate: null,
        parsedAmountDue: 0,
      };
    }
    const amountDue = costUtils.formatCost(invoice['amount_due']);
    // Stripe's invoice object returns either "charge_automatically" or "send_invoice" here.
    // https://stripe.com/docs/api/invoices/object#invoice_object-collection_method
    const dateField =
      invoice['collection_method'] === 'charge_automatically' ? 'next_payment_attempt' : 'due_date';
    return {
      //Stripe uses unix epoch timestamps for their date values in their invoices
      parsedDueDate: `${moment.unix(invoice[dateField]).format('MMMM Do')}`,
      parsedAmountDue: amountDue,
    };
  };

  const formattedDueDates = (upcomingInvoices) => {
    const uniqueDueDates: number[] = Array.from(
      new Set(
        upcomingInvoices.map((invoice: ApiPaidLayerInvoice) => {
          return parseNextPayment(invoice).parsedDueDate;
        })
      )
    );
    return uniqueDueDates.join(' and ');
  };

  return (
    <div className={cs.planInformationBox}>
      <div className={cs.planInformationHeader}>
        Next Imagery Payment{!!upcomingInvoices?.length && 's'}
      </div>
      {!upcomingInvoices?.length ? (
        <>
          <div className={classnames(cs.planName, isLoading ? 'bp5-skeleton' : '')}>
            No invoices found
          </div>
          <div className={isLoading ? 'bp5-skeleton' : ''}>
            Please reach out to <a href="mailto:lens@upstream.tech">lens@upstream.tech</a> with any
            questions.
          </div>
        </>
      ) : (
        <>
          <div className={cs.planName}>
            {/**Most customers will have exactly one invoice. */}
            {upcomingInvoices.length === 1 &&
              `${parseNextPayment(upcomingInvoices[0]).parsedDueDate} for ${
                parseNextPayment(upcomingInvoices[0]).parsedAmountDue
              }`}

            {/**A few customers have more than one invoice. In this situation, aggregate their invoice
             * dates and amounts for display. They can then view a breakdown in the modal below. */}
            {upcomingInvoices.length > 1 &&
              `${upcomingInvoices.length} payments due ${formattedDueDates(
                upcomingInvoices
              )} totaling ${costUtils.formatCost(
                upcomingInvoices.reduce(
                  (acc, currentInvoice) => (acc += currentInvoice['amount_due']),
                  0
                )
              )}`}
          </div>
          <>
            {
              /** Display account credits on the main page, if they have any.
               * We can look at just the first invoice here because starting_balance,
               * ending_balance are specific to the customer, not the invoice.
               */
              Math.abs(upcomingInvoices[0]['ending_balance']) > 0 && (
                <p>
                  <div className={cs.tightLineSpacing}>
                    Remaining account credit:{' '}
                    {costUtils.formatCost(Math.abs(upcomingInvoices[0]['ending_balance']))}
                    <br />
                    Last synced:{' '}
                    {moment(
                      upcomingInvoices[0].subscription_details.metadata['last_synced_at']
                    ).format('h:mma, M/DD/YYYY')}
                  </div>
                </p>
              )
            }
            <a onClick={() => setLineItemModalOpen(true)}>View details</a>

            <Modal
              isOpen={lineItemModalOpen}
              className={cs.invoicesModalContent}
              onClose={() => setLineItemModalOpen(false)}
              headerText={`Next imagery payment${upcomingInvoices.length > 1 ? 's' : ''}`}
              hideActions
            >
              {upcomingInvoices.map((invoice, idx) => {
                return (
                  <div key={idx}>
                    <h3>
                      {upcomingInvoices.length > 1 && `${idx + 1}. `} Invoice due{' '}
                      {parseNextPayment(invoice).parsedDueDate}
                    </h3>
                    <ul>
                      {invoice['lines']['data']
                        .filter(
                          (d) =>
                            ![
                              CONSTANTS.LIVE_LENS_PUBLIC_IMAGERY_PRODUCT_ID,
                              CONSTANTS.TEST_LENS_PUBLIC_IMAGERY_PRODUCT_ID,
                            ].includes(d.price.product)
                        )
                        .map((d) => formatLineItem(d))}
                      {invoice['starting_balance'] !== 0 && (
                        <>
                          <li>
                            <span>Current account credit</span>
                            <span>
                              ({costUtils.formatCost(Math.abs(invoice['starting_balance']))})
                            </span>
                          </li>
                          <li>
                            <span>Remaining account credit after this invoice</span>
                            <span>
                              ({costUtils.formatCost(Math.abs(invoice['ending_balance']))})
                            </span>
                          </li>
                        </>
                      )}
                      <li>
                        <div>Total due</div>
                        <div>{parseNextPayment(invoice).parsedAmountDue}</div>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </Modal>
          </>
        </>
      )}
    </div>
  );
};

//TODO(eva): potentially generalize this to an UpdateSubscription modal if we have more uses for it
//TODO(eva): break out some of the business logic in calculating total spend into utils-- can consolidate
//with what's used in the ContractTable
const CancelSubscriptionModal: React.FunctionComponent<
  React.PropsWithChildren<{
    subscription: ApiAccessSubscription;
    isOpen: boolean;
    onClose: () => void;
    cancelSubscription: (
      organizationId: string,
      subscriptionId: string,
      comment?: string
    ) => Promise<void>;
    orgId: string;
    contracts: ApiImageryContractWithProjects[];
    organizationUsers: ApiOrganizationUser[] | null;
    projects: any;
  }>
> = ({
  subscription,
  orgId,
  isOpen,
  onClose,
  cancelSubscription,
  contracts,
  organizationUsers,
  projects,
}) => {
  const [cancellationComment, setCancellationComment] = React.useState('');
  const [isCancelling, setIsCancelling] = React.useState(false);

  const totalSpend: number =
    contracts
      ?.map((contract) => {
        const usedCents =
          contract.contractType === CONSTANTS.CONTRACT_TYPE_DOLLAR_UNIT
            ? contract.usage.purchased_cents
            : 0;
        return usedCents && usedCents > 0 ? usedCents : 0;
      })
      .reduce((acc, currentValue) => acc + currentValue, 0) / 100;

  const totalAcres: number = contracts
    ?.map((contract) => {
      const orderedAcres =
        contract.contractType === 'acreUnit'
          ? contract.usage.purchased_acres + contract.usage.voided_acres
          : contract.usage.purchased_acres + contract.usage.voided_acres;
      return orderedAcres && orderedAcres > 0 ? orderedAcres : 0;
    })
    .reduce((acc, currentValue) => acc + currentValue, 0);

  const totalSpendFormatted: string =
    totalSpend > 0
      ? '$' + conversionUtils.numberWithCommas(totalSpend.toFixed(2)) + ' of '
      : 'All ordered ';

  const totalAcresFormatted: string =
    totalAcres > 0
      ? 'across ' + conversionUtils.numberWithCommas(totalAcres.toFixed(0)) + ' acres'
      : '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      primaryButton={{
        text: 'Cancel Subscription',
        intent: B.Intent.DANGER,
        isLoading: isCancelling,
        isDisabled: isCancelling,
      }}
      secondaryButton={{
        text: 'Back',
      }}
      onSubmit={async () => {
        setIsCancelling(true);
        await cancelSubscription(orgId, subscription.id, cancellationComment);
        setIsCancelling(false);
        onClose();
      }}
    >
      <div className={cs.modalContent}>
        <h2>We're sorry to see you go!</h2>
        <p>
          Your subscription is paid through{' '}
          {moment(subscription.currentPeriodEnd!).format('MMMM D, YYYY')}
          {subscription.interval === 'year' &&
            ', and there is a grace period of 30 days after your subscription ends.'}{' '}
          If you would like to proceed with cancelling your subscription, please select "Cancel
          Subscription" below.
        </p>
        <B.Callout intent={B.Intent.WARNING}>
          When your Lens subscription expires, all {organizationUsers?.length || ''} users in your
          account will be unable to access:
          <br />
          <ul>
            <li>
              Imagery, notes, and data throughout {projects?.size ?? 'your'} portfolio
              {projects?.size !== 1 && 's'}
            </li>
            <li>
              {totalSpendFormatted} ordered high-res imagery {totalAcresFormatted}
            </li>
            <li>New downloads, including reports, PNGs, and more</li>
            <li>
              {' '}
              <a
                href="https://support.upstream.tech/article/114-payments-and-cancellations"
                target="_blank"
                rel="noopener noreferrer"
              >
                See here for more details
              </a>
            </li>
          </ul>
        </B.Callout>
        <p>Please let us know why you are cancelling:</p>
        <B.TextArea
          large={true}
          fill={true}
          value={cancellationComment}
          onChange={(e) => setCancellationComment(e.target.value)}
        />
      </div>
    </Modal>
  );
};

/**
 * Component that handles rendering a loader if we're still fetching data, or
 * the table containing all contracts.
 */
export const ContractHistory: React.FunctionComponent<
  React.PropsWithChildren<{
    contracts: StatusMaybe<ApiImageryContractWithProjects[]>;
    reloadContracts: () => void;
    api?: Pick<typeof Remote.api, 'imagery' | 'organizations'>;
    organizationId: string;
  }>
> = ({contracts, reloadContracts, api = Remote.api, organizationId}) => {
  return (
    <>
      <div className={cs.page}>
        {contracts.status === 'unknown' && (
          <div className={cs.loadingContainer}>
            <B.Spinner />
          </div>
        )}
        {contracts.status === 'error' && (
          <B.NonIdealState
            icon="warning-sign"
            description="We weren’t able to load your contracts right now."
            action={
              <B.Button type="button" text="Try Again" intent="primary" onClick={reloadContracts} />
            }
          />
        )}

        {contracts.status === 'some' && (
          <ContractHistoryTable
            api={api}
            contracts={contracts.value}
            organizationId={organizationId}
          />
        )}
      </div>
    </>
  );
};

/**
 * Table with current and historical contracts.
 */
export const ContractHistoryTable: React.FunctionComponent<
  React.PropsWithChildren<{
    contracts: ApiImageryContractWithProjects[];
    api?: Pick<typeof Remote.api, 'imagery' | 'organizations'>;
    openTooltipsForTest?: boolean;
    organizationId: string;
  }>
> = ({contracts, api = Remote.api, openTooltipsForTest, organizationId}) => {
  const [isDownloadingAll, setIsDownloadingAll] = React.useState(false);

  const [isDownloadingById, setIsDownloadingById] = React.useState({});

  const columns = React.useMemo<Column<ApiImageryContractWithProjects>[]>(
    () => [
      {
        Header: 'Contract',
        accessor: (contract) => contract,
        Cell: ({cell}) => {
          const {name} = cell.value as ApiImageryContractWithProjects;

          const contractStatus = getContractStatus(contracts, cell.value);

          return (
            <div className={cs.contractCell}>
              <B.Icon
                icon="dot"
                // Green for active, gray for completed or inactive.
                color={contractStatus === 'active' ? '#0d8050' : colors.gray}
                htmlTitle={contractStatus.charAt(0).toUpperCase() + contractStatus.slice(1)}
              />

              <div>
                {name} {contractStatus === 'completed' && <i>(completed)</i>}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Portfolio(s)',
        accessor: 'projects',
        Cell: ({cell}) => {
          const projects = cell.value as ApiImageryContractWithProjects['projects'];

          switch (projects.length) {
            case 0:
              return NA_TAG;

            case 1: {
              const {name, isArchived} = projects[0];

              return (
                <div className={cs.portfolioCell} title={isArchived ? `${name} (archived)` : name}>
                  {name} {isArchived && <i>(archived)</i>}
                </div>
              );
            }

            default:
              return (
                <B.Tooltip
                  // Allows popper.js to position the tooltip outside of the
                  // dialog’s bounds. The `hide: {enabled: false}` bit prevents
                  // popper.js from spamming the console with warnings.
                  modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
                  isOpen={openTooltipsForTest}
                  content={
                    <>
                      <div>Contains orders from {projects.length} portfolios:</div>

                      <ul className={cs.popoverList}>
                        {projects.map(({name, isArchived}) => (
                          <li key={name}>
                            {name} {isArchived && <i>(archived)</i>}
                          </li>
                        ))}
                      </ul>
                    </>
                  }
                >
                  <i>Multiple</i>
                </B.Tooltip>
              );
          }
        },
        width: 100,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({cell}) =>
          imageryUtils.formatDate(cell.value as ApiImageryContractWithProjects['startDate']),
        width: 60,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: ({cell}) => {
          const endDate = cell.value as ApiImageryContractWithProjects['endDate'];

          return endDate ? imageryUtils.formatDate(endDate) : NA_TAG;
        },
        width: 60,
      },
      {
        Header: 'Total Spent',
        accessor: (contract) => contract,
        Cell: ({cell}) =>
          calculateContractSpend(cell.value.usage as ApiDollarUnitUsage, cell.value.contractType),
        width: 60,
      },
      {
        id: 'Total Ordered Acres',
        Header: (
          <div className={cs.orderedAcreageHeader}>
            <div>Total Ordered Acres</div>

            <B.Tooltip
              popoverClassName={cs.popover}
              className={cs.popoverTarget}
              content="Includes paid orders and orders provided at no cost."
              isOpen={openTooltipsForTest}
            >
              <B.Icon icon="info-sign" color={colors.darkestGray} iconSize={14} />
            </B.Tooltip>
          </div>
        ),
        accessor: (contract) => contract,
        Cell: ({cell}) => {
          const contract = cell.value as ApiImageryContractWithProjects;

          const acreage =
            contract.contractType === 'acreUnit'
              ? contract.usage.purchased_acres + contract.usage.voided_acres
              : contract.usage.purchased_acres + contract.usage.voided_acres;

          return conversionUtils.numberWithCommas(acreage.toFixed(0));
        },
        width: 80,
      },
      {
        id: 'Order History',
        Header: () => {
          // finding if there are no contracts that have any usage
          const noOrders = !contracts.find((contract) => contractHasAnyOrders(contract));
          return (
            <div className={cs.ordersHeader}>
              <div>Order History</div>

              <B.Button
                minimal
                title="Download CSV of imagery orders on all contracts"
                icon={
                  <B.Icon
                    icon="download"
                    color={isDownloadingAll ? colors.gray : colors.darkestGray}
                    iconSize={14}
                  />
                }
                disabled={isDownloadingAll || noOrders}
                onClick={async () => {
                  setIsDownloadingAll(true);

                  try {
                    const billingRecords = await Promise.all(
                      contracts.map(
                        async (c) =>
                          (
                            await api.imagery.billingRecords.fetch(
                              c.id,
                              {perPage: 100},
                              {getAllPages: 2}
                            )
                          )
                            .get('data')
                            .toJS() as ApiImageryPurchaseBillingRecord[]
                      )
                    );

                    const billingRecordsWithBillingPeriods =
                      await appendBillingPeriodsToBillingRecords(
                        billingRecords.flat(1),
                        api,
                        organizationId
                      );

                    imageryUtils.exportRecordsCsv({
                      fileName: 'Order History All Contracts',
                      billingRecords: billingRecordsWithBillingPeriods,
                      contracts,
                    });
                  } catch (error) {
                    Sentry.captureException(error);
                    console.error(error);
                    // Let the user know that there has been an issue, and they can try again.
                    window.alert(
                      'We weren’t able to download your CSV right now. Please try again.'
                    );
                  } finally {
                    setIsDownloadingAll(false);
                  }
                }}
              />
            </div>
          );
        },
        accessor: (contract) => contract,
        Cell: ({cell}) => {
          const contract = cell.value as ApiImageryContractWithProjects;
          const {id, name} = contract;

          const isDownloading = isDownloadingById[id];

          const noOrders = !contractHasAnyOrders(contract);

          return (
            <div className={classnames({[cs.disabledDownloadLink]: isDownloading || noOrders})}>
              <a
                title="Download CSV of imagery orders on contract"
                onClick={async () => {
                  setIsDownloadingById((prev) => ({...prev, [id]: true}));

                  try {
                    const billingRecords = (
                      await api.imagery.billingRecords.fetch(id, {perPage: 100}, {getAllPages: 2})
                    )
                      .get('data')
                      .toJS() as ApiImageryPurchaseBillingRecord[];

                    const billingRecordsWithBillingPeriods =
                      await appendBillingPeriodsToBillingRecords(
                        billingRecords,
                        api,
                        organizationId
                      );

                    imageryUtils.exportRecordsCsv({
                      fileName: `Order History ${name}`,
                      billingRecords: billingRecordsWithBillingPeriods,
                      contracts,
                    });
                  } catch (error) {
                    Sentry.captureException(error);
                    console.error(error);
                    // Let the user know that there has been an issue, and they can try again.
                    window.alert(
                      'We weren’t able to download your CSV right now. Please try again.'
                    );
                  } finally {
                    setIsDownloadingById((prev) => ({...prev, [id]: false}));
                  }
                }}
              >
                {isDownloading ? 'Downloading…' : 'Download'}
              </a>
            </div>
          );
        },
        width: 80,
      },
    ],
    [api, contracts, isDownloadingAll, isDownloadingById, openTooltipsForTest]
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data: contracts,
    },
    useFlexLayout
  );

  return (
    <>
      <div className={cs.tableWrapper}>
        <table {...getTableProps()} className={cs.table}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} key={i}>
                    <div>{column.render('Header')}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td {...cell.getCellProps()} key={i}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ContractHistory;

/**
 * Get the contract status. A completed contract has already ended. An inactive
 * contract has either (1) not started yet, or (2) is other than another active
 * contract for the same projects. An active project is in progress, and is the
 * newest active contract for its projects.
 */
export function getContractStatus(
  contracts: ApiImageryContractWithProjects[],
  contract: ApiImageryContractWithProjects
): 'completed' | 'inactive' | 'active' {
  const {startDate, endDate, projects} = contract;

  const today = moment();

  const hasStarted = (start: string) => moment(start).isBefore(today);
  const hasEnded = (end: string | null) => end && moment(end).isBefore(today);

  if (hasEnded(endDate)) {
    return 'completed';
  } else if (!hasStarted(startDate)) {
    return 'inactive';
  } else {
    const contractStartTime = new Date(startDate).getTime();

    // Identify all project contracts that are in progress and have the same
    // projects as the selected contract, and calculate their start times. This
    // helps us identify overlapping active contracts for the same projects, and
    // which one started most recently.
    const activeProjectContractStartTimes = contracts
      .filter(
        (c) => hasStarted(c.startDate) && !hasEnded(c.endDate) && isEqual(c.projects, projects)
      )
      .map((c) => new Date(c.startDate).getTime());

    // Determine if this contract will be the one charges are made against. In
    // addition to not being completed, this contract must have started the most
    // recently of all active contracts for the same projects.
    return contractStartTime === Math.max(...activeProjectContractStartTimes)
      ? 'active'
      : 'inactive';
  }
}

/**
 * Create a display of the total spend of a contract (or, display a tooltip referring
 * the user to the CSV to find the spend there for legacy acreage-based contracts).
 */
export function calculateContractSpend(
  usage: ApiDollarUnitUsage,
  contractType: 'dollarUnit' | 'acreUnit'
): string | React.ReactElement {
  switch (contractType) {
    case CONSTANTS.CONTRACT_TYPE_DOLLAR_UNIT:
      return '$' + conversionUtils.numberWithCommas((usage.purchased_cents / 100).toFixed(2));
    case CONSTANTS.CONTRACT_TYPE_ACRE_UNIT:
      return (
        <B.Tooltip content={'Download the CSV to view total spent for this contract.'}>
          {NA_TAG}
        </B.Tooltip>
      );
    default:
      // In practice, we don't hit this case because all of our contract types
      // are covered by the switch cases.
      return <></>;
  }
}
