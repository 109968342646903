import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import leaflet from 'leaflet';
import React from 'react';

import {FONTS as ANALYZE_POLYGON_CHART_FONTS} from 'app/components/AnalyzePolygonChart/utils';
import GlobalErrorBoundary from 'app/components/ErrorBoundaries';
import api from 'app/modules/Remote/api';
import {ApiFeature, ApiFeatureData, ApiNote} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  GeometryOverlaySetting,
  TagSetting,
} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {GeoJsonFeaturesLoader} from 'app/providers/FeaturesProvider';
import {NotesActions, StateApiNoteWithTagSettings} from 'app/stores/NotesStore';
import * as hookUtils from 'app/utils/hookUtils';
import * as layerUtils from 'app/utils/layerUtils';
import {MultiFeaturePropertyParts} from 'app/utils/multiFeaturePropertyUtils';
import * as noteUtils from 'app/utils/noteUtils';
import * as tagUtils from 'app/utils/tagUtils';

import CSS_PRINT from './CssPrint';
import CSS_RESET from './CssReset';
import {useReadyState} from './readyState';
import {ReportExportFeatureWithNotes, ReportFeatureInfo} from './ReportExportFeature';

/**
 * Entry point for the entire contents of the ReportExportWindow. Handles
 * loading the feature data and notes across what may be multiple features.
 *
 * Delegates to ReportPrintPreview for the window contents.
 */
const ReportExportWindow: React.FunctionComponent<
  React.PropsWithChildren<{
    getFeatureData: hookUtils.CachedApiGetter<[number], I.ImmutableOf<ApiFeatureData[]>>;
    firebaseToken: string;
    organization: I.ImmutableOf<ApiOrganization>;
    geoJsonFeaturesLoader: GeoJsonFeaturesLoader;
    projectId: string;
    notesActions: Pick<NotesActions, 'hydrateNote'>;
    selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
    selectedFeature: I.ImmutableOf<ApiFeature>;
    overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
    overlaySettings: GeometryOverlaySetting[];
    multiFeaturePropertyParts: MultiFeaturePropertyParts;
    includeAllParts: boolean;
    notesFilterState: noteUtils.NotesFilterState;
    savedReportId?: number | undefined;
    reportTitle?: string | undefined;
    refetchReports?: () => Promise<void>;
    reportWindow: Window | null;
  }>
> = ({
  firebaseToken,
  organization,
  geoJsonFeaturesLoader,
  notesActions,
  getFeatureData,
  projectId,
  selectedFeatureCollection,
  selectedFeature,
  overlayFeatureCollections,
  overlaySettings,
  multiFeaturePropertyParts,
  notesFilterState,
  includeAllParts,
  savedReportId,
  reportTitle = 'Report',
  refetchReports,
  reportWindow,
}) => {
  // We have our own note cache here because there isn’t one higher-up. The note
  // store code was written prior to our data fetching hooks, and so isn’t as-is
  // built for getting more than one feature’s notes at a time.
  const [getNotes] = hookUtils.useCachedApiGet(
    async ([notesActions, fcId, projectId], featureId: number) => {
      const apiNotes = (await api.featureCollections.features(fcId).listNotes(projectId, featureId))
        .get('data')
        .toJS() as ApiNote[];

      const hydratedNotesWithTagSettings: StateApiNoteWithTagSettings[] = apiNotes.map((n) => {
        const tagSettings: Record<string, TagSetting> = tagUtils
          .getTagSettings(selectedFeatureCollection, tagUtils.TAG_KIND.NOTE)
          .toJS();

        return {
          ...notesActions.hydrateNote(n),
          // It's possible for a tagId on a note to no longer exist in tagSettings, so we
          // filter out any tagIds that aren't in tagSettings at this step
          tagSettings: n.tagIds
            ? Object.keys(n.tagIds)
                .filter((tagId) => tagSettings[tagId])
                .map((tagId) => tagSettings[tagId])
            : null,
        };
      });
      return hydratedNotesWithTagSettings;
    },
    [notesActions, selectedFeatureCollection.get('id'), projectId] as const
  );

  const enrolledLayerKeys =
    layerUtils.getEnrolledLayerKeysForFeatureCollection(selectedFeatureCollection);

  // If all parts are involved, don’t make one of them “active,” which would
  // highlight them on the overview map.
  if (includeAllParts) {
    multiFeaturePropertyParts = multiFeaturePropertyParts.map((p) => ({...p, isActive: false}));
  }

  //TODO(eva): i think we should get rid of the concept of "loadedCount". it's for populating the loading bar,
  //but it's unhelpful for single-location properties because as soon as loadedCount = totalCount the report
  //is loaded anyway. putting this as a TODO bc want to also unwind the list of lists created here bc of loadedCount.
  const [features, loadedCount] = React.useMemo<[ReportFeatureInfo[] | null, number]>(() => {
    if (includeAllParts) {
      let features: ReportFeatureInfo[] | null = [];
      let loadedCount = 0;

      for (const {feature} of multiFeaturePropertyParts) {
        const featureData = getFeatureData(feature.get('id')).value;
        const notes = getNotes(feature.get('id')).value;

        // Our contract with the rest of the component is that we either return
        // an array of all of the ReportFeatureInfos, or return null. We don’t
        // want to return a partial array, since we don’t want report pages to
        // pop in. And we don’t want ReportFeatureInfo to have optional fields
        // for features and notes, since we’d have to check them later.
        //
        // _BUT_ we don’t want to short-circuit out of this loop entirely even
        // if we know we’re not going to be returning any ReportFeatureInfos.
        //
        // The reason for that is that we assume that getFeatureData and
        // getNotes are backed by useCachedApiGet. We need to call them to kick
        // off the initial requests and also to keep the pagination going.
        //
        // If we instead short-circuited at this case and returned null, then
        // the features and their data would load serially, rather than in
        // parallel, which would be slower.
        if (!featureData || !notes) {
          features = null;
        } else {
          loadedCount++;

          // This will no-op if we’re not returning features because some
          // haven’t loaded, and that’s fine.
          features?.push({
            feature,
            featureData,
            notes,
          });
        }
      }

      return [features, loadedCount];
    } else {
      // This should already be in the featureData cache established in
      // MonitorProjectView’s data provider.
      const featureData = getFeatureData(selectedFeature.get('id')).value;

      const notes = getNotes(selectedFeature.get('id')).value;

      if (!featureData || !notes) {
        return [null, 0];
      } else {
        return [
          [
            {
              feature: selectedFeature,
              featureData,
              notes,
            },
          ],
          1,
        ];
      }
    }
  }, [selectedFeature, includeAllParts, getFeatureData, getNotes, multiFeaturePropertyParts]);

  // useCallback because changing this value will reset the note order back to
  // the date sort, removing any re-ordering from the user clicking the arrow
  // buttons.
  const filterNotesCallback = React.useCallback(
    (n: StateApiNoteWithTagSettings) => noteUtils.filterNotesCallback(n, 'user', notesFilterState),
    [notesFilterState]
  );

  return (
    <ReportExportWindowContents
      firebaseToken={firebaseToken}
      organization={organization}
      projectId={projectId}
      geoJsonFeaturesLoader={geoJsonFeaturesLoader}
      overlayFeatureCollections={overlayFeatureCollections}
      overlaySettings={overlaySettings}
      propertyParts={multiFeaturePropertyParts}
      filterNotesCallback={filterNotesCallback}
      featureInfos={features}
      totalCount={includeAllParts ? multiFeaturePropertyParts.length : 1}
      loadedCount={loadedCount}
      enrolledLayerKeys={enrolledLayerKeys}
      savedReportId={savedReportId}
      reportTitle={reportTitle}
      refetchReports={refetchReports}
      reportWindow={reportWindow}
      selectedFeature={selectedFeature}
    />
  );
};

/**
 * Error content to show if there’s an exception during rendering the report.
 */
export const ReportError: React.FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <>
      <section>
        <h1>An error has occurred.</h1>
        <p>
          There was a problem generating your report. This might be a temporary hiccup, or it might
          mean our software has some bugs that we need to fix.
        </p>
        <p>
          You can close this window, reload Lens, and try again. If this keeps happening, please
          send us an email at <a href="mailto:lens@upstream.tech">lens@upstream.tech</a>.
        </p>
      </section>
    </>
  );
};

/**
 * Leaflet _heavily_ assumes that it’s running in the same window / document as
 * its DOM elements, even moreso than MapboxGL. So, rather than try to hack
 * through it, we add a Leaflet <script> tag to the page and expose its object
 * in this Context.
 */
export const LeafletContext = React.createContext<typeof leaflet | null>(null);

/**
 * Component that inserts <head> and <body> tags for report export.
 *
 * Includes Blueprint stylesheets and also brings in a <script> tag for Leaflet.
 * Does not include our general app custom .styl styles, but does both a CSS
 * reset and brings in CssPrint.ts’s styles.
 *
 * This component’s children will be rendered in the body of the window,
 * surrounded by a LeafletContext.
 *
 * NOTE! There are some fun time shenanigans here because all of our React code
 * is running in the _parent_ window. We have portal’d our DOM content into this
 * popup window. For some browsers, there are strict difference between the
 * classes of DOM elements running across different documents, which can cause
 * errors.
 *
 * It’s because of that that we have the Leaflet code inserted via <script>
 * element so that it runs in the popup window’s DOM. We’re able to pull the
 * Leaflet object out of that window’s global namespace and make it available to
 * our React code, however.
 *
 * This works for now! But is something that might be fragile if browsers change
 * any behavior around communicating across even same-domain windows.
 */
export const PrintWindowStructure: React.FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    fontsToCheck?: string[];
    onFontsReady?: () => void;
    onFontsTimeout?: () => void;
  }>
> = ({title, fontsToCheck, onFontsReady, onFontsTimeout, children}) => {
  const headRef = React.useRef<HTMLHeadElement>(null);

  // Reference to the pop-up’s Leaflet instance.
  const [L, setL] = React.useState<typeof leaflet | null>(null);

  // Adds our Leaflet <script> tags to the <head> and captures the Leaflet
  // instance asynchronously.
  React.useLayoutEffect(() => {
    const headEl = headRef.current!;
    const popupDocument = headEl.ownerDocument!;
    const popupWindow = popupDocument.defaultView;

    const addScript = (src: string, integrity: string) => {
      return new Promise((resolve, reject) => {
        const script = popupDocument.createElement('script');

        script.onload = resolve;
        script.onerror = reject;

        // TODO(fiona): Host Leaflet on our own servers rather than relying on
        // unpkg.
        script.src = src;
        // NOTE: The integrity check fails in Storybook, but only for Firefox.
        script.integrity = integrity;
        script.crossOrigin = 'anonymous';
        script.async = true;

        headEl.appendChild(script);
      });
    };

    // Note: If you're upgrading the leaflet versions here, fetch new integrity value by visiting the unpkg url with `?meta` at the end.
    addScript(
      'https://unpkg.com/leaflet@1.9.3/dist/leaflet.js',
      'sha384-okbbMvvx/qfQkmiQKfd5VifbKZ/W8p1qIsWvE1ROPUfHWsDcC8/BnHohF7vPg2T6'
    ).then(() => {
      // The default Leaflet script sets itself on window.L, so we can find it
      // there and then store it in our local state.
      setL((popupWindow as any).L);
    });
  }, []);

  React.useLayoutEffect(() => {
    const headEl = headRef.current!;
    const popupDocument = headEl.ownerDocument!;

    /** Poll regularly to see if fonts have been loaded. When all fonts are
     * available, notify our upstream consumers and clear the interval. */
    const interval = setInterval(() => {
      const isEveryFontReady = (fontsToCheck || []).every((font) =>
        popupDocument.fonts.check(font)
      );
      if (isEveryFontReady) {
        onFontsReady?.();
        clearInterval(interval);
      }
    }, 500);

    /** Escape hatch so we don’t poll indefinitely in the event some of our
     * fonts are not available after a reasonable amount of time. The delay
     * value is not very scientific, but more of an estimate of how long we
     * should reasonably wait for the fonts to load before displaying a loading
     * state holds up the report generation workflow. */
    const timeout = setTimeout(() => {
      onFontsTimeout?.();
      clearInterval(interval);
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <head ref={headRef}>
        <title>{title}</title>

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />

        {/* TODO(fiona): Host Leaflet locally. */}
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin=""
        />

        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

        {/* Blueprint style dependencies and stylesheets */}
        <link
          href="https://unpkg.com/@blueprintjs/icons@^5.16.0/lib/css/blueprint-icons.css"
          rel="stylesheet"
        />
        <link
          href="https://unpkg.com/@blueprintjs/core@5.16.2/lib/css/blueprint.css"
          rel="stylesheet"
        />

        <style type="text/css">
          {CSS_RESET}
          {CSS_PRINT}
        </style>
      </head>

      <body>
        <LeafletContext.Provider value={L}>{children}</LeafletContext.Provider>
      </body>
    </>
  );
};

/**
 * HTML structure for the report export window, given loaded feature information
 * (FeatureData and notes).
 */
export const ReportExportWindowContents: React.FunctionComponent<
  React.PropsWithChildren<{
    firebaseToken: string;
    organization: I.ImmutableOf<ApiOrganization>;
    projectId: string;
    geoJsonFeaturesLoader: GeoJsonFeaturesLoader;
    filterNotesCallback: (note: StateApiNoteWithTagSettings) => boolean;
    overlayFeatureCollections: I.ImmutableOf<ApiFeatureCollection[]>;
    overlaySettings: GeometryOverlaySetting[];
    featureInfos: ReportFeatureInfo[] | null;
    propertyParts: MultiFeaturePropertyParts;
    totalCount: number;
    loadedCount: number;
    enrolledLayerKeys: string[];
    savedReportId?: number | undefined;
    reportTitle: string;
    refetchReports?: () => Promise<void>;
    reportWindow: Window | null;
    selectedFeature: I.ImmutableOf<ApiFeature>;
  }>
> = ({
  firebaseToken,
  organization,
  projectId,
  geoJsonFeaturesLoader,
  overlayFeatureCollections,
  overlaySettings,
  filterNotesCallback,
  featureInfos,
  totalCount,
  loadedCount,
  propertyParts,
  enrolledLayerKeys,
  savedReportId,
  reportTitle,
  refetchReports,
  reportWindow,
  selectedFeature,
}) => {
  let featureName = propertyParts[0].feature.getIn(['properties', 'name']);
  const activePropertyPart = propertyParts.find((p) => p.isActive);

  if (propertyParts.length > 1 && activePropertyPart) {
    featureName = `${featureName}, ${activePropertyPart.partName}`;
  }

  const [areMapsReady, areMapsReadyReporter] = useReadyState(false);
  const [areFontsReady, setAreFontsReady] = React.useState(false);

  return (
    <PrintWindowStructure
      title={featureInfos === null ? 'Loading...' : reportTitle}
      fontsToCheck={ANALYZE_POLYGON_CHART_FONTS}
      onFontsReady={() => {
        setAreFontsReady(true);
      }}
      /** If we timed out waiting for our fonts to load, we still want to
       * proceed with rendering components that depend on them. We handle this
       * somewhat gracefully by setting CSS font fallbacks. */
      onFontsTimeout={() => {
        setAreFontsReady(true);
      }}
    >
      {/*
          If there’s an error in report generation we want to catch it here, since otherwise it
          would bubble up and crash the main Lens window.

          We put the error boundary inside PrintWindowContents so we get the CSS, though.
      */}
      <GlobalErrorBoundary errorContent={<ReportError />}>
        {!featureInfos && (
          <div style={{width: '30%', margin: '10rem auto'}}>
            <B.ProgressBar value={loadedCount / totalCount} intent="primary" />
          </div>
        )}

        {featureInfos && (
          <>
            <ReportExportFeatureWithNotes
              firebaseToken={firebaseToken}
              organization={organization}
              projectId={projectId}
              geoJsonFeaturesLoader={geoJsonFeaturesLoader}
              overlayFeatureCollections={overlayFeatureCollections}
              overlaySettings={overlaySettings}
              filterNotesCallback={filterNotesCallback}
              propertyParts={propertyParts}
              isMultiPartProperty={propertyParts.length > 1}
              isCombinedMultiPart={featureInfos.length > 1}
              areMapsReadyReporter={areMapsReadyReporter}
              areFontsReady={areFontsReady}
              enrolledLayerKeys={enrolledLayerKeys}
              areMapsReady={areMapsReady}
              savedReportId={savedReportId}
              featureInfos={featureInfos}
              reportTitle={reportTitle}
              refetchReports={refetchReports}
              reportWindow={reportWindow}
              selectedFeature={selectedFeature}
            />
          </>
        )}
      </GlobalErrorBoundary>
    </PrintWindowStructure>
  );
};

export default ReportExportWindow;
