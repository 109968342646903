import * as I from 'immutable';

import {
  ApiFeature,
  ApiFeatureCommonProperties,
  ApiOrderableScene,
} from 'app/modules/Remote/Feature';
import {FeaturesActions} from 'app/providers/FeaturesProvider';
import * as CONSTANTS from 'app/utils/constants';

export const OVERLAY_POPUP_INSET = 10;

export function getSelectedFeature(
  selectedFeatures: I.Set<I.MapAsRecord<I.ImmutableFields<ApiFeature>>>
) {
  return selectedFeatures.size === 1 ? selectedFeatures.first() : null;
}

/**
 * Helper function to use the updateFeature action to add / remove scenes from
 * the feature’s hiddenScenes "__app" property.
 *
 * Kinda tricky because it needs to modify the existing value, which is nested a
 * few layers in the properties.
 */
export function setIsImageHidden(
  updateFeature: FeaturesActions['updateFeature'],
  feature: I.MapAsRecord<I.ImmutableFields<ApiFeature>>,
  orderableScene: I.MapAsRecord<I.ImmutableFields<ApiOrderableScene>>,
  isHidden: boolean
) {
  const appProperties = feature.getIn(['properties', '__app']);

  let hiddenScenes: NonNullable<
    ApiFeatureCommonProperties[typeof CONSTANTS.APP_PROPERTIES_KEY]
  >['hiddenScenes'] = appProperties?.get('hiddenScenes')?.toJS();

  if (!hiddenScenes) {
    hiddenScenes = [];
  }

  const newAppProperties: ApiFeatureCommonProperties[typeof CONSTANTS.APP_PROPERTIES_KEY] = {
    hiddenScenes: isHidden
      ? // If isHidden, then append our scene to the end of the list
        [...hiddenScenes, [orderableScene.get('sourceId'), orderableScene.get('sensingTime')]]
      : // Otherwise, filter our scene out of the list.
        hiddenScenes.filter(
          ([sourceId, date]) =>
            orderableScene.get('sourceId') !== sourceId ||
            orderableScene.get('sensingTime') !== date
        ),
  };

  // slight slow-roll building up this value so we can establish types.
  const newProperties: Partial<ApiFeatureCommonProperties> = {
    [CONSTANTS.APP_PROPERTIES_KEY]: newAppProperties,
  };

  updateFeature(feature.get('id'), I.fromJS({properties: newProperties}));
}
