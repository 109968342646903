import {
  ALL_TIME_DATE_RANGE_LABEL,
  ONE_YEAR_DATE_RANGE_LABEL,
  TWO_YEAR_DATE_RANGE_LABEL,
} from 'app/stores/RasterCalculationStore';
import {
  CHLORIS_10_CARBON_STOCK,
  CHLORIS_10_FOREST_COVER,
  CHLORIS_30_CARBON_STOCK,
  CHLORIS_30_FOREST_COVER,
  CHLORIS_CARBON_STOCK,
  ESA_WORLDCOVER_LANDCOVER,
  GLOBAL_FOREST_CHANGE_LOSSYEAR,
  IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER,
  IMPACT_OBSERVATORY_LANDCOVER,
  L8_NDBI,
  MAPBIOMAS_BRAZIL_LANDCOVER,
  MAPBIOMAS_CHACO_LANDCOVER,
  METAWRI_CANOPY_HEIGHT,
  NLCD_LANDCOVER,
  PLANET_FOREST_ABOVEGROUND_CARBON,
  PLANET_FOREST_ABOVEGROUND_CARBON_3M,
  PLANET_FOREST_CANOPY_COVER,
  PLANET_FOREST_CANOPY_HEIGHT,
  PRISM_M_PRECIPITATION,
  S2_NDBI,
  SALO_ABOVEGROUND_CARBON_30M,
  SALO_ABOVEGROUND_CARBON_3M,
  SBTN_NATURAL_LANDCOVER,
  SPACE_INTELLIGENCE_CARBON,
  SPACE_INTELLIGENCE_HABITAT,
  SPACE_INTELLIGENCE_HABITAT_10_CLASS,
  TMF_ANNUAL_CHANGE_LANDCOVER,
} from 'app/utils/layers';

import {ExtraGraphOptions, GraphMode} from './AnalyzePolygonPopup';
import {GraphRange} from '../AnalyzePolygonChart/types';

export interface LayerSetting {
  graphModes?: GraphMode[];
  defaultGraphMode?: GraphMode;
  graphRanges?: GraphRange[];
  defaultGraphRange?: GraphRange;
}
export type LayerSettings = Record<string, LayerSetting>;

export const DEFAULT_GRAPH_MODES: GraphMode[] = ['average', 'averageMonthly', 'area'];
export const DEFAULT_GRAPH_MODE = 'average' as const;
export const DEFAULT_GRAPH_RANGES: GraphRange[] = [
  {type: 'simple', label: ONE_YEAR_DATE_RANGE_LABEL},
  {type: 'simple', label: TWO_YEAR_DATE_RANGE_LABEL},
  {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  {type: 'custom', range: [null, null]},
  {type: 'by-year', yearType: 'calendar'},
];
export const DEFAULT_GRAPH_RANGE_FOR_ANNUAL_LAYERS: GraphRange[] = [
  {type: 'simple', label: TWO_YEAR_DATE_RANGE_LABEL},
  {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  {type: 'custom', range: [null, null]},
];
export const DEFAULT_GRAPH_RANGE = {type: 'simple', label: ONE_YEAR_DATE_RANGE_LABEL} as const;

export const DEFAULT_LAYER_SETTING: LayerSetting = {
  graphModes: DEFAULT_GRAPH_MODES,
  defaultGraphMode: DEFAULT_GRAPH_MODE,
  graphRanges: DEFAULT_GRAPH_RANGES,
  defaultGraphRange: DEFAULT_GRAPH_RANGE,
};

export const DEFAULT_EXTRA_GRAPH_OPTIONS: ExtraGraphOptions = {
  showCumulativeValues: false,
};

const CATEGORY_LAYER_DEFAULT_GRAPH_SETTING: LayerSetting = {
  graphModes: ['areaByCategory'],
  defaultGraphMode: 'areaByCategory',
  graphRanges: [{type: 'some-years', options: []}],
  defaultGraphRange: {type: 'some-years', options: []},
};

/**
 * Utility function for getting custom layer settings.
 */
export function getLayerSettings(layerKey?: string) {
  return (layerKey && LAYER_SETTINGS[layerKey]) || {};
}

/**
 * Custom layer settings specifically for Analyze Area and related interfaces.
 */
export const LAYER_SETTINGS: LayerSettings = {
  [SALO_ABOVEGROUND_CARBON_3M]: {
    graphModes: ['aggregateCarbonSalo'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [SALO_ABOVEGROUND_CARBON_30M]: {
    graphModes: ['aggregateCarbonSalo'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [SPACE_INTELLIGENCE_CARBON]: {
    graphModes: ['aggregateCarbonSpaceIntelligence'],
    defaultGraphMode: 'aggregateCarbonSpaceIntelligence',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [PLANET_FOREST_ABOVEGROUND_CARBON]: {
    graphModes: ['aggregateCarbonSalo', 'area'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [PLANET_FOREST_ABOVEGROUND_CARBON_3M]: {
    graphModes: ['aggregateCarbonSalo', 'area'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [PLANET_FOREST_CANOPY_COVER]: {
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [PLANET_FOREST_CANOPY_HEIGHT]: {
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [CHLORIS_CARBON_STOCK]: {
    graphModes: ['aggregateCarbonSalo', 'area'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [CHLORIS_30_CARBON_STOCK]: {
    graphModes: ['aggregateCarbonSalo', 'area'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [CHLORIS_30_FOREST_COVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [CHLORIS_10_CARBON_STOCK]: {
    graphModes: ['aggregateCarbonSalo', 'area'],
    defaultGraphMode: 'aggregateCarbonSalo',
    defaultGraphRange: {type: 'simple', label: ALL_TIME_DATE_RANGE_LABEL},
  },
  [CHLORIS_10_FOREST_COVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [PRISM_M_PRECIPITATION]: {defaultGraphMode: 'averageMonthly'},

  [S2_NDBI]: {
    graphModes: ['average', 'area'],
    defaultGraphMode: 'average',
  },
  [L8_NDBI]: {
    graphModes: ['average', 'area'],
    defaultGraphMode: 'average',
  },
  [METAWRI_CANOPY_HEIGHT]: {
    graphModes: ['average', 'area'],
    defaultGraphMode: 'average',
  },

  [NLCD_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [ESA_WORLDCOVER_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [SPACE_INTELLIGENCE_HABITAT]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [SPACE_INTELLIGENCE_HABITAT_10_CLASS]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [IMPACT_OBSERVATORY_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [IMPACT_OBSERVATORY_HIGH_RES_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [MAPBIOMAS_BRAZIL_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [MAPBIOMAS_CHACO_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [SBTN_NATURAL_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [TMF_ANNUAL_CHANGE_LANDCOVER]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
  [GLOBAL_FOREST_CHANGE_LOSSYEAR]: CATEGORY_LAYER_DEFAULT_GRAPH_SETTING,
};
