import * as B from '@blueprintjs/core';
import React from 'react';
import {ConnectDragSource, ConnectDropTarget, useDrag, useDrop} from 'react-dnd';

import * as hookUtils from 'app/utils/hookUtils';

import MapOverlayDialog from './MapOverlayDialog';

const DRAG_AROUND_TYPE = 'target';

interface Position {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}
interface DragAroundProps {
  initialPosition: Position;
}

type DragItem = {type: typeof DRAG_AROUND_TYPE} & Position;

type MapDialogOverlayProps = React.ComponentProps<typeof MapOverlayDialog>;
type Props = MapDialogOverlayProps &
  DragAroundProps & {isCollapsable?: boolean; isCurrentlyDraggable?: boolean};

interface Result {
  drag: ConnectDragSource;
  drop: ConnectDropTarget;
  position: Position;
  isDragging: boolean;
}

function useDragAround({initialPosition}: DragAroundProps): Result {
  const [position, setPosition] = React.useState<Position>(initialPosition);

  const [{isDragging}, drag] = useDrag({
    type: DRAG_AROUND_TYPE,
    item: {type: DRAG_AROUND_TYPE, ...position},
    canDrag: true,
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  initialPosition = hookUtils.useContinuity(initialPosition);
  React.useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  const [, drop] = useDrop({
    accept: DRAG_AROUND_TYPE,
    drop(item: DragItem, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta) {
        let top, right, left, bottom;
        if (item.left) {
          left = Math.round(item.left + delta.x);
        }
        if (item.top) {
          top = Math.round(item.top + delta.y);
        }
        if (item.right) {
          right = Math.round(item.right - delta.x);
        }
        if (item.bottom) {
          bottom = Math.round(item.bottom - delta.y);
        }
        setPosition({top, right, bottom, left});
      }
    },
  });

  return {drag, drop, position, isDragging};
}

const DraggableMapOverlayDialog: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  initialPosition,
  isCollapsable = false,
  isCurrentlyDraggable = true,
  children,
  ...props
}) => {
  const {drag, drop, position, isDragging} = useDragAround({initialPosition});
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  return (
    <>
      <MapOverlayDialog
        {...props}
        additionalButtons={[
          <B.Button
            key="toggleCollapse"
            icon={isCollapsed ? 'maximize' : 'minimize'}
            minimal
            onClick={() => {
              setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
            }}
          />,
        ]}
        ref={isCurrentlyDraggable ? drag : undefined}
        style={{
          ...position,
          ...props.style,
          display: isDragging ? 'none' : 'block',
        }}
        contentStyles={
          isCollapsed
            ? {
                padding: 0,
              }
            : {}
        }
        titleBarStyle={{cursor: 'grab'}}
      >
        {!isCollapsed && children}
      </MapOverlayDialog>
      {isDragging && (
        <div
          ref={isCurrentlyDraggable ? drop : undefined}
          style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}
        />
      )}
    </>
  );
};

export default DraggableMapOverlayDialog;
