import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';
import {ColumnInstance, Row, TableInstance} from 'react-table';

import List, {ListItem} from 'app/components/List';
import {AlertPolicy, ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, TagSetting} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import colors from 'app/styles/colors.json';
import * as tagUtils from 'app/utils/tagUtils';

import * as cs from './PropertyOverview.styl';
import {PropertyTableRow} from './PropertyTable';
import {getAlertListItems, makeUserListItems} from './utils';

export type FilterProps = Pick<TableInstance<PropertyTableRow>, 'setFilter'> & {
  column: Pick<ColumnInstance<PropertyTableRow>, 'id' | 'filterValue'>;
};

/**
 * Filter component for a react-table Column.  Used for the "Name" search box.
 *
 * "Pick" in Props to make it more obvious what parts of the react-table objects
 * need to be provided in stories.
 */
export const SearchBoxFilter: React.FunctionComponent<React.PropsWithChildren<FilterProps>> = ({
  column,
  setFilter,
}) => {
  const queryFieldRef = React.useRef<HTMLInputElement>();
  const query = column.filterValue || '';

  const hotkeys = React.useMemo(
    () => [
      {
        global: true,
        combo: '/',
        label: 'Search by name',
        onKeyDown: (ev) => {
          ev.preventDefault();
          queryFieldRef?.current?.focus();
        },
      },
    ],
    []
  );

  const onChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(column.id, ev.currentTarget.value);
    },
    [setFilter, column.id]
  );

  const onKeyDown = React.useCallback((ev: React.KeyboardEvent) => {
    if (queryFieldRef.current && ev.key === 'Escape') {
      queryFieldRef.current.blur();
    } else if (ev.key === 'ArrowDown') {
      // A bit of a hack that we go up to the DOM for this, but it’s somewhat
      // better than the effort of trying to push a function through to here
      // that would basically just do the same thing.
      const firstRow = document.getElementsByClassName(cs.row)[0] as HTMLElement | undefined;

      if (firstRow) {
        firstRow.focus();
      }

      ev.preventDefault();
    }
  }, []);

  const {handleKeyDown, handleKeyUp} = B.useHotkeys(hotkeys);

  const rightElement = query.length ? (
    <B.Button
      minimal={true}
      icon="delete"
      onClick={() => {
        setFilter(column.id, '');

        // We need to re-focus to keep keyboard events going
        // through the input group.
        if (queryFieldRef.current) {
          queryFieldRef.current.focus();
        }
      }}
    />
  ) : undefined;

  return (
    <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      <B.InputGroup
        className={cs.propertySearchInput}
        inputRef={queryFieldRef as any}
        leftIcon={<B.Icon icon="search" color={colors.darkestGray} />}
        placeholder="Search properties…"
        dir="auto"
        value={query}
        onChange={onChange}
        onKeyDown={onKeyDown}
        rightElement={rightElement}
      />
    </div>
  );
};

export interface TagFilterValue {
  op: 'any' | 'all' | 'none';
  tagIds: I.Set<string>;
}

export function encodeTagFilterValue(val: TagFilterValue) {
  return `${val.op}:${val.tagIds.join(',')}`;
}

/**
 * The filter value for tags is the operation followed by a colon, then a
 * comma-separated list of tag IDs.
 */
export function decodeTagFilterValue(
  val: string,
  tagSettings: I.OrderedMap<string, I.ImmutableOf<TagSetting>>
): TagFilterValue {
  const bits = val?.split(':');
  if (bits?.length !== 2) {
    return {op: 'all', tagIds: I.Set([])};
  } else {
    const tagIds = bits[1]
      .split(',')
      // Remove the single blank you get when calling split on ""
      .filter((s) => !!s);

    // If any of the active tag IDs are invalid, update the filter state with
    // only the valid tag IDs.
    const validTagIds = tagIds.filter((id) => tagSettings.find((s) => s!.get('id') === id));

    return {
      op: bits[0] as TagFilterValue['op'],
      tagIds: I.Set(validTagIds),
    };
  }
}

/**
 * "make" function so we can parameterize it over whether or not we’re treating
 * hidden imagery as available.
 */
export const makeTagsFilter =
  (tagSettings: I.OrderedMap<string, I.ImmutableOf<TagSetting>>) =>
  (rows: Row<PropertyTableRow>[], _, filterValue = ''): Row<PropertyTableRow>[] => {
    const value = decodeTagFilterValue(filterValue, tagSettings);
    return rows.filter((r) => featureTagFilter(r.original.features, value));
  };

export function featureTagFilter(
  features: I.ImmutableOf<ApiFeature[]>,
  value: TagFilterValue
): boolean {
  if (value.tagIds.isEmpty()) {
    return true;
  }

  // A list where each element is a set of tag IDs set on that feature.
  // Multi-location property rows will have multiple elements in this list.
  const tagIdsPerFeature = features.map((feature) => tagUtils.getFeatureTagIds(feature!));

  if (value.op === 'any') {
    return tagIdsPerFeature.some((tagIds) => !tagIds!.intersect(value.tagIds).isEmpty());
  } else if (value.op === 'all') {
    return tagIdsPerFeature.some((tagIds) => tagIds!.isSuperset(value.tagIds));
  } else if (value.op === 'none') {
    return tagIdsPerFeature.some((tagIds) => tagIds!.intersect(value.tagIds).isEmpty());
  } else {
    return true;
  }
}

export const TableTagFilter: React.FunctionComponent<
  React.PropsWithChildren<
    FilterProps & {
      tagSettings: I.OrderedMap<string, I.ImmutableOf<TagSetting>>;
    }
  >
> = ({column, setFilter, tagSettings}) => {
  const value = decodeTagFilterValue(column.filterValue, tagSettings);
  const setValue = (v: TagFilterValue | undefined) => {
    const shouldResetTags = !v || (v.tagIds.isEmpty() && v.op === 'all');
    return setFilter('tags', !shouldResetTags ? encodeTagFilterValue(v) : undefined);
  };

  return (
    <TagFilter
      tagSettings={tagSettings}
      value={value}
      setValue={setValue}
      showTooltip={false}
      popoverProps={{usePortal: false}}
    />
  );
};

export const TagFilter: React.FunctionComponent<
  React.PropsWithChildren<{
    tagSettings: I.OrderedMap<string, I.ImmutableOf<TagSetting>>;
    value: TagFilterValue;
    setValue: (v: TagFilterValue | undefined) => void;
    openPopoverForTest?: boolean;
    minimal?: boolean;
    showTooltip?: boolean;
    popoverProps?: B.PopoverProps;
  }>
> = ({
  tagSettings,
  value,
  setValue,
  openPopoverForTest,
  minimal = true,
  showTooltip = true,
  popoverProps,
}) => {
  return (
    <B.Popover
      position="bottom"
      isOpen={openPopoverForTest}
      {...popoverProps}
      content={
        <div>
          <div className={cs.tagFilterOptions}>
            <div>Show properties with</div>

            <B.ButtonGroup fill>
              {(['any', 'all', 'none'] as const).map((op) => (
                <B.Button
                  key={op}
                  text={op}
                  onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setValue({...value, op});
                  }}
                  active={value.op === op}
                  outlined
                  small
                />
              ))}
            </B.ButtonGroup>

            <div>of the following tags:</div>
          </div>
          <List
            className={cs.tagFilterWrapper}
            items={[
              ...tagUtils.makeTagListItems(tagSettings, (tagId) => value.tagIds.includes(tagId)),
              {id: 'sep1', text: '', isSeparator: true},
              {id: 'reset', text: 'Reset filter'},
            ]}
            onClickItem={({id}, ev) => {
              ev.preventDefault();

              if (id === 'reset') {
                setValue(undefined);
              } else {
                const isSelected = value.tagIds.includes(id);

                if (isSelected) {
                  setValue({...value, tagIds: value.tagIds.remove(id)});
                } else {
                  setValue({...value, tagIds: value.tagIds.add(id)});
                }
              }
            }}
          />
        </div>
      }
    >
      <B.Tooltip content="Filter by tags" disabled={!showTooltip}>
        <B.AnchorButton
          minimal={minimal}
          active={!!value.tagIds.size}
          icon={<B.Icon icon="filter" color={colors.darkestGray} />}
          small
        />
      </B.Tooltip>
    </B.Popover>
  );
};

export type ImageryFilterValue =
  | 'all'
  | 'purchased'
  | 'not-purchased'
  | 'not-purchased-submeter'
  | 'none';

export const ImageryStatusFilter: React.FunctionComponent<
  React.PropsWithChildren<
    FilterProps & {
      isHiddenImageryShownAsAvailable: boolean;
      setIsHiddenImageryShownAsAvailable: (show: boolean) => void;
      openPopoverForTest?: boolean;
    }
  >
> = ({
  column,
  setFilter,
  isHiddenImageryShownAsAvailable,
  setIsHiddenImageryShownAsAvailable,
  openPopoverForTest,
}) => {
  const value: ImageryFilterValue = column.filterValue || 'all';

  return (
    <B.Popover
      position={B.Position.BOTTOM}
      usePortal={false}
      isOpen={openPopoverForTest}
      content={
        <List
          items={[
            {id: 'all', text: 'All', isSelected: value === 'all'},
            {id: 'sep', text: '', isSeparator: true},
            {id: 'purchased', text: 'Ordered', isSelected: value === 'purchased'},
            {
              id: 'not-purchased',
              text: 'Unordered all',
              isSelected: value === 'not-purchased',
            },
            {
              id: 'not-purchased-submeter',
              text: 'Unordered sub-meter',
              isSelected: value === 'not-purchased-submeter',
            },
            {id: 'sep2', text: '', isSeparator: true},
            {id: 'none', text: 'No Imagery', isSelected: value === 'none'},
            {id: 'sep3', text: '', isSeparator: true},
            {
              id: 'hidden',
              text: 'Show Hidden as Available',
              icon: isHiddenImageryShownAsAvailable ? 'small-tick' : 'blank',
            },
          ]}
          onClickItem={({id}) => {
            if (id === 'hidden') {
              setIsHiddenImageryShownAsAvailable(!isHiddenImageryShownAsAvailable);
            } else {
              // For "all" we clear, rather than having "#imagery=all" in the
              // location field
              setFilter(column.id, id === 'all' ? undefined : id);
            }
          }}
        />
      }
    >
      <B.Button
        minimal
        active={value !== 'all'}
        icon={<B.Icon icon="filter" color={colors.darkestGray} />}
        small
      />
    </B.Popover>
  );
};

// TODO(fiona): This should also show email addresses that have been assigned to
// but don’t have an org user.
export const AssigneeFilter: React.FunctionComponent<
  React.PropsWithChildren<
    FilterProps & {
      organizationUsers: ApiOrganizationUser[];
      openPopoverForTest?: boolean;
    }
  >
> = ({column, setFilter, organizationUsers, openPopoverForTest}) => {
  const value: string | null = column.filterValue === undefined ? null : column.filterValue;

  return (
    <B.Popover
      position={B.Position.BOTTOM}
      usePortal={false}
      isOpen={openPopoverForTest}
      content={
        <List
          className={cs.actionMenu}
          items={[
            {id: 'all', text: 'All Properties', isSelected: value === null},
            {id: 'sep', text: '', isSeparator: true},
            {id: 'any', text: 'Any Assignee', isSelected: value === '*'},
            {id: 'none', text: 'No Assignee', isSelected: value === ''},
            {id: 'sep', text: '', isSeparator: true},
            ...makeUserListItems(organizationUsers, (u) => value === u.email),
          ]}
          onClickItem={({id}) => {
            let newValue: string | undefined = id;
            if (newValue === 'all') {
              newValue = undefined;
            } else if (newValue === 'any') {
              newValue = '*';
            } else if (newValue === 'none') {
              newValue = '';
            }
            setFilter(column.id, newValue);
          }}
        />
      }
    >
      <B.Button
        minimal
        active={value !== null}
        icon={<B.Icon icon="filter" color={colors.darkestGray} />}
        small
      />
    </B.Popover>
  );
};

export const DateFilter: React.FunctionComponent<
  React.PropsWithChildren<
    FilterProps & {
      openPopoverForTest?: boolean;
    }
  >
> = ({column, setFilter, openPopoverForTest}) => {
  const value: string | null = column.filterValue === undefined ? null : column.filterValue;

  return (
    <B.Popover
      position={B.Position.BOTTOM}
      usePortal={false}
      isOpen={openPopoverForTest}
      content={
        <List
          className={cs.actionMenu}
          items={[
            {id: 'all', text: 'All', isSelected: value === null},
            {id: 'sep', text: '', isSeparator: true},
            {id: 'any', text: 'Any Date', isSelected: value === '*'},
            {id: 'none', text: 'No Date', isSelected: value === ''},
            {id: 'sep', text: '', isSeparator: true},
            {id: '7', text: 'Next 7 days', isSelected: value === '7'},
            {id: '30', text: 'Next 30 days', isSelected: value === '30'},
            {id: '90', text: 'Next 90 days', isSelected: value === '90'},
          ]}
          onClickItem={({id}) => {
            let newValue: string | undefined = id;
            if (newValue === 'all') {
              newValue = undefined;
            } else if (newValue === 'any') {
              newValue = '*';
            } else if (newValue === 'none') {
              newValue = '';
            }
            setFilter(column.id, newValue);
          }}
        />
      }
    >
      <B.Button
        minimal
        active={value !== null}
        icon={<B.Icon icon="filter" color={colors.darkestGray} />}
        small
      />
    </B.Popover>
  );
};

export type NoteCountFilterValue = null | '>0' | '0';
export const NoteCountFilter: React.FunctionComponent<
  React.PropsWithChildren<
    FilterProps & {
      openPopoverForTest?: boolean;
    }
  >
> = ({column, setFilter, openPopoverForTest}) => {
  const value: NoteCountFilterValue = column.filterValue === undefined ? null : column.filterValue;

  return (
    <B.Popover
      position={B.Position.BOTTOM}
      isOpen={openPopoverForTest}
      usePortal={false}
      content={
        <List
          className={cs.actionMenu}
          items={[
            {id: 'all', text: 'All', isSelected: value === null},
            {id: 'sep', text: '', isSeparator: true},
            {id: 'some', text: 'Has Some', isSelected: value === '>0'},
            {id: 'none', text: 'Has None', isSelected: value === '0'},
          ]}
          onClickItem={({id}) => {
            let newValue: string | undefined = id;
            if (newValue === 'all') {
              newValue = undefined;
            } else if (newValue === 'some') {
              newValue = '>0';
            } else if (newValue === 'none') {
              newValue = '0';
            }
            setFilter(column.id, newValue);
          }}
        />
      }
    >
      <B.Button
        minimal
        active={value !== null}
        icon={<B.Icon icon="filter" color={colors.darkestGray} />}
        small
      />
    </B.Popover>
  );
};

export type AlertCountFilterValue = I.Set<string>;

export function encodeAlertCountFilterValue(filterValue: AlertCountFilterValue) {
  return `${filterValue.toArray().join(',')}`;
}

export function decodeAlertCountFilterValue(filterString: string | undefined) {
  return filterString ? I.Set(filterString.split(',')) : I.Set([]);
}

export const AlertCountFilter: React.FunctionComponent<
  React.PropsWithChildren<
    FilterProps & {
      openPopoverForTest?: boolean;
      selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
      alertPolicies: AlertPolicy[];
    }
  >
> = ({column, setFilter, openPopoverForTest, alertPolicies, selectedFeatureCollection}) => {
  const value = decodeAlertCountFilterValue(column.filterValue);

  return (
    <B.Popover
      position={B.Position.BOTTOM}
      isOpen={openPopoverForTest}
      usePortal={false}
      content={
        <List
          className={cs.actionMenu}
          items={
            [
              ...getAlertListItems(
                'none', // First two props aren't relevant for us because we aren't using icon info
                'none',
                selectedFeatureCollection,
                alertPolicies,
                I.List([])
              ).map((item) => ({
                id: item.id,
                text: item.text,
                icon: value.has(item.id) ? 'tick' : 'blank',
              })),
              {id: 'sep1', text: '', isSeparator: true, icon: 'blank'},
              {id: 'reset', text: 'Reset filter', icon: 'blank'},
            ] as ListItem<{id: string}>[]
          }
          onClickItem={({id}, ev) => {
            ev.preventDefault();

            if (id === 'reset') {
              setFilter(column.id, undefined);
            } else {
              const isSelected = value.has(id);

              if (isSelected) {
                setFilter(column.id, encodeAlertCountFilterValue(value.remove(id)));
              } else {
                setFilter(column.id, encodeAlertCountFilterValue(value.add(id)));
              }
            }
          }}
        />
      }
    >
      <B.Button
        minimal
        active={!!value?.size}
        icon={<B.Icon icon="filter" color={colors.darkestGray} />}
        small
      />
    </B.Popover>
  );
};
