import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import {CarouselSlider} from 'app/components/CarouselSlider';

import cs from './LensLibraryView.styl';
import {LayerDataset, LibraryDataset, OverlayDataset} from './LibraryTypes';

const SplashDetails: React.FunctionComponent<
  React.PropsWithChildren<{item: LibraryDataset<LayerDataset | OverlayDataset>}>
> = ({item}) => (
  <div className={cs.detailsInfo}>
    <h2>{item.name}</h2>
    {'source' in item && <h3>{item.source}</h3>}
    <div className={classnames(cs.detailsText, cs.tags, cs.datasetType)}>
      {'type' in item && (
        <B.Tag minimal round>
          {item.type}
        </B.Tag>
      )}
      {'resolution' in item && <B.Tag round minimal large>{`${item.resolution}m`}</B.Tag>}
      {item.tags?.map((t) => (
        <B.Tag key={t.label} round minimal large style={{backgroundColor: t.color}}>
          {t.label}
        </B.Tag>
      ))}
    </div>
    <div className={cs.detailsText}>{item.description}</div>
  </div>
);

const LibrarySplash: React.FunctionComponent<
  React.PropsWithChildren<{
    item: LibraryDataset<LayerDataset | OverlayDataset>;
    openDetails: () => void;
  }>
> = ({item, openDetails}) => {
  const preview =
    'highResPreview' in item && item.highResPreview ? item.highResPreview : item.thumbnailUrl;
  return (
    <div className={cs.librarySplash} onClick={() => openDetails()}>
      <img src={preview} />
      <div className={cs.librarySplashOverlay}>
        <SplashDetails item={item} />
      </div>
    </div>
  );
};

export const LibrarySplashCarousel: React.FunctionComponent<
  React.PropsWithChildren<{
    featuredItems: LibraryDataset<OverlayDataset | LayerDataset>[];
    openDetails: (string) => void;
    header: string;
  }>
> = ({featuredItems, openDetails, header}) => {
  // We're doing this somewhat more convoluted thing where we track active index
  // instead of just passing the layer and the handler because clicks arent registering
  // properly on each slide. Instead we localize our state here, using a provided
  // callback for the carousel to set the soon-to-be active slide's index, and then
  // open whatever the active slide is from all slides. It'd also be cleaner to wrap
  // the carousel in an element that does this, but then we capture clicks on the dots.
  const [currentSlide, setCurrentSlide] = React.useState(0);
  return (
    <>
      <h2 className={cs.splashHeader}>{header}</h2>
      <CarouselSlider
        settings={{
          beforeChange: (_, nextIndex) => setCurrentSlide(nextIndex),
          slidesToShow: 1,
          fade: true,
          arrows: false,
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 10000,
          speed: 1000,
        }}
        dotSize={'largeDots'}
      >
        {featuredItems.map((item, key) => (
          <LibrarySplash
            key={key}
            item={item}
            openDetails={() => {
              const currentItem = featuredItems[currentSlide];
              openDetails(currentItem.libraryKey);
            }}
          />
        ))}
      </CarouselSlider>
    </>
  );
};
