import * as I from 'immutable';
import React from 'react';

import api from 'app/modules/Remote/api';
import {
  ApiNotificationPref,
  ApiNotificationScope,
  ApiNotificationType,
} from 'app/modules/Remote/Organization';
import {UseApiGetMeta, useApiGet} from 'app/utils/hookUtils';
import * as immutableUtils from 'app/utils/immutableUtils';

export interface NotificationPrefsActions {
  setNotificationPref: (
    pId: string,
    type: ApiNotificationType,
    scope: ApiNotificationScope
  ) => Promise<void>;
}

export interface NotificationPrefsValue {
  notificationPrefs: I.ImmutableOf<ApiNotificationPref[]> | null;
  meta: UseApiGetMeta<I.ImmutableOf<ApiNotificationPref[]>>;
  actions: NotificationPrefsActions;
}

const NotificationPrefsContext = React.createContext<NotificationPrefsValue | undefined>(undefined);

const NotificationPrefsProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    prefs?: typeof api.prefs;
  }>
> = ({children, prefs = api.prefs}) => {
  const [{value: notificationPrefs = null}, meta] = useApiGet(
    async (prefs) => (await prefs.notifications.fetch()).get('data'),
    [prefs]
  );

  const actions = React.useMemo<NotificationPrefsActions>(
    () => ({
      setNotificationPref: async (
        pId: string,
        type: ApiNotificationType,
        scope: ApiNotificationScope
      ) => {
        // We keep track of the most recent version of the row from the server,
        // starting with the pre-update version. In case of failure, we’ll
        // revert to this.
        let serverRow: I.ImmutableOf<ApiNotificationPref> | null = null;

        // Remove any existing pref for this project / type combination, and
        // optimistically add the new one. Necessary so that the <select>
        // dropdown doesn’t flash to its old value during the server update.
        meta.updateLocal(
          (notificationPrefs) =>
            notificationPrefs &&
            notificationPrefs
              .filter((p) => {
                if (p!.get('projectId') === pId && p!.get('type') === type) {
                  serverRow = p!;

                  return false;
                } else {
                  return true;
                }
              })
              .toList()
              .push(
                immutableUtils.toImmutableMap<ApiNotificationPref>({
                  projectId: pId,
                  scope,
                  type,
                })
              )
        );

        try {
          serverRow = (await prefs.notifications.set(pId, type, scope)).get('data');
        } catch (e) {
          // If the API request fails, "serverRow" will be the saved version of
          // the preference from before.
          throw e;
        } finally {
          // Remove the optimistically added row and add in serverRow, which
          // will either be the updated one from our set request or the saved
          // one if the request failed.
          meta.updateLocal(
            (notificationPrefs) =>
              notificationPrefs &&
              notificationPrefs
                .filter((p) => !(p!.get('projectId') === pId && p!.get('type') === type))
                .concat(serverRow !== null ? I.List([serverRow]) : I.List())
                .toList()
          );
        }
      },
    }),
    [prefs, meta]
  );

  return (
    <NotificationPrefsContext.Provider value={{notificationPrefs, meta, actions}}>
      {children}
    </NotificationPrefsContext.Provider>
  );
};

export function useNotificationPrefs(): NotificationPrefsValue {
  const value = React.useContext(NotificationPrefsContext);

  if (value === undefined) {
    throw new Error('useNotificationPrefs must be beneath a NotificationPrefsProvider');
  }

  return value;
}

export const FakeNotificationPrefsProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    prefs?: I.ImmutableOf<ApiNotificationPref[]> | null;
    action?: (name: string) => () => any;
  }>
> = ({children, prefs = I.List([]), action = () => () => null}) => {
  const value = React.useMemo<NotificationPrefsValue>(
    () => ({
      notificationPrefs: prefs,
      meta: {
        loading: prefs === null,
        refresh: action('refresh'),
        updateLocal: action('updateLocal'),
      },
      actions: {setNotificationPref: action('setNotificationPref')},
    }),
    [prefs, action]
  );

  return (
    <NotificationPrefsContext.Provider value={value}>{children}</NotificationPrefsContext.Provider>
  );
};

export default NotificationPrefsProvider;
