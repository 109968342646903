import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import justsatSrc from 'app/assets/images/justsat.png';
import layersStackSrc from 'app/assets/images/layers_stack.png';
import AppNav from 'app/components/AppNav';
import LayersIcon from 'app/components/GlobalSettings/Library/Icons/LayersIcon';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {getOrgPrefix} from 'app/utils/organizationUtils';
import {makeUuidPrefix} from 'app/utils/routeUtils';

import AssignedProperties from './myProperties';
import Notifications from './notifications';
import PortfoliosView from './portfolios';
import cs from './styles.styl';

const LensHome: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
  }>
> = ({history, organization, profile, loggedInUserActions}) => {
  const [projectsMap, , , {loading, error}] = useProjects();
  const noProjects = !loading && !projectsMap?.size;

  return (
    <>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      {noProjects ? (
        <EmptyHome history={history} organization={organization} error={error} />
      ) : (
        <div className={cs.pageContainer}>
          <div className={cs.pageHeader}>
            <h1>Home</h1>
            <B.Button
              small
              minimal
              intent="primary"
              icon={<LayersIcon />}
              text={'Explore the Lens Library'}
              onClick={() => history.push(`/${getOrgPrefix(organization)}/settings/layers`)}
            />
          </div>

          <div className={cs.pageContentContainer}>
            <div className={cs.sectionContainer}>
              <Notifications organization={organization} history={history} />
              <AssignedProperties profile={profile} history={history} organization={organization} />
            </div>
            <div className={cs.sectionContainer}>
              <PortfoliosView profile={profile} organization={organization} history={history} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const EmptyHome: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    history: History;
    error?: boolean;
  }>
> = ({history, organization, error = false}) => {
  const orgIdPrefix = makeUuidPrefix(organization.get('id'));

  const EMPTY_STATE_CONTENT = {
    error: {
      header: 'Something went wrong',
      message: 'Please wait for a moment, then try refreshing the page.',
      action: () => {
        window.location.reload();
      },
      actionText: 'Refresh page',
    },
    noProjects: {
      header: 'Welcome to Lens!',
      message: (
        <>
          <div>When your organization adds its first portfolio, you'll see it here.</div>
          <div>Ready to get started?</div>
        </>
      ),
      action: () => {
        history.push(`/${orgIdPrefix}/admin/manageProperties`);
      },
      actionText: 'Add a portfolio',
    },
  };
  const mode = error ? 'error' : 'noProjects';

  return (
    <div className={cs.emptyState}>
      <img src={mode === 'error' ? justsatSrc : layersStackSrc} className={cs.emptyStateImage} />
      <div className={cs.emptyStateHeader}>{EMPTY_STATE_CONTENT[mode].header}</div>
      <div>{EMPTY_STATE_CONTENT[mode].message}</div>
      <B.Button
        intent="primary"
        large
        onClick={EMPTY_STATE_CONTENT[mode].action}
        text={EMPTY_STATE_CONTENT[mode].actionText}
      />
    </div>
  );
};

export default LensHome;
