import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import cs from './styles.styl';

export interface ToolbarItem {
  menuKey: string;
  buttonText?: string | React.ReactNode;
  icon?: B.IconName;
  intent?: B.Intent;
  tooltipText?: string;
  notInteractive?: boolean;

  isLoading?: boolean;
  isDisabled?: boolean;
  openPopoverForTest?: boolean;
  addMargin?: boolean;

  onClick?: (e: React.MouseEvent) => unknown;
  renderPopover?: () => string | JSX.Element;
}

const Toolbar: React.FunctionComponent<
  React.PropsWithChildren<{
    items: ToolbarItem[];
    className?: string;
  }>
> = ({items, className}) => {
  return (
    <B.Navbar className={classnames(cs.toolbar, className)}>
      <B.NavbarGroup align={B.Alignment.LEFT}>
        {items.map(
          ({
            buttonText,
            tooltipText,
            icon,
            isLoading,
            isDisabled,
            openPopoverForTest,
            onClick,
            renderPopover,
            notInteractive,
            addMargin = false,
            intent = 'none',
            menuKey,
          }) => (
            <B.Popover
              key={menuKey}
              hasBackdrop
              minimal
              autoFocus={false}
              position="bottom-left"
              isOpen={openPopoverForTest}
              disabled={isDisabled}
              content={renderPopover && renderPopover()}
              lazy={true}
            >
              <div
                className={classnames(cs.buttonContainer, {
                  [cs.addMargin]: addMargin,
                  [cs.notInteractiveCursor]: !!notInteractive,
                })}
              >
                <B.Tooltip content={tooltipText}>
                  <B.AnchorButton
                    minimal
                    rightIcon={!icon ? 'caret-down' : null}
                    icon={icon}
                    intent={intent}
                    disabled={isDisabled}
                    text={
                      buttonText ? (
                        <span
                          className={classnames(cs.buttonText, {
                            [B.Classes.SKELETON]: isLoading,
                          })}
                        >
                          {buttonText}
                        </span>
                      ) : null
                    }
                    onClick={onClick}
                  ></B.AnchorButton>
                </B.Tooltip>
              </div>
            </B.Popover>
          )
        )}
      </B.NavbarGroup>
    </B.Navbar>
  );
};

export default Toolbar;
