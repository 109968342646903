import * as B from '@blueprintjs/core';
import geojson from 'geojson';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import {createPortal} from 'react-dom';

import Map from 'app/components/DeclarativeMap';
import DrawLayer, {DrawingMode} from 'app/components/DeclarativeMap/DrawLayer';
import FeatureCollectionVector from 'app/components/DeclarativeMap/FeatureCollectionVector';
import GeoJsonFitter from 'app/components/DeclarativeMap/GeoJsonFitter';
import {GeometryOverlayContent} from 'app/components/DeclarativeMap/GeometryOverlaysContent';
import MapContent from 'app/components/DeclarativeMap/MapContent';
import MapGeocoderControl, {GeocoderResult} from 'app/components/DeclarativeMap/MapGeocoderControl';
import MapHelptext from 'app/components/DeclarativeMap/MapHelptext';
import MapLegend from 'app/components/DeclarativeMap/MapLegend';
import MapNavigationControl from 'app/components/DeclarativeMap/MapNavigationControl';
import MapStyle from 'app/components/DeclarativeMap/MapStyle';
import MapStyleControl from 'app/components/DeclarativeMap/StyleControl';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {
  GeometryOverlaySetting,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {MeasurementSystem} from 'app/modules/Remote/Organization';
import colors from 'app/styles/colors.json';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as mapUtils from 'app/utils/mapUtils';
import {REGRID_OVERLAY} from 'app/utils/overlayUtils';

import {NumberedFeature} from './ManageFeaturesProvider';
import {BLANK_HYDRATED_FEATURE_COLLECTION} from './ManageFeaturesView';
import cs from './styles.styl';

import {ManagePropertiesMode} from '.';

const NEW_PROPERTIES_COLOR = colors.darkOrange;
const EXISTING_PROPERTIES_COLOR = colors.brightYellow;

type AllowsDrawing =
  | {
      allowDrawing: true;
      isEditMode: boolean;
      featureToEdit: null | geojson.Feature;
      setFeatureToEdit: React.Dispatch<React.SetStateAction<geojson.Feature | null>>;
      setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
      onMapFeatureClick?: (
        f: geojson.Feature<geojson.Polygon | geojson.MultiPolygon>,
        ev: mapboxgl.MapLayerMouseEvent
      ) => void;
      drawingMode?: DrawingMode;
    }
  | {
      allowDrawing?: false;
      isEditMode?: undefined;
      featureToEdit?: undefined;
      setFeatureToEdit?: undefined;
      setIsEditMode?: undefined;
      onMapFeatureClick?: undefined;
      drawingMode?: undefined;
    };

export type Props = AllowsDrawing & {
  existingFeatureCollection: HydratedFeatureCollection | null | undefined;
  newFeatureCollection: HydratedFeatureCollection | null;
  hoveredFeatureIds: I.Set<number>;
  hoverFeatureById: (fId: number | null) => void;
  selectedFeature: NumberedFeature | null;
  measurementSystem: MeasurementSystem;
  mode: ManagePropertiesMode;
  overlaySetting?: GeometryOverlaySetting;
  firebaseToken: string | null;
};

const ManageFeaturesMap: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  existingFeatureCollection,
  newFeatureCollection,
  selectedFeature,
  hoveredFeatureIds,
  hoverFeatureById,
  featureToEdit,
  setFeatureToEdit,
  isEditMode,
  setIsEditMode,
  mode,
  allowDrawing = false,
  measurementSystem,
  overlaySetting,
  firebaseToken,
  onMapFeatureClick,
  drawingMode = 'draw_polygon',
}) => {
  const [mapStyle, setMapStyle] = React.useState<mapUtils.MapStyle>(
    allowDrawing ? mapUtils.MAPBOX_SATELLITE_STREETS_STYLE : mapUtils.MAPBOX_DARK_STYLE
  );
  const [showParcelData, setShowParcelData] = React.useState(true);
  const [geocoderResult, setGeocoderResult] = React.useState<GeocoderResult | null>(null);

  const EMPTY_NEW_FEATURE_COLLECTION = BLANK_HYDRATED_FEATURE_COLLECTION.set('id', 1);
  const EMPTY_EXISTING_FEATURE_COLLECTION = BLANK_HYDRATED_FEATURE_COLLECTION.set('id', 2);
  const REGRID_FC = React.useMemo(() => I.fromJS(REGRID_OVERLAY) as HydratedFeatureCollection, []);

  const newFeatures = React.useMemo(
    () =>
      newFeatureCollection
        ? geoJsonUtils.featureCollection(newFeatureCollection.get('features').toJS())
        : null,
    [newFeatureCollection]
  );

  const existingFeatures: geojson.FeatureCollection | null = React.useMemo(
    () =>
      existingFeatureCollection
        ? geoJsonUtils.featureCollection(existingFeatureCollection.get('features').toJS())
        : null,
    [existingFeatureCollection]
  );

  // Was the query all coordinate?
  // Notes: we may have coords separated by comma or space or some combination
  const matchedQuery = geocoderResult && geocoderResult.query.match(/([-0-9.]+)[ ,]+([-0-9.]+)/);

  // The geocoder result may have a point or a bbox, but we may
  // want to use the coordinates in the query, if we matched any.
  const geocoderGeometry = React.useMemo(() => {
    if (!geocoderResult) {
      return null;
    }

    // If we have a matched query, set as a point. If we have a bbox, use the polygon. Otherwise, use result geom.
    switch (true) {
      case !!matchedQuery:
        return {type: 'Point', coordinates: [matchedQuery[2], matchedQuery[1]]};
      case !!geocoderResult.result.bbox:
        return {type: 'Polygon', coordinates: geocoderResult.result.bbox};
      default:
        return geocoderResult.result.geometry;
    }
  }, [geocoderResult]);

  // Feature(s) for the GeoJsonFitter to focus the map on. A geocoder result takes precedence, followed
  // by selected feature, then the entire newly uploaded collection, then the pre-existing collection.
  const fitterFeatures = (geocoderGeometry ||
    selectedFeature ||
    newFeatures ||
    existingFeatures) as unknown as geojson.GeoJSON | null;

  // Once we move away from a geocoder result and focus another feature, we want
  // to clear it, so that We don't refit the map to an old search location.
  React.useEffect(() => {
    setGeocoderResult(null);
  }, [selectedFeature, newFeatures, existingFeatures]);

  return (
    <div className={cs.map}>
      <MapHelptext
        hide={!((isEditMode && allowDrawing) || mode === 'parcel')}
        top={mode === 'parcel' ? '6rem' : '2rem'}
      >
        <div className={cs.mapHelpText}>
          {isEditMode && allowDrawing ? (
            <>
              <div>Click on the map to start drawing a property.</div>
              {drawingMode === 'draw_polygon' && (
                <div>Double-click or press enter to complete.</div>
              )}
              {drawingMode === 'draw_rectangle' && <div>Click again to complete.</div>}
            </>
          ) : (
            <>
              <div>Click on a parcel to add property.</div>
              <div>Shift-click to add another parcel to that property.</div>
            </>
          )}
        </div>
      </MapHelptext>
      {/* hide the navigationControl that gets added to DeclarativeMap-- we will
      render it separately below with the MapNavigationControl component to allow
      us to conditionally show/hide it */}
      <Map
        firebaseToken={firebaseToken}
        controlPosition="top-right"
        hideNavigationControl
        // Disabiling boxZoom allows us to use shift+click for the parcel data interactions
        // otherwise mapbox's boxZoom handler catches those events and they don't make it to
        // our event handler
        mapOptions={{boxZoom: false}}
      >
        {(map, isMapLoaded) => {
          const layers = existingFeatureCollection
            ? [{color: EXISTING_PROPERTIES_COLOR, name: 'Existing Properties'}]
            : [];
          if (newFeatureCollection) {
            layers.push({
              color: NEW_PROPERTIES_COLOR,
              name: mode === 'overlay' ? 'New Overlay' : 'New Properties',
            });
          }
          return (
            <React.Fragment>
              <MapStyle map={map} style={mapStyle} />
              {!isEditMode && (
                <>
                  <MapNavigationControl
                    map={map}
                    isMapLoaded={isMapLoaded}
                    position={'top-right'}
                  />
                  <MapStyleControl
                    map={map}
                    isMapLoaded={isMapLoaded}
                    styles={mapUtils.MAPBOX_STYLES}
                    onChangeMapStyle={setMapStyle}
                    selectedStyle={mapStyle}
                    position={'top-right'}
                  />
                </>
              )}
              <MapContent
                map={map}
                isMapLoaded={isMapLoaded}
                controls={[new mapboxgl.ScaleControl({unit: measurementSystem})]}
                controlPosition={'bottom-left'}
              />
              {allowDrawing && !isEditMode && (
                <MapGeocoderControl
                  map={map}
                  isMapLoaded={isMapLoaded}
                  position={'top-left'}
                  onResult={setGeocoderResult}
                />
              )}
              {(existingFeatureCollection || newFeatureCollection) && !isEditMode && (
                <MapLegend layers={layers} className={cs.mapLegend} />
              )}
              {mode === 'parcel' && firebaseToken && (
                <>
                  <ParcelDataMapControl
                    map={map}
                    isMapLoaded={isMapLoaded}
                    showParcelData={showParcelData}
                    setShowParcelData={setShowParcelData}
                  />
                  {showParcelData && onMapFeatureClick && (
                    <GeometryOverlayContent
                      map={map}
                      isMapLoaded={isMapLoaded}
                      // TODO: change color?
                      color={'#000'}
                      hydratedFeatureCollection={REGRID_FC}
                      onClick={(
                        f: I.ImmutableOf<ApiFeature>,
                        _fc: HydratedFeatureCollection,
                        ev: mapboxgl.MapLayerMouseEvent
                      ) => {
                        if (!isEditMode && f) {
                          onMapFeatureClick(f.toJS(), ev);
                        }
                      }}
                      isVisible={true}
                      defaultFilled={true}
                      defaultUnfilled={false}
                      // hack to make the fill exist so you can click in the middle, but not noticable to the eye
                      fillOpacityOverride={0.01}
                    />
                  )}
                </>
              )}
              {/* Dont need this for overlays */}
              {allowDrawing &&
                setFeatureToEdit &&
                isEditMode &&
                setIsEditMode &&
                featureToEdit !== undefined && (
                  <DrawLayer
                    map={map}
                    isMapLoaded={isMapLoaded}
                    visible={true}
                    drawingMode={drawingMode}
                    feature={featureToEdit}
                    setFeature={setFeatureToEdit}
                    onDeselectFeature={() => setIsEditMode(false)}
                  />
                )}
              <GeoJsonFitter
                map={map}
                geoJson={fitterFeatures}
                fitBoundsOptions={{animate: false, maxZoom: 17}}
                geoJsonComparison={'shallow'}
              />
              {mode === 'overlay' && newFeatureCollection ? (
                <GeometryOverlayContent
                  map={map}
                  isMapLoaded={isMapLoaded}
                  color={overlaySetting?.color || NEW_PROPERTIES_COLOR}
                  hydratedFeatureCollection={newFeatureCollection}
                  onClick={() => {}}
                  isVisible={true}
                  defaultFilled={overlaySetting?.defaultFilled || true}
                  defaultUnfilled={overlaySetting?.defaultUnfilled || false}
                />
              ) : (
                <>
                  <FeatureCollectionVector
                    map={map}
                    isMapLoaded={isMapLoaded}
                    featureCollection={newFeatureCollection ?? EMPTY_NEW_FEATURE_COLLECTION}
                    selectedFeatureIds={I.Set([])}
                    selectFeatureById={() => null}
                    hoveredFeatureIds={hoveredFeatureIds}
                    hoverFeatureById={hoverFeatureById}
                    labelOnHover={true}
                    customColor={NEW_PROPERTIES_COLOR}
                    // make sure new properties are always on top
                    sendToTop={true}
                  />

                  <FeatureCollectionVector
                    map={map}
                    isMapLoaded={isMapLoaded}
                    featureCollection={
                      existingFeatureCollection ?? EMPTY_EXISTING_FEATURE_COLLECTION
                    }
                    selectedFeatureIds={I.Set([])}
                    selectFeatureById={() => null}
                    hoveredFeatureIds={hoveredFeatureIds}
                    hoverFeatureById={hoverFeatureById}
                    labelOnHover={true}
                    customColor={EXISTING_PROPERTIES_COLOR}
                  />
                </>
              )}
            </React.Fragment>
          );
        }}
      </Map>
    </div>
  );
};

class ParcelDataMapControl extends React.Component<{
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  showParcelData: boolean;
  setShowParcelData: any;
}> {
  private parcelDataControlButtonEl = document.createElement('div');
  private parcelDataControlButton = new mapUtils.PortalControl(this.parcelDataControlButtonEl);

  private renderContent = () => {
    const {showParcelData, setShowParcelData} = this.props;
    return (
      <B.Tooltip
        content={showParcelData ? 'Hide US Parcel Data' : 'Show US Parcel Data'}
        position={'left'}
      >
        <B.AnchorButton
          icon={showParcelData ? 'eye-off' : 'eye-open'}
          onClick={() => setShowParcelData((s) => !s)}
          className={cs.mapIcon}
        />
      </B.Tooltip>
    );
  };

  render() {
    const {map, isMapLoaded} = this.props;
    return (
      <React.Fragment>
        <MapContent
          map={map}
          isMapLoaded={isMapLoaded}
          controls={[this.parcelDataControlButton]}
          controlPosition={'top-right'}
        />
        {createPortal(this.renderContent(), this.parcelDataControlButtonEl)}
      </React.Fragment>
    );
  }
}

export default ManageFeaturesMap;
