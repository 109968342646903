import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {ApiImageryPrices} from 'app/modules/Remote/Imagery';
import * as layerUtils from 'app/utils/layerUtils';

import {LayerDataset, LibraryDataset, LibraryDatasetType, OverlayDataset} from './LibraryTypes';

// adapted from https://gist.github.com/changhuixu/de092ee55a9e115abba988910bd68d41
// to handle JSX nodes/objects and remove IE handling since this is for internal use only

type CsvRow = {
  [key: string]: string | number | Date | JSX.Element | React.ReactNode | null | undefined;
};
function exportToCsv(filename: string, rows: CsvRow[]) {
  if (!rows || !rows.length) {
    return;
  }
  const separator = '\t';
  const keys = Object.keys(rows[0]);
  const csvContent =
    keys.join(separator) +
    '\n' +
    rows
      .map((row) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            if (cell instanceof Date) {
              cell = cell.toLocaleString();
            } else if (React.isValidElement(cell)) {
              //check if we're passing a JSX node and convert it to a string
              cell = ReactDOMServer.renderToString(cell);
            } else {
              cell = JSON.stringify(cell).replace(/"/g, '');
            }
            return cell;
          })
          .join(separator);
      })
      .join('\n');

  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

  // if we don't want to do this lil link trick we could also just have this function
  // return a blob and control the download in the click handler but i kinda like this
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}

export const exportLibraryDatasetsToCsv = (
  datasets: LibraryDataset<LayerDataset | OverlayDataset>[],
  imageryPrices: ApiImageryPrices
) => {
  return exportToCsv(
    'libraryDatasets.csv',
    datasets.map((dataset: LibraryDataset<LayerDataset | OverlayDataset>) => {
      const {sourceId} = layerUtils.parseLayerKey(dataset.libraryKey);
      return {
        key: dataset.libraryKey,
        name: dataset.name,
        type: dataset.type,
        description: dataset.description,
        thumbnailUrl: dataset.thumbnailUrl,
        resolution: dataset.type === LibraryDatasetType.LAYER ? dataset.resolution : '',
        source: dataset.source,
        centsPerAcre: imageryPrices[sourceId],
        // marketing is fine w just two tags for now and this single column format better for webflow
        tag1string: dataset.tags[0]?.label,
        tag1color: dataset.tags[0]?.color,
        tag2string: dataset.tags[1]?.label,
        tag2color: dataset.tags[1]?.color,
      };
    })
  );
};
