import mapboxgl from 'mapbox-gl';
import React from 'react';
import {createRoot} from 'react-dom/client';

import {initSentry} from 'app/tools/Sentry';

import history from './history';
import App from './view';

const MOUNT_ELEMENT_ID = 'root';

initSentry(history);
require('velocity-animate');
require('velocity-animate/velocity.ui');

// Prewarm was added in 1.10 and the types haven't caught up to it
(mapboxgl as any).prewarm();

const container = document.getElementById(MOUNT_ELEMENT_ID);
const root = createRoot(container!);
root.render(<App history={history} />);
