import * as I from 'immutable';

import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {SYSTEM_USER_ID} from 'app/utils/constants';
import * as CONSTANTS from 'app/utils/constants';

import {isUpstreamAndMemberOfInternalOrg} from './featureFlags';
import {useKeyPress} from './hookUtils';

export const UPSTREAM_TEST_ORG_ID = '-KQ66NhS23Am_QKkk7CR';

export function isUpstreamTeamEmail(email: string | null) {
  return /^team\+\S*@upstream.tech$/.test(email || '');
}

export function isSystemUserId(userId: string) {
  return userId === SYSTEM_USER_ID;
}

export function isUpstreamEmail(email: string | null) {
  return /^\S*@upstream.tech$/.test(email || '');
}

export function showInternalTools(
  profile: I.ImmutableOf<ApiOrganizationUser>,
  organization: I.ImmutableOf<ApiOrganization>,
  optionKey?: boolean
) {
  return (
    ([UPSTREAM_TEST_ORG_ID].includes(organization.get('id')) || optionKey !== false) &&
    isUpstreamEmail(profile.get('email'))
  );
}

// Check if alt button is pressed, and then if user is allowed to see internal tools
export function useShowInternalToolsOnAltPress(profile: I.ImmutableOf<ApiOrganizationUser>) {
  const altPressed = useKeyPress(['AltLeft', 'AltRight']);
  return altPressed && isUpstreamAndMemberOfInternalOrg(profile);
}

export function showAnalyzeAreaInternalTools(
  profile: I.ImmutableOf<ApiOrganizationUser>,
  organization: I.ImmutableOf<ApiOrganization>,
  optionKey?: boolean
) {
  return (
    ([UPSTREAM_TEST_ORG_ID].includes(organization.get('id')) || optionKey !== false) &&
    isUpstreamEmail(profile.get('email'))
  );
}

export function mayOrderImagery(role: ApiOrganizationUser['role']) {
  return role === 'owner';
}

/**
 * Returns true if the user is currently accessing Lens as their logged-in Organization,
 * which is a parent org. Behavior changes a little for the internal org.
 */
export function shouldSeeOrgSwitcher(
  _currentOrganization: I.ImmutableOf<ApiOrganization>,
  profileOrganizations: I.List<I.MapAsRecord<I.ImmutableFields<ApiOrganization>>>
) {
  const profileOrg = profileOrganizations.get(0);
  const hasMultipleProfileOrgs = profileOrganizations.size > 1;

  const isInternalOrgUser = profileOrg.get('id') == CONSTANTS.INTERNAL_ORGANIZATION_ID;
  const childOrgs = profileOrg.get('childOrganizations');
  const profileOrgIsAParentOrg = childOrgs.size > 0;
  return isInternalOrgUser
    ? true // always show org switcher if they're from the internal org
    : profileOrgIsAParentOrg || hasMultipleProfileOrgs;
}

export function getSuffixedUserName(user: ApiOrganizationUser) {
  const suffix = user.role === CONSTANTS.USER_ROLE_READONLY ? ' (read-only)' : '';
  return user.name + suffix;
}
