import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {BasemapView} from 'app/components/DeclarativeMap/ManageBasemapDialog';
import {PushNotificationFn, usePushNotification} from 'app/components/Notification';
import {
  ApiOrganization,
  ApiOrganizationAreaUnit,
  ApiOrganizationUser,
  getAreaUnit,
  getOrgBasemapStyle,
  getShowSmartSummaries,
} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {recordEvent} from 'app/tools/Analytics';
import * as CONSTANTS from 'app/utils/constants';
import featureFlags from 'app/utils/featureFlags';
import {useStateWithDeps} from 'app/utils/hookUtils';
import {MAPBOX_STYLES, MapStyle} from 'app/utils/mapUtils';

import * as GlobalSettings from './GlobalSettings';
import ImageFileUpload from './ImageFileUpload';
import cs from './OrganizationView.styl';

const NAME_INPUT_ID = 'name-input';

const OrganizationView: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
    allowOrganizationLogoUpload?: boolean;
  }>
> = ({
  organization,
  profile,
  loggedInUserActions,
  allowOrganizationLogoUpload = featureFlags.ORGANIZATION_LOGO_UPLOAD(organization, profile),
}) => {
  const pushNotification = usePushNotification();

  return (
    <GlobalSettings.Body title="Organization">
      <div className={cs.section}>
        <div className={cs.sectionHead}>
          <h1>Details</h1>
        </div>
        <div className={cs.sectionControls}>
          <div className={cs.stacked}>
            <div className={cs.inline}>
              <OrganizationName
                organization={organization}
                profile={profile}
                pushNotification={pushNotification}
                setOrganizationName={loggedInUserActions.setOrganizationName}
              />
            </div>
            {allowOrganizationLogoUpload && (
              <div className={cs.inline}>
                <OrganizationLogo
                  organization={organization}
                  pushNotification={pushNotification}
                  setOrganizationLogo={loggedInUserActions.setOrganizationLogo}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={cs.section}>
        <div className={cs.sectionHead}>
          <h1>Defaults</h1>
          <div className={cs.sectionHelpText}>
            These defaults affect all users in your organization.
          </div>
        </div>
        <div className={cs.sectionControls}>
          <div className={cs.stacked}>
            <OrganizationUnits
              organization={organization}
              profile={profile}
              pushNotification={pushNotification}
              setOrganizationUnits={loggedInUserActions.setOrganizationUnits}
            />
            <OrganizationBasemap
              organization={organization}
              profile={profile}
              pushNotification={pushNotification}
              setOrganizationBasemap={loggedInUserActions.setOrganizationBasemap}
            />
            {featureFlags.SMART_SUMMARIES(organization) && (
              <OrganizationShowSmartSummaries
                organization={organization}
                profile={profile}
                pushNotification={pushNotification}
                setOrganizationShowSmartSummaries={
                  loggedInUserActions.setOrganizationShowSmartSummaries
                }
              />
            )}
          </div>
        </div>
      </div>
    </GlobalSettings.Body>
  );
};

const OrganizationBasemap: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    pushNotification: PushNotificationFn;
    setOrganizationBasemap: LoggedInUserActions['setOrganizationBasemap'];
  }>
> = ({organization, profile, pushNotification, setOrganizationBasemap}) => {
  const id = organization.get('id');
  const basemapStyle: MapStyle = getOrgBasemapStyle(organization);
  const canUpdate = profile.get('role') === 'owner';

  const [isUpdating, setIsUpdating] = React.useState(false);

  const changeOrgBasemap = React.useCallback(
    async (value: MapStyle) => {
      try {
        setIsUpdating(true);
        await setOrganizationBasemap(id, value);
        pushNotification({
          message: 'Basemap style updated.',
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.SUCCESS,
          },
        });
      } catch (e) {
        pushNotification({
          message: `There was a problem updating basemap style. Please try again.`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [id, pushNotification, setOrganizationBasemap]
  );

  return (
    <div className={cs.field}>
      <label>Basemap style</label>
      <div>
        <B.Popover
          content={
            <div style={{padding: '1rem 1.5rem'}} className={B.Classes.POPOVER_DISMISS}>
              <BasemapView
                selectedStyle={basemapStyle}
                onChangeMapStyle={(value) => changeOrgBasemap(value)}
              />
            </div>
          }
          position={'right'}
        >
          <B.Tooltip
            content={'Only organization admins can update this setting.'}
            disabled={canUpdate}
          >
            <B.AnchorButton
              text={MAPBOX_STYLES.find((s) => s.id === basemapStyle)!.title}
              rightIcon="double-caret-vertical"
              className={cs.selectControl}
              disabled={isUpdating || !canUpdate}
            />
          </B.Tooltip>
        </B.Popover>
      </div>
    </div>
  );
};

const OrganizationUnits: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    pushNotification: PushNotificationFn;
    setOrganizationUnits: LoggedInUserActions['setOrganizationUnits'];
  }>
> = ({organization, profile, pushNotification, setOrganizationUnits}) => {
  const unitTypes: Record<string, string> = {
    [CONSTANTS.UNIT_AREA_ACRE]: 'Acres and Feet', // Imperial
    [CONSTANTS.UNIT_AREA_HECTARE]: 'Hectares and Meters', // Metric
  };
  const id = organization.get('id');
  const canUpdate = profile.get('role') === 'owner';
  const units: ApiOrganizationAreaUnit = getAreaUnit(organization);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const changeOrganizationUnits = React.useCallback(
    async (newUnits: ApiOrganizationAreaUnit) => {
      try {
        setIsUpdating(true);
        await setOrganizationUnits(id, newUnits);
        pushNotification({
          message: 'Organization units updated.',
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.SUCCESS,
          },
        });
      } catch (e) {
        pushNotification({
          message: `There was a problem updating organization units. Please try again.`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [id, pushNotification, setOrganizationUnits]
  );

  return (
    <div className={cs.field}>
      <label>Units</label>
      <B.Tooltip
        content={'Only organization admins can update this setting.'}
        disabled={canUpdate}
        className={cs.selectControl}
      >
        <B.HTMLSelect
          disabled={isUpdating || !canUpdate}
          fill
          value={units}
          onChange={(e) =>
            changeOrganizationUnits(e.currentTarget.value as ApiOrganizationAreaUnit)
          }
          aria-label={`Change units for ${organization.get('name')}`}
        >
          {Object.entries(unitTypes).map(([unitType, displayName]) => (
            <option key={unitType} value={unitType}>
              {displayName}
            </option>
          ))}
        </B.HTMLSelect>
      </B.Tooltip>
    </div>
  );
};

const OrganizationShowSmartSummaries: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    pushNotification: PushNotificationFn;
    setOrganizationShowSmartSummaries: LoggedInUserActions['setOrganizationShowSmartSummaries'];
  }>
> = ({organization, profile, pushNotification, setOrganizationShowSmartSummaries}) => {
  const id = organization.get('id');
  const canUpdate = profile.get('role') === 'owner';
  const shouldShowSmartSummaries: boolean = getShowSmartSummaries(organization);

  const available = 'Available';
  const hidden = 'Hidden';

  const [isUpdating, setIsUpdating] = React.useState(false);

  const changeSmartSummarySettings = React.useCallback(
    async (newValue: boolean) => {
      try {
        setIsUpdating(true);
        await setOrganizationShowSmartSummaries(id, newValue);
        pushNotification({
          message: `Smart summaries are now ${newValue ? 'visible' : 'hidden'}`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.SUCCESS,
          },
        });
      } catch (e) {
        pushNotification({
          message: `There was a problem updating this setting. Please try again.`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [id, pushNotification, setOrganizationShowSmartSummaries]
  );

  return (
    <div className={cs.field}>
      <label>
        Smart Summary in reports{' '}
        <a
          className={cs.supportLinkIcon}
          href="https://support.upstream.tech/article/158-smart-summary"
          target="_blank"
          rel="noreferrer noopener"
        >
          <B.Icon icon="help" intent="none" />
        </a>
      </label>
      <B.Tooltip
        content={'Only organization admins can update this setting.'}
        disabled={canUpdate}
        className={cs.selectControl}
      >
        <B.HTMLSelect
          disabled={isUpdating || !canUpdate}
          fill
          value={shouldShowSmartSummaries ? available : hidden}
          onChange={(e) => changeSmartSummarySettings(e.currentTarget.value === available)}
          aria-label={`Show or hide the Smart Summaries tool for ${organization.get('name')}`}
        >
          <option key={available} value={available}>
            {available}
          </option>
          <option key={hidden} value={hidden}>
            {hidden}
          </option>
        </B.HTMLSelect>
      </B.Tooltip>
    </div>
  );
};

const OrganizationLogo: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    pushNotification: PushNotificationFn;
    setOrganizationLogo: LoggedInUserActions['setOrganizationLogo'];
  }>
> = ({organization, pushNotification, setOrganizationLogo}) => {
  const id = organization.get('id');
  const logo = organization.getIn(['settings', 'logo']);

  const [isUpdating, setIsUpdating] = React.useState(false);

  const removeFile = React.useCallback(async () => {
    try {
      setIsUpdating(true);
      await setOrganizationLogo(id);
      recordEvent('Unset organization logo');
    } catch (e) {
      pushNotification({
        message: 'There was a problem removing your logo. Please try again.',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } finally {
      setIsUpdating(false);
    }
  }, [id, pushNotification, setOrganizationLogo]);

  const uploadFile = React.useCallback(
    async (file: File) => {
      try {
        setIsUpdating(true);
        await setOrganizationLogo(id, file);
        recordEvent('Set organization logo');
      } catch (e) {
        pushNotification({
          message: 'There was a problem uploading your logo. Please try again.',
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [id, pushNotification, setOrganizationLogo]
  );

  return (
    <div className={cs.field}>
      <label>Logo</label>
      <div className={cs.logo}>
        <p>
          Your logo will appear on the title page of{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.upstream.tech/article/18-generating-reports"
          >
            reports
          </a>
          .
        </p>
        <ImageFileUpload
          label="Logo"
          removeFile={removeFile}
          uploadFile={uploadFile}
          isUpdating={isUpdating}
          url={logo ? new URL(logo) : undefined}
        />
      </div>
    </div>
  );
};

const OrganizationName: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    pushNotification: PushNotificationFn;
    setOrganizationName: LoggedInUserActions['setOrganizationName'];
  }>
> = ({organization, profile, pushNotification, setOrganizationName}) => {
  const id = organization.get('id');
  const name = organization.get('name');

  const isEditor = profile.get('role') === 'owner';

  /**
   * Using useStateWithDeps to update the field if organization changes after render.
   *
   * At the time of writing the case for this is related to how the AuthProvider
   * determines what the current organization is. When a parent profile user looks
   * at a child org, this component will briefly render with the parent org's
   * settings. Asynchronously a useEffect in WithLoggedInAuth will see that we're
   * looking at a child org, and reset the current organization to the child org.
   * */
  const [nameFieldValue, setNameFieldValue] = useStateWithDeps(name, [name]);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const saveName = React.useCallback(async () => {
    // No-op if we don’t yet have an input element reference or if the current
    // input value has not changed.
    if (nameFieldValue === name) {
      return;
    }

    // Reset the input and no-op if the user attempts to save a name consisting
    // exclusively of whitespace.
    if (!nameFieldValue.trim()) {
      setNameFieldValue(name);
      return;
    }

    try {
      setIsUpdating(true);
      await setOrganizationName(id, nameFieldValue);
      pushNotification({
        message: 'Organization name updated.',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.SUCCESS,
        },
      });
    } catch (e) {
      // Reset the input and display an error toast if the update fails.
      setNameFieldValue(name);
      pushNotification({
        message: 'There was a problem updating the organization name. Please try again.',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } finally {
      setIsUpdating(false);
    }
  }, [id, name, nameFieldValue, pushNotification, setNameFieldValue, setOrganizationName]);

  return (
    <div className={cs.field}>
      <label htmlFor={NAME_INPUT_ID}>Name</label>
      {isEditor ? (
        <B.InputGroup
          id={NAME_INPUT_ID}
          disabled={isUpdating}
          value={nameFieldValue}
          onValueChange={(value) => setNameFieldValue(value)}
          onBlur={saveName}
        />
      ) : (
        name
      )}
    </div>
  );
};

export default OrganizationView;
