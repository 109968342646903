import React from 'react';

import View, {AppNavToasterContext, Props} from './view';

export {AppNavToasterContext};

const AppNavDataProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  return <View {...props} />;
};

export default AppNavDataProvider;
