import mapboxgl from 'mapbox-gl';
import React from 'react';

import MapContent from './MapContent';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
}

// component to render MapNavigationControl as a separate component, rather
// than rendering DeclarativeMap with hideNavigationControls=false. this is
// useful if you want to conditionally show/hide the nav controls.

export default class MapNavigationControl extends React.Component<Props> {
  render() {
    const {map, isMapLoaded, position} = this.props;

    const navigationControl = new mapboxgl.NavigationControl({showCompass: false});

    return (
      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[navigationControl]}
        controlPosition={position}
      />
    );
  }
}
