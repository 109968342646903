// Fix for https://github.com/tannerlinsley/react-table/issues/1797
//
// The bug is that useFilters modifies the row objects such that instance.rows
// and instance.flatRows contain different objects. This breaks useRowSelect,
// which sets "isSelected" on instance.rows but then reads it back from
// instance.flatRows.
//
// We fix it by manually reconstructing instance.flatRows from the objects in
// instance.rows.
//
// This should be removable when 7.0.0-rc.16 is released, according to the above
// issue.

import React from 'react';

const useFiltersPatch = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useFiltersPatch.pluginName = 'useFiltersPatch';

function useInstance(instance) {
  const {rows} = instance;

  const newFlatRows = React.useMemo(() => {
    const newFlatRows: any[] = [];

    rows.forEach((r) => {
      newFlatRows.push(r);
      newFlatRows.push(...r.subRows);
    });

    return newFlatRows;
  }, [rows]);

  Object.assign(instance, {
    flatRows: newFlatRows,
  });
}

export default useFiltersPatch;
