import posthog from 'posthog-js';
import React from 'react';

import {LoggedInAuth, useAuth} from 'app/providers/AuthProvider';
import {isProd} from 'app/utils/envUtils';
import {getLensTier} from 'app/utils/organizationUtils';
import {isUpstreamEmail} from 'app/utils/userUtils';

// Set to true for testing
const CAPTURE_ME_PLEASE = false;

// this is a convenience to give the recordEvent and onboarding flows easy
// access without having to dance props around and create cumbersome required
// props everywhere. I prefer to limit the surface area of business logic changes like this.
let analyticsAuth: LoggedInAuth | undefined = undefined;

const Analytics: React.FunctionComponent = () => {
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.status === 'logged in') {
      analyticsAuth = auth;
    }
  }, [auth]);

  let profile, profileOrganizations, firebaseUid, profileOrganization;

  if (auth.status === 'logged in') {
    profile = auth.profile;
    profileOrganizations = auth.profileOrganizations;
    firebaseUid = auth.firebaseUid;
    const organizationKey = profile?.get('organizationKey');
    if (organizationKey)
      profileOrganization = profileOrganizations?.find((org) => org?.get('id') === organizationKey);
  }

  React.useEffect(() => {
    if (!firebaseUid || !profileOrganization || !profile) {
      return;
    }

    if ((isProd && !isUpstreamEmail(profile.get('email'))) || CAPTURE_ME_PLEASE) {
      posthog.init('phc_he5ukraFzEqMLOKXr9i2nDqDa3KhHqviNubB9ZWazt1', {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        autocapture: false,
      });

      posthog.identify(firebaseUid, {
        userId: profile.get('id'),
        email: profile.get('email'),
        role: profile.get('role'),
        organizationId: profileOrganization.get('id'),
        organization: profileOrganization.get('name'),
        organizationPlan: getLensTier(profileOrganization),
      });

      posthog.group('organization', profileOrganization.get('id'), profileOrganization.toJS());

      return () => {
        posthog.reset();
      };
    }
  }, [firebaseUid, profile, profileOrganization]);

  return null;
};

export default Analytics;

type EventProps = Record<string, string | number | boolean>;

export function recordEvent(name: Events, props?: EventProps): void {
  if (posthog.__loaded) {
    posthog.capture(name, props);
  }
  maybeUpdateOnboardingTasks(name);
}

function maybeUpdateOnboardingTasks(name: Events) {
  if (!analyticsAuth || !analyticsAuth.profile) {
    return;
  }
  const onboardingTasks =
    analyticsAuth.profile.getIn(['settings', 'onboardingTasks'])?.toJS() || {};

  // default case below sets to false, so won't update unless it matches a case statement
  let shouldUpdate = true;
  switch (name) {
    case 'Created properties from drawing':
    case 'Uploaded properties from file':
      onboardingTasks['property'] = true;
      break;
    case 'Entered compare mode':
      onboardingTasks['compare'] = true;
      break;
    case 'Ordered an image':
      onboardingTasks['order'] = true;
      break;
    case 'Created note from scratch':
    case 'Created note from Analysis':
    case 'Created note from Lookout notification':
      onboardingTasks['note'] = true;
      break;
    case 'Generated chart from Analysis':
      onboardingTasks['analysis'] = true;
      break;
    case 'Created Lookout policy':
    case 'Enabled Lookout policy':
      onboardingTasks['lookout'] = true;
      break;
    case 'Created report':
      onboardingTasks['report'] = true;
      break;
    default:
      shouldUpdate = false;
  }

  if (shouldUpdate) {
    analyticsAuth?.actions.updateSettings({onboardingTasks});
  }
}

type Events =
  // Onboarding
  | 'Completed onboarding task list'
  | 'Forced completion of onboarding task list'
  | 'Dismissed onboarding task list'

  // Lookout
  | 'Created note from Lookout notification'
  | 'Created Lookout policy'
  | 'Enabled Lookout policy'
  | 'Disabled Lookout policy'

  // Library
  | 'Layer added from layers library'
  | 'Followed missing layer warning'
  | 'Changed basemap'
  | 'Added dataset'
  | 'Removed dataset'
  | 'Uploaded overlay from file'
  | 'Added Upstream overlay'
  | 'Removed Upstream overlay'
  | 'Followed missing layer warning'
  | 'Ordered an image'
  | 'Downloaded PNG'
  | 'Downloaded GeoTIFF'

  // Analysis
  | 'Opened Analysis'
  | 'Generated chart from Analysis'

  // Settings
  | 'Set notification preference'
  | 'Unset organization logo'
  | 'Set organization logo'
  | 'Set user role'
  | 'Set user name'
  | 'Deleted user'
  | 'Set email'

  // Notes
  | 'Created note from Analysis'
  | 'Created note from scratch'

  // Reports
  | 'Created report'
  | 'Saved report'

  // Portfolio
  | 'Archived features'
  | 'Created share link'
  | 'Set assignee'
  | 'Unset assignee'
  | 'Set report due date'
  | 'Unset report due date'
  | 'Updated properties'
  | 'Created properties from drawing'
  | 'Uploaded properties from file'
  | 'Set tag'
  | 'Unset tag'

  // Controls
  | 'Entered 3D mode'
  | 'Exited 3D mode'
  | 'Opened measure distance'
  | 'Entered compare mode'
  | 'Exited compare mode';
