import colors from 'app/utils/colorUtils';

const DEFAULT_COLOR: string = colors.white;
// Current mapboxgl typings don’t seem to support using booleans in the filter
const DEFAULT_FILTER = true as any;
const DEFAULT_LINE_WIDTH = 2;
const DEFAULT_MIN_ZOOM = 2;
const DEFAULT_MAX_ZOOM = 22;
const DEFAULT_OPACITY = 0.8;

// Unfortunately we can’t type this more accurately, since the "any" bit is
// paired elements followed by the default, which isn’t really typeable in
// TypeScript.
type BooleanExpression = ['!', string[]] | ['has', string] | ['in' | '!in', string, ...any[]];
type StepExpression = ['step', string[], ...any[]];

interface LineOpts {
  color?: string | mapboxgl.Expression;
  maxZoom?: number;
  minZoom?: number;
  opacity?: number | mapboxgl.Expression;
  width?: number | mapboxgl.Expression;
  filter?: boolean | BooleanExpression | StepExpression;
}

export function makeLine(opts: LineOpts): Omit<mapboxgl.LineLayer, 'id'> {
  const {color, maxZoom, minZoom, opacity, width, filter} = {
    color: DEFAULT_COLOR,
    maxZoom: DEFAULT_MAX_ZOOM,
    minZoom: DEFAULT_MIN_ZOOM,
    opacity: DEFAULT_OPACITY,
    width: DEFAULT_LINE_WIDTH,
    filter: DEFAULT_FILTER,
    ...filterObject(opts),
  };

  return {
    type: 'line',
    minzoom: minZoom,
    maxzoom: maxZoom,
    paint: {
      'line-opacity': opacity,
      'line-color': color,
      'line-width': width,
    },
    filter,
  };
}

interface FillOpts {
  fillColor?: string | mapboxgl.Expression;
  filter?: boolean | BooleanExpression | StepExpression;
  maxZoom?: number;
  minZoom?: number;
  opacity?: number | mapboxgl.Expression;
}

export function makeFill(opts: FillOpts): Omit<mapboxgl.FillLayer, 'id'> {
  const {fillColor, filter, maxZoom, minZoom, opacity} = {
    fillColor: DEFAULT_COLOR,
    filter: DEFAULT_FILTER,
    maxZoom: DEFAULT_MAX_ZOOM,
    minZoom: DEFAULT_MIN_ZOOM,
    opacity: DEFAULT_OPACITY,
    ...filterObject(opts),
  };

  return {
    type: 'fill',
    minzoom: minZoom,
    maxzoom: maxZoom,
    paint: {
      'fill-opacity': opacity,
      'fill-color': fillColor,
    },
    filter,
  };
}

function filterObject<T extends object>(obj: T): {[k in keyof T]?: NonNullable<T[k]>} {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
}
