import * as I from 'immutable';

import * as Remote from 'app/modules/Remote';
import {ApiOrderableScene} from 'app/modules/Remote/Feature';
import {StatusMaybe, useCachedApiGet} from 'app/utils/hookUtils';

export interface OrderableScenesActions {
  getOrderableScenes: OrderableScenesLoader;
  invalidateOrderableScenes: InvalidateOrderableScenes;
}

export type OrderableScenesLoader = (
  featureId: number
) => StatusMaybe<I.ImmutableOf<ApiOrderableScene[]>>;

export type InvalidateOrderableScenes = (featureId: number) => void;

export function useOrderableScenesLoader(
  featureCollectionId: number,
  projectId: string
): [OrderableScenesLoader, InvalidateOrderableScenes] {
  const [getOrderableScenes, {invalidate}] = useCachedApiGet(
    async ([fcId, pId], fId: number) => {
      const orderableScenes = (
        await Remote.api.featureCollections
          .features(fcId as number)
          .orderableScenes(pId as string, fId)
      ).get('data');
      return removeDuplicateScenes(orderableScenes);
    },
    [featureCollectionId, projectId]
  );

  return [getOrderableScenes, invalidate];
}

// We can't guarantee that the response from the orderableScenes api does not include
// any duplicates. It is unlikely, but just in case, we check and filter them out.
function removeDuplicateScenes(scenes: I.ImmutableOf<ApiOrderableScene[]>) {
  return scenes
    .filter(
      (thing, index) =>
        index ===
        scenes.findIndex(
          (t) =>
            t?.get('sensingTime') === thing?.get('sensingTime') &&
            t?.get('sourceId') === thing?.get('sourceId')
        )
    )
    .toList();
}
