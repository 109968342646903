import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {useOverlays} from 'app/components/GlobalSettings/Library/OverlaysProvider';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';

import {useManageFeatures} from './ManageFeaturesProvider';
import ManageFeaturesView from './ManageFeaturesView';

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
}

const ManageFeaturesOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
}) => {
  const {manageFeaturesState, manageFeaturesActions, loading} = useManageFeatures();
  const {overlays} = useOverlays();

  const overlayNames = overlays.size > 0 ? overlays.map((o) => o!.get('name')).toArray() : [];

  return (
    <ManageFeaturesView
      history={history}
      profile={profile}
      organization={organization}
      loggedInUserActions={loggedInUserActions}
      manageFeaturesState={manageFeaturesState}
      manageFeaturesActions={manageFeaturesActions}
      mode={'overlay'}
      loading={loading}
      overlayNames={overlayNames}
      firebaseToken={null}
    />
  );
};

export default ManageFeaturesOverlay;
