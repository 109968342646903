import {Elements, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';

import NewCustomerSignUp from 'app/components/SignUp/NewCustomerSignUpForm';
import {api} from 'app/modules/Remote';
import {ApiNewCustomerSignupResponse} from 'app/modules/Remote/Organization';
import * as CONSTANTS from 'app/utils/constants';
import {useStripeTestEnv} from 'app/utils/envUtils';
import {STRIPE_API_KEY} from 'app/utils/stripeUtils';

export interface FormState {
  accountEmail: string;
  organizationName: string;
  billingAddress: {
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  agreeToService: boolean;
  accessSubscriptionType: CONSTANTS.LENS_ACCESS_SUBSCRIPTIONS_TYPE;
  taxExempt: boolean;
  billingEmail: string;
  subscriptionAddOns: string[];
  freeTrial?: string;
}

export const DEFAULT_FORM_STATE: FormState = {
  accessSubscriptionType: 'plus_annual',
  accountEmail: '',
  organizationName: '',
  billingAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
  agreeToService: false,
  taxExempt: false,
  billingEmail: '',
  subscriptionAddOns: [],
  freeTrial: undefined,
};

interface Props {
  // Allows for formState to be pre-populated from query params in the url so we can direct users
  // to the specific Lens plan they selected in the marketing site or back to this form from
  // the Stripe Checkout page.
  initialFormState?: FormState;
}

const NewCustomerSignUpProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  initialFormState = DEFAULT_FORM_STATE,
}) => {
  const [formState, setFormState] = React.useState<FormState>(initialFormState);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isElementsLoading, setIsElementsLoading] = React.useState(true);
  const elements = useElements();

  React.useEffect(() => {
    if (elements) {
      const element = elements.getElement('address')!;
      element.on('ready', () => {
        setIsElementsLoading(false);
      });
    }
  }, [elements]);

  const onSubmit = async () => {
    const {
      accessSubscriptionType,
      accountEmail,
      taxExempt,
      subscriptionAddOns,
      organizationName,
      billingAddress,
      freeTrial,
    } = formState;
    if (!accessSubscriptionType || !accountEmail || !organizationName || !billingAddress) {
      return;
    }

    setIsSubmitting(true);
    setErrorMessage('');

    try {
      // If running locally or on staging, this env var will default to true. This allows us during
      // development to easily test things in stripe without the risk of forgetting to switch the variable
      // and accidentally writing to the production stripe instance
      const isTest = useStripeTestEnv;

      const customerSignupResponse: ApiNewCustomerSignupResponse = (
        await api.signup.subscribe(
          organizationName,
          accountEmail,
          accessSubscriptionType,
          subscriptionAddOns,
          taxExempt,
          billingAddress,
          freeTrial,
          isTest
        )
      ).toJS();

      // Redirect to stripe checkout
      window.location.href = customerSignupResponse.url;
    } catch (e) {
      const errorMessage =
        (e as any).error?.message ||
        'There was an error processing your sign up. Please try again.';
      setErrorMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NewCustomerSignUp
      formState={formState}
      setFormState={setFormState}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      errorMessage={errorMessage}
      isLoading={isElementsLoading}
    />
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe = loadStripe(STRIPE_API_KEY);

const NewCustomerSignUpProviderWrapper: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  initialFormState = DEFAULT_FORM_STATE,
}) => {
  return (
    <Elements
      stripe={stripe}
      options={{
        loader: 'always',
        appearance: {
          variables: {
            colorText: '182026',
            fontFamily: 'Lato,Helvetica,Arial,sans-serif',
            fontSizeBase: '14px',
            fontSizeSm: '14px',
          },
          rules: {
            // https://stripe.com/docs/elements/appearance-api
            // Try to match the appearance of the bluperint inputs
            '.Input': {
              paddingBottom: '0px',
              paddingTop: '0px',
              paddingLeft: '10px',
              paddingRight: '10px',
              lineHeight: '30px',
              borderColor: '#182026',
              borderWidth: '0px',
              borderStyle: 'none',
              boxShadow:
                '0 0 0 0 rgb(39, 144, 209, 0), 0 0 0 0 rgb(39, 144, 209, 0), inset 0 0 0 1px rgb(16, 22, 26, 0.15), inset 0 1px 1px rgb(16, 22, 26, 0.2)',
              color: '#182026',
            },
            '.Label': {
              marginBottom: '5px',
            },
          },
        },
      }}
    >
      <NewCustomerSignUpProvider initialFormState={initialFormState} />
    </Elements>
  );
};

export default NewCustomerSignUpProviderWrapper;
