import classNames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {api} from 'app/modules/Remote';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {useProjects} from 'app/providers/ProjectsProvider';
import {alphanumericSort} from 'app/utils/featureUtils';
import {useCachedApiGet, useCmdCtrlPress} from 'app/utils/hookUtils';
import {makeProjectDashboardUrl} from 'app/utils/routeUtils';
import * as tagUtils from 'app/utils/tagUtils';

import {skeletonRows} from './notifications';
import * as cs from './styles.styl';

const AssignedProperties: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    organization: I.ImmutableOf<ApiOrganization>;
  }>
> = ({history, organization}) => {
  const [getAssignedFeatures] = useCachedApiGet(
    async (_, orgId: string) => await api.organizations.assignedFeatures(orgId),
    []
  );
  const [projects, featureCollectionsById, , projectsMeta] = useProjects();

  const projectsByPrimaryFeatureCollectionId = React.useMemo(() => {
    return projects?.reduce((acc, p) => {
      const primaryFeatureCollectionId = p!
        .get('featureCollections')
        .find((fc) => fc?.get('kind') === 'primary')
        .get('id');
      return acc!.set(primaryFeatureCollectionId, p!);
    }, I.Map<number, I.ImmutableOf<ApiProject>>());
  }, [projects]);

  const featuresStatusMaybe = getAssignedFeatures(organization.get('id'));
  const features = featuresStatusMaybe?.value
    ?.get('data')
    .sortBy((feature) => feature?.getIn(['properties', 'name']), alphanumericSort);
  const loading = featuresStatusMaybe.status === 'unknown' || projectsMeta.loading === true;

  const featureList =
    projectsByPrimaryFeatureCollectionId &&
    projectsByPrimaryFeatureCollectionId.size > 0 &&
    features &&
    features.size ? (
      <ul className={cs.cardList}>
        <>
          {features.map((f) => (
            <li key={f!.get('id')}>
              <PropertyCard
                feature={f!}
                featureCollection={featureCollectionsById!.get(
                  f!.getIn(['properties', 'featureCollectionId'])
                )}
                project={projectsByPrimaryFeatureCollectionId!.get(
                  f!.getIn(['properties', 'featureCollectionId'])
                )}
                history={history}
              />
            </li>
          ))}
        </>
      </ul>
    ) : (
      <div className={cs.filterEmptyState}>You are not yet assigned to any properties</div>
    );

  return (
    <div className={cs.section}>
      <div className={cs.header}>
        <h2 className={cs.headerText}>My properties</h2>
      </div>

      <div className={cs.sectionContent}>{loading ? skeletonRows() : featureList}</div>
    </div>
  );
};

const PropertyCard: React.FunctionComponent<
  React.PropsWithChildren<{
    feature: I.ImmutableOf<ApiFeature>;
    featureCollection: I.MapAsRecord<I.ImmutableFields<ApiFeatureCollection>>;
    project: I.MapAsRecord<I.ImmutableFields<ApiProject>>;
    history: History;
  }>
> = ({feature, featureCollection, project, history}) => {
  const isCmdCtrlKeyPressed = useCmdCtrlPress();

  const tagIds = tagUtils.getFeatureTagIds(feature);
  const tagSettings = featureCollection
    ? tagUtils.getTagSettings(featureCollection, tagUtils.TAG_KIND.FEATURE)
    : I.List([]);
  const activeTags = tagSettings.filter((t) => tagIds.includes(t!.get('id'))).toList();
  return (
    <div
      className={classNames(cs.card, cs.propertyCard)}
      onClick={() => {
        if (project) {
          const url = makeProjectDashboardUrl(
            project.get('organizationId'),
            project.get('id'),
            'map',
            [feature.get('id')]
          );
          if (isCmdCtrlKeyPressed) {
            window.open(url, '_blank');
          } else {
            history.push(url);
          }
        }
      }}
    >
      <div className={cs.propertyCardTextPadding}>
        <b>{feature.getIn(['properties', 'name'])}</b>
        <div>{project?.get('name')}</div>

        <div className={cs.propertyCardTags}>
          <>
            {activeTags.map((t) => (
              <div key={t!.get('id')}>
                <tagUtils.Tag setting={t!} className={cs.tagMargins} />
              </div>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default AssignedProperties;
