import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import cs from './styles.styl';

const MENU_ITEM_HEIGHT = 30;

/**
 * The first type parameter for this is the "extension" type that gets
 * intersected with the base ListItem. This is the most ergonomic way to get the
 * right type signature into the ListAction callbacks.
 *
 * We include a second type parameter for the text. By default this is any
 * element that React can render, but we make it a type parameter so that
 * wrappers of this component can enforce a specific type (such as a string, so
 * that equality checks can be done reliably).
 */
export type ListItem<E = {}, T extends React.ReactNode = React.ReactNode> = E & {
  actions?: ListAction<E>[];
  icon?: B.IconName | React.ReactElement;
  isDisabled?: boolean;
  isSelected?: boolean;
  // Not the best API, but we’re shoe-horning this in. text is ignored if this
  // is provided.
  isSeparator?: boolean;
  text: T;
  subitems?: ListItem<E, T>[];
  href?: string;
  height?: number;
  centerVertically?: boolean;
  rightEl?: React.ReactNode;
  className?: string;
};

export interface ListAction<E> {
  icon: B.IconName;
  onClick: (item: ListItem<E>, event: React.MouseEvent) => unknown;
  text: number | string;
}

export interface Props<E, T extends React.ReactNode> {
  items: ListItem<E, T>[];

  className?: string;
  onClickItem: (item: ListItem<E, T>, event: React.MouseEvent) => unknown;

  itemHeight?: number;
}

/**
 * Apply this CSS class to B.MenuItem components to make the "label" component
 * on the right side only appear when hovering.
 */
export const MENU_ITEM_WITH_LABEL_ON_HOVER_CLASS = cs.labelOnHover;

export default class List<
  E = {},
  T extends React.ReactNode = React.ReactNode,
> extends React.Component<Props<E, T>> {
  static defaultProps: Pick<Props<never, never>, 'className' | 'onClickItem'> = {
    className: '',
    onClickItem: () => {},
  };

  private menuRef = React.createRef<HTMLUListElement>();

  componentDidMount() {
    this.scrollSelectedIntoView();
  }

  componentDidUpdate() {
    this.scrollSelectedIntoView();
  }

  private scrollSelectedIntoView() {
    const {items} = this.props;
    const {current: menuElem} = this.menuRef;

    // Adapted from QueryList. Scrolls to the selected only if it’s offscreen.
    const firstSelectedItemIndex = items.findIndex((i) => i.isSelected);

    if (!menuElem || firstSelectedItemIndex === -1) {
      return;
    }

    const {
      children,
      offsetTop: parentOffsetTop,
      scrollTop: parentScrollTop,
      clientHeight: parentHeight,
    } = menuElem;

    const activeElement = children.item(firstSelectedItemIndex) as HTMLElement;
    const {offsetTop: activeTop, offsetHeight: activeHeight} = activeElement;

    // compute the two edges of the active item for comparison, including parent padding
    const activeBottomEdge = activeTop + activeHeight - parentOffsetTop;
    const activeTopEdge = activeTop - parentOffsetTop;

    if (activeBottomEdge >= parentScrollTop + parentHeight) {
      // offscreen bottom: align bottom of item with bottom of viewport
      menuElem.scrollTop = activeBottomEdge + activeHeight - parentHeight;
    } else if (activeTopEdge <= parentScrollTop) {
      // offscreen top: align top of item with top of viewport
      menuElem.scrollTop = activeTopEdge - activeHeight;
    }
  }

  private renderItems(items: ListItem<E, T>[]) {
    const {onClickItem, itemHeight = MENU_ITEM_HEIGHT} = this.props;

    return items.map((item, itemIndex) => {
      const actions = item.actions || [];
      const subitems = item.subitems || [];

      if (item.isSeparator) {
        return <B.MenuDivider key={itemIndex} />;
      }

      const labelElement = (
        <div className={cs.itemActions}>
          {item.rightEl}

          {actions.map((action, itemActionIndex) => (
            <B.Tooltip
              key={`itemAction-${itemIndex}-${itemActionIndex}`}
              content={action.text.toString()}
              position={B.Position.RIGHT}
              disabled={item.isDisabled}
            >
              <B.Icon
                icon={action.icon}
                onClick={(e) => this.handleActionOnClick(e, action.onClick, item)}
              />
            </B.Tooltip>
          ))}
        </div>
      );
      return (
        <B.MenuItem
          style={{
            height: item.height || itemHeight,
            alignItems: item.centerVertically ? 'center' : 'flex-start',
          }}
          // If there’s a defined element to the right then don’t only show it
          // when hovering.
          className={classnames(
            {[MENU_ITEM_WITH_LABEL_ON_HOVER_CLASS]: !item.rightEl},
            item.className
          )}
          key={`item-${itemIndex}`}
          active={!!item.isSelected}
          disabled={item.isDisabled}
          icon={item.icon}
          text={item.text}
          labelElement={labelElement}
          shouldDismissPopover={false}
          onClick={(event: React.MouseEvent) => {
            item.href && event.preventDefault();
            onClickItem(item, event);
          }}
          href={item.href}
        >
          {subitems.length ? this.renderItems(subitems) : null}
        </B.MenuItem>
      );
    });
  }

  render() {
    const {className, items} = this.props;

    return (
      // ulRef is typed to the old ref callback function, but since it’s passed
      // through to the <ul> element we can actually give it the new createRef
      // instead.
      <B.Menu ulRef={this.menuRef as any} className={classnames(cs.menu, className)}>
        {this.renderItems(items)}
      </B.Menu>
    );
  }

  private handleActionOnClick(
    event: React.MouseEvent,
    onClick: ListAction<E>['onClick'],
    item: ListItem<E>
  ) {
    event.preventDefault();
    event.stopPropagation();
    onClick(item, event);
  }
}
