import {bbox} from '@turf/turf';
import geojson from 'geojson';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import Map from 'app/components/DeclarativeMap';
import GeoJsonFitter from 'app/components/DeclarativeMap/GeoJsonFitter';
import MapContent, {SourceRecord} from 'app/components/DeclarativeMap/MapContent';
import MapStyle from 'app/components/DeclarativeMap/MapStyle';
import ZoomCenterControl from 'app/components/DeclarativeMap/ZoomCenterControl';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiTaskingPlan} from 'app/modules/Remote/Tasking';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as mapUtils from 'app/utils/mapUtils';

const FeaturesVector: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    selectedFeatureCollection: geojson.FeatureCollection;
    unselectedFeatureCollection: geojson.FeatureCollection;
    hoveredFeatureId: number | null;
    onClickFeature: (feature: mapboxgl.MapboxGeoJSONFeature) => void;
    onHoverFeature: (featureId: mapboxgl.MapboxGeoJSONFeature['id']) => void;
  }>
> = ({
  map,
  isMapLoaded,
  selectedFeatureCollection,
  unselectedFeatureCollection,
  hoveredFeatureId,
  onClickFeature,
  onHoverFeature,
}) => {
  selectedFeatureCollection = hookUtils.useContinuity(selectedFeatureCollection);
  unselectedFeatureCollection = hookUtils.useContinuity(unselectedFeatureCollection);

  const sources: SourceRecord[] = [
    {
      id: 'selected-features',
      source: {
        type: 'geojson',
        data: selectedFeatureCollection,
      },
    },
    {
      id: 'unselected-features',
      source: {
        type: 'geojson',
        data: unselectedFeatureCollection,
      },
    },
  ];

  const layers: mapboxgl.AnyLayer[] = [
    {
      id: 'selected-features-fill',
      type: 'fill',
      source: 'selected-features',
      paint: {'fill-opacity': 0},
    },
    {
      id: 'selected-features-line',
      type: 'line',
      source: 'selected-features',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#2790D1',
        'line-width': ['match', ['id'], hoveredFeatureId ?? '', 4, 3],
      },
    },
    {
      id: 'unselected-features-fill',
      type: 'fill',
      source: 'unselected-features',
      paint: {'fill-opacity': 0},
    },
    {
      id: 'unselected-features-line',
      type: 'line',
      source: 'unselected-features',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#FFFFFF',
        'line-width': ['match', ['id'], hoveredFeatureId ?? '', 2, 1],
      },
    },
  ];

  mapUtils.useClickableFeatures(map, layers, onClickFeature);
  mapUtils.useHoverableFeatures(map, layers, (_, featureId) => onHoverFeature(featureId));

  return <MapContent map={map} isMapLoaded={isMapLoaded} sources={sources} layers={layers} />;
};

const TaskingPlanVector: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    featureCollection: geojson.FeatureCollection;
  }>
> = ({map, isMapLoaded, featureCollection}) => {
  featureCollection = hookUtils.useContinuity(featureCollection);

  const sources: SourceRecord[] = [
    {
      id: 'tasking-plan',
      source: {
        type: 'geojson',
        data: featureCollection,
      },
    },
  ];

  const layers: mapboxgl.AnyLayer[] = [
    {
      id: 'tasking-plan-fill',
      type: 'fill',
      source: 'tasking-plan',
      paint: {'fill-color': '#FFC266', 'fill-opacity': 0.25},
    },
    {
      id: 'tasking-plan-line',
      type: 'line',
      source: 'tasking-plan',
      paint: {'line-color': '#FFC266', 'line-width': 2},
    },
  ];

  return <MapContent map={map} isMapLoaded={isMapLoaded} sources={sources} layers={layers} />;
};

const TaskImageryModalMap: React.FunctionComponent<
  React.PropsWithChildren<{
    selectedFeatureCollection: geojson.FeatureCollection;
    unselectedFeatureCollection: geojson.FeatureCollection;
    hoveredFeatureId: number | null;
    toggleFeatureSelection: (featureId: number) => void;
    setHoveredFeatureId: (featureId: number | null) => void;
    fitTarget: geojson.GeoJSON;
    zoomToFitTarget: geojson.GeoJSON;
    taskingPlan?: ApiTaskingPlan;
  }>
> = ({
  selectedFeatureCollection,
  unselectedFeatureCollection,
  hoveredFeatureId,
  toggleFeatureSelection,
  setHoveredFeatureId,
  fitTarget,
  zoomToFitTarget,
  taskingPlan,
}) => (
  <Map firebaseToken={null} controlPosition="top-right" hideNavigationControl>
    {(map, isMapLoaded) => (
      <React.Fragment>
        <MapStyle map={map} style={mapUtils.MAPBOX_DARK_STYLE} />
        <GeoJsonFitter map={map} geoJson={fitTarget} fitBoundsOptions={{padding: 40}} />
        <ZoomCenterControl
          map={map}
          isMapLoaded={isMapLoaded}
          featureBounds={bbox(zoomToFitTarget) as geoJsonUtils.BBox2d}
          fitBoundsOptions={{padding: 40}}
          zoomToFitButtonTooltip="Reset zoom"
        />
        <FeaturesVector
          map={map}
          isMapLoaded={isMapLoaded}
          selectedFeatureCollection={selectedFeatureCollection}
          unselectedFeatureCollection={unselectedFeatureCollection}
          hoveredFeatureId={hoveredFeatureId}
          onClickFeature={(feature) => toggleFeatureSelection(feature.id as ApiFeature['id'])}
          onHoverFeature={(featureId) => setHoveredFeatureId(featureId as ApiFeature['id'])}
        />
        {!!taskingPlan && (
          <TaskingPlanVector map={map} isMapLoaded={isMapLoaded} featureCollection={taskingPlan} />
        )}
      </React.Fragment>
    )}
  </Map>
);

export default TaskImageryModalMap;
