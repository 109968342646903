import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {AppNavToasterContext} from 'app/components/AppNav';
import DateChangeToast from 'app/components/AppNav/DateChangeToast';
import DateSelect from 'app/components/DateSelect';
import FeatureList from 'app/components/FeatureList';
import {FilterType} from 'app/components/FeatureList/view';
import LayersMenu from 'app/components/LayersMenu';
import Toolbar, {ToolbarItem} from 'app/components/Toolbar';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {recordEvent} from 'app/tools/Analytics';
import {FEATURE_WAITING_FOR_PROCESSING_STATUS} from 'app/utils/constants';
import {hydratedFeatureCollectionAsApiFeatureCollection} from 'app/utils/featureCollectionUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as layers from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import MODES, {Mode} from 'app/utils/mapUtils/modes';

import {changeSelection} from '../ProjectDashboardSwitch';
import {ImageRefIndex, MapStateDispatch} from './MapStateProvider';
import cs from './Navigation.styl';
import {MapTool} from './view';

// In practical terms, this just appears in the Nav as the feature is loading,
// if you follow a URL straight to the property.
const DESELECT_TEXT = '…';

export interface Props {
  imageRefs: mapUtils.MapImageRefs;
  featureData: I.ImmutableOf<ApiFeatureData[]> | null;
  mode: Mode;
  selectedFeatureCollection: HydratedFeatureCollection;
  selectedFeatureIdParams: I.Set<string>;
  selectedFeatures: I.Set<I.ImmutableOf<ApiFeature>>;
  mapStateDispatch: MapStateDispatch;
  selectedProject: I.ImmutableOf<ApiProject>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.ImmutableOf<ApiOrganization>;
  firebaseToken: string | null;

  activeTool: MapTool | null;
  setActiveTool: (tool: MapTool | null) => void;
}

interface State {
  propertyMenuFilter: FilterType;
}

export default class MonitorProjectViewNavigation extends React.PureComponent<Props, State> {
  state: State = {
    propertyMenuFilter: {type: 'all'},
  };

  render() {
    const {
      mapStateDispatch: setMapState,
      selectedFeatureCollection,
      selectedFeatureIdParams,
      selectedFeatures,
      selectedProject,
      imageRefs,
      mode,
      profile,
      organization,
    } = this.props;

    const {propertyMenuFilter} = this.state;

    if (!selectedFeatureCollection) {
      return null;
    }

    const items: ToolbarItem[] = [
      {
        menuKey: 'properties-selector',
        buttonText: this.renderPropertiesMenuText(),
        addMargin: true,
        isDisabled: false,
        renderPopover: () => (
          <FeatureList
            organization={organization}
            profile={profile}
            changeSelection={changeSelection}
            selectedFeatureCollection={selectedFeatureCollection}
            selectedFeatureLensIds={selectedFeatures
              .map((f) => f!.getIn(['properties', 'lensId'])!)
              .toSet()}
            selectedProject={selectedProject}
            deselectText={DESELECT_TEXT}
            listClassName={cs.featureList}
            filter={propertyMenuFilter}
            setFilter={(f) => this.setState({propertyMenuFilter: f})}
          />
        ),
      },
    ];

    const isCompare = mode === MODES.COMPARE && imageRefs.length > 1;

    const selectedFeaturesAreProcessing =
      selectedFeatures.size &&
      selectedFeatures.every(
        (feature) =>
          feature?.getIn(['properties', 'status']) === FEATURE_WAITING_FOR_PROCESSING_STATUS
      );

    if (selectedFeaturesAreProcessing) {
      items.push({
        menuKey: 'processing-imagery-notice',
        tooltipText: 'Imagery is loading',
        icon: 'time',
        addMargin: true,
        intent: B.Intent.WARNING,
        notInteractive: true,
      });
    }

    // Only show date/layer selection controls if we have one feature selected. If you
    // select a multi-location property, these controls don't make sense until you've
    // clicked into a specific feature because we won't have fetched any data yet
    if (selectedFeatureIdParams.size === 1) {
      imageRefs.map((_imageRef, idx) => {
        items.push(this.makeLayerNavItem(idx as ImageRefIndex));
        items.push(this.makeDateSelectNavItem(idx as ImageRefIndex));
        items.push({
          menuKey: `compare-menu ${idx}`,
          tooltipText: isCompare ? 'Leave compare mode' : 'Add layer and date to compare',
          onClick: () => {
            isCompare
              ? //if we click the "x" button for one image, keep the other one
                setMapState({type: 'SET_MODE', mode: 'VIEW', imageRefIdxToKeep: idx === 0 ? 1 : 0})
              : setMapState({type: 'SET_MODE', mode: 'COMPARE'});
            recordEvent(isCompare ? 'Exited compare mode' : 'Entered compare mode');
          },
          icon: isCompare ? 'cross' : 'plus',
          addMargin: isCompare && idx === 0,
        });
      });
    }

    const activeImageRefCursors = I.List(
      imageRefs.map(({cursor}) => cursor).filter((c): c is string => c !== null)
    );

    return (
      <AppNavToasterContext.Consumer>
        {(toaster) => (
          <>
            <Toolbar items={items} />

            {toaster && selectedFeatureIdParams.size === 1 && selectedFeatures.size === 1 && (
              <DateChangeToast
                toaster={toaster}
                featureId={selectedFeatures.first().get('id')}
                activeCursors={activeImageRefCursors}
              />
            )}
          </>
        )}
      </AppNavToasterContext.Consumer>
    );
  }
  private makeDateSelectNavItem(imageRefIdx: ImageRefIndex) {
    const {
      selectedFeatureCollection,
      selectedFeatureIdParams,
      imageRefs,
      featureData,
      organization,
      mode,
      selectedFeatures,
      mapStateDispatch,
      profile,
      firebaseToken,
    } = this.props;

    const isLoading = !featureData;
    const isDisabled =
      isLoading ||
      selectedFeatureIdParams.size !== 1 ||
      imageRefs[imageRefIdx]!.layerKey === layers.NONE;

    const selectedLayer = imageRefs[imageRefIdx]!.layerKey;
    const selectedDate = imageRefs[imageRefIdx]!.cursor;

    // Use the current formmatted date as a loading placeholder
    // so the loading skeleton has the same width as a loaded date.
    // If we have no selectedDate yet we pass an empty string instead
    // to create the loading placeholder
    const buttonText =
      selectedDate || isLoading
        ? imageryUtils.formatDate(selectedDate || '', imageRefs[imageRefIdx]!.layerKey)
        : 'None';

    return {
      menuKey: `date-nav-${imageRefIdx}`,
      buttonText,
      isDisabled,
      isLoading,
      renderPopover: () => (
        <DateSelect
          className={cs.dateSelect}
          imageRefLayerKey={imageRefs[imageRefIdx]!.layerKey}
          featureCollection={selectedFeatureCollection}
          featureData={featureData || I.List()}
          imageRefs={imageRefs}
          organization={organization}
          profile={profile}
          mode={mode}
          selectedDate={selectedDate!}
          selectedLayer={selectedLayer}
          onChange={(newImageRefs) =>
            mapStateDispatch({
              type: 'SET_IMAGE_REFS',
              imageRefs: newImageRefs,
            })
          }
          feature={selectedFeatures.first()}
          firebaseToken={firebaseToken}
        />
      ),
    };
  }

  private makeLayerNavItem(imageRefIdx: ImageRefIndex): ToolbarItem {
    const {
      selectedFeatureCollection,
      imageRefs,
      featureData,
      organization,
      mapStateDispatch,
      selectedFeatures,
    } = this.props;

    const layer = layerUtils.getLayer(imageRefs[imageRefIdx]!.layerKey);

    return {
      menuKey: `layer-menu-${imageRefIdx}`,
      buttonText: <div>{layer.display}</div>,
      isLoading: !selectedFeatures.size,
      isDisabled: !selectedFeatures.size,
      renderPopover: () => {
        return (
          <LayersMenu
            organization={organization}
            activeLayerKey={imageRefs[imageRefIdx]!.layerKey}
            featureCollection={hydratedFeatureCollectionAsApiFeatureCollection(
              selectedFeatureCollection
            )}
            featureData={featureData}
            onChangeLayer={(layerKey: string) => {
              mapStateDispatch({type: 'SET_LAYER', layerKey, imageRefIdx});
            }}
            feature={selectedFeatures.first()}
          />
        );
      },
    };
  }

  private renderPropertiesMenuText() {
    const {selectedFeatures} = this.props;
    if (!selectedFeatures || !selectedFeatures.size) {
      return DESELECT_TEXT;
    }
    return selectedFeatures.first().getIn(['properties', 'name']);
  }
}
