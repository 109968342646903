import * as B from '@blueprintjs/core';
import React from 'react';

import LensLogoSvg from 'app/components/Logo/LensLogoSvg';
import {ReportNotePage} from 'app/modules/Remote/Feature';
import {LayerInfo} from 'app/utils/layers';
import * as layers from 'app/utils/layers';

export const CategoryLegendsPage: React.FunctionComponent<
  React.PropsWithChildren<{
    reportNoteState: ReportNotePage;
    setReportState: (ReportStatePath) => void;
    noteIndex: number;
    title: string;
    categoryLegends: LayerInfo[];
    alignment: 'horizontal' | 'vertical';
    areAttachedLegendsVisible: boolean;
  }>
> = ({
  reportNoteState,
  areAttachedLegendsVisible,
  setReportState,
  noteIndex,
  title,
  categoryLegends,
  alignment,
}) => {
  return (
    <section
      className={
        !reportNoteState.hidden && areAttachedLegendsVisible ? '' : 'note-unselected no-print'
      }
    >
      <div className="note-control-btns no-print">
        <B.AnchorButton
          title={
            areAttachedLegendsVisible
              ? 'Exclude attached legends from report'
              : 'Include attached legends in report'
          }
          icon={areAttachedLegendsVisible ? 'eye-off' : 'eye-open'}
          active={!areAttachedLegendsVisible}
          onClick={() => {
            setReportState([
              ['notePages', noteIndex, 'legendsHidden'],
              !reportNoteState.legendsHidden,
            ]);
          }}
        />
      </div>

      <div className="title-and-lens-watermark">
        <h2 contentEditable suppressContentEditableWarning>
          {title} | Legends
        </h2>
        <div className="lens-watermark">
          <LensLogoSvg />
        </div>
      </div>

      <div className="note-category-legend-container">
        {categoryLegends.map((layer, i) => {
          return (
            <div className="note-category-legend" key={`${i}-${layer.key}`}>
              <b>
                {layer.display}
                {': '}
                {categoryLegends.length > 1 &&
                  i === 0 &&
                  (alignment === 'vertical' ? 'top image' : 'left image')}
                {categoryLegends.length > 1 &&
                  i === 1 &&
                  (alignment === 'vertical' ? 'bottom image' : 'right image')}
              </b>
              <div>
                {/**the type on layer is handled where we define categoryLegends above */}
                {layers.renderColorKey(
                  Object.values((layer as {layerLegendMap: Record<string, any>}).layerLegendMap)
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
