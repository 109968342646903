import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import List from 'app/components/List';
import {TagSetting} from 'app/modules/Remote/FeatureCollection';
import {recordEvent} from 'app/tools/Analytics';
import * as tagUtils from 'app/utils/tagUtils';

import * as cs from './EditTagsMenu.styl';

/**
 * Popover-based dropdown to show the list of tags, with the ones currently on
 * the selected features/notes as checked.
 *
 * The children passed to this component will be the button that triggers the
 * popover.
 */
const EditTagsMenu: React.FunctionComponent<
  React.PropsWithChildren<{
    featureCollectionId: number;
    tagSettings: I.OrderedMap<string, I.MapAsRecord<I.ImmutableFields<TagSetting>>>;
    tagStatusMaps: I.ImmutableOf<tagUtils.TagStatusMap[]>;
    onChange: (tagStatusMap: tagUtils.TagStatusMap) => void;
    openCustomizeTagsModal: null | (() => void);
    arrow?: boolean;
    onClose?: B.PopoverProps['onClose'];
    kind: tagUtils.TAG_KIND;
  }>
> = ({
  featureCollectionId,
  tagSettings,
  tagStatusMaps,
  openCustomizeTagsModal,
  onChange,
  children,
  arrow,
  onClose,
  kind,
}) => {
  // Map of tag ID -> number of times it appears in the selected features.
  const tagCounts = React.useMemo(() => tagUtils.getTagCounts(tagStatusMaps), [tagStatusMaps]);
  // Used to determine if the checkmark next to the tag in the menu should be
  // checked, unchecked, or indeterminate.
  const calculateTagStatus = (id: string): boolean | 'mixed' => {
    const count = tagCounts.get(id) || 0;
    if (count === tagStatusMaps.size) {
      return true;
    } else if (count === 0) {
      return false;
    } else {
      return 'mixed';
    }
  };

  return (
    <B.Popover
      position={B.Position.BOTTOM_RIGHT}
      modifiers={{arrow: {enabled: !!arrow}}}
      onClose={onClose}
      rootBoundary="viewport"
      content={
        <List
          className={cs.menu}
          items={[
            {id: 'clear', text: `Clear all ${tagUtils.TAG_KIND_DISPLAY[kind]} Tags`},
            {id: 'sep', text: '', isSeparator: true},
            ...tagUtils.makeTagListItems(tagSettings, calculateTagStatus),
            ...(openCustomizeTagsModal
              ? [
                  {id: 'sep2', text: '', isSeparator: true},
                  {id: 'customize', text: `Customize ${tagUtils.TAG_KIND_DISPLAY[kind]} Tags…`},
                ]
              : []),
          ]}
          onClickItem={({id}, ev) => {
            if (id === 'customize') {
              openCustomizeTagsModal?.();
              return;
            }

            // Keeps the menu open so you can add more than one tag at a time.
            ev.preventDefault();

            let tagIds: Record<string, boolean>;

            if (id === 'clear') {
              if (
                !confirm(
                  `Are you sure you want to clear ${
                    tagUtils.TAG_KIND_DISPLAY[kind]
                  } Tags for selected ${kind === tagUtils.TAG_KIND.FEATURE ? 'properties' : 'notes'}?`
                )
              ) {
                return;
              }
              tagIds = tagSettings.reduce(
                (acc, val) => ({
                  [val!.get('id')]: false,
                  ...acc!,
                }),
                {}
              );
            } else if (tagSettings.has(id)) {
              const status = calculateTagStatus(id);
              let nextVal: boolean;

              if (status === true) {
                nextVal = false;
              } else if (status === false) {
                nextVal = true;
              } else {
                // Selecting a tag that is currently "mixed" adds it for
                // everything.
                nextVal = true;
              }

              tagIds = {[id]: nextVal};
            } else {
              return;
            }

            onChange(tagIds);

            // We've lost the context of whether this is a feature or note tag. Should we add a kind prop back in?
            // There also isn't a concept of featureCount for notes
            recordEvent(tagIds[id] ? 'Set tag' : 'Unset tag', {
              featureCollectionId,
              tagId: id,
              featureCount: tagStatusMaps.size,
            });
          }}
        />
      }
    >
      {children}
    </B.Popover>
  );
};

export default EditTagsMenu;
