import * as B from '@blueprintjs/core';
import * as turf from '@turf/turf';
import classnames from 'classnames';
import geojson from 'geojson';
import * as I from 'immutable';
import leaflet from 'leaflet';
import sortBy from 'lodash/sortBy';
import React from 'react';

import RulerIcon from 'app/components/DeclarativeMap/RulerIcon';
import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection, GeometryOverlaySetting} from 'app/modules/Remote/FeatureCollection';
import {MeasurementSystem} from 'app/modules/Remote/Organization';
import COLORS from 'app/styles/colors.json';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as layers from 'app/utils/layers';

import {ReadyStateReporter, useReadyStateReporter} from './readyState';
import {LeafletContext} from './ReportExportWindow';
import {
  CustomLeafletControl,
  LeafletGeometry,
  bboxToLeafletBounds,
  useLeafletFeatureImagery,
  useLeafletGeometry,
  useLeafletMap,
  useLeafletMapFrozen,
} from '../LeafletMap/LeafletMap';

const WEB_PPI = 96;
const ICON_COLOR = '#182026';

export interface Props {
  firebaseToken: string;
  feature: geojson.Feature;
  featureData: I.ImmutableOf<ApiFeatureData[]>;
  layerKey: string;
  cursor: string | undefined;
  noteAreaFeature?: geojson.Feature;
  allowEdits?: boolean;

  bounds?: leaflet.LatLngBoundsExpression;
  onBoundsChanged?: (bounds: leaflet.LatLngBounds) => void;
  saveMapState: (fieldToSave: string, update: any) => void;
  savedBounds?: leaflet.LatLngBoundsExpression | undefined;
  savedShowNotePoints?: boolean;

  widthInches: number;
  maxHeightInches: number;
  aspectRatio?: number | 'auto';

  initialMode?: 'static' | 'editing';
  readyStateReporter: ReadyStateReporter;

  overlayFeatureCollections: (Pick<ApiFeatureCollection, 'name' | 'id' | 'tiles'> &
    geojson.FeatureCollection)[];
  overlaySettings: GeometryOverlaySetting[];
  savedIsOverlayVisibleByName: Record<string, boolean>;

  renderDateDropdown?: () => JSX.Element;

  initialShowScale?: boolean;
  measurementSystem?: MeasurementSystem;

  noteGeometries?: geojson.Geometry[];
}

const ZOOM_PADDING = 10;

const ReportExportFeatureMap: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  feature,
  featureData,
  layerKey,
  cursor = null,
  noteAreaFeature,
  firebaseToken,
  allowEdits = false,

  maxHeightInches,
  widthInches,
  aspectRatio = 4 / 3,
  overlayFeatureCollections,
  overlaySettings,

  bounds,
  onBoundsChanged,

  // reportState setter and loaded values from reportState
  saveMapState,
  savedBounds,
  savedShowNotePoints,
  savedIsOverlayVisibleByName,

  initialMode,
  readyStateReporter,
  renderDateDropdown,

  initialShowScale = true,
  measurementSystem = 'imperial',

  noteGeometries,
}) => {
  const L = React.useContext(LeafletContext);
  const scaleRef = React.useRef<leaflet.Control.Scale | null>(null);
  const [showScale, setShowScale] = React.useState(initialShowScale);
  const [isHovering, setIsHovering] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [mode, setMode] = React.useState<'editing' | 'static'>(initialMode || 'static');

  overlaySettings = React.useMemo(() => sortBy(overlaySettings, 'name'), [overlaySettings]);

  const setIsReady = useReadyStateReporter(readyStateReporter);

  // By converting to web mercator we can assume that the coordinates refer to
  // square pixels, which is necessary for correctly calculating an aspect
  // ratio.
  const geometryBounds = turf.bbox(turf.toMercator(noteAreaFeature || feature));

  const numericAspectRatio =
    aspectRatio === 'auto'
      ? Math.abs((geometryBounds[0] - geometryBounds[2]) / (geometryBounds[1] - geometryBounds[3]))
      : aspectRatio;

  const mapWidth = widthInches * WEB_PPI;

  // TODO(fiona): Need to handle devicepixelratio stuff and pre-scale the map
  // when it’s offscreen for cases when we’re not doing retina.
  const mapStyle: React.CSSProperties = {
    // We always go the full width of the page. The question is how tall the
    // image is, to fit the aspect ratio of the property.
    width: mapWidth,
    height: Math.min(
      maxHeightInches * WEB_PPI,
      (mapWidth - 2 * ZOOM_PADDING) / numericAspectRatio + 2 * ZOOM_PADDING
    ),
  };

  const featureDatum = React.useMemo<I.ImmutableOf<ApiFeatureData> | null>(() => {
    const typePredicate = featureUtils.makeLayerKeyPredicate(layerKey);

    return (
      featureData.findLast(
        (d) =>
          typePredicate(d) &&
          d!.get(featureUtils.getCursorKeyForLayerKey(layerKey, null)) === cursor
      ) || null
    );
  }, [featureData, layerKey, cursor]);

  const [map, {zoom: currentZoom}, setMapEl] = useLeafletMap(L);

  /** Handles showing and hiding the map scale in the lower-left corner. */
  React.useEffect(() => {
    if (L && map) {
      if (showScale) {
        const scale = L.control.scale({
          imperial: measurementSystem === 'imperial',
          metric: measurementSystem === 'metric',
        });
        scale.addTo(map);
        scaleRef.current = scale;
      } else {
        scaleRef.current?.remove();
        scaleRef.current = null;
      }
    }
  }, [L, map, measurementSystem, showScale]);

  // Freezes interactions when we’re in static mode (rather than editing)
  useLeafletMapFrozen(map, mode === 'static');

  // Report out our bounds changing.
  React.useEffect(() => {
    if (!map || !onBoundsChanged) {
      return;
    }

    const handler = () => {
      onBoundsChanged(map.getBounds());
    };
    map.on('moveend', handler);

    return () => {
      map.off('moveend', handler);
    };
  }, [map, onBoundsChanged]);

  // Callback to reset the position back to our geometry.
  const resetPosition = React.useCallback(() => {
    map?.fitBounds(bboxToLeafletBounds(turf.bbox(noteAreaFeature || feature)), {
      maxZoom: 18,
      padding: [ZOOM_PADDING, ZOOM_PADDING],
      animate: false,
    });
  }, [map, noteAreaFeature, feature]);

  // Translate the current map bounds into a format we can save to reportState.
  const makeBoundsUpdate = React.useCallback(
    (bounds: leaflet.LatLngBounds): number[] | undefined => {
      if (!noteAreaFeature) {
        return undefined;
      }
      const boundsBBox = [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ] as geojson.BBox;

      const defaultBBox = turf.bbox(noteAreaFeature);
      // If our saved bounds are the default bounds, unset note page map, so we render the default for saved reports.
      const bboxUpdate = geoJsonUtils.bboxAreEqual(boundsBBox, defaultBBox)
        ? undefined
        : boundsBBox;
      return bboxUpdate;
    },
    [noteAreaFeature]
  );

  const hasBounds = !!bounds;

  // Have Leaflet re-check size when our dimensions change, and re-zoom the map
  // to match the new size. This also causes the initial zooming to the feature
  // geometry when the map is created.
  React.useEffect(() => {
    map?.invalidateSize();

    if (!hasBounds && !savedBounds) {
      resetPosition();
    }
  }, [map, widthInches, maxHeightInches, aspectRatio, hasBounds, resetPosition, savedBounds]);

  // If bounds is controlled, updates the map to match its new value.
  React.useEffect(() => {
    if (savedBounds) {
      map?.fitBounds(savedBounds, {
        animate: false,
      });
    } else if (bounds) {
      map?.fitBounds(bounds, {
        animate: false,
      });
    }
  }, [map, bounds, savedBounds]);

  const imageLayerKey =
    // For right now we're always passing in ANY_TRUECOLOR_HIGH_RES from ReportExportFeature,
    // but leaving this logic in place in case we extend that in the future.
    featureDatum && layerKey === layers.ANY_TRUECOLOR_HIGH_RES
      ? (featureUtils.findFirstProcessedHighResTruecolorLayerKey(featureDatum) ?? layerKey)
      : layerKey;

  const tilesLoaded = useLeafletFeatureImagery(
    L,
    map,
    featureDatum,
    imageLayerKey,
    cursor,
    firebaseToken
  );

  React.useEffect(() => {
    // We report being ready if the tiles are loaded and we’re not in edit mode.
    setIsReady(tilesLoaded && mode === 'static');
  }, [setIsReady, tilesLoaded, mode]);

  // Add a gray background behind the features. If imagery does not cover propery it
  // makes the property boundries easier to see.
  // If the scene has been fully ordered, use the bounds (feature + buffer). If it hasn't
  // then the bounds only represent the AOI and instead we just use the feature. The buffer
  // will not be grayed as well.
  const featureBackground: geojson.Feature<geojson.Polygon | geojson.MultiPolygon> =
    featureDatum?.getIn(['measurements', `${imageLayerKey}_is-fully-ordered`], true)
      ? featureDatum?.getIn(['images', 'bounds']).toJS()
      : feature;

  useLeafletGeometry(L, map, featureBackground, firebaseToken, {
    style: {
      stroke: false,
      fillColor: COLORS.gray,
      fillOpacity: 1,
    },
    pane: 'backgroundPane',
  });

  const [showPropertyBoundaries, setShowPropertyBoundaries] = React.useState(true);
  const [showNotePoints, setShowNotePoints] = React.useState(savedShowNotePoints ?? false);

  // Render feature (ie, property boundaries) as a white outline on the map.
  useLeafletGeometry(
    L,
    map,
    // As long as we're showing property boundaries, AND property boundaries are
    // different from the note geometry, render the property boundary outline.
    // (Notes saved without user-specified geometry have feature === noteAreaFeature,
    // but we want to give it the yellow note styling, so we return null here in that case.)
    feature !== noteAreaFeature && showPropertyBoundaries ? feature : null,
    firebaseToken,
    {
      style: {
        stroke: true,
        color: cursor ? '#ffffff' : COLORS.darkGray,
        fill: false,
        weight: 3,
      },
    }
  );

  // Render noteAreaFeature (ie the note geometry) as a yellow outline on the map.
  useLeafletGeometry(
    L,
    map,
    // The only situation where we want to return null here is when the note polygon
    // is identical to the feature boundaries polygon AND showPropertyBoundaries is set to false.
    (noteAreaFeature &&
      !turf.booleanEqual(
        feature.geometry as geojson.Polygon,
        noteAreaFeature.geometry as geojson.Polygon
      )) ||
      showPropertyBoundaries
      ? noteAreaFeature
      : null,
    firebaseToken,
    {
      style: (f) =>
        f?.geometry.type === 'Point'
          ? // Has to be blank for points, otherwise these values override the style for the circleMarker
            {}
          : {
              stroke: true,
              color: '#F5E050',
              fill: false,
              weight: 3,
            },
      pointToLayer: (_, latlng) =>
        L!.circleMarker(latlng, {
          radius: 5,
          fill: true,
          fillColor: '#F5E050',
          fillOpacity: 1,
          stroke: false,
        }),
    }
  );

  return (
    <div className="map-container">
      <div
        className={classnames('map', {'active-map': mode === 'editing'})}
        ref={setMapEl}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={mapStyle}
      />

      {mode === 'editing' && L && (
        <>
          <CustomLeafletControl L={L} map={map} opts={{position: 'topleft'}}>
            <B.AnchorButton
              icon={<B.Icon color={ICON_COLOR} icon="tick" />}
              title={'Save'}
              className="map-btn"
              onClick={() => {
                if (saveMapState && map) {
                  saveMapState('mapBounds', makeBoundsUpdate(map?.getBounds()));
                }
                setMode('static');
              }}
            />

            <B.AnchorButton
              icon={<B.Icon color={ICON_COLOR} icon="reset" />}
              title={'Reset'}
              className="map-btn"
              onClick={resetPosition}
            />
          </CustomLeafletControl>

          <CustomLeafletControl L={L} map={map} opts={{position: 'topleft'}}>
            <B.ButtonGroup vertical>
              <B.AnchorButton
                icon={<B.Icon color={ICON_COLOR} icon="plus" />}
                className="map-btn"
                disabled={currentZoom === map?.getMaxZoom()}
                onClick={() => map?.zoomIn()}
              />

              <B.AnchorButton
                icon={<B.Icon color={ICON_COLOR} icon="minus" />}
                className="map-btn"
                disabled={currentZoom === map?.getMinZoom()}
                onClick={() => map?.zoomOut()}
              />
            </B.ButtonGroup>
          </CustomLeafletControl>

          {overlayFeatureCollections.length > 0 && (
            <CustomLeafletControl L={L} map={map} opts={{position: 'topleft'}}>
              <B.Popover
                usePortal={false}
                position="right"
                // prevent map scrollWheelZoom when the overlays menu is open
                // so that people can scroll through overlays without zooming the map,
                // without freezing all edit (or manual zoom) functionality
                onOpened={() => {
                  map?.scrollWheelZoom.disable();
                }}
                onClosed={() => {
                  map?.scrollWheelZoom.enable();
                }}
                content={
                  <B.Menu className="map-overlay-menu">
                    {overlaySettings
                      .filter(
                        ({name}) => !!overlayFeatureCollections.find((fc) => fc.name === name)
                      )
                      .map(({name, color}) => (
                        <B.MenuItem
                          className="overlay-menu-item"
                          key={name}
                          icon={savedIsOverlayVisibleByName[name] ? 'tick' : 'blank'}
                          text={name}
                          labelElement={
                            <div className="overlay-menu-swatch" style={{backgroundColor: color}} />
                          }
                          shouldDismissPopover={false}
                          onClick={() => {
                            saveMapState('overlayVisibilityByName', {
                              ...savedIsOverlayVisibleByName,
                              [name]: !savedIsOverlayVisibleByName[name],
                            });
                          }}
                        />
                      ))}
                  </B.Menu>
                }
              >
                <B.AnchorButton
                  icon={<B.Icon color={ICON_COLOR} icon="layers" />}
                  className="map-btn"
                  onClick={() => {}}
                />
              </B.Popover>
            </CustomLeafletControl>
          )}
        </>
      )}

      {mode === 'static' && (
        <div
          className="no-print map-control-btns"
          style={{
            visibility: !isHovering && !isMenuOpen ? 'hidden' : 'visible',
          }}
          onMouseEnter={() => setIsHovering(true)}
        >
          {allowEdits && (
            <B.AnchorButton
              icon={<B.Icon color={ICON_COLOR} icon="edit" />}
              title="Edit"
              className="map-btn"
              onClick={() => {
                setMode('editing');
              }}
            />
          )}

          {renderDateDropdown && (
            <B.Popover
              content={renderDateDropdown()}
              usePortal={false}
              position="bottom"
              onOpening={() => setIsMenuOpen(true)}
              onClosed={() => setIsMenuOpen(false)}
            >
              <B.AnchorButton
                icon={<B.Icon color={ICON_COLOR} icon="calendar" />}
                title="Change Date"
                className="map-btn"
              />
            </B.Popover>
          )}

          <B.AnchorButton
            icon={<RulerIcon color={ICON_COLOR} size={18} />}
            title={showScale ? 'Hide scale' : 'Show scale'}
            active={showScale}
            className="map-btn"
            onClick={() => {
              setShowScale((prevShowScale) => {
                saveMapState('showMapScale', !prevShowScale);
                return !prevShowScale;
              });
            }}
          />

          <B.AnchorButton
            icon={<B.Icon icon="polygon-filter" color={ICON_COLOR} size={18} />}
            active={showPropertyBoundaries}
            title={showPropertyBoundaries ? 'Hide property boundaries' : 'Show property boundaries'}
            className="map-btn"
            onClick={() => {
              setShowPropertyBoundaries((prevShowBoundaries) => !prevShowBoundaries);
            }}
          />

          {!!noteGeometries?.length && (
            <B.AnchorButton
              icon={<B.Icon icon="area-of-interest" color={ICON_COLOR} size={18} />}
              title={showNotePoints ? 'Hide note locations' : 'Show note locations'}
              active={showNotePoints}
              className="map-btn"
              onClick={() => {
                setShowNotePoints((prevShowNotePoints) => {
                  saveMapState('showNotePoints', !prevShowNotePoints);
                  return !prevShowNotePoints;
                });
              }}
            />
          )}
        </div>
      )}

      {L &&
        map &&
        showNotePoints &&
        noteGeometries?.map((noteGeometry, i) => {
          return (
            <LeafletGeometry
              key={i}
              L={L}
              map={map}
              //typing this with a turf type helper "Geometry" bc note geometries can
              //be Multipolygons and therefore have an additional coordinates field
              geojson={turf.centroid(noteGeometry as geojson.Geometry).geometry}
              firebaseToken={firebaseToken}
            />
          );
        })}

      {L &&
        map &&
        overlayFeatureCollections
          .filter((fc) => savedIsOverlayVisibleByName[fc.name])
          .map((fc) => {
            const color = overlaySettings.find(({name}) => name === fc.name)?.color || '#000';

            return (
              <LeafletGeometry
                key={fc.id}
                L={L}
                map={map}
                geojson={fc}
                firebaseToken={firebaseToken}
                opts={{
                  style: {
                    stroke: true,
                    color,
                    fill: false,
                    weight: 3,
                    opacity: 0.7,
                  },
                  pointToLayer: (_, latlng) =>
                    L!.circleMarker(latlng, {
                      radius: 5,
                      fill: true,
                      fillColor: color,
                      fillOpacity: 1,
                      stroke: false,
                    }),
                }}
              />
            );
          })}
    </div>
  );
};

export default ReportExportFeatureMap;
