import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import MapContent from 'app/components/DeclarativeMap/MapContent';
import FeatureMiniMap from 'app/components/FeatureMiniMap/FeatureMiniMap';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {useStateWithDeps} from 'app/utils/hookUtils';
import {PortalControl} from 'app/utils/mapUtils';

import cs from './MiniMapControl.styl';

/**
 * MapboxGL control that adds a "mini map" for navigating among the features of
 * a multi-feature property.
 *
 * Shows the selected feature and allows clicking on the other features of the
 * property to navigate to them.
 *
 * Uses FeatureMiniMap.
 */
const MiniMapControl: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    featureCollection: HydratedFeatureCollection;
    selectedFeatureIds: I.Set<number>;
    selectFeatureById: (featureId: number) => void;
    hoveredFeatureId: number | null;
    hoverFeatureById: (featureId: number | null) => void;
  }>
> = ({
  map,
  isMapLoaded,
  selectedFeatureIds,
  featureCollection,
  selectFeatureById,
  hoveredFeatureId,
  hoverFeatureById,
}) => {
  const polygonControlButtonEl = React.useRef(document.createElement('div'));
  const polygonControl = React.useRef(new PortalControl(polygonControlButtonEl.current));

  const selectedFeatureName = React.useMemo(
    () =>
      !selectedFeatureIds.isEmpty()
        ? featureCollection
            .get('features')
            .find((f) => f!.get('id') === selectedFeatureIds.first())
            .getIn(['properties', 'name'])
        : undefined,
    [featureCollection, selectedFeatureIds]
  );

  // We want to re-open automatically every time we switch to a new property, so
  // this dep is set up by name.
  //
  // Note that hiding the control when the feature is not part of a
  // multi-feature property is done elsewhere.
  const [isOpen, setIsOpen] = useStateWithDeps(!!selectedFeatureName, [selectedFeatureName]);

  // Finds all the features that match the selected feature’s name, which
  // combined form the property.
  const featuresInProperty = React.useMemo<I.ImmutableOf<ApiFeature[]>>(
    () =>
      selectedFeatureName
        ? featureCollection
            .get('features')
            .filter((f) => f!.getIn(['properties', 'name']) === selectedFeatureName)
            .toList()
        : I.List(),
    [selectedFeatureName, featureCollection]
  );

  return (
    <>
      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[polygonControl.current]}
        controlPosition="bottom-right"
      />

      {ReactDOM.createPortal(
        // If the current property is not multi-feature, we render an empty
        // <div>. We do this rather than remove the control so that we can
        // maintain a consistent control ordering in the map.
        featuresInProperty.size > 1 && (
          <div style={{position: 'relative'}}>
            <B.Tooltip content={!isOpen ? 'Property mini-map' : undefined} position="left">
              <B.AnchorButton
                className={cs.button}
                icon={isOpen ? 'arrow-bottom-right' : 'arrow-top-left'}
                onClick={() => setIsOpen((o) => !o)}
              />
            </B.Tooltip>

            <div className={classnames(cs.popup, {[cs.open]: isOpen})}>
              <FeatureMiniMap
                features={featuresInProperty}
                width={150}
                height={150}
                selectedFeatureId={
                  selectedFeatureIds.size === 1 ? selectedFeatureIds.first() : null
                }
                setSelectedFeatureId={selectFeatureById}
                hoveredFeatureId={hoveredFeatureId}
                hoverFeatureById={hoverFeatureById}
              />
            </div>
          </div>
        ),
        polygonControlButtonEl.current
      )}
    </>
  );
};

export default MiniMapControl;
