import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import {MeasurementSystem} from 'app/modules/Remote/Organization';
import {ImageRefIndex} from 'app/pages/MonitorProjectView/MapStateProvider';
import {MapTool} from 'app/pages/MonitorProjectView/view';
import {PolygonDispatch, PolygonState} from 'app/providers/MapPolygonStateProvider';
import {NotesActions, NotesState} from 'app/stores/NotesStore';
import * as mapUtils from 'app/utils/mapUtils';
import {PortalControl} from 'app/utils/mapUtils';

import MapContent from './MapContent';
import MeasureTool from './MeasureTool';
import cs from './MeasureToolButton.styl';
import RulerIcon from './RulerIcon';

import {MapControlPosition} from '.';

export class MeasureToolButton extends mapboxgl.Evented implements mapboxgl.IControl {
  private container: HTMLElement;

  constructor() {
    super();
    this.container = this.createContainer();
  }

  public getDefaultPosition(): string {
    const defaultPosition = 'top-left';
    return defaultPosition;
  }

  public onAdd(map: mapboxgl.Map): HTMLElement {
    const mapContainer = map.getContainer();
    mapContainer.appendChild(this.container);
    return this.container;
  }

  public onRemove(): void {
    const parentNode = this.container.parentNode;
    if (parentNode) {
      parentNode.removeChild(this.container);
    }
  }

  private createContainer(): HTMLElement {
    const container = document.createElement('div');
    container.className = `mapboxgl-ctrl container`;
    return container;
  }

  public getContainer(): HTMLElement {
    return this.container;
  }
}

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;

  polygonDispatch: PolygonDispatch;
  polygonState: PolygonState;

  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setActiveTool: (t: MapTool | null) => void;
  activeTool: MapTool | null;

  disabled?: boolean;

  measurementSystem?: MeasurementSystem;

  imageRefs: mapUtils.MapImageRefs;
  mapIndex: ImageRefIndex;

  notesState: NotesState;
  notesActions: NotesActions;
}

export default class DistanceControl extends React.Component<Props> {
  private distanceOverlayButtonEl = document.createElement('div');
  private distanceOverlayButton = new PortalControl(this.distanceOverlayButtonEl);

  private renderContent() {
    const {position, isOpen, setIsOpen, disabled} = this.props;

    // TODO(fiona): These buttons are very slightly different looking from the
    // default Mapbox controls, but now is not the time to fix that.
    return (
      <B.Tooltip
        content="Measure"
        position={position === 'top-left' ? B.Position.RIGHT : B.Position.LEFT}
      >
        <B.AnchorButton
          className={cs.controlButton}
          active={isOpen}
          onClick={() => {
            const nextIsOpen = !isOpen;
            setIsOpen(nextIsOpen);
          }}
          disabled={disabled}
          icon={<RulerIcon size={21} />}
        />
      </B.Tooltip>
    );
  }

  render() {
    const {
      map,
      isMapLoaded,
      isOpen,
      setIsOpen,
      position,
      measurementSystem,
      polygonDispatch,
      polygonState,
      setActiveTool,
      activeTool,
      imageRefs,
      mapIndex,
      notesState,
      notesActions,
    } = this.props;

    return (
      <React.Fragment>
        <MapContent
          map={map}
          isMapLoaded={isMapLoaded}
          controls={[this.distanceOverlayButton]}
          controlPosition={position}
        />

        {isOpen && isMapLoaded && (
          <MeasureTool
            map={map}
            onExit={() => setIsOpen(false)}
            measurementSystem={measurementSystem}
            polygonDispatch={polygonDispatch}
            polygonState={polygonState}
            setActiveTool={setActiveTool}
            activeTool={activeTool}
            imageRefs={imageRefs}
            notesState={notesState}
            notesActions={notesActions}
            mapIndex={mapIndex}
          />
        )}

        {ReactDOM.createPortal(this.renderContent(), this.distanceOverlayButtonEl)}
      </React.Fragment>
    );
  }
}
