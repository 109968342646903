/* eslint no-console: 0 */

import React from 'react';

import {InvalidateOrderableScenes} from 'app/providers/OrderableScenesProvider';
import {useFirebaseDatabase} from 'app/tools/Firebase';

const DEBOUNCE_MS = 1500;

interface DataSnapshotValue {
  [featureId: string]:
    | {
        data?: string;
        properties?: string;
      }
    // "string" only here for compatibility with the "data" and "properties" keys.
    | string
    | undefined;

  data?: string;
  properties?: string;
}

interface Props {
  featureCollectionId: number;
  refreshFeatureCollection: (id: number) => void | Promise<void>;
  invalidateFeature: (args: {
    featureId: number;
    featureCollectionId: number;
  }) => void | Promise<void>;
  invalidateFeatureData: (args: {
    featureId: number;
    featureCollectionId: number;
  }) => void | Promise<void>;
  invalidateOrderableScenes: InvalidateOrderableScenes;
  refreshFeatures: (clear?: boolean | undefined) => Promise<void>;
}

/**
 * Listens to the realtime channel and updates FeatureCollection and Features
 * with new data that comes in.
 *
 * Adapted from eventChannel.ts and saga.ts.
 *
 * Needs to be rendered underneath AuthProvider so that the firebase app is set
 * up.
 */
const FeatureCollectionMonitor: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  featureCollectionId,
  refreshFeatureCollection,
  invalidateFeature,
  invalidateFeatureData,
  invalidateOrderableScenes,
  refreshFeatures,
}) => {
  const database = useFirebaseDatabase();

  // We keep the latest featureIds in a ref so that we don’t have to unsubscribe
  // / resubscribe every time the user switches features (which we would have to
  // do if the listener was closing over a particular value of the prop).
  const refreshFeatureCollectionRef = React.useRef(refreshFeatureCollection);
  const invalidateFeatureRef = React.useRef(invalidateFeature);
  const invalidateFeatureDataRef = React.useRef(invalidateFeatureData);
  const invalidateOrderableScenesRef = React.useRef(invalidateOrderableScenes);
  const refreshFeaturesRef = React.useRef(refreshFeatures);

  React.useEffect(() => {
    refreshFeatureCollectionRef.current = refreshFeatureCollection;
    invalidateFeatureRef.current = invalidateFeature;
    invalidateFeatureDataRef.current = invalidateFeatureData;
    invalidateOrderableScenesRef.current = invalidateOrderableScenes;
    refreshFeaturesRef.current = refreshFeatures;
  }, [
    refreshFeatureCollection,
    invalidateFeature,
    invalidateFeatureData,
    invalidateOrderableScenes,
    refreshFeatures,
  ]);

  React.useEffect(() => {
    const featureCollectionRef = database.ref(`realtime/featureCollections/${featureCollectionId}`);

    // Dict of childKey: Date so we can debounce by childKey
    const lastRunTime: Record<string, Date> = {};

    // The last value for the entire featureCollection.
    let lastFcValue: DataSnapshotValue = {};

    const handleChildChanged = (childSnapshot: firebase.database.DataSnapshot) => {
      // This will be either "data", "properties", or a feature ID.
      const childKey = childSnapshot.key;
      const childValue: NonNullable<DataSnapshotValue['']> = childSnapshot.val();

      // If childKey is null, we can't (1) access it on the `lastValue` object,
      // or (2) pass it through the `parseInt` method. Therefore, no-op.
      if (!childKey) {
        return;
      }

      // TODO(fiona): Would be nice to re-work this throttle so that updates run
      // on the trailing edge.
      if (lastRunTime[childKey]) {
        if (Date.now() - lastRunTime[childKey].getTime() < DEBOUNCE_MS) {
          return;
        }
      }

      lastRunTime[childKey] = new Date();

      const lastChildValue = lastFcValue[childKey];
      lastFcValue[childKey] = childValue;

      // These keys refer to changes in the feature collection.
      if (childKey === 'data' || childKey === 'properties') {
        // These will be datetime strings
        if (childValue !== lastChildValue) {
          console.info(`Received realtime update for featureCollection ${featureCollectionId}`);
          refreshFeatureCollectionRef.current(featureCollectionId);
        }
      } else if (typeof childValue === 'object') {
        const featureId = parseInt(childKey, 10);

        console.info(
          `Received realtime update for featureCollection ${featureCollectionId}, feature ${featureId}`
        );

        const needsDataUpdate =
          typeof lastChildValue !== 'object' || childValue.data !== lastChildValue.data;

        const needsPropertyUpdate =
          typeof lastChildValue !== 'object' || childValue.properties !== lastChildValue.properties;

        if (needsDataUpdate) {
          // Normally reloading all the data is a lot, but since FeatureData
          // comes through useCachedApiGet, we can invalidate it and then it
          // will only reload if there’s a current component that’s using it.
          invalidateFeatureDataRef.current({
            featureCollectionId,
            featureId,
          });

          // Also reload the orderable scene data as a side effect when the data
          // for the target feature ID has changed.
          invalidateOrderableScenesRef.current(featureId);
        }

        if (needsPropertyUpdate) {
          // update feature properties
          invalidateFeatureRef.current({featureCollectionId, featureId});
        }

        // lastChildValue being undefined signals that the feature was just created so
        // we want to refetch features so the new feature is in the feature list
        if (lastChildValue === undefined) {
          refreshFeaturesRef.current();
        }
      }
    };

    featureCollectionRef.once('value').then((lastValueSnapshot) => {
      lastFcValue = lastValueSnapshot.val() || {};
    });

    console.info(`Subscribing to realtime channel for featureCollection ${featureCollectionId}`);
    featureCollectionRef.on('child_changed', handleChildChanged);

    return () => {
      console.info(
        `Unsubscribing from realtime channel for featureCollection ${featureCollectionId}`
      );
      featureCollectionRef.off('child_changed', handleChildChanged);
    };
  }, [database, featureCollectionId]);

  return null;
};

export default FeatureCollectionMonitor;
