import React from 'react';

import {useFirebaseStorage} from 'app/tools/Firebase';
import * as firebaseUtils from 'app/utils/firebaseUtils';
import {useMemoAsync} from 'app/utils/hookUtils';

const ResizedImage: React.FunctionComponent<
  React.PropsWithChildren<{
    imageUrl: string;
    dimension: 300 | 1500 /* Resized images are only generated at these dimensions */;
  }>
> = ({imageUrl, dimension}) => {
  const firebaseStorage = useFirebaseStorage();

  // Use resized image if it exists, otherwise fall back to original image
  const [bestImage] = useMemoAsync(
    async () =>
      (await firebaseUtils.getResizedImageUrl(firebaseStorage, imageUrl, dimension)) ?? imageUrl,
    [firebaseStorage, imageUrl, dimension]
  );

  return bestImage ? <img src={bestImage} /> : null;
};

export default ResizedImage;
