// import * as B from '@blueprintjs/core';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {DataRange} from 'app/components/AnalyzePolygonChart/types';
import {getMeasurementSystem} from 'app/modules/Remote/Organization';
import {TileData} from 'app/stores/RasterCalculationStore';
import * as CONSTANTS from 'app/utils/constants';
import {isUpstreamAndMemberOfInternalOrg, useUserInfo} from 'app/utils/featureFlags';
import {StatusMaybe} from 'app/utils/hookUtils';
import {getLayer} from 'app/utils/layerUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import {formatMeasurement} from 'app/utils/mathUtils';

import PixelInfoDebugger from './PixelInfoDebugger';
import cs from './pixelInfoStyles.styl';
import {MapMousePopup} from '../../MapMousePopup/MapMousePopup';

export interface PixelInfoDebugData {
  pixelColor: string | null;
  emulatedImageData: ImageData | null;
  markerCoordinates: {
    [key: string]: {x: number; y: number};
  } | null;
  rawPixelReading: number;
  tileBounds: mapboxgl.LngLatBounds;
  tileData: StatusMaybe<TileData>;
}

export interface ScaledPixelValue {
  displayValue: string | null;
  coords: mapboxgl.LngLat;
  dataRange: DataRange;
}

interface PixelInfoPopupContextValue {
  state: {
    isOpen: boolean;
    projectedMousePostion: mapboxgl.LngLat | null;
    scaledPixelValue: ScaledPixelValue | null;
    pixelInspectorReadyStates: {
      [key: string]: boolean;
    };
    debugData: PixelInfoDebugData | null;
    isPopupFrozen: boolean;
  };
  actions: {
    setIsOpen: (isOpen: boolean) => void;
    setProjectedMousePostion: (projectedMousePostion: mapboxgl.LngLat | null) => void;
    setScaledPixelValue: (scaledPixelValue: ScaledPixelValue | null) => void;
    setPixelInspectorReady: (panelIndex: number, isReady: boolean) => void;
    setDebugData: (debugData: PixelInfoDebugData | null) => void;
    setPanelIndex: (panelIndex: number) => void;
    setMap: (map: mapboxgl.Map | null) => void;
    togglePopupFrozen: () => void;
    setElevation: (elevation: number | null) => void;
  };
}

export const PixelInfoPopupContext = React.createContext<PixelInfoPopupContextValue>({
  state: {
    isOpen: false,
    projectedMousePostion: null,
    scaledPixelValue: null,
    pixelInspectorReadyStates: {},
    debugData: null,
    isPopupFrozen: false,
  },
  actions: {
    setIsOpen: () => {},
    setProjectedMousePostion: () => {},
    setScaledPixelValue: () => {},
    setPixelInspectorReady: () => {},
    setPanelIndex: () => {},
    setDebugData: () => {},
    setMap: () => {},
    setElevation: () => {},
    togglePopupFrozen: () => {},
  },
});

export const PixelInfoPopupProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    imageRefs: mapUtils.MapImageRefs;
  }>
> = ({children, isOpen, setIsOpen, imageRefs}) => {
  const [map, setMap] = React.useState<mapboxgl.Map | null>(null);
  const [projectedMousePostion, setProjectedMousePostion] = React.useState<mapboxgl.LngLat | null>(
    null
  );
  const [elevation, setElevation] = React.useState<number | null>(null);
  // The value of the current mouse pixel adjusted for the data range, and the raw pixel value.
  const [scaledPixelValue, setScaledPixelValue] = React.useState<ScaledPixelValue | null>(null);
  // Tracks if the pixel info tool is ready to be used, currently based on if the calculator is current.
  const [pixelInspectorReadyStates, setPixelInspectorReadyStates] = React.useState<{
    [key: string]: boolean;
  }>({});
  const appendPixelInspectorReadyState = React.useCallback(
    (panelIndex: number, isReady: boolean) => {
      setPixelInspectorReadyStates((prev) => ({...prev, [panelIndex]: isReady}));
    },
    [setPixelInspectorReadyStates]
  );
  const [panelIndex, setPanelIndex] = React.useState<number>(0);

  // Sticky ppopup tools: when the user clicks on the map, the popup will stick to the clicked
  // location until the user clicks the map again or the imageRefs change.
  const [isPopupFrozen, setPopupFrozen] = React.useState<boolean>(false);

  const togglePopupFrozen = () => {
    setPopupFrozen((prev) => !prev);
  };

  React.useEffect(() => {
    setPopupFrozen(false);
  }, [imageRefs]);

  // Debugger state tools.
  const [_, profile] = useUserInfo();
  const [debugData, setDebugData] = React.useState<PixelInfoDebugData | null>(null);

  const resetAndClosePixelInfoPopup = () => {
    setIsOpen(false);
    setPopupFrozen(false);
    setProjectedMousePostion(null);
    setScaledPixelValue(null);
    setDebugData(null);
    setElevation(null);
  };

  return (
    <PixelInfoPopupContext.Provider
      value={{
        actions: {
          setIsOpen,
          setProjectedMousePostion,
          setScaledPixelValue,
          setPixelInspectorReady: appendPixelInspectorReadyState,
          setDebugData,
          setPanelIndex,
          setMap,
          togglePopupFrozen,
          setElevation,
        },
        state: {
          isOpen,
          projectedMousePostion,
          scaledPixelValue,
          pixelInspectorReadyStates,
          debugData,
          isPopupFrozen,
        },
      }}
    >
      {children}
      {map && imageRefs[panelIndex]?.layerKey && (
        <PixelInfoPopup
          isOpen={isOpen}
          projectedMousePostion={projectedMousePostion}
          scaledPixelValue={scaledPixelValue}
          pixelInspectorReadyStates={
            isPopupFrozen
              ? Object.fromEntries(
                  Object.entries(pixelInspectorReadyStates).map(([key]) => [key, true])
                )
              : pixelInspectorReadyStates
          }
          layerKey={imageRefs[panelIndex]?.layerKey}
          panelIndex={panelIndex}
          map={map}
          mapIndex={panelIndex}
          isPopupFrozen={isPopupFrozen}
          elevation={elevation}
          closeCallback={isPopupFrozen ? () => resetAndClosePixelInfoPopup() : undefined}
        />
      )}
      {isUpstreamAndMemberOfInternalOrg(profile) &&
        isOpen &&
        imageRefs[panelIndex]?.layerKey &&
        debugData?.emulatedImageData &&
        debugData.markerCoordinates && (
          <PixelInfoDebugger
            emulatedImageData={debugData.emulatedImageData}
            debugMarkerCoordinates={debugData.markerCoordinates}
            debugPixelColor={debugData.pixelColor}
            scaledPixelValue={scaledPixelValue}
            activeLayerKey={imageRefs[panelIndex].layerKey}
            rawPixelReading={debugData.rawPixelReading}
            tileBounds={debugData.tileBounds}
            tileData={debugData.tileData}
            isPixelInspectorReady={pixelInspectorReadyStates[panelIndex]}
          />
        )}
    </PixelInfoPopupContext.Provider>
  );
};

export function usePixelInfoPopup(): PixelInfoPopupContextValue {
  const value = React.useContext(PixelInfoPopupContext);
  if (!value) {
    throw new Error('usePixelInfoPopup must be beneath a PixelInfoPopupProvider');
  }
  return value;
}

export const PixelInfoPopup: React.FunctionComponent<{
  isOpen: boolean;
  projectedMousePostion: mapboxgl.LngLat | null;
  scaledPixelValue: ScaledPixelValue | null;
  pixelInspectorReadyStates: {
    [key: string]: boolean;
  };
  layerKey: string | null;
  panelIndex: number;
  map: mapboxgl.Map;
  mapIndex: number;
  isPopupFrozen: boolean;
  elevation: number | null;
  closeCallback?: () => void | undefined;
}> = ({
  isOpen,
  projectedMousePostion,
  scaledPixelValue,
  pixelInspectorReadyStates,
  layerKey,
  panelIndex,
  map,
  mapIndex,
  isPopupFrozen,
  elevation,
  closeCallback,
}) => {
  const [organization] = useUserInfo();

  const activeLayerReady = React.useMemo(() => {
    return pixelInspectorReadyStates[panelIndex];
  }, [panelIndex, pixelInspectorReadyStates]);

  const [lng, lat] = [scaledPixelValue?.coords.lng, scaledPixelValue?.coords.lat].map(
    (coord) => coord?.toFixed(5) || 'N/A'
  );

  const layerInfo = layerKey ? getLayer(layerKey) : null;
  const hasRawLayer = layerKey && layerUtils.getRawLayerKey(layerKey);

  const islayerWithPixelValues =
    layerInfo &&
    hasRawLayer &&
    (layerInfo.type === 'data' || layerInfo?.resolutionCategory === 'data');

  const elevationElement = React.useMemo(() => {
    if (organization && elevation !== null) {
      const measurementSystem = getMeasurementSystem(organization);
      const formattedMeasurement = formatMeasurement({
        value: elevation,
        unit: CONSTANTS.UNIT_LENGTH_M,
        unitTo: measurementSystem === 'metric' ? CONSTANTS.UNIT_LENGTH_M : CONSTANTS.UNIT_LENGTH_FT,
        decimals: 2,
      }).valueWithUnit;
      return elevation !== null && `<div>Elevation: ${formattedMeasurement}</div>`;
    }
  }, [elevation, organization]);

  const headerElement = document.createElement('span');
  headerElement.className = cs.pixelInfoPopupHeader;
  headerElement.innerHTML = `Pixel info`;

  const bodyElement = React.useMemo(() => {
    const bodyContainer = document.createElement('div');

    if (!activeLayerReady) {
      bodyContainer.textContent = 'Loading...';
      return bodyContainer;
    }

    const footerElement = document.createElement('span');
    footerElement.textContent = `Click ${isPopupFrozen ? 'on map to unpin' : 'to pin'}`;
    if (elevation == null) footerElement.textContent += `, open 3D terrain to view elevation data`;
    footerElement.className = cs.pixelInfoPopupFooter;

    const bodyContent = document.createElement('div');
    bodyContent.className = cs.pixelInfoPopupContentContainer;
    bodyContainer.appendChild(bodyContent);

    if (islayerWithPixelValues) {
      bodyContent.innerHTML = `
          <span>Value: ${scaledPixelValue?.displayValue || 'N/A'}</span>
          ${
            layerInfo && 'dataRange' in layerInfo
              ? `<span>${layerInfo?.display} range: ${layerInfo?.dataRange.join(' to ')}</span>`
              : ''
          }
      `;
    }
    bodyContent.innerHTML += `<span>Coordinates: ${lng}, ${lat}</span>`;
    bodyContent.innerHTML += `${elevationElement || ''}`;
    bodyContent.innerHTML += `${footerElement.outerHTML}`;

    return bodyContainer;
  }, [
    activeLayerReady,
    isPopupFrozen,
    elevation,
    islayerWithPixelValues,
    elevationElement,
    scaledPixelValue?.displayValue,
    layerInfo,
    lng,
    lat,
  ]);

  return (
    <MapMousePopup
      isOpen={isOpen && !!scaledPixelValue?.displayValue}
      projectedMousePostion={projectedMousePostion}
      bodyContent={bodyElement}
      headerContent={headerElement}
      map={map}
      mapIndex={mapIndex}
      closeCallback={closeCallback}
    />
  );
};
