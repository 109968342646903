import url from 'url';

import firebase from 'firebase';

export function getFileNameFromObjectDownloadUrl(
  downloadURL: string | null,
  maxCharCount: number | null = null
) {
  if (downloadURL === null) {
    return null;
  }

  try {
    // #s in the download url cause everything after the # to not be parsed
    const decodedDownloadURL = decodeURIComponent(downloadURL).replace('#', '');
    const {pathname} = url.parse(decodedDownloadURL);

    if (!pathname || !pathname.includes('/')) {
      return null;
    }

    // Capture the characters after the final forward slash (/) in the URL
    // pathname.
    let filename = pathname.match(new RegExp('.*/(.*)$'))![1];

    // IF a maximum character count is specified, replace extraneous internal
    // characters with an ellipsis.
    if (maxCharCount && filename.length > maxCharCount) {
      const firstCharCount = Math.ceil((2 / 3) * maxCharCount);
      const firstChars = filename.substr(0, firstCharCount);
      const lastCharCount = maxCharCount - firstCharCount - 1;
      const lastChars = filename.substr(filename.length - lastCharCount, filename.length);
      filename = `${firstChars}…${lastChars}`;
    }

    return filename;
  } catch (error) {
    return null;
  }
}

// Modify path to include resized directory and append resized dimensions to the filename.
// E.g. `monitoring/108304116/notes/f88ecb3d-ccdd-4fac-8358-8c91620c5996/beach.jpeg` ->
// monitoring/108304116/notes/f88ecb3d-ccdd-4fac-8358-8c91620c5996/resized/beach_300x300.jpeg
export function getResizedImagePath(originalImagePath: string, dimension: 300 | 1500) {
  const substitution = `$1/resized$2_${dimension}x${dimension}.$3`;
  const resizedImagePath = originalImagePath.replace(/(.*notes\/.*)(\/.*)\.(.*)/g, substitution);

  return resizedImagePath;
}

export async function getResizedImageUrl(
  storage: firebase.storage.Storage,
  imageUrl: string,
  dimension: 300 | 1500
) {
  const originalRef = storage.refFromURL(imageUrl);
  const originalPath = originalRef.fullPath;

  const resizedImagePath = getResizedImagePath(originalPath, dimension);

  const storageRef = storage.ref();
  const fileRef = storageRef.child(resizedImagePath);

  // Firebase throws an exception if the download url does not exist or if another error occurs.
  // Return null in the event of any exception.
  try {
    const resizedImageUrl: string = await fileRef.getDownloadURL();
    return resizedImageUrl;
  } catch {
    return null;
  }
}

/**
 * Utility function for uploading a file to any Firebase Storage location.
 */
export async function uploadFile(storage: firebase.storage.Storage, file: File, filepath: string) {
  const storageRef = storage.ref();
  const fileRef = storageRef.child(filepath);
  const snapshot = await fileRef.put(file);
  return snapshot.ref.getDownloadURL();
}
