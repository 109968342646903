import * as B from '@blueprintjs/core';
import React from 'react';

import LensLogoSvg from 'app/components/Logo/LensLogoSvg';
import ResizedImage from 'app/components/ResizedImage';
import {ReportNotePage} from 'app/modules/Remote/Feature';

import {ICON_COLOR} from '../ReportExportFeature';

//The separate page that renders attached images
export const AttachedImagePage: React.FunctionComponent<
  React.PropsWithChildren<{
    reportNoteState: ReportNotePage;
    setReportState: (ReportStatePath) => void;
    noteIndex: number;
    title: string;
    attachmentImages: any;
    alignment: 'horizontal' | 'vertical';
    areAttachedImagesVisible: boolean;
    heroImage: string | null;
    setHeroImage: React.Dispatch<React.SetStateAction<string | null>>;
  }>
> = ({
  reportNoteState,
  areAttachedImagesVisible,
  setReportState,
  noteIndex,
  title,
  attachmentImages,
  heroImage,
  setHeroImage,
}) => {
  return (
    <section
      className={
        !reportNoteState.hidden && areAttachedImagesVisible
          ? 'note'
          : 'note note-unselected no-print'
      }
    >
      <div className="note-control-btns no-print">
        <B.AnchorButton
          title={
            areAttachedImagesVisible
              ? 'Exclude attached images from report'
              : 'Include attached images in report'
          }
          icon={areAttachedImagesVisible ? 'eye-off' : 'eye-open'}
          active={!areAttachedImagesVisible}
          onClick={() => {
            setReportState([
              ['notePages', noteIndex, 'attachmentsHidden'],
              !reportNoteState.attachmentsHidden,
            ]);
          }}
        />
      </div>

      <div className="title-and-lens-watermark">
        <h2 contentEditable suppressContentEditableWarning>
          {title} | Attachments
        </h2>
        <div className="lens-watermark">
          <LensLogoSvg />
        </div>
      </div>
      <div className="note-interpretation-images">
        {attachmentImages.map((image, i) => (
          <div
            key={i}
            className="note-interpretation-image"
            // Some styling to cluster remaining images around a central
            // point, creating a "pinwheel" design since we can’t do a
            // true masonry grid with fixed width and height.
            style={{
              // If we have only one or two attached images, they may fill up
              // to the full width. If more, split them across two columns.
              width: attachmentImages.length > 2 ? '50%' : '100%',
              // If we only have one attached image, it may fill up
              // the full height. If more, split them across two rows.
              height: attachmentImages.length > 1 ? '50%' : '100%',
              // Bottom-align images on the first row them so that they
              // cluster around the center of all attached images. This
              // also ensures that the "Make primary image" button on
              // hover is visible over the image instead of potentially
              // floating in white space.
              alignItems: attachmentImages.length > 2 && i % 2 === 0 ? 'flex-end' : 'flex-start',
            }}
          >
            {!!heroImage && (
              <div className="no-print set-hero-image-btn">
                <B.AnchorButton
                  icon={<B.Icon color={ICON_COLOR} icon="arrow-up" />}
                  title="Make primary image"
                  onClick={() => {
                    setHeroImage(image);
                  }}
                />
              </div>
            )}
            <ResizedImage imageUrl={image} dimension={1500} />
          </div>
        ))}
      </div>
    </section>
  );
};
