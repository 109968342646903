import React from 'react';

import api from 'app/modules/Remote/api';
import {ApiNotification} from 'app/modules/Remote/Organization';
import {useCachedApiGet} from 'app/utils/hookUtils';

export interface NotificationsValue {
  notifications: ApiNotification[];
  loadingNotifications: boolean;
  atEndOfList: boolean;
}

const NotificationsContext = React.createContext<NotificationsValue | undefined>(undefined);

const NotificationsProvider: React.FunctionComponent<
  React.PropsWithChildren<{orgId: string; page: number}>
> = ({orgId, page = 1, children}) => {
  const [loadingNotifications, setLoadingNotifications] = React.useState(false);
  const [notifications, setNotifications] = React.useState<ApiNotification[]>([]);
  const [atEndOfList, setAtEndOfList] = React.useState(false);

  const pageSize = 100;

  const [getNotifications] = useCachedApiGet(async (_, orgId: string, page: number) => {
    try {
      return (
        await api.organizations.listNotifications(
          orgId,
          {page: page, perPage: pageSize, type: 'all'},
          {getAllPages: false}
        )
      )
        .get('data')
        .toJS() as ApiNotification[];
    } catch (e) {
      console.error(e as string);
    }
  }, []);

  // reset loading state, notifications, and atEndOfList every time page updates
  React.useEffect(() => {
    const notificationsMaybe = getNotifications([orgId, page]);
    const notifications = notificationsMaybe.value || [];
    setLoadingNotifications(notificationsMaybe.status === 'unknown');
    setNotifications((prevNotifications) => [...prevNotifications, ...notifications]);
    setAtEndOfList(notifications.length < pageSize);
  }, [getNotifications, page, orgId]);

  return (
    <NotificationsContext.Provider value={{notifications, loadingNotifications, atEndOfList}}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const FakeNotificationsProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    notifications?: ApiNotification[];
    loadingNotifications?: boolean;
    atEndOfList?: boolean;
  }>
> = ({children, notifications = [], loadingNotifications = false}) => {
  const value = React.useMemo<NotificationsValue>(
    () => ({
      notifications: notifications,
      loadingNotifications: loadingNotifications,
      atEndOfList: false,
    }),
    [notifications, loadingNotifications]
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export function useNotifications(): NotificationsValue {
  const value = React.useContext(NotificationsContext);

  if (value === undefined) {
    throw new Error('useNotifications must be beneath a NotificationsProvider');
  }

  return value;
}

export default NotificationsProvider;
