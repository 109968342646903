import * as B from '@blueprintjs/core';
import React from 'react';

/**
 * B.Icon component for our polygon-filter SVG.
 * This SVG is based of of Blueprint's polygon-filter icon but customized
 * to have an optional transparent fill and custom colors.
 */
const PolygonFilterIcon: React.FunctionComponent<
  React.PropsWithChildren<{
    color?: string;
    fill?: boolean;
    size?: number;
  }>
> = ({color, fill, size = 16}) => (
  <B.Icon
    style={{cursor: 'pointer'}}
    icon={
      // Re-using bp5-icon here gives us consistent color / fill handling with
      // Blueprint’s built-in icons.
      <span className="bp5-icon">
        <svg
          width={size}
          height={size}
          viewBox="0 0 20 20"
          enableBackground="new 0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          xmlSpace="preserve"
        >
          <g id="polygon_filter">
            <g>
              {/* This path represents the filled space inside the icon */}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18,7c-0.27,0-0.52,0.05-0.75,0.15l-6.28-4.88C10.98,2.18,11,2.09,11,2
                c0-1.1-0.9-2-2-2S7,0.9,7,2c0,0.06,0.01,0.12,0.02,0.19l-4.19,3C2.57,5.07,2.29,5,2,5C0.9,5,0,5.9,0,7c0,0.74,0.4,1.38,1,1.72
                v7.55C0.4,16.62,0,17.26,0,18c0,1.1,0.9,2,2,2c0.74,0,1.38-0.4,1.72-1h7.55c0.35,0.6,0.98,1,1.72,1c1.1,0,2-0.9,2-2
                c0-0.37-0.11-0.72-0.29-1.02L18.03,11c1.09-0.02,1.97-0.9,1.97-2C20,7.9,19.1,7,18,7z"
                fill={fill ? color : 'none'}
                fillOpacity={0.5}
              />
              {/* bp3 polygon-filter icon */}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18,7c-0.27,0-0.52,0.05-0.75,0.15l-6.28-4.88C10.98,2.18,11,2.09,11,2
			c0-1.1-0.9-2-2-2S7,0.9,7,2c0,0.06,0.01,0.12,0.02,0.19l-4.19,3C2.57,5.07,2.29,5,2,5C0.9,5,0,5.9,0,7c0,0.74,0.4,1.38,1,1.72
			v7.55C0.4,16.62,0,17.26,0,18c0,1.1,0.9,2,2,2c0.74,0,1.38-0.4,1.72-1h7.55c0.35,0.6,0.98,1,1.72,1c1.1,0,2-0.9,2-2
			c0-0.37-0.11-0.72-0.29-1.02L18.03,11c1.09-0.02,1.97-0.9,1.97-2C20,7.9,19.1,7,18,7z M12.97,16c-0.72,0.01-1.35,0.41-1.69,1H3.72
			C3.55,16.7,3.3,16.45,3,16.28V8.72C3.6,8.38,4,7.74,4,7c0-0.06-0.01-0.12-0.02-0.19l4.19-3C8.43,3.93,8.71,4,9,4
			c0.27,0,0.52-0.05,0.75-0.15l6.28,4.88C16.02,8.82,16,8.91,16,9c0,0.37,0.11,0.72,0.29,1.02L12.97,16z"
                fill={color}
              />
            </g>
          </g>
        </svg>
      </span>
    }
  />
);

export default PolygonFilterIcon;
