import classnames from 'classnames';
import React from 'react';

import LensLogo from 'app/components/Logo/LensLogo';

import cs from './styles.styl';

/**
 * Landing page for new users to be directed to after a successful payment
 * signing up in Stripe
 */
const NewCustomerSignUpSuccess: React.FunctionComponent = () => {
  return (
    <div className={cs.user}>
      <LensLogo isWhite={true} className={cs.logo} />
      <div className={classnames(cs.content, cs.signUpSuccess)}>
        <h2>Thank you for choosing Lens!</h2>
        <div>Check your email for a link to create your account</div>
        <div className={cs.note}>
          Note: If you entered banking details manually, it may take a few days to verify your
          account first.
        </div>
      </div>
    </div>
  );
};

export default NewCustomerSignUpSuccess;
