import * as B from '@blueprintjs/core';
import {useVirtualizer} from '@tanstack/react-virtual';
import cssEscape from 'css.escape';
import {History} from 'history';
import * as I from 'immutable';
import queryString from 'query-string';
import React from 'react';
import {Filters, SortingRule} from 'react-table';

import AppNav from 'app/components/AppNav';
import {ButtonLarge} from 'app/components/ButtonLarge';
import {PushNotificationFn} from 'app/components/Notification';
import OrderImageryModal, {getBillableAcres, orderImagery} from 'app/components/OrderImageryModal';
import TaskImageryModal from 'app/components/TaskImageryModal/TaskImageryModal';
import {ApiFeature, ApiLensImageryRecord, ApiOrderableScene} from 'app/modules/Remote/Feature';
import {
  ApiAlertCountsByFeatureId,
  ApiFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser, getAreaUnit} from 'app/modules/Remote/Organization';
import {ApiImageryContract, ApiProject} from 'app/modules/Remote/Project';
import CustomizeTagsModal from 'app/pages/MonitorProjectView/CustomizeTagsDialog';
import {setIsImageHidden} from 'app/pages/MonitorProjectView/utils';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {FeaturesActions, FeaturesStatus} from 'app/providers/FeaturesProvider';
import {useImageryPrices} from 'app/providers/ImageryPricesProvider';
import {
  InvalidateOrderableScenes,
  OrderableScenesLoader,
} from 'app/providers/OrderableScenesProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import * as C from 'app/utils/constants';
import featureFlags from 'app/utils/featureFlags';
import * as featureUtils from 'app/utils/featureUtils';
import {
  CachedApiGetterOptions,
  StatusMaybe,
  useHistoryState,
  useStateWithDeps,
} from 'app/utils/hookUtils';
import {checkFeatureCollectionIsProcessingStatus} from 'app/utils/layerUtils';
import * as routeUtils from 'app/utils/routeUtils';
import {TAG_KIND} from 'app/utils/tagUtils';

import ImageryPurchaseDrawer from './ImageryPurchaseDrawer';
import * as cs from './PropertyOverview.styl';
import {
  PropertyRow,
  SelectedRowActions,
  TableControls,
  TableHeader,
  getSelectedPropertyFlatRows,
  getSelectedPropertyRows,
  usePropertyTable,
} from './PropertyTable';

import {NoteCountsByFeatureId} from '.';

export interface Props {
  pushNotification: PushNotificationFn;

  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  loggedInUserActions: LoggedInUserActions;
  organizationUsers: ApiOrganizationUser[];
  profile: I.ImmutableOf<ApiOrganizationUser>;
  selectedProject: I.ImmutableOf<ApiProject>;
  selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;

  imageryRecords: StatusMaybe<I.ImmutableOf<ApiLensImageryRecord[]>>;
  refreshImageryRecords: () => void;

  userNoteCountsByFeatureId: StatusMaybe<NoteCountsByFeatureId>;
  alertNoteCountsByFeatureId: StatusMaybe<ApiAlertCountsByFeatureId>;

  getCurrentImageryContract: (
    projectId: string,
    opts?: CachedApiGetterOptions
  ) => StatusMaybe<ApiImageryContract | null>;

  /** Year is state of PropertyOverviewDataProvider because it affects loading */
  year: number;
  setYear: (year: number) => void;

  features: I.ImmutableOf<ApiFeature[]>;
  featuresStatus: FeaturesStatus;
  featuresActions: FeaturesActions;
  projectsActions: ProjectsActions;

  initialFilterState: Filters<I.ImmutableOf<ApiFeature[]>>;
  initialSortState: SortingRule<I.ImmutableListOf<ApiFeature>>[];
  onFilterStateChange: (filters: Filters<I.ImmutableOf<ApiFeature[]>>) => void;
  onSortStateChange: (sortBy: SortingRule<I.ImmutableListOf<ApiFeature>>[]) => void;

  isExpandedForTest?: boolean;

  orderableScenesLoader: OrderableScenesLoader;
  invalidateOrderableScenes: InvalidateOrderableScenes;
}

/** Type of the map we build from feature ID to imagery notes */
export type ImageryNotificationsByFeatureId = I.Map<
  number,
  I.Iterable<number, I.ImmutableOf<ApiLensImageryRecord>>
>;

/**
 * Lens property overview page.
 *
 * This page maintains some state with the browser to make it bookmarkable and
 * so when you click "back" to it it will be in roughly the same shape that you
 * left it.
 *
 * The state of the table filters is stored in the location’s hash. This makes
 * the filters bookmarkable. We update them with history.replace to avoid adding
 * a bunch of entries to the browser history, however.
 *
 * The state of the table scroll and any focused table row is stored in history
 * state. This makes it available only when clicking back and forward to the
 * page. We do this so you can click into a property, then click back and see
 * the table just as you left it. Preserving the focus both helps with keeping
 * your place and also makes keyboard navigation persist.
 */
const PropertyOverview: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  pushNotification,
  history,
  organization,
  organizationUsers,
  loggedInUserActions,
  profile,
  selectedProject,
  selectedFeatureCollection,
  imageryRecords,
  userNoteCountsByFeatureId,
  alertNoteCountsByFeatureId,
  refreshImageryRecords,

  year,
  setYear,

  getCurrentImageryContract,

  features,
  featuresStatus,
  featuresActions,
  projectsActions,

  initialFilterState,
  initialSortState,
  onFilterStateChange,
  onSortStateChange,

  isExpandedForTest,

  orderableScenesLoader,
  invalidateOrderableScenes,
}) => {
  return (
    <div className={cs.page}>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={selectedProject}
        isMinimal={true}
        loggedInUserActions={loggedInUserActions}
        showHotkeys
      >
        <div className={cs.portfolioName}>{selectedProject.get('name')}</div>
      </AppNav>

      <PropertyOverviewProperties
        history={history}
        organization={organization}
        organizationUsers={organizationUsers}
        imageryRecords={imageryRecords}
        userNoteCountsByFeatureId={userNoteCountsByFeatureId}
        alertNoteCountsByFeatureId={alertNoteCountsByFeatureId}
        refreshImageryRecords={refreshImageryRecords}
        profile={profile}
        year={year}
        selectedProject={selectedProject}
        selectedFeatureCollection={selectedFeatureCollection}
        pushNotification={pushNotification}
        getCurrentImageryContract={getCurrentImageryContract}
        features={features}
        featuresStatus={featuresStatus}
        featuresActions={featuresActions}
        projectsActions={projectsActions}
        initialFilterState={initialFilterState}
        initialSortState={initialSortState}
        onFilterStateChange={onFilterStateChange}
        onSortStateChange={onSortStateChange}
        isExpandedForTest={isExpandedForTest}
        setYear={setYear}
        orderableScenesLoader={orderableScenesLoader}
        invalidateOrderableScenes={invalidateOrderableScenes}
      />
    </div>
  );
};

const PropertyOverviewProperties: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History<any>;
    organization: I.ImmutableOf<ApiOrganization>;
    organizationUsers: ApiOrganizationUser[];
    selectedProject: I.ImmutableOf<ApiProject>;
    selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
    imageryRecords: StatusMaybe<I.ImmutableListOf<ApiLensImageryRecord>>;
    userNoteCountsByFeatureId: StatusMaybe<NoteCountsByFeatureId>;
    alertNoteCountsByFeatureId: StatusMaybe<ApiAlertCountsByFeatureId>;
    refreshImageryRecords: () => void;
    profile: I.MapAsRecord<I.ImmutableFields<ApiOrganizationUser>>;
    year: number;
    pushNotification: PushNotificationFn;
    getCurrentImageryContract: (
      projectId: string,
      opts?: CachedApiGetterOptions
    ) => StatusMaybe<ApiImageryContract | null>;
    features: I.ImmutableOf<ApiFeature[]>;
    featuresStatus: FeaturesStatus;
    featuresActions: FeaturesActions;
    projectsActions: ProjectsActions;
    initialFilterState: Filters<I.ImmutableOf<ApiFeature[]>>;
    initialSortState: SortingRule<I.ImmutableListOf<ApiFeature>>[];
    onFilterStateChange: (filters: Filters<I.ImmutableOf<ApiFeature[]>>) => void;
    onSortStateChange: (sortBy: SortingRule<I.ImmutableListOf<ApiFeature>>[]) => void;
    isExpandedForTest?: boolean;
    setYear: (year: number) => void;
    orderableScenesLoader: OrderableScenesLoader;
    invalidateOrderableScenes: (featureId: number) => void;
  }>
> = ({
  history,
  organization,
  organizationUsers,
  selectedProject,
  selectedFeatureCollection,
  imageryRecords,
  userNoteCountsByFeatureId,
  alertNoteCountsByFeatureId,
  refreshImageryRecords,
  profile,
  year,
  pushNotification,
  getCurrentImageryContract,
  features,
  featuresStatus,
  featuresActions,
  projectsActions,
  initialFilterState,
  initialSortState,
  onFilterStateChange,
  onSortStateChange,
  isExpandedForTest,
  setYear,
  orderableScenesLoader,
  invalidateOrderableScenes,
}) => {
  const organizationId = organization.get('id');
  const projectId = selectedProject.get('id');
  const featureCollectionId = selectedFeatureCollection.get('id');
  const layersProcessing = checkFeatureCollectionIsProcessingStatus(selectedFeatureCollection);

  const [getHistoryState, setHistoryState] = useHistoryState(history, 'PropertyOverview', {
    scrollOffset: 0,
    focusedRowId: null as string | null,
    selectedRowIds: [] as string[],
  });

  const sortedOrganizationUsers = React.useMemo(
    () => organizationUsers.sort((a, b) => featureUtils.alphanumericSort(a.name, b.name)),
    [organizationUsers]
  );

  // Features are combined by name, and then sorted. Each group is sorted by
  // feature ID for consistency.
  const groupedFeatures = React.useMemo<I.ImmutableOf<ApiFeature[]>[]>(
    () =>
      features
        .filter((f) => !f!.getIn(['properties', 'isArchived']))
        .groupBy((f) => f!.getIn(['properties', 'name']))
        .valueSeq()
        .map((seq) => seq!.sortBy((f) => f!.get('id')).toList())
        .sortBy((seq) => seq!.first().getIn(['properties', 'name']), featureUtils.alphanumericSort)
        .toArray(),
    [features]
  );

  // Convert our list of records about available imagery into a by-ID map.
  const imageryByFeatureId = React.useMemo<ImageryNotificationsByFeatureId | null>(() => {
    switch (imageryRecords.status) {
      case 'unknown':
        return null;
      case 'error':
        return I.Map();
      case 'some':
        return imageryRecords.value.groupBy((n) => n!.get('feature_id')).toMap();
    }
  }, [imageryRecords]);

  const defaultSelectedRowIds = React.useMemo<Record<string, boolean>>(
    () => getHistoryState('selectedRowIds').reduce((acc, key) => ({...acc, [key]: true}), {}),
    [getHistoryState]
  );

  const [featureIdsForImageryDrawer, setFeatureIdsForImageryDrawer] = useStateWithDeps(
    I.Set<number>(),
    [selectedFeatureCollection.get('id')]
  );

  const [activeFeatureIdForImageryDrawer, setActiveFeatureIdForImageryDrawer] = useStateWithDeps<
    number | null
  >(null, [selectedFeatureCollection.get('id')]);

  const role = profile.get('role');

  const allowSelection = role !== 'readonly';

  const showImageryPurchaseDrawer = React.useCallback(
    (features: I.ImmutableOf<ApiFeature[]>, activeFeatureId: number | null) => {
      setFeatureIdsForImageryDrawer(I.Set(features.map((f) => f!.get('id'))));
      setActiveFeatureIdForImageryDrawer(activeFeatureId);
    },
    [setFeatureIdsForImageryDrawer, setActiveFeatureIdForImageryDrawer]
  );

  const openCustomizeTagsModal = React.useMemo(
    () => (role === C.USER_ROLE_OWNER ? () => setIsCustomizeTagsModalOpen(true) : null),
    [role]
  );

  const [isUpdatingFeatures, setIsUpdatingFeatures] = React.useState(false);

  const table = usePropertyTable({
    year,
    groupedFeatures,
    imageryByFeatureId,
    userNoteCountsByFeatureId,
    alertNoteCountsByFeatureId,
    organizationUsers: sortedOrganizationUsers,
    organization,
    projectId,
    featureCollection: selectedFeatureCollection,
    allowSelection,
    savedFilterState: initialFilterState,
    savedSortState: initialSortState,
    savedSelectedRowIds: defaultSelectedRowIds,
    showImageryPurchaseDrawer,
    batchPatchFeature: featuresActions.batchPatchFeature,
    openCustomizeTagsModal,
    isExpandedForTest,
    setYear,
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const sortByQueryString = queryString.stringify(
    table.state.sortBy.reduce((state, {id, desc}) => ({...state, [id]: desc}), {})
  );
  const filterQueryString = queryString.stringify(
    table.state.filters.reduce((state, {id, value}) => ({...state, [id]: value}), {})
  );

  const fullQueryString =
    filterQueryString + (sortByQueryString ? '&sortBy' + sortByQueryString : '');

  React.useEffect(
    () =>
      history.replace({
        ...history.location,

        hash: fullQueryString,
      }),
    [history, table.state.filters, table.state.sortBy, fullQueryString]
  );

  React.useEffect(
    () => onFilterStateChange(table.state.filters),
    [onFilterStateChange, table.state.filters]
  );

  React.useEffect(
    () => onSortStateChange(table.state.sortBy),
    [onSortStateChange, table.state.sortBy]
  );

  React.useEffect(
    () =>
      setHistoryState(
        'selectedRowIds',
        Object.entries(table.state.selectedRowIds)
          .filter(([, val]) => val)
          .map(([key]) => key)
      ),
    [setHistoryState, table.state.selectedRowIds]
  );

  // Corrects for a react-table bug where all the "child" sub-rows may be
  // unselected but the "parent" row is still selected.
  React.useEffect(() => {
    table.selectedFlatRows.forEach((row) => {
      if (row.isExpanded && !row.subRows.some((subRow) => subRow.isSelected)) {
        row.toggleRowSelected();
      }
    });
  }, [table.selectedFlatRows]);

  const itemData: Parameters<typeof PropertyRow>[0]['data'] = {
    table,
    allowSelection,
    organizationId: organization.get('id'),
    projectId,
    featureCollectionId,

    onRowFocus(row) {
      setHistoryState('focusedRowId', row.id);
    },
    onRowBlur(row) {
      if (getHistoryState('focusedRowId') === row.id) {
        setHistoryState('focusedRowId', null);
      }
    },
  };

  // Function to run after the first render to restore the focused row, if there
  // was one.
  const onItemsRenderedRef = React.useRef<Function | null>(async () => {
    const lastFocusedRowId = getHistoryState('focusedRowId');

    const rowEl =
      lastFocusedRowId &&
      document.querySelector(`.${cs.row}[data-id=${cssEscape(lastFocusedRowId)}]`);

    if (rowEl) {
      (rowEl as HTMLElement).focus();
    }

    // We set the ref to null so that our function only runs after the first
    // render and not subsequent ones.
    onItemsRenderedRef.current = null;
  });

  const selectedPropertyRows = getSelectedPropertyRows(table.flatRows);
  const hasSelectedPropertyRows = !!selectedPropertyRows.length;

  const selectedFeatures = selectedPropertyRows.reduce(
    (accum, row) =>
      accum.concat(
        getSelectedPropertyFlatRows(row).map((row) => row.original.features.first().toJS())
      ),
    [] as ApiFeature[]
  );

  const [orderImageryInfo, setOrderImageryInfo] = React.useState<{
    feature: I.ImmutableOf<ApiFeature>;
    orderableScene: I.ImmutableOf<ApiOrderableScene>;
  } | null>(null);
  const {prices: imageryPrices, refresh: refreshImageryPrices} = useImageryPrices();

  const [isCustomizeTagsModalOpen, setIsCustomizeTagsModalOpen] = React.useState(false);

  const [isTaskImageryModalOpen, setIsTaskImageryModalOpen] = React.useState(false);
  const isTaskImageryModalEnabled = featureFlags.TASK_IMAGERY_MODAL(organization, profile);
  const openTaskImageryModal = isTaskImageryModalEnabled
    ? () => setIsTaskImageryModalOpen(true)
    : undefined;

  const projectIdPrefix = routeUtils.makeUuidPrefix(projectId);
  const orgIdPrefix = routeUtils.makeUuidPrefix(organizationId);
  const addProperties = React.useCallback(() => {
    return history.push(`/${orgIdPrefix}/admin/manageProperties/select/${projectIdPrefix}`);
  }, [history, orgIdPrefix, projectIdPrefix]);

  const updateProperties = React.useCallback(() => {
    return history.push(`/${orgIdPrefix}/admin/updateProperties/${projectIdPrefix}`);
  }, [history, orgIdPrefix, projectIdPrefix]);

  const rowVirtualizer = useVirtualizer({
    count: table.rows.length,
    estimateSize: () => 56, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    getItemKey: React.useMemo(() => (index) => table.rows[index].id, [table.rows]),
    initialOffset() {
      return getHistoryState('scrollOffset');
    },
    onChange: ({scrollOffset}) => {
      if (scrollOffset) setHistoryState('scrollOffset', scrollOffset);
    },
  });

  if (featuresStatus === 'done' && features.isEmpty()) {
    const deletePortfolio = () => {
      if (
        confirm(
          `Are you sure you want to delete ${selectedProject.get(
            'name'
          )} portfolio? This will also delete any properties in your portfolio.`
        )
      ) {
        projectsActions.deleteProject(projectId);
        history.push(`/${routeUtils.makeUuidPrefix(organization.get('id'))}/projects`);
      }
    };

    return (
      <div className={cs.noPropertiesState}>
        <div className={cs.emptyStateButtons}>
          {role !== C.USER_ROLE_READONLY && (
            <ButtonLarge
              intent={'primary'}
              icon={'map-create'}
              text={'Add properties'}
              onClick={addProperties}
            />
          )}
          {role === C.USER_ROLE_OWNER && (
            <ButtonLarge
              intent={'danger'}
              text={'Delete portfolio'}
              onClick={deletePortfolio}
              icon={'trash'}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <TableControls
        table={table}
        addProperties={addProperties}
        updateProperties={updateProperties}
        profile={profile}
        project={selectedProject}
        featureCollection={selectedFeatureCollection}
      />
      {layersProcessing && (
        <B.Callout className={cs.callout} intent={B.Intent.WARNING} icon={null}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <B.Icon icon="time" className={cs.warningIcon} intent="warning" />
            <div className={cs.calloutContent}>Imagery is loading</div>
          </div>
        </B.Callout>
      )}
      <div className={cs.tableContainer} ref={tableContainerRef}>
        {featuresStatus !== 'done' || isUpdatingFeatures ? (
          <B.Spinner className={cs.tableLoadingSpinner} />
        ) : (
          <>
            <table>
              <thead>
                <TableHeader table={table} />
                {hasSelectedPropertyRows && !isUpdatingFeatures && (
                  <tr className={cs.selectedRowActionsContainer}>
                    <th style={{padding: 0, width: '100vw'}}>
                      <SelectedRowActions
                        project={selectedProject}
                        role={profile.get('role')}
                        rows={table.flatRows}
                        organization={organization}
                        userNoteCountsByFeatureId={userNoteCountsByFeatureId}
                        alertNoteCountsByFeatureId={alertNoteCountsByFeatureId}
                        year={year}
                        organizationUsers={sortedOrganizationUsers}
                        batchPatchFeature={featuresActions.batchPatchFeature}
                        batchArchiveFeatures={featuresActions.batchArchiveFeatures}
                        moveFeatures={featuresActions.moveFeatures}
                        clearSelection={() => table.toggleAllRowsSelected(false)}
                        selectedFeatureCollection={selectedFeatureCollection}
                        openCustomizeTagsModal={() => setIsCustomizeTagsModalOpen(true)}
                        setIsUpdatingFeatures={setIsUpdatingFeatures}
                        openTaskImageryModal={openTaskImageryModal}
                      />
                    </th>
                  </tr>
                )}
              </thead>
              <tbody
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = table.rows[virtualRow.index];
                  return <PropertyRow key={row.id} data={itemData} virtualRow={virtualRow} />;
                })}
              </tbody>
            </table>
            {table.rows.length === 0 && (
              <div className={cs.emptyListMessage}>
                No properties match these filters.
                <br />
                <a
                  onClick={(ev) => {
                    ev.preventDefault();
                    table.setAllFilters([]);
                  }}
                >
                  Show all properties
                </a>
              </div>
            )}
          </>
        )}

        <ImageryPurchaseDrawer
          organization={organization}
          features={features.filter((f) => featureIdsForImageryDrawer.has(f!.get('id'))).toList()}
          year={year}
          orderableScenesLoader={orderableScenesLoader}
          onPreviewOrderableScene={(feature, orderableScene) =>
            setOrderImageryInfo({feature, orderableScene})
          }
          onClose={() => setFeatureIdsForImageryDrawer(I.Set())}
          makeSceneUrl={(featureLensId, sourceId, date) =>
            routeUtils.makeHighResSceneUrl({
              organizationId,
              projectId,
              featureLensId,
              date,
              sourceId,
            })
          }
          setIsImageHidden={
            profile.get('role') === C.USER_ROLE_OWNER
              ? (feature, orderableScene, isHidden) => {
                  setIsImageHidden(
                    featuresActions.updateFeature,
                    feature,
                    orderableScene,
                    isHidden
                  );
                }
              : null
          }
          activeFeatureId={activeFeatureIdForImageryDrawer ?? undefined}
        />
      </div>
      {orderImageryInfo && (
        <OrderImageryModal
          feature={orderImageryInfo.feature}
          orderableScene={orderImageryInfo.orderableScene}
          subsetFeature={null}
          organization={organization}
          role={profile.get('role')}
          getCurrentImageryContract={(opts) => getCurrentImageryContract(projectId, opts)}
          modalUrl={routeUtils.makeHighResSceneUrl({
            organizationId,
            projectId,
            featureLensId: orderImageryInfo.feature.getIn(['properties', 'lensId']),
            sourceId: orderImageryInfo.orderableScene.get('sourceId'),
            date: orderImageryInfo.orderableScene.get('sensingTime'),
          })}
          onClose={() => setOrderImageryInfo(null)}
          onSubmit={async () => {
            // closes the modal
            setOrderImageryInfo(null);
            const {feature, orderableScene} = orderImageryInfo;
            const {billableAcres} = getBillableAcres(feature, orderableScene, null);
            const updatedFeature = await orderImagery(
              projectId,
              featureCollectionId,
              orderImageryInfo.feature.get('id'),
              orderImageryInfo.orderableScene,
              billableAcres,
              imageryPrices[orderImageryInfo.orderableScene.get('sourceId')],
              refreshImageryPrices,
              pushNotification,
              false
            );

            invalidateOrderableScenes(orderImageryInfo.feature.get('id'));

            if (updatedFeature) {
              featuresActions.updateFeature(updatedFeature.get('id'), updatedFeature);

              // This reloads the "x available" data used to power the High-Res
              // column.
              refreshImageryRecords();
            }
          }}
        />
      )}
      {isCustomizeTagsModalOpen && (
        <CustomizeTagsModal
          featureCollection={selectedFeatureCollection}
          updateFeatureCollection={projectsActions.updateFeatureCollection}
          onClose={() => setIsCustomizeTagsModalOpen(false)}
          kind={TAG_KIND.FEATURE}
        />
      )}
      {isTaskImageryModalEnabled && (
        <TaskImageryModal
          features={selectedFeatures}
          areaUnit={getAreaUnit(organization)}
          isOpen={isTaskImageryModalOpen}
          maySubmit={featureFlags.TASK_IMAGERY_MODAL_SUBMIT(organization, profile)}
          onClose={() => setIsTaskImageryModalOpen(false)}
        />
      )}
    </>
  );
};

export default PropertyOverview;
