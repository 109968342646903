import React from 'react';
import {Redirect} from 'react-router-dom';

import Loading from 'app/components/Loading';
import {LoggedInAuth, LoggedOutAuth} from 'app/providers/AuthProvider';

export interface Props {
  auth: LoggedInAuth | LoggedOutAuth;
}

export default class SignOut extends React.Component<Props> {
  componentDidMount() {
    const {auth} = this.props;

    if (auth.status === 'logged in') {
      auth.actions.signOut();
    }
  }

  render() {
    const {auth} = this.props;
    if (auth.status === 'logged in') {
      return <Loading />;
    } else {
      // We do our own Redirect rather than use WithLoggedInProvider because
      // WithLoggedInProvider automatically generates a URL to /signin that
      // redirects back to the current page on success.
      //
      // If we did that here we’d just redirect back to logging the user out
      // after they logged in.
      return <Redirect to="/signin" />;
    }
  }
}
