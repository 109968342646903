import * as B from '@blueprintjs/core';
import React from 'react';

/**
 * B.Icon component (for use with Blueprint) for our custom "polygon marker"
 * SVG.
 */
const PolygonIcon: React.FunctionComponent<React.PropsWithChildren<{size?: number}>> = ({
  size = 16,
}) => (
  <B.Icon
    icon={
      // Re-using bp5-icon here gives us consistent color / fill handling with
      // Blueprint’s built-in icons.
      <span className="bp5-icon">
        <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9999 2.99802H2.99957V12.9984H12.9999V2.99802ZM1.33286 1.3313V14.6651H14.6666V1.3313H1.33286Z"
          />
          <path d="M4.66683 2.33341C4.66683 3.62212 3.62212 4.66683 2.33341 4.66683C1.0447 4.66683 0 3.62212 0 2.33341C0 1.0447 1.0447 0 2.33341 0C3.62212 0 4.66683 1.0447 4.66683 2.33341Z" />
          <path d="M4.66683 13.665C4.66683 14.9537 3.62212 15.9984 2.33341 15.9984C1.0447 15.9984 0 14.9537 0 13.665C0 12.3763 1.0447 11.3316 2.33341 11.3316C3.62212 11.3316 4.66683 12.3763 4.66683 13.665Z" />
          <path d="M16 13.665C16 14.9537 14.9553 15.9984 13.6666 15.9984C12.3779 15.9984 11.3332 14.9537 11.3332 13.665C11.3332 12.3763 12.3779 11.3316 13.6666 11.3316C14.9553 11.3316 16 12.3763 16 13.665Z" />
          <path d="M16 2.33341C16 3.62212 14.9553 4.66683 13.6666 4.66683C12.3779 4.66683 11.3332 3.62212 11.3332 2.33341C11.3332 1.0447 12.3779 0 13.6666 0C14.9553 0 16 1.0447 16 2.33341Z" />
        </svg>
      </span>
    }
  />
);

export default PolygonIcon;
