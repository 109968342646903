import React from 'react';

import type {LibraryDataset, OverlayDataset} from './LibraryTypes';
import {
  BIODIVERSITY_TAG,
  ENVIRONMENTAL_JUSTICE_TAG,
  FOREST_TAG,
  HYDROLOGY_TAG,
  INFRASTRUCTURE_TAG,
  LANDCOVER_TAG,
} from '../../../utils/layers';
import type {WithRequired} from '../../../utils/tsUtils';

export type FeaturedOverlayMetadata = WithRequired<
  Partial<LibraryDataset<OverlayDataset>>,
  'libraryKey' | 'source' | 'thumbnailUrl'
>;

const INTACT_FOREST_LANDSCAPES_METADATA: FeaturedOverlayMetadata = {
  libraryKey: 'GLOBAL_FOREST_WATCH',
  source: 'Global Forest Watch',
  geographicExtent: 'Global',
  description: (
    <>
      The Intact Forest Landscapes dataset from Global Forest Watch identifies the world’s last
      remaining unfragmented forests, critical for retaining native biodiversity and essential
      habitats. This data tracks changes over the last two decades. Ideal for carbon project
      diligence and biodiversity assessments, this dataset provides invaluable insights into the
      conservation value of these vital ecosystems.
    </>
  ),
  tags: [FOREST_TAG, BIODIVERSITY_TAG],
  license: {
    name: 'Creative Commons Attribution 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  thumbnailUrl:
    'https://storage.googleapis.com/upstream-icons/overlays/2020-intact-forest-landscapes.png',
};

export const FEATURED_OVERLAY_METADATA: Record<string, FeaturedOverlayMetadata> = {
  Flowlines: {
    libraryKey: 'USGS_FLOWLINES',
    source: 'USGS',
    geographicExtent: 'United States',
    description: (
      <>
        Flowlines come from the USGS National Hydrography Dataset for linear surface water drainage
        network across the United States. This overlay can be useful if you’re interested in
        monitoring areas with streams or rivers. License: free and in the public domain
      </>
    ),
    tags: [HYDROLOGY_TAG],
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/flowlines.png',
  },
  'Microsoft Building Footprints': {
    libraryKey: 'MICROSOFT_BUILDING_FOOTPRINTS',
    source: 'Microsoft',
    geographicExtent: 'Continental United States',
    description: (
      <>
        Microsoft Building Footprints is an open dataset developed by Microsoft Maps and Bing Maps
        showing building footprint datasets in the US. This dataset contains nearly 130 million
        computer generated building footprints derived using our computer vision algorithms on
        satellite imagery. License:{' '}
        <a href="https://github.com/microsoft/GlobalMLBuildingFootprints/blob/main/LICENSE">
          Open Data Commons Open Database License
        </a>{' '}
      </>
    ),
    tags: [INFRASTRUCTURE_TAG],
    license: {
      name: 'Open Data Commons Open Database License (ODbL)',
      url: 'https://opendatacommons.org/licenses/odbl/',
    },
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/building-footprints.png',
  },
  'Parcel Data': {
    libraryKey: 'REGRID_PARCEL_DATA',
    source: 'Regrid',
    geographicExtent: 'United States',
    restriction: 'Standard and Enterprise only',
    description: (
      <>
        Parcel Data from Regrid pulls parcel data from across the US directly into Lens, allowing
        you to easily check tax parcel ownership. This overlay is available to our Plus and
        Enterprise users, and shows the parcel ID, owner, and address on hover. We also have a
        Parcel Owner Alert feature based on this data if you're interested in receiving automated
        notifications if ownership changes on any parcels that overlap your Lens property. License:
        limited use to access derived data through Lens
      </>
    ),
    tags: [INFRASTRUCTURE_TAG],
    license: {
      name: 'Open Data Commons Open Database License (ODbL)',
      url: 'https://opendatacommons.org/licenses/odbl/',
    },
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/parcel.png',
  },
  'Indigenous Territories': {
    libraryKey: 'NATIVE_LAND_INDIGENOUS_TERRITORIES',
    source: 'Native Land Digital',
    geographicExtent: 'Global',
    description: (
      <>
        Indigenous Territories is an open dataset developed by Native Land Digital, an
        Indigenous-led nonprofit based in Canada. This overlay maps Indigenous territories and is
        intended to shed light on the history of colonialism, Indigenous ways of knowing, and
        settler-Indigenous relations. Territories are shown globally where data is available. The
        dataset does not represent or intend to represent official or legal boundaries of any
        Indigenous nations, and any conflicting territorial claims err on the side of being more
        expansive. To learn about definitive boundaries, contact the nations in question, which are
        linked in the Overlay details on hover.
      </>
    ),
    tags: [LANDCOVER_TAG, ENVIRONMENTAL_JUSTICE_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl:
      'https://storage.googleapis.com/upstream-icons/overlays/indigenous-territories.png',
  },
  'Electric Power Transmission Lines': {
    libraryKey: 'HOMELAND_INFRASTRUCTURE_POWER_LINES',
    source: 'Homeland Infrastructure Foundation Level Database',
    geographicExtent: 'Continental United States',
    description: (
      <>
        This dataset shows transmission lines for the electric power grid within the continental
        United States. It is provided by the U.S. Department of Homeland Security Geospatial
        Management Office's Homeland Infrastructure Foundation-Level Data portal. This data was last
        updated in June of 2023.
      </>
    ),
    tags: [INFRASTRUCTURE_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/transmission-lines.png',
  },
  Mines: {
    libraryKey: 'HOMELAND_INFRASTRUCTURE_MINES',
    source: 'Homeland Infrastructure Foundation Level Database',
    geographicExtent: 'United States',
    description: (
      <>
        This dataset includes mines and mineral resource sites including manufacturing plants across
        the United States. It was last updated in June of 2017. This data is made available by the
        U.S. Department of Homeland Security Geospatial Management Office's Homeland Infrastructure
        Foundation-Level Data portal.
      </>
    ),
    tags: [INFRASTRUCTURE_TAG, ENVIRONMENTAL_JUSTICE_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/mines.png',
  },
  'Natural Gas Pipelines': {
    libraryKey: 'HOMELAND_INFRASTRUCTURE_PIPELINES',
    source: 'Homeland Infrastructure Foundation Level Database',
    geographicExtent: 'United States',
    description: (
      <>
        This dataset includes the major natural gas transmission pipelines in the United States
        including interstate, intrastate, and gathering pipelines. Data was obtained by the U.S.
        Energy Information Administration and last updated in June of 2019. It is made available by
        the U.S. Department of Homeland Security Geospatial Management Office's Homeland
        Infrastructure Foundation-Level Data portal.
      </>
    ),
    tags: [INFRASTRUCTURE_TAG, ENVIRONMENTAL_JUSTICE_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl:
      'https://storage.googleapis.com/upstream-icons/overlays/natural-gas-pipelines.png',
  },
  'US Protected Areas': {
    libraryKey: 'HOMELAND_INFRASTRUCTURE_US_PROTECTED_AREAS',
    source: 'Homeland Infrastructure Foundation Level Database',
    geographicExtent: 'United States',
    description: (
      <>
        This overlay includes protected conservation areas at the national, state, and local level,
        as well as easements, as of June 2023. It is derived from the USGS Protected Areas Database
        of the U.S. (PAD-US) and includes public lands and parks, Wilderness Areas, National
        Wildlife Refuges, reserves, conservation easements, Marine Protected Areas, and more. This
        data is provided by the U.S. Department of Homeland Security Geospatial Management Office's
        Homeland Infrastructure Foundation-Level Data portal.
      </>
    ),
    tags: [LANDCOVER_TAG, FOREST_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/conservation-areas.png',
  },
  'Massachusetts Wetlands Inventory': {
    libraryKey: 'HOMELAND_INFRASTRUCTURE_MASSACHUSETTS_WETLANDS',
    source: 'Homeland Infrastructure Foundation Level Database',
    geographicExtent: 'Massachusetts',
    description: (
      <>
        The National Wetland Inventory (NWI) is a comprehensive geospatial database maintained by
        the United States Fish and Wildlife Service (USFWS) that provides detailed information on
        the location, extent, type, and condition of wetlands across the United States. The Wetlands
        Data Layer is the product of over 45 years of work by the National Wetlands Inventory (NWI)
        and its collaborators and currently contains more than 35 million wetland and deepwater
        features. Massachusetts wetland areas have been updated between 1975 and 2013, depending on
        the part of the state. You can learn more about NWI data{' '}
        <a href="https://www.fws.gov/program/national-wetlands-inventory">here</a>.{' '}
      </>
    ),
    tags: [HYDROLOGY_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl:
      'https://storage.googleapis.com/upstream-icons/overlays/massachusetts-wetlands-inventory.png',
  },
  'EPA Superfund Sites': {
    libraryKey: 'EPA_SUPERFUND_SITES',
    source: 'United States Environmental Protection Agency',
    geographicExtent: 'United States',
    description: (
      <>
        This overlay includes EPA Superfund sites as of May, 2024. The EPA’s Superfund program is
        responsible for cleaning up some of the nation’s most contaminated land and responding to
        environmental emergencies, oil spills, and natural disasters. This dataset contains polygons
        showing the extent of Superfund sites, or areas polluted with hazardous materials. Superfund
        features are managed by regional teams of geospatial professionals and remedial program
        managers. The name of each site and a short description are shown on hover. Data is provided
        by the U.S. Environmental Protection Agency (EPA).
      </>
    ),
    tags: [INFRASTRUCTURE_TAG, ENVIRONMENTAL_JUSTICE_TAG],
    license: {
      url: null,
      name: 'Public Use',
    },
    thumbnailUrl: 'https://storage.googleapis.com/upstream-icons/overlays/epa-superfund-sites.png',
  },
  'WWF World Terrestrial Ecoregions': {
    libraryKey: 'WWF_TERRESTRIAL_ECOREGIONS',
    source: 'World Wildlife Fund',
    geographicExtent: 'Global',
    description: (
      <>
        The WWF Terrestrial Ecoregions of the World data is a biogeographic regionalization of the
        Earth's terrestrial biodiversity. Our biogeographic units are ecoregions, which are defined
        as relatively large units of land or water containing a distinct assemblage of natural
        communities sharing a large majority of species, dynamics, and environmental conditions.
        There are 867 terrestrial ecoregions, classified into 14 different biomes such as forests,
        grasslands, or deserts. Ecoregions represent the original distribution of distinct
        assemblages of species and communities. Learn more about this dataset{' '}
        <a href="https://en.wikipedia.org/wiki/List_of_terrestrial_ecoregions_(WWF)">here</a>.
      </>
    ),
    tags: [LANDCOVER_TAG, BIODIVERSITY_TAG],
    license: {
      url: 'https://creativecommons.org/licenses/by-nc/3.0/',
      name: 'Creative Commons Attribution-NonCommercial 3.0',
    },
    thumbnailUrl:
      'https://storage.googleapis.com/upstream-icons/overlays/wwf-world-terrestrial-ecoregions.png',
  },
  'Biodiversity Hotspots': {
    libraryKey: 'CEPF_BIODIVERSITY_HOTSPOTS',
    source: 'Critical Ecosystem Partnership Fund (CEPF)',
    geographicExtent: 'Global',
    description: (
      <>
        These are the biologically richest but most threatened areas on the planet. Each hotspot
        contains at least 1,500 plant species found nowhere else on the planet and has lost at least
        70 percent of its original primary vegetation. Taken together, remaining natural habitats
        within the 35 biodiversity hotspots cover only 2.3 percent of the Earth’s land surface, yet
        they support nearly 60 percent of the world’s plant, bird, mammal, reptile and amphibian
        species.
      </>
    ),
    tags: [BIODIVERSITY_TAG],
    license: {
      name: 'Creative Commons Attribution-ShareAlike 4.0 International',
      url: 'https://creativecommons.org/licenses/by-sa/4.0/legalcode',
    },
    thumbnailUrl:
      'https://storage.googleapis.com/upstream-icons/overlays/biodiversity-hotspots.png',
  },
  '2000 Intact Forest Landscapes': INTACT_FOREST_LANDSCAPES_METADATA,
  '2013 Intact Forest Landscapes': INTACT_FOREST_LANDSCAPES_METADATA,
  '2016 Intact Forest Landscapes': INTACT_FOREST_LANDSCAPES_METADATA,
  '2020 Intact Forest Landscapes': INTACT_FOREST_LANDSCAPES_METADATA,
};
