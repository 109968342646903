import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import geojson from 'geojson';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapContent from './MapContent';

import {MapControlPosition} from '.';

interface Props {
  map: mapboxgl.Map;
  isMapLoaded: boolean;
  position: MapControlPosition;
  placeholder?: string;
  collapsed?: boolean;
  onResult?: (gr: GeocoderResult) => void;
}

export interface GeocoderResult {
  query: string; // Any query that's being added to the geocoder
  result: {
    // Incomplete, only includes the fields we use
    bbox: [number, number, number, number];
    geometry: geojson.Geometry;
    center: [number, number];
  };
}

// this makes use of Mapbox's geocoder component.
// API docs available at https://github.com/mapbox/mapbox-gl-geocoder/blob/main/API.md

const MapGeocoderControl: React.FunctionComponent<Props> = (props: Props) => {
  const {
    map,
    isMapLoaded,
    position,
    placeholder = 'Search...',
    collapsed = false,
    onResult,
  } = props;
  const inputQuery = React.useRef<GeocoderResult['query']>('');

  /*
    Note: Passing an onResult handler to this component lets a parent handle the outcome 
      of a geocoder result to do something else with it, such as fitting the map differently.
  */
  const geocoder = new MapboxGeocoder({
    // Initialize the geocoder
    accessToken: mapboxgl.accessToken, // Set the access token
    mapboxgl: mapboxgl, // Set the mapbox-gl instance
    marker: false, // Do not use the default marker style
    placeholder: placeholder,
    collapsed: collapsed,
    reverseGeocode: true,
    flyTo: !!onResult,
  })
    .on('result', (r) => onResult && onResult({query: inputQuery.current, result: r.result}))
    .on('loading', (l) => (inputQuery.current = l.query));

  return (
    <React.Fragment>
      <MapContent
        map={map}
        isMapLoaded={isMapLoaded}
        controls={[geocoder]}
        controlPosition={position}
      />
    </React.Fragment>
  );
};

export default MapGeocoderControl;
