import * as Sentry from '@sentry/react';
import * as I from 'immutable';
import React from 'react';

import {ApiFeatureData} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import * as featureUtils from 'app/utils/featureUtils';
import {StatusMaybe, useContinuity, useStateWithDeps} from 'app/utils/hookUtils';
import * as layers from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

export interface MapState {
  readonly mode: mapUtils.Mode;
  readonly imageRefs: mapUtils.MapImageRefs;
}

export type MapStateAction =
  | {type: 'SET_MODE'; mode: mapUtils.Mode; imageRefIdxToKeep?: ImageRefIndex}
  /** Also updates the mode to match the number of refs. */
  | {type: 'SET_IMAGE_REFS'; imageRefs: mapUtils.MapImageRefs}
  /** Affects all current cursors. */
  | {type: 'SET_LAYER'; layerKey: string; imageRefIdx?: ImageRefIndex};

export type MapStateDispatch = (action: MapStateAction) => void;

export type ImageRefIndex = 0 | 1;

/**
 * Maintains the layer key, compare / view mode, and date cursors for the Lens
 * map. Provides a MapStateDispatch function that handles changing this state
 * via a set of defined actions.
 *
 * Cursors are adjusted to match the changing state. For example:
 *
 * - Changing from one layer to another should find cursors in the new layer.
 * - Changing from VIEW to COMPARE mode will add a new cursor before the current
 *   one (if possible, otherwise later).
 * - Changing between feature data, which most commonly happens when the user
 *   selects a new feature, will adjust the cursors once the feature data has
 *   loaded.
 *
 * In general, the cursors are synchronously adjusted when feature data is
 * available to match changes in layer, mode, or feature data. Nevertheless, the
 * returned cursors may not be found in the feature data while feature data is
 * loading or if the cursors were explicitly set to missing values. The latter
 * can occur when an old note references scenes that have since been deleted.
 *
 * Consumers will need to account for the fact that cursors are not guaranteed
 * to be found in the filtered feature data, and that cursors will only be
 * adjusted once feature data becomes available. Note as well that some UI
 * behavior (like not showing COMPARE mode for a multi-feature selection) needs
 * to be handled by UI components, not here.
 */
export function useMapState(
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  featureIds: I.Set<number>,
  getFeatureData: (featureId: number) => StatusMaybe<I.ImmutableOf<ApiFeatureData[]>>,
  // For cases where we want to skip the default layer if a layer that should be present is in a note, alert, etc.
  initializeLayerKeyAsNone = false
): readonly [MapState, MapStateDispatch] {
  featureIds = useContinuity(featureIds, I.is);

  // If there’s more than one feature, we ignore the feature data for all of them.
  const featureDataMaybe = featureIds.size === 1 ? getFeatureData(featureIds.first()) : null;

  // Wait until we have some feature data to return it.
  const featureData = featureDataMaybe?.status === 'some' ? featureDataMaybe.value : null;

  const [mapState, setMapState] = useStateWithDeps<MapState>(
    {
      mode: mapUtils.MODES.VIEW,
      imageRefs: [
        {
          layerKey: initializeLayerKeyAsNone
            ? layers.NONE
            : layerUtils.getDefaultLayerKeyForFeatureCollection(featureCollection),
          cursor: null,
        },
      ],
    },
    [featureCollection.get('id')]
  );

  const mapStateDispatch = React.useCallback<MapStateDispatch>(
    (action) => {
      setMapState(applyUpdatesToState(action, featureData));
    },
    [setMapState, featureData]
  );

  // If feature data changes (e.g., a different feature is selected), update
  // map state with the adjusted cursors. This is done in the
  // `useLayoutEffect` hook to avoid UI flashing due to changing dates.
  React.useLayoutEffect(() => {
    setMapState((prevMapState) => {
      if (featureData === null) {
        return prevMapState;
      }

      const imageRefs = adjustImageRefs(prevMapState.imageRefs, prevMapState.mode, featureData);

      return {
        mode: imageRefs.length === 1 ? mapUtils.MODES.VIEW : mapUtils.MODES.COMPARE,
        imageRefs,
      };
    });
  }, [setMapState, featureData]);

  return [mapState, mapStateDispatch] as const;
}

/**
 * Applies the MapStateAction to the state.
 *
 * See the inline comments for notes on specific behavior, such as when we
 * adjust the cursors and when we don’t.
 */
export function applyUpdatesToState(
  action: MapStateAction,
  featureData: I.ImmutableOf<ApiFeatureData[]> | null
) {
  return (oldState: MapState): MapState => {
    switch (action.type) {
      case 'SET_MODE': {
        // Switching between view and compare. If going from compare -> view,
        // just sets the image to the later one in the list.
        //
        // Going from view to compare triggers a call to adjustImageRefs to find
        // a nearby image to compare to.
        const nextState = {
          ...oldState,
          mode: action.mode,
        };

        if (action.mode === oldState.mode) {
          return oldState;
        } else if (action.mode === mapUtils.MODES.VIEW) {
          // We assume that oldState’s imageRefs are already as correct as
          // they’re going to be, so no need to adjust or try to replace the
          // layerKey. Just collapse down to one ref.
          const [before, after] = oldState.imageRefs;
          // If we specify an imageRefIdx to keep, keep that imageRef. Provide the
          // first imageRef as a fallback, which is guaranteed to exist.
          if (action.imageRefIdxToKeep === 0 || action.imageRefIdxToKeep === 1) {
            nextState.imageRefs = [oldState.imageRefs[action.imageRefIdxToKeep] || before];
          } else {
            // If we don't specify an imageRef to keep, just keep the later one, but
            // provide a fallback value of the first imageRef, which is guaranteed to exist.
            nextState.imageRefs = [after || before];
          }
          return nextState;
        } else if (action.mode === mapUtils.MODES.COMPARE) {
          if (featureData !== null) {
            nextState.imageRefs = adjustImageRefs(oldState.imageRefs, nextState.mode, featureData);
          }
          return nextState;
        } else {
          throw new Error('Unknown mode: ' + action.mode);
        }
      }

      case 'SET_IMAGE_REFS': {
        // When SET_IMAGE_REFS values are coming from somewhere
        // specific, such as a note or a link. In the case that these
        // cursors may have gotten out of sync with the cursors in featureData
        // (like in the case of reprocessing), we attempt to adjust them. If
        // we don't find anything close enough, like in the case of a scene being
        // removed, we do not update them and allow it to fail and show a blank
        //
        // We also sort them and update mode to match the number of imageRefs provided.
        //
        // And make sure that they’re using a precise layerKey if it’s available
        // (this is for migrating from e.g. old notes that still reference
        // ALL_high-res-truecolor).

        const updatedImageRefs = action.imageRefs.map((r) => {
          if (!r.cursor) {
            return r;
          }
          const updatedLayerKey = findAppropriateLayerKey(r.layerKey, r.cursor, featureData);

          if (featureData && r.cursor) {
            const datum = featureData.find(
              (d) => d!.get('types').includes(updatedLayerKey) && d!.get('date') === r.cursor
            );

            if (!datum) {
              // If a featureDatum was not found for a given layerKey and and cursor, try
              // and adjust the cursor within the acceptable range to account for cursors
              // not lining up after reprocessing
              const newCursorWithinRange = adjustCursor(updatedLayerKey, r.cursor, featureData);

              if (newCursorWithinRange) {
                return {layerKey: updatedLayerKey, cursor: newCursorWithinRange};
              } else {
                Sentry.captureException(
                  new Error(
                    `Missing FeatureData for requested imageRef: ${r.cursor} / ${updatedLayerKey}`
                  ),
                  {extra: {cursor: r.cursor, layerKey: updatedLayerKey}}
                );
              }
            }
          }
          return {layerKey: updatedLayerKey, cursor: r.cursor};
        }) as mapUtils.MapImageRefs;

        sortImageRefs(updatedImageRefs);

        return {
          ...oldState,
          mode: action.imageRefs.length === 1 ? mapUtils.MODES.VIEW : mapUtils.MODES.COMPARE,
          imageRefs: updatedImageRefs,
        };
      }

      case 'SET_LAYER': {
        // Changes the imageRef (specified by index) to use the new layerKey,
        // then updates the cursors to find matching data from the new layer.

        // Once When we are in View mode, the imageRefIdx will always be 0. In
        // compare mode, the imageRefIdx will be 0 for start date, and 1
        // for End Date.

        let updatedImageRefs;
        // apply new layer to all imageRefs if 0 or 1 not specified
        if (!action.imageRefIdx && action.imageRefIdx !== 0) {
          updatedImageRefs = oldState.imageRefs.map((r) => ({
            ...r,
            layerKey: action.layerKey,
          })) as mapUtils.MapImageRefs;
        } else {
          oldState.imageRefs[action.imageRefIdx]!.layerKey = action.layerKey;
          updatedImageRefs = oldState.imageRefs;
        }

        return {
          ...oldState,

          imageRefs: featureData
            ? adjustImageRefs(updatedImageRefs, oldState.mode, featureData)
            : updatedImageRefs,
        };
      }
    }
  };
}

/**
 * Returns a layerKey for the given cursor and feature data, based on a previous
 * layerKey.
 *
 * If the scene is not high-res, just returns the previous layerKey.
 *
 * If the scene is high-res, it will return the previous layerKey if the cursor
 * has a processed image for that layer. If it doesn’t, it will return the first
 * layerKey that there is an image for, or ANY_TRUECOLOR_HIGH_RES if none exist.
 *
 * This is necessary when looking at, say, a Pleiades image and then switching
 * to a date that only has a Maxar image.
 */
export function findAppropriateLayerKey(
  prevLayerKey: string,
  cursor: string,
  featureData: I.ImmutableListOf<ApiFeatureData> | null
): string {
  if (!layerUtils.isLayerKeyHighResTruecolor(prevLayerKey) || !featureData) {
    return prevLayerKey;
  }

  const datum = featureData.find((d) => d!.get('date') === cursor);

  if (!datum) {
    return layers.ANY_TRUECOLOR_HIGH_RES;
  }

  const templateUrls = datum.get('images').get('templateUrls');
  if (templateUrls.get(prevLayerKey)) {
    return prevLayerKey;
  }

  return (
    featureUtils.findFirstProcessedHighResTruecolorLayerKey(datum) || layers.ANY_TRUECOLOR_HIGH_RES
  );
}

function sortImageRefs(imageRefs: mapUtils.MapImageRefs) {
  imageRefs.sort((a, b) => {
    if (a.cursor === null && b.cursor === null) {
      return 0;
    } else if (a.cursor === null) {
      return -1;
    } else if (b.cursor === null) {
      return 1;
    }

    if (a.cursor < b.cursor) {
      return -1;
    } else if (a.cursor > b.cursor) {
      return 1;
    } else {
      return 0;
    }
  });
}
/**
 * This function is called when we cannot find a featureDatum corresponding to a
 * imageRef's layerKey and cursor when calling SET_IMAGE_REFS. It attempts to find
 * the closet other cursor in featureData for the given layerKey.
 *
 * This is helpful in the case of data getting reprocessed and the cursor associated with
 * the new scene in featureData may be slightly off from what the cursor was when the featureData
 * for that scene was saved somewhere like a note.
 */
export function adjustCursor(
  layerKey: string,
  cursor: string,
  featureData: I.ImmutableOf<ApiFeatureData[]>
) {
  // Do not try to adjust the cursor of a highResTruecolor image - since layerKeys may not
  // be specific in this case and we are looking for any highResTruecolor datum, trying
  // to adjust for reprocessed scene may result in incorrectly attributing an image to a
  // different source that was captured near the same time
  if (layerUtils.isLayerKeyHighResTruecolor(layerKey)) {
    return cursor;
  }
  const filteredFeatureData = featureData
    .filter((d) => d!.get('types').includes(layerKey))
    .toList();
  if (filteredFeatureData.isEmpty()) {
    return null;
  }

  const closestCursor = findClosestCursor(cursor, filteredFeatureData, 'date');
  if (closestCursor === null) {
    throw new Error('No cursor found even after we checked');
  }
  return closestCursor;
}

/**
 * Finds and return the closest cursor in featureData for a given cursor
 */
function findClosestCursor(
  cursor: string,
  filteredFeatureData: I.ImmutableListOf<ApiFeatureData>,
  cursorKey: keyof ApiFeatureData = 'date'
) {
  const cursorMs = Date.parse(cursor);

  let closestDiff = Infinity;
  let closestCursor: string | null = null;

  for (let i = 0; i < filteredFeatureData.size; ++i) {
    const datumCursor = filteredFeatureData.get(i).get(cursorKey) as string;
    const datumCursorMs = Date.parse(datumCursor);
    const datumCursorDiff = Math.abs(cursorMs - datumCursorMs);

    if (datumCursorDiff < closestDiff) {
      closestDiff = datumCursorDiff;
      closestCursor = datumCursor;
    }
  }

  return closestCursor;
}

/**
 * Correct the cursors to match what’s available in the featureData for the
 * current layer, if it has loaded.
 *
 * Note: does not attempt to preserve consistency of the imageRefs.
 */
export function adjustImageRefs(
  imageRefs: mapUtils.MapImageRefs,
  mode: mapUtils.Mode,
  featureData: I.ImmutableOf<ApiFeatureData[]>
): mapUtils.MapImageRefs {
  // We collapse the high res layer keys together for the featureDataByLayerKey map.
  const layerKeys = imageRefs.map(({layerKey}) =>
    layerUtils.isLayerKeyHighResTruecolor(layerKey) ? layers.ANY_TRUECOLOR_HIGH_RES : layerKey
  );

  // This is us being very pendantic. cursorKey is always going to be "date"
  // right now in Lens.
  const cursorKey = featureUtils.getCursorKeyForLayerKey(null, layerKeys);

  const imageRefFeatureDataByLayerKey = I.Set(layerKeys).reduce(
    (acc, layerKey) =>
      acc!.set(
        layerKey!,
        featureUtils.getProcessedFeatureData(
          featureData.filter(featureUtils.makeLayerKeyPredicate(layerKey!)).toList() ?? I.List(),
          layerKey!
        )
      ),
    I.Map<string, I.ImmutableOf<ApiFeatureData[]>>()
  );

  // Move the cursors to match the closest ones in featureData. This may mean
  // that both cursors map to the same value, in which case we’ll de-dupe later
  // and add back in another cursor (if we’re in compare mode).
  //
  // While a better algorithm might minimize the distance of both cursors at the
  // same time, that level of complication would be unnecessary.
  //
  // We use an Immutable List here because it will guarantee a stable sort order
  // later on.

  let imageRefList = I.List(
    imageRefs.map<mapUtils.MapImageRef>(({layerKey, cursor}) => {
      const featureDataForLayer = imageRefFeatureDataByLayerKey.get(
        layerUtils.isLayerKeyHighResTruecolor(layerKey) ? layers.ANY_TRUECOLOR_HIGH_RES : layerKey
      );

      //If the user is switching to Basemap, null out the cursor and let 'er rip
      if (layerKey === layers.NONE) {
        return {layerKey, cursor: null};
      }
      // If the user navigates to a feature and they have no valid scenes for that layerKey,
      // send them to truecolor imagery instead. First checking for high res imagery, but
      // isn't guaranteed for customers outside of the US. In that case default to S2_TRUECOLOR
      // which is global and is assumed to always exist
      else if (featureDataForLayer.isEmpty()) {
        // Start with highResTruecolor
        let newLayerKey = layers.ANY_TRUECOLOR_HIGH_RES;
        let newTruecolorFeatureData = featureUtils.getProcessedFeatureData(
          featureData
            .filter(featureUtils.makeLayerKeyPredicate(layers.ANY_TRUECOLOR_HIGH_RES))
            .toList() ?? I.List(),
          layers.ANY_TRUECOLOR_HIGH_RES
        );

        // Fall back to S2 truecolor if we can't find any highRes
        if (newTruecolorFeatureData.isEmpty()) {
          newLayerKey = layers.S2_TRUECOLOR;
          newTruecolorFeatureData = featureUtils.getProcessedFeatureData(
            featureData.filter(featureUtils.makeLayerKeyPredicate(layers.S2_TRUECOLOR)).toList() ??
              I.List(),
            layers.S2_TRUECOLOR
          );
        }

        // If we still have no data somehow, fall back to the basemap
        if (newTruecolorFeatureData.isEmpty()) {
          return {layerKey, cursor: null};
        }

        // If there's already a cursor set, try to find the closest cursor for our new
        // layerKey. If not, default to the latest date.
        const newCursor = (
          cursor
            ? findClosestCursor(cursor!, newTruecolorFeatureData, cursorKey)
            : newTruecolorFeatureData.first().get(cursorKey)
        ) as string;

        return {
          layerKey: findAppropriateLayerKey(newLayerKey, newCursor, newTruecolorFeatureData),
          cursor: newCursor,
        };
      } else if (cursor === null) {
        const updatedCursor = featureDataForLayer.first().get(cursorKey) as string;

        return {
          layerKey: findAppropriateLayerKey(layerKey, updatedCursor, featureData),
          cursor: updatedCursor,
        };
      }

      const closestCursor = findClosestCursor(cursor, featureDataForLayer, cursorKey);

      if (closestCursor === null) {
        // featureDataForLayerKey check from before
        throw new Error('No cursor found even after we checked');
      }

      return {
        layerKey: findAppropriateLayerKey(layerKey, closestCursor, featureData),
        cursor: closestCursor,
      };
    })
  );

  // Check and make sure that the above "find the closest cursor" didn’t leave
  // us with the same cursor and layerKey for two images. If so, remove one and
  // let the compare mode correction mode add it back in.
  if (imageRefList.size === 2) {
    const first = imageRefList.get(0);
    const second = imageRefList.get(1);

    if (first.layerKey === second.layerKey && first.cursor === second.cursor) {
      imageRefList = imageRefList.take(1).toList();
    }
  }

  // Adjust the cursors to match the mode.
  if (mode === mapUtils.MODES.VIEW && imageRefList.size > 1) {
    imageRefList = imageRefList.takeLast(1).toList();
  } else if (mode === mapUtils.MODES.COMPARE && imageRefList.size === 1) {
    const {layerKey, cursor} = imageRefList.first();
    const featureDataForLayer = featureUtils.getProcessedFeatureData(
      featureData.filter(featureUtils.makeLayerKeyPredicate(layerKey)).toList() ?? I.List(),
      layerKey
    );

    if (cursor === null) {
      // If we get here it’s because there’s no valid scenes in the feature data
      // for this layerKey. The frontend should prevent this but we should make
      // sure we handle it without crashing.
      imageRefList = imageRefList.push({
        layerKey: layerUtils.isLayerKeyHighResTruecolor(layerKey)
          ? layers.ANY_TRUECOLOR_HIGH_RES
          : layerKey,
        cursor: null,
      });
    } else {
      const currentCursorIdx = featureDataForLayer.findIndex((d) => d!.get(cursorKey) === cursor);

      if (currentCursorIdx === -1) {
        throw new Error('Cursor not found in featureDataForLayer after reconcilliation');
      }

      // "prev" because we’re sorted descending
      const prevCursorIdx = currentCursorIdx + 1;
      const nextCursorIdx = currentCursorIdx - 1;

      if (prevCursorIdx < featureDataForLayer.size) {
        const newCursor = featureDataForLayer.get(prevCursorIdx).get(cursorKey) as string;

        imageRefList = imageRefList.push({
          layerKey: findAppropriateLayerKey(layerKey, newCursor, featureData),
          cursor: newCursor,
        });
      } else if (nextCursorIdx >= 0) {
        const newCursor = featureDataForLayer.get(nextCursorIdx).get(cursorKey) as string;

        // The current cursor is the last one in the FeatureData collection, so
        // let’s add the cursor before it. Sorting will be handled later.
        imageRefList = imageRefList.push({
          layerKey: findAppropriateLayerKey(layerKey, newCursor, featureData),
          cursor: newCursor,
        });
      } else {
        imageRefList = imageRefList.push({
          layerKey: layerUtils.isLayerKeyHighResTruecolor(layerKey)
            ? layers.ANY_TRUECOLOR_HIGH_RES
            : layerKey,
          cursor: null,
        });
      }
    }
  }

  // using the ol’ “ISO8601 dates are sortable lexographically” hack.
  imageRefList = imageRefList.sortBy((ref) => ref!.cursor).toList();

  return imageRefList.toArray() as mapUtils.MapImageRefs;
}
