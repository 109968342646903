import * as B from '@blueprintjs/core';
import React from 'react';

import cs from './ImageFileUpload.styl';

const ImageFileUpload: React.FunctionComponent<
  React.PropsWithChildren<{
    label: string;
    removeFile: () => Promise<void>;
    uploadFile: (file: File) => Promise<void>;
    isUpdating?: boolean;
    url?: URL;
  }>
> = ({label, removeFile, uploadFile, isUpdating, url}) => {
  const hiddenFileInputRef = React.useRef<HTMLInputElement>(null);

  const chooseFile = React.useCallback(() => {
    !isUpdating && hiddenFileInputRef.current?.click();
  }, [isUpdating]);

  const lowerCasedLabel = label.toLowerCase();
  const capitalizedLabel = lowerCasedLabel.charAt(0).toUpperCase() + lowerCasedLabel.slice(1);

  return (
    <div className={cs.file}>
      <input
        accept="image/png, image/jpg, image/jpeg"
        className={cs.hiddenFileInput}
        id="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const file = e.currentTarget.files?.[0];
          file && uploadFile(file);
        }}
        ref={hiddenFileInputRef}
        type="file"
        value=""
      />

      {url ? (
        <div className={cs.image}>
          <img alt={capitalizedLabel} src={url.href} />
          {isUpdating ? (
            <div className={cs.fileOverlay}>
              <B.Spinner size={20} />
            </div>
          ) : (
            <div className={cs.fileOverlayActions}>
              <div className={cs.actions}>
                <B.Button onClick={chooseFile} text="Replace" />
                <B.Button
                  onClick={() => {
                    if (confirm(`Are you sure you want to remove your ${lowerCasedLabel}?`)) {
                      removeFile();
                    }
                  }}
                  text="Remove"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={cs.placeholder}>
          <B.Button
            intent="primary"
            minimal
            onClick={chooseFile}
            text={`Upload your ${lowerCasedLabel}`}
            disabled={isUpdating}
            loading={isUpdating}
          />
        </div>
      )}
    </div>
  );
};

export default ImageFileUpload;
