import {bbox} from '@turf/turf';
import * as geojson from 'geojson';
import * as I from 'immutable';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import pointerImageSrc from 'app/assets/images/map_pointer.png';
import satelliteWithLabelsBasemapsSrc from 'app/assets/images/satellite-with-labels.png';
import satelliteBasemapSrc from 'app/assets/images/satellite.png';
import terrainBasemapSrc from 'app/assets/images/terrain.png';
import topoBasemapSrc from 'app/assets/images/topographic.png';
import arrowImageSrc from 'app/assets/images/upstream_arrow.png';
import circleImageSrc from 'app/assets/images/upstream_circle.png';
import circleSelectedSrc from 'app/assets/images/upstream_circle_selected.png';
import vectorBasemapSrc from 'app/assets/images/vector.png';
import {useMapCursor} from 'app/components/DeclarativeMap/MapCursorProvider';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import * as apiUtils from 'app/utils/apiUtils';
import * as C from 'app/utils/constants';
import {useLocalTiler} from 'app/utils/envUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as layerUtils from 'app/utils/layerUtils';

import * as makeLayers from './makeLayers';
import {isPublicLensUrl} from '../routeUtils';

export * from './modes';
export * from './polygonLayers';

export const MAPBOX_OWNER_ID = 'mmoutenot';
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibW1vdXRlbm90IiwiYSI6ImNpcDVsbTZtbjAwaHd2Y2tyaGlkY3JrdWkifQ.cJC8ofyhRA-8F0vgnw7wPg';

// Each style ID corresponds to a custom style in our Mapbox Studio account.
// Login credentials are in the Upstream Tech team 1Password vault.
export const MAPBOX_SATELLITE_STREETS_STYLE = 'ckkmyw3b63tco17jy4507dg49';
export const MAPBOX_SATELLITE_STYLE = 'ckkn5m61n5erd17m9lp8y26ge';
export const MAPBOX_DARK_STYLE = 'ckkmyb3m057p517p6jad2659a';
export const MAPBOX_OUTDOORS = 'ckkmymu68581417p6e1vrvx87';
export const MAPBOX_TERRAIN_STYLE = 'cm60y8hr2000s01s69tvz9a9m';

export const DEFAULT_STYLE = MAPBOX_DARK_STYLE;

export type MapStyle =
  | typeof MAPBOX_SATELLITE_STREETS_STYLE
  | typeof MAPBOX_SATELLITE_STYLE
  | typeof MAPBOX_DARK_STYLE
  | typeof MAPBOX_OUTDOORS
  | typeof MAPBOX_TERRAIN_STYLE;

export interface MapStyleInfo {
  id: MapStyle;
  title: string;
  description: string;
  thumbnailSrc: string;
}

export {makeLayers};

const ICONS = {
  upstream_arrow: arrowImageSrc,
  upstream_circle_selected: circleSelectedSrc,
  upstream_circle: circleImageSrc,
  map_pointer: pointerImageSrc,
};

/**
 * Our map styles, in the order that they should appear in the dropdown.
 */
export const MAPBOX_STYLES: MapStyleInfo[] = [
  {
    id: MAPBOX_DARK_STYLE,
    title: 'Vector',
    description: 'Dark with subtle geographic context',
    thumbnailSrc: vectorBasemapSrc,
  },
  {
    id: MAPBOX_OUTDOORS,
    title: 'Terrain',
    description: 'Simple terrain data and natural features',
    thumbnailSrc: topoBasemapSrc,
  },
  {
    id: MAPBOX_SATELLITE_STYLE,
    title: 'Satellite',
    description: 'Composite imagery from multiple sources',
    thumbnailSrc: satelliteBasemapSrc,
  },
  {
    id: MAPBOX_SATELLITE_STREETS_STYLE,
    title: 'Satellite with labels',
    description: 'Composite imagery with place names',
    thumbnailSrc: satelliteWithLabelsBasemapsSrc,
  },
  {
    id: MAPBOX_TERRAIN_STYLE,
    title: 'Topographic',
    description: 'Shaded relief and elevation contours',
    thumbnailSrc: terrainBasemapSrc,
  },
];

export const DEFAULT_TEXT_FIELD = 'name';

export function getMapboxUri(style: string) {
  const prefix = `mapbox://styles/${MAPBOX_OWNER_ID}/`;
  return style.startsWith(prefix) ? style : `${prefix}${style}`;
}

export function getFeatureCollectionBounds(
  features: ApiFeature[]
): I.ImmutableOf<geojson.Geometry> {
  const extent = bbox(geoJsonUtils.featureCollection(features));
  return I.fromJS(geoJsonUtils.bboxPolygon(extent).geometry);
}

export function safeAddLayer(
  map: mapboxgl.Map | undefined,
  layer: mapboxgl.AnyLayer,
  beforeLayerId?: string
) {
  if (map && !map.getLayer(layer.id)) {
    map.addLayer(layer, beforeLayerId);
  }
}

export function safeAddSource(
  map: mapboxgl.Map | undefined,
  sourceId: string,
  source: mapboxgl.AnySourceData
) {
  if (map && !map.getSource(sourceId)) {
    map.addSource(sourceId, source);
  }
}

export function safeRemoveLayer(map: mapboxgl.Map | undefined, layerId: string) {
  if (map && !!map.getStyle() && map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
}

export function safeRemoveSource(map: mapboxgl.Map, sourceId: string) {
  if (map && !!map.getStyle() && map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }
}

export function safeQueryRenderedFeatures(
  map: mapboxgl.Map | undefined,
  geometry: mapboxgl.PointLike | [mapboxgl.PointLike, mapboxgl.PointLike],
  options: any = {}
): mapboxgl.MapboxGeoJSONFeature[] | undefined {
  try {
    return map?.queryRenderedFeatures(geometry, options);
  } catch {
    return;
  }
}

function makeSourceId(featureCollection: HydratedFeatureCollection) {
  return `featureCollection-${featureCollection.get('id')}`;
}

export interface FeatureCollectionSourceRecord {
  id: string;
  source: mapboxgl.AnySourceData;
  /** Important for styling tiled "vector" sources */
  sourceLayer: string | undefined;
}

export function getSourceForFeatureCollection(
  featureCollection: HydratedFeatureCollection
): FeatureCollectionSourceRecord {
  let source: mapboxgl.AnySourceData;
  let sourceLayer: string | undefined = undefined;

  const tiles = featureCollection.get('tiles');

  if (tiles) {
    const authenticatedTileUrls: string[] = tiles
      .get('urls')
      .map((url) => (url!.startsWith('http') ? url! : apiUtils.getApiRoute(url!)))
      .toJS();
    sourceLayer = tiles.get('sourceLayer');

    source = {
      type: 'vector',
      tiles: authenticatedTileUrls,
      // For vector tiles (eg overlays), we only want to request tiles for zoom
      // levels that we process. After that, Mapbox will automatically "overzoom"
      // the data that's already been fetched to higher zoom levels.
      // https://docs.mapbox.com/help/glossary/overzoom/
      maxzoom:
        featureCollection.get('id') === C.REGRID_FEATURE_COLLECTION_ID
          ? 14
          : (tiles.get('maxZoom') ?? C.OVERLAY_MAX_ZOOM_LEVEL),
    };
  } else {
    // cast to generic geojson FeatureCollection type since Immutable keeps that
    // from being inferred
    const sourceFeatureCollection: geojson.FeatureCollection = featureCollection
      .set('type', 'FeatureCollection')
      .set('features', featureUtils.getFeatures(featureCollection))
      .toJS();

    source = {
      type: 'geojson',
      data: sourceFeatureCollection,
    };
  }

  return {
    id: makeSourceId(featureCollection),
    source,
    sourceLayer,
  };
}

export function makeLayerSourceOptions(
  featureCollection: HydratedFeatureCollection
): Pick<mapboxgl.Layer, 'source' | 'source-layer'> {
  const sourceId = makeSourceId(featureCollection);

  const sourceOptions = {source: sourceId};

  // For tiled geojson sources, we need to say which layer within the source is
  // the one we’re displaying.
  if (featureCollection.get('tiles')) {
    sourceOptions['source-layer'] = featureCollection.getIn(['tiles', 'sourceLayer']);
  }

  return sourceOptions;
}

export async function loadIconImages(map: mapboxgl.Map) {
  try {
    const images = ['upstream_arrow', 'upstream_circle_selected', 'upstream_circle', 'map_pointer'];

    images.forEach((image) => {
      // Remove any cached custom icons.
      if (map.hasImage(image)) {
        map.removeImage(image);
      }
    });

    await Promise.all(
      images.map(
        (image) =>
          new Promise<void>((resolve, reject) => {
            // Load each custom icon, then add it to the map.
            map.loadImage(
              ICONS[image],
              function (
                error?: Error | undefined,
                data?: HTMLImageElement | ImageBitmap | undefined
              ) {
                if (error) {
                  return reject(error);
                }

                if (data) {
                  //set sdf to true to enable data-driven colors
                  //https://docs.mapbox.com/help/troubleshooting/using-recolorable-images-in-mapbox-maps/
                  map.addImage(image, data, {sdf: false});
                }

                resolve();
              }
            );
          })
      )
    );
  } catch (error) {
    console.error(error);
  }
}

/**
 * Uses the PaddingOptions to inset the Mapbox control containers. Useful for
 * when we’re adding overlays to the map (e.g. TabbedSidebar)
 */
export function applyPaddingToMapboxControls(
  mapboxglControlContainer: HTMLDivElement | undefined,
  padding: mapboxgl.PaddingOptions
) {
  if (!mapboxglControlContainer) {
    return;
  }

  const marginTop = `${padding.top}px`;
  const marginRight = `${padding.right}px`;
  const marginBottom = `${padding.bottom}px`;
  const marginLeft = `${padding.left}px`;

  Array.from(mapboxglControlContainer.children).forEach((node) => {
    const div = node as HTMLDivElement;
    switch (div.className) {
      case 'mapboxgl-ctrl-top-left':
        div.style.marginTop = marginTop;
        div.style.marginLeft = marginLeft;
        break;
      case 'mapboxgl-ctrl-top-right':
        div.style.marginTop = marginTop;
        div.style.marginRight = marginRight;
        break;
      case 'mapboxgl-ctrl-bottom-left':
        div.style.marginBottom = marginBottom;
        div.style.marginLeft = marginLeft;
        break;
      case 'mapboxgl-ctrl-bottom.right':
        div.style.marginBottom = marginBottom;
        div.style.marginRight = marginRight;
        break;
    }
  });
}

export function insetPadding(
  padding: mapboxgl.PaddingOptions,
  amount: number | [number, number, number, number]
): mapboxgl.PaddingOptions {
  if (Array.isArray(amount)) {
    return {
      top: padding.top + amount[0],
      right: padding.right + amount[1],
      bottom: padding.bottom + amount[2],
      left: padding.left + amount[3],
    };
  } else {
    return {
      top: padding.top + amount,
      right: padding.right + amount,
      bottom: padding.bottom + amount,
      left: padding.left + amount,
    };
  }
}

/**
 * Minimal wrapper for when you want to create a control from an HTML element
 * that you’re managing through a ReactDOM portal.
 *
 * Create the element in your component, then pass it in the constructor for
 * this class and use it as the target in your ReactDOM.createPortal call.
 *
 * Automatically adds the 'mapboxgl-ctrl' class to the element.
 */

export class PortalControl extends mapboxgl.Evented implements mapboxgl.IControl {
  private el: HTMLElement;

  constructor(el: HTMLElement) {
    super();

    this.el = el;
    el.className = 'mapboxgl-ctrl';
  }

  onAdd() {
    return this.el;
  }

  onRemove() {
    // Mapbox doesn’t do this cleanup for us.
    if (this.el.parentElement) {
      this.el.parentElement.removeChild(this.el);
    }
  }
}

/**
 * A mode for mapbox-gl-draw that doesn’t draw any of the features and doesn’t
 * have any interactions. Used when we’re not editing and we want NoteVector to
 * handle the editing and interactions.
 *
 * @see https://github.com/mapbox/mapbox-gl-draw/blob/master/docs/MODES.md
 */
export const BlankDrawMode = {
  onSetup(this: any) {
    this.setActionableState(); // default actionable state is false for all actions

    return {};
  },

  toDisplayFeatures() {},
};

/**
 * Context to keep track of whether clickable features are currently disabled.
 * Access with useAreClickableFeaturesDisabled. Modify with
 * useDisableClickableFeatures or <DisableClickableFeatures />
 */
const DisableClickableFeaturesContext = React.createContext(false);

/**
 * Context to provide the operations for updating
 * DisableClickableFeaturesContext. Used by useDisableClickableFeatures and
 * <DisableClickableFeatures />.
 */
const SetDisableClickableFeaturesContext = React.createContext<{
  disable: () => void;
  enable: () => void;
}>({disable() {}, enable() {}});

/**
 * Provider to manage the reference count state of whether clickable features
 * are temporarily disabled.
 */
export const DisableClickableFeaturesProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({children}) => {
  // Ref count of outstanding "disable" hooks.
  const [count, setCount] = React.useState(0);

  const actions = React.useRef({
    disable: () => setCount((c) => c + 1),
    enable: () => setCount((c) => c - 1),
  });

  return (
    <SetDisableClickableFeaturesContext.Provider value={actions.current}>
      <DisableClickableFeaturesContext.Provider value={count > 0}>
        {children}
      </DisableClickableFeaturesContext.Provider>
    </SetDisableClickableFeaturesContext.Provider>
  );
};

/**
 * Pass true to this hook to disable clickable features on the map. Use when
 * putting on draw mode or measure distance or things like that.
 */
export function useDisableClickableFeatures(shouldDisable: boolean) {
  const {disable, enable} = React.useContext(SetDisableClickableFeaturesContext);

  React.useEffect(() => {
    if (shouldDisable) {
      disable();

      return () => {
        enable();
      };
    }
  }, [shouldDisable, disable, enable]);
}

/**
 * Conditionally render this component to disable clickable features.
 *
 * Adapts useDisableClickableFeatures for class components.
 */
export const DisableClickableFeatures: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  useDisableClickableFeatures(true);

  return null;
};

/**
 * Returns true if clickable features are currently disabled. They can be
 * disabled when the map is in an interactive mode, such as drawing a polygon,
 * to prevent those clicks from also executing navigation.
 */
export function useAreClickableFeaturesDisabled() {
  return React.useContext(DisableClickableFeaturesContext);
}

/**
 * Hook to make certain layers interactive.
 *
 * If an "onFeatureClick" callback is provided, hovering over the given layer’s
 * features will turn the mouse into a pointer and clicking on the features will
 * call the callback.
 *
 * Can be temporarily disabled with the useDisableClickableFeatures hook.
 */
export function useClickableFeatures(
  map: mapboxgl.Map,
  layers: mapboxgl.AnyLayer[],
  /** This can be undefined because you can’t put a hook in a conditional, so we
   * need a way to disable it when it’s not needed. */
  onFeatureClick:
    | undefined
    | null
    | false
    | ((feature: mapboxgl.MapboxGeoJSONFeature, ev: mapboxgl.MapLayerMouseEvent) => void),
  /** Return false from this method to ignore an event. */
  eventFilter?: (ev: mapboxgl.MapLayerMouseEvent) => boolean
) {
  const disableClickableFeatures = React.useContext(DisableClickableFeaturesContext);

  useMapCursor(
    onFeatureClick && !disableClickableFeatures ? layers.map((l) => l.id) : [],
    'pointer',
    eventFilter
  );

  React.useEffect(() => {
    if (!onFeatureClick || disableClickableFeatures) {
      return;
    }

    const onClickPolygon = (ev: mapboxgl.MapLayerMouseEvent) => {
      if (eventFilter !== undefined && !eventFilter(ev)) {
        return;
      }

      const feature = (ev.features || [])[0];

      if (feature) {
        onFeatureClick(feature, ev);
      }
    };

    layers.forEach(({id}) => {
      map.on('click', id, onClickPolygon);
    });

    return () => {
      layers.forEach(({id}) => {
        map.off('click', id, onClickPolygon);
      });
    };
  }, [map, layers, onFeatureClick, disableClickableFeatures, eventFilter]);
}

/**
 * A function that wraps the mapbox-gl map.fitBounds method in a try/catch
 * statement. This prevents internal mapbox-gl-js errors from bubbling up and
 * crashing the application.
 *
 * This function is recommended when setTerrain is also acting on the map since
 * "Invalid LngLat object: (NaN, NaN)" errors have been observed when the style
 * ‘s terrain is still being set.
 */
export function safeFitBounds(
  map: mapboxgl.Map,
  bounds: mapboxgl.LngLatBoundsLike,
  options?: mapboxgl.FitBoundsOptions,
  eventData?: mapboxgl.EventData
) {
  try {
    map.fitBounds(bounds, options, eventData);
  } catch (error) {
    // If the fitBounds method invocation throws an error, no-op. If it‘s
    // important that this eventually succeeds, consider gating or firing on
    // events that will be called after style properties like terrain are set.
  }
}

interface GenericMapImageRef<C> {
  layerKey: string;
  cursor: C;
}

/** Narrowed type to be used with type predicate when filtering a list of image
 * references to those with truthy cursors. */
export type MapImageRefWithCursor = GenericMapImageRef<string>;

export type MapImageRef = GenericMapImageRef<string | null>;

export type MapImageRefs = [MapImageRef] | [MapImageRef, MapImageRef];

export function layerAndCursorsToImageRefs(layerKey: string, cursors: string[]): MapImageRefs {
  if (cursors.length == 0) {
    return [{layerKey, cursor: null}];
  } else if (cursors.length == 1) {
    return [{layerKey, cursor: cursors[0]}];
  } else {
    return [
      {layerKey, cursor: cursors[0]},
      {layerKey, cursor: cursors[1]},
    ];
  }
}

export function imageRefsToScenes(imageRef: MapImageRef): {
  source_id: string;
  layer_id: string;
  sensing_time: string;
} {
  const {sourceId, layerId} = layerUtils.parseLayerKey(imageRef.layerKey);
  return {source_id: sourceId, layer_id: layerId, sensing_time: imageRef.cursor || ''};
}

/** Use this when you have a new imageRef to display in compare mode, and need to
 * decide which pre-existing imageRef should remain onscreen.
 */
export function makeNewImageRefs(
  newImageRef: MapImageRefWithCursor,
  oldImageRefs: MapImageRefs,
  isStart: boolean
): MapImageRefs {
  //if for some reason we erroneously pass in only one imageRef to this function,
  //return only the new one (you will get kicked out of compare mode)
  if (oldImageRefs.length <= 1) {
    return [newImageRef];
  }
  //the SET_IMAGE_REFS hook already sorts these, so just pass it the correct values you
  //want to keep and it will sort them
  return isStart
    ? //if you're choosing a new start date, default to keeping the existing end date
      [newImageRef, oldImageRefs[1]!]
    : //if you're choosing a new end date, default to keeping the existing start date
      [oldImageRefs[0]!, newImageRef];
}

export function isTilerUrl(url: string): boolean {
  return (
    url.startsWith('http://localhost') || !!url.match(/^https?:\/\/([a-zA-Z]+\.)*upstream\.tech\//)
  );
}

export function formatTileUrl(
  url: string,
  authToken: string | null, // The Firebase JWT, or a STREAMIMAGERY API key
  appendApiToken = true,
  // An optional function that can be used to transform the URL before it is returned.
  transformUrl: ((url: URL) => URL) | undefined = undefined
): string {
  // Requests to a local server or one of our production domains should get the Firebase auth token
  // added on to authenticate the request.
  if (isTilerUrl(url)) {
    let parsedUrl = new URL(url);
    if (useLocalTiler && parsedUrl.host == 'tiler.upstream.tech') {
      parsedUrl.protocol = 'http';
      parsedUrl.host = 'localhost';
      parsedUrl.port = '8000';
    }

    if (apiUtils.contextOrganizationId) {
      parsedUrl.searchParams.set('cOrgId', apiUtils.contextOrganizationId);
    }

    if (transformUrl) {
      parsedUrl = transformUrl(parsedUrl);
    }

    if (appendApiToken) {
      if (authToken) {
        // Send the token as a query param to avoid needing to make a preflight request first.
        // (Custom headers, including `Authorization`, require a CORS preflight request)
        parsedUrl.searchParams.set('apiToken', authToken);
      } else if (isPublicLensUrl()) {
        const publicLensConfigId = window.location.pathname.split('/p/')[1];
        parsedUrl.searchParams.set('apiToken', `publicLensConfigId:${publicLensConfigId}`);
      }
    }

    url = decodeURI(parsedUrl.toString());
  }
  return url;
}

/**
 * Hook to make certain layers hoverable. When the mouse is initially moved over
 * a feature in one of the provided layers, the onHoverFeature callback will be
 * called with the hovered feature’s ID. Conversely, when the mouse is moved out
 * of a feature in one of the provided layers, the onHoverFeature callback will
 * be called with undefined.
 */
export function useHoverableFeatures(
  map: mapboxgl.Map,
  layers: mapboxgl.AnyLayer[],
  onHoverFeature: (layerId: string, featureId: string | number | undefined) => void
) {
  const onMouseEnter = React.useCallback(
    (event: mapboxgl.MapLayerMouseEvent) => {
      if (event.features && event.features.length) {
        event.features.forEach((feature) => {
          onHoverFeature(feature.layer.id, feature.id);
        });
      }
    },
    [onHoverFeature]
  );

  /**
   * Because the `mouseleave` event listener does not contain any information
   * about what layer it’s no longer hovering, we use a higher-order function
   * that binds the layer ID to the callback function on initialization.
   */
  const makeOnMouseLeave = React.useCallback(
    (layerId: string) => () => {
      onHoverFeature(layerId, undefined);
    },
    [onHoverFeature]
  );

  /**
   * Attach event listeners to `mouseenter` and `mouseleave` events that are
   * responsible for invoking the `onHoverFeature` callback with the correct
   * arguments. This has the potential to not always trigger for layers where
   * features are adjacent, such as parcel data. If this becomes an issue, we
   * can investigate over map events, such as `mousemove` paired with
   * `queryRenderedFeatures`.
   */
  React.useEffect(() => {
    const mouseLeaveEventListeners: Record<string, () => void> = {};

    layers.forEach(({id}) => {
      mouseLeaveEventListeners[id] = makeOnMouseLeave(id);
      map.on('mouseenter', id, onMouseEnter);
      map.on('mouseleave', id, mouseLeaveEventListeners[id]);
    });

    return () => {
      layers.forEach(({id}) => {
        map.off('mouseenter', id, onMouseEnter);
        map.off('mouseleave', id, mouseLeaveEventListeners[id]);
      });
    };
  }, [map, layers, onMouseEnter, makeOnMouseLeave]);

  return {onMouseEnter, makeOnMouseLeave};
}

export interface CameraOptions {
  center: mapboxgl.LngLat;
  pitch: number;
  bearing: number;
  zoom: number;
}
