import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React, {MouseEvent} from 'react';

import {AlertPolicy} from 'app/modules/Remote/Feature';
import {AlertNoteType, StateApiNote, getAlertNoteType} from 'app/stores/NotesStore';
import * as imageryUtils from 'app/utils/imageryUtils';
import {noteHasUnenrolledLayers} from 'app/utils/noteUtils';

import cs from './NoteCard.styl';
import {SceneLocationPopover} from './SceneLocationPopover';
import {UnenrolledLayerMessage} from './UnenrolledLayerMessage';
import {useAlertPolicies} from '../Alerts/AlertPolicyProvider';

const MAX_CUSTOM_ALERT_NAME_DISPLAY_LENGTH = 40; // A magic number length that felt right

const ALERT_NOTE_CONFIGURATIONS: {
  [K in AlertNoteType]: {
    icon: B.IconName;
    accentColor: string;
    alertDisplayMessage: (note: StateApiNote, alertPolicy?: AlertPolicy) => string;
    focusClass: string;
  };
} = {
  vegetationDrop: {
    icon: 'tree',
    accentColor: cs.vegetationDropColor,
    focusClass: cs.selectedVegAlert,
    alertDisplayMessage: (note) =>
      `Vegetation change detected on ${imageryUtils.formatDate(note.createdAt)}`,
  },
  ownershipChange: {
    icon: 'key',
    accentColor: cs.ownershipChangeColor,
    focusClass: cs.selectedOwnershipAlert,
    alertDisplayMessage: (note) =>
      `Parcel owner change detected on ${imageryUtils.formatDate(note.createdAt)}`,
  },
  customAlert: {
    icon: 'notifications',
    accentColor: cs.customAlertColor,
    focusClass: cs.selectedCustomAlert,
    // Eventually parse this for the specific custom alert type
    alertDisplayMessage: (note, alertPolicy) => {
      let alertName = alertPolicy?.name || 'Custom alert';
      if (alertName.length > MAX_CUSTOM_ALERT_NAME_DISPLAY_LENGTH) {
        alertName = `${alertName.substring(0, MAX_CUSTOM_ALERT_NAME_DISPLAY_LENGTH - 3)}...`; // Subtract 3 to account for the length of "..."
      }
      return `${alertName} · ${imageryUtils.formatDate(note.createdAt)}`;
    },
  },
};

const AlertCard: React.FunctionComponent<
  React.PropsWithChildren<{
    note: StateApiNote;
    isSelected: boolean;
    isFocused: boolean;
    enrolledLayerKeys?: string[];
    toggleSelected: (id: number | string) => void;
    onDelete: (event: MouseEvent<HTMLElement>) => void;
    onCreateNoteFromAlert: (note: StateApiNote) => void;
    onSelectNote: (note: StateApiNote) => void;
    onHoverNote: (event: MouseEvent<HTMLElement>) => void;
    onUnhoverNote: (event: MouseEvent<HTMLElement>) => void;
  }>
> = ({
  note,
  isSelected,
  isFocused,
  enrolledLayerKeys,
  toggleSelected,
  onDelete,
  onSelectNote,
  onCreateNoteFromAlert,
  onHoverNote,
  onUnhoverNote,
}) => {
  const {alertPolicies} = useAlertPolicies();
  const alertPolicy = alertPolicies.find(
    (alertPolicy) => alertPolicy.id === note.properties?.alert_policy_id
  );

  const noteType = getAlertNoteType(note);
  const noteConfig = ALERT_NOTE_CONFIGURATIONS[noteType];

  // If this is an alert, apply type specific accents.
  const accentColor = noteConfig.accentColor;

  if (note.isCreating || note.isUpdating) {
    return (
      <div className={cs.card}>
        <B.Spinner className={cs.spinner} size={B.SpinnerSize.SMALL} />
      </div>
    );
  }

  //check if the note is associated with an unenrolled layer
  const hasUnenrolledLayer = enrolledLayerKeys && noteHasUnenrolledLayers(note, enrolledLayerKeys);

  const selectionAction = (
    <div className={accentColor} onClick={(e) => e.stopPropagation()}>
      <B.Checkbox
        className={cs.selectCheckbox}
        checked={isSelected}
        onChange={() => toggleSelected(note.id)}
      />
    </div>
  );

  const noteBody = (
    <>
      {hasUnenrolledLayer && <UnenrolledLayerMessage note={note} />}
      <p>{note.text}</p>
      <div className={accentColor}>
        <B.Button
          style={{marginRight: '0.5rem'}}
          small
          text="Save as note"
          icon="annotation"
          onClick={(e) => {
            e.stopPropagation();
            onCreateNoteFromAlert(note);
          }}
          intent="primary"
          minimal
        />
        <B.Button
          small
          icon="trash"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(e);
          }}
          text="Dismiss"
          minimal
        />
      </div>
    </>
  );

  // Generates alertType specific messaging
  const alertTypeMessage = noteConfig.alertDisplayMessage(note, alertPolicy);

  return (
    <B.Card
      interactive
      className={classnames(cs.card, {[noteConfig.focusClass]: isFocused})}
      onClick={() => onSelectNote(note)}
      onMouseEnter={onHoverNote}
      onMouseLeave={onUnhoverNote}
    >
      <div className={cs.body}>
        <div className={cs.header}>
          <div className={cs.headerText}>
            <div className={cs.headerTextChild}>{alertTypeMessage}</div>
          </div>
          <div className={cs.headerRightGroup}>
            <SceneLocationPopover note={note} />
            {selectionAction}
          </div>
        </div>

        {noteBody}
      </div>
    </B.Card>
  );
};

export default AlertCard;
