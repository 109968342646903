import * as B from '@blueprintjs/core';
import {DateRangeInput3} from '@blueprintjs/datetime2';
import classnames from 'classnames';
import geojson from 'geojson';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React from 'react';

import MemoizedFeatureArea from 'app/components/MemoizedFeatureArea';
import {usePushNotification} from 'app/components/Notification';
import {api} from 'app/modules/Remote';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import {
  ApiTaskingPlan,
  ApiTaskingPlanProperties,
  ApiTaskingVendor,
} from 'app/modules/Remote/Tasking';
import colors from 'app/styles/colors.json';
import * as conversionUtils from 'app/utils/conversionUtils';
import * as envUtils from 'app/utils/envUtils';
import * as featureUtils from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {UTMArea} from 'app/utils/geoJsonUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as taskingUtils from 'app/utils/taskingUtils';

import cs from './TaskImageryModal.styl';
import TaskImageryModalMap from './TaskImageryModalMap';

const SPECTRAL_BAND_OPTIONS = [
  'Truecolor 3-band (red, green, blue)',
  '4-band (red, green, blue, infrared)',
  'Other',
];
const MISSING_PLAN_MESSAGE = 'You must run a tasking request plan before submitting.';
export const UNAUTHORIZED_MESSAGE = 'You are not authorized to submit a tasking request.';

type Run = ApiTaskingPlanProperties & {
  plan: ApiTaskingPlan;
  planAreaM2: number;
  planCost: number;
};

const TaskImageryModal: React.FunctionComponent<
  React.PropsWithChildren<{
    features: ApiFeature[];
    areaUnit: ApiOrganizationAreaUnit;
    /** Allows the modal to maintain run cache even after being closed, as long as
     * the features are the same. */
    isOpen: boolean;
    maySubmit: boolean;
    onClose: () => void;
  }>
> = ({features, areaUnit, isOpen, maySubmit, onClose}) => {
  features = hookUtils.useContinuity(features);

  const pushNotification = usePushNotification();

  /**
   * Wrapper around useStateWithDeps that hardcodes the features prop as a
   * dependency. This allows us to efficiently reset the entire modal state when
   * the features prop changes.
   */
  const useState = React.useCallback(
    <S,>(initialState: S, deps: readonly any[] = []) =>
      hookUtils.useStateWithDeps<S>(initialState, [features, ...deps]),
    [features]
  );

  const [modalPage, setModalPage] = useState<number>(1);

  const [selectedVendor, setSelectedVendor] = useState<ApiTaskingVendor>('airbus_pleiades');

  const [featureIds] = useState(features.map(({id}) => id));
  const [featureCollection] = useState(geoJsonUtils.featureCollection(features));
  const [selectedFeatureIds, setSelectedFeatureIds] = useState(featureIds);
  const [hoveredFeatureId, setHoveredFeatureId] = useState<number | null>(null);

  const [runs, setRuns] = useState<Run[]>([]);
  const [activeRunIndex, setActiveRunIndex] = useState(-1);
  const activeRun = activeRunIndex >= 0 ? runs[activeRunIndex] : null;

  // Step 3 metadata
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [spectralBands, setSpectralBands] = useState<string>(SPECTRAL_BAND_OPTIONS[0]);
  const [signatoryInfo, setSignatoryInfo] = useState<string | undefined>(undefined);
  const [additonalInfo, setAdditionalInfo] = useState<string | undefined>(undefined);

  const [fitTarget, setFitTarget] = useState<geojson.GeoJSON>(featureCollection);

  const [isRunning, setIsRunning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [planSubmitted, setPlanSubmitted] = useState(false);

  const getIsFeatureSelected = React.useCallback(
    (featureId: number) => selectedFeatureIds.includes(featureId),
    [selectedFeatureIds]
  );

  const selectedFeatureCollection = React.useMemo(() => {
    const selectedFeatures = features.filter(({id}) => getIsFeatureSelected(id));
    return geoJsonUtils.featureCollection(selectedFeatures);
  }, [features, getIsFeatureSelected]);

  const unselectedFeatureCollection = React.useMemo(() => {
    const unselectedFeatures = features.filter(({id}) => !getIsFeatureSelected(id));
    return geoJsonUtils.featureCollection(unselectedFeatures);
  }, [features, getIsFeatureSelected]);

  const zoomToFitTarget = React.useMemo<geojson.GeoJSON>(
    () => activeRun?.plan || selectedFeatureCollection,
    [activeRun, selectedFeatureCollection]
  );

  const toggleFeatureSelection = React.useCallback(
    (featureId: number) =>
      setSelectedFeatureIds((prevSelectedFeatureIds) =>
        prevSelectedFeatureIds.includes(featureId)
          ? prevSelectedFeatureIds.filter((id) => id !== featureId)
          : prevSelectedFeatureIds.concat(featureId)
      ),
    [setSelectedFeatureIds]
  );

  const onModalClose = () => {
    onClose();
    setModalPage(1);
    setPlanSubmitted(false);
  };

  const runPlan = React.useCallback(async () => {
    try {
      setIsRunning(true);
      const plan = (await api.tasking.optimizePlan(selectedFeatureIds, selectedVendor)).toJS();
      const planAreaM2 = UTMArea(plan);
      const planCost = estimateCost(selectedVendor, planAreaM2);
      setRuns((prevRuns) =>
        prevRuns.concat({
          featureIds: selectedFeatureIds,
          vendor: selectedVendor,
          plan,
          planAreaM2,
          planCost,
        })
      );
    } catch (e) {
      console.error(e);
      pushNotification({
        message: 'There was a problem estimating your tasking plan. Please try again.',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } finally {
      setIsRunning(false);
    }
  }, [selectedFeatureIds, selectedVendor, pushNotification]);

  const submitPlan = React.useCallback(async () => {
    try {
      if (!activeRun) {
        throw new Error(MISSING_PLAN_MESSAGE);
      }
      if (!startDate || !endDate) {
        throw new Error('You must select a date range before submitting.');
      }
      if (!maySubmit) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      }
      setIsSubmitting(true);

      if (envUtils.isProd) {
        /** Only make the API request from a production environment. It only
         * creates an Asana task and posts to Slack, so this helps us reduce
         * noise during development. */
        await api.tasking.submitPlan(
          activeRun.plan,
          startDate?.toISOString(),
          endDate?.toISOString(),
          spectralBands,
          additonalInfo || '',
          signatoryInfo || '',
          formatCost(activeRun.planCost)
        );
      }
      setPlanSubmitted(true);
    } catch (e) {
      console.error(e);
      pushNotification({
        message: 'There was a problem submitting your tasking plan. Please try again.',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [
    activeRun,
    startDate,
    endDate,
    maySubmit,
    spectralBands,
    additonalInfo,
    signatoryInfo,
    pushNotification,
  ]);

  /** If the current feature and vendor selection matches a record in our run
   * history, set the active run index so we use the cached plan instead of
   * prompting the user to re-run. */
  React.useEffect(() => {
    setActiveRunIndex(
      runs.findIndex((run) => {
        const hasFeatureIdsMatch = isEqual(sortBy(run.featureIds), sortBy(selectedFeatureIds));
        const hasVendorMatch = isEqual(run.vendor, selectedVendor);
        return hasFeatureIdsMatch && hasVendorMatch;
      })
    );
  }, [selectedFeatureIds, selectedVendor, runs, setActiveRunIndex]);

  /** Refit the map to the tasking plan geometry when it changes. */
  React.useEffect(() => {
    if (activeRun) {
      setFitTarget(activeRun.plan);
    }
  }, [activeRun]);

  /** Refit the map to the selected features if a tasking plan geometry isn’t
   * available. We also check that the preventRefit reference is set to false.
   * This provides a seam for us to make selection changes by clicking features
   * on the map without refitting the map, which can be disorienting. */
  const preventRefitRef = React.useRef(false);
  React.useEffect(() => {
    if (!activeRun) {
      if (!preventRefitRef.current) {
        setFitTarget(selectedFeatureCollection);
      }
      preventRefitRef.current = false;
    }
  }, [activeRun, selectedFeatureCollection]);

  const isAnyFeatureMultiLocation = features.some((f) => f.properties.multiFeaturePartName);
  const isAnyFeatureSelected = !!selectedFeatureIds.length;
  const isEachFeatureSelected = selectedFeatureIds.length === features.length;

  const pastEstimatesButtonGroup = (
    <div className={cs.footerButtonGroup}>
      {runs.length > 0 && (
        <B.Popover
          content={
            <B.Menu>
              {runs.map((run, index) => (
                <B.MenuItem
                  key={index}
                  htmlTitle={`Run ${index + 1}`}
                  active={activeRunIndex === index}
                  text={[
                    `${index + 1}`,
                    formatVendor(run.vendor),
                    formatPropertyCount(run.featureIds),
                    formatAreaWithUnit(run.planAreaM2, areaUnit),
                    formatCost(run.planCost),
                  ].join(' | ')}
                  onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    event.stopPropagation();
                    setSelectedVendor(run.vendor);
                    setSelectedFeatureIds(run.featureIds);
                  }}
                />
              ))}
            </B.Menu>
          }
        >
          <B.AnchorButton minimal intent={'primary'} text="Past Estimates" />
        </B.Popover>
      )}
    </div>
  );

  // Step 1
  const renderIntro = () => {
    return (
      <div className={cs.main}>
        <div className={cs.controls}>
          <p>
            <strong>Thank you for trying our Tasking Estimator Tool!</strong>
          </p>
          <p>
            Tasking is the process of requesting a satellite provider to capture imagery at a
            designated time and place. It's a great way to guarantee that you'll get the imagery you
            need, when you need it. Tasking costs more than the archive imagery you can order in
            Lens.
          </p>
          <p>
            <strong>This tool is intended for planning purposes only</strong> to give you a sense of
            cost including area minimums and buffer requirements for each vendor. The prices shown
            are estimates, and final quotes will be provided by the vendor based on your specific
            parameters.
          </p>
          <p>
            <a
              href="https://support.upstream.tech/article/72-tasking"
              target="_blank"
              rel="noopener noreferrer"
            >
              Check out our support doc here for more information.
            </a>
          </p>
        </div>
        <div className={cs.footer}>
          <div className={cs.footerButtons}>
            {pastEstimatesButtonGroup}
            <B.AnchorButton
              text="Next"
              intent="primary"
              onClick={() => setModalPage((modalPage) => modalPage + 1)}
            />
          </div>
        </div>
      </div>
    );
  };

  // Step 2
  const renderTaskingEstimator = () => {
    return (
      <div className={cs.main}>
        <div className={cs.controls}>
          <div>
            Please select an imagery source and properties to include in your tasking estimate. You
            can create multiple estimates by changing your selections.{' '}
            <a
              href="https://support.upstream.tech/article/72-tasking"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more about minimums and tradeoffs here.
            </a>
          </div>
          <div className={cs.field}>
            <label htmlFor="vendor">
              <h3>Imagery Source</h3>
            </label>

            <B.HTMLSelect
              id="vendor"
              name="vendor"
              title="Select an imagery vendor"
              value={selectedVendor}
              onChange={(e) => {
                setSelectedVendor(e.target.value as ApiTaskingVendor);
              }}
            >
              {taskingUtils.TASKING_VENDORS.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {formatVendorWithResolution(vendor)}
                </option>
              ))}
            </B.HTMLSelect>
          </div>
          <div className={cs.field}>
            <h3>Properties</h3>
            <div>{selectedFeatureIds.length || 'None'} selected</div>
          </div>
          <div className={cs.table}>
            <table>
              <tbody>
                <tr>
                  <th>
                    <B.Checkbox
                      label="Property"
                      className={cs.tableCheckbox}
                      checked={isEachFeatureSelected}
                      indeterminate={isAnyFeatureSelected && !isEachFeatureSelected}
                      onChange={() => {
                        setSelectedFeatureIds(isEachFeatureSelected ? [] : featureIds);
                      }}
                    />
                  </th>
                  {isAnyFeatureMultiLocation && <th>Location</th>}
                  <th className={cs.tableCellAlignRight}>{capitalize(formatAreaUnit(areaUnit))}</th>
                  <th className={cs.tableCellAlignCenter}>Locate</th>
                </tr>
                {features.map((feature) => (
                  <tr
                    key={feature.id}
                    className={classnames({
                      [cs.tableRowHovered]: feature.id === hoveredFeatureId,
                    })}
                    onMouseEnter={() => setHoveredFeatureId(feature.id)}
                    onMouseLeave={() => setHoveredFeatureId(null)}
                  >
                    <td>
                      <B.Checkbox
                        label={feature.properties.name}
                        checked={getIsFeatureSelected(feature.id)}
                        onChange={() => {
                          toggleFeatureSelection(feature.id);
                        }}
                      />
                    </td>
                    {isAnyFeatureMultiLocation && (
                      <td>{feature.properties.multiFeaturePartName ?? '—'}</td>
                    )}
                    <td className={cs.tableCellAlignRight}>
                      <MemoizedFeatureArea
                        feature={feature}
                        areaUnit={areaUnit}
                        formatAreaFn={formatArea}
                      />
                    </td>
                    <td className={cs.tableCellAlignCenter}>
                      <B.AnchorButton
                        title="Locate"
                        minimal
                        icon={<B.Icon icon="locate" color={colors.darkestGray} />}
                        onClick={() => setFitTarget(feature)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={cs.footer}>
          {isRunning && selectedFeatureIds.length > 10 && (
            <div className={cs.footerCallouts}>
              <B.Callout intent="primary" icon={null} className={cs.footerCalloutRunning}>
                This may take up to a few minutes. Hang tight!
              </B.Callout>
            </div>
          )}
          {!isRunning && !!activeRun && (
            <div className={cs.footerCallouts}>
              <B.Callout
                intent="success"
                icon={null}
                className={cs.footerCalloutPlan}
                data-testid="callout-plan"
              >
                The estimate to task your selected properties with{' '}
                {formatVendorWithResolution(selectedVendor)} is{' '}
                <strong>{formatCost(activeRun.planCost)}</strong> for{' '}
                {formatAreaWithUnit(activeRun.planAreaM2, areaUnit)}, including buffers and area
                minimums.
              </B.Callout>
            </div>
          )}

          <div className={cs.footerButtons}>
            {pastEstimatesButtonGroup}
            <div className={cs.footerButtonGroup}>
              {!activeRun ? (
                <B.AnchorButton
                  text="Estimate"
                  icon={isRunning ? <ButtonSpinner /> : null}
                  intent="primary"
                  disabled={!isAnyFeatureSelected || !!activeRun || isRunning}
                  onClick={runPlan}
                />
              ) : (
                <B.ButtonGroup>
                  <B.AnchorButton
                    text="Next"
                    intent="primary"
                    disabled={!activeRun || isRunning}
                    onClick={() => setModalPage((modalPage) => modalPage + 1)}
                  />
                  <B.Popover
                    content={
                      <B.Menu>
                        {!!activeRun && (
                          <B.MenuItem
                            text="Export GeoJSON"
                            onClick={() => {
                              const vendor = formatVendorWithResolution(activeRun.vendor);
                              const propertyCount = formatPropertyCount(activeRun.featureIds);
                              const area = formatAreaWithUnit(activeRun.planAreaM2, areaUnit);
                              const cost = formatCostWithCode(activeRun.planCost);
                              const filename = `Tasking_Plan-${vendor}-${propertyCount}-${area}-${cost}`;
                              const formattedFilename = formatFileName(filename);
                              geoJsonUtils.exportGeoJson(activeRun.plan, formattedFilename);
                            }}
                          />
                        )}
                      </B.Menu>
                    }
                  >
                    <B.AnchorButton
                      icon="chevron-down"
                      intent="primary"
                      disabled={!activeRun || isRunning}
                    />
                  </B.Popover>
                </B.ButtonGroup>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Step 3
  const renderMetadataForm = () => {
    return (
      <div className={cs.main}>
        <div className={cs.controls}>
          <h3>Please fill out the following details to help us prepare a formal quote:</h3>
          <div className={cs.inputWrapper}>
            <strong>Preferred capture timing - please select a 2 week window minimum</strong>
            <DateRangeInput3
              shortcuts={false}
              value={[startDate, endDate]}
              onChange={([nextStartDate, nextEndDate]) => {
                setStartDate(nextStartDate);
                setEndDate(nextEndDate);
              }}
              formatDate={(date) => moment(date).format('L')}
              parseDate={(str) => moment(str, 'L').toDate()}
              minDate={new Date()}
            />
          </div>
          <div className={cs.inputWrapper}>
            <strong>Spectral bands</strong>
            <B.HTMLSelect
              options={SPECTRAL_BAND_OPTIONS}
              value={spectralBands}
              onChange={(ev) => setSpectralBands(ev.currentTarget.value)}
            />
          </div>
          <div className={cs.inputWrapper}>
            <strong>Any other details you'd like to share?</strong>
            <B.InputGroup
              id="additional-info-input"
              value={additonalInfo}
              // TODO: do we have guards around free form text inputs?
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAdditionalInfo(e.target.value);
              }}
            />
          </div>
          <div className={cs.inputWrapper}>
            <strong>
              After clicking submit, we will confirm the price with the vendor and send you a quote
              to be signed. Please include the name and email of the person who should sign the
              quote, if different than the person submitting this form.
            </strong>
            <B.InputGroup
              id="additional-info-input"
              value={signatoryInfo}
              // TODO: do we have guards around free form text inputs?
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSignatoryInfo(e.target.value);
              }}
            />
          </div>
          <div className={cs.descriptionWrapper}>
            <strong>Please note:</strong>
            <div>
              Imagery quality can vary by vendor and based on factors like shadows. Shadows can be
              particularly extreme at times of the year when the sun is lower in the sky (ex:
              northern US and Canada in winter).
            </div>
            <div>
              If you are tasking with Planet your property may be tasked through multiple captures.
              These will come into Lens as separate images.
            </div>
            <div>
              Additionally, you may start to see new images appearing in the order pane for the
              tasked properties as your areas are captured. Once all images are captured they will
              be delivered to your Lens account and you do not need to purchase them from the order
              pane.
            </div>
          </div>
        </div>

        <div className={cs.footer}>
          {planSubmitted && (
            <div className={cs.footerCallouts}>
              <B.Callout
                intent="success"
                icon={null}
                className={cs.footerCalloutPlan}
                data-testid="callout-plan"
              >
                Your quote request has been sent and we'll be in touch within a few days.
              </B.Callout>
            </div>
          )}
          <div className={cs.footerButtons}>
            {pastEstimatesButtonGroup}
            <div className={cs.footerButtonGroup}>
              {!planSubmitted ? (
                <>
                  <B.AnchorButton
                    text="Back"
                    intent="primary"
                    onClick={() => setModalPage((modalPage) => modalPage - 1)}
                  />
                  <B.Tooltip content={!maySubmit ? UNAUTHORIZED_MESSAGE : undefined}>
                    <B.AnchorButton
                      text="Submit"
                      icon={isSubmitting ? <ButtonSpinner /> : null}
                      intent="primary"
                      disabled={
                        !maySubmit ||
                        !activeRun ||
                        isRunning ||
                        isSubmitting ||
                        !startDate ||
                        !endDate ||
                        planSubmitted
                      }
                      onClick={submitPlan}
                    />
                  </B.Tooltip>
                </>
              ) : (
                <B.AnchorButton text={'Close'} intent="primary" onClick={onModalClose} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    switch (modalPage) {
      case 3:
        return renderMetadataForm();
      case 2:
        return renderTaskingEstimator();
      case 1:
      default:
        return renderIntro();
    }
  };

  return (
    <B.Dialog isOpen={isOpen} onClose={onModalClose} className={cs.dialog}>
      <div className={cs.header}>
        <B.Button minimal onClick={onModalClose} icon={'cross'} />
      </div>
      <div className={cs.body}>
        {renderBody()}
        <div className={cs.map}>
          <TaskImageryModalMap
            selectedFeatureCollection={selectedFeatureCollection}
            toggleFeatureSelection={(featureId) => {
              preventRefitRef.current = true;
              toggleFeatureSelection(featureId);
            }}
            setHoveredFeatureId={setHoveredFeatureId}
            fitTarget={fitTarget}
            zoomToFitTarget={zoomToFitTarget}
            taskingPlan={activeRun?.plan}
            unselectedFeatureCollection={unselectedFeatureCollection}
            hoveredFeatureId={hoveredFeatureId}
          />
        </div>
      </div>
    </B.Dialog>
  );
};

export default TaskImageryModal;

const ButtonSpinner: React.FunctionComponent = () => (
  <B.Spinner size={12} className={cs.buttonSpinner} />
);

function estimateCost(vendor: ApiTaskingVendor, areaM2: number) {
  return taskingUtils.TASKING_VENDOR_CONFIGS[vendor].estimateCost(areaM2);
}

function formatArea(areaM2: number, areaUnit: ApiOrganizationAreaUnit) {
  const convertedArea = featureUtils.featureM2ToArea(areaM2, {unit: areaUnit});
  return conversionUtils.numberWithCommas(convertedArea);
}

function formatAreaWithUnit(areaM2: number, areaUnit: ApiOrganizationAreaUnit) {
  const formattedArea = formatArea(areaM2, areaUnit);
  return `${formattedArea} ${formatAreaUnit(areaUnit, formattedArea === '1')}`;
}

function formatAreaUnit(areaUnit: ApiOrganizationAreaUnit, isSingular?: boolean) {
  return areaUnit === 'areaHectare'
    ? isSingular
      ? 'hectare'
      : 'hectares'
    : isSingular
      ? 'acre'
      : 'acres';
}

function formatCost(cost: number) {
  const roundedCost = cost.toFixed(0);
  return '$' + conversionUtils.numberWithCommas(roundedCost);
}

function formatCostWithCode(cost: number) {
  const roundedCost = cost.toFixed(0);
  return roundedCost + ' ' + 'USD';
}

function formatPropertyCount(featureIds: number[]) {
  const count = featureIds.length;
  return `${count} ${count === 1 ? 'property' : 'properties'}`;
}

function formatVendor(vendor: ApiTaskingVendor) {
  return taskingUtils.TASKING_VENDOR_CONFIGS[vendor].name;
}

function formatVendorWithResolution(vendor: ApiTaskingVendor) {
  const {name, resolution} = taskingUtils.TASKING_VENDOR_CONFIGS[vendor];
  return `${name} (${resolution})`;
}

function formatFileName(filename: string) {
  return filename.replace(/\s+/g, '_').replace(/\(|\)|,/g, '');
}
