import {ApiTaskingVendor} from 'app/modules/Remote/Tasking';

const M2_TO_KM2 = 0.000001;

interface TaskingVendorConfig {
  name: string;
  resolution: string;
  estimateCost: (areaM2: number) => number;
}

export const TASKING_VENDORS: ApiTaskingVendor[] = [
  'airbus_pleiades',
  'airbus_pneo',
  'planet_skysat',
];

export const TASKING_VENDOR_CONFIGS: {[key in ApiTaskingVendor]: TaskingVendorConfig} = {
  airbus_pleiades: {
    name: 'Airbus Pléiades',
    resolution: '0.5m',
    estimateCost: (areaM2) => {
      const USD_PER_KM2 = 18;
      const areaKM2 = areaM2 * M2_TO_KM2;
      return USD_PER_KM2 * areaKM2;
    },
  },
  airbus_pneo: {
    name: 'Airbus Pléiades Neo',
    resolution: '0.3m',
    estimateCost: (areaM2) => {
      const USD_PER_KM2 = 28;
      const areaKM2 = areaM2 * M2_TO_KM2;
      return USD_PER_KM2 * areaKM2;
    },
  },
  planet_skysat: {
    name: 'Planet Skysat',
    resolution: '0.5m',
    estimateCost: (areaM2) => {
      const USD_PER_KM2 = 12.48;
      const areaKM2 = areaM2 * M2_TO_KM2;
      const baseCost = USD_PER_KM2 * areaKM2;
      /** There's a $250 fee for requests below $500. */
      return baseCost + (baseCost < 500 ? 250 : 0);
    },
  },
} as const;
