import * as B from '@blueprintjs/core';
import {DateRangeInput3, DateRangeShortcut} from '@blueprintjs/datetime2';
import shpwrite from '@mapbox/shp-write';
import * as Sentry from '@sentry/react';
import {centroid} from '@turf/turf';
import classnames from 'classnames';
import {ExportToCsv} from 'export-to-csv';
import * as geojson from 'geojson';
import * as I from 'immutable';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

import AnalyzePolygonChart from 'app/components/AnalyzePolygonChart/AnalyzePolygonChart';
import MapOverlayDialog from 'app/components/MapOverlayDialog/MapOverlayDialog';
import {NoteForm} from 'app/components/NoteCard/NoteCard';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser, getAreaUnit} from 'app/modules/Remote/Organization';
import {MapStateDispatch} from 'app/pages/MonitorProjectView/MapStateProvider';
import {
  AnalyzePolygonDrawMode,
  OverlayMaskFilter,
  OverlayMaskSource,
  OverlayMaskState,
  PolygonFeature,
  PolygonState,
  getDataRange,
  useMapPolygonDispatch,
  useMapPolygonState,
} from 'app/providers/MapPolygonStateProvider';
import {ProjectsActions} from 'app/providers/ProjectsProvider';
import ClipWorker from 'app/src/workers/clipWorker?worker';
import {NotesActions, NotesState, StateApiNote} from 'app/stores/NotesStore';
import {FeatureDataMeta, RasterCalculationResult} from 'app/stores/RasterCalculationStore';
import {recordEvent} from 'app/tools/Analytics';
import colors from 'app/utils/colorUtils';
import * as CONSTANTS from 'app/utils/constants';
import {alphanumericSort} from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {UTMArea} from 'app/utils/geoJsonUtils';
import {useStateWithDeps} from 'app/utils/hookUtils';
import {ALL_MNDWI, DataLayerInfo, S2_NDSI, S2_NDWI} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as mathUtils from 'app/utils/mathUtils';
import {getOrgPrefix} from 'app/utils/organizationUtils';
import * as userUtils from 'app/utils/userUtils';

import cs from './AnalyzePolygonPopup.styl';
import Inspector from './Inspector';
import {
  calculateDataPoints,
  createLoadingProps,
  getGraphModes,
  getGraphRanges,
  getLanduseLayerValueMap,
} from './utils';
import {calculateAverageMonthlyGraphPoints} from '../AnalyzePolygonChart/ByMonthChart';
import {
  ByYearGraphRange,
  CustomGraphRange,
  DataRange,
  Graph,
  GraphColumn,
  GraphDataRangeMode,
  GraphMetadata,
  GraphPoint,
  GraphRange,
  GraphTimeRange,
  NoteGraph,
  NoteGraphDataRange,
  SimpleGraphRange,
  SomeYearsGraphRange,
} from '../AnalyzePolygonChart/types';
import {getDefaultDataRange, parseGraphDatesAsIsoStrings} from '../AnalyzePolygonChart/utils';
import {usePushNotification} from '../Notification';
import {MaskData} from '../RasterClipper/logic';
/**
 * CAUTION: The `GraphMode` string literal union type is stored in note graph
 * attachments. Only *add* additional types to the union unless you can confirm
 * the type you are removing does not exist on any notes.
 */
export type GraphMode =
  | 'aggregateCarbonSalo'
  | 'aggregateCarbonSpaceIntelligence'
  | 'average'
  | 'averageMonthly'
  | 'area'
  | 'areaByCategory';

const GRAPH_MODE_LABELS: {[mode in GraphMode]: string} = {
  aggregateCarbonSalo: 'Aggregate value',
  aggregateCarbonSpaceIntelligence: 'Aggregate value',
  average: 'Average value',
  area: 'Area in range',
  areaByCategory: 'Area by category',
  averageMonthly: 'Monthly average value',
};

const PRESET_GROUP_LABEL: {[mode in GraphMode]: string | null} = {
  aggregateCarbonSalo: null,
  aggregateCarbonSpaceIntelligence: null,
  average: null,
  area: 'Area by Category',
  areaByCategory: null,
  averageMonthly: null,
};

export type ExtraGraphOptions = {
  showCumulativeValues: boolean;
};

export type AreaModePreset = {label: string; min: number; max: number};

const AREA_PRESETS: Record<string, AreaModePreset[] | undefined> = {
  [ALL_MNDWI]: [
    {label: 'Deep water', min: 0.78, max: 1.0},
    {label: 'Moist earth / shallow water', min: 0.34, max: 0.77},
    {label: 'All water', min: 0.34, max: 1.0},
  ],
  [S2_NDWI]: [
    {label: 'Not water', min: -0.1, max: 0.045},
    {label: 'Water', min: 0.05, max: 0.4},
  ],
  [S2_NDSI]: [
    {label: 'Not Snow', min: 0, max: 0.312},
    {label: 'Snow', min: 0.32, max: 0.8},
  ],
};

// Discriminated union so we can be clear about what values are available in
// what states.
export type TimeSeriesCalculatorData =
  | {
      status: 'loaded';
      values: [Date, RasterCalculationResult, FeatureDataMeta][];
      threshold: readonly [number, number];
    }
  | {
      status: 'loading';
      progress: number | null;
    }
  | {
      status: 'loading-data';
      progress: number | null;
    }
  | {
      status: 'error';
    };

export type AnalyzePolygonGeometryError = 'geometry-error';
export type AnalyzePolygonLoadingError = 'loading-error';

type AnalyzePolygonStatus =
  | AnalyzePolygonGeometryError
  | AnalyzePolygonLoadingError
  | 'incomplete-custom'
  | 'complete'
  | 'missing-layers';

const STATUS_MESSAGES = {
  'incomplete-custom': () => null,
  'geometry-error': () => (
    <div>This area is too far away from the property. Move or draw it closer.</div>
  ),
  'loading-error': () => <div>There was a problem loading data for this property.</div>,
  'missing-layers': ({organization}: {organization: I.ImmutableOf<ApiOrganization>}) => (
    <>
      First, enable an analytical data layer (ex: S2 vegetation) from the{' '}
      <Link
        to={`/${getOrgPrefix(organization)}/settings/layers`}
        onClick={() => recordEvent('Followed missing layer warning')}
        target="_blank"
      >
        Layers Library page.
      </Link>
    </>
  ),
};

const STATUS_ICONS: Record<string, B.IconName | null> = {
  'incomplete-custom': null,
  'geometry-error': 'warning-sign',
  'loading-error': 'warning-sign',
  'missing-layers': 'new-layers',
};

const LOWER_BOUND_CONFIDENCE_TITLE = '90% Confidence interval lower bound (5th percentile)';
const UPPER_BOUND_CONFIDENCE_TITLE = '90% Confidence interval upper bound (95th percentile)';

export const ANALYZE_POLYGON_POPUP_WIDTH = 360;
export const ANALYZE_POLYGON_POPUP_EXPANDED_WIDTH = 720;

const MenuItemText: React.FunctionComponent<
  React.PropsWithChildren<{
    label: string;
  }>
> = ({label}) => <div className={cs.menuItemText}>{label}</div>;

function makeAction(status: AnalyzePolygonStatus, onRetry: undefined | (() => void)) {
  if (status === 'loading-error' && onRetry) {
    return <B.Button onClick={() => onRetry()}>Try again</B.Button>;
  }
}

/**
 * Pure visual side of the AnalyzePolygonPopup that takes in data and renders
 * it. For the version that runs calculations and handles async state, see
 * AnalyzePolygonPopupWithState.
 */
const AnalyzePolygonPopup: React.ForwardRefRenderFunction<
  HTMLDivElement,
  {
    warning: string | null;
    error: AnalyzePolygonGeometryError | AnalyzePolygonLoadingError | null;
    polygonState: PolygonState;
    calculatorData: TimeSeriesCalculatorData;
    graphLayerKey: string;
    availableLayerKeys: string[];
    setLayerKey: (key: string) => void;
    imageRefs: mapUtils.MapImageRefs;
    graphRange: GraphRange;
    setGraphRange: React.Dispatch<React.SetStateAction<GraphRange>>;
    notesState: NotesState;
    notesActions: NotesActions;
    feature: I.ImmutableOf<ApiFeature>;
    onReset: null | (() => void);
    onClose: () => void;
    onRetry?: () => void;
    onNewNote?: (note: StateApiNote) => void;
    mapStateDispatch: MapStateDispatch;
    graphMode: GraphMode;
    graphModePreset: AreaModePreset | null;
    extraGraphOptions: ExtraGraphOptions;
    setExtraGraphOptions: React.Dispatch<React.SetStateAction<ExtraGraphOptions>>;
    setGraphMode: (mode: GraphMode, preset?: AreaModePreset | null) => void;
    overlayThreshold: [number, number];
    isCalculatingMask: boolean;
    setOverlayThreshold: (threshold: [number, number]) => void;
    selectEntireFeature: () => void;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    pixelAreaInM2?: number;
    setAnalyzeAreaDrawMode: (analyzeAreaDrawMode: AnalyzePolygonDrawMode) => void;
    analyzeAreaDrawMode: AnalyzePolygonDrawMode;
    displayEditableControls: boolean;
    setDisplayEditableControls: React.Dispatch<React.SetStateAction<boolean>>;
    layerVisibilityByKey: Record<string, {onlyUnorderedImageryAvailable: boolean}>;
    clearOverlayMask: () => void;
    updateOverlayMask: (overlayMaskFilter: OverlayMaskFilter) => void;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    updateFeatureCollection: ProjectsActions['updateFeatureCollection'];
  }
> = (
  {
    warning,
    error,
    polygonState,
    onReset,
    onClose,
    imageRefs,
    onRetry,
    calculatorData,
    availableLayerKeys: layerKeys,
    setLayerKey,
    graphRange,
    setGraphRange,
    notesState,
    notesActions,
    feature,
    onNewNote,
    mapStateDispatch,
    graphMode,
    graphModePreset,
    extraGraphOptions,
    setExtraGraphOptions,
    setGraphMode,
    overlayThreshold,
    isCalculatingMask,
    setOverlayThreshold,
    selectEntireFeature,
    organization,
    profile,
    setAnalyzeAreaDrawMode,
    analyzeAreaDrawMode,
    displayEditableControls,
    setDisplayEditableControls,
    layerVisibilityByKey,
    clearOverlayMask,
    updateOverlayMask,
    featureCollection,
    updateFeatureCollection,
    graphLayerKey,
  },
  ref
) => {
  const [isOptionPressed, setIsOptionPressed] = React.useState(false);
  const [inspectorWindow, setInspectorWindow] = React.useState<Window | null>(null);
  const [inspectorHtmlEl, setInspectorHtmlEl] = React.useState<HTMLElement | null>(null);

  const {polygon, polygonFeature, overlayMask} = polygonState;
  // useMemo because this is a bit of math
  const areaInM2 = React.useMemo(() => (polygon ? UTMArea(polygon) : 0), [polygon]);

  const exportShapefile = React.useCallback(() => {
    if (!polygon) {
      return;
    }

    const fileName = `${feature.getIn(['properties', 'name'])} - AnalyzeArea`;

    shpwrite.download(geoJsonUtils.featureCollection([geoJsonUtils.feature(polygon, {})]), {
      filename: fileName,
      folder: fileName,
      types: {polygon: fileName},
      compression: 'STORE',
      outputType: 'blob',
    });
  }, [polygon, feature]);

  const exportGraphCsv = React.useCallback(() => {
    if (calculatorData.status !== 'loaded') {
      return;
    }

    const areaUnit = getAreaUnit(organization);
    const areaKey = areaUnit === CONSTANTS.UNIT_AREA_HECTARE ? 'hectares' : 'acres';

    const polygonArea = parseFloat(
      mathUtils
        .formatMeasurement({
          value: areaInM2,
          unit: CONSTANTS.UNIT_AREA_M2,
          unitTo: areaUnit,
        })
        .value.replace(/,/g, '') // remove commas for parseFloat
    );

    let dataPoints = calculateDataPoints(calculatorData, graphMode, graphLayerKey, areaInM2);

    // For the CSV export we want to only show the monthly average instead of each individual
    // date
    if (graphMode === 'averageMonthly') {
      dataPoints = calculateAverageMonthlyGraphPoints(dataPoints, graphLayerKey);
    }

    const csvDataPoints = dataPoints
      .map(([d, value, meta]) => {
        const date = layerUtils.conditionallyAdjustLayerDate(d, graphLayerKey).format('YYYY-MM-DD');
        const roundedArea = mathUtils.roundToThousandths(polygonArea);
        const roundedValue = mathUtils.roundToThousandths(value);
        switch (graphMode) {
          case 'aggregateCarbonSalo':
          case 'aggregateCarbonSpaceIntelligence':
          case 'average':
            return {
              date,
              value: roundedValue,
              [areaKey]: roundedArea,
            };

          case 'averageMonthly':
            return {
              date: layerUtils.conditionallyAdjustLayerDate(d, graphLayerKey).format('YYYY-MM'),
              value: roundedValue,
              [areaKey]: roundedArea,
            };

          case 'area':
            return {
              date,
              value: roundedValue,
              [areaKey]: mathUtils.roundToThousandths(polygonArea * value),
            };

          case 'areaByCategory': {
            const valueMap = getLanduseLayerValueMap(graphLayerKey);
            const classification = valueMap?.[meta.category!]?.label;

            return {
              date,
              category: meta.category,
              ...(classification && {classification}),
              value: roundedValue,
              [areaKey]: mathUtils.roundToThousandths(polygonArea * value),
            };
          }
        }
      })
      .filter(({value}) => !Number.isNaN(value));

    const makeHeaders = (data) => {
      // Order of the headers needs to match the data above.
      // First date, category if it exists, value, uncertainty if it exists, then area
      let headers = ['date'];
      if (data.some(({category}) => category !== undefined)) {
        headers.push('category');
        headers.push('classification');
      }
      headers.push('value');
      if (data.some(({lowerUncertainty}) => !!lowerUncertainty)) {
        // NOTE: if we add layers that contain uncertainty data and that has different bounds
        // we may need to update these titles
        headers = headers.concat([LOWER_BOUND_CONFIDENCE_TITLE, UPPER_BOUND_CONFIDENCE_TITLE]);
      }
      headers.push(areaKey);

      return headers;
    };

    const layerDisplay = layerUtils.getLayer(graphLayerKey).display;
    const csvExporter = new ExportToCsv({
      showLabels: true,
      useBom: false,
      filename: `${feature.getIn(['properties', 'name'])} - ${layerDisplay} ${
        GRAPH_MODE_LABELS[graphMode]
      }`,

      /** If any data have a category value, include the category column. */
      headers: makeHeaders(csvDataPoints),
    });

    csvExporter.generateCsv(csvDataPoints);
  }, [calculatorData, organization, areaInM2, graphMode, graphLayerKey, feature]);

  const openInspector = React.useCallback(() => {
    if (inspectorWindow) {
      inspectorWindow.focus();
    } else {
      const newInspectorWindow = window.open('', '_blank', undefined);

      if (newInspectorWindow) {
        newInspectorWindow.addEventListener('unload', () => {
          setInspectorWindow(null);
          setInspectorHtmlEl(null);
        });

        const newInspectorHtmlEl = newInspectorWindow.document.querySelector('html')!;

        while (newInspectorHtmlEl.firstChild) {
          newInspectorHtmlEl.removeChild(newInspectorHtmlEl.firstChild);
        }

        setInspectorWindow(newInspectorWindow);
        setInspectorHtmlEl(newInspectorHtmlEl);
      }
    }
  }, [inspectorWindow]);

  // Graph data from the raster calculator output.
  const graph = React.useMemo<Graph | null>(() => {
    const layer = layerUtils.getLayer(graphLayerKey) as DataLayerInfo;
    const dataPoints = calculateDataPoints(calculatorData, graphMode, graphLayerKey, areaInM2);

    return {
      dataPoints,
      dataThreshold: overlayThreshold,
      layerDisplay: layer.display,
      layerDataRange: layer.dataRange,
      layerDataLabels: layer.dataLabels,
      layerDataUnit: layer.dataUnit,
      layerGradientStops: layer.gradientStops,
      layerKey: graphLayerKey,
      graphMode,
      graphModePreset,
      graphRange,
      graphDataRange: null,
      graphDataRangeMode: 'none',
      graphTimeRange: null,
      dataColumns: null,
    };
  }, [
    graphLayerKey,
    graphMode,
    areaInM2,
    calculatorData,
    overlayThreshold,
    graphModePreset,
    graphRange,
  ]);

  // Derive metadata from polygonFeature properties, if any.
  const graphMetadata = React.useMemo<GraphMetadata | null>(() => {
    const properties = polygonFeature?.properties ?? {};
    let metadata: GraphMetadata | null = null;
    // For RDM (Residual Dry Matter) compliance data. This is usually available on an overlay,
    // and will be present here if you click on specific overlays when opening AA. Used by TNC CA.
    if (properties[CONSTANTS.RDM_PROPERTIES_KEY]) {
      const complianceList =
        properties[CONSTANTS.RDM_PROPERTIES_KEY][CONSTANTS.RDM_PROPERTIES_COMPLIANCE_KEY];
      metadata = {
        complianceByYear: complianceList.reduce(
          (byYear, item) => ({...byYear, [item.year]: item.compliance}),
          {}
        ),
      };
    }
    return metadata;
  }, [polygonFeature]);

  // Local copy of the graph’s current x-axis range, seeded with the current
  // graph’s time range, if available. Stored in state instead of a ref so we
  // can perform equality checks on rerender.
  const [localGraphTimeRange, setLocalGraphTimeRange] = React.useState<GraphTimeRange | null>(
    graph?.graphTimeRange || null
  );

  // Same as above, but for y-axis ranges
  const [localGraphDataRange, setLocalGraphDataRange] = React.useState<NoteGraphDataRange>(
    graph?.graphDataRange || null
  );

  const [localGraphDataRangeMode, setLocalGraphDataRangeMode] =
    React.useState<GraphDataRangeMode>('none');

  // Local copy of the year-over-year graph’s columns, seeded with the current
  // graph’s data columns, if available. Stored in state instead of a ref so we
  // can perform equality checks on rerender.
  const [localGraphColumns, setLocalGraphColumns] = React.useState<GraphColumn[] | null>(
    graph?.dataColumns || null
  );

  /** Lifecycle method to restore local state to defaults when settings change*/
  const clearLocalGraphData = React.useCallback(() => {
    setLocalGraphTimeRange(graph?.graphTimeRange || null);
    setLocalGraphDataRange(graph?.graphDataRange || null);
    setLocalGraphDataRangeMode('none');
    setLocalGraphColumns(graph?.dataColumns || null);
  }, [
    graph,
    setLocalGraphTimeRange,
    setLocalGraphDataRange,
    setLocalGraphDataRangeMode,
    setLocalGraphColumns,
  ]);

  // Loading props to show a spinner over the graph when we’re calculating graph
  // data from a raster source.
  const loadingProps = React.useMemo(() => createLoadingProps(calculatorData), [calculatorData]);

  // Graph data, plus local state values created by listening to uncontrolled
  // graph properties like time range and column selection. Also responsible for
  // "unparsing" the graph, or converting Date objects to ISO strings for the
  // API request payload.
  const noteGraph = React.useMemo<NoteGraph | null>(() => {
    const graphTimeRange = localGraphTimeRange;
    const graphDataRangeMode = localGraphDataRangeMode;

    if (graph && graphTimeRange) {
      /**
       * If no range mode is set, use defaults. Otherwise, use the locally set range for whatever alternate
       * mode. Cast to [number, number] since DataRange is readonly.
       *
       * This is effectively a guard around local state so that we don't save inconsistent graphs because
       * of weird state management in this component.
       * */
      const graphDataRange = (
        graphDataRangeMode === 'none' ? getDefaultDataRange(graph) : localGraphDataRange
      ) as [number, number];

      // If localGraphData exists, use that. Otherwise, use graph.dataPoints;
      const dataColumns = localGraphColumns;
      const usesDataColumns =
        graph.graphRange.type === 'by-year' || graph.graphMode === 'areaByCategory';
      const usesAndHasDataColumns = usesDataColumns && !!dataColumns;
      const layerDisplay =
        graphDataRangeMode === 'cumulative'
          ? `${graph.layerDisplay} (Cumulative)`
          : graph.layerDisplay;

      if (usesAndHasDataColumns || !usesDataColumns) {
        return parseGraphDatesAsIsoStrings({
          ...graph,
          graphTimeRange,
          graphDataRange,
          graphDataRangeMode,
          dataColumns,
          layerDisplay,
        });
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [graph, localGraphColumns, localGraphDataRange, localGraphTimeRange, localGraphDataRangeMode]);

  const graphDisplays = React.useMemo(
    () => layerKeys.map((key) => ({key, display: layerUtils.getLayer(key).display})),
    [layerKeys]
  );

  useEffect(() => {
    if (
      notesState.mayCreateNotes &&
      polygon &&
      !isEqual(polygon, notesState.pendingNoteGeometryFeature?.geometry)
    ) {
      // Update the central notes store pending geometry with the polygon, if it's different.
      notesActions.setPendingNoteGeometryFeature(
        geoJsonUtils.feature(polygon, {}, {id: `pending-note-location-feature`})
      );
    }
  }, [notesState.mayCreateNotes, notesState.pendingNoteGeometryFeature, polygon, notesActions]);

  const status =
    (!layerUtils.getRawLayerKey(graphLayerKey) && 'missing-layers') || //if we supplied a bad graphLayerKey here, we don't have any layers for AA
    (!graph && !graphDisplays.length && 'missing-layers') || //if we don't have a graph or any options for one, we don't have any layers for AA
    error ||
    (!polygon && 'incomplete-custom') ||
    'complete';

  const StatusElement = STATUS_MESSAGES[status];

  const [showNoteForm, setShowNoteForm] = useStateWithDeps(false, [polygon]);

  // controls whether the AnalyzePolygonPopup is in expanded state or not
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <MapOverlayDialog
        ref={ref}
        title={'Analysis'}
        titleIcon="timeline-line-chart"
        onClose={onClose}
        contentClassName={cs.dialogContent}
        style={{
          width: isExpanded ? ANALYZE_POLYGON_POPUP_EXPANDED_WIDTH : ANALYZE_POLYGON_POPUP_WIDTH,
        }}
        additionalButtons={
          <>
            <B.Popover
              position={B.Position.BOTTOM_RIGHT}
              modifiers={{arrow: {enabled: false}}}
              captureDismiss={true}
              content={
                <B.Menu>
                  <B.MenuItem text="Export shapefile" onClick={exportShapefile} />
                  <B.MenuItem
                    text="Export graph CSV"
                    disabled={calculatorData.status !== 'loaded'}
                    onClick={exportGraphCsv}
                  />

                  {userUtils.showAnalyzeAreaInternalTools(
                    profile,
                    organization,
                    isOptionPressed
                  ) && (
                    <>
                      <B.Divider />
                      <B.MenuItem
                        text="Open Inspector"
                        disabled={calculatorData.status !== 'loaded'}
                        onClick={openInspector}
                      />
                    </>
                  )}
                </B.Menu>
              }
            >
              <B.AnchorButton
                icon="more"
                disabled={!polygon}
                minimal
                onClick={(e) => setIsOptionPressed(e.altKey)}
              />
            </B.Popover>
            {onReset && (
              <B.Tooltip content="Start over" position={B.Position.TOP}>
                <B.AnchorButton icon="reset" minimal={true} disabled={!polygon} onClick={onReset} />
              </B.Tooltip>
            )}
            <B.Tooltip content={isExpanded ? 'Minimize' : 'Maximize'} position={B.Position.TOP}>
              <B.AnchorButton
                icon={isExpanded ? 'minimize' : 'maximize'}
                minimal={true}
                onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
              />
            </B.Tooltip>
          </>
        }
        footer={
          status === 'complete' && displayEditableControls ? (
            <div className={cs.footer}>
              <B.Button
                intent="primary"
                text={'Analyze'}
                onClick={() => {
                  setDisplayEditableControls(false);
                  recordEvent('Generated chart from Analysis', {layer: graphLayerKey});
                }}
              />
            </div>
          ) : status === 'complete' && !showNoteForm ? (
            <div className={cs.footer}>
              <B.Button text={'Edit chart'} onClick={() => setDisplayEditableControls(true)} />

              {profile.get('role', 'readonly') !== 'readonly' && (
                <B.Button
                  intent="primary"
                  text={'Add note'}
                  disabled={showNoteForm}
                  onClick={() => setShowNoteForm(true)}
                />
              )}
            </div>
          ) : (
            <></>
          )
        }
      >
        <div
          className={classnames(cs.content, {
            [cs.emptyState]: STATUS_MESSAGES[status] !== undefined,
            [cs.aaMode]: status === 'incomplete-custom',
          })}
        >
          {STATUS_MESSAGES[status] !== undefined && (
            <B.NonIdealState
              icon={STATUS_ICONS[status]}
              iconMuted={false}
              description={
                status === 'incomplete-custom' ? (
                  <>
                    <B.ButtonGroup>
                      <B.Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setAnalyzeAreaDrawMode('drawPolygon');
                        }}
                        active={analyzeAreaDrawMode === 'drawPolygon'}
                      >
                        Draw area
                      </B.Button>
                      <B.Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setAnalyzeAreaDrawMode('selectProperty');
                          selectEntireFeature();
                        }}
                        active={analyzeAreaDrawMode === 'selectProperty'}
                      >
                        Select property
                      </B.Button>
                      <B.Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setAnalyzeAreaDrawMode('selectOverlayPolygon');
                        }}
                        active={analyzeAreaDrawMode === 'selectOverlayPolygon'}
                      >
                        Select overlay
                      </B.Button>
                    </B.ButtonGroup>

                    <B.Callout className={cs.aaHelperText} intent="primary" icon={null}>
                      {analyzeAreaDrawMode === 'drawPolygon' &&
                        'Click on the map to draw an area to analyze. Double-click to complete your polygon.'}
                      {analyzeAreaDrawMode === 'selectOverlayPolygon' && (
                        <>
                          Click on an overlay polygon to analyze. Overlays can be viewed or added
                          from the <B.Icon icon={'layers'} color={colors.darkestGray} />{' '}
                          <b>Change Overlays & Basemap</b> menu.
                        </>
                      )}
                    </B.Callout>
                  </>
                ) : (
                  <StatusElement organization={organization} />
                )
              }
              action={makeAction(status, onRetry)}
            />
          )}

          {status === 'complete' && polygon && (
            // We wrap everything in a div to get it out of the display: flex
            // container so that vertical margins will collapse.
            <div>
              {graph && (
                <div className={cs.contentSection}>
                  <div className={cs.infoContainer}>
                    <AnalyzePolygonStats
                      organization={organization}
                      polygon={polygon}
                      areaInM2={areaInM2}
                    />
                    {warning && (
                      <B.Tooltip
                        position={B.Position.LEFT}
                        // Allows popper.js to position the popover outside of the
                        // dialog’s bounds
                        modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}
                        popoverClassName={cs.warningTooltip}
                        content={warning}
                      >
                        <B.Icon icon="warning-sign" color={colors.slate} />
                      </B.Tooltip>
                    )}
                  </div>

                  <Controls
                    graph={graph}
                    graphDisplays={graphDisplays}
                    imageRefs={imageRefs}
                    setLayerKey={setLayerKey}
                    graphRange={graphRange}
                    setGraphRange={setGraphRange}
                    graphMode={graphMode}
                    setGraphMode={setGraphMode}
                    clearLocalGraphData={clearLocalGraphData}
                    extraGraphOptions={extraGraphOptions}
                    setExtraGraphOptions={setExtraGraphOptions}
                    setGraphDataRangeMode={setLocalGraphDataRangeMode}
                    setOverlayThreshold={setOverlayThreshold}
                    mapStateDispatch={mapStateDispatch}
                    polygon={polygon}
                    displayEditableControls={displayEditableControls}
                    isExpanded
                    layerVisibilityByKey={layerVisibilityByKey}
                    overlayMask={overlayMask}
                    clearOverlayMask={clearOverlayMask}
                    isCalculatingMask={isCalculatingMask}
                    graphColumns={localGraphColumns}
                    updateOverlayMask={updateOverlayMask}
                  />
                  <div className={cs.graph}>
                    {!displayEditableControls && (
                      <AnalyzePolygonChart
                        graph={graph}
                        graphMetadata={graphMetadata}
                        extraGraphOptions={extraGraphOptions}
                        isExpanded={isExpanded}
                        imageRefs={imageRefs}
                        areaInM2={areaInM2}
                        areaUnit={getAreaUnit(organization)}
                        onColumnsChange={setLocalGraphColumns}
                        onTimeRangeChange={setLocalGraphTimeRange}
                        onDataRangeChange={setLocalGraphDataRange}
                        onDataRangeModeChange={setLocalGraphDataRangeMode}
                        updateCursors={(cursors: string[]) => {
                          // We switch to the graph’s layer because that’s the only
                          // way to guarantee that there’s imagery for the cursors.
                          // Otherwise if you’re looking at e.g. high-resolution
                          // imagery you just see your overlay disappear and nothing
                          // new show up.
                          //
                          // TODO(fiona): It would be cool to be able to keep the
                          // current layer if-and-only-if it had data for the new
                          // cursors.
                          //
                          // TODO(fiona): Make the graph work with imageRefs.
                          mapStateDispatch({
                            type: 'SET_IMAGE_REFS',
                            imageRefs: mapUtils.layerAndCursorsToImageRefs(graph.layerKey, cursors),
                          });
                        }}
                        loading={loadingProps.loading}
                        loadingStatus={loadingProps.loadingStatus}
                        loadingProgress={loadingProps.loadingProgress}
                        loadingIcon={loadingProps.loadingIcon}
                        loadingClassName={isExpanded ? cs.loadingExpanded : ''}
                        graphStyle={{height: isExpanded ? 250 : 150}}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* hide the box in the case that no analyzable layers are available to select*/}
              {graph && notesState.mayCreateNotes && showNoteForm && (
                <div className={cs.noteForm}>
                  <NoteForm
                    organization={organization}
                    profile={profile}
                    featureCollection={featureCollection}
                    imageRefs={imageRefs}
                    notesState={notesState}
                    notesActions={notesActions}
                    onNoteCreated={(note) => {
                      recordEvent('Created note from Analysis', {
                        projectId: note.projectId,
                        featureCollectionId: note.featureCollectionId,
                        noteId: note.id,
                      });
                      onNewNote?.(note);
                    }}
                    onSelectImageRefs={(imageRefs) =>
                      mapStateDispatch({type: 'SET_IMAGE_REFS', imageRefs})
                    }
                    autoUpdateLayerAndCursors={true}
                    graph={noteGraph}
                    onClose={() => setShowNoteForm(false)}
                    renderMinimalForAA={true}
                    updateFeatureCollection={updateFeatureCollection}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </MapOverlayDialog>
      {graph &&
        inspectorHtmlEl &&
        ReactDOM.createPortal(
          <Inspector
            window={inspectorWindow}
            title={`Inspector | ${feature.getIn(['properties', 'name'])} | Lens`}
            polygon={polygon}
            areaInM2={areaInM2}
            graphMode={graphMode}
            calculatorData={calculatorData}
            updateCursors={(cursor) =>
              mapStateDispatch({
                type: 'SET_IMAGE_REFS',
                imageRefs: mapUtils.layerAndCursorsToImageRefs(graph.layerKey, [cursor]),
              })
            }
            layerKey={graphLayerKey}
          />,
          inspectorHtmlEl
        )}
    </>
  );
};

const ThresholdSlider: React.FC<{
  layerKey: string;
  activeLayers: mapUtils.MapImageRefs;
  dataRange: DataRange;
  overlayThreshold: [number, number];
  setOverlayThreshold: (threshold: [number, number]) => void;
  setMapLayerKey: (nextLayerKey: string) => void;
  overlayMask: OverlayMaskState | null;
  clearOverlayMask: () => void;
  updateOverlayMask: (overlayMaskFilter: OverlayMaskFilter) => void;
  isCalculatingMask: boolean;
}> = ({
  layerKey,
  activeLayers,
  dataRange,
  overlayThreshold,
  setOverlayThreshold,
  updateOverlayMask,
  setMapLayerKey,
  overlayMask,
  clearOverlayMask,
  isCalculatingMask,
}) => {
  const [overlayMaskIsOn, setOverlayMaskIsOn] = React.useState(false);
  React.useEffect(() => {
    if (overlayMaskIsOn) {
      updateOverlayMask({type: 'threshold', threshold: overlayThreshold});
    } else {
      clearOverlayMask();
    }
  }, [clearOverlayMask, overlayMaskIsOn, overlayThreshold, updateOverlayMask]);

  const dataMin = dataRange[0];
  const dataMax = dataRange[1];

  return (
    <div className={cs.thresholdSlider}>
      <div className={cs.thresholdSliderContent}>
        <div style={{flex: '1'}}>
          <B.RangeSlider
            value={overlayThreshold}
            min={dataMin}
            max={dataMax}
            stepSize={0.01}
            onChange={(val) => setOverlayThreshold(val)}
          />
          <div className={cs.thresholdSliderLabels}>
            <div>{overlayThreshold[0].toFixed(2)}</div>
            <div>{overlayThreshold[1].toFixed(2)}</div>
          </div>
        </div>
        <OverlayMaskControls
          overlayMask={overlayMask}
          layerKey={layerKey}
          activeLayers={activeLayers}
          setMapLayerKey={setMapLayerKey}
          isCalculatingMask={isCalculatingMask}
          toggleOverlayMask={setOverlayMaskIsOn}
        />
      </div>
    </div>
  );
};

const OverlayMaskControls: React.FunctionComponent<{
  overlayMask: OverlayMaskState | null;
  layerKey: string;
  activeLayers: mapUtils.MapImageRefs;
  setMapLayerKey: (nextLayerKey: string) => void;
  isCalculatingMask: boolean;
  toggleOverlayMask: React.Dispatch<React.SetStateAction<boolean>>;
  disableClip?: boolean;
}> = ({
  overlayMask,
  layerKey,
  activeLayers,
  setMapLayerKey,
  isCalculatingMask,
  toggleOverlayMask,
  disableClip = false,
}) => {
  const overlayMaskApplied =
    overlayMask && activeLayers.map((iR) => iR.layerKey).includes(layerKey);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const polygonDispatch = useMapPolygonDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {polygonFeature} = useMapPolygonState();
  const pushNotification = usePushNotification();
  const [clipProcessing, setClipProcessing] = React.useState(false);
  const [hasClipped, setHasClipped] = React.useState(false);

  // Track previous geometry to support undo action
  const prevGeometryRef = React.useRef<PolygonFeature | undefined>();

  const handleRunWorker = (maskData: MaskData) => {
    prevGeometryRef.current = polygonFeature;
    clipWorkerRef.current?.postMessage(maskData);
    setClipProcessing(true);
  };
  const clipWorkerRef = React.useRef<Worker | null>(null);
  useEffect(() => {
    try {
      clipWorkerRef.current = new ClipWorker();
      if (!clipWorkerRef.current) {
        throw new Error('Worker initialization failed, no ref available');
      }
      clipWorkerRef.current.onmessage = (event) => {
        const clippedFeature = event.data;
        if (clippedFeature)
          polygonDispatch({
            type: 'setAnalyzeAreaDrawMode',
            analyzeAreaDrawMode: 'clipPolygon',
          });
        polygonDispatch({
          type: 'setPolygonFeature',
          polygonFeature: clippedFeature,
        });
        setClipProcessing(false);
        setHasClipped(true);
      };
      clipWorkerRef.current.onerror = (error) => {
        console.error('Worker error:', error);
        setClipProcessing(false);
        pushNotification({
          message: 'There was an error clipping the geometry.',
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
        // For now let's capture all the times the clipping fails. This
        // might get noisy and we can turn it off if it's too much. But
        // helpful for now to catch these cases
        Sentry.captureException(error);
      };
    } catch (error) {
      console.error('Worker initialization failed:', error);
      pushNotification({
        message: 'There was an error clipping the geometry.',
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.DANGER,
        },
      });
    }

    return () => {
      clipWorkerRef.current?.terminate();
    };
  }, [polygonDispatch, pushNotification]);

  const showOnMapIsActive =
    !!overlayMaskApplied && overlayMask.source === OverlayMaskSource.ANALYSIS;

  return (
    <div className={cs.mapControl}>
      <B.Tooltip content="Show on Map" position={B.Position.TOP}>
        <B.AnchorButton
          icon={'map'}
          active={showOnMapIsActive}
          onClick={() => {
            if (!showOnMapIsActive) {
              setMapLayerKey(layerKey);
              toggleOverlayMask(true);
            } else {
              toggleOverlayMask(false);
            }
          }}
          small
          minimal
        />
      </B.Tooltip>
      {hasClipped && prevGeometryRef.current ? (
        <B.Tooltip content="Undo clip" position={B.Position.TOP}>
          <B.AnchorButton
            small
            minimal
            icon={'undo'}
            onClick={() => {
              polygonDispatch({
                type: 'setPolygonFeature',
                polygonFeature: prevGeometryRef.current!,
              });
              setHasClipped(false);
            }}
          />
        </B.Tooltip>
      ) : (
        <B.Tooltip
          content={!overlayMaskApplied ? 'Show on map to clip geometry' : 'Clip geometry'}
          position={B.Position.TOP}
        >
          <B.AnchorButton
            icon={'cut'}
            disabled={!overlayMaskApplied || !overlayMask || disableClip}
            onClick={() => {
              if (!overlayMask?.masks?.[0] || !polygonFeature) return;
              handleRunWorker({
                ...overlayMask!.masks[0],
                analysisFeature: polygonFeature,
                layerKey,
              });
            }}
            small
            minimal
            loading={showOnMapIsActive && (clipProcessing || isCalculatingMask)}
          />
        </B.Tooltip>
      )}
    </div>
  );
};

const Controls: React.FunctionComponent<{
  graph: Graph;
  graphColumns: GraphColumn[] | null;
  graphDisplays: {key: string; display: string}[];
  imageRefs: mapUtils.MapImageRefs;
  setLayerKey: (key: string) => void;
  graphRange: GraphRange;
  setGraphRange: React.Dispatch<React.SetStateAction<GraphRange>>;
  graphMode: GraphMode;
  setGraphMode: (mode: GraphMode, preset: AreaModePreset | null) => void;
  clearLocalGraphData: () => void;
  extraGraphOptions: ExtraGraphOptions;
  setExtraGraphOptions: React.Dispatch<React.SetStateAction<ExtraGraphOptions>>;
  setGraphDataRangeMode: React.Dispatch<React.SetStateAction<GraphDataRangeMode>>;
  setOverlayThreshold: (threshold: [number, number]) => void;
  mapStateDispatch: MapStateDispatch;
  polygon: geojson.Polygon | geojson.MultiPolygon;
  displayEditableControls: boolean;
  isExpanded: boolean;
  layerVisibilityByKey: Record<string, {onlyUnorderedImageryAvailable: boolean}>;
  overlayMask: OverlayMaskState | null;
  clearOverlayMask: () => void;
  isCalculatingMask: boolean;
  updateOverlayMask: (overlayMaskFilter: OverlayMaskFilter) => void;
}> = ({
  graph,
  graphDisplays,
  imageRefs,
  setLayerKey,
  setGraphRange,
  setGraphMode,
  clearLocalGraphData,
  extraGraphOptions,
  setExtraGraphOptions,
  setGraphDataRangeMode,
  setOverlayThreshold,
  mapStateDispatch,
  polygon,
  displayEditableControls,
  isExpanded,
  layerVisibilityByKey,
  overlayMask,
  clearOverlayMask,
  isCalculatingMask,
  updateOverlayMask,
  graphColumns,
}) => {
  const {
    layerKey,
    graphMode,
    graphModePreset,
    graphRange,
    dataThreshold,
    dataPoints,
    layerDataRange,
  } = graph;
  const areaPresets = AREA_PRESETS[layerKey];
  const layerDisplay = layerUtils.getLayer(layerKey).display;

  const graphModes = getGraphModes(layerKey);

  const controlSelectClassname = classnames(cs.controlSelect, {
    [cs.expandedControlSelect]: isExpanded,
  });

  return (
    <div className={cs.controlsTable}>
      <div className={cs.controlsRow}>
        <div>Dataset</div>
        <div>
          <div className={controlSelectClassname}>
            <B.Popover
              minimal
              position={B.Position.BOTTOM_LEFT}
              hasBackdrop
              disabled={!displayEditableControls}
              content={
                <B.Menu className={cs.popoverSelect}>
                  {graphDisplays.map(({key, display}) => {
                    return (
                      <B.MenuItem
                        key={key}
                        disabled={layerVisibilityByKey[key].onlyUnorderedImageryAvailable}
                        text={
                          <B.Tooltip
                            position="top"
                            disabled={!layerVisibilityByKey[key].onlyUnorderedImageryAvailable}
                            content="Order data from the left pane"
                          >
                            <MenuItemText label={display} />
                          </B.Tooltip>
                        }
                        onClick={() => {
                          // It’s less confusing if when switching among layers we
                          // don’t preserve the threshold values, since they’re not
                          // transferrable.
                          //
                          // We also re-set graphMode w/o a preset to clear it so that
                          // it doesn’t show up as the label.

                          const layer = layerUtils.getLayer(key);
                          const defaultOverlayThreshold = defaultOverlayThresholdForDataRange(
                            getDataRange(layer)
                          );

                          setOverlayThreshold(defaultOverlayThreshold);
                          setGraphMode(graphMode, null);

                          setLayerKey(key);
                          clearLocalGraphData();
                        }}
                        icon={display === layerDisplay ? 'tick' : 'blank'}
                      />
                    );
                  })}
                </B.Menu>
              }
            >
              <B.AnchorButton
                outlined={true}
                small
                minimal
                rightIcon={displayEditableControls && 'caret-down'}
                text={layerDisplay}
                disabled={!displayEditableControls}
              />
            </B.Popover>
          </div>
        </div>
      </div>
      <div className={cs.controlsRow}>
        <div>Chart type</div>
        <div>
          <div className={controlSelectClassname}>
            <B.Popover
              minimal
              position={B.Position.BOTTOM_LEFT}
              hasBackdrop
              disabled={!displayEditableControls}
              content={
                <B.Menu>
                  {graphModes.map((m: GraphMode) =>
                    m === 'area' && areaPresets ? (
                      <React.Fragment key={m}>
                        <B.MenuDivider title={PRESET_GROUP_LABEL[m]} />
                        {areaPresets.map((p) => (
                          <B.MenuItem
                            key={p.label}
                            text={p.label}
                            onClick={() => {
                              setGraphMode(m, p);
                              setOverlayThreshold([p.min, p.max]);
                              clearLocalGraphData();
                            }}
                            icon={
                              m === graphMode &&
                              p.label === (graphModePreset && graphModePreset.label)
                                ? 'tick'
                                : 'blank'
                            }
                          />
                        ))}
                        <B.MenuItem
                          text="Custom range…"
                          onClick={() => {
                            setGraphMode(m, null);
                            clearLocalGraphData();
                          }}
                          icon={m === graphMode && graphModePreset === null ? 'tick' : 'blank'}
                        />
                      </React.Fragment>
                    ) : (
                      <B.MenuItem
                        key={m}
                        text={GRAPH_MODE_LABELS[m]}
                        disabled={m === 'averageMonthly' && graphRange.type === 'by-year'}
                        onClick={() => {
                          setGraphMode(m, null);
                          clearLocalGraphData();
                        }}
                        icon={m === graphMode ? 'tick' : 'blank'}
                      />
                    )
                  )}
                </B.Menu>
              }
            >
              <B.AnchorButton
                outlined={true}
                small
                minimal
                rightIcon={displayEditableControls && 'caret-down'}
                text={(graphModePreset && graphModePreset.label) || GRAPH_MODE_LABELS[graphMode]}
                disabled={!displayEditableControls}
              />
            </B.Popover>
          </div>

          {graphMode === 'area' &&
            graphModePreset === null &&
            !!dataThreshold &&
            !!layerDataRange && (
              <ThresholdSlider
                layerKey={layerKey}
                activeLayers={imageRefs}
                dataRange={layerDataRange}
                overlayThreshold={dataThreshold}
                setOverlayThreshold={setOverlayThreshold}
                setMapLayerKey={(layerKey) => mapStateDispatch({type: 'SET_LAYER', layerKey})}
                overlayMask={overlayMask}
                clearOverlayMask={clearOverlayMask}
                isCalculatingMask={isCalculatingMask}
                updateOverlayMask={updateOverlayMask}
              />
            )}
          {graphMode === 'areaByCategory' && graphColumns && (
            <SelectCategories
              graphColumns={graphColumns}
              updateOverlayMask={updateOverlayMask}
              overlayMask={overlayMask}
              clearOverlayMask={clearOverlayMask}
              layerKey={layerKey}
              activeLayers={imageRefs}
              setMapLayerKey={(layerKey) => mapStateDispatch({type: 'SET_LAYER', layerKey})}
              isCalculatingMask={isCalculatingMask}
            />
          )}
        </div>
      </div>
      <div className={cs.controlsRow}>
        <div>Time range</div>
        <div>
          <div className={controlSelectClassname}>
            {graphRange.type === 'some-years' ? (
              <SomeYearsGraphRangeMenu
                dataPoints={dataPoints}
                graphRange={graphRange}
                setGraphRange={(range) => {
                  setGraphRange(range);
                  clearLocalGraphData();
                }}
                layerKey={layerKey}
                displayEditableControls={displayEditableControls}
              />
            ) : (
              <GraphRangeMenu
                graphRange={graphRange}
                setGraphRange={(range) => {
                  setGraphRange(range);
                  clearLocalGraphData();
                }}
                displayEditableControls={displayEditableControls}
                layerKey={layerKey}
                graphMode={graphMode}
                disabledOptions={[graphMode === 'averageMonthly' ? 'by-year' : null]}
              />
            )}
          </div>
          {graphRange.type === 'custom' && (
            <CustomGraphTimeRangeSelector
              graphRange={graphRange}
              setGraphRange={(range) => {
                setGraphRange(range);
                clearLocalGraphData();
              }}
              polygon={polygon}
              displayEditableControls={displayEditableControls}
            />
          )}
          <B.FormGroup>
            {graphRange.type === 'by-year' && (
              <SelectCumulative
                extraGraphOptions={extraGraphOptions}
                setExtraGraphOptions={setExtraGraphOptions}
                setGraphDataRangeMode={setGraphDataRangeMode}
                isEditable={displayEditableControls}
              />
            )}
          </B.FormGroup>
        </div>
      </div>
    </div>
  );
};

const SelectCumulative: React.FunctionComponent<{
  extraGraphOptions: ExtraGraphOptions;
  setExtraGraphOptions: React.Dispatch<React.SetStateAction<ExtraGraphOptions>>;
  setGraphDataRangeMode: React.Dispatch<React.SetStateAction<GraphDataRangeMode>>;
  isEditable: boolean;
}> = ({extraGraphOptions, setExtraGraphOptions, setGraphDataRangeMode, isEditable}) => {
  const {showCumulativeValues} = extraGraphOptions;
  return (
    <B.Checkbox
      inline
      checked={showCumulativeValues}
      disabled={!isEditable}
      alignIndicator="right"
      label={'Cumulative values'}
      onChange={() => {
        setExtraGraphOptions((previous) => ({
          ...previous,
          showCumulativeValues: !previous.showCumulativeValues,
        }));
        setGraphDataRangeMode((previous) => (previous === 'cumulative' ? 'none' : 'cumulative'));
      }}
    />
  );
};

const SelectCategories: React.FunctionComponent<{
  graphColumns: GraphColumn[];
  updateOverlayMask: (overlayMaskFilter: OverlayMaskFilter) => void;
  overlayMask: OverlayMaskState | null;
  clearOverlayMask: () => void;
  layerKey: string;
  activeLayers: mapUtils.MapImageRefs;
  setMapLayerKey: (nextLayerKey: string) => void;
  isCalculatingMask: boolean;
}> = ({
  graphColumns,
  updateOverlayMask,
  overlayMask,
  clearOverlayMask,
  layerKey,
  activeLayers,
  setMapLayerKey,
  isCalculatingMask,
}) => {
  // Make local copy of the graph columns, ignoring whatever selection state they have
  const [categoryGraphColumns, setCategoryGraphColumns] = React.useState<GraphColumn[]>(
    graphColumns.map((c) => ({
      ...c,
      isSelected: true,
    }))
  );

  const [overlayMaskIsOn, setOverlayMaskIsOn] = React.useState(false);
  React.useEffect(() => {
    if (overlayMaskIsOn) {
      updateOverlayMask({
        type: 'category',
        categories: new Set(
          categoryGraphColumns.filter((c) => c.isSelected).map((c) => c.category!)
        ),
      });
    } else {
      clearOverlayMask();
    }
  }, [categoryGraphColumns, clearOverlayMask, overlayMaskIsOn, updateOverlayMask]);

  const selectedCategoriesLength = categoryGraphColumns.filter((c) => c.isSelected).length;
  return (
    <div className={cs.selectCategories}>
      <B.Popover
        minimal
        position={B.Position.BOTTOM_LEFT}
        hasBackdrop
        content={
          <B.Menu>
            <B.MenuItem
              text="All"
              icon={selectedCategoriesLength === categoryGraphColumns.length ? 'tick' : 'blank'}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setCategoryGraphColumns(
                  categoryGraphColumns.map((column) => ({...column, isSelected: true}))
                );
              }}
            />

            <B.MenuItem
              text="None"
              icon={!selectedCategoriesLength ? 'tick' : 'blank'}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setCategoryGraphColumns(
                  categoryGraphColumns.map((column) => ({...column, isSelected: false}))
                );
              }}
            />

            <B.MenuDivider />

            {categoryGraphColumns.map(({name, isSelected}) => (
              <B.MenuItem
                key={name}
                text={name}
                icon={isSelected ? 'tick' : 'blank'}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setCategoryGraphColumns(
                    categoryGraphColumns.map((column) =>
                      column.name === name ? {...column, isSelected: !column.isSelected} : column
                    )
                  );
                }}
              />
            ))}
          </B.Menu>
        }
      >
        <B.AnchorButton
          outlined
          small
          minimal
          rightIcon={'caret-down'}
          text={`Categories: ${selectedCategoriesLength === categoryGraphColumns.length ? 'All' : selectedCategoriesLength}`}
          fill
        />
      </B.Popover>
      <OverlayMaskControls
        overlayMask={overlayMask}
        layerKey={layerKey}
        activeLayers={activeLayers}
        setMapLayerKey={setMapLayerKey}
        isCalculatingMask={isCalculatingMask}
        disableClip={!selectedCategoriesLength}
        toggleOverlayMask={setOverlayMaskIsOn}
      />
    </div>
  );
};

const SomeYearsGraphRangeMenu: React.FunctionComponent<
  React.PropsWithChildren<{
    dataPoints: GraphPoint[];
    graphRange: SomeYearsGraphRange;
    setGraphRange: React.Dispatch<React.SetStateAction<GraphRange>>;
    layerKey: string;
    displayEditableControls: boolean;
  }>
> = ({dataPoints, graphRange, setGraphRange, layerKey, displayEditableControls}) => {
  const prevDataPointsRef = React.useRef<GraphPoint[]>();

  const options = graphRange.options;
  const hasOptions = !!options.length;
  const hasMultipleOptions = options.length > 1;

  /** Once our data points have loaded, pre-populate the graph range options, if
   * they do not exist, with every year in the dataset. */
  React.useEffect(() => {
    if (!prevDataPointsRef.current?.length && dataPoints.length) {
      if (!hasOptions) {
        setGraphRange({
          type: 'some-years',
          options: uniq(
            dataPoints.map(([date]) =>
              layerUtils.conditionallyAdjustLayerDate(date, layerKey).toDate().getFullYear()
            )
          )
            .sort((dateA, dateB) => alphanumericSort(dateA, dateB))
            .map((year) => ({label: year.toString(), value: year, isSelected: true})),
        });
      }
    }

    prevDataPointsRef.current = dataPoints;
  }, [dataPoints, hasOptions, setGraphRange, layerKey]);

  return (
    <B.Popover
      minimal
      position={B.Position.BOTTOM_LEFT}
      hasBackdrop
      disabled={!displayEditableControls || !hasOptions}
      content={
        <B.Menu>
          {hasMultipleOptions && (
            <React.Fragment>
              <B.MenuItem
                text="All"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setGraphRange({
                    type: 'some-years',
                    options: options.map((option) => ({...option, isSelected: true})),
                  });
                }}
              />

              <B.MenuItem
                text="None"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setGraphRange({
                    type: 'some-years',
                    options: options.map((option) => ({...option, isSelected: false})),
                  });
                }}
              />

              <B.MenuDivider />
            </React.Fragment>
          )}

          {options.map(({label, value, isSelected}) => (
            <B.MenuItem
              key={value}
              text={label}
              disabled={!hasMultipleOptions}
              icon={isSelected ? 'tick' : 'blank'}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setGraphRange({
                  type: 'some-years',
                  options: options.map((option) =>
                    option.value === value ? {...option, isSelected: !option.isSelected} : option
                  ),
                });
              }}
            />
          ))}
        </B.Menu>
      }
    >
      <B.AnchorButton
        outlined={true}
        small
        minimal
        rightIcon={displayEditableControls && 'caret-down'}
        disabled={!displayEditableControls || !hasOptions}
        text={labelForGraphRange(graphRange)}
      />
    </B.Popover>
  );
};

const GraphRangeMenu: React.FunctionComponent<
  React.PropsWithChildren<{
    graphRange: SimpleGraphRange | ByYearGraphRange | CustomGraphRange;
    setGraphRange: React.Dispatch<React.SetStateAction<GraphRange>>;
    displayEditableControls: boolean;
    layerKey: string;
    graphMode: GraphMode;
    disabledOptions: (GraphRange['type'] | null)[];
  }>
> = ({
  graphRange,
  setGraphRange,
  displayEditableControls,
  layerKey,
  graphMode,
  disabledOptions,
}) => {
  const availableGraphRanges = getGraphRanges(layerKey, graphMode);
  return (
    <B.Popover
      minimal
      position={B.Position.BOTTOM_LEFT}
      hasBackdrop
      disabled={!displayEditableControls}
      content={
        <B.Menu>
          {availableGraphRanges
            .filter((gr) => gr.type === 'simple')
            .map((gr) => {
              const {label} = gr as SimpleGraphRange;
              return (
                <B.MenuItem
                  key={label}
                  text={label}
                  onClick={() => setGraphRange(gr)}
                  disabled={disabledOptions.includes(gr.type)}
                  icon={
                    graphRange.type === 'simple' && graphRange.label === label ? 'tick' : 'blank'
                  }
                />
              );
            })}
          {availableGraphRanges.some((gr) => gr.type === 'custom') && (
            <B.MenuItem
              text={labelForGraphRange({
                type: 'custom',
                range: [null, null],
              })}
              onClick={() =>
                setGraphRange({
                  type: 'custom',
                  // Default to 1 year ago to today
                  range: [moment().subtract(1, 'year').toDate(), new Date()],
                })
              }
              disabled={disabledOptions.includes('custom')}
              icon={graphRange.type === 'custom' ? 'tick' : 'blank'}
            />
          )}
          {availableGraphRanges.some((gr) => gr.type.includes('by-year')) && (
            <>
              <B.MenuDivider />

              <B.MenuItem
                text={labelForGraphRange({type: 'by-year', yearType: 'calendar'})}
                onClick={() => setGraphRange({type: 'by-year', yearType: 'calendar'})}
                disabled={disabledOptions.includes('by-year')}
                icon={
                  graphRange.type === 'by-year' && graphRange.yearType == 'calendar'
                    ? 'tick'
                    : 'blank'
                }
              />
              <B.MenuItem
                text={labelForGraphRange({type: 'by-year', yearType: 'usgs-water'})}
                onClick={() => setGraphRange({type: 'by-year', yearType: 'usgs-water'})}
                disabled={disabledOptions.includes('by-year')}
                icon={
                  graphRange.type === 'by-year' && graphRange.yearType == 'usgs-water'
                    ? 'tick'
                    : 'blank'
                }
              />
            </>
          )}
        </B.Menu>
      }
    >
      <B.AnchorButton
        outlined={true}
        small
        minimal
        rightIcon={displayEditableControls && 'caret-down'}
        text={labelForGraphRange(graphRange)}
        disabled={!displayEditableControls}
      />
    </B.Popover>
  );
};

const CustomGraphTimeRangeSelector: React.FunctionComponent<
  React.PropsWithChildren<{
    graphRange: CustomGraphRange;
    setGraphRange: React.Dispatch<React.SetStateAction<GraphRange>>;
    polygon: geojson.Polygon | geojson.MultiPolygon;
    displayEditableControls: boolean;
  }>
> = ({graphRange, setGraphRange, polygon, displayEditableControls}) => {
  const shortcuts: DateRangeShortcut[] = React.useMemo(() => {
    const today = new Date();
    const currYear = today.getFullYear();
    const yearStart = moment(`${currYear}-01-01`);

    // Get summer dates based on hemisphere
    const isInNorthernHemisphere = geoJsonUtils.isFullyInNorthernHemisphere(polygon);
    const summerStartDate = isInNorthernHemisphere ? `${currYear}-06-21` : `${currYear - 1}-12-21`;
    const summerEndDate = isInNorthernHemisphere ? `${currYear}-09-23` : `${currYear}-03-23`;

    // Get the prior water year - not the one we are currently in
    const endWaterYear = moment(`${currYear}-09-30`);
    if (endWaterYear.isAfter(today)) {
      endWaterYear.subtract(1, 'years');
    }
    const startWaterYear = endWaterYear.clone().subtract(1, 'years').add(1, 'days');

    const startOfSummer = moment(summerStartDate);
    const endOfSummer = moment(summerEndDate);
    // Get the prior summer for the prior year if summer has not finished
    // this year yet
    if (endOfSummer.isAfter(today)) {
      endOfSummer.subtract(1, 'years');
      startOfSummer.subtract(1, 'years');
    }

    const shortcutEls: DateRangeShortcut[] = [
      {dateRange: [yearStart.toDate(), today], label: 'Year to date'},
      {
        dateRange: [moment().subtract(6, 'month').toDate(), today],
        label: 'Past 6 months',
      },
      {dateRange: [startWaterYear.toDate(), endWaterYear.toDate()], label: 'Past USGS water year'},
      {dateRange: [startOfSummer.toDate(), endOfSummer.toDate()], label: 'Past summer'},
    ];

    return shortcutEls;
  }, [polygon]);

  return (
    <div className={cs.dateRangeInput}>
      <DateRangeInput3
        // TODO (maya): maybe set a min or max date here. I tried doing this
        // but it made some of the shortcuts more complicated - for example if
        // we set the maxDate as today and you clicked "water year" and
        // it only showed the past few months you may be confused
        // why it's not a full year.
        shortcuts={shortcuts}
        singleMonthOnly={true}
        value={graphRange.range}
        onChange={([startInput, endInput]) => {
          setGraphRange((gr) => ({...gr, range: [startInput, endInput]}));
        }}
        formatDate={(date) => moment(date).format('L')}
        parseDate={(str) => moment(str, 'L').toDate()}
        popoverProps={{
          // To get this appear below the input top is required. Not sure why as
          // logically I think it should be 'bottom'
          position: 'top',
          // Allows popper.js to position the popover outside of the
          // dialog’s bounds
          modifiers: {preventOverflow: {enabled: false}, hide: {enabled: false}},
        }}
        disabled={!displayEditableControls}
      />
    </div>
  );
};

/**
 * Component for rendering area and location of a polygon.
 *
 * Broken out as a component so we can put a single conditional on the existence
 * of a polygon.
 */
const AnalyzePolygonStats: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    areaInM2: number;
    polygon: geojson.Polygon | geojson.MultiPolygon;
  }>
> = ({organization, areaInM2, polygon}) => {
  const center: geojson.Feature<geojson.Point> = React.useMemo(() => centroid(polygon), [polygon]);
  const areaUnit = getAreaUnit(organization);

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <B.Icon icon={'geolocation'} style={{marginRight: '0.5rem'}} size={14} />
      {
        mathUtils.formatMeasurement({
          value: areaInM2,
          unit: CONSTANTS.UNIT_AREA_M2,
          unitTo: areaUnit,
        }).valueWithUnit
      }
      {` (${center.geometry.coordinates[1].toFixed(5)}, ${center.geometry.coordinates[0].toFixed(
        5
      )})`}
    </div>
  );
};

export default React.forwardRef(AnalyzePolygonPopup);

/**
 * Generates a default threshold to match the given data range. We want to not
 * default to 100% because then it’s hard to tell what the purpose of Area in
 * Range is (since 100% of area is in the vmin–vmax range).
 */
export function defaultOverlayThresholdForDataRange(layerDataRange: DataRange): [number, number] {
  const dataMin = layerDataRange[0];
  const dataMax = layerDataRange[1];

  return [dataMin * 0.6 + dataMax * 0.4, dataMax];
}

function labelForGraphRange(graphRange: GraphRange): React.ReactNode {
  let label: string = 'simple';

  switch (graphRange.type) {
    case 'simple':
      label = graphRange.label;
      break;
    case 'by-year':
      switch (graphRange.yearType) {
        case 'calendar':
          label = 'Year over year (calendar)';
          break;
        case 'usgs-water':
          label = 'Year over year (USGS water)';
          break;
        default:
          label = 'Year over year';
      }
      break;
    case 'custom':
      label = 'Custom';
      break;
    case 'some-years': {
      const yearsCount = graphRange.options.length;
      const selectedYears = graphRange.options.filter(({isSelected}) => !!isSelected);
      const selectedYearsCount = selectedYears.length;

      /** TODO: Add unit test to document variations. */
      label =
        selectedYearsCount === 0
          ? 'None'
          : selectedYearsCount === 1
            ? selectedYears[0].label
            : selectedYearsCount === yearsCount
              ? 'All'
              : 'Multiple';
      break;
    }
  }

  return <MenuItemText label={label} />;
}
/**
 * For use with Space Intelligence & Salo 30m carbon layers.
 * Assumes that the data is some value per hectare, e.g. MgC/ha
 */
export function calculateAggregatePerHectare(
  mean: number,
  areaInM2: number,
  missingPixels: number,
  pixelsWithValue: number
) {
  const areaInHa = areaInM2 * 0.0001;
  const pixelsWithValuePercent = pixelsWithValue / (pixelsWithValue + missingPixels);
  return mean * areaInHa * pixelsWithValuePercent;
}

/**
 * For landuse layers, parse information about each category present in the
 * raster graph data and what portion of the polygon area it represents.
 */
export function calculateAreaByCategory(
  layerKey: string,
  pixelsWithValueByValue: RasterCalculationResult['weightedPixelsWithValueByValue'],
  totalPixelsWithValue: RasterCalculationResult['weightedPixelsWithValue']
): {categoryValue: string; categoryLabel: string | undefined; percentArea: number}[] {
  const valueMap = getLanduseLayerValueMap(layerKey);
  return Object.entries(pixelsWithValueByValue).map(([value, pixelsWithValue]) => ({
    categoryValue: value,
    categoryLabel: valueMap?.[value]?.label,
    percentArea: pixelsWithValue / totalPixelsWithValue,
  }));
}
