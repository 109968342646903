import * as I from 'immutable';

import * as C from 'app/utils/constants';
import {DEFAULT_STYLE} from 'app/utils/mapUtils';

import {ApiOrganization} from './types';

export * from './types';

/**
 * Returns the Mapbox style set as a by default the organization.
 * Defaults to our internal default
 */
export function getOrgBasemapStyle(org: I.ImmutableOf<ApiOrganization>) {
  return org.getIn(['settings', C.ORG_SETTING_DEFAULT_BASEMAP]) ?? DEFAULT_STYLE;
}

/**
 * Returns the unit to use when displaying areas for this organization, based on
 * the org’s settings. Defaults to acres.
 */
export function getAreaUnit(org: I.ImmutableOf<ApiOrganization>) {
  return org.getIn(['settings', C.ORG_SETTING_DISPLAY_AREA_UNIT]) ?? C.UNIT_AREA_ACRE;
}

/**
 * Returns whether an organization should display Smart Summaries in reports or not.
 */
export function getShowSmartSummaries(org: I.ImmutableOf<ApiOrganization>) {
  return org.getIn(['settings', C.ORG_SETTING_SHOW_SMART_SUMMARIES]) ?? true;
}

/**
 * Returns the org’s measurement system, using the area unit display as a proxy.
 * The measurement system types intentionally match the unit supported by
 * Mapbox’s map scale control, which unfortunately is only typed as a string.
 * Defaults to imperial.
 */
export type MeasurementSystem = 'imperial' | 'metric';
export function getMeasurementSystem(org: I.ImmutableOf<ApiOrganization>): MeasurementSystem {
  const areaUnit = getAreaUnit(org);
  return areaUnit === 'areaHectare' ? 'metric' : 'imperial';
}
