import * as B from '@blueprintjs/core';
import React from 'react';

import * as C from 'app/utils/constants';

import cs from './styles.styl';

/**
 * Generic-ish component to upload a geometry file. Used in
 * property additions and edits
 *
 * Displays loading state and errors in a callout below the file input
 */
export const GeometryFileUploader: React.FunctionComponent<
  React.PropsWithChildren<{
    isConvertingFile: boolean;
    conversionErrors: string[] | null;
    filename: string | null;
    onFilenameChange: (file: File) => void;
    hasSelection: boolean;
    supportDocUrl: string;
    title: string;
  }>
> = ({
  isConvertingFile,
  conversionErrors,
  filename,
  onFilenameChange,
  hasSelection,
  supportDocUrl,
  title,
}) => {
  const findSupportLink = (errorMessage) => {
    return (
      Object.values(C.UPLOAD_ERROR_CONSTANTS).find((c) => c.text.includes(errorMessage)) ?? {
        label: 'support',
        supportUrl: supportDocUrl,
      }
    );
  };
  return (
    <div>
      <h3>{title}</h3>
      <B.FileInput
        className={cs.fileInput}
        text={filename || 'Choose file (.zip, .kml, .kmz or .geojson)…'}
        hasSelection={hasSelection}
        disabled={isConvertingFile}
        inputProps={{accept: '.zip,.kml,.kmz,.geojson'}}
        onInputChange={(event) => {
          const target = event.currentTarget;
          const file = (target.files as FileList)[0];

          if (!file) {
            return;
          }

          // Setting the file name before validating the file type so that
          // the user can see the file name with the error, if applicable.
          onFilenameChange(file);

          // Resetting the input value to null so that clicking on the same file again will still
          // trigger an onChange event. This is necessary to allow people to
          // retry their fileConversion on failure
          event.currentTarget.value = '';
        }}
      />
      {isConvertingFile && (
        <B.Callout className={cs.callout} intent={'primary'} icon={null}>
          <div className={cs.loading}>
            <B.Spinner size={16} /> Converting file…
          </div>
        </B.Callout>
      )}
      {conversionErrors && (
        <B.Callout className={cs.callout} intent={'danger'} icon={null}>
          <div className={cs.conversionProblems}>
            {conversionErrors.length > 1 ? (
              <>
                <b>Errors</b>
                <ul>
                  {conversionErrors.map((errorMessage, idx) => {
                    const supportLink = findSupportLink(errorMessage);
                    return (
                      <li key={idx}>
                        {errorMessage}
                        {' - '}
                        <a target="_blank" rel="noopener noreferrer" href={supportLink.supportUrl}>
                          {supportLink.label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <b>
                Error: {conversionErrors}{' '}
                <div className={cs.problemsHelpText}>
                  See our{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={findSupportLink(conversionErrors[0]).supportUrl}
                  >
                    {findSupportLink(conversionErrors[0])!.label}
                  </a>{' '}
                  article for more information or reach out at{' '}
                  <a href="mailto:lens@upstream.tech">lens@upstream.tech</a>
                </div>
              </b>
            )}
          </div>
        </B.Callout>
      )}
    </div>
  );
};
