import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import AppNav from 'app/components/AppNav';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import featureFlags from 'app/utils/featureFlags';
import * as routeUtils from 'app/utils/routeUtils';

import cs from './styles.styl';

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
  projectId: string;
}

const ManageFeaturesSelectMode: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
  projectId,
}) => {
  const allowParcelSelect = featureFlags.PARCEL_DATA_IN_UPLOADER(organization, profile);
  const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));
  //
  const cameFromPortfolioView = history.location.pathname.split('/').includes('select');
  return (
    <div>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      <div className={cs.content}>
        <div className={cs.selectMode}>
          <h1>Let's get started</h1>
          <h3>How would you like to add properties?</h3>
          <div className={cs.selectModeOptions}>
            <B.Button
              minimal
              onClick={() =>
                history.push(`/${orgIdPrefix}/admin/manageProperties/draw/${projectId}`)
              }
            >
              <div className={cs.selectModeOption}>
                <B.Icon icon="new-drawing" size={32} />
                <h4>Draw on the map</h4>
              </div>
            </B.Button>

            <B.Button
              minimal
              onClick={() =>
                history.push(`/${orgIdPrefix}/admin/manageProperties/upload/${projectId}`)
              }
            >
              <div className={cs.selectModeOption}>
                <B.Icon icon="cloud-upload" size={32} />
                <h4>Upload from a file</h4>
              </div>
            </B.Button>
            {allowParcelSelect && (
              <B.Button
                minimal
                onClick={() =>
                  history.push(`/${orgIdPrefix}/admin/manageProperties/parcel/${projectId}`)
                }
              >
                <div className={cs.selectModeOption}>
                  <B.Icon icon="select" size={32} />
                  <h4>{`Select US tax parcels`}</h4>
                </div>
              </B.Button>
            )}
          </div>
          <div className={cs.goBack}>
            or{' '}
            <a onClick={() => history.goBack()}>
              go back to {cameFromPortfolioView ? 'your portfolio' : 'your homepage'}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageFeaturesSelectMode;
