import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import I from 'immutable';
import React from 'react';

import DateSelectCard from 'app/components/DateSelectCard';
import {ApiFeature, ApiFeatureData} from 'app/modules/Remote/Feature';
import {HydratedFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import * as featureUtils from 'app/utils/featureUtils';
import {SORT_DESC} from 'app/utils/frontendConstants';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';
import MODES from 'app/utils/mapUtils/modes';

import {THUMBNAIL_BLOCKED_SOURCES} from '../OrderImageryModal';
import cs from './styles.styl';

const ITEM_HEIGHT = 60;
const ITEM_MARGIN_BOTTOM = 6;

const HIGH_RES_TRUECOLOR_BUCKETS = ['All', '≤1.5m', '1.6m-5m'] as const;

export interface Props {
  imageRefLayerKey: string;
  className?: string;
  selectedDate: string;
  selectedLayer: string;
  featureCollection: HydratedFeatureCollection | undefined;
  featureData: I.ImmutableOf<ApiFeatureData[]>;
  imageRefs: mapUtils.MapImageRefs;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  mode: string;
  onChange: (newImageRefs: mapUtils.MapImageRefs) => void;
  feature: I.ImmutableOf<ApiFeature>;
  blockedSources?: string[];
  firebaseToken: string | null;
}

const DateSelect: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  imageRefLayerKey,
  className = '',
  selectedDate,
  selectedLayer,
  featureCollection,
  featureData,
  imageRefs,
  organization,
  mode,
  onChange,
  feature,
  blockedSources = THUMBNAIL_BLOCKED_SOURCES,
  firebaseToken,
}) => {
  const listRef = React.useRef<HTMLDivElement | null>(null);

  const [selectedHighResTruecolorResolutionRange, setSelectedHighResTrueColorResolutionRange] =
    React.useState<(typeof HIGH_RES_TRUECOLOR_BUCKETS)[number]>('All');

  const renderCompareIndicator = (
    isComparedCursor: boolean,
    isActive: boolean,
    isCursorStart: boolean
  ) => {
    if (isComparedCursor) {
      return <div className={cs.compareIndicator}>{isCursorStart ? 'End' : 'Start'}</div>;
    } else if (isActive) {
      return <div className={cs.compareIndicator}>{isCursorStart ? 'Start' : 'End'}</div>;
    } else {
      return null;
    }
  };

  const scrollToSelectedCursor = React.useCallback(() => {
    const {current: listElem} = listRef;

    if (!listElem) {
      return;
    }

    // We want to find the specific <div> because the year dividers prevent us
    // from calculating where it should be based on indexes and heights.
    const selectedItem = listElem.querySelector(`[data-date="${selectedDate}"]`) as HTMLElement;

    if (!selectedItem) {
      return;
    }

    const selectedItemTop = selectedItem.offsetTop;
    const selectedItemBottom = selectedItemTop + selectedItem.clientHeight;

    const visibleTop = listElem.scrollTop;
    const visibleBottom = visibleTop + listElem.clientHeight;

    if (selectedItemBottom < visibleTop || selectedItemTop > visibleBottom) {
      // This case handles the element not being visible at all, so we scroll it
      // to the center.
      listElem.scrollTop = Math.max(
        selectedItemBottom - listElem.clientHeight / 2 - ITEM_HEIGHT / 2,
        0
      );
    } else if (selectedItemTop <= visibleTop) {
      // The item is partially obscured off the top. Scroll to put it into view
      // fully at the top.
      listElem.scrollTop = selectedItemTop - ITEM_MARGIN_BOTTOM;
    } else if (selectedItemBottom >= visibleBottom) {
      // Item is partially obscured off the bottom. Scroll so that its bottom is
      // at the bottom of the scroll container.
      listElem.scrollTop = selectedItemBottom - listElem.clientHeight;
    }
  }, [listRef, selectedDate]);

  React.useEffect(() => {
    scrollToSelectedCursor();
  }, [scrollToSelectedCursor, selectedDate]);

  const getFilteredFeatureData = () => {
    return I.List<I.MapAsRecord<I.ImmutableFields<ApiFeatureData>>>(
      featureUtils.filterFeatureData(featureData, imageRefLayerKey, SORT_DESC)
    );
  };

  if (!featureCollection || !featureData) {
    return <></>;
  }

  const isCompareMode = mode === MODES.COMPARE;

  const filteredFeatureData = getFilteredFeatureData();
  if (!filteredFeatureData.size) {
    return (
      <div className={cs.emptyState}>
        <p>No data available</p>
      </div>
    );
  }

  const paidImagery = featureUtils.getPaidImagery(feature);
  const scenes = paidImagery?.get('scenes');

  let lastYear: number;
  const children: React.ReactElement[] = [];

  filteredFeatureData.forEach((d, i) => {
    const dateString = d!.get('date');

    const layerKey = layerUtils.isLayerKeyHighResTruecolor(imageRefLayerKey)
      ? // regardless of the “active” layer key we want to show whatever the right
        // high-res layer is for the particular FeatureData.
        featureUtils.findBestHighResTruecolorLayerKey(d!) || imageRefLayerKey
      : imageRefLayerKey;

    // we need to know if we are in high-res-truecolor mode so we know whether to filter out sources
    // based on layerKey or not
    const hiResTrueColorMode = layerUtils.isLayerKeyHighResTruecolor(layerKey);
    const layer = layerUtils.parseLayerKey(layerKey).layerId;

    //identify all high-res-truecolor types available in our featureDatum
    const availableTruecolorTypes = d!
      .get('types')
      .filter((type) => layerUtils.isLayerKeyHighResTruecolor(type!));

    //if we are looking at high-res-truecolor imagery, we want to filter our available imagery sources
    //down to anything we have available as a high-res-truecolor type.
    //otherwise, we want to whittle our available imagery sources down to ONLY sources that match the
    //layerKey.
    //this is so that when we're in high-res-truecolor mode, we can get back multiple high-res-truecolor
    //sources back (eg, NAIP and Planet Basemap), but if we're looking
    //at another type of imagery, we only pull back the sources associated with the imageRefLayerKey
    const filteredSources = d!
      .get('sources')
      .filter((source) => {
        // This might be different than the layerKey passed into the component in the
        // highResTruecolor case since we show all layers of that type here. Create a layer
        // key for the specific source to ensure we get correct sourceDetails
        const layerKeyForSource = layerUtils.getLayerKeyFromSourceAndLayer(source!, layer);
        if (hiResTrueColorMode) {
          const details = featureUtils.imageAndSourceDetails(d!, layerKeyForSource, organization);
          const sourceIsInResolution = (() => {
            switch (selectedHighResTruecolorResolutionRange) {
              case 'All':
                return true;
              case '≤1.5m':
                return details.sourceDetails.resolution <= 1.5;
              case '1.6m-5m':
                return (
                  1.5 < details.sourceDetails.resolution && details.sourceDetails.resolution <= 5
                );
            }
          })();

          return (
            availableTruecolorTypes.toJS().find((t) => t.startsWith(source)) && sourceIsInResolution
          );
        } else {
          return layerKey.startsWith(source!);
        }
      })
      .sort((sourceA, sourceB) => featureUtils.alphanumericSort(sourceA, sourceB)); //ensure our filtered source strings are sorted alphabetically

    // Date select cards: we want one card per allowed imagery source on the featureDatum
    filteredSources.forEach((source) => {
      // This might be different than the layerKey passed into the component in the
      // highResTruecolor case since we show all layers of that type here. Create a layer
      // key for the specific source to ensure we get correct sourceDetails
      const layerKeyForSource = layerUtils.getLayerKeyFromSourceAndLayer(source!, layer);
      //for each source, return a featureDatum with URLS and metadata pruned to only
      //include data relevant for that source
      const prunedFeatureDatum = featureUtils.pruneFeatureDatumBySourceAndLayerKey(
        d!,
        source!,
        layerKeyForSource
      );

      let rightCardTag: null | React.ReactElement = null;
      const orderImageryState = featureUtils.getOrderImageryState(
        scenes,
        prunedFeatureDatum,
        layerKeyForSource
      );

      if (orderImageryState === featureUtils.ORDER_IMAGERY_STATE_AVAILABLE) {
        return null;
      } else if (orderImageryState === featureUtils.ORDER_IMAGERY_STATE_PROCESSING) {
        rightCardTag = <B.Tag minimal>Processing</B.Tag>;
      } else if (!d!.getIn(['measurements', `${layerKeyForSource}_is-fully-ordered`], true)) {
        rightCardTag = (
          <B.Tag minimal intent="warning">
            Partial
          </B.Tag>
        );
      }

      //check whether we are in the Start Date or End Date menu: imageRefs[0] is start menu
      //and imageRefs[1] is end menu, since imageRefs are automatically sorted by cursor date
      //in the provider.
      const isCursorStart =
        imageRefs[0].cursor === selectedDate && imageRefs[0].layerKey === selectedLayer;

      //"isActive" identifies the selected/active DateSelect card, chosen in imageRef[0]
      //for the Start Date menu and imageRefs[1] for the End Date menu.
      const isActive = isCursorStart
        ? imageRefs[0].layerKey.startsWith(source!) && selectedDate === dateString
        : imageRefs.length > 1 &&
          imageRefs[1]!.layerKey.startsWith(source!) &&
          selectedDate === dateString;

      //"isComparedCursor" identifies the active DateSelect card for the opposite menu. ie,
      //when we're looking at the Start Date menu, "isComparedCursor" identifies the card
      //identified in imageRef[1] that is active in the End Menu.
      //TODO(eva): need to make sure we can handle identical cursors
      const isComparedCursor = isCursorStart
        ? imageRefs.length > 1 &&
          imageRefs[1]!.layerKey.startsWith(source!) &&
          imageRefs[1]!.cursor === dateString &&
          !isActive
        : imageRefs[0]!.layerKey.startsWith(source!) &&
          imageRefs[0]!.cursor === dateString &&
          !isActive;

      // Year divider
      const date = layerUtils.conditionallyAdjustLayerDate(dateString, layerKeyForSource);
      if (!lastYear || date.year() !== lastYear) {
        children.push(
          <div key={date.year()} className={cs.yearMarker}>
            <span>{date.year()}</span>
            <div className={cs.yearMarkerLine} />
          </div>
        );
        lastYear = date.year();
      }

      children.push(
        <DateSelectCard
          key={dateString + source}
          className={classnames({
            [cs.isActive]: isActive,
            [cs.isComparedCursor]: isComparedCursor,
          })}
          style={{
            marginBottom: i === filteredFeatureData.size - 1 ? 0 : ITEM_MARGIN_BOTTOM,
          }}
          onClick={() => {
            if (orderImageryState === featureUtils.ORDER_IMAGERY_STATE_PROCESSED) {
              const newImageRef = {
                layerKey: layerKeyForSource,
                cursor: dateString,
              };
              isCompareMode
                ? onChange(mapUtils.makeNewImageRefs(newImageRef, imageRefs, isCursorStart))
                : onChange([newImageRef]);
            }
          }}
          blockedSources={blockedSources}
          isOrdered={orderImageryState === featureUtils.ORDER_IMAGERY_STATE_PROCESSED}
          renderRightElement={() => (
            <div className={cs.cardRightElement}>
              <div className={cs.rightCardTag}>{rightCardTag}</div>
              {isCompareMode
                ? renderCompareIndicator(isComparedCursor, isActive, isCursorStart)
                : null}
            </div>
          )}
          featureDatum={prunedFeatureDatum}
          layerKey={layerKeyForSource}
          organization={organization}
          firebaseToken={firebaseToken}
        />
      );
    });
  });

  const emptyState = (
    <B.NonIdealState
      icon="media"
      iconMuted={false}
      title="No processed imagery"
      description="There is no processed imagery for this property."
    />
  );

  return (
    <div className={classnames(cs.container, className)}>
      {layerUtils.isLayerKeyHighResTruecolor(selectedLayer) && (
        <B.ButtonGroup className={cs.highRestTruecolorSwitch}>
          {HIGH_RES_TRUECOLOR_BUCKETS.map((b) => (
            <B.Button
              key={b}
              text={b}
              active={selectedHighResTruecolorResolutionRange === b}
              onClick={() => setSelectedHighResTrueColorResolutionRange(b)}
            />
          ))}
        </B.ButtonGroup>
      )}
      <div ref={(el) => (listRef.current = el)} className={cs.cardsContainer}>
        <div className={cs.cards}>{!children.length ? emptyState : children}</div>
      </div>

      <div className={cs.footer}>
        <div className={cs.footerTip}>
          <strong>Tip: </strong>
          Use left and right arrow keys to change dates from the map.
        </div>
      </div>
    </div>
  );
};

export default DateSelect;
