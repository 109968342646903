import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import AppNav from 'app/components/AppNav';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';

import {slides} from './slides';
import cs from './styles.styl';

export const OnboardingSlidesView: React.FunctionComponent<
  React.PropsWithChildren<{
    redirectUrl: string;
    history: History;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
  }>
> = ({redirectUrl = '/projects', history, organization, profile, loggedInUserActions}) => {
  //redirect user to wherever they were originally going to be sent by the router
  //(defaults to /projects)
  const dismissSlides = () => {
    localStorage.setItem('hasLoggedIn', 'true');
    history.push(redirectUrl);
  };

  //all of our buttons either dismiss the slides or increment the slide progress.
  //this assumes that the first slide and the last slide have special logic, but
  //otherwise, the right button increments and left decrements.
  const [visibleSlideIdx, setVisibleSlideIdx] = React.useState(0);
  const buttonAction = (idx, buttonPosition, slidesCount) => {
    switch (idx) {
      case 0:
        switch (buttonPosition) {
          case 'left':
            dismissSlides();
            break;
          case 'right':
            setVisibleSlideIdx(visibleSlideIdx + 1);
            break;
        }
        break;
      case slidesCount - 1:
        switch (buttonPosition) {
          case 'left':
            setVisibleSlideIdx(visibleSlideIdx - 1);
            break;
          case 'right':
            dismissSlides();
            break;
        }
        break;
      default:
        switch (buttonPosition) {
          case 'left':
            setVisibleSlideIdx(visibleSlideIdx - 1);
            break;
          case 'right':
            setVisibleSlideIdx(visibleSlideIdx + 1);
            break;
        }
    }
  };

  return (
    <>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      <div className={cs.slidesContainer}>
        <Slide
          progress={visibleSlideIdx / (slides.length - 1)}
          title={slides[visibleSlideIdx].title}
          description={slides[visibleSlideIdx].description}
          imgAlt={slides[visibleSlideIdx].imgAlt}
          imgLocation={slides[visibleSlideIdx].imgLocation}
          leftButtonLabel={slides[visibleSlideIdx].leftButtonLabel}
          leftButtonIcon={slides[visibleSlideIdx].leftButtonIcon}
          leftButtonAction={() => buttonAction(visibleSlideIdx, 'left', slides.length)}
          rightButtonLabel={slides[visibleSlideIdx].rightButtonLabel}
          rightButtonIcon={slides[visibleSlideIdx].rightButtonIcon}
          rightButtonAction={() => buttonAction(visibleSlideIdx, 'right', slides.length)}
          dismissSlides={() => dismissSlides()}
        />
      </div>
    </>
  );
};

export default OnboardingSlidesView;

export const Slide: React.FunctionComponent<
  React.PropsWithChildren<{
    progress: number;
    title: string;
    description: string;
    imgLocation: string;
    imgAlt: string;
    leftButtonLabel?: string;
    leftButtonIcon?: B.IconName | undefined;
    leftButtonAction: () => void;
    rightButtonLabel?: string;
    rightButtonIcon?: B.IconName | undefined;
    rightButtonAction: () => void;
    dismissSlides: () => void;
  }>
> = ({
  progress,
  title,
  description,
  imgLocation,
  imgAlt,
  leftButtonLabel = 'Back',
  leftButtonIcon,
  leftButtonAction,
  rightButtonLabel = 'Next',
  rightButtonIcon,
  rightButtonAction,
  dismissSlides,
}) => {
  return (
    <div className={cs.slides}>
      <div className={cs.slidesHeader}>
        <h2>{title}</h2>
        <B.Button minimal role="button" onClick={dismissSlides} icon="cross" />
      </div>
      <div className={cs.slidesContent}>
        <img src={imgLocation} alt={imgAlt} />
        {description}
      </div>
      <div className={cs.slidesActions}>
        <div className={cs.slidesButtons}>
          <B.Button
            role="button"
            intent={B.Intent.NONE}
            icon={leftButtonIcon}
            minimal
            onClick={leftButtonAction}
          >
            {leftButtonLabel}
          </B.Button>{' '}
          <B.Button
            role="button"
            intent={B.Intent.PRIMARY}
            onClick={rightButtonAction}
            rightIcon={rightButtonIcon}
          >
            {rightButtonLabel}
          </B.Button>
        </div>
        <B.ProgressBar
          animate={false}
          value={progress}
          intent={B.Intent.PRIMARY}
          stripes={false}
        ></B.ProgressBar>
      </div>
    </div>
  );
};
