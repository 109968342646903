import * as Sentry from '@sentry/react';
import React from 'react';

import {api} from 'app/modules/Remote';
import {ApiAccessSubscription, ApiPaidLayerInvoice} from 'app/modules/Remote/Organization';
import {ApiImageryContract, ApiImageryContractWithProjects} from 'app/modules/Remote/Project';
import {
  CachedApiGetterOptions,
  makeFetchWithBackOff,
  useApiGet,
  useCachedApiGet,
} from 'app/utils/hookUtils';

export function useCurrentImageryContract() {
  return useCachedApiGet(
    makeFetchWithBackOff(async (_, projectId: string) => {
      const result = (await api.projects.currentImageryContract(projectId)).get('data');
      return result && (result.toJS() as ApiImageryContract);
    }),
    []
  );
}

export function useUpcomingInvoices(orgId: string, subIds) {
  return useApiGet(
    async (orgId: string, subIds: string[]) => {
      try {
        const result: ApiPaidLayerInvoice[] = await Promise.all(
          subIds.map(async (subId) =>
            (await api.organizations.upcomingInvoice(orgId, subId)).get('data').toJS()
          )
        );
        return result;
      } catch (e) {
        // the backend is already throwing a Sentry alert if we don't find a matching subscription
        // don't surface error to the user, instead just return empty list (no upcoming invoices found)
        console.error(e);
        return [];
      }
    },
    [orgId, subIds]
  );
}

export function useAccessSubscription() {
  const [getAccessSubscription, {invalidate}] = useCachedApiGet(async (_, orgId: string) => {
    const result = (await api.organizations.accessSubscription(orgId)).get('data');
    return result && (result.toJS() as ApiAccessSubscription);
  }, []);

  const cancelSubscription = async (
    organizationId: string,
    subscriptionId: string,
    comment = ''
  ) => {
    if (confirm('Are you sure you want to cancel your subscription?')) {
      try {
        await api.organizations.cancelSubscription(organizationId, subscriptionId, comment);
      } catch (e) {
        // e is unknown, add a type, use optional chaining, and provide a default
        Sentry.captureException(e);
        const errorMessage = 'We weren’t able to cancel your subscription. Please try again.';
        window.alert(errorMessage);
      }
    } else {
      return;
    }
    //either way, re-fetch the subscription so that we get the latest from Stripe
    invalidate([organizationId]);
  };

  return [getAccessSubscription, cancelSubscription, {invalidate}] as const;
}

export function useImageryPurchases() {
  return useCachedApiGet(async function* (_, imageryContractId: number) {
    return yield* api.imagery.purchases.fetchGen(
      imageryContractId,
      {perPage: 100, orderBy: '-purchased_at'},
      {getAllPages: 2}
    );
  }, []);
}

export function useImageryPurchaseBillingRecords() {
  return useCachedApiGet(async function* (_, imageryContractId: number) {
    return yield* api.imagery.billingRecords.fetchGen(
      imageryContractId,
      {perPage: 100, orderBy: '-purchased_at'},
      {getAllPages: 2}
    );
  }, []);
}

/**
 * A hook to list all contracts associated with an organization. Getter function
 * (first element in the response) can take an optional second argument,
 * projectId, to scope the contracts just to those assigned to the given
 * project.
 */
export function useImageryContracts() {
  const [getImageryContracts, ...rest] = useCachedApiGet(async function (
    _,
    organizationId: string,
    projectId: string
  ) {
    const result = (
      await api.organizations.contracts(organizationId, projectId ? {projectId} : {})
    ).get('data');
    return result && (result.toJS() as ApiImageryContractWithProjects[]);
  }, []);

  // Adaptor to put organizationId and projectId as separate arguments instead
  // of an array, and make projectId optional.
  const nicerGetImageryContracts = React.useCallback(
    (organizationId: string, projectId?: string, opts?: CachedApiGetterOptions) =>
      getImageryContracts([organizationId, projectId ?? ''], opts),
    [getImageryContracts]
  );

  return [nicerGetImageryContracts, ...rest] as const;
}
