import React from 'react';

import classNames from './styles.styl';
import LensLogo from '../Logo/LensLogo';

interface ErrorPageProps {
  message: string;
  linkTo?: string | null;
}

export default ({message, linkTo}: ErrorPageProps) => {
  const logo = <LensLogo noText={true} style={{height: '15rem', marginBottom: '5rem'}} />;
  return (
    <div className={classNames.error}>
      <article>
        {linkTo ? (
          <a target="_blank" rel="noopener noreferrer" href={linkTo}>
            {logo}
          </a>
        ) : (
          <div>{logo}</div>
        )}
        <h1>{message}</h1>
      </article>
    </div>
  );
};
