import React from 'react';

import justsatSrc from 'app/assets/images/justsat.png';

import cs from './styles.styl';

export default () => (
  <div className={cs.error}>
    <article>
      <img src={justsatSrc} className={cs.notFoundImage} />
      <h1>Lost in space…</h1>
      <h2>Something went wrong, but we're on it!</h2>
      <p style={{marginTop: '2.4rem'}}>
        <a href="https://app.upstream.tech">Back to sign in</a> or{' '}
        <a href="mailto:lens@upstream.tech">shoot us an email</a>
      </p>
    </article>
  </div>
);
