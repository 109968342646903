import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import {ApiOrganization} from 'app/modules/Remote/Organization';
import {ApiOverlay} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {useProjects} from 'app/providers/ProjectsProvider';
import {recordEvent} from 'app/tools/Analytics';
import * as featureUtils from 'app/utils/featureUtils';
import {overlayIsReady} from 'app/utils/overlayUtils';
import {useCurrentOrgIdPrefix} from 'app/utils/routeUtils';

import * as GlobalSettings from '../GlobalSettings';
import cs from './LensLibraryView.styl';
import {LibraryDatasetType} from './LibraryTypes';
import {useOverlays} from './OverlaysProvider';
import {LibraryItemDropdown} from './portfolioMenu';

const OverlaysView: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    history: History;
  }>
> = ({organization, history}) => {
  const {actions, overlays} = useOverlays();
  const [projectsMap, , , projectsMeta] = useProjects();

  const isLoading = projectsMeta.loading || !overlays.size;

  const projects = (projectsMap || I.OrderedMap()).valueSeq().toList();

  const addLibraryItem = (
    projectIds: string[],
    featureCollectionId: string,
    addTracking: boolean,
    name: string
  ): Promise<void> => {
    if (addTracking) {
      recordEvent('Added Upstream overlay', {
        name: name,
        portfolioCount: projectIds.length,
      });
    }
    return actions.addOverlays(projectIds, parseInt(featureCollectionId));
  };

  const removeLibraryItem = (
    projectIds: string[],
    featureCollectionId: string,
    addTracking = false,
    name: string
  ): Promise<void> => {
    if (addTracking) {
      recordEvent('Removed Upstream overlay', {
        name: name,
        portfolioCount: projectIds.length,
      });
    }
    return actions.removeOverlays(projectIds, parseInt(featureCollectionId));
  };

  const deleteLibraryItem = (featureCollectionId: string): Promise<void> =>
    actions.deleteOverlay(parseInt(featureCollectionId));

  const downloadLibraryItem = (featureCollectionId: string): Promise<void> => {
    return actions.downloadOverlay(parseInt(featureCollectionId));
  };

  const userUploadedOverlays = React.useMemo(() => {
    return overlays
      .filter((o) => !!o && o.get('uploadedBy') === organization.get('name'))
      .sortBy((o) => o!.get('name'), featureUtils.alphanumericSort)
      .toArray();
  }, [overlays, organization]);

  return isLoading ? (
    <GlobalSettings.CenteredSpinner />
  ) : (
    <CustomOverlaysTable
      overlays={userUploadedOverlays}
      projects={projects}
      addLibraryItem={addLibraryItem}
      removeLibraryItem={removeLibraryItem}
      deleteLibraryItem={deleteLibraryItem}
      downloadLibaryItem={downloadLibraryItem}
      history={history}
    />
  );
};

const CustomOverlaysTable: React.FunctionComponent<
  React.PropsWithChildren<{
    overlays: I.ImmutableOf<ApiOverlay>[];
    projects: I.ImmutableListOf<ApiProject>;
    addLibraryItem: (
      projectIds: string[],
      keyName: string,
      addTracking: boolean,
      name: string,
      type: LibraryDatasetType
    ) => Promise<void>;
    removeLibraryItem: (
      projectIds: string[],
      keyName: string,
      addTracking: boolean,
      name: string,
      type: LibraryDatasetType
    ) => Promise<void>;
    deleteLibraryItem: (keyName: string) => Promise<void>;
    downloadLibaryItem: (keyName: string) => Promise<void>;
    history: History;
  }>
> = ({
  overlays,
  projects,
  addLibraryItem,
  deleteLibraryItem,
  downloadLibaryItem,
  removeLibraryItem,
  history,
}) => {
  const orgIdPrefix = useCurrentOrgIdPrefix();

  return !overlays.length ? (
    <div className={cs.overlaysBlankstate}>
      <B.NonIdealState
        iconMuted={false}
        icon={<B.Icon icon="new-layers" size={120} />}
        description={
          <>
            Add a{' '}
            <a href="https://support.upstream.tech/article/34-adding-overlays#adding">
              custom overlay
            </a>{' '}
            to view your vector data in Lens, such as building envelopes, photopoints, trails, and
            more.
          </>
        }
        action={
          <B.AnchorButton
            intent="primary"
            text={'Add an overlay'}
            icon={'plus'}
            onClick={() => history.push(`/${orgIdPrefix}/admin/manageProperties/overlay`)}
          />
        }
      ></B.NonIdealState>
    </div>
  ) : (
    <div className={cs.tableWrapper}>
      <div className={cs.tableHeader}>
        <h2>Custom Overlays</h2>
        <B.AnchorButton
          intent="primary"
          text={'Add an overlay'}
          icon={'plus'}
          onClick={() => history.push(`/${orgIdPrefix}/admin/manageProperties/overlay`)}
        />
      </div>

      <table className={cs.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Created On</th>
            <th>Usage</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {overlays.map((o: I.ImmutableOf<ApiOverlay>, i) => {
            const isProcessing = !overlayIsReady(o.get('status'));
            const name = o.get('name');
            return (
              <tr key={i}>
                <td className={classnames(cs.nameCell, cs.tableCell)}>
                  {name}
                  {isProcessing && (
                    <B.Tooltip content={'Overlay is processing'} position="top">
                      <B.Icon icon="time" intent="warning" className={cs.overlayProcessingIcon} />
                    </B.Tooltip>
                  )}
                </td>
                <td className={cs.tableCell}>
                  {moment(o.get('createdAt')).format('MMMM D, YYYY')}
                </td>
                <td className={cs.tableCell}>
                  <LibraryItemDropdown
                    name={o.get('name')}
                    keyName={o.get('featureCollectionId').toString()}
                    enrolledProjectIds={o.get('projectIds').toJS()}
                    addTracking={o.get('uploadedBy') === 'Upstream System'}
                    projects={projects}
                    disabled={false}
                    addLibraryItem={addLibraryItem}
                    removeLibraryItem={removeLibraryItem}
                    type={LibraryDatasetType.OVERLAY}
                  >
                    <B.Button
                      className={cs.usageCell}
                      rightIcon="caret-down"
                      minimal
                      text={
                        o.get('projectIds').size === 1
                          ? `1 portfolio`
                          : `${o!.get('projectIds').size} portfolios`
                      }
                    />
                  </LibraryItemDropdown>
                </td>
                <td className={cs.deleteCell}>
                  <B.AnchorButton
                    icon={'download'}
                    minimal
                    aria-label="download"
                    onClick={() => {
                      downloadLibaryItem(o.get('featureCollectionId').toString());
                    }}
                  />
                  <B.AnchorButton
                    icon={'trash'}
                    minimal
                    aria-label="delete"
                    onClick={() => {
                      if (
                        confirm(
                          `Are you sure you want to delete ${o.get(
                            'name'
                          )} permanently from your library? You will no longer be able to add or remove it from your portfolios.`
                        )
                      ) {
                        deleteLibraryItem(o.get('featureCollectionId').toString());
                      }
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OverlaysView;
