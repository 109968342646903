import * as I from 'immutable';

/**
 * Type-safe creation of MapAsRecord values. Gets around I.MapAsRecord not
 * existing as a real type.
 */
export function toImmutableMap<T extends object>(r: T): I.MapAsRecord<T> {
  return I.Map(r) as any;
}

/**
 * Takes a list and return a list of lists, each no more than chunkSize long.
 * Used to break up a potentially long list to fit in e.g. batch request limit.
 *
 * Credit: https://stackoverflow.com/a/38765329/51835
 */
export function splitIntoChunks<T>(list: I.Seq.Indexed<T>, chunkSize = 1) {
  return I.Range(0, list.count(), chunkSize).map((chunkStart) =>
    list.slice(chunkStart, chunkStart! + chunkSize)
  );
}
