import throttle from 'lodash/throttle';
import mapboxgl from 'mapbox-gl';
import React from 'react';

import {useContinuity} from 'app/utils/hookUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {useMap} from './MapContent';

export type CursorType = 'pointer' | 'crosshair';

interface MapCursorContextValue {
  watchLayerId(
    id: string,
    type: CursorType,
    eventFilter?: (event: mapboxgl.MapLayerMouseEvent) => boolean
  ): void;
  unwatchLayerId(id: string): void;
}

const MapCursorContext = React.createContext<MapCursorContextValue | undefined>(undefined);

/**
 * Manages setting the mouse cursor to a pointer when you hover over elements on
 * the Mapbox GL map.
 *
 * @see useMapPointer
 */
const MapCursorProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    isMapLoaded: boolean;
    /** The cursor to use when we’re not hovering over one of the features. */
    globalCursor?: CursorType | undefined;
  }>
> = ({map, isMapLoaded, children, globalCursor = ''}) => {
  // It’s ok that changes to this don’t trigger a re-render because we’re only
  // reading it in event callbacks.

  const layerToCursorMapRef = React.useRef(
    new Map<string, [CursorType, ((event: mapboxgl.MapLayerMouseEvent) => boolean) | undefined]>()
  );

  const watchLayerId = React.useCallback(
    (
      id: string,
      type: CursorType,
      eventFilter?: (event: mapboxgl.MapLayerMouseEvent) => boolean
    ) => {
      layerToCursorMapRef.current.set(id, [type, eventFilter]);
    },
    []
  );

  const unwatchLayerId = React.useCallback((id: string) => {
    layerToCursorMapRef.current.delete(id);
  }, []);

  // We need to make sure to clear the cursor on unmount so that it’s not stuck.
  React.useEffect(() => {
    return () => {
      const canvas = map.getCanvas();

      if (canvas) {
        canvas.style.cursor = '';
      }
    };
  }, [map]);

  // useMap so we don’t try to query when layers are in flux, as it will cause
  // the map to emit errors.
  useMap(
    (map) => {
      if (globalCursor) {
        const canvas = map.getCanvas();

        if (canvas) {
          canvas.style.cursor = globalCursor;
        }

        return () => {
          canvas.style.cursor = '';
        };
      }

      const mouseMoveListener = throttle(
        (e: mapboxgl.MapMouseEvent) => {
          const results = mapUtils.safeQueryRenderedFeatures(map, e.point, {
            layers: Array.from(layerToCursorMapRef.current.keys()),
          });

          const canvas = map.getCanvas();

          let found = false;

          if (results?.length) {
            // Go through the results to find the first one with a defined
            // cursor.
            for (let i = 0; i < results.length; ++i) {
              const {layer} = results[i];

              const val = layerToCursorMapRef.current.get(layer.id);

              if (!val) {
                continue;
              }

              const [cursor, eventFilter] = val;

              if (
                eventFilter &&
                !eventFilter(
                  // HACK(fiona): We simulate a MapLAYERMouseEvent by finding
                  // all the results that match the current layer and add them
                  // in, since the eventFilter function expects to see all
                  // matching features from its layer.
                  Object.assign({}, e, {
                    features: results.filter((f) => f.layer === layer),
                  })
                )
              ) {
                continue;
              }

              if (cursor) {
                canvas.style.cursor = cursor;
                found = true;
                break;
              }
            }
          }

          if (!found) {
            canvas.style.cursor = globalCursor;
          }
        },
        100,
        {leading: false}
      );

      map.on('mousemove', mouseMoveListener);

      return () => {
        map.off('mousemove', mouseMoveListener);
      };
    },
    [globalCursor],
    {map, isMapLoaded}
  );

  const value = {
    watchLayerId,
    unwatchLayerId,
  };

  return <MapCursorContext.Provider value={value}>{children}</MapCursorContext.Provider>;
};

export default MapCursorProvider;

/**
 * Call with a layer ID and CursorType to add that cursor as a hover effect for
 * that layer.
 *
 * Relies on MapCursorProvider for setting the context and handling changing the
 * cursor.
 */
export function useMapCursor(
  layerIds: (string | false | undefined)[],
  type: CursorType,
  eventFilter?: (event: mapboxgl.MapLayerMouseEvent) => boolean
) {
  layerIds = useContinuity(layerIds);

  const value = React.useContext(MapCursorContext);

  if (value === undefined) {
    throw new Error('useMapCursor must be used beneath a MapCursorProvider');
  }

  const {watchLayerId, unwatchLayerId} = value;

  React.useEffect(() => {
    layerIds.forEach((l) => l && watchLayerId(l, type, eventFilter));

    return () => {
      layerIds.forEach((l) => l && unwatchLayerId(l));
    };
  }, [watchLayerId, unwatchLayerId, type, layerIds, eventFilter]);
}
