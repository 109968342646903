import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import * as I from 'immutable';
import React from 'react';
import Gravatar from 'react-gravatar';

import {usePushNotification} from 'app/components/Notification';
import {ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {OrgUsersActions} from 'app/providers/OrgUsersProvider';
import {recordEvent} from 'app/tools/Analytics';
import * as C from 'app/utils/constants';
import {useStateWithDeps} from 'app/utils/hookUtils';
import * as inviteUtils from 'app/utils/inviteUtils';

import cs from './TeamMemberModal.styl';

export interface Props {
  editingUserId: string | null;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organizationUsers: ApiOrganizationUser[];
  actions: OrgUsersActions;
  onClose: () => void;
  loggedInUserActions: LoggedInUserActions;
}

export const ROLE_MESSAGING: {[role in ApiOrganizationUser['role']]: string} = {
  readonly: 'Read-only accounts can view imagery and notes.',
  regular:
    'Member accounts can view imagery, add new properties and portfolios, make notes, and generate reports.',
  owner:
    'Admin accounts can order and view imagery, add new properties and portfolios, send invites to other users, change member permissions, edit notes, remove team members, make notes, archive properties, and generate reports.',
};

export const ROLE_SUPPORT = 'https://support.upstream.tech/article/32-inviting-your-team';

const TeamMemberModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  profile,
  editingUserId,
  organizationUsers,
  actions,
  onClose,
  loggedInUserActions,
}) => {
  const isOpen = !!editingUserId;
  const organizationUser = organizationUsers.find((u) => u!.id === editingUserId)!;
  const userId = organizationUser?.id;
  const isOwner = profile.get('role') === C.USER_ROLE_OWNER;
  const isSelf = profile.get('id') === userId;
  const isRoleSelectDisabled = !isOwner || isSelf;
  const isDeleteDisabled = !isOwner || isSelf;
  const pushNotification = usePushNotification();

  const [isUpdating, setIsUpdating] = React.useState(false);

  const [nameField, setNameField] = useStateWithDeps<string | undefined>(organizationUser?.name, [
    organizationUser,
  ]);
  const [roleField, setRoleField] = useStateWithDeps<ApiOrganizationUser['role'] | undefined>(
    organizationUser?.role,
    [organizationUser]
  );

  const onRoleSelectChange = React.useCallback(
    async (roleField) => {
      const currentRole = organizationUser.role;

      // Do nothing if we dont have a ref, or if role is current role
      if (!roleField || roleField === currentRole) {
        return;
      }

      // This is identical to our role select enabled check,
      //  but just in case something unexpected happens, guard
      if (!isOwner || isSelf) {
        return;
      }

      // Check if valid role and isn't current role
      if (!inviteUtils.ROLE_NAMES[roleField]) {
        return;
      }

      try {
        setIsUpdating(true);
        await actions.setUserRole(userId, roleField);
        recordEvent('Set user role');
        pushNotification({
          message: `User role updated.`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.SUCCESS,
          },
        });
      } catch (_e) {
        setRoleField(currentRole);
        pushNotification({
          message: "There was a problem updating user's role name. Please try again.",
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [actions, isOwner, isSelf, organizationUser, pushNotification, setRoleField, userId]
  );

  const onInputBlur = React.useCallback(async () => {
    const name = organizationUser.name;

    // Do nothing if we dont have a ref, or our value has not changed.
    if (!nameField || nameField === name) {
      return;
    }

    if (!nameField.trim()) {
      setNameField(name);
      return;
    }

    try {
      setIsUpdating(true);
      await actions.setUserName(userId, nameField);
      recordEvent('Set user name');
      pushNotification({
        message: `User name updated.`,
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.SUCCESS,
        },
      });
    } catch (_e) {
      // Reset input value and inform of failure
      setNameField(name);
      pushNotification({
        message: "There was a problem updating user's name. Please try again.",
        autoHideDuration: 3000,
        options: {
          intent: B.Intent.DANGER,
        },
      });
    } finally {
      setIsUpdating(false);
    }
  }, [actions, nameField, organizationUser, pushNotification, setNameField, userId]);

  const onDelete = React.useCallback(async () => {
    const name = organizationUser.name;
    if (
      confirm(`Are you sure you want to remove ${organizationUser.name}? This cannot be undone.`)
    ) {
      try {
        setIsUpdating(true);
        await actions.deleteUser(userId);
        recordEvent('Deleted user');
        // Inform user of success and close modal
        pushNotification({
          message: `User ${name} deleted.`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.SUCCESS,
          },
        });
        onClose();
      } catch (_e) {
        // Inform user of failure
        pushNotification({
          message: `There was a problem deleting ${name}. Please try again.`,
          autoHideDuration: 3000,
          options: {
            intent: B.Intent.DANGER,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    }
  }, [actions, onClose, organizationUser, pushNotification, userId]);

  // Our editingUserId isnt in the organization, return null
  if (!organizationUser) {
    return null;
  }

  return (
    <B.Dialog isOpen={isOpen} onClose={onClose} className={cs.dialog}>
      <div className={cs.header}>
        <B.Button minimal tabIndex={0} onClick={() => onClose()}>
          <B.Icon icon="cross" size={32} />
        </B.Button>
      </div>
      <div className={classnames(cs.section, cs.pullUp)}>
        <div className={cs.sectionHead}>
          <h1>Profile</h1>
        </div>
        <div className={cs.sectionControls}>
          <div className={cs.stacked}>
            <div className={cs.field}>
              <label>Photo</label>
              <Gravatar email={organizationUser.email} />
            </div>
            <div className={cs.inline}>
              <div className={cs.field}>
                <label htmlFor="name-input">Name</label>
                <B.InputGroup
                  id="name-input"
                  disabled={isUpdating}
                  value={nameField}
                  onValueChange={(val) => setNameField(val)}
                  onBlur={onInputBlur}
                />
              </div>
              <div className={cs.field}>
                <label htmlFor="email-input">Email</label>
                <div className={cs.updateEmailContainer}>
                  <div className={cs.email}>{organizationUser.email}</div>
                  {isSelf && (
                    <UpdateEmailModal
                      profile={profile}
                      loggedInUserActions={loggedInUserActions}
                      orgUsersActions={actions}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cs.section}>
        <div className={cs.sectionHead}>
          <h1>Permissions and Security</h1>
        </div>
        <div className={cs.sectionControls}>
          <div className={cs.inline}>
            <div className={cs.field}>
              <label htmlFor="role-select">Role</label>

              <B.HTMLSelect
                id="role-select"
                disabled={isRoleSelectDisabled || isUpdating}
                onChange={(e) => onRoleSelectChange(e.currentTarget.value)}
                value={roleField}
                aria-label={`Change role for ${organizationUser.email}`}
              >
                {Object.keys(inviteUtils.ROLE_NAMES).map((role) => (
                  <option key={role} value={role}>
                    {inviteUtils.ROLE_NAMES[role]}
                  </option>
                ))}
              </B.HTMLSelect>
            </div>
            <div className={cs.description}>
              <div>{ROLE_MESSAGING[organizationUser.role]}</div>
              <a target="_blank" rel="noopener noreferrer" href={ROLE_SUPPORT}>
                Learn more about role permissions here.
              </a>
            </div>
          </div>
        </div>
      </div>
      {isOwner && (
        <div className={cs.section}>
          <div className={cs.sectionHead}>
            <h1>Account</h1>
            <div>Actions in this section may not be reversible. Use caution.</div>
          </div>
          <div className={cs.sectionControls}>
            <div className={cs.inline}>
              <div>
                <B.Button
                  disabled={isDeleteDisabled || isUpdating}
                  intent={B.Intent.DANGER}
                  onClick={onDelete}
                >
                  Delete User
                </B.Button>
              </div>
              {isSelf && <div>Note: Admins may not delete themselves.</div>}
            </div>
          </div>
        </div>
      )}
    </B.Dialog>
  );
};

const UpdateEmailModal: React.FunctionComponent<
  React.PropsWithChildren<{
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
    orgUsersActions: OrgUsersActions;
  }>
> = ({profile, loggedInUserActions, orgUsersActions}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [newEmail, setNewEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [errorText, setErrorText] = React.useState<string>('');

  const _onClose = () => {
    setNewEmail('');
    setPassword('');
    setErrorText('');
    setIsOpen(false);
  };

  const isReadyToSubmit = !!(newEmail && password && newEmail !== profile.get('email'));

  const onUpdateEmail = React.useCallback(async () => {
    if (!isReadyToSubmit) {
      return;
    }
    try {
      setIsUpdating(true);
      setErrorText('');
      await loggedInUserActions.confirmPassword(password);
      await orgUsersActions.setUserEmail(newEmail);
      recordEvent('Set email');
    } catch (e) {
      if ((e as {code: string})?.code === 'auth/wrong-password') {
        setErrorText('Invalid password');
      } else {
        const errorText =
          (e as {body: {error: string}; error: Error})?.body?.error || 'Error updating email';
        setErrorText(errorText);
      }
    } finally {
      setIsUpdating(false);
    }
  }, [loggedInUserActions, newEmail, password, orgUsersActions, isReadyToSubmit]);

  return (
    <>
      <B.Button icon={'edit'} minimal onClick={() => setIsOpen(true)} />
      <B.Dialog
        isOpen={isOpen}
        onClose={_onClose}
        usePortal={false}
        className={cs.updateEmailModal}
      >
        <div className={B.Classes.DIALOG_BODY}>
          <label htmlFor="email-input">New email</label>
          <B.InputGroup
            id="new-email-input"
            value={newEmail}
            onValueChange={(value) => setNewEmail(value)}
            disabled={isUpdating}
          />
          {newEmail === profile.get('email') && (
            <div className={cs.errorText}>New email must be different from current email.</div>
          )}
          <br />
          <label htmlFor="confirm-password-input">Confirm password</label>
          <B.InputGroup
            id="confirm-password-input"
            value={password}
            onValueChange={(value) => setPassword(value)}
            disabled={isUpdating}
            type="password"
          />
          {errorText && <div className={cs.errorText}>{errorText}</div>}
          <B.Callout intent={B.Intent.PRIMARY} className={cs.changeEmailCallout}>
            After updating your email you will be redirected to sign back in.
          </B.Callout>
        </div>
        <div className={B.Classes.DIALOG_FOOTER}>
          <div className={B.Classes.DIALOG_FOOTER_ACTIONS}>
            <B.Button text={'Cancel'} onClick={_onClose} />
            <B.Button
              text={'Submit'}
              disabled={!isReadyToSubmit}
              loading={isUpdating}
              intent={B.Intent.PRIMARY}
              onClick={async () => await onUpdateEmail()}
            />
          </div>
        </div>
      </B.Dialog>
    </>
  );
};

export default TeamMemberModal;
