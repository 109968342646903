import * as B from '@blueprintjs/core';
import classNames from 'classnames';
import geojson from 'geojson';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import {api} from 'app/modules/Remote';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {ShareLinkConfig} from 'app/providers/ShareLinkProvider';
import {recordEvent} from 'app/tools/Analytics';
import {ApiResponseError} from 'app/utils/apiUtils';
import {useStateWithDeps} from 'app/utils/hookUtils';
import * as imageryUtils from 'app/utils/imageryUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as routeUtils from 'app/utils/routeUtils';

import Modal from '../Modal/Modal';
import {usePushNotification} from '../Notification';
import cs from './styles.styl';
import {NoteGraph} from '../AnalyzePolygonChart/types';

interface CopyableTextProps {
  description: string | JSX.Element;
  copyableText: string;
}

export interface ApiShareLinkConfig {
  lens_id: string;
  feature_collection_id: number;
  scenes: {
    sensing_time: string;
    source_id: string;
    layer_id: string;
  }[];
  overlay_feature_collection_ids: number[];
  description: string;
  note_geometry?: geojson.Geometry | null;
  attachments?: string[] | null;
  graph?: NoteGraph | null;
  mapOptions?: mapUtils.CameraOptions | null;
}

const CopyableText = ({description, copyableText}: CopyableTextProps) => {
  const [isCopied, setCopied] = React.useState<boolean>(false);

  // Utility function to animate copy actions
  const showCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <>
      <div className={cs.shareDescriptionModal}>{description}</div>
      <CopyToClipboard text={copyableText} onCopy={showCopied}>
        {/* This div is required for copying to work. */}
        <div>
          <B.InputGroup
            className={classNames(cs.truncateUrl, cs.monospace)}
            type="text"
            value={copyableText}
            readOnly
            rightElement={
              <B.Button className={cs.fixedWidthButon}>{isCopied ? 'Copied!' : 'Copy'}</B.Button>
            }
          />
        </div>
      </CopyToClipboard>
    </>
  );
};

const EMBED_TEMPLATE = `<iframe width="100%" height="100%" src="<EMBED>?embedded=true" frameborder="0" allowfullscreen sandbox="allow-same-origin allow-scripts allow-top-navigation-by-user-activation"></iframe>`;

export const ShareModal: React.FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean;
    onClose: () => void;
    shareDetails: ShareLinkConfig;
  }>
> = ({isOpen, onClose, shareDetails}) => {
  const [shareId, setShareId] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const pushNotification = usePushNotification();
  const [description, setDescription] = useStateWithDeps<string>(
    shareDetails.defaultDescription || '',
    [shareDetails.defaultDescription, isOpen]
  );
  const [useCurrentCameraOptions, setUseCurrentCameraOptions] = React.useState<boolean>(false);
  const [includeNoteGeometry, setIncludeNoteGeometry] = React.useState<boolean>(true);
  const [includeNoteAttachments, setIncludeNoteAttachments] = React.useState<boolean>(true);
  const [includeNoteGraph, setIncludeNoteGraph] = React.useState<boolean>(true);

  const link = shareId ? routeUtils.makeAppUrl(`/p/${shareId}`) : '';

  const {
    feature,
    featureData,
    project,
    imageRefs,
    overlayFeatureCollections,
    overlayVisibilityByName,
    cameraOptions,
  } = shareDetails;

  const visibleOverlays: ApiFeatureCollection[] = React.useMemo(
    () =>
      overlayFeatureCollections
        .filter((ofc) => overlayVisibilityByName[ofc?.get('name') || ''])
        .toJS(),
    [overlayFeatureCollections, overlayVisibilityByName]
  );

  const selectedFeatureData = React.useMemo(() => {
    if (!featureData) {
      return [];
    }

    return imageRefs.flatMap((imageRef) => {
      const datum = featureData.findLast(
        (d) => d!.get('types').includes(imageRef.layerKey) && d!.get('date') === imageRef.cursor
      );
      return datum ?? []; // filter nulls out of the array
    });
  }, [featureData, imageRefs]);

  const embedCode = EMBED_TEMPLATE.replace('<EMBED>', link);

  const generateShareLink = React.useCallback(() => {
    const makeShareLink = async () => {
      setIsLoading(true);
      const {
        feature,
        imageRefs,
        overlayFeatureCollections,
        overlayVisibilityByName,
        attachments,
        graph,
        noteGeometry: geometry,
      } = shareDetails;
      const enabledOverlays = overlayFeatureCollections
        .toJS()
        .reduce(
          (ofcIds, ofc) => (overlayVisibilityByName[ofc.name] ? [...ofcIds, ofc.id] : ofcIds),
          []
        );
      const shareRequestParams: ApiShareLinkConfig = {
        lens_id: feature.getIn(['properties', 'lensId']),
        feature_collection_id: feature.getIn(['properties', 'featureCollectionId']),
        scenes: imageRefs.map(mapUtils.imageRefsToScenes),
        overlay_feature_collection_ids: enabledOverlays,
        description,
        attachments: includeNoteAttachments ? attachments : [],
        graph: includeNoteGraph ? graph : null,
        note_geometry: includeNoteGeometry ? geometry : null,
        ...(useCurrentCameraOptions && {map_options: cameraOptions}),
      };
      try {
        const response = await api.makeShareLink(shareRequestParams);
        const id = response.getIn(['data', 'id']);
        setShareId(id);
      } catch (e) {
        const {error, status} = e as ApiResponseError;
        let message, intent;
        if (status === 400) {
          message = `Unable to create share link from paid imagery. Please select a different layer and try again.`;
          intent = B.Intent.WARNING;
        } else {
          message = `Something went wrong: ${error.message}`;
          intent = B.Intent.DANGER;
        }
        pushNotification({
          message,
          autoHideDuration: 3000,
          options: {
            intent,
          },
        });
      } finally {
        setIsLoading(false);
        recordEvent('Created share link');
      }
    };
    makeShareLink();
  }, [
    shareDetails,
    description,
    includeNoteAttachments,
    includeNoteGraph,
    includeNoteGeometry,
    useCurrentCameraOptions,
    cameraOptions,
    pushNotification,
  ]);

  const mapOptionsDisabled = !!shareId || isLoading;

  return (
    <Modal
      isOpen={isOpen}
      headerText="Share Map"
      canOutsideClickClose={false}
      onClose={() => {
        setShareId(null);
        setUseCurrentCameraOptions(false);
        onClose();
      }}
      hideActions={true}
    >
      <div className={cs.shareModal}>
        <div>
          <div style={{marginTop: '2rem'}}>
            Share an interactive property map with partners and colleagues—no Lens account needed.{' '}
            <a
              href={'https://support.upstream.tech/article/151-lens-share-link#share'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more here.
            </a>
          </div>
          <table className={cs.shareModalDetails}>
            <tbody>
              <tr>
                <th colSpan={2}>
                  <div className={cs.sectionHeader}>
                    <div>Map details</div>
                    <div className={cs.sectionHeaderDivider} />
                  </div>
                </th>
              </tr>
              <tr>
                <th>Portfolio</th>
                <td>{project.get('name')}</td>
              </tr>
              {feature && (
                <tr>
                  <th>Property</th>
                  <td>{feature.getIn(['properties', 'name'])}</td>
                </tr>
              )}
              {imageRefs && imageRefs.length === selectedFeatureData.length && (
                <tr>
                  <th>{imageRefs.length == 1 ? 'Layer' : 'Layers'}</th>
                  <td>
                    {imageRefs.length < 2 ? (
                      <div>{imageryUtils.formatImageRef(selectedFeatureData[0], imageRefs[0])}</div>
                    ) : (
                      <ul>
                        <li>{imageryUtils.formatImageRef(selectedFeatureData[0], imageRefs[0])}</li>
                        <li>{imageryUtils.formatImageRef(selectedFeatureData[1], imageRefs[1])}</li>
                      </ul>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                {visibleOverlays.length === 1 ? (
                  <>
                    <th>Overlay</th>
                    <td>{visibleOverlays[0].name}</td>
                  </>
                ) : (
                  <>
                    <th>Overlays</th>
                    <td>
                      {visibleOverlays.length === 0 ? (
                        <div>No overlays selected</div>
                      ) : (
                        <ul>
                          {visibleOverlays.map((ofc, i) => (
                            <li key={i}>{ofc.name}</li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <th colSpan={2}>
                  <div className={cs.sectionHeader}>
                    <div>Map options</div>
                    <div className={cs.sectionHeaderDivider} />
                  </div>
                </th>
              </tr>
              <tr>
                <th>Default position</th>
                <td>
                  <B.RadioGroup
                    onChange={(e) =>
                      setUseCurrentCameraOptions(e.currentTarget.value === 'true' ? true : false)
                    }
                    selectedValue={useCurrentCameraOptions ? 'true' : 'false'}
                    disabled={mapOptionsDisabled}
                  >
                    <B.Radio label="Fitted to property boundary" value={'false'} />
                    <B.Radio
                      label="Current map position"
                      value={'true'}
                      className={cs.shareLinkUseCameraOptionsSelect}
                    />
                  </B.RadioGroup>
                </td>
              </tr>
              <tr>
                <th>
                  <span>Description</span>
                  <br />
                  <span className={cs.descriptionSubHeader}>
                    <em>Optional</em>
                  </span>
                </th>
                <td>
                  <B.TextArea
                    className={cs.shareLinkDescriptionInput}
                    disabled={mapOptionsDisabled}
                    fill={true}
                    placeholder={!mapOptionsDisabled ? 'Description' : ''}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    growVertically={true}
                  />
                </td>
              </tr>
              {(shareDetails.noteGeometry || shareDetails.graph || shareDetails.attachments) && (
                <tr>
                  <th>Note options</th>
                  <td className={cs.shareLinkMapOptions}>
                    {shareDetails.noteGeometry && (
                      <B.Tooltip
                        disabled={!(includeNoteGraph && !!shareDetails.graph)}
                        content={'Note Location must be selected if Graph is selected'}
                        placement={'right'}
                      >
                        <B.Checkbox
                          checked={includeNoteGeometry}
                          onChange={() => setIncludeNoteGeometry((s) => !s)}
                          label={'Location'}
                          disabled={
                            (includeNoteGraph && !!shareDetails.graph) || mapOptionsDisabled
                          }
                        />
                      </B.Tooltip>
                    )}
                    {shareDetails.graph && (
                      <B.Checkbox
                        checked={includeNoteGraph}
                        onChange={() => {
                          if (includeNoteGraph) {
                            setIncludeNoteGraph(false);
                          } else {
                            // If we are including the graph we must also include geometry
                            setIncludeNoteGraph(true);
                            setIncludeNoteGeometry(true);
                          }
                        }}
                        label={'Graph'}
                        disabled={mapOptionsDisabled}
                      />
                    )}
                    {shareDetails.attachments && (
                      <B.Checkbox
                        checked={includeNoteAttachments}
                        onChange={() => setIncludeNoteAttachments((s) => !s)}
                        label={'Attachments'}
                        disabled={mapOptionsDisabled}
                      />
                    )}
                  </td>
                </tr>
              )}

              <tr>
                <B.AnchorButton
                  className={cs.shareLinkConfirmButton}
                  disabled={isLoading}
                  onClick={() => (shareId ? setShareId(null) : generateShareLink())}
                  loading={isLoading}
                  text={shareId ? 'Edit' : 'Next'}
                  intent={B.Intent.PRIMARY}
                />
              </tr>
              {shareId && (
                <>
                  <tr>
                    <th colSpan={2}>
                      <div className={cs.sectionHeader}>
                        <div>Share</div>
                        <div className={cs.sectionHeaderDivider} />
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <CopyableText
                        description={
                          <span>
                            <div className={cs.shareSectionHead}>
                              <b>Lens Share Link</b>
                            </div>
                            <span className={cs.shareLinkShareDetails}>
                              Send an interactive property link to external partners
                            </span>
                          </span>
                        }
                        copyableText={link}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <CopyableText
                        description={
                          <span>
                            <div className={cs.shareSectionHead}>
                              <b>Lens Map Embed</b>
                            </div>
                            <span className={cs.shareLinkShareDetails}>
                              Include an interactive map on a webpage
                            </span>
                          </span>
                        }
                        copyableText={embedCode}
                      />
                    </th>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};
