import * as B from '@blueprintjs/core';
import * as S from '@blueprintjs/select';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature/types';

import * as cs from './styles.styl';

interface FeatureMultiSelectProps {
  features: ApiFeature[];
  onSelectionUpdate: (selection: ApiFeature[]) => void;
  initialSelection?: ApiFeature[];
  placeholder?: string;
  radioSelectionProps?: RadioSelectionProps;
  resetButtonProps?: {
    hidden: boolean;
    tooltip: string;
  };
}

interface RadioSelectionProps {
  title?: string | null;
  allText?: string;
  noneText?: string;
  someText?: string;
}

const defaultRadioText: RadioSelectionProps = {
  title: 'Select properties:',
  allText: 'All properties',
  noneText: 'No properties',
  someText: 'Select specific properties:',
};

export const FeatureMultiSelect: React.FC<FeatureMultiSelectProps> = ({
  features,
  onSelectionUpdate,
  initialSelection = [],
  placeholder,
  radioSelectionProps = {},
  resetButtonProps,
}) => {
  const MultiSelect = S.MultiSelect.ofType<ApiFeature>();
  const [selectedItems, setSelectedItems] = React.useState<ApiFeature[]>(initialSelection);
  const {allText, noneText, someText, title}: RadioSelectionProps = Object.assign(
    {},
    defaultRadioText,
    radioSelectionProps
  );
  const {hidden: resetButtonHidden, tooltip: resetButtonTooltip} = resetButtonProps || {
    hidden: true,
    tooltip: 'Reset to initial selection',
  };

  const composeName = (feature: ApiFeature): string =>
    feature.properties.multiFeaturePartName
      ? feature.properties.name + ' > ' + feature.properties.multiFeaturePartName
      : feature.properties.name;

  React.useEffect(() => {
    onSelectionUpdate(selectedItems);
  }, [selectedItems, onSelectionUpdate]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const {value} = event.target as HTMLInputElement;
    if (value === 'all') {
      setSelectedItems(features);
    } else if (value === 'none') {
      setSelectedItems([]);
    }
  };

  return (
    <>
      <B.RadioGroup
        label={title}
        onChange={handleChange}
        selectedValue={
          selectedItems.length === features.length
            ? 'all'
            : selectedItems.length === 0
              ? 'none'
              : 'some'
        }
      >
        <B.Radio label={allText} value="all" />
        <B.Radio label={noneText} value="none" />
      </B.RadioGroup>
      <p style={{marginTop: '1rem'}}>{someText}</p>
      <div className={cs.featureSelectInputContainer}>
        <MultiSelect
          fill={true}
          items={features}
          itemRenderer={(item, {handleClick}) => (
            <B.MenuItem
              key={item.id}
              text={composeName(item)}
              onClick={handleClick}
              active={selectedItems.includes(item)}
            />
          )}
          itemPredicate={(query, item) => {
            return (
              selectedItems.findIndex((feature) => feature.properties.id === item.properties.id) <
                0 && composeName(item).toLowerCase().includes(query.toLowerCase())
            );
          }}
          itemsEqual={(a, b) => a.id === b.id}
          onItemSelect={(item) => {
            // if feature is already selected, remove it
            if (selectedItems.includes(item)) {
              setSelectedItems(selectedItems.filter((i) => i !== item));
            } else {
              setSelectedItems([...selectedItems, item]);
            }
          }}
          noResults={<B.MenuItem disabled={true} text="No results." />}
          tagRenderer={(item) => <span className={cs.featureTag}>{composeName(item)}</span>}
          tagInputProps={{
            onRemove: (_, index) => setSelectedItems(selectedItems.filter((_, i) => i !== index)),
          }}
          selectedItems={selectedItems}
          placeholder={placeholder || 'Select or type to search...'}
        />
        {!resetButtonHidden && (
          <B.Tooltip position={B.PopoverPosition.TOP} className={cs.resetButton}>
            <B.Button
              text="Reset"
              icon={'reset'}
              minimal
              onClick={() => setSelectedItems(initialSelection)}
            />
            <span>{resetButtonTooltip}</span>
          </B.Tooltip>
        )}
      </div>
    </>
  );
};
