import * as B from '@blueprintjs/core';
import React from 'react';

import {ManagePropertiesMode} from 'app/pages/ManageProperties';
import {GroupPropertiesOptions} from 'app/pages/ManageProperties/ManageFeaturesProvider';

import cs from './styles.styl';

export interface Props {
  groupProperties: GroupPropertiesOptions;
  setGroupProperties: (options: GroupPropertiesOptions) => void;
  mode: ManagePropertiesMode;
}

const GeometryFileBehaviorCallout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  groupProperties,
  setGroupProperties,
  mode,
}) => {
  return (
    <div className={cs.groupingSelects}>
      {mode === 'upload' && (
        <B.Callout className={cs.callout} intent={B.Intent.WARNING} icon={null}>
          <div>
            {`At least 2 features have the same name. ${
              mode === 'upload'
                ? `Please edit the Property
      Names or choose another field to differentiate them.`
                : `Please edit the Property Names or add Location Names to differentiate them.`
            }`}
            <B.RadioGroup
              onChange={(ev) =>
                setGroupProperties(
                  ev.currentTarget.value === 'group' || ev.currentTarget.value === 'individual'
                    ? ev.currentTarget.value
                    : null
                )
              }
              selectedValue={groupProperties ?? undefined}
            >
              <B.Radio
                className={cs.radioOptions}
                labelElement={
                  <span>
                    <span className={cs.bold}>
                      Combine as a{' '}
                      <a href="https://support.upstream.tech/article/31-multi-location-properties">
                        multi-location property.
                      </a>
                    </span>{' '}
                    Ideal if you'd like to create a single report for all locations.
                  </span>
                }
                value="group"
              />
              <B.Radio
                className={cs.radioOptions}
                labelElement={
                  <span>
                    <span className={cs.bold}>Keep as separate properties.</span> Ideal if you plan
                    to create individual reports.
                  </span>
                }
                value="individual"
              />
            </B.RadioGroup>
          </div>
        </B.Callout>
      )}
      {(mode === 'draw' || mode === 'parcel') && (
        <B.Callout className={cs.callout} intent={B.Intent.WARNING} icon={null}>
          <ul>
            At least two features in this portfolio have the same Property Name.{' '}
            <li>
              To keep these properties separate (ideal for running individual reports),{' '}
              <strong>you must edit the Property Names to be different.</strong>
            </li>
            <li>
              To combine the features into one property (ideal for creating a single report for all
              locations), <strong> keep their Property Names the same</strong>. You may optionally
              add a Location Name to distinguish between them.
            </li>
          </ul>
        </B.Callout>
      )}
    </div>
  );
};

export default GeometryFileBehaviorCallout;
