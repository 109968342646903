import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {recordEvent} from 'app/tools/Analytics';
import colors from 'app/utils/colorUtils';
import * as CONSTANTS from 'app/utils/constants';
import {atLeastLensTier} from 'app/utils/featureFlags';

import cs from './styles.styl';

interface Props {
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.ImmutableOf<ApiOrganization>;
  loggedInUserActions: LoggedInUserActions;
}

interface OnboardingTask {
  name: string;
  isComplete: boolean;
  docUrl: string;
  filter?: (
    org: I.ImmutableOf<ApiOrganization>,
    profile: I.ImmutableOf<ApiOrganizationUser>
  ) => boolean;
}

const ONBOARDING_TASKS: Record<string, OnboardingTask> = {
  property: {
    name: 'Upload a property',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/16-adding-properties',
  },
  compare: {
    name: 'Open compare mode',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/6-seeing-changes-with-view-and-compare-modes',
  },
  order: {
    name: 'Order an image',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/8-ordering-high-resolution-imagery',
    filter: (_org, profile) => profile.get('role') === CONSTANTS.USER_ROLE_OWNER,
  },
  note: {
    name: 'Add a note',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/7-making-notes',
  },
  analysis: {
    name: 'Run analysis',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/17-analysis-tool',
  },
  lookout: {
    name: 'Enroll in lookout',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/164-lens-lookout',
    filter: (org, _profile) => {
      return atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, org);
    },
  },
  report: {
    name: 'Create a report',
    isComplete: false,
    docUrl: 'https://support.upstream.tech/article/18-generating-reports',
  },
};

export const Onboarding = ({profile, organization, loggedInUserActions}: Props) => {
  const userSettings: ApiOrganizationUser['settings'] = profile.get('settings').toJS();

  const onboardingTasks: ({id: string} & OnboardingTask)[] = React.useMemo(() => {
    if (!userSettings.showOnboarding) {
      return [];
    }
    return Object.keys(ONBOARDING_TASKS)
      .filter((taskKey) => {
        const filter = ONBOARDING_TASKS[taskKey].filter;
        return filter ? filter(organization, profile) : true;
      })
      .map((taskKey) => {
        const isComplete = userSettings.onboardingTasks?.[taskKey];
        return {id: taskKey, ...ONBOARDING_TASKS[taskKey], isComplete: !!isComplete};
      });
  }, [organization, profile, userSettings]);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [forceViewList, setForceViewList] = React.useState<boolean>(false);

  if (!userSettings.showOnboarding) {
    return;
  }

  const isComplete = onboardingTasks.every((task) => task.isComplete);

  return (
    <B.Popover
      className={cs.onboardingMenuPopover}
      isOpen={isOpen}
      autoFocus={false}
      enforceFocus={false}
      content={
        !isComplete || forceViewList ? (
          <div className={cs.onboardingTaskListContainer}>
            <ul className={cs.onboardingTaskList}>
              {onboardingTasks.map((task, i) => {
                if (!task) {
                  return null;
                }

                return (
                  <li key={i}>
                    <B.Icon
                      icon={task.isComplete ? 'tick-circle' : 'circle'}
                      color={colors.green}
                    />
                    <span
                      className={cs.onboardingMenuTaskText}
                      style={{textDecoration: task.isComplete ? 'line-through' : 'none'}}
                    >
                      <a href={task.docUrl} target="_blank" rel="noopener noreferrer">
                        {task.name}
                      </a>
                    </span>
                  </li>
                );
              })}
            </ul>
            <div className={cs.onboardingMenuActions}>
              <ul>
                <li>
                  <a
                    onClick={() => {
                      recordEvent('Forced completion of onboarding task list');
                      loggedInUserActions.updateSettings({
                        onboardingTasks: onboardingTasks.reduce((acc, task) => {
                          return {...acc, [task.id]: true};
                        }, {}),
                      });
                    }}
                  >
                    Mark all complete
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (
                        confirm(
                          'Are you sure you want to permanently hide the Lens onboarding checklist?'
                        )
                      ) {
                        recordEvent('Dismissed onboarding task list');
                        loggedInUserActions.updateSettings({
                          showOnboarding: false,
                        });
                      }
                    }}
                  >
                    Don't show checklist again
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div
            className={cs.onboardingTaskListContainer}
            style={{padding: '4rem 2rem 2rem', width: '30rem'}}
          >
            <B.NonIdealState
              icon={<B.Icon icon="rocket" size={80} />}
              title="You did it!"
              iconMuted={false}
              description={
                <div>
                  Checklist complete.
                  <br />
                  You're off to the races with Lens.
                </div>
              }
            >
              <div className={cs.onboardingMenuActions}>
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        setForceViewList(true);
                      }}
                    >
                      Return to checklist
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        recordEvent('Completed onboarding task list');
                        recordEvent('Dismissed onboarding task list');
                        loggedInUserActions.updateSettings({
                          showOnboarding: false,
                        });
                      }}
                    >
                      Don't show checklist again
                    </a>
                  </li>
                </ul>
              </div>
            </B.NonIdealState>
          </div>
        )
      }
      interactionKind="click"
      position={B.Position.BOTTOM_LEFT}
    >
      <div
        className={cs.onboardingMenu}
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
      >
        <span style={{marginRight: '.5rem'}}>Get started</span>
        {isComplete ? (
          <B.Icon icon="tick-circle" color={colors.green} size={18} />
        ) : (
          <B.Spinner
            value={
              onboardingTasks.filter((task) => task.isComplete).length / onboardingTasks.length
            }
            size={B.SpinnerSize.SMALL}
          />
        )}
        <B.Icon className={cs.buttonIconSpacing} icon="chevron-down" />
      </div>
    </B.Popover>
  );
};
