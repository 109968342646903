import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import moment from 'moment';
import React from 'react';

import LensLogoSvg from 'app/components/Logo/LensLogoSvg';
import {api} from 'app/modules/Remote';
import {ApiFeature, ReportCoverPage} from 'app/modules/Remote/Feature';
import {ApiOrganization} from 'app/modules/Remote/Organization';
import {StateApiNoteWithTagSettings} from 'app/stores/NotesStore';
import * as C from 'app/utils/constants';
import featureFlags from 'app/utils/featureFlags';
import {useStateWithDeps} from 'app/utils/hookUtils';
import {
  MultiFeaturePropertyPart,
  MultiFeaturePropertyParts,
} from 'app/utils/multiFeaturePropertyUtils';

import {MultiPartPropertyKeyPage} from './ReportExportFeature';

function formatCoverPageTitle(
  feature: I.MapAsRecord<I.ImmutableFields<ApiFeature>>,
  isCombinedMultiPart: boolean,
  isMultiPartProperty: boolean,
  currentPropertyPart: MultiFeaturePropertyPart
) {
  return `${feature.getIn(['properties', 'name'])}
        ${
          isMultiPartProperty && !isCombinedMultiPart && currentPropertyPart.partName
            ? `, ${currentPropertyPart.partName}`
            : ''
        }`;
}

function scrollToTop(elementId: string, reportWindow: Window | null | undefined) {
  const element = reportWindow?.document.getElementById(elementId);
  if (element) {
    element.scrollTo(0, 0);
  }
}

/** Component that renders the default title page for a report. If we patch in report state and dispatch,
 * then the editable fields can be saved to report state. Otherwise these are just contentEditable fields.
 */
export const ReportExportTitlePage: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
    projectId: string;
    feature: I.ImmutableOf<ApiFeature>;
    filteredNotes?: StateApiNoteWithTagSettings[];
    /** True if the feature is part of a multi-part property. */
    isMultiPartProperty: boolean;
    /** True if the report is a combination of all of the parts of a multi-part property. */
    isCombinedMultiPart: boolean;
    propertyParts: MultiFeaturePropertyParts;
    defaultReportSubtitle?: string;
    reportCoverPageState?: ReportCoverPage;
    setReportState?: ((ReportStatePath: any) => void) | undefined;
    firebaseToken: string;
    reportWindow?: Window | null;
  }>
> = ({
  organization,
  projectId,
  feature,
  filteredNotes,
  propertyParts,
  isMultiPartProperty,
  isCombinedMultiPart,
  defaultReportSubtitle = 'Remote Monitoring Report',
  reportCoverPageState,
  setReportState,
  firebaseToken,
  reportWindow,
}) => {
  const currentPropertyPart = propertyParts.find((p) => p.feature === feature)!;

  const logo = organization.getIn(['settings', 'logo']);

  // Set default cover page values in these useMemos:
  const title = React.useMemo(() => {
    return reportCoverPageState && reportCoverPageState.title.length > 0
      ? reportCoverPageState.title
      : formatCoverPageTitle(
          feature,
          isCombinedMultiPart,
          isMultiPartProperty,
          currentPropertyPart
        );
  }, [
    reportCoverPageState,
    currentPropertyPart,
    feature,
    isCombinedMultiPart,
    isMultiPartProperty,
  ]);

  const subtitle = React.useMemo(() => {
    return reportCoverPageState && reportCoverPageState.subtitle.length > 0
      ? reportCoverPageState.subtitle
      : defaultReportSubtitle;
  }, [reportCoverPageState, defaultReportSubtitle]);

  const company = React.useMemo(() => {
    return reportCoverPageState && reportCoverPageState.company.length > 0
      ? reportCoverPageState.company
      : organization.get('name');
  }, [reportCoverPageState, organization]);

  const date = React.useMemo(() => {
    return reportCoverPageState && reportCoverPageState.date.length > 0
      ? reportCoverPageState.date
      : moment().format('LL');
  }, [reportCoverPageState]);

  const [smartSummaryIsLoading, setSmartSummaryIsLoading] = React.useState<boolean>(false);

  // Keeping this state separate from reportCoverPageState lets us use it to control the
  // description textarea (while still having good performance). Updates are synced to
  // the reportCoverPageState on blur.
  const [description, setDescription] = React.useState<string>(
    reportCoverPageState?.description ?? ''
  );

  return (
    <>
      <section className="cover-page">
        <div className="note-control-btns no-print">
          <B.AnchorButton
            title="Add a page for additional text"
            icon="plus"
            onClick={() => {
              const newPageRecords = reportCoverPageState?.overflowPages?.length
                ? [...reportCoverPageState.overflowPages]
                : [];
              newPageRecords.push('');
              if (setReportState) {
                setReportState([['coverPage', 'overflowPages'], newPageRecords]);
              }
            }}
          />
        </div>

        <div>
          <h1
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => {
              if (setReportState) {
                const target = e.target as HTMLElement;
                setReportState([['coverPage', 'title'], target.innerText]);
              }
            }}
          >
            {title}
          </h1>
          <h2
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => {
              if (setReportState) {
                const target = e.target as HTMLElement;
                setReportState([['coverPage', 'subtitle'], target.innerText]);
              }
            }}
          >
            {subtitle}
          </h2>
          <h3
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => {
              if (setReportState) {
                const target = e.target as HTMLElement;
                setReportState([['coverPage', 'company'], target.innerText]);
              }
            }}
          >
            {company}
          </h3>
          <h3
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => {
              if (setReportState) {
                const target = e.target as HTMLElement;
                setReportState([['coverPage', 'date'], target.innerText]);
              }
            }}
          >
            {date}
          </h3>
        </div>

        {
          // does the org have access to Smart Summaries
          featureFlags.SMART_SUMMARIES(organization) &&
            // have smart summaries not been disabled for the org
            organization.getIn(['settings', C.ORG_SETTING_SHOW_SMART_SUMMARIES]) !== false &&
            filteredNotes &&
            filteredNotes.length > 0 && (
              <B.Callout className="no-print" style={{marginBottom: '9px'}}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <B.Tooltip
                    content="Delete existing text to generate a Smart Summary"
                    disabled={description === '' || smartSummaryIsLoading}
                    /* Can’t use the default portal, since it’s in the other window. */
                    usePortal={false}
                  >
                    <B.Button
                      icon="clean"
                      text="Generate Smart Summary"
                      style={{fontFamily: 'Lato,Helvetica,Arial,sans-serif'}}
                      disabled={description !== ''}
                      loading={smartSummaryIsLoading}
                      onClick={async () => {
                        if (setReportState && description === '') {
                          setSmartSummaryIsLoading(true);

                          let summary = '';
                          try {
                            const noteIds = filteredNotes.map((n) => n.id);
                            const summaryStream = api.featureCollections
                              .features(feature.getIn(['properties', 'featureCollectionId']))
                              .summarizeNotes(firebaseToken, projectId, feature.get('id'), noteIds);
                            for await (const chunk of summaryStream) {
                              summary = chunk;
                              setDescription(summary);
                            }
                          } finally {
                            setSmartSummaryIsLoading(false);
                            setReportState([['coverPage', 'description'], summary]);
                          }
                        }
                      }}
                    />
                  </B.Tooltip>
                  <div style={{paddingLeft: '10px', fontSize: '13px', lineHeight: 1.4}}>
                    Automatically summarize the notes in this report.{' '}
                    <a
                      href="https://support.upstream.tech/article/158-smart-summary"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Learn more{' '}
                      <B.Icon icon="share" iconSize={12} style={{verticalAlign: 'inherit'}} />
                    </a>{' '}
                    or{' '}
                    <a
                      href="https://form.asana.com/?k=K-PUvJ8rLntanBC-zsI6ww&d=253187955686276"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      give feedback{' '}
                      <B.Icon icon="share" iconSize={12} style={{verticalAlign: 'inherit'}} />
                    </a>
                    .
                  </div>
                </div>
              </B.Callout>
            )
        }
        <textarea
          id="text-description"
          placeholder={smartSummaryIsLoading ? 'Generating Smart Summary…' : 'Add your text here…'}
          className="freeform-text"
          value={description}
          disabled={smartSummaryIsLoading}
          onChange={(e) => {
            const target = e.target as HTMLTextAreaElement;
            setDescription(target.value);
          }}
          onBlur={(e) => {
            scrollToTop('text-description', reportWindow);
            if (setReportState) {
              const target = e.target as HTMLTextAreaElement;
              setReportState([['coverPage', 'description'], target.value]);
            }
          }}
        />
        <div className="cover-page-footer">
          <div className="cover-page-footer-logo">
            {logo ? <img src={logo} /> : <LensLogoSvg />}
          </div>
          <div>
            <p>Report created in Upstream Tech Lens</p>
          </div>
        </div>
      </section>
      {reportCoverPageState?.overflowPages?.map((_, pageIdx) => {
        return (
          <OverflowTextPage
            key={pageIdx}
            pageIdx={pageIdx}
            logo={logo}
            smartSummaryIsLoading={smartSummaryIsLoading}
            setReportState={setReportState}
            reportCoverPageState={reportCoverPageState}
            reportWindow={reportWindow}
          />
        );
      })}
      {isMultiPartProperty && <MultiPartPropertyKeyPage propertyParts={propertyParts} />}
    </>
  );
};

export const OverflowTextPage: React.FunctionComponent<
  React.PropsWithChildren<{
    pageIdx: number;
    logo: string | undefined;
    smartSummaryIsLoading: boolean;
    setReportState?: ((ReportStatePath: any) => void) | undefined;
    reportCoverPageState: ReportCoverPage;
    reportWindow: Window | null | undefined;
  }>
> = ({
  pageIdx,
  logo,
  smartSummaryIsLoading,
  setReportState,
  reportCoverPageState,
  reportWindow,
}) => {
  const pageId = `page-${pageIdx}`;

  const [pageText, setPageText] = useStateWithDeps<string>(
    reportCoverPageState.overflowPages![pageIdx],
    [reportCoverPageState.overflowPages]
  );

  return (
    <section>
      <div className="note-control-btns no-print">
        <B.AnchorButton
          icon={'trash'}
          title="Remove this page"
          onClick={() => {
            const overflowPagesCopy = [...reportCoverPageState.overflowPages!];
            overflowPagesCopy.splice(pageIdx, 1);
            if (setReportState) {
              setReportState([['coverPage', 'overflowPages'], overflowPagesCopy]);
            }
          }}
        />
      </div>
      <div className="title-and-lens-watermark">
        <div></div>
        <div className="lens-watermark">
          <LensLogoSvg />
        </div>
      </div>
      <textarea
        id={pageId}
        placeholder={smartSummaryIsLoading ? 'Generating Smart Summary…' : 'Add your text here…'}
        className="freeform-text"
        value={pageText}
        disabled={smartSummaryIsLoading}
        onChange={(e) => {
          const target = e.target as HTMLTextAreaElement;
          setPageText(target.value);
          if (setReportState) {
            setReportState([['coverPage', 'overflowPages', pageIdx], target.value]);
          }
        }}
        onBlur={() => scrollToTop(pageId, reportWindow)}
      />

      <div className="cover-page-footer">
        <div className="cover-page-footer-logo">{logo ? <img src={logo} /> : <LensLogoSvg />}</div>
        <div>
          <p>Report created in Upstream Tech Lens</p>
        </div>
      </div>
    </section>
  );
};
