import * as B from '@blueprintjs/core';
import {length} from '@turf/turf';
import classNames from 'classnames';
import * as geojson from 'geojson';
import isEqual from 'lodash/isEqual';
import mapboxgl, {AnyLayer} from 'mapbox-gl';
import React from 'react';

import MapOverlayDialog from 'app/components/MapOverlayDialog/MapOverlayDialog';
import {MeasurementSystem} from 'app/modules/Remote/Organization';
import {ImageRefIndex} from 'app/pages/MonitorProjectView/MapStateProvider';
import {MapTool} from 'app/pages/MonitorProjectView/view';
import {useUserInfo} from 'app/providers/AuthProvider';
import {PolygonDispatch, PolygonState} from 'app/providers/MapPolygonStateProvider';
import {NotesActions, NotesState} from 'app/stores/NotesStore';
import * as CONSTANTS from 'app/utils/constants';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import {makeAreaDisplayString} from 'app/utils/geoJsonUtils';
import * as mapUtils from 'app/utils/mapUtils';
import * as mathUtils from 'app/utils/mathUtils';

import cs from './MeasureTool.styl';
import NoteForm from '../NoteCard/NoteForm';

const SOURCE_ID = 'MeasureTool';

const LAYER_POINTS_ID = 'measure-points';
const LAYER_LINESTRING_ID = 'measure-linestring';

const COLOR = '#FBB03B';

const DISTANCE_TOOL = 'measureDistance';
const AREA_TOOL = 'measureArea';
const DRAW_POLYGON_MODE = 'drawPolygon';
const DRAW_RECTANGLE_MODE = 'drawRectangle';

type PointFeatureProperties = geojson.GeoJsonProperties & {
  id: string;
};

const MeasureTool: React.FunctionComponent<
  React.PropsWithChildren<{
    map: mapboxgl.Map;
    onExit: () => unknown;
    measurementSystem?: MeasurementSystem;
    polygonDispatch: PolygonDispatch;
    polygonState: PolygonState;
    setActiveTool: (t: MapTool | null) => void;
    activeTool: MapTool | null;
    imageRefs: mapUtils.MapImageRefs;
    mapIndex: ImageRefIndex;
    notesState: NotesState;
    notesActions: NotesActions;
  }>
> = ({
  measurementSystem = 'imperial',
  polygonDispatch,
  polygonState,
  setActiveTool,
  activeTool,
  imageRefs,
  mapIndex,
  notesState,
  notesActions,
  map,
  onExit,
}) => {
  const [organization, profile] = useUserInfo();

  const [distance, setDistance] = React.useState<number>(0);
  const [showNoteForm, setShowNoteForm] = React.useState<boolean>(false);

  const pointFeaturesRef = React.useRef<geojson.Feature<geojson.Point, PointFeatureProperties>[]>(
    []
  );

  // only allow drawing the distance measure in the right window for compare mode
  const allowDrawing = imageRefs.length == 1 || (imageRefs.length == 2 && mapIndex === 1);

  const updateMap = React.useCallback(() => {
    const features: geojson.Feature[] = [...pointFeaturesRef.current];

    if (features.length >= 2) {
      // We can only show a line / measure if there are at least 2 points
      const lineStringFeature = geoJsonUtils.lineString(
        pointFeaturesRef.current.map((p) => p.geometry.coordinates)
      );

      features.push(lineStringFeature);
      setDistance(length(lineStringFeature));
    } else {
      setDistance(0);
    }

    const source = map.getSource(SOURCE_ID) as mapboxgl.GeoJSONSource;
    source && source.setData(geoJsonUtils.featureCollection(features));
  }, [map]);

  const onMapClick = React.useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      const clickedPointFeatures = mapUtils.safeQueryRenderedFeatures(map, e.point, {
        layers: [LAYER_POINTS_ID],
      });

      const clickedPoint = clickedPointFeatures?.[0];

      if (clickedPoint && clickedPoint.properties) {
        pointFeaturesRef.current = pointFeaturesRef.current.filter(
          (p) => clickedPoint.properties!.id !== p.properties.id
        );
      } else {
        // Since the user didn’t click on an existing point, we should add a new
        // one where they clicked on the map. We add an ID so we can reference it
        // when deleting it later. (The ID has to be in properties because Mapbox
        // doesn’t round-trip the Feature’s ID.)
        pointFeaturesRef.current.push(
          geoJsonUtils.point([e.lngLat.lng, e.lngLat.lat], {id: `MeasureTool-${Date.now()}`})
        );
      }

      updateMap();
    },
    [map, updateMap]
  );

  // TODO(fiona): Update this to use MapContent
  const setupMeasureDistance = React.useCallback(() => {
    pointFeaturesRef.current = [];
    map.doubleClickZoom.disable();

    mapUtils.safeAddSource(map, SOURCE_ID, {
      type: 'geojson',
      data: geoJsonUtils.featureCollection(pointFeaturesRef.current),
    });

    mapUtils.safeAddLayer(map, DISTANCE_POINT_LAYER_CONFIG);
    mapUtils.safeAddLayer(map, DISTANCE_LINE_LAYER_CONFIG);

    map.on('click', onMapClick);
  }, [map, onMapClick]);

  const teardownMeasureDistance = React.useCallback(() => {
    map.off('click', onMapClick);
    map.doubleClickZoom.enable();

    mapUtils.safeRemoveLayer(map, LAYER_POINTS_ID);
    mapUtils.safeRemoveLayer(map, LAYER_LINESTRING_ID);
    mapUtils.safeRemoveSource(map, SOURCE_ID);
  }, [map, onMapClick]);

  const onClear = () => {
    pointFeaturesRef.current = [];
    polygonDispatch({type: 'setPolygonFeature', polygonFeature: null});

    updateMap();
  };

  const onClearAndExit = () => {
    onClear();
    onExit();
  };

  React.useEffect(() => {
    // on component mount
    if (allowDrawing) {
      setupMeasureDistance();
    }
    return () => {
      // on component unmount
      if (allowDrawing) {
        teardownMeasureDistance();
      }
    };
  }, [allowDrawing, imageRefs.length, mapIndex, setupMeasureDistance, teardownMeasureDistance]);

  // make sure that we're storing our polygons in notesState
  React.useEffect(() => {
    const polygonFeature = polygonState.polygon;
    const noteGeometry = notesState.pendingNoteGeometryFeature?.geometry;
    if (polygonFeature && !isEqual(polygonFeature, noteGeometry)) {
      notesActions.setPendingNoteGeometryFeature(
        geoJsonUtils.feature(polygonFeature, {}, {id: `pending-note-location-feature`})
      );
    }
  }, [notesActions, notesState.pendingNoteGeometryFeature?.geometry, polygonState.polygon]);

  if (!allowDrawing) {
    return null;
  }

  return (
    <MapOverlayDialog
      className={cs.container}
      title="Measure"
      onClose={onClearAndExit}
      additionalButtons={
        <B.Tooltip content="Undo" position={B.Position.TOP}>
          <B.AnchorButton
            icon="undo"
            disabled={
              activeTool === DISTANCE_TOOL
                ? !distance && !pointFeaturesRef.current.length
                : !polygonState.polygon
            }
            minimal={true}
            onClick={() => onClear()}
          />
        </B.Tooltip>
      }
    >
      <div className={cs.spacing}>
        <B.ButtonGroup>
          <B.AnchorButton
            onClick={() => {
              onClear();
              setupMeasureDistance();
              setActiveTool(DISTANCE_TOOL);
            }}
            active={activeTool === DISTANCE_TOOL}
          >
            Distance
          </B.AnchorButton>
          <B.AnchorButton
            onClick={() => {
              onClear();
              teardownMeasureDistance();
              setActiveTool(AREA_TOOL);
            }}
            active={activeTool === AREA_TOOL}
          >
            Area
          </B.AnchorButton>
        </B.ButtonGroup>
      </div>
      {activeTool === DISTANCE_TOOL && (
        <div>
          <p>
            Click the map to add points to the measured line. To delete a point, click on it. To
            delete the line, click the <strong>Undo</strong> button.
          </p>
          <strong>Distance: </strong>
          {
            mathUtils.formatMeasurement({
              value: distance,
              unit: CONSTANTS.UNIT_LENGTH_KM,
              unitTo:
                measurementSystem === 'metric' ? CONSTANTS.UNIT_LENGTH_M : CONSTANTS.UNIT_LENGTH_FT,
              decimals: 0,
            }).valueWithUnit
          }
          <mapUtils.DisableClickableFeatures />
        </div>
      )}
      {activeTool === AREA_TOOL && (
        <>
          <p className={cs.spacing}>
            {polygonState.analyzeAreaDrawMode === DRAW_POLYGON_MODE &&
              'Click the map to add vertices. Double click to complete.'}
            {polygonState.analyzeAreaDrawMode === DRAW_RECTANGLE_MODE &&
              'Click the map to start drawing a rectangle. Click again to complete.'}{' '}
            To start over, click the <strong>Undo</strong> button.
          </p>
          <div className={cs.spacing}>
            <B.AnchorButton
              icon="polygon-filter"
              onClick={() => {
                onClear();
                polygonDispatch({
                  type: 'setAnalyzeAreaDrawMode',
                  analyzeAreaDrawMode: DRAW_POLYGON_MODE,
                });
              }}
              active={polygonState.analyzeAreaDrawMode === DRAW_POLYGON_MODE}
            ></B.AnchorButton>

            <B.AnchorButton
              icon="widget"
              onClick={() => {
                onClear();
                polygonDispatch({
                  type: 'setAnalyzeAreaDrawMode',
                  analyzeAreaDrawMode: DRAW_RECTANGLE_MODE,
                });
              }}
              active={polygonState.analyzeAreaDrawMode === DRAW_RECTANGLE_MODE}
            ></B.AnchorButton>
          </div>
          <div className={classNames(cs.spacing, cs.areaResults)}>
            <div>
              <strong>Area estimate:</strong>
              <div>
                {measurementSystem === 'imperial' ? 'Square feet' : 'Square meters'}:{' '}
                {makeAreaDisplayString(
                  polygonState.polygon ?? null,
                  measurementSystem === 'imperial' ? 'areaF2' : 'areaM2',
                  true
                ) ?? 0}
              </div>
              <div>
                {measurementSystem === 'imperial' ? 'Acres' : 'Hectares'}:{' '}
                {makeAreaDisplayString(
                  polygonState.polygon ?? null,
                  measurementSystem === 'imperial' ? 'areaAcre' : 'areaHectare',
                  true
                ) ?? 0}
              </div>
            </div>
            {!showNoteForm && (
              <B.Button
                intent="primary"
                disabled={!polygonState.polygon}
                onClick={() => {
                  setShowNoteForm(true);
                }}
              >
                Add note
              </B.Button>
            )}
          </div>
          {showNoteForm && (
            <NoteForm
              organization={organization!}
              profile={profile!}
              imageRefs={imageRefs}
              notesState={notesState}
              notesActions={notesActions}
              renderMinimal={true}
              onClose={() => {
                setShowNoteForm(false);
              }}
            />
          )}
        </>
      )}
    </MapOverlayDialog>
  );
};

export default MeasureTool;

const DISTANCE_POINT_LAYER_CONFIG: AnyLayer = {
  id: LAYER_POINTS_ID,
  type: 'circle',
  source: SOURCE_ID,
  paint: {
    'circle-radius': 5,
    'circle-color': COLOR,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#FFFFFF',
  },
  filter: ['in', '$type', 'Point'],
};

const DISTANCE_LINE_LAYER_CONFIG: AnyLayer = {
  id: LAYER_LINESTRING_ID,
  type: 'line',
  source: SOURCE_ID,
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': COLOR,
    'line-width': 2.5,
    'line-dasharray': [2, 2],
  },
  filter: ['in', '$type', 'LineString'],
};
