import {useLocalLoginApp} from 'app/utils/envUtils';

/* 
  More sophisticated environment related variables and helpers that 
  lean heavily on ./envUtils.js, but wouldn't be good to define there,
  since that file is run during a specific part of the app lifecycle.
*/

export const loginAppUrl = useLocalLoginApp
  ? 'http://localhost:3001'
  : 'https://login.upstream.tech';
