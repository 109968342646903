import classnames from 'classnames';
import React, {CSSProperties} from 'react';

import logoSrc from './assets/lens_logo.svg';
import noTextLogoSrc from './assets/lens_logo_no_text.svg';
import cs from './Logo.styl';

const LensLogo: React.FunctionComponent<
  React.PropsWithChildren<{
    noText?: boolean;
    isWhite?: boolean;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
  }>
> = ({noText, isWhite, className, style, onClick}) => {
  return (
    <div
      className={classnames(className, cs.imageContainer, {
        [cs.isWhite]: isWhite,
        [cs.isButton]: onClick,
      })}
      style={style}
      onClick={onClick}
    >
      {noText ? <img alt="lens icon" src={noTextLogoSrc} /> : <img alt="lens logo" src={logoSrc} />}
    </div>
  );
};

export default LensLogo;
