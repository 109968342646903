/**
 * This file contains constants useful across frontend code.
 *
 * For constants shared between backend and frontend code, refer to the
 * common/constants.json file in the root directory.
 */

export type SortDirection = 'ascending' | 'descending';

export const SORT_ASC = 'ascending';
export const SORT_DESC = 'descending';
