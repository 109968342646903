import * as B from '@blueprintjs/core';
import classNames from 'classnames';
import React, {SyntheticEvent} from 'react';

import cs from './Modal.styl';

/** This modal component is most useful for dialogs where users fill out a form and must submit,
 * but could be useful for other modal cases as well.
 */
const Modal: React.FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean;
    onClose: (event: SyntheticEvent<HTMLElement> | undefined) => unknown;
    className?: string;
    headerText?: string;
    canOutsideClickClose?: boolean;
    primaryButton?: {
      text: string;
      intent?: B.Intent;
      isLoading?: boolean;
      isDisabled?: boolean;
      onClick?: () => void; // will override onSubmit
      minimal?: boolean;
    };
    secondaryButton?: {
      text?: string;
      intent?: B.Intent;
      isLoading?: boolean;
      isDisabled?: boolean;
      onClick?: () => void; // will override onClose
      minimal?: boolean;
    };
    hideActions?: boolean;
    onSubmit?: () => unknown;
    children?: React.ReactNode;
    onOpening?: () => void;
  }>
> = ({
  className,
  isOpen,
  onSubmit,
  onClose,
  headerText,
  children,
  primaryButton,
  secondaryButton,
  hideActions,
  canOutsideClickClose = true,
  onOpening,
}) => (
  <B.Dialog
    canOutsideClickClose={canOutsideClickClose}
    canEscapeKeyClose={true}
    isOpen={isOpen}
    onClose={onClose}
    className={classNames(className, cs.dialog)}
    onOpening={onOpening}
  >
    <div className={cs.header}>
      {/* TODO(eva): potentially add capabilities for a "back" button here as well */}
      <B.Button
        data-test="close-icon"
        icon={<B.Icon icon="cross" />}
        className={cs.closeButton}
        onClick={onClose}
        minimal={true}
      />
      {headerText && <span className={cs.headerText}>{headerText}</span>}
    </div>
    <div className={cs.body}>{children}</div>
    {!hideActions && (
      <div className={cs.footer}>
        {secondaryButton && (
          <B.Button
            data-test="cancel-button"
            onClick={secondaryButton.onClick || onClose}
            intent={secondaryButton.intent}
            loading={!!secondaryButton.isLoading}
            disabled={!!secondaryButton.isDisabled}
            minimal={secondaryButton.minimal}
          >
            {secondaryButton.text ? secondaryButton.text : 'Cancel'}
          </B.Button>
        )}
        {primaryButton && (
          <B.Button
            data-test="primary-button"
            intent={primaryButton.intent ? primaryButton.intent : 'primary'}
            disabled={!!primaryButton.isDisabled}
            loading={!!primaryButton.isLoading}
            type="submit"
            onClick={primaryButton.onClick || onSubmit}
            minimal={primaryButton.minimal}
          >
            {primaryButton.text}
          </B.Button>
        )}
      </div>
    )}
  </B.Dialog>
);

export default Modal;
