import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';
import Gravatar from 'react-gravatar';

import {ApiOrganizationUser} from 'app/modules/Remote/Organization';

import cs from './UserAvatar.styl';

const UserAvatar: React.FunctionComponent<
  React.PropsWithChildren<{
    profile: ApiOrganizationUser;
    isTooltipEnabled?: boolean;
    className?: string;
    openTooltipForTest?: boolean;
  }>
> = ({profile, isTooltipEnabled, className, openTooltipForTest}) => {
  const renderedAvatar = (
    <div className={classnames(cs.avatarContainer, className)}>
      <Gravatar email={profile.email} size={32} />
      <div className={cs.online} />
    </div>
  );

  if (isTooltipEnabled) {
    return (
      <B.Tooltip content={profile.name} isOpen={openTooltipForTest}>
        {renderedAvatar}
      </B.Tooltip>
    );
  } else {
    return renderedAvatar;
  }
};

export default UserAvatar;
